import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { filter, map, defaults, extend } from 'lodash';

import { UserProfile } from 'angularjs-providers/user.provider';

import { NotificationModalComponent } from 'commons/components/modals';

import { FacebookPagesModalComponent, FacebookMortgageRelatedModalComponent, LinkedInPagesModalComponent } from 'tpo/social-accounts';
import { ConnectsModalComponent } from 'tpo/social-accounts/connects-modals';
import { SocialConnectResourceService } from 'tpo/social-accounts/company/social-connect-resource.service';

import { GmbConnector, SmNetworkConnector } from './connectors';
import { GMBListingsModalComponent } from './gmb-listings';

declare let window: Window;
declare let screen: Screen;

@Injectable()
export class ConnectSmMixin {
	networks: { [key: string]: SmNetworkConnector };
	modalRef: BsModalRef;
	modalService: BsModalService;
	onConnectFinish: (data?) => void;
	connectModalState: {
		resolving: boolean,
		title?: string,
		message?: string,
		services: SmNetworkConnector[],
	};
	stateService: StateService;
	socialConnectService: SocialConnectResourceService;
	connecting: boolean = false;
	callbacks: { [key: string]: { window: Window, action: () => void } } = {};
	User: UserProfile;

	connect(): void {
		const initialState = {
			resolving: false,
			...this.connectModalState,
		};

		this.modalRef = this.modalService.show(ConnectsModalComponent, {
			class: 'modal-smd social-accounts-modal modal-new',
			initialState,
		});
	}

	reconnect(entry) {
		const { networkCode } = entry;
		this.networks[networkCode].click(entry);
	}

	openConnectWindow(connector: SmNetworkConnector): Promise<boolean> {
		this.hideModal();

		return new Promise((resolve) => {
			const { url, windowParams: { width, height } } = connector;
			const top = (screen.height - height) / 2;
			const left = (screen.width - width) / 2;
			const connectorWindow = window.open(
				url,
				connector.getWindowName(),
				`width=${width},height=${height},top=${top},left=${left}`
			);
			const interval = window.setInterval((() => {
				if (connectorWindow.closed) {
					window.clearInterval(interval);
					resolve(true);
				}
			}), 1000);
		});
	}

	connectCommon(connector: SmNetworkConnector): void {
		this.openConnectWindow(connector)
			.then(() => {
				this.onConnectFinish();
			});
	}

	hideModal() {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	// Legacy support
	getStatusesForConnect(): any {
		return [];
	}

	noFacebookPagesToConnect(): void {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'No Facebook Pages',
				confirmText: 'Ok',
				notification: 'You have no Facebook Pages to connect',
				onConfirm: () => {
					this.hideModal();
				},
			},
			class: 'modal-smd modal-new',
		});

		this.connecting = false;
		// window.FB.logout();
	}

	showFacebookPagesSelection(data, accessToken): void {
		const pages = map(data, (page) => defaults(page, { $selected: true }));

		let shouldResolve = true;
		const list = pages;
		const groups = [
			{
				type: 'LOCAL_BUSINESS',
				title: 'Page',
			},
			{
				type: 'STORE',
				title: 'Store',
			},
		];

		this.modalRef = this.modalService.show(FacebookPagesModalComponent, {
			class: 'modal-smd modal-new',
			initialState: {
				list,
				groups,
				onConfirm: () => {
					this.hideModal();
					shouldResolve && (this.connecting = false);
					shouldResolve = false;
					const entries = filter(list, (page) => page.$selected);
					this.connectSelectedFacebookPages(entries, accessToken);
				},

				onCancel: () => {
					this.hideModal();
					this.connecting = false;
				},

				shouldResolve,
			},
		});
	}

	showFacebookMortgageRelatedSelection(data, accessToken): void {
		//const pages = map(data, (page) => defaults(page, { $selected: true }));

		/*let shouldResolve = true;*/
		// const list = pages;
		const list = data.relatedMediaLinks;

		this.modalRef = this.modalService.show(FacebookMortgageRelatedModalComponent, {
			class: 'modal-smd modal-new',
			initialState: {
				list,
				onConfirm: () => {
					const context = this.modalRef.content;
					context.resolving = true;
					this.socialConnectService.setMortgageRelatedAccounts({}, list, () => {
						this.onConnectFinish();
						this.hideModal();
					});
				},

				onCancel: () => {
					this.onConnectFinish();
					this.hideModal();
					this.connecting = false;
				},
			},
		});
	}

	facebookUserShouldLogout(): void {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Already Connected',
				confirmText: 'Ok',
				notification: 'Your Facebook account is already connected. ' +
					'<a target="_blank" ng-href="https://www.facebook.com/">Log out</a> of Facebook and try once again.',
				onConfirm: () => {
					this.hideModal();
				},
			},
			class: 'modal-smd modal-new',
		});

		this.connecting = false;
	}

	connectFacebook(entry = null): void {
		const isReconnect = entry && entry.lastConnectionStatus === 'DISCONNECTED';

		const params = {
			scope: window.env.facebook_permissions,
			return_scopes: true,
		};

		if (isReconnect) {
			extend(params, {
				auth_type: 'rerequest',
				auth_nonce: Math.random().toString(36),
			});
		} else {
			this.modalRef.content.resolving = true;
		}

		window.FB.login((response) => { // @Notice: will show the FB modal window
			if (response != null && response.status === 'connected') {
				// save token for actual connect after page selection
				const accessToken = response.authResponse.accessToken;
				this.connectSelectedFacebookPages(null, accessToken);
			} else {
				if (!isReconnect) {
					this.hideModal();
				}
			}
		}, params);
	}

	connectGMB(connector: GmbConnector, entry = null): void {
		const { url } = connector;
		const isReconnect = !!entry;
		this.hideModal();

		const { width, height } = connector.windowParams;
		const left = (screen.width - width) / 2;
		const top = (screen.height - height) / 2;
		const name = connector.getWindowName();
		const connectFlow = isReconnect ? 'reconnect' : 'connect';
		const { nmlsId } = this.User.organization;
		const params = connector.isDM ? { connectType: 'digital-media', nmlsId, connectFlow } : { connectType: 'social-media', connectFlow };

		this.callbacks[name] = {
			window: window.open(url, name, `width=${width},height=${height},top=${top},left=${left}`),
			action: () => {
				if (isReconnect) {
					const { linkId } = entry;
					this.finishGMBConnect({ linkId }, params);
				} else {
					this.showGMBPages(params);
				}
			},
		};
	}

	onMessage(event) {
   		// Ignoring Facebook Event
		if (event?.data?.pcmPixelPostMessageEvent) {
			return false;
		}

		try {
			const { connected, name } = JSON.parse(event.data);
			const { [name]: callback } = this.callbacks;

			callback.window.close();
			if (connected && callback) {
				callback.action();
			} else {
				this.onConnectFinish();
			}
			delete this.callbacks[name];
		} catch (e) {
			// tslint:disable:no-console
			console.warn('onMessage Error', event);
		}
	}

	showGMBPages(params: any): void {
		this.connecting = true;
		const list = this.socialConnectService.getGMBLocations(params, () => {
		}, /* error */ () => {
			this.hideModal();
			this.connecting = false;
		});

		this.modalRef = this.modalService.show(GMBListingsModalComponent, {
			class: 'modal-smd modal-new',
			initialState: {
				list,
				onConfirm: () => {
					unsubscribable.unsubscribe();
					this.hideModal();
					const data = filter(list, (listing) => listing.$selected);
					this.finishGMBConnect({ names: data.map((value: any) => (value.name)) }, params);
				},

				onCancel: () => {
					this.hideModal();
					this.connecting = false;
				},
			},
		});

		const unsubscribable = this.modalService.onHide.subscribe(() => {
			this.connecting = false;
			unsubscribable.unsubscribe();
		});
	}

	finishGMBConnect(pages: any, params: any): void {
		this.connecting = true;
		this.socialConnectService.finishGMB(params, pages).$promise
			.finally(() => {
				this.onConnectFinish({
					GMB: {
						pages,
					},
				});
				this.connecting = false;
			});
	}

	connectFacebookPage(entry = null): void {
		const isReconnect = entry && entry.lastConnectionStatus === 'DISCONNECTED';

		const params = {
			scope: window.env.facebook_permissions,
			return_scopes: true,
		};

		if (isReconnect) {
			extend(params, {
				auth_type: 'rerequest',
				auth_nonce: Math.random().toString(36),
			});
		} else {
			this.modalRef.content.resolving = true;
		}

		window.FB.login((response) => { // @Notice: will show the FB modal window
			if (response != null && response.status === 'connected') {
				// save token for actual connect after page selection
				const accessToken = response.authResponse.accessToken;
				const statuses = this.getStatusesForConnect();
				this.socialConnectService.getFacebookPages({}, {
					token: accessToken,
					statuses,
				}, (data) => {
					if (!isReconnect) {
						this.hideModal();

						data.$promise.then(() => {
							if (!data.length) {
								this.noFacebookPagesToConnect();
								return;
							}

							this.showFacebookPagesSelection(data, accessToken);
						});
					} else {
						data.$promise.then(() => {
							this.connectSelectedFacebookPages(filter(data, { previouslyConnected: true }), accessToken, isReconnect);
						});
					}
				}, () => {
					if (!isReconnect) {
						this.hideModal();
					}
				});
			} else {
				if (!isReconnect) {
					this.hideModal();
				}
			}
		}, params);
	}

	// @selectedPages pass null to connect individual
	connectSelectedFacebookPages(selectedPages = null, accessToken, isReconnect: boolean = false): void {
		this.connecting = true;
		const statuses = this.getStatusesForConnect();
		const isDM = !!selectedPages;

		this.socialConnectService.connect({
			networkType: 'facebook',
			connectType: isDM ? 'digital-media' : 'social-media',
		}, {
			token: accessToken,
			statuses,
			...isDM ? { pages: selectedPages } : { connectAllPages: true },
		}, (data) => {
			if (data.shouldLogout) {
				this.facebookUserShouldLogout();
			} else {
				if (isDM) {
					this.onConnectFinish(isReconnect ? null : {
						FB: {
							pages: selectedPages,
						},
					});
					this.hideModal();
				} else {
					this.hideModal();
					this.showFacebookMortgageRelatedSelection(data, accessToken);
				}
				this.connecting = false;
			}
		});
	}

	connectLinkedIn(
		connector: SmNetworkConnector,
		entry,
	): void {
		const reconnect: boolean = !!entry;
		this.openConnectWindow(connector)
			.then(() => {
				this.connecting = true;
				this.socialConnectService.getLinkedInPages().$promise
					.then((data) => {
						this.connecting = false;
						if (!data.length) {
							return this.openNoLinkedInPagesWindow();
						}

						if (!reconnect) {
							return this.openLinkedInPagesWindow(data);
						}

						const selectedPages = data.filter(({ previouslyConnected }) => previouslyConnected === true);
						return this.connectLinkedInPages(selectedPages);
					})
					.finally(() => {
						this.connecting = false;
						this.hideModal();
					});
			});
	}

	connectLinkedInPages(pages): Promise<void> {
		return new Promise((resolve) => {
			this.connecting = true;
			this.socialConnectService.connect(
				{
					connectType: 'digital-media',
					networkType: 'linkedin',
				},
				{
					pages,
				}
			).$promise
				.then(() => {
					this.onConnectFinish({
						LI: {
							pages,
						},
					});
				})
				.finally(() => {
					this.connecting = false;
					resolve();
				});
		});
	}

	openLinkedInPagesWindow(pages): Promise<void> {
		return new Promise((resolve) => {
			this.modalRef = this.modalService.show(
				LinkedInPagesModalComponent,
				{
					initialState: {
						pages,
						onConfirm: (selectedPages) => {
							this.hideModal();
							this.connectLinkedInPages(selectedPages)
								.finally(() => resolve());
						},
						onCancel: () => {
							this.connecting = false;
							resolve();
						},
					},
					class: 'modal-smd modal-new',
				}
			);
		});
	}

	openNoLinkedInPagesWindow(): Promise<void> {
		return new Promise((resolve) => {
			this.modalRef = this.modalService.show(
				NotificationModalComponent,
				{
					initialState: {
						title: 'No LinkedIn Pages',
						notification: 'You have no LinkedIn Pages to connect',
						confirmText: 'Ok',
						onConfirm: () => resolve(),
					},
					class: 'modal-smd modal-new',
				}
			);
		});
	}

	showConnectInstagramModal(): void {
		this.hideModal();
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Instagram',
				confirmText: 'Close',
				notification: `Facebook now requires your Instagram business account to be linked to a Facebook page.
				For help and instructions click <a href="https://o.comergencesupport.com/solution/articles/8000077712-connecting-to-instagram" target="_blank">here</a>.
				<br>
				<br>
				Once you have linked your Instagram to a Facebook page, it will appear in your list of social accounts once we connect or update Facebook.
				Please make sure the Facebook page you link to is a connected account.`,
				onConfirm: () => {
					this.hideModal();
				},
			},
			class: 'modal-smd modal-new',
		});
	}
}
