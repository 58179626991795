import { Injectable } from '@angular/core';
import { RealmHttpClient, RealmParams } from 'commons/services/http';
import { Observable } from 'rxjs';
import { InvestorInformation, InvestorsSelfReportedInformation } from 'tpo/account/investors/investors.interface';

declare let apiPath;

@Injectable()
export class InvestorsService {

    constructor(private http: RealmHttpClient) {

    }

    getInvestors(tpoId): Observable<InvestorsSelfReportedInformation []> {
        return this.http.request(
            'GET',
            `${apiPath}/tpos/${tpoId}/self-reported-investor-information`
        );
    }

    getInvestorsInformation(tpoId): Observable<InvestorInformation[]> {
        return this.http.request(
            'GET',
            `${apiPath}/tpos/${tpoId}/investor-information`
        );
    }

    updateInvestor(tpoId, investorId: number, updatedInvestor): Observable<InvestorsSelfReportedInformation> {
        const tpoIdParam: RealmParams = {tpoId: tpoId};
        return  this.http.request<InvestorsSelfReportedInformation>(
            'PUT',
            `${apiPath}/investors/${investorId}`,
            tpoIdParam,
            updatedInvestor
        );
    }

    addNewInvestor(tpoId, newInvestor): Observable<InvestorsSelfReportedInformation> {
        const tpoIdParam: RealmParams = {tpoId: tpoId};
        return this.http.request<InvestorsSelfReportedInformation>(
            'POST',
            `${apiPath}/investors/`,
            tpoIdParam,
            newInvestor
        );
    }
}
