import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NewWarehousesService } from '../warehouses.service';
import { warehouseForm } from 'shared/account/information/new-warehouse/warehouse.form';

@Component({
    templateUrl: './new-warehouse-modal.component.html',
})
export class NewWarehouseModalComponent {
    tpoId: number;
    resolving: boolean;
    newWarehouseForm = warehouseForm();

    onConfirm: (params?) => void;

    constructor(
        private warehousesService: NewWarehousesService,
        public modalRef: BsModalRef,
    ) {
    }

    onCancel() {
        this.modalRef.hide();
    }

    async onSubmit() {
        try {
            this.resolving = true;
            await this.warehousesService.addNewWarehouse(this.tpoId, this.newWarehouseForm.getRawValue()).toPromise();
            this.onCancel();
            this.onConfirm();
        } catch (e) {
            this.newWarehouseForm.setServerError(e);
        } finally {
            this.resolving = false;
        }
    }
}
