import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';
import {
    ScorecardComment,
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments.interface';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';


@Component({
    templateUrl: './scorecard-finding-details-comments-form.component.html',
    selector: 'findings-comments-form',
})
export class ScorecardFindingDetailsCommentsFormComponent implements OnInit {

    @Input() tpoId: number;
    @Input() findingId: number;
    @Output() commentSubmitted: EventEmitter<void> = new EventEmitter<void>();

    editing: boolean = false;
    resolved: boolean = false;
    availableCommentType: CommentType[];

    findingDetailsCommentForm = new RealmFormGroup({
        comment: new RealmFormControl(
            'comment',
            {
                label: 'Comment',
            },
            Validators.required,
        ),
        commentType: new RealmFormControl(
            'commentType',
            {
                label: 'Comment Type',
            }
        ),
    });

    constructor(
        public scorecardService: ScorecardService,
    ) {

    }

    async ngOnInit(): Promise<void> {
        try {
            this.availableCommentType = await this.scorecardService.getCommentTypes().toPromise();
            if(this.availableCommentType.length) {
                this.findingDetailsCommentForm.patchValue({commentType: this.availableCommentType[0]});
            }
        } finally {
            this.resolved = true;
        }

    }

    cancelEditing(): void {
        this.findingDetailsCommentForm.get('comment').reset();
        this.editing = false;
    }

    setEditing(): void {
        this.editing = true;
    }

    async saveComment(): Promise<void> {
        const newComment: ScorecardComment = {
            comment: this.findingDetailsCommentForm.get('comment').value,
            commentType: this.findingDetailsCommentForm.get('commentType').value.code,
            tpoId: this.tpoId,
        }
        try {
            this.resolved = false;
            await this.scorecardService.createScorecardFindingComment(this.tpoId, this.findingId, newComment).toPromise();
            this.commentSubmitted.emit();
        } finally {
            this.resolved = true;
        }
    }
}
