import { Component, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { defaults, includes, map, uniq } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

type Group = { type: string, title: string };

@Component({
	templateUrl: './facebook-pages-modal.component.html',
})
export class FacebookPagesModalComponent implements AfterViewInit {
	title: string;
	message: string;
	confirmText: string;
	cancelText: string;
	list: any;
	groups: Group[] = [];
	onConfirm: (params?: any) => any;
	onCancel: (params?: any) => any;
	resolving: boolean;
	shouldResolve?: boolean;
	User: any;
	$all: {[key: string]: boolean} = {};

	constructor(
		public modalRef: BsModalRef,
		userService: UserService,
	) {
		this.User = userService.profile;

		defaults(this, {
			title: 'Company Social Media',
			message: `Confirm by selecting ${this.User.organization.name} Facebook pages and stores you wish to connect.`,
			confirmText: 'Save',
			cancelText: 'Cancel',
			shouldResolve: true,
			submitDisabled: true,
			resolving: false,
		});
	}

	ngAfterViewInit(): void {
		const groupsActive = uniq(map(this.list, 'type'));
		this.groups = this.groups.filter((group) => {
			const { type } = group;
			if (includes(groupsActive, type)) {
				this.checkAll(group);
				return true;
			} else {
				return false;
			}
		});
	}

	checkAll(group: Group) {
		const { type } = group;

		setTimeout(() => {
			this.$all[type] = this.list.reduce((acc, item) => {
				return acc && (item.type !== type || item.$selected);
			}, true);
		});
	}

	selectAll(group: Group) {
		const { type } = group;
		setTimeout(() => {
			this.list.forEach((item) => {
				if (item.type === type && !item.previouslyConnected) {
					item.$selected = this.$all[type];
				}
			});
			this.checkAll(group);
		});
	}
}
