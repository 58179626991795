<div class="rows-bordered remote-resource" [class.resolved] = !resolving>
    <div class="row-fluid header">
        <div class="col-md-3"><b>Individual Name</b></div>
        <div class="col-md-a"><b>Status</b></div>
        <div class="col-md-a"><b>Type</b></div>
        <div class="col-md-2"><b class="two-liners">Individual NMLS ID</b></div>
        <div class="col-md-2"><b>Email</b></div>
        <div class="col-md-2"><b>Phone</b></div>
    </div>
    <div class="row-fluid" *ngFor="let item of list | async" [ngSwitch]="item.type.id">
        <div class="col-md-3 row client" *ngSwitchCase="'CUSTOMER'">
            <a uiSref="customers.:customerType.:organizationId.manage.:actionType.:id"
                [uiParams]="{customerType: 'customer', organizationId: item.relatedAccount.organizationId, actionType: 'view', id: item.userId}"
                class="text-bigger"
                target="_blank">{{item.fullName}}</a>
            <br>
            <a uiSref="customers.:customerType.:organizationId"
               [uiParams]="{customerType: 'customer', organizationId: item.relatedAccount.organizationId}"
               class="text-light"
               target="_blank">
                {{item.relatedAccount.name.trim()}}
                <span *ngIf="item.relatedAccount.nmlsId">, NMLS ID: {{item.relatedAccount.nmlsId}}</span>
            </a>
        </div>
        <div class="col-md-3 row client" *ngSwitchCase="'T'">
            <a uiSref = "tpo.:id.contacts.:contactId.information"
               [uiParams] ="{id: item.relatedAccount.tpoId, contactId: item.contactId}"
               class="text-bigger"
               target="_blank">
                    {{item.fullName}}
            </a>
            <br>
            <a uiSref="tpo.:id.account.information"
             [uiParams]="{id: item.relatedAccount.tpoId}"
               class="text-light" target="_blank">
                {{item.relatedAccount.name.trim()}}
                <span *ngIf="item.relatedAccount.nmlsId">, NMLS ID: {{item.relatedAccount.nmlsId}}</span>
            </a>
        </div>
        <div class="col-md-3 row client" *ngSwitchDefault>
            <span class="text-bigger">{{item.fullName}}</span>
            <br>
            <span class="text-light">{{item.relatedAccount.name.trim()}}</span>
        </div>
        <div class="col-md-a solid">
            <ng-container *ngIf="statusConfig[item.status.id]; let status">
                <span class="min-65 label" [ngClass]="'label-' + status.color">{{status.title}}</span>
            </ng-container>
        </div>
        <div class="col-md-a">{{item.type.name}}</div>
        <div class="col-md-2" *ngIf="item.individualNmlsId">
            <a uiSref="nmls.individual.:nmlsId"
            [uiParams]="{nmlsId: item.individualNmlsId}" target="_blank">{{item.individualNmlsId}}</a>
        </div>
        <div class="col-md-2" *ngIf="!item.individualNmlsId">&ndash;</div>
        <div class="col-md-2"><a href="mailto:{{item.email}}">{{item.email}}</a></div>
        <div class="col-md-2">{{item.phone | tel }}</div>
    </div>
    <div class="row-fluid" *ngIf="(list | async)?.length == 0">
        <span class="col-md-12 text-muted text-center text-bigger">No individuals found</span>
    </div>
    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>
