<div class="add-question" [ngClass]="{ 'edit': editing, 'remote-resource': hasOwnLoader, 'resolved': list.$resolved }">
	<div class="flex-row gap" *ngIf="User.can('MANAGE_GLOBAL_QUESTIONNAIRE') && !editing">
		<div class="flex-grow"></div>
		<div class="dropdown" dropdown>
			<button type="button" [disabled]="disabled" class="dropdown-toggle btn btn-primary" dropdownToggle>
				Add Question
			</button>
			<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
				<li>
					<a class="link-option" (click)="edit(false)">New Question</a>
				</li>
				<li>
					<a
						class="link-option"
						uiSref="questionnaires.:questionnaireId.from-bank"
						[uiParams]="{ questionnaireId: questionnaireId }"
					>
						From Bank
					</a>
				</li>
			</ul>
		</div>
		<button class="btn btn-secondary" [disabled]="disabled" (click)="edit(true)">Add Group</button>
	</div>

	<form
		*ngIf="editing"
		[formGroup]="form"
		class="add-question-form"
		(onValidSubmit)="save()"
	>
		<questionnaire-question-edit
			*ngIf="question"
			[availableDocuments]="availableDocuments"
			[question]="question"
			[form]="form"
			[isNew]="true"
			[isGroup]="isGroup"
		>
			<div form-footer class="form-group row">
				<hr [class.no-margin-top]="!isSelect || isGroup">
				<label class="col-sm-2 control-label aligned-label required">{{place.label}}</label>
				<div class="col-sm-3">
					<ng-select
						formControlName="place"
						[items]="afterOptions"
						bindValue="i"
						bindLabel="name"
						[clearable]="false"
					></ng-select>
				</div>
				<span
					*ngIf="hints[place.value] as hint"
					class="text-primary glyphicon glyphicon-eye-open medium-icon"
					[tooltip]="templateRef"
				>
					<ng-template #templateRef>
						<span class="text-default" [innerHTML]="hint"></span>
					</ng-template>
				</span>
			</div>
		</questionnaire-question-edit>

		<div class="flex-row right">
			<button type="submit" class="btn btn-primary" tabindex="20">
				Add
			</button>
			<button class="btn btn-default" (click)="cancel()" tabindex="20">Cancel</button>
		</div>
	</form>
</div>
