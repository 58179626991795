<div>
    <div class="pull-right comment-info text-light text-smaller">
        <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-8 text-right">
                {{ comment.createdDate | date:'MM/dd/yyyy HH:mm' }}
            </div>
        </div>
    </div>
    <b>{{ comment.createdBy }}</b>
    <p class="comment">{{ comment.comment }}</p>
</div>
