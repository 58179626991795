import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

@Component({
	templateUrl: './order-status-modal.component.html',
})
export class OrderStatusModalComponent implements OnInit {
	order: any;
	statuses: any;
	update: (status: any) => Promise<any>;
	resolved = true;
	statusControlPlaceholder: string = '';

	form: RealmFormGroup = new RealmFormGroup({
		status: new RealmFormControl(
			'status',
			{
				label: 'Review Status',
				updateOn: 'change',
			},
			DropdownRequiredValidator,
		),
	});


	get statusControl(): RealmFormControl {
		return this.form.get('status') as RealmFormControl;
	}

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit(): void {
		this.form.reset(this.order);
		this.statusControlPlaceholder = `Select ${this.statusControl.label}`;
	}

	submit(): void {
		const { status: code } = this.form.value;

		this.resolved = false;
		this.update({ code })
			.then(
				() => {
					this.close();
				},
				({ data }) => {
					this.form.setServerError(data);
				}
			)
			.finally(() => {
				this.resolved = true;
			})
	}

	close(): void {
		this.modalRef.hide();
	}
}
