import { NgModule } from '@angular/core';
import { RegistrationComponent } from 'login/registration/registration.component';
import { RegistrationService } from 'login/registration/registration.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { CommonModule } from '@angular/common';
import {
    RegistrationPasswordCreationComponent,
} from 'login/registration/registration-password-creation/registration-password-creation.component';
import { PasswordChangeModalComponent } from 'login/password-change-modal/password-change-modal.component';
import { UserSetPasswordService } from 'login/user-set-password.service';
import { ForgotModalComponent } from 'login/forgot-modal/forgot-modal.component';
import { InvalidTokenComponent } from 'login/invalid-token/invalid-token.component';
import { ForgotPageComponent } from 'login/forgot-page/forgot-page.component';
import { SetupPasswordComponent } from 'login/setup-password/setup-password.component';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        CommonModule,
    ],
    declarations: [
        RegistrationComponent,
        RegistrationPasswordCreationComponent,
        PasswordChangeModalComponent,
        ForgotModalComponent,
        InvalidTokenComponent,
        ForgotPageComponent,
        SetupPasswordComponent,
    ],
    providers: [
        RegistrationService,
        UserSetPasswordService,
    ],
})
export class LoginModule {}
