<div class="rows-bordered" *ngIf="channelApplicationDetail">
    <div class="row-fluid header">
        <div class="text-big col-md-6">New Applications
            <div class="pull-right">
                <div tooltip="Manage Notifications" placement="top end">
                    <span class="glyphicon glyphicon-bell" (click)="openNewApplicationsModal()"></span>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div>
        <div
            [id]="newApplicationsTopRowId"
            class="row-fluid"
        >
            <div class="col-md-6 text-left">
                <form [formGroup]="newApplicationQuestionnaireForm" (onValidSubmit)="saveNewApplicationQuestionnaireSelection()">
                    <div has-error class="row">
                        <label class="col-sm-6 control-label">New Questionnaire</label>
                        <div class="col-sm-6">
                            <div class="row">
                                <div [isEditable]="newApplicationQuestionnaireEditing" class="solid-word flex-row space-between align-top">
                                    <span view class="col-sm-10 control-value">
                                        {{ newApplicationQuestionnaireForm.controls.newQuestionnaire.value?.name || '&ndash;' }}
                                    </span>
                                    <div *ngIf="canManageChannelSettings" view class="text-right va-m button-holder">
                                        <a class="btn btn-icon btn-tertiary" (click)="startEditingNewApplicationQuestionnaire()">
                                            <span class="glyphicon glyphicon-pencil"></span>
                                        </a>
                                    </div>

                                    <ng-select
                                            edit
                                            name="newQuestionnaire"
                                            formControlName="newQuestionnaire"
                                            [items]="questionnaires"
                                            bindLabel="name"
                                            class="col-sm-8 questionnaire-dropdown"
                                            tabindex="9"
                                    >
                                    </ng-select>
                                    <div edit class="col-sm-4 text-right va-m button-holder questionnaire-edit-controls">
                                        <button type="submit" class="btn btn-icon btn-tertiary">
                                            <span class="glyphicon glyphicon-ok"></span>
                                        </button>
                                        <a (click)="cancelEditingNewApplicationQuestionnaire()" class="btn btn-icon btn-tertiary">
                                            <span class="glyphicon glyphicon-remove"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6 text-left">
                <div class="row right-colum-alignment">
                    <label class="col-sm-6 control-label">Invite Expires</label>
                    <div
                        [popover-edit]
                        fieldName="inviteExpirationDays"
                        [value]="channelApplicationDetail?.channelSettings?.inviteExpirationDays"
                        [externalContainerElementId]="newApplicationsTopRowId"
                        [maxlength]="6"
                        [validators]="invitationExpirationSettingsValidators"
                        [readOnly]="!canManageChannelSettings"
                        (saveTriggered)="saveChannelSettingsWithPseudoPatch($event)"
                        [viewControlClasses]="'flex-row right'"

                        class="col-sm-6"
                    >
                        {{ channelApplicationDetail?.channelSettings?.inviteExpirationDays + ' Days' || '&ndash;' }}
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div
            [id]="newApplicationsMiddleRowId"
            class="row-fluid"
        >
            <div class="col-md-6 text-left">
                <div class="row">
                    <label class="col-sm-6 control-label">App Expires</label>
                    <div
                        [popover-edit]
                        fieldName="expirationNewDays"
                        [value]="channelApplicationDetail?.applicationExpirationSettings?.expirationNewDays"
                        [externalContainerElementId]="newApplicationsMiddleRowId"
                        [maxlength]="3"
                        [validators]="applicationExpirationSettingsValidators"
                        [readOnly]="!canManageChannelSettings"
                        (saveTriggered)="saveChannelApplicationExpirationsWithPseudoPatch($event)"
                        [viewControlClasses]="'flex-row right'"
                        class="col-sm-6"
                    >
                        {{ channelApplicationDetail?.applicationExpirationSettings?.expirationNewDays + ' Days'|| '&ndash;' }}
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-left">
                <div class="row right-colum-alignment">
                    <label class="col-sm-6 control-label">Lender Review Expires</label>
                    <div
                        [popover-edit]
                        fieldName="expirationNewLenderReviewDays"
                        [value]="channelApplicationDetail?.applicationExpirationSettings?.expirationNewLenderReviewDays"
                        [externalContainerElementId]="newApplicationsMiddleRowId"
                        [maxlength]="3"
                        [validators]="applicationExpirationSettingsValidators"
                        [readOnly]="!canManageChannelSettings"
                        (saveTriggered)="saveChannelApplicationExpirationsWithPseudoPatch($event)"
                        [viewControlClasses]="'flex-row right'"
                        class="col-sm-6"
                    >
                        {{ channelApplicationDetail?.applicationExpirationSettings?.expirationNewLenderReviewDays + ' Days'|| '&ndash;' }}
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div
            [id]="newApplicationsBottomRowId"
            class="row-fluid bottom-row-absolute-hack"
        >
            <div class="col-md-6 text-left">
                <div
                    class="row"
                >
                    <label class="col-sm-6 control-label">Screen Applicant</label>
                    <div class="col-sm-6">
                        <div class="col-sm-5 text-left toggle-container-left-alignment">
                            <toggle
                                [ngModel]="channelApplicationDetail?.channelSettings?.screenTpo"
                                [disabled]="!canManageChannelSettings"
                                [loading]="toggleLoading"
                                (ngModelChange)="toggleScreenApplicant(channelApplicationDetail, true)"
                            ></toggle>
                        </div>
                        <div class="col text-right" *ngIf="channelApplicationDetail?.channelSettings?.screenTpo">
                            <a class="text-smaller" (click)="toggleScreenApplicant(channelApplicationDetail, false)">Screening Info</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-left">
                <div class="row right-colum-alignment">
                    <label class="col-sm-6 control-label">Charge Applicant</label>
                    <div class="col-sm-6">
                        <toggle
                            [ngModel]="channelApplicationDetail?.channelSettings?.tpoPaysForApplication"
                            [disabled]="!canManageChannelSettings"
                            (ngModelChange)="toggleChargeApplicant()"
                        ></toggle>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row-fluid header">
        <div class="text-big col-md-6">Renewal Applications
            <div class="pull-right">
                <span tooltip="Manage Notifications" placement="top end" class="glyphicon glyphicon-bell" (click)="openRenewalApplicationsModal()"></span>
            </div>
        </div>
    </div>
    <br />
    <div>
        <div
            [id]="renewalApplicationsTopRowId"
            class="row-fluid"
        >
            <div class="col-md-6 text-left">
                <form [formGroup]="renewalApplicationQuestionnaireForm" (onValidSubmit)="saveRenewalApplicationQuestionnaireSelection()">
                    <div has-error class="row">
                        <label class="col-sm-6 control-label">New Questionnaire</label>
                        <div class="col-sm-6">
                            <div [isEditable]="renewalApplicationQuestionnaireEditing" class="row">
                                <span view class="control-value col-sm-10">
                                    {{ renewalApplicationQuestionnaireForm.controls.renewalQuestionnaire.value?.name || '&ndash;' }}
                                </span>
                                <div *ngIf="canManageChannelSettings" view class="text-right va-m button-holder flex-row right">
                                    <a class="btn btn-icon btn-tertiary" (click)="startEditingRenewalApplicationQuestionnaire()">
                                        <span class="glyphicon glyphicon-pencil"></span>
                                    </a>
                                </div>

                                <ng-select
                                    edit
                                    name="renewalQuestionnaire"
                                    formControlName="renewalQuestionnaire"
                                    [items]="questionnaires"
                                    bindLabel="name"
                                    class="col-sm-8 questionnaire-dropdown"
                                    tabindex="9"
                                >
                                </ng-select>
                                <div edit class="col-sm-4 text-right va-m button-holder questionnaire-edit-controls">
                                    <button type="submit" class="btn btn-icon btn-tertiary">
                                        <span class="glyphicon glyphicon-ok"></span>
                                    </button>
                                    <a (click)="cancelEditingRenewalApplicationQuestionnaire()" class="btn btn-icon btn-tertiary">
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6 text-left">
                <div class="row right-colum-alignment">
                    <label class="col-sm-6 control-label">Schedule</label>
                    <div
                        [popover-edit]
                        fieldName="renewalScheduleMonths"
                        [value]="channelApplicationDetail?.channelSettings?.renewalScheduleMonths"
                        [externalContainerElementId]="renewalApplicationsTopRowId"
                        [maxlength]="3"
                        [validators]="renewalMonthsValidators"
                        [readOnly]="!canManageChannelSettings || !channelApplicationDetail?.channelSettings?.autoRenewal"
                        (saveTriggered)="saveChannelSettingsWithPseudoPatch($event)"
                        class="col-sm-6"
                    >
                        {{ channelApplicationDetail?.channelSettings?.renewalScheduleMonths + ' Months'|| '&ndash;' }}
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div
            [id]="renewalApplicationsMiddleRowId"
            class="row-fluid"
        >
            <div class="col-md-6 text-left">
                <div class="row">
                    <label class="col-sm-6 control-label">Renewal Expires</label>
                    <div
                        [popover-edit]
                        fieldName="expirationRenewalDays"
                        [value]="channelApplicationDetail?.applicationExpirationSettings?.expirationRenewalDays"
                        [externalContainerElementId]="renewalApplicationsMiddleRowId"
                        [maxlength]="3"
                        [validators]="applicationExpirationSettingsValidators"
                        [readOnly]="!canManageChannelSettings"
                        (saveTriggered)="saveChannelApplicationExpirationsWithPseudoPatch($event)"
                        [viewControlClasses]="'flex-row right'"
                        class="col-sm-6"
                    >
                        {{ channelApplicationDetail?.applicationExpirationSettings?.expirationRenewalDays + ' Days'|| '&ndash;' }}
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-left">
                <div class="row right-colum-alignment">
                    <label class="col-sm-6 control-label">Lender Review Expires</label>
                    <div
                        [popover-edit]
                        fieldName="expirationRenewalLenderReviewDays"
                        [value]="channelApplicationDetail?.applicationExpirationSettings?.expirationRenewalLenderReviewDays"
                        [externalContainerElementId]="renewalApplicationsMiddleRowId"
                        [maxlength]="3"
                        [validators]="applicationExpirationSettingsValidators"
                        [readOnly]="!canManageChannelSettings"
                        (saveTriggered)="saveChannelApplicationExpirationsWithPseudoPatch($event)"
                        [viewControlClasses]="'flex-row right'"
                        class="col-sm-6"
                    >
                        {{ channelApplicationDetail?.applicationExpirationSettings?.expirationRenewalLenderReviewDays + ' Days'|| '&ndash;' }}
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div
            [id]="renewalApplicationsBottomRowId"
            class="row-fluid"
        >
            <div class="col-md-6 text-left">
                <div class="row">
                    <label class="col-sm-6 control-label">Charge Applicant</label>
                    <div class="col-sm-6">
                        <div class="row">
                            <toggle
                                #renewalChargeApplicantToggle
                                [ngModel]="channelApplicationDetail?.channelSettings?.tpoPaysForRenewal"
                                [disabled]="!canManageChannelSettings"
                                (ngModelChange)="toggleRenewalChargeApplication()"
                                class="col-sm-4"
                            ></toggle>
                            <div
                                id="renewalChargeApplicantPopover"
                                #renewalChargeApplicantPopover
                                [popover-edit]
                                fieldName="renewalAmount"
                                [value]="channelApplicationDetail?.channelSettings?.renewalAmount"
                                [externalContainerElementId]="renewalApplicationsBottomRowId"
                                [maxlength]="6"
                                [validators]="moneyValidators"
                                [displayValue]="channelApplicationDetail?.channelSettings?.tpoPaysForRenewal"
                                [readOnly]="!canManageChannelSettings || !channelApplicationDetail?.channelSettings?.tpoPaysForRenewal"
                                [popoverContentClasses]="'col-sm-9'"
                                (editChanged)="renewalChargeApplicantValueEditChanged($event)"
                                (saveTriggered)="renewalChargeApplicantValueChanged($event)"
                                class="col-sm-8 text-left"
                            >
                                ${{(channelApplicationDetail?.channelSettings?.renewalAmount | number) || ''}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 text-left">
                <div class="row right-colum-alignment">
                    <label class="col-sm-6 control-label">Auto-renewal</label>
                    <div class="col-sm-6">
                        <toggle
                            [ngModel]="channelApplicationDetail?.channelSettings?.autoRenewal"
                            [disabled]="!canManageChannelSettings"
                            (ngModelChange)="toggleAutoRenewalApplication()"
                        ></toggle>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
