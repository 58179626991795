import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { LicenseDetailsModalComponent } from 'shared/account/licenses/details-modal';
import { SharedLicenseListComponent } from 'shared/account/licenses/list';
import { SharedLicensesService } from 'shared/account/licenses/licenses.service';

@NgModule({
	imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        DirectivesModule,
	],
	declarations: [
		LicenseDetailsModalComponent,
        SharedLicenseListComponent,
	],
	exports: [
        LicenseDetailsModalComponent,
        SharedLicenseListComponent,
	],
    providers: [
        SharedLicensesService,
    ],
})
export class SharedLicensesModule {}
