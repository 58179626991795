import { Injectable } from '@angular/core';

import moment from 'moment';


@Injectable()
export class DateFormatterService {
    formatDate(date: any, format: string): string {
        return moment(date).format(format);
    }
}