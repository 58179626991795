<div class="remote-resource" [class.resolved]="!resolving">
    <div *ngIf="crmSettings">
        <h2 class="title-label clearfix">
            <div>
                <span class="pull-left">
                    Investor Marketing
                    <ng-container [ngSwitch]="crmSettings.active">
                        <span *ngSwitchCase="true" class="label label-success text-bigger">Active</span>
                        <span *ngSwitchDefault class="label label-danger text-bigger">Inactive</span>
                    </ng-container>
                </span>
                <span class="pull-right" [class.curtain]="(tpoStatusCode == 'INACTIVE')">
                    <a class="text-medium" uiSref="customers.:customerType.:organizationId.services.crm.history">History</a>
                    <span *ngIf="!isNew && crmSettings.created && user.can('CCM_MANAGE_CUSTOMERS')">
                        &nbsp;
                        <span *ngIf="!crmSettings.active">
                            <button class="btn btn-success-wired" (click)="setInvestorMarketingServiceStatus(crmSettings.active)">Activate</button>
                        </span>
                        <span *ngIf="crmSettings.active">
                            <button class="btn btn-danger-wired" (click)="setInvestorMarketingServiceStatus(crmSettings.active)">Deactivate</button>
                        </span>
                    </span>
                    <span *ngIf="!crmSettings.created && !isNew && user.can('CCM_MANAGE_CUSTOMERS')">
                        &nbsp;
                        <button class="btn btn-primary" uiSref="customers.:customerType.:organizationId.services.crm.new">Add Service</button>
                    </span>
                </span>
            </div>
        </h2>

        <br>

        <div *ngIf="tpoStatusCode == 'INACTIVE'" class="alert alert-warning text-center row-fluid">
            <a class="underlined text-warning" uiSref="^.tpo">Third Party Oversight</a> Service required
        </div>
        <div *ngIf="activationStatusMessage" class="row-fluid" [ngSwitch]="activationStatus">
            <div *ngSwitchDefault class="alert alert-warning text-center">{{activationStatusMessage}}</div>
            <div *ngSwitchCase="true" class="alert alert-success text-center">
                <span class="glyphicon glyphicon-ok-sign"> </span>
                {{activationStatusMessage}}
            </div>
        </div>

        <div [class.curtain]="(tpoStatusCode != 'ACTIVE')">
            <div *ngIf="crmSettings.created || isNew">
                <investor-marketing-visibility-form [crmSettings]="crmSettings" [tpoStatusCode]="tpoStatusCode"></investor-marketing-visibility-form>
            </div>
        </div>
    </div>
</div>

