import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

@Component({
	templateUrl: './template-update-modal.component.html',
})

export class TemplateUpdateModalComponent {
	title: string;
	onConfirm: (statusId) => any;
	onCancel: () => any;
	resolving: boolean;
	templateForm: RealmFormGroup = new RealmFormGroup({
		action: new RealmFormControl(
			'action',
			{
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: 'Save Template',
			confirmText: 'Save',
			cancelText: 'Cancel',
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: false,
		});
	}
}
