<div class="account-disconnected-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
	</div>
	<div class="modal-body">
		<div class="text-center">
			<sm-icon [config]="{
				 	networkCode: icon.networkCode,
				 	profilePictureUrl: icon.reviewerAvatarUrl,
				 	title: icon.title
				 }">
			</sm-icon>
			<h3>{{title}}</h3>
			<p class="text-muted">{{content}}</p>
			<a class="btn btn-primary"
			   (click)="onCancel()"
			   uiSref="people.contacts.:contactId.social-media"
			   [uiParams]="{ contactId: contactId }">{{btnText}}</a>
		</div>
	</div>
</div>
