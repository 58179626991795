import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class UserActionsService {
	resource: any;

	constructor(public injector: Injector) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const basePath = `${apiPath}/users/:userId/:action`;

		this.resource = PagedResource(`${basePath}`, {}, {
			actionLog: {method: 'get', isArray: true},
		});
	}

	actionLog = (...args) => this.resource.actionLog(...args);
}
