import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

import { GroupService } from './group.service';

@Component({
	templateUrl: './group.component.html',
})
export class GroupComponent {

	group: any = {};

	constructor(
		public stateService: StateService,
		public groupService: GroupService,
	) {
		const { groupId } = stateService.params;
		this.group = groupService.get(groupId);
	}
}
