<div class="edit-widget">
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
			<span>{{isNew ? 'Create' : 'Edit'}} Widget</span>
		</back-bread>
	</div>

	<section class="remote-resource" [class.resolved]="(widget.$resolved || isNew) && !saving">
		<form [formGroup]="editWidgetForm" name="activityForm" id="activityForm" (onValidSubmit)="save()">
			<h3 class="flex-row">
				Settings
				<span class="right">
					<button type="submit" [disabled]="!selectedAmount" class="btn btn-primary" tabindex="20">
						Save ({{selectedAmount}})
					</button>
					<button class="btn btn-default" uiSref="^" tabindex="20">Cancel</button>
				</span>
			</h3>
			<form-errors [form]="editWidgetForm"></form-errors>

			<div class="rows-striped">
				<div class="row-fluid">
					<div class="col-sm-6">
						<div class="form-group row" has-error>
							<label for="widget-name" class="col-sm-3 control-label solid required">
								{{editWidgetForm.getFieldLabel('widgetName')}}
							</label>
							<div class="col-sm-9">
								<input id="widget-name" class="form-control" formControlName="widgetName"
								   (change)="widget.name = $event.target.value" tabindex=1 required maxlength="128">
							</div>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="form-group row" has-error>
							<label class="col-sm-3 control-label solid required">
								{{editWidgetForm.getFieldLabel('showLast')}}
							</label>
							<div class="col-sm-9">
								<ng-select
									(change)="widget.showLast = $event"
									[items]="[9, 15, 30]"
									formControlName="showLast"
									[clearable]="false"
									tabindex=3
									required
								>
									<ng-template ng-label-tmp ng-option-tmp let-item="item">
										{{item}} reviews
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</section>
	<section id="sources" class="remote-resource" [class.resolved]="sources.$resolved && !saving">
		<h3>Sources</h3>

		<div class="filters-rows">
			<div class="row-fluid filter-labels">
				<div class="col-sm-2">
					<div class="text-muted">Entity</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Search</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Account type</div>
				</div>
				<div class="col-sm-2"></div>
				<div class="col-sm-2"></div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="col-sm-2">
					<ng-select
						[(ngModel)]="params.entityTypes"
						(change)="setFilter({ entityTypes: $event })"
						[items]="filters.entityTypes"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All Entities">
					</ng-select>
				</div>
				<div class="col-sm-2">
					<search-list placeholder="Name, NMLS"></search-list>
				</div>
				<div class="col-sm-2">
					<ng-select
						[(ngModel)]="params.accountTypes"
						(change)="setFilter({ accountTypes: $event })"
						[items]="filters.accountTypes.$promise | async"
						bindValue="id"
						bindLabel="displayName"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All Types">
					</ng-select>
				</div>
				<div class="col-sm-2"></div>
				<div class="col-sm-2"></div>
			</div>
		</div>
		<div class="rows-bordered">
			<div class="row-fluid header">
				<div class="btn-group col-f-40-custom" dropdown>
					<div dropdownToggle
						 type="button"
						 class="dropdown-toggle text-center sortable"
						 aria-controls="dropdown-basic">
						<input class="va-m" type="checkbox" [(ngModel)]="selectedAll" (click)="false">
						<span class="caret"></span>
					</div>
					<ul *dropdownMenu class="dropdown-menu"
						role="menu" aria-labelledby="button-basic">
						<li role="menuitem"><a (click)="addAllSources()" class="dropdown-item">Select All</a></li>
						<li role="menuitem">
							<a (click)="addSourceArray(sources); this.selectedAll = true;" class="dropdown-item">
								Select All Visible
							</a>
						</li>
						<li role="menuitem"><a (click)="resetSources()" class="dropdown-item">Clear</a></li>
					</ul>
				</div>
				<sortable-header class="col-sm-a" columnName="account">Account</sortable-header>
				<sortable-header class="col-sm-3" columnName="source">Source</sortable-header>
				<sortable-header class="col-sm-2" columnName="nmlsId">NMLS ID</sortable-header>
			</div>

			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<span class="col-sm-12 text-center va-m">Filters are applied.
					<a (click)="resetFilters()">Reset filters</a> to see all results
				</span>
			</div>

			<div *ngFor="let source of sources" class="row-fluid clickable" (click)="handleCheck($event, inputElement)">
				<div class="col-f-40-custom text-center va-m">
					<input #inputElement
						type="checkbox"
						[(ngModel)]="selectedSources[source.compositeId]"
						(ngModelChange)="onInputChange($event, source)"
					>
				</div>
				<div class="col-sm-a">
					<div class="flex-row gap">
						<sm-icon
							[config]="{
								networkCode: source?.networkCode || source?.providerCode || 'ER',
								profilePictureUrl: source?.profilePictureUrl,
								title: (source?.providerCode && source?.providerName) ? source?.providerName : (!source?.networkCode && source.name)
							}"
						></sm-icon>
						<div class="flex text-overflow">
							<ng-container *ngIf="source?.linkText; then linkAnchor; else linkName"></ng-container>
							<ng-template #linkAnchor>
								<a [href]="source?.linkText | hrefReady" target="_blank">{{source.name}}</a>
							</ng-template>
							<ng-template #linkName>{{source.name}}</ng-template>
						</div>
					</div>
				</div>
				<div class="col-sm-3 va-m">
					<span [ngSwitch]="source.networkCode">
						<ng-container *ngSwitchCase="'FB'">Facebook</ng-container>
						<ng-container *ngSwitchCase="'GMB'">Google</ng-container>
						<ng-container *ngSwitchCase="'YLP'">Yelp</ng-container>
						<ng-container *ngSwitchDefault>{{source.providerName}}</ng-container>
					</span>
				</div>
				<div class="col-sm-2 va-m">
					<span>{{source.individualNMLSId || source.companyNMLSId || '&ndash;'}}</span>
				</div>
			</div>

			<div class="row-fluid" *ngIf="sources.$resolved && !sources.length">
				<div class="col-sm-12 text-center text-light text-bigger">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No reviews'}}
				</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="sources" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</section>
</div>
