<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">Change Finding Status</h4>
</div>

<form [formGroup]="updateStatusForm" (onValidSubmit)="submitStatusForm()" *ngIf="updateStatusForm.enabled">
	<div class="remote-resource" [class.resolved]="!resolving">
		<div class="modal-body">

			<form-errors [form]="updateStatusForm"></form-errors>

			<p>Please select Status to set for this Finding</p>
			<div class="row form-group">
				<div class="col-sm-7">
					<ng-select
						formControlName="status"
						[loading]="!statuses.$resolved"
						[items]="statuses.$promise | async"
						bindLabel="status"
						[clearable]="false"
					></ng-select>
				</div>
			</div>
		</div>
		<div [class.curtain]="resolving">
			<div class="modal-footer">
				<div class="pull-left">
					<button type="button" class="btn btn-secondary" (click)="enableCreateStatusForm()">Create New Status</button>
				</div>
				<div class="pull-right">
					<button type="submit" class="btn btn-primary">Save</button>
					<a class="btn btn-secondary" (click)="modalRef.hide()">Cancel</a>
				</div>
			</div>
		</div>
	</div>
</form>

<form [formGroup]="createStatusForm" (onValidSubmit)="submitStatusForm()" *ngIf="createStatusForm.enabled">
	<div class="remote-resource" [class.resolved]="!resolving">
		<div class="modal-body">

			<form-errors [form]="createStatusForm"></form-errors>

			<p>Create the name of your status.</p>
			<p class="text-danger">Please note, once a status has been created, it can not be removed.</p>
			<div class="row form-group">
				<label for="status" class="col-sm-4 control-label required">
					{{createStatusForm.getFieldLabel('status')}}
				</label>
				<div class="col-sm-8">
					<input type="text" class="form-control" id="status" formControlName="status" maxlength="200">
				</div>
			</div>
			<div class="row form-group">
				<label class="col-sm-4 control-label">
					{{createStatusForm.getFieldLabel('correspondingCondition')}}
				</label>
				<div class="col-sm-8">
					<label class="control-label radio-inline">
						<input type="radio" formControlName="correspondingCondition" value="Closed">
						Closed
					</label>
					<label class="control-label radio-inline">
						<input type="radio" formControlName="correspondingCondition" value="In Progress">
						In Progress
					</label>
				</div>
			</div>
		</div>
	</div>
	<div [class.curtain]="resolving">
		<div class="modal-footer">
			<div class="pull-left">
				<button type="button" class="btn btn-secondary" (click)="enableUpdateStatusForm()">Back</button>
			</div>
			<div class="pull-right">
				<button type="submit" class="btn btn-primary">Save</button>
				<a class="btn btn-secondary" (click)="modalRef.hide()">Cancel</a>
			</div>
		</div>
	</div>
</form>
