<div class="folder-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<form-errors [form]="folderForm"></form-errors>
		<form [formGroup]="folderForm" id="folderForm" (onValidSubmit)="submit()">
			<div class="form-group row flex-row" [class.has-error]="hasError('folderName')">
				<label for="folderName" class="col-md-2 solid text-normal control-label">
					{{this.folderForm.getFieldLabel('folderName')}}
				</label>
				<div class="col-md-10">
					<input id="folderName" class="form-control" formControlName="folderName" placeholder="Enter Name"
						   tabindex="1" required>
				</div>
			</div>
			<div class="form-group row flex-row" [class.has-error]="hasError('assetSharing')">
				<label class="col-md-2 text-normal control-label">
					{{ this.folderForm.getFieldLabel('assetSharing')}}
				</label>
				<div class="col-md-10">
					<campaign-share-to-edit
							[value]="folderForm?.value?.assetSharing"
							(change)="folderForm.patchValue({ assetSharing: $event })"
							(ready)="resolving = false"
					></campaign-share-to-edit>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button type="submit" form="folderForm" class="btn btn-primary">Save</button>
			<button (click)="onCancel()" type="button" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>
