import { from, Observable } from 'rxjs';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { ContactService } from 'shared/contact/contact.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { concatMap, map, take, takeUntil, tap } from 'rxjs/operators';
import { FullTpoContact } from '../contacts.interface';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { ContactLists } from 'shared/contact/contact-lists.interface';


export interface ContactListsListParams extends ListParams {
    organizationContactId: number;
}

@Component({
    viewProviders: [
        { provide: NewPagedListComponent, useExisting: forwardRef(() => NewContactListsComponent) },
        { provide: ListComponent, useExisting: forwardRef(() => NewContactListsComponent) },
    ],
	templateUrl: './new-contact-lists.component.html',
})
export class NewContactListsComponent extends NewPagedListComponent<ContactLists> {
	static listName = 'newContactLists';

    columnClass: Record<string, string> = {
        id: 'col-sm-1',
        listName: 'col-sm-3',
        contacts: 'col-sm-2',
        createdDate: 'col-sm-2',
    };

	contact: FullTpoContact;
	canAdd: boolean = false;
	canRemove: boolean = false;
	add: any = {
		show: false,
		loading: false,
		form: new RealmFormGroup({
			listIds: new RealmFormControl(
				'listIds',
				{
					label: 'Lists',
				},
				[
					Validators.required,
				],
			),
		}),
	};
	modalRef: BsModalRef;

	constructor(
        uiRouter: UIRouter,
        public investorDetails: InvestorContactDetailsComponent,
		public user: UserService,
		public contactService: ContactService,
		public modalService: BsModalService,
        private cd: ChangeDetectorRef,
	) {
		super(uiRouter);

		this.canAdd = user.profile.can('ADD_CONTACT_TO_LIST');
		this.canRemove = user.profile.can('DELETE_CONTACT_FROM_LIST');

        this.investorDetails.contact.pipe(
            takeUntil(this.destroy$),
        ).subscribe((contact: FullTpoContact) => {
            const { organizationContactId } = contact;
            this.contact = contact;

            this.add.toggle = function() {
                this.show = !this.show;
                if (this.show) {
                    this.availableLists = contactService.lists.get({
                        organizationContactId,
                        containedInList: 'false',
                    });
                    this.availableLists$ = from(this.availableLists.$promise);
                } else {
                    this.form.reset();
                }
            };

            const that = this;
            this.add.submit = function() {
                this.loading = true;
                contactService.lists.add(
                    {
                        organizationContactId,
                    },
                    {
                        listIds: this.form.get('listIds').value,
                    },
                    () => {
                        that.updateList();
                        this.toggle();
                    },
                    ({ data }) => {
                        this.form.setServerError(data);
                    },
                ).$promise
                    .finally(() => {
                        this.loading = false;
                    }
                );
            };

            this.cd.markForCheck();
        });
	}

    // Override
    loadList(listParams: ContactListsListParams): Observable<PagedData<ContactLists[]>> {
        return this.investorDetails.contact
            .pipe(
                take(1),
                concatMap((contact: FullTpoContact) => {
                    const organizationId: number = this.user.profile.organization.organizationId;
                    const organizationContactId: number = contact.organizationContactId;

                    return this.contactService.getContactLists(
                        organizationId,
                        organizationContactId,
                        true,
                        listParams
                    );
                })
            );
	}

	remove({ id: listId, name: listName }) {
		const { organizationContactId } = this.contact;

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				message: `Please confirm removing from <b>${listName}</b>.`,
				confirmText: 'Remove',
				cancelText: 'Cancel',
				onConfirm: () => {
					this.modalRef.content.resolving = true;
					this.contactService.lists.remove(
						{
							organizationContactId,
							listId,
						},
					).$promise
						.finally(() => {
							this.updateList();
							this.modalRef.hide();
						});
				},
			},
		});
	}
}
