import { Component, forwardRef, Input } from '@angular/core';
import { ContactHint, RelatedProspectContact } from 'lender/prospects/prospects.interface';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { UIRouter } from '@uirouter/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { Observable, Subject } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MarketLocation } from 'lender/crm/market/market.interface';
import { DropdownRequiredValidator } from 'commons/validators';
import { ProspectsService } from 'lender/prospects/prospects.service';

@Component({
    selector: 'prospect-related-contacts',
    templateUrl: './related-contacts.components.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ProspectRelatedContactsComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ProspectRelatedContactsComponent) },
    ],
})
export class ProspectRelatedContactsComponent<T = RelatedProspectContact> extends NewPagedListComponent<T> {
    static listName = 'prospectContacts';

    @Input() public editable = false;
    linking = false;
    form = new RealmFormGroup({
        contactId: new RealmFormControl('contactId', { label: 'Contact' }, DropdownRequiredValidator),
    });
    User: UserProfile;
    protected destroyed$ = new Subject<void>();
    protected contactSearch$ = new Subject<string>();
    contactHintsLoading = false;
    contactHints: ContactHint[] = [];

    constructor(
        { profile }: UserService,
        router: UIRouter,
        private readonly prospectsService: ProspectsService,
    ) {
        super(router);
        this.User = profile;

        this.contactSearch$.pipe(
            distinctUntilChanged(),
            tap(q => {
                this.contactHintsLoading = q?.length > 2;
            }),
            debounceTime(500),
            switchMap(async (q) => {
                const valid = q?.length > 2;
                if (valid) {
                    try {
                        this.contactHints = await this.prospectsService.searchContact(this.prospectId, q).toPromise();
                        return;
                    } catch (err) {
                    }
                }
                this.contactHints = [];
            }),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.contactHintsLoading = false;
        });
    }

    get prospectId(): number {
        return this.router.globals.params.id;
    }

    loadList(params: ListParams): Observable<PagedData<T[]>> {
        return this.prospectsService.getContacts<T[]>(this.prospectId, params).pipe(
            tap(() => {
                this.cancelLink();
            }),
        );
    }

    async addContact(): Promise<void> {
        const { contactId } = this.form.value;
        this.resolving = true;
        try {
            await this.prospectsService.addContact(this.prospectId, contactId).toPromise();
            this.updateList();
        } catch (e) {
            this.resolving = false;
        }
    }

    cancelLink(): void {
        this.form.reset();
        this.linking = false;
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
