<ng-container *ngIf="finishedLoading">
    <div id="resolverLoading" class="remote-resource" [class.resolved]="!loadingResolves"></div>
    <info-message-holder></info-message-holder>
    <!--Browser outdated and cookie enabled code: END-->
    <div id="content-holder" class="main-content-holder" [ngClass]="className" [class.out]="!finishedLoading">
        <global-navbar *ngIf="state !== 'login'" [class.ng-hide]="isFullPageView"></global-navbar>
        <global-logo class="global-logo" *ngIf="state === 'login'"></global-logo>
        <div class="container main-wrapper" ui-view></div>
        <global-footer [class.ng-hide]="isFullPageView"></global-footer>
    </div>
</ng-container>
