import { Component, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { MARKET_LIST_NAMES, MarketSrefParams, MarketTab } from './market.interface';
import { MarketDataService } from './market-data.service';
import { CompaniesListComponent } from './companies-list';
import { MarketMapComponent } from 'lender/crm/market/map/map.component';

@Component({
	templateUrl: './market.component.html',
})
export class MarketComponent implements OnDestroy {
	protected destroyed$: Subject<void> = new Subject();

	title = 'Market';
	hasSrefParams: boolean;
	tabs: MarketTab[];
	mapSrefParams: MarketSrefParams;

	constructor(
		private readonly dataService: MarketDataService,
		private readonly stateService: StateService,
	) {
		this.dataService.getSrefParams$().pipe(
			skip(1),
			takeUntil(this.destroyed$),
		).subscribe((srefParams) => {
			this.hasSrefParams = !!srefParams;
			if (!this.hasSrefParams) { return; }
			const { listParams, ...params } = srefParams || {};

			this.tabs = [
				{
					sref: 'crm.market.companies',
					title: 'Companies',
					params: {
						...this.getTargetListParams(CompaniesListComponent.listName, listParams),
						...params,
					},
				},
				{
					sref: 'crm.market.msa',
					title: 'MSA Details',
					params,
				},
			];

			this.mapSrefParams = {
				...this.getTargetListParams(MarketMapComponent.listName, listParams),
				...params,
			};
		});
	}

	getTargetListParams(listName: typeof MARKET_LIST_NAMES[number], listParams) {
		return listParams ? { [listName]: listParams } : {}
	}

	getCurrentTab(tab): boolean {
		return this.stateService.current?.name.indexOf(tab.sref) > -1;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
