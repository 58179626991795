import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { CodeValueService } from 'shared/code-value.service';
import { RolesResourceService } from 'shared/roles/roles-resource.service';
import { UsersResourceService } from 'shared/users/users-resource.service';
import { RolesModule } from 'shared/roles/roles.module';

import { SocialAccountsModule } from 'tpo/social-accounts';

import { ContactsResourceService } from './contacts-resource.service';
import { OriginatorsResourceService } from './originators-resource.service';
import { OwnershipModalComponent } from './ownership-modal.component';
import { OwnersResourceService } from './owners-resource.service';
import { TpoAccountResourceService } from './tpo-account-resource.service';

import { LabelTemplateDirective } from './merge/contact-merge-label-template.directive';

import { PublisherRequestsComponent } from './publisher-requests/publisher-requests.component';

const components = [
	OwnershipModalComponent,
	PublisherRequestsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		RolesModule,
		SocialAccountsModule,
	],
	declarations: [
		...components,
		LabelTemplateDirective,
	],
	providers: [
		ContactsResourceService,
		OriginatorsResourceService,
		OwnersResourceService,
		TpoAccountResourceService,
		CodeValueService,
		UsersResourceService,
		RolesResourceService,
	],
})
export class TPOContactsModule {
}
