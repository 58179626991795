import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './post-history-modal.component.html',
})
export class PostHistoryModalComponent implements OnInit {
	resource: any;
	list: any = [];
	resolved = true;

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit() {
		this.resolved = false;
		this.resource.$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.resolved = true;
			});
	}
}
