import { findIndex } from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { linkTemplate } from 'shared/supporting-documents/helpers';

import { UserService } from 'angularjs-providers/user.provider';

import {
	NgPagedResource,
	NgResource,
	NgResourceArray,
	NgResourceObject,
	NgRequestGeneralError,
} from 'commons/interfaces';

import {
	QuestionnaireItem,
	QuestionnaireUsage,
	Question,
	QuestionnaireDocument,
	QuestionnaireDocumentAvailable,
	QuestionType,
	AnswerOption,
	QuestionBankCategory,
	BankQuestionInUseStats,
	QuestionBankCategoryWithCount,
	QuestionnaireStatus,
	FinancialRequestValue,
	FinancialRequests,
	FinancialDocumentType,
} from './questionnaires.interface';

declare let apiPath: string;

@Injectable()
export class LenderManageQuestionnaireService {
	questionnairesResource: any;
	questionnaireResource: any;
	documentsResource: any;
	questionBankResource: any;
	private readonly organizationId: number;

	constructor(
		injector: Injector,
		protected user: UserService,
		private http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.organizationId = user.profile.organization.organizationId;

		const questionnaireApiV2 = `${apiPath}/v2/organizations/:organizationId/questionnaires/:questionnaireId`;
		const defaultParams = { organizationId: this.organizationId };

		const questionnairesConfig = {
			url: `${apiPath}/organizations/:organizationId/questionnaires`,
			params: {
				...defaultParams,
			},
		};

		// https://r5-dev.ccmdev.com/swagger-ui.html#/organization45questionnaire45resource
		this.questionnairesResource = PagedResource(
			questionnairesConfig.url,
			questionnairesConfig.params,
			{
				list: {
					method: 'get',
					isArray: true,
				},
				create: {
					method: 'post',
				},
			},
		);

		const transformQuestions = (data) => {
			data = JSON.parse(data);

			if (!Array.isArray(data)) {
				return data;
			}

			function iterateAnswer(answer: AnswerOption) {
				if (answer.nextQuestionId === null || answer.nextQuestionId === undefined) {
					answer._skipLogic = false;
					answer._nextQuestion = null;
				} else {
					const sIndex = findIndex(data, { id: answer.nextQuestionId });

					answer._skipLogic = true;
					answer._nextQuestion = {
						index: sIndex,
						id: answer.nextQuestionId,
						title: sIndex >= 0 ? `${data[sIndex]._number}` : 'End',
					};
				}
			}

			let number = 0;
			// first we need to calculate _number
			const result = data.map((item) => {
				if (item.questionType !== QuestionType.GROUP) {
					item._number = ++number;
				}

				return item;
			});

			// then iterate answers and use _number as nextQeustion title
			result.forEach((item) => {
				if (item.answers && item.answers.length) {
					item.answers.forEach(iterateAnswer);
				}
			});

			return result;
		};

		const questionnaireConfig = {
			url: `${questionnairesConfig.url}/:questionnaireId`,
			params: {
				...defaultParams,
			},
		};

		this.questionnaireResource = $resource(
			questionnaireConfig.url,
			questionnaireConfig.params,
			{
				get: {
					method: 'get',
				},
				usage: {
					url: `${questionnaireConfig.url}/usage`,
					method: 'get',
				},
				getQuestions: {
					url: `${questionnaireApiV2}/questions`,
					method: 'get',
					isArray: true,
					transformResponse: transformQuestions,
				},
				updateQuestions: {
					url: `${questionnaireApiV2}/questions`,
					method: 'put',
					isArray: true,
					transformResponse: transformQuestions,
				},
				checkQuestionDeletable: {
					url: `${questionnaireApiV2}/questions/:questionId/check-deletable`,
					method: 'get',
				},
				deleteQuestion: {
					url: `${questionnaireApiV2}/questions/:questionId`,
					method: 'delete',
					isArray: true,
					transformResponse: transformQuestions,
				},
				moveQuestion: {
					url: `${questionnaireApiV2}/questions/:questionId/move`,
					method: 'put',
					isArray: true,
					transformResponse: transformQuestions,
				},
				update: {
					method: 'put',
				},
				clone: {
					url: `${questionnaireApiV2}/clone`,
					method: 'post',
				},
			},
		);

		const documentsConfig = {
			url: questionnaireApiV2,
			params: {
				...defaultParams,
			},
		};

		this.documentsResource = PagedResource(
			`${documentsConfig.url}/documents/:investorClientDocumentId`,
			documentsConfig.params,
			{
				availableDocuments: {
					url: `${documentsConfig.url}/available-documents`,
					method: 'get',
					isArray: true,
				},
				update: {
					method: 'put',
				},
			},
		);

		const questionBankConfig = {
			url: `${apiPath}/organizations/:organizationId/question-bank`,
			params: {
				...defaultParams,
			},
		};

		this.questionBankResource = $resource(
			`${questionBankConfig.url}/questions`,

			questionBankConfig.params,
			{
				categories: {
					url: `${questionBankConfig.url}/categories`,
					method: 'get',
					isArray: true,
				},
				addQuestions: {
					url: `${questionnaireApiV2}/questions/add-from-bank`,
					method: 'patch',
					isArray: true,
					params: { questionnaireId: '@questionnaireId' },
				},
				inUse: {
					url: `${questionBankConfig.url}/questions/:questionId/in-use`,
					method: 'get',
				},
				addQuestion: {
					url: `${questionBankConfig.url}/questions`,
					method: 'post',
					isArray: true,
				},
				updateQuestion: {
					url: `${questionBankConfig.url}/questions/:questionId`,
					method: 'put',
					isArray: true,
				},
				removeQuestion: {
					url: `${questionBankConfig.url}/questions/:questionId`,
					method: 'delete',
				},
				categoriesWithCount: {
					url: `${questionBankConfig.url}/categoriesWithCount`,
					method: 'get',
					isArray: true,
				},
				addCategory: {
					url: `${questionBankConfig.url}/categories`,
					method: 'post',
				},
				updateCategory: {
					url: `${questionBankConfig.url}/categories/:categoryId`,
					method: 'put',
				},
				removeCategory: {
					url: `${questionBankConfig.url}/categories/:categoryId`,
					method: 'delete',
				},
			},
		);
	}

	list = (...args): NgResourceArray<QuestionnaireItem> => this.questionnairesResource.list(...args);
	add = (...args): NgResourceObject<QuestionnaireItem> => this.questionnairesResource.create(...args);

	get = (...args): NgResourceObject<QuestionnaireItem> => this.questionnaireResource.get(...args);
	usage = (...args): NgResourceObject<QuestionnaireUsage> => this.questionnaireResource.usage(...args);
	questions = (...args): NgResourceArray<Question> => this.questionnaireResource.getQuestions(...args);
	updateQuestions = (...args): NgResourceArray<Question> => this.questionnaireResource.updateQuestions(...args);
	save = (...args): NgResourceObject<QuestionnaireItem> => this.questionnaireResource.update(...args);
	clone = (...args): NgResourceObject<QuestionnaireItem> => this.questionnaireResource.clone(...args);
	checkIsQuestionRemovable = (...args): NgResourceObject<QuestionnaireItem | NgRequestGeneralError> => this.questionnaireResource.checkQuestionDeletable(...args);
	deleteQuestion = (...args): NgResourceArray<Question> => this.questionnaireResource.deleteQuestion(...args);
	moveQuestion = (...args): NgResourceArray<Question> => this.questionnaireResource.moveQuestion(...args);

	documents = (...args): NgPagedResource<QuestionnaireDocument> => this.documentsResource.query(...args);
	availableDocuments = (...args): NgResourceArray<QuestionnaireDocumentAvailable> => this.documentsResource.availableDocuments(...args);
	updateDocuments = (...args): NgResourceArray<QuestionnaireDocumentAvailable> => this.documentsResource.update(...args);
	removeDocuments = (...args): NgResource => this.documentsResource.remove(...args);
	getDocumentTemplateLink = (documentId: number): string => {
		return linkTemplate(
			`${apiPath}/v2/lenders/:id/docmanagement/:documentId/template`,
			{ id: this.user.profile.organization.id, documentId }
		);
	};

	bankQuestions = (...args): NgResourceArray<Question> => this.questionBankResource.query(...args);
	bankCategories = (...args): NgResourceArray<QuestionBankCategory> => this.questionBankResource.categories(...args);
	addQuestionsFromBank = (...args): NgResourceArray<Question> => this.questionBankResource.addQuestions(...args);
	addBankQuestion = (...args): NgResourceArray<Question> => this.questionBankResource.addQuestion(...args);
	updateBankQuestion = (...args): NgResourceArray<Question> => this.questionBankResource.updateQuestion(...args);
	removeBankQuestion = (...args): NgResourceArray<Question> => this.questionBankResource.removeQuestion(...args);
	getInUseBankQuestion = (...args): NgResource<BankQuestionInUseStats> => this.questionBankResource.inUse(...args);
	bankCategoriesWithCount = (...args): NgResourceArray<QuestionBankCategoryWithCount> => this.questionBankResource.categoriesWithCount(...args);
	addBankCategory = (...args): NgResource<QuestionBankCategoryWithCount> => this.questionBankResource.addCategory(...args);
	updateBankCategory = (...args): NgResource<QuestionBankCategoryWithCount> => this.questionBankResource.updateCategory(...args);
	removeBankCategory = (...args): NgResource<QuestionBankCategoryWithCount> => this.questionBankResource.removeCategory(...args);

	toggleStatus(questionnaireId: number, status: QuestionnaireStatus['status']): Observable<QuestionnaireStatus> {
		return this.http.request<QuestionnaireStatus>(
			'PUT',
			`${apiPath}/organizations/${this.organizationId}/questionnaires/${questionnaireId}/status`,
			null,
			{ status }
		);
	}

	financialRequests(questionnaireId: number): Observable<FinancialRequests[]> {
		return this.http.request<FinancialRequests[]>(
			'GET',
			`${apiPath}/v2/organizations/${this.organizationId}/questionnaires/${questionnaireId}/financial-documents/settings`,
			null,
		);
	}

	financialRequestValues(questionnaireId: number): Observable<FinancialRequestValue[]> {
		return this.http.request<FinancialRequestValue[]>(
			'GET',
			`${apiPath}/v2/organizations/${this.organizationId}/questionnaires/${questionnaireId}/financial-documents/settings-options`,
			null,
		);
	}

	updateFinancialRequests(questionnaireId: number, requests: FinancialRequests[]): Observable<void> {
		return this.http.request<void>(
			'PUT',
			`${apiPath}/v2/organizations/${this.organizationId}/questionnaires/${questionnaireId}/financial-documents/settings`,
			null,
			requests
		);
	}

	removeFinancialDocuments(questionnaireId: number, financialDocumentType: FinancialDocumentType): Observable<void> {
		return this.http.request<void>(
			'DELETE',
			`${apiPath}/v2/organizations/${this.organizationId}/questionnaires/${questionnaireId}/financial-documents/${financialDocumentType}`,
		);
	}
}
