import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedData, RealmHttpClient } from 'commons/services/http';

import { AuditReview } from 'shared/social-compliance/audits/audits.service.interface';
import { FindingsItem, FindingsQueryParams } from 'shared/findings/$findingId/finding.interface';

import { ContactLists } from './contact-lists.interface';

declare let apiPath: string;

@Injectable({
	providedIn: 'root',
})
export class ContactService {
	contact: any;
	lists: any;
	compliance: {
		audits: any,
		findings: any,
	};

	constructor(
		injector: Injector,
		userService: UserService,
        private http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const User = userService.profile;

		const baseResourceProps = {
			url: `${apiPath}/tpos/:tpoId`,
			params: {
				...(User.isTpo && { tpoId: User.organization.id }),
			},
		};
		const contactResourceProps = {
			url: `${baseResourceProps.url}/contacts/:contactId`,
			params: {
				...baseResourceProps.params,
			},
		};
		const listsResourceProps = {
			url: `${apiPath}/organizations/:organizationId/contact-lists/contact/:organizationContactId`,
			params: {
				organizationId: User.organization.organizationId,
				organizationContactId: '@organizationContactId',
			},
		};

		this.contact = $resource(
			contactResourceProps.url,
			contactResourceProps.params,
			{
				get: {
					method: 'GET',
				},
			},
		);

		this.lists = PagedResource(
			listsResourceProps.url,
			listsResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				add: {
					method: 'POST',
					transformRequest(data) {
						return JSON.stringify(data.listIds);
					},
				},
				remove: {
					url: `${apiPath}/organizations/:organizationId/contact-lists/:listId/contact/:organizationContactId`,
					params: listsResourceProps.params,
					method: 'DELETE',
				},
			},
		);

		this.compliance = {
			audits: PagedResource(
				`${contactResourceProps.url}/social-media-compliance/reviews`,
				contactResourceProps.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			findings: PagedResource(
				`${baseResourceProps.url}/social-media-compliance/findings-board/contacts/:contactId`,
				baseResourceProps.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
		};
	}

	getContact = (...args) => this.contact.get(...args);

    public getContactLists(
            organizationId: number,
            organizationContactId: number,
            listParamscontainedInList: boolean,
            listParams): Observable<PagedData<ContactLists[]>> {
        return this.http.pagedRequest<ContactLists[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/contact-lists/contact/${organizationContactId}`,
            {
                ...listParams,
                containedInList: listParamscontainedInList,
            }
        );
    }

    public getAudits(
            tpoId: number,
            contactId: number,
            listParams): Observable<PagedData<AuditReview[]>> {
        return this.http.pagedRequest<AuditReview[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/contacts/${contactId}/social-media-compliance/reviews`,
            listParams);
    }

    public getFindings(
            tpoId: number,
            contactId: number,
            listParams: FindingsQueryParams = {}): Observable<PagedData<FindingsItem[]>> {
        return this.http.pagedRequest<FindingsItem[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/social-media-compliance/findings-board/contacts/${contactId}`,
            <any>listParams);
    }
}
