import { Injectable } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { TPOArchiveResourceService } from './archive-resource.service';
import { ArchivePost } from './list/post.interface';

interface ArchiveQueryParams {
	q?: string;
	postStartDate?: number;
	postEndDate?: number;
	entity?: string;
}

interface PostHistoryParams {
	smAccountId: string;
	contentType: string;
	postId: string;
	version: number;
}

interface PostCommentsParams {
	id: string;
	smAccountId: string;
	contentType: string;
	version: number;
}

interface MediaContentType {
	shortName: 'video' | 'photo';
	name: string;
}

interface MediaTypesResponse {
	$promise: Promise<MediaContentType[]>;
}

interface AccountType {
	id: 'FB' | 'LI' | 'TW' | 'IG' | 'YT' | 'OT';
	name: string | 'Facebook' | 'LinkedIn' | 'Twitter' | 'Instagram' | 'Youtube' | 'Other';
}

interface AccountTypesResponse {
	$promise: Promise<AccountType[]>;
}

interface ListResponse {
	$promise: Promise<ArchivePost[]>;
}

@Injectable()
export class TPOArchiveService {
	constructor(
		public archiveResource: TPOArchiveResourceService,
		public User: UserService,
	) {}

	list = (queryParams?: ArchiveQueryParams): ListResponse => {
		return this.archiveResource.list.get(queryParams);
	}

	saveFinding = (params, body) => {
		return this.archiveResource.finding.save(params, body);
	}

	getPostHistory = (params: PostHistoryParams) => {
		return this.archiveResource.postHistory.get(params);
	}

	getComments = (params: PostCommentsParams) => {
		return this.archiveResource.comments.get(params);
	}

	getEntities = () => {
		return this.archiveResource.entities.get();
	}

	getMediaContentTypes = (): MediaTypesResponse => {
		return this.archiveResource.mediaContentTypes.get();
	}

	getAccountTypes = (): AccountTypesResponse => {
		return this.archiveResource.accountTypes.get();
	}
}
