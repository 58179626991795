import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class LenderNMLSContactsResourceService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const basePath = `${apiPath}/nmls/company/:tpoId/contacts`;
		const defaultParams = {};
		this.resource = PagedResource(`${basePath}`, defaultParams, {
			query: {method: 'get', isArray: true},
		});
	}

	query = (...args) => this.resource.query(...args);
}
