import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { HistoryInformation } from 'comergence/customers/investor-marketing-service/investor-marketing.interface';
import { CrmSettings } from 'comergence/customers/services/customer-services/crm-settings.interface';
import { UserInformation } from 'comergence/customers/services/customer-services/user-information.interface';

declare let apiPath: string;

@Injectable()
export class InvestorMarketingService {
    constructor(
        private  http: RealmHttpClient,
    ) {}

    public getHistoryList(organizationId, listParam): Observable<PagedData<HistoryInformation[]>> {
        return this.http.pagedRequest<HistoryInformation[]> (
            'GET' ,
            `${apiPath}/organizations/${organizationId}/crm-settings/history`,
            listParam
        );
    }

    public getCrmSettings(organizationId: number): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'GET',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information`,
            null,
            null
        );
    }

    public saveCrmSettings(organizationId: number, newSettings: CrmSettings): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information`,
            null,
            newSettings
        );
    }

    public addNewCrmSettings(organizationId: number, newSettings: CrmSettings): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'POST',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information`,
            null,
            newSettings
        );
    }

    public getUsers(organizationId: number): Observable<UserInformation[]> {
        return this.http.request<UserInformation[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/users/all/page`
        );
    }

    public activateInvestorMarketingService(organizationId: number): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information/active`
        )
    }

    public deactivateInvestorMarketingService(organizationId: number): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'DELETE',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information/active`
        )
    }
}
