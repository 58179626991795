<ng-container *ngIf="hasViewPermissions; then validPermissions; else invalidPermissions"></ng-container>
<ng-template #validPermissions>
	<ng-container *ngIf="User.isTpo; then lenderHeader; else ccmHeader"></ng-container>
	<ng-template #lenderHeader>
		<div class="gray-head">
			<h2>
                Keyword Triggers
				<smHelpLink [url]="helpLink" *ngIf="User.isTpo"></smHelpLink>
			</h2>
		</div>
	</ng-template>
	<ng-template #ccmHeader>
		<h2>Triggers</h2>
	</ng-template>

	<notification></notification>

	<div class="remote-resource" [class.resolved]="list.$resolved">
		<div class="filters-rows">
			<div class="row filter-labels">
				<div class="col-sm-3">
					<div class="text-light">Search</div>
				</div>
			</div>

			<div class="row-fluid filter-selectors">
				<div class="col-sm-3">
					<search-list placeholder="Keyword"></search-list>
				</div>
				<div class="col-sm-9">
					<div class="pull-right">
						<a
							*ngIf="hasManagePermissions"
							class="btn btn-primary"
							uiSref=".new"
						>Add Trigger</a>
					</div>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-8" columnName="name">Trigger Name</sortable-header>
				<sortable-header class="col-sm-2" columnName="modifiedDate">Modified Date</sortable-header>
				<sortable-header class="col-sm-2" columnName="status">Status</sortable-header>
			</div>

			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
			</div>

			<a
				*ngFor="let item of list"
				class="row-fluid clickable"
				uiSref=".:triggerId"
				[uiParams]="{ triggerId: item.id }"
			>
				<div class="col-sm-8">
					<div class="flex-row gap">
						<div style="width: 21px; height: 21px; flex: 0 0 auto;">
							<span
								*ngIf="item.isDefault"
								class="comergence-icon"
								tooltip="Default Trigger"
								placement="top"
							></span>
						</div>
						<span class="flex-shrink ellipsis" [class.text-muted]="item.isDeactivatedByCCM">
							{{item.name}}
						</span>
					</div>
				</div>
				<div class="col-sm-2 va-m">{{(item.modifiedDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
				<div class="col-sm-1 va-m text-bigger">
					<span
						class="label"
						[ngClass]="item.isDeactivatedByCCM ? [ 'label-default' ] : [ triggerStatuses[item.status.code].class ]"
					>
						{{item.status.label || '&ndash;'}}
					</span>
				</div>
				<div class="col-sm-1 va-m text-bigger">
					<a
						*ngIf="!item.isDefault"
						class="btn btn-tertiary btn-icon"
						(click)="preventEventBubbling($event)"
						uiSref=".:triggerId.history"
						[uiParams]="{ triggerId: item.id }"
					>
						<span class="c-icon c-icon-back-in-time medium-icon"></span>
					</a>
				</div>
			</a>

			<div class="row-fluid" *ngIf="list.$resolved && !list.length">
				<div class="col-sm-12 text-center text-bigger text-light va-m">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No triggers'}}
				</div>
			</div>

			<br>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</ng-template>
<ng-template #invalidPermissions>
	<no-permissions title="Triggers">
		<img src="/static/images/promo/sm-get-started/Icon_settings_off.svg" alt="Triggers"/>
	</no-permissions>
</ng-template>
