import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { Observable } from 'rxjs';

export abstract class ContactServiceInterface {
    abstract getContact(contactId: number, tpoId: number, ...args): Observable<FullTpoContact>;

    abstract saveNewContact(contact: FullTpoContact, tpoId: number, ...args): Observable<FullTpoContact>;

    abstract saveExistingContact(contact: FullTpoContact, tpoId: number, ...args): Observable<FullTpoContact>;

    abstract removeContact(contact: FullTpoContact, tpoId: number, ...args): Observable<FullTpoContact>;

    abstract restoreContact(contact: FullTpoContact, tpoId: number, ...args): Observable<FullTpoContact>;
}
