export const CampaignStatuses = {
	'Not Started': {
		color: 'success',
	},
	'In Progress': {
		color: 'warning',
	},
	'Ended': {
		color: 'default',
	},
};
