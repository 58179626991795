import { Injectable } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResource, NgResourceArray, NgResourceObject } from 'commons/interfaces';

import { CustomFieldsResourceService } from './custom-fields-resource.service';

@Injectable()
export class CustomFieldsService {
	public static EntityTitles = {
		Company: 'Company',
		CRMActivity: 'Activities',
		CRMContact: 'Contacts',
		CRMOpportunity: 'Opportunities',
		CRMProspect: 'Prospects',
		CCM_CUSTOMER: 'Customer',
        CCM_TPO: 'Client',
	};
	public static SystemTypes: SystemTypes = {
		CRMProspect: [
			{
				fieldName: 'Status',
				systemType: 'ProspectStatus',
				fieldDescription: 'Current status of the prospect. Status values allow you to manage and track workflow of prospect records.',
				controlTypeId: 'Droplist',
				editable: true,
			},
		],
	};
	public static customFieldsConfig = {
		Text: {
			name: 'Text Field',
			description: 'Allows user to enter any combination of letters and numbers.',
		},
		Email: {
			name: 'Email',
			description: 'Allows user to enter email.',
			placeholder: 'Enter Email',
		},
		PhoneNumber: {
			name: 'Phone Number',
			description: 'Allows user to enter phone number.',
			placeholder: 'Enter Phone Number',
		},
		Date: {
			name: 'Date',
			description: 'Allows user to enter date.',
			placeholder: 'mm/dd/yyyy',
		},
		Number: {
			name: 'Number',
			description: 'Allows user to enter number.',
			placeholder: 'Enter Number',
		},
		Percent: {
			name: 'Percent',
			description: 'Allows user to enter percent.',
			placeholder: 'Enter %',
		},
		Dollar: {
			name: 'Dollar',
			description: 'Allows user to enter dollars.',
			placeholder: 'Enter Amount',
		},
		YesNo: {
			name: 'Yes/No',
			description: 'Allows user to enter Yes/No answer.',
			options: [
				'Yes',
				'No',
			],
		},
		Droplist: {
			name: 'Single Selection',
			description: 'Allows user to select a value from a list you define.',
			placeholder: 'Select value',
		},
		MultipleSelection: {
			name: 'Multiple Selection',
			description: 'Allows user to select multiple values from a list you define.',
			placeholder: 'Select values',
		},
	};

	constructor(
		public fieldsResource: CustomFieldsResourceService,
		public User: UserService,
	) {}

	// fields
	entities = (params: unknown): NgResourceArray<FieldsEntity> => this.fieldsResource.fields.query(params);

	entityFields(entityTypeId: EntityType): NgResourceArray<CustomField> {
		return this.fieldsResource.fields.entityFields({ entityTypeId });
	}

	values(entityTypeId: EntityType, entityId: number): NgResourceArray<CustomFieldsElement> {
		return this.fieldsResource.fields.values({ entityTypeId, entityId });
	}


	updateValues(entityTypeId: EntityType, entityId: number, values: CustomFieldsElement[]): NgResourceArray<CustomFieldsElement> {
		return this.fieldsResource.fields.updateValues({ entityTypeId, entityId }, values);
	}

	addField(entityTypeId: EntityType, field: Partial<CustomField>): NgResourceObject<CustomField> {
		return this.fieldsResource.fields.add({ entityTypeId }, field);
	}

	collection<T>(value: T[]): NgResourceArray<T> {
        const result = (value || []) as NgResourceArray<T>;
        Object.defineProperty(result, '$resolved', { enumerable: false, writable: true, value: true });
        Object.defineProperty(result, '$promise', { enumerable: false, value: Promise.resolve(result) });
		return result;
	}

	// fields (new structure)
	layout(entityTypeId: EntityType): NgResourceObject<Layout> {
		return this.fieldsResource.fields.layout({ entityTypeId });
	}

	layoutUpdate(entityTypeId: EntityType, fields: NgResourceObject<Layout>): NgResourceObject<Layout> {
		return this.fieldsResource.fields.layoutUpdate({ entityTypeId }, fields);
	}

	valuesNew(entityTypeId: EntityType, entityId: number): NgResourceObject<LayoutElements> {
		return this.fieldsResource.fields.valuesNew({ entityTypeId, entityId });
	}

	updateValuesNew(entityTypeId: EntityType, entityId: number, values: NgResource<LayoutElements>): NgResource<LayoutElements> {
		return this.fieldsResource.fields.updateValuesNew({ entityTypeId, entityId }, values);
	}

	// field
	getField(id: number): NgResourceObject<CustomField> {
		return this.fieldsResource.field.get({ id });
	}

	updateField(id: number, field: Partial<CustomField>): NgResourceObject<CustomField> {
		return this.fieldsResource.field.update({ id }, field);
	}

	removeField(id: number): NgResource {
		return this.fieldsResource.field.remove({ id });
	}

	fieldAction(id: number, action: Action): NgResourceObject<CustomField> {
		return this.fieldsResource.field.action({ id, action });
	}

	// groups
	groups(entityTypeId: EntityType): NgResourceArray<Group> {
		return this.fieldsResource.groups.query({ namesOnly: true, entityTypeId });
	}

	createGroup(entityTypeId: EntityType, group: Group): NgResourceObject<Group> {
		return this.fieldsResource.groups.add({ entityTypeId }, group);
	}

	updateGroup(groupId: number, groupUpdate: Group): NgResourceObject<Group> {
		return this.fieldsResource.groups.update({ groupId }, groupUpdate);
	}

	removeGroup(groupId: number): NgResourceObject<Group> {
		return this.fieldsResource.groups.remove({ groupId });
	}

	groupAction(id: number, action: GroupAction): NgResourceObject<Group> {
		return this.fieldsResource.groups.action({ id, action });
	}

	// types
	controlTypes(): NgResourceArray<ControlType> {
		return this.fieldsResource.types.get();
	}

	// prospect (@Todo: use prospects service, when it will be rewritten to a new angular)
	getProspectStatuses(): NgResourceArray<ProspectStatus> {
		return this.fieldsResource.prospectSystemStatuses.query();
	}

	updateProspectStatuses(data: Array<Omit<ProspectStatus, 'id'>>): NgResourceArray<ProspectStatus> {
		return this.fieldsResource.prospectSystemStatuses.update(data);
	}

	getProspectEvents(): NgResourceArray<ProspectEvent> {
		return this.fieldsResource.prospectSystemStatuses.events();
	}
}

export type EntityType = keyof typeof CustomFieldsService.EntityTitles;

export interface Layout {
	groups: Group[];
	fields: CustomField[];
}

export interface LayoutElements {
	groups: GroupElement[];
	fields: CustomFieldsElement[];
}

export interface Group {
	id: number;
	name: string;
	displayOrder: number;
	fields?: CustomField[];
	editing?: boolean; // custom prop
}

export interface GroupElement extends Omit<Group, 'fields'> {
	fields?: CustomFieldsElement[];
}

export interface SplitFieldsLayout {
	fields: CustomField[];
	fieldsCol2: CustomField[];
	groups: Array<Group & { fieldsCol2: CustomField[] }>;
}

export interface ProspectStatus {
	eventId: string;
	id: number;
	name: string;
	stateId: string;
}

export interface ProspectEvent {
	id: string;
	name: string;
	stateId: string;
}

export type Action = 'up' | 'down' | 'show' | 'hide';

export type GroupAction = 'up' | 'down';

export interface FieldsEntity {
	count: number,
	id: string,
	name: string,
	permissionType: string;
}

export interface ControlType {
	shortName: string;
	name: string;
}

interface SystemTypes {
	[systemType: string]: Array<SystemField>,
}

export interface SystemField {
	fieldName: string,
	systemType: string,
	fieldDescription: string,
	controlTypeId: string,
	editable: boolean,
}

export interface CustomFieldsElement extends Partial<CustomField> {
	customField?: CustomField;
	value: string | null;
}

export interface CustomFieldChangeValue {
	from: string;
	to: string;
}

export interface CustomField {
	controlTypeId: ControlTypeId;
	displayOrder: number;
	displayColumn: 1 | 2;
	displayRow: number;
	editable: boolean;
	fieldDescription: string | null;
	fieldName: string;
	helpText: string | null;
	id: number;
	required: boolean;
	valuesList: string[] | null;
	visible: boolean;
	groupId?: number;
	changeValues?: CustomFieldChangeValue[]
}

export type ControlTypeId =
	| 'Text'
	| 'Droplist'
	| 'PhoneNumber'
	| 'Number'
	| 'YesNo'
	| 'Percent'
	| 'MultipleSelection'
	| 'Email'
	| 'Dollar'
	| 'Date';
