import { Component, forwardRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { StaticValuesService } from 'angularjs-providers/static-values.provider';
import { UserService as UService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { PublishingPermissionsResourceService } from './publishing-permissions-resource.service';
import { PublishingPermissionsComponent } from './publishing-permissions.component';

@Component({
	templateUrl: './publishing-permissions.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => IndividualPublishingPermissionsComponent) },
	],
})
export class IndividualPublishingPermissionsComponent extends PublishingPermissionsComponent {
	isIndividual: boolean = true;
	newEntry: Array<{
		id?: number,
		fullName?: string,
	}> = [];

	entryAddForm: RealmFormGroup = new RealmFormGroup({
		fullName: new RealmFormControl(
			'fullName',
			{
				label: 'User',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		publishingPermissionsResourceService: PublishingPermissionsResourceService,
		UserService: UService,
		StaticValues: StaticValuesService,
		modalService: BsModalService,
	) {
		super(
			transitionService,
			stateService,
			publishingPermissionsResourceService,
			UserService,
			StaticValues,
			modalService,
		);
	}

	fillNewEntry(): void {
		const { fullName: users } = this.entryAddForm.value;
		this.newEntry = users;
	}

	getNonPermissionsUsers() {
		return this.canManage ? super.getNonPermissionsUsers() : Promise.resolve([]);
	}

	setupResources(): void {
		const { linkId, id, contactId } = this.stateService.params;
		const tpoId = this.User.organization.type === 'TPO' ? this.User.organization.id : id;
		const { contactId: userContactId } = this.User;

		this.canManage = contactId * 1 === userContactId;
		this.permissionParams = {
			tpoId: this.User.organization.type === 'TPO' ? this.User.organization.id : id,
			linkId,
			id: contactId,
		};
		this.createParams = {
			tpoId,
			linkId,
			id: contactId,
		};
		this.permissionsResource = this.publishingPermissionsResourceService.individual;
	}
}
