import { mapValues, map } from 'lodash';
import { Component, Host, Input, OnInit } from '@angular/core';
import { share } from 'rxjs/operators';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';

import { Filters, MarketLocationParams } from '../market.interface';
import { MarketService } from '../market.service';

@Component({
	selector: 'market-filters',
	templateUrl: 'market-filters.component.html',
	host: { class: 'filters-rows' },
})
export class MarketFiltersComponent implements OnInit {
	@Input() locationParams: MarketLocationParams;
	@Input() resetPage = true;

	filters: Filters;

	constructor(
		@Host() public listComponent: NewPagedListComponent<unknown>,
		private readonly marketService: MarketService,
	) {}

	ngOnInit(): void {
		this.filters = {
			lt: this.marketService.getLicenseTypes(this.locationParams).pipe(share()),
			auth: this.marketService.getAuthValues().pipe(share()),
			bm: this.marketService.getBusinessModel().pipe(share()),
			bt: this.marketService.getBusinessTypes().pipe(share()),
			ct: this.marketService.getClientTypes().pipe(share()),
			loant: this.marketService.getLoanTypes().pipe(share()),
		};

		this.listComponent.defaultParams = {
			...this.listComponent.defaultParams,
			lt: [],
			auth: undefined,
			bm: [],
			bt: [],
			ct: [],
			loant: [],
		};
    }

	setFilter(filters: ListParams): void {
		const processedFilters = mapValues(filters, (value: any, key: string) => {
			switch (key) {
				case 'auth':
					return value?.id;
				default:
					return map(value || [], 'id');
			}
		});

		this.listComponent.setFilter(processedFilters, this.resetPage);
	}
}