import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { ScorecardFindingSupportingDocument } from '../scorecard-finding.interface';
import { SupportingDocumentReplacedEvent } from './scorecard-supporting-documents.interface';


@Component({
    selector: 'scorecard-supporting-documents',
    templateUrl: './scorecard-supporting-documents.component.html',
})
export class ScorecardSupportingDocumentsComponent<T = (ScorecardFindingSupportingDocument | File)> {
    @Input() files: T;
    @Input() allowAddNew: boolean = true;
    @Input() allowReplace: boolean = true;
    @Output() fileAdded: EventEmitter<FileList> = new EventEmitter();
    @Output() fileReplaced: EventEmitter<SupportingDocumentReplacedEvent> = new EventEmitter();
    @Output() fileRemoved: EventEmitter<File> = new EventEmitter();

    constructor(
        private readonly changeDetector: ChangeDetectorRef,
    ) {
    }

    addNewFile(file: FileList) {
        this.fileAdded.emit(file);

        this.changeDetector.detectChanges();
    }

    replaceFile(fileToReplace: File, selectedFiles: FileList) {
        if (!selectedFiles.length) {
            return;
        }

        const event: SupportingDocumentReplacedEvent = {
            fileToReplace,
            newFile: selectedFiles.item(0),
        };

        this.fileReplaced.emit(event);

        this.changeDetector.detectChanges();
    }

    removeFile(fileToRemove: File) {
        this.fileRemoved.emit(fileToRemove);

        this.changeDetector.detectChanges();
    }
}
