<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="hide()">&times;</button>
	<h4 class="modal-title text-center">Privacy Settings</h4>
</div>
<div class="modal-body">
    <section *ngIf="privacySettings.selected">
    	<h4>Selected Accounts</h4>
    	<div *ngFor="let selectedPrivacySetting of privacySettings.selected" class="row bordered">
    		<div class="col-md-8">
    			<div class="pseudo-tabel">
    				<div class="pseudo-row">
    					<div class="pseudo-cell va-m icon-holder cell-space">
    						<sm-icon [config]="selectedPrivacySetting"></sm-icon>
    					</div>
    					<div class="pseudo-cell va-m">
    						{{ selectedPrivacySetting.name }}
    					</div>
    				</div>
    			</div>
    		</div>
    		<div class="col-md-4">
                <ng-select
                    [(ngModel)]="selectedPrivacySetting.visibility"
                    [items]="selectedPrivacySetting.visibilityOptions"
                    bindValue="code"
                    bindLabel="name"
                    [multiple]="false"
                    [markFirst]="true">
                </ng-select>
    		</div>
    	</div>
    </section>
    <section *ngIf="privacySettings.enroll">
    	<h4>Enrolled Accounts</h4>
    	<div *ngFor="let enrolledPrivacySetting of privacySettings.enroll" class="row bordered">
    		<div class="col-md-8">
    			<div class="pseudo-tabel">
    				<div class="pseudo-row">
    					<div class="pseudo-cell va-m icon-holder cell-space">
    						<sm-icon [config]="enrolledPrivacySetting"></sm-icon>
    					</div>
    					<div class="pseudo-cell va-m">
    						{{ enrolledPrivacySetting.name }}
    					</div>
    				</div>
    			</div>
    		</div>
    		<div class="col-md-4">
                <ng-select
                    [(ngModel)]="enrolledPrivacySetting.visibility"
                    [items]="enrolledPrivacySetting.visibilityOptions"
                    bindValue="linkId"
                    bindLabel="name"
                    [multiple]="false"
                    [markFirst]="true">
                </ng-select>
    		</div>
    	</div>
    </section>
</div>
<div class="modal-footer" [class.curtain]="resolving">
    <div class="btn-group-lg">
        <button class="btn btn-primary" type="button" (click)="confirm()">Save</button>
        <button type="button" class="btn btn-default" (click)="hide()">Cancel</button>
    </div>
</div>
