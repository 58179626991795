import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class ReportingLicensesService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const defaultParams = {organizationId: User.profile.organization.id};
		const reportingPath = `${apiPath}/organizations/:organizationId/reporting`;

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/reporting45settings45resource/getReportingLicensesUsingGET
		this.resource = $resource(reportingPath, defaultParams, {
			get: {
				url: `${reportingPath}/licenses`,
				method: 'GET',
				isArray: true,
			},
		});
	}

	getLicensesOptions = (...args) => this.resource.get(...args);
}
