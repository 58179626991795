<ng-container [ngSwitch]="hasViewAllPermission">
	<div *ngSwitchCase="true" class="social-settings remote-resource" [class.resolved]="resolved.schedule && resolved.monitoring">
		<div class="flex-head clearfix">
			<h2>
				Settings
				<smHelpLink [url]="helpLink"></smHelpLink>
			</h2>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<span class="col-md-3">
					<b class="two-liners">Settings</b>
				</span>
				<span class="col-md-7">
					<b class="two-liners">Description</b>
				</span>
				<span class="col-md-1">
					<b class="two-liners">Status</b>
				</span>
			</div>
			<div (click)="settingClick(setting)" class="row-fluid setting-link"  *ngFor="let setting of settingSections">
				<span class="col-md-3 va-m">
					<b class="icon-holder">
						<span class="medium-icon branded-text" [ngClass]="setting.icon"></span>&nbsp;
						{{setting.name}}&nbsp;<span *ngIf="setting.beta" class="label label-success text-smaller label-text-normal">Beta</span>
					</b>
					<span *ngIf="setting.alert && (setting.alert | async)" class="on-page-alert">
            			<span class="icon-alert"></span>
						{{ setting.alert | async }}
					</span>
				</span>
				<span class="col-md-7 va-m text-muted">
					{{setting.description}}
				</span>
				<span class="col-md-1 va-m">
					<span *ngIf="setting.enabled === true">
						<span class="glyphicon glyphicon-ok text-success"></span> On
					</span>
					<span *ngIf="setting.enabled === false">
						<span class="glyphicon glyphicon-remove text-danger"></span> Off
					</span>
				</span>
			</div>
		</div>
	</div>
	<div *ngSwitchDefault class="row">
		<no-permissions title="Compliance Settings">
			<img src="/static/images/promo/sm-get-started/Icon_settings_off.svg" alt="Compliance Settings"/>
		</no-permissions>
	</div>
</ng-container>
