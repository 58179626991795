import { Component, Input, OnInit } from '@angular/core';

import { chunk, unzip } from 'lodash';

import { Channel } from 'lender/manage-channels/manage-channel-card.interface';

import { DocumentCardDetails, DocumentCardSummary } from '../document-card.interface';
import { DocumentScheduleSettingsStrategy } from '../strategy.interface';
import { ColumnSplitterService } from '../../../commons/services/utils/column-splitter.service';


@Component({
    selector: 'document-card-container',
    templateUrl: 'document-card-container.component.html',
})
export class DocumentCardContainerComponent<S extends DocumentCardSummary, D extends DocumentCardDetails> implements OnInit {
    public columnCount = 2;
    public columnWidth = Math.floor(12 / this.columnCount);

    @Input() strategy: DocumentScheduleSettingsStrategy<S, D>;
    @Input() allChannels: Channel[] = [];
    @Input() financialDocumentParams: any = {};

    private _documentSummaries: S[] = [];
    @Input() set documentSummaries(documentSummaries: S[]) {
        this._documentSummaries = documentSummaries;

        this.documentSummaryColumns = this.cardContainerService.splitIntoColumns(this.documentSummaries, this.columnCount);
    }
    get documentSummaries(): S[] {
        return this._documentSummaries;
    }

    documentSummaryColumns: S[][] = [];

    constructor(protected readonly cardContainerService: ColumnSplitterService) {
    }

    // Override
    ngOnInit(): void {
    }
}
