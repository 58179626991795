import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { ReviewAlertInformation } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-finding-summary.interface';
import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath;

@Injectable()
export class ScorecardMarkReviewedService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    public checkAlertPermission(userService: UserService): boolean {
        return  userService.profile.can('EDIT_ALERTS') && userService.profile.can('VIEW_ALERTS');
    }

    public markReviewAndGetAlertInformation(tpoId: number, findingId: (string | number)) : Observable<ReviewAlertInformation> {
        return this.http.request<ReviewAlertInformation>(
            'POST',
            `${apiPath}/tpos/${tpoId}/findings/${findingId}/review/finding-alert`,
        );
    }

    public updateAlertStatus(lenderId: number, findingId: (string | number), status: string): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/lenders/${lenderId}/alerts/findings/${findingId}/finding-alert-status`,
            null,
            status
        );
    }

}
