<div class="row-fluid">
    <span class="col-md-3">
        <span class="control-value flex-row gap">
            <a target="_blank"
               [aDisabled]="item.isContactDeleted"
               [uiSref]="contactSref" [uiParams]="contactSrefParams">
                {{item.name}}
            </a>
            <div *ngIf="!item.isContactRelevant">
                <span
                  class="text-danger glyphicon glyphicon-exclamation-sign"
                  tooltip="Contact is no longer related to the company"></span>
            </div>
        </span>
    </span>
    <span class="col-md-a control-value">
        <div class="text-overflow">{{item.designation || '&ndash;'}}</div>
    </span>
    <span class="col-md-a control-value">{{item.title || '&ndash;'}}</span>
    <span class="col-md-a control-value">{{item.location || '&ndash;'}}</span>
    <span class="col-md-a control-value">{{item.phone || '&ndash;' | tel}}</span>
    <span class="col-md-a control-value">
        <a [aDisabled]="!item.email" href="mailto:{{item.email}}">{{item.email || '&ndash;'}}</a>
    </span>
    <span class="col-f-120 button-holder text-right" *ngIf="editable" [ngSwitch]="askRemove">
        <ng-container *ngSwitchDefault>
            <button type="button" class="btn btn-icon btn-tertiary" (click)="askRemove = true">
                <span class="glyphicon glyphicon-trash"></span>
            </button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="button" class="btn btn-icon btn-tertiary" (click)="remove()">
                <span class="glyphicon glyphicon-ok"></span>
            </button>
            <button type="button" class="btn btn-icon btn-tertiary" (click)="askRemove = false">
                <span class="glyphicon glyphicon-remove"></span>
            </button>
        </ng-container>
    </span>
</div>
