import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { SocialMediaBillingComponent } from 'comergence/tpo/billing/social-media/social-media.component';
import { CcmSmInvoicingSettingsComponent } from 'comergence/tpo/billing/social-media/invoicing-settings/invoicing-settings.component';
import { CcmInvoicingSettingsService } from 'comergence/tpo/billing/social-media/invoicing-settings/invoicing-settings.service';
import { CcmBillingSubscriptionDetailsResourceService } from 'comergence/tpo/billing/social-media/billing-subscription/billing-subscription-resource.service';
import { CcmSmBillingSubscriptionComponent } from 'comergence/tpo/billing/social-media/billing-subscription/billing-subscription.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        SocialMediaBillingComponent,
        CcmSmInvoicingSettingsComponent,
        CcmSmBillingSubscriptionComponent,
    ],
    providers: [
        CcmInvoicingSettingsService,
        CcmBillingSubscriptionDetailsResourceService,
    ],
})
export class TpoBillingModule {
}
