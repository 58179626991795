<div class="remote-resource" [class.resolved]="!resolving">
	<div class="modal-header">
		<button type="button" class="close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body">
		<p>{{message}}</p>
		<p *ngIf="companyName"><strong>{{companyName}}</strong></p>
		<div class="btn-section">
			<span  *ngFor="let service of services" class="connect-btn" [ngClass]="service.btnClass" (click)="service.click()">
			</span>
		</div>
	</div>
</div>
