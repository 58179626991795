<div class="modal-header">
	<button
			type="button"
			class="close pull-right"
			aria-label="Close"
			(click)="modalRef.hide()"
	>&times;</button>
	<h4 class="modal-title">Waive Document</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="snapshotData.$resolved">
	<div>
		<span class="text-muted text-smaller inline-mb-6">Reason</span><br>
		<div *ngIf="snapshotData?.comment; else dash">{{snapshotData.comment}}</div>
	</div>
	<br>
	<div>
		<span class="text-muted text-smaller inline-mb-6">Waive</span><br>
		<div *ngIf="snapshotData?.waiveType; else dash">{{snapshotData.waiveType}}</div>
	</div>
	<ng-template #dash><div class="text-medium">&ndash;</div></ng-template>
</div>
<div class="modal-footer">
	<div>
		<button type="button" (click)="modalRef.hide()" class="btn btn-primary">
			Close
		</button>
	</div>
</div>
