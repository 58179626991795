import { Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ProfileAccessLevel } from 'shared/account/company-information/account-information.service';

import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { FinancialDocumentResourceService } from 'commons/services/financial-documents-resource.service';
import { TPOFinancialDocument, TPOFinancialDocumentFields, TPOFinancialDocumentField } from 'commons/services/financial-documents.interface';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { TpoIssuesStatusService } from 'tpo/status';


@Component({
    selector: 'tpo-financial-document-list',
    templateUrl: './tpo-account-financial-document-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => TpoAccountFinancialDocumentListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => TpoAccountFinancialDocumentListComponent) },
    ],
})
export class TpoAccountFinancialDocumentListComponent extends NewPagedListComponent<TPOFinancialDocument> implements OnInit, OnChanges {
    userProfile: UserProfile;
    @Input() tpoId: number;
    @Input() investorId?: number;
    @Input() relationshipStatus?: ProfileAccessLevel;

    tpoFinancialDocumentFields: TPOFinancialDocumentFields = {
        FINANCIAL_STATEMENT: [],
        BALANCE_SHEET: [],
        INCOME_PROFIT_LOSS_STATEMENT: [],
        STATEMENT_OF_CASH_FLOWS: [],
    };

    balanceSheetDocuments: TPOFinancialDocument[] = [];
    incomeStatementDocuments: TPOFinancialDocument[] = [];
    cashFlowsStatementDocuments: TPOFinancialDocument[] = [];

    isOnGoing: boolean;
    summaryFields: TPOFinancialDocumentField[];

    constructor(
        router: UIRouter,
        private readonly financialDocumentResourceService: FinancialDocumentResourceService,
        private readonly userService: UserService,
        private readonly tpoIssuesStatusService: TpoIssuesStatusService,
    ) {
        super(router);
        this.userProfile = this.userService.profile;
    }

    ngOnChanges({ relationshipStatus }: SimpleChanges): void {
        if (this.userProfile.isLender && relationshipStatus.currentValue) {
            this.isOnGoing = relationshipStatus.currentValue.id === 'OG';
        }
    }

    // Override
    ngOnInit(): void {
        super.ngOnInit();
    }

    // Override
    protected loadList(params: ListParams): Observable<PagedData<TPOFinancialDocument[]>> {
        this.resetDocuments();

        const page: Observable<PagedData<TPOFinancialDocument[]>> = this.getTpoFinancialDocuments(params);

        return page
            .pipe(
                tap(({ data }: PagedData<TPOFinancialDocument[]>) => {
                    const [financialDocument] = data;
                    this.summaryFields = financialDocument?.fields;
                    return this.categorizeDocumentsIntoIndividualLists(data);
                })
            );
    }

    private resetDocuments(): void {
        this.balanceSheetDocuments = [];
        this.incomeStatementDocuments = [];
        this.cashFlowsStatementDocuments = [];
    }

    private getTpoFinancialDocuments(params: ListParams): Observable<PagedData<TPOFinancialDocument[]>> {
        if (this.investorId) {
            return this.financialDocumentResourceService.getTpoFinancialDocumentsForInvestor(this.tpoId, this.investorId, params);
        } else {
            return this.financialDocumentResourceService.getTpoFinancialDocuments(this.tpoId, params);
        }
    }

    private categorizeDocumentsIntoIndividualLists(documents: TPOFinancialDocument[]): void {
        documents.forEach((tpoFinancialDocument: TPOFinancialDocument) => {
            this.categorizeDocumentIntoIndividualList(tpoFinancialDocument);
        });
    }

    private categorizeDocumentIntoIndividualList(tpoFinancialDocument: TPOFinancialDocument): void {
        if ('BALANCE_SHEET' === tpoFinancialDocument.type) {
            this.balanceSheetDocuments.push(tpoFinancialDocument);
        } else if ('INCOME_PROFIT_LOSS_STATEMENT' === tpoFinancialDocument.type) {
            this.incomeStatementDocuments.push(tpoFinancialDocument);
        } else if ('STATEMENT_OF_CASH_FLOWS' === tpoFinancialDocument.type) {
            this.cashFlowsStatementDocuments.push(tpoFinancialDocument);
        }
    }

    updateList(params: ListParams = this.params) {
        super.updateList(params);
        if (this.userProfile.isTpo) {
            this.tpoIssuesStatusService.financial.update();
        }
    }
}
