import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { NgModule } from '@angular/core';

import { CustomFieldsResourceService } from './custom-fields-resource.service';
import { CustomFieldsService } from './custom-fields.service';
import { CustomFieldsEntitiesComponent } from './custom-fields-entities/custom-fields-entities.component';
import { EntityFieldsComponent } from './custom-fields-entities/entity-fields/entity-fields.component';
import { DetailsComponent } from './custom-fields-entities/entity-fields/details/details.component';
import { SystemDetailsComponent } from './custom-fields-entities/entity-fields/system-details/system-details.component';
import { FieldRemoveModalComponent } from './custom-fields-entities/entity-fields/details/remove-modal/field-remove-modal.component';
import { EditValuesModalComponent } from './custom-fields-entities/entity-fields/details/edit-values-modal/edit-values-modal.component';
import { FieldsEditableComponent } from './additional-fields/fields-editable/fields-editable.component';
import { JsonParsePipe } from './json-parse.pipe';

import { AdditionalFieldsComponent } from 'shared/fields/additional-fields';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		PipesModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		DragDropModule,
	],
	declarations: [
		CustomFieldsEntitiesComponent,
		EntityFieldsComponent,
		DetailsComponent,
		SystemDetailsComponent,
		FieldRemoveModalComponent,
		EditValuesModalComponent,
		FieldsEditableComponent,
		JsonParsePipe,
		AdditionalFieldsComponent,
	],
	providers: [
		CustomFieldsResourceService,
		CustomFieldsService,
	],
	exports: [
		FieldsEditableComponent,
		AdditionalFieldsComponent,
	],
})

export class CustomFieldsModule {}
