import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

import { ContactListContact } from 'lender/crm/contact/investor-crm-contact-lists/investor-crm-contact-list.interface';
import { InvestorCrmContactListService } from 'lender/crm/contact/investor-crm-contact-lists/services/investor-crm-contact-list.service';


@Component({
	selector: 'add-contact-search',
	templateUrl: 'add-contact-search.component.html',
})
export class AddContactSearchComponent implements OnDestroy {
	readonly MINIMUM_SEARCH_TEXT_CHARACTERS: number = 3;

    @Input() organizationId: number;
	@Input() contactListId: number;
	@Output() contactSelected: EventEmitter<ContactListContact> = new EventEmitter();

	isLoading: boolean = false;
	contactsFound: ContactListContact[] = [];

	contactsSearchInput$ = new Subject<string>();
	selectedContact: ContactListContact;

	private destroyed$: Subject<void> = new Subject();

	constructor(
		private readonly investorCrmContactListService: InvestorCrmContactListService,
	) {
		this.subscribeToSearchInput();
	}

	// Override
	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	subscribeToSearchInput(): void {
		this.isLoading = false;

		this.contactsSearchInput$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((searchText: string) => {
				this.isLoading = (searchText && (searchText.length >= this.MINIMUM_SEARCH_TEXT_CHARACTERS));
				this.contactsFound = [];
			});

		this.contactsSearchInput$
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				switchMap(async (searchText: string) => {
					if (this.isLoading) {
						try {
							return await this.investorCrmContactListService.searchContacts(
								this.organizationId,
								this.contactListId,
								{ q: searchText }
							).toPromise();
						} catch (err) {
							// error
						}
					}
					return [];
				}),
				takeUntil(this.destroyed$),
			)
			.subscribe((contactsFound: ContactListContact[]) => {
				this.contactsFound = contactsFound;
				this.isLoading = false;
			});
	}

	setSelectedContact(): void {
		if (this.selectedContact?.added) {
			this.selectedContact = null;
		}

		this.contactSelected.next(this.selectedContact);

		this.contactsFound = [];
	}
}