import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';
import { AccountIdentifier } from 'shared/account/company-information/account-information.service';

export interface TotalVolume {
    units: number;
    volume: number;
}

export interface List {
    name: string;
    nmlsId: number | string;
    units: number | string;
    volume: number | string;
}

export interface NqmStatus {
    isNqm: boolean;
}

declare let apiPath;

@Injectable()
export class CommonProductionService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {}

    getTotal(nmlsId: (number | string)): Observable<TotalVolume> {
        return this.http.request<TotalVolume>(
            'GET',
            `${apiPath}/company/${nmlsId}/production/total-volume`,
        );
    }

    getTopBranches(nmlsId: (number | string)): Observable<List[]> {
        return this.http.request<List[]>(
            'GET',
            `${apiPath}/company/${nmlsId}/production/top-branches`,
        );
    }

    getTopLoanOfficers(nmlsId: (number | string)): Observable<List[]> {
        return this.http.request<List[]>(
            'GET',
            `${apiPath}/company/${nmlsId}/production/top-loan-officers`,
        );
    }

    getProductionLocation(nmlsId: (number | string), identifier: AccountIdentifier,): Observable<TotalVolume> {
        return this.http.request<TotalVolume>(
            'GET',
            `${apiPath}/tpos/company/locations/${nmlsId}/production`,
            { ...identifier },
        );
    }

    getNqmStatus(nmlsId: (number | string)): Observable<NqmStatus> {
        return this.http.request<NqmStatus>(
            'GET',
            `${apiPath}/company/${nmlsId}/production/nqm`,
        );
    }
}
