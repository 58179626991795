import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { sortBy, keys } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { SupportingDocumentEditModalComponent } from './document-modal';
import { DocumentsServiceInterface } from './documents.service.interface';

export abstract class SupportingDocumentsComponent extends ListComponent {
	User: any;
	list: any = [];
	modalRef: BsModalRef;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public modalService: BsModalService,
		public documentService: DocumentsServiceInterface,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;

		this.configureList();
	}

	configureList() {
		const idParams = this.getIdParams();

		this.defaultFilters = {
			...idParams,
		};

		this.omitParams = [
			...this.omitParams,
			...keys(idParams),
		];
	}

	abstract getIdParams(): { [key: string]: any };

	addDocument(): void {
		const { documentService } = this;
		const initialState = {
			document: {},
			params: this.getIdParams(),
			documentService,
			onSuccess: (document) => {
				this.list.push(document);
				this.list = sortBy(this.list, ['name']);
				this.list.$resolved = true;
			},
		};
		this.modalRef = this.modalService.show(SupportingDocumentEditModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.documentService.documents.list(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = sortBy(data, ['name']);
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	reloadList() {
		this.loadList(this.params);
	}
}
