<div class="questionnaires-list">
	<div class="gray-head flex-row">
		<h2 class="flex">Manage Questionnaires</h2>
		<ul class="tab-menu align-self-bottom">
			<li
				*ngFor="let tab of tabs"
				uiSrefActive="active"
			>
				<a uiSref="{{tab.state}}">
					<b>{{tab.title}}</b>
				</a>
			</li>
		</ul>
	</div>

	<div class="from-bank remote-resource" [class.resolved]="!params?.categoryId || list.$resolved">
		<div class="row">
			<div class="col-md-2">
				<div class="text-muted">Question Category</div>
			</div>
		</div>
		<div class="row-fluid" >
			<div class="col-md-2 va-m" [class.curtain]="editId || isCreateFormOpened">
				<ng-select
					[ngModel]="params.categoryId"
					(change)="setFilter({ categoryId: $event })"
					[items]="filters?.categoryId.$promise | async"
					[loading]="!filters?.categoryId.$resolved"
					[searchable]="true"
					[clearable]="false"
					[multiple]="false"
					placeholder="Select Category"
					bindValue="id"
					bindLabel="name"
				></ng-select>
			</div>
			<div class="col-md-2 va-m" [class.curtain]="editId || isCreateFormOpened">
				<a uiSref=".manage-categories" *ngIf="User.can('MANAGE_GLOBAL_QUESTIONNAIRE')">Manage Categories</a>
			</div>
			<div class="col-md-8 va-m">
				<div
					class="flex-row right"
					*ngIf="User.can('MANAGE_GLOBAL_QUESTIONNAIRE')"
					[class.curtain]="editId || isCreateFormOpened"
				>
					<button type="button" class="btn btn-primary" (click)="isCreateFormOpened = true">
						Add Question
					</button>
				</div>
			</div>
		</div>

		<div class="questionnaire-list" *ngIf="isCreateFormOpened">
			<div class="question-container edit remote-resource" [class.resolved]="!questionIsSaving">
				<questionnaire-question-edit
					class="flex"
					[question]="getNewQuestion()"
					[isBankQuestion]="true"
					[isNew]="true"
					[categories]="filters?.categoryId || []"
					(update)="create({}, $event)"
					(discard)="isCreateFormOpened = false"
				></questionnaire-question-edit>
			</div>
		</div>

		<div *ngIf="!params?.categoryId">
			<hr class="questions-list" />
			<h3>Select a category to see Questions</h3>
		</div>

		<div class="questionnaire-list">
			<div class="row-fluid" *ngIf="list.$resolved && !list.length">
				<span class="col-sm-12 text-muted text-center text-bigger">No records added</span>
			</div>

			<div
				*ngFor="let item of list"
				class="question-container flex-row align-top gap"
				[class.curtain]="isCreateFormOpened || (editId && editId !== item.id)"
				[class.edit]="editId === item.id"
			>
				<questionnaire-list-item
					class="flex"
					[item]="item"
					[isBankQuestion]="true"
					[showInUseLink]="true"
					[categories]="filters?.categoryId || []"
					[closeOnUpdateCall]="false"
					[updateError]="updateError"
					(edit)="toggleEdit(item, $event)"
					(update)="update(item, $event)"
					(remove)="remove(item)"
					(showInUse)="showUsage(item)"
				></questionnaire-list-item>
			</div>
		</div>
	</div>
</div>
