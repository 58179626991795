import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';
import { LandingPageConfig } from 'shared/social-media/landing-page/landing-page.component';

import businessIcon from '~static/images/material/two-tone/business_black_24dp.svg';
import tpIcon from '~static/images/material/two-tone/3p_black_24dp.svg';
import inventoryIcon from '~static/images/material/two-tone/inventory_black_24dp.svg';

@Component({
    templateUrl: './social-accounts.component.html',
})
export class SocialAccountsComponent {
    User: any;
    newMenu: boolean;
    tabs: any[] = [
        {
            title: 'Company',
            url: '.company',
            params: {
                linkListType: 'social',
            },
        },
        {
            title: 'Individuals',
            url: '.individuals',
            params: {},
        },
        {
            title: 'Inventory',
            url: '.inventory',
            params: {},
            permission: 'TPO_SMC_VIEW_SOCIAL_INVENTORY',
        },
        // {
        // 	title: 'Audit Log',
        // 	url: 'social-accounts.audit-log',
        // },
    ];
    landingItems: LandingPageConfig[] = [];

    constructor(
        public userService: UserService,
        public stateService: StateService,
    ) {
        this.User = userService.profile;
        this.newMenu = this.User.isTpo;
        if (this.newMenu) {
            this.landingItems = [
                {
                    icon: businessIcon,
                    isMaterial: true,
                    title: 'Company Accounts',
                    sref: '.company',
                    button: 'Connect',
                },
                {
                    icon: tpIcon,
                    isMaterial: true,
                    title: 'Individual Accounts',
                    sref: '.individuals',
                    button: 'View',
                },
                {
                    icon: inventoryIcon,
                    isMaterial: true,
                    disabled: !this.User.package.isInventoryVisible,
                    title: 'Social Inventory',
                    sref: '.inventory',
                    button: 'Inventory',
                },
            ];
        }
    }

    getParams({ params }) {
        return {
            ...params,
            q: null,
            page: null,
            sort: null,
            size: null,
        };
    }
}
