<div class="non-qm">
	<div class="gray-head">
		<h2>Non-QM</h2>
	</div>

	<div *ngIf="uploadStatus" class="text-center alert" [ngClass]="uploadStatus.class">{{uploadStatus.message}}</div>

	<div [class.remote-resource]="attachment" class="remote-resource" [class.resolved]="resolved">
		<uploader (select)="onPick($event)"></uploader>
	</div>

    <br/>

    <div class="remote-resource" [class.resolved]="!resolving">
        <div class="rows-bordered">
            <div class="row-fluid header">
                <div class="col-md-3">
                    <b class="two-liners">File</b>
                </div>
                <div class="col-md-3">
                    <b class="two-liners">Uploaded By</b>
                </div>
                <div class="col-md-3">
                    <b class="two-liners">Uploaded Date</b>
                </div>
                <div class="col-md-3">
                    <b class="two-liners">Upload Status</b>
                </div>
            </div>
            <div *ngIf="!resolving && ((list | async)?.length > 0); else noRecord">
                <div class="row-fluid table-row" *ngFor="let item of list | async">
                    <div class="col-md-3">
                        <span class="control-value">{{item.fileName || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-3">
                        <span class="control-value">{{item.uploadedBy || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-3">
                        <span class="control-value">{{item.uploadedDate | date : 'MM/dd/yyyy' || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-3">
                        <span class="control-value">{{item.fileStatus || '&ndash;'}}</span>
                    </div>
                </div>
            </div>
            <ng-template #noRecord>
                <span class="col-md-12 text-muted text-center text-bigger text-weak">No records added</span>
            </ng-template>
            <br>
            <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
        </div>
    </div>
</div>
