import { Injectable } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/core';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationStateService extends StateService {
    constructor(
        router: UIRouter,
        private notificationsService: GlobalNotificationsService,
    ) {
        super(router);
    }

    notify = function (to, { notification: { message = null, type: rawType = null, timeout = 5000 } = {}, ...toParams }, options) {
        if (message) {
            let type = GlobalNotificationType.INFO;
            switch (rawType) {
                case 'alert-success':
                    type = GlobalNotificationType.POSITIVE;
                    break;
                case 'alert-warning':
                    type = GlobalNotificationType.NEGATIVE;
                    break;
            }

            this.notificationsService.add({ message, type, timeout });
        }

        this.go(to, toParams, options);
    };
}
