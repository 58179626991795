<ng-container [ngTemplateOutlet]="customTemplate || defaultTemplate"></ng-container>

<ng-template #defaultTemplate>
	<div class="uploader-container flex-row center gap" (fileDrop)="select.emit($event)">
		<label class="btn btn-secondary">
			Upload File
			<input type="file" (input)="onFileInput($event)" [multiple]="config.multiple" [accept]="config.accept">
		</label>
		<span class="text-muted">or Drag and Drop here</span>
	</div>
</ng-template>
