<div class="template-update-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body min-300 remote-resource" [class.resolved]="!resolving">
		<form-errors [form]="templateForm"></form-errors>
		<form [formGroup]="templateForm" id="templateForm" (onValidSubmit)="onConfirm(this.templateForm.get('action').value)">
			<p>There are unpublished posts for this template.</p>
			<div class="radio-holder">
				<label class="text-normal radio">
					<input type="radio" [value]="true" formControlName="action" required />
					Apply template changes to unpublished posts
				</label>
				<label class="text-normal radio">
					<input type="radio" [value]="false" formControlName="action" required />
					Cancel unpublished posts which use previous template version
				</label>
			</div>
		</form>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button type="submit" form="templateForm" class="btn btn-primary">Save</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>
