import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import {  CompareFieldsValidator } from 'commons/validators';

import { ChannelsService, StatusReason } from 'lender/clients/$id/channels/channels.service';

@Component({
    templateUrl: './status-reason.modal.html',
})
export class ChangeStatusReasonModal implements OnInit {
    resolving: boolean = false;
    channelId: number;
    tpoId: number;
    status: StatusReason;
    isNew = false;
    statusReasons: StatusReason[] = [];
    statusForm = new RealmFormGroup({
        statusReason: new RealmFormControl(
            'statusReason',
            {
                label: 'Optional Status',
            },
        ),
    });
    newStatusControls = {
        newStatusName: new RealmFormControl(
            'newStatusName',
            {
                label: 'Create New Reason',
                updateOn: 'change',
            },
            [
                Validators.required,
            ],
        ),
        confirmNewStatusName: new RealmFormControl(
            'confirmNewStatusName',
            {
                label: 'Confirm New Reason',
                updateOn: 'change',
            },
            [
                Validators.required,
            ],
        ),
    };
    newStatusForm = new RealmFormGroup(
        this.newStatusControls,
        CompareFieldsValidator(this.newStatusControls.newStatusName, this.newStatusControls.confirmNewStatusName)
    );

    form = this.statusForm;

    onUpdate: (newStatus: StatusReason) => void;

    constructor(
        readonly modalRef: BsModalRef,
        public channelsService: ChannelsService,
    ) {}

    toggleIsNewMode(): void {
        this.isNew = !this.isNew;
        this.toggleForm();
    }

    toggleForm(): void {
        this.form = this.isNew ? this.newStatusForm : this.statusForm;
    }

    addStatus(reason: string) {
        return { reason };
    }

    async ngOnInit() {
        this.statusReasons = await this.channelsService.getStatusReasons().toPromise();
        this.form.reset({
            statusReason: this.status,
        });
    }

    async save(){
        if (!this.isNew && this.form.pristine) {
            this.modalRef.hide();
            return;
        }

        let { statusReason }: { statusReason: StatusReason } = this.form.value;
        try {
            this.resolving = true;

            statusReason = await this.channelsService.setStatusReason(
                this.tpoId,
                this.channelId,
                this.isNew ? { reason: this.newStatusForm.get('newStatusName').value } : statusReason,
            ).toPromise();

            this.onUpdate(statusReason);

            this.modalRef.hide();
        } catch ({ error }) {
            this.form.setServerError(error);
            this.resolving = false;
        }
    }
}
