import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './details.modal.component.html',
})
export class FindingAuditLogDetailsModalComponent {
	entry: any;

	constructor(
		public modalRef: BsModalRef,
	) {
	}
}
