<div class="limits text-muted va-m">
	<div *ngFor="let item of accounts | arrayUniqueBy: 'networkCode'">
		<div (click)="scrollToElement(item.networkCode)"
			*ngxInit="networksConfig[item.networkCode].signLimit - textLength as limit"
			[ngClass]="{'red': limit < 0 }">
			<span class="icon-alert" *ngIf="limit < 0"></span>
			{{networksConfig[item.networkCode].title}}: {{limit}}
		</div>
	</div>
</div>
