import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { combineLatest } from 'rxjs';

import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ChannelsSummary } from 'lender/clients/channels/channels-summary.interface';
import { Transitions } from 'lender/clients/clients.interface';

import { MapUtils } from 'utils/map-utils';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { ChannelCardService } from 'lender/clients/channels/channel-card.service';


@Component({
    templateUrl: './channel-card-list.component.html',
    host: {
        '[class]': '"remote-resource"',
    },
})
export class ChannelCardListComponent implements OnInit {
    // Hostbinding seems to be necessary for the container of this component to be resolved
    @HostBinding('class.resolved') resolved = false;
    @Input() lenderInfo: AccountInformation;

    readonly userProfile: UserProfile;
    readonly canInvite: boolean;
    readonly investorId: number;

    investorIsRegistered: boolean = false;

    channels: ChannelsSummary[] = [];
    channelsByIds: Map<number, ChannelsSummary>;

    transitions: Transitions;

    constructor(
        private readonly channelCardService: ChannelCardService,
        private readonly userService: UserService,
    ) {
        this.userProfile = this.userService.profile;
        this.canInvite = this.userProfile.can('INVITE_TO_APPLY');
        this.investorId = this.userProfile.organization.id;
    }

    ngOnInit(): void {
        this.investorIsRegistered = this.lenderInfo.isRegistered;

        this.loadChannels();
    }

    // Left in in case we ever need to notify other child components of the channel changing
    // async updateLocalChannel(channel: ChannelsSummary): Promise<void> {
    //     if (this.channelsByIds.has(channel.channelId)) {
    //         this.channelsByIds.set(channel.channelId, channel);
    //     }
    // }

    protected async loadChannels(): Promise<void> {
        try {
            this.resolved = false;

            [ this.channels, this.transitions ] = await combineLatest([
                this.channelCardService.getChannels(this.lenderInfo, this.investorId),
                this.channelCardService.getStatusTransitions(),
            ]).toPromise();

            this.channelsByIds = MapUtils.mapByObjectKey(this.channels, 'channelId');
        } finally {
            this.resolved = true;
        }
    }
}
