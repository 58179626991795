import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { QUESTION_TYPE, TpoQuestionnaireQuestion, TpoQuestionnaireService } from 'tpo/questionnaire/questionnaire.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { AbstractControl, Validators } from '@angular/forms';
import { distinctUntilChanged, filter, map, skip, startWith, tap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { DecimalPipe } from '@angular/common';
import moment from 'moment';
import { DropdownRequiredValidator } from 'commons/validators';
import { Subject } from 'rxjs';

@Component({
    selector: 'single-question',
    templateUrl: './question.component.html',
    host: {
        'class': 'remote-resource',
    },
})
export class TpoQuestionnaireQuestionComponent implements OnInit {
    @Input() @HostBinding('class.row') show = true;
    @HostBinding('class.has-error') protected hasError = false;
    @HostBinding('class.resolved') protected resolved = true;
    @HostBinding('class.group') protected group = false;
    @Input() question: TpoQuestionnaireQuestion;
    @Input() questionnaireId: number;
    @Input() shouldSendData = true;
    @Output() protected change = new EventEmitter<void>();
    @Output() protected submit = new EventEmitter<void>();
    @Output() protected saved = new EventEmitter<void>();
    private value$ = new Subject();

    protected QUESTION_TYPE = QUESTION_TYPE;
    protected form: RealmFormGroup = new RealmFormGroup({
        explanation: new RealmFormControl(
            'explanation',
            {},
            Validators.required,
        ),
    });
    protected answerField: AbstractControl;
    protected explanationField = this.form.controls.explanation;

    protected set explanationRequired(required: boolean) {
        if (required) {
            this.explanationField.enable();
        } else {
            this.explanationField.disable();
        }
        this.form.updateValueAndValidity();
    }

    protected get explanationRequired(): boolean {
        return this.explanationField.enabled;
    }

    constructor(
        private questionnaireService: TpoQuestionnaireService,
    ) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        const { answers, questionType, group } = this.question;
        if (group) {
            this.group = group;
            return;
        }
        const blurTypes = [QUESTION_TYPE.FREE_FORM, QUESTION_TYPE.NUMBER, QUESTION_TYPE.DOLLAR, QUESTION_TYPE.PERCENT];
        const answerField = new RealmFormControl(
            'answer',
            blurTypes.includes(questionType) ? { updateOn: 'blur' } : { updateOn: 'change' },
            DropdownRequiredValidator,
        );
        this.form.addControl(answerField.fieldName, answerField);
        this.answerField = this.form.controls.answer;
        this.answerField.valueChanges.subscribe(answer => {
            const isMultiple = !!answer && typeof answer === 'object'; // typeof array === object
            const selectedAnswers = answers.filter(({ text }) => isMultiple ? answer.indexOf(text) > -1 : answer === text);
            this.explanationRequired = selectedAnswers.reduce((result, { explanationRequired }) => (result || explanationRequired), false);
        });
        this.form.valueChanges.pipe(
            distinctUntilChanged(isEqual),
            skip(1),
        ).subscribe(this.value$);
        this.value$.pipe(
            map(() => {
                const answer = this.getFormattedAnswer();
                const { answer: _a, ...formRest } = this.form.value;
                return { answer, ...formRest };
            }),
            tap(() => this.hasError = !this.form.valid),
            distinctUntilChanged(isEqual),
            filter(() => this.form.valid),
        ).subscribe((formData) => {
            this.save(formData);
        });
        this.reset();

        // this.form.statusChanges.subscribe(() => this.form.updateValueAndValidity({ emitEvent: false }));
    }

    private get selectedAnswer(): string | string[] {
        const { answer, questionType } = this.question;

        switch (questionType) {
            case QUESTION_TYPE.MULTISELECT:
                return answer ? (answer || '').split(', ') : [];
            case QUESTION_TYPE.NUMBER:
            case QUESTION_TYPE.DOLLAR:
            case QUESTION_TYPE.PERCENT:
                return (answer || '').replace(/[, $%]/g, '');
            case QUESTION_TYPE.FREE_FORM:
                return (answer || '');
        }

        return answer;
    }

    protected getFormattedAnswer(): string {
        const answer = this.answerField.value;
        const decimalPipe = new DecimalPipe('en-US');

        switch (this.question.questionType) {
            case QUESTION_TYPE.MULTISELECT:
                return answer ? answer.join(', ') : [];
            case QUESTION_TYPE.FREE_FORM:
                return (answer || '');
            case QUESTION_TYPE.NUMBER:
                return decimalPipe.transform(answer, '1.0-3');
            case QUESTION_TYPE.DOLLAR:
                return '$' + decimalPipe.transform(answer, '1.0-2');
            case QUESTION_TYPE.PERCENT:
                return decimalPipe.transform(answer, '1.0-3') + '%';
            case QUESTION_TYPE.DATE:
                return moment(answer).format('MM/DD/YYYY');
        }

        return answer;
    }

    protected async save(formData): Promise<void> {
        const { id } = this.question;
        this.resolved = false;
        try {
            let question = { ...this.question, ...formData };
            Object.assign(this.question, question);
            this.change.emit();
            if (this.shouldSendData) {
                question = await this.questionnaireService.setQuestionAnswer(this.questionnaireId, id, question).toPromise();
                Object.assign(this.question, question);
                this.change.emit();
            }
            this.saved.emit();
        } catch (e) {
        }
        this.resolved = true;
    }

    protected setMultiAnswer(answer, { target }: Event): void {
        const { checked } = target as HTMLInputElement;
        const { value: answers } = this.answerField;
        if (checked) {
            return this.answerField.setValue([answer, ...answers]);
        }
        return this.answerField.setValue((answers as []).filter(value => value !== answer));
    }

    get valid() {
        return !this.show || this.question.group || this.form.valid;
    }

    reset(): void {
        const { explanation } = this.question;
        this.form.reset({ answer: this.selectedAnswer, explanation });
    }

    update(): void {
        if (!this.group && this.show && this.form.invalid) {
            this.value$.next(this.form.value);
        }
    }
}
