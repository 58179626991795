<div class="gray-head flex-head">
    <div class="col-fit">
        <h2 class="title-label">
            <span>{{ headerText }}</span>
        </h2>
    </div>
    <div *ngIf="!isNew && resolved" class="col-min">
        <ul class="tab-menu col-min">
            <li uiSrefActive="active">
                <a uiSref="^.details">
                    <b>Details</b>
                </a>
            </li>
            <li uiSrefActive="active">
                <a uiSref="^.contacts">
                    <b>Contacts</b>
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="lender-crm-contact-new-list">
	<form [id]="formName"
        [name]="formName"
        [formGroup]="listForm"
        class="remote-resource"
        [class.resolved]="resolved"
        (onValidSubmit)="submit()"
    >
        <investor-crm-contact-list-general-information
            *ngIf="resolved"
            [userCanManageContactLists]="userCanManageContactLists"
            [listForm]="listForm"
            [isNew]="isNew"
            [isCloning]="isCloning"
            [editing]="editing"
            [availableUsersResolved]="availableUsersResolved"
            [availableUsers]="availableUsers"
            [existingContactList]="existingContactList"
            (editingChange)="changeEdit($event)"
            (deleteListModal)="showDeleteListModal()"
        >
        </investor-crm-contact-list-general-information>
		<!-- <div ng-include="'views/lender/crm/contact/lists/segment-filter/set.html'"></div> -->
        <investor-crm-contact-list-criteria
            [criteriaResolved]="criteriaResolved"
            [listForm]="listForm"
            [isNew]="isNew"
            [editing]="editing"
            [orClauseManager]="orClauseManager"
            [criteria]="criteria"
        >
        </investor-crm-contact-list-criteria>
        <section *ngIf="isNew" class="clearfix">
			<div class="pull-right">
                <!-- TODO -->
				<!-- <button type="button" class="btn btn-link ng-hide" ng-show="preview.previewButton" ng-click="preview.showList()">
					Preview Results
				</button> -->
                <!-- TODO -->
				<!-- <button type="button" class="btn btn-link ng-hide" ng-show="preview.refreshButton" ng-click="preview.refreshList()">
					<span class="glyphicon glyphicon-repeat"></span>
					Refresh Results
				</button> -->
			</div>
		</section>
        <investor-crm-contact-list-visibility-list
            *ngIf="!editing && !isCloning && existingContactList?.designatedOption?.enabled && availableUsers"
            [contactList]="existingContactList"
            [availableUsers]="availableUsers"
        >
        </investor-crm-contact-list-visibility-list>
    </form>
</div>
