<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving"
		(click)="cancel()">&times;</button>
	<h4 class="modal-title">Create New Code</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<form-errors [form]="appcodeForm"></form-errors>
	<form [formGroup]="appcodeForm" id="appcodeForm" (onValidSubmit)="save()">
		<div class="row">
			<div class="form-group" has-error>
				<label class="control-label col-sm-3 text-right required">Number of Uses</label>
				<div class="col-sm-3">
					<input type="text"
						name="numberOfUses"
						formControlName="numberOfUses"
						class="form-control"
						maxlength="9"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-a">
				<div class="form-group" has-error>
					<label class="control-label col-sm-3 text-right required">Start Date</label>
					<div class="col-sm-3">
						<input type="text"
							autocomplete="off"
							name="startDate"
							formControlName="startDate"
							class="form-control"
							bsDatepicker
							[bsConfig]="bsConfig"
							placeholder="mm/dd/yyyy"
							[minDate]="today"
							(bsValueChange)="validateDates()"
						>
					</div>
				</div>
			</div>
			<div class="col-sm-a">
				<div class="form-group clearfix" has-error>
					<label class="control-label col-sm-2 text-right required">Expires</label>
					<div class="col-sm-3">
						<input type="text"
							autocomplete="off"
							name="expirationDate"
							formControlName="expirationDate"
							class="form-control"
							bsDatepicker
							[bsConfig]="bsConfig"
							placeholder="mm/dd/yyyy"
							[minDate]="today"
							(bsValueChange)="validateDates()"
						>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="form-group" has-error>
				<label class="control-label col-sm-3 text-right required">Description</label>
				<div class="col-sm-8">
					<textarea
						class="col-sm-12 resize-vertical"
						name="description"
						formControlName="description"
						maxlength="255"
					></textarea>
				</div>
			</div>
		</div>
	</form>

</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="text-right">
		<button type="submit" form="appcodeForm" class="btn btn-primary">Create</button>
		<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
	</div>
</div>
