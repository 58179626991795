import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

import { FinancialDocumentResourceService } from 'commons/services/financial-documents-resource.service';
import {
    ClientFinancialSettings, ClientFinancialSettingsData,
    FinancialNotificationRequest,
    TPOFinancialDocument,
    TPOFinancialDocumentField,
    TPOFinancialDocumentType,
    TPO_FINANCIAL_DOCUMENT_TYPE_MAP,
} from 'commons/services/financial-documents.interface';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { TpoFinancialDocumentUploadModalComponent } from './tpo-financial-document-upload-modal/tpo-financial-document-upload-modal.component';

import { RequestFinancialDocumentModalComponent } from 'shared/account/financials/request-document-modal/request-document-modal.component';
import {
    ClientFinancialSettingsModalComponent,
} from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-item/client-financial-settings-modal';


@Component({
    selector: 'tpo-financial-document-item',
    templateUrl: './tpo-financial-document-item.component.html',
})
export class TpoFinancialDocumentItemComponent implements OnInit {
    @Input() tpoId: number;
    @Input() investorId: number;
    @Input() tpoFinancialDocument: TPOFinancialDocument;
    @Input() documentFields: TPOFinancialDocumentField[];
    @Input() canUpload = false;
    @Input() isOnGoing?: boolean;
    @Output() onDocumentUpdated: EventEmitter<void> = new EventEmitter();

    User: UserProfile;
    statusClassNames: string[] = [ 'status' ];
    resolving: boolean;
    modalRef: BsModalRef;
    hasRequestPermission: boolean = false;
    hasAuditedStatus: boolean = false;
    auditedText: string = '';
    documentCanBeReviewed: boolean;

    constructor(
        private readonly userService: UserService,
        public readonly modalService: BsModalService,
        private readonly financialDocumentResourceService: FinancialDocumentResourceService,
    ) {
    }

    // Override
	ngOnInit(): void {
        if ('REQUESTED' == this.tpoFinancialDocument.financialDocumentState) {
            this.statusClassNames.push('status-red');
        } else if ('UPLOADED' == this.tpoFinancialDocument.financialDocumentState) {
            this.statusClassNames.push('status-green');
        }

        this.hasRequestPermission = this.userService.profile.can('REQUEST_FINANCIALS');
        this.User = this.userService.profile;

        if (this.tpoFinancialDocument?.audited === true) {
            this.hasAuditedStatus = true;
            this.auditedText = 'Audited';
        } else if (this.tpoFinancialDocument?.audited === false) {
            this.hasAuditedStatus = true;
            this.auditedText = 'Unaudited';
        }

        this.documentCanBeReviewed = this.investorId && this.hasRequestPermission && this.tpoFinancialDocument.financialDocumentId && this.tpoFinancialDocument.uploaded;
    }

    openUploadFileModal(): void {
        this.modalRef = this.modalService.show(TpoFinancialDocumentUploadModalComponent, {
            class: 'modal-vlg modal-new',
            initialState: {
                tpoId: this.tpoId,
                type: this.tpoFinancialDocument.type,
                documentFields: this.documentFields,
                onUploaded: (uploadedDocument) => this.onDocumentUpdated.emit(),
            },
        });
    }

    getLink(): string {
        return `/api/rest/lenders/${this.investorId}/tpos/${this.tpoId}/financial-documents/${this.tpoFinancialDocument.type}/uploadedDocument`;
    }

    async markAsReviewed() {
        this.resolving = true;
        try {
            await this.financialDocumentResourceService.markDocumentReviewed(this.investorId,this.tpoId,this.tpoFinancialDocument.financialDocumentId).toPromise();
            this.onDocumentUpdated.emit();
        } finally {
            this.resolving = false;
        }
    }

    async request(): Promise<void> {
        this.resolving = true;
        try {
            this.modalRef = this.modalService.show(
                RequestFinancialDocumentModalComponent,
                {
                    initialState: {
                        title: this.tpoFinancialDocument.name,
                        document: this.tpoFinancialDocument,
                        // getTpoUsers: this.financialDocumentResourceService.getTpoNotificationUsers,
                        // getLenderUsers: this.financialDocumentResourceService.getLenderNotificationUsers,
                        getResourceParams: () => ({
                            lenderId: this.investorId,
                            tpoId: this.tpoId,
                            documentId: this.tpoFinancialDocument.financialDocumentId,
                        }),
                        onRequest: async (data: FinancialNotificationRequest) => {
                            try {
                                await this.financialDocumentResourceService.requestTpoDocument(
                                    this.tpoId,
                                    this.investorId,
                                    this.tpoFinancialDocument.type,
                                    { ...data },
                                ).toPromise();
                                this.onDocumentUpdated.emit();
                                this.modalRef.content.saving = false;
                                this.modalRef.hide();
                            } catch (e) {
                                this.modalRef.content.saving = false;
                                if (e.status === 403) {
                                    this.modalRef.hide();

                                    throw e;
                                } else {
                                    this.modalRef.content.assignForm.setServerError(e.error || e);
                                }
                            }
                        },
                    },
                    backdrop: 'static',
                    class: 'modal-new modal-smd',
                },
            );
        } catch (e) {
            this.resolving = false;
        } finally {
            this.resolving = false;
        }
    }

    openClientFinancialSettings() {
        const params: [TPOFinancialDocumentType, number, number] = [
            this.tpoFinancialDocument.type,
            this.investorId,
            this.tpoId,
        ];
        this.modalRef = this.modalService.show(ClientFinancialSettingsModalComponent, {
            class: 'modal-new modal-md',
            initialState: {
                title: TPO_FINANCIAL_DOCUMENT_TYPE_MAP[this.tpoFinancialDocument.type],
                settings$: this.financialDocumentResourceService.getClientFinancialSettings(...params).pipe(
                    finalize(() => this.modalRef.content.resolving = false),
                ),
                options$: this.financialDocumentResourceService.getClientFinancialSettingOptions(...params),
                onSave: async (clientChannel: ClientFinancialSettingsData): Promise<ClientFinancialSettings> => {
                    this.modalRef.content.resolving = true;

                    try {
                       return await this.financialDocumentResourceService.updateClientFinancialSettings(
                           ...params,
                           clientChannel,
                       ).toPromise()
                    } catch (e) {
                        console.error(e);
                        // error
                    } finally {
                        this.modalRef.content.resolving = false;
                    }
                },
            },
        });
    }
}
