export const statusConfig = {
    ACTIVE: {
        color: 'success',
        title: 'Active',
    },
    SUSPENDED: {
        color: 'default',
        title: 'Suspended',
    },
    INACTIVE: {
        color: 'danger',
        title: 'Inactive',
    },
};
