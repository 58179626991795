<div class="trigger-history-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm && onConfirm(); modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body remote-resource flex-row align-top" [class.resolved]="!resolving">
		<ng-container *ngIf="history">
			<div class="flex">
				<b>From:</b>
				<br><br>
				{{history.oldValue}}
			</div>
			<div class="flex">
				<b>To:</b>
				<br><br>
				{{history.newValue}}
			</div>
		</ng-container>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div>
			<button type="button" (click)="onConfirm && onConfirm(); modalRef.hide()" class="btn btn-primary">{{confirmText}}</button>
		</div>
	</div>
</div>