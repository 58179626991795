import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { OwnerContactInformation, ScorecardSummaryOwner } from 'shared/due-diligence/scorecard/scorecard-summary-owners/scorecard.interface';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import { ScorecardUtilityService } from '../services/scorecard-utility.service';
import { UserService } from 'angularjs-providers/user.provider';
import { KeyedScorecardFindingCategories, ScorecardFindingCategories } from '../scorecard-finding-category.interface';
import { ScorecardMarkReviewedService } from 'shared/due-diligence/scorecard/services/scorecard-mark-reviewed-service';


@Component({
    templateUrl: 'scorecard-summary-owner.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardSummaryOwnerComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardSummaryOwnerComponent) },
    ],
})
export class ScorecardSummaryOwnerComponent extends NewPagedListComponent<ScorecardSummaryOwner> implements OnInit {
    ownerId: number;
    tpoId: number;
    @Input() public lenderInfo: AccountInformation;
    ownerInformation: OwnerContactInformation;
    isUserCommergence: boolean;
    scorecardFindingCategories: ScorecardFindingCategories;
    keyedScorecardFindingCategories: KeyedScorecardFindingCategories;
    hasReviewFindingPermission: boolean;
    sref: string;
    isUserLender: boolean;
    lenderId: number;
    hasEditAndViewAlertPermission: boolean;

    configurationDataIsLoading: boolean = false;
    markingFindingAsReviewed: boolean = false;

    constructor(
        router: UIRouter,
        public routerGlobals: UIRouterGlobals,
        private scorecardService: ScorecardService,
        private readonly scorecardUtilityService: ScorecardUtilityService,
        private readonly userService: UserService,
        private readonly scorecardMarkReviewedService: ScorecardMarkReviewedService,
    )
    {
      super(router);
    }

    async ngOnInit() {
        super.ngOnInit();
        this.ownerId = this.routerGlobals.params.ownerId;
        this.tpoId = this.lenderInfo.identifier.tpoId;
        this.isUserCommergence = this.userService.profile.isComergence;
        this.hasReviewFindingPermission = this.userService.profile.can('REVIEW_FINDINGS');
        this.lenderId = this.userService.profile.organization.id;
        this.isUserLender = this.userService.profile.isLender;
        this.hasEditAndViewAlertPermission = this.scorecardMarkReviewedService.checkAlertPermission(this.userService);

        if(this.isUserCommergence) {
            this.sref = 'tpo.:id.scorecard.finding.:sType.:sid';
        }
        else {
            this.sref = 'client.:id.reviews.scorecard.finding.:sType.:sid'
        }
        this.configurationDataIsLoading = true;
        [
            this.ownerInformation,
            this.scorecardFindingCategories,
        ] = await Promise.all([
            this.scorecardService.getOwnerContactInformation(this.ownerId, this.tpoId).toPromise(),
            this.scorecardService.getScorecardCategories(this.tpoId).toPromise(),
        ]);
        this.configurationDataIsLoading = false;

        this.keyedScorecardFindingCategories = this.scorecardUtilityService.keyCategoriesByCode(this.scorecardFindingCategories);
    }

    protected loadList(params: ListParams) : Observable<PagedData<ScorecardSummaryOwner[]>> {
        return this.scorecardService.getScorecardSummaryOwnerFindings(params, this.tpoId, this.ownerId);
    }

    reloadList() {
        this.updateList();
    }

    setMarkingReviewed(markingFindingAsReviewed: boolean) {
        this.markingFindingAsReviewed = markingFindingAsReviewed;
    }
}
