<div class="questionnaire-tabs">
	<div class="gray-head flex-head">
		<back-bread class="flex-grow" [opts]="{headerClass: 'title-label'}" [ngSwitch]="action" [filter]="[ '**.:questionnaireId.**' ]">
			<span *ngSwitchCase="actions.EDIT">{{ editing ? 'Edit Questionnaire' : (item?.name || '&nbsp;') }}</span>
			<span *ngSwitchCase="actions.CLONE">Clone Questionnaire</span>
			<span *ngSwitchCase="actions.NEW">Create Questionnaire</span>
			<span *ngSwitchDefault>{{item?.name || '&nbsp;'}}</span>
		</back-bread>
		<div class="col-min" *ngIf="(!action || action === actions.EDIT) && !editing">
			<ul class="tab-menu">
				<li *ngFor="let tab of tabs" uiSrefActive="active">
					<a [uiSref]="tab.sref" [uiParams]="{ action: tab.action, questionnaireId: questionnaireId }">
						<b>{{tab.title}}</b>
					</a>
				</li>
			</ul>
		</div>
	</div>

	<div ui-view></div>
</div>
