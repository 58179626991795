import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { SharedProspectingModule } from 'shared/prospecting/prospecting.module';
import { LocationsService } from 'shared/account/locations/locations.service';
import { LocationsListComponent } from 'shared/account/locations/locations-list.component';
import { LocationHeaderComponent } from 'shared/account/locations/single/header.component';
import { LocationOriginatorsComponent } from 'shared/account/locations/single/originators/originators.component';
import { LocationDbaComponent } from 'shared/account/locations/single/dba/dba.component';
import { SharedDbaModule } from 'shared/account/dba/dba.module';
import { LocationBranchManagerListComponent } from 'shared/account/locations/single/originators/branch-managers/branch-manager-list.component';
import { LocationOriginatorListComponent } from 'shared/account/locations/single/originators/originators/originators-list.component';
import { LocationLicensesComponent } from 'shared/account/locations/single/licenses/licenses.component';
import { SharedLicensesModule } from 'shared/account/licenses';
import { CommonProductionModule } from 'shared/production/production.module';
import { LocationsChannelsComponent } from './single/channels/locations-channels.component';
import { SingleLocationsChannelComponent } from './single/channels/single-locations-channel.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        FormsModule,
        PipesModule,
        SharedLicensesModule,
        SharedDbaModule,
        SharedProspectingModule,
        CommonProductionModule,
        NgSelectModule,
        ReactiveFormsModule,
    ],
    declarations: [
        LocationsListComponent,
        LocationHeaderComponent,
        LocationOriginatorsComponent,
        LocationBranchManagerListComponent,
        LocationOriginatorListComponent,
        LocationLicensesComponent,
        LocationDbaComponent,
        LocationsChannelsComponent,
        SingleLocationsChannelComponent,
    ],
    providers: [
        LocationsService,
    ],
})
export class SharedLocationsModule {
}
