import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

@Component({
	templateUrl: './audit-status-modal.component.html',
})
export class AuditStatusModalComponent implements OnInit {
	User: UserProfile
	createMode: boolean;
	form: RealmFormGroup;
	review: any;
	statuses: any;
	getStatuses: () => any;
	update: (status: any) => Promise<any>;
	create: (status: string, condition: string) => Promise<any>;
	resolved = true;

	get statusControl(): RealmFormControl {
		return this.form.get('status') as RealmFormControl;
	}

	get statusControlPlaceholder(): string {
		return `Select ${this.statusControl.label}`;
	}

	get conditionControl(): RealmFormControl {
		return this.form.get('condition') as RealmFormControl;
	}

	constructor(
		public modalRef: BsModalRef,
		{ profile: User }: UserService,
	) {
		this.User = User;
	}

	ngOnInit(): void {
		this.statuses = this.getStatuses();
		this.initForm();
		this.toggleCreateMode(false);
	}

	initForm(): void {
		this.form = new RealmFormGroup({});
	}

	toggleCreateMode(
		createMode = !this.createMode
	): void {
		this.createMode = createMode;

		if (createMode) {
			this.form.removeControl('status');
			this.form.addControl(
				'status',
				new RealmFormControl(
					'status',
					{
						label: 'New Status Name',
						updateOn: 'change',
					},
					Validators.required,
				)
			);
			this.form.addControl(
				'condition',
				new RealmFormControl(
					'condition',
					{
						label: 'Condition',
						updateOn: 'change',
					},
					DropdownRequiredValidator,
				)
			);
		} else {
			this.form.removeControl('status');
			this.form.removeControl('condition');
			this.form.addControl(
				'status',
				new RealmFormControl(
					'status',
					{
						label: 'Audit Status',
						value: this.review.auditStatus,
						updateOn: 'change',
					},
					DropdownRequiredValidator,
				)
			);
		}
	}

	submit(): void {
		const { status } = this.form.value;

		this.resolved = false;
		if (this.createMode) {
			const { condition } = this.form.value;
			this.create(status, condition)
				.then(
					(status) => {
						this.statuses = this.getStatuses();
						this.toggleCreateMode(false);
						this.form.patchValue({
							status,
						});
					},
					({ data }) => {
						this.form.setServerError(data);
					}
				)
				.finally(() => {
					this.resolved = true;
				})
		} else {
			this.update(status)
				.then(
					() => {
						this.close();
					},
					({ data }) => {
						this.form.setServerError(data);
					}
				)
				.finally(() => {
					this.resolved = true;
				})
		}
	}

	close(): void {
		this.modalRef.hide();
	}
}
