import { Component, Input } from '@angular/core';

import { StateService, UIRouter } from '@uirouter/core';

import {
    AccountExecutive,
    AccountExecutiveDetails,
    ChannelsSummary,
    Status,
    StatusReason,
} from 'lender/clients/channels/channels-summary.interface';
import { Availability, Transitions } from 'lender/clients/clients.interface';
import { ChannelCardService } from 'lender/clients/channels/channel-card.service';
import { cloneDeep } from 'lodash';
import { ConfirmModalComponent } from 'commons/components/modals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { DotMenuItem } from 'commons/components/navigation/dots-menu/dot-menu.items';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ChannelInfo } from 'lender/clients/$id/channels/channels.service';
import { ChannelsOtherActionsConfig, ActionConfig } from 'lender/channels/channel-other-actions-config.interface';
import { UserProfile } from 'angularjs-providers/user.provider';

import { CancelInviteConfig, CancelInviteModalService } from 'commons/services/modal/cancel-invite-modal.service';
import { ChannelsOtherActionsService } from 'lender/channels/channel-other-actions.service';
import { PurchaseRelationshipModalService } from 'commons/services/modal/purchase-relationship-modal.service';
import { RenewalApplicationModalService } from 'commons/services/modal/renewal-application-modal.service';
import { InvestorUserRelationshipService } from 'commons/services/user-capabilities/investor-user/investor-user-relationship.service';
import { SimpleShowAnimation } from 'commons/animation/simpleShowAnimation';
import { ChannelEstablishRSModalComponent } from 'lender/clients/$id/channels/single/details/establish-modal/establish.modal';


export type EligibilityDatePseudoPatch = {
    eligibilityDate: string;
};

@Component({
    templateUrl: 'channel-card.component.html',
    selector: 'channel-summary-card',
    animations: [
        SimpleShowAnimation,
    ],
})
export class ChannelCardComponent {
    @Input() tpoId: number;
    @Input() channel: ChannelsSummary;
    @Input() investorIsRegistered: boolean = false;
    @Input() lenderInfo: AccountInformation;
    @Input() totalChannels: number;
    @Input() statusTransitions: Transitions;
    @Input() userProfile: UserProfile;

    private readonly purchaseConfig: ActionConfig = {
        click: () => this.showPurchaseModal(),
    };
    private readonly renewalConfig: ActionConfig = {
        click: () => this.showRenewalModal(),
    };
    private readonly cancelInviteConfig: ActionConfig = {
        click: () => this.showCancelInviteModal(),
    };

    investorId: number;
    accountExecutives: AccountExecutiveDetails[];
    availableStatuses: Availability[];
    resolved: boolean = true;
    readonly tomorrowDate: Date;

    private _showContent: boolean = false;
    set showContent(show: boolean) {
        this._showContent = show;
    }

    get showContent(): boolean {
        return this._showContent;
    }

    private _hasEditPermission: boolean = false;
    get hasEditPermission(): boolean {
        return this._hasEditPermission;
    }

    set hasEditPermission(hasEdit: boolean) {
        this._hasEditPermission = hasEdit;
    }

    get canEdit(): boolean {
        return this.hasEditPermission && this.showContent && this.channel.accessible;
    }

    get canEditApprovalStatus(): boolean {
        return this.canEdit && this.investorUserRelationshipService.relationshipIsEditable(this.channel.approvalStatus);
    }

    get canEditRenewalDate(): boolean {
        return this.canEdit && this.investorUserRelationshipService.relationshipIsActive(this.channel.approvalStatus);
    }

    get canEditEligibilityDate(): boolean {
        return this.hasEditPermission && this.channel.eligibilityDateEditable;
    }

    editingStatus: boolean = false;
    editingRenewalDate: boolean = false;
    editingAccountExecutive: boolean = false;

    newApprovalStatus: Status;
    newRenewalDate: Date;
    newAccountExecutive: AccountExecutiveDetails;

    clientId: number;
    channelMenuItems: DotMenuItem[] = [];

    private modalRef: BsModalRef;

    constructor(
        private readonly cancelInviteModalService: CancelInviteModalService,
        private readonly channelCardService: ChannelCardService,
        private readonly channelsOtherActionsService: ChannelsOtherActionsService,
        private readonly purchaseRelationshipModalService: PurchaseRelationshipModalService,
        private readonly renewalApplicationModalService: RenewalApplicationModalService,
        private readonly router: UIRouter,
        private readonly stateService: StateService,
        private readonly modalService: BsModalService,
        private readonly investorUserRelationshipService: InvestorUserRelationshipService,
    ) {
        this.clientId = this.router.globals.params.id;

        this.tomorrowDate = new Date();
        this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1);
    }

    ngOnInit() {
        this.investorId = this.userProfile.organization.id;
        this.hasEditPermission = this.userProfile.can('EDIT_RELATIONSHIP_STATUS');

        if (this.totalChannels <= 2) {
            this.showContent = true;
            this.showCardContent();
        }

        this.initFromChannel(true);
    }

    cloneDeepAccountExecutive(accountExecutive: AccountExecutive): AccountExecutiveDetails {
        let unconvertedClone = cloneDeep(accountExecutive);
        return {
            id: unconvertedClone.id,
            fullName: unconvertedClone.name,
        };
    }

    showCardContent() {
        if (this.totalChannels > 2) {
            this.showContent = !this.showContent;
        }
        if (!this.accountExecutives) {
            this.getAccountExecutives();
        }
        if (!this.availableStatuses) {
            this.getAvailableStatuses();
        }
    }

    getAvailableStatuses() {
        if (this.channel.approvalStatus) {
            this.availableStatuses = this.statusTransitions[this.channel.approvalStatus.id].availability;
            let containsCurrentStatus = this.availableStatuses.some((status) => status.id === this.channel.approvalStatus.id);
            if (!containsCurrentStatus) {
                this.availableStatuses.push({ id: this.channel.approvalStatus.id, name: this.channel.approvalStatus.name });
            }
        }
    }

    async getAccountExecutives() {
        try {
            this.resolved = false;
            if (this.channel.accessible) {
                this.accountExecutives = await this.channelCardService.getAccountExecutives(this.channel.channelId).toPromise();
            }
        } finally {
            this.resolved = true;
        }

    }

    compareAccountExecutives(item: AccountExecutiveDetails, selected: AccountExecutive): boolean {
        return ((item.id == selected.id) && (item.fullName == selected.name));
    }

    getLabel(status: Status): string {
        if (!status) {
            return '';
        }
        switch (status.id) {
            case 'A':
                return 'label label-success text-large';
            case 'T':
            case 'C':
            case 'WD':
            case 'D':
                return 'label label-danger';
            case 'P':
            case 'PR':
                return 'label label-warning';
            default:
                return 'label label-default';
        }
    }

    async confirmAssigneeUpdate(): Promise<void> {
        await this.saveField('accountExecutive', this.newAccountExecutive);

        let resolved = false;
        const initialState: Partial<ConfirmModalComponent> = {
            title: 'Confirm to Proceed',
            confirmText: 'Yes',
            cancelText: 'No',
            message: `Would you like to make the same Account Assignment to all locations?`,
            onConfirm: async () => {
                const modal = this.modalRef.content;
                try {
                    modal.resolving = true;

                    await this.channelCardService.saveAccountExecutiveForAllLocations(
                        this.newAccountExecutive,
                        this.channel.channelId,
                        this.investorId,
                        this.tpoId,
                    ).toPromise();
                } catch ({ error: { message } }) {
                    modal.errorText = message;

                    return;
                } finally {
                    modal.resolving = false;
                }

                resolved = true;
                this.modalRef.hide();
            },
            onCancel: () => {
                resolved = true;
                this.modalRef.hide();
            },
        };
        this.modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });

        this.modalRef.onHide.subscribe(() => {
            if (resolved) {
                return;
            }
        });
    }

    showTerminateModal(): void {
        this.modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: {
                title: 'Confirm to Proceed',
                confirmText: 'Yes',
                cancelText: 'No',
                message: `Are you sure that you want to terminate relationship?`,
                onConfirm: () => {
                    this.saveField('approvalStatus', { id: 'T', name: 'Terminated' });
                    this.modalRef.hide();
                },
            },
            class: 'modal-smd modal-new',
        });
    }

    saveApprovalStatus(fieldValue: any): void {
        switch (fieldValue.id) {
            case 'T':
                this.showTerminateModal();
                break;
            default:
                this.saveField('approvalStatus', fieldValue);
        }
    }

    async saveField(fieldName: string, fieldValue: any): Promise<void> {
        try {
            this.resolved = false;

            this.channel = await this.channelCardService
                .saveChannelFields(this.channel.channelId, this.tpoId, this.investorId, fieldName, fieldValue)
                .toPromise();
            this.initFromChannel(false);

            switch (fieldName) {
                case 'approvalStatus':
                    if (!this.investorUserRelationshipService.relationshipIsActive(this.channel.approvalStatus)) {
                        this.editingRenewalDate = false;
                    }

                    this.editingStatus = false;
                    break;
                case 'approvalRenewalDate':
                    this.editingRenewalDate = false;
                    break;
                case 'accountExecutive':
                    this.editingAccountExecutive = false;
                    break;
            }
        } finally {
            this.resolved = true;
        }
    }

    async saveEligibilityDate(pseudoPatch: EligibilityDatePseudoPatch): Promise<void> {
        try {
            this.resolved = false;

            const updatedTPOChannelDetails: ChannelsSummary = await this.channelCardService.updateEligibilityDate(
                this.investorId,
                this.tpoId,
                this.channel.channelId,
                pseudoPatch.eligibilityDate,
            ).toPromise();

            this.initFromChannel(false, updatedTPOChannelDetails);
        } finally {
            this.resolved = true;
        }
    }

    saveChannelUID(popoverEvent: any) {
        this.saveField('channelUID', popoverEvent.channelUID);
    }

    formatDate(inputRenewalDate: Date): string {
        return this.channelCardService.formatDate(inputRenewalDate);
    }

    cancelStatus() {
        this.toggleEditingStatus();
    }

    toggleEditingStatus() {
        this.editingStatus = !this.editingStatus;
    }

    cancelRenewalDate() {
        this.toggleEditingRenewalDate();
    }

    toggleEditingRenewalDate() {
        this.editingRenewalDate = !this.editingRenewalDate;
    }

    cancelAccountExecutive() {
        this.toggleEditingAccountExecutive();
    }

    toggleEditingAccountExecutive() {
        this.editingAccountExecutive = !this.editingAccountExecutive;
    }

    async saveStatusReason(statusReason: StatusReason): Promise<void> {
        try {
            this.resolved = false;

            this.channel.statusReason = await this.channelCardService.saveStatusReason(this.channel.channelId, this.tpoId, statusReason).toPromise();

            await this.reloadChannel();
        } finally {
            this.resolved = true;
        }
    }

    showEstablishRSModal(): void {
        const { channelId } = this.channel;
        const { tpoId, investorId } = this;
        const initialState: Partial<ChannelEstablishRSModalComponent> = {
            channelId,
            tpoId,
            investorId,
            success: (channel: ChannelsSummary) => {
                this.getAvailableStatuses();
                this.getAccountExecutives();
                this.initFromChannel(true, channel);
            },
        };

        this.modalRef = this.modalService.show(ChannelEstablishRSModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    showRenewalModal(): void {
        this.modalRef = this.renewalApplicationModalService.showRenewalModal(
            this.channel.channelId,
            this.lenderInfo.identifier.tpoId,
            () => this.stateService.reload(),
            // TODO: Why do we reload the state here instead of just reload the channel?
            // Dmytro Khavilo: Probably because you also need to update badges in the side menu.
        );
    }

    showPurchaseModal(): void {
        this.modalRef = this.purchaseRelationshipModalService.showPurchaseModal(
            this.channel.channelId,
            this.lenderInfo.identifier.tpoId,
            (channelInfo: ChannelInfo) => this.reloadChannel(),
        );
    }

    showCancelInviteModal(): void {
        const config: CancelInviteConfig = {
            investorId: this.userProfile.organization.id,
            channelId: this.channel.channelId,
            channelName: this.channel.channelName,
            tpoId: this.lenderInfo.identifier.tpoId,
            tpoName: this.lenderInfo.companyName,
            handleHttpErrorResponse: true,
            navigateBack: false,
            resolving: (resolving: boolean) => this.modalRef.content.resolving = resolving,
            finally: () => this.reloadChannel(),
        };
        this.modalRef = this.cancelInviteModalService.showCancelInviteModal(config);
    }

    async reloadChannel(): Promise<void> {
        try {
            this.resolved = false;

            const updatedChannel: ChannelsSummary = await this.channelCardService.getChannel(
                this.lenderInfo.identifier,
                this.investorId,
                this.channel.channelId,
            ).toPromise();

            this.initFromChannel(false, updatedChannel);
        } finally {
            this.resolved = true;
        }
    }

    initFromChannel(skipOptionUpdate: boolean, updatedTPOChannelDetails?: ChannelsSummary): void {
        if (!!updatedTPOChannelDetails) {
            Object.assign(this.channel, updatedTPOChannelDetails);
        }

        this.newAccountExecutive = this.cloneDeepAccountExecutive(this.channel.accountExecutive);
        this.newApprovalStatus = cloneDeep(this.channel.approvalStatus);
        this.newRenewalDate = cloneDeep(this.channel.approvalRenewalDate);

        if (!skipOptionUpdate) {
            this.getAvailableStatuses();
        }

        this.buildMenuItems();
    }

    private buildMenuItems(): void {
        const otherActionsMenuConfig: ChannelsOtherActionsConfig = {
            tpoId: this.lenderInfo.identifier.tpoId,
            tpoNmlsId: this.lenderInfo.nmlsId,
            channelId: this.channel.channelId,
            userCanAccessChannel: this.channel.accessible,
            userCanPurchaseChannel: this.channel.isPurchasable,
            channelIsInvitable: this.channel.invitable,
            userProfile: this.userProfile,
            inviteStatus: this.channel.inviteStatus,
            relationshipStatus: this.channel.approvalStatus,
            investorIsRegistered: this.investorIsRegistered,
            purchaseConfig: this.purchaseConfig,
            renewalConfig: this.renewalConfig,
            cancelInviteConfig: this.cancelInviteConfig,
        };
        this.channelMenuItems = this.channelsOtherActionsService.createChannelMenuItems(otherActionsMenuConfig);
    }
}
