<section class="remote-resource" [class.resolved]="alertEvents">
    <h2>Events</h2>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-4">
                <b>Parameter</b>
            </span>
            <span class="col-sm-4">
                <b>Before</b>
            </span>
            <span class="col-sm-4">
                <b>After</b>
            </span>
        </div>

        <div *ngFor="let alertEvent of alertEvents" class="row-fluid">
            <span class="col-sm-4">
                <label class="control-label">{{ alertEvent.columnName }}</label>
            </span>
            <span class="col-sm-4">
                {{ alertEvent.oldValue || '&ndash;' }}
            </span>
            <span class="col-sm-4">
                {{ alertEvent.newValue || '&ndash;' }}
            </span>
        </div>

        <div *ngIf="!alertEvents || !alertEvents.length" class="row-fluid">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Events</span>
        </div>
    </div>
</section>
