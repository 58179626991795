<div class="company-listing-groups">
	<div class="gray-head">
		<h2>Company Listings</h2>
	</div>

	<div class="remote-resource" [class.resolved]="list.$resolved && !connecting">
		<div class="filters-rows">
			<div class="row-fluid filter-labels">
				<div class="col-sm-3">
					<span class="text-muted">Search</span>
				</div>
				<div class="col-sm-a"></div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="col-sm-3">
					<search-list placeholder="Address, Name, NMLS"></search-list>
				</div>
				<div class="col-sm-a"></div>
				<div class="col-sm-3 text-right" *ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')">
					<a class="btn btn-primary" (click)="connect()"><span class="glyphicon glyphicon-plus"></span> Connect Listings</a>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<div class="col-sm-12">Group</div>
			</div>

			<a
				*ngFor="let item of list"
				class="row-fluid clickable"
				[class.discrepancy-highlight]="item.hasDiscrepancy"
				uiSref=".:listingGroupId"
				[uiParams]="{ listingGroupId: item.id }"
			>
				<div class="col-sm-9 va-m">
					<div class="flex-row gap-m">
						<div class="list-item-image" [bgImage]="item.coverImage"></div>
						<div class="flex text-muted">
							<div *ngIf="item.name">{{item.name}}</div>
							<div *ngIf="item.nmlsId">NMLS ID: {{item.nmlsId}}</div>
							<div>{{item | address}}</div>
							<div>{{item.phoneNumber | tel}}</div>
							<div>{{item.website}}</div>
						</div>
					</div>
				</div>
				<div class="col-sm-3 va-m">
					<div class="flex-row right gap">
						<sm-network-icon
							[config]="{ networkCode: item.removedFromNmls ? 'NMLS*' : 'NMLS' }"
							[className]="!item.networks['NMLS'] && 'icon-lighter-gray'"
							[minified]="true"
						></sm-network-icon>

						<sm-networks-connection
							[networks]="networksIndication"
							[networksConnection]="item.networks"
						></sm-networks-connection>
					</div>
				</div>
			</a>

			<div class="row-fluid" *ngIf="!list.length">
				<div class="col-sm-12 text-center text-bigger text-muted va-m">No listings found</div>
			</div>
		</div>

		<br/>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[100]"></new-paging>
	</div>
</div>
