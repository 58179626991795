import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';

import { TpoContactsService } from '../contacts.service';
import { of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
    templateUrl: './tpo-contact-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpoContactDetailsComponent extends SharedContactDetailsComponent {
    public accountSref = 'account.information';

    constructor(
        { profile }: UserService,
        { params }: UIRouterGlobals,
        protected contactsService: TpoContactsService,
    ) {
        super();
        const { contactId = null } = params;
        const { name, organizationId } = profile.organization;
        this.user = profile;
        this.contactsService.getContact(contactId).subscribe(contact => this.setContact(contact));

        this.company = of({ name, organizationId }).pipe(
            shareReplay(1),
        );
    }
}
