import { noop } from 'lodash';
import { Injectable } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { PagedListComponent } from './paged-list.component';

@Injectable()
export abstract class InfiniteScrollForPagedResourceComponent extends PagedListComponent {
	endpoint: (params?) => any = noop;
	resolveCount: number = 0;
	shouldResetList: boolean = false;
	lastPageReached: boolean = false;

	list: any = null;

	constructor(transitionService: TransitionService, stateService: StateService) {
		super(transitionService, stateService);

		// do not reflect page in the url for now, since it breaks infinite scroll logic
		this.omitLocationParams.push('page');
	}

	loadList(queryParams) {
		// RESET list if search happen or some filter applied,
		// TODO: think about sort
		this.shouldResetList = this.resolveCount && (this.params$.getValue().page === 0);

		this.endpoint(this.getClearParams(queryParams))
			.$promise.then((result) => {
				this.list = result;

				this.resolveCount++;
			});
	}

	loadNextPage() {
		const page = (this.params$.getValue().page || 0) + 1;

		this.lastPageReached = page >= this.list.$pagesTotal;

		if (this.lastPageReached) {
			return;
		}

		this.params$.next({...this.params$.getValue(), page });
	}
}
