<div class="publisher remote-resource" [ngClass]="{'resolved': review.$resolved && nmlsIds.$resolved && availableImages.$resolved}">
	<div class="flex-head">
		<ul class="breadcrumbs">
			<li>
				<a (click)="back()">
					<span class="glyphicon glyphicon-chevron-left"></span>
					Back
				</a>
			</li>
		</ul>
		<h2>{{review?.companyName}}</h2>
	</div>
	<br>
	<div class="clearfix">
		<div class="btn-group pull-left" role="group">
			<button (click)="changeViewMode()" class="btn" [ngClass]="{'btn-primary': !editing, 'btn-default': editing}" type="button">Preview</button>
			<button (click)="changeViewMode()" class="btn" [ngClass]="{'btn-primary': editing, 'btn-default': !editing}" type="button">Edit</button>
		</div>
		<div class="pull-right" *ngIf="(User?.can('TPO_PB_REVIEW_PUBLISH') === true) || (User?.contactId === review?.contactId)">
			<button type="button" class="btn btn-primary" (click)="post()">
				<span class="c-icon c-icon-paper-plane"></span>
				Post
			</button>
		</div>
	</div>
	<section class="review-details" [ngClass]="{'editing': editing}">
		<header class="row">
			<div class="col-md-6">
				<div class="field"
					 *ngIf="review.reviewerName"
					 [ngClass]="{'disabled': review.disabledSections?.reviewerName, 'hidden': review.disabledSections?.reviewerName && !editing}"
					 [ngSwitch]="review.disabledSections?.reviewerName">

					<span *ngSwitchCase="false">
						<span class="text-muted">Reviewed by</span> {{review.reviewerName}} <span class="glyphicon glyphicon-remove field-toggle" (click)="toogleSection('reviewerName')"></span>
					</span>
					<span *ngSwitchCase="true" (click)="toogleSection('reviewerName')" class="field-toggle">
						<span class="text-muted">
							<span class="glyphicon glyphicon-plus"></span>&nbsp;
							borrower {{review.reviewerName}}
						</span>
					</span>
				</div>
			</div>
			<div class="col-md-6 text-right nmlsId" [ngSwitch]="editing">
				<span *ngSwitchCase="false" class="text-muted">
					NMLS ID: {{review.nmlsId || '&ndash;'}}
				</span>
				<span *ngSwitchCase="true" class="text-muted">
					<ng-select
						[(ngModel)]="review.nmlsId"
						[items]="nmlsIds"
						[searchable]="false"
						[clearable]="false"
						appendTo=".nmlsId">
							<ng-template ng-label-tmp let-item="item">
								<span class="text-muted">
									NMLS ID: {{item}}
								</span>
							</ng-template>
					</ng-select>
				</span>
			</div>
		</header>
		<div class="review-content text-center">
			<div class="image-holder" *ngIf="selectedImgId" [style.background-image]="'/api/rest/tpos/'+ tpoId +'/external-reviews/images/'+ selectedImgId +'/image-file' | urlSanitizer">
				<span (click)="showModal(lenderLogoModal)" *ngIf="editing" class="glyphicon glyphicon-pencil"></span>
			</div>

			<div [ngSwitch]="editing" class="score">
				<h3 *ngSwitchCase="false">{{score?.parameter}}</h3>
				<span *ngSwitchCase="true" class="text-left">
					<ng-select
						[(ngModel)]="score"
						[items]="review?.scores"
						bindLabel="parameter"
						[searchable]="false"
						[clearable]="false"
						appendTo=".score span">
							<ng-template ng-label-tmp let-item="item">
								 <h3>{{item.parameter}}</h3>
							</ng-template>
					</ng-select>
				</span>
			</div>

			<reviews rating="{{score?.score}}"></reviews>

			<div class="field loNmlsId" [ngClass]="{'disabled': review.disabledSections?.loNmlsId, 'hidden': review.disabledSections?.loNmlsId && !editing}"
				 						[ngSwitch]="review.disabledSections?.loNmlsId" *ngIf="review.loNmlsId">
				<span *ngSwitchCase="false">
					{{review.loanOriginatorFullName}}, NMLS ID: {{review.loNmlsId}} <span class="glyphicon glyphicon-remove field-toggle" (click)="toogleSection('loNmlsId')"></span>
				</span>
				<span *ngSwitchCase="true" (click)="toogleSection('loNmlsId')" class="field-toggle text-muted">
					<span class="glyphicon glyphicon-plus"></span>&nbsp; {{review.loanOriginatorFullName}}, NMLS ID: {{review.loNmlsId}}
				</span>
			</div>
		</div>
		<footer class="row">
			<div class="col-md-6">
				<img *ngIf="review.reviewId"
					 srcTest="/api/rest/tpos/{{tpoId}}/external-reviews/{{review.reviewId}}/data-provider-logo">
			</div>
			<div class="col-md-6 text-right">
				<img src="/static/images/comergence-nopad.svg" alt="Optimal Blue">
			</div>
		</footer>
	</section><!--end:review-details-->
</div>

<ng-template #lenderLogoModal>
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">Select Image</h4>
	</div>
	<div class="modal-body review-lender-logos">
		<div *ngFor="let item of availableImages"
			 [class.selected]="item.id == selectedImgId"
			 [style.background-image]="'/api/rest/tpos/'+ tpoId +'/external-reviews/images/'+ item.id +'/image-file' | urlSanitizer"
			 (click)="selectImg(item.id)"
		>
		</div>
	</div>
</ng-template>
