import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { LandingPageComponent } from 'shared/social-media/landing-page/landing-page.component';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

import reviewsIcon from '~static/images/material/two-tone/reviews_black_24dp.svg';
import rateReviewIcon from '~static/images/material/two-tone/rate_review_black_24dp.svg';
import widgetIcon from '~static/images/material/two-tone/widgets_black_24dp.svg';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: '../../../shared/social-media/landing-page/landing-page.component.html',
})
export class ReputationComponent extends LandingPageComponent {
    title = 'Reputation';

    constructor(
        userService: UserService,
        modalService: BsModalService,
        stateService: StateService,
    ) {
        super(modalService, stateService, userService);
        this.items = [
            {
                icon: reviewsIcon,
                isMaterial: true,
                title: 'Reviews',
                sref: 'reviews.list',
                button: 'View',
            },
            {
                icon: rateReviewIcon,
                isMaterial: true,
                title: 'Settings',
                sref: 'reviews.settings.accounts',
                button: 'View',
            },
            {
                icon: widgetIcon,
                isMaterial: true,
                title: 'Widgets',
                sref: 'reviews.widgets',
                button: 'Manage',
            },
        ];
    }

    get enabled(): boolean {
        return this.userService.profile.hasFeature(PACKAGE_FEATURE.REPUTATION_MANAGEMENT);
    }
}
