import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
    templateUrl: 'user-management-header.component.html',
    styles: ['.gray-head.tab-menu {margin: 0;padding: 0;list-style: none;white-space: nowrap; color: #808080}',
        '.b {font-weight: bold; color: black}',
    ],
})
export class UserManagementHeaderComponent {

    constructor(
        public stateService: StateService,
    ) {
        this.stateService = stateService;
    }
}
