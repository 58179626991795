import { Injectable } from '@angular/core';
import { RealmHttpClient, RealmParams } from 'commons/services/http';
import { Observable } from 'rxjs';
import { UnderwritingInterface } from 'tpo/account/underwriting/underwriting.interface';

declare let apiPath;

@Injectable()
export class UnderwritingService {

    constructor(private http: RealmHttpClient) {

    }

    getServices(tpoId: number): Observable<UnderwritingInterface[]> {
        const tpoIdParam: RealmParams = { tpoId: tpoId };
        return this.http.request(
            'GET',
            `${apiPath}/underwriting`,
            tpoIdParam
        );
    }

    createServices(tpoId: number, newService: UnderwritingInterface): Observable<UnderwritingInterface> {
        const tpoIdParam: RealmParams = { tpoId: tpoId };
        return this.http.request(
            'POST',
            `${apiPath}/underwriting`,
            tpoIdParam,
            newService
        );
    }

    updateServices(tpoId: number, newService: UnderwritingInterface, id: number): Observable<UnderwritingInterface> {
        const tpoIdParam: RealmParams = { tpoId: tpoId };
        return this.http.request(
            'PUT',
            `${apiPath}/underwriting/${id}`,
            tpoIdParam,
            newService
        );
    }
}
