import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    Audit,
    AuditReview,
    AuditStatus,
    BulkedAuditUpdate,
    SelectedAuditStatus
} from '../../audits.service.interface';
import { AuditsService } from '../../audits.service';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from '../../../../../commons/forms';

@Component({
    templateUrl: './audit-status-modal.component.html',
})
export class BulkAuditStatusModalComponent implements OnInit {
    audits: Audit[] = [];
    auditStatuses: AuditStatus[] = [];
    tpoId: number;
    callbackOnSave: () => void;

    resolved: boolean = false;
    selectedAuditStatuses: SelectedAuditStatus[] = [];

    auditStatusControls: RealmFormArray = new RealmFormArray([]);
    form: RealmFormGroup = new RealmFormGroup({
        auditStatusControls: this.auditStatusControls,
    });

    constructor(
        private modalRef: BsModalRef,
        private auditsService: AuditsService,
    ) {}

    async ngOnInit() {
        this.resolved = true;
        this.audits.forEach( (audit: Audit) => {
            if(!this.selectedAuditStatuses.find( (selectedAuditStatus: SelectedAuditStatus) => selectedAuditStatus.status.status === audit.auditStatus)) {
                let status = this.auditStatuses.find( (auditStatus: AuditStatus) => auditStatus.status === audit.auditStatus);
                let selectedStatus = {
                    selectedNumber: 1,
                    status: status,
                    oldStatusName: status.status,
                    isCompleted: status.condition === 'C',
                };
                this.selectedAuditStatuses.push(selectedStatus);
            } else {
                this.selectedAuditStatuses.find( (selectedAuditStatus: SelectedAuditStatus) => selectedAuditStatus.status.status === audit.auditStatus).selectedNumber++;
            }
        });

        this.selectedAuditStatuses.forEach( (auditStatus: SelectedAuditStatus) => {
            this.createAuditStatusControl(auditStatus);
        });
    }

    createAuditStatusControl(auditStatus: SelectedAuditStatus) {
        let newControl = new RealmFormGroup({
            selectedNumber: new RealmFormControl(
                'selectedNumber',
                {
                    label: 'Selected',
                },
            ),
            status: new RealmFormControl(
                'status',
                {
                    label: 'Status',
                    disabled: auditStatus.isCompleted,
                },
            ),
            oldStatusName: new RealmFormControl(
                'oldStatusName',
                {
                    label: 'Old Status Name',
                },
            ),
        });
        newControl.patchValue(auditStatus);
        this.auditStatusControls.push(newControl);
    }

    close(): void {
        this.modalRef.hide();
    }

    async submit() {
        const changedStatusesFromForm = this.form.get('auditStatusControls').value;
        let bulkedAuditUpdates: BulkedAuditUpdate[] = [];
        this.audits.forEach( (audit: Audit) => {
            const selectedStatus = changedStatusesFromForm.find( (status: SelectedAuditStatus) => status.oldStatusName === audit.auditStatus);
            if(selectedStatus.status) {
                let bulkedAuditUpdate: BulkedAuditUpdate = {
                    auditId: audit.id,
                    auditStatusId: selectedStatus.status.id,
                };
                bulkedAuditUpdates.push(bulkedAuditUpdate);
            }
        });
        try {
            this.resolved = false;
            await this.auditsService.bulkUpdateAuditStatus(bulkedAuditUpdates, this.tpoId).toPromise();
        } finally {
            this.resolved = true;
            this.callbackOnSave();
            this.modalRef.hide();
        }
    }
}
