<ng-container *ngFor="let detail of detailRow.items">
    <ng-container *ngIf="detail">
        <span class="col-sm-3 text-muted text-right">
            {{ detail.label }}
        </span>
        <span class="col-sm-3 text-left">
            <span *ngIf="!detail.isArray">{{ detail.value }}</span>
            <ul *ngIf="detail.isArray" class="list-unstyled">
                <li *ngFor="let value of detail.value">{{ value }}</li>
            </ul>
        </span>
    </ng-container>
</ng-container>