<h3>Audit Log</h3>

<div class="remote-resource" [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row-fluid filter-labels">
            <div class="col-sm-2" *ngIf="filters.type">
                <span class="text-muted small">Type</span>
            </div>
            <div class="col-sm-2">
                <span class="text-muted small">Start Date</span>
            </div>
            <div class="col-sm-2">
                <span class="text-muted small">End Date</span>
            </div>
            <div class="col-sm-a"></div>
        </div>
        <div class="row-fluid filter-selectors">
            <div *ngIf="filters.type" class="col-sm-2">
                <ng-select
                    [ngModel]="params.type"
                    [items]="filters.type!"
                    (change)="setFilter({ type: $event.value })"
                    [clearable]="false"
                    bindValue="value"
                    bindLabel="title"
                    placeholder="All Types"
                ></ng-select>
            </div>
            <div class="col-sm-2">
                <input
                    type="text"
                    class="form-control"
                    bsDatepicker
                    [bsValue]="params.dateFrom"
                    [maxDate]="params.dateTo"
                    placeholder="mm/dd/yyyy"
                    (bsValueChange)="setFilter({dateFrom: $event})"
                />
            </div>
            <div class="col-sm-2">
                <input
                    type="text"
                    class="form-control"
                    bsDatepicker
                    [bsValue]="params.dateTo"
                    [minDate]="params.dateFrom"
                    placeholder="mm/dd/yyyy"
                    (bsValueChange)="setFilter({dateTo: $event})"
                />
            </div>
            <div class="col-sm-a"></div>
        </div>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-4"><b>Date</b></span>
            <span class="col-sm-4"><b>Action</b></span>
            <span class="col-sm-4"><b>Action By</b></span>
        </div>
        <div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
            <span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
        </div>
        <div class="row-fluid" *ngFor="let item of list | async">
            <span class="col-sm-4">{{item.actionAt | date : 'MM/dd/yyyy HH:mm'}}</span>
            <span class="col-sm-4">
			<ul class="list-unstyled">
				<li *ngFor="let info of item.actions; let isLast = last">{{info}}{{isLast ? '' : ', '}}</li>
			</ul>
		</span>
            <span class="col-sm-4">{{item.actionBy}}</span>
        </div>
        <div class="row-fluid" *ngIf="!resolving && noMatches">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
        </div>

        <div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !noMatches">
            <span class="col-sm-12 text-muted text-center text-bigger">No records added</span>
        </div>
    </div>

    <br/>

    <paginator [sizes]="[10,25,50]"></paginator>
</div>
