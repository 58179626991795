<div [ngSwitch]="hasViewAllPermission" class="monitoring-board">
	<div *ngSwitchCase="true" class="remote-resource" [class.resolved]="findings?.$resolved">
		<div class="flex-head">
			<h2>Findings <smHelpLink [url]="helpLink" *ngIf="user?.isTpo"></smHelpLink></h2>
			<div *ngIf="openFindingsCount?.$resolved" class="col-min middle text-nowrap">
				<span class="text-muted">Open Findings:</span>
				{{ openFindingsCount?.value | number }}
			</div>
		</div>
		<div>
			<div class="filters-rows">
				<div class="row-fluid filter-labels">
					<div class="col-sm-a">
						<div class="text-muted">Search</div>
					</div>
					<div class="col-sm-a">
						<div class="text-muted">Status</div>
					</div>
					<div class="col-sm-a">
						<div class="text-muted">Account type</div>
					</div>
					<div class="col-sm-a">
						<div class="text-muted">Source</div>
					</div>
					<div class="col-sm-a"></div>
				</div>
				<div class="row-fluid filter-selectors">
					<div class="col-sm-a">
						<search-list placeholder="ID or Name"></search-list>
					</div>
					<div class="col-sm-a">
						<ng-select
							[(ngModel)]="params.status"
							(change)="setFilter({ status: $event })"
							[items]="filters.status.$promise | async"
							[loading]="!filters.status.$resolved"
							bindValue="id"
							bindLabel="name"
							[multiple]="true"
							[clearable]="true"
							[closeOnSelect]="false"
							placeholder="All Statuses">
						</ng-select>
					</div>
					<div class="col-sm-a">
						<ng-select
							[(ngModel)]="params.accountType"
							(change)="setFilter({ accountType: $event })"
							[items]="filters.accountType.$promise | async"
							[loading]="!filters.accountType.$resolved"
							bindValue="id"
							bindLabel="name"
							[multiple]="true"
							[clearable]="true"
							[closeOnSelect]="false"
							placeholder="All Account Types">
						</ng-select>
					</div>
					<div class="col-sm-a">
						<ng-select
							[(ngModel)]="params.source"
							(change)="setFilter({ source: $event })"
							[items]="filters.source"
							bindValue="id"
							bindLabel="label"
							[multiple]="true"
							[clearable]="true"
							[closeOnSelect]="false"
							placeholder="All Sources">
						</ng-select>
					</div>
					<div class="col-sm-a">
						<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
					</div>
				</div>
				<div class="row-fluid filter-labels" *ngIf="moreFilters">
					<div *ngIf="moreFilters" class="col-sm-a">
						<div class="text-muted">Finding Date After</div>
					</div>
					<div *ngIf="moreFilters" class="col-sm-a">
						<div class="text-muted">Finding Date Before</div>
					</div>
					<div *ngIf="moreFilters" class="col-sm-a">
						<div class="text-muted">Entity</div>
					</div>
					<div *ngIf="moreFilters" class="col-sm-a">
						<div class="text-muted">State</div>
					</div>
					<div *ngIf="moreFilters" class="col-sm-a">
						<div class="text-muted">Branch</div>
					</div>
				</div>
				<div class="row-fluid filter-selectors" *ngIf="moreFilters">
					<div *ngIf="moreFilters" class="col-sm-a">
						<input type="text" class="form-control"
							   bsDatepicker
							   [bsConfig]="bsConfig"
							   [bsValue]="params.from"
							   [maxDate]="params.to"
							   (bsValueChange)="setFilter({from: $event})"
							   placeholder="mm/dd/yyyy">
					</div>
					<div *ngIf="moreFilters" class="col-sm-a">
						<input type="text" class="form-control"
							   bsDatepicker
							   [bsConfig]="bsConfig"
							   [bsValue]="params.to"
							   [minDate]="params.from"
							   (bsValueChange)="setFilter({to: $event})"
							   placeholder="mm/dd/yyyy">
					</div>
					<div *ngIf="moreFilters" class="col-sm-a">
						<ng-select
							[(ngModel)]="params.entity"
							(change)="setFilter({ entity: $event })"
							[items]="filters.entity.$promise | async"
							[loading]="!filters.entity.$resolved"
							bindValue="id"
							bindLabel="name"
							[clearable]="true"
							placeholder="All Entities">
						</ng-select>
					</div>
					<div *ngIf="moreFilters" class="col-sm-a" [class.curtain]="params?.entity !== 'individual'">
						<ng-select
							[(ngModel)]="params.state"
							(change)="setFilter({ state: $event })"
							[items]="filters.state.$promise | async"
							[loading]="params?.entity === 'individual' && !filters.state.$resolved"
							bindValue="shortName"
							bindLabel="name"
							[multiple]="true"
							[clearable]="true"
							[closeOnSelect]="false"
							placeholder="All States">
						</ng-select>
					</div>
					<div *ngIf="moreFilters" class="col-sm-a" [class.curtain]="!params?.state?.length">
						<ng-select
							[(ngModel)]="params.locationId"
							(change)="setFilter({ locationId: $event })"
							[items]="filters.locationId.$promise | async"
							[loading]="params?.state?.length && !filters.locationId.$resolved"
							bindValue="id"
							bindLabel="displayName"
							[multiple]="true"
							[clearable]="true"
							[closeOnSelect]="false"
							placeholder="All Branches">
						</ng-select>
					</div>
				</div>
			</div>
		</div>
		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-1" columnName="id">ID</sortable-header>
				<sortable-header class="col-sm-3" columnName="contactOrCompanyName">Name</sortable-header>
				<sortable-header class="col-sm-3" columnName="name">Account</sortable-header>
				<sortable-header class="col-sm-a" columnName="status">Status</sortable-header>
				<sortable-header class="col-sm-a" columnName="statusDate">Status Date</sortable-header>
				<sortable-header class="col-sm-a" columnName="source">Source</sortable-header>
			</div>
			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<span class="col-sm-12 text-center va-m">Filters are applied.
					<a (click)="resetFilters()">Reset filters</a> to see all results
				</span>
			</div>
			<a
				*ngFor="let item of findings"
				class="row-fluid clickable"
				uiSref="social-compliance.findings.:actionType.:findingId"
				[uiParams]="{ actionType: 'edit', findingId: item.id }"
			>
				<div class="col-sm-1 va-m">
					<a class="id-holder">{{item.id}}</a>
				</div>
				<div class="col-sm-3 va-m">
					<div *ngIf="item.isCompany">
						<span class="text-bigger">{{item.companyName || '&ndash;'}}</span>
						<span *ngIf="item.companyNmlsId">
							<br>
							<span class="text-muted">
								NMLS ID:
							</span>
							<span class="text-light">{{item.companyNmlsId}}</span>
						</span>
					</div>
					<div *ngIf="item.isCompany === false">
						<span class="text-bigger">{{item.contactName || '&ndash;'}}</span>
						<span *ngIf="item.contactNmlsId">
							<br>
							<span class="text-muted">
								NMLS ID:
							</span>
							<span class="text-light">{{item.contactNmlsId}}</span>
						</span>
					</div>
				</div>
				<div class="col-sm-3 va-m">
					<div class="flex-row gap">
						<div [class.light-gray]="!item?.mediaLink?.linkId && !item.mediaLink.link">
							<sm-icon [config]="item.mediaLink"></sm-icon>
						</div>
						<div class="pseudo-cell truncate">
							<span>{{item.mediaLink?.name}}</span>
						</div>
					</div>
				</div>
				<div class="col-sm-a text-bigger va-m">
					<span class="label mxw100 truncate" [ngClass]="statuses[item.condition]?.color">{{item.status}}</span>
				</div>
				<div class="col-sm-a va-m">
					{{item.statusDate || '&ndash;' | date : 'MM/dd/yyyy'}}
				</div>
				<div class="col-sm-a va-m">
                    <finding-source class="flex-row gap" [source]="item.source"></finding-source>
				</div>
			</a>

			<div class="row-fluid" *ngIf="findings.$resolved && !findings.length">
				<div class="col-sm-12 text-center text-light text-bigger">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No findings'}}
				</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="findings" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
	<div *ngSwitchCase="false" class="row">
		<no-permissions title="Findings">
			<img src="/static/images/promo/sm-get-started/Icons_monitoring_off.svg" alt="Findings"/>
		</no-permissions>
	</div>
</div>
