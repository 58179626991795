import { Component } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { WarningModalComponent } from 'commons/components/modals';

import { AuditsService } from '../audits.service';

@Component({
	templateUrl: './audits-details.component.html',
	preserveWhitespaces: true,
})
export class AuditsDetailsComponent {
	User: UserProfile
	helpLink = '/help/authenticated/tpo/smc/review-details';
	harvestingStatus: any;
	modalRef: BsModalRef;
	reviewId: number;

	constructor(
		{profile: User}: UserService,
		public stateService: StateService,
		public routerGlobals: UIRouterGlobals,
		public auditsService: AuditsService,
		private modalService: BsModalService,
	) {
		this.User = User;
		this.reviewId = this.routerGlobals.params.reviewId;

		if (this.User.isComergence) {
			this.harvestingStatus = this.auditsService.auditHarvestingStatus({ reviewId: this.reviewId });
		}
	}

	remove() {
		const initialState = {
			message: `
					<div class="text-center text-big">
						The action of Removing an audit <span class="text-danger">CANNOT</span> be undone!
					</div>
				`,
			confirmText: 'Remove',
			onConfirm: async (): Promise<void> => {
				this.modalRef.content.resolving = true;
				try	{
					await this.auditsService.auditDelete({ reviewId: this.reviewId }).$promise;
					this.modalRef.content.resolving = false;
					this.modalRef.hide();
					this.stateService.go('social-compliance.reviews');
				} catch (e) {
					this.modalRef.content.errorText = e.data.message || e.statusText;
					this.modalRef.content.resolving = false;
				}

			},
		};
		this.modalRef = this.modalService.show(
			WarningModalComponent,
			{
				initialState,
				class: 'warning',
				backdrop: 'static',
			},
		);
    }
}
