import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class TaskNotificationService {
	private socialMediaComplianceTaskNotificationResource: any;

	constructor(
		injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const resourceConfig = {
			url: `${apiPath}/tpos/:tpoId/social-media-compliance/contact/:contactId`,
			params: {},
		};

		this.socialMediaComplianceTaskNotificationResource = $resource(
			resourceConfig.url,
			resourceConfig.params,
			{
				notify: {
					url: `${resourceConfig.url}/task-notification`,
					method: 'POST',
				},
				emails: {
					url: `${resourceConfig.url}/emails`,
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	notify = (...args) => this.socialMediaComplianceTaskNotificationResource.notify(...args);
	emails = (...args) => this.socialMediaComplianceTaskNotificationResource.emails(...args);
}
