import { assign, find, filter, range, remove } from 'lodash';
import { Component, Injector } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';

import { UploadImageModalComponent } from '../';
import { ReviewsResourceService } from '../../reviews-resource.service';

@Component({
	templateUrl: './images.component.html',
})
export class ReviewsSettingsImagesComponent {
	User: UserProfile;
	$scope: any;
	loading: boolean;
	availableImages: any[];
	defaultImage: any;
	modalRef: BsModalRef;
	maxItems: number = 9;
	filter = filter;
	range = range;

	constructor(
		public reviewsResource: ReviewsResourceService,
		public sanitizer: DomSanitizer,
		public modalService: BsModalService,
		public injector: Injector,
		{ profile }: UserService,
	) {
		this.User = profile;
		this.$scope = this.injector.get('$scope');
		if (!this.User.can('TPO_PB_MANAGE_REVIEW_SETTINGS')) {
			this.$scope.$emit('USER:403', 'Reviews Settings');
			return;
		}

		this.loading = true;
		reviewsResource.availableImages.get().$promise
			.then((response) => {
				this.availableImages = response;
				this.defaultImage = find(response, { deletable: false });
			})
			.finally(() => {
				this.loading = false;
			});
	}

	setDefault(image: any): void {
		this.loading = true;
		this.reviewsResource.imagesSettings.setDefault({ imageId: image.id }).$promise
			.then(() => {
				assign(this.defaultImage, { deletable: true }); // discard previous default image
				this.defaultImage = assign(image, { deletable: false }); // set new default image
			})
			.finally(() => {
				this.loading = false;
			});
	}

	addImage(): void {
		const initialState = {
			upload: (file) => {
				const resource = this.reviewsResource.imagesSettings.upload({}, file);
				// process success to add new image to the list
				resource.$promise
					.then(({ id, deletable }) => {
						this.availableImages.push({ id, deletable });
					});
				// return resource with $promise to process error
				return resource;
			},
		};
		this.modalService.show(UploadImageModalComponent, { initialState, class: 'modal-sm' });
	}

	removeImage({ id }: any): void {
		const initialState = {
			title: 'Remove Image',
			confirmText: 'Remove',
			cancelText: 'Cancel',
			message: 'Are you sure you want to remove the image?',
			onConfirm: () => {
				this.modalRef.hide();
				this.loading = true;
				this.reviewsResource.imagesSettings.remove({ imageId: id }).$promise
					.then(() => {
						remove(this.availableImages, {id});
					})
					.finally(() => {
						this.loading = false;
					});
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd' });
	}

	// @Todo: move to separate class
	sanitize = (imageId): SafeStyle => {
		const url = `/api/rest/tpos/${this.User.organization.id}/external-reviews/images/${imageId}/image-file`;
		return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
	}
}
