<h2>{{channelInfo.channelName}}</h2>
<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
	<div class="row-fluid header">
		<span class="col-sm-3">
			<b>Date</b>
		</span>
		<span class="col-sm-5">
			<b>Action</b>
		</span>
		<span class="col-sm-3">
			<b>Action By</b>
		</span>
	</div>
	<div class="row-fluid" *ngFor="let item of list | async">
		<span class="col-sm-3">
			{{item.activityDateTime | date : 'MM/dd/yyyy HH:mm'}}
		</span>
		<span class="col-sm-5">
			{{item.infos[0].description}}
		</span>
		<span class="col-sm-3">
			{{item.actionBy.fullName}}
		</span>
	</div>
	<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !noMatches">
		<div class="col-md-12 text-center text-bigger text-muted va-m ">No audit logs found</div>
	</div>
	<br>
	<paginator></paginator>
</div>