<div class="rows-bordered">
    <div class="row-fluid header">
            <span class="col-sm-6">
                <b>Ownership</b>
                <ul class="inline-toggle normalize">
                    <li *ngFor="let ownershipTypeLabel of ownershipTypeList"
                        [class.active]="ownershipTypeLabel === ownershipType"
                        (click)="changeOwnershipType(ownershipTypeLabel)">{{ ownershipTypeLabel }}</li>
                </ul>
            </span>
            <span class="col-sm-4">
                <b>Ownership</b>
            </span>
            <span class="col-sm-2 text-center">
                <b>Findings</b>
            </span>
    </div>
    <scorecard-summary-owners-row
        *ngFor="let owner of ownerFindings[ownershipType]"
        [tpoId]="tpoId"
        [isCCM]="isCCM"
        [owner]="owner"
    ></scorecard-summary-owners-row>
    <scorecard-summary-owners-default-text
        *ngIf="ownerFindings.total && !ownerFindings[ownershipType].length"
        text="No matches found"
    ></scorecard-summary-owners-default-text>
    <scorecard-summary-owners-default-text
        *ngIf="!ownerFindings.total"
        text="No records added"
    ></scorecard-summary-owners-default-text>
</div>
