import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { NewContactAuditLogService } from './contact-audit-log.service';
import { NewContactAuditLogComponent } from './contact-audit-log.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
    ],
    declarations: [
        NewContactAuditLogComponent,
    ],
    providers: [
        NewContactAuditLogService,
    ],
})
export class NewContactAuditLogModule {}
