<div class="col-md-12 text-left remote-resource" [class.resolved]="resolved">
    <div class="row">
        <div class="col-md-9">
            <label class="text-big">
                {{ channel.name }}
            </label>
        </div>
        <div class="col-md-3">
            <div *ngIf="userCanManageFinancials" class="text-right request-button-padding">
                    <button type="button" class="btn btn-default" (click)="openRequestModal(channel.id)">
                        Request
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9">
            <label class="control-label text-smaller text-light">Automated Schedule</label>
            &nbsp;
            <a *ngIf="!editing && userCanManageFinancials" class="btn btn-icon-tertiary text-light text-smaller" (click)="toggleEdit()">
                <span class="glyphicon glyphicon-pencil"></span>
            </a>

        </div>
        <div class="col-md-3"></div>
    </div>
    <form [formGroup]="form" (onValidSubmit)="save()">
        <div [isEditable]="editing">
            <div view>
                <div *ngIf="(!channelSettings?.settings?.length || (channelSettings.settings.length === 0)); else settingsExist" class="row">
                    <div class="col-md-12">
                        &ndash;
                    </div>
                </div>
                <ng-template #settingsExist>
                    <div *ngFor="let settingsEntry of channelSettings?.settings" class="row">
                        <div class="col-md-9">
                            {{ settingsEntry.periodDisplay }} {{ settingsEntry.auditedDisplay }}
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                </ng-template>
            </div>
            <div edit>
                <div class="col-md-9">
                    <ng-select
                        name="settings"
                        formControlName="settings"
                        [items]="settingsOptions"
                        bindLabel="settingName"
                        bindValue="setting"
                        multiple="true"
                        [compareWith]="compareSettings"
                    >
                    </ng-select>
                </div>
                <div class="col-md-3 button-holder">
                    <button type="submit" class="btn btn-icon btn-tertiary">
                        <span class="glyphicon glyphicon-ok"></span>
                    </button>
                    <a (click)="toggleEdit()" class="btn btn-icon btn-tertiary">
                        <span class="glyphicon glyphicon-remove"></span>
                    </a>
                </div>
            </div>
        </div>
    </form>
</div>
