import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AbstractRoleNavComponent } from '../abstract-role-nav.component';
import { NavigationMenuItem } from 'utils/navigation/navigation.interface';

@Component({
	selector: 'comergence-usernav',
	templateUrl: './role-usernav.component.html',
})
export class ComergenceUsernavComponent extends AbstractRoleNavComponent {
	isTPOLimited: boolean;

	constructor(
		stateService: StateService,
		userService: UserService,
	) {
		super(stateService, userService);

		this.isTPOLimited = userService.profile.organization.type === 'TPO_LIMITED';
	}

    // Override
    protected rebuildMenuItems(user: UserProfile): NavigationMenuItem[] {
        const menuItems: NavigationMenuItem[] = [];

        menuItems.push(
            {
                sref: 'users-management.users',
                title: 'User Management',
            },
			...user.can('CCM_MANAGE_CUSTOM_FIELDS') ?
				[{
					sref: 'fields',
					title: 'Custom Fields',
				}] : [],
            {
                sref: 'documents',
                title: 'Document Library',
            },
        );

        if (user.can('CCM_DATA_MATCH')) {
            menuItems.push({
                sref: 'data-match',
                title: 'Data Match',
            });
        }

		if (user.can('CCM_UPLOAD_PRODUCTION_DATA')) {
			menuItems.push({
				'sref': 'non-qm',
				'title': 'Non-QM',
			});
		}

        menuItems.push({
            sref: '/b2c/logout',
            rawHref: true,
            title: 'Logout',
        });

        return menuItems;
    }

}
