export const TriggerStatuses = {
	A: {
		class: 'label-success',
		title: 'Active',
	},
	I: {
		class: 'label-danger',
		title: 'Inactive',
	},
};
