import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class GroupsResourceService {
	groups: any;
	group: any;
	groupContacts: any;
	groupContactCandidates: any;
	groupSharingCampaigns: any;
	groupSharingCampaign: any;
	groupSharedTemplates: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const groupsPath = {
			url: `${apiPath}/tpos/:tpoId/social-media-groups`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const groupPath = {
			url: `${groupsPath.url}/:groupId`,
			params: {
				...groupsPath.params,
				groupId: '@groupId',
			},
		};
		const groupContactsPath = {
			url: `${groupPath.url}/contacts`,
			params: {
				...groupPath.params,
			},
		};
		const groupContactCandidatesPath = {
			url: `${groupPath.url}/contact-candidates`,
			params: {
				...groupPath.params,
			},
		};
		const groupSharingPath = {
			url: `${groupPath.url}/sharing`,
			params: {
				...groupPath.params,
			},
		};
		const groupSharingCampaignsPath = {
			url: `${groupSharingPath.url}/campaigns`,
			params: {
				...groupSharingPath.params,
			},
		};
		const groupSharingCampaignPath = {
			url: `${groupSharingCampaignsPath.url}/:campaignId`,
			params: {
				...groupSharingCampaignsPath.params,
				campaignId: '@campaignId',
			},
		};
		const groupTemplatesPath = {
			url: `${groupPath.url}/templates/:templateId`,
			params: {
				...groupPath.params,
				templateId: '@templateId',
			},
		};

		this.groups = PagedResource(
			groupsPath.url,
			groupsPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getAll: {
					url: `${groupsPath.url}/all`,
					method: 'GET',
					isArray: true,
				},
				create: {
					method: 'POST',
				},
			},
		);

		this.group = $resource(
			groupPath.url,
			groupPath.params,
			{
				get: {
					method: 'GET',
				},
				rename: {
					url: `${groupPath.url}/name`,
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
			},
		);

		this.groupContacts = PagedResource(
			groupContactsPath.url,
			groupContactsPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getIds: {
					url: `${groupContactsPath.url}/ids`,
					method: 'GET',
					isArray: true,
				},
				update: {
					method: 'PUT',
				},
				remove: {
					url: `${groupContactsPath.url}/:contactId`,
					params: {
						...groupContactsPath.params,
						contactId: '@contactId',
					},
					method: 'DELETE',
				},
			},
		);

		this.groupContactCandidates = PagedResource(
			groupContactCandidatesPath.url,
			groupContactCandidatesPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getStates: {
					url: `${groupContactCandidatesPath.url}/states`,
					method: 'GET',
					isArray: true,
				},
				getBranches: {
					url: `${groupContactCandidatesPath.url}/branches`,
					params: {
						...groupContactCandidatesPath.params,
						state: '@state',
					},
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.groupSharingCampaigns = PagedResource(
			groupSharingCampaignsPath.url,
			groupSharingCampaignsPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getCampaignsCandidates: {
					url: `${groupSharingPath.url}/campaigns-candidates`,
					method: 'GET',
					isArray: true,
				},
				add: {
					method: 'POST',
				},
			},
		);

		this.groupSharingCampaign = $resource(
			groupSharingCampaignPath.url,
			groupSharingCampaignPath.params,
			{
				checkEnrolledUsers: {
					url: `${groupSharingCampaignPath.url}/enrolled-users-exist`,
					method: 'GET',
				},
				remove: {
					method: 'DELETE',
				},
			},
		);

		this.groupSharedTemplates = PagedResource(
			groupTemplatesPath.url,
			groupTemplatesPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				remove: {
					method: 'DELETE',
				},
				getTemplatesCandidates: {
					url: `${groupPath.url}/available-templates`,
					method: 'GET',
					isArray: true,
				},
				add: {
					method: 'PUT',
				},
			},
		);
	}
}
