import { every } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import {
    GlobalNotificationsService,
    GlobalNotificationType,
    GlobalNotificationMessages,
} from 'global-elements/notication-center/notifications.service';

import { ApplicationCommonComponent } from 'tpo/application/common/application-common.component';
import {
    AppChannel,
    ReviewBySection,
    TpoApplicationService,
    QuestionnaireRow,
    OwnershipRow,
    CompanyInformationRow,
} from 'tpo/application/application.service';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';
import { TpoDocumentListComponent } from 'tpo/documents';

@Component({
    templateUrl: './app-summary.component.html',
})
export class AppSummaryComponent implements OnInit {
    appChannel: AppChannel;
    reviewBySection: ReviewBySection = {} as ReviewBySection;
    questionnaires: QuestionnaireRow = {} as QuestionnaireRow;
    ownership: OwnershipRow = {} as OwnershipRow;
    companyInformation: CompanyInformationRow = {} as CompanyInformationRow;
    sections: { id: PROFILE_SECTION, label: string, sref: string }[] = [
        { id: PROFILE_SECTION.AFFILIATES, label: 'Affiliates', sref: 'account.relationships.affiliates' },
        { id: PROFILE_SECTION.AGENCY, label: 'Agency', sref: 'account.relationships.agency' },
        { id: PROFILE_SECTION.INSURANCE_BONDS, label: 'Insurance and Bonds', sref: 'account.relationships.insuranceBonds' },
        { id: PROFILE_SECTION.INVESTORS, label: 'Investors', sref: 'account.relationships.investors' },
        { id: PROFILE_SECTION.TECHNOLOGY_OPERATIONS, label: 'Technology & Operations', sref: 'account.relationships.tech-ops' },
        { id: PROFILE_SECTION.UNDERWRITING, label: 'Underwriting', sref: 'account.relationships.underwriting' },
        { id: PROFILE_SECTION.WAREHOUSE, label: 'Warehouse', sref: 'account.relationships.warehouse' },
    ];
    resolved = false;
    channelId: number;

    constructor(
        readonly appComponent: ApplicationCommonComponent,
        private readonly router: UIRouter,
        private readonly applicationService: TpoApplicationService,
        private readonly notificationsService: GlobalNotificationsService,
    ) {
        const { channelId } = this.router.globals.params;
        this.channelId = channelId;
    }

    async ngOnInit() {
        [
            this.appChannel,
            this.reviewBySection,
            this.questionnaires,
            this.ownership,
            this.companyInformation,
        ] = await Promise.all([
            this.appComponent.application,
            this.applicationService.getAppReviews(this.channelId).toPromise(),
            this.applicationService.getAppQuestionnaires(this.channelId).toPromise(),
            this.applicationService.getAppOwnership(this.channelId).toPromise(),
            this.applicationService.getAppCompanyInformation(this.channelId).toPromise(),
        ]);
        this.resolved = true;
    }

    get canSubmit(): boolean {
        const filteredSections = this.sections.map(({ id }) => this.reviewBySection[id]);
        return (
            every(filteredSections, { expired: false }) &&
            this.questionnaires.completed &&
            this.ownership.completed &&
            this.companyInformation.completed
        )
    }

   async submit() {
       this.resolved = false;
       try {
           await this.applicationService.submitApplication(this.channelId).toPromise();
           this.router.stateService.go('documents.list', {
               [TpoDocumentListComponent.listName]: {
                   lenderId: this.appChannel.investor.id,
                   channelId: [this.channelId],
               },
           });
       } catch (e) {
           this.notificationsService.add({
               type: GlobalNotificationType.ERROR,
               message: GlobalNotificationMessages.ERROR,
           });
       }
       this.resolved = true
    }
}
