import { Directive } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { PagedListComponent } from 'commons/components/list';

import { HistoryDocument } from './document-history.interface';

export enum DocumentHistoryType {
	WAIVE = 'WAIVE',
	REQUEST = 'REQUEST',
}

@Directive()
export abstract class HistoryDocumentListComponent<T extends HistoryDocument> extends PagedListComponent {
	list: NgResourceArray<T> = [];
	DocumentHistoryType = DocumentHistoryType;

    canManageDocuments: boolean;
    isLender: boolean;
    resolving: boolean = false;

    protected abstract listResource;
	protected configureList: () => void;
	protected getDownloadLink?: (params: any) => string;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
	) {
		super(transitionService, stateService);
	}

	ngOnInit(): void {
		Object.assign(
			this.defaultFilters,
			{
				size: 10,
			},
		);

		this.configureList();

		super.ngOnInit();
	}

	processHistory = (item) => {
		if (this.getDownloadLink && item.hasUploadedDocument) {
			item.downloadLink = this.getDownloadLink(item);
		}

		item.type = null;
		if (item.hasRequestAssignment) {
			item.type = DocumentHistoryType.REQUEST;
		} else if (item.hasWaiveDetails) {
			item.type = DocumentHistoryType.WAIVE;
		}
	}

	async loadList(queryParams) {
		this.list.$resolved = false;
		try {
			this.list = await this.listResource(
				this.getClearParams(queryParams),
			).$promise;

			this.list.forEach(this.processHistory);
		} catch (e) {}
		this.list.$resolved = true;
	}

    showWaiveHistoryModal(...args) {}
    markDocumentReviewed(...args) {}
}
