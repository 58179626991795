<h2 *ngIf="isNew">Create Contact</h2>
<form
    class="remote-resource"
    [class.resolved]="!loading"
    [formGroup]="contactForm"
    (onValidSubmit)="triggerSave()"
>
    <div class="flex-row mb10" [class.curtain]="curtains[BLOCKS.MAIN]">
        <h3 class="flex-grow">General Information</h3>
        <div class="flex-none" *ngIf="!isNew && contact.contactType == TYPE.CRM && hasPermission('MANAGE_CONTACT')" [isEditable]="editing">
            <ng-container view>
				<a *ngIf="!contact.nmlsId && !contact.isPrevious" (click)="showRemoveDialog()" class="btn btn-link" tabindex=14>Remove Contact</a>
                <a (click)="edit()" class="btn btn-primary" tabindex=14><span class="glyphicon glyphicon-pencil"></span> Edit</a>
            </ng-container>

            <ng-container edit>
                <button type="submit" class="btn btn-primary" tabindex=14>Save</button>
                <a (click)="cancelEdit()" class="btn btn-default" tabindex=14>Cancel</a>
            </ng-container>
        </div>
    </div>


    <ng-container [ngSwitch]="contact.contactType" *ngIf="contact.contactType">
        <simple-general-information *ngSwitchDefault [contact]="contact" [class.curtain]="curtains[BLOCKS.MAIN]"></simple-general-information>
        <div *ngSwitchCase="TYPE.CRM" [class.curtain]="curtains[BLOCKS.MAIN]">
            <form-errors *ngIf="editing" [form]="contactForm"></form-errors>
            <div class="rows-striped">
                <div class="row-fluid">
                    <div class="col-md-5">
                        <div class="form-group row" has-error>
                            <label class="col-sm-5 control-label" [class.required]="hasField('firstName')">First Name</label>
                            <div class="col-sm-7" [isEditable]="hasField('firstName')">
                                <span view class="control-value">{{contact.firstName || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('firstName')" tabindex=10 class="form-control" maxlength="100"
                                       formControlName="firstName">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group row">
                            <label class="col-sm-5 control-label">Street</label>
                            <div class="col-sm-7" [isEditable]="hasField('address')">
                                <span view class="control-value">{{contact.address || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('address')" tabindex=10 class="form-control" maxlength="100" formControlName="address">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row-fluid">
                    <div class="col-md-5">
                        <div class="form-group row" has-error>
                            <label class="col-sm-5 control-label" [class.required]="hasField('lastName')">Last Name</label>
                            <div class="col-sm-7" [isEditable]="hasField('lastName')">
                                <span view class="control-value">{{contact.lastName || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('lastName')" tabindex=10 class="form-control" maxlength="100" formControlName="lastName">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group row">
                            <label class="col-sm-5 control-label">Suite</label>
                            <div class="col-sm-7" [isEditable]="hasField('suite')">
                                <span view class="control-value">{{contact.suite || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('suite')" tabindex=10 class="form-control" maxlength="50" formControlName="suite">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row-fluid">
                    <div class="col-md-5">
                        <div class="form-group row">
                            <label class="col-sm-5 control-label">Middle Name</label>
                            <div class="col-sm-7" [isEditable]="hasField('middleName')">
                                <span view class="control-value">{{contact.middleName || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('middleName')" tabindex=10 class="form-control" maxlength="100"
                                       formControlName="middleName">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group row" has-error>
                            <label class="col-sm-5 control-label">Zip</label>
                            <div class="col-sm-7" [isEditable]="hasField('zip')">
                                <span view class="control-value">{{contact.zip || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('zip')" tabindex=10 class="form-control" maxlength="20" formControlName="zip">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row-fluid">
                    <div class="col-md-5">
                        <div class="form-group row" has-error>
                            <label class="col-sm-5 control-label">Email</label>
                            <div class="col-sm-7" [isEditable]="hasField('email')">
                                <span view class="control-value">{{contact.email || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('email')" tabindex=10 class="form-control" maxlength="100" formControlName="email">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group row">
                            <label class="col-sm-5 control-label">State</label>
                            <div class="col-sm-7" [isEditable]="hasField('state')">
                                <span view class="control-value">{{contact.state || '&ndash;'}}</span>
                                <ng-select
                                    edit
                                    *ngIf="hasField('state')"
                                    formControlName="state"
                                    [items]="states | async"
                                    [loading]="loadingValue.states"
                                    bindValue="name"
                                    bindLabel="name"
                                    [clearable]="true"
                                    placeholder="Select state"
                                    tabindex=13>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row-fluid">
                    <div class="col-md-5">
                        <div class="form-group row" has-error>
                            <label class="col-sm-5 control-label">Phone</label>
                            <div class="col-sm-7" [isEditable]="hasField('phone')">
                                <span view class="control-value">{{contact.phone || '&ndash;' | tel}}</span>
                                <input edit *ngIf="hasField('phone')" phoneFormatter tabindex=10 class="form-control" formControlName="phone">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group row">
                            <label class="col-sm-5 control-label">City</label>
                            <div class="col-sm-7" [isEditable]="hasField('city')">
                                <span view class="control-value">{{contact.city || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('city')" tabindex=10 class="form-control" maxlength="50" formControlName="city">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row-fluid">
                    <div class="col-md-5">
                        <div class="form-group row">
                            <label class="col-sm-5 control-label">Title</label>
                            <div class="col-sm-7" [isEditable]="hasField('contactTitle')">
                                <span view class="control-value">{{contact.contactTitle || '&ndash;'}}</span>
                                <input edit *ngIf="hasField('contactTitle')" tabindex=10 class="form-control" maxlength="100"
                                       formControlName="contactTitle">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">

                    </div>
                    <div class="col-md-1"></div>
                </div>

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="(contact.organizationContactId || isNew) && user.crmEnabled">
        <additional-fields
            [isNew]="isNew"
            [curtain]="curtains[BLOCKS.CUSTOM_FIELDS]"
            (editingChange)="editSection(BLOCKS.CUSTOM_FIELDS, $event)"
            entityTypeId="CRMContact"
            [entityId]="contact.organizationContactId"
            [parentForm]="contactForm"
        ></additional-fields>
        <br>
        <related-account
            [curtain]="curtains[BLOCKS.ACCOUNT]"
            (editingChange)="editSection(BLOCKS.ACCOUNT, $event)"
            (accountChange)="updateCompany($event)"
            [account]="contact.relatedAccount"
            [resource]="relatedAccountResource"
            [parentForm]="contactForm"
            [notEditableOnView]="contact.contactType !== 'lender_individual'"
            [isNew]="isNew"
            [resolved]="true"
        ></related-account>
        <br>

        <div class="text-right" *ngIf="isNew">
            <button type="submit" class="btn btn-primary" tabindex=14>Save</button>
            <a (click)="cancelEdit()" class="btn btn-default" tabindex=14>Cancel</a>
        </div>

        <related-activities *ngIf="!isNew" [class.curtain]="curtains[BLOCKS.ACTIVITIES]"></related-activities>
    </ng-container>
</form>
