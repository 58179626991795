import { Injectable } from '@angular/core';
import { $resource } from 'commons/services/compatibility/resource';
import { NotificationStateService } from 'commons/services/compatibility/$state.service';
import { TimezonesService, TimezonesList } from 'commons/services/compatibility/timezones.service';
import { RealmHttpClient } from 'commons/services/http';
import { ErrorModalService } from 'commons/services/error/error-modal.service';

@Injectable({
    providedIn: 'root',
})
export class CompatibilityInjectorService {
    constructor(
        private readonly notificationStateService: NotificationStateService,
        private readonly timezonesService: TimezonesService,
        private readonly errorModalService: ErrorModalService,
        private readonly http: RealmHttpClient,
    ) {
    }

    get(dependency: string) {
        switch (dependency) {
            case 'MemoResource':
            case '$resource':
                return $resource(this.http);
            case 'PagedResource':
                return $resource(this.http, true);
            case '$scope':
            case '$rootScope':
                return  {
                    $emit: (name, ...args) => {
                        if (name === 'USER:403') {
                            this.errorModalService.handleHttpError(403);
                        }
                    },
                    $broadcast: () => {},
                };
            case '$state':
                return this.notificationStateService;
            case 'TimezonesService':
                return this.timezonesService;
            case 'TimezonesList':
                return TimezonesList;

            default:
                throw new Error(`No substitute for "${dependency}" in compatibility layer`);
        }
        return {};
    }
}
