import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';

declare let apiPath: string;

@Injectable()
export class InvestorChannelsService {
	private channels: any;

	private baseUrl = `${apiPath}/lenders/:lenderId`;
	private baseParams = {
		lenderId: null,
	};

	constructor(
		injector: Injector,
		user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');

		this.baseParams = {
			...this.baseParams,
			lenderId: user.profile.organization.id,
		};

		const channelsResourceConfig = {
			url: `${this.baseUrl}/channels`,
			params: {
				...this.baseParams,
			},
		};

		this.channels = $resource(
			channelsResourceConfig.url,
			channelsResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getChannels = (...args): NgResourceArray<any> => this.channels.get(...args);
}
