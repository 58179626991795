import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { MaxLengthValidator, PhoneValidator } from 'commons/validators';

const ComergenceUserDetailsForm = new RealmFormGroup({
	systemRoleId: new RealmFormControl(
		'systemRoleId',
		{label: 'System Role'},
		Validators.required,
	),
	firstName: new RealmFormControl(
		'firstName',
		{label: 'First Name'},
		Validators.compose([ Validators.required, MaxLengthValidator(50) ]),
	),
	lastName: new RealmFormControl(
		'lastName',
		{label: 'Last Name'},
		Validators.compose([ Validators.required, MaxLengthValidator(50) ]),
	),
	email: new RealmFormControl(
		'email',
		{label: 'Email'},
		Validators.compose([ Validators.required, MaxLengthValidator(100), Validators.email ]),
	),
	phone: new RealmFormControl(
		'phone',
		{label: 'Phone'},
		Validators.compose([ MaxLengthValidator(10), PhoneValidator() ]),
	),
	isComergenceRepresentative: new RealmFormControl(
		'isComergenceRepresentative',
		{label: 'Reviewer'},
	),
	reportingLicense: new RealmFormControl(
		'reportingLicense',
		{label: 'Reporting License'},
	),
});

export { ComergenceUserDetailsForm };
