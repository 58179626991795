import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { InvitesListComponent } from './list';
import { InvitesResourceService } from './invites.service';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	providers: [
		InvitesResourceService,
	],
	declarations: [
		InvitesListComponent,
	],
	exports: [
		InvitesListComponent,
	],
})
export class InvitesModule {}
