<div class="btn-group">
    <ng-container *ngFor="let item of items">
	    <button *ngIf="!item.ignore"
                type="button"
                [ngClass]="{
                    'disabled': item.disabled,
                    'btn-primary': item.value === value,
                    'btn-default': item.value !== value,
                    'btn': true
                }"
                (click)="change(item.value)">
            {{item.title}}
            <span *ngIf="item.alert$ | async" class="icon-alert segmented-controls-icon-alert">
            </span>
        </button>
    </ng-container>
</div>
