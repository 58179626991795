import {isEqual, pick, keys, trim} from 'lodash';
import {TransitionService, StateService} from '@uirouter/core';

import {ListComponent} from './list.component';

export abstract class PagedListComponent extends ListComponent {
	omitParams: any = ['sort', 'page', 'size'];
	omitResetParams: any = ['sort', 'size'];
	defaultFilters: any = {
		sort: '',
		q: '',
		page: 0,
		size: 10,
	};

	constructor(transitionService: TransitionService, stateService: StateService) {
		super(transitionService, stateService);
	}

	resetSearch() {
		const {q} = this.params$.getValue();

		if (!q) {
			return;
		}

		this.params$.next({...this.params$.getValue(), page: 0, q: this.defaultFilters.q});
	}

	resetPage() {
		const {page} = this.params$.getValue();

		if (!page) {
			return;
		}

		this.params$.next({...this.params$.getValue(), page: 0});
	}

	setSearch($event) {
		const q = trim(`${$event.target.value}`);
		const currentParams = this.params$.getValue();
		if (currentParams.q === q) {
			// do not search on same input
			return;
		}

		this.params$.next({...this.params$.getValue(), page: 0, q });
	}

	setFilter(filter, resetPage: boolean = true) {
		const params = this.params$.getValue();
		const currentFilter = pick(params, keys(filter));
		if (isEqual(currentFilter, filter)) {
			return;
		}

		const pageParams = resetPage ? {page: 0} : {};
		this.params$.next({...params, ...pageParams, ...filter});
	}
}
