export abstract class SmNetworkConnector {
	public windowParams: { width: number, height: number } = { width: 400, height: 600 };
	public name: string;
	public url: string;
	public btnClass: string;
	public iconClass: string;
	public click: (entry?) => void;
	public isDM: boolean = false;

	constructor(props: {url: string, click: (entry?) => void, 	isDM?: boolean}) {
		Object.assign(this, props);
	}

	getWindowName() {
		return `connect:/${this.url}`;
	}
}
