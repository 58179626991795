import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import {
    AllDirectionalInvestorShareRequests,
    DirectionalInvestorShareRequest,
    IncomingInvestorUserShareRequest,
    InvestorShareRequestReview,
} from 'commons/interfaces/investor-share-requests.type';


declare let apiPath: string;

@Injectable()
export class InvestorShareRequestsResourceService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getIncomingInvestorUserShareRequests(investorId: number): Observable<IncomingInvestorUserShareRequest[]> {
        return this.http.request<IncomingInvestorUserShareRequest[]>(
            'GET',
            `${apiPath}/investors/${investorId}/share-requests`,
        );
    }

    getInvestorShareRequestReview(investorId: number, shareRequestId: number): Observable<InvestorShareRequestReview> {
        return this.http.request<InvestorShareRequestReview>(
            'GET',
            `${apiPath}/investors/${investorId}/share-requests/${shareRequestId}`,
        );
    }

    getAllDirectionalInvestorShareRequests(investorId: number): Observable<AllDirectionalInvestorShareRequests> {
        return this.http.request<AllDirectionalInvestorShareRequests>(
            'GET',
            `${apiPath}/investors/${investorId}/share-requests/directional/all`,
        );
    }

    acceptShareRequest(investorId: number, shareRequestId: number): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/investors/${investorId}/share-requests/${shareRequestId}/accepted`,
        );
    }

    declineShareRequest(investorId: number, shareRequestId: number): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/investors/${investorId}/share-requests/${shareRequestId}/declined`,
        );
    }

    cancelIncomingShareRequest(investorId: number, shareRequestId: number): Observable<DirectionalInvestorShareRequest> {
        return this.http.request<DirectionalInvestorShareRequest>(
            'PUT',
            `${apiPath}/investors/${investorId}/share-requests/${shareRequestId}/cancel-incoming`,
        );
    }

    cancelOutgoingShareRequest(investorId: number, shareRequestId: number): Observable<DirectionalInvestorShareRequest> {
        return this.http.request<DirectionalInvestorShareRequest>(
            'PUT',
            `${apiPath}/investors/${investorId}/share-requests/${shareRequestId}/cancel-outgoing`,
        );
    }

    resendOutgoingShareRequest(investorId: number, shareRequestId: number): Observable<DirectionalInvestorShareRequest> {
        return this.http.request<DirectionalInvestorShareRequest>(
            'POST',
            `${apiPath}/investors/${investorId}/share-requests/${shareRequestId}/resend`,
        );
    }
}
