import { Injectable } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { FilterDefault } from 'commons/components/list/list.interface';

import { TPOFindingsResourceService } from './findings-resource.service';

import { FindingsItem, FindingsQueryParams } from 'shared/findings/$findingId/finding.interface';

@Injectable()
export class TPOFindingsService {
	constructor(
		public findingsResource: TPOFindingsResourceService,
		public User: UserService,
	) {}

	list = (queryParams?: FindingsQueryParams): NgResourceArray<FindingsItem> => this.findingsResource.findingsBoard.get(queryParams);

	getAccountTypes = (): NgResourceArray<FilterDefault> => this.findingsResource.findingsBoardFilters.accountTypes();

	getEntities = (): NgResourceArray<FilterDefault> => this.findingsResource.findingsBoardFilters.entities();

	getStatuses = (): NgResourceArray<FilterDefault> => this.findingsResource.findingsBoardFilters.statuses();

	getOpenFindingsCount = (): NgResourceObject<{ value: string }> => this.findingsResource.findingsBoardFilters.openFindingsCount();

	getLocations = (state): NgResourceArray<Location> => this.findingsResource.findingsBoardFilters.locations({ state });
}
