import { Component, OnInit, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { ListComponent } from 'commons/components/list/list.component';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import {
    AssignedUser,
    DueDiligenceReview,
    Status,
    StatusTransition,
} from 'comergence/due-diligence/due-diligence-list.interface';
import { DueDiligenceListService } from 'comergence/due-diligence/due-diligence-list.service';
import { PagedData } from 'commons/services/http';
import { forkJoin, Observable } from 'rxjs';
import { statusConfig } from './statusConfig';
import moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { assign } from 'lodash';

@Component({
    templateUrl: './due-diligence-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => DueDiligenceListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => DueDiligenceListComponent) },
    ],
})
export class DueDiligenceListComponent extends NewPagedListComponent<DueDiligenceReview> implements OnInit {
    static listName = 'due-diligence';
    statusConfig = statusConfig;

    bsConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: 'MM/DD/YYYY',
    };

    assignedUsersList: AssignedUser[];
    statusTransitions: StatusTransition[];
    statusesList: Status[];
    resolved: boolean = true;

    constructor(
        uiRouter: UIRouter,
        private dueDiligenceListService: DueDiligenceListService,
    ) {
        super(uiRouter);
        this.defaultParams.size = 25;
        this.defaultParams.assigned = null;
        this.defaultParams.status = null;
        this.defaultParams.dateFrom = null;
        this.defaultParams.dateTo = null;
    }

    async ngOnInit() {
        await this.getAssignedUsersAndStatuses();
        super.ngOnInit();
    }

    protected loadList(params: ListParams): Observable<PagedData<DueDiligenceReview[]>> {
        return this.dueDiligenceListService.getDueDiligenceList(params);
    }

    async getAssignedUsersAndStatuses() {
        this.resolved = false;
        forkJoin([
            this.dueDiligenceListService.getAssignedUsers(),
            this.dueDiligenceListService.getStatusTransitions(),
            this.dueDiligenceListService.getStatuses(),
        ]).subscribe( response => {
            this.assignedUsersList = response[0];
            this.statusTransitions = response[1];
            this.statusesList = response[2];
            this.resolved = true;
        });
    }

    setStatusFilter(statuses: Status[]) {
        const statusCodes: string[] = [];
        statuses.forEach( (indivStatus) => {
            statusCodes.push(indivStatus.id);
        })

        const statusParam: ListParams = {
            status: statusCodes,
        }
        this.setFilter(statusParam);
    }

    setAssignedFilter(assignedUser: AssignedUser) {
        const assigneeParam: ListParams = {
            assigned: assignedUser.id,
        }
        this.setFilter(assigneeParam);
    }

    setToDateFilter(date: Date) {
        const convertedDate: number = moment(date).unix() * 1000;
        this.setFilter({ dateTo: convertedDate });
    }

    setFromDateFilter(date: Date) {
        const convertedDate: number = moment(date).unix() * 1000;
        this.setFilter({ dateFrom: convertedDate });
    }

    toDatePickerReadableFormat(unixTimestamp: number) {
        if(unixTimestamp) {
            return moment(unixTimestamp).toDate();
        }
        return null;
    }
}
