<h3>Location Production</h3>
<ng-container [ngSwitch]="hasProductionAvailable">
    <production-total-volume *ngSwitchCase="true" [nmlsId]="branchNmlsId" [method]="'getProductionLocation'" [lenderInfo]="lenderInfo" class="mb20">
        <ng-template #productionTemplate let-totalVolume="totalVolume">
            <div>
                <b class="text-big" [class.weight-normal]="!totalVolume?.volume">{{(totalVolume?.volume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}</b>&nbsp;
                <span class="text-muted text-smaller">Branch Volume</span>
            </div>
            <div>
                <b class="text-big" [class.weight-normal]="!totalVolume?.units">{{totalVolume?.units || '&ndash;'}}</b>&nbsp;
                <span class="text-muted text-smaller">Units</span>
            </div>
        </ng-template>
    </production-total-volume>
    <div *ngSwitchDefault class="alert alert-info text-center text-bigger">
        To access premium investor data upgrade your subscription.
    </div>
</ng-container>


<h3>Branch Managers</h3>
<location-branch-manager-list [lenderInfo]="lenderInfo" [locationInfo]="location" [contactsAvailable]="contactsAvailable"></location-branch-manager-list>

<h3>Originators</h3>
<location-originator-list [lenderInfo]="lenderInfo" [locationInfo]="location" [contactsAvailable]="contactsAvailable"></location-originator-list>
