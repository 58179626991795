import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import sourceIcon from '~static/images/material/two-tone/source_black_24dp.svg';
import contentIcon from '~static/images/material/two-tone/content_paste_go_black_24dp.svg';

import { LandingPageComponent } from 'shared/social-media/landing-page/landing-page.component';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
	templateUrl: '../../../../shared/social-media/landing-page/landing-page.component.html',
})
export class LibraryMenuComponent extends LandingPageComponent {
    title = 'Content Library';

	constructor(
		modalService: BsModalService,
        stateService: StateService,
        userService: UserService,
	) {
        super(modalService, stateService, userService);
		this.items = [
            {
                icon: contentIcon,
                isMaterial: true,
                title: 'Templates',
                sref: 'social-compliance.publisher.library.templates',
                button: 'View',
            },
            {
                icon: sourceIcon,
                isMaterial: true,
                title: 'Curated Content',
                sref: 'social-compliance.publisher.library.content',
                button: 'View',
            },
        ]
	}

}
