import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactListContact } from 'lender/crm/contact/investor-crm-contact-lists/investor-crm-contact-list.interface';
import { InvestorCrmContactListService } from 'lender/crm/contact/investor-crm-contact-lists/services/investor-crm-contact-list.service';


@Component({
    selector: 'add-contact-list-contact',
    templateUrl: './add-contact-list-contact.component.html',
})
export class AddContactListContactComponent {
    @Input() listResolving: boolean;
    @Input() organizationId: number;
    @Input() contactListId: number;
    @Input() userCanAddContactToList: boolean;
    @Input() userCanExportContactList: boolean;
    @Input() exportDownloadUrl: string;
    @Output() contactAdded: EventEmitter<ContactListContact> = new EventEmitter();

    addingContact: boolean = false;
    savingContact: boolean = false;
    selectedContact: ContactListContact;

    constructor(
        private readonly investorCrmContactListService: InvestorCrmContactListService,
    ) {
    }

    enterAddContactMode(): void {
        this.addingContact = true;
    }

    exitAddContactMode(): void {
        this.addingContact = false;
        this.selectedContact = null;
    }

	setSelectedContact(contact: ContactListContact): void {
        this.selectedContact = contact;
    }

    async addContact(): Promise<void> {
        try {
            this.savingContact = true;

            const contact: ContactListContact = this.selectedContact;
            await this.investorCrmContactListService
                .addContactToList(this.organizationId, this.contactListId, contact.organizationContactId)
                .toPromise();
            
            this.exitAddContactMode();
            this.contactAdded.next(contact);
        } finally {
            this.savingContact = false;
        }
    }
}