export interface Tab {
	id: string;
	name: string;
}

export const FindingTabs = {
	DETAILS: {
		id: 'DETAILS',
		name: 'Details',
	},
	ISSUES: {
		id: 'ISSUES',
		name: 'Issues',
	},
	AUDIT_LOG: {
		id: 'AUDIT_LOG',
		name: 'Audit Log',
	},
	CHECK_LIST: {
		id: 'CHECK_LIST',
		name: 'Checklist',
	},
	TASK: {
		id: 'TASK',
		name: 'Task',
	},
};
