import { Component, HostBinding, QueryList, ViewChildren } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { QuestionnaireStatus } from 'shared/questionnaires/questionnaires.interface';
import { TpoQuestionnaire, TpoQuestionnaireQuestion } from 'tpo/questionnaire/questionnaire.service';
import { LenderManageQuestionnairesPreviewService } from 'lender/questionnaires/manage/questionnaires-preview.service';

import { TpoQuestionnaireQuestionComponent } from 'tpo/questionnaire/question/question.component';

declare let window: Window;

@Component({
    templateUrl: './question-preview.component.html',
    host: { class: 'question-preview remote-resource display-block' },
})
export class QuestionPreviewComponent {
    questionnaire: TpoQuestionnaire;
    questions: TpoQuestionnaireQuestion[] = [];
    visibility: boolean[] = [];
    finished = true;
    isValid: boolean = true;
    isTouched: boolean = false;
    isCompleted: boolean = true;
    isApplication: boolean = false;
    private maps = {
        id2index: {},
        index2id: {},
    };
    @HostBinding('class.resolved') resolved = false;
    @ViewChildren(TpoQuestionnaireQuestionComponent) questionComponents!: QueryList<TpoQuestionnaireQuestionComponent>;

    constructor(
        private router: UIRouter,
        private lenderManageQuestionnairesPreviewService: LenderManageQuestionnairesPreviewService,
    ) {
        this.init();
    }

    async init(): Promise<void> {
        const { questionnaireId } = this.router.globals.params;

        try {
            this.questionnaire = await this.lenderManageQuestionnairesPreviewService.getPreviewQuestionnaire(questionnaireId).toPromise();
            this.isCompleted = this.questionnaire?.status?.id === QuestionnaireStatus.Completed;
            this.isApplication = Boolean(this.questionnaire?.applicationId)
            if (this.isCompleted && !this.isApplication) {
                window.location.replace(`/tpos/questionnaires/${this.questionnaire?.id}/print`);
                return;
            }
            this.questions = await this.lenderManageQuestionnairesPreviewService.getPreviewQuestions(questionnaireId).toPromise();
        } catch (e) {
            this.resolved = true;
            throw e;
        }
        this.resolved = true;
        this.initCache();
    }

    initCache(): void {
        this.maps = this.questions.reduce(({ id2index, index2id }, { id }, index) => {
            id2index[id] = index;
            index2id[index] = id;
            return { id2index, index2id };
        }, this.maps);
        this.calculateVisibility();
    }

    protected calculateVisibility(): void {
        const finishIndex = this.questions.length;
        let nextIndex = 0;
        this.visibility = this.questions.map((question, index) => {
            if (nextIndex !== index) {
                return false;
            }

            if (!question.useSkipLogic) {
                nextIndex = index + 1;
                return true;
            }

            const id = this.getNextQuestionId(question);
            //Finish questionnaire
            switch (id) {
                case -1:
                    nextIndex = finishIndex;
                    break;
                case undefined:
                    nextIndex = Infinity;
                    break;
                case null:
                    nextIndex = index + 1;
                    break;
                default:
                    nextIndex = this.maps.id2index[id];
            }

            return true;
        });
        this.finished = nextIndex === finishIndex;
    }

    ngAfterViewInit() {
        this.questionComponents.changes.subscribe((update) => {
            this.checkValidity();
        });
    }

    checkValidity() {
        this.isValid = this.questionComponents.reduce((acc, { valid }) => (acc && valid), true as boolean);
    }

    answerChange() {
        this.isCompleted = false;
    }

    private getNextQuestionId({ answer, answers }: TpoQuestionnaireQuestion): number {
        const { nextQuestionId } = answers.find(({ text }) => text === answer) || {};
        return nextQuestionId;
    }
}
