<div class="users">
	<notification></notification>

	<div class="remote-resource" [class.resolved]="list?.$resolved">
		<div class="filters-rows">
			<div class="row filter-labels">
				<div class="col-md-2">
					<div class="text-muted">Search</div>
				</div>
			</div>
			<div class="row filter-selectors">
				<div class="col-md-2">
					<search-list placeholder="Search by name"></search-list>
				</div>

				<div class="col-md-10">
					<div class="horizontal-flex-group right">
						<div class="btn-group" dropdown [insideClick]="true">
							<div class="dropdown-target dropdown-toggle" dropdownToggle id="button-columns-manage" aria-controls="dropdown-columns-manage">
								<span class="c-icon c-icon-list-bullet" tooltip="Manage Columns" placement="top"></span>
							</div>
							<ul id="dropdown-columns-manage" *dropdownMenu class="dropdown-menu" aria-labelledby="button-columns-manage">
								<li *ngFor="let column of columnValues()">
									<a class="link-option" (click)="toggleColumn(column.id)">
										<span class="checkbox-holder">
											<input type="checkbox" [checked]="column.enabled">
											{{column.name}}
										</span>
									</a>
								</li>
							</ul>
						</div>
						<a *ngIf="userCanCreateUsers"
							[uiSref]="createUserSref"
							[uiParams]="createUserSrefParams"
							[uiOptions]="createUserSrefUIOptions"
							class="btn btn-primary space-big"
						>
							Create User
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-2" columnName="personalName">Name</sortable-header>
				<sortable-header class="col-sm-a" columnName="status">Status</sortable-header>
				<sortable-header class="col-sm-a" columnName="systemRole"><span class="two-liners">System Role</span></sortable-header>
				<ng-container *ngFor="let column of columnValues()">
					<sortable-header class="col-sm-a" isColumnEnabled [columnName]="column.id">
						<span class="two-liners">{{column.name}}</span>
					</sortable-header>
				</ng-container>
			</div>
			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
			</div>
			<div class="row-fluid" *ngFor="let item of list">
				<span class="col-md-2 text-left">
					<div class="pseudo-tabel">
						<span class="pseudo-cell c-icon text-muted text-big" [ngClass]="{'c-icon-reporting-manager': item.isComergenceRepresentative}"></span>
						<a
							uiSref="users-management.users.:actionType.:id"
							[uiParams]="{id: item.id, actionType: 'view'}"
							class="pseudo-cell left-align"
						>
							{{item.personalName}}
						</a>
					</div>
				</span>
				<span class="col-md-a">
					<span class="label min-65 text-capitalize label-{{UserStatusColor[item.status.id] || 'default'}}">{{item.status.name | lowercase}}</span>
				</span>
				<span class="col-md-a">{{item.systemRole || '&ndash;'}}</span>
				<span class="col-md-a" isColumnEnabled columnName="lastLoginDate">{{(item.lastLoginDate | date:'MM/dd/yyyy') || '&ndash;'}}</span>
				<span class="col-md-a" isColumnEnabled columnName="email">{{item.email || '&ndash;'}}</span>
				<span class="col-md-a" isColumnEnabled columnName="createdBy">{{item.createdBy || '&ndash;'}}</span>
				<span class="col-md-a" isColumnEnabled columnName="createdDate">{{(item.createdDate | date : 'MM/dd/yyyy HH:mm') || '&ndash;'}}</span>
				<span class="col-md-a" isColumnEnabled columnName="lastModifiedBy">{{item.lastModifiedBy || '&ndash;' }}</span>
				<span class="col-md-a" isColumnEnabled columnName="lastModifiedDate">{{(item.lastModifiedDate | date : 'MM/dd/yyyy HH:mm') || '&ndash;'}}</span>
			</div>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length && (filtersApplied || filters.q)">
				<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
			</div>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length && !filtersApplied && !filters.q">
				<span class="col-md-12 text-muted text-center text-bigger">No users</span>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</div>
