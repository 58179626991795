<div class="content social-compliance" ui-view>
	<div class="order">
		<div class="gray-head flex-head">
			<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
				<span class="pull-left">
					<span>New Order</span>
					<smHelpLink [url]="helpLink"></smHelpLink>
				</span>
				<span class="pull-right text-muted text-right">
					<span class="text-medium">Selected: <b>{{selectedAmount}}</b></span>&nbsp;&nbsp;&nbsp;
					<a uiSref=".payment" [uiParams]="{selectedContacts: selectedContacts}" [class.disabled]="!selectedAmount" class="btn btn-primary">Place Order</a>
					<button type="button" class="btn btn-default" (click)="cancelOrder()">Cancel</button>
				</span>
			</back-bread>
		</div>

		<div class="filters-rows">
			<div class="row-fluid filter-labels">
				<div class="coldp-sm-5">
					<div class="text-muted">
						Employment
					</div>
				</div>
				<div class="coldp-sm-5">
					<div class="text-muted">
						Search
					</div>
				</div>
				<div class="coldp-sm-5">
					<div class="text-muted">
						State
					</div>
				</div>
				<div class="coldp-sm-5" [class.curtain]="!params.state">
					<div class="text-muted">
						Branch
					</div>
				</div>
				<div class="coldp-sm-4"></div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="coldp-sm-5 remote-resource" [class.resolved]="filters.employment.$resolved">
					<ng-select
						[ngModel]="params.employment"
						(change)="setFilter({ employment: $event })"
						[items]="filters.employment.$promise | async"
						bindValue="shortName"
						bindLabel="name"
						[clearable]="false"
					>
					</ng-select>
				</div>
				<div class="coldp-sm-5">
					<search-list placeholder="Name or NMLS ID" [callback]="setSelectedAllToFalse"></search-list>
				</div>
				<div class="coldp-sm-5 remote-resource" [class.resolved]="filters.state.$resolved">
					<ng-select
						[ngModel]="params.state"
						(change)="setFilter({ state: $event })"
						[items]="filters.state.$promise | async"
						bindValue="shortName"
						bindLabel="name"
						[multiple]="true"
						[clearable]="true"
						placeholder="All States"
					>
					</ng-select>
				</div>
				<div class="coldp-sm-5 remote-resource" [class.resolved]="filters.branchId.$resolved" [class.curtain]="!params.state">
					<ng-select
						[ngModel]="params.branchId"
						(change)="setFilter({ branchId: $event })"
						[items]="filters.branchId.$promise | async"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[disabled]="!params.state"
						[clearable]="true"
						placeholder="All Branches"
					>
					</ng-select>
				</div>
				<div class="coldp-sm-4"></div>
			</div>
		</div>
		<div class="remote-resource contacts" [class.resolved]="contacts.$resolved">
			<div class="rows-bordered">
				<div class="row-fluid header">
					<div class="btn-group col-md-1" dropdown>
						<div dropdownToggle
							 type="button"
							 class="dropdown-toggle text-center sortable"
							 aria-controls="dropdown-basic">
							<input class="va-m" type="checkbox" [(ngModel)]="selectedAll" (click)="false">
							<span class="caret"></span>
						</div>
						<ul *dropdownMenu class="dropdown-menu"
							role="menu" aria-labelledby="button-basic">
							<li role="menuitem"><a (click)="getAllContacts()" class="dropdown-item">Select All</a></li>
							<li role="menuitem"><a (click)="addContactsArray(contacts)" class="dropdown-item">Select All Visible</a></li>
							<li role="menuitem"><a (click)="resetContacts()" class="dropdown-item">Clear</a></li>
						</ul>
					</div>
					<span class="col-md-3" [ngClass]="getSortClass('fullName')" (click)="setSort('fullName')">
						<b>Name</b>
					</span>
					<span class="col-md-3">
						<b>Employment Dates</b>
					</span>
					<span class="col-md-2"  [ngClass]="getSortClass('lastAuditDate')" (click)="setSort('lastAuditDate')">
						<b>Last Audit Date</b>
					</span>
					<span class="col-md-2" [ngClass]="getSortClass('lastAuditStatus')" (click)="setSort('lastAuditStatus')">
						<b>Audit Status</b>
					</span>
				</div>

				<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
					<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters(); selectedAll = false">Reset filters</a> to see all results</span>
				</div>

				<div class="row-fluid align-items-center" *ngFor="let item of contacts" [class.selected-contact]="selectedContacts[item.contactId]">
					<span class="col-sm-1 text-center va-m">
						<input type="checkbox"
							   [(ngModel)]="selectedContacts[item.contactId]"
							   (change)="toggleContact($event.target, item)">
					</span>
					<span class="col-sm-3 loan-margin">
						<span *ngIf="item.nmlsId" class="c-icon c-icon-loan text-light" tooltip="Loan Originator" data-placement="top"></span>
						<a target="_blank" uiSref="people.contacts.:contactId.information" [uiParams]="{contactId: item.contactId}" class="text-bigger">{{item.fullName}}</a><br>
						<span *ngIf="item.nmlsId">
							<span class="text-muted">
								NMLS ID:
							</span>
							<a target="_blank" uiSref="account.licenses.nmls.:nmlsId" [uiParams]="{nmlsId:item.nmlsId}">{{item.nmlsId}}</a>
						</span>
					</span>
					<span class="col-sm-3 va-m">
						<span *ngIf="!item.employments?.length">&ndash;</span>
						<span *ngIf="item.employments?.length == 1">
							{{item.employments[0].startDate | date : 'MM/dd/yyyy'}} &ndash; {{(item.employments[0].endDate | date : 'MM/dd/yyyy') || 'Present'}}
						</span>
						<span class="branded-text" *ngIf="item.employments?.length > 1">
							<ng-template #popTemplate><div [innerHtml]="getDataRange(item.employments)"></div></ng-template>
							<span
								[tooltip]="popTemplate"
								data-placement="top">{{item.employments.length}} dates</span>
						</span>
					</span>
					<span class="col-sm-2 va-m">
						{{(item.lastAuditDate | date : 'MM/dd/yyyy') || '&ndash;'}}
					</span>
					<span class="col-sm-2 va-m">
						{{item.lastAuditStatus || '&ndash;'}}
					</span>
				</div>

				<div class="row-fluid" *ngIf="noMatches">
					<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
				</div>

				<div class="row-fluid" *ngIf="!contacts.length && !noMatches">
					<span class="col-md-12 text-muted text-center text-bigger">No contacts</span>
				</div>

				<br>
				<new-paging class="no-border" [(list)]="contacts" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
			</div>
		</div>
	</div>
</div>
