import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from 'comergence/customers/services/customer-list/customer.service';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
// Services
import { ApiSettingsResourceService } from './api-settings/api-settings-resource.service';
import { ApiSettingsComponent } from './api-settings';
import { ThirdPartyOversightService } from 'comergence/customers/services/customer-services/third-party-oversight.service';
import { InvestorMarketingService } from 'comergence/customers/services/customer-services/investor-marketing-service.service';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		ReactiveFormsModule,
		FormsModule,
		DirectivesModule,
	],
	declarations: [
		ApiSettingsComponent,
	],
	providers: [
		ApiSettingsResourceService,
        CustomerService,
        ThirdPartyOversightService,
        InvestorMarketingService,
	],
})
export class ComergenceServicesModule {

}
