import { Component, Input, Output, AfterViewInit, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { pullAt } from 'lodash';

const noop = () => {
};

export const WORD_EDITOR_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => WordEditorComponent),
	multi: true,
};

@Component({
	selector: 'word-editor',
	templateUrl: './word-editor.component.html',
	providers: [WORD_EDITOR_CONTROL_VALUE_ACCESSOR],
})
export class WordEditorComponent implements ControlValueAccessor, AfterViewInit {
	words: string[];
	toAdd: string = '';
	@Input() editable: boolean;
	@Output() update: EventEmitter<string[]> = new EventEmitter();

	// Placeholders for the callbacks which are later provided by the Control Value Accessor
	public onTouchedCallback: () => void = noop;
	public onChangeCallback: (_: any) => void = noop;

	constructor() {
	}

	ngAfterViewInit(): void {
	}

	addWord(event: Event): void {
		if (this.toAdd) {
			event.stopPropagation();
			event.preventDefault();
			this.words.push(this.toAdd)
			this.toAdd = '';
			this.onChangeCallback(this.words);
			this.update.emit();
		}
	}

	removeWord(index: number): void {
		pullAt(this.words, index);
		this.onChangeCallback(this.words);
		this.update.emit();
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.words) {
			this.words = value;
		}
	}
}
