<sidenav-menu [items]="items" *ngIf="!newMenu">
	<div class="social-compliance">
		<ui-view></ui-view>
	</div>
</sidenav-menu>
<tpo-social-media-menu *ngIf="newMenu">
	<div class="social-compliance">
		<ui-view></ui-view>
	</div>
</tpo-social-media-menu>
