import { find } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import { first, finalize } from 'rxjs/operators';
import { UserService } from 'angularjs-providers/user.provider';
import { StateService } from 'angularjs-providers/state.provider';
import { NonQMFileUploadHistory } from './non-qm.interface';
import { NonQmService } from './non-qm.service';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';

const FileTypes = [
    {
        type: 'CSV',
        regexp: /^.+\.(CSV)$/i,
    },
];

@Component({
    templateUrl: './non-qm.component.html',
    viewProviders: [
        {provide: ListComponent, useExisting: forwardRef(() => NonQmComponent)},
        {provide: NewPagedListComponent, useExisting: forwardRef(() => NonQmComponent)},
    ],
})
export class NonQmComponent extends NewPagedListComponent<NonQMFileUploadHistory> {
    attachment: File;
	uploadStatus: { class: 'alert-success'|'alert-danger', message: string };
	messageSuccessTO: number;
	resolved = true;

    static listName = 'nqmFileUpload';

	constructor(
		private readonly state: StateService,
		private readonly User: UserService,
		private readonly nonQmService: NonQmService,
        router: UIRouter,
	) {
        super(router);
		if (!this.User.profile.can('CCM_UPLOAD_PRODUCTION_DATA')) {
			return this.state.go('home');
		}
	}

	onPick(files: FileList) {
		const file = files[0];
		if (!file) {
			return;
		}

		const fileTypeConfig = find(FileTypes, (config) => (
			config.regexp.test(file.name)
		));

		if (!fileTypeConfig) {
			this.setStatus('alert-danger', 'Wrong file type. Use .csv file.');
			return;
		}

		const reader = new FileReader();
		reader.onload = ({ target: { result } }) => {
			if (!result.toString().trim()) {
				this.setStatus('alert-danger', 'Wrong data format. File can not be uploaded');
				return;
			}

			this.resolved = false;
			this.saveFile(file);
		};

		reader.readAsText(file);
	}

	setStatus(statusClass, message, timeout: number = 0)  {
		this.uploadStatus = {class: statusClass, message};

		if (this.messageSuccessTO) {
			clearTimeout(this.messageSuccessTO);
		}

		if (timeout) {
			setTimeout(() => {
				this.uploadStatus = null;
			}, timeout);
		}

		this.attachment = null;
	}

	saveFile(file, hecmMapping = {}) {
		const fileTypeConfig = find(FileTypes, (config) => (
			config.regexp.test(file.name)
		));

		if (!fileTypeConfig) {
			this.setStatus('alert-danger', 'Wrong file type. Use .csv file.');
			return;
		}

		this.nonQmService.nonQM(file)
			.pipe(
				first(),
				finalize(() => {
					this.resolved = true;
				})
			)
			.subscribe({
				next: (result) => {
					this.setStatus('alert-success', 'File uploaded successfully', 5000);
                    this.updateList();
				},
				error: ({ error }) => {
					this.setStatus('alert-danger', error.message);
				},
			});
	}

    protected loadList(params: ListParams): Observable<PagedData<NonQMFileUploadHistory[]>> {
        return this.nonQmService.getNonQMFileUploadHistory(params);
    }
}
