import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Channel } from 'lender/manage-channels/manage-channel-card.interface';
import {
    DocumentCardChannelScheduleSettings,
    DocumentCardChannelScheduleSettingsDisplay, DocumentCardChannelScheduleSettingsEntry,
    DocumentCardDetails,
    DocumentCardSummary,
} from 'shared/document-schedule-settings-card/document-card.interface';
import { DocumentScheduleSettingsStrategy } from 'shared/document-schedule-settings-card/strategy.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ChannelBulkRequestModalComponent } from 'shared/document-schedule-settings-card/card/channels/bulk-request-modal/channel-bulk-request-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'angularjs-providers/user.provider';


@Component({
    selector: '[channel-schedule-settings]',
    templateUrl: 'channel-schedule-settings.component.html',
})
export class ChannelScheduleSettingsEntryComponent<S extends DocumentCardSummary, D extends DocumentCardDetails> implements OnInit, OnChanges {
    @Input() strategy: DocumentScheduleSettingsStrategy<S, D>;
    @Input() channel: Channel;
    @Input() strategyParams: any = {};
    @Input() documentSummary: S;
    @Input() documentDetails: D;

    resolved: boolean = true;

    settingsOptions: DocumentCardChannelScheduleSettingsDisplay[] = [
        {
            settingName: 'Quarterly Audited',
            setting: {
                isAudited: true,
                period: 'QUARTER',
                settingId: null,
            },
        },
        {
            settingName: 'Quarterly Unaudited',
            setting: {
                isAudited: false,
                period: 'QUARTER',
                settingId: null,
            },
        },
        {
            settingName: 'Annually Audited',
            setting: {
                isAudited: true,
                period: 'ANNUAL',
                settingId: null,
            },
        },
        {
            settingName: 'Annually Unaudited',
            setting: {
                isAudited: false,
                period: 'ANNUAL',
                settingId: null,
            },
        },
    ]

    editing: boolean = false;

    userCanManageFinancials: boolean;

    channelSettings: DocumentCardChannelScheduleSettings;

    form: RealmFormGroup = new RealmFormGroup({
        settings: new RealmFormControl(
            'settings',
            {
                label: 'Settings',
            },
        ),
    });

    constructor(
        private readonly modalService: BsModalService,
        private userService: UserService
    ) {
    }

    // Override
    ngOnInit(): void {
        this.channelSettings = this.documentDetails?.channelsSettingsByIds?.get(this.channel?.id);
        this.form.patchValue({settings: this.channelSettings?.settings});
        this.userCanManageFinancials = this.userService.profile.can('TPO_MANAGE_GLOBAL_FINANCIALS');
    }

    ngOnChanges(changes: SimpleChanges) {
        this.documentDetails = changes.documentDetails.currentValue;
        this.channelSettings = this.documentDetails?.channelsSettingsByIds?.get(this.channel?.id);
        this.form.patchValue({settings: this.channelSettings?.settings});
    }

    toggleEdit(): void {
        if(this.editing) {
            this.form.reset({settings: this.channelSettings?.settings});
        }
        this.editing = !this.editing;
    }

    async save() {
        const params = {
            settings: this.form.controls['settings'].value,
            channelId: this.channel.id,
        }
        try {
            this.resolved = false;
            this.channelSettings = await this.strategy.updateScheduleSettings(this.documentSummary, this.documentDetails, params);
        } finally {
            this.resolved = true;
            this.toggleEdit();
        }
    }

    compareSettings(item: DocumentCardChannelScheduleSettingsDisplay, selected: DocumentCardChannelScheduleSettingsEntry): boolean {
        return ((item.setting.period === selected.period) && (item.setting.isAudited === selected.isAudited));
    }

    openRequestModal(id: number) {
        const initialState = {
            channelId: id,
            document: this.documentSummary,
            lenderId: this.documentDetails.lenderId,
        };
        this.modalService.show(
            ChannelBulkRequestModalComponent,
            {
                initialState,
                class: 'modal-new modal-smd',
                backdrop: 'static',
            },
        );
    }
}
