import { map, pick, startsWith } from 'lodash';
import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { Observable } from 'rxjs';
import { map as rxMap } from 'rxjs/operators';
import { Review } from 'tpo/social-compliance/reviews/reviews.interface';
import { RealmHttpClient } from 'commons/services/http/http-client';
import { PagedData } from 'commons/services/http';

declare let apiPath: string;

export interface ReviewSourceResponseInterface {
	displayName: string;
	// can be extended in future by other providers
	type: 'CUSTOMER' | 'GMB' | string;
	customerId: number | null;
}

export interface ReviewSourceInterface {
	displayName: string;
	// can be extended in future by other providers
	type: 'CUSTOMER' | 'GMB' | string;
	id: number | string;
}

export interface ReviewSourceDecodeIdInterface {
	customer: number | null;
	provider: string | null;
}

@Injectable()
export class ReviewsResourceService {
	reviews: any;
	reviewSources: any;
	review: any;
	externalReviews: any;
	availableImages: any;
	imagesSettings: any;
	zillowSettings: any;

	constructor(
		public injector: Injector,
		public user: UserService,
		private http: RealmHttpClient,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const tpoId = user.profile.organization.id;
		const externalReviewsPath = `${apiPath}/tpos/:tpoId/external-reviews`;

		this.reviews = PagedResource(
			externalReviewsPath,
			{ tpoId },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.reviewSources = $resource(
			`${externalReviewsPath}/sources`,
			{ tpoId },
			{
				get: {
					method: 'GET',
					isArray: true,
					transformResponse: (response): ReviewSourceInterface[] => {
						const data = JSON.parse(response) || [];
						return map(data, (entry: ReviewSourceResponseInterface) => {
							const commonData = pick(entry, 'displayName', 'type');

							return {
								...commonData,
								id: this.encodeReviewSourceId(entry),
							};
						});
					},
				},
			},
		);

		this.review = $resource(
			`${externalReviewsPath}/:reviewId`,
			{
				tpoId,
				reviewId: '@reviewId',
			},
			{
				preparePost: {
					method: 'POST',
					url: `${externalReviewsPath}/:reviewId/preparePost`,
				},
				reply: {
					method: 'POST',
					url: `${externalReviewsPath}/:reviewId/reply`,
				},
				editReply: {
					method: 'PUT',
					url: `${externalReviewsPath}/:reviewId/reply/:replyId`,
				},
				sendMarketingEmail: {
					method: 'POST',
					url: `${apiPath}/tpos/:tpoId/social-media-posts/marketing-email`,
				},
			},
		);

		this.externalReviews = $resource(
			`${externalReviewsPath}/nmlsIds`,
			{ tpoId },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.availableImages = $resource(
			`${externalReviewsPath}/available-images`,
			{ tpoId },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		const imagesSettingsPath = `${externalReviewsPath}/images/:imageId/`;
		this.imagesSettings = $resource(
			imagesSettingsPath,
			{ tpoId, imageId: '@imageId' },
			{
				setDefault: {
					method: 'PUT',
					url: `${imagesSettingsPath}/default`,
				},
				upload: {
					url: `${externalReviewsPath}/images/upload`,
					method: 'POST',
					headers: { 'Content-Type': undefined },
					transformRequest: (file) => {
						const data = new FormData();
						data.append('file', file);
						return data;
					},
				},
				remove: {
					method: 'PUT',
					url: `${imagesSettingsPath}/removed`,
				},
			},
		);

		this.zillowSettings = $resource(`${externalReviewsPath}/zillow/company-settings`, { tpoId }, {
			update: {
				method: 'PUT',
			},
		});
	}

	encodeReviewSourceId = (entry: ReviewSourceResponseInterface) => {
		return entry.type === 'CUSTOMER' ? `c_${entry.customerId}` : `p_${entry.type}`;
	}

	decodeReviewSourceId = (id: string): ReviewSourceDecodeIdInterface => {
		const result: ReviewSourceDecodeIdInterface = {
			customer: null,
			provider: null,
		};

		if (startsWith(id, 'p_')) {
			result.provider = id.slice(2);
		}

		if (startsWith(id, 'c_')) {
			result.customer = parseInt(id.slice(2), 10);
		}

		return result;
	}

	getList(params): Observable<PagedData<Review[]>> {
		const { id: tpoId } = this.user.profile.organization;
		return this.http.pagedRequest<Review[]>('GET', `${apiPath}/tpos/${tpoId}/external-reviews`, params);
	}

	getSources(): Observable<ReviewSourceInterface[]> {
		const { id: tpoId } = this.user.profile.organization;
		return this.http.request<ReviewSourceResponseInterface[]>('GET', `${apiPath}/tpos/${tpoId}/external-reviews/sources`)
			.pipe(
				rxMap((data): ReviewSourceInterface[] => {
					return map(data, (entry: ReviewSourceResponseInterface) => {
						const commonData = pick(entry, 'displayName', 'type');

						return {
							...commonData,
							id: this.encodeReviewSourceId(entry),
						};
					});
				})
			);
	}
}
