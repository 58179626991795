import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { License } from 'shared/account/licenses/licenses.interface';

@Component({
	templateUrl: './details-modal.component.html',
})
export class LicenseDetailsModalComponent<T = License> {
	license: T;

	constructor(
		public modalRef: BsModalRef,
	) {}
}
