<div class="documents">
	<div
		*ngIf="state.includes['client.:id.documents']"
		class="gray-head flex-head"
	>
		<div class="col-fit">
			<h2>Documents</h2>
		</div>
		<div class="col-min">
			<ul class="tab-menu">
				<li
					*ngIf="User.can('VIEW_CLIENT_DOCUMENTS')"
					uiSrefActive="active"
				>
					<a uiSref="client.:id.documents.list" role="tab">Documents</a>
				</li>
				<li
					*ngIf="User.can('VIEW_INTERNAL_DOCUMENTS')"
					uiSrefActive="active"
				>
					<a uiSref="client.:id.documents.internal-documents" role="tab">Internal</a>
				</li>
				<li
					*ngIf="hasAccess(RELATION.ASSIGNED, RELATION.NOT_ACCESSIBLE, RELATION.NOT_ASSIGNED) && User.can('VIEW_DOCUMENT_LIBRARY')"
					uiSrefActive="active"
				>
					<a uiSref="client.:id.documents.library" role="tab">Library</a>
				</li>
			</ul>
		</div>
	</div>
	<ui-view></ui-view>
</div>
