import { Injector, Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class NotifyVerificationService {
	resource: any;

	constructor(public injector: Injector, { profile }: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const base = {
			url: `${apiPath}/tpos/:id/owners/request_verification`,
			params: { id: profile.organization.id },
		};

		this.resource = $resource(base.url, base.params, {
			verify: { method: 'post' },
		});
	}

	verify = (...args) => this.resource.verify(...args);
}
