import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { AbstractIssueStatus } from '../abstractIssueStatus';


@Injectable()
export class HasOutstandingRequiredDocumentsIssueStatus extends AbstractIssueStatus {
    public constructor(private readonly userService: UserService) {
        super();
    }

    // Override
	protected async updateValue(): Promise<boolean> {
        const { hasOutstandingRequiredDocuments } = this.userService.profile;
		return Promise.resolve(hasOutstandingRequiredDocuments);
	}
}
