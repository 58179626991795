import { ScorecardFindingReviewerInfo } from '../scorecard-finding.interface';


const _FINDING_TYPES = [
    { key: 'T', label: 'Company' },
    { key: 'I', label: 'Individual' },
];

export type FindingType = typeof _FINDING_TYPES[number];

export const FINDING_TYPES: FindingType[] = [..._FINDING_TYPES];


export interface FindingAlertStatus {
    alertId: number;
    alertStatus: string;
}

export interface ReviewAlertInformation {
    review: ScorecardFindingReviewerInfo;
    alert: FindingAlertStatus;
}

export const Statuses = [
    {
        id: 'NEW',
        status: 'New',
    },
    {
        id: 'UNDER_REVIEW',
        status: 'Under Review',
    },
    {
        id: 'CLOSED',
        status: 'Closed',
    },
];
