<div class="gray-head">
	<h2>Export</h2>
</div>

<div class="remote-resource export-list" [class.resolved]="resolved">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<div class="text-muted">Type</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Request Date After</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Request Date Before</div>
			</div>
			<div class="col-sm-5"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<ng-select
					[multiple]="true"
					[(ngModel)]="params.exportType"
					(change)="setFilter({ exportType: $event })"
					[items]="filters.exportType.$promise | async"
					[loading]="!filters.exportType.$resolved"
					bindValue="id"
					bindLabel="name"
					placeholder="All Types"
				></ng-select>
			</div>

			<div class="col-sm-2">
				<input type="text" class="form-control"
					bsDatepicker
					[bsConfig]="bsConfig"
					[bsValue]="params.requestFrom"
					[maxDate]="params.requestTo"
					(bsValueChange)="setFilter({ requestFrom: $event })"
					placeholder="mm/dd/yyyy"
				>
			</div>

			<div class="col-sm-2">
				<input type="text" class="form-control"
					bsDatepicker
					[bsConfig]="bsConfig"
					[bsValue]="params.requestTo"
					[minDate]="params.requestFrom"
					(bsValueChange)="setFilter({ requestTo: $event })"
					placeholder="mm/dd/yyyy"
				>
			</div>

			<div class="col-sm-5"></div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<!-- <sortable-header class="col-sm-5" columnName="filename">File Name</sortable-header>
			<sortable-header class="col-sm-2" columnName="exportType">Type</sortable-header>
			<sortable-header class="col-sm-2" columnName="requestDate">Request Date</sortable-header>
			<sortable-header class="col-sm-2" columnName="lastDownload">Last Download</sortable-header> -->
			<div class="col-sm-5"><b>File Name</b></div>
			<div class="col-sm-2"><b>Type</b></div>
			<div class="col-sm-2"><b>Request Date</b></div>
			<div class="col-sm-2"><b>Last Download</b></div>
			<div class="col-sm-1">&nbsp;</div>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<span class="col-sm-12 text-center va-m">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
		</div>
		<div class="row-fluid" *ngFor="let item of list" [class.downloadable]="item.status === 'COMPLETED'" (click)="download(item)">
			<div class="col-sm-5 va-m">
				<span [class.text-link]="item.status === 'COMPLETED'">{{item.fileName}}</span>
			</div>
			<div class="col-sm-2 va-m">{{exportTypes[item.type] || '&ndash;'}}</div>
			<div class="col-sm-2 va-m" columnName="requestDate">{{(item.requestDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-2 va-m" columnName="lastDownload">{{(item.lastDownload | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-1 va-m">&nbsp;</div>
		</div>
		<div class="row-fluid" *ngIf="resolved && !list.length">
			<div class="col-sm-12 va-m text-center text-muted text-bigger">
				{{ (filtersApplied || filters.q) ? 'No matches found' : 'No Exports'}}
			</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
