<div class="rows-bordered" *ngxInit="list$ | async as list" [class.remote-resource]="!list">
    <div class="row-fluid header">
        <span class="col-md-3">{{title}}</span>
        <span class="col-md-3">NMLS ID</span>
        <span class="col-md-3 text-right">Units</span>
        <span class="col-md-3 text-right">Dollars</span>
    </div>
    <div class="row-fluid" *ngFor="let item of list">
        <div class="col-md-3">
            {{item?.name || '&ndash;'}}
        </div>
        <div class="col-md-3" [ngSwitch]="type">
            <a
                *ngSwitchCase="'branches'"
                uiSref="{{prefix}}account.locations.:branchId.originators"
                [uiParams]="{branchId: item?.nmlsId}"
                class="text-bigger"
                [aDisabled]="!item?.nmlsId"
            >
                {{item?.nmlsId || '&ndash;'}}
            </a>
            <a
                *ngSwitchCase="'officers'"
                uiSref="{{prefix}}account.licenses.nmls.:nmlsId"
                [uiParams]="{nmlsId: item?.nmlsId}"
                class="text-bigger"
                [aDisabled]="!item?.nmlsId"
            >
                {{item?.nmlsId || '&ndash;'}}
            </a>
        </div>
        <div class="col-md-3 text-right">
            {{item?.units || '&ndash;'}}
        </div>
        <div class="col-md-3 text-right">
            {{(item?.volume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
        </div>
    </div>

    <div *ngIf="showTableError" class="text-center alert alert-info text-normal">
        {{errorMsg}}
    </div>

    <div class="row-fluid" *ngIf="!list?.length">
        <div class="col-md-12 va-m text-muted text-center text-bigger">
            No records
        </div>
    </div>
</div>