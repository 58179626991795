<div class="rows-bordered gap-l">
    <div class="row-fluid header">
        <div class="col-sm-5">
            <b>Account Information</b>
        </div>
        <div class="col-sm-5"><b>Status</b></div>
        <div class="col-sm-2"><b>Date</b></div>
    </div>
    <div class="row-fluid" *ngFor="let section of sections | keyvalue">
        <application-summary-profile-review
            [section]="summary.profileReview?.[section.key]"
            [sectionLabel]="section.value.label"
            [sectionUrl]="section.value.sref"
            [clientId]="summary.tpo.id">
        </application-summary-profile-review>
    </div>
</div>
