import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    GenericMenuItem,
    GenericMenuComponent,
    AnyLink,
} from 'commons/components/navigation/menu/generic-menu.component';
import { combineLatest, identity, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface GenericSideMenuItem<T = AnyLink> extends GenericMenuItem<T> {
	items?: GenericSideMenuItem<T>[]; // override with extended type
	hasAlert?: Observable<boolean>; // leave empty for children hasAlert to combine
	badge?: string;
}

export const combineAlerts = (alerts: Observable<boolean>[]) =>
	combineLatest(alerts).pipe(
		map((values) => values.some(identity)),
	);

@Component({
	selector: 'generic-side-menu',
	templateUrl: '../generic-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent<U extends AnyLink = AnyLink, T extends GenericSideMenuItem<U> = GenericSideMenuItem<U>>
	extends GenericMenuComponent<U, T> implements OnInit, OnDestroy {
	constructor(
		changeDetector: ChangeDetectorRef,
		stateService: StateService,
	) {
		super(changeDetector, stateService);
	}

	// adds coalesce for hasAlert observables for parent items
	protected traverseChildrenInner(item: T): T {
		// eslint-disable-next-line
		let { hasAlert, items, ...rest } = super.traverseChildrenInner(item);

		if ( !(hasAlert instanceof Observable) && items.length ) {
			const childrenAlerts = items
				.map(({hasAlert}) => hasAlert)
				.filter(hasAlert => hasAlert instanceof Observable);

			hasAlert = combineAlerts(childrenAlerts).pipe(
				takeUntil(this.destroyed$),
			);
		}

		return { ...rest, items, hasAlert } as T;
	}
}
