import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import {
    ScorecardAssignedUser,
    ScorecardReview,
    ScorecardStatus,
    ScorecardStatusTransition,
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-reviews.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { ScorecardReviewsTableComponent } from '../scorecard-reviews-table.component';


@Component({
    templateUrl: './scorecard-review-item.component.html',
    selector: 'scorecard-review-item',
})
export class ScorecardReviewItemComponent implements OnInit {
    @Input() reviewItem: ScorecardReview;
    @Input() assignedUsers: ScorecardAssignedUser[];
    @Input() statusTransitions: ScorecardStatusTransition[];

    @Output() refreshPage: EventEmitter<void> = new EventEmitter<void>();

    statuses: ScorecardStatus[];

    resolved: boolean = true;
    editingAssignedUser: boolean;
    editingStatus: boolean;
    notEditable: boolean = false;

    reviewAssignedForm = new RealmFormGroup({
            assignedId: new RealmFormControl(
                'assignedId',
                {
                    label: 'assignedId',
                },
                Validators.required,
            ),
        },
    );

    reviewStatusForm = new RealmFormGroup({
        statusId: new RealmFormControl(
            'statusId',
            {
                label: 'statusId',
            },
            Validators.required,
        ),
    });

    srefParams: any = {};

    constructor(
        public scorecardService: ScorecardService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.srefParams = {
            id: this.reviewItem.tpoId,
            reviewId: this.reviewItem.id,
            [ScorecardReviewsTableComponent.listName]: null,
        };

        this.reviewAssignedForm.patchValue({assignedId: this.reviewItem.assignedId});
        this.reviewStatusForm.patchValue({statusId: this.reviewItem.statusId});

        this.populateListOfStatuses();
    }

    populateListOfStatuses() {
        const reviewStatusTransition: ScorecardStatusTransition = this.statusTransitions[this.reviewItem.statusId];
        const currentStatus: ScorecardStatus = {id: this.reviewItem.statusId, name: this.reviewItem.statusName};
        this.notEditable = !reviewStatusTransition.canChangeAssignee;
        if (!this.notEditable) {
            this.statuses = reviewStatusTransition.availability.concat(currentStatus);
        }

    }

    toggleAssignedUserEditing() {
        this.editingAssignedUser = !this.editingAssignedUser;
    }

    async saveAssignedUser() {
        this.resolved = false;
        try {
            await this.scorecardService.updateAssignedUser(this.reviewItem.id, {id: this.reviewAssignedForm.get('assignedId').value}).toPromise();
            this.refreshPage.emit();
        } finally {
            this.editingAssignedUser = false;
            this.resolved = true;
        }
    }

    toggleStatusEditing() {
        this.editingStatus = !this.editingStatus;
    }

    async saveStatus() {
        this.resolved = false;
        try {
            await this.scorecardService.updateStatus(this.reviewItem.id, {id: this.reviewStatusForm.get('statusId').value}).toPromise();
            this.refreshPage.emit();
        } finally {
            this.editingStatus = false;
            this.resolved = true;
        }
    }
}
