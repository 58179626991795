<div class="modal-header">
	<button type="button" *ngIf="!saving" class="close pull-right" aria-label="Close" (click)="cancel()">&times;</button>
	<h4 class="modal-title text-center">
		<span class="glyphicon glyphicon-exclamation-sign"></span> WARNING
	</h4>
</div>
<div class="remote-resource" [class.resolved]="!saving">
	<div class="modal-body">
		<div class="alert alert-danger" *ngIf="error">{{error}}</div>
		<div class="text-center text-big">
			The action of Discontinuing a document <span class="text-danger">CANNOT</span> be undone!
		</div>
	</div>
	<div class="modal-footer">
		<div class="btn-group-lg  text-center">
			<button type="button" (click)="confirm()" class="btn btn-danger">Discontinue</button>
			<button type="button" (click)="cancel()" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>
