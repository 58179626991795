import { map } from 'lodash';
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator } from 'commons/validators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AccessibleCategories, InvestorClientCommentsService } from './client-comments.service';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';


@Component({
	templateUrl: './client-comments.component.html',
})
export class InvestorClientComments {
	User: UserProfile;
	commentTypes: NgResourceArray<CommentType> = [];
	typeDescriptions: { [code: string]: CommentType };
	accessibleCategories: NgResourceArray<AccessibleCategories>;
	commentsForm: RealmFormGroup = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{ label: 'Comment', updateOn: 'change' },
			Validators.compose([ Validators.required, MaxLengthValidator(2000) ]),
		),
		commentType: new RealmFormControl(
			'commentType',
			{ label: 'commentType', value: 'PUBLIC', updateOn: 'change' },
			[Validators.required],
		),
	});
	tpoId: number;

	constructor(
		public stateService: StateService,
		public userService: UserService,
		public commentsService: InvestorClientCommentsService,
	) {
		this.User = userService.profile;

		this.commentTypes = this.commentsService.comments.commentType();
		this.commentTypes.$promise.then((types) => {
			this.typeDescriptions = types.reduce((result, value) => {
				result[value.code] = value.description;
				return result;
			}, {});
		});

		this.tpoId = this.stateService.params.id;
		this.accessibleCategories = this.commentsService.accessibleCategories.query({ tpoId: this.tpoId });
	}

	processFilterParams(filterValue) {
		return filterValue?.length ? map(filterValue, 'code') : null;
	}

	get commentsResource(): any {
		return {
			query: (listParams) => {
				const params = { ...listParams, tpoId: this.tpoId };
				return this.commentsService.comments.query(params);
			},
			add: (listParams, data, successHandler, ererrorHandlerror) => {
				const params = { ...listParams, tpoId: this.tpoId };
				return this.commentsService.comments.add(params, data, successHandler, ererrorHandlerror);
			},
		}
	}
}
