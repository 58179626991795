import { ColumnConfig } from 'commons/mixins';

export const columnsConfig: ColumnConfig[] = [
	{
		id: 'approvalStatus',
		name: 'Relationship Status',
	},
	{
		id: 'expirationDate',
		name: 'Expiration Date',
		isDefault: true,
	},
	{
		id: 'accountExecutive',
		name: 'Assigned',
		isDefault: true,
	},
	{
		id: 'statusDate',
		name: 'App Status Date',
		isDefault: true,
	},
	{
		id: 'createdDate',
		name: 'Created Date',
	},
	{
		id: 'lenderName',
		name: 'Partner',
	},
	{
		id: 'optionalStatus',
		name: 'Optional Status',
	},
	{
		id: 'applicationType',
		name: 'Application Type',
	},
];
