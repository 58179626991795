import { CodeValueService } from 'shared/code-value.service';

export abstract class StaticValuesService {
	get: (params?, success?, fail?) => any;
}

export const StaticValuesProvider = {
	provide: StaticValuesService,
	useClass: CodeValueService,
};
