import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIRouterGlobals } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { pick } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { CommentsResourceInterface } from 'commons/components/comments';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator } from 'commons/validators';
import { NgResourceObject } from 'commons/interfaces';

import { AuditsService } from 'shared/social-compliance/audits/audits.service';

import { reviewStatuses } from '../../../review-statuses';
import {
	AssigneeModalComponent,
	AuditStatusModalComponent,
	ReviewStatusModalComponent,
} from '.';
import { AuditReview } from '../audits.service.interface';

@Component({
	templateUrl: './audits-information.component.html',
	preserveWhitespaces: true,
})
export class AuditsInformationComponent {
	User: UserProfile
	review: NgResourceObject<AuditReview>;
	contact: any = {};
	reviewStatuses = reviewStatuses;

	commentsForm: RealmFormGroup = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{ label: 'Comment', updateOn: 'change' },
			Validators.compose([ Validators.required, MaxLengthValidator(2000) ]),
		),
	});

	constructor(
		{ profile: User }: UserService,
		public routerGlobals: UIRouterGlobals,
		public auditsService: AuditsService,
		public modalService: BsModalService,
	) {
		this.User = User;
		const { reviewId } = this.routerGlobals.params;
		this.review = this.auditsService.audit(
			{ reviewId },
			() => {
				this.updateContact();
			}
		);
	}

	updateContact(): void {
		this.contact = pick(this.review, [ 'contactName', 'contactType', 'contactId', 'tpoId' ]);
	}

	editReviewStatus(): void {
		const { id: reviewId } = this.review;
		const initialState = {
			review: this.review,
			statuses: this.auditsService.reviewStatuses(),
			update: (status: any): Promise<any> => (
				this.auditsService.updateReviewStatus(
					{ reviewId },
					status
				).$promise
					.then((data) => {
						this.review = data;
					})
			),
		};
		this.modalService.show(
			ReviewStatusModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			}
		);
	}

	editAuditStatus(): void {
		const { id: reviewId } = this.review;
		const initialState = {
			review: this.review,
			getStatuses: (): any => (
				this.auditsService.auditStatuses()
			),
			update: (status: any): Promise<any> => (
				this.auditsService.updateAuditStatus(
					{ reviewId },
					status
				).$promise
					.then((data) => {
						this.review = data;
					})
			),
			create: (status: string, condition: string): Promise<any> => (
				this.auditsService.createAuditStatus(
					null,
					{
						status,
						condition,
					}
				).$promise
			),
		};
		this.modalService.show(
			AuditStatusModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			}
		);
	}

	editReviewAssigned(): void {
		const { id: reviewId } = this.review;
		const initialState = {
			review: this.review,
			assignees: this.auditsService.assignees({ reviewId }),
			update: (assignee: any): Promise<any> => (
				this.auditsService.updateAssignee(
					{ reviewId },
					assignee
				).$promise
					.then((data) => {
						this.review = data;
					})
			),
		};
		this.modalService.show(
			AssigneeModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			}
		);
	}

	getCommentResource(type: 'comment' | 'message'): CommentsResourceInterface {
		return this.auditsService.getCommentsResource(this.routerGlobals.params.reviewId, type);
	}

    get canEditAssignee(): boolean {
        return (
            (this.User.isComergence && this.review.reviewStatus != 'C' && this.review.auditStatus?.condition != 'C') ||
            (this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') && this.review.auditStatus?.condition != 'C')
        );
    }
}
