import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LenderHomeService, ShareRequest } from 'lender/home/lender-home.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';

@Component({
    templateUrl: './request-review.modal.html',
})
export class RequestReviewModalComponent implements OnInit {
    id: number;
    resolved = false;
    request: ShareRequest;
    form = new RealmFormGroup({
        check: new RealmFormControl(
            'check',
            {
                label: 'Check',
                updateOn: 'change',
            },
            Validators.requiredTrue,
        ),
    });
    onFinalize: () => void;

    constructor(
        public readonly modalRef: BsModalRef,
        private readonly lenderHomeService: LenderHomeService,
    ) {
    }

    async ngOnInit() {
        try {
            this.request = await this.lenderHomeService.getShareRequest(this.id).toPromise();
            this.resolved = true;
        } finally {
            this.resolved = true;
        }
    }

    async finalize(accepted: boolean ) {
        if (this.form.valid || !accepted) {
            try {
                this.resolved = false;
                await this.lenderHomeService.finalizeShareRequest(this.id, accepted).toPromise();
                this.modalRef.hide();
                this.onFinalize?.();
            } finally {
                this.resolved = true;
            }
        }
    }
}

