<div class="remote-resource" [class.resolved]="!resolving">
    <h3 class="flex-row gap-m">
        <span>Contacts</span>
        <segmented-controls
            [items]="filters.previous"
            [value]="params.previous"
            (selected)="setFilter({previous: $event})"
        ></segmented-controls>

        <div *ngIf="hasPermission('MANAGE_CONTACT')" class="flex-grow text-right">
            <a class="btn btn-primary" fixedSref=".new">
                <span class="glyphicon glyphicon-plus small-icon"></span> Add Contact
            </a>
        </div>
    </h3>

    <notification></notification>

    <div class="filters-rows">
        <div class="row-fluid filter-labels">
            <div class="col-sm-3">
                <span class="text-muted small">Type</span>
            </div>
            <div class="col-sm-a"></div>
        </div>
        <div class="row-fluid filter-selectors">
            <div class="col-sm-3">
                <ng-select
                    [ngModel]="params.contactType"
                    (change)="setFilter({contactType: $event.value})"
                    [items]="filters.contactType"
                    [disabled]="!hasPermission('FILTER_CONTACT')"
                    bindValue="value"
                    bindLabel="title"
                    [clearable]="false"
                    placeholder="All">
                </ng-select>
            </div>
            <div class="col-sm-9">
                <div class="flex-row">
                    <div class="flex-grow"></div>
                    <div>
                        <a class="btn btn-tertiary btn-icon" [href]="exportUrl" target="_blank"><span class="c-icon c-icon-download-alt"></span></a>
                        <a *ngIf="hasPermission('MANAGE_CONTACT')" class="btn btn-primary" uiSref="people.contacts-import.upload">Import</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-sm-4">
                <search-list placeholder="Search by any parameter"></search-list>
            </div>
            <sortable-header class="col-sm-2" columnName="nmlsId"><span class="two-liners">NMLS ID</span></sortable-header>
            <sortable-header class="col-sm-2" columnName="phone">Phone</sortable-header>
            <sortable-header class="col-sm-3" columnName="email">Email</sortable-header>
            <div class="col-sm-a"></div>
        </div>
        <div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
            <div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see
                all results
            </div>
        </div>
        <div class="row-fluid" *ngFor="let item of list | async">
            <div class="col-sm-4 loan-margin">
                <i *ngIf="item.isMobileUser" class="c-icon c-icon-phone" placement="top" tooltip="Mobile User"></i>
                <!-- TODO: check contact-list template, it has .lender-individual route, normalize crm routes -->
                <a
                    fixedSref=".:contactId.information"
                    [uiParams]="{ contactId: item.contactId }"
                    class="text-bigger"
                >{{item.fullName}}</a>
                <br>
                <span class="text-light">{{item.officerDesignation?.join(', ')}}</span>
            </div>
            <div class="col-sm-2">
                <a
                    *ngIf="item.nmlsId"
                    fixedSref="account.licenses.nmls.:nmlsId"
                    [uiParams]="{ nmlsId:item.nmlsId }"
                >{{item.nmlsId || '&ndash;'}}</a>
                <span *ngIf="!item.nmlsId">{{item.nmlsId || '&ndash;'}}</span>
            </div>
            <div class="col-sm-2">{{item.phone || '&ndash;' | tel}}</div>
            <div class="col-sm-3 break-word">
                <a [aDisabled]="!item.email" href="mailto:{{item.email}}">{{item.email || '&ndash;'}}</a>
            </div>
            <div class="col-sm-1 text-right">
                <div *ngIf="item.isPrimaryContact">
                    <span class="glyphicon glyphicon-star action" tooltip="Notifications" placement="left"></span>
                </div>
                <contact-role [contact]="item"></contact-role>
            </div>
        </div>
        <div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
            <span class="col-sm-12 text-muted text-bigger text-center">
                {{noMatches ? 'No matches found' : 'No contacts'}}
            </span>
        </div>
    </div>

    <br/>

    <paginator [sizes]="[10,25,50]"></paginator>
</div>
