<generic-history
    [pageTitle]="'History'"
    [useCustomHistoryEntryLine]="true"
    [historyActionsStrategy]="historyStrategy"
    [listName]="listName"
></generic-history>

<ng-template #entryTemplate let-item>
    <div class="col-md-4">
        {{item.date | date: 'MM/dd/yyyy HH:mm'}}
    </div>
    <div class="col-md-4">
        <a *ngIf="item.bulkRequestID" (click)="openModal(item.bulkRequestID)">
            {{item.action}}
        </a>
        <div *ngIf="!item.bulkRequestID">
            {{item.action}}
        </div>
    </div>
    <div class="col-md-4">
        {{item.actionBy}}
    </div>
</ng-template>
