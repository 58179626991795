import { Injectable } from '@angular/core';
import { AccountInProgressQuestionnairesIssueStatus } from './account/account-in-progress-questionnaires-issue-status';
import { HasOutstandingRequiredDocumentsIssueStatus } from './documents/has-outstanding-required-documents-issue-status';
import { ListingAlertsIssueStatus, ListingGroupIssueStatus } from './social-media/listings';
import { SocialComplianceOrderPaymentFailedIssueStatus } from './social-media/social-compliance/social-compliance-order-payment-failed-issue-status';
import { SocialComplianceScheduleIssueStatus } from './social-media/social-compliance/social-compliance-schedule-issue-status';
import { HasPastDueInvoiceAlertStatus } from './social-media/subscription/has-past-due-invoice-alert-status';
import { FinancialAlertStatus } from 'tpo/status/financial/financial-alert-status';


@Injectable({ providedIn: 'root' })
export class TpoIssuesStatusService {
	constructor(
        public readonly accountInProgressQuestionnairesIssueStatus: AccountInProgressQuestionnairesIssueStatus,
        public readonly hasOutstandingRequiredDocumentsIssueStatus: HasOutstandingRequiredDocumentsIssueStatus,
        public readonly socialComplianceScheduleIssueStatus: SocialComplianceScheduleIssueStatus,
        public readonly listingGroupIssueStatus: ListingGroupIssueStatus,
        public readonly listingAlertsIssueStatus: ListingAlertsIssueStatus,
        public readonly hasPastDueInvoiceAlertStatus: HasPastDueInvoiceAlertStatus,
        public readonly socialComplianceOrderPaymentFailedIssueStatus: SocialComplianceOrderPaymentFailedIssueStatus,
        private readonly financialAlertStatus: FinancialAlertStatus,
    ) {
	}

    public readonly account = {
        questionnaires: this.accountInProgressQuestionnairesIssueStatus,
    };

    public readonly documents = {
        outstandingRequired: this.hasOutstandingRequiredDocumentsIssueStatus,
    };

    public readonly financial = this.financialAlertStatus;

    public readonly socialMedia = {
        socialCompliance: {
            schedule: this.socialComplianceScheduleIssueStatus,
            orderPaymentFailed: this.socialComplianceOrderPaymentFailedIssueStatus,
        },
        listings: {
            groups: this.listingGroupIssueStatus,
            alerts: this.listingAlertsIssueStatus,
        },
        subscription: {
            hasPastDueInvoice: this.hasPastDueInvoiceAlertStatus,
        },
	};
}
