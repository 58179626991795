import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ChannelAlert,
    ChannelAlertsGrouped,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.interface';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';

@Component({
    templateUrl: 'manage-channel-alert-group.component.html',
    selector: 'manage-channel-alert-group',
})
export class ManageChannelAlertGroupComponent {
    @Input() channelAlertGrouping: ChannelAlertsGrouped[];
    @Input() editingDisabled: boolean = false;
    @Input() canEditAlerts: boolean;
    @Input() resolving: boolean;

    @Output() editingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saveEvent: EventEmitter<ChannelAlert[]> = new EventEmitter<ChannelAlert[]>();

    editing: boolean = false;
    editedAlerts: ChannelAlert[];

    alertsFormsValid: boolean = true;

    constructor() {
    }

    clearAndPopulateEditedAlerts() {
        this.editedAlerts = [];
        this.channelAlertGrouping.forEach((groupedAlerts) => {
            groupedAlerts.alerts.forEach((alert) => {
                this.editedAlerts.push(alert);
            });
        });

    }

    valueEdited(editedAlert: ChannelAlert) {
        if(!this.editedAlerts || this.editedAlerts.length == 0) {
            this.clearAndPopulateEditedAlerts();
        }
        const index = this.editedAlerts.findIndex((alert) => {
            return alert.alertCategoryCode == editedAlert.alertCategoryCode
        });
        this.editedAlerts[index] = editedAlert;
    }

    toggleEditing() {
        this.editing = !this.editing;
        this.editingState.emit(this.editing);
    }

    save() {
        this.saveEvent.emit(this.editedAlerts);
        this.editedAlerts = [];
        this.toggleEditing();
    }

    cancel() {
        this.editedAlerts = [];
        this.toggleEditing();
    }

    setAlertFormValid(isValid: boolean) {
        this.alertsFormsValid = isValid;
    }

    isSavingDisabled(): boolean {
        return (!this.editedAlerts || !this.alertsFormsValid || this.editedAlerts.length == 0)
    }
}
