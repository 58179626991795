import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { ListParams } from 'commons/components/new-list/list.component';

import { UserService } from 'angularjs-providers/user.provider';

import { AccountIdentifier, AccountInformation, AccountInformationService } from 'shared/account/company-information/account-information.service';

import { Channel } from 'lender/documents/documents.interface';

import { Entity } from 'tpo/documents/documents.interface';

import { ChangedLocationItem, LocationListItem } from './single/locations/locations.interface';
import { Availability, Transitions } from '../../clients.interface';
import {
    ChannelApplicationHistoryList,
} from 'lender/clients/$id/channels/applications/channel-application.interface';

declare let apiPath: string;

export type TextEntity = {
    id: string;
    name: string;
}

export type ChannelSummary = {
    prospectStatusForUser: string,
    approvalStatus: TextEntity
    accountExecutive: Entity;
    inviteStatus: TextEntity;
    channel: Channel & { accessible: boolean };
}

export type Person = {
    id: number;
    fullName: string;
}

export type InviteHistoryItem = {
    actionAt: number;
    actionBy: string;
    inviteStatus: typeof TPOInviteStatuses[keyof typeof TPOInviteStatuses];
}

export const TPOInviteStatuses = {
    SENT: 'Sent',
    ACCEPTED: 'Accepted',
    DECLINED: 'Declined',
    CANCELLED: 'Cancelled',
};

export type ChannelInfo = {
    accountExecutive: Person;
    approvalRenewalDate: string;
    approvalStatus: TextEntity;
    approvalStatusChangedDate: number;
    channelId: number;
    channelName: string;
    channelSID: string;
    channelUID: string;
    isLocationApprovalStatusEditable: boolean;
    maxRenewalDate: number;
    statusReason: StatusReason;
}

export interface StatusReason {
    id?: number,
    reason: string,
}

@Injectable()
export class ChannelsService {
    constructor(
        private readonly http: RealmHttpClient,
        private readonly user: UserService,
        private readonly accountInformationService :AccountInformationService,
    ) {
    }

    get lenderId(): number {
        return this.user.profile.organization.id;
    }

    getList<T extends ChannelSummary[] = ChannelSummary[]>(params: AccountIdentifier): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/channel-summary`,
            params,
        );
    }

    getBase<T extends Entity = Entity>(channelId: number): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/channels/${channelId}/base`,
        );
    }

    sendInvite<T extends void = void>(tpoId: number, channelId: number, body: { accountExecutiveId: number, contactId: number, message: string }): Observable<T> {
        return this.http.request<T>(
            'POST',
            `${apiPath}/lenders/${this.lenderId}/channels/${channelId}/tpos/${tpoId}/invites`,
            {},
            body,
        );
    }

    getInviteHistory<T extends InviteHistoryItem[] = InviteHistoryItem[]>(tpoId: number, channelId: number, params: ListParams): Observable<PagedData<T>> {
        return this.http.pagedRequest<T>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/channels/${channelId}/tpos/${tpoId}/invites/history`,
            params,
        );
    }
    // /api/rest/organization-channels/290/tpos/47072?noCache=1682926359438
    getChannelInfo<T extends ChannelInfo = ChannelInfo>(tpoId: number, channelId: number): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/tpos/${tpoId}`,
        );
    }

    /**
     * TODO: Remove me. This method appears to be unused.
     * @deprecated
     */
    // /api/rest/organization-channels/290/tpos/47072?noCache=1682926359438
    saveChannelInfo<T extends ChannelInfo = ChannelInfo>(tpoId: number, channelId: number, body: T): Observable<T> {
        return this.http.request<T>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/tpos/${tpoId}`,
            {},
            body
        ).pipe(
            tap(() => {
                // Update badges after channel save
                this.accountInformationService.updateBadges();
            })
        );
    }

    renewApplication<T extends ChannelInfo = ChannelInfo>(tpoId: number, channelId: number): Observable<T> {
        return this.http.request<T>(
            'DELETE',
            `${apiPath}/organization-channels/${channelId}/tpos/${tpoId}`,
        );
    }

    // /api/rest/organization-channels/264/tpos/47014/restore
    restoreChannel<T extends ChannelInfo = ChannelInfo>(tpoId: number, channelId: number, body: {restoreToStatusId: string, restoreDate: string}): Observable<T> {
        return this.http.request<T>(
            'POST',
            `${apiPath}/organization-channels/${channelId}/tpos/${tpoId}/restore`,
            {},
            body
        ).pipe(
            tap(() => {
                // Update badges after channel save
                this.accountInformationService.updateBadges();
            })
        );
    }

    getStatusTransitions(): Observable<Transitions> {
        return this.http.request<Transitions>(
            'GET',
            `${apiPath}/applications/approval-statuses/transitions`,
        );
    }

    getRenewableStatuses(): Observable<Availability[]> {
        return this.http.request<Availability[]>(
            'GET',
            `${apiPath}/applications/approval-statuses/renewable`,
        );
    }

    getAccountExecutives<T extends Person[] = Person[]>(channelId: number): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/account-executives`,
        );
    }

    getInviteContacts<T extends Person[] = Person[]>(tpoId: number): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/tpos/${tpoId}/invite-individual-contacts`,
        );
    }

    getAvailableChannels<T extends Channel[] = Channel[]>(tpoId: number): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/channels/invitable/tpos/${tpoId}`,
        );
    }

    getChannelLocations<T extends LocationListItem[] = LocationListItem[]>(tpoId: number, channelId: number, params: ListParams): Observable<PagedData<LocationListItem[]>> {
        return this.http.pagedRequest<LocationListItem[]>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/tpos/${tpoId}/channels/${channelId}/location-statuses`,
            params,
        );
    }

    getChannelActivityLogs<T extends ChannelApplicationHistoryList[] = ChannelApplicationHistoryList[]>(tpoId: number, channelId: number, params: ListParams): Observable<PagedData<ChannelApplicationHistoryList[]>> {
        return this.http.pagedRequest<ChannelApplicationHistoryList[]>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/tpos/${tpoId}/channels/${channelId}/activity-logs`,
            params,
        );
    }

    setChannelLocation(tpoId: number, channelId: number, changedLocationItem: ChangedLocationItem): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/lenders/${this.lenderId}/tpos/${tpoId}/channels/${channelId}/location-statuses`,
            null,
            [changedLocationItem],
        );
    }

    // TODO: Not currently used, but may be used again with upcoming work?
    setChannelLocationAssignee(tpoId: number, channelId: number, assignee: Person): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/lenders/${this.lenderId}/tpos/${tpoId}/channels/${channelId}/location-statuses/assigned`,
            null,
            assignee,
        );
    }

    getStatusReasons<T extends StatusReason[] = StatusReason[]>(): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/status-reasons`,
        );
    }

    setStatusReason(tpoId: number, channelId: number, reason: StatusReason): Observable<StatusReason> {
        return this.http.request<StatusReason>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/tpos/${tpoId}/status-reason`,
            {lenderId: this.lenderId},
            reason || {}, //for empty field
        );
    }
}

const channelInfo: (UIRouter, LocationsService, AccountInformation) => Promise<ChannelInfo> = (router: UIRouter, channelsService: ChannelsService, account: AccountInformation) => {
    const { channelId } = router.globals.transition.params();
    return channelsService.getChannelInfo(account.identifier.tpoId, channelId).pipe(
        shareReplay(1),
    ).toPromise();
};

export const channelInfoResolver = { token: 'channelInfo', deps: [UIRouter, ChannelsService, 'lenderInfo'], resolveFn: channelInfo };
