import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { StatusReason } from 'lender/clients/$id/channels/channels.service';
import { ChangeStatusReasonModal } from './change-status-reason.modal';

@Component({
    selector: 'status-reason',
    templateUrl: './status-reason.component.html',
})
export class StatusReasonComponent {
    @Input() channelId!: number;
    @Input() tpoId!: number;
    @Input() status!: StatusReason;
    @Input() canEdit: boolean;
    @Input() inlineEditStyle: boolean = false;
    @Output() onUpdate = new EventEmitter<StatusReason>();

    private modalRef: BsModalRef;

    constructor(
        private readonly modalService: BsModalService,
    ) {
    }

    showEditModal() {
        const { status, channelId, tpoId } = this;
        const onUpdate = (newStatus: StatusReason) => {
            this.onUpdate.emit(newStatus);
        };

        this.modalRef = this.modalService.show(
            ChangeStatusReasonModal,
            {
                initialState: { status, onUpdate, channelId, tpoId },
                backdrop: 'static',
                class: 'modal-new modal-smd',
            },
        );
    }
}
