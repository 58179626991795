import { Component, Input } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { shareReplay } from 'rxjs/operators';
import { CustomerInformation } from '../services/customer-list/customer-information.service.interface';
import { CustomerService } from '../services/customer-list/customer.service';


export function customerInformation$(router: UIRouter, customerService: CustomerService): Promise<CustomerInformation> {
    const params = router.globals.transition.params();

    return customerService
        .getCustomer(params.customerType, params.organizationId)
        .pipe(
            shareReplay(1)
        )
        .toPromise();
}

@Component({
    templateUrl: './ccm-customers.component.html',
})
export class CcmCustomersComponent {
	@Input() customerInformation: CustomerInformation;
}
