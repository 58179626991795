import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { UserService } from 'angularjs-providers/user.provider';
import { WholeNumberValidator } from 'commons/validators/whole-number.validator';
import { Channel, ChannelCardDetail } from 'lender/manage-channels/manage-channel-card.interface';
import { Subject } from 'rxjs';


@Component({
    templateUrl: 'channel-card-ownership-and-description.component.html',
    selector: 'channel-card-ownership-and-description',
})
export class ChannelCardOwnershipAndDescriptionComponent implements OnInit, OnDestroy {
    @Input() showTruncatedDescription: boolean = false;
    @Input() channel: Channel;
    @Output() patchTriggered: EventEmitter<any> = new EventEmitter();
    @Output() channelSaving: EventEmitter<boolean> = new EventEmitter();
    @Output() channelUpdated: EventEmitter<number> = new EventEmitter();

    ownershipVerificationId: string;
    descriptionDescriptionId: string;

    protected readonly ownershipPercentageValidators: ValidatorFn[] = [
        WholeNumberValidator(),
        Validators.min(0),
        Validators.max(100),
    ];
    protected readonly descriptionValidators: ValidatorFn[] = [
        Validators.maxLength(300),
    ];

    canManageChannelSettings: boolean;

    private readonly destroyed$: Subject<void> = new Subject();

    constructor(
        private readonly userService: UserService,
    ) {
    }

    // Override
    ngOnInit(): void {
        this.canManageChannelSettings = this.userService.profile.can('MANAGE_CHANNEL_SETTINGS');

        this.ownershipVerificationId = `ownershipVerificationId_${this.channel.id}`;
        this.descriptionDescriptionId = `descriptionDescriptionId_${this.channel.id}`;
    }

    // Override
    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    async triggerPatch(pseudoPatch: any): Promise<void> {
        this.patchTriggered.emit(pseudoPatch);
    }
}
