import { Injectable, Injector } from '@angular/core';
import { noop } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResource } from 'commons/interfaces';

import {
	ThumbnailFrame,
} from '../posts/$postType/$postId/posts-preview/publisher-attachments-preview/publisher-attachments-previews.service';

declare let apiPath: string;

@Injectable()
export class TpoPublisherAttachmentService {
	attachment: any;
	videoThumbnails: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const defaultParams = {
			tpoId: this.User.profile.organization.id,
			type: '@type',
		};
		const videoThumbnailsPath = `${apiPath}/tpos/:tpoId/:postType/attachments/:attachmentId/video-thumbnails`;

		this.attachment = $resource(`${ apiPath }/tpos/:tpoId/:postType/attachments/:type/files`, { ...defaultParams }, {
			attach: {
				method: 'POST',
				headers: {
					'Content-Type': undefined,
				},
				transformRequest: (item: File) => {
					const data = new FormData();
					data.append('file', item);
					return data;
				},
				cancellable: true,
			},
		});

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/sm45post45resource/uploadThumbnailFilesUsingPOST
		this.videoThumbnails = $resource(`${videoThumbnailsPath}`, { ...defaultParams }, {
			get: {
				method: 'GET',
			},

			uploadThumbnailFiles: {
				url: `${videoThumbnailsPath}/generated/files`,
				method: 'POST',
				transformRequest: (previews: ThumbnailFrame[]) => {
					const data = new FormData();
					previews.forEach((preview) => {
						data.append('files', preview.file);
                        data.append('offsets', preview.offset.toString());
					});
					return data;
				},
				headers: {
					'Content-Type': undefined,
				},
				cancellable: true,
			},

			uploadThumbnailFile: {
				url: `${videoThumbnailsPath}/manual/files`,
				method: 'POST',
				headers: {
					'Content-Type': undefined,
				},
				transformRequest: (preview: Blob) => {
					const data = new FormData();
					data.append('file', preview);
					return data;
				},
				cancellable: true,
			},
		});
	}

	attach = (...args) => this.attachment.attach(...args);
	getThumbnails = (attachmentId: number): NgResource<Thumbnails> => {
		const postType = window.location.hash.includes('template')
			? 'social-media-post-templates'
			: 'social-media-posts';
		return this.videoThumbnails.get({ attachmentId, postType });
	};
	uploadThumbnails = (...args): NgResource<Thumbnails> => this.videoThumbnails.uploadThumbnailFiles(...args);
	uploadThumbnail = (...args): NgResource<Thumbnails> => this.videoThumbnails.uploadThumbnailFile(...args);
}

export interface Thumbnails {
	thumbnailIds: [number, number, number];
	selectedThumbnailId: number | null;
	uploadedManuallyThumbnailId: number | null;
}
