import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator, CompareFieldsValidator } from 'commons/validators';
import { Entity } from 'tpo/documents/documents.interface';
import { LenderApplicationsResourceService } from 'lender/applications/applications.service';

@Component({
    templateUrl: './change-optional-status.modal.html',
})
export class ChangeOptionalStatusModal implements OnInit {
    resolving: boolean = false;
    applicationId: number;
    status: Entity;
    statusList: Entity[];
    isNew = false;
    optionalStatusForm = new RealmFormGroup({
        optionalStatus: new RealmFormControl(
            'optionalStatus',
            {
                label: 'Optional Status',
            },
            DropdownRequiredValidator,
        ),
    });
    newStatusControls = {
        newStatusName: new RealmFormControl(
            'newStatusName',
            {
                label: 'New Status Name',
                updateOn: 'change',
            },
            [
                Validators.required,
            ],
        ),
        confirmNewStatusName: new RealmFormControl(
            'confirmNewStatusName',
            {
                label: 'Confirm New Status Name',
                updateOn: 'change',
            },
            [
                Validators.required,
            ],
        ),
    };
    newStatusForm = new RealmFormGroup(
        this.newStatusControls,
        CompareFieldsValidator(this.newStatusControls.newStatusName, this.newStatusControls.confirmNewStatusName)
    );

    form = this.optionalStatusForm;

    onUpdate: (newStatus: Entity) => void;

    constructor(
        readonly modalRef: BsModalRef,
        protected readonly applicationsService: LenderApplicationsResourceService,
    ) {}

    toggleIsNewMode(): void {
        this.isNew = !this.isNew;
        this.toggleForm();
    }

    toggleForm(): void {
        this.form = this.isNew ? this.newStatusForm : this.optionalStatusForm;
    }

    addStatus(name: string) {
        return { name };
    }

    ngOnInit() {
        this.form.reset({
            optionalStatus: this.status,
        });
    }

    async save() {
        let { optionalStatus }: { optionalStatus: Entity } = this.form.value;
        try {
            this.resolving = true;

            if (this.isNew) {
                const name = this.newStatusForm.get('newStatusName').value;
                optionalStatus = await this.applicationsService.createOptionalStatus({ name }).toPromise();
            }

            await this.applicationsService.setOptionalStatus(this.applicationId, optionalStatus).toPromise();
            this.onUpdate(optionalStatus);

            this.modalRef.hide();
        } catch ({ error }) {
            this.form.setServerError(error);
            this.resolving = false;
        }
    }
}
