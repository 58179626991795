import { Component, EventEmitter, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { ConfirmModalComponent, NotificationModalComponent } from 'commons/components/modals';
import { DocumentComponent, DocumentStatus } from 'shared/documents';
import electronicSignatureModalConfig from 'shared/documents/client-document/electronicSignatureModalConfig';

import { TpoDocument, TpoLibraryDocument } from 'tpo/documents/documents.interface';
import { TpoDocumentsService } from 'tpo/documents/documents.service';

import { TpoDocumentUploadFromLibraryModalComponent } from './upload-from-library-modal';

declare let apiPath: string;

@Component({
	selector: 'tpo-document-list-item',
	templateUrl: './item.component.html',
})
export class TpoDocumentListItemComponent extends DocumentComponent<TpoDocument> {
	@Output() reloadList = new EventEmitter<void>();

	get templateDownloadLink(): string {
		const {
			id: documentId,
			libraryId,
		} = this.document;
		return this.documentsService.getTemplateDownloadLink({
			documentId,
			libraryId,
		});
	}

	get canUpload(): boolean {
		const {
			isActive,
			isEditable,
			isUploadable,
			docusignTemplate,
		} = this.document;
		return (
			isActive
			&& isEditable
			&& isUploadable
			&& !docusignTemplate
		);
	}

	get canSign(): boolean {
		const {
			isActive,
			documentState,
			docusignTemplate,
		} = this.document;
		return (
			isActive
			&& documentState !== DocumentStatus.WAIVED
			&& !!docusignTemplate
		);
	}

	// TODO: reimplement with RsJS shareReplay
	get library(): NgResourceArray<TpoLibraryDocument> {
		if (!this._library) {
			this._library = this.documentsService.getUploadedLibraryDocuments();
		}

		return this._library;
	}
	private _library: NgResourceArray<TpoLibraryDocument>;

	modalRef: BsModalRef;

	constructor(
		private stateService: StateService,
		private userService: UserService,
		private documentsService: TpoDocumentsService,
		private modalService: BsModalService,
	) {
		super(userService);
	}

	// TODO: use shared upload logic
	async confirmUpload(file: File): Promise<void> {
		return new Promise((resolve, reject): void => {
			const { name } = this.document;
			const initialState = {
				title: 'Upload Document',
				message: `
					<div class="modal-message">
						<div class="well bigger">You are about to upload <strong>${file.name}</strong> to this document</div>
						<span class="glyphicon glyphicon-arrow-down"></span>
						<div class="document-card">${name}</div>
					</div>
				`,
				confirmText: 'Confirm',
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});
	}

	async onFileSelect(files: FileList): Promise<void> {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		try {
			await this.confirmUpload(file);

			this.modalRef.content.resolving = true;
			const {
				id: documentId,
				libraryId,
			} = document;
			this.document = (
				documentId
					? await this.documentsService.uploadDocument(
						{ documentId },
						{ file },
					).$promise
					: await this.documentsService.uploadLibraryDocument(
						{ libraryId },
						{ file },
					).$promise
			) as TpoDocument;

			this.actionSuccess();
		} catch (e) {
			if (e) {
				this.document.$error = e.data?.message;
			} else {
				// Promise reject from `confirmUpload`
			}
		}
		this.closeModal();
	}

	async confirmLibrary(): Promise<TpoLibraryDocument> {
		return new Promise((resolve, reject): void => {
			const { document } = this;
			const initialState = {
				document,
				library: this.library,
				onConfirm: (_document: TpoLibraryDocument) => {
					resolve(_document);
				},
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				TpoDocumentUploadFromLibraryModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});
	}

	async chooseFromLibrary(): Promise<void> {
		try {
			const libDoc = await this.confirmLibrary();

			this.modalRef.content.resolving = true;
			const {
				id: documentId,
			} = this.document;
			const params = {
				documentId,
			};
			this.document = await this.documentsService.uploadDocumentFromLibrary(
				params,
				libDoc,
			).$promise;

			this.actionSuccess();
		} catch (e) {
			if (e) {
				this.document.$error = e.data?.message;
			} else {
				// Promise reject from `confirmLibrary`
			}
		}
		this.closeModal();
	}

	async sign(): Promise<void> {
		this.document.$pending = true;
		const {
			id: documentId,
		} = this.document;
		let isSuccess = true;

		const url = `${apiPath}/v2/tpos/${this.userService.profile.organization.id}/docmanagement/${documentId}/docusign`;
		const docuSignWindow = window.open(url, '_blank');
		docuSignWindow.document.title = 'Loading...';
		docuSignWindow.addEventListener('load', () => {
			docuSignWindow.document.body.style.display = 'none';
			const response = JSON.parse(docuSignWindow.document.body.textContent);
			try {
				if (['INVALID_CONNECTION', 'RECIPIENT_NOT_IN_SEQUENCE'].includes(response.errorCode)) {
					isSuccess = false;
					docuSignWindow?.close();
					this.modalRef = this.modalService.show(NotificationModalComponent, electronicSignatureModalConfig);
				}
			} catch (err) {}
		});

		const interval = window.setInterval((() => {
			if (docuSignWindow.closed) {
				window.clearInterval(interval);
				isSuccess && this.reloadList.emit();
			}
		}), 1000);

		this.document.$pending = false;
	}

	showHistory(): void {
		const {
			id: documentId,
			libraryId,
		} = this.document;
		if (documentId) {
			this.stateService.go('documents.list.:documentId.history', { documentId });
		} else {
			this.stateService.go('documents.list.library.:libraryId.history', { libraryId });
		}
	}

	private actionSuccess(): void {
		this.document.$success = true;
		this.userService.getUser();
		setTimeout(() => {
			this.document.$success = false;
		}, 5000);
	}

	private closeModal(): void {
		this.modalRef?.hide();
	}
}
