import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';

import { ConnectAccountModalComponent } from './connect-account-modal';
import { SocialMediaMenuComponent } from 'tpo/social-media/menu/sm-menu.component';
import { LandingPageComponent } from 'shared/social-media/landing-page/landing-page.component';
import { DirectivesModule } from 'commons/directives/directives.module';
import { SubscriptionComponent } from 'tpo/social-media/subscription/subscription.component';
import { RequestDemoButtonComponent } from 'tpo/social-media/request-demo/request-demo-button/request-demo-button.component';
import { SubscriptionPaymentComponent } from 'tpo/social-media/subscription/payment/payment.component';
import { RecurlyModule } from 'commons/recurly';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
        DirectivesModule,
        RecurlyModule,
        FormsModule,
        ReactiveFormsModule,
	],
	declarations: [
		ConnectAccountModalComponent,
		SocialMediaMenuComponent,
        LandingPageComponent,
        SubscriptionComponent,
		RequestDemoButtonComponent,
        SubscriptionPaymentComponent,
	],
	exports: [
		ConnectAccountModalComponent,
		SocialMediaMenuComponent,
        LandingPageComponent,
	],
})
export class SocialMediaModule {}
