import { Component, Input } from '@angular/core';

import { PostsTypeConfig, PostsTypeMap } from './posts-type-config';

@Component({
	selector: 'posts-type-list',
	templateUrl: './posts-type-list.component.html',
})
export class PostsTypeListComponent {
	@Input() sRefName: string = 'postType';
	uiParams: { [sRefName: string]: string };
	postTypes: PostsTypeMap = PostsTypeConfig;

	getParams(id) {
		return {[this.sRefName]: id};
	}

	sortNull = () => null;
}
