import { Component, Input, OnInit } from '@angular/core';
import { ContactList, ContactListDesignatedUser } from '../../../investor-crm-contact-list.interface';


@Component({
    selector: 'investor-crm-contact-list-visibility-list',
    templateUrl: './investor-crm-contact-list-visibility-list.component.html',
})
export class InvestorCrmContactVisibilityListComponent implements OnInit {
    @Input() contactList: ContactList;
    @Input() availableUsers: ContactListDesignatedUser[];

    displayUsers: ContactListDesignatedUser[] = [];

    // Override
    ngOnInit(): void {
        const availableUsersById: Map<number, ContactListDesignatedUser> = this.mapUsersById();
        this.displayUsers = this.buildDisplayUsers(availableUsersById);
    }

    mapUsersById(): Map<number, ContactListDesignatedUser> {
        const availableUsersById: Map<number, ContactListDesignatedUser> = new Map();
        this.availableUsers.forEach((user: ContactListDesignatedUser) => availableUsersById.set(user.id, user));

        return availableUsersById;
    }

    buildDisplayUsers(availableUsersById: Map<number, ContactListDesignatedUser>): ContactListDesignatedUser[] {
        const displayUsers: ContactListDesignatedUser[] = this.contactList.designatedOption.designatedUsers
            .filter((userId: number) => availableUsersById.has(userId))
            .map((userId: number) => availableUsersById.get(userId));
        
        return displayUsers;
    }
}
