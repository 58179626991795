<div class="remote-resource library-content" [class.resolved]="list.$resolved">
	<div class="flex-head mb30">
		<div class="col-fit">
			<ul class="breadcrumbs grey">
				<li>
					<a uiSref="social-compliance.publisher.library.content">Content</a>
				</li>
			</ul>
			<h2 class="title-label">Posts</h2>
		</div>
	</div>
	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-md-6">
				Post
			</div>
			<div class="col-md-2" [ngClass]="getSortClass('sentDate')" (click)="setSort('sentDate')">
				<b>Posted Date</b>
			</div>
			<div class="col-md-2">
				Posted by
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-md-6 clearfix">
				<div class="preview">
					<div class="image-holder">
						<div class="rss-image"
							 *ngIf="item.imagePreviewLink"
							 [style.background-image]="item.imagePreviewLink | urlSanitizer"></div>
						<i *ngIf="!item.imagePreviewLink" class="c-icon c-icon-rss"></i>
					</div>
					<div class="description">
						<show-more [text]="item.text" [maxHeight]="110"></show-more>
					</div>
				</div>
			</div>
			<div class="col-md-2 text-muted">
				{{item.sentDate | date:'MM/dd/yyyy'}}
			</div>
			<div class="col-md-2 text-muted">
				{{item.updatedBy}}
			</div>
			<div class="col-md-1 text-right">
				<a class="btn btn-default" uiSref="social-compliance.publisher.posts.:postType.:postId" [uiParams]="{ postType: 'posted', postId: item.id }">View</a>
			</div>
		</div>
	</div>
</div>
