<div class="document-modal-form">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title">Request Document</h4>
    </div>
    <form [formGroup]="requestForm" (onValidSubmit)="makeRequest()">
        <div class="modal-body remote-resource" [class.resolved]="resolved">
            <form-errors [form]="requestForm"></form-errors>
            <div class="form-group gap-l" has-error>
                <div class="flex-row gap-l">
                    <label class="checkbox-inline">
                        <input type="checkbox" formControlName="notifyClient"/>
                        {{requestForm?.getFieldLabel('notifyClient')}}
                    </label>
                    <label class="checkbox-inline">
                        <input type="checkbox" formControlName="notifyAssigned"/>
                        {{requestForm?.getFieldLabel('notifyAssigned')}}
                    </label>
                </div>
            </div>
            <div class="form-group" has-error>
                <label class="control-label required">
                    {{requestForm?.getFieldLabel('isAudited')}}
                </label>
                <div class="flex-row gap-l">
                    <label class="radio-inline">
                        <input type="radio" formControlName="isAudited" [value]="true"> Audited
                    </label>
                    <label class="radio-inline">
                        <input type="radio" formControlName="isAudited" [value]="false"> Unaudited
                    </label>
                </div>
            </div>
            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label required">
                        {{requestForm?.getFieldLabel('period')}}
                    </label>
                    <div>
                        <ng-select
                            formControlName="period"
                            [items]="quarters"
                            [clearable]="false"
                            placeholder="Select Period"
                            [tabindex]="2"
                        ></ng-select>
                    </div>
                </div>

                <div class="flex-grow form-group" has-error>
                    <label class="control-label required">
                        {{requestForm?.getFieldLabel('year')}}
                    </label>
                    <div>
                        <ng-select
                            formControlName="year"
                            [items]="years"
                            [clearable]="false"
                            placeholder="Select Year"
                            [tabindex]="2"
                            (change)="resetPeriod()"
                        ></ng-select>
                    </div>
                </div>
            </div>
            <div *ngIf="requestForm.controls.notifyClient.value || requestForm.controls.notifyAssigned.value" class="form-group" has-error>
                <label class="control-label required">
                    {{ requestForm?.getFieldLabel('message') }}
                </label>
                <div>
                    <textarea
                        id="message"
                        class="form-control resize-vertical"
                        formControlName="message"
                        [rows]="9"
                        [maxlength]="4000"
                        [tabindex]="2"
                        placeholder="Enter message"
                    ></textarea>
                </div>
                <div class="characters text-muted text-smaller">Symbols: {{getCharsCount()}}</div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="text-right">
                <button type="submit" class="btn btn-primary" [disabled]="hasSnapshot">Request</button>
                <button type="button" (click)="close()" class="btn btn-default">Cancel</button>
            </div>
        </div>
    </form>
</div>
