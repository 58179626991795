<form class="remote-resource" [ngClass]="{resolved: resolved, curtain: curtain}"
	  [formGroup]="relatedContactForm" (onValidSubmit)="save()">

	<h3>Related Contact
		<span class="activity-buttons">
			<button *ngIf="!isNew && contact?.organizationContactId && !editing"
					type="button" class="btn btn-secondary btn-icon" (click)="remove()">
				<span class="glyphicon glyphicon-trash"></span>
			</button>
			<button *ngIf="!editing && !isNew && contact?.organizationContactId"
					class="btn btn-primary" (click)="edit()">
				<span class="glyphicon glyphicon-pencil text-smaller"></span> Edit
			</button>

			<span *ngIf="editing && !isNew">
				<button type="submit" class="btn btn-primary" tabindex="20">Save</button>
				<button class="btn btn-default" (click)="cancelEdit()" tabindex="20">Cancel</button>
			</span>

			<button *ngIf="!isNew && !editing && !contact?.organizationContactId"
					class="btn btn-primary" (click)="edit()">
				<span class="glyphicon glyphicon-plus small-icon"></span> Add
			</button>
		</span>
	</h3>

	<form-errors *ngIf="editing" [form]="relatedContactForm"></form-errors>

	<div class="rows-striped">
		<div class="row-fluid">
			<div class="col-md-12">
				<div class="form-group row">
					<label class="coldp-sm-3 control-label">Contact</label>
					<div [ngClass]="editing ? 'coldp-sm-14' : 'coldp-sm-18'">

						<div class="has-feedback" *ngIf="editing && (!isNew || !isContactActivityDetails)">
							<ng-select
									[items]="relatedContactHints?.$promise | async"
									(change)="onSelect($event)"
									[loading]="relatedLoading"
									[typeahead]="relatedContactInput"
									formControlName="related"
									placeholder="Search by Contact Name">

								<ng-template ng-label-tmp let-item="item">
									<i class="text-light glyphicon glyphicon-search"></i>&nbsp;
									<span class="text">{{item.contactName}}<span *ngIf="item.companyName" class="text-light small">, {{item.companyName}}</span></span>
								</ng-template>

								<ng-template ng-option-tmp let-item="item">
									{{item.contactName}} <div *ngIf="item.companyName" class="text-light text-smaller"> {{item.companyName}}</div>
								</ng-template>

								<ng-template ng-typetosearch-tmp>
									<div class="ng-option disabled">
										Please enter at least 3 characters to start search
									</div>
								</ng-template>
								<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
									<div class="ng-option disabled" *ngIf="searchTerm.length > 2">
										No contact found for "{{searchTerm}}"
									</div>
									<div class="ng-option disabled" *ngIf="searchTerm.length <= 2">
										Please enter at least 3 characters to start search
									</div>
								</ng-template>
								<ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
									<div class="ng-option disabled">
										Searching for "{{searchTerm}}"
									</div>
								</ng-template>
							</ng-select>
						</div>

						<div class="control-value"
							 *ngIf="!editing && contact || (isNew && isContactActivityDetails && contact)" [ngSwitch]="contact.contactType">
							<!-- TODO: normalize routing for crm -->
							<!-- <contact-link contact="contact" disabled="contact.isDeleted" blank="true" crm="true"></contact-link> -->
							<a *ngSwitchCase="TYPE.CRM" target="_blank" [aDisabled]="contact.isDeleted" uiSref="crm.contacts.:contactId.information" [uiParams]="{contactId: contact.contactId}">{{contact.contactName}}</a>

                            <ng-container *ngSwitchDefault>
                                <a *ngIf="contact.tpoId" target="_blank"
                                   [aDisabled]="contact.isDeleted"
                                   uiSref="client.:id.contacts.:contactId.information"
                                   [uiParams]="{id: contact.tpoId, contactId: contact.contactId}">{{contact.contactName}}</a>

                                <a *ngIf="!contact.tpoId" target="_blank"
                                   [aDisabled]="contact.isDeleted"
                                   uiSref="nmls.client.:id.contacts.:contactId.information"
                                   [uiParams]="{id: contact.companyNmlsId, contactId: contact.contactId}">{{contact.contactName}}</a>
                            </ng-container>

							<small *ngIf="contact.companyName">, {{contact.companyName}}</small>
							<span *ngIf="contact.isDeleted" class="text-muted small">
								&nbsp; <span class="glyphicon glyphicon-info-sign"></span> Contact Deleted
							</span>
						</div>

						<span *ngIf="!contact && !editing" class="control-value">&ndash;</span>
					</div>
					<div class="coldp-sm-3"></div>
				</div>
			</div>
		</div>
	</div>
</form>
