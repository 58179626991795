<div class="remote-resource" [class.resolved]="resolved">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title text-center">Manage Audit Assignment</h4>
    </div>
    <div class="modal-body">
        Please select Audit Assignment
        <ng-select
            [items]="users"
            bindLabel="name"
            [clearable]="false"
            placeholder="Select Assignee"
            [(ngModel)]="selectedUser"
        ></ng-select>
    </div>
    <div class="modal-footer">
        <div class="btn-group pull-right">
            <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
            <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
        </div>
    </div>
</div>
