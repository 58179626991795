import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals';
import { DocumentComponent, DocumentStatus } from 'shared/documents';

import { GlobalDocumentsService } from '../../../../../documents/documents.service';

import { RequestDocumentModalComponent } from '../../common';

import { ClientDocument } from '../../client-documents.interface';
import { ClientDocumentsService } from '../../client-documents.service';
import { WaiveDocumentModalComponent } from '../../common/waive-document-modal';

@Component({
	selector: 'client-document-list-item',
	templateUrl: './item.component.html',
})
export class ClientDocumentListItemComponent extends DocumentComponent<ClientDocument> implements OnInit {
	@Input() tpoId: number;
	@Input() editable: boolean;

	actions: Set<string> = new Set();
	modalRef: BsModalRef;

    resolving: boolean = false;

    canManageClientDocs: boolean;
    @Output() docReviewed = new EventEmitter<void>();

	get params(): {
		lenderId: number,
		tpoId: number,
		documentId: number,
	} {
		const {
			user: {
				organization: {
					id: lenderId,
				},
			},
			tpoId,
			document: {
				id: documentId,
			},
		} = this;

		return {
			lenderId,
			tpoId,
			documentId,
		};
	}

	get canUpload(): boolean {
		const {
			isActive,
		} = this.document;
		return (
			this.actions.has('UPLOAD')
			&& this.editable
			&& isActive
		);
	}

	get canRequest(): boolean {
		const {
			isActive,
		} = this.document;
		return (
			this.user.can('MANAGE_CLIENT_DOCUMENTS')
			&& this.editable
			&& isActive
		);
	}

	get downloadLink(): string {
		return this.clientDocumentsService.getDownloadLink({
			...this.params,
		});
	}

	get templateDownloadLink(): string {
		const { documentId } = this.params;
		return this.globalDocumentsService.getTemplateDownloadLink({
			documentId,
		});
	}

	constructor(
		private stateService: StateService,
		userService: UserService,
		private globalDocumentsService: GlobalDocumentsService,
		private clientDocumentsService: ClientDocumentsService,
		private modalService: BsModalService,
	) {
		super(userService);
	}

	ngOnInit(): void {
		this.setActions();
        this.canManageClientDocs = this.user.can('MANAGE_CLIENT_DOCUMENTS');
	}

	setActions(): void {
		this.actions.clear();

		if (
			!this.editable
			|| !this.document.isActive
		) {
			return;
		}

		if (
			this.user.can('UPLOAD_CLIENT_DOCUMENTS')
			&& !this.document.docusignTemplate
			&& this.document.isUploadable
		) {
			this.actions.add('UPLOAD');
		}

		if (this.user.can('MANAGE_CLIENT_DOCUMENTS')) {
			if (this.document.isCSD) {
				this.actions.add('MANAGE');
			}

			if (this.document.documentState === DocumentStatus.REQUESTED) {
				this.actions.add('WAIVE');
			}
            if (this.editable && this.document.isActive) {
                this.actions.add('REQUEST');
            }
		}
	}

	async request(): Promise<void> {
		const initialState = {
			document: this.document,
			sendRequest: this.clientDocumentsService.requestDocument,
			reload: (updatedDocument: ClientDocument) => {
				Object.assign(this.document, updatedDocument);
				this.setActions();
			},
			getResourceParams: () => this.params,
		};
		this.modalRef = this.modalService.show(
			RequestDocumentModalComponent,
			{
				initialState,
				backdrop: 'static',
				class: 'modal-new modal-smd',
			},
		);
	}

	// TODO: use shared upload logic
	async confirmUpload(file: File): Promise<void> {
		return new Promise((resolve, reject): void => {
			const { name } = this.document;
			const initialState = {
				title: 'Upload Document',
				message: `
					<div class="modal-message">
						<div class="well bigger">You are about to upload <strong>${file.name}</strong> to this document</div>
						<span class="glyphicon glyphicon-arrow-down"></span>
						<div class="document-card">${name}</div>
					</div>
				`,
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});
	}

	async onFileSelect(files: FileList): Promise<void> {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		try {
			await this.confirmUpload(file);
			this.modalRef.content.resolving = true;
			try {
				this.document = await this.clientDocumentsService.document.upload(
					this.params,
					{ file },
				).$promise;

				this.actionSuccess();

				this.setActions();
			} catch (error) {
				document.$error = error.data?.message;
			}
		} catch (e) {}
		this.closeModal();
	}

	async confirmWaive(): Promise<void> {
		return new Promise((resolve, reject): void => {
			const initialState = {
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				WaiveDocumentModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new',
					backdrop: 'static',
				},
			);
		});
	}

	async waiveDocument(): Promise<void> {
		try {
			const payload = await this.confirmWaive();
			try {
				this.modalRef.content.resolving = true;
				const updatedDocument: ClientDocument = await this.clientDocumentsService.document.waive(
					this.params,
					payload,
				).$promise;
				Object.assign(this.document, updatedDocument);
				this.setActions();
			} catch (error) {
				if (error.data) {
					this.document.$error = error.data.message;
				}
			}
		} catch (e) {
		}
		this.closeModal();
	}

	showDetails(): void {
		const {
			tpoId: id,
			documentId,
		} = this.params;
		this.stateService.go('.:documentId', { id, documentId });
	}

	showHistory(): void {
		const {
			tpoId: id,
			documentId,
		} = this.params;
		this.stateService.go('.:documentId.history', { id, documentId });
	}

    async markDocumentReviewed() {
        try {
            this.resolving = true;
            await this.clientDocumentsService.markCurrentDocumentAsReviewed(this.params.lenderId, this.tpoId, this.document.id).toPromise();
            this.docReviewed.emit();
        } finally {
            this.resolving = false;
            this.setActions();
        }
    }

	private actionSuccess(): void {
		this.document.$success = true;
		setTimeout(() => {
			this.document.$success = false;
		}, 5000);
	}

	private closeModal(): void {
		this.modalRef?.hide();
	}
}
