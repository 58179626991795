import { assign } from 'lodash';
import { Component, forwardRef, Input } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { PagedData, RealmParams } from 'commons/services/http';

import { Location, LocationsService } from 'shared/account/locations/locations.service';
import { ProspectInfo } from 'shared/prospecting/prospecting.interface';

import { UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'locations-list',
    templateUrl: './locations-list.component.html',
    viewProviders: [
        // TODO: Used for SearchListComponent, change it's definition once old ListComponent will be removed
        { provide: ListComponent, useExisting: forwardRef(() => LocationsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => LocationsListComponent) },
    ],
})
export class LocationsListComponent extends NewPagedListComponent<Location> {
    static listName = 'locationsList';
    @Input() private externalParams: RealmParams = {};
    @Input() lenderInfo: AccountInformation;

    directAccess: boolean;
    hasProspectPermission = false;

    constructor(
        router: UIRouter,
        private locationsService: LocationsService,
        { profile: User }: UserService,
    ) {
        super(router);
        const { component } = router.globals.current as any;
        this.directAccess = component === this.constructor;
        this.useLocation = this.directAccess;
        this.hasProspectPermission = User.isLender && User.crmEnabled && User.can('ADD_PROSPECT');

        this.defaultParams.searchNmlsId = '';
    }

    protected loadList(params: ListParams): Observable<PagedData<Location[]>> {
        const { identifier } = this.lenderInfo;
        return this.locationsService.getList(identifier, { ...this.externalParams, ...params });
    }

    getProspectInfo({ name, city, stateCode, zip, canUserAddProspect, prospectStatusForUser, hqNmlsId, nmlsId }: Location) {
        const location = `${city}, ${stateCode}, ${zip}`;

        return {
            companyName: name,
            companyNmlsId: hqNmlsId,
            location,
            locationNmlsId: nmlsId,
            prospectStatusForUser,
            canUserAddProspect,
        };
    }

    onProspectInfoChange(locationData: Location, {
        companyNmlsId,
        locationNmlsId,
        canUserAddProspect,
        prospectStatusForUser,
    }: ProspectInfo): void {
        assign(locationData, {
            hqNmlsId: companyNmlsId,
            nmlsId: locationNmlsId,
            canUserAddProspect,
            prospectStatusForUser,
        });
    }
}
