<div class="remote-resource" [class.resolved]="!updating">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
        <h4 class="modal-title">Grant Access</h4>
    </div>
    <form (onValidSubmit)="createUser()" id="GrantAccessForm" [formGroup]="grantAccessForm">
        <div class="modal-body">
            <form-errors [form]="grantAccessForm"></form-errors>
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-md-12">
                        You are about to create the user <strong>{{contact.fullName}}</strong>.<br>
                        Please select:
                    </div>
                </div>
                <div class="row-fluid form-group" has-error>
                    <div class="col-md-3">
                        <label class="control-label required">Role</label>
                    </div>
                    <div class="col-md-9">
                        <ng-select
                            formControlName="role"
                            [items]="roles?.$promise | async"
                            [loading]="!roles?.$resolved"
                            bindLabel="name"
                            placeholder="Select role">
                        </ng-select>
                    </div>
                </div>
                <div class="row-fluid form-group" has-error *ngIf="grantAccessForm.contains('email')">
                    <div class="col-md-3">
                        <label class="control-label required">Email</label>
                    </div>
                    <div class="col-md-9">
                        <input class="form-control" maxlength="100" formControlName="email">
                    </div>
                </div>
                <div class="row-fluid form-group" has-error *ngIf="grantAccessForm.contains('officerDesignation')">
                    <div class="col-md-3">
                        <label class="control-label required">Designation</label>
                    </div>
                    <div class="col-md-9">
                        <ng-select
                            formControlName="officerDesignation"
                            [items]="contactTypeList | async"
                            [loading]="(contactTypeList | async)?.length == 0"
                            [multiple]="true"
                            bindValue="name"
                            bindLabel="name"
                            placeholder="Select designation(s)"
                            tabindex=15>
                        </ng-select>
                    </div>
                </div>
                <div class="row-fluid form-group" has-error *ngIf="grantAccessForm.contains('zip')">
                    <div class="col-md-3">
                        <label class="control-label required">Zip</label>
                    </div>
                    <div class="col-md-9">
                        <input class="form-control" maxlength="10" formControlName="zip">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="required-block pull-left text-muted">
                <span class="control-label required"></span> &ndash; Required fields
            </div>

            <div class="text-right">
                <input type="submit" class="btn btn-primary" value="Grant Access">
                <a class="btn btn-default" (click)="modalRef.hide();">Cancel</a>
            </div>
        </div>
    </form>
</div>
