import { Component, Host, Input } from '@angular/core';
import {isUndefined, range, map} from 'lodash';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginationData } from 'commons/services/http';

@Component({
	selector: 'paginator',
	templateUrl: './new-paginator.component.html',

})
export class NewPaginatorComponent {
	@Input()
	sizes: number[] = [];

	@Input()
	padding: number = 5;

	size: number;
	pagination: PaginationData;
	pagesToDisplay: number[] = [];
	minSize: number = 10;
	display: boolean = false;
	protected destroy$: Subject<void> = new Subject();

	constructor(@Host() public listComponent: NewPagedListComponent<unknown>) {

	}

	ngOnInit(): void {
		this.display = this.sizes.length > 1;
		this.listComponent.pagination
			.pipe(takeUntil(this.destroy$))
			.subscribe(pagination => this.update(pagination));
		this.listComponent.params$
			.pipe(takeUntil(this.destroy$))
			.subscribe(params => this.size = parseFloat(params.size));
	}

	ngOnChanges(change) {
		this.minSize = Array.isArray(this.sizes) && this.sizes.length ? Math.min(...this.sizes) : 10;
	//
	// 	if (change.list && change.list.currentValue.$resolved &&
	// 		(isUndefined(change.list.currentValue.$pagesTotal) || isUndefined(change.list.currentValue.$page))) {
	// 		throw new Error('PagedResource required for paging directive, check your controller');
	// 	}
	//
	// 	if (change.list && change.list.currentValue.$resolved) {
	// 		// 5 comes from orginal directive
	// 		this.pagesToDisplay = this.calcPages(change.list.currentValue, 5);
	// 	}
	}

	next() {
		const page = this.pagination.page + 1;

		this.listComponent.params$.next({...this.listComponent.params$.getValue(), page });
	}

	prev() {
		const page = this.pagination.page - 1;

		this.listComponent.params$.next({...this.listComponent.params$.getValue(), page });
	}

	setCurrent(page) {
		if (page === this.listComponent.params$.getValue().page) {
			return;
		}

		this.listComponent.params$.next({...this.listComponent.params$.getValue(), page });
	}

	setSize(size) {
		const oldSize = this.size;
		const oldPage = this.pagination.page;
		const page =    Math.floor(oldPage * oldSize / size);
		this.listComponent.params$.next({...this.listComponent.params$.getValue(), size, page});
	}

	update(pagination: PaginationData) {
		const pages = [];
		const { padding } = this;
		const { pagesTotal, page } = pagination;
		this.pagination = pagination;

		this.display = Math.max(pagesTotal, page, this.sizes.length) > 1;

		if (pagesTotal) {
			const leftLimit = Math.max(page - padding, 1);
			const rightLimit = Math.min(page + padding, pagesTotal - 2);

			// First page
			pages.push(0);

			// Separator
			if (leftLimit > 1) {
				pages.push('|');
			}

			// for $pagesTotal = 1 map(pageRange) adds empty second page
			if (pagesTotal > 1) {
				// range does not include end value, so inc it
				const pageRange = range(leftLimit, rightLimit + 1);
				map(pageRange, (page) => pages.push(page));
			}

			// Separator
			if (rightLimit < pagesTotal - 2) {
				pages.push('|');
			}

			// Last page
			if (pagesTotal > 1) {
				pages.push(pagesTotal - 1);
			}
		}

		this.pagesToDisplay =  pages;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
