<ng-template #panelTemplate>
	<div class="panel">
		<div class="panel-body">

			<h3 class="panel-title">
				{{title}}
				<span class="label label-{{postStatuses[post.status]?.color}}">{{postStatuses[post.status]?.title}}</span>
			</h3>

			<section class="remote-resource" [class.resolved]="isResolved()">

				<div class="form-group accounts" *ngIf="accounts.$resolved">
					<label class="control-label">Accounts</label>
					<div class="account-container flex-row gap" *ngFor="let item of accounts$ | async">
						<sm-icon
							[config]="item"
							[minified]="true"
							[showTooltip]="false"
						></sm-icon>
						<span class="flex">{{item.name}}</span>
						<span *ngIf="post.status === 'SENT'"
							class="account-status c-icon"
							[ngClass]="item.publishStatus?.cssClass"
							tooltip="{{item.publishStatus?.title}}"
							placement="left"
						></span>
					</div>
				</div>

				<div class="form-group" *ngIf="post.text">
					<label class="control-label">Post</label>
					<span class="display-block">
						<formatted-text [text]="post.text"></formatted-text>
					</span>
				</div>

				<div class="form-group" *ngIf="post.attachments?.length">
					<post-attachments [post]="post"
									  [editable]="false"
									  [config]="attachmentConfig"
									  (error)="error($event)">
					</post-attachments>
				</div>

				<div class="form-group" *ngIf="post.updatedDate">
					<label class="control-label">Updated Date</label>
					<span class="display-block">
						{{post.updatedDate | date: 'MM/dd/yyyy hh:mm a'}}
					</span>
				</div>

				<div class="form-group" *ngIf="post.scheduledDate">
					<label class="control-label">Schedule Date</label>
					<span class="display-block">
						{{post.scheduledTimeInZone}}
					</span>
				</div>

				<div class="form-group" *ngIf="post.sentDate">
					<label class="control-label">Post Time</label>
					<span class="display-block">
						{{post.sentDate | date: 'MM/dd/yyyy hh:mm a'}}
					</span>
				</div>

				<div class="form-group campaigns" *ngIf="post.campaigns?.length && campaigns.$resolved">
					<label class="control-label">Campaigns</label>
					<div class="campaigns-container">
						<div class="campaign-container flex-row" *ngFor="let item of campaigns$ | async">
							<div class="icon-container">
								<span class="campaign-color" [style.background-color]="'#' + item.colorCode"></span>
							</div>
							<div class="flex">
								<span *ngIf="!canViewCampaigns">{{item.name}}</span>
								<a uiSref="social-compliance.publisher.campaigns.:campaignId" [uiParams]="{ campaignId: item.id }" *ngIf="canViewCampaigns">{{item.name}}</a>
								<div class="campaign-dates">
									{{item.startDate}} - {{item.endDate}}
								</div>
							</div>
							<div class="campaign-status">
								<span class="label label-{{campaignStatuses[item.status]?.color}}">{{item.status}}</span>
							</div>
						</div>
					</div>
				</div>

			</section>

		</div>
		<div class="panel-footer clearfix" *ngIf="post.canEdit || embedded">
			<div class="pull-left">
				<button *ngIf="embedded" type="button" class="btn btn-primary" (click)="close(false)">
					Close
				</button>
				<button *ngIf="post.canEdit && !embedded" type="button" class="btn btn-default btn-icon" (click)="remove()">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
			</div>
			<div class="pull-right">
				<button *ngIf="post.canEdit && embedded" type="button" class="btn btn-default btn-icon" (click)="remove()">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				<button *ngIf="post.canEdit" type="button" class="btn btn-default" (click)="edit()">
					<span class="glyphicon glyphicon-pencil"></span>
					Edit
				</button>
			</div>
		</div>
	</div>
</ng-template>

<full-page-layout *ngIf="!embedded" class="social-compliance-publisher-post view" (onClose)="close()">

	<ng-template #headerTemplate>
		<h4 class="page-title">Post Editor</h4>
	</ng-template>

	<ng-template #contentTemplate>
		<ng-container *ngTemplateOutlet="panelTemplate"></ng-container>
		<posts-preview
			[class.hide-logo]="previewAccounts?.length"
			[accounts]="previewAccounts"
			[text]="post.text"
			[resolvedDynamicContent]="resolvedDynamicContent"
			[attachments]="post.attachments"
			[attachmentsErrors]="attachmentsErrors"
			[previewDate]="post.sentDate || (post.scheduledDate || post.updatedDate)"
			[linkPreview]="linkPreview"
		></posts-preview>
	</ng-template>

</full-page-layout>

<ng-container *ngIf="embedded">
	<div class="social-compliance-publisher-post view">
		<ng-container *ngTemplateOutlet="panelTemplate"></ng-container>
	</div>
</ng-container>
