import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
    templateUrl: './clients-header.component.html',
})
export class ClientsHeaderComponent {

    constructor(
        public stateService: StateService
    ) {}

    get onListPage(): boolean {
        return this.stateService.includes('clients.list');
    }
}
