import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

declare let apiPath: string;

export enum PACKAGE_FEATURE {
    LISTINGS = 'SEO_LISTINGS',
    REPUTATION_MANAGEMENT = 'REPUTATION_MANAGEMENT',
    SOCIAL_ACCOUNTS = 'SOCIAL_ACCOUNTS',
    MARKETING = 'MARKETING',
    COMPLIANCE = 'COMPLIANCE',
    REPORTING = 'REPORTING',
}

export class ActivePackage {
    packageName: string;
    invoicing: boolean;
    isInventoryVisible: boolean;
    features: PACKAGE_FEATURE[];

    has(feature: PACKAGE_FEATURE): boolean {
        return this.features.includes(feature);
    }
}

type PackageDetailsAddonKey = 'compliance-audits' | 'reporting';
type PackageDetailsAddons = Record<PackageDetailsAddonKey, number>;

export class ActivePackageDetails {
    isSubscribed: boolean;
    canSubscribe: boolean;
    tier: number;
    price: number;
    addonsPrices: PackageDetailsAddons;
    accountManagementUrl?: string;
    hasPastDueInvoice?: boolean;
}

export interface HasPastDueInvoiceAlert {
    hasPastDueInvoice: boolean;
}

export interface DemoRequest {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    company: string;
    additionalInformation: string;
}

export type ActivePackageData = Pick<ActivePackage, 'packageName' | 'invoicing' | 'features' | 'isInventoryVisible'>;

@Injectable({
    providedIn: 'root',
})
export class SmPackageService {
    constructor(
        private http: RealmHttpClient,
    ) {
    }

    getActivePackage(tpoId: number): Observable<ActivePackage> {
        return this.http.request('get', `${apiPath}/tpos/${tpoId}/sm-package/info`).pipe(
            map((data) => Object.assign(new ActivePackage(), data)),
        )
    }

    getActivePackageDetails(tpoId: number): Observable<ActivePackageDetails> {
        return this.http.request<ActivePackageDetails>('get', `${apiPath}/tpos/${tpoId}/sm-package/subscription-details/SOCIAL_STUDIO`)
            .pipe(
                tap((activePackageDetails: ActivePackageDetails) => {
                    if (!activePackageDetails.accountManagementUrl) {
                        activePackageDetails.accountManagementUrl = 'https://comergencerealm5.recurly.com/';
                    }
                })
            );
    }

    subscribe(tpoId: number, billingInfo): Observable<void> {
        return this.http.request<void>('post', `${apiPath}/tpos/${tpoId}/sm-package/subscription/SOCIAL_STUDIO`,{}, billingInfo);
    }

    getHasPastDueInvoiceAlert(tpoId: number): Observable<HasPastDueInvoiceAlert> {
        return this.http.request<HasPastDueInvoiceAlert>('get', `${apiPath}/tpos/${tpoId}/sm-package/subscription/SOCIAL_STUDIO/hasPastDueInvoice`, {});
    }

    requestDemo(tpoId: number, demoRequest: DemoRequest): Observable<void> {
        return this.http.request<void>('post', `${apiPath}/tpos/${tpoId}/sm-package/demoRequest`, {}, demoRequest);
    }
}
