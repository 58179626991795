<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">&times;</button>
    <h4 class="modal-title">Forgot {{entityName}}</h4>
</div>
<div class="remote-resource" [class.resolved]="resolved">
    <div class="modal-body">
        <div class="well text-center">The {{entityName}} will be sent to the registered Email.</div>

        <form class="form-horizontal" [formGroup]="form" id="forgotEntityForm" (onValidSubmit)="save()">
            <form-errors [form]="form"></form-errors>
            <div class="form-group" has-error>
                <label class="control-label hidden">Email</label>
                <label class="col-sm-5 control-label">Please enter your Email:</label>
                <div class="col-sm-4">
                    <input type="email" formControlName="email" class="form-control" />
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <div class="btn-group-lg">
            <button type="submit" class="btn btn-primary" form="forgotEntityForm">Submit</button>
            <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
    </div>

</div>
