import { Component, Input, OnInit } from '@angular/core';
import { LenderSectionReviewService, PROFILE_SECTION, SectionReview } from 'tpo/account/section-review/section-review.service';
import { UserService } from 'angularjs-providers/user.provider';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { Transition } from '@uirouter/angular';

@Component({
    selector: 'section-review',
    templateUrl: './section-review.component.html',
})
export class SectionReviewComponent implements OnInit {
    @Input() section: PROFILE_SECTION;
    @Input() organizationId: number;
    @Input() allowEmpty: boolean = true;
    protected isTpo = false;
    resolving = false;
    protected review: SectionReview;

    constructor(
        { profile: { isTpo } }: UserService,
        private readonly sectionReviewService: LenderSectionReviewService,
        private readonly notificationsService: GlobalNotificationsService,
        private readonly transition: Transition,
    ) {
        this.isTpo = isTpo;
    }

    ngOnInit(): void {
        if (!this.organizationId) {
            const lenderInfo = this.transition.injector().get('lenderInfo') as AccountInformation;
            if (lenderInfo) {
                this.organizationId = lenderInfo.organizationId;
            }
        }
        this.updateReview();
    }

    updateReview(): void {
        this.makeQuery();
    }

    protected markReviewed(): void {
        this.makeQuery(true);
    }

    protected async makeQuery(update = false): Promise<void> {
        if (!this.organizationId || !this.isTpo && update) return;
        try {
            this.resolving = true;
            this.review = await (
                update
                    ? this.sectionReviewService.updateSection(this.section, this.organizationId)
                    : this.sectionReviewService.getSection(this.section, this.organizationId)
            ).toPromise();
        } catch ({ error: { message } }) {
            this.notificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
        } finally {
            this.resolving = false;
        }
    }
}
