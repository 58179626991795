import { Observable, Subject } from 'rxjs';
import { startWith, switchMap, throttleTime } from 'rxjs/operators';

export abstract class AbstractStatusService<T = any> {
	private _status$: Observable<T>;
	private _refresh$ = new Subject<void>();

	protected getStatus$(
		fetchOnInit: boolean = true,
		throttleRequests: number = 10e+3,
	): Observable<T> {
		if (!this._status$) {
			this._status$ = this._refresh$
				.pipe(
					startWith(fetchOnInit),
					throttleTime(
						throttleRequests,
						undefined,
						{
							leading: true,
							trailing: true,
						},
					),
					switchMap(this.fetchStatus),
				);
		}

		return this._status$;
	}

	protected abstract fetchStatus(): Observable<T>;

	protected refreshStatus(): void {
		this._refresh$.next();
	}
}
