import { Component, Input } from '@angular/core';

import { OwnershipRow } from 'tpo/application/application.service';

@Component({
    templateUrl: './ownership-row.component.html',
    selector: '[ownership-row]',
})
export class AppSummaryOwnershipRowComponent {
    @Input() ownership:OwnershipRow = {} as OwnershipRow;
    @Input() channelId: number;
    constructor() {}
}
