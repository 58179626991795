<div class="subscription-payment">
    <h2 class="flex-row gap">
        Subscription
    </h2>
    <form [formGroup]="paymentForm" (onValidRecurlySubmit)="submit()">
        <section>
            <b>By completing your credit card information on this screen and clicking “Activate” you agree to pay all fees incurred for your monthly subscription as described on the<br>
            subscription page. Your first month subscription fee will be prorated and any usage based add on fees will be charged in the following month.</b>
        </section>

        <section class="flex-row space-between subscription-information">
            <b>Subscription Summary</b>
            <b *ngIf="packageDetails?.price as price">${{ price }}/Month</b>
        </section>

        <br>

        <section class="flex-row space-between">
            <h3>
                Credit Card Information
            </h3>

            <div class="required-block-lg text-muted">
                <span class="control-label required"></span> &ndash; Required fields
            </div>
        </section>

        <form-errors [form]="paymentForm"></form-errors>

        <div class="rows-striped">
            <div class="row-fluid">
                <div class="col-sm-6">
                    <div class="form-group" [recurlyError]="paymentForm.get('card')" [class.has-error]="paymentForm.get('card').showError">
                        <label class="col-sm-3 control-label required">{{paymentForm.get('card').label}}</label>
                        <div class="col-sm-9">
                            <div data-recurly="card"></div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <p class="text-smaller text-muted">The CVV number is the 3 digit number located on the back of your Visa or Master card. For American Express, it is a 4 digit number in the small print located on the front of your card.</p>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-6">
                    <div class="form-group" has-error>
                        <label class="col-sm-3 control-label required">{{paymentForm.get('first_name').label}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" data-recurly="first_name" formControlName="first_name" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group" has-error>
                        <label class="col-sm-3 control-label required">{{paymentForm.get('last_name').label}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" data-recurly="last_name" formControlName="last_name" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-6">
                    <div class="form-group" has-error>
                        <label class="col-sm-3 control-label required">{{paymentForm.get('email').label}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" data-recurly="email" formControlName="email" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group" has-error>
                        <label class="col-sm-3 control-label">{{paymentForm.get('costCenter').label}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" data-recurly="costCenter" formControlName="costCenter" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-6">
                    <div class="form-group" has-error>
                        <label class="col-sm-3 control-label">{{paymentForm.get('poNumber').label}}</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" data-recurly="poNumber" formControlName="poNumber" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6"></div>
            </div>
        </div>

        <br>

        <div class="flex-row">
            <div class="flex-grow">
                <label class="checkbox-inline">
                    <input type="checkbox" formControlName="agree" />
                    I have read and agree with <a href="/help/authenticated/tpo/smc/terms-and-services" target="_blank">Terms of Use</a>
                </label>
            </div>
            <div>
                <button type="submit" class="btn btn-lg btn-primary" [disabled]="!paymentForm.get('agree').value">Activate</button>
                <a class="btn btn-lg btn-default" uiSref="^">Cancel</a>
            </div>
        </div>
    </form>
</div>