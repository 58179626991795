<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" *ngIf="!resolving" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">RSS</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<ng-container *ngIf="!removeConfirmation">
		<a target="_blank" class="text-bigger" [href]="link?.data?.rssLink | hrefReady">{{link?.data?.rssLink}}</a>
	</ng-container>
	<ng-container *ngIf="removeConfirmation">
		You are about to remove RSS Link. Are you sure you want to proceed?
	</ng-container>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="clearfix">
		<div class="pull-left margin-top-sm">
			<a class="text-danger" (click)="toggleRemoveConfirmation(true)" *ngIf="!removeConfirmation && canDelete">Remove RSS</a>&nbsp;
		</div>
		<div class="pull-right">
			<ng-container *ngIf="!removeConfirmation">
				<a (click)="modalRef.hide()" class="btn btn-lg btn-primary">Close</a>
			</ng-container>
			<ng-container *ngIf="removeConfirmation">
				<a class="btn btn-lg btn-primary" (click)="remove()">Remove</a>
				<a class="btn btn-lg btn-default" (click)="toggleRemoveConfirmation(false)">Cancel</a>
			</ng-container>
		</div>
	</div>
</div>
