<alert-details
    [userCanEditAlerts]="userCanEditAlerts"
    [lenderId]="lenderId"
    [alertStatuses]="alertStatuses"
    [alertSummary]="alertSummary"
    [alertRecord]="alertRecord"
    (alertStatusUpdated)="alertStatusUpdated($event)"
>
</alert-details>

<alert-events
    [alertEvents]="alertEvents"
>
</alert-events>

<alert-channels
    [alertChannels]="alertChannels"
>
</alert-channels>

<alert-ids
    [alertIds]="alertIds"
>
</alert-ids>

<alert-history
    [alertHistoryItems]="alertHistoryItems"
>
</alert-history>

<alert-comments
    *ngIf="commentTypes"
    [lenderId]="lenderId"
    [tpoId]="tpoId"
    [alertId]="alertId"
    [commentTypes]="commentTypes"
    [comments]="comments"
    (commentAdded)="reloadComments()"
>
</alert-comments>
