<div class="remote-resource" [class.resolved]="!resolving">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
        <h4 class="modal-title">Add Warehouse</h4>
    </div>
    <div class="modal-body ">
        <form [formGroup]="newWarehouseForm" name="newWarehouseForm" (onValidSubmit)="onSubmit()">
            <form-errors [form]="newWarehouseForm"></form-errors>
            <div class="form-group" has-error>
                <label class="control-label required">
                    {{newWarehouseForm.getFieldLabel('companyName')}}
                </label>
                <div>
                    <input
                        type="text"
                        formControlName="companyName"
                        class="form-control"
                        maxlength="99" />
                </div>
            </div>
            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('contactName')}}
                    </label>
                    <div>
                        <input
                            type="text"
                            formControlName="contactName"
                            class="form-control"
                            maxlength="99" />
                    </div>
                </div>
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('email')}}
                    </label>
                    <div>
                        <input
                            formControlName="email"
                            class="form-control"
                            maxlength="99" />
                    </div>
                </div>
            </div>
            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('phone')}}
                    </label>
                    <div>
                        <input
                            phoneFormatter
                            formControlName="phone"
                            class="form-control"
                            maxlength="100" />
                    </div>
                </div>
                <div class="flex-grow form-group" has-error>
                    <label class="control-label required">
                        {{newWarehouseForm.getFieldLabel('approvedLineAmount')}}
                    </label>
                    <div>
                        <input
                            maxlength="15"
                            validFloat="1.0-0"
                            formControlName="approvedLineAmount"
                            class="form-control" />
                    </div>
                </div>
            </div>
            <div class="form-group" has-error>
                <label class="control-label required">
                    {{newWarehouseForm.getFieldLabel('sellLoans')}}
                </label>
                <div class="flex-column">
                    <div class="flex-row gap-l">
                        <label class="radio-inline">
                            <input type="radio" formControlName="sellLoans" [value]="true"> Yes
                        </label>
                        <br />
                        <label class="radio-inline">
                            <input type="radio" formControlName="sellLoans" [value]="false"> No
                        </label>
                    </div>
                </div>
            </div>
            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label required">
                        {{newWarehouseForm.getFieldLabel('relationshipStartDate')}}
                    </label>
                    <div>
                        <input
                           type="text"
                           autocomplete="off"
                           [maxDate]="newWarehouseForm.get('relationshipEndDate').value"
                           formControlName="relationshipStartDate"
                           class="form-control"
                           bsDatepicker
                           placeholder="mm/dd/yyyy"
                        >
                    </div>
                </div>
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('relationshipEndDate')}}
                    </label>
                    <div>
                        <input
                            type="text"
                            autocomplete="off"
                            [minDate]="newWarehouseForm.get('relationshipStartDate').value"
                            formControlName="relationshipEndDate"
                            class="form-control"
                            bsDatepicker
                            placeholder="mm/dd/yyyy"
                        >
                    </div>
                </div>
            </div>
            <h4>
                <b>Covenants</b>
            </h4>
            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('minimumTNWRequired')}}
                    </label>
                    <div>
                        <input
                            maxlength="15"
                            validFloat="1.0-0"
                            formControlName="minimumTNWRequired"
                            class="form-control" />
                    </div>
                </div>
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('minimumLiquidityRequired')}}
                    </label>
                    <div>
                        <input
                            maxlength="15"
                            validFloat="1.0-0"
                            formControlName="minimumLiquidityRequired"
                            class="form-control" />
                    </div>
                </div>
            </div>
            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('maximumLeverageAllowed')}}
                    </label>
                    <div>
                        <input
                            maxlength="15"
                            validFloat="1.0-0"
                            formControlName="maximumLeverageAllowed"
                            class="form-control" />
                    </div>
                </div>
                <div class="flex-grow form-group" has-error>
                    <label class="control-label">
                        {{newWarehouseForm.getFieldLabel('minimumProfitability')}}
                    </label>
                    <div>
                        <input
                            maxlength="15"
                            validFloat="1.0-0"
                            formControlName="minimumProfitability"
                            class="form-control" />
                    </div>
                </div>
            </div>
            <br>
            <div class="text-right">
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" (click)="modalRef.hide()" class="btn btn-default">Cancel</button>
            </div>
        </form>
    </div>
</div>
