<div class="remote-resource" [ngClass]="{ 'resolved': resolved }">
	<header>
		<div class="column">
			<div class="row">
				<div class="col-md-4"><span class="text-muted">Location</span></div>
				<div class="col-md-3"><span class="text-muted">Individual</span></div>
				<div class="col-md-a"></div>
			</div>

			<div class="row">
			<div class="col-md-4">
				<ng-container *ngIf="review?.companyName || review?.nmlsId">
					<ng-container *ngIf="review.companyName">{{review.companyName}}{{review.nmlsId ? ',' : ''}} </ng-container>
					<ng-container *ngIf="review.nmlsId">NMLS ID: {{review.nmlsId}}</ng-container>
				</ng-container>
				<ng-container *ngIf="!review?.companyName && !review.nmlsId">&ndash;</ng-container>
			</div>
			<div class="col-md-3">
				<a *ngIf="review?.contactId && !(review.owners?.length > 1)"
				   uiSref="people.contacts.:contactId.information"
				   [uiParams]="{ contactId: review.contactId }"
				   target="_blank">{{review.loanOriginatorFullName}}
				</a>
				<a *ngIf="review?.contactId && review.owners?.length > 1"
				   (click)="showIndividualsModal(review.owners)">
					{{review.owners.length}} individuals
				</a>
				{{!review?.contactId ? '&ndash;' : ''}}
			</div>
			<div class="col-md-a"></div>
		</div>
		</div>
		<div class="column button-holder">
		<a class="btn btn-primary"
		   *ngIf="review?.hasPostPermission"
		   (click)="post(review)"
		>
			<i class="c-icon c-icon-send"></i>&nbsp;Post
		</a>
	</div>
	</header>
	<div class="content-holder">
		<div class="column">
			<sm-icon [config]="{
						networkCode: review?.networkId || review?.providerCode || 'ER',
						profilePictureUrl: review?.reviewerAvatarUrl,
						title: !review?.networkId && review.providerName
					 }">
			</sm-icon>
		</div>
		<div class="column">
			<div><b>{{review?.reviewerName}}</b></div>

			<div class="text-muted">
				{{(review?.reviewDate | date:'MMMM d, y \'at\' h:mm a') || '&ndash;'}}
			</div>

			<div class="scores">
				<div *ngFor="let score of review?.scores">
					<reviews [rating]="score?.score"></reviews>
					{{score?.parameter}}
				</div>
			</div>

			<div *ngIf="review?.text" class="review-text">
				<show-more [maxHeight]="60" [text]="review.text"></show-more>
			</div>

			<review-comments *ngIf="review.replies?.length"
							 [replies]="review.replies"
							 [reviewId]="review.reviewId"
							 [permission]="review.hasReplyEditPermission"
							 [companyName]="review.companyName"
							 (onAdd)="onReply()">
			</review-comments>

			<div class="reply-holder btn-reply-holder">
				<button class="btn btn-default"
					[class.curtain]="replyReviewLoading"
					(click)="openReplyOnSite()"
				>
					<span class="c-icon c-icon-message"></span> Respond
				</button>
			</div>
		</div>
	</div>
</div>
