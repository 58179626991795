<div class="gray-head flex-head">
    <div class="col-fit">
        <h2 class="title-label">
            <span>{{ existingContactList?.name }}</span>
        </h2>
    </div>
    <div *ngIf="!loadingContactList" class="col-min">
        <ul class="tab-menu col-min">
            <li uiSrefActive="active">
                <a uiSref="^.details">
                    <b>Details</b>
                </a>
            </li>
            <li uiSrefActive="active">
                <a uiSref="^.contacts">
                    <b>Contacts</b>
                </a>
            </li>
        </ul>
    </div>
</div>
<contact-list-contacts-list
    *ngIf="existingContactList"
    [userCanManageContactLists]="userCanManageContactLists"
    [existingContactList]="existingContactList"
>
</contact-list-contacts-list>
