<div class="move-listing-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>

	<form [formGroup]="form" (onValidSubmit)="submit()">
		<div class="modal-body remote-resource" [class.resolved]="resolved">
			<form-errors [form]="form"></form-errors>

			<ng-container *ngIf="!createMode">
				<div class="form-group">
					<ng-select
						formControlName="listingGroup"
						[items]="listingGroupsList.$promise | async"
						[loading]="!listingGroupsList.$resolved"
						[searchFn]="search"
						placeholder="Select Group"
					>
						<ng-template ng-label-tmp let-item="item">
							{{item.businessName || item.name}}
						</ng-template>

						<ng-template ng-option-tmp let-item="item">
							<div><b>{{item.businessName || item.name}}</b></div>
							<div class="text-muted text-overflow">{{item | address}}</div>
						</ng-template>

						<ng-template ng-footer-tmp>
							<div class="ng-dropdown-footer-items">
								<div class="ng-option" (click)="toggleCreateMode(true)">
									<span class="text-big text-light">+</span>
									Create New Group
								</div>
							</div>
						</ng-template>
					</ng-select>
				</div>
			</ng-container>
			<ng-container *ngIf="createMode">
				<div class="form-group">
					<input type="text" class="form-control" formControlName="name" maxlength="300"/>
				</div>
			</ng-container>

			<div class="selected-listing-group flex-row gap-m">
				<ng-container *ngIf="form.get('listingGroup').value as listingGroup">
					<ng-container *ngIf="!createMode">
						<div class="listing-group-image" [bgImage]="listingGroup.coverImage"></div>
					</ng-container>
					<ng-container *ngIf="createMode">
						<div class="listing-group-image" [bgImage]="listingGroup.coverImage"></div>
					</ng-container>

					<div class="listing-group-details text-muted flex">
						<div *ngIf="listingGroup.businessName || listingGroup.name">{{listingGroup.businessName || listingGroup.name}}</div>
						<div *ngIf="(listingGroup | address) as address">{{address}}</div>
						<div *ngIf="(listingGroup.phoneNumber | tel) as phoneNumber">{{phoneNumber}}</div>
						<div *ngIf="listingGroup.website" class="text-overflow">{{listingGroup.website}}</div>
					</div>
				</ng-container>
			</div>
		</div>

		<div class="modal-footer" [class.curtain]="!resolved">
			<div class="btn-group-lg">
				<button type="submit" class="btn btn-primary" [disabled]="form.invalid">Save</button>
				<button type="button" (click)="close()" class="btn btn-default">Cancel</button>
			</div>
		</div>
	</form>
</div>
