import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SharedTechOpsService, TechOps, TOField } from 'shared/account/information/tech-ops/tech-ops.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import {
    GlobalNotificationMessages,
    GlobalNotificationsService,
    GlobalNotificationType,
} from 'global-elements/notication-center/notifications.service';
import { map } from 'rxjs/operators';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: './tech-ops.component.html',
})
export class SharedTechOpsComponent implements OnInit {
    @Input() public lenderInfo: AccountInformation;
    @ViewChild(SectionReviewComponent, { static: true }) sectionReview: SectionReviewComponent;
    public readonly sectionType = PROFILE_SECTION.TECHNOLOGY_OPERATIONS;
    resolved = false;
    editing = false;
    canManage = this.userService.profile.isTpo;
    fields: [TOField, TOField?][];
    values: TechOps;
    form = new RealmFormGroup({});

    constructor(
        private readonly techOpsService: SharedTechOpsService,
        private readonly globalNotificationsService: GlobalNotificationsService,
        private readonly userService: UserService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
        this.initFormField();
    }

    async loadData(): Promise<void> {
        const { identifier: { tpoId } } = this.lenderInfo;
        try {
            [
                this.fields,
                this.values,
            ] = await Promise.all([
                this.techOpsService.getFields().pipe(
                    map(fields => {
                        const result = [];
                        while (fields.length) {
                            result.push(fields.splice(0, 2));
                        }
                        return result;
                    }),
                ).toPromise(),
                this.techOpsService.get(tpoId).toPromise(),
            ]);
        } catch (e) {
            const { status } = e;
            if ([403, 404].includes(status)) {
                throw e;
            }

            this.globalNotificationsService.add({
                type: GlobalNotificationType.ERROR,
                message: GlobalNotificationMessages.ERROR,
            });
        } finally {
            this.resolved = true;
        }
    }

    initFormField(): void {
        const addField = (field: TOField) => {
            const { id: name, name: label } = field;
            this.form.addControl(name, new RealmFormControl(name, { label, updateOn: 'change' }, DropdownRequiredValidator));
        };
        this.fields.forEach(pair => pair.forEach(addField));
    }

    resetForm(): void {
        this.form.reset(this.values);
    }

    startEditing(): void {
        this.resetForm();
        this.editing = true;
    }

    cancelEditing(): void {
        this.resetForm();
        this.editing = false;
    }

    async save(): Promise<void> {
        const { identifier: { tpoId } } = this.lenderInfo;
        this.resolved = false;
        try {
            this.values = await this.techOpsService.save(tpoId, this.form.value).toPromise();
            this.cancelEditing();
            this.sectionReview.updateReview();
        } catch ({ error }) {
            this.form.setServerError(error);
        } finally {
            this.resolved = true;
        }
    }
}
