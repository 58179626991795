import { Component, forwardRef, Input } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { SmallContact, Location, LocationsService } from 'shared/account/locations/locations.service';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'location-branch-manager-list',
    templateUrl: './branch-manager-list.component.html',
    viewProviders: [
        // TODO: Used for SearchListComponent, change it's definition once old ListComponent will be removed
        { provide: ListComponent, useExisting: forwardRef(() => LocationBranchManagerListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => LocationBranchManagerListComponent) },
    ],
})
export class LocationBranchManagerListComponent extends NewPagedListComponent<SmallContact> {
    static listName = 'locationBMList';
    @Input('locationInfo') location: Location;
    @Input() lenderInfo: AccountInformation;
    @Input() contactsAvailable = false;
    User: UserProfile;

    constructor(
        { profile: User }: UserService,
        router: UIRouter,
        private locationsService: LocationsService,
    ) {
        super(router);
        this.User = User;
    }

    protected loadList(params: ListParams): Observable<PagedData<SmallContact[]>> {
        const { identifier } = this.lenderInfo;
        const { nmlsId } = this.location;
        return this.locationsService.getBranchManagerList(identifier, nmlsId, { ...params });
    }
}
