import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient, RealmPagedParams } from 'commons/services/http';

import { UserService } from 'angularjs-providers/user.provider';

import { ReviewAccount } from '../account.interface';

declare let apiPath: string;

@Injectable()
export class GoogleBusinessService {
    constructor(private http: RealmHttpClient, private user: UserService) {}

    getAccounts(listParams: RealmPagedParams): Observable<PagedData<ReviewAccount[]>> {
        return this.http.pagedRequest<ReviewAccount[]>(
            'GET',
            `${ apiPath }/tpos/${ this.user.profile.organization.id }/reputation/google-business-profile`,
            listParams,
        );
    }
}
