<div class="sidenav-container" [class.collapsed]="!expanded" [class.visible]="visible">
	<div class="sidenav">
        <div class="sidenav-header-container">
            <div class="sidenav-header"><ng-content select="[header]"></ng-content></div>
            <a class="sidenav-switcher visible-switcher" tabindex="-1" #toggleElement
               (focus)="showMenu()" (mousedown)="hideIfVisible($event)" (blur)="delayHideMenu($event)"><span class="c-icon c-icon-triangle-right"></span></a>
            <a class="sidenav-switcher expanded-switcher" (click)="toggleExpanded()"><span class="c-icon c-icon-triangle-right"></span></a>
        </div>
		<generic-side-menu #menuElement class="sidenav-menu" [items]="menuItems" [updateOn]="menuUpdate$">
			<ng-template label let-item="item">
				<span>
					{{item.title}}
					<span *ngIf="item.badge as badge;" class="label label-success text-smaller">{{badge}}</span>
				</span>
				<span class="icon-alert" *ngIf="item.hasAlert | async"></span>
			</ng-template>
		</generic-side-menu>
	</div>
	<div class="content">
		<ng-content></ng-content>
	</div>
</div>
