<div class="modal-header">
	<h4 class="modal-title">
		Confirm to proceed
	</h4>
</div>
<div class="modal-body">
	<p class="well text-center text-bigger">You are about to link</p>

	<div class="documents">
		<div class="row">
			<div class="col-md-10 col-md-offset-1">
				<ul class="files-new">
					<li class="droppable">
						<div class="frontface no-actions">
							<div class="status"></div>
							<h4>{{toDoc.name}}</h4>
							<span *ngIf="toDoc.libraryId" class="label label-default text-smaller">from Library</span>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<span class="glyphicon glyphicon-arrow-down text-center"></span>

		<div class="row">
			<div class="col-md-10 col-md-offset-1">
				<ul class="files-new">
					<li class="droppable">
						<div class="frontface no-actions">
							<div class="status"></div>
							<h4>{{fromDoc.name}}</h4>
							<span *ngIf="fromDoc.libraryId" class="label label-default text-smaller">from Library</span>
						</div>
					</li>
				</ul>
			</div>
		</div>

	</div>
</div>
<div class="modal-footer">
	<div class="btn-group-lg">
		<button type="button" (click)="onConfirm()" class="btn btn-primary">Confirm</button>
		<button type="button" (click)="modalRef.hide()" class="btn btn-default">Cancel</button>
	</div>
</div>

