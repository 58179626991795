import { includes } from 'lodash';

import { allowedConnectionStatuses, allowConnectionStatusCheck } from './allowed-connection-statuses';

export class SocialAccountLink {
	data: any = {};
	isTpo: boolean;
	isComergence: boolean;
	ui: any = {};

	isPublisherActive: boolean = false;

	constructor({
		data,
		isTpo,
		isComergence,
	}) {
		this.data = data;
		this.isTpo = isTpo;
		this.isComergence = isComergence;

		this.isPublisherActive = data.publisherStatus === 'A';
		this.ui.canRequestAccess = (
			data.isMortgageRelated &&
			!data.publisherStatus &&
			!data.isManual &&
			data.networkCode !== 'RS'
		);
		this.ui.isDangerClass = this.isDangerClass(data);
		this.ui.hasActionDropdown = this.isActionDropdown(data);
	}

	isRSSLink = (): boolean => {
		return this.data.isManual || !includes(allowedConnectionStatuses, this.data.networkCode);
	}

	setRemovalRequested = (isRequested): void => {
		this.data.isRemovalRequested = isRequested;

		this.ui.isDangerClass = this.isDangerClass(this.data);
		this.ui.hasActionDropdown = this.isActionDropdown(this.data);
	}

	isActionDropdown(data) {
		return !(this.isTpo && data.lastConnectionStatus === 'CONNECTED') && data.linkId && data.isEditable && !data.isRemovalRequested;
	}

	updateLinkStatus = (status): void => {
		if (this.isRSSLink()) {
			this.data.linkStatus = 'RSS';
			return;
		}

		this.data.linkStatus = status;
	}

	isDangerClass = (data) => {
		return (
			this.isTpo &&
			(data.lastConnectionStatus === 'DISCONNECTED' || data.linkStatus === 'INSUFFICIENT_PERMISSIONS') &&
			!data.isRemovalRequested &&
			allowConnectionStatusCheck(data)
		) || (
			this.isComergence &&
			(data.isRemovalRequested || data.monitoringStatus === 'pending')
		);
	}
}
