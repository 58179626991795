<div class="gray-head">
	<h2>Applications</h2>
</div>

<div class="remote-resource list-management" [class.resolved]="!resolving">
	<div class="filters-rows">
		<div class="row filter-labels">
			<div class="col-md-2">
				<div class="text-muted">Search</div>
			</div>
			<div class="col-md-2">
				<div class="text-muted">Application Status</div>
			</div>
			<div class="col-md-2">
				<div class="text-muted">Application Type</div>
			</div>
			<div class="col-md-2">
				<div class="text-muted">Channel</div>
			</div>
		</div>
		<div class="row filter-selectors">
			<div class="col-md-2">
				<search-list placeholder="Name, NMLS ID"></search-list>
			</div>
			<div class="col-md-2">
				<ng-select
					[ngModel]="params.applicationStatus"
					(change)="setFilter({ applicationStatus: $event })"
					[items]="filters.applicationStatus.$resolved ? filters.applicationStatus : null"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[loading]="!filters.applicationStatus.$resolved"
					placeholder="All Statuses">
				</ng-select>
			</div>
			<div class="col-md-2">
				<ng-select
					[ngModel]="params.applicationType"
					(change)="setFilter({ applicationType: $event })"
					[items]="filters.applicationType"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					placeholder="All Types">
				</ng-select>
			</div>
			<div class="col-md-2">
				<ng-select
					[ngModel]="params.channelName"
					(change)="setFilter({ channelName: $event })"
					[items]="filters.channelName.$resolved ? filters.channelName : null"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[loading]="!filters.channelName.$resolved"
					placeholder="All Channels">
				</ng-select>
			</div>

			<div class="col-md-2">
				<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
			</div>

			<div class="col-md-2">
				<div class="horizontal-flex-group right">
					<div class="dropdown" dropdown [insideClick]="true">
						<a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Manage Columns" placement="top">
							<span class="c-icon c-icon-list-bullet"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li *ngFor="let column of columnValues()">
								<a class="link-option" (click)="toggleColumn(column.id)">
									<span class="checkbox-holder">
										<input type="checkbox" [checked]="column.enabled">
										{{column.name}}
									</span>
								</a>
							</li>
						</ul>
					</div>
					<div class="dropdown" dropdown *ngIf="User?.profile?.can('EXPORT_APPLICATIONS')">
						<a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Export" placement="top">
							<span class="c-icon c-icon-download-alt"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li><a class="link-option" (click)="export(true)">Export all available fields</a></li>
							<li><a class="link-option" (click)="export()">Export selected fields</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div [class.hidden]="!moreFilters">
			<div class="row filter-labels">
				<div class="col-md-2">
					<div class="text-muted">Assigned</div>
				</div>
				<div class="col-md-2">
					<div class="text-muted">ALT Assigned</div>
				</div>
				<div class="col-md-2">
					<div class="text-muted">Created After</div>
				</div>
				<div class="col-md-2">
					<div class="text-muted">Created Before</div>
				</div>
				<div class="col-md-2">
					<div class="text-muted">Optional Status</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<ng-select
						[ngModel]="params.accountExecutive"
						(change)="setFilter({ accountExecutive: $event })"
						[items]="filters.accountExecutive.$resolved ? filters.accountExecutive : null"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[loading]="!filters.accountExecutive.$resolved"
						placeholder="All Assigned">
					</ng-select>
				</div>
				<div class="col-md-2">
					<ng-select
						[ngModel]="params.alternativeAssignee"
						(change)="setFilter({ alternativeAssignee: $event })"
						[items]="filters.alternativeAssignee.$resolved ? filters.alternativeAssignee : null"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[loading]="!filters.alternativeAssignee.$resolved"
						placeholder="All ALT Assigned">
					</ng-select>
				</div>
				<div class="col-sm-2">
					<input type="text" class="form-control"
					       bsDatepicker
					       [bsValue]="params.createdAfter"
					       [maxDate]="params.createdBefore"
					       placeholder="mm/dd/yyyy"
					       (bsValueChange)="setFilter({createdAfter: $event})"
					/>
				</div>
				<div class="col-sm-2">
					<input type="text" class="form-control"
					       bsDatepicker
					       [bsValue]="params.createdBefore"
					       [minDate]="params.createdAfter"
					       placeholder="mm/dd/yyyy"
					       (bsValueChange)="setFilter({createdBefore: $event})"
					/>
				</div>
				<div class="col-md-2">
					<ng-select
						[ngModel]="params.optionalStatus"
						(change)="setFilter({ optionalStatus: $event })"
						[items]="filters.optionalStatus.$resolved ? filters.optionalStatus : null"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[loading]="!filters.optionalStatus.$resolved"
						placeholder="All Statuses">
					</ng-select>
				</div>
			</div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-3" columnName="clientName">Client</sortable-header>
			<sortable-header class="col-sm-a" columnName="applicationStatus"><span class="two-liners">Application Status</span></sortable-header>
			<sortable-header class="col-sm-a" columnName="channelName">Channel</sortable-header>
			<ng-container *ngFor="let column of columnValues()">
				<sortable-header class="col-sm-a" isColumnEnabled [columnName]="column.id">
					<span class="two-liners">{{column.name}}</span>
				</sortable-header>
			</ng-container>
            <sortable-header class="col-f-150" columnName="" [active]="false"></sortable-header>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<span class="col-sm-12 va-m text-center">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>
		<div class="row-fluid" *ngFor="let item of list | async">
			<span class="col-md-3 text-left">
				<a uiSref="client.:id.account.information" [uiParams]="{id: item.tpoId}">{{item.clientName}}</a><br>
				<span class="text-light text-smaller">NMLS ID:{{item.nmlsId}}</span>
			</span>
			<span class="col-md-a">
				<span class="dot block" [ngClass]="('dot-' + (applicationStatusColors[item.applicationStatus.id] || 'warning'))"></span>&nbsp;
				<a [aDisabled]="item.channel.id == -1 || ['AP', 'CN', 'DE', 'WD'].indexOf(item.applicationStatus.id) >= 0" uiSref="client.:id.application-summary.:applicationId" [uiParams]="{applicationId: item.applicationId, id: item.tpoId}">
					{{item.applicationStatus.name|| '&ndash;'}}
				</a>
			</span>
			<span class="col-md-a">
				<a [aDisabled]="item.channel.id == -1" uiSref="client.:id.account.channels" [uiParams]="{id: item.tpoId }">{{item.channel.name || '&ndash;'}}</a>
			</span>
			<span class="col-md-a text-bigger" isColumnEnabled columnName="approvalStatus"><span class="label block" [ngClass]="'label-' + (relationStatusColors[item.approvalStatus.id] || 'default')">{{item.approvalStatus.name || '&ndash;'}}</span></span>
			<span class="col-md-a" isColumnEnabled columnName="statusReason">{{item.statusReason || '&ndash;'}}</span>
			<ng-container [ngSwitch]="hasStatusPermission  && ['NS', 'UR', 'DR', 'LR'].indexOf(item.applicationStatus.id) >= 0">
				<ng-container *ngSwitchCase="true">
					<span class="col-md-a" isColumnEnabled columnName="expirationDate">
						<a class="btn btn-tertiary inline-edit" (click)="editExpirationDate(item)">
							<span class="glyphicon glyphicon-pencil"></span>
							<span class="text-default">{{!item.expirationDate ? '&ndash;' : item.expirationDate | date:'MM/dd/yyyy'}}</span>
						</a>
					</span>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<span class="col-md-a" isColumnEnabled columnName="expirationDate">{{!item.expirationDate ? '&ndash;' : item.expirationDate | date:'MM/dd/yyyy'}}</span>
				</ng-container>
			</ng-container>
			<span class="col-md-a" isColumnEnabled columnName="accountExecutive">{{item.accountExecutive.name || 'Unassigned'}}</span>
			<span class="col-md-a" isColumnEnabled columnName="alternativeAssignee">
                <cell-select class="inline-edit"
                             [editable]="hasStatusPermission && item.inProgress"
                             (open)="openAltAssigned($event, item)"
                             (save)="saveAltAssigned($event, item)"
                             [config]="altAssignedConfig">
                    {{item.alternativeAssignee?.name || '&ndash;'}}
                </cell-select>
			</span>
			<span class="col-md-a" isColumnEnabled columnName="statusDate">{{!item.statusDate ? '&ndash;' : item.statusDate | date:'MM/dd/yyyy'}}</span>
			<span class="col-md-a" isColumnEnabled columnName="createdDate">{{!item.createdDate ? '&ndash;' : item.createdDate | date:'MM/dd/yyyy'}}</span>
			<span class="col-md-a" isColumnEnabled columnName="optionalStatus">
                <optional-status
                    [applicationId]="item.applicationId"
                    [status]="item.optionalStatus"
                    [statusList]="filters.optionalStatus.$promise | async"
                    [canEdit]="hasStatusPermission"
                    (onUpdate)="updateOptionalFilter(item, $event)"
                ></optional-status>
            </span>
			<span class="col-md-a" isColumnEnabled columnName="applicationType">{{item.applicationType == 'NEW' ? 'New' : 'Renewal'}}</span>
			<span class="col-md-a" isColumnEnabled columnName="clientUID">{{item.clientUID || '&ndash;'}}</span>
            <span class="col-f-150 text-right col-actions">
                <div class="flex-row gap">
                    <div class="flex-grow"></div>
                    <ng-container *ngIf="item.inProgress" [ngSwitch]="hasStatusPermission">
						<ng-container *ngSwitchCase="true">
							<application-transition-dropdown
								[applicationStatusId]="item.applicationStatus.id"
								[appStatuses]="appStatuses"
								(appStatusSelected)="setAppStatus(item, $event)"
							>
							</application-transition-dropdown>
						</ng-container>
                        <div *ngSwitchDefault>
                            <a class="btn btn-primary btn-dropdown" tooltip="You don't have permissions" placement="bottom">
                                <span>Status</span>
                                <span class="caret"></span>
                            </a>
                        </div>
                    </ng-container>

					<application-link-dropdown
						[userProfileCanPrintPackage]="User.profile.can('PRINT_PACKAGE')"
						[application]="item"
						[userProfileOrganizationId]="lenderId"
					>
					</application-link-dropdown>
                </div>
            </span>
		</div>

        <div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
            <span class="col-sm-12 text-muted text-bigger text-center">
                {{noMatches ? 'No matches found' : 'No applications'}}
            </span>
        </div>

		<br>
		<paginator class="no-border" [sizes]="[10,25,50]"></paginator>
	</div>
</div>

