<div class="finding">
    <div *ngIf="tpoId && findingId">
        <finding-category-summary-header
            [findingId]="findingId"
            [tpoId]="tpoId">
        </finding-category-summary-header>
    </div>
    <div class="row">
        <div class="flex-row gap-sm">
            <div class="col-sm-3"></div>
            <div class="col-sm-3" *ngIf="params.findingFor == 'I'">
                <span class="text-muted small">Search</span>
            </div>
            <div class="col-sm-6"></div>
        </div>
        <div class="flex-row gap-sm">
            <div class="col-sm-3">
                <ul class="inline-toggle normalize">
                    <li *ngFor="let fType of findingTypeList" [class.active]="fType.key === params.findingFor" (click)="changeFindingForType(fType)">{{fType.label}}</li>
                </ul>
            </div>
            <div class="col-sm-3 display-block" *ngIf="params.findingFor == 'I'">
                <search-list placeholder="Name, NMLS ID"></search-list>
            </div>
            <div class="col-sm-6"></div>
        </div>
    </div>
    <br>

    <div class="remote-resource" [class.resolved]="!resolving && !markingFindingAsReviewed">
        <div class="rows-bordered">
            <div class="row-fluid header">
                <span *ngIf="params.findingFor == 'T'" class="col-sm-4"></span>
                <sortable-header class="col-sm-4 text-center" columnName="name" *ngIf="params.findingFor == 'I'"></sortable-header>
                <span class="col-sm-5">
				<b>Summary</b>
			    </span>
                <sortable-header class="col-sm-1" columnName="createdDate">
				    Date Added
			    </sortable-header>
                <sortable-header columnName="reviewed" class="col-sm-2 text-center">
                    Reviewed By
                </sortable-header>
            </div>
            <div class="row-fluid findings" *ngFor="let item of list | async">
                <span class="col-sm-4 text-bigger text-muted">
                    <span *ngIf="isUserCommergence" class="finding-prefix glyphicon" [ngClass]="item.isVisible ? 'glyphicon-eye-open' : 'glyphicon-eye-close'">&nbsp;</span>
                    <a [uiSref]="sref"
                        [uiParams]="{ id: tpoId, sType: item.categoryId, sid: item.id, scorecardFindingsSummaryComponent: null }"
                        [uiOptions]="{ inherit: false, reload: true }"
                    >
                        <span [ngClass]="isUserCommergence ? 'findings-text-comergenceUser' : 'findings-text-nonComergenceUser'">{{item.name}}</span>
                    </a>
                    <div class="flex-row gap-sm">
                        <span *ngIf="isUserCommergence" class="finding-summary-icon-space">&nbsp;</span>
                        <span tooltip="Current Owner" class="c-icon c-icon-user text-success" *ngIf="item.isPreviousOwner === false && params.findingFor == 'I'"></span>
                        <span tooltip="Previous Owner" class="c-icon c-icon-user text-danger" *ngIf="item.isPreviousOwner === true && params.findingFor == 'I'">&nbsp;</span>
                        <span tooltip="Previous Contact" class="c-icon c-icon-user" *ngIf="item.isPrevious && params.findingFor == 'I'">&nbsp;</span>
                        <span tooltip="This record was removed from NMLS" class="glyphicon glyphicon-ban-circle text-danger" *ngIf="item.params?.removedFromSourceSystem && item.categoryId == 'MFS'" ></span>
                    </div>
                </span>
                <span class="col-sm-5">
                    <span>{{item.realmSummary || '&ndash;'}}</span>
                </span>
                <span class="col-sm-1">
                    <span>{{item.createdDate || '&ndash;' | date: 'MM/dd/YY'}}</span>
                </span>
                <span class="col-sm-2 text-center">
                    <scorecard-finding-summary-mark-reviewed
                        [isUserCommergence]="isUserCommergence"
                        [hasReviewFindingPermission]="hasReviewFindingPermission"
                        [findingId]="item.id"
                        [isUpdated]="item.isUpdated"
                        [reviewed]="item.reviewed"
                        [reviewer]="item.reviewer"
                        [tpoId]="tpoId"
                        [lenderId]="lenderId"
                        [isUserLender]="isUserLender"
                        [hasEditAndViewAlertPermission]="hasEditAndViewAlertPermission"
                        (findingReviewed)="reloadList()"
                        (resolving)="setMarkingReviewed($event)">
                    ></scorecard-finding-summary-mark-reviewed>
                </span>
            </div>
            <div class="row-fluid" *ngIf="(list | async)?.length == 0">
                <span class="col-md-12 text-muted text-center text-bigger">No matches found</span>
            </div>
            <br>
            <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
        </div>
    </div>
</div>
