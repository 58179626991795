<div class="form-group has-feedback">
	<sm-network-icon
		[config]="networkIconConfig"
		[minified]="true"
		[className]="networkIconClassName"
	></sm-network-icon>
	<input
		type="text"
		class="form-control"
		[formControl]="formControl"
		maxlength="2000"
		placeholder="www."
	>
	<a class="form-control-feedback glyphicon glyphicon-remove" (click)="reset.emit()" *ngIf="showReset"></a>
</div>
