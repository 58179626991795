import { Component, Injector, Input, Output, EventEmitter } from '@angular/core';
import { StateService } from '@uirouter/core';
import { from, Observable } from 'rxjs';
import { reject, find, forEach, includes, map, filter } from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals';
import { DefaultNetworkCodes } from 'commons/components/sm-icons';
import { filterUniqueByPipe } from 'commons/pipes/array-uniq-by.pipe';

import { SocialMediaRSSResourceService } from 'tpo/social-compliance/publisher/library/content/social-media-rss-resource.service';

import { PostsResourceService } from '../../../posts-resource.service';
import { TemplatesResourceService } from '../../../../library';
import { AttachmentsConfig } from '../../../../attachments/attachments.config';

const postStatuses = {
	DRAFT: {
		title: 'Draft',
		color: 'default',
	},
	SCHEDULED: {
		title: 'Scheduled',
		color: 'warning',
	},
	SENT: {
		title: 'Posted',
		color: 'success',
	},
};

const campaignStatuses = {
	'Not Started': {
		color: 'success',
	},
	'In Progress': {
		color: 'warning',
	},
	'Ended': {
		color: 'default',
	},
};

const accountStatuses = {
	SUCCESS: {
		cssClass: 'c-icon-status-check text-green',
		title: 'Posted',
	},
	FAILED: {
		cssClass: 'c-icon-status-cross text-red',
		title: 'Failed',
	},
	PROCESSING: {
		cssClass: 'c-icon-status-uncheck text-gray',
		title: 'Processing',
	},
};

const networks = DefaultNetworkCodes;

@Component({
	templateUrl: './post-view.component.html',
	selector: 'publisher-post-view',
})
export class PostViewComponent {

	// on calendar we nee only part of the view, this flag will trigger proper parts to show
	@Input()
	embedded: boolean = false;

	@Output()
	onClose: EventEmitter<any> = new EventEmitter();

	@Output()
	onEdit: EventEmitter<any> = new EventEmitter();

	title: string;
	params: any = {};
	post: any;
	attachmentsErrors = [];
	template: any;
	accounts: any;
	accounts$: Observable<any>;
	campaigns: any;
	campaigns$: Observable<any>;
	canViewCampaigns: boolean;
	postStatuses: any = postStatuses;
	accountStatuses: any = accountStatuses;
	campaignStatuses: any = campaignStatuses;
	attachmentConfig: AttachmentsConfig;
	previewAccounts: any;
	linkPreview: any = {
		entries: [],
		$resolved: false,
	};
	resolvedDynamicContent: Record<string, string> = {};
	modalRef: BsModalRef;

	constructor(
		public injector: Injector,
		public state: StateService,
		public historyLogService: HistoryLogService,
		public user: UserService,
		public postsResource: PostsResourceService,
		public templatesResource: TemplatesResourceService,
		public SocialMediaRSS: SocialMediaRSSResourceService,
		public modalService: BsModalService,
	) {
		this.attachmentConfig = {
			tpoId: this.user.profile.organization.id,
			template: false,
		};

		// legacy injections
		const TimezonesService = this.injector.get('TimezonesService');

		const self = this;
		this.title = 'Post';

		const { postId } = state.params;

		Object.assign(this.params, { postId });

		this.post = postsResource.post.get(this.params);

		this.accounts = postsResource.post.postedAccounts(this.params);
		this.accounts$ = from(this.accounts.$promise);

		this.campaigns = postsResource.post.postedCampaigns(this.params);
		this.campaigns$ = from(this.campaigns.$promise);
		this.canViewCampaigns = user.profile.can('TPO_PB_VIEW_CAMPAIGNS');

		this.template = {
			resolved: true,
			_fetchTemplate(templateId): Promise<any> {
				this.resolved = false;
				return templatesResource.template.get({ templateId }).$promise
					.catch((rejection) => {
						// template could be removed, but there should be a possibility to view post details
						rejection.stopPropagation();
					})
					.finally((): void => {
						this.resolved = true;
					});
			},
			init(templateId): Promise<any> {
				if (templateId) {
					this._fetchTemplate(templateId)
						.then((data): void => {
							if (data && data.summary) {
								self.title = data.summary;
							}
						});
				}

				return Promise.resolve();
			},
		};

		Promise.all([
			this.post.$promise,
			this.accounts.$promise,
		])
			.then((): void => {
				if (!this.post.sentDate && this.post.isEnrolling) {
					this.accounts.push({
						linkId: 'enroll',
						name: 'Enrolled Users',
						networkCode: 'EU',
					});
				}

				this.setAccountPublishStatus(this.accounts, this.post);

				this.template.init(this.post.templateId);

				const { scheduledDate, timezone } = this.post;
				if (scheduledDate) {
					const timezoneTime = TimezonesService.convertTimezoneToUTC(scheduledDate, timezone, 'MM/DD/YYYY, hh:mm A');
					const timezoneName = TimezonesService.getZoneAbbr(timezone);
					this.post.scheduledTimeInZone = `${ timezoneTime } ${ timezoneName }`;
				}

				if (this.post.isEnrolling) {
					this.previewAccounts = reject (this.accounts, {linkId: 'enroll'});

					networks.forEach((networkCode) => {
						if (!find(this.accounts, { networkCode })) {
							this.previewAccounts.push({
								networkCode,
								name: 'User name',
							});
						}
					});
				} else {
					this.previewAccounts = this.accounts;
				}

				// track changes should come last, since it relies on this.previewAccounts
				this.trackTextChanges();
			});
	}

	isResolved = (): boolean => (
		this.post.$resolved &&
		this.accounts.$resolved &&
		this.campaigns.$resolved &&
		this.template.resolved
	)

	remove(): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Confirm Post removal',
				confirmText: 'Remove',
				cancelText: 'Cancel',
				message: 'Are you sure you want to delete this Post?',
				onConfirm: () => {
					this.modalRef.content.resolving = true;
                    this.postsResource.doRemove(
						this.params,
                        this.post,
						() => {
							this.modalRef.content.resolving = false;
							this.modalRef.hide();
							if (this.embedded) {
								this.onEdit.emit({ data: { removedPost: true } });
							} else {
								this.close();
							}
						},
						({ data }) => {
							this.modalRef.content.resolving = false;
							this.modalRef.content.errorText = data.message;
						},
					);
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	// Reusable for all publisher view components
	edit(withRedirect = true): void {
		this.onEdit.emit({
			postId: this.post.id,
			data: { isEditing: true },
		});

		if (withRedirect) {
			this.state.go('.edit', {}, { location: 'replace' });
		}
	}

	// Reusable for all publisher full-page components
	close = (withRedirect = true): void => {
		this.onClose.emit();

		if (withRedirect) {
			const backState = this.historyLogService.findBackState([
				'**.new',
				'**.:postId.**',
			], false);

			if (backState) {
				return this.historyLogService.goBackTo(backState);
			}

			this.state.go('social-compliance.publisher.posts');
		}
	}

	trackTextChanges(): void {
		let networkCodes = this.accounts.reduce((acc, { networkCode }) => (
			acc.includes(networkCode)
				? acc
				: acc.concat(networkCode)
		), []);

		if (includes(networkCodes, 'EU')) {
			networkCodes = networks;
		}

		const previewAccountsFromView = filterUniqueByPipe(this.previewAccounts, 'networkCode');
		const accountsWithEnrolled = map(previewAccountsFromView, (account) => ({
			networkCode: account.networkCode,
			linkId: account.linkId,
		}));
		const accounts = map(filter(accountsWithEnrolled, 'linkId'), 'linkId');

		// RM-28497: ACCOUNTS needed for dynamic content only,
		// if no accounts passed, BE skip string replacement
		const request = {
			networkCodes,
			text: `${this.post.text}`,
			accounts,
		};

		this.linkPreview.resolved = false;

		this.postsResource.post.preview(request, ({ linkPreviews, networkCodeToResolvedText }) => {
			this.resolvedDynamicContent = networkCodeToResolvedText;
			this.linkPreview = {
				entries: linkPreviews,
				$resolved: true,
			};
		});
	}

	error = (error: [{ networkCode: string, error: string }?]): void => {
		this.attachmentsErrors = error;
	}

	setAccountPublishStatus(accounts, post): void {
		forEach(accounts, (account) => {
			const accountStatus = (find(post.linkPublishStatus, {linkId: account.linkId}) as any) || { status: 'PROCESSING' };
			account.publishStatus = this.accountStatuses[accountStatus.status];
		});
	}
}
