import { Component, OnInit } from '@angular/core';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { UIRouter } from '@uirouter/core';
import {
    ChannelAlert,
    ChannelAlertConfig, ChannelAlertsGrouped,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.interface';
import { Channel } from 'lender/manage-channels/manage-channel-card.interface';
import { UserService } from 'angularjs-providers/user.provider';
import {
    AlertsSortingService,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.service';

@Component({
    templateUrl: 'manage-channel-alerts.component.html',
})
export class ManageChannelAlertsComponent implements OnInit {
    alerts: ChannelAlert[];
    alertGroups: ChannelAlertConfig;
    channel: Channel;
    channelId: number;

    leftAlertGroup: ChannelAlertsGrouped[] = [];
    rightAlertGroup: ChannelAlertsGrouped[] = [];

    canEditAlerts: boolean;
    companyEditingDisabled: boolean = false;
    individualEditingDisabled: boolean = false;
    resolving: boolean;

    constructor(
        private manageChannelService: ManageChannelService,
        private alertsSortingService: AlertsSortingService,
        private router: UIRouter,
        private user: UserService,
    ) {}

    async ngOnInit() {
        this.canEditAlerts = this.user.profile.can('MANAGE_CHANNEL_SETTINGS')
        try {
            this.resolving = true;
            this.channelId = this.router.globals.params.id;
            [
                this.alerts,
                this.alertGroups,
                this.channel,
            ] = await Promise.all([
                this.manageChannelService.getAlerts(this.channelId).toPromise(),
                this.manageChannelService.getAlertConfig().toPromise(),
                this.manageChannelService.getChannelDetails(this.channelId).toPromise(),
            ]);
            this.splitAlertsByGroup(this.alerts, this.alertGroups);
        } finally {
            this.resolving = false;
        }
    }

    companyEditing(value: boolean) {
        this.individualEditingDisabled = value;
    }

    individualEditing(value: boolean) {
        this.companyEditingDisabled = value;
    }

    splitAlertsByGroup(alerts: ChannelAlert[], alertGroups: ChannelAlertConfig) {
        this.leftAlertGroup = [];
        this.rightAlertGroup = [];
        alertGroups.groupings[0].forEach( groupName => {
            let newAlertGroup: ChannelAlertsGrouped = {
                groupName: groupName,
                alerts: this.getAlertsInGroup(groupName),
            };
            newAlertGroup = this.alertsSortingService.orderAlertGroup(newAlertGroup);
            this.leftAlertGroup.push(newAlertGroup);
        });
        alertGroups.groupings[1].forEach( groupName => {
            let newAlertGroup: ChannelAlertsGrouped = {
                groupName: groupName,
                alerts: this.getAlertsInGroup(groupName),
            };
            newAlertGroup = this.alertsSortingService.orderAlertGroup(newAlertGroup);
            this.rightAlertGroup.push(newAlertGroup);
        });
    }

    getAlertsInGroup(groupName: string): ChannelAlert[] {
        const matchingAlerts = []
        this.alerts.forEach( (alert) => {
            if(alert.alertGroup == groupName) {
                matchingAlerts.push(alert);
            }
        })
        return matchingAlerts;
    }

    async saveAlerts(changedAlerts: ChannelAlert[]) {
        this.alerts.forEach((alert) => {
            const index = this.findByCategoryCode(changedAlerts, alert.alertCategoryCode);
            if(index == -1) {
                changedAlerts.push(alert);
            }
        });

        try {
            this.resolving = true;
            this.alerts = await this.manageChannelService.updateAlerts(this.channelId, changedAlerts).toPromise();
            this.splitAlertsByGroup(this.alerts, this.alertGroups);
        } finally {
            this.resolving = false;
        }
    }

    findByCategoryCode(alertList: ChannelAlert[], alertCategoryCode: string): number {
        return alertList.findIndex((alert) => {
            return alert.alertCategoryCode == alertCategoryCode
        });
    }
}
