import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals';
import { DocumentComponent } from 'shared/documents';

// import { TpoDocumentUploadFromLibraryModalComponent } from './upload-from-library-modal';
import { ComergenceDocument } from 'comergence/documents/comergence-documents.interface';
import { ComergenceDocumentsService } from 'comergence/documents/comergence-documents.service';

@Component({
	selector: 'comergence-document-list-item',
	templateUrl: './comergence-document-list-item.component.html',
})
export class ComergenceDocumentListItemComponent extends DocumentComponent<ComergenceDocument> {
	modalRef: BsModalRef;

	get templateDownloadLink(): string {
		const {
			id: documentId,
			libraryId,
		} = this.document;
		return this.documentsService.getTemplateDownloadLink({
			documentId,
			libraryId,
		});
	}

	constructor(
		private stateService: StateService,
		private userService: UserService,
		private documentsService: ComergenceDocumentsService,
		private modalService: BsModalService,
	) {
		super(userService);
	}

	// TODO: use shared upload logic
	async confirmUpload(file: File): Promise<void> {
		return new Promise((resolve, reject): void => {
			const { name } = this.document;
			const initialState = {
				title: 'Upload Document',
				message: `
					<div class="modal-message">
						<div class="well bigger">You are about to upload <strong>${file.name}</strong> to this document</div>
						<span class="glyphicon glyphicon-arrow-down"></span>
						<div class="document-card">${name}</div>
					</div>
				`,
				confirmText: 'Confirm',
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});
	}

	async onFileSelect(files: FileList): Promise<void> {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		try {
			await this.confirmUpload(file);

			this.modalRef.content.resolving = true;
			try {
				const {
					id: documentId,
					libraryId,
				} = document;
				this.document = documentId
					? await this.documentsService.uploadLibraryDocument(
						{ documentId },
						{ file },
					).$promise
					: await this.documentsService.uploadClientLibraryDocument(
						{ libraryId },
						{ file },
					).$promise as ComergenceDocument;

				this.actionSuccess();
			} catch (error) {
				document.$error = error.data?.message;
			}
		} catch (e) {
			if (e) {
				this.document.$error = e.data?.message;
			} else {
				// Promise reject from `confirmUpload`
			}
		}
		this.closeModal();
	}

	showHistory(): void {
		const {
			id: documentId,
			// libraryId,
		} = this.document;
		if (documentId) {
			this.stateService.go('documents.list.:documentId.history', { documentId });
		// } else {
		// 	this.stateService.go('documents.list.library.:libraryId.history', { libraryId });
		}
	}

	private actionSuccess(): void {
		this.document.$success = true;
		this.userService.getUser();
		setTimeout(() => {
			this.document.$success = false;
		}, 5000);
	}

	private closeModal(): void {
		this.modalRef?.hide();
	}
}
