<section class="social-compliance-publisher-group-contacts">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<div class="text-muted">
					Search
				</div>
			</div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<search-list placeholder="Name or NMLS ID"></search-list>
			</div>
			<div class="col-sm-9 text-right">
				<button type="button" class="btn btn-tertiary btn-icon" (click)="renameGroup()" *ngIf="User.can('TPO_PB_MANAGE_GROUPS')">
					<span class="glyphicon glyphicon-pencil"></span>
				</button>
				<button type="button" class="btn btn-tertiary btn-icon" (click)="removeGroup()" *ngIf="User.can('TPO_PB_MANAGE_GROUPS')">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				<a class="btn btn-primary" uiSref=".manage" [uiParams]="{ page: null, size: null, sort: null, q: null }" *ngIf="User.can('TPO_PB_MANAGE_GROUPS')">Manage</a>
			</div>
		</div>
	</div>
	<div class="rows-bordered remote-resource" [class.resolved]="contacts.$resolved">
		<div class="row-fluid header">
			<div class="col-sm-a" [ngClass]="getSortClass('contactName')" (click)="setSort('contactName')">
				<b>Name</b>
			</div>
			<div class="col-sm-a" [ngClass]="getSortClass('dateAdded')" (click)="setSort('dateAdded')">
				<b>Date Added</b>
			</div>
			<div class="col-sm-2"></div>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
		</div>

		<ng-container *ngFor="let item of contacts">
			<div class="row-fluid" *ngIf="item.contactId !== removeContactId">
				<div class="col-sm-a va-m">
					<a *ngIf="item.contactId && item.contactName" uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: item.contactId }" target="_blank">
						{{item.contactName}}
					</a>
					<span *ngIf="!item.contactId">{{item.contactName || '&ndash;'}}</span>
					<div class="text-smaller text-muted" *ngIf="item.contactNmlsId">NMLS ID: {{item.contactNmlsId}}</div>
				</div>
				<div class="col-sm-a va-m">
					{{(item.dateAdded | date: 'MM/dd/yyyy') || '&ndash;'}}
				</div>
				<div class="col-sm-2 va-m text-right">
					<div class="dropdown" dropdown *ngIf="User.can('TPO_PB_MANAGE_GROUPS')">
						<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
							<span class="c-icon c-icon-dot-3"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li role="menuitem">
								<a (click)="removeContact(item.contactId)">Remove</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row-fluid row-remove" *ngIf="item.contactId === removeContactId">
				<div class="col-sm-a va-m">
					Are you sure you want to remove {{item.contactName}}?
				</div>
				<div class="col-sm-3 va-m text-right">
					<button type="button" class="btn btn-primary" (click)="confirmRemoveContact()">Remove</button>
					<button type="button" class="btn btn-secondary" (click)="cancelRemoveContact()">Cancel</button>
				</div>
			</div>
		</ng-container>

		<div class="row-fluid" *ngIf="noMatches">
			<div class="col-sm-12 text-center text-bigger text-muted">No matches found</div>
		</div>

		<div class="row-fluid" *ngIf="!contacts.length && !noMatches">
			<div class="col-sm-12 text-center text-bigger text-muted">No contacts</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="contacts" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</section>
