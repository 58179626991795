import { Injectable } from '@angular/core';

import { AbstractIssueStatus } from '../../abstractIssueStatus';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'angularjs-providers/user.provider';
import { OrderPaymentAlert, OrdersService } from 'shared/social-compliance/orders/orders.service';


@Injectable()
export class SocialComplianceOrderPaymentFailedIssueStatus extends AbstractIssueStatus {
    public constructor(
        private readonly ordersService: OrdersService,
        private readonly userService: UserService
    ) {
        super();
    }

    get(forceUpdate: boolean = false): Observable<boolean> {
        if (forceUpdate && this._initialized) {
            // reset to false when force update is triggered.
            this.subject.next(false);
        }

        return super.get(forceUpdate);
    }

    // Override
	public async updateValue(): Promise<boolean> {
        const tpoId: number = this.userService.profile.organization.id;

        return this.ordersService.getTpoOrderPaymentAlert(tpoId)
            .pipe(map((orderPaymentAlert: OrderPaymentAlert) => orderPaymentAlert.mostRecentOrderPaymentFailed))
            .toPromise();
	}
}
