import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Notifications } from './comergence-documents.interface';


@Component({
	templateUrl: './comergence-documents.component.html',
})
export class ComergenceDocumentsComponent implements OnInit, OnDestroy {
    private readonly userProfileUnsubscribe$: Subject<void> = new Subject();
    public userProfile: UserProfile;

    @Input() notifications: Notifications = {};

    public constructor(private readonly userService: UserService) {
    }

    // Override
    public ngOnInit(): void {
        this.userService.profile$
            .pipe(takeUntil(this.userProfileUnsubscribe$))
            .subscribe((userProfile: UserProfile) => this.userProfile = userProfile);
    }

    // Override
    public ngOnDestroy(): void {
        this.userProfileUnsubscribe$.next();
        this.userProfileUnsubscribe$.complete();
    }
}
