import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

@Component({
	templateUrl: './review-status-modal.component.html',
})
export class ReviewStatusModalComponent implements OnInit {
	form: RealmFormGroup;
	review: any;
	statuses: any;
	update: (status: any) => Promise<any>;
	resolved = true;

	get statusControl(): RealmFormControl {
		return this.form.get('status') as RealmFormControl;
	}

	get statusControlPlaceholder(): string {
		return `Select ${this.statusControl.label}`;
	}

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.form = new RealmFormGroup({
			status: new RealmFormControl(
				'status',
				{
					label: 'Review Status',
					value: this.review.reviewStatus,
					updateOn: 'change',
				},
				DropdownRequiredValidator,
			),
		});
	}

	submit(): void {
		const { status } = this.form.value;

		this.resolved = false;
		this.update({
			code: status,
		})
			.then(
				() => {
					this.close();
				},
				({ data }) => {
					this.form.setServerError(data);
				}
			)
			.finally(() => {
				this.resolved = true;
			})
	}

	close(): void {
		this.modalRef.hide();
	}
}
