<div class="reviews-settings-zillow remote-resource" [class.resolved]="zillowData?.$resolved">
	<form [formGroup]="zillowForm" class="mb20" (onValidSubmit)="save()">

		<div class="clearfix mb10">
            <div class="pull-left">
                <h3>Zillow</h3>
            </div>
			<div class="pull-right">
				<ng-container *ngIf="editMode; else viewModeButtonsTmp">
					<button type="submit" class="btn btn-primary">Save</button>
					<button type="button" class="btn btn-default" (click)="cancelEdit()">Cancel</button>
				</ng-container>

				<ng-template #viewModeButtonsTmp>
					<button *ngIf="zillowData?.partnerId" type="button" class="btn btn-default btn-icon" (click)="remove()">
						<span class="glyphicon glyphicon-trash"></span>
					</button>
					<button type="button" class="btn btn-primary" (click)="edit()">
						<span class="glyphicon glyphicon-pencil"></span> Edit
					</button>
				</ng-template>
			</div>
		</div>

		<form-errors [form]="zillowForm"></form-errors>

		<div class="rows-striped">
			<div class="row-fluid">

				<div class="col-sm-6">
					<div class="form-group row"
						[class.has-error]="editMode && hasError('partnerId')">
						<label for="name" class="col-sm-3 control-label" [class.required]="editMode">
							Partner ID
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editMode" [view]="zillowData?.partnerId || '&ndash;'">
								<ng-template #editTemplate>
									<input id="name" class="form-control" formControlName="partnerId" required>
									<a target="_blank" href="https://www.zillow.com/mortgage/api/#/">Request Partner ID</a>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group row"
						[class.has-error]="editMode && hasError('companyName')">
						<label for="companyName" class="col-sm-5 control-label solid" [class.required]="editMode">
							Company Name on Zillow
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editMode" [view]="zillowData?.companyName || '&ndash;'">
								<ng-template #editTemplate>
									<input id="companyName" class="form-control" formControlName="companyName" required>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="!editMode && zillowData?.avgScore && zillowData.totalReviews" class="row-fluid">
				<div class="col-sm-6">
					<div class="form-group row">
						<label class="col-sm-3 control-label">Avg Rating</label>
						<div class="col-sm-7 control-value">{{zillowData.avgScore}}</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group row">
						<label class="col-sm-5 control-label solid">Total Reviews</label>
						<div class="col-sm-7 control-value">{{zillowData.totalReviews}}</div>
					</div>
				</div>
			</div>

			<div *ngIf="!editMode && zillowData?.profileUrl as url" class="row-fluid">
				<div class="col-sm-12">
					<div class="form-group row">
						<label class="coldp-sm-3 control-label">Zillow Profile</label>
						<div class="coldp-sm-21 control-value">
							<a [href]="url" target="_blank">{{url}}</a>
						</div>
					</div>
				</div>
			</div>

		</div>
	</form>

	<ng-container *ngIf="editMode">
		<div class="alert alert-warning text-center">Your lender name must match your Zillow profile page. Navigate to your page and enter the name as it appears below.</div>
		<img class="mxw100" [src]="exampleImage" alt="example">
	</ng-container>
</div>
