<h3>Removed</h3>

<div class="remote-resource" [class.resolved]="!resolving">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-5">
				<span class="text-muted small">Search</span>
			</div>
			<div class="col-sm-a"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-5">
				<search-list placeholder="Account name, network, URL"></search-list>
			</div>
			<div class="col-sm-a"></div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-11" columnName="account">Account</sortable-header>
			<div class="col-sm-a"></div>
		</div>

        <reset-filters class="row-fluid"></reset-filters>

		<div class="row-fluid" *ngFor="let item of list | async">
			<div class="va-m col-sm-11">
				<div class="flex-row gap">
					<sm-icon
						[config]="item?.data"
						[showRssStatus]="item?.data?.monitoringStatus === 'on'"
					></sm-icon>
					<div class="flex text-overflow">
						<ng-container *ngIf="item?.data?.networkCode !== 'RS' && item?.data?.link; then companyLinkAnchor; else companyLinkName"></ng-container>
						<ng-template #companyLinkAnchor>
							<a [href]="item?.data?.link | hrefReady" target="_blank">{{item?.data?.name}}</a>
						</ng-template>
						<ng-template #companyLinkName>
							{{item?.data?.name}}
						</ng-template>
					</div>
				</div>
			</div>
			<div class="col-sm-a">
				<ng-container *ngIf="canRestore">
					<div class="dropdown" dropdown>
						<a class="dropdown-toggle btn btn-icon btn-tertiary text-bigger text-very-light" dropdownToggle>
							<span class="c-icon c-icon-dot-3"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li role="menuitem">
								<a (click)="restore(item)">Restore</a>
							</li>
						</ul>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
			<div class="col-sm-12 text-center text-bigger text-muted va-m">
				{{noMatches ? 'No accounts found' : 'No accounts removed'}}
			</div>
		</div>
	</div>

	<br/>

	<paginator [sizes]="[10,25,50]"></paginator>
</div>
