import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { SharedDocumentsModule } from 'shared/documents';

import {
	QuestionnaireComponent,
	QuestionnaireDocumentsComponent,
	ConfigureFinancialDocumentsModalComponent,
	QuestionnaireDocumentComponent,
	ManageQuestionnairesComponent,
	EditQuestionnairesComponent,
	QuestionnaireListComponent,
	QuestionnaireListItemComponent,
	QuestionsGroupComponent,
	QuestionsGroupEditComponent,
	QuestionComponent,
	QuestionEditComponent,
	MoveQuestionControlsComponent,
	QuestionNewComponent,
	QuestionFromBankComponent,
	QuestionPreviewComponent,
} from './manage';

import { QuestionnairesComponent } from './list/questionnaires.component';
import { LenderQuestionnairesListService } from './list/questionnaires.service';
import { LenderManageQuestionnaireService } from './manage/questionnaires.service';
import { LenderManageQuestionnairesPreviewService } from './manage/questionnaires-preview.service';
import { QuestionBankComponent } from './bank/bank.component';
import { RemoveBankQuestionModalComponent } from './bank/modals/remove-question-modal.component';
import { BankQuestionUsageModalComponent } from './bank/modals/usage-question-modal.component';
import { QuestionBankCategoriesComponent } from './bank/categories/list.component';
import {
	ManageBankCategoryModalComponent,
	RemoveEmptyBankCategoryModalComponent,
	RemoveBankCategoryWithQuestionsModalComponent,
} from './bank/categories/modals';
import { TpoQuestionnaireModule } from 'tpo/questionnaire/questionnaire.module';

const components = [
	QuestionnairesComponent,
	QuestionnaireComponent,
	QuestionnaireDocumentsComponent,
	ConfigureFinancialDocumentsModalComponent,
	QuestionnaireDocumentComponent,
	ManageQuestionnairesComponent,
	EditQuestionnairesComponent,
	QuestionnaireListComponent,
	QuestionnaireListItemComponent,
	QuestionsGroupComponent,
	QuestionsGroupEditComponent,
	QuestionComponent,
	QuestionEditComponent,
	MoveQuestionControlsComponent,
	QuestionNewComponent,
	QuestionFromBankComponent,
	QuestionBankComponent,
	RemoveBankQuestionModalComponent,
	QuestionBankCategoriesComponent,
	ManageBankCategoryModalComponent,
	RemoveEmptyBankCategoryModalComponent,
	RemoveBankCategoryWithQuestionsModalComponent,
	BankQuestionUsageModalComponent,
	QuestionPreviewComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		ComponentsModule,
		DirectivesModule,
		PipesModule,
		SharedDocumentsModule,
		TpoQuestionnaireModule,
	],
	declarations: [
		...components,
	],
	providers: [
		LenderQuestionnairesListService,
		LenderManageQuestionnaireService,
		LenderManageQuestionnairesPreviewService,
	],
})
export class QuestionnairesModule {}
