import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { filterAttachmentsTransform } from '../attachments/filterAttachmentsTrasform';

declare let apiPath: string;

@Injectable()
export class TemplatesResourceService {
	templates: any;
	template: any;
	folder: any;
	templateNetworks: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const templatesPath = {
			url: `${apiPath}/tpos/:tpoId/social-media-post-templates`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const templatePath = {
			url: `${templatesPath.url}/:templateId`,
			params: {
				...templatesPath.params,
				templateId: '@templateId',
			},
		};

		this.templates = PagedResource(
			templatesPath.url,
			templatesPath.params,
			{
				query: {
					method: 'GET',
					isArray: true,
				},
				tags: {
					url: `${templatesPath.url}/tags`,
					method: 'GET',
					isArray: true,
				},
				browse: {
					url: `${templatesPath.url}/all`,
					params: {
						...templatesPath.params,
						campaignId: '@campaignId',
					},
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.template = $resource(
			templatePath.url,
			templatePath.params,
			{
				create: {
					url: templatesPath.url,
					method: 'POST',
					transformRequest: filterAttachmentsTransform,
				},
				get: {
					method: 'GET',
				},
				update: {
					method: 'PUT',
					transformRequest: filterAttachmentsTransform,
				},
				move: {
					url: `${templatePath.url}/move`,
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
				tags: {
					url: `${templatesPath.url}/tag-hint`,
					method: 'GET',
					isArray: true,
				},
				additionalInfo: {
					url: `${templatePath.url}/additional-info`,
					method: 'POST',
					transformRequest: filterAttachmentsTransform,
				},
				validate: {
					url: `${apiPath}/tpos/:tpoId/social-media/publisher/template/text/validation`,
					method: 'POST',
				},
			},
		);

		this.folder = $resource(
			`${templatesPath.url}/folders/:folderId`,
			{
				...templatesPath.params,
				folderId: '@folderId',
			},
			{
				get: {
					method: 'GET',
				},
				create: {
					method: 'POST',
				},
				edit: {
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
			},
		);

		this.templateNetworks = $resource(
			`${templatePath.url}/network-types`,
			templatePath.params,
			{
				query: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}
}
