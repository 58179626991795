import { Injectable } from '@angular/core';
import { RealmHttpClient, RealmParams } from 'commons/services/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare let apiPath;

interface WarehouseBase {
    approvedLineAmount: number;
    companyName: string;
    contactName: string;
    email: string;
    id: number;
    phone: string;
    sellLoans: boolean;
    minimumTNWRequired: number;
    minimumLiquidityRequired: number;
    maximumLeverageAllowed: number;
    minimumProfitability: number;
}
export interface Warehouse extends WarehouseBase {
    relationshipEndDate: Date | null;
    relationshipStartDate: Date;
}

interface WarehouseBE extends WarehouseBase {
    relationshipEndDate: number | null;
    relationshipStartDate: number;
}

const decodeWarehouse = ({ relationshipStartDate, relationshipEndDate, ...warehouse }: WarehouseBE): Warehouse => ({
    relationshipStartDate: new Date(relationshipStartDate),
    relationshipEndDate: relationshipEndDate ? new Date(relationshipEndDate) : null,
    ...warehouse,
});

const encodeWarehouse = (input: Partial<Warehouse>): Partial<WarehouseBE> => {
    const { relationshipStartDate, relationshipEndDate, ...warehouse } = input;
    return {
        ...(Object.hasOwn(input, 'relationshipStartDate') ? { relationshipStartDate: relationshipStartDate.getTime() } : {}),
        ...(Object.hasOwn(input, 'relationshipEndDate') ? { relationshipEndDate: relationshipEndDate ? relationshipEndDate.getTime() : null } : {}),
        ...warehouse,
    };
};


@Injectable()
export class NewWarehousesService {
    constructor(private http: RealmHttpClient) {
    }

    addNewWarehouse(tpoId, newWarehouse): Observable<Warehouse> {
        return this.http.request<WarehouseBE>(
            'POST',
            `${apiPath}/warehouses`,
            { tpoId },
            encodeWarehouse(newWarehouse),
        ).pipe(
            map(decodeWarehouse),
        );
    }

    updateWarehouse(tpoId, id: number, values: Partial<Warehouse>): Observable<Warehouse> {
        const patchedValues = Object.entries(encodeWarehouse(values))
            .map(([name, value]) => ({ op: 'replace', path: `/${name}`, value }));
        return this.http.request<WarehouseBE>(
            'PATCH',
            `${apiPath}/warehouses/${id}`,
            { tpoId },
            patchedValues,
        ).pipe(
            map(decodeWarehouse),
        );
    }

    getWarehouses(tpoId): Observable<Warehouse[]> {
        return this.http.request<WarehouseBE[]>(
            'GET',
            `${apiPath}/warehouses`,
            { tpoId },
        ).pipe(
            map(warehouses => warehouses.map(decodeWarehouse)),
        );
    }
}
