<form
    class="col-md-12 remote-resource"
    [class.resolved]="!resolving"
    [formGroup]="insuranceBondForm"
    (onValidSubmit)="doSave()"
>
    <div class="row-fluid">
        <div id="carrierName" class="col-sm-4 form-group" [isEditable]="editing" has-error>
            <div view class="control-value">{{ item.carrierName }}</div>
            <input edit type="text" name="carrierName" formControlName="carrierName" class="form-control" maxlength="100" tabindex="1" />
        </div>
        <div id="amount" class="col-sm-3 form-group" [isEditable]="editing" has-error>
            <div view class="control-value">${{ item.amount | number }}</div>
            <input edit type="text" name="amount" formControlName="amount" class="form-control" maxlength="12" />
        </div>
        <div id="phone" class="col-sm-3 form-group" [isEditable]="editing" has-error>
            <div view class="control-value">{{ item.phone || '&ndash;'| tel }}</div>
            <input edit phoneFormatter type="text" name="phone" formControlName="phone" class="form-control" />
        </div>
        <div id="expiration" class="col-sm-3 form-group" [isEditable]="editing" has-error>
            <div view
                class="control-value"
                [class.text-danger]="itemIsExpired"
                [class.text-light]="!itemIsExpired"
            >
                {{ item.expiration ? (item.expiration | date : 'MM/dd/yyyy') : '&ndash;' }}
            </div>
            <input edit
                type="text"
                name="expiration"
                formControlName="expiration"
                class="form-control"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                [placeholder]="'MM/DD/YYYY'"
            />
        </div>
        <div class="col-f-100 text-right va-m button-holder" [ngSwitch]="editing">
            <ng-container *ngSwitchDefault>
                <a *ngIf="userCanAddEdit" class="btn btn-icon btn-tertiary" (click)="doEdit()">
                    <span class="glyphicon glyphicon-pencil"></span>
                </a>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <button class="btn btn-icon btn-tertiary" type="submit"><span class="glyphicon glyphicon-ok"></span></button>
                <a class="btn btn-icon btn-tertiary" (click)="doCancel()"><span class="glyphicon glyphicon-remove"></span></a>
            </ng-container>
        </div>
    </div>
</form>
