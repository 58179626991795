<div>
    <h2 class="flex-row gap-l">
        <span>{{prospect?.companyName}}</span>
        <span class="text-bigger state-badge">
            <span [ngClass]="stateClass">{{stateValues[prospect?.prospectState]}}</span>
        </span>
    </h2>
</div>
<form
    class="remote-resource"
    [class.resolved]="resolved"
    [class.curtain]="![PROSPECT_SECTION.NONE, PROSPECT_SECTION.DETAILS].includes(editing)"
    [formGroup]="form"
    (onValidSubmit)="save()"
>
    <div class="flex-row">
        <h3 class="flex-grow">
            Prospect Information
            <btn class="btn btn-tertiary" uiSref="crm.prospects.:id.history" [uiParams]="{ id: prospect?.id}">
                <span class="c-icon c-icon-back-in-time"></span>
                History
            </btn>
        </h3>

        <div *ngIf="prospect?.editable && prospect?.editableStatus" [ngSwitch]="editing === PROSPECT_SECTION.DETAILS">
            <button *ngSwitchCase="false" type="button" class="btn btn-primary" (click)="edit(PROSPECT_SECTION.DETAILS)">
                <span class="glyphicon glyphicon-pencil"></span> Edit
            </button>
            <ng-container *ngSwitchCase="true">
                <button type="submit" class="btn btn-primary" tabindex="20">
                    Save
                </button>
                <button type="button" class="btn btn-default" (click)="edit(PROSPECT_SECTION.NONE)" tabindex="20">
                    Cancel
                </button>
            </ng-container>
        </div>
    </div>
    <form-errors [form]="form"></form-errors>
    <div class="rows-striped">
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">Related Account</label>
                    <div class="col-sm-8 control-value">
                        <a *ngIf="prospect?.tpoId" class="text-bigger"
                           uiSref="client.:id.account.information" [uiParams]="{id: prospect?.tpoId}">{{prospect?.companyName}}</a>
                        <a *ngIf="!prospect?.tpoId && prospect?.nmlsId" class="text-bigger"
                           uiSref="nmls.client.:id.account.information" [uiParams]="{id: prospect?.nmlsId}">{{prospect?.companyName}}</a>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">State</label>
                    <div class="col-sm-8 control-value">{{prospect?.state}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">Channel</label>
                    <div class="col-sm-8 control-value">{{prospect?.channelName}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">ZIP</label>
                    <div class="col-sm-8 control-value">{{prospect?.zip}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">Location</label>
                    <div class="col-sm-8 control-value">
                        {{prospect?.locationName}}
                        <span *ngIf="prospect?.locationType" [ngClass]="'label label-' + (prospect.locationType == 'HQ' ? 'default' : 'default-wired')">
                            {{prospect.locationType == 'HQ' ? 'Headquarters' : 'Branch'}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">Location NMLS ID</label>
                    <div class="col-sm-8 control-value">{{prospect?.locationNmlsId}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">Address</label>
                    <div class="col-sm-8 control-value">{{prospect?.address}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="form-group row" [ngSwitch]="editing === PROSPECT_SECTION.DETAILS">
                    <label class="col-sm-4 control-label">Status</label>
                    <div *ngSwitchCase="false" class="col-sm-8 control-value">{{prospect?.prospectStatus}}</div>
                    <div *ngSwitchCase="true" class="col-sm-6">
                        <ng-select
                            formControlName="prospectStatus"
                            [items]="statusList | async"
                            [loading]="(statusList | async)?.length < 1"
                            [clearable]="false"
                            bindValue="name"
                            bindLabel="name"
                        ></ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-4 control-label">City</label>
                    <div class="col-sm-8 control-value">{{prospect?.city}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="form-group row" [ngSwitch]="editing === PROSPECT_SECTION.DETAILS && (User.can('ASSIGN_PROSPECT') || prospect?.assigneeUserId == User.id) && prospect.prospectState == 'O'">
                    <label class="col-sm-4 control-label">Assigned</label>
                    <div *ngSwitchCase="false" class="col-sm-6 control-value">{{prospect?.assigneeName || 'Unassigned'}}</div>
                    <div *ngSwitchCase="true" class="col-sm-6">
                        <ng-select
                            formControlName="assigneeUserId"
                            [items]="assigneeList | async"
                            [loading]="(assigneeList | async)?.length < 1"
                            [clearable]="true"
                            bindValue="id"
                            bindLabel="name"
                            placeholder="Unassigned"
                        ></ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
    <h3>Production</h3>
    <div class="rows-striped">
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="row">
                    <label class="col-sm-4 control-label">Total Volume</label>
                    <div class="col-sm-8 control-value"> {{(prospect?.totalVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="row">
                    <label class="col-sm-4 control-label">Brokered Volume</label>
                    <div class="col-sm-8 control-value"> {{(prospect?.brokerVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5">
                <div class="row">
                    <label class="col-sm-4 control-label">Total Units</label>
                    <div class="col-sm-8 control-value"> {{prospect?.individualNmlsCount  || '&ndash;' }}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="row">
                    <label class="col-sm-4 control-label">Sold Volume</label>
                    <div class="col-sm-8 control-value"> {{(prospect?.soldVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}</div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</form>
<ng-container *ngIf="prospect">
    <additional-fields
        [class.curtain]="![PROSPECT_SECTION.NONE, PROSPECT_SECTION.FIELDS].includes(editing)"
        entityTypeId="CRMProspect"
        [entityId]="prospect.id"
        [editPermission]="prospect.editable"
        (editingChange)="$event ? this.edit(PROSPECT_SECTION.FIELDS) : this.edit(PROSPECT_SECTION.NONE)"
    ></additional-fields>
    <prospect-related-contacts
        [class.curtain]="![PROSPECT_SECTION.NONE, PROSPECT_SECTION.CONTACTS].includes(editing)"
        [editable]="prospect.editable"
    ></prospect-related-contacts>
    <related-activities
        [class.curtain]="![PROSPECT_SECTION.NONE, PROSPECT_SECTION.ACTIVITIES].includes(editing)"
    ></related-activities>
    <div [class.curtain]="![PROSPECT_SECTION.NONE, PROSPECT_SECTION.COMMENTS].includes(editing)">
        <h3>Comments</h3>
        <comments
            [resource]="commentResource"
            [permissions]="{ canAdd: true }"
            [addForm]="commentsForm"
            (onAdd)="commentsForm.get('commentType').patchValue('PUBLIC')"
        >
            <ng-template #commentTemplate let-comment="comment">
                <div>
                    <div class="horizontal-flex-group">
                        <div class="full-width">
                            <b>{{comment.createdBy}}</b>
                        </div>
                        <div class="text-light text-smaller row">
                            <span class="col-md-5">{{comment.commentType}}</span>
                            <span class="col-md-7 text-nowrap">{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}</span>
                        </div>
                    </div>
                    <p class="comment">{{comment.comment}}</p>
                </div>
            </ng-template>

            <ng-template #formTemplate
                         let-addForm="addForm"
                         let-add="methods.add"
                         let-entityName="entityName"
                         let-editing="editing"
                         let-submitted="submitted"
                         let-setEditMode="methods.setEditMode"
                         let-resetFormOnEmptyComment="methods.resetFormOnEmptyComment">
                <form [formGroup]="addForm" id="addCommentForm" class="add-comment-form"
                      (onValidSubmit)="add()" autocomplete="off" role="presentation">
                    <div class="mb15 remote-resource" [class.resolved]="!submitted">
                        <div class="form-group full-width">
								<textarea
                                    class="form-control no-resize"
                                    formControlName="comment"
                                    rows="{{editing ? 5 : 1}}"
                                    maxlength="2000"
                                    placeholder="Add your {{entityName | lowercase}} here"
                                    (focus)="setEditMode(true)">
								</textarea>
                        </div>

                        <div class="form-group row" *ngIf="editing">
                            <div class="col-md-3">
                                <ng-select
                                    [items]="commentTypes | async"
                                    [loading]="!typeDescriptions"
                                    formControlName="commentType"
                                    bindLabel="label"
                                    bindValue="code"
                                    [searchable]="false"
                                    [clearable]="false"
                                    required>
                                </ng-select>
                            </div>

                            <div class="col-md-9 control-value" *ngIf="typeDescriptions">
                                {{typeDescriptions[addForm.value.commentType]}}
                            </div>
                        </div>

                        <div class="btn-group-lg" *ngIf="editing">
                            <button  type="submit" class="btn btn-primary"
                                     [disabled]="submitted || !addForm.value.comment">
                                Add Comment
                            </button>
                            <button type="button" class="btn btn-default"
                                    (click)="setEditMode(false); addForm.patchValue({ commentType: 'PUBLIC' })">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </ng-template>
        </comments>
    </div>
</ng-container>
