import { Component, Input, OnInit } from '@angular/core';
import { AccountInformation, AccountInformationService } from 'shared/account/company-information/account-information.service';

@Component({
	templateUrl: './investor-client-menu-container.component.html',
})
export class InvestorClientMenuContainerComponent implements OnInit {
    @Input() lenderInfo: AccountInformation;

	constructor(
		protected accountInformationService: AccountInformationService,
	) {}

	ngOnInit() {
		this.accountInformationService.updateBadges();
	}
}
