import { cloneDeep, filter, forEach, get, sortBy, some } from 'lodash';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/core';

import { RealmFormGroup } from 'commons/forms';
import { NgResource } from 'commons/interfaces';

import {
	CustomFieldsElement,
	CustomFieldsService,
	EntityType,
	LayoutElements,
} from 'shared/fields/custom-fields.service';
import { fieldStates } from 'shared/fields/additional-fields/fields-editable/fields-editable.component';

@Component({
	templateUrl: './additional-fields.component.html',
	selector: 'additional-fields',
})
export class AdditionalFieldsComponent implements OnInit {
	@Input() heading: string;
	@Input() entityTypeId: EntityType;
	@Input() entityId?: number;
	@Input() isNew: boolean;
	@Input() curtain: boolean;
	@Input() editPermission: boolean = true;
	@Input() parentForm?: RealmFormGroup;

	@Input() editing: boolean;
	@Output() editingChange = new EventEmitter<boolean>();

	hasFields: boolean;
	groupedFields: NgResource<LayoutElements> = { fields: [], groups: [] };
	@Output() groupedFieldsChange = new EventEmitter<NgResource<LayoutElements>>();

	groupedFieldsInitial: NgResource<LayoutElements>;
	groupedFieldsRows: {
		fields: Array<[number, number]>,
		groups: Array<{ name: string, fields: Array<[number, number]> }>,
	} = { fields: [], groups: [] };

	additionalFieldsForm: RealmFormGroup = new RealmFormGroup({});

	constructor(
		public fieldsService: CustomFieldsService,
		public stateService: StateService,
        private _cd: ChangeDetectorRef,
	) {}

	removeHiddenFields(groupedFields: NgResource<LayoutElements>): void {
		const isFieldVisible = (field) => field?.customField?.visible || field?.visible;
		groupedFields.fields = filter(groupedFields.fields, isFieldVisible);
		for (const group of groupedFields.groups) {
			group.fields = filter(group.fields, isFieldVisible);
		}
	}

	setHasFields(): void {
		this.hasFields = !!this.groupedFields.fields.length || some(this.groupedFields.groups, 'fields.length');
	}

	async ngOnInit(): Promise<void> {
		this.groupedFields.$resolved = false;
		this.groupedFields = await this.fieldsService.valuesNew(this.entityTypeId, this.entityId).$promise;
		this.removeHiddenFields(this.groupedFields);
		this.setHasFields();

		this.groupedFieldsChange.emit(this.groupedFields);

		this.sortFields();
		this.divideFieldIndexes();
		this.groupedFieldsInitial = this.getGroupedFieldsCopy(this.groupedFields);
		if (this.isNew) {
			this.parentForm?.addControl('additionalFieldsForm', this.additionalFieldsForm);
			this.edit();
		}
		this.groupedFields.$resolved = true;
        this._cd.detectChanges();
	}

	patchField = (field: CustomFieldsElement): void => {
		const { value } = field;
		const { fieldName, controlTypeId } = field.customField || field;
		const { processValue } = fieldStates[controlTypeId] || {};
		this.additionalFieldsForm.get(fieldName)?.patchValue(value && processValue ? processValue(value) : value);
	}

	formInit(): void {
		forEach(this.groupedFields.fields, this.patchField)
		for (const group of this.groupedFields.groups) {
			forEach(group.fields, this.patchField)
		}
	}

	getGroupedFieldsCopy(groupedFields: NgResource<LayoutElements>): NgResource<LayoutElements> {
		const { fields, groups, $promise, $resolved } = groupedFields;
		return {
			fields: cloneDeep(fields),
			groups: cloneDeep(groups),
			$promise: cloneDeep($promise),
			$resolved,
		};
	}

	async save(): Promise<void> {
		this.groupedFields.$resolved = false;

		try {
			this.groupedFieldsInitial = await this.fieldsService.updateValuesNew(
				this.entityTypeId, this.entityId, cloneDeep(this.groupedFields),
			).$promise;
			this.removeHiddenFields(this.groupedFieldsInitial);
			this.setHasFields();
		} catch (e) {
			this.additionalFieldsForm.setServerError(e.data);
		}
		this.cancelEdit();

		this.groupedFields.$resolved = true;
        this._cd.markForCheck();
	}

	setEditing(editing: boolean): void {
		this.editing = editing;
		this.editingChange.emit(editing);
	}

	cancelEdit(): void {
		this.setEditing(false);
		this.groupedFields = this.getGroupedFieldsCopy(this.groupedFieldsInitial);
		this.groupedFieldsChange.emit(this.groupedFields);
		this.divideFieldIndexes();
		this.formInit();
		this.additionalFieldsForm.markAsPristine();
		this.additionalFieldsForm.markAsUntouched();
	}

	edit(): void {
		this.setEditing(true);
	}

	sortFields(): void {
		const sortKey = this.isNew ? 'displayOrder' : 'customField.displayOrder';
		this.groupedFields.fields = this.fieldsService.collection(
			sortBy(this.groupedFields.fields, sortKey)
		);
		for (const group of this.groupedFields.groups) {
			group.fields = this.fieldsService.collection(
				sortBy(group.fields, sortKey)
			);
		}
	}

	divideFieldIndexes(): void {
		this.groupedFieldsRows = { fields: [], groups: [] };
		const displayColumn = this.isNew ? 'displayColumn' : 'customField.displayColumn';
		let indexes: [number[], number[]] = [[], []];
		forEach(this.groupedFields.fields, (field, index) => {
			const column = get(field, displayColumn);
			indexes[column-1].push(index);
		});

		const [left, right] = indexes;
		for (let i = 0; i < Math.max(left.length, right.length); i++) {
			this.groupedFieldsRows.fields.push([left[i], right[i]]);
		}

		forEach(this.groupedFields.groups, (group) => {
			indexes = [[], []];
			forEach(group.fields, (field, index) => {
				const column = get(field, displayColumn);
				indexes[column-1].push(index);
			});

			const [left, right] = indexes;
			const fieldIndexes: Array<[number, number]> = [];
			for (let i = 0; i < Math.max(left.length, right.length); i++) {
				fieldIndexes.push([left[i], right[i]]);
			}
			this.groupedFieldsRows.groups.push({ name: group.name, fields: fieldIndexes });
		});
	}
}
