import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class BounceEmailService {
	resource: any;

	constructor(public injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/emails`;

		this.resource = $resource(`${basePath}`, {}, {
			unbounce: { method: 'post', url: `${basePath}/unbounced` },
		});
	}

	unbounce = (...args) => this.resource.unbounce(...args);
}
