import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isFunction } from 'lodash';

import { endOfDay } from 'utils/date-utils';

import {
    NumberMessageTemplate,
    NumberConverterFn,
    NumberProviderFn,
    DEFAULT_NUMBER_MESSAGE
} from './validation-types';


export function MinimumNumberValidator<T>(
    converter: NumberConverterFn<T>,
    comparisonValueProvider: NumberProviderFn,
    equalityAllowed: boolean = false,
    messageTemplate: NumberMessageTemplate = ''
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const rawValue: T = control.value;
        const value: number = converter(rawValue);
        const comparison: number = comparisonValueProvider();

        const isValid = (
            !value ||
            !comparison ||
            (value > comparison) ||
            (equalityAllowed && (value === comparison))
        );
        if (isValid) {
            return null;
        }

        const providedMessage: string = (
            isFunction(messageTemplate) ?
            messageTemplate(value) :
            messageTemplate
        );
        const message: string = (providedMessage || DEFAULT_NUMBER_MESSAGE);

        const validationError = {
            num: {
                value,
                message: message,
            },
        };

        return validationError;
    };
}

// Find a better home for this?
export const STRING_TO_DATE_CONVERTER: NumberConverterFn<string> = (
    rawValue: string,
    // Allow date creator fn eventually?
) => {
    if (!!rawValue) {
        const asDate: Date = endOfDay(new Date(rawValue));
        const asNumber: number = asDate.getTime();

        return asNumber;
    }

    return null;
};
