import { filter, includes } from 'lodash';
import { Component, Injector } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { ConfirmModalComponent } from 'commons/components/modals';
import { DefaultNetworks, DefaultNetwork, allowedNetworks, name } from 'commons/components/sm-icons';

import { PostsResourceService } from '../../../../posts/posts-resource.service';
import { TemplatesService } from '../../../templates.service';
import { PostsService } from 'tpo/social-compliance/publisher/posts/posts.service';
import {
	DynamicField,
	wrapDynamicFieldsToHtml,
} from 'tpo/social-compliance/publisher/library/templates/$templateId/template-text-edit/utils';

@Component({
	templateUrl: './template-view.component.html',
})
export class TemplateViewComponent {
    previewAccounts: DefaultNetwork[] = [{ networkCode: allowedNetworks.GOOGLE_MY_BUSINESS, name }];
	params: any = {};
	template: any;
	canManage: boolean = false;
	attachment: any;
	attachmentsErrors = [];
	linkPreview: any = {
		entries: [],
		$resolved: false,
	};
	modalRef: BsModalRef;
	canViewCampaigns: boolean;
	dynamicFields: NgResourceArray<DynamicField> = [];

	campaignStatuses = {
		'Not Started': {
			color: 'success',
		},
		'In Progress': {
			color: 'warning',
		},
		'Ended': {
			color: 'default',
		},
	};

	constructor(
		public injector: Injector,
		public state: StateService,
		public historyLogService: HistoryLogService,
		public user: UserService,
		public postsResource: PostsResourceService,
		public templatesService: TemplatesService,
		public modalService: BsModalService,
		public postsService: PostsService,
	) {
		const { templateId } = state.params;
		Object.assign(this.params, { templateId });
		this.dynamicFields = this.postsService.getDynamicFields();
		this.template = templatesService.template(templateId);

		Promise.all([this.template.$promise, this.dynamicFields.$promise]).then(() => {
			this.trackTextChanges();
			this.template.text = wrapDynamicFieldsToHtml(this.template.text, this.dynamicFields);
			this.selectNetworks([allowedNetworks.GOOGLE_MY_BUSINESS]);
		});

		this.canManage = user.profile.can('TPO_PB_MANAGE_TEMPLATES');
		this.canViewCampaigns = user.profile.can('TPO_PB_VIEW_CAMPAIGNS');

		this.attachment = {
			config: {
				tpoId: user.profile.organization.id,
				template: true,
			},
			error: (error: [{ networkCode: string, error: string }?]): void => {
				this.attachmentsErrors = error;
			},
		};
	}

	// Reusable for template edit
	remove(): void {
		const { template, params, close } = this;
		const initialState = {
			title: 'Remove Template',
			message: 'By removing the template, all unpublished posts will be cancelled. Please confirm.',
			confirmText: 'Remove',
			onConfirm: (): void => {
				this.modalRef.content.resolving = true;
				this.templatesService.removeTemplate(params, template).$promise.then(
					() => {
                        this.modalRef.content.resolving = false;
                        close();
					},
					({ data }) => {
						this.modalRef.content.resolving = false;
						this.modalRef.content.errorText = data.hasOwnProperty('message') && data.message;
					},
				);
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState,
			class: 'social-media, social-media-posts, remove-template-modal modal-smd modal-new',
		});
	}

	// Reusable for all publisher view components
	edit(): void {
		this.state.go('.edit', {}, { location: 'replace' });
	}

	// Reusable for all publisher full-page components
	close = (): void => {
		const backState = this.historyLogService.findBackState([
			'**.new',
			'**.:templateId.**',
		], false);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }

		this.state.go('social-compliance.publisher.library.templates');
	};

	trackTextChanges(): void {
		const request = {
			networkCodes: ['FB', 'TW', 'LI'],
			text: `${this.template.text}`,
		};

		this.linkPreview.resolved = false;

		this.postsResource.post.preview(request, ({ linkPreviews }) => {
			this.linkPreview = {
				entries: linkPreviews,
				$resolved: true,
			};
		});
	}

	selectNetworks(targetNetworkCodes: string[] | undefined): void {
		if (!targetNetworkCodes?.length) {
			this.previewAccounts = DefaultNetworks;
			return;
		}

		this.previewAccounts = filter(DefaultNetworks, ({ networkCode }) => {
			return includes(targetNetworkCodes, networkCode);
		});
	}
}
