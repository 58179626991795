<div class="mortgage-related-pages-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="onCancel();">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="remote-resource" [class.resolved]="!resolving">
		<div class="modal-body max-h450">
			<p>
				{{message}}
			</p>

			<section>
				<div class="rows-bordered">
					<div class="row header">
						<div class="col-md-12 horizontal-flex-group">
							<b class="header-title flex-grow">Account</b>
							<b class="account-select header-title text-center">Mortgage Business</b>
						</div>
					</div>
				</div>
				<div class="rows-bordered">
					<div class="row clickable" *ngFor="let page of list">
						<label class="col-md-12 horizontal-flex-group">
							<sm-icon class="space-bigger" [config]="page"></sm-icon>
							<div class="full-width">
								<span>
									{{page.name}}
								</span><br>
								<span class="text-muted" *ngIf="page.address">
									{{page.address}}
								</span>
							</div>
							<span class="account-select text-center">
								<input [(ngModel)]="page.isMortgageRelated" type="checkbox" />
							</span>
						</label>
					</div>
				</div>
			</section>

		</div>
		<div class="modal-footer" [class.curtain]="resolving">
			<div class="btn-group-lg">
				<button type="button" (click)="onConfirm()" class="btn btn-primary">{{confirmText}}</button>
				<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
			</div>
		</div>
	</div>
</div>
