<div class="audit-notify-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
		<h4 class="modal-title">
			Notify {{contactName}}
			<ng-container *ngIf="nmlsId">NMLS ID: {{nmlsId}}</ng-container>
		</h4>
	</div>

	<form [formGroup]="form" (onValidSubmit)="submit()">
		<div class="modal-body remote-resource" [class.resolved]="resolved">
			<form-errors [form]="form"></form-errors>

			<div class="form-group row" [class.has-error]="toEmailsControl.showError" *ngIf="!isAdding">
				<label class="col-sm-3 control-label required">{{toEmailsControl.label}}</label>
				<div class="col-sm-9">
					<ng-select
						[multiple]="true"
						[clearable]="false"
						[items]="emails.$promise | async"
						[loading]="!emails.$resolved"
						bindValue="email"
						bindLabel="email"
						placeholder="Select Email"
						[formControl]="toEmailsControl"
					>
						<ng-template ng-footer-tmp>
							<div class="ng-dropdown-footer-items">
								<div class="ng-option" (click)="toggleAddEmail()">
									<span class="text-big text-light">+</span>
									Add Email
								</div>
							</div>
						</ng-template>
					</ng-select>
				</div>
			</div>

			<div class="form-group row" [class.has-error]="toEmailsControl.showError" *ngIf="isAdding">
				<label class="col-sm-3 control-label required">{{toEmailsControl.label}}</label>
				<div class="col-sm-9">
					<div class="form-group has-feedback no-margin">
						<input
							type="text"
							class="form-control"
							[formControl]="toEmailsControl"
							maxlength="200"
							placeholder="Enter Email"
							#emailInput
						>
						<a class="form-control-feedback glyphicon glyphicon-remove" (click)="toggleAddEmail()"></a>
					</div>

				</div>
			</div>

			<div class="form-group row" [class.has-error]="ccEmailsControl.showError">
				<label class="col-sm-3 control-label">{{ccEmailsControl.label}}</label>
				<div class="col-sm-9">
					<tag-input
						[formControl]="ccEmailsControl"
						[modelAsStrings]="true"
						[separatorKeyCodes]="[188, 186]"
						placeholder="Enter Email"
						secondaryPlaceholder="Enter Email"
					></tag-input>
				</div>
			</div>

			<div class="alert alert-warning framed">
				Hello {{contactName}},
				<br/>
				{{userName}} from {{userCompany}} has sent this notification to make you aware of open tasks assigned to you.
			</div>

			<div class="form-group" [class.has-error]="messageControl.showError">
				<textarea
					class="form-control resize-vertical"
					rows="3"
					maxlength="2000"
					placeholder="Message Body"
					[formControl]="messageControl"
				></textarea>
			</div>
		</div>

		<div class="modal-footer" [class.curtain]="!resolved">
			<div class="btn-group-lg">
				<button type="submit" class="btn btn-primary">Notify</button>
				<button type="button" class="btn btn-default" (click)="close()">Cancel</button>
			</div>
		</div>
	</form>
</div>
