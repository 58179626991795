import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';

import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';
import { PagedData, RealmHttpClient, RealmPagedParams, RealmParams } from 'commons/services/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare let apiPath: string;

@Injectable()
export class NewContactSocialAccountsService {
    linkUrl = `${apiPath}/social-media/links`;
    constructor(public http: RealmHttpClient, public User: UserService) {
    }

    private getList({ linkListType, tpoId, contactId, ...params }: RealmPagedParams): Observable<PagedData<SocialAccountLink[]>> {
        const { isTpo, isComergence } = this.User.profile;
        return this.http.pagedRequest<SocialAccountLink[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/contacts/${contactId}/social-media-links/${linkListType}`,
            params,
        ).pipe(
            map(list => {
                list.data = list.data.map(data => (new SocialAccountLink({
                    data,
                    isTpo,
                    isComergence,
                })));
                return list;
            }),
        );
    }

    public getSocialAccounts = (params: RealmPagedParams) => this.getList({ linkListType: 'SOCIAL_ACCOUNTS', ...params });

    public addLink({ entityIdType, entityId, ...params }: RealmParams, body): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${this.linkUrl}/${entityIdType}/${entityId}`,
            params,
            body,
        );
    }

    public removeLink({ entityIdType, entityId, mediaLinkId, ...params }: RealmParams): Observable<void> {
        return this.http.request<void>(
            'DELETE',
            `${this.linkUrl}/${entityIdType}/${entityId}/${mediaLinkId}`,
            params,
        );
    }

    public setRemoval(suffix: string, { entityIdType, entityId, mediaLinkId, ...params }: RealmParams): Observable<SocialAccountLink> {
        return this.http.request<SocialAccountLink>(
            'PUT',
            `${this.linkUrl}/${entityIdType}/${entityId}/${mediaLinkId}/${suffix}`,
            params,
        );
    }

    public acceptRemoval(params: RealmParams): Observable<SocialAccountLink> {
        return this.setRemoval('removal-acceptance', params);
    }

    public declineRemoval(params: RealmParams): Observable<SocialAccountLink> {
        return this.setRemoval('removal-denial', params);
    }

    // Removed
    public getRemovedSocialAccounts = (params: RealmPagedParams) => this.getList({linkListType: 'REMOVED', ...params});

    public restoreLink(params: RealmParams): Observable<SocialAccountLink> {
        return this.setRemoval('restore', params);
    }
}
