<header>
	<sm-network-icon
		[config]="{ networkCode: networkCode }"
		[minified]="true"
		[showTitle]="true"
	></sm-network-icon>
</header>

<ng-container [ngSwitch]="networkCode">
	<facebook-post *ngSwitchCase="'FB'" class="post-content"
				[name]="name"
				[profilePictureUrl]="profilePictureUrl"
				[webLink]="webLink"
				[textTruncated]="textTruncated"
				[attachments]="attachments"
				[previewDate]="previewDate"
				[linkPreview]="linkPreview"
				[networkCode]="networkCode"
				[sanitize]="sanitize"
				[resolved]="resolved"
	></facebook-post>

	<instagram-post *ngSwitchCase="'IG'" class="post-content"
				[name]="name"
				[profilePictureUrl]="profilePictureUrl"
				[webLink]="webLink"
				[textTruncated]="textTruncated"
				[attachments]="attachments"
				[previewDate]="previewDate"
				[linkPreview]="linkPreview"
				[networkCode]="networkCode"
				[sanitize]="sanitize"
				[resolved]="resolved"
	></instagram-post>

	<linkedin-post *ngSwitchCase="['LI'].includes(networkCode) ? networkCode : !networkCode" class="post-content"
				[name]="name"
				[profilePictureUrl]="profilePictureUrl"
				[webLink]="webLink"
				[textTruncated]="textTruncated"
				[attachments]="attachments"
				[previewDate]="previewDate"
				[linkPreview]="linkPreview"
				[networkCode]="networkCode"
				[sanitize]="sanitize"
				[resolved]="resolved"
	></linkedin-post>

	<twitter-post *ngSwitchCase="'TW'" class="post-content"
				[name]="name"
				[profilePictureUrl]="profilePictureUrl"
				[webLink]="webLink"
				[textTruncated]="textTruncated"
				[attachments]="attachments"
				[previewDate]="previewDate"
				[linkPreview]="linkPreview"
				[networkCode]="networkCode"
				[sanitize]="sanitize"
				[resolved]="resolved"
	></twitter-post>

	<gmb-post *ngSwitchCase="'GMB'" class="post-content"
			[name]="name"
			[profilePictureUrl]="profilePictureUrl"
			[webLink]="webLink"
			[textTruncated]="textTruncated"
			[attachments]="attachments"
			[previewDate]="previewDate"
			[linkPreview]="linkPreview"
			[networkCode]="networkCode"
			[sanitize]="sanitize"
			[resolved]="resolved"
	></gmb-post>
</ng-container>
