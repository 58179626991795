import { Component, Input, OnInit } from '@angular/core';

import { OwnerFindings } from 'shared/due-diligence/scorecard/scorecard-owners.interface';


export const INVESTOR_OWNER_LINK: string = 'client.:id.reviews.scorecard.owner.:ownerId';
export const CCM_OWNER_LINK: string = 'tpo.:id.scorecard.owner.:ownerId';

@Component({
    selector: '[scorecard-summary-owners-row],scorecard-summary-owners-row',
	templateUrl: './scorecard-summary-owners-row.component.html',
    host: { 'class': 'row-fluid' },
})
export class ScorecardSummaryOwnerRowComponent implements OnInit {
    @Input() tpoId: (number | string);
    @Input() isCCM: boolean;
    @Input() owner: OwnerFindings;

    link: string = '';
    linkParams: any = {};

    // Override
    ngOnInit(): void {
        if (this.isCCM) {
            this.link = CCM_OWNER_LINK;
        } else {
            this.link = INVESTOR_OWNER_LINK;
        }

        this.linkParams = {
            id: this.tpoId,
            ownerId: this.owner.id,
        };
    }
}