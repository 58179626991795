<ng-container *ngIf="config">
	<div [ngClass]="iconContainerClassName">
		<span
			*ngIf="!isSVGIcon"
			[ngClass]="iconClassName"
			[tooltip]="showTooltip && title"
			placement="top"
		></span>

		<icon-svg
			*ngIf="isSVGIcon"
			[name]="networkCode"
			[ngClass]="iconClassName"
			[tooltip]="showTooltip && title"
			size="big"
			placement="top"
		></icon-svg>

		<span
			*ngIf="showBadge"
			[ngClass]="iconBadgeClassName"
		></span>
	</div>

	<div
		*ngIf="showTitle"
		[ngClass]="titleClassName"
	>{{title}}</div>
</ng-container>
