<div class="step" uiSref="people.contacts-import.upload" uiSrefActive="active">
    <div class="flex-row">
        <div class="flex-grow"></div>
        <div class="number">1</div>
        <div class="line"></div>
    </div>
    <h4>Select File</h4>
</div>
<div class="line"></div>
<div class="step" uiSref="people.contacts-import.match" uiSrefActive="active">
    <div class="flex-row">
        <div class="line"></div>
        <div class="number">2</div>
        <div class="flex-grow"></div>
    </div>
    <h4>Map Columns</h4>
</div>
