import { AfterContentInit, Component } from '@angular/core';
import { ConfirmModalComponent } from 'commons/components/modals';

@Component({
    templateUrl: '../../../../../../commons/components/modals/confirm-modal.component.html',
})
export class SelectionPoolResetModalComponent extends ConfirmModalComponent implements AfterContentInit {
    all: boolean;
    doRequest: () => Promise<unknown>;
    onConfirm = async () => {
        this.errorText = null;
        this.resolving = true;
        try {
            await this.doRequest();
            this.modalRef.hide();
        } catch (e) {
            const { message } = e.error;
            this.resolving = false;
            this.errorText = message;
        }
    }

    ngAfterContentInit() {
        this.message = this.all
            ? 'You are about to reset the selection pool for the related group. All individuals in this group will be reset to be included in the pool, including any previously excluded.'
            : 'You are about to reset the selection pool for the related group. All individuals in this group will be reset to be excluded from selection. You will need to add any individuals you wish to be included in future audits.';
        this.title = 'Reset Selection Pool';
        this.confirmText = 'Reset';
    }
}
