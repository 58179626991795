import { NgModule } from '@angular/core';

import { ScorecardModule } from './scorecard/scorecard.module';


@NgModule({
    imports: [
        ScorecardModule,
    ],
})
export class DueDiligenceModule {
}
