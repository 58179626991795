import { Injectable } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';

import { TasksResourceService } from './tasks-resource.service';

@Injectable()
export class TasksService {
	constructor(
		public tasksResource: TasksResourceService,
		public User: UserService,
	) {}

	list = (queryParams?: RemediationsQueryParams): NgResourceArray<Remediation> => this.tasksResource.tasks.get(queryParams);
	getFindingStatuses = (): NgResourceArray<FindingStatus> => this.tasksResource.findingStatus.get();
}

interface RemediationsQueryParams {
	q?: string;
	remediationStatus?: string[];
	findingStatus?: string[];
	dueDateFrom?: number;
	dueDateTo?: number;
	completedDateFrom?: number;
	completedDateTo?: number;
	page?: number;
	size?: number;
	sort?: string[];
}

export interface Remediation {
	dueDate: string;
	findingId: number;
	findingStatus: string;
	isMobileUser: true;
	name: string;
	nmlsId: number;
	remediationStatus: string;
	statusDate: number;
}

interface FindingStatus {
	correspondingCondition: string;
	id: number;
	status: string;
}
