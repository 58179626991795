<div class="remote-resource" [class.resolved]="!resolving">
    <ng-container *ngIf="list | async as list">
        <div *ngIf="list.length > 0" class="rows-bordered">
            <div *ngFor="let document of list" class="row-fluid no-border">
                <tpo-financial-document-item
                    [canUpload]="userProfile.isTpo"
                    [tpoId]="tpoId"
                    [investorId]="investorId"
                    [tpoFinancialDocument]="document"
                    [documentFields]="document.fieldConfig"
                    [isOnGoing]="isOnGoing"
                    (onDocumentUpdated)="updateList()"
                >
                </tpo-financial-document-item>
            </div>

            <ng-container *ngIf="userProfile.isTpo || isOnGoing">
                <br>
                <h3>Financial Summary</h3>

                <tpo-financial-summary [tpoFinancialDocuments]="summaryFields"></tpo-financial-summary>
            </ng-container>
        </div>
    </ng-container>
</div>
