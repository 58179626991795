import { Component, Input } from '@angular/core';

import { CompanyInformationRow } from 'tpo/application/application.service';

@Component({
    templateUrl: './company-information-row.component.html',
    selector: '[company-information-row]',
})
export class AppSummaryCompanyInformationRowComponent {
    @Input() companyInformation: CompanyInformationRow = {} as CompanyInformationRow;
    @Input() channelId: number;
    constructor() {}
}
