import { find } from 'lodash';
import { Component } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';
import { NavigationMenuItem } from 'utils/navigation/navigation.interface';

type NavigationMenuItemWithTabs = NavigationMenuItem & { tabs?: NavigationMenuItem[], permissionKey?: string };

@Component({
	templateUrl: './reviews.component.html',
})
export class ReviewsComponent {
	items: NavigationMenuItemWithTabs[];
	activeItem: NavigationMenuItemWithTabs;
	User: UserProfile

	constructor(
		{ profile }: UserService,
		transitionService: TransitionService,
		public state: StateService,
	) {
		this.User = profile;
		this.items = [
			{
				sref: 'reviews.list',
				title: 'Reviews',
			},
			{
				sref: 'reviews.settings',
				title: 'Settings',
				tabs: [
					{
						sref: 'reviews.settings.accounts',
						title: 'Accounts',
					},
					{
						sref: 'reviews.settings.images',
						title: 'Images',
					},
				],
				permissionKey: 'TPO_PB_MANAGE_REVIEW_SETTINGS',
			},
			{
				sref: 'reviews.widgets',
				title: 'Widgets',
				permissionKey: 'TPO_PB_MANAGE_REVIEW_SETTINGS',
			},
		];
		this.setActiveItem();

		transitionService.onFinish({ retained: 'reviews' }, ({ promise }) => {
			promise.then(this.setActiveItem);
		})
	}

	setActiveItem = (): void => {
		this.activeItem = find(this.items, (item) => this.state.includes(item.sref))
	};
}
