<div class="social-media publisher-requests">
    <h3>Publisher Requests</h3>

	<section class="requests">
		<div class="remote-resource" [class.resolved]="!resolving">
            <ng-container *ngIf="!resolving && ((list | async)?.length > 0); else noRequests">
                <div class="alert alert-info flex-row gap" *ngFor="let request of list | async">
                    <div class="flex flex-row gap">
                        <sm-icon [config]="request"></sm-icon>
                        <div class="flex text-overflow">
                            <div>
                                <a uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: request.requestorTpoIndividualContactId }" target="_blank">
                                    {{ request.requestorName }}
                                </a> requests access to post your social media account
                            </div>
                            <div>
                                <a class="url-holder" target="_blank"  [href]="request.link">{{ request.linkName }}</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a class="btn btn-primary" (click)="confirmAccess(request, true)">Accept</a>
                        <a class="btn btn-default" (click)="confirmAccess(request, false)">Decline</a>
                    </div>
                </div>
            </ng-container>

            <ng-template #noRequests>
                <div class="no-publisher-requests text-muted text-bigger text-center">No records</div>
            </ng-template>
		</div>
	</section>
</div>
