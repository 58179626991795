import { extend, find, map, once, orderBy, pick, values } from 'lodash';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { RecurlyService } from 'commons/recurly';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import {
	GlobalNotificationsService,
	GlobalNotificationType,
} from 'global-elements/notication-center/notifications.service';

import { SmOrderService as SmService } from 'tpo/social-compliance/orders/sm-order.service';

import { TpoNewOrderComponent } from '.';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

@Component({
	templateUrl: './payment.component.html',
	preserveWhitespaces: true,
})
export class TpoPaymentOrderComponent implements AfterViewInit, OnInit {
	helpLink = '/help/authenticated/tpo/smc/orders-board';
	@Input() recurlyConfig;

	payment: any = {};
	autocloseStatuses: any = [];
	selectedContacts: any = [];
	shouldPay: boolean = false;
    ccRequired: boolean = false;
	isPaying: boolean = false;
	isPaid: boolean;

	paymentForm: RealmFormGroup = new RealmFormGroup({
		payingToken: new RealmFormControl(
			'payingToken',
			{
				label: 'Token',
			},
		),
		card: new RealmFormControl(
			'card',
			{
				label: 'Card',
				updateOn: 'change',
			},
		),
		first_name: new RealmFormControl(
			'first_name',
			{
				label: 'First Name',
			},
		),
		last_name: new RealmFormControl(
			'last_name',
			{
				label: 'Last Name',
			},
		),
		autocloseData: new RealmFormGroup({
			autoClose: new RealmFormControl(
				'autoClose',
				{
					label: 'Auto Close Reviews with no Findings',
					value: true,
					updateOn: 'change',
				},
			),
			autoCloseStatusId: new RealmFormControl(
				'autoCloseStatusId',
				{
					label: 'Change Audit Status to',
				},
				Validators.required,
			),
		}),
		costCenter: new RealmFormControl(
			'costCenter',
			{
				label: 'Cost Center',
			},
		),
		poNumber: new RealmFormControl(
			'poNumber',
			{
				label: 'PO Number',
			},
		),
		agree: new RealmFormControl(
			'agree',
			{
				updateOn: 'change',
			},
		),
	});

	initRecurly: () => void;

	constructor(
		public parent: TpoNewOrderComponent,
		public stateService: StateService,
		public $state: $stateProvider,
		public SmOrderService: SmService,
		public recurly: RecurlyService,
		private notificationService: GlobalNotificationsService,
        private cd: ChangeDetectorRef,
	) {
		this.autocloseStatuses = this.SmOrderService.getAutoclose((statuses) => {
			const completedEntry = find(statuses, {name: 'Completed'}) as any;
			this.paymentForm.get('autocloseData.autoCloseStatusId').setValue(completedEntry && completedEntry.id ? completedEntry.id : statuses[0] && statuses[0].id);
		});
		this.paymentForm.get('autocloseData.autoClose').valueChanges.subscribe((value) => {
			this.setRequired('autocloseData.autoCloseStatusId', value);
			this.setDisabled('autocloseData.autoCloseStatusId', !value);
		});
	}

	setRequired(name, value) {
		const field = this.paymentForm.get(name);
		if (value) {
			field.setValidators(Validators.required);
		} else {
			field.clearValidators();
			field.updateValueAndValidity();
		}
	}

	setDisabled(name, value) {
		this.paymentForm.get(name)[value ? 'disable' : 'enable']();
	}

	calculatePrice() {
		this.selectedContacts = orderBy(values(this.parent.selectedContacts), ['fullName'], ['asc']);
		const ids = this.getContactIds();

		if (!this.parent.selectedAmount) {
			this.stateService.go('^');
		}

		this.payment.$resolved = false;
		this.SmOrderService.getPrice({}, ids,
			/*success*/(data) => {
                this.payment = data;
				this.shouldPay = !this.payment.invoicing && !!this.payment.price;
                this.ccRequired = this.shouldPay && !this.parent.User.profile.hasFeature(PACKAGE_FEATURE.COMPLIANCE);
				this.initRecurly();
				this.setRequired('first_name', this.ccRequired);
				this.setRequired('last_name', this.ccRequired);
				this.setDisabled('autocloseData.autoCloseStatusId', !this.paymentForm.get('autocloseData.autoClose').value);
			},
			/*error*/({ data }) => {
				this.payment.$resolved = true;
				this.paymentForm.setServerError(data);
			},
		).$promise.finally(() => this.cd.detectChanges());
        this.cd.detectChanges();
	}

	getContactIds() {
        return { orderContacts: this.selectedContacts.map(({ contactId }) => ({ contactId })) };
	}

	removeContact(contact) {
		this.parent.removeContact(contact);
		this.calculatePrice();
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.initRecurly = once(() => {
			setTimeout(() => {
				if (this.ccRequired && this.parent.selectedAmount) {
					this.recurly.configure(this.recurlyConfig);
				}
			});
		});
		this.calculatePrice();
	}

	getRequestParams() {
		const value = this.paymentForm.value;
		const params = {...value.autocloseData, ...this.getContactIds()};

		if (this.ccRequired) {
			extend(params, pick(value, ['payingToken', 'costCenter', 'poNumber']));
		}

		return params;
	}

	cancelOrder(): void {
		this.parent.cancelOrder();
	}

	pay(): void {
		this.isPaying = true;
		this.SmOrderService.save(this.getRequestParams(), (data) => {
			this.isPaying = false;
			this.isPaid = true;
			this.notificationService.add({
				type: GlobalNotificationType.POSITIVE,
				message: `Order was created successfully. <a href="#/social-compliance/orders/${data.id}" class="text-success underlined">View Order</a>`,
				timeout: 10000,
			});
			this.stateService.go('social-compliance.orders');
		}, ({ data: response }) => {
			if(response.errorCode !== 'Recurly') {
				this.notificationService.add({
					type: GlobalNotificationType.ERROR,
					message: `Something went wrong`,
					timeout: 10000,
				});
			}
			this.isPaying = false;
			this.paymentForm.setServerError(response);
		});
	}
}
