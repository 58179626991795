import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { CustomFieldsModule } from 'shared/fields';
import { AccountInformationComponent } from 'shared/account/company-information/details/account-information.component';
import { AccountInformationResourceService } from 'shared/account/company-information/account-information-resource.service';
import { AccountInformationService } from 'shared/account/company-information/account-information.service';
import { ClientUidComponent } from 'shared/account/company-information/client-uid/client-uid.component';
import { AccountHistoryListComponent } from 'shared/account/company-information/history/history.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		ReactiveFormsModule,
		CustomFieldsModule,
	],
	providers: [
		AccountInformationResourceService,
		AccountInformationService,
	],
	declarations: [
		AccountInformationComponent,
        ClientUidComponent,
		AccountHistoryListComponent,
	],
})
export class AccountInformationModule {}
