import { Injectable } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { InvitesResourceService } from 'lender/invites/invites.service';

import { ConfirmModalComponent } from 'commons/components/modals';
import { ErrorModalService } from 'commons/services/error/error-modal.service';
import { HttpErrorResponse } from '@angular/common/http';


export type CancelInviteConfig = {
    investorId: number;
    channelId: number;
    channelName: string;
    tpoId: number;
    tpoName: string;

    handleHttpErrorResponse?: boolean;
    navigateBack?: boolean;

    resolving?: (resolving: boolean) => any;
    success?: () => any;
    finally?: () => any;
};

@Injectable({
	providedIn: 'root',
})
export class CancelInviteModalService {
    constructor(
        private readonly errorModalService: ErrorModalService,
		private readonly invitesResourceService: InvitesResourceService,
        private readonly modalService: BsModalService,
    ) {
    }

	showCancelInviteModal(config: CancelInviteConfig): BsModalRef {
        let modalRef: BsModalRef;
        const initialState = {
            title: 'Cancel Invite',
            message: `Are you sure you want to cancel an invite to <b>${config.channelName}</b> channel sent to <b>${config.tpoName}</b>?`,
            onConfirm: () => this.cancelInvite(config, modalRef),
        };
        modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
			class: 'modal-smd modal-new',
		});

        return modalRef;
	}

    private async cancelInvite(
        config: CancelInviteConfig,
        modalRef: BsModalRef
    ): Promise<void> {
        try {
            modalRef.content.resolving = true;

            if (!!config.resolving) {
                config.resolving(true);
            }

            await this.invitesResourceService.cancelInvite(
                config.investorId,
                config.channelId,
                config.tpoId,
            ).toPromise();

            if (!!config.success) {
                config.success();
            }
        } catch (_e: any) {
            const e: HttpErrorResponse = _e;

            if (!!config.handleHttpErrorResponse) {
                this.errorModalService.handleHttpError(e.status, !!config.navigateBack);
            } else {
                throw e;
            }
        } finally {
            if (!!config.resolving) {
                config.resolving(false);
            }

            if (!!config.finally) {
                config.finally();
            }

            modalRef.hide();
            modalRef.content.resolving = false;
        }
    }
}
