<div class="questionnaires-list">
	<div class="gray-head">
		<h2>Questionnaires</h2>
	</div>

	<div class="filters-rows remote-resource" [class.resolved]="!downloading">
		<div class="row-fluid filter-labels">
			<div class="col-sm-2">
				<div class="text-muted" *ngIf="!isExportMode">Search</div>
			</div>
			<ng-container *ngIf="!isExportMode">
				<div class="col-sm-2" *ngIf="!defaultFilters.tpoId">
					<div class="text-muted">Status</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Assigned After</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Assigned Before</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Questionnaires</div>
				</div>
			</ng-container>
			<div class="col-sm-2">&nbsp;</div>
		</div>

		<div class="row-fluid filter-selectors">
			<div class="col-sm-2" *ngIf="!isExportMode">
				<search-list placeholder="Name, NMLS ID"></search-list>
			</div>
			<div class="col-sm-2" *ngIf="!defaultFilters.tpoId && !isExportMode"
				 [ngClass]="{ resolved: filters?.status?.$resolved }">
				<ng-select
					[ngModel]="params.status"
					(change)="setFilter({ status: $event })"
					[items]="filters?.status | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Statuses">
				</ng-select>
			</div>
			<div class="form-group col-sm-{{isExportMode ? 1 : 2 }}" [class.hide]="isExportMode">
				<input type="text" class="form-control"
					   placeholder="mm/dd/yyyy"
					   [bsValue]="params?.assignedAfter"
					   [maxDate]="params?.assignedBefore"
					   (bsValueChange)="setFilter({assignedAfter: $event})"
					   bsDatepicker>
			</div>
			<div class="form-group col-sm-{{isExportMode ? 1 : 2 }}" [class.hide]="isExportMode">
				<input type="text" class="form-control"
					   placeholder="mm/dd/yyyy"
					   [bsValue]="params?.assignedBefore"
					   [minDate]="params?.assignedAfter"
					   (bsValueChange)="setFilter({assignedBefore: $event})"
					   bsDatepicker>
			</div>
			<div class="col-sm-2 remote-resource"
				[class.resolved]="filters?.questionnaire?.$resolved"
				[class.curtain]="!filters?.questionnaire"
				*ngIf="!isExportMode"
			>
				<ng-select
					[ngModel]="params.questionnaire"
					(change)="setFilter({ questionnaire: $event })"
					[items]="filters?.questionnaire?.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="false"
					[clearable]="true"
					placeholder="All Questionnaire">
				</ng-select>
			</div>
			<div class="col-sm-{{isExportMode ? 10 : 2 }}">
				<a class="hide" href="#" #downloadFile target="_blank"></a>
				<div class="horizontal-flex-group right">
					<span class="btn btn-tertiary btn-icon toggle-export-btn" *ngIf="!isExportMode" (click)="toggleExportMode(true)">
						<span class="c-icon c-icon-download-alt"></span>
					</span>
					<ng-container *ngIf="isExportMode">
						<div class="selected-questionnaires">
							Selected Questionnaires: {{ selectedAmount }}
						</div>
						<a
						   class="btn btn-primary"
						   [class.disabled]="!selectedAmount"
						   (click)="exportQuestionnaires()"
						>
							Export
						</a>
						<a class="btn btn-default" (click)="toggleExportMode(false)">Cancel</a>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
		<div class="row-fluid header">
			<div class="btn-group col-md-check multiple-check-fix" dropdown *ngIf="isExportMode && list?.$resolved">
				<div dropdownToggle
					 type="button"
					 class="dropdown-toggle text-center sortable"
					 aria-controls="dropdown-basic">
					<input class="va-m" type="checkbox" [(ngModel)]="isSelectedAll" (click)="false">
					<span class="caret"></span>
				</div>
				<ul *dropdownMenu class="dropdown-menu"
					role="menu" aria-labelledby="button-basic">
					<li role="menuitem"><a (click)="getAllQuestionnaires()" class="dropdown-item">Select All</a></li>
					<li role="menuitem"><a (click)="addQuestionnairesArray(this.list)" class="dropdown-item">Select All Visible</a></li>
					<li role="menuitem"><a (click)="resetExport()" class="dropdown-item">Clear</a></li>
				</ul>
			</div>
			<sortable-header class="col-md-4" columnName="companyName" [active]="!isExportMode">Client</sortable-header>
			<sortable-header class="col-md-5" columnName="questionnaireName" [active]="!isExportMode">Questionnaire</sortable-header>
			<sortable-header class="col-md-a" columnName="assignedDate" [active]="!isExportMode">Assigned Date</sortable-header>
			<sortable-header class="col-md-a" columnName="status" [active]="!isExportMode">Status</sortable-header>
		</div>
		<div class="row-fluid yellow-bg no-border" [class.curtain]="isExportMode" *ngIf="filtersApplied">
			<div class="col-md-12 va-m text-center">
				Filters are applied. <a (click)="!isExportMode && resetFilters()">Reset filters</a> to see all results.
			</div>
		</div>
		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-md-check va-m text-center multiple-check-fix" *ngIf="isExportMode && list?.$resolved">
				<input
					type="checkbox"
				   	[(ngModel)]="selectedQuestionnaires[item.tpoQuestionnaireId]"
				   	(change)="toggleQuestionnaire($event.target, item)"
				/>
			</div>
			<div class="col-md-4 va-m">
				<div>
					<a target="_blank" uiSref="client.:id.account.information" [uiParams]="{ id: item.tpoId }" [textContent]="item.companyName"></a>
				</div>
				<div>
					<span class="text-muted" [textContent]="'NMLS ID: ' + item.nmlsId"></span>
				</div>
			</div>
			<div class="col-md-5 va-m">
				<a target="_blank" href="/tpos/questionnaires/{{item.tpoQuestionnaireId}}/print" [textContent]="item.questionnaireName"></a>
			</div>
			<div class="col-md-a va-m" [textContent]="item.assignedDate | date : 'MM/dd/yyyy'"></div>
			<div class="col-md-a va-m">
				<span class="label" [ngClass]="questionnairesStatuses[item.status].color" [textContent]="questionnairesStatuses[item.status].name"></span>
			</div>
		</div>
		<div class="row-fluid" *ngIf="!list.length && filters.q">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>
		<div class="row-fluid" *ngIf="!list.length && !filters.q">
			<span class="col-md-12 text-muted text-center text-bigger">No Questionnaires</span>
		</div>
		<br>
		<div>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</div>
