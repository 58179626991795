import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { merge } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { InternalDocumentsService } from '../../../internal-documents.service';
import { NgResourceObject } from 'commons/interfaces';

interface InternalDocument {
	internalDocumentId?: number;
	name: string;
	description: string;
	file?: File;
	fileName?: string;

	$resolved?: boolean;
	$error?: string;
}

interface InternalDocumentResource extends InternalDocument {
	$get: any;
	$update: any;
}

@Component({
	templateUrl: './details.component.html',
})
export class InternalDocumentDetailsComponent {
	User: any;
	params: any;
	isNew: boolean;
	title: string;
	document: NgResourceObject<InternalDocument>;
	documentDetailsForm = new RealmFormGroup({
		name: new RealmFormControl(
			'name',
			{ label: 'Document Name' },
			Validators.compose([
				Validators.required,
				Validators.maxLength(200),
			]),
		),
		description: new RealmFormControl(
			'description',
			{ label: 'Description' },
			Validators.compose([
				Validators.required,
				Validators.maxLength(1000),
			]),
		),
	});

	// refs
	modalRef: BsModalRef;

	constructor(
		public stateService: StateService,
		userService: UserService,
		public modalService: BsModalService,
		public internalDocumentsService: InternalDocumentsService,
	) {
		this.User = userService.profile;

		const {
			id: tpoId,
			documentId,
		} = stateService.params;

		this.params = {
			tpoId,
		};

		this.isNew = !documentId;

		if (this.isNew) {
			this.title = 'Add Internal Document';

			this.document = { $resolved: true } as NgResourceObject<InternalDocument>;
		} else {
            this.title = 'Edit Document';

            this.params.documentId = documentId;
            this.document = this.internalDocumentsService.internalDocument.get(this.params) as NgResourceObject<InternalDocument>;
            this.document.$promise.then((document: InternalDocument): void => {
                this.documentDetailsForm.patchValue(document);
            });
		}
	}

	submitDocumentDetailsForm(): void {
		const {
			document,
			documentDetailsForm: form,
		} = this;

		merge(
			document,
			form.getRawValue(),
		);

		const {
			name,
		} = document;

		if (this.isNew) {
			const {
				description,
				file,
			} = document;

			if (!file) {
				form.setServerError({
					message: 'Please upload a file',
				});

				return;
			}

			document.$resolved = false;
			this.internalDocumentsService.internalDocument.create(
				this.params,
				{
					file,
					properties: {
						name,
						description,
					},
				},
			).$promise
				.then(({ internalDocumentId: documentId }) => {
					const href = this.stateService.href(
						'client.:id.documents.internal-documents.:documentId',
						{
							...this.params,
							documentId,
						},
					);
					const link = `<a class="underlined text-success" href="${href}">View Document</a>`;

					this.stateService.go('^', {
						notification: {
							type: 'alert-success',
							message: `<b>${name}</b> was created successfully. ${link}`,
						},
					});
				})
				.catch(({ data: response }) => {
					form.setServerError(response);
				})
				.finally(() => {
					document.$resolved = true;
				});
		} else {
            document.$resolved = false;
            this.internalDocumentsService.internalDocument.update(this.params, this.document).$promise
				.then(() => {
                    document.$resolved = true;
					this.stateService.go('^', {
						notification: {
							type: 'alert-success',
							message: `<b>${name}</b> was updated successfully.`,
						},
					});
				})
				.catch(({ data: response }) => {
                    document.$resolved = true;
					form.setServerError(response);
				});
		}
	}

	// TODO: share document upload logic
	setDocument(files: FileList): void {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		if (this.isNew) {
			Object.assign(document, {
				file,
				fileName: file.name,
			});
		}
	}

	removeDocument(): void {
		const { document } = this;

		if (this.isNew) {
			Object.assign(document, {
				file: null,
				fileName: null,
			});
		}
	}

	discardError(): void {
		delete this.document.$error;
	}
}
