import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class CalendarResourceService {
	resource: any;

	constructor(public injector: Injector, public user: UserService) {
		const $resource = injector.get('$injector').get('$resource');

		const basePath = `${apiPath}/tpos/:tpoId/social-media-calendar`;
		const defaultParams = user.profile && user.profile.isTpo ? {tpoId: user.profile.organization.id} : {};

		this.resource = $resource(`${basePath}`, defaultParams, {
			list: {method: 'get', isArray: true},
			campaigns: {url: `${basePath}/campaigns`, method: 'get', isArray: true},
		});
	}

	list = (...args) => this.resource.list(...args);
	campaigns = (...args) => this.resource.campaigns(...args);
}
