<div>
	<h2>
		Order Details
		<smHelpLink [url]="helpLink" *ngIf="User.isTpo"></smHelpLink>
	</h2>

	<section>
		<div class="remote-resource" [class.resolved]="order.$resolved">
			<div class="alert alert-danger text-center" role="alert" *ngIf="order.isLastScheduledOrderFailed && User.organization.type == 'TPO' && order.status == 'PF'">
				This order cannot be processed. Please update your billing information.
			</div>
			<div class="rows-striped">
				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Customer</label>
							<div class="col-sm-8">
								<span class="control-value" [ngSwitch]="User.isComergence">
									<a target="_blank" uiSref="tpo.:id.account.information" [uiParams]="{id: order.tpoId}" *ngSwitchCase="true">{{order.customerName}}</a>
									<a target="_blank" uiSref="account.information" *ngSwitchDefault>{{order.customerName}}</a>
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Status</label>
							<div class="col-sm-8">
								<span class="control-value">
									<ng-container *ngIf="statusesConfig[order.status]; let orderStatus">
										<span class="label" [ngClass]="'label-' + orderStatus.color">{{orderStatus.title}}</span>
									</ng-container>
									<span class="text-muted">{{(order.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}</span>&nbsp;&nbsp;
									<a *ngIf="User.isComergence && order.status != 'PF' && order.status != 'C'" (click)="editStatus()">Edit</a>
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>
				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">NMLS ID</label>
							<div class="col-sm-8">
								<span class="control-value">{{order.customerNmlsId}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Order ID</label>
							<div class="col-sm-8">
								<span class="control-value">{{order.id}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>
				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Address</label>
							<div class="col-sm-8">
								<span class="control-value">{{order.customerAddress}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Order Date</label>
							<div class="col-sm-8">
								<span class="control-value">{{order.orderDate | date : 'MM/dd/yyyy'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>
				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Order Type</label>
							<div class="col-sm-8">
								<span class="control-value">{{order.orderType}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-4 control-label">Ordered By</label>
							<div class="col-sm-8">
								<span class="control-value">{{order.orderedBy}}, {{order.initialDate | date : 'MM/dd/yyyy HH:mm'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>
			</div>
		</div>
	</section>

	<section>
		<h3>Audits</h3>
		<div class="remote-resource" [class.resolved]="list.$resolved">
			<div class="filters-rows">
				<div class="row filter-labels">
					<div class="col-sm-3">
						<div class="text-light">Search</div>
					</div>
				</div>
				<div class="row filter-selectors">
					<div class="col-sm-3">
						<search-list placeholder="Name, NMLS ID"></search-list>
					</div>
				</div>
			</div>

			<div class="rows-bordered">
				<div class="row-fluid header">
					<sortable-header class="col-sm-3" columnName="contactName">Name</sortable-header>
					<sortable-header class="col-sm-a" columnName="assigned">Assigned</sortable-header>
					<sortable-header class="col-sm-a" columnName="reviewStatus">Review Status</sortable-header>
					<sortable-header class="col-sm-a" columnName="reviewStatusDate">Status Date</sortable-header>
					<sortable-header class="col-sm-a" columnName="auditStatus">Audit Status</sortable-header>
					<sortable-header class="col-sm-a" columnName="auditStatusDate">Audit Date</sortable-header>
					<div *ngIf="User.isComergence" class="col-sm-1"></div>
				</div>
				<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
					<div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
				</div>
				<a
					*ngFor="let item of list"
					class="row-fluid clickable"
					uiSref=".reviews.:reviewId.information"
					[uiParams]="{ reviewId: item.id }"
				>
					<div class="col-sm-3 va-m">
						<strong>{{item.contactName}}</strong>
						<div class="text-light text-smaller" *ngIf="item.nmlsId">NMLS ID: {{item.nmlsId}}</div>
					</div>
					<div class="col-sm-a va-m">{{item.assigned || '&ndash;'}}</div>
					<div class="col-sm-a va-m text-bigger">
						<ng-container *ngIf="statusesConfig[item.reviewStatus]; let reviewStatus">
							<span class="label" [ngClass]="'label-' + reviewStatus.color">{{reviewStatus.title}}</span>
						</ng-container>
					</div>
					<div class="col-sm-a va-m">{{(item.reviewStatusDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
					<div class="col-sm-a va-m">{{item.auditStatus || '&ndash;'}}</div>
					<div class="col-sm-a va-m">{{(item.auditStatusDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
					<div *ngIf="User.isComergence" class="col-sm-1 va-m">
						<i *ngIf="item.auditReady" class="dot dot-success block"></i>
					</div>
				</a>
				<div class="row-fluid" *ngIf="list.$resolved && !list.length">
					<div class="col-sm-12 text-center text-light text-bigger">
						{{(filtersApplied || filters.q) ? 'No matches found' : 'No audits'}}
					</div>
				</div>
				<br>
				<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
			</div>
		</div>
	</section>
</div>
