import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'sm-networks-connection',
	templateUrl: './sm-networks-connection.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaNetworksConnectionComponent {
	@Input() networks = [
		{ networkCode: 'GMB' },
		{ networkCode: 'FB' },
		{ networkCode: 'IG' },
		{ networkCode: 'LI' },
		{ networkCode: 'TW' },
		{ networkCode: 'YT' },
		{ networkCode: 'RSS*', responseNetworkCode: 'RSS' },
	];

	@Input() networksConnection: any;

	getNetworkStatus = ({ networkCode, responseNetworkCode }): boolean => this.networksConnection[responseNetworkCode || networkCode];
}
