import { filter, get, assign } from 'lodash';

export const filterAttachmentsTransform = (resource) => {
	const data = { ...resource };
	const attachments = get(data, 'attachments');

	if (attachments) {
		assign(data, {
			attachments: filter(attachments, 'id'),
		});
	}

	return JSON.stringify(data);
};
