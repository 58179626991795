import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewContactsModule } from 'shared/new-contacts';
import { CustomFieldsModule } from 'shared/fields';

import { CcmCustomersComponent } from './ccm-customers/ccm-customers.component';
import { CcmCustomersMenuComponent } from './ccm-customers-menu/ccm-customers-menu.component';
import { CcmCustomerInformationComponent } from './customer-information/ccm-customer-information.component';
import { NewCcmCustomerInformationComponent } from './customer-information/new-customer/new-ccm-customer-information.component';
import { CcmCustomerDeactivateModalComponent } from './customer-information/ccm-customer-deactivate-modal/ccm-customer-deactivate-modal.component';
import { CcmServiceStatusService } from './ccm-service-status.service';

@NgModule({
    imports: [
        CommonModule,
		FormsModule,
		ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        NewContactsModule,
        CustomFieldsModule,
    ],
    declarations: [
        CcmCustomersComponent,
        CcmCustomersMenuComponent,
        CcmCustomerInformationComponent,
        NewCcmCustomerInformationComponent,
        CcmCustomerDeactivateModalComponent,
    ],
    providers: [
        CcmServiceStatusService,
    ],
})
export class CcmCustomersModule {}
