<div class="configure-financial-documents-modal" *ngxInit="requestsData$ | async as data">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<p [innerHTML]="guidanceText"></p>

		<div class="financial-settings-grid" *ngIf="data">
			<div
				*ngFor="let requestType of data.requests"
			>
				<div class="flex-row space-between toggle-request" [class.text-muted]="!requestType.enabled">
					<span class="text-bigger">{{financialRequestType[requestType.documentType]}}</span>
					<toggle
						[value]="requestType.enabled"
						(toggle)="toggleRequestType(requestType); disabledSave = notValid(data.requests)"
					></toggle>
				</div>

				<div class="text-muted" [class.curtain]="!requestType.enabled">
					Request Previous
					<ng-select #select
						[(ngModel)]="requestType.settings"
					    (ngModelChange)="disabledSave = notValid(data.requests)"
						[items]="data.values"
						[multiple]="true"
						[closeOnSelect]="false"
						bindLabel="title"
						[placeholder]="select.focused ? 'Select Period' : '—'"
					></ng-select>
				</div>
			</div>
		</div>

	</div>
	<div class="modal-footer text-right" [class.curtain]="resolving">
		<button type="submit" class="btn btn-primary" (click)="onConfirm(data.requests)" [disabled]="disabledSave">
			{{confirmText}}
		</button>
		<button type="button" class="btn btn-secondary" (click)="onCancel()">
			{{cancelText}}
		</button>
	</div>
</div>