import { Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

import { MarketService } from '../market.service';
import { GlobalMarketParams, MarketLocation, MarketLocationParams } from '../market.interface';
import { MarketDataService } from '../market-data.service';


@Component({
	selector: 'market-search',
	templateUrl: 'market-search.component.html',
	host: { class: 'market-search' },

})
export class MarketSearchComponent {
	loading: boolean;
	locationsHints: MarketLocation[] = [];
	locationsInput = new Subject<string>();
	locationsInputValue: MarketLocationParams;
	protected destroyed$: Subject<void> = new Subject();

	constructor(
		{ success$, params }: UIRouterGlobals,
		private readonly marketService: MarketService,
		private readonly dataService: MarketDataService,
	) {
		success$.pipe(
			takeUntil(this.destroyed$),
		).subscribe(() => {
			const globalLocationParams = this.dataService.getLocationParams(params as GlobalMarketParams);
			const locationParams = this.dataService.isEmptyParams(globalLocationParams) ? null : globalLocationParams;

			this.locationsInputValue = locationParams;
			this.dataService.setLocation(locationParams, true);
		});

		this.subscribeLocations();
	}

	onSelect(location: MarketLocation): void {
		this.dataService.setLocation(location);
		this.locationsHints = [];
	}

	subscribeLocations(): void {
		let search = false;
		this.locationsInput
			.pipe(takeUntil(this.destroyed$))
			.subscribe((term) => {
				this.locationsHints = [];
				search = term && term.length > 1;
				this.loading = search;
			});

		this.locationsInput
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				switchMap(async (term) => {
					if (search) {
						try {
							return await this.marketService.getMarketplaceLocations(term).toPromise();
						} catch (err) {
							// error
						}
					}
					return [];
				}),
				takeUntil(this.destroyed$),
			)
			.subscribe((locations: MarketLocation[]) => {
				this.locationsHints = locations;
				this.loading = false;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}