import { Component, Input } from '@angular/core';

import { OtherTradeName } from '../interfaces/other-trade-name.interface';


@Component({
    selector: 'nmls-individual-other-names',
    templateUrl: './nmls-individual-other-names.component.html',
})
export class NmlsIndividualOtherNamesComponent {
    @Input() otherNamesResolving: boolean;
    @Input() otherNames: OtherTradeName[];
}
