import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class ContactsResourceService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		// console.log('create ContactsResourceService');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const basePath = `${apiPath}/tpos/:tpoId/contacts/:id`;
		const defaultParams = User.profile.organization.type === 'TPO' ? {tpoId: User.profile.organization.id} : {};
		this.resource = PagedResource(`${basePath}`, {...defaultParams}, {
			query: {method: 'get', isArray: true},
			history: {method: 'get', isArray: true, url: `${apiPath}/contacts/:contactId/history`},
			remove: {method: 'delete', url: `${basePath}/status`, params: {id: '@id'}},
		});
	}

	query = (...args) => this.resource.query(...args);
	history = (...args) => this.resource.history(...args);
	remove = (...args) => this.resource.remove(...args);
}
