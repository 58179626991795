<div class="remote-resource assign-questionnaire-form" [class.resolved]="resolved && !saving">
	<div class="modal-header">
		<button type="button" *ngIf="!saving" class="close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{ title }}</h4>
	</div>
	<form [formGroup]="assignForm" id="assignForm" (onValidSubmit)="onSave()" autocomplete="off" role="presentation">
		<div class="modal-body" [class.curtain]="saving">
			<form-errors [form]="assignForm"></form-errors>

			<div *ngIf="!isQuestionnaireConfirmed" class="form-group" has-error>
				<label for="questionnaireId" class="control-label">
					{{ assignForm?.getFieldLabel('questionnaireId') }}
				</label>
				<div *ngIf="questionnaires?.length">
					<ng-select
						id="questionnaireId"
						*ngIf="questionnaires.$resolved"
						formControlName="questionnaireId"
						[items]="questionnaires"
						bindValue="id"
						bindLabel="name"
						[clearable]="false"
						[searchable]="true"
						placeholder="Select Questionnaire"
						tabindex="1"
					></ng-select>
				</div>
				<div *ngIf="questionnaires?.$resolved && !questionnaires?.length">
					No Questionnaires to select
				</div>
				<p *ngIf="assignForm?.value?.questionnaireId && description?.length" class="description">
					{{ description }}
				</p>
			</div>

			<div *ngIf="isQuestionnaireConfirmed">

				<div class="form-group">
					<label class="checkbox-inline">
						<input
							type="checkbox"
							formControlName="_notify_upon_assignment"
							tabindex="2"
						>
						{{ assignForm?.getFieldLabel('_notify_upon_assignment') }}
					</label>
				</div>

				<div *ngIf="assignForm.get('_notify_upon_assignment').value">
					<div class="form-group" has-error>
						<label for="toContactIds" class="control-label required">
							{{ assignForm?.getFieldLabel('toContactIds') }}
						</label>
						<div>
							<ng-select
								id="toContactIds"
								formControlName="toContactIds"
								[items]="contacts"
								bindValue="contactId"
								bindLabel="fullName"
								[searchable]="true"
								[multiple]="true"
								placeholder="Select Users"
								[tabindex]="5"
							></ng-select>
						</div>
					</div>

					<div class="form-group" has-error>
						<label for="ccRealmUserIds" class="control-label">
							{{ assignForm?.getFieldLabel('ccRealmUserIds') }}
						</label>
						<div>
							<ng-select
								id="ccRealmUserIds"
								[items]="cc"
								bindValue="realmUserId"
								bindLabel="fullName"
								[searchable]="true"
								[multiple]="true"
								formControlName="ccRealmUserIds"
								placeholder="Select Users"
								[tabindex]="7"
							></ng-select>
						</div>
					</div>

					<div class="form-group" has-error>
						<label for="message" class="control-label required">
							{{ assignForm?.getFieldLabel('message') }}
						</label>
						<div>
							<textarea
								id="message"
								class="form-control resize-vertical"
								formControlName="message"
								rows="4"
								maxlength="messageCharsAmount"
								[tabindex]="8"
								placeholder="Enter message"
							></textarea>
						</div>
						<div class="characters text-muted text-smaller">Symbols: {{getCharsCount()}}</div>
					</div>
				</div>

				<div class="form-group">
					<label class="checkbox-inline">
						<input
							type="checkbox"
							formControlName="_notify_upon_completion"
							tabindex="3"
						>
						{{ assignForm?.getFieldLabel('_notify_upon_completion') }}
					</label>
				</div>

				<div *ngIf="assignForm.get('_notify_upon_completion').value">
					<div class="form-group" has-error>
						<label for="notifyUponCompletionRealmUserIds" class="control-label required">
							{{ assignForm?.getFieldLabel('notifyUponCompletionRealmUserIds') }}
						</label>
						<div>
							<ng-select
								id="notifyUponCompletionRealmUserIds"
								[items]="cc"
								bindValue="realmUserId"
								bindLabel="fullName"
								[searchable]="true"
								[multiple]="true"
								formControlName="notifyUponCompletionRealmUserIds"
								placeholder="Select Users"
								[tabindex]="7"
							></ng-select>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label class="checkbox-inline">
						<input
							type="checkbox"
							formControlName="_is_scheduled"
							tabindex="4"
						>
						{{ assignForm?.getFieldLabel('_is_scheduled') }}
					</label>
				</div>

				<div *ngIf="assignForm.get('_is_scheduled').value">
					<div class="form-group" has-error>
						<label for="scheduledDate" class="control-label required">
							{{ assignForm?.getFieldLabel('scheduledDate') }}
						</label>
						<div class="schedule-selector-holder">
							<input type="text"
								id="scheduledDate"
								autocomplete="off"
								formControlName="scheduledDate"
								class="form-control"
								bsDatepicker
								[bsConfig]="bsConfig"
								placeholder="Select Date"
								[minDate]="tomorrow"
							>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="modal-footer" [class.curtain]="saving">
			<div class="text-right">
				<input
					*ngIf="isQuestionnaireConfirmed && questionnaires?.$resolved && questionnaires?.length"
					type="submit"
					class="btn btn-primary"
					[value]="assignForm.get('_is_scheduled').value ? 'Schedule' : 'Assign'"
					tabindex="5"
				>
				<button
					*ngIf="!isQuestionnaireConfirmed"
					[disabled]="!assignForm.get('questionnaireId')?.value"
					(click)="toDetailsStep()"
					class="btn btn-primary"
				>Next</button>
				<a (click)="onCancel()" class="btn btn-default">Cancel</a>
			</div>
		</div>
	</form>
</div>

