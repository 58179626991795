import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { map, cloneDeep, filter, concat } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { StateService as $state } from 'angularjs-providers/state.provider';

import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NgResourceObject } from 'commons/interfaces';

import { Trigger, TriggersService } from '../../triggers.service';
import { TriggerStatuses } from '../trigger-statuses';

@Component({
	templateUrl: './trigger-details.component.html',
})
export class TriggerDetailsComponent implements OnInit {
	User: UserProfile;
	triggerStatuses = TriggerStatuses;
	details: NgResourceObject<Trigger> = {
		name: null,
		andExpressions: [],
		$resolved: true,
		$promise: null,
	};
	helpLink = '/help/authenticated/tpo/smc/triggers';
	preview: string = '';
	editing: boolean = false;
	isNew: boolean = false;
	isCloning: boolean = false;

	form = new RealmFormGroup({
		name: new RealmFormControl(
			'name',
			{ label: 'Trigger Name',  updateOn: 'change' },
			[ Validators.required, Validators.maxLength(250) ],
		),
		andExpressions: new RealmFormArray([
				new RealmFormControl(
					'words',
					{ label: 'Words', updateOn: 'change' },
				),
				new RealmFormControl(
					'words',
					{ label: 'Words', updateOn: 'change' },
				),
				new RealmFormControl(
					'words',
					{ label: 'Words', updateOn: 'change' },
				),
			],
			{ updateOn: 'change' }
		),
	});

	get hasViewPermissions(): boolean {
		return (
			this.User.can('TPO_SMC_VIEW_ALL') ||
			this.User.can('CCM_SMC_VIEW_ALL')
		);
	}

	get hasManagePermissions(): boolean {
		return (
			this.User.can('TPO_SMC_MANAGE_TRIGGERS') ||
			this.User.can('CCM_SMC_MANAGE_TRIGGERS')
		);
	}

	get canSave(): boolean {
		const { name, andExpressions } = this.form.value;

		return !!name && !!concat([], ...andExpressions).length;
	}

	get andExpressions(): RealmFormArray {
		return this.form.get('andExpressions') as RealmFormArray;
	}

	constructor(
		public stateService: StateService,
		public $state: $state,
		public userService: UserService,
		public triggersService: TriggersService,
	) {
		this.User = userService.profile;
	}

	generatePreviewPreview(): void {
		const andExpressions: string[][] = this.andExpressions.value as string[][];
		const columns = filter(
			map(andExpressions, (value: string[]): any => {
				return map(value, s => `"${s}"`).join(' OR ')
			}),
			(value) => {
				return value && value.length;
			}
		);

		this.preview = columns.length ? `(${columns.join(') <strong>AND</strong> (')})` : '';
	}

	save(): void {
		const { name, andExpressions: unfilteredAnds } = this.form.value;
		const { triggerId } = this.stateService.params;
		const andExpressions = filter(unfilteredAnds, (value: string[]) => {
			return value && value.length;
		});
		this.details.$resolved = false;

		if ( this.isNew ) {
			const success = (data: NgResourceObject<Trigger>) => {
				this.$state.notify(this.isCloning ? '^.^' : '^', {
					notification: {
						type: 'alert-success',
						message: `Trigger was created successfully. <a href="#/social-compliance/triggers/${data.id}" class="text-success underlined">View Trigger</a>`,
					},
				});
			};
			const fail = ({ data }) => {
				this.form.setServerError(data);
				this.details.$resolved = true;
			};
			if (this.isCloning) {
				this.triggersService.clone({ triggerId }, { name, andExpressions }, success, fail);
			} else {
				this.triggersService.add({ name, andExpressions }, success, fail);
			}
		} else {
			this.triggersService.save({ triggerId }, { name, andExpressions }, (data: NgResourceObject<Trigger>) => {
				this.details = data;
				this.cancelEditing();
				this.generatePreviewPreview();
			}, ({ data }) => {
				this.form.setServerError(data);
				this.details.$resolved = true;
			});
		}
	}

	ngOnInit(): void {
		const { triggerId, isNew } = this.stateService.params;
		if ( this.hasViewPermissions ) {
			this.isNew = isNew;
			if ( !triggerId ) {
				this.enterEditing();
			} else {
				this.isCloning = this.isNew;
				this.details = this.triggersService.get({ triggerId }, () => {
					if (this.isNew) {
						this.details.name = '';
						this.enterEditing()
						this.generatePreviewPreview();
					} else {
						this.cancelEditing();
						this.generatePreviewPreview();
					}
				});
			}
		}
	}

	enterEditing(): void {
		const { name, andExpressions: originalAnds } = this.details;
		const andExpressions = cloneDeep(originalAnds);

		while ( andExpressions.length < 3 ) {
			andExpressions.push([]);
		}
		this.form.reset({ name, andExpressions });
		this.editing = true;
	}

	cancelEditing(): void {
		if (this.isNew) {
			this.stateService.go('^');
		} else {
			this.editing = false;
			this.form.reset(this.details);
			this.generatePreviewPreview();
		}
	}

	toggleStatus(data?): void {
		const enabled = this.details.status.code === 'A';
		const { triggerId } = this.stateService.params;
		this.details.$resolved = false;
		const result =  this.triggersService[enabled ? 'deactivate' : 'activate']({ triggerId }, {});

		result.$promise.then((data: NgResourceObject<Trigger>) => {
			this.details = data;
		}).finally(() => {
			this.details.$resolved = true;
		});
	}
}
