export interface SocialMediaNetworkVisibility {
	name: string;
	code: string;
}

export interface SocialMediaNetworkConfig {
	className: string;
	badgeClassName?: string;
	title: string;
	regexp?: RegExp;
	signLimit?: number;
	imageDimension?: {
		value: {
			min: { width: number, height: number },
			max: { width: number, height: number },
		};
		error: string;
	};
	maxImageSize?: {
		value: number;
		error: string;
	};
	allowedImageFormats?: {
		value: RegExp;
		error: string;
	};
	maxImageCount?: {
		value: number;
		error: string;
	};
	maxVideoSize?: {
		value: number;
		error: string;
	};
	allowedVideoFormats?: {
		value: RegExp;
		error: string;
	};
	maxVideoCount?: {
		value: number;
		error: string;
	};
	maxGifSize?: {
		value: number;
		error: string;
	};
	maxGifCount?: {
		value: number;
		error: string;
	};
	visibility?: SocialMediaNetworkVisibility[];
	visibilityKey?: string;
}

export type SocialMediaNetworksConfig = Record<string, SocialMediaNetworkConfig>;

export const socialMediaNetworksVisibility: Record<string, SocialMediaNetworkVisibility> = {
	PUBLIC: {
		name: 'Public',
		code: 'PUBLIC',
	},
	PRIVATE: {
		name: 'Private',
		code: 'PRIVATE',
	},
	UNLISTED: {
		name: 'Unlisted',
		code: 'UNLISTED',
	},
	FRIENDS: {
		name: 'Friends',
		code: 'FRIENDS',
	},
	FRIENDS_OF_FRIENDS: {
		name: 'Friends of friends',
		code: 'FRIENDS_OF_FRIENDS',
	},
	CONNECTIONS: {
		name: 'Connections',
		code: 'CONNECTIONS',
	},
};

const toBytes = (value: number, key: 'KB' | 'MB' | 'GB' | 'TB',): number => {
	const coefficient = { KB: 1, MB: 2, GB: 3, TB: 4 };
	return value * Math.pow(1024, coefficient[key]);
}

const LIPartialConfig = {
	className: 'c-icon c-icon-linkedin-circled sm-icon icon-deep-blue',
	title: 'LinkedIn',
	signLimit: 1300,
	maxImageSize: {
		value: toBytes(10, 'MB'),
		error: `The photos you have uploaded are not supported by LinkedIn. Photos should be smaller than 10MB`,
	},
	allowedImageFormats: {
		value: /^.+\.(JPG|JPEG|PNG|GIF)$/i,
		error: `The photos you have uploaded are not supported by LinkedIn. Photos should be saved as GIF, JPG or PNG files.`,
	},
	maxImageCount: {
		value: 9,
		error: `Attachment count exceeds the allowable limit. Maximum LinkedIn attachments amount 9.`,
	},
	maxVideoSize: {
		value: toBytes(200, 'MB'),
		error: `The video you have uploaded is not supported by LinkedIn. Video should be smaller than 200Mb.`,
	},
	allowedVideoFormats: {
		value: /^.+\.(mov|mp4|m4v)$/i,
		error: `The video you have uploaded is not supported by LinkedIn. Video should be saved as .mp4, .m4v and .mov files.`,
	},
	maxVideoCount: {
		value: 1,
		error: `You can only attach one media type (video, gif, media link or image) and only images may have multiple attachments.`,
	},
	maxGifSize: {
		value: toBytes(10, 'MB'),
		error: `The photos you have uploaded are not supported by LinkedIn. Photos should be smaller than 10MB.`,
	},
	maxGifCount: {
		value: 9,
		error: `You can only attach one media type (video, gif, media link or image) and only images may have multiple attachments.`,
	},
	visibilityKey: 'linkedInVisibility',
};

export const socialMediaNetworksConfig: SocialMediaNetworksConfig = {
	'FB': {
		className: 'c-icon c-icon-facebook-circled sm-icon icon-dark-blue',
		title: 'Facebook',
		regexp: /^((https?):\/\/)?(www\.)?(m\.)?(facebook.com|fb.com)?(:\d{2,4})?\/./i,
		signLimit: 63100,
		maxImageSize: {
			value: toBytes( 4, 'MB'),
			error: `The photos you have uploaded are not supported by Facebook. Photos should be smaller than 4MB.`,
		},
		allowedImageFormats: {
			value: /^.+\.(JPG|JPEG|BMP|GIF|PNG|TIF|TIFF|HEIF|heic|WebP)$/i,
			error: `The photos you have uploaded are not supported by Facebook. Photos should be saved as JPG, PNG, BMP, TIFF, HEIF or WebP files.`,
		},
		maxImageCount: {
			value: 42,
			error: `Attachment count exceeds the allowable limit. Maximum Facebook attachments amount 42.`,
		},
		maxVideoSize: {
			value: toBytes(1, 'GB'),
			error: `The video you have uploaded is not supported by Facebook. Video should be smaller than 1Gb.`,
		},
		allowedVideoFormats: {
			value: /^.+\.(3g2|3gp|3gpp|asf|avi|dat|divx|dv|f4v|flv|m2ts|m4v|mkv|mod|mov|mp4|mpe|mpeg|mpeg4|mpg|mts|nsv|ogm|ogv|qt|tod|ts|vob|wmv|webm)$/i,
			error: `The video you have uploaded is not supported by Facebook. Video should be saved as 3g2, 3gp, 3gpp, asf, avi, dat, divx, dv, f4v, flv, gif, m2ts, m4v, mkv, mod, mov, mp4, mpe, mpeg, mpeg4, mpg, mts, nsv, ogm, ogv, qt, tod, ts, vob, wmv and webm files.`,
		},
		maxVideoCount: {
			value: 1,
			error: `You can only attach one media type (video, gif, media link or image) and only images may have multiple attachments.`,
		},
		maxGifSize: {
			value:  toBytes(1, 'GB'),
			error: `The GIF animation you have uploaded is not supported by Facebook. GIF should be smaller than 1Gb.`,
		},
		maxGifCount: {
			value: 1,
			error: `You can only attach one media type (video, gif, media link or image) and only images may have multiple attachments.`,
		},
		visibility: [
			socialMediaNetworksVisibility.PUBLIC,
			socialMediaNetworksVisibility.FRIENDS,
			socialMediaNetworksVisibility.FRIENDS_OF_FRIENDS,
		],
		visibilityKey: 'facebookVisibility',
	},
	'IG': {
		className: 'c-icon c-icon-instagram-circled sm-icon icon-red-instagram',
		title: 'Instagram',
		regexp: /^((https?):\/\/)?(www\.)?instagram.com(:\d{2,4})?\/./i,
		signLimit: 2200,
		maxImageSize: {
			value: toBytes( 40, 'MB'),
			error: `The photos you have uploaded are not supported by Instagram. Photos should be smaller than 40MB.`,
		},
		allowedImageFormats: {
			value: /^.+\.(JPG|JPE|JPEG|PNG|BMP|GIF)$/i, // @Todo make GIF like IMAGE
			error: `The photos you have uploaded are not supported by Instagram. Photos should be saved as JPG, PNG, BMP or GIF files.`,
		},
		maxImageCount: {
			value: 10,
			error: `Attachment count exceeds the allowable limit. Maximum Instagram attachments amount 10.`,
		},
		maxVideoSize: {
			value: toBytes(100, 'MB'),
			error: `The video you have uploaded is not supported by Instagram. Video should be smaller than 100MB.`,
		},
		allowedVideoFormats: {
			value: /^.+\.(3g2|3gp|mov|mp4|m4v)$/i,
			error: `The video you have uploaded is not supported by Instagram. Video should be saved as 3g2, 3gp, mp4, m4v and mov files.`,
		},
		maxVideoCount: {
			value: 10,
			error: `Attachment count exceeds the allowable limit. Maximum Instagram attachments amount 10.`,
		},
		maxGifSize: {
			value:  toBytes(40, 'MB'),
			error: `The photos you have uploaded are not supported by Instagram. Photos should be smaller than 40MB.`,
		},
		maxGifCount: {
			value: 10,
			error: `Attachment count exceeds the allowable limit. Maximum Instagram attachments amount 10.`,
		},
		visibility: [
			socialMediaNetworksVisibility.PUBLIC,
			socialMediaNetworksVisibility.FRIENDS,
			socialMediaNetworksVisibility.FRIENDS_OF_FRIENDS,
		],
		visibilityKey: 'instagramVisibility',
	},
	'LI': {
		regexp: /^((https?):\/\/)?(www\.)?(.+?\.)?linkedin.com(:\d{2,4})?\/.|^((https?):\/\/)?(touch\.)?(www\.)?linkedin.com(:\d{2,4})?\/./i,
		...LIPartialConfig,
		visibility: [
			socialMediaNetworksVisibility.PUBLIC,
			socialMediaNetworksVisibility.CONNECTIONS,
		],
	},
	'TW': {
		className: 'c-icon c-icon-twitter-circled sm-icon icon-sky-blue',
		title: 'Twitter',
		regexp: /^((https?):\/\/)?(www\.)?twitter.com(:\d{2,4})?\/|^((https?):\/\/)?(www\.)?(mobile\.)?twitter.com(:\d{2,4})?\/./i,
		signLimit: 280,
		maxImageSize: {
			value: toBytes(5, 'MB'),
			error: `The photos you have uploaded are not supported by Twitter. Photos should be smaller than 5MB and GIF smaller than 15M.`,
		},
		allowedImageFormats: {
			value: /^.+\.(JPG|JPEG|PNG|GIF|WebP)$/i,
			error: `The photos you have uploaded are not supported by Twitter. Photos should be saved as JPG, PNG, GIF or WebP files.`,
		},
		maxImageCount: {
			value: 4,
			error: `Attachment count exceeds the allowable limit. Maximum Twitter attachments amount 4.`,
		},
		maxVideoSize: {
			value: toBytes(15, 'MB'),
			error: `The video you have uploaded is not supported by Twitter. Video should be smaller than 15MB.`,
		},
		allowedVideoFormats: {
			value: /^.+\.(MOV|MP4|M4V)$/i,
			error: `The video you have uploaded is not supported by Twitter. Video should be saved as .mp4, .m4v and .mov files.`,
		},
		maxVideoCount: {
			value: 1,
			error: `You can only attach one media type (video, gif, media link or image) and only images may have multiple attachments.`,
		},
		maxGifSize: {
			value: toBytes(15, 'MB'),
			error: `The gif animation you have uploaded is not supported by Twitter. GIF should be smaller than 15MB.`,
		},
		maxGifCount: {
			value: 1,
			error: `You can only attach one media type (video, gif, media link or image) and only images may have multiple attachments.`,
		},
		visibility: [
			socialMediaNetworksVisibility.PUBLIC,
		],
		visibilityKey: 'twitterVisibility',
	},
	'YT': {
		className: 'c-icon c-icon-play-circled sm-icon icon-red',
		title: 'YouTube',
		regexp: /^(?:https?:\/{2})?(?:w{3}\.)?youtu(?:\.be\/|be\.com)/i,
		visibility: [
			socialMediaNetworksVisibility.PUBLIC,
			socialMediaNetworksVisibility.PRIVATE,
			socialMediaNetworksVisibility.UNLISTED,
		],
		visibilityKey: 'youtubeVisibility',
	},
	'G': {
		className: 'c-icon c-icon-google sm-icon',
		title: 'Google',
	},
	'GP': {
		className: 'c-icon c-icon-google-plus-circle sm-icon icon-red',
		title: 'Google+',
		regexp: /^((https?):\/\/)?(www\.)?plus.google.com(:\d{2,4})?\/./i,
	},
	'GMB': {
		className: 'c-icon c-icon-google-my-business-circle sm-icon',
		title: 'Google',
		signLimit: 1500,
		imageDimension: {
			value: {
				min: { width: 250, height: 250 },
				max: { width: 10000, height: 10000 },
			},
			error: `Images should have a minimum dimension of 250x250px and maximum of 10000x10000px.`,
		},
		maxImageSize: {
			value: toBytes(20, 'MB'),
			error: `The photos you have uploaded are not supported by Google My Business. Photos should be smaller than 20MB.`,
		},
		allowedImageFormats: {
			value: /^.+\.(JPG|JPEG|BMP|PNG)$/i,
			error: `The photos you have uploaded are not supported by Google My Business. Photos should be saved as JPG, BMP or PNG files.`,
		},
		maxImageCount: {
			value: 1,
			error: `Attachment count exceeds the allowable limit. Maximum Google My Business attachments amount is 1.`,
		},
		maxVideoSize: {
			value: 0,
			error: `Google My Business does not support video uploads.`,
		},
		allowedVideoFormats: {
			value: /(˜VideosAreNotSupported↑)$/,
			error: `Google My Business does not support video uploads.`,
		},
		maxVideoCount: {
			value: 0,
			error: `Google My Business does not support video uploads.`,
		},
		maxGifSize: {
			value: 0,
			error: `Google My Business does not support gif animation uploads.`,
		},
		maxGifCount: {
			value: 0,
			error: `Google My Business does not support gif animation uploads.`,
		},
		visibility: [
			socialMediaNetworksVisibility.PUBLIC,
		],
		visibilityKey: 'googleMyBusinessVisibility',
	},
	'NMLS': {
		className: 'c-icon c-icon-nmls-circled sm-icon icon-nmls-blue',
		title: 'NMLS',
	},
	'NMLS*': {
		className: 'c-icon c-icon-nmls-circled sm-icon icon-nmls-blue',
		badgeClassName: 'c-icon c-icon-cross sm-icon icon-red',
		title: 'Removed from NMLS',
	},
	// Account Disconnected
	'AD': {
		className: 'c-icon c-icon-cross sm-icon icon-red',
		title: 'Account Disconnected',
	},
	'ER': {
		className: 'c-icon c-icon-message icon-circled sm-icon',
		title: 'External Reviewer',
	},
	'BL': {
		className: 'c-icon c-icon-blog-circled sm-icon icon-light-blue',
		title: 'Blog',
	},
	'NW': {
		className: 'c-icon c-icon-news-circled sm-icon icon-light-blue',
		title: 'News',
	},
	'FR': {
		className: 'c-icon c-icon-forum-circled sm-icon icon-light-blue',
		title: 'Forums',
	},
	'TM': {
		className: 'c-icon c-icon-tumblr-circled sm-icon icon-deep-gray-blue',
		title: 'Tumblr',
	},
	'B': {
		className: 'c-icon c-icon-bing sm-icon',
		title: 'Bing',
	},
	'WS': {
		className: 'c-icon c-icon-internet-scan sm-icon icon-light-blue',
		title: 'Internet Scan',
	},
	'RS': {
		className: 'c-icon c-icon-internet-scan sm-icon icon-light-blue',
		title: 'Internet Scan',
	},
	'RSS': {
		className: 'c-icon c-icon-globe sm-icon icon-light-blue',
		title: 'RSS',
		regexp: /^\S*$/, // FIXME: should be unique
	},
	'RSS*': {
		className: 'c-icon c-icon-wifi sm-icon icon-light-blue',
		title: 'RSS',
	},
	'OT': {
		className: 'c-icon c-icon-globe sm-icon icon-light-blue',
		title: 'Other',
		regexp: /^\S*$/, // FIXME: should be unique
	},
	'EU': {
		className: 'c-icon c-icon-phone-circled sm-icon icon-light-green',
		title: 'Enrolled Users',
		regexp: /^\S*$/, // FIXME: should be unique
	},
	'ZLW': {
		className: 'c-icon sm-icon',
		title: 'Zillow',
		regexp: /^\S*$/, // FIXME: should be unique
	},
	'YLP': {
		className: 'c-icon sm-icon',
		title: 'Yelp',
		regexp: /^\S*$/, // FIXME: should be unique
	},
};
