import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class SocialMediaResourceService {
	links: any;
	link: any;

	constructor(
		public injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const linksPath = {
			url: `${apiPath}/digital-media/links/NMLSID/:nmlsId`,
			params: {
				nmlsId: '@nmlsId',
			},
		};
		const linkPath = {
			url: `${linksPath.url}/:linkId`,
			params: {
				...linksPath.params,
				linkId: '@linkId',
			},
		};

		this.links = PagedResource(
			linksPath.url,
			linksPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.link = $resource(
			linkPath.url,
			linkPath.params,
			{
				getConnectionStatus: {
					method: 'GET',
				},
				updateMonitoringStatus: {
					url: `${linkPath.url}/on-monitoring-status`,
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
			},
		);
	}
}
