<div class="gray-head flex-head">
    <h2>Scorecard</h2>
    <div class="col-min">
        <ul class="tab-menu col-min">
            <li
                class="tab-menu-item"
                *ngFor="let tab of tabs"
                uiSrefActive="active"
            >
                <a [uiSref]="tab.state" [uiParams]="baseSrefParams">
                    <b>{{tab.title}}</b>
                </a>
            </li>
        </ul>
    </div>
</div>
