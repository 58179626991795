import { Component, forwardRef, OnInit } from '@angular/core';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';

import { ScorecardService } from '../services/scorecard.service';
import {
    ScorecardFindingHistory,
} from 'shared/due-diligence/scorecard/scorecard-finding-history/scorecard-finding-history.interface';
import { ScorecardFinding } from 'shared/due-diligence/scorecard/scorecard-finding.interface';


@Component({
    templateUrl: './scorecard-finding-history.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardFindingHistoryComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardFindingHistoryComponent) },
    ],
})
export class ScorecardFindingHistoryComponent extends NewPagedListComponent<ScorecardFindingHistory> implements OnInit {
    static listName = 'findingsHistory';
    tpoId: number;
    findingId: number;
    finding: ScorecardFinding;

    constructor(
        public uiRouter: UIRouter,
        private readonly scorecardService: ScorecardService,
    ) {
        super(uiRouter);
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.uiRouter.globals.params.id;
        this.findingId = this.uiRouter.globals.params.sid;
        super.ngOnInit();
    }

    loadList(params: ListParams): Observable<PagedData<ScorecardFindingHistory[]>> {
        this.getFinding();
        return this.scorecardService.getScorecardFindingHistory(this.tpoId, this.findingId, params);
    }

    async getFinding() {
        this.finding = await this.scorecardService.getFindingDetails(this.tpoId, this.findingId).toPromise();
    }

}
