<h2 class="flex-row">
    <span class="flex-grow">Channels</span>
    <div *ngIf="canInvite && availableChannels?.length" class="dropdown" dropdown [insideClick]="true">
        <a dropdownToggle class="btn btn-primary">Invite <span class="caret"></span></a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem" *ngFor="let channel of availableChannels">
                <a uiSref=".invite.:channelId" [uiParams]="{channelId: channel.id}">{{channel.name}}</a>
            </li>
        </ul>
    </div>
</h2>

<div class="rows-bordered">
    <div class="row-fluid header">
        <span class="col-sm-4"><b>Channels</b></span>
        <span class="col-sm-4"><b>Relationship Status</b></span>
        <span class="col-sm-2"><b>Prospecting</b></span>
        <span class="col-sm-2"><b>Invite Status</b></span>
    </div>

    <div class="row-fluid" *ngFor="let item of channels">
        <span class="col-sm-4">
            <a [aDisabled]="!(lenderInfo.isRegistered && item.channel.accessible)"
               uiSref=".:channelId" [uiParams]="{channelId: item.channel.id}"
               class="text-bigger"
            >
                {{item.channel.name || '&ndash;'}}
            </a>
        </span>
        <span class="col-md-4 text-bigger">
            <approval-status [status]="item.approvalStatus"></approval-status>
            <div *ngIf="item.approvalStatus" class="text-smaller"><span class="text-muted">Assigned:</span> {{item.accountExecutive?.name || 'Unassigned'}}</div>
        </span>
        <span class="col-sm-2">
            <prospecting-status [canAdd]="false" [prospectInfo]="item"></prospecting-status>
            <span *ngIf="item.prospectStatusForUser === 'NONE'">&ndash;</span>
        </span>
        <span class="col-sm-2">
            <a [aDisabled]="!(lenderInfo.isRegistered && item.inviteStatus)"
               uiSref=".invite.:channelId.history" [uiParams]="{channelId: item.channel.id}"
            >{{item.inviteStatus?.name || '&ndash;'}}</a>
        </span>
    </div>

    <div class="row-fluid" *ngIf="!channels.length">
        <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Records added</span>
    </div>
</div>
