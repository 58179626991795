<card [expanded]="expanded()">
    <span title>{{ warehouse().companyName }}</span>
    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Company</label>
            <div *ngIf="inlineConfig('companyName') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" [options]="{ maxLength: 99 }" (save)="patch($event, ie)">
                    {{ warehouse().companyName | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Contact Name</label>
            <div *ngIf="inlineConfig('contactName') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" [options]="{ maxLength: 99 }" (save)="patch($event, ie)">
                    {{ warehouse().contactName | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
    </div>
    <hr>
    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Email</label>
            <div *ngIf="inlineConfig('email') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" [options]="{ maxLength: 99 }" (save)="patch($event, ie)">
                    <a [aDisabled]="!warehouse().email || canManage()"
                       [href]="warehouse().email | hrefReady">{{ warehouse().email  | empty : minLength }}</a>
                </new-inline-edit>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Phone</label>
            <div *ngIf="inlineConfig('phone') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="phone" (save)="patch($event, ie)">
                    {{ warehouse().phone | tel | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
    </div>
    <hr>
    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Sell Loans</label>
            <div class="flex-row gap">
                <ng-container *ngIf="canManage()">
                    <toggle #tg [ngModel]="warehouse().sellLoans" (ngModelChange)="patchToggle({ sellLoans: $event }, tg)"></toggle>
                </ng-container>
                <span>{{ warehouse().sellLoans ? 'Yes' : 'No' }}</span>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Line Amount</label>
            <div *ngIf="inlineConfig('approvedLineAmount') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="number" [options]="{ maxLength: 15 }" (save)="patch($event, ie)">
                    {{ warehouse().approvedLineAmount | currency:'USD':'$':'1.0-0' | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
    </div>
    <hr>
    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Start Date</label>
            <div *ngIf="inlineConfig('relationshipStartDate') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="date" [options]="{ placeholder: 'mm/dd/yyyy', maxDate: warehouse().relationshipEndDate }" (save)="patch($event, ie)">
                    {{ warehouse().relationshipStartDate | date : 'MM/dd/yyyy' }}
                </new-inline-edit>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">End Date</label>
            <div *ngIf="inlineConfig('relationshipEndDate') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="date" [options]="{ placeholder: 'mm/dd/yyyy', clearable: true, minDate: warehouse().relationshipStartDate }" (save)="patch($event, ie)">
                    <ng-container [ngSwitch]="!warehouse().relationshipEndDate">
                        <ng-container *ngSwitchDefault>{{ warehouse().relationshipEndDate | date : 'MM/dd/yyyy' }}</ng-container>
                        <ng-container *ngSwitchCase="true">Present</ng-container>
                    </ng-container>
                </new-inline-edit>
            </div>
        </div>
    </div>

    <h3>Covenants</h3>
    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Minimum TNW Required</label>
            <div *ngIf="inlineConfig('minimumTNWRequired') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="number" [options]="{ maxLength: 15 }" (save)="patch($event, ie)">
                    {{ warehouse().minimumTNWRequired | currency:'USD':'$':'1.0-0' | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Minimum Liquidity Required</label>
            <div *ngIf="inlineConfig('minimumLiquidityRequired') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="number" [options]="{ maxLength: 15 }" (save)="patch($event, ie)">
                    {{ warehouse().minimumLiquidityRequired | currency:'USD':'$':'1.0-0' | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
    </div>
    <hr>
    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Maximum Leverage Allowed</label>
            <div *ngIf="inlineConfig('maximumLeverageAllowed') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="number" [options]="{ maxLength: 15 }" (save)="patch($event, ie)">
                    {{ warehouse().maximumLeverageAllowed | currency:'USD':'$':'1.0-0' | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Minimum Profitability</label>
            <div *ngIf="inlineConfig('minimumProfitability') as cfg">
                <new-inline-edit #ie [editable]="canManage()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="number" [options]="{ maxLength: 15 }" (save)="patch($event, ie)">
                    {{ warehouse().minimumProfitability | currency:'USD':'$':'1.0-0' | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
    </div>
</card>
