import { Component, forwardRef, OnInit } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { UsersInterface } from 'shared/global-search/users/users.interface';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { UIRouter } from '@uirouter/core';
import { GlobalSearchService } from 'shared/global-search/global-search.service';
import { ListComponent } from 'commons/components/list';
import { statusConfig } from './statusConfig';
import { GlobalSearchDataService } from 'shared/global-search/global-search-data.service';
import { tap } from 'rxjs/operators';

@Component({
    templateUrl: 'user.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => SearchUsersListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => SearchUsersListComponent) },
    ],
})
export class SearchUsersListComponent extends NewPagedListComponent<UsersInterface> implements OnInit {
    statusConfig = statusConfig;
    static listName = 'globalSearchUsers';
    constructor(
        router: UIRouter,
        private globalSearchService: GlobalSearchService,
        private globalSearchDataService: GlobalSearchDataService,
    )
    {
        super(router);
        this.globalParams.push('q');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.globalSearchDataService.resetUserCount();
    }

    protected loadList(params: ListParams): Observable<PagedData<UsersInterface[]>> {
        return this.globalSearchService.getUsersList(params).pipe(
            tap(data => {
                this.globalSearchDataService.getUserCount(data);
            })
        );
    }
}
