<div class="company-listing-group-details">
	<div class="gray-head flex-head">
		<back-bread class="col-fit">
			{{ editingInfo ? 'Edit Listing' : (details.businessName || '&ndash;') }}
		</back-bread>
	</div>
	<form [formGroup]="form" class="remote-resource" [class.resolved]="details.$resolved && !settingsUpdating" (onValidSubmit)="save()">
		<notification></notification>
		<form-errors [form]="form"></form-errors>
		<div class="clearfix">
			<span class="pull-right" *ngIf="!editingInfo && User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')">
				<button type="button" [disabled]="details.syncWithGroup" class="btn btn-primary" (click)="startEdit()"><span class="glyphicon glyphicon-pencil"></span> Edit</button>
			</span>
			<span class="pull-right" *ngIf="editingInfo">
				<button class="btn btn-primary" type="submit">Submit</button>
				<button class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
			</span>

			<div class="pull-left" [class.curtain]="editingInfo">
				<toggle
					[disabled]="!User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')"
					[tooltip]="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA') ? '' : 'You don\'t have permissions'"
					[ngModel]="details.syncWithGroup"
					[ngModelOptions]="{standalone: true}"
					(toggle)="toggleGroupSetting()"
				></toggle>
				&nbsp;Group Sync
			</div>
		</div>

		<section class="listing-manage-images flex-row gap-m">
			<div class="listing-group-logo" [bgImage]="imagePreview.logo || details.logo"></div>
			<div class="listing-group-logo-description">
				<h3>Logo</h3>
				<p class="text-light">Your logo is used to show your identity when you post a photo or reply to a review.</p>
				<div *ngIf="editingInfo">
					<label class="file-handler">
						<input type="file" (input)="onImageSelect('logo', $event.target.files)" [multiple]="false" accept="image/png, image/jpeg">
						<span class="btn btn-secondary">Select</span>
					</label>
				</div>
			</div>
			<div class="listing-group-cover" [bgImage]="imagePreview.coverImage || details.coverImage"></div>
			<div class="flex">
				<h3>Cover Photo</h3>
				<p class="text-light">Cover photo should showcase the personality of your business. It is your prefered photo to be shown on your listing in Search and Maps.</p>
				<div *ngIf="editingInfo">
					<label class="file-handler">
						<input type="file" (input)="onImageSelect('coverImage', $event.target.files)" [multiple]="false" accept="image/png, image/jpeg">
						<span class="btn btn-secondary">Select</span>
					</label>
				</div>
			</div>
		</section>

		<section>
			<h3>
				Information
			</h3>
			<div class="rows-bordered">
				<div class="row-fluid header">
					<div class="col-md-12">
						Field
					</div>
				</div>

				<ng-container *ngIf="!( editingInfo && details.networkCode != 'FB' )">
					<div class="row-fluid">
						<div class="col-md-2 control-label">Business name</div>
						<div class="col-md-10 va-m">
							<sm-network-icon *ngIf="details.networkCode" [config]="details" [minified]="true" class="va-m"></sm-network-icon>&nbsp;
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('businessName') !== -1">
								<a [href]="details.link" [aDisabled]="!details.link" target="_blank">{{ details.businessName || '&ndash;' }}</a>
							</span>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="!editingInfo">
					<div class="row-fluid">
						<div class="col-md-2 control-label">Address</div>
						<div class="col-md-10 control-value">
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('fullAddress') !== -1">
								{{ details.$resolved ? (details | address) || '&ndash;' : '&ndash;' }}
							</span>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-2 control-label">Phone</div>
						<div class="col-md-10 control-value">
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('phoneNumber') !== -1">
								{{ (details.phoneNumber | tel) || '&ndash;' }}
							</span>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-2 control-label">Website</div>
						<div class="col-md-10 control-value">
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('website') !== -1">
								<a [href]="details.website | url" *ngIf="details.website" target="_blank">{{ details.website }}</a>
								<span *ngIf="!details.website">&ndash;</span>
							</span>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-2 control-label">Description</div>
						<div class="col-md-10 control-value">
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('description') !== -1">
								{{ details.description || '&ndash;'}}
							</span>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="editingInfo && details.networkCode != 'FB'">
					<div class="row-fluid">
						<div class="col-md-12">
							<div class="row form-group" has-error>
								<div class="col-md-2 control-label required">Business name</div>
								<div class="col-md-10 business-name has-feedback">
									<sm-network-icon
										[config]="details"
										[minified]="true"
										class="form-control-feedback"
									></sm-network-icon>
									<input type="text" class="form-control" formControlName="businessName" maxlength="90"/>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="editingInfo">
					<div class="row-fluid">
						<div class="col-md-12">
							<div class="row form-group" has-error>
								<div class="col-md-2 control-label required">Address</div>
								<div class="col-md-10">
									<input type="text" class="form-control" formControlName="address" maxlength="100"/>
								</div>
							</div>
							<div class="row form-group" has-error>
								<div class="col-md-2 control-label required">City</div>
								<div class="col-md-10">
									<input type="text" class="form-control" formControlName="city" maxlength="50"/>
								</div>
							</div>
							<div class="row form-group">
								<div class="col-md-6">
									<div class="row form-group" has-error>
										<div class="col-md-4 control-label required">State</div>
										<div class="col-md-8">
											<ng-select
												formControlName="state"
												[items]="states?.$promise | async"
												[loading]="!states?.$resolved"
												bindValue="shortName"
												bindLabel="name"
												[clearable]="true"
												placeholder="Select state">
											</ng-select>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="row form-group" has-error>
										<div class="col-md-2"></div>
										<div class="col-md-2 control-label">Zip</div>
										<div class="col-md-8">
											<input type="text" class="form-control" formControlName="zip" maxlength="20"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-12">
							<div class="row form-group" has-error>
								<div class="col-md-2 control-label">Phone</div>
								<div class="col-md-10">
									<input phoneFormatter type="text" class="form-control" formControlName="phoneNumber" />
								</div>
							</div>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-12">
							<div class="row form-group" has-error>
								<div class="col-md-2 control-label">Website</div>
								<div class="col-md-10">
									<input type="text" class="form-control" formControlName="website" maxlength="250"/>
								</div>
							</div>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-12">
							<div class="row form-group" has-error>
								<div class="col-md-2 control-label">Description</div>
								<div class="col-md-10">
									<textarea type="text" class="form-control" formControlName="description" maxlength="4000"></textarea>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

			</div>
		</section>
		<section class="regular-hours">
			<h3>
				Regular Hours
			</h3>
			<div class="rows-bordered" formGroupName="regularHours">

				<ng-container *ngIf="!editingInfo">
					<div class="row-fluid header">
						<div class="col-md-2">
							Day
						</div>
						<div class="col-md-a">
							Time
						</div>
					</div>
					<div class="row-fluid" *ngFor="let day of days">
						<div class="col-md-2">
							{{DAY_NAMES[day]}}
						</div>
						<div class="col-md-a" *ngIf="!details.regularHours || !details.regularHours[day]?.length">
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('regularHours.' + day) !== -1">
								Closed
							</span>
						</div>
						<div class="col-md-a" *ngIf="details.regularHours && details.regularHours[day]?.length">
							<span [class.has-discrepancy] = "details.discrepancies?.indexOf('regularHours.' + day) !== -1">
								<span *ngFor="let time of details.regularHours[day]">
									{{ time.startTime | date:'h:mm a' }} - {{ time.endTime | date:'h:mm a' }} <br>
								</span>
							</span>
						</div>
					</div>
				</ng-container>

				<ng-container *ngIf="editingInfo">
					<div class="row-fluid header">
						<div class="col-md-2">
							Day
						</div>
						<div class="col-md-2">
							From
						</div>
						<div class="col-md-2">
							To
						</div>
						<div class="col-md-4"></div>
					</div>
					<div class="row-fluid" *ngFor="let day of days" [formArrayName]="day">
						<div class="col-md-2 timepicker-padding">
							<div class="inline-block btn-holder va-m">
								<toggle
									[value]="enabledDays[day]"
									(toggle)="toggleDay(day)"
								></toggle>
							</div>
							&nbsp;{{DAY_NAMES[day]}}
						</div>
						<ng-container *ngIf="enabledDays[day]">
							<div class="col-md-2">
								<div class="form-group" has-error *ngFor="let time of regularHours.get(day).controls; index as i" [formGroupName]="i">
									<timepicker formControlName="startTime"></timepicker>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group" has-error *ngFor="let time of regularHours.get(day).controls; index as i" [formGroupName]="i">
									<timepicker formControlName="endTime"></timepicker>
								</div>
							</div>
							<div class="col-md-4">
								<ng-container *ngFor="let time of regularHours.get(day).controls; index as i; first as isFirst; last as isLast" [formGroupName]="i">
									<div class="timepicker-padding btn-holder">
										<button *ngIf="isLast" class="btn btn-secondary btn-icon" (click)="addTimeToDay(day)"><span class="btn-icon-add"></span></button>
										<button *ngIf="!isFirst" class="btn btn-tertiary btn-icon" (click)="removeTimeFromDay(day, i)"><span class="glyphicon glyphicon-trash"></span></button>
									</div>
								</ng-container>
							</div>
						</ng-container>
						<ng-container *ngIf="!enabledDays[day]">
							<div class="col-md-8 timepicker-padding va-m btn-holder">
								Closed
							</div>
						</ng-container>
					</div>
				</ng-container>

			</div>
		</section>
		<section class="text-right" *ngIf="editingInfo">
			<button class="btn btn-primary" type="submit">Submit</button>
			<button class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
		</section>
	</form>
</div>
