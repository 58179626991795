import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { NgResourceArray } from 'commons/interfaces';
import { TpoLibraryDocument } from 'tpo/documents/documents.interface';
import { TpoDocumentsService } from 'tpo/documents/documents.service';

@Component({
	templateUrl: './requests-modal.component.html',
})
export class TpoLibraryDocumentRequestsModalComponent implements OnInit {
	// TODO: fix interface
	// document: TpoLibraryDocument;
	document: any;
	requests: NgResourceArray<any>;

	get params() {
		const {
			document: {
				libraryId,
			},
		} = this;
		return {
			libraryId,
		};
	}

	constructor(
		private documentsService: TpoDocumentsService,
		public modalRef: BsModalRef,
	) {}

	ngOnInit(): void {
		this.requests = this.documentsService.getLibraryDocumentRequests({
			...this.params,
		});
	}
}
