import { isString } from 'lodash';
import { Injectable } from '@angular/core';

const fromJson = (json) => {
	return isString(json) ? JSON.parse(json) : json;
};

@Injectable()
export class LocalStorageService {
	get(key) {
		return fromJson(localStorage.getItem(key));
	}

	save(key, data) {
		localStorage.setItem(key, JSON.stringify(data));
	}

	remove(key) {
		localStorage.removeItem(key);
	}

	clearAll() {
		localStorage.clear();
	}
}
