<div class="tpo-add-edit-modal">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title text-center">Authorization Request - {{channelFrom.organization.organizationName}}</h4>
    </div>
    <form class="remote-resource" [class.resolved]="!resolving" [formGroup]="form">
        <div class="modal-body">
            <div class="well">
                If you accept, this client relationship status will be updated from <b>{{approvalStatus.name}}</b> to
                <b>Active</b>. If you decline, no action will be taken.
            </div>
            <form-errors [form]="form"></form-errors>
            <div class="form-group" has-error>
                <label class="control-label required">Relationship Status</label>
                <ng-select
                    [items]="approvalStatuses | async"
                    bindLabel="name"
                    [multiple]="false"
                    [loading]="loading.approvalStatus"
                    placeholder="Relationship Status"
                    formControlName="approvalStatus"></ng-select>
            </div>
            <div class="form-group" has-error>
                <label class="control-label required">Assigned</label>
                <ng-select
                    [items]="users | async"
                    bindLabel="name"
                    [multiple]="false"
                    [loading]="loading.users"
                    placeholder="Select Assigned"
                    formControlName="assigned"></ng-select>
            </div>
            <div class="form-group" has-error>
                <label class="control-label required">Renewal Date</label>
                <input
                    class="form-control input-date"
                    autocomplete="off"
                    bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                    formControlName="renewalDate"
                    placeholder="mm/dd/yyyy"
                >
            </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group pull-right">
                <button class="btn btn-primary" [disabled]="this.form.invalid" (click)="accept()">Accept</button>
                <button class="btn btn-danger-wired" (click)="decline()">Decline</button>
            </div>
        </div>
    </form>
</div>
