import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';
import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

@Component({
	templateUrl: './folder-modal.component.html',
})
export class FolderModalComponent implements OnInit {
	title: string;
	name: string;
	assetSharing: {
		sharingType: { id: string | number, name: string },
		shareTo: Array<{ id: string | number, name: string }>,
	}
	onConfirm: (folderName, sharingId?) => any;
	onCancel: (params?) => any;
	resolving: boolean;

	folderForm: RealmFormGroup = new RealmFormGroup({
		folderName: new RealmFormControl(
			'folderName',
			{
				label: 'Folder Name',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
		assetSharing: new RealmFormControl(
			'assetSharing',
			{
				label: 'Sharing',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: 'Create Folder',
			assetSharing: {
				sharingType: { id: 'NV', name: 'None' },
				shareTo: [],
			},
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: true,
		});
	}

	ngOnInit(): void {
		this.folderForm.patchValue({
			folderName: this.name,
			assetSharing: this.assetSharing,
		})
	}

	submit(): void {
		const { folderName, assetSharing } = this.folderForm.value;
		this.onConfirm(folderName, assetSharing);
	}

	hasError = (controlName: string): boolean => {
		const { invalid, touched } = this.folderForm.get(controlName);
		return invalid && touched;
	};
}
