<ng-container>
	<div class="full-page-header">
		<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span aria-hidden="true">
				Close
				<span class="glyphicon glyphicon-remove"></span>
			</span>
		</button>
	</div>
	<div class="content">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</div>
</ng-container>
