import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { CommentType } from '../interfaces/comment-type.interface';


declare let apiPath: string;

@Injectable()
export class CommonInvestorCommentsService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

	getCommentTypes(
	): Observable<CommentType[]> {
		return this.http.request(
			'GET',
			`${apiPath}/commenttype`,
		);
	}
}
