<form [formGroup]="locationChannelForm" (onValidSubmit)="saveChannel()" class="remote-resource" [class.resolved]="resolved">
    <div class="col-sm-4">
        {{channel.name}}
    </div>
    <div class="col-sm-4" [isEditable]="isStatusEditable">
        <div view class="{{channel.label}}">
            {{channel.approvalStatus?.name || '&ndash;'}}
        </div>
        <div edit>
            <ng-select
                [items]="statuses"
                bindLabel="name"
                [clearable]="false"
                placeholder="Select Status"
                formControlName="approvalStatus"
            >
            </ng-select>
        </div>
    </div>
    <div class="col-sm-3" [isEditable]="isAssignedEditable">
        <div view>
            {{channel.accountExecutive?.name || '&ndash;'}}
        </div>
        <div edit>
            <ng-select [items]="accountExecutives"
                       bindLabel="fullName"
                       [clearable]="false"
                       placeholder="Select Account Executive"
                       [compareWith]="compareAccountExecutives"
                       formControlName="assigned"
            >
            </ng-select>
        </div>
    </div>
    <div class="col-sm-1">
        <div *ngIf="isEditable && !editing">
            <button class="btn btn-tertiary btn-icon" (click)="editChannel()">
                <span class="glyphicon glyphicon-pencil"></span>
            </button>
        </div>
        <div *ngIf="editing">
            <button class="btn btn-tertiary btn-icon" (click)="saveChannel()">
                <span class="glyphicon glyphicon-ok"></span>
            </button>
            <button class="btn btn-tertiary btn-icon" (click)="cancelEdit()">
                <span class="glyphicon glyphicon-remove"></span>
            </button>
        </div>
    </div>
</form>


