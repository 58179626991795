import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
	message: string;
	errorText: string;
	title: string;
	confirmText: string;
	cancelText: string;
	onConfirm: (params?) => any;
	onCancel: (params?) => any;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: 'Please Confirm',
			confirmText: 'Confirm',
			cancelText: 'Cancel',
			onCancel: () => {
				this.modalRef.hide();
			},
			message: '<div class="alert alert-danger">Developer should always fill at least <b>message</b> property</div>',
			resolving: false,
		});
	}
}
