import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { defaults, includes, map, uniq } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

@Component({
	templateUrl: './facebook-related-modal.component.html',
})
export class FacebookMortgageRelatedModalComponent {
	title: string;
	message: string;
	confirmText: string;
	cancelText: string;
	list: any;
	onConfirm: (params?: any) => any;
	onCancel: (params?: any) => any;
	resolving: boolean;
	User: any;
	$all: {[key: string]: boolean} = {};

	constructor(
		public modalRef: BsModalRef,
		userService: UserService,
	) {
		this.User = userService.profile;

		defaults(this, {
			title: 'Select Accounts',
			message: `Please select the accounts you use for your mortgage business only.
					  You wil be able to see and reply to your reviews and publish content to these accounts.`,
			confirmText: 'Save',
			cancelText: 'Cancel',
			resolving: false,
		});
	}
}
