<ng-container [ngSwitch]="editable()">
    <ng-template #content><ng-content></ng-content></ng-template>
    <ng-container *ngSwitchDefault>
       <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="true">
        <div class="editable" #popover [popover]="editField" [placement]="placement()" (onShown)="initPopover()" [adaptivePosition]="true"
             [outsideClick]="true">
            <i class="inl-edit-btn glyphicon glyphicon-pencil"></i>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </div>
        <ng-template #editField>
            <form
                *ngIf="control()"
                class="remote-resource" [class.resolved]="resolved()"
                [formGroup]="form" (onValidSubmit)="save()">
                <div class="form-group" has-error [ngSwitch]="type()">
                    <ng-container *ngSwitchCase="INLINE_EDIT_TYPE.TEXT">
                        <input
                            class="form-control"
                            [formControlName]="this.name()"
                            [placeholder]="options().placeholder"
                            [attr.maxlength]="options().maxLength"
                        />
                    </ng-container>
                    <ng-container *ngSwitchCase="INLINE_EDIT_TYPE.PHONE">
                        <input
                            phoneFormatter
                            class="form-control"
                            [formControlName]="this.name()"
                            [placeholder]="options().placeholder"
                            [attr.maxlength]="options().maxLength"
                        />
                    </ng-container>
                    <ng-container *ngSwitchCase="INLINE_EDIT_TYPE.NUMBER">
                        <input
                            class="form-control"
                            [formControlName]="this.name()"
                            [placeholder]="options().placeholder"
                            [attr.maxlength]="options().maxLength"
                            [validFloat]="options().numberFormat"
                        />
                    </ng-container>
                    <ng-container *ngSwitchCase="INLINE_EDIT_TYPE.DATE">
                        <input
                            class="form-control"
                            [formControlName]="this.name()"
                            [placeholder]="options().placeholder"
                            placement="bottom left"
                            autocomplete="off"
                            bsDatepicker
                            [bsConfig]="datepickerConfig()"
                        >
                    </ng-container>
                    <form-errors [form]="form"></form-errors>
                </div>
                <div class="text-right">
                    <button type="submit" class="btn btn-tertiary btn-icon">
                        <i class="glyphicon glyphicon-ok"></i>
                    </button>
                    <button type="submit" class="btn btn-tertiary btn-icon" (click)="cancel()">
                        <i class="glyphicon glyphicon-remove"></i>
                    </button>
                </div>
            </form>
        </ng-template>
    </ng-container>
</ng-container>


