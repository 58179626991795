import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FullTpoContact, TpoContactType } from 'shared/new-contacts/contacts.interface';
import { DropdownRequiredValidator } from 'commons/validators';
import { CommonValue, CommonValuesService } from 'shared/services/common-values.service';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { TpoContactsService } from 'tpo/people/contacts/contacts.service';

@Component({
    templateUrl: './grant-access.modal.html',
})
export class GrantAccessModal implements OnInit {
    public grantAccessForm = new RealmFormGroup({
        role: new RealmFormControl(
            'role',
            { label: 'Role' },
            Validators.required,
        ),
    });
    contact: FullTpoContact;
    roles: any;
    contactTypeList: Observable<CommonValue[]>;
    contactDetails: SharedContactDetailsComponent;
    updating: boolean = false;
    tpoId: number;

    constructor(
        public readonly modalRef: BsModalRef,
        private readonly commonValuesService: CommonValuesService,
        private contactsService: TpoContactsService,
        private _cd: ChangeDetectorRef,
    ) {
        this.contactTypeList = this.commonValuesService.getDesignations(true);
    }

    ngOnInit() {
        if (this.contact.contactType === TpoContactType.NMLS) {
            this.addRequiredField(this.grantAccessForm);
        }
    }

    addRequiredField(form: RealmFormGroup): void {
        form.addControl('zip', new RealmFormControl(
            'zip',
            { label: 'Zip' },
            Validators.required,
        ));

        form.addControl('email', new RealmFormControl(
            'email',
            { label: 'Email' },
            Validators.compose([Validators.required, Validators.email]),
        ));

        form.addControl('officerDesignation', new RealmFormControl(
            'officerDesignation',
            { label: 'Designation' },
            DropdownRequiredValidator,
        ));

        form.reset(this.contact);
    }

    async createUser(): Promise<void> {
        const { tpoId, contact: { contactId }, grantAccessForm: form } = this;
        this.updating = true;
        const data = { ...this.contact, ...form.value };
        try {
            const contact = await this.contactsService.grantAccess(data, contactId, tpoId).toPromise();
            this.contactDetails.setContact(contact);
            this.modalRef.hide();
        } catch ({ error: { message }, message: httpError }) {
            form.setServerError({ message: message || httpError });
        }
        this._cd.markForCheck();
        this.updating = false;
    }
}
