<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">{{title}}</h4>
</div>
<form [formGroup]="form" (onValidSubmit)="confirm()">
	<div class="modal-body remote-resource document-modal-form" [class.resolved]="!resolving">
		<p [innerHTML]="message"></p>

		<div class="form-group">
			<label for="waive-comment" class="control-label">Reason</label>
			<textarea
				id="waive-comment"
				class="form-control resize-vertical"
				formControlName="comment"
				rows="4"
				[maxlength]="messageCharsAmount"
				placeholder="Add comment"
			></textarea>
			<div class="characters text-muted text-smaller">Symbols: {{ messageCharsAmount - (form.value?.comment?.length || 0) }}</div>
		</div>

		<div class="form-group">
			<label class="control-label required">Waive</label>
			<div>
				<label class="radio-inline">
					<input type="radio" formControlName="isPermanent" [value]="true"> Permanently
				</label>
				<label class="radio-inline">
					<input type="radio" formControlName="isPermanent" [value]="false"> One-time
				</label>
			</div>
		</div>
	</div>
	<div class="modal-footer text-right" [class.curtain]="resolving">
		<button type="submit" class="btn btn-primary" [disabled]="form.invalid">{{confirmText}}</button>
		<button type="button" (click)="onCancel()" class="btn btn-secondary">{{cancelText}}</button>
	</div>
</form>

