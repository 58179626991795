import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { UserService } from 'angularjs-providers/user.provider';
import { RootScopeService } from 'angularjs-providers/root-scope.provider';
import { StateService } from 'angularjs-providers/state.provider';

import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';

import { OwnershipVerificationService } from './ownership-verification.service';

@Component({
	templateUrl: './ownership-verification-modal.component.html',
})

export class OwnershipVerificationModalComponent {
	ownerInfo: any = {};
	ssnVerificationForm = new RealmFormGroup({
		ssn: new RealmFormControl(
			'ssn',
			{label: 'SSN'},
			Validators.compose([
				Validators.required,
				Validators.minLength(9),
				Validators.maxLength(9)],
			),
		),
	});

	constructor(
			public User: UserService,
			public modalRef: BsModalRef,
			public OwnershipVerification: OwnershipVerificationService,
			public $state: StateService,
			public $rootScope: RootScopeService) {}

	ngOnInit() {
		this.ownerInfo = this.OwnershipVerification.get();
	}

	verify() {
		this.ownerInfo.$resolved = false;
		this.OwnershipVerification.update(this.ssnVerificationForm.getRawValue(), () => {
			this.modalRef.hide();
			this.User.setNextScreen('');
			this.User.profile.ownershipVerificationRequired = false;

			const id = this.User.profile.organization.appliedForChannelId;
			if (id) { this.$state.go('application:id', {id}); }
		}, ({data: response}) => {
			this.ownerInfo.$resolved = true;
			this.ssnVerificationForm.setServerError(response);
		});
	}

	logout() {
		this.$rootScope.$broadcast('User.Logout');
		this.modalRef.hide();
	}
}
