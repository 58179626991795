import { isFunction } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Values do not match';

type templateStringFn = (value: string | number | null) => string;

export function CompareFieldsValidator(
	firstControl: AbstractControl,
	secondControl: AbstractControl,
	messageTemplate: string | templateStringFn = ''
): ValidatorFn {
	return ({ value }: AbstractControl): ValidationErrors | null => {
		const isValid = firstControl.value === secondControl.value;
		const message = isFunction(messageTemplate) ? messageTemplate(value) : messageTemplate;

		return isValid ? null : {
			match_error: {
				value,
				message: message || defaultMessage,
			},
		};
	};
}
