<form autocomplete="off" class="flex-row align-top space-between">
	<h2>Comments</h2>
	<ng-select
		class="w250 text-medium"
		[ngModel]="commentsComponent.params.categoryIds"
		[ngModelOptions]="{standalone: true}"
		[items]="accessibleCategories.$promise  | async"
		(change)="commentsComponent.setFilter({ categoryIds: processFilterParams($event) })"
		[loading]="!accessibleCategories.$resolved"
		bindValue="code"
		bindLabel="label"
		[multiple]="true"
		[closeOnSelect]="false"
		placeholder="All Categories"
	></ng-select>
</form>

<comments #commentsComponent
	[resource]="commentsResource"
	[permissions]="{ canAdd: true }"
	[addForm]="commentsForm"
	[defaultFiltersParams]="{ categoryIds: undefined }"
	(onAdd)="commentsForm.get('commentType').patchValue('PUBLIC')"
>
	<ng-template #formTemplate
                 let-addForm="addForm"
                 let-add="methods.add"
                 let-entityName="entityName"
                 let-editing="editing"
                 let-submitted="submitted"
                 let-setEditMode="methods.setEditMode">
		<form [formGroup]="addForm" id="addCommentForm" class="add-comment-form"
			  (onValidSubmit)="add()" autocomplete="off" role="presentation">
			<div class="mb15 remote-resource" [class.resolved]="!submitted">
				<div class="form-group full-width">
					<textarea
						class="form-control no-resize"
						formControlName="comment"
						rows="{{editing ? 5 : 1}}"
						maxlength="2000"
						placeholder="Add your {{entityName | lowercase}} here"
						(focus)="setEditMode(true)"
					></textarea>
				</div>

				<div class="form-group row" *ngIf="editing">
					<div class="col-md-3">
						<ng-select
							[items]="commentTypes?.$promise | async"
							[loading]="!commentTypes?.$resolved"
							formControlName="commentType"
							bindLabel="label"
							bindValue="code"
							[searchable]="false"
							[clearable]="false"
							required
						></ng-select>
					</div>

					<div class="col-md-9 control-value" *ngIf="commentTypes?.$resolved">
						{{typeDescriptions[addForm.value.commentType]}}
					</div>
				</div>

				<div class="btn-group-lg" *ngIf="editing">
					<button type="submit" class="btn btn-primary"
							[disabled]="submitted || !addForm.value.comment">
						Add {{entityName | titlecase}}
					</button>
					<button
						type="button"
						class="btn btn-default"
						(click)="setEditMode(false); addForm.patchValue({ commentType: 'PUBLIC' })"
					>
						Cancel
					</button>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #commentTemplate let-comment="comment">
		<div>
			<div class="horizontal-flex-group">
				<div class="full-width">
					<b>{{comment.createdBy}}&nbsp;</b>
					<span *ngIf="comment.category as category" [ngSwitch]="category" class="text-light">
						<span *ngSwitchCase="'Client Profile'">{{ category }}</span>
						<a *ngSwitchCase="'Alert'" uiSref="client.:id.reviews.alerts.:alertId" [uiParams]="{ id: comment.tpoId, alertId: comment.alertId }">{{ category }}</a>
						<a *ngSwitchCase="'Application'" uiSref="client.:id.account.channels.:channelId.applications.:applicationId.comments" [uiParams]="{ id: comment.tpoId, channelId: comment.channelId, applicationId: comment.applicationId }">{{ category }}</a>
						<a *ngSwitchCase="'Findings'" uiSref="client.:id.reviews.scorecard.finding.:sType.:sid" [uiParams]="{ id: comment.tpoId, sType: comment.findingCategory, sid: comment.findingId }">{{ category }}</a>
						<a *ngSwitchCase="'Scorecard'" uiSref="client.:id.reviews.scorecard.comments" [uiParams]="{ id: comment.tpoId }">{{ category }}</a>
					</span>
				</div>
				<div class="text-light text-smaller row">
					<span class="col-md-5">{{comment.commentType}}</span>
					<span class="col-md-7 text-nowrap">{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}</span>
				</div>
			</div>
			<p class="comment">{{comment.comment}}</p>
		</div>
	</ng-template>
</comments>
