<h2 class="flex-row">
    <span class="flex-grow">{{questionnaire?.name}}</span>
    <a class="btn btn-tertiary" href="/tpos/questionnaires/{{questionnaire?.id}}/print" target="_blank">
        <span class="glyphicon glyphicon-print"></span> Print View
    </a>
</h2>
<div class="alert alert-danger" role="alert" *ngIf="isTouched && !isValid">
    <div>Please answer all highlighted questions in red.</div>
</div>
<div class="rows-bordered counter no-margin" [class.top-border]="!questions[0]?.group">
    <single-question
        class="ordered-group form-group"
        *ngFor="let question of questions; index as i;"
        [questionnaireId]="questionnaire.id"
        [question]="question"
        [show]="visibility[i]"
        (submit)="submit()"
        (change)="calculateVisibility(); checkValidity();"
        (saved)="answerChange();"
    ></single-question>
</div>
<br>
<div class="alert alert-warning text-center" *ngIf="!finished"><b>Complete all questions to continue</b></div>
<div class="alert alert-danger" role="alert" *ngIf="isTouched && !isValid">
    <div>Please answer all highlighted questions in red.</div>
</div>
<div class="text-right sticky block bottom">
    <button class="btn btn-primary" [disabled]="isCompleted" (click)="submit()">Done</button>
</div>
