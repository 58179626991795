import { Component, Input, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { Location } from 'shared/account/locations/locations.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { UserService } from 'angularjs-providers/user.provider';
import { RELATION } from 'shared/models/Relation';

@Component({
    templateUrl: './originators.component.html',
})
export class LocationOriginatorsComponent implements OnInit {
    @Input('locationInfo') location: Location;
    @Input() lenderInfo: AccountInformation;
    contactsAvailable = false;
    branchNmlsId: number;
    hasProductionAvailable = true;

    constructor(
        protected userService: UserService,
        { params }: UIRouterGlobals,
    ) {
        this.branchNmlsId = params.branchId;
    }

    ngOnInit(): void {
        const { profile: User } = this.userService;
        switch (User.organization.type) {
            case 'COMERGENCE':
                this.contactsAvailable = this.lenderInfo.isRegistered;
                break;
            case 'CUSTOMER':
                this.contactsAvailable = User.isLender && (User.crmEnabled || this.lenderInfo.hasAccess(RELATION.TERMINATED, RELATION.ASSIGNED));
                this.hasProductionAvailable = User.crmEnabled;
                break;
            default:
                this.contactsAvailable = true;
                break;
        }
    }
}
