import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';

@Component({
    selector: 'simple-general-information',
    templateUrl: './simple-general-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleGeneralInformationComponent {
    @Input() contact: FullTpoContact;
}
