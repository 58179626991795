import { Component, Input } from '@angular/core';

import { DirectionalInvestorShareRequest, InvestorShareRequestChannelDesignation } from 'commons/interfaces/investor-share-requests.type';
import { InvestorChannelSharingTableActionButtonConfig } from 'lender/channel-shares/investor-channel-sharing-table-config.type';


@Component({
	selector: 'investor-channel-sharing-table',
	templateUrl: './investor-channel-sharing-table.component.html',
})
export class InvestorChannelSharingTableComponent {
    @Input() actionButtonConfigs: InvestorChannelSharingTableActionButtonConfig[] = [];
	@Input() header: string = '';
	@Input() otherInvestorDesignation: InvestorShareRequestChannelDesignation;
    @Input() shareRequests: DirectionalInvestorShareRequest[];
}
