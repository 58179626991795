import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { NgResourceObject } from 'commons/interfaces';

import { TpoDocument, TpoLibraryDocument } from 'tpo/documents/documents.interface';
import { TpoDocumentsService } from 'tpo/documents/documents.service';

@Component({
	templateUrl: './document.component.html',
})
export class TpoDocumentComponent implements OnInit {
	title: string;

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		private documentsService: TpoDocumentsService,
	) {}

	async ngOnInit(): Promise<void> {
		const {
			documentId,
			libraryId,
		} = this.uiRouterGlobals.params;
        const { name }: NgResourceObject<TpoDocument | TpoLibraryDocument> = documentId
            ? await this.documentsService.getDocument({ documentId }).$promise
            : await this.documentsService.getLibraryDocument({ libraryId }).$promise;
        this.title = name;
	}
}
