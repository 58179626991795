import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class PostTypeResourceService {
	posts: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const params = {
			tpoId: this.user.profile.organization.id,
		};
		const postsPath = {
			url: `${ apiPath }/tpos/:tpoId/social-media-posts`,
			params,
		};

		this.posts = PagedResource(
			postsPath.url,
			params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				accounts: {
					url: `${ postsPath.url }/accounts`,
					method: 'GET',
					isArray: true,
				},
				stats: {
					url: `${ postsPath.url }/stats`,
					method: 'GET',
				},
			},
		);
	}
}
