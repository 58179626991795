import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { Entity } from 'commons/interfaces/entity.type';
import { TextEntity } from 'lender/clients/$id/channels/channels.service';

declare let apiPath: string;

export type ShareRequest = {
    id: number;
    message?: string;
    requestedChannelId?: number;
    requestedChannelName?: string;
    requestorInvestorId?: number;
    requestorInvestorName: string;
}

export type RequestChannelInfo = {
    channelId: number;
    channelName: string;
    organization: {
        organizationId: number;
        organizationName: string;
    };
}

export type ShareRequestApprovalRequest = {
    id: number;
    renewalDate: number;
    approvalStatus: TextEntity;
    assigned: Entity;
    approvalAssigned: Entity;
    channelFrom: RequestChannelInfo;
    channelTo: RequestChannelInfo;
    lender: {
        companyName: string;
        companyAddress: string;
        state: string;
        city: string;
        zip: string;
        nmlsId: number;
        tpoId: number;
    };
}

@Injectable()
export class LenderHomeService {
    constructor(
        private readonly http: RealmHttpClient,
        private readonly userService: UserService,
    ) {
    }

    get investorId(): number {
        return this.userService.profile.organization.id;
    }

    getShareRequests(): Observable<ShareRequest[]> {
        return this.http.request<ShareRequest[]>(
            'GET',
            `${apiPath}/investors/${this.investorId}/share-requests`,
        );
    }

    getShareRequestsApprovals(): Observable<ShareRequestApprovalRequest[]> {
        return this.http.request<ShareRequestApprovalRequest[]>(
            'GET',
            `${apiPath}/investors/${this.investorId}/share-approvals`,
        );
    }

    getShareRequest(id: number): Observable<ShareRequest> {
        return this.http.request<ShareRequest>(
            'GET',
            `${apiPath}/investors/${this.investorId}/share-requests/${id}`,
        );
    }

    finalizeShareRequest(id: number, accepted: boolean): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/investors/${this.investorId}/share-requests/${id}/${accepted ? 'accepted' : 'declined'}`,
        );
    }

    getRenewableApprovalStatuses(): Observable<TextEntity[]> {
        return this.http.request<TextEntity[]>(
            'GET',
            `${apiPath}/applications/approval-statuses/renewable`,
        );
    }

    getOrganizationUsers(id: number): Observable<TextEntity[]> {
        return this.http.request<TextEntity[]>(
            'GET',
            `${apiPath}/investors/${this.investorId}/share-approvals/${id}/assignee-options`,
        );
    }

    acceptShareApprovalRequest(id: number, request): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/investors/${this.investorId}/share-approvals/${id}/accepted`,
            null,
            request,
        );
    }

    declinedShareApprovalRequest(id: number, request): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/investors/${this.investorId}/share-approvals/${id}/declined`,
            null,
            request,
        );
    }
}
