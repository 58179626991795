import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';

import {ComponentsModule} from 'commons/components/components.module';
import {DirectivesModule} from 'commons/directives/directives.module';

import { RolesListComponent } from './list/roles-list.component';
import { RoleComponent } from './role/role.component';
import { RolePermissionSectionComponent } from './role/permission-section/permission-section.component';
import { PermissionComponent } from './role/permission/permission.component';
import { DeactivationModalComponent } from './role/deactivation-deprecation-modal/deprecation-modal.component';

import { RolesResourceService } from './roles-resource.service';
import { RawPermissionsService } from 'shared/roles/raw-permissions.service';
import { RolePermissionSectionsComponent } from 'shared/roles/role/permission-sections/permission-sections.component';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		DirectivesModule,
		ReactiveFormsModule,
		FormsModule,
	],
	declarations: [
		RolesListComponent,
		RoleComponent,
		RolePermissionSectionComponent,
        RolePermissionSectionsComponent,
        PermissionComponent,
        DeactivationModalComponent,
	],
	providers: [
		RolesResourceService,
        RawPermissionsService,
	],
	exports: [
		RoleComponent,
		RolePermissionSectionComponent,
        RolePermissionSectionsComponent,
		PermissionComponent,
	],
})
export class RolesModule {
}
