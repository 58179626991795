import { Component, Input } from '@angular/core';

import { OwnershipType, OWNERSHIP_TYPES, SortedOwnerFindings } from '../../scorecard-owners.interface';


@Component({
    selector: 'scorecard-summary-owners-section',
	templateUrl: './scorecard-summary-owners-section.component.html',
})
export class ScorecardSummaryOwnersSectionComponent {
    readonly ownershipTypeList: OwnershipType[] = OWNERSHIP_TYPES;

    @Input() tpoId: (number | string);
    @Input() isCCM: boolean;
    @Input() ownerFindings: SortedOwnerFindings;

    ownershipType: OwnershipType = 'Current';

    changeOwnershipType(newType: OwnershipType): void {
        this.ownershipType = newType;
    }
}
