import { Component, Input } from '@angular/core';

import { AccountInformation } from '../../shared/account/company-information/account-information.service';

@Component({
    templateUrl: './tpo-account.component.html',
})
export class TpoAccountComponent {
    @Input() lenderInfo: AccountInformation;
}
