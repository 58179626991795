<div class="services clearfix">
    <h2 class="title-label clearfix">
        <span class="pull-left">
            Investor Marketing Service
        </span>
    </h2>
    <div class="remote-resource" [class.resolved]="!resolving">
        <div class="rows-bordered">
            <div class="row-fluid header">
                <div class="col-sm-1"></div>
                <sortable-header class="col-sm-3" columnName="actionAt">Date</sortable-header>
                <sortable-header class="col-sm-5" columnName="action">Action</sortable-header>
                <sortable-header class="col-sm-3" columnName="actionBy">Action by</sortable-header>
            </div>
            <div *ngIf="!resolving && ((list | async)?.length > 0); else noHistory ">
                <div class="row-fluid table-row" *ngFor="let item of list | async ">
                    <div class="col-sm-1"></div>
                    <div columnName="actionAt" class="col-sm-3">
                        <div>{{item.actionAt | date:'MM/dd/yyyy HH:mm'}}</div>
                    </div>
                    <div columnName="actions" class="col-sm-5">
                        <div *ngFor="let actions of item.actions; last as isLast">
                            {{actions}}{{isLast ? '' : ', '}}
                        </div>
                    </div>
                    <div columnName="actionBy" class="col-sm-3">
                        <div>{{item.actionBy}}</div>
                    </div>
                </div>
            </div>
            <ng-template #noHistory>
                <div class="row-fluid">
                    <span class="col-md-12 text-muted text-center text-bigger">No History</span>
                </div>
            </ng-template>
            <br>
            <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
        </div>
    </div>
</div>
