import { Component, Input } from '@angular/core';

import { KeyedScorecardFindingCategories } from '../../scorecard-finding-category.interface';
import { ScorecardFindingSection } from '../../scorecard-findings-metrics.interface';


@Component({
    selector: 'scorecard-summary-findings-metrics-section',
	templateUrl: './scorecard-summary-findings-metrics-section.component.html',
})
export class ScorecardSummaryFindingsMetricsSectionComponent {
    @Input() tpoId: (number | string);
    @Input() keyedScorecardFindingCategories: KeyedScorecardFindingCategories;
    @Input() findingsMetricsSection: ScorecardFindingSection;
}
