<div class="company-listing-group-details">
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [filter]="[ '**.listings.company.:listingGroupId.**' ]">
			Group
		</back-bread>

		<ul class="tab-menu col-min">
			<li
				*ngFor="let tab of tabs"
				uiSrefActive="active"
			>
				<a uiSref="{{tab.state}}">
					<b>{{tab.title}}</b>
					<ng-container *ngIf="tab.exclamation$ | async">
						&nbsp;<span class="glyphicon glyphicon-exclamation-sign alert-danger"></span>
					</ng-container>
				</a>
			</li>
		</ul>
	</div>

	<ui-view></ui-view>
</div>
