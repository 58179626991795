import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient, RealmPagedParams, RealmParams } from 'commons/services/http';
import {
    AccountIdentifier,
    AccountInformation,
} from 'shared/account/company-information/account-information.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { UIRouter } from '@uirouter/core';
import { LocationChannel } from './single/channels/locations-channels.interface';
import { AccountExecutiveDetails } from 'lender/clients/channels/channels-summary.interface';

declare let apiPath;

export interface Location {
    address1: string;
    address2: string;
    authorization: boolean;
    canUserAddProspect: boolean;
    city: string;
    headquarter: boolean;
    hqNmlsId: number;
    name: string;
    nmlsId: number; // the same as branchId
    prospectStatusForUser: string;
    state: string;
    stateCode: string;
    tpoId: number;
    zip: string;
}

export interface SmallContact {
    contactId: number;
    name: string;
    nmlsId: number;
}

@Injectable()
export class LocationsService {
    constructor(private http: RealmHttpClient) {
    }

    getList<T extends Location[] = Location[]>(identifier: AccountIdentifier, params: RealmPagedParams): Observable<PagedData<T>> {
        return this.http.pagedRequest<T>(
            'GET',
            `${apiPath}/tpos/company/locations`,
            { ...identifier, ...params },
        );
    }

    getBranchManagerList<T extends SmallContact[] = SmallContact[]>(identifier: AccountIdentifier, nmlsId, params: RealmPagedParams): Observable<PagedData<T>> {
        return this.http.pagedRequest<T>(
            'GET',
            `${apiPath}/tpos/company/locations/${nmlsId}/branch-managers`,
            { ...identifier, ...params },
        );
    }

    getOriginatorsList<T extends SmallContact[] = SmallContact[]>(identifier: AccountIdentifier, nmlsId, params: RealmPagedParams): Observable<PagedData<T>> {
        return this.http.pagedRequest<T>(
            'GET',
            `${apiPath}/tpos/company/locations/${nmlsId}/originators`,
            { ...identifier, ...params },
        );
    }

    getSingle<T extends Location = Location>(identifier: AccountIdentifier, nmlsId): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${apiPath}/tpos/company/locations/${nmlsId}`,
            { ...identifier },
        );
    }

    getChannelsList(nmlsId: number, tpoId: number, lenderId: number, params: RealmParams): Observable<PagedData<LocationChannel[]>> {
        return this.http.pagedRequest(
            'GET',
            `${apiPath}/tpos/company/locations/${nmlsId}/channels`,
            {
                ...params,
                tpoId: tpoId,
                lenderId: lenderId
            },
        )
    }

    getAccountExecutives(channelId: number): Observable<AccountExecutiveDetails[]> {
        return this.http.request<any>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/account-executives`,
        );
    }

    saveLocationChannel(lenderId: number, tpoId: number, channelId: number, locationChannels: LocationChannel[]): Observable<any> {
        return this.http.request(
            'PUT',
            `${apiPath}/lenders/${lenderId}/tpos/${tpoId}/channels/${channelId}/location-statuses`,
            null,
            locationChannels,
        );
    }
}

const locationInfo: (UIRouter, LocationsService, AccountInformation) => Promise<Location> = (router: UIRouter, locationsService: LocationsService, account: AccountInformation) => {
    const { branchId } = router.globals.transition.params();
    return locationsService.getSingle(account.identifier, branchId).pipe(
        shareReplay(1),
    ).toPromise();
};

export const locationInfoResolver = { token: 'locationInfo', deps: [UIRouter, LocationsService, 'lenderInfo'], resolveFn: locationInfo };
