import { Component, inject, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ResetPasswordPolicy, UserSetPasswordService } from 'login/user-set-password.service';
import { PinValidator } from 'commons/validators';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { UserService } from '../../angularjs-providers/user.provider';

const EqualPasswords: ValidatorFn = (form: AbstractControl) => {
    const { password, repeatPassword } = form.value;
    if (!password || !repeatPassword || password === repeatPassword) return null;
    return {
        equals: {
            value: password,
            message: 'Passwords do not match',
        },
    };
};

@Component({
    templateUrl: './password-change-modal.component.html',
})
export class PasswordChangeModalComponent implements OnInit {
    userService = inject(UserService);

    protected resolving = false;
    protected form = new RealmFormGroup(
        {
            password: new RealmFormControl('password', { label: 'Password', value: '', updateOn: 'change' }, Validators.required),
            repeatPassword: new RealmFormControl('repeatPassword', { label: 'Confirm Password',  value: '' }, Validators.required),
            pin: new RealmFormControl('pin', { label: 'Security Pin Code',  value: '' }, [Validators.required, PinValidator()]),
        },
        EqualPasswords,
    );
    passwordNotification: string = 'Your password expired, please set new password.';
    title: string = 'Set New Password';
    resetPolicy: ResetPasswordPolicy;
    resetToken: string = '';
    static = false;
    error: string = null;

    onReset = () => {};

    constructor(
        private readonly modalRef: BsModalRef,
        private readonly passwordService: UserSetPasswordService,
        private readonly notificationService: GlobalNotificationsService,
    ) {

    }

    async ngOnInit() {
        this.resolving = true;
        try {
            this.resetPolicy = await this.passwordService.get(this.resetToken).toPromise();
            if (this.resetPolicy.pinSet) {
                this.form.get('pin').disable();
            }
            this.resolving = false;
            if (this.static) {
                const { pinSet, firstName } = this.resetPolicy;
                this.passwordNotification = `Hello, <strong>${firstName}</strong>, please create your Password ${pinSet ? '': 'and set Security Pin Code '}in order to use this application`
            }
        } catch ({ error: { message } }) {
            this.error = message;
            this.resolving = false;
        }
    }

    async save() {
        const { value } = this.form;
        this.resolving = true;
        try {
            await this.passwordService.save(this.resetToken, value).toPromise();
            this.userService.redirectToLogin();
        } catch ({ error }) {
            this.form.setServerError(error);
            this.resolving = false;
        }
    }

    cancel() {
        this.userService.redirectToLogin();
    }
}
