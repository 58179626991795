import { Component, Input } from '@angular/core';
import { Location } from 'shared/account/locations/locations.service';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: './header.component.html',
})
export class LocationHeaderComponent {
    @Input('locationInfo') location: Location;

    isInvestor: boolean;

    constructor(
        userService: UserService,
    ) {
        this.isInvestor = userService.profile.isLender;
    }
}
