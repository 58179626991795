import { assign, cloneDeep } from 'lodash';
import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

import { UserProfile, UserService as UService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { ApiSettingsResourceService } from './api-settings-resource.service';
import { CcmServiceStatusService } from 'comergence/customers/ccm-service-status.service';

@Component({
	templateUrl: './api-settings.component.html',
})
export class ApiSettingsComponent {
    apiSettings: any = { organizationId: this.state.params.organizationId };
	editing: boolean;
	apiSettingsForm: RealmFormGroup = new RealmFormGroup({
		adClientId: new RealmFormControl(
			'adClientId',
			{
				label: 'Client ID',
			},
		),
	});
	apiSettingsFormCopy: any;
	User: UserProfile;

	constructor(
		public apiSettingsResource: ApiSettingsResourceService,
		public state: StateService,
		public userService: UService,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
	) {
		this.User = this.userService.profile;
	}

	ngOnInit() {
		this.apiSettings = this.apiSettingsResource.get(this.apiSettings, (response) => {
			this.apiSettingsForm.reset({...response});
			this.apiSettingsFormCopy = cloneDeep(this.apiSettingsForm.value);
		});
	}

	save() {
		assign(this.apiSettings, {
			adClientId: this.apiSettingsForm.getRawValue().adClientId,
			organizationId: this.state.params.organizationId,
		});

        this.apiSettings = this.apiSettingsResource.update(this.apiSettings, () => {
			this.apiSettingsFormCopy = cloneDeep(this.apiSettingsForm.value);
			this.editing = false;
            this.ccmServiceStatusService.fetchServiceStatuses();
		}, ({data: response}) => {
			this.apiSettingsForm.setServerError(response);
		});
	}

	reset() {
		this.editing = false;
		this.apiSettingsForm.reset({...this.apiSettingsFormCopy});
	}

	edit() {
		this.editing = true;
	}
}
