<section>
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<div class="text-muted">
					Search
				</div>
			</div>
			<div class="col-sm-3">
				<div class="text-muted">
					State
				</div>
			</div>
			<div class="col-sm-3" [class.curtain]="!params.state">
				<div class="text-muted">
					Branch
				</div>
			</div>
			<div class="col-sm-3"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<search-list placeholder="Name or NMLS ID"></search-list>
			</div>
			<div class="col-sm-3 remote-resource" [class.resolved]="filters.state.$resolved">
				<ng-select
					[ngModel]="params.state"
					(change)="setFilter({ state: $event })"
					[items]="filters.state.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[clearable]="true"
					placeholder="All States"
				>
				</ng-select>
			</div>
			<div class="col-sm-3 remote-resource" [class.resolved]="filters.branchId.$resolved" [class.curtain]="!params.state">
				<ng-select
					[ngModel]="params.branchId"
					(change)="setFilter({ branchId: $event })"
					[items]="filters.branchId.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[disabled]="!params.state.length"
					[clearable]="true"
					placeholder="All Branches"
				>
				</ng-select>
			</div>
			<div class="col-sm-3 text-right">
				<button type="button" class="btn btn-primary" (click)="save()">
					Save ({{selectedContactIds.length}})
				</button>
				<a class="btn btn-secondary" uiSref="^">
					Cancel
				</a>
			</div>
		</div>
	</div>
	<div class="rows-bordered remote-resource" [class.resolved]="contacts.$resolved">
		<div class="row-fluid header">
			<div class="col-sm-check">
				<!-- Select all -->
			</div>
			<div class="col-sm-a">
				<b>Name</b>
			</div>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
		</div>

		<div class="row-fluid" *ngFor="let item of contacts">
			<div class="col-sm-check va-m">
				<input type="checkbox" [checked]="selectedContactIds.includes(item.contactId)" (click)="toggleContact(item)">
			</div>
			<div class="col-sm-a va-m">
				<a *ngIf="item.contactId && item.contactName" uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: item.contactId }" target="_blank">
					{{item.contactName}}
				</a>
				<span *ngIf="!item.contactId">{{item.contactName || '&ndash;'}}</span>
				<div class="text-smaller text-muted" *ngIf="item.contactNmlsId">NMLS ID: {{item.contactNmlsId}}</div>
			</div>
		</div>

		<div class="row-fluid" *ngIf="noMatches">
			<div class="col-sm-12 text-center text-bigger text-muted">No matches found</div>
		</div>

		<div class="row-fluid" *ngIf="!contacts.length && !noMatches">
			<div class="col-sm-12 text-center text-bigger text-muted">No contacts</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="contacts" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</section>
