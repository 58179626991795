import { Component, Input, OnInit } from '@angular/core';
import { defaults } from 'lodash';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';

export interface BackBreadOptions {
	headerClass?: string;
}

const DefaultOptions = {
	headerClass: '',
};

@Component({
	selector: 'back-bread',
	templateUrl: './back-bread.component.html',
})
export class BackBreadComponent implements OnInit {
    @Input() headerClass: string = '';
    @Input() opts: BackBreadOptions = DefaultOptions;

    @Input() set filter(value: string | string[]) {
        this._filters = typeof value === 'string' ? [value] : value;
    }

    get filter(): string[] {
        return this._filters;
    }

    private _filters: string[] = [];
    enabled: boolean = true;

    public constructor(private readonly historyLogService: HistoryLogService) {
    }

    // Override
    public ngOnInit(): void {
        this.opts = defaults(this.opts, DefaultOptions);
    }

    public goBack(): void {
        const backState = this.historyLogService.findBackState(this._filters);
        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }
        return window.history.back();
    }
}
