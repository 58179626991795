import { SetupPasswordComponent } from 'login/setup-password/setup-password.component';

export default [
    {
        name: 'setPassword',
        url: '/set-password/:token',
        component: SetupPasswordComponent,
        free: true,
        class: 'home-blue',
    },
]
