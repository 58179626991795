import { Injectable, Injector } from '@angular/core';
import { map, isArray } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';

declare let apiPath: string;

@Injectable()
export class SocialAccountsResourceService {
	company: any;
	accountInfo: any;
	nmlsCompanyInfo: any;
	individuals: any;
	inventory: any;
	mediaLinks: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const MemoResource = injector.get('$injector').get('MemoResource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const {
			isTpo,
			isComergence,
		} = this.user.profile;

		const accountInfoPath = {
			url: `${apiPath}/tpos/:id/company-information`,
			params: {
				id: this.user.profile.organization.id,
			},
		};

		const nmlsCompanyInfoPath = {
			url: `${apiPath}/nmls/company/:nmlsId/company-information`,
			params: {
				nmlsId: '@nmlsId',
			},
		};

		const individualsMonitoringPath = {
			url: `${apiPath}/social-media/individuals`,
			params: {},
		};

		const inventoryPath = {
			url: `${apiPath}/social-media-inventory/:tpoId`,
			params: {
				tpoId: '@tpoId',
			},
		};

		const mediaLinksConfig = {
			url: `${apiPath}/:mediaType/links/:entityIdType/:entityId`,
			params: {},
		};

		this.nmlsCompanyInfo = $resource(
			nmlsCompanyInfoPath.url,
			nmlsCompanyInfoPath.params,
			{
				get: {
					method: 'GET',
				},
			},
		);

		this.accountInfo = MemoResource(
			accountInfoPath.url,
			accountInfoPath.params,
			{
				get: {
					method: 'GET',
				},
			},
		);

		this.individuals = {
			list: PagedResource(
				individualsMonitoringPath.url,
				individualsMonitoringPath.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			contact: $resource(
				individualsMonitoringPath.url,
				individualsMonitoringPath.params,
				{
					getAccounts: {
						url: `${individualsMonitoringPath.url}/:contactId/accounts`,
						params: {
							contactId: '@contactId',
						},
						method: 'GET',
						isArray: true,
					},
				},
			),
		};

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/social45media45inventory45resource/getSocialMediaInventoryUsingGET
		this.inventory = {
			list: PagedResource(
				`${inventoryPath.url}/links`,
				inventoryPath.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			accountTypes: PagedResource(
				`${inventoryPath.url}/account-types`,
				inventoryPath.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			locations: PagedResource(
				`${inventoryPath.url}/locations`,
				inventoryPath.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			export: PagedResource(
				`${inventoryPath.url}/export`, {
					tpoId: '@tpoId',
					q: '@q',
					states: '@states',
					locationNmlsIds: '@locationNmlsIds',
					accountTypes: '@accountTypes',
					entityTypes: '@entityTypes',
				},
				{
					get: {
						method: 'POST',
					},
				},
			),
		};

		this.company = {
			// FIXME: migrate to SharedSocialMediaService
			links: PagedResource(
				`${apiPath}/digital-media/links/:nmlsId`,
				{},
				{
					get: {
						method: 'GET',
						isArray: true,
						transformResponse(response) {
							const result = JSON.parse(response);
							return isArray(result) ? map(result, (entry) => (new SocialAccountLink({
								data: entry,
								isTpo,
								isComergence,
							}))) : result;
						},
					},
				},
			),
			nmlsLinks: PagedResource(
				`${apiPath}/digital-media/links/NMLSID/:nmlsId`,
				{},
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			billing: $resource(
				`${apiPath}/organizations/:organizationId/billing/social-media-monitoring`,
				{ organizationId: '@organizationId' },
				{
					get: {
						method: 'GET',
					},
					save: {
						method: 'PUT',
					},
				},
			),
		};
	}
}
