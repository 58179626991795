import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import DEFAULT_PROFILE_IMG from '~static/images/social-media/other.png';
import {
	SocialMediaLinkConfig,
	SocialMediaNetworkIconConfig,
} from '../';

@Component({
	selector: 'sm-icon',
	templateUrl: './sm-link-icon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaLinkIconComponent {
	@Input() config: SocialMediaLinkConfig;
	@HostBinding('class.minified') @Input() minified: boolean;
	@Input() showRssStatus = true;
	@Input() showTooltip = true;

	// prefix class names to make it more specific and not intersect with existing class names
	// FIXME: replace with a proper implementation (RM-24608)
	smIconClassNamePrefix = 'sm-icon';
	smIconNetworkIconClassName = `${this.smIconClassNamePrefix}-network-icon`;
	smIconProfileImageClassName = `${this.smIconClassNamePrefix}-profile-image`;
	smIconBadgeClassName = `${this.smIconClassNamePrefix}-badge`;

	constructor(
		public sanitizer: DomSanitizer,
	) {}

	// TODO: config.linkStatus === 'RSS' was in previous implementation, remove this condition in future; config.rssLink is valid solution
	get hasRss(): boolean {
		const {
			config: {
				linkStatus,
				rssLink,
			},
		} = this;
		return (
			linkStatus === 'RSS' ||
			!!rssLink
		);
	}

	get networkIconConfig(): SocialMediaNetworkIconConfig {
		const { networkCode, source, title } = this.config;
		return { networkCode, source, title };
	}

	get networkIconClassName(): string[] {
		return [
			this.smIconNetworkIconClassName,
			...(!this.hasRss ? [ 'icon-light-gray' ] : []),
		];
	}

	sanitize = (url: string | void): SafeStyle => (
		this.sanitizer.bypassSecurityTrustStyle(
			[
				...(url ? [ `url(${url})` ] : []),
				`url(${DEFAULT_PROFILE_IMG})`,
			].join(', '),
		)
	)
}
