<div class="client-document">
	<div class="gray-head">
		<back-bread>{{title}}</back-bread>
	</div>

    <div class="global-document">
	<form [formGroup]="form"
            class="remote-resource"
            [class.resolved]="resolved && !gettingLinkedDocumentCount && !discontinuingDocument && !activatingDocument"
            (onValidSubmit)="submit()">
		<section
			formGroupName="documentDetails"
			class="document-details"
		>
			<h3 class="flex-row">
				Document Information
				<div class="right">
                    <ng-container *ngIf="canEdit && isViewing">
                        <button type="button" class="btn btn-primary" (click)="toggleEdit(true)">
                            <span class="glyphicon glyphicon-pencil"></span> Edit
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!isViewing">
                        <button *ngIf="!isViewing && document" type="button" class="btn btn-link" (click)="discontinueOrActivate()">{{ document.isActive ? 'Discontinue document' : 'Activate document' }}</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                        <button type="button" class="btn btn-default" (click)="cancelEdit()">Cancel</button>
                    </ng-container>
				</div>
			</h3>

			<form-errors [form]="form"></form-errors>

			<div class="rows-striped">
                <ng-container *ngIf="isViewing">
                    <div class="row">
                        <label class="col-sm-2 control-label">
                            {{ nameFormControl.label }}
                        </label>
                        <div class="col-sm-10">
                            <span class="control-value">{{ nameFormControl.value }}</span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!isViewing">
                    <div class="row form-group" has-error>
                        <label class="col-sm-2 control-label required">
                            {{ nameFormControl.label }}
                        </label>
                        <div class="col-sm-5">
                            <input type="text"
                                    class="form-control"
                                    [formControl]="nameFormControl"
                                    maxlength="100"
                                    required />
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isViewing">
                    <div class="row">
						<label class="col-sm-2 control-label">
							{{ descriptionFormControl.label }}
						</label>
						<div class="col-sm-10">
							<span class="control-value">{{ descriptionFormControl.value }}</span>
						</div>
					</div>
                </ng-container>
                <ng-container *ngIf="!isViewing">
                    <div class="row form-group" has-error>
                        <label class="col-sm-2 control-label required">
                            {{ descriptionFormControl.label }}
                        </label>
                        <div class="col-sm-10">
                            <textarea class="form-control"
                                    [formControl]="descriptionFormControl"
                                    rows="2"
                                    maxlength="2000"
                                    required>
                            </textarea>
                        </div>
                    </div>
                </ng-container>

				<div class="row form-group" *ngIf="isViewing">
					<label class="col-sm-2 control-label">
						{{expirationDaysFormControl.label}}
					</label>
					<div class="col-sm-5">
						<span class="control-value">
							{{(expirationDaysFormControl.value | i18nPlural: {
							'=1': '1 day',
							'other': '# days'
						}) || '&ndash;'}}
						</span>
					</div>
				</div>

				<div class="row form-group" has-error *ngIf="!isViewing">
					<label class="col-sm-2 control-label required">
						{{expirationDaysFormControl.label}}
					</label>
					<div class="col-sm-5">
						<input
							type="text"
							class="form-control"
							style="display: inline-block; width: 50px;"
							formControlName="expirationDays"
							required
						/>
						&nbsp;days
					</div>
				</div>
			</div>
		</section>

        <ng-container *ngIf="isViewing">
			<section *ngIf="templateNameFormControl.value">
				<h3>Template</h3>
				<div class="document-template">
                    <div class="front-face flex-row gap">
						<a [href]="templateDownloadLink" target="_blank" class="title flex">
					        <span class="c-icon c-icon-file text-muted"></span>
                            {{ templateNameFormControl.value }}
                        </a>
                    </div>
					<!-- <span class="c-icon c-icon-file"></span>
					<div class="file-name flex">
						<a [href]="templateDownloadLink" target="_blank">{{ templateNameFormControl.value }}</a>
					</div> -->
				</div>
			</section>
        </ng-container>
        <ng-container *ngIf="!isViewing">
            <section>
                <h3>Template</h3>
                <div class="document-template" (fileDrop)=setTemplate($event)>
                    <div class="front-face">
                        <ng-container *ngIf="templateNameFormControl.value; else noFile">
                            <div class="flex-row gap">
                                <span class="title flex">
                                    <span class="c-icon c-icon-file text-muted"></span>
                                    {{ templateNameFormControl.value }}
                                </span>
                                <button type="button" class="btn btn-tertiary btn-icon" (click)="setTemplate(null)">
                                    <span class="glyphicon glyphicon-trash"></span>
                                </button>
                                <ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Replace' }"></ng-container>
                            </div>
                        </ng-container>
                        <ng-template #noFile>
                            <div class="flex-row center gap">
                                <ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Upload File' }"></ng-container>
                                <span class="text-muted">or Drag and Drop here</span>
                            </div>
                        </ng-template>
                        <ng-template #fileInput let-buttonText="buttonText">
                            <label class="file-input">
                                <span class="btn btn-primary">{{ buttonText }}</span>
                                <input type="file" accept=".doc, .docx, .xls, .xlsx, .pdf" (input)="setTemplate($event.target.files)" [multiple]="false" />
                            </label>
                        </ng-template>
                    </div>
                </div>
            </section>
        </ng-container>

		<section *ngIf="!isViewing" class="flex-row">
			<div class="right">
				<button type="submit" class="btn btn-primary">Save</button>
				<button type="button" class="btn btn-default" (click)="cancelEdit()">Cancel</button>
			</div>
		</section>
	</form>
    </div>
</div>
