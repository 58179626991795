import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { SCScheduleService } from 'tpo/social-compliance/schedule-old';
import { MonitoringSettingsResourceService } from 'tpo/status/monitoring-settings-resource.service';
import { SocialComplianceOrderPaymentFailedIssueStatus } from 'tpo/status/social-media/social-compliance/social-compliance-order-payment-failed-issue-status';

interface SettingSection {
	name: string;
	icon: string;
	alert?: Observable<string>;
	description: string;
	enabled: boolean;
	beta?: boolean;
	sref: string;
}

const SCHEDULE_SECTION: SettingSection = {
	name: 'Monitoring Schedule',
	icon: 'c-icon c-icon-calendar-empty',
	description: 'Customize your social media monitoring schedule to automate your review process',
	enabled: false,
	sref: 'social-compliance.compliance-schedule',
};

// const MONITORING_SECTION: SettingSection = {
// 	name: 'Real-Time Alerts Settings',
// 	icon: 'c-icon c-icon-globe',
// 	description: 'Customize real-time alert controls for company and individual social media accounts',
// 	enabled: false,
// 	beta: false,
// 	sref: 'social-compliance.monitoring-settings.company',
// };

@Component({
	templateUrl: './sc-settings.component.html',
})
export class ScSettingsComponent {
	User: UserProfile;
	hasViewAllPermission: boolean;
	settingSections: SettingSection[];
	helpLink = '/help/authenticated/tpo/smc/settings';
	resolved = {
		schedule: false,
		monitoring: true,
	};

	constructor(
		userService: UserService,
		public scScheduleService: SCScheduleService,
		public stateService: StateService,
		public monitoringSettingsService: MonitoringSettingsResourceService,
        public readonly socialComplianceOrderPaymentFailedIssueStatus: SocialComplianceOrderPaymentFailedIssueStatus,
	) {
		this.User = userService.profile;
		this.hasViewAllPermission = this.User.can('TPO_SMC_VIEW_ALL');

		if (this.hasViewAllPermission) {
			this.init();
		}
	}

	init() {
		this.settingSections = [
			SCHEDULE_SECTION,
			// MONITORING_SECTION,
			{
				name: 'Keyword Triggers',
				icon: 'glyphicon glyphicon-flag',
				description: 'Manage Keywords which you want to track to initiate flagged posts',
				enabled: null,
				beta: false,
				sref: 'social-compliance.triggers',
			},
			{
				name: 'Audit Checklist',
				icon: 'c-icon c-icon-checklist',
				description: 'Customize your audit checklist for your social media audits',
				enabled: null,
				beta: false,
				sref: 'social-compliance.audit-checklist.regulations',
			},
			{
				name: 'Orders',
				icon: 'c-icon c-icon-assignment',
				alert: this.socialComplianceOrderPaymentFailedIssueStatus.get(false)
					.pipe(map((orderPaymentAlert: boolean) => orderPaymentAlert ? 'Payment failed' : null)),
				description: 'View and track your social media audit orders',
				enabled: null,
				beta: false,
				sref: 'social-compliance.orders',
			},
		];

		this.scScheduleService.state(({auditSchedule}) => {
			this.resolved.schedule = true;
			SCHEDULE_SECTION.enabled = auditSchedule.enabled;
		});

		// this.monitoringSettingsService.settings.get(({isActive}) => {
		// 	this.resolved.monitoring = true;
		// 	MONITORING_SECTION.enabled = isActive;
		// });
	}

	settingClick({sref}) {
		this.stateService.go(sref);
	}
}
