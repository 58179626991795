import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults, some, isEmpty } from 'lodash';

import { RelationStatusColors } from 'lender/relation-status-colors';
import { Transitions, TransitionToChange } from '../../clients.interface';


@Component({
	templateUrl: './relationship-status-modal.component.html',
})
export class RelationshipStatusModalComponent {
	transitions: Transitions;
	selectedClientsIdsByStatuses: Record<string, number[]>;
	onSave: (transitionsToChange: TransitionToChange[]) => any;
	onCancel: (params?) => any;
	resolving: boolean;
	selectedStatusesToChange: Record<string, string> = {};
	relationStatusColors = RelationStatusColors;
	isConfirmed: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: false,
		});
	}

	isTerminatedSelected() {
		return some(this.selectedStatusesToChange, (status) => status === 'T');
	}

	isSaveDisabled() {
		return this.isTerminatedSelected() && !this.isConfirmed || isEmpty(this.selectedStatusesToChange);
	}

	save() {
		const transitionsToChange: TransitionToChange[] = [];
		for (const key in this.selectedStatusesToChange) {
			transitionsToChange.push({
				tpoIds: this.selectedClientsIdsByStatuses[key],
				fromStatus: { id: key },
				toStatus: { id: this.selectedStatusesToChange[key] },
			});
		}

		this.onSave(transitionsToChange);
	}

	clearSelectedStatus(key: string) {
		delete this.selectedStatusesToChange[key];
	}
}
