<h3>
    Financials
    <span class="sm-help-link">
        <a class="btn btn-tertiary" target="_blank" href="{{helpLink}}">
            <i class="glyphicon glyphicon-question-sign"></i> Help
        </a>
    </span>
</h3>

<tpo-financial-document-list
        *ngIf="tpoId"
        [tpoId]="tpoId"
        [investorId]="investorId"
        [relationshipStatus]="companyInformation?.relationshipStatus"
>
</tpo-financial-document-list>
