import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RealmFormGroup } from 'commons/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { RequestableDocumentService } from 'lender/clients/$id/documents/client-documents.service';
import { DocumentContactsEntry, IDocumentLenderContactsEntry } from 'lender/clients/$id/documents/client-documents.interface';
import { requestMessageCharsAmount } from 'lender/clients/$id/documents/common/request-document-modal/request-document-modal.form';

@Component({
    selector: 'notification-users-form',
    templateUrl: './notification-users-form.component.html',
})
export class NotificationUsersFormComponent implements OnInit, OnDestroy {
    @Input('form') assignForm: RealmFormGroup;
    @Input() service: RequestableDocumentService;
    @Input() params: Record<string, unknown>;

    messageCharsAmount = requestMessageCharsAmount;
    destroyed$ = new Subject<void>();
    contacts: IDocumentLenderContactsEntry[] = [];
    cc: DocumentContactsEntry[] = [];
    resolved = false;

    ngOnInit(): void {
        this.loadResources();

        this.assignForm.get('_notify_upon_assignment').valueChanges
            .pipe(
                startWith(true),
                takeUntil(this.destroyed$),
            )
            .subscribe((notifyUponCompletion) => this.enableControls([
                this.assignForm.get('message'),
                this.assignForm.get('toClients'),
            ], notifyUponCompletion));

        this.assignForm.get('_notify_upon_completion').valueChanges
            .pipe(
                startWith(true),
                takeUntil(this.destroyed$),
            )
            .subscribe((notifyUponCompletion) => this.enableControls([
                this.assignForm.get('notifyUponCompletionRealmUserIds'),
            ], notifyUponCompletion));
    }

    protected enableControls(controls: AbstractControl[], enabled: boolean): void {
        controls.forEach(control => {
            enabled ? control.enable() : control.disable();
            control.updateValueAndValidity();
        });
    }

    async loadResources(): Promise<void> {
        const params = this.params as { tpoId, lenderId };
        const tpoUsers$ = this.service.getTpoNotificationUsers(params);
        const lenderUsers$ = this.service.getLenderNotificationUsers(params);

        try {
            const [contacts, cc] = await Promise.all([
                tpoUsers$.toPromise(),
                lenderUsers$.toPromise(),
            ]);

            // need to create value for ng-select, since it does not support calc values
            this.cc = cc.map((entry) => ({ ...entry, fullName: `${entry.firstName} ${entry.lastName}` }));
            this.contacts = contacts.map((entry) => ({ ...entry, fullName: `${entry.firstName} ${entry.lastName}` }));

        } catch (e) {
            this.assignForm.setServerError({
                message: 'An error happened on data load. Please, try to reopen this modal',
            });
        }

        this.resolved = true;
    }

    getCharsCount = (): number => Math.max(0, (requestMessageCharsAmount - (this.assignForm.value?.message?.length ?? 0)));

    ngOnDestroy(): void {
        this.destroyed$.complete();
    }
}
