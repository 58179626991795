<div class="entity-fields-details">
	<back-bread class="gray-head flex-head" [class.mb15]="!isNew">
		{{isNew ? 'Add Custom Field' : field.fieldName}}
	</back-bread>

	<div class="clearfix mb15" *ngIf="!isNew">
		<span class="pull-right" *ngIf="!editMode">
			<button type="button" class="btn btn-secondary btn-icon" (click)="remove()">
				<span class="glyphicon glyphicon-trash"></span>
			</button>
			<button type="button" class="btn btn-primary" (click)="edit()">
				<span class="glyphicon glyphicon-pencil"></span> Edit
			</button>
		</span>
		<span class="pull-right" *ngIf="editMode">
			<button class="btn btn-primary" type="submit" form="fieldForm">Save</button>
			<button class="btn btn-secondary" (click)="cancelEdit()">Cancel</button>
		</span>

		<div class="pull-left" [class.curtain]="editMode">
			<toggle
				[ngModel]="field.visible"
				[loading]="!field.$resolved"
				(toggle)="toggleVisibility()"
			></toggle>&nbsp;Visible
		</div>
	</div>

	<form
		class="remote-resource"
		[formGroup]="fieldForm"
		name="fieldForm"
		id="fieldForm"
		[class.resolved]="isNew ? types.$resolved : field.$resolved"
		(onValidSubmit)="isNew ? add() : save()"
	>

		<div class="rows-bordered" *ngIf="isNew && step == 1">
			<div class="row-fluid header">
				<span class="col-md-3">Type</span>
				<span class="col-md-a">Description</span>
			</div>
			<div class="row-fluid clickable" *ngFor="let type of types" (click)="radio.click()">
				<span class="col-md-3 va-m">
					<label class="text-normal radio-inline">
						<input
							#radio type="radio"
							formControlName="controlTypeId"
							[value]="type.shortName"
							(change)="this.field.controlTypeId = type.shortName"
						>
						&nbsp;<span class="field-icon field-icon-{{type.shortName}}"></span>
						{{type.name}}
					</label>
				</span>
				<span class="col-md-a va-m">{{typesConfig[type.shortName].description}}</span>
			</div>
		</div>

		<div *ngIf="step === 2">
			<form-errors [form]="fieldForm"></form-errors>

			<div class="rows-striped">
				<div class="row-fluid">
					<label class="col-md-2 control-label">Field Type</label>
					<div class="col-md-a control-value">
						<span class="field-icon field-icon-{{field.controlTypeId}}"></span>
						{{typesConfig[field.controlTypeId]?.name}}
					</div>
				</div>

				<div class="row-fluid" [class.has-error]="hasError('fieldName')">
					<label class="col-md-2 control-label" [class.required]="editMode">Field Name</label>
					<div class="col-md-6">
						<!--@Notice: has-error directive doesn't work correct with editable-component-->
						<editable-component [editable]="editMode" [view]="field.fieldName">
							<ng-template #editTemplate>
								<input
									formControlName="fieldName"
									type="text"
									class="form-control"
									(input)="field.fieldName = getInputValue($event)"
									[maxlength]="maxNameLength"
								>
								<span class="text-smaller text-muted">
									Characters left: {{maxNameLength - (field.fieldName?.length || 0)}}
								</span>
							</ng-template>
						</editable-component>
					</div>
					<div class="col-md-a"></div>
				</div>

				<div class="row-fluid" [class.has-error]="hasError('fieldDescription')">
					<label class="col-md-2 control-label">Field Description</label>
					<div class="col-md-6">
						<editable-component [editable]="editMode" [view]="field.fieldDescription || '&ndash;'">
							<ng-template #editTemplate>
								<textarea
									formControlName="fieldDescription"
									type="text"
									class="form-control resize-vertical"
									rows="4"
									(input)="field.fieldDescription = getInputValue($event)"
									[maxlength]="maxDescriptionLength"
								></textarea>
								<span class="text-smaller text-muted">
									Characters left: {{maxDescriptionLength - (field.fieldDescription?.length || 0)}}
								</span>
							</ng-template>
						</editable-component>
					</div>
					<div class="col-md-a"></div>
				</div>

				<div class="row-fluid" [class.has-error]="hasError('helpText')">
					<label class="col-md-2 control-label">Help Text</label>
					<div class="col-md-6">
						<editable-component [editable]="editMode" [view]="field.helpText || '&ndash;'">
							<ng-template #editTemplate>
								<textarea
									formControlName="helpText"
									type="text"
									class="form-control resize-vertical"
									rows="4"
									(input)="field.helpText = getInputValue($event)"
									[maxlength]="maxHelpLength"
								></textarea>
								<span class="text-smaller text-muted">
									Characters left: {{maxHelpLength - (field.helpText?.length || 0)}}
								</span>
							</ng-template>
						</editable-component>
					</div>
					<div class="col-md-a"></div>
				</div>

<!--				<div *ngIf="typesConfig[field.controlTypeId]?.placeholder" class="row-fluid">-->
<!--					<label class="col-md-2 control-label">Placeholder</label>-->
<!--					<div class="col-md-a control-value">-->
<!--						{{typesConfig[field.controlTypeId].placeholder}}-->
<!--					</div>-->
<!--				</div>-->

				<div
					class="row-fluid"
					*ngIf="isArrayTypeField && !editMode"
				>
					<label class="col-md-2 control-label" [class.required]="editMode">Field Value</label>
					<div class="col-md-6">
						<ul class="control-value pretty">
							<li *ngFor="let value of field.valuesList">{{value}}</li>
						</ul>
					</div>
					<div class="col-md-a"></div>
				</div>

				<div class="row-fluid" *ngIf="field.controlTypeId === 'YesNo'">
					<label class="col-md-2 control-label">Field Value</label>
					<div class="col-md-6">
						<ul class="control-value pretty">
							<li *ngFor="let value of typesConfig[field.controlTypeId].options">
								{{value}}
							</li>
						</ul>
					</div>
					<div class="col-md-a"></div>
				</div>

				<div class="row-fluid" [class.has-error]="hasError('required')">
					<label class="col-md-2 control-label" [class.required]="isNew || editMode">
						Required Field
					</label>
					<div class="col-md-6">
						<editable-component [editable]="editMode" [view]="field.required ? 'Yes' : 'No'">
							<ng-template #editTemplate>
								<div class="control-value">
									<label class="text-normal radio-inline">
										<input
											type="radio"
											formControlName="required"
											[value]="true"
											(change)="field.required = true"
										> Yes
									</label>

									<label class="text-normal radio-inline">
										<input
											type="radio"
											formControlName="required"
											[value]="false"
											(change)="field.required = false"
										> No
									</label>
								</div>
							</ng-template>
						</editable-component>
					</div>
					<div class="col-md-a"></div>
				</div>

				<div class="row-fluid" [class.has-error]="hasError('groupId')">
					<label class="col-md-2 control-label">Group</label>
					<div class="col-md-6">
						<editable-component [editable]="editMode" [view]="groupName || '&ndash;'">
							<ng-template #editTemplate>
								<ng-select
									formControlName="groupId"
									[items]="groups.$promise | async"
									[loading]="!groups.$resolved"
									(change)="field.groupId = $event?.id"
									bindValue="id"
									bindLabel="name"
									placeholder="Select Group">
								</ng-select>
							</ng-template>
						</editable-component>
					</div>
					<div class="col-md-a"></div>
				</div>
			</div>

			<div
				*ngIf="isArrayTypeField && editMode"
				class="rows-bordered"
			>
				<h3 class="section-name">Values</h3>

				<div class="row-fluid header">
					<span class="col-md-9">Value</span>
					<span class="col-md-3">Order</span>
				</div>

				<div class="row-fluid custom-field-values" formArrayName="valuesList">
					<div
						class="row form-group"
						*ngFor="let v of valuesList.value; index as i; first as isFirst; last as isLast; trackBy: trackByIndex"
						has-error
					>
						<div class="col-md-9">
							<div class="col-md-6 name-holder">
								<input
									[formControlName]="i"
									type="text"
									class="form-control"
									maxlength="25"
									(input)="changeValueListInput(i, $event)"
								>
							</div>
							<button
								*ngIf="isLast && field.valuesList.length < 25"
								type="button" class="btn btn-secondary btn-icon"
								(click)="addNewValue()"
								tabindex="-1"
							>
								<span class="glyphicon glyphicon-plus small-icon"></span>
							</button>
							<button
								*ngIf="field.valuesList.length > 1"
								class="btn btn-tertiary btn-icon"
								(click)="removeItem(i)"
								type="button"
								tabindex="-1"
							>
								<span class="glyphicon glyphicon-trash"></span>
							</button>
						</div>
						<div class="col-md-3">
							<div class="common-order-actions arrows">
								<button
									class="btn btn-tertiary btn-icon btn-ml2"
									[class.hide-by-default]="isFirst"
									(click)="move(i, -1)"
									type="button"
									tabindex="-1"
								>
									<span class="c-icon c-icon-arrow-down icon-reverse text-bigger"></span>
								</button>

								<button
									class="btn btn-tertiary btn-icon btn-ml2"
									[class.hide-by-default]="isLast"
									(click)="move(i, +1)"
									type="button"
									tabindex="-1"
								>
									<span class="c-icon c-icon-arrow-down text-bigger"></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="text-right" *ngIf="isNew && step == 1">
			<br>
			<a (click)="next()" class="btn btn-primary" [aDisabled]="!field.controlTypeId">Next</a>
			<a uiSref="^.^" class="btn btn-default">Cancel</a>
		</div>

		<div class="text-right" *ngIf="isNew && step == 2">
			<br>
			<input class="btn btn-primary" type="submit" value="Save">
			<a (click)="back()" class="btn btn-default">Back</a>
		</div>
	</form>

</div>
