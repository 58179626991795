<div class="gray-head flex-row gap no-margin">
	<h2>{{ title }}</h2>&nbsp;
	<market-search></market-search>
</div>
<ng-container *ngIf="hasSrefParams">
	<br>
	<div class="row-fluid">
		<div class="col-md-12">

			<div class="pull-left">
				<ul class="inline-toggle normalize no-margin">
					<ng-container *ngFor="let tab of tabs">
						<li *ngxInit="getCurrentTab(tab) as isActive" [class.active]="isActive">
							<a
								[class.text-white]="isActive"
								[uiSref]="tab.sref"
								[uiParams]="tab.params"
								[aDisabled]="isActive"
							>
								{{tab.title}}
							</a>
						</li>
					</ng-container>
				</ul>
			</div>

			<div class="pull-right">
				<a class="text-link underlined text-bigger" uiSref="crm.market.map" [uiParams]="mapSrefParams">
					<span class="glyphicon glyphicon-map-marker"></span>
					<span class="text">View on Map</span>
				</a>
			</div>
		</div>
	</div>
	<br>
</ng-container>

<ui-view></ui-view>