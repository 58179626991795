import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { NgResourceObject, NgResourceArray } from 'commons/interfaces';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { ListParams } from 'commons/components/new-list/list.component';

declare let apiPath: string;

export interface TriggerHistory {
	action: string;
	actionBy: string;
	actionById: number;
	date: number;
	newValue: string;
	oldValue: string;
}

export interface Trigger {
	id?: number|string;
	name: string;
	andExpressions: string[][];
	isDefault?: boolean;
	status?: {
		code: 'A'|'I';
	};
}

@Injectable({
	providedIn: 'root',
})
export class TriggersService {
	private triggers: any;
	private baseUrl: any;
	private User: UserProfile;

	constructor(
		protected http: RealmHttpClient,
		{ profile }: UserService,
		injector: Injector,
	) {
		// const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		this.User = profile;

		this.baseUrl = `${apiPath}${this.User.isTpo ? '/tpos/:tpoId/social-media-compliance/trigger-rule/:triggerId' : '/sm-default-trigger-rule/:triggerId'}`;
		const baseParams = {
			...(this.User.isTpo && { tpoId: this.User.organization.id }),
		};

		this.triggers = PagedResource(
			this.baseUrl,
			baseParams,
			{
				list: {
					method: 'GET',
					isArray: true,
				},
				get: {
					method: 'GET',
				},
				save: {
					method: 'PUT',
				},
				activate: {
					url: `${this.baseUrl}/active`,
					method: 'PUT',
				},
				deactivate: {
					url: `${this.baseUrl}/inactive`,
					method: 'PUT',
				},
				add: {
					method: 'POST',
				},
				clone: {
					url: `${this.baseUrl}/clone`,
					method: 'POST',
				},
				history: {
					url: `${this.baseUrl}/history`,
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	list = (...args): NgResourceArray<Trigger> => this.triggers.list(...args);
	get = (...args): NgResourceObject<Trigger> => this.triggers.get(...args);
	save = (...args): NgResourceObject<Trigger> => this.triggers.save(...args);
	add = (...args): NgResourceObject<Trigger> => this.triggers.add(...args);
	clone = (...args): NgResourceObject<Trigger> => this.triggers.clone(...args);
	activate = (...args): NgResourceObject<Trigger> => this.triggers.activate(...args);
	deactivate = (...args): NgResourceObject<Trigger> => this.triggers.deactivate(...args);
	history(triggerId: number, params: ListParams): Observable<PagedData<TriggerHistory[]>> {
		const processedBaseUrl = this.baseUrl
			.replace(':tpoId', this.User.organization.id)
			.replace(':triggerId', triggerId);

		return this.http.pagedRequest<TriggerHistory[]>('GET', `${processedBaseUrl}/history`, params);
	}
}
