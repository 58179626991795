import { Component, OnInit, Host, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { FindingComponent, IssueEditModalComponent } from '..';

@Component({
	selector: 'issue-list',
	templateUrl: './issue-list.component.html',
})
export class IssueListComponent implements OnInit, OnDestroy {
	User: any;
	modalRef: BsModalRef;
	list: any = [];
	finding: any;
	canManage: boolean = false;

	constructor(
		public stateService: StateService,
		public userService: UserService,
		@Host() public findingComponent: FindingComponent,
		public modalService: BsModalService,
	) {
		this.User = userService.profile;
	}

	ngOnInit() {
		this.finding = this.findingComponent.finding;
		this.loadList();
	}

	ngOnDestroy(): void {
		this.findingComponent.cancelEdit();
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	loadList() {
		this.list.$resolved = false;
		this.finding
			.$promise
			.then((data: any) => {
				this.enableEditing();
				this.list = data.issues;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	enableEditing() {
		const isMonitoringFinding = !this.finding.reviewId;
		const reviewFinding = this.finding.reviewId && this.finding.reviewAuditStatus.condition !== 'C';

		this.canManage = (
			(this.User.isComergence && this.User.can('CCM_SMC_VIEW_ALL'))
			||
			(this.User.isTpo && this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') && (isMonitoringFinding || reviewFinding))
		);

	}

	showEditModal(issue): void {
		const initialState = {
			issue,
			issueList: this,
		};

		this.modalRef = this.modalService.show(IssueEditModalComponent, { initialState, class: 'issue-edit-modal', backdrop: 'static', ignoreBackdropClick: true, keyboard: false, animated: false });
	}

	reloadList() {
		this.findingComponent.reloadFinding();
		this.loadList();
	}

	addIssue() {
		this.findingComponent.addIssue({});
	}
}
