import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

@Component({
    selector: 'new-sm-link',
    templateUrl: './new-sm-link.component.html',
})
export class NewSocialMediaLinkComponent {
    resolving = false;
    form = new RealmFormGroup({
        link: new RealmFormControl(
            'link',
            {
                label: 'Link',
                updateOn: 'change',
            },
            Validators.required,
        ),
    });

    constructor(private _cd: ChangeDetectorRef) {
    }

    get formControl(): RealmFormControl {
        return this.form.get('link') as RealmFormControl;
    }

    @Input() show = false;
    @Input() save: (link: string) => Promise<any>;

    toggleShow(show = !this.show): void {
        this.show = show;
        this.form.reset();
        this._cd.detectChanges();
    }

    submit() {
        const { form } = this;
        const { link } = form.value;

        this.resolving = true;
        this.save(link)
            .then(() => {
                this.toggleShow(false);
            })
            .catch(({ data, error }) => {
                form.setServerError(data || error);
            })
            .finally(() => {
                this.resolving = false;
                this._cd.detectChanges();
            });
    }
}
