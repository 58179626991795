<div class="remote-resource" [class.resolved]="resolved && isAttachmentsRendered">
	<div class="post-row">
		<div class="column profile-image">
			<i [style.background-image]="sanitize(profilePictureUrl)" class="profile-image profile"></i>
		</div>
		<div class="column profile-name">
			<a [aDisabled]="!webLink" href="{{webLink}}" target="_blank"><b>{{name}}</b></a>
			<div class="text-muted">Job Title at Company Name <br/>
				Age of the post</div>
		</div>
	</div>

	<div class="post-text" *ngIf="textTruncated | textToUrl: { regex: regLink }">
		<formatted-text [text]="textTruncated | textToUrl: { regex: regLink }"></formatted-text>
	</div>

	<div *ngIf="linkPreview?.url && !attachments?.length" class="link-preview">
		<a [href]="linkPreview?.url" target="_blank" class="link-section clearfix">
			<img *ngIf="linkPreview?.image" [src]="linkPreview?.image">
			<span class="text-holder">
				<span class="title">{{linkPreview?.title}}</span>
				<span class="link">{{linkPreview?.domain}}</span>
			</span>
		</a>
	</div><!--end: post-preview-->

	<publisher-attachments-preview
		[attachments]="attachments"
		[networkCode]="networkCode"
		(onIsRendered)="attachmentsLoaded($event)"
	></publisher-attachments-preview>

	<footer>
		<img src="/static/images/posts-preview/in.png" alt="linkedin">
	</footer>
</div>
