import { Component, forwardRef, OnInit } from '@angular/core';

import { ScorecardService } from '../services/scorecard.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import {
    ScorecardAssignedUser,
    ScorecardReview,
    ScorecardStatusTransition,
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-reviews.interface';


@Component({
    templateUrl: './scorecard-reviews-table.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardReviewsTableComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardReviewsTableComponent) },
    ],
})
export class ScorecardReviewsTableComponent extends NewPagedListComponent<ScorecardReview> implements OnInit {
    static listName = 'reviewsList';
    tpoId: number;

    statusTransitions: ScorecardStatusTransition[];
    userAssignees: ScorecardAssignedUser[];

    constructor(
        public uiRouter: UIRouter,
        private readonly scorecardService: ScorecardService,
    ) {
        super(uiRouter);
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.uiRouter.globals.params.id;
        this.defaultParams.tpoId = this.tpoId;
        await this.getStatusesAndUsers();
        super.ngOnInit();
    }

    async getStatusesAndUsers() {
        try {
            this.resolving = true;
            this.statusTransitions = await this.scorecardService.getStatusTransitions().toPromise();
            this.userAssignees = await this.scorecardService.getAssignedUsers().toPromise();
        } finally {
            this.resolving = false;
        }
    }

    loadList(params: ListParams): Observable<PagedData<ScorecardReview[]>> {
        return this.scorecardService.getScorecardReviews(params);
    }

    refreshPage() {
        this.updateList();
    }
}
