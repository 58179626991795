import { Validators } from '@angular/forms';

import { RealmFormGroup, RealmFormControl } from 'commons/forms';
import {
	DropdownRequiredValidator,
	MaxLengthValidator,
	FileRequiredValidator,
} from 'commons/validators';

export const NewFindingForm = new RealmFormGroup({
	mediaLinkId: new RealmFormControl(
		'mediaLinkId',
		{ label: 'mediaLinkId' },
	),
	uiId: new RealmFormControl(
		'uiId',
		{ label: 'Account' },
		DropdownRequiredValidator,
	),
	newSocialMediaLink: new RealmFormControl(
		'newSocialMediaLink',
		{
			label: 'Media Link',
			updateOn: 'change',
		},
		[
			MaxLengthValidator(2000),
			Validators.required,
		],
	),
	originalPostDate: new RealmFormControl(
		'originalPostDate',
		{ label: 'Date' },
	),
	additionalInformation: new RealmFormControl(
		'additionalInformation',
		{ label: 'Additional Information' },
		MaxLengthValidator(2000),
	),
	screenshot: new RealmFormControl(
		'screenshot',
		{ label: 'Screenshot' },
		FileRequiredValidator,
	),
});
