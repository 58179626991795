import { chunk, unzip } from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class ColumnSplitterService {
    constructor() {
    }

    splitIntoColumns<T>(items: T[], columnCount: number = 4): T[][] {
            const rows: T[][] = chunk(items, columnCount);
            const columns: T[][] = unzip(rows);
            //Note: unzip will pad out the columns with null values so that all columns are equal length
            return columns;
    }
}
