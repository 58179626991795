import { FindingComponent } from 'shared/findings';

import { EmptyContainerComponent } from 'commons/components';
import { PublicTaskListComponent } from 'tpo/social-compliance/tasks';

export default [
	{
		name: 'public',
		url: `/public/:accessToken`,
		component: EmptyContainerComponent,
		children: [
			{
				name: `.tasks`,
				url: `/tasks?${PublicTaskListComponent.listName}`,
				params: {
					[PublicTaskListComponent.listName]: {
						type: 'json',
						value: {},
						inherit: false,
					},
				},
				reloadOnSearch: false,
				views: {
					'@': {
						component: PublicTaskListComponent,
					},
				},
				children: [
					{
						name: '.:findingId',
						url: `/{findingId:int}`,
						params: {
							tab: null,
						},
						isFullPageView: true,
						views: {
							'@': {
								component: FindingComponent,
							},
						},
					},
				],
			},
		],
	},
];
