import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { from, Observable } from 'rxjs';

import { AbstractStatusService } from 'commons/services';

import { CompanyListingsService } from '../../company-listings.service';
import { map } from 'rxjs/operators';

@Injectable()
export class GroupAlertsStatusService extends AbstractStatusService<boolean> {
    constructor(
        private stateService: StateService,
        private companyListingsService: CompanyListingsService,
    ) {
        super();
    }

    get exclamation$(): Observable<boolean> {
        return super.getStatus$();
    }

    fetchStatus = (): Observable<boolean> => {
        const { listingGroupId } = this.stateService.params;
        return from(
            this.companyListingsService.groupAlertsExclamation({
                listingGroupId,
            }).$promise,
        ).pipe(
            map(({ exclamation }) => exclamation),
        );
    };

    public refresh() {
        super.refreshStatus();
    }
}
