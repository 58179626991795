import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SmTypes } from './connector.interface';

@Injectable()
export abstract class BaseConnectorAdapter {
    public onConnectFinish$ = new Subject<void>();
    protected windowParams = { width: 400, height: 600 };
    protected connectType: SmTypes;

	public abstract connect(entry?: unknown): void;
    public cleanUp(): void {}
}
