import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';

import { QuestionnairesListEntry } from './questionnaires.interface';

declare let apiPath: string;

export type QuestionnairesResource = any;

@Injectable()
export class QuestionnairesService {
	list: any;
	private filters: {
		questionnaires: any,
		statuses: any,
		investors: any,
	};
	assignableQuestionnaires: any;
	contacts: any;
	lenderContacts: any;

	constructor(
		injector: Injector,
		userService: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const User = userService.profile;

		this.list = PagedResource(
			`${apiPath}/tpos/questionnaires`,
			{},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				remove: {
					method: 'DELETE',
				},
				assign: {
					method: 'POST',
				},
				update: {
					url: `${apiPath}/tpos/questionnaires/assignment/:assignmentId`,
					method: 'PUT',
				},
				getAssignment: {
					method: 'GET',
					url: `${apiPath}/tpos/questionnaires/assignment/:assignmentId`,
				},
				getAllInProgress: {
					method: 'GET',
					url: `${apiPath}/tpos/questionnaires/all-in-progress`,
					isArray: true,
				},
			},
		);

		this.filters = {
			questionnaires: $resource(
				`${apiPath}/tpos/questionnaires/filters/questionnaires`,
				{},
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			investors: $resource(
				`${apiPath}/tpos/questionnaires/filters/lenders`,
				{},
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			statuses: $resource(
				`${apiPath}/tpos/questionnaires/filters/statuses`,
				{},
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
		};

		this.contacts = PagedResource(
			`${apiPath}/tpos/questionnaires/tpo-users`,
			{},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.assignableQuestionnaires = PagedResource(
			`${apiPath}/organizations/:organizationId/questionnaires/:questionnaireId`,
			{
				organizationId: User.organization.organizationId,
			}, {
				get: {
					method: 'GET',
					isArray: true,
					params: {
						questionnaireId: 'assignment-options',
					},
				},
			},
		);

		this.lenderContacts = $resource(
			`${apiPath}/tpos/questionnaires/lender-users`,
			{},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getQuestionnairesFilter = (...args): NgResourceArray<any> => this.filters.questionnaires.get(...args);
	getInvestorsFilter = (...args): NgResourceArray<any> => this.filters.investors.get(...args);
	getStatusesFilter = (...args): NgResourceArray<any> => this.filters.statuses.get(...args);
	getAllInProgress = (...args): NgResourceArray<QuestionnairesListEntry> => this.list.getAllInProgress(...args);
}
