import { forEach, find } from 'lodash';

// NOTICES:
// 1) constructors are skipped,
// 2) if your mixins have lifecycle hooks -> they overwrite target class methods, so better not to use it
// 3) you can't overwrite arrow functions, so saveHandlers, errorHandlers can't mixin
export function Mixin(baseClasses: any) {
	return (derivedClass: any) => {
		// this is used to make sure no mathods or props are owerlap in mixin classes
		const alreadyDefinedProps = [];

		const checkExistence = (name) => {
			const isUsedAlready = find(alreadyDefinedProps, (propName) => propName === name);

			if (isUsedAlready) {
				// tslint:disable-next-line:no-console
				console.warn(`utils/mixin.decorator: property "${name}" is already added by several mixins from your list, please check carefully if it breaks your logic`);
			}

			alreadyDefinedProps.push(name);
		};

		forEach(baseClasses, (baseClass) => {
            Object.getOwnPropertyNames(baseClass.prototype).forEach((name) => {
                if (name == 'constructor') return;
                checkExistence(name);

                Object.defineProperty(
                    derivedClass.prototype,
                    name,
                    Object.getOwnPropertyDescriptor(baseClass.prototype, name) || Object.create(null)
                );
            });
		});

	};
}
