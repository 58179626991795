import { NgModule } from '@angular/core';

import { NewContactAuditLogModule } from './audit-log';
import { NewContactSocialAccountsComponent } from 'shared/new-contacts/social-accounts/social-accounts.component';
import { NewContactSocialAccountsService } from 'shared/new-contacts/social-accounts/social-accounts.service';
import { NewContactWebPresenceComponent } from 'shared/new-contacts/web-presence/web-presence.component';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialAccountsModule } from 'tpo/social-accounts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RolesModule } from 'shared/roles/roles.module';

// Components
import { NewContactRemovedSocialAccountsComponent } from './social-accounts';
import { IndividualConnectComponent } from 'shared/new-contacts/social-accounts/connect/individual-connect.component';
import { SharedContactSettingsComponent } from 'shared/new-contacts/settings/contact-settings.component';
import { NewPublisherRequestsComponent } from './publisher-requests/new-publisher-requests.component';
import { GrantAccessModal } from 'shared/new-contacts/settings/grant-access.modal';
import { NewContactListsComponent } from './new-contact-lists/new-contact-lists.component';
import { NewContactsAuditsListComponent } from './compliance/audits/new-contacts-audits-list.component';
import { NewContactFindingsListComponent } from './compliance/findings/new-findings-list.component';
import { ContactRoleComponent } from 'shared/new-contacts/role/contact-role.component';
import { FindingSourceComponent } from 'shared/new-contacts/compliance/findings/finding-source/finding-source.component';
import { ReportingGroupPipe } from 'shared/new-contacts/reportingGroup.pipe';

@NgModule({
    imports: [
        NewContactAuditLogModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        SocialAccountsModule,
        BsDropdownModule.forRoot(),
        RolesModule,
    ],
    declarations: [
        NewContactSocialAccountsComponent,
        NewContactWebPresenceComponent,
        NewContactRemovedSocialAccountsComponent,
        IndividualConnectComponent,
        SharedContactSettingsComponent,
        NewPublisherRequestsComponent,
        NewContactListsComponent,
        NewContactsAuditsListComponent,
        NewContactFindingsListComponent,
        FindingSourceComponent,
        GrantAccessModal,
        ContactRoleComponent,
        ReportingGroupPipe,
    ],
    providers: [
        NewContactSocialAccountsService,
    ],
    exports: [
        ContactRoleComponent,
        FindingSourceComponent,
        ReportingGroupPipe,
    ],
})
export class NewContactsModule {}
