<ng-container
		*ngTemplateOutlet="isFormOpened() ?
		 replyFormTemplate || defaultReplyForm :
		 replyViewTemplate || defaultReplyView;
		 context:vewContext">
</ng-container>

<ng-template #defaultReplyView
			 let-openForm="methods.openForm"
			 let-permission="permission"
			 let-replyBtnText="replyBtnText">
	<button
		class="btn btn-default"
		[class.disabled]="!permission"
		(click)="permission && openForm()"
	>
		<span class="c-icon c-icon-message"></span> {{replyBtnText}}
	</button>
</ng-template>

<ng-template #defaultReplyForm
			 let-addReplyForm="addReplyForm"
			 let-replyBtnText="replyBtnText"
			 let-submitted="submitted"
			 let-isInvalid="isInvalid"
			 let-rows="rows"
			 let-charsAmount="charsAmount"
			 let-add="methods.add"
			 let-cancelEdit="methods.cancelEdit"
			 let-getCharsCount="methods.getCharsCount">
	<form [formGroup]="addReplyForm" id="addReplyForm" (onValidSubmit)="add()" autocomplete="off" role="presentation">
		<form-errors [form]="addReplyForm"></form-errors>

		<div class="mb15 remote-resource" [class.resolved]="!submitted">
			<div class="full-width mb10">
				<textarea
						class="form-control no-resize"
						formControlName="text"
						[rows]="rows"
						[maxlength]="charsAmount"
						placeholder="Type here">
				</textarea>
			</div>
			<div class="characters mb10 text-muted text-smaller">Characters: {{getCharsCount()}}</div>
			<div>
				<span class="btn btn-default" (click)="cancelEdit()">Cancel</span>

				<button [disabled]="isInvalid" type="submit" class="btn btn-primary">
					{{replyBtnText}}
				</button>
			</div>
		</div>

	</form>
</ng-template>


