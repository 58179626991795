import { Component, forwardRef, Optional } from '@angular/core';
import { UIRouterGlobals, UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedData } from 'commons/services/http';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';

import { ContactAuditLog } from './contact-audit-log.interface';
import { NewContactAuditLogService } from './contact-audit-log.service';
import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { takeUntil } from 'rxjs/operators';

interface Filters {
    type: {
        [key in 'value' | 'title']: string;
    };
    dateFrom: Date;
    dateTo: Date;
}

type RawParams = Partial<Pick<Filters, 'dateFrom' | 'dateTo'>> & {
    contactId: number;
    type?: string;
}

type Params = ListParams & Pick<RawParams, 'contactId' | 'type'> & {
    dateFrom?: number;
    dateTo?: number;
}

@Component({
    templateUrl: './contact-audit-log.component.html',
    viewProviders: [
        { provide: NewPagedListComponent, useExisting: forwardRef(() => NewContactAuditLogComponent) },
    ],
})
export class NewContactAuditLogComponent extends NewPagedListComponent<ContactAuditLog> {
    static listName = 'newContactAuditLog';

    private details: SharedContactDetailsComponent;
    filters: Partial<Filters> = {};

    constructor(
        @Optional() tpoDetails: TpoContactDetailsComponent,
        @Optional() investorDetails: InvestorContactDetailsComponent,
        @Optional() ccmDetails: CcmContactDetailsComponent,
        router: UIRouter,
        { params }: UIRouterGlobals,
        { profile }: UserService,
        private auditLogService: NewContactAuditLogService,
    ) {
        super(router);

        this.details = ccmDetails || investorDetails || tpoDetails;

        const { contactId } = params;
        const {
            isLender,
        } = profile;
        const now = new Date();

        Object.assign(
            this.filters,
            {
                type: !isLender
                    ? [
                        { value: '', title: 'All Types' },
                        { value: 'MU', title: 'Actions' },
                        { value: 'LG', title: 'Login History' },
                    ]
                    : null,
            }
        );

        Object.assign(
            this.defaultParams,
            {
                type: '',
                contactId,
                dateFrom: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 10),
                dateTo: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
            }
        );
    }

    ngOnInit() {
        this.resolving = true;
        this.details.company.pipe(
            takeUntil(this.destroy$),
        ).subscribe(({ organizationId }) => {
            this.defaultParams.organizationId = organizationId;
            super.ngOnInit();
        });
    }
    
    processParams({
        dateFrom,
        dateTo,
        ...rest
    }: RawParams): Params {
        return {
            ...rest,
            ...(dateFrom && { dateFrom: dateFrom?.getTime() }),
            ...(dateTo && { dateTo: dateTo?.getTime() }),
        };
    }

    loadList(params: RawParams): Observable<PagedData<ContactAuditLog[]>> {
        return this.auditLogService.getList(
            this.processParams(params)
        );
    }
}
