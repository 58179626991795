<div class="modal-header">
	<button
			type="button"
			class="close pull-right"
			aria-label="Close"
			(click)="modalRef.hide()"
	>&times;</button>
	<h4 class="modal-title">Request Document</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="snapshotData.$resolved">
	<div>
		<b class="inline-mb-6">Notify</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.toClient}}</span><br>
		<div class="fake-select text-smaller" *ngIf="snapshotData?.toTpoUsers?.length; else dash">
			<span *ngFor="let user of snapshotData.toTpoUsers">{{user.name}}</span>
		</div>
	</div>
	<br>
	<div>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.ccInternal}}</span><br>
		<div class="fake-select text-smaller">
			<ng-container *ngIf="snapshotData?.ccLenderUsers?.length; else dash">
				<span *ngFor="let user of snapshotData.ccLenderUsers">{{user.name}}</span>
			</ng-container>
		</div>
	</div>
	<br>
	<div class="message">
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.message}}</span><br>
		<div *ngIf="snapshotData?.message as msg; else dash" [innerHTML]="msg+'<br>'"></div>
	</div>
	<br>
	<div>
		<b class="inline-mb-6">{{snapshotTitles.notifyCompletion}}</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.toInternal}}</span><br>
		<div class="fake-select text-smaller" *ngIf="snapshotData?.notifyUponCompletionLenderUsers?.length; else dash">
			<span *ngFor="let user of snapshotData.notifyUponCompletionLenderUsers">{{user.name}}</span>
		</div>
	</div>
	<br>
	<ng-template #dash><div class="text-medium">&ndash;</div></ng-template>
</div>
<div class="modal-footer">
	<div>
		<button type="button" (click)="modalRef.hide()" class="btn btn-primary">
			Close
		</button>
	</div>
</div>
