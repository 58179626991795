import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient } from 'commons/services/http';

import { GenericHistoryEntry } from 'commons/components/history/generic-history-entry.interface';


declare let apiPath: string;

@Injectable()
export class FinancialsManagementHistoryService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getFinancialsManagementHistory(
        investorId: number,
        documentTypeId: number,
        listParam,
    ): Observable<PagedData<GenericHistoryEntry[]>> {
        return this.http.pagedRequest<GenericHistoryEntry[]>(
            'GET',
            `${apiPath}/lenders/${investorId}/financial-documents/${documentTypeId}/management-history`,
            listParam
        );
    }
}
