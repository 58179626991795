import {
	Document,
	HistoryDocument,
} from 'shared/documents';

export interface GlobalDocument extends Document {
	isActive?: boolean;
}

export interface Channel {
	id: number;
	name: string;
	$requested?: boolean;
}

export interface ChannelResource {
	channelId: number;
	channelName: string;
}

export interface GlobalDocumentDetails<C extends Channel | ChannelResource = Channel> extends GlobalDocument {
	templateName?: string;
	channelsSettings: C[];
}

export interface GlobalDocumentDetailsFormData extends GlobalDocumentDetails {
	templateFile: File;
}

export interface GlobalHistoryDocument extends HistoryDocument {
	documentId: number;
	libraryId?: number
	historyId: number;
}

export interface Questionnaire {
	name: string;
	description: string;
	assignedFor: string;
	id: number;
	questionsNumber: number;
}

export interface DocuSignTemplate {
	description: string;
	name: string;
	templateId: string;
	valid: boolean;
}

export enum DocuSignStatuses {
	CONNECTED = 'C',
	DISCONNECTED = 'D',
}

export interface DocuSignStatus {
	accountName?: string;
	status?: DocuSignStatuses;
	userEmail?: string;
	userName?: string;
}
