import { Injectable, Injector } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { PublisherRequest, PublisherRequestCount } from './publisher-request.interface';

declare let apiPath: string;

export interface IPermissionsResourceService {
	links: any;
	permissions: any;
	noPermissions: any;
	requests?: any;

    listRequests?(): Observable<PublisherRequest[]>;
    getRequestCount?(): Observable<PublisherRequestCount>;
}

@Injectable()
export class PublishingPermissionsResourceService {
	company: IPermissionsResourceService;
	individual: IPermissionsResourceService;

	constructor(
		public injector: Injector,
        private http: RealmHttpClient,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const paths = {
			company: {
				base: `${apiPath}/tpos/:tpoId/digital-media-team-access`,
			} as {
				base: string;
				links: string;
				permissions: string;
				noPermissions: string;
			},
			individual: {
				base: `${apiPath}/social-media`,
			} as {
				base: string;
				links: string;
				permissions: string;
				noPermissions: string;
				requests: string;
			},
		};

		paths.company.links = `${paths.company.base}/links/:linkId`;
		paths.company.permissions = `${paths.company.links}/granted-users`;
		paths.company.noPermissions = `${paths.company.links}/non-granted-users`;

		paths.individual.links = `${paths.individual.base}/links/:idType/:id/:linkId`;
		paths.individual.permissions = `${paths.individual.links}/publisher`;
		paths.individual.noPermissions = `${paths.individual.links}/non-publisher`;
		paths.individual.requests = `${paths.individual.base}/publisherRequest`;

		const params = {
			company: {
				tpoId: '@tpoId',
				linkId: '@linkId',
			},
			individual: {
				tpoId: '@tpoId',
				idType: 'CONTACTID',
				id: '@id',
				linkId: '@linkId',
			},
		};

		this.company = {
			links: $resource(paths.company.links, params.company,
				{
					get: {
						method: 'GET',
					},
					create: {
						url: paths.company.base,
						method: 'POST',
					},
				},
			),
			permissions: PagedResource(paths.company.permissions, params.company,
				{
					list: {
						method: 'GET',
						isArray: true,
					},
					update: {
						method: 'PUT',
					},
					removeCurrent: {
						url: `${paths.company.permissions}/current`,
						method: 'DELETE',
					},
					remove: {
						url: `${paths.company.permissions}/:userId`,
						method: 'DELETE',
					},
				},
			),
			noPermissions: $resource(paths.company.noPermissions, params.company,
				{
					list: {
						method: 'GET',
						isArray: true,
					},
				},
			),
		};

		this.individual = {
			links: $resource(paths.individual.links, params.individual,
				{
					get: {
						url: `${paths.individual.links}/publisher/link-info`,
						method: 'GET',
					},
					create: {
						url: `${paths.individual.links}/publishers-list`,
						method: 'POST',
					},
				},
			),
			permissions: PagedResource(paths.individual.permissions, params.individual,
				{
					list: {
						method: 'GET',
						isArray: true,
					},
					update: {
						method: 'PUT',
					},
					removeCurrent: {
						url: `${paths.individual.permissions}/current`,
						method: 'DELETE',
					},
					remove: {
						url: `${paths.individual.permissions}/:userId`,
						method: 'DELETE',
					},
				},
			),
			noPermissions: $resource(paths.individual.noPermissions, params.individual,
				{
					list: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			requests: $resource(paths.individual.requests, {},
				{
					list: {
						method: 'GET',
						isArray: true,
					},
					count: {
						url: `${paths.individual.requests}Count`,
						method: 'GET',
					},
					add: {
						method: 'PUT',
						url: `${paths.individual.links}/publisherRequest`,
						params:  params.individual,
					},
					cancel: {
						method: 'PUT',
						url: `${paths.individual.links}/cancelPublisherRequest`,
						params:  params.individual,
					},
					accept: {
						method: 'PUT',
						url: `${paths.individual.requests}/:id/accept`,
						params: {id: '@id'},
					},
					decline: {
						method: 'PUT',
						url: `${paths.individual.requests}/:id/decline`,
						params: {id: '@id'},
					},
				},
			),
            listRequests: (): Observable<PublisherRequest[]> => {
                return this.http.request<PublisherRequest[]>(
                    'GET',
                    `${paths.individual.requests}`,
                );
            },
            getRequestCount: (): Observable<PublisherRequestCount> => {
                return this.http.request<PublisherRequestCount>(
                    'GET',
                    `${paths.individual.requests}Count`,
                );
            },
		};
	}
}
