import { RealmFormArray } from 'commons/forms';
import { ContactListField, ContactListOption, ContactListSegmentFilterAndClause, FilterOperationFieldType } from '../../../investor-crm-contact-list.interface';
import { AndClauseFormGroup } from './AndClauseFormGroup';


export class OrClauseFormArray extends RealmFormArray {
    constructor(addDefaultAndClause: boolean) {
        super([]);

        if (addDefaultAndClause) {
            this.addAndClause();
        }
    }

    get andClauseCount(): number {
        return this.length;
    }

    createAndClauseFrom(
        andClauseDto: ContactListSegmentFilterAndClause,
        fieldTypesById: Map<string, ContactListOption>,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>,
    ): void {
        const andClauseFormGroup: AndClauseFormGroup = this.addAndClause(andClauseDto);
        andClauseFormGroup.setupFrom(andClauseDto, fieldTypesById, fieldsById_ByFieldTypeId, operationsById_ByTypeId);
    }

    addAndClause(andClauseDto?: ContactListSegmentFilterAndClause): AndClauseFormGroup {
        const andClauseFormGroup: AndClauseFormGroup = new AndClauseFormGroup(andClauseDto);
        this.push(andClauseFormGroup);

        return andClauseFormGroup;
    }

    removeAndClause(andClauseIndex: number): void {
        this.removeAt(andClauseIndex);
    }
}
