import {
	Component,
	OnInit,
	forwardRef,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Output,
	EventEmitter,
} from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { take } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { chain, map } from 'lodash';

import { Mixin } from 'utils/mixin.decorator';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmPagedParams } from 'commons/services/http';
import { ColumnsManagerMixin } from 'commons/mixins';
import { ListComponent } from 'commons/components/list/list.component';
import { NotificationModalComponent } from 'commons/components/modals';

import { RelationStatusColors } from '../../relation-status-colors';
import { ClientsService } from '../clients.service';
import { columnsConfig } from './columns-config';

import { AccountAssignmentModalComponent } from './account-assignment';
import { PagedClientsListComponent } from './paged-clients-list.component';
import { RelationshipStatusModalComponent } from './relationship-status-modal';
import { TransitionToChange } from '../clients.interface';
import { RenewalModalComponent } from './renewal-modal';
import { ClientFinancialsBulkRequestModalComponent } from './client-financials-bulk-request/client-financials-bulk-request-modal.component';
import { ShareClientsModalComponent } from './share-modal/share-clients-modal.component';

@Mixin([
	ColumnsManagerMixin,
])
@Component({
	templateUrl: './bulk-clients-list.component.html',
	selector: 'bulk-client-list',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => BulkClientsListComponent) },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkClientsListComponent extends ListComponent implements OnInit, ColumnsManagerMixin {
	//should use the same value as in PagedClientsListComponent
	listName = PagedClientsListComponent.listName;

	user: UserProfile;
	relationStatusColors = RelationStatusColors;
	list: any = [];
	selection: any[] = [];
	private modalRef: BsModalRef;

	// ColumnsManagerMixin
	StorageService;
	localStorageName = 'LenderClientsColumns';
	columns = new Map();
	columns$;
    columnValues: () => [];
	prepareColumns;
	toggleColumn;
	getColumnVisibility;

	@Output() bulkExit = new EventEmitter<void>();

    hasEditRelationshipStatusPermission: boolean;
    hasEditAssignmentPermission: boolean;
    hasManageClientDocumentsPermission: boolean;
    hasRequestFinancialsPermission: boolean;

    manageDropdownMenuItem = [
        'Financials',
        'Renewal',
        'Status',
        'Assignment',
        'Document',
    ];

	constructor(
		private ref: ChangeDetectorRef,
		public transitionService: TransitionService,
		public stateService: StateService,
		public modalService: BsModalService,
		public userService: UserService,
		public clientsService: ClientsService,
        public bsModalService: BsModalService,
	) {
		super(transitionService, stateService);

		this.user = userService.profile;
	}

	ngOnInit(): void {
		this.configureFilters();
		this.configureColumns();

        this.hasEditRelationshipStatusPermission = this.user.can('EDIT_RELATIONSHIP_STATUS');
        this.hasEditAssignmentPermission = this.user.can('EDIT_AA_ASSIGNMENT');
        this.hasManageClientDocumentsPermission = this.user.can('MANAGE_CLIENT_DOCUMENTS');
        this.hasRequestFinancialsPermission = this.user.can('REQUEST_FINANCIALS');

		super.ngOnInit();
        this.addShareIfChannelShareable();
	}

    addShareIfChannelShareable(): void {
        this.clientsService.checkChannelShareable(this.user.organization.id, this.params.channelName[0])
            .pipe(take(1))
            .subscribe((canShare) => {
                if (canShare) {
                    this.manageDropdownMenuItem.push('Share');
                }
            });
    }

	configureFilters(): void {
		this.defaultFilters = {
			...this.defaultFilters,
			channelName: [],
			state: [],
			approvalStatus: [],
			accountExecutive: [],
		};
	}

	configureColumns(): void {
		this.prepareColumns(columnsConfig);
	}

	onSelect(event, tpoId) {
		const { target: { checked } } = event;
		if ( checked ) {
			this.selection.push(tpoId);
		} else {
			this.selection.splice(this.selection.indexOf(tpoId), 1);
		}
		this.ref.markForCheck();
	}

	onSelectAll(event) {
		const { target: { checked } } = event;
		if ( checked ) {
			this.selection = this.list.map(item => item.tpoId);
		} else {
			this.selection.length = 0;
		}
		this.ref.markForCheck();
	}

	loadList(queryParams: RealmPagedParams) {
		this.list.$resolved = false;
		this.ref.markForCheck();

		return this.clientsService.listAll(
			this.getClearParams(queryParams),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
				this.ref.markForCheck();
			});
	}

	showAccessDeniedModal(): void {
		this.modalRef = this.modalService.show(
			NotificationModalComponent,
			{
				initialState: {
					title: 'Sorry! Access Denied',
					notification: '<div class="denied-lock"></div>' +
						'You currently do not have the necessary permissions to access this section.<br><br>' +
						'If you believe this is an error, please contact your Administrator to manage your permissions.',
					confirmText: 'Ok',
					onConfirm: () => {
						this.modalRef.hide();
					},
				},
				class: 'modal-smd modal-new modal-403',
			},
		);
	}

	editAssignment() {
		const channelId = this.params.channelName[0];
		const initialState = {
			accountExecutives: this.clientsService.channelAccountExecutives({ channelId }),
			assign: (formData) => (
				this.clientsService.assign(
					{ channelId },
					{
						...formData,
						tpoIds: this.selection,
					},
				).$promise
					.then((): void => {
						this.bulkExit.emit();
					})
			),
		};
		this.modalService.show(AccountAssignmentModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	editDocument() {
		const channelId = this.params.channelName[0];
		this.stateService.transitionTo('clients.list.document', {
			channelId,
			tpoIds: this.selection,
		});
	}

	reloadList() {
		this.loadList(this.params);
	}

	cancel(): void {
		this.bulkExit.emit();
	}

	async changeRelationshipStatus() {
		const statusesTransitions = await this.clientsService.statusesTransitions();

		const selectedClients = chain(this.list)
			.keyBy('tpoId')
			.at(this.selection)
			.value();

		const selectedClientsIdsByStatuses = chain(selectedClients)
			.groupBy('approvalStatus.id')
			.mapValues(
				(transition) => map(transition, 'tpoId')
			)
			.value();

		this.modalRef = this.modalService.show(RelationshipStatusModalComponent, {
			initialState: {
				transitions: statusesTransitions,
				selectedClientsIdsByStatuses,
				onSave: async (transitionsToChange: TransitionToChange[]) => {
					this.modalRef.content.resolving = true;
					try	{
						await this.clientsService.bulkUpdateChannelRelationship(
							{ channelId: this.params.channelName[0] },
							{ transitions: transitionsToChange },
						).$promise;
						this.modalRef.hide();
						this.cancel();
					} catch (e) {
						// error
					}
					this.modalRef.content.resolving = false;
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	showRenewalModal() {
		this.modalRef = this.modalService.show(RenewalModalComponent, {
			initialState: {
				onSave: async (renewalDate: string) => {
					this.modalRef.content.resolving = true;
					try	{
						await this.clientsService.bulkUpdateRenewalDate(
							{ channelId: this.params.channelName[0] },
							{ renewalDate, tpoIds: this.selection },
						).$promise;
						this.modalRef.hide();
						this.cancel();
					} catch (e) {
						// error
					}
					this.modalRef.content.resolving = false;
				},
			},
			class: 'modal-smd modal-new',
		});
	}

    openSelectedItemModal(selectedMenuItem: string) {
        switch(selectedMenuItem) {
            case 'Renewal':
                this.hasEditRelationshipStatusPermission ? this.showRenewalModal() : this.showAccessDeniedModal();
                break;
            case 'Status':
                this.hasEditRelationshipStatusPermission ? this.changeRelationshipStatus() : this.showAccessDeniedModal();
                break;
            case 'Assignment':
                this.hasEditAssignmentPermission ? this.editAssignment() : this.showAccessDeniedModal();
                break;
            case 'Document':
                this.hasManageClientDocumentsPermission ?  this.editDocument() : this.showAccessDeniedModal();
                break;
            case 'Financials':
                this.hasRequestFinancialsPermission ? this.openFinancialsRequestModal() : this.showAccessDeniedModal();
                break;
            case 'Share':
                this.hasEditRelationshipStatusPermission ? this.shareChannel() : this.showAccessDeniedModal();
                break;
        }
    }

    shareChannel() {
        const initialState: Partial<ShareClientsModalComponent> = {
            tpoIds: this.selection,
            channelId: this.params.channelName[0],
            callbackOnSave: () => {
                this.cancel();
            },
        };
        this.bsModalService.show(
            ShareClientsModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
            });
    }

    openFinancialsRequestModal() {
        const initialState = {
            channelId: this.params.channelName[0],
            investorId: this.user.organization.id,
            lenderIds: this.selection,
            organizationName: this.user.organization.name,
            callbackOnSave: () => {
                this.cancel();
            },
        };
        this.modalService.show(
            ClientFinancialsBulkRequestModalComponent,
            {
                initialState,
                class: 'modal-new modal-smd',
                backdrop: 'static',
            },
        );
    }
}
