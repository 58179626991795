import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, OnDestroy } from '@angular/core';
import { NewPagedListComponent } from 'commons/components/new-list/list.component';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'reset-filters',
    template: `
        <ng-container *ngIf="listComponent?.filtersApplied">
            <span>Filters are applied. <a (click)="listComponent?.resetFilters()">Reset filters</a> to see all results</span>
        </ng-container>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewResetFiltersComponent implements AfterViewInit, OnDestroy {
    protected destroyed$: Subject<void> = new Subject();

    constructor(
        @Host() public listComponent: NewPagedListComponent<unknown>,
        private _cd: ChangeDetectorRef,
    ) {
    }

    ngAfterViewInit(): void {

        this.listComponent.params$
            .pipe(
                debounceTime(10),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                this._cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
