import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { A11yModule } from '@angular/cdk/a11y';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { GALLERY_CONFIG, GalleryModule } from  'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxModule } from  'ng-gallery/lightbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { commonComponents } from '.';
import { NavigationModule } from './navigation';
import { ColumnSplitterService } from '../services/utils/column-splitter.service';
import { ListQueryUtilsService } from 'commons/services/utils/list-query-utils.service';
import { RelationshipStatusBadgeService } from '../services/utils/relationship-status-badge.service';

TagInputModule.withDefaults({
	tagInput: {
		addOnBlur: true,
		addOnPaste: true,
		ripple: false,
		animationDuration: {
			enter: '0ms',
			leave: '0ms',
		},
	},
});

const modules = [
	NgSelectModule,
	UIRouterModule,
	A11yModule,
	TagInputModule,
	GalleryModule,
	LightboxModule,
	NavigationModule,
];

export function defaultTooltipConfig(): TooltipConfig {
	return Object.assign(new TooltipConfig(), {
		placement: 'top',
		container: 'body',
	});
}

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		DirectivesModule,
		FormsModule,
		ModalModule.forRoot(),
		TooltipModule.forRoot(),
		BsDatepickerModule.forRoot(),
		PopoverModule,
		TimepickerModule.forRoot(),
		CollapseModule.forRoot(),
		...modules,
		ReactiveFormsModule,
		BrowserAnimationsModule,
	],
	declarations: [
		...commonComponents,
	],
	exports: [
		...commonComponents,
		ModalModule,
		TooltipModule,
		BsDatepickerModule,
		PopoverModule,
		TimepickerModule,
		CollapseModule,
		...modules,
	],
	providers: [
		{ provide: TooltipConfig, useFactory: defaultTooltipConfig },
		{
			provide: GALLERY_CONFIG,
			useValue: {
				loadingMode: 'indeterminate',
			},
		},
		{
			provide: LIGHTBOX_CONFIG,
			useValue: {
				// default lightbox settings go here
			},
		},
        ColumnSplitterService,
		ListQueryUtilsService,
        RelationshipStatusBadgeService,
	],
})
export class ComponentsModule {}
