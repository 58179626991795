import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient, RealmPagedParams } from 'commons/services/http';
import { Observable } from 'rxjs';
import { AccountInformation } from '../company-information/account-information.service';


declare let apiPath: string;

export type FederalRegistrationStatus = ('Active' | 'Inactive');

export interface FederalRegistration {
    id: number;
    name: string;
    nmlsId: number;
    startDate: string;
    status: FederalRegistrationStatus;
}

@Injectable()
export class FederalRegistrationsResourceService {
    public constructor(
        protected readonly http: RealmHttpClient,
    ) {
    }

    public getList(accountInformation: AccountInformation, params: RealmPagedParams): Observable<PagedData<FederalRegistration[]>> {
        if (accountInformation.identifier.nmlsId) {
            return this.getNmlsList(accountInformation.identifier.nmlsId, params);
        }

        return this.getTpoList(accountInformation.identifier.tpoId, params);
    }

    public getNmlsList(nmlsId: number, params: RealmPagedParams): Observable<PagedData<FederalRegistration[]>> {
        return this.http.pagedRequest(
            'GET',
            `${apiPath}/nmls/company/${nmlsId}/federal-registration`,
            params
        );
    }

    public getTpoList(tpoId: number, params: RealmPagedParams): Observable<PagedData<FederalRegistration[]>> {
        return this.http.pagedRequest(
            'GET',
            `${apiPath}/tpos/${tpoId}/federal-registration`,
            params
        );
    }
}
