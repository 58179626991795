// import { ExampleComponentsComponent } from 'commons/__components';
import { RegistrationComponent } from 'login/registration/registration.component';
import { recaptchaResolver } from 'commons/recaptcha/recaptcha.resolver';
import { RealmStateDeclaration } from 'routes/sharedRoutes';
import { CardPreviewComponent } from 'commons/card/card-preview.component';
import { ForgotPageComponent } from 'login/forgot-page/forgot-page.component';
import commonRoutes from 'routes/commonRoutes';

export default [
    {
        name: 'registration',
        free: true,
        component: RegistrationComponent,
        resolve: [
            recaptchaResolver,
        ],
    },
    ...commonRoutes,
    {
		name: 'card',
		url: '/--card',
        component: CardPreviewComponent,
		free: true,
		class: 'home-blue'
	},
	{
		name: 'forgot-password',
		free: true,
		url: '/forgot-password',
		component: ForgotPageComponent,
	}
] as RealmStateDeclaration[];
