import { Component, Input, HostBinding, HostListener, Host, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { RegulationsModalComponent } from 'shared/reviews/$reviewId/checklist/modals';

import { IssueListComponent } from '..';
import { FindingComponent, FindingModelService } from '../..';

interface Issue {
	description: string;
	id: number;
	number: number;
	observations: Array<{
		id: number,
		observation: string,
	}>;
	screenPosX: number;
	screenPosY: number;
}

@Component({
	selector: 'issue',
	templateUrl: './issue.component.html',
})
export class IssueComponent implements OnInit, OnDestroy {
	User: UserProfile;
	modalRef: BsModalRef;

	get canManage(): boolean {
		return this.issueList.canManage;
	}

	get isNew(): boolean {
		return !this.issue.id;
	}

	focusSubscription: Subscription;
	findingComponent: FindingComponent;

	@Input() issue: Issue;
	@HostBinding('class.active') isFocused: boolean = false;

	constructor(
		@Host() public issueList: IssueListComponent,
		public element: ElementRef,
		public stateService: StateService,
		public routerGlobals: UIRouterGlobals,
		public modalService: BsModalService,
		public findingModelService: FindingModelService,
		{ profile }: UserService,
	) {
		this.User = profile;
	}

	@HostListener('click') focusIssue(): void {
		if (!this.findingModelService.isEditingIssue && !this.isFocused) {
			this.findingComponent.highlightIssue(this.issue);
		}
	}

	ngOnInit(): void {
		this.findingComponent = this.issueList.findingComponent;

		this.focusSubscription = this.findingModelService.issueInFocus$.subscribe((selectedIssue) => {
			this.isFocused = selectedIssue === this.issue;

			if (this.isFocused) {
				setTimeout(() => {
					// scroll only new issues to top
					this.element.nativeElement.scrollIntoView({
						behavior: 'smooth',
						block: this.isNew ? 'center' : 'nearest',
					});
				});
			}
		});

		// New Issue Case
		if (this.isNew) {
			this.enterEditing();
		}
	}

	ngOnDestroy(): void {
		this.focusSubscription.unsubscribe();
	}

	enterEditing(): void {
		this.issueList.showEditModal(this.issue);
	}

	showRegulations(observationId: number): void {
		const initialState = {
			observationId,
			...(this.getRegulationParams()),
			checkListFindingId: this.routerGlobals.params.findingId,
		};

		this.modalRef = this.modalService.show(RegulationsModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}

	getRegulationParams(): { tpoId?: number } {
		if (this.User.isTpo) {
			return {};
		}

		return {
			tpoId: this.findingComponent.finding?.contact?.tpoId,
		};
	}
}
