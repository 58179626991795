<ng-template #viewControls>
    <div *ngIf="!readOnly" class="col-xs-2 va-m button-holder" [class.text-right]="!controlsOnLeftSide">
        <a *ngIf="!isEditing" class="btn btn-icon btn-tertiary" (click)="startEditing()">
            <span class="glyphicon glyphicon-pencil"></span>
        </a>
    </div>
</ng-template>
<ng-template #editControls>
    <div class="col-xs-4">
        <div class="va-m button-holder" [class.text-right]="!controlsOnLeftSide">
            <button type="submit" class="btn btn-icon btn-tertiary">
                <span class="glyphicon glyphicon-ok"></span>
            </button>
            <a class="btn btn-icon btn-tertiary" (click)="cancelEditing()">
                <span class="glyphicon glyphicon-remove"></span>
            </a>
        </div>
    </div>
</ng-template>

<div class="col-sm-12">
    <form [formGroup]="form" (onValidSubmit)="triggerSave()" (keyup.escape)="handleEscapeKey()">
        <div class="row">
            <div class="form-group col-sm-12" has-error>
                <div [isEditable]="isEditing">
                    <div view class="row">
                        <ng-container *ngIf="controlsOnLeftSide">
                            <ng-container *ngTemplateOutlet="viewControls"></ng-container>
                        </ng-container>

                        <div class="col-xs-10 control-value">
                            <ng-content></ng-content>
                        </div>

                        <ng-container *ngIf="!controlsOnLeftSide">
                            <ng-container *ngTemplateOutlet="viewControls"></ng-container>
                        </ng-container>
                    </div>
                    <div edit class="row">
                        <ng-container *ngIf="controlsOnLeftSide">
                            <ng-container *ngTemplateOutlet="editControls"></ng-container>
                        </ng-container>

                        <div class="col-xs-8">
                            <input
                                bsDatepicker
                                id="expirationDate"
                                autocomplete="off"
                                name="value"
                                formControlName="value"
                                tabindex="1"
                                trigger="mousedown"
                                placeholder="mm/dd/yyyy"
                                [bsConfig]="bsConfig"
                                [cdkTrapFocusAutoCapture]="isEditing"
                                [cdkTrapFocus]="isEditing"
                                (blur)="updateValue()"
                                (onChange)="updateValue()"
                                (onHidden)="updateValue()"
                                class="form-control"
                            >
                        </div>

                        <ng-container *ngIf="!controlsOnLeftSide">
                            <ng-container *ngTemplateOutlet="editControls"></ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
