<div class="row-fluid filter-labels">
	<div class="col-md-a">
		<div class="text-muted">License Type</div>
	</div>
	<div class="col-md-a">
		<div class="text-muted">Authorization</div>
	</div>
	<div class="col-md-a">
		<div class="text-muted">Business Model</div>
	</div>
	<div class="col-md-a">
		<div class="text-muted">Business Type</div>
	</div>
	<div class="col-md-a">
		<div class="text-muted">Client Type</div>
	</div>
	<div class="col-md-a">
		<div class="text-muted">Loan Type</div>
	</div>
<!--	<div class="col-md-1"></div>-->
</div>
<div class="row-fluid filter-selectors">
	<div class="col-md-a">
		<ng-select
			[ngModel]="listComponent.params.lt"
			[items]="filters.lt | async"
			(change)="setFilter({ lt: $event })"
			[loading]="!filters.lt | async"
			bindValue="id"
			bindLabel="name"
			[multiple]="true"
			placeholder="All License Types"
		></ng-select>
	</div>
	<div class="col-md-a">
		<ng-select
			[ngModel]="listComponent.params.auth"
			[items]="filters.auth | async"
			(change)="setFilter({ auth: $event })"
			[loading]="!filters.auth | async"
			bindValue="id"
			bindLabel="name"
			placeholder="All"
		></ng-select>
	</div>
	<div class="col-md-a">
		<ng-select
			[ngModel]="listComponent.params.bm"
			[items]="filters.bm | async"
			(change)="setFilter({ bm: $event })"
			[loading]="!filters.bm | async"
			bindValue="id"
			bindLabel="name"
			[multiple]="true"
			placeholder="All Business Models"
		></ng-select>
	</div>
	<div class="col-md-a">
		<ng-select
			[ngModel]="listComponent.params.bt"
			[items]="filters.bt | async"
			(change)="setFilter({ bt: $event })"
			[loading]="!filters.bt | async"
			bindValue="id"
			bindLabel="name"
			[multiple]="true"
			placeholder="All Business Types"
		></ng-select>
	</div>
	<div class="col-md-a">
		<ng-select
			[ngModel]="listComponent.params.ct"
			[items]="filters.ct | async"
			(change)="setFilter({ ct: $event })"
			[loading]="!filters.ct | async"
			bindValue="id"
			bindLabel="name"
			[multiple]="true"
			placeholder="All Client Types"
		></ng-select>
	</div>
	<div class="col-md-a">
		<ng-select
			[ngModel]="listComponent.params.loant"
			[items]="filters.loant | async"
			(change)="setFilter({ loant: $event })"
			[loading]="!filters.loant | async"
			bindValue="id"
			bindLabel="name"
			[multiple]="true"
			placeholder="All Loan Types"
		></ng-select>
	</div>
</div>
