import {
    ChangeDetectorRef,
    Component,
    Input, OnChanges, SimpleChanges,
} from '@angular/core';

@Component({
	selector: '[isEditable]',
	templateUrl: './editable.component.html',
})
export class NewEditableComponent implements OnChanges{
	@Input('isEditable') editable: boolean;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.changeDetector.detectChanges();
    }
}
