<h2>Tasks</h2>

<div class="remote-resource remediation" [class.resolved]="list.$resolved">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<span class="text-muted">Search</span>
			</div>
			<div class="col-sm-3">
				<span class="text-muted">Task Status</span>
			</div>
			<div class="col-sm-3">
				<span class="text-muted">Finding Status</span>
			</div>
			<div class="col-sm-3"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<search-list placeholder="Name"></search-list>
			</div>
			<div class="col-sm-3">
				<ng-select
					[(ngModel)]="params.remediationStatus"
					(change)="setFilter({ remediationStatus: $event })"
					[items]="filters.remediationStatus"
					bindLabel="title"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Statuses"
				></ng-select>
			</div>
			<div class="col-sm-3">
				<ng-select
					[(ngModel)]="params.findingStatus"
					(change)="setFilter({ findingStatus: $event })"
					[items]="filters.findingStatus.$promise | async"
					[loading]="!filters.findingStatus.$resolved"
					bindLabel="status"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Statuses"
				></ng-select>
			</div>
			<div class="col-sm-3">
				<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
			</div>
		</div>
		<ng-container *ngIf="moreFilters">
			<div class="row-fluid filter-labels">
				<div class="col-sm-3">
					<span class="text-muted">Due Date From</span>
				</div>
				<div class="col-sm-3">
					<span class="text-muted">Due Date To</span>
				</div>
				<div class="col-sm-3">
					<span class="text-muted">Completed Date From</span>
				</div>
				<div class="col-sm-3">
					<span class="text-muted">Completed Date To</span>
				</div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="col-sm-3">
					<input
						type="text"
						class="form-control"
						bsDatepicker
						[bsConfig]="bsConfig"
						[bsValue]="params.dueDateFrom"
						[maxDate]="params.dueDateTo"
						(bsValueChange)="setFilter({dueDateFrom: $event})"
						placeholder="mm/dd/yyyy"
					>
				</div>
				<div class="col-sm-3">
					<input
						type="text"
						class="form-control"
						bsDatepicker
						[bsConfig]="bsConfig"
						[bsValue]="params.dueDateTo"
						[minDate]="params.dueDateFrom"
						(bsValueChange)="setFilter({dueDateTo: $event})"
						placeholder="mm/dd/yyyy"
					>
				</div>
				<div class="col-sm-3">
					<input
						type="text"
						class="form-control"
						bsDatepicker
						[bsConfig]="bsConfig"
						[bsValue]="params.completedDateFrom"
						[maxDate]="params.completedDateTo"
						(bsValueChange)="setFilter({completedDateFrom: $event})"
						placeholder="mm/dd/yyyy"
					>
				</div>
				<div class="col-sm-3">
					<input
						type="text"
						class="form-control"
						bsDatepicker
						[bsConfig]="bsConfig"
						[bsValue]="params.completedDateTo"
						[minDate]="params.completedDateFrom"
						(bsValueChange)="setFilter({completedDateTo: $event})"
						placeholder="mm/dd/yyyy"
					>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-a" columnName="fullname">Name</sortable-header>
			<sortable-header class="col-sm-a" columnName="remediationStatus">Status</sortable-header>
			<sortable-header class="col-sm-a" columnName="statusDate">Status Date</sortable-header>
			<sortable-header class="col-sm-a" columnName="dueDate">Due Date</sortable-header>
			<sortable-header class="col-sm-a" columnName="findingStatus">Finding Status</sortable-header>
		</div>

		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<div class="col-sm-12 text-center va-m">Filters are applied.
				<a (click)="resetFilters()">Reset filters</a> to see all results
			</div>
		</div>

		<a
			*ngFor="let item of list"
			class="row-fluid clickable"
			uiSref="social-compliance.findings.:actionType.:findingId"
			[uiParams]="{ actionType: 'edit', findingId: item.findingId, tab: 'TASK' }"
		>
			<div class="col-sm-a">
				<div>
					<a>{{item.name || '&ndash;'}}</a>
				</div>
				<div class="text-muted" *ngIf="item.nmlsId">NMLS ID: {{item.nmlsId}}</div>
			</div>
			<div class="col-sm-a va-m">
				<div
					class="label"
					[class.label-warning]="[ 'Open', 'Reopened' ].includes(item.remediationStatus)"
					[class.label-success]="item.remediationStatus == 'Completed'"
				>
					<span class="text-capitalize text-lowercase">{{item.remediationStatus}}</span>
				</div>
			</div>
			<div class="col-sm-a va-m">
				{{(item.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}
			</div>
			<div class="col-sm-a va-m">
				{{item.dueDate || '&ndash;'}}
			</div>
			<div class="col-sm-a va-m">
				{{item.findingStatus || '&ndash;'}}
			</div>
		</a>

		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-bigger text-muted">
				{{(filtersApplied || filters.q) ? 'No matches found' : 'No Tasks'}}
			</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
