<form class="remote-resource" [class.resolved]="resolved" [formGroup]="findingDetailsCommentForm" (onValidSubmit)="saveComment()">
    <form-errors [form]="findingDetailsCommentForm"></form-errors>
    <div class="form-group" has-error>
        <textarea
            type="text"
            name="comment"
            formControlName="comment"
            class="form-control"
            maxlength="2000"
            tabindex="1"
            placeholder="Add your comment here"
            rows="{{editing ? 5 : 1}}"
            (click)="setEditing()"
        ></textarea>
    </div>
    &nbsp;
    <div class="row-fluid" *ngIf="editing">
        <div class="col-md-3">
            <ng-select
                formControlName="commentType"
                [items]="availableCommentType"
                bindLabel="label"
                tabindex="2"
                [clearable]="false"
            ></ng-select>
        </div>
        <div class="col-md-8">
            {{findingDetailsCommentForm.get('commentType').value.description}}
        </div>
    </div>
    &nbsp;
    <div class="row-fluid" *ngIf="editing">
        <div class="col-md-12">
            <button class="btn btn-primary" type="submit">
                Add Comment
            </button>
            <button class="btn btn-primary-wired" type="button" (click)="cancelEditing()">
                Cancel
            </button>
        </div>
    </div>
</form>
