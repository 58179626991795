import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { linkTemplate } from 'shared/supporting-documents/helpers';
import { UploadResourceProps } from './common';

declare let apiPath: string;

@Injectable()
export class InternalDocumentsService {
	public resource: any;
	public history: any;
	public one: any;
	public internalDocument: any;

	private baseUrl = `${apiPath}/lenders/:lenderId/tpos/:tpoId`;
	private baseParams = {
		lenderId: null,
		tpoId: null,
	};

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.baseParams = {
			...this.baseParams,
			lenderId: user.profile.organization.id,
		};

		const documentsResourceConfig = {
			url: `${this.baseUrl}/internal-documents`,
			params: {
				...this.baseParams,
				tpoId: '@tpoId',
			},
		};
		const legacyDocumentResourceConfig = {
			url: `${documentsResourceConfig.url}/:docId`,
			params: {
				...documentsResourceConfig.params,
				documentId: '@docId',
			},
		};
		const documentResourceConfig = {
			url: `${documentsResourceConfig.url}/:documentId`,
			params: {
				...documentsResourceConfig.params,
				documentId: '@documentId',
			},
		};

		this.resource = $resource(
			documentsResourceConfig.url,
			documentsResourceConfig.params,
			{
				list: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.one = $resource(
			legacyDocumentResourceConfig.url,
			legacyDocumentResourceConfig.params,
			{
				// Deprecated
				// get: {
				// 	method: 'GET',
				// },
				upload: {
					url: `${legacyDocumentResourceConfig.url}/file`,
					...UploadResourceProps,
				},
			},
		);

		this.history = PagedResource(
			`${legacyDocumentResourceConfig.url}/history`,
			legacyDocumentResourceConfig.params,
			{
				query: { method: 'get', isArray: true },
			},
		);

		// TODO: merge with existing
		this.internalDocument = $resource(
			documentResourceConfig.url,
			documentResourceConfig.params,
			{
				create: {
					method: 'POST',
					url: documentsResourceConfig.url,
					params: documentsResourceConfig.params,
					headers: {
						'Content-Type': undefined,
					},
					transformRequest: ({ file, properties }) => {
						const formData = new FormData();
						formData.append('properties', JSON.stringify(properties));
						if (file) {
							formData.append('file', file);
						}
						return formData;
					},
				},
				get: {
					method: 'GET',
				},
				update: {
					method: 'PUT',
				},
			},
		);
	}

	/**
	 * @param {Object} params
	 * @param {number} [params.lenderId]
	 * @param {number} [params.tpoId]
	 * @param {number} [params.documentId]
	 * @param {number} [params.historyId]
	 * @param {'template'|'uploadedDocument'} [params.type]
	 */
	getDownloadLink = (params): string => {
		const { historyId } = params;
		return linkTemplate(
			`${this.baseUrl}/internal-documents/:documentId${historyId ? '/library-history/:historyId' : ''}/:type`,
			{
				...this.baseParams,
				...params,
			},
		);
	};
}
