import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'move-question-controls',
	templateUrl: './move.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveQuestionControlsComponent {
	@Input() showUp = true;
	@Input() showDown = true;
	@Output('moveUp') _moveUp = new EventEmitter<void>();
	@Output('moveDown') _moveDown = new EventEmitter<void>();

	moveUp = (): void => this._moveUp.emit();
	moveDown = (): void => this._moveDown.emit();
}
