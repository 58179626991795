<div *ngIf="isNew" class="gray-head flex-head">
	<back-bread><span>New Activity</span></back-bread>
</div>

<form [formGroup]="activityFull" class="activity-details remote-resource" [ngClass]="{ resolved: !creating }"
	  (onValidSubmit)="isNew && saveAll()">
	<form class="remote-resource" [ngClass]="{ resolved: activity?.$resolved || isNew, curtain: isCurtain('activity') }"
		  [formGroup]="activityForm" name="activityForm" id="activityForm" (onValidSubmit)="save()">

		<h3>Activity information
			<span *ngIf="!editing.activity && !isNew" class="activity-buttons">
				<button type="button" class="btn btn-secondary btn-icon" (click)="remove()">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				<button type="button" class="btn btn-primary" (click)="edit()">
					<span class="glyphicon glyphicon-pencil text-smaller"></span> Edit
				</button>
			</span>
			<span *ngIf="editing.activity && !isNew" class="activity-buttons">
				<button type="submit" class="btn btn-primary" tabindex="20">Save</button>
				<button class="btn btn-default" (click)="cancelEdit()" tabindex="20">Cancel</button>
			</span>
		</h3>

		<form-errors [form]="activityForm"></form-errors>

		<div class="rows-striped">
			<div class="row-fluid">
				<div class="col-sm-6">
					<div class="form-group row"
						 [class.has-error]="editing.activity && hasError('name')">
						<label for="name" class="col-sm-3 control-label" [class.required]="editing.activity">
							Activity Name
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editing.activity" [view]="activity?.name || '&ndash;'">
								<ng-template #editTemplate>
									<input id="name" class="form-control" formControlName="name" maxlength="100"
										   (change)="activity.name = getInputValue($event)" tabindex=1 required>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group row"
						 [class.has-error]="editing.activity && hasError('dueDate')">
						<label for="dueDate" class="col-sm-3 control-label" [class.required]="editing.activity">
							Due Date
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editing.activity">
								<ng-template #viewTemplate>
									<span class="control-value">
										{{(activity?.dueDate | date:'MM/dd/yyyy') || '&ndash;'}}
									</span>
								</ng-template>

								<ng-template #editTemplate>
									<input id="dueDate" class="form-control input-date" formControlName="dueDate"
										   bsDatepicker
										   [bsConfig]="bsConfig"
										   [bsValue]="activityForm.get('dueDate').value"
										   (bsValueChange)="activity.dueDate = $event ? $event.getTime() : null"
										   placeholder="mm/dd/yyyy"
										   tabindex=2
										   required>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-sm-6">
					<div class="form-group row"
						 [class.has-error]="editing.activity && hasError('priority')">
						<label class="col-sm-3 control-label" [class.required]="editing.activity">
							Priority
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editing.activity" [view]="activity?.priority?.name || '&ndash;'">
								<ng-template #editTemplate>
									<ng-select
											(change)="activity.priority.id = $event.shortName"
											[items]="priorities?.$promise | async"
											[loading]="!priorities?.$resolved"
											formControlName="priority"
											bindLabel="name"
											bindValue="shortName"
											[clearable]="false"
											placeholder="Set Priority"
											tabindex=3
											required>
									</ng-select>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group row">
						<label class="col-sm-3 control-label" [class.required]="editing.activity">
							Status
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editing.activity">
								<ng-template #viewTemplate>
									<span class="control-value text-bigger">
										<span [ngClass]="'label label-' + (statusColors[activity?.status?.id] || 'default')">
											{{activity?.status?.name}}
										</span>
									</span>
								</ng-template>

								<ng-template #editTemplate>
									<ng-select
											(change)="activity.status.id = $event.shortName"
											[items]="statuses?.$promise | async"
											[loading]="!statuses?.$resolved"
											formControlName="status"
											bindLabel="name"
											bindValue="shortName"
											[clearable]="false"
											placeholder="Set Status"
											tabindex=4
											required>
									</ng-select>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-sm-6">
					<div class="form-group row">
						<label class="col-sm-3 control-label" [class.required]="editing.activity">
							Assigned to
						</label>
						<div class="col-sm-7">
							<editable-component [editable]="editing.activity" [view]="activity?.assignedTo?.name || '&ndash;'">
								<ng-template #editTemplate>
									<ng-select
											(change)="activity.assignedTo.id = $event.id"
											[items]="assignedTo?.$promise | async"
											[loading]="!assignedTo?.$resolved"
											formControlName="assignedTo"
											bindLabel="name"
											bindValue="id"
											[clearable]="false"
											placeholder="Set Assignment"
											tabindex=5
											required>
									</ng-select>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-sm-6"></div>
			</div>
			<div class="row-fluid">
				<div class="form-group row">
				<span class="col-sm-12">
					<label for="description" class="coldp-sm-3 control-label">
						Description
					</label>
					<span class="coldp-sm-18 description">
						<editable-component [editable]="editing.activity" [view]="activity?.description || '&ndash;'">
							<ng-template #editTemplate>
								<textarea id="description" class="col-sm-11 form-control resize-vertical"
									(change)="activity.description = getInputValue($event)"
									formControlName="description" tabindex="6" rows="4" maxlength="2000">
								</textarea>
							</ng-template>
						</editable-component>
					</span>
					<span class="coldp-sm-3"></span>
				</span>
				</div>
			</div>
		</div>
	</form>

	<additional-fields
			entityTypeId="CRMActivity"
			[entityId]="activityId"
			[parentForm]="activityFull"
			[curtain]="isCurtain('additionalFields')"
			[isNew]="isNew"
			[(editing)]="editing.additionalFields"
            (editingChange)="detectChanges();"
			(groupedFieldsChange)="groupedFields = $event; detectChanges();"
	></additional-fields>

	<related-account #account
			[resource]="relatedAccountResource"
			[(account)]="activity.relatedAccount"
			(accountChange)="prospect.reset($event?.nmlsId); tpoContext && this.showNotification(account); detectChanges();"
			[parentForm]="activityFull"
			[notEditableOnCreate]="defaultActivity.relatedAccount && ['COMPANY', 'CONTACT', 'PROSPECT'].includes(embedded)"
			[curtain]="isCurtain('relatedAccount')"
            [(editing)]="editing.relatedAccount"
			(editingChange)="detectChanges();"
			[(resolved)]="activity.$resolved"
			(resolvedChange))="detectChanges();"
	></related-account>

	<related-contact
			[(contact)]="activity.relatedContact"
            (contactChange)="detectChanges()"
			[parentForm]="activityFull"
			[notEditableOnCreate]="['CONTACT'].includes(embedded)"
			[curtain]="isCurtain('relatedContact')"
			[(editing)]="editing.relatedContact"
            (editingChange)="detectChanges();"
			[(resolved)]="activity.$resolved"
            (resolvedChange))="detectChanges();"
	></related-contact>

	<related-prospect #prospect
			[(prospect)]="activity.relatedProspect"
			(prospectChange)="$event && account.setAccount({ nmlsId: $event.nmlsId, name: $event.companyName }); detectChanges();"
			[parentForm]="activityFull"
			[accountNmlsId]="activity?.relatedAccount?.nmlsId"
			[notEditableOnCreate]="this.embedded === 'PROSPECT'"
			[curtain]="isCurtain('relatedProspect')"
			[(editing)]="editing.relatedProspect"
			[(resolved)]="activity.$resolved"
            (resolvedChange))="detectChanges();"
	></related-prospect>

	<div [ngClass]="{curtain: !isNew && isCurtain()}">
    		<section *ngIf="!isNew">
			<h3 class="remote-resource" [class.resolved]="commentsComponent.resolved">Comments</h3>

			<comments #commentsComponent
					  [resource]="commentsResource" [permissions]="{ canAdd: true }" [addForm]="commentsForm"
					  (onAdd)="commentsForm.get('commentType').patchValue('PUBLIC')">

				<ng-template #formTemplate
							 let-addForm="addForm"
							 let-add="methods.add"
							 let-entityName="entityName"
							 let-editing="editing"
							 let-submitted="submitted"
							 let-setEditMode="methods.setEditMode"
							 let-resetFormOnEmptyComment="methods.resetFormOnEmptyComment">
					<form [formGroup]="addForm" id="addCommentForm" class="add-comment-form"
						  (onValidSubmit)="add()" autocomplete="off" role="presentation">
						<div class="mb15 remote-resource" [class.resolved]="!submitted">
							<div class="form-group full-width">
								<textarea
										class="form-control no-resize"
										formControlName="comment"
										rows="{{editing ? 5 : 1}}"
										maxlength="2000"
										placeholder="Add your {{entityName | lowercase}} here"
										(focus)="setEditMode(true)">
								</textarea>
							</div>

							<div class="form-group row" *ngIf="editing">
								<div class="col-md-3">
									<ng-select
											[items]="commentTypes?.$promise | async"
											[loading]="!commentTypes?.$resolved"
											formControlName="commentType"
											bindLabel="label"
											bindValue="code"
											[searchable]="false"
											[clearable]="false"
											required>
									</ng-select>
								</div>

								<div class="col-md-9 control-value" *ngIf="commentTypes?.$resolved">
									{{typeDescriptions[addForm.value.commentType]}}
								</div>
							</div>

							<div class="btn-group-lg" *ngIf="editing">
								<button  type="submit" class="btn btn-primary"
										 [disabled]="submitted || !addForm.value.comment">
									Add Comment
								</button>
								<button type="button" class="btn btn-default"
										(click)="setEditMode(false); addForm.patchValue({ commentType: 'PUBLIC' })">
									Cancel
								</button>
							</div>
						</div>
					</form>
				</ng-template>

				<ng-template #commentTemplate let-comment="comment">
					<div>
						<div class="horizontal-flex-group">
							<div class="full-width">
								<b>{{comment.createdBy}}</b>
							</div>
							<div class="text-light text-smaller row">
								<span class="col-md-5">{{comment.commentType}}</span>
								<span class="col-md-7 text-nowrap">{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}</span>
							</div>
						</div>
						<p class="comment">{{comment.comment}}</p>
					</div>
				</ng-template>

			</comments>
		</section>
	</div>

	<br/>
	<div class="clearfix" *ngIf="isNew">
		<div class="pull-right">
			<button type="submit" [disabled]="creating" class="btn btn-primary" tabindex="20">Save</button>
			<button type="button" [disabled]="creating" class="btn btn-default" tabindex="20"
					(click)="cancelNewActivity()">
				Cancel
			</button>
		</div>
	</div>
</form>
