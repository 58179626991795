import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function FileTypeValidator(extensions: string[]): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const { value } = control;
        const typeRegex = new RegExp(`\.(${extensions.join('|')})$`, 'i');
        console.log(value?.name, typeRegex)
		if (value instanceof File && !value.name.match(typeRegex)) {
			return {
				fileType: {
					value,
					message: `Wrong file type, please use supported file types for upload: ${extensions.join(', ')}`,
				},
			}
		}
		return null;
	};
}
