import { Injectable } from '@angular/core';

import { GroupsResourceService } from '../groups-resource.service';

@Injectable()
export class GroupService {
	group: any = {};

	constructor(
		public groupsResource: GroupsResourceService,
	) {}

	get(groupId) {
		const isCurrent = groupId && parseInt(groupId, 10) === this.group.id;
		if (!isCurrent) {
			if (groupId) {
                this.group = this.groupsResource.group.get({ groupId });
			} else {
                this.group = {};
                Object.defineProperty(this.group, '$resolved', { enumerable: false, writable: true, value: true });
                Object.defineProperty(this.group, '$promise', { enumerable: false, value: Promise.resolve(this.group) });
            }
		}

		return this.group;
	}
}
