import { isString } from 'lodash';
import { Directive, Renderer2, ElementRef, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type UnknownModelValue = string | number | boolean | undefined | null | unknown[] | Record<string, unknown>;

/**
 * @description
 * change DOM input value to expected model format.
 * $parser in old terminology.
 * @param value DOM input value
 * @returns string | undefined | null
 * @notice DOM input[type=text] value is a STRING, no need to check for other values
 */
export const getParsedValue = (value: unknown): string => {
	if (!isString(value)) {
		// this can't happen in real browser for input[type=text]
		return '';
	}

	// NOTICE: special characters allowed, but not text chars,
	// so keep text chars for vaidation
	return `${value}`.replace(/[^0-9a-zA-Z]/g, '');
};

/**
 * @description
 * change form field value to expected UI format.
 * $formatter in old terminology.
 * @param value form field value
 * @returns UnknownModelValue
 */
export const getFormattedValue = (value: UnknownModelValue): UnknownModelValue => {
	if (value === undefined || value === null) {
		return '';
	}

	if (Array.isArray(value)) {
		return value;
	}

	const stringValue = `${value}`;
	const intNumber = parseInt(stringValue, 10);
	// some formatting applied on level of model -> pass to input as is
	// we expect 10 numbers for tel or fax
	// if it is NaN -> error happened on level of model data
	if (isNaN(intNumber)) {
		return value;
	}

	const parts = {
		cityCode: stringValue.slice(0, 3),
		phoneStart: stringValue.slice(3, 6),
		phoneEnd: stringValue.slice(6),
	};

	return `(${parts.cityCode}) ${parts.phoneStart}-${parts.phoneEnd}`;
}

@Directive({
	selector: '[phoneFormatter]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => RealmPhoneNumberAccessor),
		multi: true,
	}],
})
export class RealmPhoneNumberAccessor implements ControlValueAccessor {
	onChangeCallback = (_: unknown): void => {
	};
	onTouchedCallback = (_: unknown): void => {
	};

	constructor(
		private renderer : Renderer2,
		private element : ElementRef,
	) {
	}

	@HostListener('input', [ '$event.target.value' ])
	input(value: unknown): void {
		const parsedValue = getParsedValue(value);
		this.onChangeCallback(parsedValue);
	}

	@HostListener('blur', [ '$event.target.value' ])
	blur(value: unknown): void {
		const parsedValue = getParsedValue(value);
		this.onTouchedCallback(parsedValue);
	}

	writeValue(value: UnknownModelValue): void {
		const element = this.element.nativeElement;

		const formattedValue = getFormattedValue(value);
		this.renderer.setProperty(element, 'value', formattedValue);
	}

	registerOnChange(fn: (_: unknown) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: (_: unknown) => void): void {
		this.onTouchedCallback = fn;
	}
}
