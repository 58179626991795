<form [formGroup]="underwritingServiceForm" class="col-md-12 remote-resource" (onValidSubmit)="save()" [class.resolved]="!resolving">
    <div class="row-fluid">
        <div class="form-group col-md-4" [isEditable]="isEditing" has-error>
            <div view class="control-value">{{item.companyName || '&ndash;'}}</div>
            <input edit type="text" maxlength="99" name="companyName" formControlName="companyName" class="form-control"/>
        </div>
        <div class="form-group col-md-3" [isEditable]="isEditing" has-error>
            <div view class="control-value" >{{(item.phone || '&ndash;') | tel}}</div>
            <input edit type="phoneFormatter" phoneFormatter name="phone" formControlName="phone" class="form-control" />
        </div>
        <div class="form-group col-md-4" [isEditable]="isEditing" has-error>
            <div view class="control-value">{{item.contactName || '&ndash;'}}</div>
            <input edit type="text" maxlength="99" name="contactName" formControlName="contactName" class="form-control" />
        </div>

        <div class="col-f-140 form-group" [isEditable]="isEditing" has-error>
            <div view class="control-value">
                {{item.relationshipStartDate ? (item.relationshipStartDate | date:labelDateFormat) : '&ndash;'}}
            </div>
            <input edit
                   formControlName="relationshipStartDate"
                   class="form-control"
                   bsDatepicker
                   [bsConfig]="{ dateInputFormat: dateFormat }"
            />
        </div>
        <div class="col-f-140 form-group" [isEditable]="isEditing" has-error>
            <div view class="control-value">
                {{item.relationshipEndDate ? (item.relationshipEndDate | date:labelDateFormat) : 'Present'}}
            </div>
            <input edit
                   formControlName="relationshipEndDate"
                   class="form-control"
                   bsDatepicker
                   [bsConfig]="{ dateInputFormat: dateFormat }"
            />
        </div>

        <div class="col-f-100 text-right va-m button-holder" [ngSwitch]="isEditing">
            <ng-container *ngSwitchDefault>
                <a *ngIf="userCanAddEdit" class="btn btn-icon btn-tertiary" (click)="enableEdit()"><span class="glyphicon glyphicon-pencil"></span></a>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <button class="btn btn-icon btn-tertiary" type="submit"><span class="glyphicon glyphicon-ok"></span></button>
                <a class="btn btn-icon btn-tertiary" (click)="cancel()"><span class="glyphicon glyphicon-remove"></span></a>
            </ng-container>
        </div>
    </div>
</form>
