<div class="gmb-listings-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="onCancel();">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<ng-container *ngIf="!list.$resolved || list.length > 0">
		<div class="modal-body no-top-spacing remote-resource" [class.resolved]="list.$resolved">
			<div class="rows-bordered">
				<div class="row header">
					<label class="col-md-12 horizontal-flex-group sortable">
						<span class="listing-select">
							<input type="checkbox" [(ngModel)]="$all" (click)="selectAll($event)"/>
						</span>
						<span>Listing</span>
					</label>
				</div>
			</div>
			<div class="rows-bordered max-h300">
				<label class="row horizontal-flex-group clickable" *ngFor="let listing of list">
					<span class="listing-select">
						<input [(ngModel)]="listing.$selected" type="checkbox" (click)="checkAll($event)"/>
					</span>
					<sm-icon class="space-bigger" [config]="listing"></sm-icon>
					<div class="full-width">
						<span>
							{{listing.locationName}}
						</span><br>
						<span class="text-muted">
							{{([listing.address?.address, listing.address?.locality, listing.address?.administrativeArea, listing.address?.postalCode, listing.address?.regionCode] | arrayFilter).join(', ')}}
						</span>
					</div>
				</label>
			</div>
		</div>
		<div class="modal-footer" [class.curtain]="resolving">
			<div class="btn-group">
				<button type="button" (click)="onConfirm()" [disabled]="!saveEnabled" class="btn btn-primary">{{confirmText}}</button>
				<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
			</div>
		</div>
	</ng-container>

	<div class="modal-body clearfix" *ngIf="list.$resolved && list.length == 0">
		<div class="col-md-12 text-center">
			<br>
			<sm-network-icon [config]="{networkCode: 'GMB'}" [showTooltip]="false"></sm-network-icon>
			<h2>Create Listings</h2>
			<br>
			<div class="text-muted">You first need to create your Google My Business Listing and then return here to connect.</div>
			<br>
			<div><a class="btn btn-primary" href="https://www.google.com/intl/en/business/" (click)="onCancel();" target="_blank">Use Google My Business</a></div>
			<br>
		</div>
	</div>
</div>
