<div class="gray-head">
	<h2>Applications</h2>
</div>

<div class="remote-resource" [class.resolved]="!resolving">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-md-a">
				<div class="text-muted">Application Status</div>
			</div>
			<div class="col-md-a">
				<div class="text-muted">Application Type</div>
			</div>
			<div class="col-md-a">
				<div class="text-muted">Partners</div>
			</div>
			<div class="col-md-a">
				<div class="text-muted">Created After</div>
			</div>
			<div class="col-md-a">
				<div class="text-muted">Created Before</div>
			</div>
			<div class="col-f-60"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-md-a">
				<ng-select
					[ngModel]="params.applicationStatus"
					(change)="setFilter({ applicationStatus: $event })"
					[items]="filters.applicationStatus | async"
					[loading]="!filters.applicationStatus | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					placeholder="All Statuses"
				></ng-select>
			</div>
			<div class="col-md-a">
				<ng-select
					[ngModel]="params.applicationType"
					(change)="setFilter({ applicationType: $event })"
					[items]="filters.applicationType"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					placeholder="All Types"
				></ng-select>
			</div>
			<div class="col-md-a">
				<ng-select
					[ngModel]="params.lenderId"
					(change)="setFilter({ lenderId: $event })"
					[items]="filters.lenderId | async"
					[loading]="!filters.lenderId | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					placeholder="All Partners"
				>
                    <ng-template ng-option-tmp let-item="item">
                        <div class="flex-row gap-sm">
                            <span class="text flex-grow ellipsis">{{item.name}}</span>
                            <ng-container [ngSwitch]="item.status">
                                <span *ngSwitchCase="'ACTIVE'" class="label label-success">Active</span>
                                <span *ngSwitchCase="'INACTIVE'" class="label label-danger">Inactive</span>
                                <span *ngSwitchCase="'SUSPENDED'" class="label label-default">Suspended</span>
                                <span *ngSwitchDefault class="label label-primary">{{item.status}}</span>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-select>
			</div>
			<div class="col-md-a">
				<input
					class="form-control"
					type="text"
					bsDatepicker
					[bsConfig]="bsConfig"
					[bsValue]="params.createdAfter"
					[maxDate]="params.createdBefore"
					placeholder="mm/dd/yyyy"
					(bsValueChange)="setFilter({ createdAfter: $event })"
				/>
			</div>
			<div class="col-md-a">
				<input
					class="form-control"
					type="text"
					bsDatepicker
					[bsConfig]="bsConfig"
					[bsValue]="params.createdBefore"
					[minDate]="params.createdAfter"
					placeholder="mm/dd/yyyy"
					(bsValueChange)="setFilter({ createdBefore: $event })"
				/>
			</div>
			<div class="col-f-60">
				<div class="horizontal-flex-group right">
					<div class="dropdown" dropdown [insideClick]="true">
						<a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Manage Columns" placement="top">
							<span class="c-icon c-icon-list-bullet"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li *ngFor="let column of columnValues()">
								<a class="link-option" (click)="toggleColumn(column.id)">
									<span class="checkbox-holder">
										<input type="checkbox" [checked]="column.enabled">
										{{column.name}}
									</span>
								</a>
							</li>
						</ul>
					</div>
					<div class="dropdown" dropdown *ngIf="User?.profile?.can('EXPORT_APPLICATIONS')">
						<a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Export" placement="top">
							<span class="c-icon c-icon-download-alt"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li><a class="link-option" (click)="export(true)">Export all available fields</a></li>
							<li><a class="link-option" (click)="export()">Export selected fields</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-md-a">
				<div class="text-muted">Search</div>
			</div>
			<div class="col-md-a"></div>
			<div class="col-md-a"></div>
			<div class="col-md-a"></div>
			<div class="col-md-a"></div>
			<div class="col-f-60"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-md-a">
				<search-list placeholder="Name or NMLS ID"></search-list>
			</div>
			<div class="col-md-a"></div>
			<div class="col-md-a"></div>
			<div class="col-md-a"></div>
			<div class="col-md-a"></div>
			<div class="col-f-60"></div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-3" columnName="clientName">Name</sortable-header>
			<sortable-header class="col-sm-2" columnName="applicationStatus">
				<span class="two-liners">Application Status</span>
			</sortable-header>
			<sortable-header class="col-sm-2" columnName="channelName">Channel</sortable-header>
			<ng-container *ngFor="let column of columnValues()">
				<sortable-header class="col-sm-2" isColumnEnabled [columnName]="column.id">
					<span class="two-liners">{{column.name}}</span>
				</sortable-header>
			</ng-container>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<span class="col-sm-12 va-m text-center">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>
		<div class="row-fluid" *ngFor="let item of list | async">
			<span class="col-md-3 text-left">
				<a class="text-bigger" uiSref="tpo.:id.account.information" [uiParams]="{id: item.tpoId}">{{item.clientName}}</a><br>
				<span class="text-light text-smaller">NMLS ID:{{item.nmlsId}}</span>
			</span>
			<span class="col-md-2">
				<span class="dot block" [ngClass]="('dot-' + (applicationStatusColors[item.applicationStatus.id] || 'warning'))"></span>&nbsp;
				<a [aDisabled]="item.channel.id == -1 || ['AP', 'CN', 'DE', 'WD'].indexOf(item.applicationStatus.id) >= 0" uiSref="client.:id.application-summary.:applicationId" [uiParams]="{applicationId: item.applicationId, id: item.tpoId}">
					{{item.applicationStatus.name|| '&ndash;'}}
				</a>
			</span>
			<span class="col-md-2">
				<!--<a [aDisabled]="item.channel.id == -1" uiSref="tpo.:id.account.channels.:channelId" [uiParams]="{id: item.tpoId, channelId: item.channel.id}">
					{{item.channel.name || '&ndash;'}}
				</a>-->
				{{item.channel.name || '&ndash;'}}
			</span>
			<span class="col-md-2 text-bigger" isColumnEnabled columnName="approvalStatus"><span class="label block" [ngClass]="'label-' + (relationStatusColors[item.approvalStatus.id] || 'default')">{{item.approvalStatus.name || '&ndash;'}}</span></span>
			<span class="col-md-2" isColumnEnabled columnName="statusReason">{{item.statusReason || '&ndash;'}}</span>
			<ng-container [ngSwitch]="hasStatusPermission  && ['NS', 'UR', 'DR', 'LR'].indexOf(item.applicationStatus.id) >= 0">
				<ng-container *ngSwitchCase="true">
					<span class="col-md-2" isColumnEnabled columnName="expirationDate">
						<a class="btn btn-tertiary inline-edit" (click)="editExpirationDate(item)">
							<span class="glyphicon glyphicon-pencil"></span>
							<span class="text-default">{{!item.expirationDate ? '&ndash;' : item.expirationDate | date:'MM/dd/yyyy'}}</span>
						</a>
					</span>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<span class="col-md-2" isColumnEnabled columnName="expirationDate">{{!item.expirationDate ? '&ndash;' : item.expirationDate | date:'MM/dd/yyyy'}}</span>
				</ng-container>
			</ng-container>
			<span class="col-md-2" isColumnEnabled columnName="accountExecutive">{{item.accountExecutive.name || 'Unassigned'}}</span>
			<span class="col-md-2" isColumnEnabled columnName="statusDate">{{!item.statusDate ? '&ndash;' : item.statusDate | date:'MM/dd/yyyy'}}</span>
			<span class="col-md-2" isColumnEnabled columnName="createdDate">{{!item.createdDate ? '&ndash;' : item.createdDate | date:'MM/dd/yyyy'}}</span>
			<span class="col-md-2" isColumnEnabled columnName="lenderName">
				<a
					class="text-bigger"
					uiSref="customers.:customerType.:organizationId"
					[uiParams]="{customerType: item.customerType, organizationId: item.customerOrganizationId}"
				>
					{{item.lenderName}}
				</a>
			</span>
			<span class="col-md-2" isColumnEnabled columnName="optionalStatus">
				{{item.optionalStatus?.name || '&ndash;'}}
            </span>
			<span class="col-md-2" isColumnEnabled columnName="applicationType">{{item.applicationType == 'NEW' ? 'New' : 'Renewal'}}</span>
			<span class="col-md-2" isColumnEnabled columnName="clientUID">{{item.clientUID || '&ndash;'}}</span>
		</div>
		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && filtersApplied">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>
		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !filtersApplied">
			<div class="col-md-12 text-center text-bigger text-muted">No applications</div>
		</div>
		<br>
		<paginator [sizes]="[10, 25, 50]"></paginator>
	</div>
</div>

