import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { UIRouter } from '@uirouter/core';
import { InsuranceBond, InsuranceBondService } from 'shared/account/information/insurance-bonds/insurance-bonds.service';
import { INSURANCE_BONDS_TYPE } from 'shared/account/information/insurance-bonds/insurance-bonds-types';
import { map } from 'rxjs/operators';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';

type SectionedIBList = Record<INSURANCE_BONDS_TYPE, InsuranceBond[]>;

@Component({
    selector: 'insurance-bonds',
    templateUrl: './insurance-bonds.component.html',
})
export class InsuranceBondsComponent implements OnInit {
    @Input() public lenderInfo: AccountInformation;
    @ViewChild(SectionReviewComponent, { static: true }) sectionReview: SectionReviewComponent;
    public readonly sectionType = PROFILE_SECTION.INSURANCE_BONDS;
    sections: INSURANCE_BONDS_TYPE[] = [ 'fidelityBonds', 'stateSuretyBonds', 'errorAndOmissions', 'mortgageInsurance' ];
    sectionLists: Record<INSURANCE_BONDS_TYPE, InsuranceBond[]> = this.sections.reduce((acc, key) => {
        acc[key] = [];
        return acc;
    }, {}) as SectionedIBList;

    directAccess: boolean = false;
    resolved = false;

    constructor(
        private router: UIRouter,
        private insuranceBondService: InsuranceBondService,
    ) {
    }

    // Override
    async ngOnInit(): Promise<void> {
        const { component } = this.router.globals.current as any;
        this.directAccess = component === this.constructor;

        this.sectionLists = await this.insuranceBondService.getList(this.lenderInfo.identifier).pipe(
            map(list => this.insuranceBondService.separateInsuranceBonds(list, this.sectionLists))
        ).toPromise();

        this.resolved = true;
    }

    public getDirectAccess(): boolean {
        return this.directAccess;
    }

    public getSections(): INSURANCE_BONDS_TYPE[] {
        return this.sections;
    }
}
