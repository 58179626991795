import { mapValues as _mapValues, map as _map, filter as _filter, debounce, trim, isEmpty } from 'lodash';
import { Component, forwardRef, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { HttpErrorResponse } from '@angular/common/http';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { NotificationModalComponent } from 'commons/components/modals';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import {
    ProspectsListItem,
    InvestorChannels,
    ProspectStatus,
    ProspectStateValue,
    PROSPECT_STATE,
    CustomFieldFilter,
} from 'lender/prospects/prospects.interface';
import { Entity } from 'tpo/documents/documents.interface';
import { CellSelectComponent, CellSelectConfig } from 'commons/components/cell-select/cell-select.component';
import { map, shareReplay } from 'rxjs/operators';
import { ProspectsService } from 'lender/prospects/prospects.service';
import { Mixin } from 'utils/mixin.decorator';
import { ColumnsManagerMixin } from 'commons/mixins';
import { columnsConfig } from './columns-config';

@Component({
    templateUrl: './prospects-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ProspectsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ProspectsListComponent) },
    ],
})
@Mixin([ ColumnsManagerMixin ])
export class ProspectsListComponent extends NewPagedListComponent<ProspectsListItem> implements OnInit, ColumnsManagerMixin {
    // ColumnsManagerMixin
    // GOTCHA: objects and arrays should have default value to properly work with mixins
    StorageService;
    localStorageName = 'LenderProspectsCols';
    columns = new Map();
    columns$;
    columnValues: () => [];
    prepareColumns;
    toggleColumn;
    getColumnVisibility;


    static listName = 'prospectsList' as const;

    protected User: UserProfile;
    channelAssigneeOptions: Observable<Entity[]>;
    selectedAssignee: number;
    editing: boolean;
    saving: boolean;
    moreFilters: boolean;
    dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    modalRef: BsModalRef;

    get checkedProspects(): number[] {
        let checkedProspects = [];
        this.list.subscribe((list) => {
            checkedProspects = _map(_filter(list, {checked: true}), 'id');
        });
        return checkedProspects;
    }

    filters: Partial<{
        channels: Observable<InvestorChannels[]>,
        assigneeOptions: Observable<Entity[]>,
        statuses: Observable<ProspectStatus[]>,
        result: Observable<ProspectStateValue[]>,
        customFields: Observable<CustomFieldFilter[]>,
    }> = {};

    statusConfig: CellSelectConfig = {
        bindValue: 'name',
        bindLabel: 'name',
        title: 'Change Status',
        label: 'Status',
        message: this.getStatusMessage(),
    };

    assigneeConfig: CellSelectConfig = {
        bindValue: 'id',
        bindLabel: 'name',
        title: 'Change Assigned',
        label: 'Assigned',
        placeholder: 'Unassigned',
        clearable: true,
        message: '<p>Please choose new Assigned to set for the selected prospect</p>',
    };

    protected statuses$ = this.prospectsService.getStatuses();
    protected statusesWithNew$ = this.statuses$.pipe(
        map(statuses => [
            { name: 'New', stateId: PROSPECT_STATE.OPEN },
            ...statuses,
        ]),
        shareReplay(1),
    );

    constructor(
        router: UIRouter,
        userService: UserService,
        public prospectsService: ProspectsService,
        public modalService: BsModalService,
        private readonly notificationsService: GlobalNotificationsService,
    ) {
        super(router);
        this.User = userService.profile;

        this.configureFilters();

        this.params$.subscribe(({ organizationChannelId }) => {
            if(organizationChannelId) {
                this.channelAssigneeOptions = this.prospectsService.getChannelAssigneeOptions(organizationChannelId);
            }
        })
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.readColumnsConfig();
    }

    configureFilters(): void {
        this.setCustomFieldValue = debounce(this.setCustomFieldValue, 1000);

        this.filters = {
            channels: this.prospectsService.getProspectsChannels(true, true),
            assigneeOptions: this.prospectsService.getProspectsAssignee(),
            statuses: this.prospectsService.getProspectsStatuses(),
            result: this.prospectsService.getStateValues(),
            customFields: this.prospectsService.getCustomFields(),
        };

        this.defaultParams = {
            ...this.defaultParams,
            organizationChannelId: undefined,
            prospectAssigneeId: [],
            prospectStatus: [],
            prospectState: [],
            customFieldId: undefined,
            customFieldValue: null,
            size: 10,
            reassignable: undefined,
        };
    }

    readColumnsConfig() {
        this.prepareColumns(columnsConfig);
    }

    setFilter(filters: ListParams, resetPage: boolean = true): void {
        const processedFilters = _mapValues(filters, (value: any, key: string) => {
            if (key === 'prospectAssigneeId') {
                return _map(value || [], 'id');
            } else if (key === 'prospectStatus') {
                return _map(value || []);
            } else if (key === 'prospectState') {
                return _map(value || [], 'shortName');
            } else if (key === 'customFieldValue') {
                return isEmpty(value) ? null : value
            }

            return value?.id;
        });

        super.setFilter(processedFilters, resetPage);
    }

    setCustomFieldValue ({ target }: Event) {
        const customFieldValue = trim((target as HTMLInputElement).value);

        const currentParams = this.params$.getValue();
        if (currentParams.customFieldValue === customFieldValue) {
            // do not search on same input
            return;
        }

        this.setFilter({ customFieldValue });
    }

    toggleFilters(): void {
        this.moreFilters = !this.moreFilters;
    }

    protected loadList(params: ListParams): Observable<PagedData<ProspectsListItem[]>> {
        return this.prospectsService.getList(params);
    }

    getStatusMessage(pendingApplicationsExists = false) {
        let result = '<p>Please choose new Status to set for the selected prospect</p>';

        if (pendingApplicationsExists) {
            result = `<div class="well">There are application(s) that have not been approved yet</div>${result}`;
        }

        return result;
    }

    openStatus(component: CellSelectComponent, item: ProspectsListItem) {
        const { prospectStatus, id } = item;
        const values$ = prospectStatus === 'New' ? this.statusesWithNew$ : this.statuses$;
        component.open(values$, prospectStatus);
        this.statusConfig.message = this.getStatusMessage();
        component.setResolving(true);
        this.prospectsService.get(id).toPromise().then(({ pendingApplicationsExists }) => {
            this.statusConfig.message = this.getStatusMessage(pendingApplicationsExists);
            component.setResolving(false);
        });
    }

    async saveStatus([component, prospectStatus]: [CellSelectComponent, string], item: ProspectsListItem) {
        try {
            component.setResolving(true);
            const { id } = item;
            const prospect = await this.prospectsService.setStatus(id, prospectStatus).toPromise();
            Object.assign(item, prospect);
            component.setResolving(false);
            component.close();
        } catch ({ error }) {
            component.setError(error);
            component.setResolving(false);
        }
    }

    openAssigned(component: CellSelectComponent, item: ProspectsListItem) {
        const { assigneeUserId, id } = item;
        const values$ = this.prospectsService.getAssignee(id);
        component.open(values$, assigneeUserId);
    }

    async saveAssigned([component, assigneeUserId]: [CellSelectComponent, number], item: ProspectsListItem) {
        try {
            component.setResolving(true);
            const { id } = item;
            const prospect = await this.prospectsService.setAssigned(id, assigneeUserId).toPromise();
            Object.assign(item, prospect);
            component.setResolving(false);
            component.close();
        } catch ({ error }) {
            component.setError(error);
            component.setResolving(false);
        }
    }

    changeAssignment(): void {
        if (!this.params.organizationChannelId) {
            this.showSelectChannelModal();
            return
        }

        this.editing = true;

        this.params$.next({
            ...this.params$.getValue(),
            reassignable: true,
        });
    }

    async saveAssignment(): Promise<void> {
        this.saving = true;

        try {
            await this.prospectsService.bulkReassign({
                newAssigneeId: this.selectedAssignee,
                prospectIds: this.checkedProspects,
            }).toPromise();

            this.editing = false;

            this.params$.next({
                ...this.params$.getValue(),
                reassignable: undefined,
            });
        } catch ({error, status}) {
            if (status === 403) {
                this.editing = false;
                this.saving = false;
                throw new HttpErrorResponse({ status: 403 });
            } else {
                this.notificationsService.add({
                    type: GlobalNotificationType.ERROR,
                    message: error.message,
                });
            }
        }

        this.saving = false;
    }

    cancel(): void {
        this.editing = false;
        this.selectedAssignee = null;

        this.params$.next({
            ...this.params$.getValue(),
            reassignable: undefined,
        });
    }

    showSelectChannelModal(): void {
        this.modalRef = this.modalService.show(
            NotificationModalComponent,
            {
                initialState: {
                    title: 'Change Assignment',
                    notification: 'You must first select a channel before you can edit Assignment.',
                    confirmText: 'Ok',
                    onConfirm: () => {
                        this.modalRef.hide();
                    },
                },
                class: 'modal-smd modal-new',
            },
        );
    }
}
