<div class="document-request-modal">
	<div class="modal-header flex-row">
		<h4 class="modal-title flex">{{title}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	</div>
	<form [formGroup]="form" (onValidSubmit)="confirm()">
		<div class="modal-body remote-resource" [class.resolved]="!resolving">

			<form-errors [form]="form"></form-errors>

			<p [innerHTML]="message"></p>

			<div class="form-group">
				<label class="checkbox-inline">
					<input type="checkbox" formControlName="assignedButNotWaived"/>
					{{form.getFieldLabel('assignedButNotWaived')}}
				</label>
			</div>
			<div class="form-group">
				<label class="checkbox-inline">
					<input type="checkbox" formControlName="permanentlyWaived"/>
					{{form.getFieldLabel('permanentlyWaived')}}
				</label>
			</div>
			<div class="form-group">
				<label class="checkbox-inline">
					<input type="checkbox" formControlName="oneTimeWaived"/>
					{{form.getFieldLabel('oneTimeWaived')}}
				</label>
			</div>
		</div>
		<div class="modal-footer text-right" [class.curtain]="resolving">
			<button type="submit" class="btn btn-primary" [disabled]="form.invalid">{{confirmText}}</button>
			<button type="button" (click)="onCancel()" class="btn btn-secondary">{{cancelText}}</button>
		</div>
	</form>
</div>
