import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class CodeValueService {
	private resource: any;

	constructor(public injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/code-value/:code`;

		this.resource = $resource(`${basePath}`, {}, {
			get: {
				cacheConfig: {
					url: `${apiPath}/code-value/state`,
				},
				method: 'get',
				isArray: true,
			},
		});
	}

	get = (...args) => this.resource.get(...args);
}
