import { NgModule } from '@angular/core';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { ManageChannelLandingPageComponent } from 'lender/manage-channels/manage-channel-landing-page.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { ChannelCardComponent } from 'lender/manage-channels/channel-card.component';
import { ChannelCardOwnershipAndDescriptionComponent } from 'lender/manage-channels/channel-card-ownership-and-description/channel-card-ownership-and-description.component';
import { ChannelCardApplicationComponent } from 'lender/manage-channels/channel-card-new-and-renewal-application/channel-card-application.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
    ChannelManageNotificationsModalComponent,
} from 'lender/manage-channels/channel-manage-notifications-modal/channel-manage-notifications-modal.component';
import { ScreenApplicantToggleModalComponent } from 'lender/manage-channels/channel-card-new-and-renewal-application/manage-channel-screen-applicant-toggle/screen-applicant-toggle-modal.component';
import {
    ManageChannelAlertsComponent,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.component';
import {
    ManageChannelAlertGroupComponent,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alert-group.component';
import {
    AlertsSortingService,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.service';
import { ChannelHistoryComponent } from 'lender/manage-channels/channel-card-ownership-and-description/channel-card-history/channel-history.component';
import {
    ManageChannelAlertItemComponent,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts-item/manage-channel-alerts-item.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        ReactiveFormsModule,
        BsDropdownModule,
    ],
    providers: [
        ManageChannelService,
        AlertsSortingService,
    ],
    declarations: [
        ManageChannelLandingPageComponent,
        ChannelCardComponent,
        ChannelCardOwnershipAndDescriptionComponent,
        ChannelCardApplicationComponent,
        ChannelManageNotificationsModalComponent,
        ScreenApplicantToggleModalComponent,
        ManageChannelAlertsComponent,
        ManageChannelAlertGroupComponent,
        ManageChannelAlertItemComponent,
        ChannelHistoryComponent,
    ],
})
export class ManageChannelModule {}
