<div class="home">
    <ng-container *ngIf="isSectionEnabled('share')">
        <tpo-home-share-requests
            (onLoaded)="setListLoaded('share', $event)"
        ></tpo-home-share-requests>
    </ng-container>
	<ng-container *ngIf="isSectionEnabled('invites')">
		<invites
			(onLoaded)="setListLoaded('invites', $event)"
		></invites>
	</ng-container>
	<ng-container *ngIf="isSectionEnabled('documents')">
		<required-documents-partners
			(onLoaded)="setListLoaded('documents', $event)"
		></required-documents-partners>
	</ng-container>
	<ng-container *ngIf="isSectionEnabled('questionnaires')">
		<client-assigned-questionnaires
			(onLoaded)="setListLoaded('questionnaires', $event)"
		></client-assigned-questionnaires>
	</ng-container>
	<ng-container *ngIf="isSectionEnabled('applications')">
		<pending-applications
			(onLoaded)="setListLoaded('applications', $event)"
		></pending-applications>
	</ng-container>
</div>
