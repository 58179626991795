import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { UserService } from 'angularjs-providers/user.provider';
import { map } from 'rxjs/operators';
import { ListParams } from 'commons/components/new-list/list.component';

declare let apiPath: string;

export const COLUMNS_AVAILABLE = ['nmlsId', 'email', 'phone', 'title', 'street', 'suite', 'zip'] as const;
export type ContactImportColumnKey = typeof COLUMNS_AVAILABLE[number];
export type ContactImportUpload = {
    id: number;
}
export type ContactImportColumnMappings = Partial<Record<ContactImportColumnKey, ContactImportColumn>>;
export type ContactImport = {
    columnMappingInfo?: ContactImportColumn[];
    mappings?: ContactImportColumnMappings;
}
export type ContactImportColumn = {
    columnNumber: number;
    fileColumnName: string;
    sampleData: string[];
};
export type ContactImportItem = {
    id: number;
    importedBy: string;
    importedDate: number;
    originalFileName: string;
    status: string;
    successCount: number | null;
    failedCount: number | null;
    downloadLink?: string;
    failedLink?: string;
};

@Injectable()
export class ContactsImportService {
    constructor(
        private readonly http: RealmHttpClient,
        private readonly userService: UserService,
    ) {
    }

    get tpoId(): number {
        return this.userService.profile.organization.id;
    }

    get urlPrefix(): string {
        return `${apiPath}/tpos/${this.tpoId}/contacts/import`;
    }

    public getImportList(params: ListParams): Observable<PagedData<ContactImportItem[]>> {
        return this.http.pagedRequest<ContactImportItem[]>(
            'GET',
            `${this.urlPrefix}`,
            params,
        ).pipe(
            map(({ data, pagination }) => (
                {
                    data: data.map(({ id, failedCount, ...rest }) => ({
                        id,
                        failedCount,
                        ...rest,
                        downloadLink: `${this.urlPrefix}/${id}/uploaded`,
                        failedLink: failedCount ? `${this.urlPrefix}/${id}/failed` : null,
                    })),
                    pagination,
                })),
        );
    }

    public uploadContactsImport(file: File): Observable<ContactImportUpload> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.request(
            'POST',
            `${this.urlPrefix}`,
            null,
            formData,
        );
    }

    public getColumns(importId: number): Observable<ContactImportColumn[]> {
        return this.http.request<ContactImport>(
            'GET',
            `${this.urlPrefix}/${importId}/column-mapping-options`,
        ).pipe(
            map(({ columnMappingInfo }) => columnMappingInfo),
        );
    }

    public saveMappingAndImport(importId: number, importData: ContactImport): Observable<ContactImportUpload> {

        return this.http.request(
            'POST',
            `${this.urlPrefix}/${importId}/column-mapping`,
            null,
            importData,
        );
    }
}
