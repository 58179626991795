import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tel',
})
export class TelPipe implements PipeTransform {
	transform(value: string | number | null | undefined): string | null {
		if (!value) {
			return null;
		}

		const cleanedValue = `${value}`
			.replace(/\s/g, '')
			.replace(/^\+/, '')
			.replace(/[()]/g, '');

		if (!/^\d+$/.test(cleanedValue)) {
			return `${value}`;
		}

		const parsedValue = ((input: string): {
			countryCode: string;
			cityCode: string;
			phoneNumber: string;
		} => {
			switch (input.length) {
				// PPP####### -> C (PPP) ###-####
				case 10:
					return {
						countryCode: '1',
						cityCode: input.slice(0, 3),
						phoneNumber: input.slice(3),
					};
				// +CPPP####### -> C (PPP) ###-####
				case 11:
					return {
						countryCode: input[0],
						cityCode: input.slice(1, 4),
						phoneNumber: input.slice(4),
					};
				// +CCCPP####### -> CCC (PP) ###-####
				case 12:
					return {
						countryCode: input.slice(0, 3),
						cityCode: input.slice(3, 5),
						phoneNumber: input.slice(5),
					};
				default:
					return null;
			}
		})(cleanedValue);

		if (!parsedValue) {
			return `${value}`;
		}

		const { countryCode, cityCode, phoneNumber } = parsedValue;
		return [
			...(countryCode === '1' ? [] : [ countryCode ]),
			`(${cityCode})`,
			`${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`,
		].join(' ');
	}
}
