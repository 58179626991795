<span
    class="text-muted text-big glyphicon glyphicon-question-sign"
    placement="top"
    [tooltip]="passTemplate"
></span>

<ng-template #passTemplate>
    <div class="text-small">
        Length: 8-16 characters <br>
        Must Contain 3 of the following character types:
        <ul>
            <li>
                UPPERCASE LETTER(S)
            </li>
            <li>
                lowercase letter(s)
            </li>
            <li>
                Number(s)
            </li>
            <li>
                Special Characters(!&#64;#$%^&*)
            </li>
        </ul>
    </div>
</ng-template>