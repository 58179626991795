import { Directive, HostBinding, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Directive({
	selector: '[srcTest]',
})
export class SrcTestDirective {
	@Input() srcTest: string;
	@Input() srcDefault?: string;
	@HostBinding('attr.src') public src: any;

	constructor(
		public http: HttpClient,
	) {}

	ngOnChanges() {
		if (this.srcTest) {
			const img = new Image();
			img.src = this.srcTest;
			img.onload = (event) => {
				this.src = this.srcTest;
			};
			img.onerror = () => {
				this.src = this.srcDefault;
			};
		} else if (this.srcDefault) {
			this.src = this.srcDefault;
		}
	}
}
