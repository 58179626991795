import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[persistant]',
})
export class CardPersistantPartDirective {
    constructor(public template: TemplateRef<unknown>) {
        console.log('PersistantDirective');
    }
}
