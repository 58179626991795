<div>
    <h2 class="gray-head">
        Add New Finding
    </h2>
    <div class="remote-resource" [class.resolved]="!resolving">
        <form [formGroup]="addFindingForm" (onValidSubmit)="addNewFinding()">
            <h3>Information</h3>
            <form-errors [form]="addFindingForm"></form-errors>
            <div class="form-group">
                <label class="checkbox-inline">
                    <input type="checkbox" formControlName="notify" tabindex=1 (change)="notifyChange()">
                    Notify Customer
                </label>
            </div>
            <div class="alert alert-warning form-inline" *ngIf="!isNotify">
                WARNING! Unchecking this box will prevent a lender from receiving an alert. To confirm, type in the statement exactly as shown,
                "Do Not Notify" to save and proceed.
                <br/><br/>
                <div class="form-group row" has-error>
                    <label class="control-label required">&nbsp;&nbsp;&nbsp;Statement</label> &nbsp;&nbsp;
                    <input class="form-control" formControlName="statement">
                </div>
            </div>
            <div class="rows-striped">
                <div class="row-fluid">
                    <div class="col-md-6">
                        <div class="form-group row" has-error>
                            <label class="col-sm-4 control-label text-right required">Category</label>
                            <div class="col-sm-8">
                                <ng-select
                                formControlName="findingCategory"
                                placeholder="Select Type"
                                [items]="scorecardFindingCategoryList"
                                bindValue="code"
                                bindLabel="abbreviation"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                   <div class="col-md-6">
                        <div class="form-group row" has-error>
                            <label class="col-sm-4 control-label text-right required">Related To</label>
                            <div class="col-sm-8">
                                <ng-select
                                formControlName="individualId"
                                placeholder="Select Type"
                                [loading]="resolved"
                                [items]="filteredOwnerList"
                                bindLabel="name"
                                bindValue="id"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-12">
                        <div class="form-group row" has-error>
                            <label class="col-sm-2 control-label text-right required">Summary</label>
                            <div class="col-sm-10">
                                <textarea formControlName="summary" class="form-control resize-vertical" rows="3" maxlength="400" required></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <h3>Supporting Documents</h3>
            <scorecard-supporting-documents
                [files]="addFindingForm.controls.files.value"
                (fileAdded)="addNewFile($event)"
                (fileReplaced)="replaceFile($event)"
                (fileRemoved)="removeFile($event)"
            ></scorecard-supporting-documents>
            <br>
            <div class="text-right btn-group-lg">
                <button type="submit" class="btn btn-primary">
                    Save
                </button>
                <button class="btn btn-default" (click)="cancelAddFinding()">Cancel</button>
            </div>
        </form>
    </div>
</div>

