import { Component, Input } from '@angular/core';

@Component({
	templateUrl: './embed-code.component.html',
	selector: 'embed-code',
})
export class EmbedCodeComponent {
	@Input() code: string;
	buttonName = 'Copy';
	disabled: boolean;

	notify(): void {
		this.buttonName = 'Copied!'
		this.disabled = true;
		setTimeout(() => {
			this.buttonName = 'Copy'
			this.disabled = false;
		}, 2000);
	}
}
