import { Component, Input, OnInit } from '@angular/core';

import { UserProfile } from 'angularjs-providers/user.provider';

import { EmploymentHistory } from '../interfaces/employment-history.interface';


@Component({
    selector: 'nmls-individual-companies',
    templateUrl: './nmls-individual-companies.component.html',
})
export class NmlsIndividualCompaniesComponent implements OnInit {
    @Input() userProfile: UserProfile;
    @Input() companiesResolving: boolean;
    @Input() companies: EmploymentHistory[];
    @Input() individualNmlsId: number;

    userIsTpo: boolean = false;

    // Override
    ngOnInit(): void {
        this.userIsTpo = this.userProfile.isTpo;
    }
}
