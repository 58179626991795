import { map, filter, omit, isEmpty } from 'lodash';
import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class TPOArchiveResourceService {
	public list: any;
	public finding: any;
	public postHistory: any;
	public comments: any;
	public entities: any;
	public mediaContentTypes: any;
	public accountTypes: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const globalArchive = `${apiPath}/tpos/:tpoId/social-media-compliance/global-archive`;
		const connected = `${globalArchive}/archive`; // TODO: change route to connected
		const defaultParams = { tpoId: User.profile.organization.id };

		this.list = PagedResource(
			`${globalArchive}/connected`, // TODO: change `${globalArchive}/connected` to const "connected"
			{
				tpoId: User.profile.organization.id,
			},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				transformResponse(data) {
					return map(JSON.parse(data), (post: any) => {
						if (post && post.attachments) {
							post.commentedAttachments = filter(post.attachments, (item) => {
								return item.comments && item.comments.totalElements;
							});
						}

						return post;
					});
				},
			},
		);

		this.finding = $resource(
			`${globalArchive}/finding`, defaultParams, {
				save: {
					method: 'post',
					headers: { 'Content-Type': undefined },
					transformRequest(item) {
						const data = new FormData();

						// required finding part
						const findingFields = omit(item, ['file', 'documentProperties']);
						const finding = new Blob([JSON.stringify(findingFields)], {
							type: 'application/json',
						});

						data.append('finding', finding);

						if (!item.file || isEmpty(item.documentProperties)) {
							return data;
						}

						// optional document part
						const docProperties = new Blob([JSON.stringify(item.documentProperties)], {
							type: 'application/json',
						});

						data.append('documentProperties', docProperties);
						data.append('file', item.file);

						return data;
					},
				},
			},
		);

		this.postHistory = $resource(
			`${connected}/:smAccountId/:contentType/:postId/:version/history`, defaultParams, {
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/global45archive45resource/getReviewArchivePostCommentsForTPOUsingGET
		this.comments = $resource(
			`${connected}/:smAccountId/:contentType/:id/:version/comments`, defaultParams, {
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/global45archive45resource/getTpoEntitiesUsingGET
		this.entities = $resource(
			`${connected}/filter/entities`, defaultParams, {
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.mediaContentTypes = $resource(
			`${connected}/filter/mediaContentTypes`, defaultParams, {
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/global45archive45resource/getSourcesFilterForTpoUsingGET
		this.accountTypes = $resource(
			`${connected}/filter/sources`, defaultParams, {
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}
}
