<div class="client-financial-settings-modal">
	<div class="modal-header">
		<button
			type="button"
			class="close pull-right"
			aria-label="Close"
			(click)="onConfirm && onConfirm(); modalRef.hide()"
		>&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<p class="guidance-text mb20" [innerHTML]="guidanceText"></p>

		<div *ngIf="settings">
			<div class="settings-header">
				<h4 class="font-bold">Global Settings</h4>
				<h4 class="font-bold">Client Specific Settings</h4>
			</div>

			<div
				class="settings-row"
				*ngFor="let globalChannel of settings.globalSettings; index as i; first as isFirst; last as isLast"
			>
				<ng-container *ngxInit="settings.clientSettings[i] as clientChannel">

					<div class="settings-block" [ngClass]="{ first: isFirst, last: isLast, 'text-muted': clientChannel.settings?.length }">
						<b class="text-bigger">{{globalChannel.name}}</b>&nbsp;
						<i
							*ngIf="!clientChannel.settings?.length"
							class="glyphicon glyphicon-ok-sign text-success text-big"
						></i><br>
						<div class="text-muted text-smaller">Automated Schedule</div>
						<ng-container *ngIf="globalChannel.settings?.length; else empty">
							<div *ngFor="let setting of globalChannel.settings">
								{{setting.title}}
							</div>
						</ng-container>
						<ng-template #empty>
							&ndash;
						</ng-template>
					</div>

					<div class="settings-block" [ngClass]="{ first: isFirst, last: isLast, 'text-muted': !clientChannel.settings?.length }">
						<b class="text-bigger">{{clientChannel.name}}</b>&nbsp;
						<i
							*ngIf="clientChannel.settings?.length"
							class="glyphicon glyphicon-ok-sign text-success text-big"
						></i><br>

						<div [isEditable]="clientChannel.editing">
							<div view>
								<span class="text-muted text-smaller">Automated Schedule</span>&nbsp;
								<a class="btn btn-tertiary btn-icon text-light text-smaller" (click)="edit(clientChannel)">
									<span class="glyphicon glyphicon-pencil"></span>
								</a><br>

								<ng-container *ngIf="clientChannel.settings?.length; else inherited">
									<div *ngFor="let setting of clientChannel.settings">
										{{setting?.title}}
									</div>
								</ng-container>
								<ng-template #inherited>
									<i class="c-icon c-icon-globe-lines"></i>&nbsp;Global Inherited
								</ng-template>
							</div>

							<div edit>
								<ng-select
									class="mb5"
									[(ngModel)]="clientChannel.settings"
									(ngModelChange)="updateDisabledStatus($event)"
									(focus)="updateDisabledStatus(clientChannel.settings)"
									[items]="options"
									[loading]="!options"
									[multiple]="true"
									[closeOnSelect]="false"
									bindLabel="title"
									bindvalue="title"
								></ng-select>
								<div class="pull-right">
									<a class="btn btn-icon btn-tertiary" (click)="save(clientChannel)">
										<span class="glyphicon glyphicon-ok"></span>
									</a>
									<a class="btn btn-icon btn-tertiary" (click)="cancel(clientChannel)">
										<span class="glyphicon glyphicon-remove"></span>
									</a>
								</div>
							</div>
						</div>
					</div>

				</ng-container>
			</div>
		</div>

	</div>
	<div class="modal-footer text-right" [class.curtain]="resolving">
		<button class="btn btn-primary" (click)="onConfirm && onConfirm(); modalRef.hide()" [disabled]="closeDisabled">
			{{confirmText}}
		</button>
	</div>
</div>