<div class="audit-status-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
		<h4 class="modal-title">Change Audit Status</h4>
	</div>

	<form [formGroup]="form" (onValidSubmit)="submit()">
		<div class="modal-body remote-resource" [class.resolved]="resolved">

			<form-errors [form]="form"></form-errors>

			<ng-container *ngIf="createMode">
				<p>Create the name of your status.</p>

				<p class="text-danger">Please note, once a status has been created, it can not be removed.</p>

				<div class="form-group row" [class.has-error]="statusControl.showError">
					<label class="col-sm-4 control-label text-right required">{{statusControl.label}}</label>
					<div class="col-sm-8">
						<input type="text" maxlength="30" class="form-control" [formControl]="statusControl"/>
					</div>
				</div>
				<div class="form-group row" [class.has-error]="conditionControl.showError">
					<label class="col-sm-4 control-label text-right required">{{conditionControl.label}}</label>
					<div class="col-sm-8">
						<label class="radio-inline">
							<input type="radio" [formControl]="conditionControl" value="C"/>
							Closed
						</label>
						<label class="radio-inline">
							<input type="radio" [formControl]="conditionControl" value="I"/>
							In Progress
						</label>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="!createMode">
				<p>Please select Audit Status to set for this audit.</p>

				<div class="form-group row">
					<div class="col-sm-9">
						<ng-select
							[multiple]="false"
							[clearable]="false"
							[items]="statuses.$promise | async"
							[loading]="!statuses.$resolved"
							bindLabel="status"
							[placeholder]="statusControlPlaceholder"
							[formControl]="statusControl"
						></ng-select>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="modal-footer" [class.curtain]="!resolved">
			<div class="clearfix">
				<div class="btn-group-lg pull-left">
					<button type="button" class="btn btn-default" (click)="toggleCreateMode()">
						{{createMode ? 'Back' : 'Create New Status'}}
					</button>
				</div>
				<div class="btn-group-lg pull-right">
					<button type="submit" class="btn btn-primary">Save</button>
					<button type="button" class="btn btn-default" (click)="close()">Cancel</button>
				</div>
			</div>
		</div>
	</form>
</div>
