import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ClientInterface } from 'shared/global-search/clients/client.interface';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { GlobalSearchService } from 'shared/global-search/global-search.service';
import { GlobalSearchDataService } from 'shared/global-search/global-search-data.service';
import { tap } from 'rxjs/operators';

@Component({
    templateUrl: './clients.component.html',
    styles: ['.table-row {padding: 10px; border-bottom: 1px solid #E6E7EC}'],
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => SearchClientsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => SearchClientsListComponent) },
    ],
})
export class SearchClientsListComponent extends NewPagedListComponent<ClientInterface> implements OnInit {
    static listName = 'globalSearchClients';
    user: any;
    userIsLender: boolean;
    userIsLenderAndCrmEnabled: boolean;
    userCanInviteToApply: boolean;

    constructor(
        public router: UIRouter,
        public stateService: StateService,
        public userService: UserService,
        private globalSearchService: GlobalSearchService,
        private globalSearchDataService: GlobalSearchDataService,
    ) {
        super(router);
        this.globalSearchService = globalSearchService;
        this.user = userService.profile;
        this.globalParams.push('q');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.userIsLender = this.user.isLender;
        this.userIsLenderAndCrmEnabled = this.userIsLender && this.user.crmEnabled;
        this.userCanInviteToApply = this.user.can('INVITE_TO_APPLY');
        this.globalSearchDataService.resetClientCount();
    }

    protected loadList(params: ListParams): Observable<PagedData<ClientInterface[]>> {
        return this.globalSearchService.getClientsList(params).pipe(
            tap(data => {
                this.globalSearchDataService.getClientCount(data);
            })
        );
    }
}
