import { Injectable } from '@angular/core';
import { Observable  } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { ListParams } from 'commons/components/new-list/list.component';

import {
    DocumentContactsEntry,
    IDocumentLenderContactsEntry,
} from 'lender/clients/$id/documents/client-documents.interface';
import { RequestableDocumentService } from 'lender/clients/$id/documents/client-documents.service';
import {
    ClientFinancialSettingOption,
    ClientFinancialSettings,
    ClientFinancialSettingsData,
    FulfilledFinancialDocumentRequest,
    FinancialDocumentHistoryItem,
    FinancialDocumentOpenRequestItem,
    FinancialHistoryParams,
    FinancialHistoryRequestData,
    FinancialNotificationRequest,
    RequestFinancialHistoryModalParams,
    TPOFinancialDocument,
    TPOFinancialDocumentFields,
    TPOFinancialDocumentType,
    TPOFinancialDocumentUpload,
    WaiveData,
} from './financial-documents.interface';

declare let apiPath: string;

@Injectable({ providedIn: 'root' })
export class FinancialDocumentResourceService implements RequestableDocumentService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {}

    public getTpoFinancialDocuments(
        tpoId: number,
        listParams?: any
    ): Observable<PagedData<TPOFinancialDocument[]>> {
        return this.http.pagedRequest<TPOFinancialDocument[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/financial-documents`,
            listParams
        );
    }

    public getTpoFinancialDocumentsForInvestor(
        tpoId: number,
        investorId: number,
        listParams?: ListParams
    ): Observable<PagedData<TPOFinancialDocument[]>> {
        return this.http.pagedRequest<TPOFinancialDocument[]>(
            'GET',
            `${apiPath}/lenders/${investorId}/tpos/${tpoId}/financial-documents`,
            listParams
        );
    }

    public getFinancialHistoryList(
        { lenderId, tpoId, financialDocumentType, listParams  }: FinancialHistoryParams,
    ): Observable<PagedData<FinancialDocumentHistoryItem[]>> {
        const lenderPath = lenderId ? `/lenders/${lenderId}` : '';
        return this.http.pagedRequest<FinancialDocumentHistoryItem[]>(
            'GET',
            `${apiPath}${lenderPath}/tpos/${tpoId}/financial-documents/${financialDocumentType}/history`,
            listParams,
        );
    }

    public getDocumentHistoricalRequest(
        { lenderId, tpoId, financialDocumentType, historyId }: RequestFinancialHistoryModalParams,
    ): Observable<FinancialHistoryRequestData> {
        const lenderPath = lenderId ? `/lenders/${lenderId}` : '';
        return this.http.request<FinancialHistoryRequestData>(
            'GET',
            `${apiPath}${lenderPath}/tpos/${tpoId}/financial-documents/${financialDocumentType}/history/${historyId}/request`,
        );
    }

    public getDocumentRequestsHistory(
        { lenderId, tpoId, financialDocumentType, listParams }: FinancialHistoryParams,
    ): Observable<PagedData<FinancialDocumentOpenRequestItem[]>> {
        const lenderPath = lenderId ? `/lenders/${lenderId}` : '';
        return this.http.pagedRequest<FinancialDocumentOpenRequestItem[]>(
            'GET',
            `${apiPath}${lenderPath}/tpos/${tpoId}/financial-documents/${financialDocumentType}/open-requests`,
            listParams,
        );
    }

    public getFinancialWaiveHistory(
        { financialDocumentType, lenderId, tpoId, historyId }: RequestFinancialHistoryModalParams
    ): Observable<WaiveData> {
        const path = `${apiPath}/lenders/${lenderId}/tpos/${tpoId}`;
        return this.http.request<WaiveData>(
            'GET',
            `${path}/financial-documents/${financialDocumentType}/history/${historyId}/waive`,
        );
    }

    public waiveFinancialDocument(
        financialDocumentType: TPOFinancialDocumentType,
        lenderId: number,
        tpoId: number,
        requestId: number,
        comment: string
    ): Observable<void> {
        const path = `${apiPath}/lenders/${lenderId}/tpos/${tpoId}`;
        return this.http.request<void>(
            'POST',
            `${path}/financial-documents/${financialDocumentType}/open-requests/${requestId}/waived`,
            null,
            { comment }
        );
    }

    public getClientFinancialSettings(
        financialDocumentType: TPOFinancialDocumentType,
        lenderId: number,
        tpoId: number,
    ): Observable<ClientFinancialSettings> {
        const path = `${apiPath}/lenders/${lenderId}/tpos/${tpoId}`;
        return this.http.request<ClientFinancialSettings>(
            'GET',
            `${path}/financial-documents/${financialDocumentType}/settings`,
        ).pipe(shareReplay(1));
    }

    public updateClientFinancialSettings(
        financialDocumentType: TPOFinancialDocumentType,
        lenderId: number,
        tpoId: number,
        clientChannel: ClientFinancialSettingsData,
    ): Observable<ClientFinancialSettings> {
        const path = `${apiPath}/lenders/${lenderId}/tpos/${tpoId}`;
        return this.http.request<ClientFinancialSettings>(
            'PUT',
            `${path}/financial-documents/${financialDocumentType}/channels/${clientChannel.channelId}/settings`,
            null,
             clientChannel.settings
        );
    }

    public getClientFinancialSettingOptions(
        financialDocumentType: TPOFinancialDocumentType,
        lenderId: number,
        tpoId: number,
    ): Observable<ClientFinancialSettingOption[]> {
        const path = `${apiPath}/lenders/${lenderId}/tpos/${tpoId}`;
        return this.http.request<ClientFinancialSettingOption[]>(
            'GET',
            `${path}/financial-documents/${financialDocumentType}/settings-options`,
        );
    }

    // public getDocumentFields(
    //     tpoId: number
    // ): Observable<TPOFinancialDocumentFields> {
    //     return this.http.request<TPOFinancialDocumentFields>(
    //         'GET',
    //         `${apiPath}/tpos/${tpoId}/financial-documents/fields`
    //     );
    // }

    public getLenderNotificationUsers = <T = DocumentContactsEntry>({ lenderId, tpoId }): Observable<T[]> => (
        this.http.request<T[]>(
            'GET',
            `${apiPath}/lenders/${lenderId}/tpos/${tpoId}/financial-documents/lender-users`,
        )
    )

    public getTpoNotificationUsers = <T = IDocumentLenderContactsEntry>({ lenderId, tpoId }): Observable<T[]> => (
        this.http.request<T[]>(
            'GET',
            `${apiPath}/lenders/${lenderId}/tpos/${tpoId}/financial-documents/tpo-users`,
        )
    )

    public requestTpoDocument(
        tpoId: number,
        investorId: number,
        financialDocumentType: TPOFinancialDocumentType,
        body: FinancialNotificationRequest,
    ): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${apiPath}/lenders/${investorId}/tpos/${tpoId}/financial-documents/${financialDocumentType}/requested`,
            null,
            body,
        );
    }

    public uploadFinancialDocument(
        tpoId: number,
        financialDocumentType: TPOFinancialDocumentType,
        document: File,
        details: TPOFinancialDocumentUpload
    ): Observable<TPOFinancialDocumentUpload> {
        const formData = new FormData();
        formData.append('document', document);
        formData.append('details', JSON.stringify(details));

        return this.http.uploadFileWithForm(
            'PUT',
            `${apiPath}/tpos/${tpoId}/financial-documents/${financialDocumentType}`,
            'document',
            'details',
            document,
            details
        );
    }

    public markDocumentReviewed(lenderId: number, tpoId: number, financialDocumentId: number):Observable<any> {
        return this.http.request<any>(
            'PUT',
            `${apiPath}/lenders/${lenderId}/tpos/${tpoId}/financial-documents/document/${financialDocumentId}/review-status`,
        )
    }

    // TODO: Not actually resource-related
    public getDocumentTypeLabel(type: TPOFinancialDocumentType): string {
        switch (type) {
            case 'BALANCE_SHEET':
            default:
                return 'Balance Sheet';
            case 'INCOME_PROFIT_LOSS_STATEMENT':
                return 'Income (Profit / Loss) Statement';
            case 'FINANCIAL_STATEMENT':
                return 'Financial Statement';
            case 'STATEMENT_OF_CASH_FLOWS':
                return 'Statement of Cash Flows';
        }
    }

    public getFulfilledRequests(
        lenderId: number,
        tpoId: number,
        documentType: string,
    ): Observable<FulfilledFinancialDocumentRequest[]> {
        return this.http.request<FulfilledFinancialDocumentRequest[]>(
            'GET',
            `${apiPath}/lenders/${lenderId}/tpos/${tpoId}/financial-documents/${documentType}/check-uploaded`
        )
    }

}
