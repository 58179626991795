import { StateServiceProvider } from './state.provider';
import { $rootScopeServiceProvider, RootScopeServiceProvider } from './root-scope.provider';
import { $scopeProvider, ScopeServiceProvider } from './scope.provider';
import { PagedResourceServiceProvider } from './paged-resource.provider';
import { ModalServiceProvider } from './modal.provider';
import { TimezonesListProvider, TimezonesServiceProvider } from './time-zones.provider';
import { HistoryLogServiceProvider } from './history-log-service.provider';
import { StaticValuesProvider } from './static-values.provider';

const AngularJSServiceProviders = [
	StateServiceProvider, // Layer added
    $rootScopeServiceProvider, // Mocked
    RootScopeServiceProvider, // Mocked
    $scopeProvider, // Mocked
    ScopeServiceProvider, // Mocked
	PagedResourceServiceProvider, // Layer added
    ModalServiceProvider, // Check
    TimezonesServiceProvider, // Layer added
    TimezonesListProvider, // Layer added
    StaticValuesProvider, // Layer added
];

export { AngularJSServiceProviders };
