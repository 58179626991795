import { Component, Input } from '@angular/core';
import { ScorecardFindingDetailRow } from 'shared/due-diligence/scorecard/scorecard-finding.interface';


@Component({
    selector: 'scorecard-finding-details',
    templateUrl: './scorecard-finding-details.component.html',
})
export class ScorecardFindingDetailsComponent {
    @Input() detailRows: ScorecardFindingDetailRow[];
}