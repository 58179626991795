import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { Entity } from 'tpo/documents/documents.interface';
declare let apiPath: string;

export type TOField = {
    id: string;
    name: string;
    values: Entity[];
};

export type TechOps = Record<string, Entity[]>

@Injectable()
export class SharedTechOpsService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getFields(): Observable<TOField[]> {
        return this.http.request<TOField[]>(
            'GET',
            `${apiPath}/tech-ops/fields`,
        )
    }

    get(tpoId: number): Observable<TechOps> {
        return this.http.request<TechOps>(
            'GET',
            `${apiPath}/tpos/${tpoId}/tech-ops`,
        )
    }

    save(tpoId: number, value: TechOps): Observable<TechOps> {
        return this.http.request<TechOps>(
            'PUT',
            `${apiPath}/tpos/${tpoId}/tech-ops`,
            {},
            value
        )
    }
}
