import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { BulkRequestSnapshot } from 'lender/manage-channels/manage-channel-card.interface';


declare let apiPath;

@Injectable()
export class BulkRequestSnapshotService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getBulkRequestSnapshot(lenderId: number, bulkRequestId: number): Observable<BulkRequestSnapshot> {
        return this.http.request<BulkRequestSnapshot>(
            'GET',
            `${apiPath}/lenders/${lenderId}/bulk-requests/${bulkRequestId}`
        )
    }
}
