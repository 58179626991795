<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()" *ngIf="!isEditing">&times;</button>
        <h4 class="modal-title text-left">Screening Information</h4>
    </div>

    <form [formGroup]="screenApplicantForm" (onValidSubmit)="submit()">
        <div class="modal-body remote-resource" [class.resolved]="!resolving">
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-sm-12">
                        <div class="form-group row" has-error>
                            <label class="col-sm-3 control-label text-left" [class.required]="isEditing">{{ isEditing ? screenApplicantForm.getFieldLabel('name') : screenApplicantForm.getFieldLabel('name') + ':'}}</label>
                            <div class="col-sm-9 text-left">
                                <div [isEditable]="isEditing">
                                    <span view class="control-value">{{screenApplicantInfo.channelSettings.contact.name || '&ndash;'}}</span>
                                    <input edit type="text" name="name" formControlName="name" class="form-control" maxlength="100" tabindex="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-fluid">
                    <div class="col-sm-12">
                        <div class="form-group row" has-error>
                            <label class="col-sm-3 control-label text-left" [class.required]="isEditing">{{isEditing ? screenApplicantForm.getFieldLabel('phone') : screenApplicantForm.getFieldLabel('phone') + ':' }}</label>
                            <div class="col-sm-9">
                                <div [isEditable]="isEditing">
                                    <span view class="control-value">{{screenApplicantInfo.channelSettings.contact.phone || '&ndash;' | tel}}</span>
                                    <input edit phoneFormatter type="text" name="phone" formControlName="phone" class="form-control" tabindex="1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-sm-12">
                        <div class="form-group row" has-error>
                            <label class="col-sm-3 control-label text-left" [class.required]="isEditing">{{isEditing ? screenApplicantForm.getFieldLabel('email') : screenApplicantForm.getFieldLabel('email') + ':'}}</label>
                            <div class="col-sm-9">
                                <div [isEditable]="isEditing">
                                    <span view class="control-value">{{screenApplicantInfo.channelSettings.contact.email || '&ndash;'}}</span>
                                    <input edit type="text" name="email" formControlName="email" class="form-control" tabindex="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-sm-12">
                        <div class="form-group row" has-error>
                            <label class="col-sm-3 control-label text-left" [class.required]="isEditing">{{isEditing ? screenApplicantForm.getFieldLabel('webSite') : screenApplicantForm.getFieldLabel('webSite') + ':'}}</label>
                            <div class="col-sm-9">
                                <div [isEditable]="isEditing">
                                    <span view class="control-value">
                                        <a [href]="screenApplicantInfo.channelSettings.webSite | url" target="_blank">{{screenApplicantInfo.channelSettings.webSite || '&ndash;'}}</a></span>
                                    <input edit type="text" name="webSite" formControlName="webSite" class="form-control" tabindex="1" maxlength="255" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-sm-12" [isEditable]="isEditing">
                        <div class="form-group row" has-error edit>
                            <div class="col-sm-12">
                                <label class="control-label text-left required">{{screenApplicantForm.getFieldLabel('description')}}</label>
                            </div>
                            <div class="col-sm-12">
                                <textarea id="description" rows="3" name="description" formControlName="description" class="form-control" maxlength="1000" tabindex="1"></textarea>
                            </div>
                            <div>
                                <div class="characters text-muted text-smaller">&nbsp;&nbsp; &nbsp;Count: {{getCharsCount()}}</div>
                            </div>
                        </div>
                        <div class="form-group row" view>
                            <div class="col-sm-3">
                                <label class="control-label text-left">{{screenApplicantForm.getFieldLabel('description') + ':'}}</label>
                            </div>
                            <div class="col-sm-9">
                                <span class="control-value">{{screenApplicantInfo.channelSettings.contact.description || '&ndash;'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="canManageChannelSettings">
            <div class="btn-group-lg pull-right" *ngIf="isEditing">
                <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]="screenApplicantForm.invalid">Save</button>
            </div>
            <div class="btn-group-lg pull-right" *ngIf="!isEditing">
                <button type="submit" class="btn btn-primary"(click)="setEditMode(true)">
                    <span class="glyphicon glyphicon-pencil"></span>
                    Edit</button>
            </div>
        </div>
    </form>
</div>
