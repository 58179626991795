import { Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Dimension } from 'commons/interfaces';

export interface SrcInfo {
	error?: string;
	type?: string;
	size?: number;
	dimension?: Dimension;
}

@Directive({
	selector: '[srcBlob]',
})
export class SrcBlobDirective {
	@Input() srcBlob: string;
	@Input() srcDefault?: string;

	@Output() srcInfo = new EventEmitter<SrcInfo>();

	@HostBinding('attr.src')
	src: string | SafeUrl;

	constructor(
		public http: HttpClient,
		public sanitizer: DomSanitizer
	) {}

	ngOnChanges(): void {
		if (this.srcBlob) {
			this.http.get(this.srcBlob, { responseType: 'blob' })
				.subscribe((blob) => {
						const { type, size } = blob;

						const img = new Image();
						img.src = URL.createObjectURL(blob);
						img.onload = () => {
							const { width, height } = img;
							this.srcInfo.emit({ type, size, dimension: { width, height } });

							this.src = this.sanitizer.bypassSecurityTrustUrl(img.src);
						};
						img.onerror = (error) => {
							this.srcDefault && (this.src = this.srcDefault);
							this.srcInfo.emit({ error: error.toString() });
						};
					}, (res) => {
						res.stopPropagation && res.stopPropagation();
						this.srcDefault && (this.src = this.srcDefault);
						this.srcInfo.emit({ error: res.message });
					},
				);
		} else if (this.srcDefault) {
			this.src = this.srcDefault;
		}
	}
}
