import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerService } from 'comergence/customers/services/customer-list/customer.service';
import { ServiceChannel } from 'comergence/customers/services/customer-list/customer.service.interface';
import { UIRouterGlobals } from '@uirouter/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CcmServiceStatusService } from 'comergence/customers/ccm-service-status.service';

@Component({
    templateUrl: 'ccm-customer-deactivate-modal.component.html',
})
export class CcmCustomerDeactivateModalComponent implements OnInit {
    private readonly customerUnsubscribe$: Subject<void> = new Subject();
    setStatus: () => Promise<void>;
    organizationName: string;
    organizationId: number;
    servicesChannel: ServiceChannel;
    resolving: boolean;
    customerType: string;

    constructor(
        public modalRef: BsModalRef,
        public customerService: CustomerService,
        private routerGlobals: UIRouterGlobals,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
    ) {
    }

    ngOnInit(): void {
        this.organizationId = this.routerGlobals.params.organizationId;
        this.customerType = this.routerGlobals.params.customerType;
        this.loadServicesChannels();
    }

    loadServicesChannels() {
        this.resolving = true;
        return this.customerService.getActiveServiceAndChannels(this.organizationId)
            .pipe(takeUntil(this.customerUnsubscribe$))
            .subscribe(data => {
               this.servicesChannel = data;
               this.resolving = false;
        });
    }

    close() {
        this.modalRef.hide();
    }

    async deactivate(): Promise<void> {
        this.resolving = true;
        await this.setStatus();
        this.resolving = false;
        this.ccmServiceStatusService.fetchServiceStatuses();

        this.close();
    }

    ngOnDestroy(): void {
        this.customerUnsubscribe$.next();
        this.customerUnsubscribe$.complete();
    }
}
