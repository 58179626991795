import { Component, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { ForgotEntityType, RegistrationService } from 'login/registration/registration.service';

@Component({
    templateUrl: './forgot-modal.component.html',
})
export class ForgotModalComponent {
    userService = inject(UserService);

    entity: ForgotEntityType;
    resolved = true;

    get entityName(): string {
        if (this.entity == 'password')
            return 'Password';

        return '';
    }

    form = new RealmFormGroup({
            email: new RealmFormControl(
                'email',
                {
                    label: 'Email',
                },
                [
                    Validators.required,
                    Validators.email,
                ],
            ),
        },
    );

    constructor(
        readonly modalRef: BsModalRef,
        private readonly registrationService: RegistrationService,
        private readonly notificationsService: GlobalNotificationsService,
        public stateService: StateService,
    ) {
    }

    async save() {
        this.resolved = false;
        try {
            await this.registrationService.forgot(this.entity, this.form.value).toPromise();
            // this.notificationsService.add({
            //     type: GlobalNotificationType.POSITIVE,
            //     message: 'Email successfully sent.',
            // });
            this.userService.redirectToLogin();
        } catch ({ error: { message } }) {
            this.notificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
    }
        this.resolved = true;
    }

    cancel() {
        this.userService.redirectToLogin();
    }
}
