import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Optional } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';

import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';

import { NewContactSocialAccountsService } from './social-accounts.service';
import { NewPagedListComponent } from 'commons/components/new-list/list.component';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';
import { takeUntil, tap } from 'rxjs/operators';
import { IPermissionsResourceService, PublishingPermissionsResourceService } from 'tpo/social-compliance/publishing-permissions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SmConfirmModalComponent } from 'commons/components/modals';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { allowConnectionStatusCheck } from 'tpo/social-accounts/company/allowed-connection-statuses';

@Component({
    templateUrl: './social-accounts.component.html',
    viewProviders: [
        { provide: NewPagedListComponent, useExisting: forwardRef(() => NewContactSocialAccountsComponent) },
        // TODO: Used for SearchListComponent, change it's definition once old ListComponent will be removed
        { provide: ListComponent, useExisting: forwardRef(() => NewContactSocialAccountsComponent) },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewContactSocialAccountsComponent extends NewPagedListComponent<any> {
    static listName = 'contactSM';

    User: UserProfile;
    isTpo: false;
    isComergence: false;
    isLender: false;
    columnClass: Record<string, string> = {
        account: 'col-sm-4',
        lastConnectionStatus: 'col-sm-2',
        monitoringStatus: 'col-f-100',
        rss: 'col-f-100',
        actions: 'col-sm-a',
    };
    modalRef: BsModalRef;
    hasPublishPermission: boolean = false;
    isPublisherOnThePage: boolean = false;
    isCurrentUser: boolean = false;
    canManage: boolean = false;
    canRestore: boolean = false;
    canRemove: boolean = false;
    canManageRss: boolean = false;
    permissionsResource: IPermissionsResourceService;
    socialMediaServiceParams: {
        mediaType: string,
        entityIdType: string,
        entityId: string | number,
    };
    contact: FullTpoContact;
    listLength: number = 0;
    allowConnectionStatusCheck = allowConnectionStatusCheck;

    private details: SharedContactDetailsComponent;

    constructor(
        @Optional() tpoDetails: TpoContactDetailsComponent,
        @Optional() investorDetails: InvestorContactDetailsComponent,
        @Optional() ccmDetails: CcmContactDetailsComponent,
        router: UIRouter,
        { profile }: UserService,
        { individual }: PublishingPermissionsResourceService,
        private modalService: BsModalService,
        private cd: ChangeDetectorRef,
        private contactSocialAccountsService: NewContactSocialAccountsService,
    ) {
        super(router);

        this.details = ccmDetails || investorDetails || tpoDetails;
        this.User = profile;
        const { isTpo, isComergence, isLender } = profile;
        Object.assign(this, { isTpo, isComergence, isLender });
        this.canManageRss = this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS');

        this.columnClass.account = `col-sm-${(this.isLender ? 9 : 4)}`;

        this.details.contact.pipe(
            takeUntil(this.destroy$),
        ).subscribe((contact: FullTpoContact) => {
            const { contactId, isPrevious } = contact;
            this.contact = contact;
            this.hasPublishPermission = this.User.can('TPO_TEAM_PUBLISH') && !isPrevious;


            this.socialMediaServiceParams = {
                mediaType: 'social-media',
                entityIdType: 'CONTACTID',
                entityId: contactId,
            };

            this.cd.markForCheck();
        });

        this.permissionsResource = individual;
    }

    ngOnInit() {
        const { contactId } = this.router.globals.params;
        const { contactId: userContactId } = this.User;

        super.ngOnInit();

        this.isCurrentUser = userContactId === contactId * 1;

        this.canManage = (
            this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') ||
            this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')
        );
        this.canRestore = (
            this.isCurrentUser ||
            this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS')
        );
        this.canRemove = this.canRestore;
        this.canManageRss = this.User.can('CCM_EDIT_SOCIAL_MEDIA_CONTACTS');
    }

    toPublishingPermissions(link) {
        if (!this.isLender) {
            this.router.stateService.go('^.publishing-permissions', {
                linkId: link.data.linkId,
            });
            return;
        }
    }

    getPermissionParams(link) {
        const { linkId } = link;
        const { contactId } = this.contact;
        const { tpoId } = this;

        return {
            tpoId,
            linkId,
            id: contactId,
        };
    }

    get tpoId() {
        const { id } = this.router.globals.params;
        return this.isTpo ? this.User.organization.id : id;
    }

    get listParams() {
        const { contactId } = this.router.globals.params;
        const { tpoId } = this;

        return {
            includePublisherStatus: this.isTpo,
            includePublisherCount: this.isTpo || this.isComergence,
            tpoId,
            contactId,
        };
    }

    loadList(params) {
        return this.contactSocialAccountsService.getSocialAccounts({ ...this.listParams, ...params })
            .pipe(
                tap(list => {
                    this.listLength = list.data.length;
                    this.isPublisherOnThePage = list.data.some((entry: SocialAccountLink) => (!!entry.data.publisherStatus));
                }),
            );
    }

    requestAccess({ data: link }: SocialAccountLink, cancel: boolean = false) {
        const initialState = {
            title: cancel ? 'Cancel Request' : 'Publisher Access',
            message: cancel ? `Are you sure you want to cancel Publisher Request for <strong>${link.name}</strong>?` : `You are requesting permission to post on behalf of <strong>${this.contact.fullName}</strong> for this account:`,
            confirmText: cancel ? 'Yes' : 'Request',
            cancelText: cancel ? 'No' : 'Cancel',
            account: link,
            onConfirm: () => {
                const modalScope = this.modalRef.content;
                modalScope.resolving = true;
                modalScope.errorText = null;

                this.permissionsResource.requests[cancel ? 'cancel' : 'add']({
                        ...this.getPermissionParams(link),
                    }, () => {
                        this.modalRef.hide();
                        this.updateList();
                    }, ({ data }) => {
                        modalScope.resolving = false;
                        modalScope.errorText = data.message;
                        this.cd.detectChanges();
                    },
                );
            },
        };
        this.modalRef = this.modalService.show(SmConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
    }

    manageLink = async (link: SocialAccountLink, action: 'acceptRemoval' | 'declineRemoval'): Promise<void> => {
        this.resolving = true;
        const { entityId, entityIdType } = this.socialMediaServiceParams;

        try {
            await this.contactSocialAccountsService[action]({ entityId, entityIdType, mediaLinkId: link.data.linkId}).toPromise();
            let { page } = this.params;

            if (this.listLength === 1 && action === 'acceptRemoval') {
                page = page > 0 ? page - 1 : 0;
            }

            this.updateList({ ...this.params, page });
        } catch (e) {}
        this.resolving = false;
        this.cd.detectChanges();
    };

    removeLink = async (link: SocialAccountLink): Promise<void> => {
        this.resolving = true;
        try {
            const { entityId, entityIdType } = this.socialMediaServiceParams;
            await this.contactSocialAccountsService.removeLink({
                entityId,
                entityIdType,
                mediaLinkId: link.data.linkId,
            }).toPromise();

            if (this.isComergence) {
                this.updateList();
                return;
            }

            link.setRemovalRequested(true);
        } catch (e) {
        }
        this.resolving = false;
        this.cd.detectChanges();
    };

    removeCurrentPublisherAccess({ data: account }: SocialAccountLink) {
        const initialState = {
            title: 'Remove Publisher Access',
            message: `You will no longer be able to post on behalf of <strong>${account.name}</strong> for this account:`,
            confirmText: 'Confirm',
            account,
            onConfirm: () => {
                const modalScope = this.modalRef.content;
                modalScope.resolving = true;
                modalScope.errorText = null;

                this.permissionsResource.permissions.removeCurrent({
                        ...this.getPermissionParams(account),
                    }, () => {
                        this.modalRef.hide();
                        this.updateList();
                    }, ({ data }) => {
                        modalScope.resolving = false;
                        modalScope.errorText = data.message;
                        this.cd.detectChanges();
                    },
                );
            },
        };

        this.modalRef = this.modalService.show(SmConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
    }

    addLink = (link: string): Promise<void> => {
        const { entityId, entityIdType } = this.socialMediaServiceParams;
        return this.contactSocialAccountsService.addLink({ entityId, entityIdType }, {
            link,
            isManual: true,
        }).toPromise().then(() => {
            this.updateList();
        });
    }
}
