<div class="remote-resource" [class.resolved]="!resolving">
    <div class="gray-head">
        <a class="breadcrumb" uiSref="channels">Manage Channels</a>
        <h2>{{channel?.name}} Alerts</h2>
    </div>
    <div class="col-md-6">
        <manage-channel-alert-group
            [channelAlertGrouping]="leftAlertGroup"
            [editingDisabled]="companyEditingDisabled"
            [canEditAlerts]="canEditAlerts"
            [resolving]="resolving"
            (editingState)="companyEditing($event)"
            (saveEvent)="saveAlerts($event)"
        ></manage-channel-alert-group>
    </div>
    <div class="col-md-6">
        <manage-channel-alert-group
            [channelAlertGrouping]="rightAlertGroup"
            [editingDisabled]="individualEditingDisabled"
            [canEditAlerts]="canEditAlerts"
            [resolving]="resolving"
            (editingState)="individualEditing($event)"
            (saveEvent)="saveAlerts($event)"
        ></manage-channel-alert-group>
    </div>
</div>
