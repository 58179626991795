import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { UserService } from 'angularjs-providers/user.provider';

import { TpoQuestionnaireQuestion, TpoQuestionnaire } from 'tpo/questionnaire/questionnaire.service';

declare let apiPath: string;

@Injectable()
export class LenderManageQuestionnairesPreviewService {
	questionnaireApi: string;
	questionnaireApiV2: string;

	constructor(
		injector: Injector,
		{ profile }: UserService,
		private http: RealmHttpClient,
	) {
		const { organizationId } = profile.organization;
		this.questionnaireApi = `${apiPath}/organizations/${organizationId}/questionnaires`;
		this.questionnaireApiV2 = `${apiPath}/v2/organizations/${organizationId}/questionnaires`;
	}

	getPreviewQuestions<T extends TpoQuestionnaireQuestion[] = TpoQuestionnaireQuestion[]>(questionnaireId: number): Observable<T> {
		return this.http.request<T>(
			'GET',
			`${this.questionnaireApiV2}/${questionnaireId}/questions-preview`,
		);
	}
	getPreviewQuestionnaire<T extends TpoQuestionnaire = TpoQuestionnaire>(questionnaireId: number): Observable<T> {
		return this.http.request<T>(
			'GET',
			`${this.questionnaireApi}/${questionnaireId}`,
		);
	}
}
