<div class="gray-head">
	<h2>Custom Fields</h2>
</div>

<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div class="row-fluid header">
		<sortable-header class="col-sm-4" columnName="name">Object</sortable-header>
		<sortable-header class="col-md-a" columnName="count">Custom Fields</sortable-header>
	</div>
	<a *ngFor="let item of list"
		class="row-fluid clickable"
		(click)="checkPermission(item)"
		[uiSref]="item.permissionType === 'CRM' && !this.User.crmEnabled ? '' : '.:entityType'"
		[uiParams]="{ entityType: item.id }"
	>
		<span class="col-md-4 va-m">
			<a class="text-bigger" [textContent]="item.name"></a>
		</span>
		<span class="col-md-a va-m">{{item.count}}</span>
	</a>
	<div class="row-fluid" *ngIf="!list.length">
		<span class="col-md-12 va-m text-muted text-center text-bigger">No objects</span>
	</div>
	<br>
</div>
