import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    templateUrl: './audit-list-edit-dropdown.component.html',
    selector: 'audit-list-edit-dropdown',
})
export class AuditListEditDropdownComponent {

    @Input()
    someAuditsAreSelected: boolean = false;

    @Output()
    assignUser: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    editStatus: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    cancelEdit: EventEmitter<void> = new EventEmitter<void>();

    constructor(

    ) {}

    onAssignClicked() {
        this.assignUser.emit();
    }

    onStatusClicked() {
        this.editStatus.emit();
    }

    onCancelClicked() {
        this.cancelEdit.emit();
    }

}
