<div class="questionnaire">
    <h2>Questionnaires</h2>
    <div class="remote-resource" [class.resolved]="list?.$resolved">

        <notification></notification>

        <div class="filters-rows">
            <div class="row-fluid filter-labels">
                <div class="col-sm-3">
					<span class="text-muted">
						<ng-container *ngIf="isInvestor">Name</ng-container>
						<ng-container *ngIf="isLender">Investor</ng-container>
					</span>
                </div>
                <div class="col-sm-3">
                    <span class="text-muted">Status</span>
                </div>
                <div class="col-sm-6"></div>
            </div>
            <div class="row-fluid filter-selectors">
                <div class="col-sm-3">
                    <ng-select
                        *ngIf="isInvestor"
                        [(ngModel)]="params.questionnaireId"
                        (change)="setFilter({ questionnaireId: $event })"
                        [loading]="!filters.questionnaireId?.$resolved"
                        [items]="filters.questionnaireId?.$promise | async"
                        bindValue="id"
                        bindLabel="name"
                        [multiple]="true"
                        [clearable]="true"
                        placeholder="All Questionnaires"
                    ></ng-select>
                    <ng-select
                        *ngIf="isLender"
                        [(ngModel)]="params.lenderId"
                        (change)="setFilter({ lenderId: $event })"
                        [loading]="!filters.lenderId?.$resolved"
                        [items]="filters.lenderId?.$promise | async"
                        bindValue="lenderId"
                        bindLabel="lenderName"
                        [multiple]="true"
                        [clearable]="true"
                        placeholder="All Investors"
                    ></ng-select>
                </div>
                <div class="col-sm-3">
                    <ng-select
                        [(ngModel)]="params.statusId"
                        (change)="setFilter({ statusId: $event })"
                        [loading]="!filters.statusId?.$resolved"
                        [items]="filters.statusId?.$promise | async"
                        bindValue="id"
                        bindLabel="name"
                        [multiple]="true"
                        [clearable]="true"
                        placeholder="All Statuses"
                    ></ng-select>
                </div>
                <div class="col-sm-6 text-right">
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="assignQuestionnaire()"
                        *ngIf="user.can('ASSIGN_QUESTIONNAIRE')"
                    >
                        Assign Questionnaire
                    </button>
                </div>
            </div>
        </div>

        <div class="rows-bordered">
            <div class="row-fluid header">
                <sortable-header class="col-md-6" *ngIf="!user.isTpo" columnName="name">Name</sortable-header>
                <sortable-header class="col-sm-6" *ngIf="user.isTpo" columnName="name">Lender</sortable-header>
                <sortable-header class="col-sm-2" columnName="status">Status</sortable-header>
                <sortable-header class="col-sm-2" columnName="statusDate">Date</sortable-header>
                <span class="col-md-2"></span>
            </div>
            <div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
                <div class="col-md-12 text-center">
                    Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results.
                </div>
            </div>
            <a *ngFor="let item of list"
               class="row-fluid clickable template-row"
               [class.no-pointer]="isHrefSkipped(item)"
               [href]="getHref(item)"
               [target]="getTarget(item)"
               (click)="toDetails(item, $event)"
            >
                <div class="col-md-6 text-bigger" *ngIf="!user.isTpo">
                    <span [class.text-link]="!isHrefSkipped(item)">{{item.name}}</span>
                    <br>
                    <span class="text-light text-smaller">
						{{ getDescription(item) }}
					</span>
                </div>
                <div class="col-md-6 text-bigger" *ngIf="user.isTpo">
                    <span class="text-link">{{item.lender?.lenderName}}</span>
                    <br>
                    <span class="text-light text-smaller">
						{{item.name}}
					</span>
                </div>
                <div class="col-md-2 va-m text-bigger">
					<span class="label"
                          [class.label-warning]="item.status?.id === questionnaireStatus.InProgress"
                          [class.label-success]="item.status?.id === questionnaireStatus.Completed"
                          [class.label-default]="item.status?.id === questionnaireStatus.Scheduled"
                          [class.label-danger]="item.status?.id === questionnaireStatus.Cancelled"
                    >
						{{item.status.name}}
					</span>
                </div>
                <div class="col-md-2 va-m">
                    {{item.statusDate | date:'MM/dd/yyyy'}}
                </div>
                <div class="col-md-2 text-right va-m">
                    <div
                        class="dropdown"
                        dropdown
                        *ngIf="isInvestor
							&& (
								user.can('ASSIGN_QUESTIONNAIRE')
								|| item.applicationId
							)"
                        (click)="cancelBubble($event)"
                    >
                        <a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
                            <span class="c-icon c-icon-dot-3"></span>
                        </a>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                            <li
                                role="menuitem"
                                *ngIf="
									item.status?.id === questionnaireStatus.Scheduled
									&& user.can('ASSIGN_QUESTIONNAIRE')
								"
                            >
                                <a (click)="assignQuestionnaire(item)">Edit</a>
                            </li>
                            <li
                                role="menuitem"
                                *ngIf="
									item.cancelable
									&& user.can('ASSIGN_QUESTIONNAIRE')
								"
                            >
                                <a (click)="cancelQuestionnaire(item)">Cancel</a>
                            </li>
                            <li
                                role="menuitem"
                                *ngIf="
									item.status?.id !== questionnaireStatus.Scheduled
									&& item.questionnaireAssignmentId
									&& user.can('ASSIGN_QUESTIONNAIRE')
								"
                            >
                                <a (click)="showSnapshotModal(item.questionnaireAssignmentId);">History</a>
                            </li>
                            <li
                                role="menuitem"
                                *ngIf="item.applicationId"
                            >
                                <a (click)="openApplication(item)">Application</a>
                            </li>
                            <li
                                role="menuitem"
                                *ngIf="user.can('ASSIGN_QUESTIONNAIRE')"
                            >
                                <a (click)="assignQuestionnaire(item, true)">Schedule</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </a>
            <div class="row-fluid" *ngIf="list?.$resolved && !list.length">
				<span class="col-md-12 text-center va-m text-bigger text-muted">
					{{filtersApplied ? 'No matches found' : 'No questionnaires'}}
				</span>
            </div>
        </div>

        <br>

        <new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
    </div>
</div>
