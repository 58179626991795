<div *ngIf="files" class="documents lender">
    <div class="files">
        <div *ngFor="let file of files"
            class="droppable new-document mb20"
        >
            <uploader
                *ngIf="allowAddNew"
                #uploader
                (select)="replaceFile(file, $event)"
                [config]="{ accept: '.doc, .docx, .xls, .xlsx, .pdf' }"
                [customTemplate]="fileTemplate"
            >
                <ng-template #fileTemplate>
                    <div class="uploader-container flex-row center" (fileDrop)="uploader.select.emit($event)">
                        &nbsp;<span class="flex">
                        &nbsp;
                        <span class="c-icon c-icon-file text-muted"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                            {{ file.name }}
                        </span>
                        <span class="pull-right file-handler display-block gap-sm">
                            <label *ngIf="allowReplace" class="btn btn-secondary">
                                Replace File
                                <input
                                    type="file"
                                    (input)="uploader.onFileInput($event)"
                                    [multiple]="uploader.config.multiple"
                                    [accept]="uploader.config.accept"
                                >
                            </label>
                            <button type="button" class="btn btn-secondary" (click)="removeFile(file)">
                                Remove File
                            </button>&nbsp;
                        </span>
                    </div>
                </ng-template>
            </uploader>
            <ng-container *ngIf="!allowAddNew">
                <div class="view-file-list">
                    <div class="view-file-list-container flex-row center" (fileDrop)="uploader.select.emit($event)">
                        &nbsp;<span class="flex">
                        &nbsp;
                        <span class="c-icon c-icon-file text-muted"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                            {{ file.name }}
                        </span>
                        <span class="pull-right file-handler display-block gap-sm">
                            <button type="button" class="btn btn-secondary" (click)="removeFile(file)">
                                Remove File
                            </button>&nbsp;
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="allowAddNew" class="droppable new-document">
            <uploader
                #uploader
                (select)="addNewFile($event)"
                [config]="{ accept: '.doc, .docx, .xls, .xlsx, .pdf', multiple: true }"
            >
            </uploader>
        </div>
    </div>
</div>
