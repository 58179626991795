<div class="clearfix"
	[ngClass]="{ 'remote-resource': hasOwnLoader, 'resolved': list.$resolved }"
>
	<div class="flex-row invite-application" *ngFor="let item of list">
		<div class="flex">
			<div class="title">
				<b>{{item.lenderName}}</b> invited you to channel <b>{{item.channelName}}</b>
			</div>

			<div class="info">
				<div *ngIf="item.contactName">
					<span class="control-label text-right">{{item.assigned ? 'Assigned' : 'Contact'}}: </span>
					<span>{{item.contactName}}</span>
				</div>
				<div *ngIf="item.contactEmail">
					<span class="control-label text-right">Email: </span>
					<a href="mailto:{{item.contactEmail}}">{{item.contactEmail}}</a>
				</div>
				<div *ngIf="item.contactPhone">
					<span class="control-label text-right">Phone: </span>
					<span>{{item.contactPhone | tel}}</span>
				</div>
				<div *ngIf="item.applicationPrice">
					<span class="control-label text-right">Application fee: </span>
					<span>{{item.applicationPrice | currency}}</span>
				</div>
				<div *ngIf="item.expiresIn !== null">
					<span class="control-label text-right">Expires in: </span>
					<span class="text-danger">{{item.expiresIn | i18nPlural: { one: '# Day', other: '# Days' } }}</span>
				</div>
			</div>
		</div>
		<div [class.remote-resource]="item.$resolving">
			<a (click)="accept(item)" class="btn btn-primary">Accept</a>
			<a (click)="decline(item)" class="btn btn-default">Decline</a>
		</div>
	</div>
</div>
