import { includes } from 'lodash';

const allowedConnectionStatuses = [ 'FB', 'TW', 'LI', 'YT', 'GP', 'IG', 'GMB' ];

export { allowedConnectionStatuses };

const allowConnectionStatusCheck = (linkData): boolean => {
	return linkData && linkData.linkId && !linkData.isManual && includes(allowedConnectionStatuses, linkData.networkCode);
};

export { allowConnectionStatusCheck };
