<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm && onConfirm(); modalRef.hide()">&times;</button>
	<h4 class="modal-title">NMLS Selection Pool Upload</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<form-errors [form]="form"></form-errors>

	<p>File type must be CSV and contain NMLS ID in Column A.</p>
	<br>
	<div>
		When updating your selection pool, the operation selected will be
		applied to the individuals in your file, and the inverse operation will
		be applied to any NMLS IDs that are not yet defined. &nbsp;&nbsp;<a [href]="helpLink" target="_blank">Help</a>
	</div>
	<br>
	<form [formGroup]="form" id="form" (onValidSubmit)="onSave(form.getRawValue())">
		<div class="form-group">
			<uploader
				#uploader
				(select)="onPick($event)"
				[config]="{ accept: '.csv' }"
				[customTemplate]="file.value ? fileTemplate : null"
			>
				<ng-template #fileTemplate>
					<div class="uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
						&nbsp;&nbsp;&nbsp;&nbsp;<span class="flex">
							<span class="c-icon c-icon-file text-muted"></span>&nbsp;&nbsp;&nbsp;&nbsp;
							{{file.value.name}}
						</span>
						<label class="btn btn-secondary">
							Replace
							<input
								type="file"
								(input)="uploader.onFileInput($event)"
								[multiple]="uploader.config.multiple"
								[accept]="uploader.config.accept"
							>
						</label>&nbsp;&nbsp;&nbsp;&nbsp;
					</div>
				</ng-template>
			</uploader>

		</div>
		<br>
		<div class="form-group">
			<label class="control-label required">{{form.getFieldLabel('type')}}</label>
			<div>
				<label class="radio-inline">
					<input type="radio" formControlName="type" [value]="types.INCLUDE"> Include NMLS IDs
				</label>
				<br>
				<label class="radio-inline">
					<input type="radio" formControlName="type" [value]="types.EXCLUDE"> Exclude NMLS IDs
				</label>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div>
		<button type="submit" form="form" class="btn btn-primary">Save</button>
	</div>
</div>
