import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

export interface Comment {
	alertId: number;
	applicationId: number;
	channelId: number;
	comment: string;
	commentType: string;
	createdBy: string;
	createdDate: number;
	findingCategory: string;
	findingId: number;
	tpoId: number;
	category: string;
}

@Injectable()
export class ApplicationsCommentsService {
	comments: any;

	constructor(injector: Injector) {
		const PagedResource = injector.get('$injector').get('PagedResource');

		const basePath = `${apiPath}/applications/:applicationId`;

		this.comments = PagedResource(`${basePath}/comment`, { applicationId: '@applicationId' }, {
			query: { method: 'GET', isArray: true },
			add: { method: 'POST' },
			commentType: {
				method: 'GET',
				isArray: true,
				url: `${apiPath}/commenttype`,
			},
		});
	}
}
