<div class="remote-resource" [class.resolved]="resolved && isAttachmentsRendered">
	<div class="post-row">
		<div class="column profile-image">
			<i [style.background-image]="sanitize(profilePictureUrl)" class="profile-image profile"></i>
		</div>
		<div class="column profile-name">
			<a [aDisabled]="!webLink" href="{{webLink}}" target="_blank"><b>{{name}}</b></a>
			<div class="text-muted">{{previewDate | date: 'MMM d, yyyy'}}</div>
		</div>
	</div>

	<div class="post-text" *ngIf="textTruncated">
		<formatted-text [text]="textTruncated | textToUrl: { regex: regLink }"></formatted-text>
	</div>

	<publisher-attachments-preview
		[attachments]="attachments"
		[networkCode]="networkCode"
		(onIsRendered)="attachmentsLoaded($event)"
	></publisher-attachments-preview>
</div>
