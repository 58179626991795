<client-library-document
	className="investor-client-library-document"
	[document]="document"
	[downloadLink]="getDownloadLink('uploadedDocument')"
	[templateDownloadLink]="getDownloadLink('template')"
	(showHistory)="showHistory()"
>
	<ng-template #indications>
		<span
			*ngIf="document.isActive && document.requestsCount"
			class="label label-danger-wired text-smaller"
		>
			Requested by {{ document.requestsCount | i18nPlural : {'=1': '1 Investor', 'other': '# Investors'} }}
		</span>
	</ng-template>

	<ng-template #actions>
		<div class="flex-column right gap-sm">
			<button
				*ngIf="user.can('REQUEST_DOCUMENT_LIBRARY_DOC') && document.isActive"
				type="button"
				class="btn btn-primary w-auto"
				(click)="request()"
			>
				Request
			</button>
		</div>
	</ng-template>
</client-library-document>
