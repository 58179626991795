import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class UsersResourceService {
	resource: any;

	constructor(public injector: Injector) {
		// console.log('create RolesResourceService');
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/users/:id`;

		this.resource = $resource(`${basePath}`, {id: '@id'}, {
			get: {
				method: 'get',
			},
			save: {
				method: 'PUT',
			},
			setRole: {
				method: 'PUT',
				url: `${basePath}/role`,
			},
			resetPassword: {
				method: 'PUT',
				url: `${basePath}/password-reset`,
			},
			activate: {
				method: 'PUT',
				url: `${basePath}/active`,
                params: {
                    notifyUser: '@notifyUser'
                }
			},
			deactivate: {
				method: 'DELETE',
				url: `${basePath}/active`,
			},
			unlock: {
				method: 'DELETE',
				url: `${basePath}/lock`,
			},
			verifyPin: {
				method: 'put',
				url: `${basePath}/pin-verification`,
			},
		});
	}

	get = (...args) => this.resource.get(...args);
	save = (...args) => this.resource.save(...args);
	setRole = (...args) => this.resource.setRole(...args);
	resetPassword = (...args) => this.resource.resetPassword(...args);
	activate = (...args) => this.resource.activate(...args);
	deactivate = (...args) => this.resource.deactivate(...args);
	unlock = (...args) => this.resource.unlock(...args);
	verifyPin = (...args) => this.resource.verifyPin(...args);
}
