<div class="individual-contact content">
	<div *ngIf="!isEmbedded" class="gray-head flex-head">
		<h2>{{company.companyName}}</h2>
	</div>

	<h1 *ngIf="isEmbedded" class="text-center">{{company.companyName}}</h1>

	<div class="remote-resource" [class.resolved]="company.$resolved && !saving">
		<form name="SingleContact" id="SingleContact" (onValidSubmit)="save()" [formGroup]="companyForm" [class.preview]="!editing">
			<div style="overflow: hidden; height: 0;"><input name="password" type="password"></div>
			<h3>
				General Information
				<div class="pull-right">
					<a *ngIf="!editing && hasPermission('MANAGE_COMPANY_CONTACT')" (click)="toggleMode()" class="btn btn-primary" tabindex=11><span class="glyphicon glyphicon-pencil"></span> Edit</a>
					<button *ngIf="editing" type="submit" form="SingleContact" class="btn btn-primary" tabindex=11>Save</button>
					<a *ngIf="editing" (click)="cancel()" class="btn btn-default" tabindex=11>Cancel</a>
				</div>
			</h3>
			<form-errors [form]="companyForm"></form-errors>
			<div class="rows-striped">
				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('companyName').invalid && companyForm.get('companyName').touched">
							<label class="col-sm-5 control-label required">Company Name</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input class="form-control" maxlength="100" formControlName="companyName" tabindex=1>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.companyName}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('address').invalid && companyForm.get('address').touched">
							<label class="col-sm-5 control-label required">Street</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input class="form-control" maxlength="100" formControlName="address" tabindex=6>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.address}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>

				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('phone').invalid && companyForm.get('phone').touched">
							<label class="col-sm-5 control-label required">Phone</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input phoneFormatter class="form-control" formControlName="phone" tabindex=2>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.phone || '&ndash;' | tel}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row">
							<label class="col-sm-5 control-label">Suite</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input class="form-control" maxlength="19" formControlName="suite" tabindex=7>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.suite || '&ndash;'}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>

				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('fax').invalid && companyForm.get('fax').touched">
							<label class="col-sm-5 control-label">Fax</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input phoneFormatter class="form-control" formControlName="fax" tabindex=3>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.fax || '&ndash;' | tel}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('city').invalid && companyForm.get('city').touched">
							<label class="col-sm-5 control-label required">City</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input class="form-control" maxlength="50" formControlName="city" tabindex=8>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.city || '&ndash;'}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>

				<div class="row-fluid">
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('businessTaxId').invalid && companyForm.get('businessTaxId').touched">
							<label class="col-sm-5 control-label required">Business Tax ID</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input class="form-control" maxlength="9" formControlName="businessTaxId" tabindex=4>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.businessTaxId || '&ndash;'}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('state').invalid && companyForm.get('state').touched">
							<label class="col-sm-5 control-label required">State</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<ng-select
											*ngIf="states?.$resolved"
											formControlName="state"
											[items]="states"
											bindValue="name"
											bindLabel="name"
											[clearable]="true"
											placeholder="Select state"
											tabindex=9>
										</ng-select>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.state || '&ndash;'}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>

				<div class="row-fluid">
					<div class="col-md-5">
						<div *ngIf="!editing" class="form-group row">
							<label class="col-sm-5 control-label">Ownership, %</label>
							<div class="col-sm-7">
								<span class="control-value">
									{{company.ownershipPercentage}}
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-5">
						<div class="form-group row" [class.has-error]="companyForm.get('zip').invalid && companyForm.get('zip').touched">
							<label class="col-sm-5 control-label required">Zip</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing">
									<ng-template #editTemplate>
										<input class="form-control" maxlength="20" formControlName="zip" tabindex=10>
									</ng-template>

									<ng-template #viewTemplate>
										<span class="control-value">
											{{company.zip || '&ndash;'}}
										</span>
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-1"></div>
				</div>
			</div>
		</form>
	</div>
</div>
