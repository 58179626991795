import { Pipe, PipeTransform } from '@angular/core';
import { pad } from 'lodash';

const memo: string[] = [];

@Pipe({
	name: 'bulletpoints',
})
export class BulletpointsPipe implements PipeTransform {
	transform(value: string | null | undefined): string | null {
		const length: number = value?.length;

		if (!length) {
			return null;
		}

		if (memo[length]) {
			return memo[length];
		}

		memo[length] = pad('', length, '•');
		return memo[length];
	}
}
