<div class="permission" *ngIf="map && visible">
	<label class="checkbox-inline" [class.text-muted]="editing && disabled">
		<span class="glyphicon" *ngIf="!editing" [ngClass]="'glyphicon-' + (map[permissionConfig.key] && !disabled ? 'ok' : 'remove')"></span>
		<input type="checkbox"
			[(ngModel)]="map[permissionConfig.key]"
			(ngModelChange)="togglePermission()"
			[checked]="map[permissionConfig.key]"
			tabindex="1"
			*ngIf="editing"
			[disabled]="disabled">
		<ng-content></ng-content>
	</label>
</div>
