import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';

import { NotificationModalComponent } from 'commons/components/modals';

import emptyIcon from '~static/images/promo/Icon_empty.svg';
import { UserService } from 'angularjs-providers/user.provider';

export type LandingPageConfig = {
    title: string;
    sref: string;
    icon?: string;
    isMaterial?: boolean;
    description?: string;
    button?: string;
    disabled?: boolean;
    noAccess?: boolean;
    beta?: boolean;
    onEnter?: () => void;
    additionalButtons?: RightButton[];
};

type RightButton = {
    sref: string;
    title: string;
}
@Component({
    selector: 'landing-page',
    templateUrl: './landing-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit {
    @HostBinding('class.landing-page') private readonly _class = true;
    @Input() items: LandingPageConfig[];
    @Input() title: string;
    buttons: RightButton[] = [];
    helpLink: string;
    private modalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private stateService: StateService,
        protected userService: UserService,
    ) {
    }

    ngOnInit() {
        this.processConfig();
    }

    processConfig() {
        this.items = this.items.map(
            ({
                 title,
                 icon = emptyIcon,
                 description = '',
                 disabled = false,
                 noAccess = false,
                 button = title,
                 ...rest
             }) => {
                return {
                    title,
                    icon,
                    description,
                    disabled,
                    noAccess,
                    button,
                    ...rest,
                };
            });
    }

    private _enabled = true;
    @Input()
    set enabled(enabled: boolean) {
        this._enabled = enabled;
    }
    get enabled(): boolean {
        return this._enabled;
    }

    public showNotEnabledModal(): void {
        if (!this.userService.profile.package.invoicing) {
            this.stateService.go('social-compliance.subscription')
            return ;
        }

        this.modalRef = this.modalService.show(
            NotificationModalComponent,
            {
                initialState: {
                    title: 'No Access',
                    notification: 'To activate this service, please contact us at 720-709-4353 for more information.',
                    confirmText: 'Ok',
                    onConfirm: () => {
                        this.modalRef.hide();
                    },
                },
                class: 'modal-smd modal-new',
            },
        );
    }

    public showAccessDeniedModal(): void {
        this.modalRef = this.modalService.show(NotificationModalComponent, {
            initialState: {
                title: 'Sorry! Access Denied',
                confirmText: 'Ok',
                notification: `<div class="denied-lock"></div>
					<p>You currently do not have the necessary permissions to access this section.</p>
					<p>If you believe this is an error, please contact your Administrator to manage your permissions.</p>
				`,
                onConfirm: () => {
                    this.modalRef.hide();
                },
            },
            class: 'modal-smd modal-403',
        });
    }
}
