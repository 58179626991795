import { Injectable } from '@angular/core';

import { UserProfile } from 'angularjs-providers/user.provider';
// TODO: Move this to a commons location
import { TextEntity } from 'lender/clients/$id/channels/channels.service';
import { InvestorUserRelationshipService } from 'commons/services/user-capabilities/investor-user/investor-user-relationship.service';


@Injectable({
	providedIn: 'root',
})
export class InvestorUserTpoChannelService {
    constructor(
        private readonly investorTpoRelationshipService: InvestorUserRelationshipService,
    ) {
    }

    /**
     * @param channelIsAccessible Provided via the specific channel data
     * @param relationshipStatus Needed to know if there is a relationship status ({@see InvestorTpoRelationshipService#relationshipIsActive()})
     * @param investorIsRegistered Provided via {@link AccountInformation#isRegistered}
     * @returns true if the user can access the channel details page
     */
    canAccessTpoChannelDetails(
        channelIsAccessible: boolean,
        relationshipStatus: TextEntity,
        investorIsRegistered: boolean,
    ): boolean {
        const hasRelationship: boolean = this.investorTpoRelationshipService.relationshipExists(relationshipStatus);

        const canAccessTpoChannelDetails: boolean = (
            hasRelationship &&
            investorIsRegistered &&
            channelIsAccessible
        );

        return canAccessTpoChannelDetails;
    }

    /**
     * @param channelIsAccessible Provided via the specific channel data
     * @param user Needed to determine if the user can renew a relationship. Assumed to be defined and valid.
     * @param relationshipStatus Needed to know if the current relationship status is active ({@see InvestorTpoRelationshipService#relationshipIsActive()})
     * @param investorIsRegistered Provided via {@link AccountInformation#isRegistered}
     * @returns true if the user can renew the specified TPO relationship
     */
    canRenewTpoRelationship(
        channelIsAccessible: boolean,
        user: UserProfile,
        relationshipStatus: TextEntity,
        investorIsRegistered: boolean,
    ): boolean {
        const userCanEditRelationshipStatuses: boolean = user.can('EDIT_RELATIONSHIP_STATUS');
        const hasActiveRelationship: boolean = this.investorTpoRelationshipService.relationshipIsActive(relationshipStatus);

        const canAccessTpoChannel: boolean = (
            userCanEditRelationshipStatuses &&
            hasActiveRelationship &&
            investorIsRegistered &&
            channelIsAccessible
        );

        return canAccessTpoChannel;
    }

    /**
     * @param channelIsAccessible Provided via the specific channel data
     * @param relationshipStatus Needed to know if the current relationship status is active ({@see InvestorTpoRelationshipService#relationshipIsActive()})
     * @param investorIsRegistered Provided via {@link AccountInformation#isRegistered}
     * @param channelIsPurchasable Provided via the specific channel data
     * @returns true if the user can purchase the specified TPO profile
     */
    canPurchaseTpoProfile(
        channelIsAccessible: boolean,
        relationshipStatus: TextEntity,
        investorIsRegistered: boolean,
        channelIsPurchasable: boolean,
    ): boolean {
        const hasRedRelationship: boolean = this.investorTpoRelationshipService.relationshipIsRed(relationshipStatus);

        const canAccessTpoChannel: boolean = (
            hasRedRelationship &&
            investorIsRegistered &&
            channelIsAccessible &&
            channelIsPurchasable
        );

        return canAccessTpoChannel;
    }

    /**
     * @param channelIsInvitable Provided via the specific channel data
     * @param user Needed to determine if the user can invite a client to apply. Assumed to be defined and valid.
     * @param relationshipStatus Needed to know if the current relationship status is NOT active ({@see InvestorTpoRelationshipService#relationshipIsActive()})
     * @param investorIsRegistered Provided via {@link AccountInformation#isRegistered}
     * @returns true if the user can invite the specified TPO to the specified channel
     */
    canInviteTpoToChannel(
        channelIsInvitable: boolean,
        user: UserProfile,
        relationshipStatus: TextEntity,
        investorIsRegistered: boolean,
    ): boolean {
        const userCanInviteToApply: boolean = user.can('INVITE_TO_APPLY');
        const hasRedRelationshipStatus: boolean = this.investorTpoRelationshipService.relationshipIsRed(relationshipStatus);
        const hasNoRelationship: boolean = !this.investorTpoRelationshipService.relationshipExists(relationshipStatus);
        const relationshipIsInviteable: boolean = (hasRedRelationshipStatus || hasNoRelationship);

        const canAccessTpoChannel: boolean = (
            userCanInviteToApply &&
            relationshipIsInviteable &&
            investorIsRegistered &&
            channelIsInvitable
        );

        return canAccessTpoChannel;
    }

    /**
     * @param inviteStatus Provided via the specific channel data
     * @param user Needed to determine if the user can cancel an invite. Assumed to be defined and valid.
     * @returns true if the user can cancel the invitation
     */
    canCancelInvitation(
        inviteStatus: TextEntity,
        user: UserProfile,
    ): boolean {
        const userCanInviteToApply: boolean = user.can('INVITE_TO_APPLY');
        const invitationSent: boolean = (inviteStatus?.id === 'SENT');

        const canAccessTpoChannel: boolean = (
            userCanInviteToApply &&
            invitationSent
        );

        return canAccessTpoChannel;
    }
}
