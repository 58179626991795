import { Injector, Injectable } from '@angular/core';

import { NgResource } from 'commons/interfaces';
import { UserService as UService } from 'angularjs-providers/user.provider';
import { Observable } from 'rxjs';
import { TableauReportingGroup } from 'shared/new-contacts/contacts.interface';
import { RealmHttpClient } from 'commons/services/http';

declare let apiPath: string;

export type ExtendedUserProfile = {
	address1: string;
	address2: string;
	channelsAssignedIds: number[];
	city: string;
	externalId: number;
	firstName: string;
	fullName: string;
	id: number;
	isAccountAssigned: boolean;
	isActive: boolean;
	isComergenceRepresentative: boolean;
	isEmailBounced: boolean;
	isFullVisibilityAllowed: boolean;
	isReportingManager: boolean;
	lastName: boolean;
	organizationReportingEnabled: boolean;
	phone: string,
	reportingLicense: string;
	reportsToUserId: number;
	state: string;
	status: {
		id: string;
		name: string;
	}
	systemRoleId: number;
	userName: string;
	userReportingEnabled: boolean;
	zip: string;
};

@Injectable()
export class LenderUsersResourceService {
	channels: any;
	user: any;
	managers: any;
	reportingStatus: any;

	constructor(
		public injector: Injector,
		public userService: UService,
        private http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');

		this.channels = $resource(`${apiPath}/lenders/:lenderId/channels`, {
			lenderId: this.userService.profile.organization.id,
		}, {
			get: {method: 'get', isArray: true},
		});

		const userPath = `${apiPath}/organizations/:organizationId/users/:userId`;
		this.user = $resource(userPath, {
			userId: '@userId',
			organizationId: this.userService.profile.organization.organizationId,
		},
		{
			get: {
				method: 'get',
			},
			update: {
				method: 'put',
			},
			assignmentVerification: {
				method: 'post',
				url: `${userPath}/assignment_verification`,
			},
			getSettings: {
				method: 'get',
				url: `${userPath}/settings`,
			},
			updateSettings: {
				method: 'put',
				url: `${userPath}/settings`,
			},
		});

		const managersPath = `${apiPath}/organizations/:organizationId/users/reporting-managers`;
		this.managers = $resource(
			managersPath,
			this.userService.profile.organization.type === 'CUSTOMER' ? { organizationId: this.userService.profile.organization.organizationId } : {},
			{
				list: {
					method: 'get',
					isArray: true,
				},
			},
		);
	}

	getChannels = (...args) => this.channels.get(...args);
	getReportingManagers = (...args) => this.managers.list(...args);
	get = (...args): NgResource<ExtendedUserProfile> => this.user.get(...args);
	update = (...args) => this.user.update(...args);
	assignmentVerification = (...args) => this.user.assignmentVerification(...args);
	getSettings = (...args) => this.user.getSettings(...args);
	updateSettings = (...args) => this.user.updateSettings(...args);

    getReportingGroups(organizationId: number = this.userService.profile.organization.organizationId): Observable<TableauReportingGroup[]> {
        return this.http.request<TableauReportingGroup[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/reporting/groups`,
        );
    }
}
