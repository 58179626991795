import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CcmDataMatchService } from 'comergence/data-match/ccm-data-match.service';
import { CcmDataMatchReport } from 'comergence/data-match/ccm-data-match.interface';
import { Validators } from '@angular/forms';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';

@Component({
    selector: 'ccm-data-match-relationship-status-section',
    templateUrl: 'ccm-data-match-update-relationship-status.component.html',
})
export class CcmDataMatchUpdateRelationshipStatusComponent implements OnInit {
    @Input() isApplicable: boolean;
    @Input() dataMatchReloadClient: CcmDataMatchReport;
    @Output() newRelationshipToRestore: EventEmitter<CcmDataMatchReport> = new EventEmitter<CcmDataMatchReport>();
    @Output() resolving = new EventEmitter<boolean>();
    @Input() reportId: number;
    isNoUpdateSelected: boolean;
    selectedCheckBoxList: string[] = [];
    isNoUpdateCheckboxGroupSelected: boolean = false;
    otherGroupCheckboxSelected: boolean = false;
    isLoadClientButtonDisabled: boolean;
    preConditionDisabledLoadClient: boolean;
    private _isLoaded: boolean;

    form: RealmFormGroup = new RealmFormGroup({
        terminate: new RealmFormControl(
            'terminate',
            {
                label: 'Terminate',
            },
        ),
        declined: new RealmFormControl(
            'declined',
            {
                label: 'Declined',
            },
        ),
            cancelled: new RealmFormControl(
            'cancelled',
            {
                label: 'Cancelled',
            },
        ),
        noUpdate: new RealmFormControl(
            'noUpdate',
            {
                label: 'No Update',
            },
        ),
    });

    constructor(private ccmDataMatchService: CcmDataMatchService) {}

    @Input() set isLoaded(value) {
        this._isLoaded = value;
        if(this.dataMatchReloadClient) {
            this.reevaluateCondition();
        }
    }

    get isLoaded() {
        return this._isLoaded;
    }

    ngOnInit(): void {
        this.reevaluateCondition();
        if (this.isLoaded) {
            this.form.addControl('message', new RealmFormControl(
                'message',
                { label: 'Message', updateOn: 'change', value: this.dataMatchReloadClient.message},
                Validators.required,
            ));
        }
    }

    async updateRelationshipStatus() {
        const verifyResult: CcmDataMatchReport = {
            ...this.dataMatchReloadClient,
            inactiveRelationshipsToRestore: this.selectedCheckBoxList,
            message: this.form.get('message').value,
        };

        try {
            this.resolving.emit(true);
            const restoredRelationship: CcmDataMatchReport = await this.ccmDataMatchService.updateClientRelationStatus(this.reportId, verifyResult).toPromise();
            this.newRelationshipToRestore.emit(restoredRelationship);
            this.selectedCheckBoxList = [];
            this.form.reset();
            this.form.get('message').patchValue(restoredRelationship.message);
            this.isNoUpdateCheckboxGroupSelected = false;
            this.otherGroupCheckboxSelected = false;
            this.reevaluateCondition();
        } catch (e) {
            this.form.setServerError(e);
        } finally {
            this.resolving.emit(false);
        }
    }

    setCheckboxListValue($event) {
        const isChecked = $event.target.checked;
        const checkedValue = $event.target.value;
        if(this.isNoUpdateSelected) {
            this.isNoUpdateSelected = false;
            this.isNoUpdateCheckboxGroupSelected = false;
        }
        if (isChecked) {
            this.selectedCheckBoxList.push(checkedValue);
            this.otherGroupCheckboxSelected = true;
            this.reevaluateCondition();
        } else {
            const itemIndex: number = this.selectedCheckBoxList.indexOf(checkedValue);
            if (itemIndex != -1) {
                this.selectedCheckBoxList.splice(itemIndex, 1);
                if(this.selectedCheckBoxList.length == 0)
                {
                    this.otherGroupCheckboxSelected = false;
                    this.reevaluateCondition();
                }
            }
        }
    }

    noUpdateCheckboxChange(event) {
        if(event.target.checked) {
            this.form.controls['terminate'].reset();
            this.form.controls['cancelled'].reset();
            this.form.controls['declined'].reset();
            this.selectedCheckBoxList = [];
            this.isNoUpdateSelected = true;
            this.isNoUpdateCheckboxGroupSelected = true;
            this.otherGroupCheckboxSelected = false;
            this.reevaluateCondition();
        }
        else {
            this.isNoUpdateCheckboxGroupSelected = false;
            this.reevaluateCondition();
        }
    }

    reevaluateCondition() {
        this.preConditionDisabledLoadClient = !this.isLoaded || !this.isApplicable || this.dataMatchReloadClient.result.passCount === 0;
        const isCheckboxSelected: boolean = this.isNoUpdateCheckboxGroupSelected || this.otherGroupCheckboxSelected;
        this.isLoadClientButtonDisabled = this.preConditionDisabledLoadClient || !isCheckboxSelected;
    }

    exportResults() {
        window.open(`/api/rest/datamatch/${this.dataMatchReloadClient.id}/loadReport`, '_blank');
    }
}
