import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { AccountIdentifier } from 'shared/account/company-information/account-information.service';
import { Observable } from 'rxjs';
import { INSURANCE_BONDS_TYPE } from 'shared/account/information/insurance-bonds/insurance-bonds-types';

declare let apiPath;

export interface InsuranceBond {
    amount: number;
    expiration: number;
    id: number;
    insuranceBondType: INSURANCE_BONDS_TYPE;
    carrierName: string;
    phone: string;
}

@Injectable()
export class InsuranceBondService {
    constructor(private http: RealmHttpClient) {
    }

    // Resources

    getList<T extends InsuranceBond[] = InsuranceBond[]>(identifier: AccountIdentifier): Observable<T> {
        return this.http.request(
            'GET',
            `${apiPath}/tpos/${identifier.tpoId}/insurance-bond`,
        );
    }

    saveInsuranceBond<T extends InsuranceBond = InsuranceBond>(identifier: AccountIdentifier, insuranceBond: T): Observable<T> {
        if (insuranceBond.id) {
            return this.saveExistingInsuranceBond(identifier.tpoId, insuranceBond);
        }

        return this.saveNewInsuranceBond(identifier.tpoId, insuranceBond);
    }

    saveExistingInsuranceBond<T extends InsuranceBond = InsuranceBond>(tpoId: number, insuranceBond: T): Observable<T> {
        return this.http.request(
            'PUT',
            `${apiPath}/tpos/${tpoId}/insurance-bond/${insuranceBond.id}`,
            null,
            insuranceBond
        );
    }

    saveNewInsuranceBond<T extends InsuranceBond = InsuranceBond>(tpoId: number, insuranceBond: T): Observable<T> {
        return this.http.request(
            'POST',
            `${apiPath}/tpos/${tpoId}/insurance-bond`,
            null,
            insuranceBond
        );
    }

    // Utilities

    public separateInsuranceBonds(
        combined: InsuranceBond[],
        original: Record<INSURANCE_BONDS_TYPE, InsuranceBond[]>
    ): Record<INSURANCE_BONDS_TYPE, InsuranceBond[]> {
        return combined.reduce(
            (acc, item) => {
                acc[item.insuranceBondType].push(item);

                return acc;
            },
            original
        );
    }
}
