<div class="library-content">
	<div class="manage-feed">
		<div class="flex-head mb30">
			<div class="col-fit">
				<ul class="breadcrumbs grey">
					<li>
						<a uiSref="social-compliance.publisher.library.content">My Feeds</a>
					</li>
				</ul>
				<h2 class="title-label">Manage RSS</h2>
			</div>
		</div>

		<notification></notification>

		<div class="content remote-resource" [class.resolved]="list?.$resolved">
			<form [formGroup]="addRssForm" (onValidSubmit)="save()">
				<form-errors [form]="addRssForm"></form-errors>
				<div class="horizontal-flex-group right mb20">
					<h3 class="full-width no-space">Added RSS</h3>
					<button type="button" (click)="toggleRssForm()" [disabled]="showAddRssForm" class="btn btn-primary">Add RSS</button>
				</div>
				<div *ngIf="showAddRssForm" class="horizontal-flex-group addLinkGroup">
					<div class="logo-holder space-bigger">
						<div class="logo"></div>
					</div>
					<div class="full-width" [class.has-error]="addRssForm.get('url').invalid && addRssForm.get('url').touched">
						<input class="form-control" type="text" required formControlName="url" placeholder="www." />
						<a class="glyphicon glyphicon-remove form-control-feedback" (click)="clear()"></a>
					</div>
					<button type="submit" class="btn btn-primary">Save</button>
					<a (click)="cancel()" class="btn btn-default no-space">Cancel</a>
				</div>
			</form>

			<div *ngIf="addedList.length" class="rows-bordered mb20">
				<div class="row-fluid" *ngFor="let entry of addedList">
					<div class="col-sm-12">
						<div class="horizontal-flex-group">
							<div class="logo-holder space-bigger">
								<div class="logo" [ngStyle]="{ 'background-image': 'url(/static' + entry.logo + ')' }"></div>
							</div>
							<div class="full-width rss-content">
								<h5 class="title">{{entry.title}}</h5>
								<span class="description" *ngIf="entry.description">{{entry.description}}</span>
								<a *ngIf="!entry.description" [href]="item?.url">{{item?.url}}</a>
							</div>
							<div>
								<div class="inline-menu" dropdown>
									<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
										<span class="c-icon c-icon-dot-3"></span>
									</a>
									<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
										<li>
											<a (click)="approveRemoveRSS(entry)">Remove</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="list?.$resolved && !addedList.length" class="empty-list mb20">
				No RSS links added
			</div>

			<h3>Suggested</h3>

			<div *ngIf="suggestedList.length" class="rows-bordered mb20">
				<div class="row-fluid" *ngFor="let entry of suggestedList">
					<div class="col-sm-12">
						<div class="horizontal-flex-group">
							<div class="logo-holder space-bigger">
								<div class="logo" [ngStyle]="{ 'background-image': 'url(/static' + entry.logo + ')' }"></div>
							</div>
							<div class="full-width">
								<h5 class="title">{{entry.title}}</h5>
								<span class="description" *ngIf="entry.description">{{entry.description}}</span>
								<a *ngIf="!entry.description" [href]="item.url">{{item.url}}</a>
							</div>
							<a (click)="addFromSuggested(entry)" class="btn btn-primary no-space">Add</a>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="list?.$resolved && !suggestedList.length" class="empty-list mb20">
				No Suggested links
			</div>
		</div>
	</div>
</div>
