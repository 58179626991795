<div class="document-card questionnaire-document">
	<div class="front-face flex-row stretch gap-m">
		<div class="flex">
			<div class="title">{{document.documentName}}</div>

			<show-more class="description text-muted" [text]="document.documentDescription"></show-more>

			<div *ngIf="document.periods" class="text-muted mb5">
				Periods Requested: {{document.periods}}
			</div>

			<div class="indications flex-row gap-sm">
				<span
					class="label label-default{{['QUESTION', 'FINANCIAL_DOC'].includes(document.documentType) ? '-wired' : ''}} text-smaller"
				>
					{{documentName[document.documentType]}}
				</span>

				<a
					*ngIf="document.hasTemplate"
					class="btn btn-tertiary"
					[href]="documentTemplateLink"
					target="_blank"
				>
					<span class="c-icon c-icon-file"></span> Template
				</a>
			</div>
		</div>

		<div class="actions flex-column right gap-sm">
			<button
				type="button"
				*ngIf="canRemove"
				class="btn btn-tertiary btn-icon"
				(click)="remove(document.investorClientDocumentId)"
			>
				<span class="glyphicon glyphicon-trash"></span>
			</button>
		</div>
	</div>
</div>
