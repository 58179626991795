<section class="remote-resource" [class.resolved]="alertIds">
    <h2>IDs</h2>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-4">
                <b>Name</b>
            </span>
            <span class="col-sm-4">
                <b>Client SID</b>
            </span>
            <span class="col-sm-4">
                <b>Client UID</b>
            </span>
        </div>

        <div *ngFor="let alertId of alertIds" class="row-fluid">
            <span class="col-sm-4">
                <label>{{ alertId.name }}</label>
            </span>
            <span class="col-sm-4">
                {{ alertId.clientSID || '&ndash;' }}
            </span>
            <span class="col-sm-4">
                {{ alertId.clientUID || '&ndash;' }}
            </span>
        </div>

        <div *ngIf="!alertIds || !alertIds.length" class="row-fluid">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No IDs</span>
        </div>
    </div>
</section>
