import { Component } from '@angular/core';

import { ChecklistSettingsService } from '../checklist-settings.service';

@Component({
	templateUrl: './regulations.component.html',
})
export class ChecklistSettingsRegulationsComponent {
	filtersConfig: any;
	fetchIssues: any;
	updateIssues: any;
	groupColumnConfig = {
		label: 'Group',
		fieldName: 'group',
	};

	constructor(
		checklistSettingsService: ChecklistSettingsService,
	) {
		this.filtersConfig = [
			// {
			// 	container: 'col-sm-6',
			// 	filter: {
			// 		label: 'Groups',
			// 		name: 'groupId',
			// 		items: checklistSettingsService.groups.get(),
			// 		bindValue: null,
			// 		bindLabel: null,
			// 		placeholder: 'All Groups',
			// 	},
			// },
		];
		this.fetchIssues = checklistSettingsService.regulations.get;
		this.updateIssues = checklistSettingsService.regulations.update;
	}
}
