<span class="text-nowrap" *ngIf="!isRemovalRequested && status === 'CONNECTED'">
	<i class="dot dot-success block"></i>&nbsp;
	Connected
</span>
<span class="text-nowrap" *ngIf="!isRemovalRequested && status === 'DISCONNECTED'">
	<i class="dot dot-danger block"></i>&nbsp;
	Disconnected
</span>
<span class="text-nowrap" *ngIf="isRemovalRequested">
	<i class="dot dot-warning block"></i>&nbsp;
	Pending Removal
</span>
