<div class="remote-resource" [class.resolved]="list?.$resolved">
	<div class="rows-bordered">
		<div class="row-fluid header">
			<span class="col-md-3"><b class="two-liners">Date</b></span>
			<span class="col-md-5"><b class="two-liners">Action</b></span>
			<span class="col-md-3"><b class="two-liners">Action By</b></span>
		</div>
		<div class="row-fluid" *ngFor="let entry of list">
			<div class="col-md-3">
				{{entry.actionAt | date : 'MM/dd/yyyy HH:mm'}}
			</div>
			<div class="col-md-5" [ngSwitch]="entry.newValue == entry.previousValue">
				<ng-container *ngSwitchCase="true">
					<div *ngFor="let action of entry.actions">{{action}}</div>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<a (click)="showDetails(entry)">
						<div *ngFor="let action of entry.actions">{{action}}</div>
					</a>
				</ng-container>
			</div>
			<div class="col-md-3">{{entry.actionBy}}</div>
		</div>

		<div class="row-fluid" *ngIf="!list?.length && list?.$resolved">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak va-m">No matches found</span>
		</div>
	</div>

	<br>

	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>

