
const Modes = {
	new: {
		title: 'New Role',
		showActionBtn: false,
		defaultEditing: true,
		showStatusLabel: false,
	},
	edit: {
		title: 'Role Information',
		showActionBtn: true,
		defaultEditing: false,
		showStatusLabel: true,
	},
	copy: {
		title: 'Copy Role',
		showActionBtn: false,
		defaultEditing: true,
		showStatusLabel: false,
	},
};

export { Modes };
