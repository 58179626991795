import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { FacebookReviewComponent } from './facebook-review/facebook-review.component';
import { ZillowYelpReviewComponent } from './zillow-yelp-review/zillow-yelp-review.component';
import { ReviewComponent } from './review.component';
import { ReviewsReplyComponent } from './reply';

import { ReviewCommentsComponent } from './common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

const components = [
	ReviewComponent,
	FacebookReviewComponent,
	ZillowYelpReviewComponent,
	ReviewsReplyComponent,
	ReviewCommentsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		PipesModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule,
	],
	declarations: [
		...components,
	],
	exports: [
		...components,
	],
})
export class ReviewModule {}
