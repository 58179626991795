<div class="questionnaires-list">
	<div class="gray-head flex-row">
		<h2 class="flex">Manage Questionnaires</h2>
		<ul class="tab-menu align-self-bottom">
			<li
				*ngFor="let tab of tabs"
				uiSrefActive="active"
			>
				<a uiSref="{{tab.state}}">
					<b>{{tab.title}}</b>
				</a>
			</li>
		</ul>
	</div>

	<div class="mb20 flex-row space-between">
		<div class="flex-row">
			Filter by:&nbsp;&nbsp;
			<div class="w120">
				<ng-select
					[ngModel]="params.status"
					(change)="setFilter({ status: $event?.id })"
					[items]="filters.status"
					bindValue="id"
					bindLabel="name"
					placeholder="All Statuses"
				></ng-select>
			</div>
		</div>
		<a *ngIf="User.can('MANAGE_GLOBAL_QUESTIONNAIRE')"
		   class="btn btn-primary"
		   uiSref=".new.questions"
		>Create Questionnaire</a>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
		<div class="row-fluid header">
			<sortable-header class="col-md-3" columnName="name">Questionnaire Name</sortable-header>
			<sortable-header class="col-md-6" columnName="description">Description</sortable-header>
			<sortable-header class="col-md-a" columnName="questionsNumber">Number of Questions</sortable-header>
            <span class="col-f-140"></span>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<div class="col-md-12 text-center va-m">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results.
			</div>
		</div>
		<a *ngFor="let item of list"
			class="row-fluid clickable"
			uiSref="questionnaires.:questionnaireId.questions"
			[uiParams]="{ action: 'edit', questionnaireId: item.id }"
		>
			<span class="col-md-3 va-m">
				<span class="dot dot-success block" [class.hide-by-default]="item.status !== 'A'"></span>&nbsp;
				<a
					uiSref="questionnaires.:questionnaireId.questions"
					[uiParams]="{ action: 'edit', questionnaireId: item.id }"
				>
					{{item.name}}
				</a>
			</span>
			<span class="col-md-6 va-m">{{item.description}}</span>
			<span class="col-md-a va-m">{{item.questionsNumber}}</span>
            <span class="col-f-140 text-right button-holder" (click)="cancelBubble($event)">
                <ng-container  *ngIf="item.channels?.length">
                    <ng-template #tooltip>
                        <div *ngFor="let name of item.channels">{{name}}</div>
                    </ng-template>
                    <span class="btn btn-tertiary btn-icon-flex btn-icon-disabled" [tooltip]="tooltip" placement="left">
                        <span class="c-icon c-icon-connections text-big"></span>
                    </span>
                </ng-container>
                <span class="btn btn-tertiary btn-icon-flex btn-icon-disabled">
                    <span class="glyphicon glyphicon-random" *ngIf="item.useSkipLogic"></span>
                </span>
				<a
					class="btn btn-tertiary btn-icon-flex"
					uiSref="questionnaires.:questionnaireId.questions.preview"
					[uiParams]="{ action: 'edit', questionnaireId: item.id }"
				>
					 <span class="glyphicon glyphicon-play-circle text-bigger"></span>
				</a>
            </span>
		</a>
		<div class="row-fluid" *ngIf="!list.length && filters.q">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak va-m">No matches found</span>
		</div>
		<div class="row-fluid" *ngIf="!list.length && !filters.q">
			<span class="col-md-12 text-muted text-center text-bigger va-m">No Questionnaires</span>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
