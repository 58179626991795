import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';

export abstract class StateService {
	href: (stateOrName, params?, options?) => string;
	get: (stateName?) => object;
	go: (stateOrName, params?, options?) => any;
	notify: (stateOrName, params?, options?) => any;
	current: { name };
	reload: () => null;
}

export const StateServiceProvider = {
	provide: StateService,
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('$state'),
	deps: ['$injector'],
};
