<div class="financial-summary">
	<div class="rows-bordered summary-fields">
		<ng-container *ngFor="let _ of [0, 1]">
			<div class="row-fluid header">
				<span class="col-md-5 va-m"></span>
				<span class="col-md-4 va-m">Period</span>
				<span class="col-md-3 va-m">Amount</span>
			</div>
			<div class="row-fluid" *ngIf="!tpoFinancialDocuments?.length">
				<div class="col-sm-12 text-center text-muted text-bigger va-m">No data</div>
			</div>
		</ng-container>
	</div>
	<div class="rows-bordered summary-fields">
		<div *ngFor="let field of tpoFinancialDocuments" class="row-fluid summary-field">
			<span class="col-sm-5 solid va-m">{{field.name}}&nbsp;</span>
			<span class="col-sm-4 va-m">{{field.period || '&ndash;'}} {{field.year}}</span>
			<span class="col-sm-3 va-m">
				<ng-container [ngSwitch]="!!field.value || field.value === 0">
					<ng-container *ngSwitchCase="true">
						{{field.value | currency}}
					</ng-container>
					<ng-container *ngSwitchCase="false">
						&ndash;
					</ng-container>
				</ng-container>
			</span>
		</div>
	</div>
</div>