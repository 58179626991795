<client-document-card
	className="investor-internal-document"
	[document]="document"
	[downloadLink]="document.documentReference && downloadLink"
	(upload)="!document.$pending && canUpload && onFileSelect($event)"
>
	<ng-template #indications>
		<span
			*ngIf="document.requestsCount"
			class="label label-danger-wired"
		>
			Requested by {{ document.requestsCount | i18nPlural : {'=1': '1 Lender', 'other': '# Lenders'} }}
		</span>

		<a
			class="btn btn-tertiary"
			uiSref=".history"
			[uiParams]="{ docId: document.internalDocumentId }"
		>
			<span class="c-icon c-icon-back-in-time"></span> History
		</a>

		<span *ngIf="document.uploadedDocument" class="updated-by">
			Uploaded by {{document.uploadedDocument.uploadedBy}} on {{document.uploadedDocument.uploadedDate | date: 'MM/dd/yyyy HH:mm'}}
		</span>
	</ng-template>

	<ng-template #actions>
		<div class="flex-column right gap-sm">
			<ng-container *ngIf="canUpload">
				<label class="file-input">
					<span class="btn btn-primary">Upload</span>
					<input type="file" [multiple]="false" (input)="onFileSelect($event.target.files)"/>
				</label>
			</ng-container>

			<ng-container *ngIf="user.can('MANAGE_INTERNAL_DOCUMENTS')">
				<a
					class="btn btn-secondary"
					uiSref=".:documentId"
					[uiParams]="{ documentId: document.internalDocumentId }"
				>
					Edit
				</a>
			</ng-container>
		</div>
	</ng-template>
</client-document-card>
