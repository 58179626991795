import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { CompanyInfo } from 'lender/crm/activities/activities.service';
import { Observable } from 'rxjs';

declare let apiPath;

@Injectable({ providedIn: 'root' })
export class CcmTpoService {

    constructor(
        protected http: RealmHttpClient,
    ) {
    }

    getTpo(tpoId): Observable<CompanyInfo> {
        return this.http.request<CompanyInfo>(
            'get',
            `${apiPath}/tpos/${tpoId}/company-information`,
        );
    }
}
