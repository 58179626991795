import { Component, Input, OnInit } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { StateService } from '@uirouter/core';
import { FilteredOwners, AddEditFindingData, OwnerInformation } from 'shared/due-diligence/scorecard/scorecard-add-findings/scorecard-add-finding.interface';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { Validators } from '@angular/forms';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { FileSizeValidator } from 'commons/validators';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import { ScorecardFindingFormService } from '../services/scorecard-finding-form.service';
import { KeyedScorecardFindingCategories, ScorecardFindingCategory } from '../scorecard-finding-category.interface';
import { SupportingDocumentReplacedEvent } from '../scorecard-supporting-documents/scorecard-supporting-documents.interface';
import { ScorecardFinding } from '../scorecard-finding.interface';


@Component({
    templateUrl: './scorecard-add-new-finding.component.html',
})
export class ScorecardAddNewFindingComponent implements OnInit {
    addFindingForm: RealmFormGroup = new RealmFormGroup( {
        notify: new RealmFormControl(
            'notify',
            {
                label: 'Notify Customer',
                value: true,
                updateOn: 'change',
            },
        ),
        statement: new RealmFormControl(
            'statement',
            {
                label: 'Statement',
                updateOn: 'blur',
            },
        ),
        findingCategory: new RealmFormControl(
            'findingCategory',
            {
                label: 'Category',
            },
            Validators.required,
        ),
        individualId: new RealmFormControl(
            'individualId',
            {
                label: 'Related To',
            },
            Validators.required,
        ),
        summary: new RealmFormControl(
            'summary',
            {
                label: 'Summary',
            },
        ),
        files: new RealmFormControl(
            'files',
            {
                label: 'Supporting Documents',
                value: [],
                updateOn: 'change',
            },
            [ FileSizeValidator(20 * Math.pow(2, 20)) ],
        ),
    },
        {validators: this.scorecardFindingFormService.confirmationValidator}
    );

    isNotify: boolean = true;
    @Input() public lenderInfo: AccountInformation;
    tpoId: number;
    scorecardFindingCategoryList: ScorecardFindingCategory[];
    keyedScorecardFindingCategories: KeyedScorecardFindingCategories;
    resolved: boolean;
    filteredOwnerList: FilteredOwners[];
    findingData: AddEditFindingData;
    resolving: boolean;

    constructor(
        private readonly scorecardService: ScorecardService,
        private readonly scorecardFindingFormService: ScorecardFindingFormService,
        private readonly stateService: StateService,
        private notificationService: GlobalNotificationsService,
    ) {

    }

    async ngOnInit() {
        this.tpoId = this.lenderInfo.identifier.tpoId;
        this.scorecardFindingCategoryList = (await this.scorecardService.getScorecardCategories(this.tpoId).toPromise()).categories
            .filter(
            category => {
                return category.code != 'LIC'
            }
        );
        this.loadOwners();
    }

    notifyChange() {
        this.isNotify = !this.isNotify;
        if(this.isNotify) {
            this.addFindingForm.get('statement').reset();
        }
    }

    async loadOwners() {
        try {
            this.resolved = true;
            const ownerList: OwnerInformation[] = await this.scorecardService.getOwnerInformation(this.tpoId).toPromise();
            this.filteredOwnerList = ownerList?.filter(e1 => {
                return e1.contactType != 'company' && e1.ownershipPercentage > 0
            }).map(owner => {return {id: owner.contactId, name: owner.fullName}});

            return this.filteredOwnerList.unshift({id: this.lenderInfo.organizationId, name: this.lenderInfo.companyName});
        }
        finally {
            this.resolved = false;
        }
    }

    addNewFile(newFileList: FileList) {
        try {
            this.scorecardFindingFormService.addNewFile(
                this.addFindingForm.controls.files.value,
                newFileList,
            );
        } catch (e) {
            // TODO: Further investigate file size validation in original edit implementation
            // this.fileError = e.message;
        }
    }

    replaceFile(event: SupportingDocumentReplacedEvent) {
        this.scorecardFindingFormService.replaceFile(
            event,
            this.addFindingForm.controls.files.value,
        );
    }

    removeFile(fileToRemove: File) {
        this.scorecardFindingFormService.removeFile(
            fileToRemove,
            this.addFindingForm.controls.files.value,
        );
    }

    async addNewFinding() {
        const formData = this.addFindingForm.getRawValue();
        const summary: string = formData.summary;
        const notify: boolean = formData.notify;
        const categoryId: string = formData.findingCategory;
        const individualId: number = formData.individualId;

        const formValue: AddEditFindingData = {
            summary: summary,
            notify: notify,
            newFiles: formData.files,
        }

        try {
            this.resolving = true;
            let newlyAdded: ScorecardFinding;
            if (individualId == this.lenderInfo.organizationId) {
                newlyAdded = await this.scorecardService.addCompanyFinding(
                    this.tpoId,
                    categoryId,
                    formValue
                ).toPromise();
            } else {
                newlyAdded = await this.scorecardService.addIndividualFinding(
                    this.tpoId,
                    categoryId,
                    individualId,
                    formValue
                ).toPromise();
            }
            this.goToNewlyAddedFinding(categoryId, newlyAdded);
        } catch({ error: { message }, message: httpError }) {
            this.addFindingForm.setServerError({message: message});
        } finally {
            this.resolving = false;
        }
    }

    cancelAddFinding() {
        this.stateService.go('tpo.:id.scorecard.summary');
    }

    private goToNewlyAddedFinding(categoryId: string, newlyAdded: ScorecardFinding) {
        this.notificationService.add({
            type: GlobalNotificationType.POSITIVE,
            message: `New Finding was added successfully. <a href="#/tpo/${this.tpoId}/scorecard/finding/${categoryId}/${newlyAdded.id}"  class="text-success underlined"> View Finding</a>`,
            timeout: 10000,
        });
        this.stateService.go('tpo.:id.scorecard.summary');
    }
}
