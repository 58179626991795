<div class="remote-resource" [class.resolved]="list.$resolved">
	<div class="row filters-rows" *ngIf="User.can('MANAGE_INTERNAL_DOCUMENTS')">
		<div class="col-sm-12 text-right">
			<a class="btn btn-primary" uiSref=".new">Add Internal Document</a>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid no-border" *ngFor="let item of list">
			<internal-document
				[document]="item"
			></internal-document>
		</div>
	</div>

	<div class="row" *ngIf="list.$resolved && !list.length">
		<div class="col-sm-12 text-center text-muted text-bigger">
			{{filtersApplied || filters.q ? 'No matches found' : 'No Documents'}}
		</div>
	</div>
</div>
