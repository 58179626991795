import { Component, Input } from '@angular/core';

import {
	QuestionType,
	Question,
} from '../../../questionnaires.interface';
import questionTypes from './question-types';

@Component({
	selector: 'questionnaire-question',
	templateUrl: './question.component.html',
})
export class QuestionComponent {
	QuestionType = QuestionType;
	@Input() question: Question;

	// Indicates special type of question: Bank question.
	// Result: remove skip logic, documents functionality
	@Input() isBankQuestion = false;

	get isSkipLogicColumn(): boolean {
		return !this.isBankQuestion && this.isSkipLogicVisible;
	}

	get questionType(): string {
		return questionTypes.find(({ id }) => id === this.question.questionType)?.title;
	}

	get isSkipLogicVisible(): boolean {
		const questionConfig = questionTypes.find(({ id }) => id === this.question.questionType);
		return !!questionConfig?.skipLogic;
	}
}
