<div class="gray-head flex-head">
	<div class="col-fit">
		<h2 class="title-label">
			Investor Sharing
		</h2>
	</div>
</div>

<br />

<div [class.resolved]="resolved" class="remote-resource">
	<ng-container *ngIf="allDirectionalInvestorShareRequests">
		<investor-channel-sharing-table
			[actionButtonConfigs]="outgoingTableActionButtons"
			[header]="'Sharing to Partners'"
			[otherInvestorDesignation]="'targetChannel'"
			[shareRequests]="allDirectionalInvestorShareRequests.outgoingShareRequests"
		>
		</investor-channel-sharing-table>

		<br />

		<investor-channel-sharing-table
			[actionButtonConfigs]="incomingTableActionButtons"
			[header]="'Shared by Partners'"
			[otherInvestorDesignation]="'sourceChannel'"
			[shareRequests]="allDirectionalInvestorShareRequests.incomingShareRequests"
		>
		</investor-channel-sharing-table>
	</ng-container>
</div>
