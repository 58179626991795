import { isEmpty } from 'lodash';
import { Component, forwardRef, OnDestroy } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';

import { msaListColumns } from './msa-list.config';
import { GlobalMarketParams, MarketLocation, MarketLocationParams, MsaListItem } from '../market.interface';
import { MarketService } from '../market.service';
import { LOCATION_PARAMS_KEYS, MarketDataService } from '../market-data.service';

@Component({
	templateUrl: 'msa-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => MsaListComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => MsaListComponent) },
	],
})
export class MsaListComponent extends NewPagedListComponent<MsaListItem> implements OnDestroy {
	static listName = 'msaList' as const;

	protected destroyed$: Subject<void> = new Subject();
	msaListColumns: typeof msaListColumns;
	isDetails: boolean;
	locationParams: MarketLocationParams;

	constructor(
		router: UIRouter,
		private readonly marketService: MarketService,
		private readonly dataService: MarketDataService,
	) {
		super(router);
		this.msaListColumns = msaListColumns;
		this.locationParams = this.dataService.getLocationParams(router.globals.params as GlobalMarketParams);
		this.isDetails = this.hasLocation(this.locationParams);

		this.configureList();

		this.dataService.getLocation$().pipe(
			skip(1),
			takeUntil(this.destroyed$),
		).subscribe(([location, skipRedirect]: [MarketLocation, boolean]) => {
			this.locationParams = location;

			if (!location) {
				this.dataService.setSrefParams(null)
				!skipRedirect && this.router.stateService.transitionTo(
						'crm.market.msa',
						{ ...this.dataService.getLocationEmptyParams(), [this.listName]: null },
						{ reload: true },
					);
			} else {
				!skipRedirect && router.stateService.go(
					this.isDetails ? 'crm.market.msa' : 'crm.market.companies',
					this.dataService.getLocationParams(this.locationParams),
					{ reload: true },
				);
			}
			this.isDetails = !!location;
		});
	}

	configureList(): void {
		this.globalParams.push(...LOCATION_PARAMS_KEYS);
		this.nonResettableParams.push(...LOCATION_PARAMS_KEYS);
	}

	protected loadList(listParams: ListParams): Observable<PagedData<MsaListItem[]>> {
		this.setLocationSrefParams();

		return this.marketService.getMsaList({
			...this.locationParams,
			...listParams,
		});
	}

	hasLocation(params: MarketLocationParams ): boolean {
		return !this.dataService.isEmptyParams(params);
	}

	setLocationSrefParams() {
		const { listParams } = this.dataService.getSrefParams() || {};

		if (this.hasLocation(this.locationParams)) {
			this.dataService.setSrefParams({
				...this.locationParams,
				...(!isEmpty(listParams) ? { listParams } : {}),
			});
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();

		this.destroyed$.next();
		this.destroyed$.complete();
	}
}