import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Inject,
    Optional,
} from '@angular/core';
import { Observable } from 'rxjs';
import { TpoContactsService } from 'tpo/people/contacts/contacts.service';
import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import { shareReplay, tap } from 'rxjs/operators';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { CommonValue, CommonValuesService } from 'shared/services/common-values.service';
import { USER } from 'shared/new-contacts/user.status';
import { ConfirmModalComponent } from 'commons/components/modals';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SharedGeneralInformationComponent } from 'shared/new-contacts/information/shared-general-information.component';
import { FullTpoContact, TableauReportingGroup, TpoContactType } from 'shared/new-contacts/contacts.interface';
import { TpoEmbeddedContactDetailsComponent } from 'tpo/people/embedded/embedded-contact-details.component';

@Component({
    templateUrl: './general-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpoContactGeneralInformationComponent extends SharedGeneralInformationComponent {
    states: Observable<CommonValue[]>;
    designations: Observable<CommonValue[]>;
    loadingValue = {
        states: true,
        designations: true,
        reportingGroups: true,
    };
    USER = USER;
    accountPrefix: string = '';
    reportingGroups: Observable<TableauReportingGroup[]>;

    constructor(
        @Optional() @Inject(forwardRef(() => TpoContactDetailsComponent)) standaloneDetailsComponent: SharedContactDetailsComponent,
        @Optional() @Inject(forwardRef(() => TpoEmbeddedContactDetailsComponent)) embeddedDetailsComponent: SharedContactDetailsComponent,
        contactsService: TpoContactsService,
        commonValues: CommonValuesService,
        protected modalService: BsModalService,
    ) {
        super(standaloneDetailsComponent || embeddedDetailsComponent, contactsService);
        this.states = commonValues.getStates()
            .pipe(
                tap(() => this.loadingValue.states = false),
                shareReplay(1),
            );
        this.designations = commonValues.getDesignations(true)
            .pipe(
                tap(() => this.loadingValue.designations = false),
                shareReplay(1),
            );
        this.reportingGroups = (this.contactsService as TpoContactsService).getReportingGroups()
            .pipe(
                tap(() => this.loadingValue.reportingGroups = false),
                shareReplay(1),
            );
    }

    // Override
    public async triggerSave(): Promise<void> {
        try {
            await this.confirmReporting();
            super.triggerSave();
            this.cd.detectChanges();
        } catch (e) {
            // modal closed or cancels
        }
    }

    // Override
    protected async loadNewData(): Promise<void> {
        this.roles = await this.rolesResourceService.getSystemRolesForLenderContactCreation(this.getTpoId()).toPromise();
    }

    // Override
    public getTpoId = () => this.user.organization.id;

    // Override
    public getNewContactData(): Partial<FullTpoContact> {
        return { contactType: TpoContactType.REGISTERED };
    }

    // Override
    public getRemoveContactMessage(): string {
        return `Are you sure you want to remove contact <b>${this.contact.fullName}</b>?`;
    }

    // Override
    public newContactSaved(savedContact: FullTpoContact): void {
        this.navigateToViewContacts(savedContact);
    }

    // Override
    public contactRemoved(): void {
    }

    // Override
    public cancelEdit() {
        super.cancelEdit();

        if (this.isNew) {
            const contactListHref: string = this.getContactListHref();
            this.stateService.go(
                contactListHref,
                {
                },
                {
                    location: 'replace',
                }
            );
        }
    }

    protected confirmReporting(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if(this.contactForm.value.reportingGroups?.length && !this.contact?.reportingGroups?.length) {
                this.modalRef = this.modalService.show(ConfirmModalComponent, {
                    initialState: {
                        title: 'Acknowledgement',
                        message: 'The user acknowledges, understands, and agrees by turning reports  “on”, ' +
                            'the user’s financial institution will be charged $25.00 per month for this feature.',
                        confirmText: 'I Agree',
                        cancelText: 'Cancel',
                        onConfirm: () => {
                            resolve();
                            subscription.unsubscribe();
                            this.modalRef.hide();
                        },
                    },
                    class: 'modal-new',
                });
                const subscription = this.modalRef.onHidden.subscribe(() => {
                    reject();
                })
            } else {
                resolve();
            }
        });
    }

    confirmPrimaryChange(): void {
        const form = this.contactForm;
        if (!form.value.isPrimaryContact) {
            form.patchValue({ isPrimaryContact: confirm('A Primary Contact has already been selected for your organization, are you sure you would like to replace the existing Primary Contact?') });
        }
    }

    protected getViewContactHref(contact: FullTpoContact): string {
		const href: string = this.stateService.href('^.:contactId.information', {
			contactId: contact.contactId,
		});

        return href;
    }

    protected getContactListHref(): string {
        return '^';
    }

    protected navigateToViewContacts(contact: FullTpoContact): void {
        const viewContactHref: string = this.getViewContactHref(contact);
		const link: string = `<a class="underlined text-success" href="${viewContactHref}">View Contact</a>`;
        const contactListHref: string = this.getContactListHref();

        this.stateService.notify(
                contactListHref,
                {
                    notification: {
                        type: 'alert-success',
                        message: `Contact <b>${contact.firstName} ${contact.lastName}</b> was created successfully. ${link}`,
                    },
                },
                {
                    location: 'replace',
                }
        );
    }
}
