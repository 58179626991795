<div class="form-group no-margin has-feedback left-position">
	<input class="form-control"
		type="search"
		[ngModel]="listComponent.params.q"
		[ngModelOptions]="{ standalone: true }"
		(keyup)="setSearch($event)"
		[maxlength]="maxlength"
		[placeholder]="placeholder"
        [disabled]="disabled"
	/>
	<a class="form-control-feedback"
		[ngClass]="{'c-icon c-icon-search': !listComponent.params.q, 'glyphicon glyphicon-remove': listComponent.params.q}"
		(click)="resetSearch()"
	></a>
</div>
