import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { socialMediaNetworksConfig } from 'commons/components/sm-icons';

import { TPOArchiveService } from '../archive.service';
import { ArchivePost } from '../list/post.interface';

@Component({
	templateUrl: './post-history-modal.component.html',
})
export class PostHistoryModalComponent implements OnInit {
	list: any = [];
	resolving = true;
	post: ArchivePost;

	smConfigs = socialMediaNetworksConfig;

	constructor(
		public modalRef: BsModalRef,
		public archiveService: TPOArchiveService,
	) {}

	ngOnInit() {
		this.archiveService.getPostHistory({
			smAccountId: this.post.smAccountId,
			contentType: this.post.contentType,
			postId: this.post.id,
			version: this.post.version,
		}).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.resolving = false;
			});
	}
}
