import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InvestorsService } from 'tpo/account/investors/investors.service';
import { InvestorsSelfReportedInformation } from 'tpo/account/investors/investors.interface';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { UserService } from 'angularjs-providers/user.provider';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';


@Component({
    selector: 'investors-list',
    templateUrl: 'investors.component.html',
})
export class InvestorsComponent implements OnInit {
    @Input() lenderInfo: AccountInformation;
    @ViewChild(SectionReviewComponent, { static: true }) sectionReview: SectionReviewComponent;
    public readonly sectionType = PROFILE_SECTION.INVESTORS;
    resolving: boolean;
    investorsList: InvestorsSelfReportedInformation[];
    userCanAddEdit: boolean = false;

    constructor(
        private investorsService: InvestorsService,
        private readonly userService: UserService,

    ) {
        this.userCanAddEdit = this.userService.profile.isTpo;
    }

    async ngOnInit(): Promise<void> {
        this.investorsList = await this.investorsService.getInvestors(this.lenderInfo.identifier.tpoId).toPromise();
        this.resolving = true;
    }

    addItem() {
        this.investorsList.push({
            id: null,
            phone: null,
            relationshipStartDate: null,
            relationshipEndDate: null,
            investorName: null,
            contactName: null,
            email: null,
            productionSoldPercent: null,
        });
    }

    cancelNewItem(item: InvestorsSelfReportedInformation): void {
        if (item.id) {
            return;
        }

        const itemIndex: number = this.investorsList.indexOf(item);
        if (itemIndex != -1) {
            this.investorsList.splice(itemIndex, 1);
        }
    }
}

