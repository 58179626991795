import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class SCScheduleService {
	public resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const defaultParams = {tpoId: User.profile.organization.id};
		const basePath = `${apiPath}/tpos/:tpoId/social-media-compliance/schedule`;
		this.resource = $resource(`${basePath}`, defaultParams, {
			state: {
				method: 'get',
			},
			save: {
				method: 'post',
			},
			count: {
				url: `${basePath}/price`,
				method: 'post',
			},
			amountType: {
				url: `${basePath}/amount-type`,
				method: 'get',
				isArray: true,
			},
			reviewFrequency: {
				url: `${basePath}/review-frequency`,
				method: 'get',
				isArray: true,
			},
			currentNmlsSwitcher: {
				url: `${basePath}/current-nmlsid-switcher`,
				method: 'get',
				isArray: true,
			},
			frequency: {
				url: `${basePath}/frequency`,
				method: 'get',
				isArray: true,
			},
			saveCurrentNmlsId: {
				url: `${basePath}/current-nmlsids`,
				method: 'put',
			},
			saveNewNmlsId: {
				url: `${basePath}/new-nmlsids`,
				method: 'put',
			},
			contactsSearch: {
				url: `${basePath}/contacts`,
				method: 'post',
				isArray: true,
			},
		});
	}

	state = (...args) => this.resource.state(...args);
	save = (...args) => this.resource.save(...args);
	count = (...args) => this.resource.count(...args);
	amountType = (...args) => this.resource.amountType(...args);
	reviewFrequency = (...args) => this.resource.reviewFrequency(...args);
	currentNmlsSwitcher = (...args) => this.resource.currentNmlsSwitcher(...args);
	frequency = (...args) => this.resource.frequency(...args);
	saveCurrentNmlsId = (...args) => this.resource.saveCurrentNmlsId(...args);
	saveNewNmlsId = (...args) => this.resource.saveNewNmlsId(...args);
    contactsSearch = ({ excludedContactId, ...params }, ...args) => this.resource.contactsSearch(params, { excludedContactIds: excludedContactId }, ...args);
}
