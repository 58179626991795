import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { SharedDocumentsModule } from 'shared/documents';

import { ComergenceDocumentsService } from './comergence-documents.service';
import { ComergenceDocumentsComponent } from './comergence-documents.component';
import {
	ComergenceDocumentListComponent,
	ComergenceDocumentListItemComponent,
	TpoDocumentUploadFromLibraryModalComponent,
    ComergenceGlobalDocumentDetailsComponent,
} from './list';
import {
	ComergenceDocumentComponent,
	ComergenceDocumentHistoryComponent,
} from './document';
import {
	// ComergenceLibraryDocumentListComponent,
	TpoLibraryDocumentListItemComponent,
	TpoLibraryDocumentRequestsModalComponent,
	// ComergenceLibraryDocumentComponent,
	ComergenceLibraryDocumentHistoryComponent,
} from './library';


const components = [
	ComergenceDocumentsComponent,
	ComergenceDocumentListComponent,
	ComergenceDocumentComponent,
	TpoDocumentUploadFromLibraryModalComponent,
	ComergenceDocumentHistoryComponent,
	// ComergenceLibraryDocumentListComponent,
	// ComergenceLibraryDocumentComponent,
	TpoLibraryDocumentRequestsModalComponent,
	ComergenceLibraryDocumentHistoryComponent,
    ComergenceGlobalDocumentDetailsComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
        ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		SharedDocumentsModule,
	],
	declarations: [
		...components,
		ComergenceDocumentListItemComponent,
		TpoLibraryDocumentListItemComponent,
	],
	providers: [
		ComergenceDocumentsService,
	],
	exports: [
		ComergenceDocumentListItemComponent,
		// ComergenceLibraryDocumentListComponent,
	],
})
export class ComergenceDocumentsModule {}
