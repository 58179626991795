<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">{{ entry.actions[0] }}</h4>
</div>
<div class="modal-body content finding-audit-details">
	<div class="rows-bordered">
		<div class="row-fluid header">
			<span class="col-md-2"><b class="two-liners">Date</b></span>
			<span class="col-md-2"><b class="two-liners">Action By</b></span>
			<span class="col-md-3"><b class="two-liners">Before</b></span>
			<span class="col-md-3"><b class="two-liners">After</b></span>
		</div>
		<div class="row-fluid">
			<div class="col-md-2">
				{{entry.actionAt | date : 'MM/dd/yyyy HH:mm'}}
			</div>
			<div class="col-md-2">{{entry.actionBy}}</div>
			<div class="col-md-3">{{entry.previousValue || '&ndash;'}}</div>
			<div class="col-md-3">{{entry.newValue || '&ndash;'}}</div>
		</div>
	</div>
</div>
