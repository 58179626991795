import { ChangeDetectionStrategy, Component, Optional, ViewChild } from '@angular/core';
import { CommonValue, CommonValuesService } from 'shared/services/common-values.service';
import { FullTpoContact, TpoContactType } from 'shared/new-contacts/contacts.interface';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { InvestorContactsService } from 'lender/new-contacts/investor-contacts.service';
import { SharedGeneralInformationComponent } from 'shared/new-contacts/information/shared-general-information.component';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { LenderContactService } from 'lender/contacts/lender-contacts.service';
import { RelatedAccountResourceInterface } from 'lender/contacts/related-account';
import { AdditionalFieldsComponent } from 'shared/fields';
import { CustomFieldsElement } from 'shared/fields/custom-fields.service';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';


const BLOCKS = {
    MAIN: 'main',
    CUSTOM_FIELDS: 'custom',
    ACCOUNT: 'account',
    ACTIVITIES: 'activities',
}
type BlockTypes = typeof BLOCKS[keyof typeof BLOCKS];

@Component({
    templateUrl: './investor-general-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorGeneralInformationComponent extends SharedGeneralInformationComponent {
    public TYPE = TpoContactType;
    states: Observable<CommonValue[]>;
    loadingValue = {
        states: true,
    };
    BLOCKS = BLOCKS;
    curtains: Partial<Record<BlockTypes, boolean>> = {};
    @ViewChild(AdditionalFieldsComponent) additionalFields: AdditionalFieldsComponent;

    constructor(
        @Optional() detailsComponent: InvestorContactDetailsComponent,
        contactsService: InvestorContactsService,
        commonValues: CommonValuesService,
        private lenderContactsService: LenderContactService,
        private notificationService: GlobalNotificationsService,
    ) {
        super(detailsComponent, contactsService);
        this.states = commonValues.getStates()
            .pipe(
                tap(() => this.loadingValue.states = false),
                shareReplay(1),
            );
    }

    // Override
    public getTpoId = () => this.routerGlobals.params.id;

    // Override
    public getNewContactData(): Partial<FullTpoContact> {
        const { relatedAccount } = this.routerGlobals.params;
        return { contactType: TpoContactType.CRM, relatedAccount };
    }

    // Override
    public getRemoveContactMessage(): string {
        return `Once removed, <b class="text-danger">this action cannot be undone</b>! You are about to permanently remove this contact. Are you sure you want to proceed?`;
    }

    // Override
    public newContactSaved(contact: FullTpoContact): void {
        const href: string = this.stateService.href('^.:contactId', {
            contactId: contact.contactId,
        });
        const link: string = `<a class="underlined text-success" href="${href}">View Contact</a>`;

        this.stateService.notify(
            'crm.contacts',
            {
                notification: {
                    type: 'alert-success',
                    message: `Contact <b>${contact.firstName} ${contact.lastName}</b> was created successfully. ${link}`,
                    timeout: 10000,
                },
            },
            {
                location: 'replace',
            }
        );
    }

    // Override
    public contactRemoved(): void
    {
        this.notificationService.add({
            type: GlobalNotificationType.NEGATIVE,
            message: `Contact has been successfully removed.`,
            timeout: 10000,
        });

		this.router.stateService.go('^.^.^.contacts');
    }

    editSection(section, value) {
        if (this.isNew) return; // ignoring for a new contact
        if (value) {
            this.curtains = Object.values(BLOCKS).reduce((result, block) => {
                if (block !== section) {
                    result[block] = true;
                }
                return result;
            }, {});
        } else {
            this.curtains = {}; // remove curtains
        }
        this.cd.markForCheck();
    }

    edit() {
        super.edit();
        this.editSection(BLOCKS.MAIN, true);
    }

    cancelEdit() {
        if (this.isNew) {
            return this.router.stateService.go('crm.contacts');
        }
        super.cancelEdit();
        this.editSection(BLOCKS.MAIN, false);
    }

    get relatedAccountResource(): RelatedAccountResourceInterface {
        return this.lenderContactsService.getRelatedAccountResource(this.contact.contactId);
    }

    public async triggerSave(): Promise<void> {
        if (!this.isNew) return super.triggerSave();

        const { groupedFields } = this.additionalFields;
        const {
            additionalFieldsForm,
            relatedAccountForm,
            ...formData
        } = this.contactForm.getRawValue();
        const allFields = [...groupedFields.fields];

        for (const group of groupedFields?.groups) {
            allFields.push(...group.fields);
        }

        const customFieldValues = allFields.map((field: CustomFieldsElement) => {
            const { value, ...customField } = field;
            return { customField, value } as CustomFieldsElement;
        });
        const nmlsId = relatedAccountForm?.related?.nmlsId;
        const relatedAccount = { nmlsId };
        const contactData = { ...this.contact, ...formData, customFieldValues, relatedAccount };

        this.loading = true;
        try {
            const savedContact: FullTpoContact = await this.contactsService.saveNewContact(contactData, null).toPromise();

            this.isNew = false;
            this.cancelEdit();

            this.newContactSaved(savedContact);
        } catch ({ error: { message }, message: httpError }) {
            this.contactForm.setServerError({ message: message || httpError });
            this.loading = false;
        }
        this.cd.detectChanges();
    }

    updateCompany(relatedAccount) {
        if (this.isNew) return;
        const { contact } = this;
        this.detailsComponent.setContact({ ...contact, relatedAccount });
    }
}
