<div class="tpo-add-edit-modal">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title text-center">{{ channelId ? 'Edit' : 'Add' }} Channel</h4>
    </div>

   <form [formGroup]="tpoAddEditForm" (onValidSubmit)="submit()">
        <div class="modal-body remote-resource" [class.resolved]="!resolving">
            <form-errors [form]="tpoAddEditForm"></form-errors>
            <div class="rows-striped">
                <div class="row-fluid">
                    <div class="col-md-8">
                        <div class="form-group row" has-error>
                            <label class="col-sm-4 control-label text-right" [class.required]="isEditing">{{tpoAddEditForm.getFieldLabel('name')}}</label>
                            <div class="col-sm-4">
                                <div [isEditable]="isEditing">
                                    <input edit type="text" name="name" formControlName="name" class="form-control" maxlength="100" tabindex="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-fluid">
                    <div class="col-md-8">
                        <div class="form-group row" has-error>
                            <label class="col-sm-4 control-label text-right" [class.required]="isEditing">{{tpoAddEditForm.getFieldLabel('template')}}</label>
                            <div class="col-sm-4">
                                <div [isEditable]="isEditing">
                                    <ng-select
                                        edit
                                        [items]="templates"
                                        [multiple]="false"
                                        [clearable]="false"
                                        formControlName="template"
                                        bindLabel="displayName"
                                        bindValue="value"
                                        placeholder="Select Template"
                                        name="template"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-8">
                        <div class="form-group row" has-error>
                            <label class="col-sm-4 control-label text-right" [class.required]="isEditing">{{tpoAddEditForm.getFieldLabel('applicationFee')}}</label>
                            <div class="col-sm-4">
                                <div [isEditable]="isEditing">
                                    <input edit type="text" name="applicationFee" formControlName="applicationFee" class="form-control" maxlength="100" tabindex="1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-8">
                        <div class="form-group row" has-error>
                            <label class="col-sm-4 control-label text-right" [class.required]="isEditing">{{tpoAddEditForm.getFieldLabel('visibleToApply')}}</label>
                            <div class="col-sm-4">
                                <label class="radio-inline">
                                    <input type="radio" formControlName="visibleToApply" name="visibleToApply" [value]="true"/>
                                    Yes
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" formControlName="visibleToApply" name="visibleToApply" [value]="false"/>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <div class="modal-footer">
           <div class="pull-left">
               <span class="red-danger">*</span><label class="text-normal text-light" text="left">- Required fields</label>
           </div>

           <div class="btn-group-lg pull-right">
               <button type="submit" class="btn btn-primary">Save</button>
               <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
           </div>
       </div>
    </form>
</div>
