<div class="users-management">
	<h3>Manage Users</h3>

	<notification></notification>

	<div class="remote-resource" [class.resolved]="list?.$resolved">
		<div class="filters-rows">
			<div class="row filter-labels">
				<div class="col-md-3">
					<div class="text-muted">Search</div>
				</div>
				<div class="col-md-3">
					<div class="text-muted">User Status</div>
				</div>
			</div>
			<div class="row filter-selectors">
				<div class="col-md-3">
					<search-list placeholder="Search by name"></search-list>
				</div>
				<div class="col-md-3">
					<ng-select
						[(ngModel)]="params.status"
						(change)="setFilter({ status: $event })"
						[items]="filters.status"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						placeholder="All Statuses">
					</ng-select>
				</div>
				<div class="col-md-6">
					<div class="horizontal-flex-group right">
						<a
							*ngIf="User?.profile?.can('CCM_MANAGE_CUSTOMER_USERS') && (information?.statusCode && information?.statusCode !== 'INACTIVE')"
							class="btn btn-primary space-big"
							uiSref=".:actionType.:id"
							[uiParams]="{ actionType:'new', id: null, customerType: customerType, organizationId: organizationId }"
							[uiOptions]="{ inherit: false, reload: true }"
						>
							Create User
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<span [ngClass]="['col-sm-3', getSortClass('personalName')]" (click)="setSort('personalName')"><b class="two-liners">Name</b></span>
				<span [ngClass]="['col-sm-a', getSortClass('status')]" (click)="setSort('status')"><b class="two-liners">Status</b></span>
				<span [ngClass]="['col-sm-a', getSortClass('systemRole')]" (click)="setSort('systemRole')"><b class="two-liners">System Role</b></span>
				<span [ngClass]="['col-sm-a', getSortClass('lastLoginDate')]" (click)="setSort('lastLoginDate')"><b class="two-liners">Last Login Date</b></span>
				<span [ngClass]="['col-sm-a', getSortClass('location')]" (click)="setSort('location')"><b class="two-liners">Location</b></span>
			</div>
			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
			</div>
			<div class="row-fluid" *ngFor="let item of list">
				<span class="col-md-3 text-left">
					<div class="pseudo-tabel">
						<span class="pseudo-cell c-icon text-muted text-big" [ngClass]="{'c-icon-reporting-manager': item.isReportingManager}"></span>
						<a
							class="pseudo-cell left-align"
							uiSref="customers.:customerType.:organizationId.manage.:actionType.:id"
							[uiParams]="{ id: item.id, actionType: 'view', customerType: customerType, organizationId: organizationId }"
						>{{item.personalName}}</a>
					</div>
				</span>
				<span class="col-md-a">
					<span class="label min-65 text-capitalize label-{{UserStatusColor[item.status.id] || 'default'}}">{{item.status.name | lowercase}}</span>
				</span>
				<span class="col-md-a">{{item.systemRole || '&ndash;'}}</span>
				<span class="col-md-a">{{item.lastLoginDate ? (item.lastLoginDate | date:'MM/dd/yyyy') : '&ndash;'}}</span>
				<span class="col-md-a">{{item.location || '&ndash;'}}</span>
			</div>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length && (filtersApplied || filters.q)">
				<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
			</div>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length && !filtersApplied && !filters.q">
				<span class="col-md-12 text-muted text-center text-bigger">No users</span>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</div>
