<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">Choose From Library</h4>
</div>
<div class="modal-body content remote-resource" [class.resolved]="!resolving">
	<div class="modal-message">
		<div class="well left">
			<ng-select
				class="mb10"
				placeholder="Choose Document From Library"
				[ngModel]="selectedDocument"
				[items]="library?.$promise | async"
				[loading]="!library?.$resolved"
				bindLabel="name"
				[clearable]="true"
				(change)="select($event)"
				required>
			</ng-select>
			<div *ngIf="selectedDocument">
				<div class="bigger">{{selectedDocument.name}}</div>
				<div class="text-muted">{{selectedDocument.description}}</div>
			</div>
		</div>
		<ng-container *ngIf="selectedDocument">
			<span class="glyphicon glyphicon-arrow-down"></span>
			<div class="document-card">{{document.name}}</div>
		</ng-container>
	</div>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="text-right">
		<button type="button" [disabled]="!selectedDocument" (click)="onConfirm(selectedDocument)" class="btn btn-primary">Confirm</button>
		<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
	</div>
</div>

