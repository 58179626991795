import {
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';

import { ClickableMenuItem, DotMenuItem } from './dot-menu.items';


@Component({
	selector: '[dot-menu],dot-menu',
	templateUrl: './dot-menu.component.html',
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotMenuComponent implements OnInit, OnDestroy {
	@Input() menuItems: DotMenuItem[];
	@Input() propogateClicks: boolean = false;

    // Override
    ngOnInit(): void {
    }

    // Override
    ngOnDestroy(): void {
    }

	menuClicked(event: PointerEvent): void {
		this.stopPropagation(event);
	}

	menuItemClicked(menuItem: ClickableMenuItem, event: PointerEvent): void {
		this.stopPropagation(event);

		menuItem.click();
	}

	private stopPropagation(event: PointerEvent): void {
		if (!this.propogateClicks) {
			event.stopPropagation();
		}
	}
}
