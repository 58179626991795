export const statusConfig = {
    Loaded: {
        color: 'success',
        title: 'Loaded',
    },
    Reverified: {
        color: 'warning',
        title: 'Reverified',
    },
    Verified: {
        color: 'warning',
        title: 'Verified',
    },
};
