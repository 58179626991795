<div class="library-content">
	<h2>Curated Content</h2>
	<div>
		<a uiSref="social-compliance.publisher.library.content.manage-feed" class="btn btn-tertiary no-space special-link">
			<i class="c-icon c-icon-rss text-light"></i> Manage RSS
		</a>
	</div>
	<br>

	<div *ngIf="resolveCount === 1 && list.$resolved && !list.length" class="get-content text-center">
		<a uiSref="social-compliance.publisher.library.content.manage-feed" class="btn btn-primary">Get Content</a>
	</div><!--end:get-content-->

	<div *ngIf="!(resolveCount === 1 && list.$resolved && !list.length)" class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-md-5">
				Article
			</div>
			<div class="col-md-3">
				Source
			</div>
			<div class="col-md-2">
				Date
			</div>
			<div class="col-md-1"></div>
			<div class="col-md-1"></div>
		</div>
		<infinite-scroll
			[list]="list"
			(onNextBatch)="loadNextPage()"
			[options]="{ entryClass: 'row-fluid', holderClass: 'rows-bordered' }"
			[shouldResetList]="shouldResetList"
			[lastPageReached]="lastPageReached"
		>
			<ng-template #entryTemplate let-entry>
				<div class="row-fluid">
					<div class="coldp-md-10 clearfix">
						<div class="horizontal-flex-group">
							<div class="image-holder">
								<div class="rss-image"
										*ngIf="entry.imageLink"
										[style.background-image]="entry.imageLink | urlSanitizer"></div>
								<i *ngIf="!entry.imageLink" class="c-icon c-icon-rss"></i>
							</div>
							<div class="description">
								<a href="{{entry.link}}" target="_blank">{{entry.title}}</a>
								<show-more [text]="entry.description" [maxHeight]="100"></show-more>
							</div>
						</div>
					</div>
					<div class="coldp-md-6">
						<!-- GOTCHA: if your image can't affect total height of an element,
							just add 'infiniteScrollSkipImageLoadEvent' attribute and infinite scroll won't wait for image to calc it's size
							in case below logo has predefined size 20x20, it less than button height or preview image-holder height
						-->
						<img class="feed-logo" infiniteScrollSkipImageLoadEvent [src]="'/static' + entry.feedLogo" />&nbsp;
						<a href="{{entry.feedLink}}" target="_blank">{{entry.feedTitle}}</a>
					</div>
					<div class="coldp-md-3 text-muted">
						{{entry.publishedDate | date:'MM/dd/yyyy'}}
					</div>
					<div class="coldp-md-2 text-muted">
						<a class="text-nowrap"
							uiSref=".:contentId"
							[uiParams]="{ contentId: entry.id }"
							*ngIf="entry.postCount">{{entry.postCount}} {{entry.postCount === 1 ? 'Post' : 'Posts'}}</a>
					</div>
					<div class="coldp-md-3 text-right">
						<a class="btn btn-primary" uiSref="social-compliance.publisher.posts.new" [uiParams]="{ rssId: entry.id }">Post</a>
					</div>
				</div>
			</ng-template>
		</infinite-scroll>
	</div>
</div>

