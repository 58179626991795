<h2 *ngIf="directAccess">Locations</h2>

<div class="remote-resource"  [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-md-3">
                <span class="text-muted small">Search</span>
            </div>
            <div class="col-md-3">
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-md-3">
                <search-list placeholder="Name, Address"></search-list>
            </div>
            <div class="col-md-3">
                <div class="form-group no-margin has-feedback left-position">
                    <input
                        class="form-control"
                        type="text"
                        [ngModel]="params.searchNmlsId"
					    (keyup)="setFilter({ searchNmlsId: $event.target.value })"
                        maxlength="75"
                        placeholder="NMLSID"
                    />
                    <a class="form-control-feedback"
                        [ngClass]="{ 'c-icon c-icon-search': !params.searchNmlsId, 'glyphicon glyphicon-remove': params.searchNmlsId }"
                        (click)="setFilter({ searchNmlsId: '' })"
                    ></a>
                </div>
            </div>
        </div>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-7" columnName="" [active]="false"></sortable-header>
            <sortable-header class="col-sm-a" columnName="" [active]="false">NMLS ID</sortable-header>
            <sortable-header class="col-sm-a" columnName="" [active]="false">Authorization</sortable-header>
            <sortable-header *ngIf="hasProspectPermission" class="col-sm-a" columnName="" [active]="false">Prospect</sortable-header>
        </div>
        <reset-filters class="row-fluid"></reset-filters>
        <ng-container *ngIf="((list | async)?.length > 0); else noItems">
            <div class="row-fluid"
                *ngFor="let item of list | async"
            >
                <div class="col-md-7">
                    <a uiSref=".:branchId.originators" [uiParams]="{branchId: item.nmlsId}" class="text-bigger">{{item.name}}</a><br>
                    <span class="text-light">{{ (item | address) }}</span>
                    <br><span class="label label-default" *ngIf="item.headquarter">Headquarter</span>
                </div>
                <div class="col-md-a">{{item.nmlsId}}</div>
                <div class="col-md-a" [ngSwitch]="item.authorization">
                    <ng-container *ngSwitchDefault>&ndash;</ng-container>
                    <span *ngSwitchCase="true" class="text-nowrap"><span class="text-success glyphicon glyphicon-ok-sign"></span> Authorized</span>
                    <span *ngSwitchCase="false" class="text-nowrap"><span class="text-danger glyphicon glyphicon-remove"></span> Not Authorized</span>
                </div>
                <div *ngIf="hasProspectPermission" class="col-md-a">
                    <prospecting-status
                        [canAdd]="hasProspectPermission"
                        [prospectInfo]="getProspectInfo(item)"
                        (prospectInfoChange)="onProspectInfoChange(item, $event)"
                    ></prospecting-status>
                </div>
            </div>
        </ng-container>
        <ng-template #noItems>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    {{filtersApplied ? 'No matches found' : 'No records added'}}
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
