import { some } from 'lodash';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfirmModalComponent } from 'commons/components/modals';
import { FinancialRequests, FinancialDocument, FinancialRequestValue } from '../../questionnaires.interface';

@Component({
	templateUrl: './configure-financial-documents-modal.component.html',
})
export class ConfigureFinancialDocumentsModalComponent extends ConfirmModalComponent {
	financialRequestType = FinancialDocument;
	title = 'Configure Financial Documents';
	guidanceText = 'Select to request financials upon questionnaire assignment.';
	confirmText = 'Save';
	resolving = true;
	disabledSave = false;
	requestsData$: Observable<{
		requests: FinancialRequests[];
		values: FinancialRequestValue[];
	}>;

	toggleRequestType(request: FinancialRequests) {
		request.enabled = !request.enabled;
		if (!request.enabled) {
			request.settings = null;
		}
	}

	notValid(requests: FinancialRequests[]): boolean {
		return some(requests, ({ enabled, settings }) => enabled && !settings?.length);
	}
}
