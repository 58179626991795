<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
	<span>{{document.name}}</span>
</back-bread>
<br>

<div class="document-history rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div class="row-fluid header">
		<div class="col-sm-3"><b class="two-liners">Date</b></div>
		<div class="col-sm-3"><b class="two-liners">Action</b></div>
		<div class="col-sm-3"><b class="two-liners">Action by</b></div>
		<div class="col-sm-3"><b class="two-liners">Document</b></div>
	</div>
	<div class="row-fluid" *ngFor="let item of list">
		<div class="col-sm-3 va-m">{{item.date | date : 'MM/dd/yyyy HH:mm'}}</div>
		<div class="col-sm-3 va-m">{{item.action}}</div>
		<div class="col-sm-3 va-m">{{item.actionBy}}</div>
		<div class="col-sm-3 va-m">
			<ng-container [ngSwitch]="!!item.fileName">
				<ng-container *ngSwitchCase="true">
					<ng-container [ngSwitch]="!!item.documentLink">
						<ng-container *ngSwitchCase="true">
							<a [href]="item.documentLink" target="_blank">{{item.fileName}}</a>
						</ng-container>
						<ng-container *ngSwitchCase="false">{{item.fileName}}</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="false">&ndash;</ng-container>
			</ng-container>
		</div>
	</div>

	<div class="row-fluid" *ngIf="list.$resolved && list.length === 0">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No history</div>
	</div>

	<br>

	<new-paging [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>
