<div class="add-word-area flex-row gap" *ngIf="editable">
	<input class="flex form-control" [(ngModel)]="toAdd" [ngModelOptions]="{ standalone: true, updateOn: 'change' }" (keydown.enter)="addWord($event)" placeholder="Enter Keywords">
	<a class="btn btn-primary" [class.disabled]="!toAdd" (click)="addWord($event)">Add</a>
</div>

<div class="words">
	<span class="word" *ngFor="let word of words; index as i">
		<span class="word-remove" *ngIf="editable" (click)="removeWord(i)">&times;</span>
		<span class="word-value">{{word}}</span>
	</span>
</div>
