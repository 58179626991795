<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" *ngIf="!resolving" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">Add RSS Link</h4>
</div>
<form [formGroup]="addForm" id="addForm" (onValidSubmit)="save()" autocomplete="off">
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<form-errors [form]="addForm"></form-errors>

		<p class="margin-bottom-lg">Please enter RSS link</p>

		<div class="row">
			<label class="col-md-6 control-label">RSS link:</label>
		</div>
		<div class="row">
			<div class="col-md-8">
				<input type="url" name="rssLink" formControlName="rssLink" class="form-control" maxlength="255" tabindex="1">
			</div>
		</div>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="btn-group-lg">
			<input type="submit" class="btn btn-lg btn-primary" value="Save">
			<a (click)="modalRef.hide()" class="btn btn-lg btn-default">Cancel</a>
		</div>
	</div>
</form>
