import { Component, Input } from '@angular/core';

export enum SOURCE_TYPE {
    MONITORING = 'M',
    ARCHIVE = 'A',
    REVIEW = 'R',
    INTERNAL = 'I',
}
export type SourceCode = typeof SOURCE_TYPE[keyof typeof SOURCE_TYPE];
export const SourceTypes = {
    'M': 'Alerts',
    'A': 'Archive',
    'R': 'Audit',
    'I': 'Internal',
}

@Component({
    selector: 'finding-source',
    templateUrl: './finding-source.component.html',
})
export class FindingSourceComponent {
    @Input() source: SourceCode;
    public SOURCE_TYPE = SOURCE_TYPE;
    public SourceTypes = SourceTypes;
}
