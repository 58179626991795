import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalSearchDataService {

    public totalCountClients$ = new BehaviorSubject<number>(null);
    public totalCountIndividuals$ = new BehaviorSubject<number>(null);
    public totalCountUsers$ = new BehaviorSubject<number>(null);

    resetClientCount() {
        this.totalCountClients$.next(null);
    }

    getClientCount(data) {
        this.totalCountClients$.next(data.pagination.total);
    }

    resetIndividualCount() {
        this.totalCountIndividuals$.next(null);
    }

    getIndividualCount(data) {
        this.totalCountIndividuals$.next(data.pagination.total);
    }

    resetUserCount() {
        this.totalCountUsers$.next(null);
    }

    getUserCount(data) {
        this.totalCountUsers$.next(data.pagination.total);
    }
}
