<div class="flex-row data gap-h" [class.remote-resource]="!totalVolume" *ngxInit="totalVolume$ | async as totalVolume">
    <ng-container *ngTemplateOutlet="productionTemplate || defaultTemplate;context: { totalVolume }"></ng-container>
</div>

<ng-template #defaultTemplate let-totalVolume="totalVolume">
    <div>
        <b class="text-big" [class.weight-normal]="!totalVolume?.units">{{totalVolume?.units || '&ndash;'}}</b>&nbsp;
        <span class="text-muted text-smaller">Units</span>
    </div>
    <div>
        <b class="text-big" [class.weight-normal]="!totalVolume?.volume">
            {{(totalVolume?.volume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
        </b>&nbsp;
        <span class="text-muted text-smaller">Last 90 Days</span>
    </div>
</ng-template>
