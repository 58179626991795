<section class="remote-resource" [class.resolved]="alertHistoryItems">
    <h2>History</h2>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-4">
                <b>Date</b>
            </span>
            <span class="col-sm-4">
                <b>Action</b>
            </span>
            <span class="col-sm-4">
                <b>Action By</b>
            </span>
        </div>
        <div *ngFor="let alertHistoryItem of alertHistoryItems" class="row-fluid">
            <span class="col-sm-4">
                {{ alertHistoryItem.actionAt | date:'MM/dd/yyyy HH:mm' }}
            </span>
            <span class="col-sm-4">
                <div *ngFor="let action of alertHistoryItem.actions">
                    {{ action }}
                </div>
            </span>
            <span class="col-sm-4">
                {{ alertHistoryItem.actionBy || '&ndash;' }}
            </span>
        </div>

        <div *ngIf="!alertHistoryItems || !alertHistoryItems.length" class="row-fluid">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No History</span>
        </div>
    </div>
</section>
