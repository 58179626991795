import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { HumanSizePipe } from 'commons/pipes/human-size.pipe';

const pipe = new HumanSizePipe();

export function FileSizeValidator(size: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const { value } = control;
		if (value instanceof File && value.size > size) {
			return {
				fileSize: {
					value,
					message: `You can upload only a file less than ${pipe.transform(size)}.`,
				},
			}
		}
		return null;
	};
}
