import { Injectable } from '@angular/core';
import { BaseConnectorAdapter } from '../base-connector.adapter';
import { SmTypes } from '../connector.interface';

// For future implementation
@Injectable()
export class FbConnectorAdapter extends BaseConnectorAdapter {
    protected override windowParams = { width: 600, height: 700 };
    protected override connectType = SmTypes.DM as const;
    public override connect(entry?: unknown): void {
		console.log('connect')
	}
}
