import { Component } from '@angular/core';
import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';

@Component({
	templateUrl: './waive-document-modal.component.html',
})
export class WaiveDocumentModalComponent extends ConfirmModalComponent {
	title = 'Waive Document Requirement';
	message = 'This document will no longer be required however, it will be available for you to manage.';
	messageCharsAmount = 4000;

	form = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{
				updateOn: 'change',
			},
		),
		isPermanent: new RealmFormControl(
			'isPermanent',
			{
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});

	confirm(): void {
		const { isPermanent, comment } = this.form.value;
		this.onConfirm({
			isPermanent,
			comment,
		});
	}
}
