<h2>Reviews</h2>
<div [ngClass]="{ 'remote-resource': true, 'resolved': !resolving }">
	<div class="filters-rows">
		<div class="row filter-labels">
			<div class="col-md-3">
				<span class="text-muted small">Search</span>
			</div>
			<div class="col-md-3">
				<span class="text-muted small">Source</span>
			</div>
		</div>
		<div class="row filter-selectors">
			<div class="col-md-3">
				<search-list placeholder="Name or NMLS ID"></search-list>
			</div>
			<div class="col-md-3">
				<ng-select
					[ngModel]="params.sources"
					[items]="filters?.sources | async"
					(change)="setFilter({ sources: $event })"
					[loading] = !filtersLoaded
					bindValue="id"
					bindLabel="displayName"
					[multiple]="true"
					[clearable]="true"
					placeholder="All Types">
				</ng-select>
			</div>
			<div class="col-md-6">
				<button type="button" class="btn-toggle btn" [class.active]="params.notReplied"
						(click)="toggleNotRepliedFilter()">Not Replied</button>
			</div>
		</div>
	</div>

	<div class="standalone-filters-applied" [class.hidden]="!filtersApplied">
		<div>Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
	</div>

	<div class="row post" *ngFor="let review of list | async">
		<ng-container [ngSwitch]="getReviewType(review)">
			<review *ngSwitchDefault [review]="review" (onAddReply)="updateList()"></review>
			<facebook-review *ngSwitchCase="'FB'" [review]="review" (onAddReply)="updateList()"></facebook-review>
			<zillow-yelp-review *ngSwitchCase="'ZLW'" [review]="review"></zillow-yelp-review>
			<zillow-yelp-review *ngSwitchCase="'YLP'" [review]="review"></zillow-yelp-review>
		</ng-container>
	</div>

	<div class="row-fluid" *ngIf="!resolving && noMatches">
		<div class="col-md-12 text-center text-bigger text-muted">No matches found</div>
	</div>

	<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !noMatches">
		<div class="col-md-12 text-center text-bigger text-muted">No reviews</div>
	</div>
	<br>
	<paginator [sizes]="[10,25,50]"></paginator>
</div>
