import { Component, Input, OnInit } from '@angular/core';

import { AccountInformation, AccountInformationService, CanAccessClientUid } from 'shared/account/company-information/account-information.service';
import { RELATION } from 'shared/models/Relation';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile } from 'angularjs-providers/user.provider';


@Component({
    selector: 'client-uid',
	templateUrl: './client-uid.component.html',
})

export class ClientUidComponent implements OnInit {
	@Input() public details: AccountInformation;
    @Input() public userProfile: UserProfile;

    public RELATION = RELATION;
    public editingClientUID: boolean = false;
    public editingClientUIDValue: string;
	public saving: boolean = false;
    public hasAccess: boolean = false;
    public canEdit: boolean = false;
    public errorMessage: string = null;

	public constructor(
            private readonly routerGlobals: UIRouterGlobals,
            private readonly accountInformationService: AccountInformationService,
    ) {
	}

    // Override
	public async ngOnInit(): Promise<void> {
		const id = (this.routerGlobals.params.id ?? this.userProfile.organization.id);
        const canEditClientUid: CanAccessClientUid = await this.accountInformationService.canAccessClientUID(id).$promise;
        this.hasAccess = canEditClientUid.canAccessClientUid;
        this.canEdit = this.userProfile.can('EDIT_CLIENT_UID');
	}

    public editClientUID(): void {
        this.editingClientUID = true;
        this.editingClientUIDValue = this.details.clientUID;
    }

	public async saveClientUID(event?: KeyboardEvent): Promise<void> {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

		if (this.saving) {
			return;
		}

		this.saving = true;

        try {
            await this.accountInformationService.setClientUID(this.routerGlobals.params.id, this.editingClientUIDValue).$promise;
        }
        catch(e){
            this.saving = false;
            this.errorMessage = e.data.errorDetails[0].description;
            return;
        }

        this.saving = false;
        this.editingClientUID = false;
        this.errorMessage = null;

        this.details.clientUID = this.editingClientUIDValue;
	}

	public cancelEditClientUID(): void {
        this.editingClientUID = false;
        this.editingClientUIDValue = this.details.clientUID;
        this.errorMessage = null;
	}
}
