<div class="remote-resource" [class.resolved]="!resolving">
    <div *ngIf="tpoServiceInformation && customer">
        <h2 class="title-label">
            Third Party Oversight
            <span *ngIf="tpoServiceInformation.INVESTOR.statusCode == 'ACTIVE'" class="label block va-m text-smaller label-success">Active</span>
            <span *ngIf="tpoServiceInformation.INVESTOR.statusCode == 'INACTIVE'" class="label block va-m text-smaller label-danger">Inactive</span>

            <div class="pull-right" *ngIf="canManageCustomers && (customer.statusCode && customer.statusCode != 'INACTIVE')">
                <button *ngIf="tpoServiceInformation.INVESTOR && tpoServiceInformation.INVESTOR.statusCode != 'ACTIVE'"
                    class="btn btn-success-wired"
                    (click)="setTpoServiceStatus('ACTIVE')"
                >
                    Activate
                </button>

                <button *ngIf="tpoServiceInformation.INVESTOR && tpoServiceInformation.INVESTOR.statusCode != 'INACTIVE'"
                    class="btn btn-danger-wired"
                    (click)="setTpoServiceStatus('INACTIVE')"
                >
                    Deactivate
                </button>

                <button *ngIf="tpoServiceInformation.INVESTOR.statusCode == 'ACTIVE'"
                    class="btn btn-primary"
                    (click)="createChannel()"
                >
                    Create Channel
                </button>
            </div>
        </h2>
        <br>
        <div class="rows-bordered remote-resource" [ngClass]="{ resolved: !resolving, curtain: tpoServiceInformation.INVESTOR.statusCode !='ACTIVE' }">
            <tpo-channel-information [reload]="channelReload$" [canManageCustomers]="canManageCustomers"></tpo-channel-information>
            <br>
            <div *ngIf="stateIsCCMCustomerTPOService">
                <reporting-switcher-section
                    [canEdit]="canManageCustomers"
                    [isInvestor]="true"
                    [statusCode]="tpoServiceInformation.INVESTOR.statusCode"
                ></reporting-switcher-section>
            </div>
            <div ui-view *ngIf="!stateIsCCMCustomerChannels"></div>
        </div>
    </div>
</div>
