<section class="remote-resource" [class.resolved]="list.$resolved">
	<div class="rows-bordered" *ngFor="let item of list">
		<div class="row-fluid header">
			<div class="col-sm-12">
				<span class="glyphicon"
					  *ngIf="item?.isAuthorized !== null"
					  [ngClass]="item?.isAuthorized ? 'glyphicon-ok' : 'glyphicon-remove'"></span>
				{{item?.group}}
			</div>
		</div>
		<div class="row-fluid">
			<div class="col-sm-12">
				<div class="observation" *ngFor="let observation of item.checklistObservations">
					<div class="horizontal-flex-group align-top">
						<show-regulations-icon
							[observationId]="observation.observationId"
						></show-regulations-icon>
						<span>{{observation.observation}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row-fluid" *ngIf="list.$resolved && !list.length">
		<div class="col-sm-12 text-center text-muted text-bigger">No data</div>
	</div>
</section>
