<div class="attachments" *ngIf="post.attachments?.length">
	<div class="attachment-container" *ngFor="let attachment of post.attachments; index as i">
		<div class="attachment"
			 [class.attachment-video]="attachment.type === 'VIDEO'"
			 [class.attachment-image]="attachment.type === 'IMAGE'">
			<img *ngIf="attachment.id"
				 [srcBlob]="attachment.imageUrl || !attachment.name && setUrl(attachment)"
				 [srcDefault]="attachment.type === 'IMAGE' && defaultImg"
				 (srcInfo)="setAttachmentInfo($event, i);" (load)="this.attachment.loaded = true"/>
			<span *ngIf="editable" class="remove c-icon c-icon-cancel" (click)="remove(attachment)"></span>
			<span *ngIf="attachment.$error" class="error" tooltip="{{ attachment.$error }}" data-placement="right">!</span>
			<div *ngIf="!attachment.$error && !attachment.loaded" class="loader-container"><loader size="big"></loader></div>
		</div>
		<a class="edit-thumbnail" *ngIf="attachment.type === 'VIDEO' && editable"
		   [class.curtain]="!attachment.loaded" [aDisabled]="!attachment.loaded" (click)="editThumbnail(attachment.id)">
			Edit Thumbnail
		</a>
	</div>
</div>

<div *ngIf="editable">
	<uploader (select)="onPick($event)" [config]="{ multiple: true }"></uploader>
</div>
