<div class="remote-resource"  [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-6" columnName="" [active]="false">Name</sortable-header>
            <sortable-header class="col-sm-a" columnName="" [active]="false">NMLS ID</sortable-header>
        </div>
        <reset-filters class="row-fluid"></reset-filters>
        <ng-container *ngIf="((list | async)?.length > 0); else noItems">
            <div class="row-fluid"
                *ngFor="let item of list | async"
            >
                <div class="col-md-6">
                    <a [aDisabled]="!contactsAvailable" [uiSref]="(User.isTpo ? 'people' : '^:id') + '.contacts.:contactId.information'" [uiParams]="{ contactId: item.contactId }">
                        {{ item.name }}
                    </a>
                </div>
                <div class="col-md-a">
                    <a [aDisabled]="!item.nmlsId" uiSref="^account.licenses.nmls.:nmlsId" [uiParams]="{ nmlsId: item.nmlsId }">{{item.nmlsId || '&ndash;'}}</a>
                </div>
            </div>
        </ng-container>
        <ng-template #noItems>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    {{filtersApplied ? 'No matches found' : 'No records added'}}
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
