<form
        [formGroup]="form"
        (onValidSubmit)="formSubmit()"
    >
    <h3 class="flex-row gap">
        <span>Auto Close Audits</span>
        <span class="flex-grow">
            <toggle
                [ngModel]="settings?.autoCloseAudits.enabled"
                [ngModelOptions]="{standalone: true}"
                [disabled]="!toggleEnabled"
                [loading]="loading"
                [tooltip]="permissionTooltipText"
                (ngModelChange)="toggleSection($event)"
                (click)="toggleClicked()"
            ></toggle>
        </span>
        <div *ngIf="toggleEnabled" [isEditable]="editing">
            <button view
                class="btn btn-primary"
                (click)="toEdit()"
                [disabled]="!settings?.autoCloseAudits.enabled"
                type="button"
            >Edit</button>
            <ng-container edit>
                <button class="btn btn-default" type="button" (click)="cancelEdit()">Cancel</button>
                <button class="btn btn-primary" type="submit">Save</button>
            </ng-container>
        </div>
    </h3>

    <div
         class="rows-striped remote-resource"
         [ngClass]="{ curtain: !settings?.autoCloseAudits.enabled, resolved: !loading }"
    >
        <div class="row-fluid" [ngSwitch]="editing">
            <div class="col-md-5 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label" [class.required]="editing">Auto-close status</label>
                    <div class="col-md-7">
                        <span class="control-value" *ngSwitchDefault>
                           {{settings?.autoCloseAudits.autoCloseStatus?.name || '&ndash;'}}
                        </span>
                        <ng-select *ngSwitchCase="true"
                                   class="flex-grow"
                                   [items]="statuses | async"
                                   [loading]="!(statuses | async)"
                                   bindLabel="name"
                                   [clearable]="false"
                                   formControlName="autoCloseStatus"
                                   tabindex=1>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-7"></div>
        </div>
    </div>
</form>
