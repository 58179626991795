import { Component, Input, OnDestroy } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { Privacy } from '../Privacy';


@Component({
	selector: 'tpo-create-post-privacy-settings-modal',
	templateUrl: './tpo-create-post-privacy-settings-modal.component.html',
})
export class TpoCreatePostPrivacySettingsModalComponent {
    @Input()
    privacySettings: Privacy;
    resolving = false;

    @Input()
    confirm: () => any;

	constructor(public modalRef: BsModalRef) {
	}

    public hide() {
        this.modalRef.hide();
    }
}
