<ng-container>
    <div class="channel-card remote-resource" [class.resolved]="!isRetrievingChannelDetail && !isSaving">
        <div class="card-heading" (click)="showCardContent()" [class.collapsed]="!showContent">
            {{ channel.name }}
            <div class="pull-right">
                <span class="glyphicon glyphicon-chevron-down"></span>
            </div>
        </div>
        <div class="card-body">
            <channel-card-ownership-and-description
                [showTruncatedDescription]="!showContent || isRetrievingChannelDetail"
                [channel]="channel"
                (patchTriggered)="patchAndUpdateDetails($event)"
                (channelSaving)="updateSaving($event)"
                (channelUpdated)="handleUpdatedChannelDetail()"
            >
            </channel-card-ownership-and-description>
        </div>
        <div class="card-body" *ngIf="showContent">
            <channel-card-new-application
                [channelName]="channel.name"
                [channelCardApplicationDetail$]="channelCardApplicationDetail$"
                [toggleLoading]="toggleLoading"
                (screenApplicantDisabled)="updateChannelDetail($event)"
                (channelSaving)="updateSaving($event)"
                (channelUpdated)="handleUpdatedChannelDetail()"
                [questionnaires]="questionnaires"
            >
            </channel-card-new-application>
        </div>
    </div>
</ng-container>
