<div class="remote-resource" [class.resolved]="resolved">
    <div class="col-sm-2">
        <a
            uiSref="tpo.:id.scorecard.reviews.history"
            [uiParams]="srefParams"
            [uiOptions]="{ inherit: false, reload: true }"
        >
            {{reviewItem.id}}</a>
    </div>
    <div [formGroup]="reviewStatusForm" (onValidSubmit)="saveStatus()">
        <div class="col-sm-3 form-group" [isEditable]="editingStatus" has-error>
            <div view class="control-value">
                <span [class.label]="notEditable" [class.label-default]="notEditable">
                    {{reviewItem.statusName}}
                </span>
            </div>
            <ng-select
                edit
                formControlName="statusId"
                [items]="statuses"
                bindLabel="name"
                bindValue="id"
                placeholder="Select status"
                tabindex="1"
                [clearable]="false"
            ></ng-select>
        </div>
        <div class="col-sm-1 text-right va-m button-holder" [ngSwitch]="editingStatus">
            <ng-container *ngSwitchDefault>
                <a *ngIf="!notEditable" class="btn btn-icon btn-tertiary" (click)="toggleStatusEditing()"><span class="glyphicon glyphicon-pencil"></span></a>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <button class="btn btn-icon btn-tertiary" (click)="saveStatus()"><span class="glyphicon glyphicon-ok"></span></button>
                <a class="btn btn-icon btn-tertiary" (click)="toggleStatusEditing()"><span class="glyphicon glyphicon-remove"></span></a>
            </ng-container>
        </div>
    </div>
    <div class="col-sm-2">{{reviewItem.statusDate | date: 'MM/dd/YYYY'}}</div>
    <div [formGroup]="reviewAssignedForm">
        <div class="col-sm-3 form-group" [isEditable]="editingAssignedUser" has-error>
            <div view class="control-value">{{reviewItem.assignedFullName}}</div>
            <ng-select
                edit
                formControlName="assignedId"
                [items]="assignedUsers"
                bindLabel="name"
                bindValue="id"
                placeholder="Select User"
                tabindex="2"
                [clearable]="false"
            ></ng-select>
        </div>
        <div class="col-sm-1 text-right va-m button-holder" [ngSwitch]="editingAssignedUser">
            <ng-container *ngSwitchDefault>
                <a *ngIf="!notEditable" class="btn btn-icon btn-tertiary" (click)="toggleAssignedUserEditing()"><span class="glyphicon glyphicon-pencil"></span></a>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <button class="btn btn-icon btn-tertiary" (click)="saveAssignedUser()"><span class="glyphicon glyphicon-ok"></span></button>
                <a class="btn btn-icon btn-tertiary" (click)="toggleAssignedUserEditing()"><span class="glyphicon glyphicon-remove"></span></a>
            </ng-container>
        </div>
    </div>
</div>
