import { Directive, HostListener, HostBinding, Input } from '@angular/core';
import { StateService, TransitionOptions } from '@uirouter/core';

@Directive({
	selector: '[fixedSref]',
})

export class FixedSrefDirective {

	@Input('fixedSref') sref: string;
	@Input('uiParams') params?: {} = {};
	@Input('uiSrefOptions') options?: TransitionOptions = {};
	@HostBinding('href') public href: string;
	constructor(public stateService: StateService) {
	}

	@HostListener ('click') go() {
		this.stateService.go(this.sref, this.getParams(), this.getOptions());
	}

	ngOnChanges() {
		this.href = this.stateService.href(this.sref, this.getParams(), this.getOptions());
	}

	getParams() {
		return {...this.stateService.params, ...this.params};
	}

	getOptions() {
		return {...{relative: this.stateService.current}, ...this.options};
	}
}
