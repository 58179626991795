<div class="gmb-locations-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="onCancel();">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<ng-container *ngIf="resolving || locations?.length > 0">
		<div class="modal-body no-top-spacing" [class.remote-resource]="resolving">
			<div class="rows-bordered">
				<div class="row header">
					<label class="col-md-12 horizontal-flex-group sortable">
						<span class="location-checkbox">
							<input type="checkbox" [(ngModel)]="checkedAll" (change)="checkAll($event)"/>
						</span>
						<span>Locations</span>
					</label>
				</div>
			</div>
			<div class="rows-bordered max-h300" *ngIf="locations">
				<label class="row horizontal-flex-group clickable" *ngFor="let location of locations">
					<span class="location-checkbox">
						<input [(ngModel)]="location.checked" type="checkbox" (change)="syncChecked()"/>
					</span>
					<sm-icon class="space-bigger" [config]="location"></sm-icon>
					<div class="full-width">
						<span>
							{{location.locationName}}
						</span><br>
						<span class="text-muted">
							{{location.computedAddress}}
						</span>
					</div>
				</label>
			</div>
		</div>
		<div class="modal-footer" [class.curtain]="resolving">
			<div class="btn-group">
				<button type="button" (click)="save()" [disabled]="!saveEnabled" class="btn btn-primary">
                    {{confirmText}}
                </button>
				<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
			</div>
		</div>
	</ng-container>

	<div class="modal-body clearfix" *ngIf="!resolving && locations.length == 0">
		<div class="col-md-12 text-center">
			<br>
			<sm-network-icon [config]="{ networkCode: 'GMB' }" [showTooltip]="false"></sm-network-icon>
			<h2>Create Account</h2>
			<br>
			<div class="text-muted">
                You first need to create your Google Business Profile and then return here to connect.
            </div>
			<br>
			<div>
                <a
                    class="btn btn-primary"
                    href="https://www.google.com/intl/en/business/"
                    target="_blank"
                    (click)="onCancel();"
                >Use Google My Business</a>
            </div>
			<br>
		</div>
	</div>
</div>
