import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { ApplicationExpiration, ChannelCardDetail, ChannelsSettingDetail, Notify } from 'lender/manage-channels/manage-channel-card.interface';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import {
    ChannelManageNotificationsModalComponent,
} from 'lender/manage-channels/channel-manage-notifications-modal/channel-manage-notifications-modal.component';
import { ScreenApplicantToggleModalComponent } from 'lender/manage-channels/channel-card-new-and-renewal-application/manage-channel-screen-applicant-toggle/screen-applicant-toggle-modal.component';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { merge } from 'lodash';
import { WholeNumberValidator } from 'commons/validators/whole-number.validator';
import { NumberValidator } from 'commons/validators';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { QuestionnaireItem } from 'lender/questionnaires/manage/questionnaires.interface';
import { PopoverEditComponent } from 'commons/components/short-hand-edit/popover-edit/popover-edit.component';
import { ToggleComponent } from 'commons/components/toggle';


export function createStubForm(...fieldNames: string[]): RealmFormGroup {
    const fields: any = {};
    fieldNames
        .forEach((fieldName: string) => {
            fields[fieldName] = new RealmFormControl(fieldName, {});
        });

    const form: RealmFormGroup = new RealmFormGroup(fields);

    return form;
}

@Component({
    selector: 'channel-card-new-application',
    templateUrl: 'channel-card-application.component.html',
})
export class ChannelCardApplicationComponent implements OnInit {
    @Input() channelName: string;
    @Input() toggleLoading: boolean;
    @Input() channelCardApplicationDetail$: Subject<ChannelCardDetail>;
    @Input() questionnaires: QuestionnaireItem[] = [];
    @Output() screenApplicantDisabled: EventEmitter<ChannelsSettingDetail> = new EventEmitter();
    @Output() channelSaving: EventEmitter<boolean> = new EventEmitter();
    @Output() channelUpdated: EventEmitter<number> = new EventEmitter();

    @ViewChild('renewalChargeApplicantToggle', { read: ToggleComponent }) private renewalChargeApplicantToggle: ToggleComponent;
    @ViewChild('renewalChargeApplicantPopover', { read: PopoverEditComponent }) private renewalChargeApplicantPopover: PopoverEditComponent;

    private modalRef: BsModalRef;
    private readonly destroyed$: Subject<void> = new Subject();
    private channelId: number;
    channelApplicationDetail: ChannelCardDetail;
    private isScreenApplicantEnabled: boolean;
    private canManageChannelSettings: boolean;

    private newApplicationsTopRowId: string;
    private newApplicationsMiddleRowId: string;
    private newApplicationsBottomRowId: string;

    private renewalApplicationsTopRowId: string;
    private renewalApplicationsMiddleRowId: string;
    private renewalApplicationsBottomRowId: string;

    protected readonly invitationExpirationSettingsValidators: ValidatorFn[] = [
        WholeNumberValidator(),
        Validators.min(0),
        Validators.max(180),
    ];
    protected readonly applicationExpirationSettingsValidators: ValidatorFn[] = [
        WholeNumberValidator(),
        Validators.min(3),
        Validators.max(90),
    ];
    protected readonly renewalMonthsValidators: ValidatorFn[] = [
        WholeNumberValidator(),
        Validators.min(1),
        Validators.max(999),
    ];
    protected readonly moneyValidators: ValidatorFn[] = [
        NumberValidator(),
        Validators.min(1),
        Validators.max(999),
    ];

    protected newApplicationQuestionnaireEditing: boolean = false;
    protected renewalApplicationQuestionnaireEditing: boolean = false;
    protected readonly newApplicationQuestionnaireForm: RealmFormGroup = new RealmFormGroup({
        newQuestionnaire: new RealmFormControl(
            'newQuestionnaire',
            {
                label: 'New Questionnaire',
            },
        ),
    });
    protected readonly renewalApplicationQuestionnaireForm: RealmFormGroup = new RealmFormGroup({
        renewalQuestionnaire: new RealmFormControl(
            'renewalQuestionnaire',
            {
                label: 'New Questionnaire',
            },
        ),
    });

    private _renewalChargeApplicationHack: boolean;

    constructor(
        private readonly modalService: BsModalService,
        private readonly userService: UserService,
        private readonly manageChannelService: ManageChannelService,
    ) {
    }

    ngOnInit(): void {
        this.channelCardApplicationDetail$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((channelDetail: ChannelCardDetail) => {
                this.channelId = channelDetail.channelSettings.channelId;
                this.channelApplicationDetail = channelDetail;
                this.isScreenApplicantEnabled = this.channelApplicationDetail.channelSettings.screenTpo;
                this._renewalChargeApplicationHack = this.channelApplicationDetail.channelSettings.tpoPaysForRenewal;

                this.newApplicationQuestionnaireForm.controls.newQuestionnaire.setValue(channelDetail.channelSettings.newQuestionnaire);
                this.renewalApplicationQuestionnaireForm.controls.renewalQuestionnaire.setValue(channelDetail.channelSettings.renewalQuestionnaire);

                this.newApplicationsTopRowId = `newApplicationsTopRowId_${this.channelId}`;
                this.newApplicationsMiddleRowId = `newApplicationsMiddleRowId_${this.channelId}`;
                this.newApplicationsBottomRowId = `newApplicationsBottomRowId_${this.channelId}`;

                this.renewalApplicationsTopRowId = `renewalApplicationsTopRowId_${this.channelId}`;
                this.renewalApplicationsMiddleRowId = `renewalApplicationsMiddleRowId_${this.channelId}`;
                this.renewalApplicationsBottomRowId = `renewalApplicationsBottomRowId_${this.channelId}`;
            });
        this.canManageChannelSettings = this.userService.profile.can('MANAGE_CHANNEL_SETTINGS');

    }

    toggleScreenApplicant(channelApplicationDetail: ChannelCardDetail, isEditing: boolean) {
        if (isEditing) {
            channelApplicationDetail.channelSettings.screenTpo = !channelApplicationDetail.channelSettings.screenTpo;
        }

        if (channelApplicationDetail.channelSettings.screenTpo) {
            const initialState = {
                screenApplicantInfo: channelApplicationDetail,
                channelId: this.channelId,
                isEditing: isEditing,
                canManageChannelSettings: this.canManageChannelSettings,
                callbackOnCancel: () => {
                    this.restoreScreenApplicantToggleState();
                },
                callbackOnSave: () => {
                    this.channelSaved();
                },
            };
            this.modalRef = this.modalService.show(
                ScreenApplicantToggleModalComponent,
                {
                    initialState,
                    class: 'modal-smd modal-new',
                    backdrop: 'static',
                }
            );
            this.modalRef.content.screenApplicantInfo.screenApplicantInfo = this.channelApplicationDetail;
        } else {
            this.screenApplicantDisabled.emit(channelApplicationDetail.channelSettings);
        }
    }

    toggleChargeApplicant() {
        const pseudoPatch: any = { tpoPaysForApplication: !this.channelApplicationDetail.channelSettings.tpoPaysForApplication };
        this.saveChannelSettingsWithPseudoPatch(pseudoPatch);
    }

    toggleRenewalChargeApplication() {
        this._renewalChargeApplicationHack = this.channelApplicationDetail.channelSettings.tpoPaysForRenewal;
        this.channelApplicationDetail.channelSettings.tpoPaysForRenewal = !this.channelApplicationDetail.channelSettings.tpoPaysForRenewal;

        if (this._renewalChargeApplicationHack) {   // turning it off
            this._renewalChargeApplicationHack = false;
            const pseudoPatch: any = { tpoPaysForRenewal: this.channelApplicationDetail.channelSettings.tpoPaysForRenewal };
            this.saveChannelSettingsWithPseudoPatch(pseudoPatch);
        } else {
            this.renewalChargeApplicantPopover.startEditing();
        }
    }

    renewalChargeApplicantValueEditChanged(isEditing: boolean): void {
        if (!isEditing && !this._renewalChargeApplicationHack && this.channelApplicationDetail.channelSettings.tpoPaysForRenewal) {
            this.channelApplicationDetail.channelSettings.tpoPaysForRenewal = false;

            this.renewalChargeApplicantToggle.setState(false);
        }
    }

    renewalChargeApplicantValueChanged(valuePatch: any): void {
        const pseudoPatch: any = {
            tpoPaysForRenewal: this.channelApplicationDetail.channelSettings.tpoPaysForRenewal,
            ...valuePatch,
        };
        this.saveChannelSettingsWithPseudoPatch(pseudoPatch);
    }

    toggleAutoRenewalApplication() {
        const pseudoPatch: any = { autoRenewal: !this.channelApplicationDetail.channelSettings.autoRenewal };
        this.saveChannelSettingsWithPseudoPatch(pseudoPatch);
    }

    openNewApplicationsModal() {
        const initialState = {
            notificationsType: 'New Application',
            channelId: this.channelId,
            channelName: this.channelName,
            submissionNotification: this.channelApplicationDetail.channelSettings.notifyNewAppSubmit,
            lenderReviewNotification: this.channelApplicationDetail.channelSettings.notifyNewAppLenderReview,
            onNotificationSave: async (submissionsNotify: Notify, lenderReviewNotify: Notify, channelId: number) => {
                const newDetails: ChannelCardDetail = this.channelApplicationDetail;
                newDetails.channelSettings.notifyNewAppSubmit = submissionsNotify;
                newDetails.channelSettings.notifyNewAppLenderReview = lenderReviewNotify;
                await this.manageChannelService.updateOrganizationalChannelSettings(channelId, newDetails.channelSettings).toPromise();
                this.modalService.hide();
            },
        }
        this.modalService.show(
            ChannelManageNotificationsModalComponent,
            {
                initialState,
                class: 'modal-md modal-new',
                keyboard: false,
            });
    }

    openRenewalApplicationsModal() {
        const initialState = {
            notificationsType: 'Renewal Application',
            channelName: this.channelName,
            channelId: this.channelId,
            submissionNotification: this.channelApplicationDetail.channelSettings.notifyRenewSubmit,
            lenderReviewNotification: this.channelApplicationDetail.channelSettings.notifyRenewLenderReview,
            onNotificationSave: async (submissionsNotify: Notify, lenderReviewNotify: Notify, channelId: number) => {
                const newDetails: ChannelCardDetail = this.channelApplicationDetail;
                newDetails.channelSettings.notifyRenewSubmit = submissionsNotify;
                newDetails.channelSettings.notifyRenewLenderReview = lenderReviewNotify;
                await this.manageChannelService.updateOrganizationalChannelSettings(channelId, newDetails.channelSettings).toPromise();
                this.modalService.hide();
            },
        }
        this.modalService.show(
            ChannelManageNotificationsModalComponent,
            {
                initialState,
                class: 'modal-md modal-new',
                keyboard: false,
            });
    }

    async saveChannelSettingsWithPseudoPatch(pseudoPatch: any): Promise<void> {
        const channelSettingsWithChange: ChannelsSettingDetail = merge(
            {},
            this.channelApplicationDetail.channelSettings,
            pseudoPatch
        );

        this.channelSaving.emit(true);
        try {
            await this.manageChannelService.updateOrganizationalChannelSettings(this.channelId, channelSettingsWithChange).toPromise();
        } finally {
            this.channelSaving.emit(false);
        }

        this.channelSaved();
    }

    async saveChannelApplicationExpirationsWithPseudoPatch(pseudoPatch: any): Promise<void> {
        const applicationExpirations: ApplicationExpiration = merge(
            {},
            this.channelApplicationDetail.applicationExpirationSettings,
            pseudoPatch
        );

        this.channelSaving.emit(true);
        try {
            await this.manageChannelService.updateOrganizationalChannelApplicationExpirations(this.channelId, applicationExpirations).toPromise();
        } finally {
            this.channelSaving.emit(false);
        }

        this.channelSaved();
    }

    saveNewApplicationQuestionnaireSelection(): void {
        const pseudoPatch: any = { newQuestionnaire: this.newApplicationQuestionnaireForm.controls.newQuestionnaire.value };
        this.saveChannelSettingsWithPseudoPatch(pseudoPatch);

        this.newApplicationQuestionnaireEditing = false;
    }

    saveRenewalApplicationQuestionnaireSelection(): void {
        const pseudoPatch: any = { renewalQuestionnaire: this.renewalApplicationQuestionnaireForm.controls.renewalQuestionnaire.value };
        this.saveChannelSettingsWithPseudoPatch(pseudoPatch);

        this.renewalApplicationQuestionnaireEditing = false;
    }

    restoreScreenApplicantToggleState() {
        this.channelApplicationDetail.channelSettings.screenTpo = this.isScreenApplicantEnabled;
    }

    private channelSaved() {
        this.channelUpdated.emit(this.channelId);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    startEditingNewApplicationQuestionnaire(): void {
        this.newApplicationQuestionnaireEditing = true;
    }

    cancelEditingNewApplicationQuestionnaire(): void {
        this.newApplicationQuestionnaireEditing = false;
        this.newApplicationQuestionnaireForm.controls.newQuestionnaire.setValue(this.channelApplicationDetail.channelSettings.newQuestionnaire);
    }

    startEditingRenewalApplicationQuestionnaire(): void {
        this.renewalApplicationQuestionnaireEditing = true;
    }

    cancelEditingRenewalApplicationQuestionnaire(): void {
        this.renewalApplicationQuestionnaireEditing = false;
        this.renewalApplicationQuestionnaireForm.controls.renewalQuestionnaire.setValue(this.channelApplicationDetail.channelSettings.renewalQuestionnaire);
    }
}
