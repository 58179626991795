import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { UserService } from 'angularjs-providers/user.provider';
import { Channel } from 'lender/manage-channels/manage-channel-card.interface';
import { QuestionnaireItem } from 'lender/questionnaires/manage/questionnaires.interface';
import { OrganizationQuestionnaireService } from 'commons/services/organization-questionnaire.service';
import { ChannelCardComponent } from './channel-card.component';

@Component({
    templateUrl: 'manage-channel-landing-page.component.html',
})
export class ManageChannelLandingPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('leftSideChannelCards', { read: ChannelCardComponent, emitDistinctChangesOnly: true }) leftSideChannelCards: QueryList<ChannelCardComponent>;
    @ViewChildren('rightSideChannelCards', { read: ChannelCardComponent, emitDistinctChangesOnly: true }) rightSideChannelCards: QueryList<ChannelCardComponent>;

    lenderId: number;
    protected organizationId: number;

    private destroy$: Subject<void> = new Subject();

    constructor(
        private readonly manageChannelService: ManageChannelService,
        private readonly userService: UserService,
        private readonly organizationQuestionnaireService: OrganizationQuestionnaireService,
    ) {
    }

    allChannels: Channel[] = [];
    oddIndexChannel: Channel[] = [];
    evenIndexChannel: Channel[] = [];
    protected questionnaires: QuestionnaireItem[] = [];
    resolving: boolean;

    // Override
    ngOnInit() {
        this.lenderId = this.userService.profile.organization.id;
        this.organizationId = this.userService.profile.organization.organizationId;

        this.retrieveData();
    }

    // Override
    ngAfterViewInit(): void {
        this.setupAutoExpandForSide(this.leftSideChannelCards);
        this.setupAutoExpandForSide(this.rightSideChannelCards);
    }

    // Override
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private async retrieveData(): Promise<void> {
        try {
            this.resolving = true;
            [
                this.allChannels,
                this.questionnaires,
            ] = await Promise.all([
                this.manageChannelService.getChannelList(this.lenderId).toPromise(),
                this.organizationQuestionnaireService.getOrganizationQuestionnaires(this.organizationId).toPromise(),
            ]);

            this.allChannels = this.allChannels.filter( channel => {
                return channel.isActive == true
            });
            this.splitChannelsBasedOnIndex(this.allChannels);
        }
        finally {
            this.resolving = false;
        }
    }

    private splitChannelsBasedOnIndex(channel: Channel[]) {
        for (let i = 0; i < channel.length; i++) {
            if ((i + 2) % 2 == 0) {
                this.oddIndexChannel.push(channel[i]);
            }
            else {
                this.evenIndexChannel.push(channel[i]);
            }
        }
    }

    private setupAutoExpandForSide(sideQuery: QueryList<ChannelCardComponent>): void {
        sideQuery.changes
            .pipe(takeUntil(this.destroy$))
            .subscribe((cards: QueryList<ChannelCardComponent>) => {
                if (this.allChannels.length <= 2) {
                    const firstCard: ChannelCardComponent = cards.get(0);
                    firstCard.showContent = true;
                }
            });
    }
}
