<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div class="row-fluid header">
        <span class="col-sm-4">
            <b>Date</b>
        </span>
		<span class="col-sm-4">
            <b>Action</b>
        </span>
		<span class="col-sm-4">
            <b>Action By</b>
        </span>
	</div>

	<div class="row-fluid" *ngFor="let item of list">
        <span class="col-sm-4">
            {{item.actionAt | date:'MM/dd/yyyy HH:mm'}}
        </span>
		<span class="col-sm-4">
            <div *ngFor="let action of item.actions; last as isLast">
                {{action}}{{isLast ? '' : ', '}}
            </div>
        </span>
		<span class="col-sm-4">
            {{item.actionBy || '&ndash;'}}
        </span>
	</div>

	<div class="row-fluid" *ngIf="!list.length">
		<span class="col-sm-12 text-muted text-center text-bigger text-weak">No History</span>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>
