import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { BulkRequestService } from 'lender/clients/list/document/bulk-request.service';

import { ClientsService } from './clients.service';
import { InvestorClientComments, InvestorClientCommentsService } from './$id';
import { PagedClientsListComponent, AccountAssignmentModalComponent } from './list';
import { ClientsListComponent } from './list/clients-list.component';
import { BulkClientsListComponent } from './list/bulk-clients-list.component';
import { BulkDocumentRequestComponent } from './list/document/bulk-document-request.component';
import { RelationshipStatusModalComponent } from './list/relationship-status-modal';
import { RenewalModalComponent } from './list/renewal-modal';
import { InvestorClientMenuComponent } from 'lender/clients/$id/investor-client-menu.component';
import { InvestorClientMenuContainerComponent } from 'lender/clients/$id/investor-client-menu-container.component';
import { ApplicationsModule } from 'lender/applications';
import { ClientsHeaderComponent } from 'lender/clients/clients-header.component';
import { ClientFinancialsBulkRequestModalComponent } from './list/client-financials-bulk-request/client-financials-bulk-request-modal.component';
import { ShareClientsModalComponent } from './list/share-modal/share-clients-modal.component';

const components = [
	PagedClientsListComponent,
	ClientsListComponent,
	AccountAssignmentModalComponent,
	BulkClientsListComponent,
	BulkDocumentRequestComponent,
	RelationshipStatusModalComponent,
	RenewalModalComponent,
    InvestorClientMenuComponent,
	InvestorClientMenuContainerComponent,
	InvestorClientComments,
    ClientsHeaderComponent,
    ClientFinancialsBulkRequestModalComponent,
    ShareClientsModalComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
        ApplicationsModule,
	],
	declarations: [
		...components,
	],
	providers: [
		ClientsService,
		InvestorClientCommentsService,
		BulkRequestService,
	],
})

export class ClientsModule {}
