<div class="social-compliance-publisher-templates">
	<div class="flex-row align-bottom gap">
		<ng-container *ngIf="folderId">
			<back-bread class="flex">{{folder?.name}}</back-bread>

			<ng-container *ngIf="folder">
				<button type="button" class="btn btn-tertiary" (click)="editFolder()" *ngIf="canManage">
					<i class="glyphicon glyphicon-pencil"></i>Edit
				</button>

				<button type="button" class="btn btn-tertiary" (click)="showGroupsModal()">
					<i class="c-icon c-icon-users"></i>&nbsp;&nbsp;Sharing
				</button>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="!folderId">
			<h2 class="flex">Templates</h2>

			<button type="button" class="btn btn-tertiary" (click)="addFolder()" *ngIf="canManage">
				<i class="c-icon c-icon-folder text-big"></i>Add Folder
			</button>
		</ng-container>

		<a class="btn btn-primary" uiSref=".new" *ngIf="canManage">
			<i class="glyphicon glyphicon-plus small-icon"></i>Create Template
		</a>
	</div>

	<div>
		<notification></notification>

		<div class="filters">
			<div class="row">
				<div class="col-md-3">
					<small class="text-muted">Search</small>
				</div>
				<div class="col-md-3">
					<small class="text-muted">Tags</small>
				</div>
				<div class="col-md-3">
					<small class="text-muted">Shared To</small>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<search-list placeholder="Template Name, Folder"></search-list>
				</div>
				<div class="col-md-3 remote-resource" [class.resolved]="filters.tagId.$resolved">
					<ng-select
						[(ngModel)]="params.tagId"
						(change)="setFilter({ tagId: $event })"
						[items]="filters.tagId.$promise | async"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						placeholder="All Tags">
					</ng-select>
				</div>
				<div class="col-md-3 remote-resource" [class.resolved]="sharingReady">
					<campaign-share-to-edit
						[value]="share"
						(change)="setSharingFilter($event)"
						[selectDefaultOnCreate]="false"
						(ready)="onSharingFilterReady($event)"
						placeholder="All">
					</campaign-share-to-edit>
				</div>
			</div>
		</div>

		<br/>

		<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">

			<div class="row-fluid header">
				<sortable-header class="col-md-a" columnName="name">Name</sortable-header>
				<sortable-header *ngIf="!folderId" class="col-md-2" columnName="type">Type</sortable-header>
				<sortable-header class="col-md-2" columnName="updatedDate">Last Updated Date</sortable-header>
				<sortable-header class="col-md-2" columnName="updatedBy">Updated by</sortable-header>
				<div class="col-md-1"></div>
			</div>

			<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
				<div class="col-md-12 text-center">
					Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
				</div>
			</div>

			<a
				class="row-fluid clickable template-row"
				*ngFor="let item of list"
				[uiSref]="getRowSref(item)"
				[uiParams]="getRowSrefParams(item)"
			>
				<div class="col-md-a va-m text-overflow-wrap">
					<div class="preview">
						<div *ngIf="item?.type === type.TEMPLATE" class="image-holder"
							 [class.attachment-video]="item.isVideoThumbnail"
							 [ngSwitch]="item.hasImageAttachment">
							<img *ngSwitchCase="true" alt="Template preview"
								 [src]="getSource(tpoId, item)">
							<i *ngSwitchCase="false" class="c-icon c-icon-user-rounded"></i>
						</div>
						<div *ngIf="item?.type === type.FOLDER" class="image-holder folder">
							<i class="c-icon c-icon-folder"></i>
						</div>
						<div class="description">
							<a>
								<show-more [text]="item?.name || 'Template Title'"
								   [maxHeight]="20"
								   showMoreText>
								</show-more>
							</a>
							<show-more *ngIf="item?.type === type.TEMPLATE"
							   [text]="item.text"
							   [maxHeight]="50"
							   showMoreText>
							</show-more>
						</div>
					</div>
				</div>
				<div *ngIf="!folderId" class="col-md-2 va-m text-capitalize">
					{{item?.type || type.TEMPLATE}}
				</div>
				<div class="col-md-2 va-m">
					{{ item.updatedDate | date:'MM/dd/yyyy' }}
				</div>
				<div class="col-md-2 va-m">
					{{ item.updatedBy }}
				</div>
				<div class="col-md-1 va-m text-center inline-menu dropdown" dropdown (click)="preventEventBubbling($event)">
					<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle
					   *ngIf="canManage">
						<span class="c-icon c-icon-dot-3"></span>
					</a>
					<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [ngSwitch]="item?.type">
						<li *ngSwitchCase="type.FOLDER">
							<a (click)="editFolder(item)" class="dropdown-item">Edit Folder</a>
						</li>
						<li *ngSwitchCase="type.FOLDER">
							<a (click)="removeFolder(item.id)" class="dropdown-item">Remove Folder</a>
						</li>
						<li *ngSwitchCase="type.TEMPLATE">
							<a (click)="moveTo(item.id)" class="dropdown-item">Move To</a>
						</li>
					</ul>
				</div>
			</a>

			<div class="row-fluid" *ngIf="list?.$resolved && !list.length">
				<div class="col-sm-12 text-center text-light text-bigger">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No templates'}}
				</div>
			</div>
			<br />
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</div>
