<form [formGroup]="form" (onValidSubmit)="save()">
    <h3 class="flex-row">
        <span class="flex-grow">Invoicing Settings</span>
        <div [isEditable]="editing" *ngIf="userService.profile.can('CCM_SMC_EDIT_BILLING')">
            <button view type="button" class="btn btn-primary" (click)="enterEdit()">Edit</button>
            <ng-container edit>
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-default" (click)="cancelEdit()">Cancel</button>
            </ng-container>
        </div>
    </h3>
    <form-errors [form]="form"></form-errors>
    <div class="rows-striped">
        <div class="row-fluid form-group">
            <div class="col-sm-2">
                <label class="control-label">Product</label>
            </div>
            <div class="col-sm-3" [isEditable]="editing">
                <span view class="control-value">{{product?.packageName || 'None'}}</span>
                <ng-select
                    edit
                    formControlName="product"
                    [items]="packages | async"
                    [loading]="loadingValues.packages"
                    (change)="setInvoicing($event)"
                    [clearable]="false"
                    bindLabel="packageName"
                    placeholder="Select Package">
                </ng-select>
            </div>

            <div class="col-sm-1"></div>

            <div class="col-sm-2">
                <label class="control-label">Invoicing</label>
            </div>
            <div class="col-sm-5">
                <span class="control-value" [ngSwitch]="invoicing">
                    <ng-container *ngSwitchDefault>
                        <span class="glyphicon glyphicon-remove text-danger"></span> Off
                    </ng-container>
                    <ng-container *ngSwitchCase="true">
                        <span class="glyphicon glyphicon-ok text-success"></span> On
                    </ng-container>
                </span>
            </div>
        </div>
    </div>
</form>
