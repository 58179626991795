import { SmNetworkConnector } from '.';

export class FacebookConnector extends SmNetworkConnector {
	name = 'Facebook';
	btnClass = 'connect-btn-fb';
	iconClass = 'c-icon c-icon-facebook';

	constructor(props: {click: (entry) => void}) {
		super({...props, url: ''});
	}
}
