import { Component, Input } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { RawPermissionsService, UserType } from 'shared/roles/raw-permissions.service';

type PermissionSection = {
    title: string;
    groups: string[];
};

@Component({
    templateUrl: './permission-sections.component.html',
    selector: 'permission-sections',
})
export class RolePermissionSectionsComponent {
    @Input()
    editing: boolean = false;

    @Input()
    permissionState: any;

    @Input()
    permissionType?: string = null;

    sections: any;

    constructor(
        public User: UserService,
        private rawPermissionsService: RawPermissionsService,
    ) {
    }

    ngOnInit() {
        const rawPermissions = this.getPermissions();
        const sections: PermissionSection[] = [];
        if (rawPermissions.sections) {
            rawPermissions.sections.forEach(({ enabled = () => true, groups }, title) => {
                enabled() && sections.push({
                    title,
                    groups,
                });
            });
        } else {
            sections.push({
                title: 'User Permissions',
                groups: Array.from(rawPermissions.grouping.keys()),
            });
        }
        this.sections = sections;
    }

    // config can have different dependencies on role, env, so prepare config for each role
    getPermissions() {
        return this.rawPermissionsService.get(this.permissionType?.toLocaleLowerCase() as UserType);
    }
}
