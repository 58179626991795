import { EventEmitter, HostBinding, Input, OnDestroy, Output, ɵɵdirectiveInject, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { StateService } from '@uirouter/core'
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { MonitoringScheduleData } from 'tpo/social-compliance/schedule/schedule.service';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

import { GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Directive()
export class CommonPartialScheduleComponent implements OnDestroy {
    @Input() settings: MonitoringScheduleData;
    @Input() priceUpdate: Subject<MonitoringScheduleData>;
    @Output('editing') editingEmitter = new EventEmitter<boolean>();
    @Output('notify') notifyEmitter = new EventEmitter<{ message?: string, type?: GlobalNotificationType }>();
    protected destroy$ = new Subject<void>();
    User: UserProfile;
    hasCompliance = false;
    hasPermission = false;
    toggleEnabled = false;
    permissionTooltipText: string = '';
    stateService: StateService = ɵɵdirectiveInject(StateService);

    @HostBinding('class.remote-resource') private _rr = true;
    @HostBinding('class.resolved') loading = false;

    constructor(
        { profile }: UserService,
    ) {
        this.User = profile;
        this.hasCompliance = this.hasComplianceFeature;
        this.hasPermission = this.hasOrderReviewPermission;
        this.toggleEnabled = (this.hasCompliance && this.hasPermission);

        this.permissionTooltipText = this.determinePermissionTooltipText();
    }

    private _editing = false;
    get editing(): boolean {
        return this._editing;
    }

    set editing(value: boolean) {
        this._editing = value;
        this.editingEmitter.emit(this.editing);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateSettings(settings: Partial<MonitoringScheduleData>): void {
        Object.assign(this.settings, settings);
    }

    redirectIfNoCompliance(): boolean {
        if (!this.hasCompliance) {
            this.stateService.go('social-compliance.subscription');
        }

        return this.hasCompliance;
    }

    toggleClicked(): boolean {
        return this.redirectIfNoCompliance();
    }

    get hasComplianceFeature(): boolean {
        return this.User.hasFeature(PACKAGE_FEATURE.COMPLIANCE);
    }

    get hasOrderReviewPermission(): boolean {
        return this.User.can('TPO_SMC_ORDER_REVIEWS');
    }

    determinePermissionTooltipText(): string {
        if (this.hasCompliance && !this.hasPermission) {
            return 'You don\'t have permissions';
        }

        return '';
    }
}
