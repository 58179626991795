import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CellSelectConfig } from 'commons/components/cell-select/cell-select.component';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';
import { Observable } from 'rxjs';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';

@Component({
    templateUrl: './cell-select-modal.component.html',
})
export class CellSelectModalComponent implements OnInit {
    confirmText = 'Save';
    cancelText = 'Cancel';
    config: CellSelectConfig;
    items$: Observable<unknown>;
    value: unknown;
    onConfirm: (value) => unknown;
    onCancel: () => unknown = () => {
        this.modalRef.hide();
    };
    resolving: boolean;

    form: RealmFormGroup;

    constructor(public modalRef: BsModalRef) {
    }

    ngOnInit() {
        this.form = new RealmFormGroup({
            select: new RealmFormControl(
                'select',
                { label: this.config.label, updateOn: 'change' },
                this.config?.required ? DropdownRequiredValidator : null,
            ),
        });
        this.form.reset({ select: this.value });
    }

    submit() {
        const { select } = this.form.value;
        this.onConfirm(select);
    }

    public setServerError(error: ServerErrorResponse) {
        this.form.setServerError(error);
    }
}
