import { last, startsWith, take } from 'lodash';

/**
 * @description holds textarea editable code, and non-editable part
 * some posts have full editable text and some have non-editable postfix
 * @example zillow review has non-editable link in the end of the post
 */
export type ViewTextConfig = {
	/**
	 * @description string to show in textarea
	 */
	text: string;
	/**
	 * @description actual postfix value
	 */
	postfix: string;
	/**
	 * @description explicit flag to show if user can change postfix
	 * if it is false => postfix won't show up in textarea
	 */
	allowPostfixChange: boolean;
};

const parseViewText = (text: string, isReview: boolean): ViewTextConfig => {
	const result = {
		text,
		postfix: '',
		allowPostfixChange: !isReview,
	};

	// postfix change allowed or no text => return full text for textarea
	if (result.allowPostfixChange || !text.length) {
		return result;
	}

	const wordsArray = text.split(' ');
	const lastWord = last(wordsArray);
	if (!lastWord) {
		return result;
	}

	// if last word is a link and allowPostfixChange if false => remove it from text
	const isShortenedLink = startsWith(lastWord, 'http');
	if (!isShortenedLink) {
		return result;
	}

	result.postfix = lastWord;
	result.text = take(wordsArray, wordsArray.length - 1).join(' ');

	return result;
}

export class PostViewText {
	/**
	 * @description string to show in textarea
	 */
	text: string = '';
	/**
	 * @description actual postfix value
	 */
	postfix: string = '';
	/**
	 * @description explicit flag to show if user can change postfix
	 * if it is false => postfix won't show up in textarea
	 */
	allowPostfixChange: boolean = true;

	/**
	 * @description calculated value for text and postfix
	 * this value saved on post, and shown on post preview
	*/
	fullText: string = '';

	constructor(text: string, isReview: boolean) {
		const config = parseViewText(text, isReview);
		this.text = config.text;
		this.postfix = config.postfix;
		this.allowPostfixChange = config.allowPostfixChange;
		this.getFullText();
	}

	setText(text: string): void {
		this.text = text;
		this.getFullText();
	}

	getFullText(): void {
		if (!this.postfix) {
			this.fullText = this.text;
			return;
		}

		if (!this.text) {
			this.fullText = this.postfix;
			return;
		}

		this.fullText = `${this.text} ${this.postfix}`;
	}
}
