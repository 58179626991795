<div class="social-media-post-comments remote-resource" [class.resolved]="post.$resolved"  *ngIf="post?.comments?.totalElements">
	<ng-container *ngFor="let parentComment of post?.comments.content;">
		<!--Parent comment-->
		<div class="info">
			<b>{{parentComment.name}}</b>
			<small class="text-muted">&nbsp;&nbsp;{{parentComment.createdDate | date : "MMMM d, yyyy 'at' HH:mm a"}}</small>
		</div>

		<sm-post-attachments
			*ngIf="parentComment?.attachments?.length"
			[post]="parentComment"
			[previewEnabled]="false"
		></sm-post-attachments>

		<p *ngIf="parentComment?.comment" [innerHTML]="parentComment.comment"></p>

		<hr>
		<!--end:Parent comment-->

		<!--Child comments-->
		<div class="sub-comments" *ngIf="parentComment?.comments?.totalElements">
			<ng-container *ngFor="let subComment of parentComment?.comments?.content;">
				<div class="info">
					<b>{{subComment?.name}}</b>
					<small class="text-muted">&nbsp;&nbsp;{{subComment.createdDate | date : "MMMM d, yyyy 'at' HH:mm a"}}</small>
				</div>

				<sm-post-attachments
					*ngIf="subComment?.attachments?.length"
					[post]="subComment"
					[previewEnabled]="false"
				></sm-post-attachments>

				<p *ngIf="subComment.comment" [innerHTML]="subComment.comment"></p>

				<hr>
			</ng-container><!--end:subComment-->
		</div>
		<!--end:Child comments-->
	</ng-container><!--end:info-->
	<a *ngIf="post?.comments?.totalElements > 5" (click)="post?.isAllCommentsVisible || (post.comments.content.length === post.comments.totalElements) ? collapseAllComments(post) : expandAllComments(post)">
		{{post.isAllCommentsVisible || (post.comments.content.length == post.comments.totalElements) ? 'Collapse comments' : 'View ' + (post.comments.totalElements - 5) + ' more comments'}}
	</a>
</div><!--end: attachment-->
