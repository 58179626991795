import { Injectable } from '@angular/core';

import { CampaignsResourceService } from '../campaigns-resource.service';

@Injectable()
export class CampaignService {
	constructor(
		public campaignsResource: CampaignsResourceService,
	) {}

    get(campaignId) {
        if (campaignId)
            return this.campaignsResource.campaign.get({ campaignId });

        const result = {};
        Object.defineProperty(result, '$resolved', { enumerable: false, writable: true, value: true });
        Object.defineProperty(result, '$promise', { enumerable: false, value: Promise.resolve(result) });
        return result;
    }

    create = (...args) => this.campaignsResource.campaign.create(...args);
    update = (...args) => this.campaignsResource.campaign.update(...args);
	prevalidate= (...args) => this.campaignsResource.campaignPrevalidate.update(...args);
}
