import { Component, Input } from '@angular/core';

import { AlertEvent } from 'lender/alerts/alert.interface';


@Component({
    selector: 'alert-events',
	templateUrl: './alert-events.component.html',
})
export class AlertEventsComponent {
	@Input() alertEvents: AlertEvent[] = [];
}
