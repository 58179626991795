import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { LenderUserStatusColors } from 'lender/user-management/user/user-status-colors';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ReadOnlyUserProfile } from 'commons/interfaces/read-only-user-profile.type';
import { UserManagementCapabilitiesService } from 'commons/services/user-capabilities/user-management-capabilities.service';


@Component({
	templateUrl: './create-read-only-user.component.html',
})
export class CreateReadOnlyUserComponent implements OnInit {
	readonly lenderUserStatusColors = LenderUserStatusColors;

	currentUserProfile: UserProfile;
	isNew: boolean = true;
	targetUserId: number;
	targetUser: ReadOnlyUserProfile;
	usersName: string;

	constructor(
		private readonly stateService: StateService,
		private readonly userManagementCapabilitiesService: UserManagementCapabilitiesService,
		private readonly userService: UserService,
	) {
	}

	// Override
	async ngOnInit(): Promise<void> {
		this.currentUserProfile = this.userService.profile;

		const userCanManageCCMUsers: boolean = this.userManagementCapabilitiesService.userCanManageCCMUsers(this.currentUserProfile);
		const userIsDesktopSupport: boolean = this.userManagementCapabilitiesService.userIsDesktopSupport(this.currentUserProfile);

		const srefOptions: any = {
			inherit: false,
			location: 'replace',
			reload: true,
		};

		if (userCanManageCCMUsers) {
			this.stateService.go(
				this.userManagementCapabilitiesService.userManagementItemSref,
				{ actionType: 'new', id: null },
				srefOptions
			);
		} else if (!userIsDesktopSupport) {
			this.stateService.go('home', {}, srefOptions);
		}
	}

	userCreated(newReadOnlyUser: ReadOnlyUserProfile): void {
		this.stateService.go(this.userManagementCapabilitiesService.userManagementUsersSref);
	}
}
