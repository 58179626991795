import { OnInit, Directive } from '@angular/core';
import { TransitionService, StateService, UIRouter } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { find, assign } from 'lodash';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { PagedListComponent } from 'commons/components/list/paged-list.component';
import { ConfirmModalComponent, NotificationModalComponent, SmConfirmModalComponent } from 'commons/components/modals';
import { SegmentedControlValue } from 'commons/components/segmented-controls/segment-control-value.interface';
import { NgResourceArray } from 'commons/interfaces';
import { SocialMediaPost } from 'commons/components/sm-post/sm-post.interface';

import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';
import { IPermissionsResourceService } from 'tpo/social-compliance/publishing-permissions/publishing-permissions-resource.service';

import { MediaLink } from 'shared/findings/$findingId/finding.interface';
import { SharedSocialMediaService } from 'shared/social-media/social-media.service';

import { allowConnectionStatusCheck } from '../allowed-connection-statuses';

@Directive()
export abstract class SocialAccountsBasicListComponent extends PagedListComponent implements OnInit {
	modalRef: BsModalRef;
	User: UserProfile;
	isTpo: boolean;
	isComergence: boolean;
	isLender: boolean;
	isSA: boolean;
	isWP: boolean;
	isRemoved: boolean;

    list: NgResourceArray<MediaLink | SocialMediaPost> = [];
	isNotificationSet: boolean = false;
	hasPublishPermission: boolean = true;
	permissionsResource: IPermissionsResourceService;

	// prevents loadList while before deferredInit() runs;
	deferredResolved: boolean = false;

	isPublisherOnThePage: boolean = false;

	socialMediaServiceParams: any;

    socialAccountsSegmentedControlValue: SegmentedControlValue = {
        value: 'social',
        title: 'Social Accounts',
        ignore: false,
    };
    webPresenceSegmentedControlValue: SegmentedControlValue = {
        value: 'web-presence',
        title: 'Web Presence',
        ignore: false,
    };
    removedSegmentedControlValue: SegmentedControlValue = {
        value: 'removed',
        title: 'Removed',
        ignore: false,
    };
	availableStates: SegmentedControlValue[] = [
        this.socialAccountsSegmentedControlValue,
        this.webPresenceSegmentedControlValue,
        this.removedSegmentedControlValue,
	];
	columnClass: { [key: string]: string } = {
		account: 'col-sm-4',
		lastConnectionStatus: 'col-sm-2',
		monitoringStatus: 'col-f-100',
		rss: 'col-f-100',
		actions: 'col-sm-a',
	};
	selectedState = 'social';

    hasInvoicingOn = false;

	apiLinkMap = {
		'social': 'SOCIAL_ACCOUNTS',
		'web-presence': 'WEB_PRESENCE',
		'removed': 'REMOVED',
	};

	allowConnectionStatusCheck = allowConnectionStatusCheck;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		public userService: UserService,
		public $state: $stateProvider,
		public modalService: BsModalService,
		public sharedSocialMediaService: SharedSocialMediaService,
        public readonly uiRouter: UIRouter,
	) {
		super(transitionService, stateService);

		this.User = userService.profile;
		this.isTpo = this.User.isTpo;
		this.isLender = this.User.isLender;
		this.isComergence = this.User.isComergence;

		if (this.isLender) {
			// Prevent removed tabs for investor
            this.removedSegmentedControlValue.ignore = true;
        }

		this.hasPublishPermission = this.isTpo && this.User.can('TPO_TEAM_PUBLISH');

		this.selectedState = this.getSelectedState();
		this.calculateStateWithAdjustedDefaultPageSize(this.selectedState);

		assign(this.defaultFilters, {
			nmlsId: this.User.organization.nmlsId,
			id: null,
		});

		this.omitParams.push('nmlsId', 'id', 'linkListType');
		this.globalParams.push('linkListType');
	}

	abstract getPermissionParams(account): {
		tpoId: number,
		linkId: number,
		id?: number,
	};

	ngOnInit() {
		if (!this.isTpo && !this.isComergence && this.stateService.params.linkListType === 'removed') {
			this.changeState({ state: 'social' });
			return;
		}

		this.params$.subscribe((params) => {
			this.calculateState(params.linkListType);
		})

		this.postInit();
	}

	postInit() {
		this.deferredInit();
	}

	deferredInit() {
		this.deferredResolved = true;
		super.ngOnInit();
	}

	reloadList(state) {
		this.loadList(this.getClearParams(this.params)).then((): void => {
			this.showNotification('New link was added successfully', state);
		});
	}

	getSelectedState() {
		const { linkListType } = this.stateService.params;
		const existingType = find(this.availableStates, [ 'value', linkListType ]);

		return existingType ? existingType.value : this.availableStates[0].value;
	}

    calculateStateWithAdjustedDefaultPageSize(state) {
        this.calculateState(state);
        this.defaultFilters.size = 10;
    }

	calculateState(state) {
		this.selectedState = state;
		const stateCode = this.apiLinkMap[state] || '';
		this.isSA = stateCode === 'SOCIAL_ACCOUNTS';
		this.isWP = stateCode === 'WEB_PRESENCE';
		this.isRemoved = stateCode === 'REMOVED';
		this.calculateClasses();
	}

	calculateClasses() {
		this.columnClass.account = `col-sm-${(this.isRemoved || this.isLender ? 9 : this.isComergence || this.isSA ? 4 : 6)}`;
	}

	changeState({ state }) {
        // The signature of list elements is different depending on the tab. It's not ideal, but Old Features that aren't in the code based anymore were wedged in after the fact.
        // We need to clear the list AS SOON AS THE TAB CHANGES, since the new tab rerenders BEFORE the new request is made.
        // Without clearing the list, that rerender causes errors when switching from Old Features to one of the other tabs because it's expecting
        // data to exist that doesn't.
        if (this.list) {
            this.list.splice(0, this.list.length);
            // Needed to not double-load.
            this.list.$resolved = false;
        }

        this.calculateState(state);
        const size = 10;
		const { q } = this.stateService.params[this.listName];
		this.stateService.transitionTo(this.stateService.current.name, {
			...this.stateService.params,
			linkListType: state,
			[this.listName]: {
                size: size,
				...(q && { q }),
			},
		}, { inherit: false, reload: false });
	}

	toPublishingPermissions(link) {
		if (!this.isLender) {
			this.stateService.go('^.publishing-permissions', {
				linkId: link.data.linkId,
			});
			return;
		}
	}

	onConnectFinish(): void {
		this.getSelectedState() === 'social' ?
			this.loadList(this.params) :
			this.changeState({state: 'social'});
	}

	showAccessDeniedModal = (): void => {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Sorry! Access Denied',
				confirmText: 'Ok',
				notification: 'Please contact Customer Service.',
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	showPermissionModal = (): void => {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Sorry! Access Denied',
				confirmText: 'Ok',
				notification: `<div class="denied-lock"></div>
					<p>You currently do not have the necessary permissions to access this section.</p>
					<p>If you believe this is an error, please contact your Administrator to manage your permissions.</p>
				`,
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-403',
		});
	}

	showNotification(message, linkListType) {
		const { name } = this.stateService.current;
		const href = this.stateService.href(name, { linkListType, [this.listName]: null });
		const index = this.availableStates.findIndex((p) => p.value === linkListType);
		const link = `<a class="underlined text-success" href="${href}">${this.availableStates[index].title}</a>`;
		if (this.getSelectedState() !== linkListType) {
			message = `${message} under ${link}`;
		}
		const timeout = 5000;

		this.$state.notify(name, {
			notification: {
				type: 'alert-success',
				message,
				timeout,
			},
		});

		this.isNotificationSet = true;

		setTimeout(() => {
			this.isNotificationSet = false;
		}, timeout);
	}

	addLink = (link: string): Promise<void> =>
		this.sharedSocialMediaService.mediaLinks.add(
			this.socialMediaServiceParams,
			{
				link,
				isManual: true,
			},
		).$promise
			.then(({ networkCode }) => {
				const state = ['YLP', 'OT'].includes(networkCode) ? 'web-presence' : 'social';
				this.reloadList(state);
			})

	removeLink = (link: SocialAccountLink): void => {
		this.list.$resolved = false;
		this.sharedSocialMediaService.mediaLinks.remove({
			...this.socialMediaServiceParams,
			mediaLinkId: link.data.linkId,
		}).$promise
			.then(() => {
				if (this.isComergence) {
					this.loadList(this.params);
					return;
				}

				link.setRemovalRequested(true);
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	manageLink = (link: SocialAccountLink, action: 'acceptRemoval' | 'declineRemoval'): void => {
		this.list.$resolved = false;
		this.sharedSocialMediaService.mediaLinks[action]({
			...this.socialMediaServiceParams,
			mediaLinkId: link.data.linkId,
		}, null).$promise
			.then(() => {
				const params = {
					...this.params,
				};

				if (this.list.length === 1 && action === 'acceptRemoval') {
					params.page = this.params.page ? this.params.page - 1 : 1;
				}

				this.loadList(this.params);
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	restoreLink(link: SocialAccountLink) {
		const initialState = {
			title: 'Account Restore',
			confirmText: 'Confirm',
			cancelText: 'Cancel',
			message: 'You are about to restore a media account. Are you sure you want to proceed?',
			onConfirm: () => {
				this.modalRef.content.resolving = true;
				this.sharedSocialMediaService.mediaLinks.restore({
					...this.socialMediaServiceParams,
					mediaLinkId: link.data.linkId,
				}, null).$promise
					.then(() => {
						this.loadList(this.params);
						this.modalRef.hide();
					})
					.catch((response) => {
						this.modalRef.content.errorText = response.data;
					})
					.finally(() => {
						this.modalRef.content.resolving = false;
					});
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState,
			class: 'modal-smd modal-new modal-restore-link',
		});
	}

	removeCurrentPublisherAccess({data: account}: SocialAccountLink) {
		const initialState = {
			title: 'Remove Publisher Access',
			message: `You will no longer be able to post on behalf of <strong>${account.name}</strong> for this account:`,
			confirmText: 'Confirm',
			account,
			onConfirm: () => {
				const modalScope = this.modalRef.content;
				modalScope.resolving = true;
				modalScope.errorText = null;

				this.permissionsResource.permissions.removeCurrent({
						...this.getPermissionParams(account),
					}, () => {
						this.modalRef.hide();
						this.loadList(this.getClearParams(this.params));
					}, ({data}) => {
						modalScope.resolving = false;
						modalScope.errorText = data.message;
					},
				);
			},
		};

		this.modalRef = this.modalService.show(SmConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

    public fakeListResolved(list: any): void {
        this.list.$resolved = true;
        list.$pagesTotal = 0;
        list.$page = 0;
    }
}
