<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div
		*ngFor="let item of list"
		class="row-fluid"
	>
		<div class="col-sm-6 va-m">
			<div class="flex-row gap-m">
				<div [ngClass]="getImageClass(item)"></div>
				<div class="flex" *ngIf="item.locationNmlsId">
					<div><span [class.has-discrepancy]="item.discrepancies?.includes('businessName')">{{item.locationName}}</span></div>
					<div class="text-muted"><span [class.has-discrepancy]="item.discrepancies?.includes('locationNmlsId')">NMLS ID: {{item.locationNmlsId}}</span></div>
					<div class="text-muted"><span [class.has-discrepancy]="item.discrepancies?.includes('fullAddress')">{{item | address}}</span></div>
					<div class="text-muted" *ngIf="item.branchManagers">Branch Manager: {{item.branchManagers}}</div>
				</div>
			</div>
		</div>
		<div class="col-sm-2"></div>
		<div class="col-sm-1 va-m">
			<sm-network-icon [config]="{ networkCode: item.removedFromNmls ? 'NMLS*' : 'NMLS' }"></sm-network-icon>
		</div>
		<div class="col-sm-3"></div>
	</div>
</div>
