import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
	selector: '[fileDrop]',
})
export class FileDropDirective implements OnInit, OnDestroy {
	@Output()
	fileDrop = new EventEmitter<FileList>();

	private hover$ = new Subject<boolean>();
	private unsubscribe$ = new Subject<void>();

	constructor(
		private ngZone: NgZone,
		private renderer: Renderer2,
		private elementRef: ElementRef,
	) {
		this.renderer.addClass(
			this.elementRef.nativeElement,
			'drop-target'
		);

		this.hover$
			.pipe(
				throttleTime(100, undefined, { trailing: true }),
				distinctUntilChanged(),
				takeUntil(this.unsubscribe$)
			)
			.subscribe((hover) => {
				this.renderer[hover ? 'addClass' : 'removeClass'](
					this.elementRef.nativeElement,
					'hover'
				);
			});
	}

	ngOnInit(): void {
		this.ngZone.runOutsideAngular(() => {
			this.elementRef.nativeElement.addEventListener(
				'dragenter',
				this.onDragEnter,
			);
			this.elementRef.nativeElement.addEventListener(
				'dragover',
				this.onDragOver,
			);
			this.elementRef.nativeElement.addEventListener(
				'dragleave',
				this.onDragLeave,
			);
			this.elementRef.nativeElement.addEventListener(
				'drop',
				this.onDrop,
			);
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onDragEnter = (e: DragEvent): void => {
		e.preventDefault();
	};

	onDragOver = (e: DragEvent): void => {
		e.preventDefault();
		this.hover$.next(true);
	};

	onDragLeave = (e: DragEvent): void => {
		e.preventDefault();
		this.hover$.next(false);
	};

	onDrop = (e: DragEvent): void => {
		e.preventDefault();
		this.hover$.next(false);
		this.ngZone.run(() => {
			this.fileDrop.emit(e.dataTransfer.files);
		});
	};
}
