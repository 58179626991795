import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'jsonParse',
})
export class JsonParsePipe implements PipeTransform {
	transform(value: string): string {
		const parsed = JSON.parse(value);
		return Array.isArray(parsed) ? parsed.join(', ') : parsed || '–';
	}
}
