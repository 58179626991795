<ng-container *ngIf="hasPermissions(); then validPermissions; else invalidPermissions"></ng-container>
<ng-template #validPermissions>
	<h2>
		Orders
		<smHelpLink [url]="helpLink" *ngIf="User.isTpo"></smHelpLink>
	</h2>
	<div class="remote-resource" [class.resolved]="list.$resolved">
		<div class="filters-rows">
			<div class="row filter-labels">
				<div class="col-sm-2">
					<div class="text-light">Search</div>
				</div>
				<div class="col-sm-2" *ngIf="User.isComergence">
					<span class="text-light">Audit Status</span>
				</div>
			</div>
			<div class="row filter-selectors">
				<div class="col-sm-2">
					<search-list placeholder="Order ID"></search-list>
				</div>
				<div class="col-sm-2" *ngIf="User.isComergence">
					<ng-select
						[multiple]="true"
						[(ngModel)]="params.status"
						[items]="filters.status.$promise | async"
						[loading]="!filters.status.$resolved"
						bindValue="code"
						bindLabel="label"
						(change)="setFilter({ status: $event })"
						placeholder="All Statuses"
					></ng-select>
				</div>
				<div [ngClass]="User.isComergence ? 'col-sm-8' : 'col-sm-10'">
					<div class="pull-right" *ngIf="User.can('TPO_SMC_ORDER_REVIEWS') && User.smEnabled">
						<a (click)="createOrder()" class="btn btn-primary">New Order</a>
					</div>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-2" columnName="id">Order ID</sortable-header>
				<sortable-header class="col-sm-a" columnName="orderDate">Order Date</sortable-header>
				<sortable-header class="col-sm-a" columnName="status">Order Status</sortable-header>
				<sortable-header class="col-sm-a" columnName="statusDate">Status Date</sortable-header>
				<sortable-header class="col-sm-a" columnName="reviewsCompleted">Audits Completed</sortable-header>
				<sortable-header class="col-sm-a" columnName="orderedBy" *ngIf="!User.isComergence">Ordered By</sortable-header>
				<sortable-header class="col-sm-a" columnName="customerName" *ngIf="User.isComergence">Customer</sortable-header>
			</div>
			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
			</div>
			<a
				*ngFor="let item of list"
				class="row-fluid clickable"
				uiSref="social-compliance.orders.:id"
				[uiParams]="{ id: item.id }"
			>
				<div class="col-sm-2 va-m">
					<strong>{{item.id}}</strong>
				</div>
				<div class="col-sm-a va-m">{{(item.orderDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
				<div class="col-sm-a va-m text-bigger">
					<ng-container *ngIf="statusesConfig[item.status]; let orderStatus">
						<span class="label" [ngClass]="'label-' + orderStatus.color">{{orderStatus.title}}</span>
					</ng-container>
				</div>
				<div class="col-sm-a va-m">{{(item.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
				<div class="col-sm-a va-m">{{item.reviewsCompleted}} of {{item.reviewsTotal}}</div>
				<div class="col-sm-a va-m" *ngIf="!User.isComergence">{{item.orderedBy || '&ndash;'}}</div>
				<div class="col-sm-a va-m" *ngIf="User.isComergence">
					{{item.customerName || '&ndash;'}}
					<div class="text-light" *ngIf="item.customerNmlsId">NMLS ID: {{item.customerNmlsId}}</div>
				</div>
			</a>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length">
				<div class="col-sm-12 text-center text-light text-bigger va-m">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No orders'}}
				</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</ng-template>
<ng-template #invalidPermissions>
	<no-permissions title="Orders">
		<img src="/static/images/promo/sm-get-started/Icon_order_off.svg" alt="Orders"/>
	</no-permissions>
</ng-template>
