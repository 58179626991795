import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CcmDataMatchReport } from 'comergence/data-match/ccm-data-match.interface';
import { CcmDataMatchService } from 'comergence/data-match/ccm-data-match.service';


@Component({
    selector: 'ccm-data-match-hq-verification-result-section',
    templateUrl: 'ccm-data-match-hq-verification-results-section.component.html',
})
export class CcmDataMatchHqVerificationResultsSectionComponent {
    @Input() dataMatchHqVerificationResult: CcmDataMatchReport;
    @Input() isLoaded: boolean;
    @Input() isApplicable: boolean;
    @Input() reportId: number;
    @Output() newReverifyEvent: EventEmitter<CcmDataMatchReport> = new EventEmitter<CcmDataMatchReport>();
    @Output() resolving = new EventEmitter<boolean> ();

   constructor(private ccmDataMatchService: CcmDataMatchService) {}

    exportReport() {
        window.open(`/api/rest/datamatch/${this.dataMatchHqVerificationResult.id}/report`, '_blank');
    }

    async reverifyResult() {
       const verifyResult: CcmDataMatchReport = {...this.dataMatchHqVerificationResult};
       try {
           this.resolving.emit(true);
           const reverifiedResult:CcmDataMatchReport = await this.ccmDataMatchService.reverifyReportContent(this.reportId, verifyResult).toPromise();
           this.newReverifyEvent.emit(reverifiedResult);
       }
       catch(e) {
       }
       finally {
           this.resolving.emit(false);
       }
    }
}
