import { Component } from '@angular/core';
import { CommonPartialScheduleComponent } from 'tpo/social-compliance/schedule/partials/common-partial.component';
import { UserService } from 'angularjs-providers/user.provider';
import { MonitoringScheduleService } from 'tpo/social-compliance/schedule/schedule.service';

@Component({
    selector: 'tpo-audit-new-nmls',
    templateUrl: './audit-new-nmls.component.html',
})
export class TpoScheduleAuditNewNmlsComponent extends CommonPartialScheduleComponent {
    constructor(
        userService: UserService,
        public scheduleService: MonitoringScheduleService,
    ) {
        super(userService);
    }


    async toggleSection(enabled: boolean): Promise<void> {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        this.loading = true;
        const settings = await this.scheduleService.update({
            ...this.settings,
            auditNewNmlsIds: { ...this.settings.auditNewNmlsIds, enabled },
        }).toPromise();
        this.updateSettings(settings)
        this.priceUpdate.next(settings);
        this.loading = false;
    }
}
