import { StateParams } from '@uirouter/angular';

export enum QuestionnaireAction {
	NEW = 'new',
	EDIT = 'edit',
	CLONE = 'clone',
}

export interface QuestionnaireTab {
	title: string;
	activeName?: string;
	sref: string;
	action?: QuestionnaireAction;
}

export const QuestionnaireTabs: QuestionnaireTab[] = [
    {
        title: 'Questions',
        sref: '.questions',
        action: QuestionnaireAction.EDIT,
    },
    {
        title: 'Documents',
        sref: '.documents',
    },
];


export interface QuestionnaireParams extends StateParams {
	action?: QuestionnaireAction;
	questionnaireId?: number;
}

export interface QuestionnaireItem {
	id: number;
	name: string;
	description: string;
	questionsNumber: number;
	assignedFor: string;
	status: string;
	channels?: string[];
	useSkipLogic?: boolean;
}

export interface QuestionnaireUsage {
	usedIn: string;
}

export enum QuestionType {
	YES_NO = 'YN',
	SINGLE_SELECT = 'PL',
	MULTI_SELECT = 'MS',
	FORM = 'FF',
	NUMBER = 'NUM',
	USD = 'USD',
	PERCENT = 'PCT',
	DATE = 'DT',
	GROUP = 'GR',
}

type NextQuestionRef = {
	index: number;
	id: string | number;
	title: string;
};

export interface QuestionnaireDocumentAvailable {
	investorClientDocumentId: number;
	documentName?: string;
}

export type AnswerOption = {
	documents: QuestionnaireDocumentAvailable[] | null;
	explanationRequired: boolean;
	id: number | string;
	nextQuestionId: number | string | null;
	text: string;
	// computed values for skipLogic calculation
	_skipLogic?: boolean;
	_nextQuestion?: null | NextQuestionRef;
}

export type AnswerOptionFormValue = Omit<AnswerOption, 'documents'> & {
	documents: [],
	_hasDocument: boolean;
};

export interface Question {
	answers: AnswerOption[];
	categoryId: number | string | null;
	id: number | string;
	questionBankQuestionId: number | string | null;
	questionType: QuestionType;
	text: string;
	usageCount: number | null;
	// computed value for skipLogic calculation
	_number?: number;
}

export const DocumentName = {
	QUESTIONNAIRE: 'Questionnaire',
	QUESTION: 'Question',
	FINANCIAL_DOC: 'Financial Doc',
} as const;

export const FinancialDocument = {
	FINANCIAL_STATEMENT: 'Financial Statements',
	BALANCE_SHEET: 'Balance Sheet',
	STATEMENT_OF_CASH_FLOWS: 'Statement of Cash Flows',
	INCOME_PROFIT_LOSS_STATEMENT: 'Income Statement',
}

export type DocumentType = keyof typeof DocumentName;
export type FinancialDocumentType = keyof typeof FinancialDocument;

export interface QuestionnaireDocument {
	documentDescription: string;
	documentName: string;
	documentType: DocumentType;
	investorClientDocumentId: number;
	hasTemplate: false;
	financialDocumentType?: FinancialDocumentType;
	periods?: string;
}

export interface afterOption {
	i: number,
	id?: string | number,
	name: string,
	hint?: string
}

export interface QuestionBankCategory {
	id: number;
	organizationId: number;
	name: string;
	editable: boolean;
}

export interface QuestionBankCategoryWithCount {
	id: number;
	questionCount: number;
	name: string;
	editable: boolean;
}

interface ShortQuestionnaireItem {
	id: number;
	name: string;
}

export type BankQuestionInUseStats = {
	inUse: ShortQuestionnaireItem[];
	skipLogic: ShortQuestionnaireItem[];
}

export interface QuestionnaireStatus {
	status: 'A' | 'I';
}

export interface FinancialRequests {
	documentType: FinancialDocumentType;
	enabled: boolean;
	settings: FinancialRequestValue[]
}

export interface FinancialRequestValue {
	isAudited: boolean;
	title: string;
	period: string;
}
