<section class="remote-resource" [class.resolved]="details.$resolved">
	<ng-container *ngIf="details.$resolved">
		<h4>
			{{details.name}}
			<ng-container *ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')">
				<button type="button" class="btn btn-tertiary" (click)="renameGroup()">
					<span class="glyphicon glyphicon-pencil"></span>
					Rename Group
				</button>
			</ng-container>
		</h4>

		<div class="flex-row gap-l">
			<div class="listing-group-image" [bgImage]="details.coverImage"></div>

			<div class="listing-group-details flex">
				<ng-container *ngIf="details.businessName">
					<div class="text-muted">Business Name</div>
					<div>{{details.businessName}}</div>
				</ng-container>
				<ng-container *ngIf="details | address as address">
					<div class="text-muted">Address</div>
					<div>{{address}}</div>
				</ng-container>
				<ng-container *ngIf="details.phoneNumber">
					<div class="text-muted">Phone</div>
					<div>{{details.phoneNumber | tel}}</div>
				</ng-container>
				<ng-container *ngIf="details.website">
					<div class="text-muted">Website</div>
					<div><a [href]="details.website | url" target="_blank">{{ details.website }}</a></div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</section>

<section>
	<h4 class="flex-row">
		<span class="flex">Business Listings</span>
		<a class="btn btn-primary" *ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')" [class.disabled]="showLinkItem" (click)="showAddListing()"><span class="glyphicon glyphicon-plus"></span> Link Listings</a>
	</h4>
	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-sm-6">Listing</div>
			<div class="col-sm-2 text-center">Group Sync</div>
			<div class="col-sm-1"></div>
			<div class="col-sm-3"></div>
		</div>
	</div>
	<listing-group-listing-add *ngIf="showLinkItem" (cancel)="hideAddListing()" (add)="doAddListing()" [group]="details"></listing-group-listing-add>
	<listing-group-nmls-locations *ngIf="details?.nmlsId"></listing-group-nmls-locations>
	<listing-group-listings></listing-group-listings>
</section>
