import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelationStatusColors } from 'lender/relation-status-colors';
import { TextEntity } from 'lender/clients/$id/channels/channels.service';

@Component({
    selector: 'approval-status',
    templateUrl: './approval-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalStatusComponent {
    @Input() status: TextEntity;
    relationStatusColors = RelationStatusColors;
}
