import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ValidationErrors, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { RegistrationService } from 'login/registration/registration.service';
import {
    NewUserRegistrationInformation,
    RegistrationInformation, RegistrationPassword,
} from 'login/registration/registration-interface.interface';

@Component({
    templateUrl: 'registration-password-creation.component.html',
    selector: 'registration-password-creation',
})
export class RegistrationPasswordCreationComponent implements OnInit {
    userService = inject(UserService);

    @Input() registrationFormInformation: RegistrationInformation;
    @Input() passwordInformation: RegistrationPassword;
    @Output() goBackEvent = new EventEmitter<RegistrationPassword>();

    passConfirmationShowing: boolean = false;
    passShowing: boolean = false;
    pinShowing: boolean = false;

    resolved: boolean = true;

    registrationPasswordForm: RealmFormGroup = new RealmFormGroup({
        password: new RealmFormControl(
            'password',
            {
                label: 'Password',
            },
            [Validators.required, Validators.minLength(6)],
        ),
        passwordConfirmation: new RealmFormControl(
            'passwordConfirmation',
            {
                label: 'Password Confirmation',
            },
            Validators.required,
        ),
        securityPin: new RealmFormControl(
            'securityPin',
            {
                label: 'Security Pin',
            },
            [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^\d+$/)],
        ),
    },
    { validators: [ () => this.validatePasswordConfirmation() ]},
    );

    constructor(
        protected registrationService: RegistrationService,
        public stateService: StateService,
    ) {}

    ngOnInit(): void {
        if(this.passwordInformation) {
            this.registrationPasswordForm.patchValue(this.passwordInformation);
        }
    }

    goBack() {
        this.goBackEvent.emit(this.registrationPasswordForm.value);
    }

    validatePasswordConfirmation(): ValidationErrors {
        if (!this.registrationPasswordForm) {
            return  null;
        } else {
            let validatorError: ValidationErrors;
            if (this.registrationPasswordForm.controls['passwordConfirmation'].value && this.registrationPasswordForm.controls['password'].value) {
                const invalidPassword = (this.registrationPasswordForm.controls['passwordConfirmation'].value !== this.registrationPasswordForm.controls['password'].value);
                if (invalidPassword) {
                    validatorError = { error: true };
                    this.registrationPasswordForm.controls['passwordConfirmation'].markAsTouched();
                    this.registrationPasswordForm.controls['passwordConfirmation'].setErrors({ error: { message: 'Please enter a matching password'} });
                }
                return invalidPassword ? validatorError: null;
            }
        }
    }

    togglePassConfirmationShowing() {
        this.passConfirmationShowing = !this.passConfirmationShowing;
    }

    togglePassShowing() {
        this.passShowing = !this.passShowing;
    }

    togglePinShowing() {
        this.pinShowing = !this.pinShowing;
    }

    async sendRegistrationRequest(){
        const registrationInfo: NewUserRegistrationInformation = {
            email: this.registrationFormInformation.email,
            firstName: this.registrationFormInformation.firstName,
            lastName: this.registrationFormInformation.lastName,
            nmlsId: this.registrationFormInformation.nmlsId,
            phone: this.registrationFormInformation.phone,
            password: this.registrationPasswordForm.value.password,
            repeatPassword: this.registrationPasswordForm.value.passwordConfirmation,
            pin: this.registrationPasswordForm.value.securityPin,
        };
        try {
            this.resolved = false;
            await this.registrationService.registerUser(registrationInfo).toPromise();
            this.userService.redirectToLogin();
        } catch({ error: { message }, message: httpError }) {
            this.registrationPasswordForm.setServerError({ message: message });
        } finally {
            this.resolved = true;
        }
    }
}
