import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { TaskNotificationService } from '../task-notification.service';
import { TaskNotificationModalComponent } from '../task-notification-modal';

@Component({
	selector: 'task-notification-button',
	templateUrl: './task-notification-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskNotificationButtonComponent {
	User: UserProfile
	@Input() tpoId: number;
	@Input() nmlsId: number;
	@Input() contactId: number;
	@Input() contactName: string;
	@Input() disabled: boolean;

	// disabledTooltip
	get disabledTooltip(): string {
		return this.disabled ? this._disabledTooltip : null;
	}
	private _disabledTooltip: string = 'Create a task to notify';

	// class
	@Input()
	set class(value: string | string[]) {
		this._class = value;
	}
	get class(): string | string[] {
		return [ 'btn' ].concat(this._class);
	}
	private _class: string | string[] = 'btn-primary';

	constructor(
		{ profile: User }: UserService,
		public taskNotificationService: TaskNotificationService,
		public modalService: BsModalService,
	) {
		this.User = User;
	}

	notify(): void {
		const {
			tpoId,
			nmlsId,
			contactId,
			contactName,
		} = this;
		const initialState = {
			tpoId,
			nmlsId,
			contactId,
			contactName,
		};
		this.modalService.show(
			TaskNotificationModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			}
		);
	}
}
