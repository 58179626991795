import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { forEach, reduce, isUndefined } from 'lodash';

import { LocalStorageService } from 'commons/services/localstorage.service';

type ColumnId = string;

interface ColumnBase {
	id: ColumnId;
	name: string;
}

export interface ColumnConfig extends ColumnBase {
	isDefault?: boolean;
}

interface Column extends ColumnBase {
	enabled: boolean;
}

type Columns = Map<ColumnId, Column>;
type Columns$ = BehaviorSubject<Columns>;

export interface IColumnsManagerMixin {
	localStorageName: string;
	columns: Columns;
	columns$: Columns$;

	prepareColumns(config: ColumnConfig[]): void;

	toggleColumn(columnId: ColumnId, nextEnabled?: boolean): void;

	getColumnVisibility(isVisible: boolean): 'table-cell' | 'none';
}

@Injectable()
export class ColumnsManagerMixin implements IColumnsManagerMixin {
    get StorageService(): LocalStorageService {
        return Injector.create({
            providers: [
                { provide: LocalStorageService, deps: [] },
            ],
        }).get(LocalStorageService);
    }

	localStorageName: string;
	// GOTCHA: do not add default values for objects and arrays,
	// since in case if target component won't add default value
	// values will be written here and will be shared between all entities by prototype
	columns: Columns;
	columns$: Columns$;

	prepareColumns(config): void {
		forEach(config, ({ id, name, isDefault }) => {
			this.columns.set(
				id,
				{
					id,
					name,
					enabled: !!isDefault,
				},
			);
		});
		const localStorageConfig = this.StorageService.get(this.localStorageName);
		if (localStorageConfig) {
			forEach(localStorageConfig, (enabled, columnId) => {
				const column = this.columns.get(columnId);
				if (column) {
					column.enabled = enabled;
				}
			});
		}

		this.columns$ = new BehaviorSubject(this.columns);
	}

    columnValues(): Column[] {
        return Array.from(this.columns.values());
    }

	toggleColumn(columnId, nextEnabled?): void {
		const column = this.columns.get(columnId);
		if (column) {
			column.enabled = isUndefined(nextEnabled) ?
				!column.enabled :
				nextEnabled;
		}

		this.columns$.next(this.columns);

		const localStorageConfig = reduce(
			Array.from(this.columns.values()),
			(acc, { id, enabled }: Column) => ({
				...acc,
				[id]: enabled,
			}),
			{},
		);

		this.StorageService.save(this.localStorageName, localStorageConfig);
	}

	getColumnVisibility(isVisible) {
		return isVisible ? 'table-cell' : 'none';
	}
}
