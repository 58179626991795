import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, model, OnDestroy, output, untracked, viewChild } from '@angular/core';
import { AvailableBSPositions } from 'ngx-bootstrap/positioning';
import { RealmFormGroup } from 'commons/forms';
import { RealmFormFamily, ServerErrorResponse } from 'commons/forms/form-group.hoc';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export enum INLINE_EDIT_TYPE {
    TEXT = 'text',
    NUMBER = 'number',
    DROPDOWN = 'dropdown',
    DATE = 'date',
    PHONE = 'phone',
}

export type InlineEditOptions = {
    placeholder: string;
    numberFormat: string;
    maxLength: number|null;
    clearable?: boolean;
    minDate: Date|null;
    maxDate: Date|null;
}

const DefaultOptions: InlineEditOptions = {
    placeholder: '',
    numberFormat: '1.0-0',
    maxLength: 100,
    clearable: false,
    minDate: null,
    maxDate: null,
};

@Component({
    selector: 'new-inline-edit',
    templateUrl: './inline-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewInlineEditComponent implements OnDestroy {
    editable = input<boolean>(false);
    placement = input<AvailableBSPositions>('bottom left');
    control = input<RealmFormFamily>(null);
    type = input<string>(INLINE_EDIT_TYPE.TEXT);
    name = input<string>('');
    value = input<unknown>('');
    options = input<InlineEditOptions, Partial<InlineEditOptions>>(DefaultOptions, {
        transform: (options: Partial<InlineEditOptions>) => ({ ...DefaultOptions, ...options })
    });
    resolved = model<boolean>(true);

    protected popover = viewChild('popover', { read: PopoverDirective });
    protected onSave = output<unknown>({ alias: 'save' });

    protected elementRef = inject(ElementRef);
    protected form = new RealmFormGroup({});
    protected INLINE_EDIT_TYPE = INLINE_EDIT_TYPE;

    private formEffectRef = effect(() => {
        const name = this.name();
        const control = this.control();
        untracked(() => {
            const current = this.form.get(name);
            if (control && current !== control) {
                this.form = new RealmFormGroup({
                    [name]: control,
                });
            }
        });
    });

    protected initPopover = () => {
        this.resolved.set(true);
        this.form.reset(this.value());
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('input.form-control')?.focus();
        });
    };

    setServerErrors(errors: ServerErrorResponse) {
        this.form.setServerError(errors);
    }

    protected save = () => {
        this.onSave.emit(this.form.value);
    };

    cancel = () => {
        this.popover().hide();
    };

    ngOnDestroy() {
        this.formEffectRef.destroy();
    }

    datepickerConfig = (): Partial<BsDatepickerConfig> => ({
        ...(this.options().clearable ? { showClearButton: true, clearPosition: 'right' } : {}),
        ...(this.options().minDate ? { minDate: this.options().minDate } : {}),
        ...(this.options().maxDate ? { maxDate: this.options().maxDate } : {}),
        adaptivePosition: true,
    });
}
