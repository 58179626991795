import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { linkTemplate } from 'shared/supporting-documents/helpers';

import { UploadResourceProps } from './common';
import {
    Channel,
    ChannelResource,
    ClientDocument,
    ClientDocumentDetails,
    ClientDocumentDetailsFormData,
    ClientHistoryDocument, DocumentContactsEntry,
    Entity, IDocumentLenderContactsEntry,
    RequestData, WaiveData,
} from './client-documents.interface';
import { Observable } from 'rxjs';
import { RealmHttpClient } from 'commons/services/http';

declare let apiPath: string;

export interface RequestableDocumentService {
    getTpoNotificationUsers({ lenderId, tpoId }): Observable<IDocumentLenderContactsEntry[]>;
    getLenderNotificationUsers({ lenderId, tpoId }): Observable<DocumentContactsEntry[]>;
}

@Injectable()
export class ClientDocumentsService implements RequestableDocumentService {
	public documents: any;
	public document: any;
	private documentHistory: any;
	public clientSpecificDocument: any;
	private requestableDocuments: any;
	private channels: any;
	private tpoUsers: any;
	private tpoContacts: any;
	private lenderUsers: any;
	// TODO: deprecated
	public one: any;

	private baseUrl = `${apiPath}/v2/lenders/:lenderId/tpos/:tpoId`;
	private baseParams = {
		lenderId: null,
		tpoId: '@tpoId',
	};

	constructor(
		public injector: Injector,
		public user: UserService,
        private http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.baseParams = {
			...this.baseParams,
			lenderId: user.profile.organization.id,
		};

		const documentsResourceConfig = {
			url: `${this.baseUrl}/docmanagement`,
			params: {
				...this.baseParams,
			},
		};
		const documentResourceConfig = {
			url: `${documentsResourceConfig.url}/:documentId`,
			params: {
				...documentsResourceConfig.params,
				documentId: '@documentId',
			},
		};
		const clientSpecificDocumentProps = {
			url: `${documentsResourceConfig.url}/client-documents/:documentId`,
			params: {
				...documentsResourceConfig.params,
				documentId: '@documentId',
			},
		};

		const transformChannelToChannelResource = ({
			id,
			name,
		}: Channel): ChannelResource => ({
			channelId: id,
			channelName: name,
		});
		const transformChannelResourceToChannel = ({
			channelId,
			channelName,
		}: ChannelResource): Channel => ({
			id: channelId,
			name: channelName,
		});
		const transformDocumentToDocumentResource = ({
			channelsSettings,
			...rest
		}: ClientDocumentDetails): ClientDocumentDetails<ChannelResource> => ({
			...rest,
			channelsSettings: channelsSettings.map(transformChannelToChannelResource),
		});
		const transformDocumentResourceToDocument = ({
			channelsSettings,
			...rest
		}: ClientDocumentDetails<ChannelResource>): ClientDocumentDetails => ({
			...rest,
			channelsSettings: channelsSettings.map(transformChannelResourceToChannel),
		});
		const transformDocumentFormData = ({
			templateFile,
			...rest
		}: ClientDocumentDetailsFormData) => {
			const formData = new FormData();
			const documentDetailsBlob = new Blob([ JSON.stringify(transformDocumentToDocumentResource(rest)) ], { type: 'application/json' });
			formData.append('documentString', documentDetailsBlob);
			if (templateFile) {
				formData.append('file', templateFile);
			}
			return formData;
		};

		this.documents = PagedResource(
			documentsResourceConfig.url,
			documentsResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				isEditable: {
					url: `${documentsResourceConfig.url}/editable`,
					method: 'GET',
					transformResponse: (value) => ({
						value: JSON.parse(value),
					}),
				},
				create: {
					url: `${documentsResourceConfig.url}/client-documents`,
					method: 'POST',
					headers: {
						'Content-Type': undefined,
					},
					transformRequest: transformDocumentFormData,
				},
				channels: {
					url: `${documentsResourceConfig.url}/channels`,
					method: 'GET',
					isArray: true,
				},
				questionnaires: {
					url: `${documentsResourceConfig.url}/questionnaires`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.document = $resource(
			documentResourceConfig.url,
			documentResourceConfig.params,
			{
				get: {
					method: 'GET',
				},
				request: {
					url: `${documentResourceConfig.url}/requested`,
					method: 'POST',
				},
				upload: {
					url: `${documentResourceConfig.url}/uploadedDocument`,
					...UploadResourceProps,
				},
				waive: {
					url: `${documentResourceConfig.url}/waived`,
					method: 'POST',
				},
				setChannel: {
					url: `${documentResourceConfig.url}/settings/:settingId`,
					method: 'PUT',
				},
			},
		);

		this.documentHistory = PagedResource(
			`${documentResourceConfig.url}/history`,
			{ ...documentResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				requests: {
					url: `${documentResourceConfig.url}/history/:historyId/request`,
					method: 'GET',
				},
				waive: {
					url: `${documentResourceConfig.url}/history/:historyId/waive`,
					method: 'GET',
				},
			},
		);

		this.clientSpecificDocument = $resource(
			clientSpecificDocumentProps.url,
			clientSpecificDocumentProps.params,
			{
				get: {
					method: 'GET',
					transformResponse: (data, getHeaders, code) => {
						if (code === 200) {
							return transformDocumentResourceToDocument(JSON.parse(data));
						} else if (code >= 400) {
							return JSON.parse(data);
						}
					},
				},
				update: {
					method: 'PUT',
					headers: {
						'Content-Type': undefined,
					},
					transformRequest: transformDocumentFormData,
					transformResponse: (data, getHeaders, code) => {
						if (code === 200) {
							return transformDocumentResourceToDocument(JSON.parse(data));
						} else if (code >= 400) {
							return JSON.parse(data);
						}
					},
				},
				discontinue: {
					method: 'DELETE',
				},
				upload: {
					url: `${clientSpecificDocumentProps.url}/tpodocument`,
					...UploadResourceProps,
				},
				request: {
					method: 'POST',
					url: `${clientSpecificDocumentProps.url}/request`,
				},
				setTemplate: {
					url: `${clientSpecificDocumentProps.url}/template`,
					...UploadResourceProps,
				},
				removeTemplate: {
					url: `${clientSpecificDocumentProps.url}/template`,
					method: 'DELETE',
				},
				addChannelSettings: {
					url: `${clientSpecificDocumentProps.url}/settings/`,
					method: 'PUT',
				},
				updateChannelSettings: {
					url: `${clientSpecificDocumentProps.url}/settings/:settingId`,
					method: 'PUT',
				},
			},
		);

		this.requestableDocuments = $resource(
			`${documentsResourceConfig.url}/requestable-documents`,
			{ ...documentsResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
					transformResponse: (data) => JSON.parse(data).map(transformDocumentResourceToDocument),
				},
			},
		);

		this.channels = $resource(
			`${apiPath}/lenders/:lenderId/channels/tpos/:tpoId/base/accessible`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.tpoUsers = $resource(
			`${documentsResourceConfig.url}/tpo-users`,
			{ ...documentsResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.tpoContacts = $resource(
			`${documentsResourceConfig.url}/tpo-contacts`,
			{ ...documentsResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.lenderUsers = $resource(
			`${documentsResourceConfig.url}/lender-users`,
			{ ...documentsResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// TODO: deprecated
		this.one = $resource(
			`${documentsResourceConfig.url}/:docType/:docId`,
			{ ...documentsResourceConfig.params },
			{
				get: {
					method: 'GET',
				},
			},
		);
	}

	getDocuments = (...args): NgResourceArray<ClientDocument> => this.documents.get(...args);
	getClientSpecificChannels = (...args): NgResourceArray<Entity> => this.documents.channels(...args);
	getClientSpecificQuestionnaires = (...args): NgResourceArray<Entity> => this.documents.questionnaires(...args);
	getDocument = (...args): NgResourceObject<ClientDocument> => this.document.get(...args);
	requestDocument = (...args): NgResourceArray<ClientDocument> => this.document.request(...args);
	getDocumentHistory = (...args): NgResourceArray<ClientHistoryDocument> => this.documentHistory.get(...args);
	getDocumentRequestsHistory = (...args): NgResourceObject<RequestData> => this.documentHistory.requests(...args);
	getDocumentWaiveHistory = (...args): NgResourceObject<WaiveData> => this.documentHistory.waive(...args);
	createClientSpecificDocument = (...args): NgResourceObject<ClientDocument> => this.documents.create(...args);
	getClientSpecificDocument = (...args): NgResourceObject<ClientDocumentDetails> => this.clientSpecificDocument.get(...args);
	updateClientSpecificDocument = (...args): NgResourceObject<ClientDocumentDetails> => this.clientSpecificDocument.update(...args);
	getRequestableDocuments = (...args): NgResourceArray<ClientDocumentDetails> => this.requestableDocuments.get(...args);
	getChannels = (...args): NgResourceArray<Channel> => this.channels.get(...args);
	getTpoUsers = (...args): NgResourceArray<any> => this.tpoUsers.get(...args);
	getTpoContacts = (...args): NgResourceArray<any> => this.tpoContacts.get(...args);
	getLenderUsers = (...args): NgResourceArray<any> => this.lenderUsers.get(...args);

    public getTpoNotificationUsers<T = IDocumentLenderContactsEntry>({ tpoId }): Observable<T[]> {
        const lenderId = this.user.profile.organization.id;

        return this.http.request<T[]>(
            'GET',
            `${apiPath}/v2/lenders/${lenderId}/tpos/${tpoId}/docmanagement/tpo-users`,
        )
    }

    public getLenderNotificationUsers<T = DocumentContactsEntry>({ tpoId }): Observable<T[]> {
        const lenderId = this.user.profile.organization.id;

        return this.http.request<T[]>(
            'GET',
            `${apiPath}/v2/lenders/${lenderId}/tpos/${tpoId}/docmanagement/lender-users`,
        )
    }

	/**
	 * @param {Object} params
	 * @param {number} [params.lenderId]
	 * @param {number} [params.tpoId]
	 * @param {number} [params.documentId]
	 * @param {number} [params.historyId]
	 */
	getDownloadLink = (params): string => {
		const { historyId } = params;
		return linkTemplate(
			`${this.baseUrl}/docmanagement/:documentId${historyId ? '/history/:historyId' : ''}/uploadedDocument`,
			{
				...this.baseParams,
				...params,
			},
		);
	};

    markCurrentDocumentAsReviewed(lenderId: number, tpoId: number, documentId: number): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/v2/lenders/${lenderId}/tpos/${tpoId}/docmanagement/${documentId}/review-status`
        )
    }

    markHistoricalDocumentAsReviewed(lenderId: number, tpoId: number, documentId: number, historyId: number): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/v2/lenders/${lenderId}/tpos/${tpoId}/docmanagement/${documentId}/review-status/history/${historyId}`
        )
    }
}
