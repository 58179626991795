<client-document-card
	[className]="className"
	[document]="document"
	[downloadLink]="downloadLink"
	(upload)=upload($event)
>
	<ng-template #indications>
		<div class="flex-row gap-sm">
			<ng-template [ngTemplateOutlet]="indicationsTemplate"></ng-template>

			<span
				*ngIf="!document.isActive"
				class="label label-danger-wired text-smaller"
			>Inactive</span>

			<span
				*ngIf="document.isExpired"
				class="label label-danger-wired text-smaller"
			>Expired</span>

			<a
				*ngIf="templateDownloadLink && document.hasTemplate"
				class="btn btn-tertiary"
				[href]="templateDownloadLink"
				target="_blank"
			>
				<span class="c-icon c-icon-file"></span> Template
			</a>

			<a
				class="btn btn-tertiary"
				(click)="showHistory()"
			>
				<span class="c-icon c-icon-back-in-time"></span> History
			</a>
		</div>
	</ng-template>

	<ng-template #actions>
		<ng-template [ngTemplateOutlet]="actionsTemplate"></ng-template>
	</ng-template>
</client-document-card>
