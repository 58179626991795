<h2>{{channel?.name ? channel.name + ' Invite History' : '&nbsp;'}}</h2>

<div class="document-history rows-bordered remote-resource" [class.resolved]="!resolving">
	<div class="row-fluid header">
		<sortable-header class="col-sm-4" columnName="" [active]="false">Date</sortable-header>
		<sortable-header class="col-sm-4" columnName="" [active]="false">Action</sortable-header>
		<sortable-header class="col-sm-4" columnName="" [active]="false">Action By</sortable-header>
	</div>
	<div class="row-fluid" *ngFor="let item of list | async">
		<div class="col-sm-4 va-m">
			{{item.actionAt | date : 'MM/dd/yyyy HH:mm'}}
		</div>
		<div class="col-sm-4 va-m">
            Invite {{STATUSES[item.inviteStatus]}}
		</div>
		<div class="col-sm-4 va-m">
			{{item.actionBy || '&ndash;'}}
		</div>
	</div>

	<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No records added</div>
	</div>

	<br>

	<paginator [sizes]="[10, 25, 50]"></paginator>
</div>
