<div class="gray-well flex-row" *ngFor="let item of requests$ | async">
    <div class="flex-grow">
        <div>
            <b>"{{ item.channelFrom.organization.organizationName }}"</b>
            would like to add NMLS ID
            <a uiSref="client.:id.account.information" [uiParams]="{ id : item.lender.tpoId }">{{ item.lender.nmlsId }}</a>
            to <b>"{{ item.channelTo.channelName }}"</b>
        </div>
        <div class="flex-row">
            <div class="flex-grow">
                <div class="control-value text-bigger">{{ item.lender.companyName }}</div>
                <div>{{ item.lender.companyAddress }}, {{ item.lender | address }}</div>
            </div>
            <div class="flex-grow">
                <div class="row control-value">
                    <label class="col-md-3 control-label">Current Status</label>
                    <div class="col-md-a text-bigger"><approval-status [status]="item.approvalStatus"></approval-status></div>
                </div>
                <div class="row">
                    <label class="col-md-3 control-label">Assigned</label>
                    <div class="col-md-a text-bigger">{{ item.assigned.name }}</div>
                </div>
            </div>
        </div>
    </div>
    <a class="btn btn-primary" (click)="reviewRequest(item)">Review</a>
</div>
