import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

// FIXME: should be replaced by AuditsService
@Injectable({
	providedIn: 'root',
})
export class ReviewsService {
	review: any;
	auditLog: any;

	constructor(
		injector: Injector,
		{ profile: User }: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const resourceConfig = {
			url: `${apiPath}${User.isTpo ? '/tpos/:tpoId' : ''}/social-media-compliance/review/:reviewId`,
			params: {
				...(User.isTpo && { tpoId: User.organization.id }),
			},
		};

		this.review = $resource(
			resourceConfig.url,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
				},
			},
		);

		this.auditLog = $resource(
			`${resourceConfig.url}/history`,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getReview = (...args) => this.review.get(...args);
	getAuditLog = (...args) => this.auditLog.get(...args);
}
