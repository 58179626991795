import { cacheEntities, cachesToSnapshot } from './cache-entities';
import { saveToStorage, getCacheName } from './helpers';

const SYNC_TIME = 5000;

class CacheSnapshots {
	releaseInterval;
	syncIndex: number = 0;

	restore() {
		this.releaseInterval = setInterval(this.sync, SYNC_TIME);
	}

	dispose() {
		if (!this.releaseInterval) {
			return;
		}

		clearInterval(this.releaseInterval);
	}

	sync = (): void => {
		if (!cachesToSnapshot.length || (cachesToSnapshot.length <= this.syncIndex)) {
			this.syncIndex = 0;
			return;
		}

		const cacheName = cachesToSnapshot[this.syncIndex];
		if (!cacheName) {
			/* tslint:disable-next-line no-console */
			console.warn(`CacheSnapshots: trying to get non-existing key: ${cacheName} for syncIndex: ${this.syncIndex}`);
			return;
		}

		const record = cacheEntities.get(cacheName);

		if (!record) {
			/* tslint:disable-next-line no-console */
			console.warn(`CacheSnapshots: cache is not defined for key: ${cacheName} for syncIndex: ${this.syncIndex}`);
			return;
		}

		const { cache, target, max } = record;

		if (target === 'in-memory') {
			return;
		}

		const dump = cache.dump();
		const storageKey = getCacheName(cacheName);

		saveToStorage(target, storageKey, { dump, target, max, name: cacheName });
	}
}

const realmCacheSnapshots = new CacheSnapshots();

export { realmCacheSnapshots };
