import { QuestionType } from 'lender/questionnaires/manage/questionnaires.interface';

export default [
	{
		id: QuestionType.YES_NO,
		title: 'Yes / No',
		skipLogic: true,
	},
	{
		id: QuestionType.SINGLE_SELECT,
		title: 'Pick List',
		skipLogic: true,
	},
	{
		id: QuestionType.MULTI_SELECT,
		title: 'Multiple Selection',
		skipLogic: false,
	},
	{
		id: QuestionType.FORM,
		title: 'Free Form',
		skipLogic: false,
	},
	{
		id: QuestionType.NUMBER,
		title: 'Number',
		skipLogic: false,
	},
	{
		id: QuestionType.USD,
		title: 'Dollar',
		skipLogic: false,
	},
	{
		id: QuestionType.PERCENT,
		title: 'Percent',
		skipLogic: false,
	},
	{
		id: QuestionType.DATE,
		title: 'Date',
		skipLogic: false,
	},
];
