<div class="gray-head">
	<h2>Application Codes</h2>
</div>
<div>&nbsp;
	<div class="pull-right" *ngIf="User?.can('MANAGE_APPLICATION_CODES')">
		<a class="btn btn-primary" (click)="createCode()">Create Code</a>
	</div>
</div>
<br/>
<br/>
<div class="rows-bordered remote-resource" [class.resolved]="appcodes && appcodes.$resolved">
	<div class="row-fluid header">
		<div class="col-md-3">
			<search-list placeholder="Search by Application Code"></search-list>
		</div>
		<span [ngClass]="['col-md-a', getSortClass('status')]" (click)="setSort('status')">
			<b class="two-liners">Code Status</b>
		</span>
		<span [ngClass]="['col-md-1', getSortClass('totalUsed')]" (click)="setSort('totalUsed')">
			<b class="two-liners">Total Used</b>
		</span>
		<span [ngClass]="['col-md-1', getSortClass('numberOfUses')]" (click)="setSort('numberOfUses')">
			<b class="two-liners">Number of Uses</b>
		</span>
		<span [ngClass]="['col-md-a', getSortClass('startDate')]" (click)="setSort('startDate')">
			<b class="two-liners">Start Date</b>
		</span>
		<span [ngClass]="['col-md-a', getSortClass('expirationDate')]" (click)="setSort('expirationDate')">
			<b class="two-liners">Expiration Date</b>
		</span>
		<span [ngClass]="['col-md-a', getSortClass('createdBy')]" (click)="setSort('createdBy')">
			<b class="two-liners">Created By</b>
		</span>
		<span [ngClass]="['col-md-a', getSortClass('createdDate')]" (click)="setSort('createdDate')">
			<b class="two-liners">Create Date</b>
		</span>
		<span class="col-md-a"></span>
	</div>
	<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
		<span class="col-sm-12 text-center">Filters are applied.
			<a (click)="resetFilters()">Reset filters</a> to see all results</span>
	</div>
	<div class="row-fluid" *ngFor="let item of appcodes">
		<span class="col-md-3 text-bigger">
			<strong class="appcode">{{item.appCode}}</strong>
			<br/>
			<span class="small text-muted">{{item.description}}</span>
		</span>
		<span class="col-md-a text-bigger">
			<span *ngIf="item.status === APPCODE.ACTIVE" class="label label-success">Active</span>
			<span *ngIf="item.status === APPCODE.EXPIRED" class="label label-default">Expired</span>
			<span *ngIf="item.status === APPCODE.FULFILLED" class="label label-default">Fulfilled</span>
			<span *ngIf="item.status === APPCODE.CANCELED" class="label label-warning">Canceled</span>
		</span>
		<span class="col-md-1 solid">
			<span class="badge badge-default">{{item.totalUsed || '0'}}</span>
		</span>
		<span class="col-md-1 solid">
			{{item.numberOfUses || '&ndash;'}}
		</span>
		<span class="col-md-a">
			{{item.startDate || '&ndash;' | date : 'MM/dd/yyyy'}}
		</span>
		<span class="col-md-a">
			{{item.expirationDate || '&ndash;' | date : 'MM/dd/yyyy'}}
		</span>
		<span class="col-md-a">
			{{item.createdBy || '&ndash;'}}
		</span>
		<span class="col-md-a">
			{{item.createdDate || '&ndash;' | date : 'MM/dd/yyyy HH:mm'}}
		</span>
		<span class="col-md-a">
			<a class="btn btn-primary" *ngIf="item.status == APPCODE.ACTIVE && User?.can('MANAGE_APPLICATION_CODES')" (click)="cancelCode(item)">Cancel</a>
		</span>
	</div>
	<div class="row-fluid" *ngIf="noMatches">
		<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
	</div>
	<div class="row-fluid" *ngIf="!appcodes.length && !noMatches">
		<span class="col-md-12 text-muted text-center text-bigger">No codes</span>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="appcodes" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>
