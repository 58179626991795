import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

// services
import { ContactService } from './contact.service';

// components
import { ContactListsComponent } from './lists';
import {
	ContactComplianceComponent,
	ContactComplianceAuditsListComponent,
	ContactComplianceFindingsListComponent,
} from './compliance';
import { NewContactsModule } from 'shared/new-contacts';
import { FindingSourceComponent } from 'shared/new-contacts/compliance/findings/finding-source/finding-source.component';

const components = [
	ContactListsComponent,
	ContactComplianceComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
        NewContactsModule,
	],
	declarations: [
		...components,
		ContactComplianceAuditsListComponent,
		ContactComplianceFindingsListComponent,
	],
	providers: [
		ContactService,
	],
})
export class ContactModule {}
