import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { QuestionnaireItem } from 'lender/questionnaires/manage/questionnaires.interface';


declare let apiPath;

@Injectable({ providedIn: 'root' })
export class OrganizationQuestionnaireService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getOrganizationQuestionnaires(organizationId: number): Observable<QuestionnaireItem[]> {
        return this.http.request<QuestionnaireItem[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/questionnaires/full-list`,
        );
    }
}