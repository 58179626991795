<div>
    <div class="rows-bordered remote-resource" [class.resolved]="!resolving">
        <div class="row-fluid header">
            <span class="col-md-5"><b>Clients Name</b></span>
            <span class="col-md-a"><b>Headquarters</b></span>
            <span class="col-md-a text-center"><b>Locations</b></span>
            <span class="col-md-a text-center"><b>Originators</b></span>
            <span class="col-md-a text-center" *ngIf="userIsLenderAndCrmEnabled"><b>Prospects</b></span>
            <ng-container *ngIf="userIsLender">
                <span class="col-md-1"></span>
                <span class="col-md-1"></span>
            </ng-container>
        </div>

        <div class="row-fluid search" *ngFor="let client of list | async">
			<span class="col-md-5 row client">
				<div class="col-md-1 logo">
					<div *ngIf="client.isRegistered" class="logo-image">
					</div>
				</div>
				<div class="col-md-11 name">
					<a *ngIf="client.tpoId" uiSref="{{userIsLender ? 'client' : 'tpo'}}.:id.account.information"
                       [uiParams]="{ id: client.tpoId }"
                       class="text-bigger" target="_blank">{{client.companyName}}</a>
					<a *ngIf="!client.tpoId" uiSref="nmls.client.:id.account.information"
                       [uiParams]="{ id: client.companyNmlsId }"
                       class="text-bigger" target="_blank">
                        {{client.companyName}}</a>
					<br>
					<span class="text-light">NMLS ID:{{client.companyNmlsId}}</span>
				</div>
			</span>
            <span class="col-md-a">{{client.headquarters || '&ndash;'}}</span>
            <span class="col-md-a text-center">{{client.locationsCount}}</span>
            <span class="col-md-a text-center">{{client.originatorsCount}}</span>
            <span class="col-md-a text-center" *ngIf="userIsLenderAndCrmEnabled">
				{{client.prospectCount}}&nbsp;&nbsp;
				<a *ngIf="client.tpoId" uiSref="client.:id.account.locations"
                   [uiParams]="{ id: client.tpoId }"
                   target="_blank">View</a>
				<a *ngIf="!client.tpoId" uiSref="nmls.client.:id.account.locations"
                   [uiParams]="{ id: client.companyNmlsId }" target="_blank">View</a>
			</span>
            <span class="col-md-1" *ngIf="userIsLender">
				<div *ngIf="client.hasRelationship">
					<span class="c-icon c-icon-users"></span>
				</div>
			</span>
            <span class="col-md-1" *ngIf="userIsLender">
				<a uiSref="client.:id.account.channels"
                   [uiParams]="{ id: client.tpoId }"
                   class="btn btn-default" *ngIf="client.isRegistered && userCanInviteToApply" target="_blank">Invite</a>
			</span>
        </div>
        <div class="row-fluid" *ngIf="(list | async)?.length == 0">
            <span class="col-md-12 text-muted text-center text-bigger">No clients found</span>
        </div>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
