import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FindingAlertStatusModalComponent } from 'shared/due-diligence/scorecard/scorecard-findings-summary/finding-alert-status-modal/finding-alert-status-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReviewAlertInformation } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-finding-summary.interface';
import { ScorecardMarkReviewedService } from 'shared/due-diligence/scorecard/services/scorecard-mark-reviewed-service';
import { ScorecardFindingReviewerInfo } from '../scorecard-finding.interface';


@Component({
    selector: 'scorecard-finding-summary-mark-reviewed',
    templateUrl: 'scorecard-finding-summary-mark-reviewed.component.html',
})
export class ScorecardFindingSummaryMarkReviewedComponent {
    @Input() isUserCommergence: boolean;
    @Input() hasReviewFindingPermission: boolean;
    @Input() findingId: (string | number);
    @Input() isUpdated: boolean;
    @Input() reviewed: boolean;
    @Input() reviewer: ScorecardFindingReviewerInfo;
    @Input() tpoId: number;
    @Input() lenderId: number;
    @Input() isUserLender: boolean;
    @Input() hasEditAndViewAlertPermission: boolean;
    @Output() resolving = new EventEmitter<boolean>();
    @Output() findingReviewed = new EventEmitter<boolean>();

    constructor(
        private readonly scorecardMarkReviewedService: ScorecardMarkReviewedService,
        private readonly modalService: BsModalService,
    ) {
    }

    async markReviewed() {
        try {
            this.resolving.emit(true);
            const alertandReviewInfo: ReviewAlertInformation = await this.scorecardMarkReviewedService
                .markReviewAndGetAlertInformation(this.tpoId, this.findingId)
                .toPromise();
            if (alertandReviewInfo.alert.alertStatus && this.isUserLender && this.hasEditAndViewAlertPermission) {
                this.openAlertStatusUpdateModal(this.findingId, alertandReviewInfo.alert.alertStatus);
            } else {
                this.findingReviewed.emit(true);
            }
        } finally {
            this.resolving.emit(false);
        }
    }

    openAlertStatusUpdateModal(findingId: (string | number), alertCurrentStatus: string) {
        const initialState = {
            findingId: findingId,
            currentAlertStatus: alertCurrentStatus,
            lenderId: this.lenderId,
            callbackOnSave: () => {
                this.doSave();
            },
        };
        this.modalService.show(
            FindingAlertStatusModalComponent,
            {
                initialState,
                class: 'modal-md modal-new',
            },
        );
    }

    doSave() {
        this.findingReviewed.emit(true);
    }
}
