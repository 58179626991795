import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

import { NgResourceObject } from 'commons/interfaces';

import { RequestData, modalTitles } from '../../../documents/client-documents.interface';

@Component({
	templateUrl: './request-document-history-modal.component.html',
})
export class RequestDocumentHistoryModalComponent {
	snapshotTitles: modalTitles;
	snapshotData: NgResourceObject<RequestData>;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			resolving: false,
			snapshotTitles: {
				toClient: 'To: Client',
				ccInternal: 'CC: Internal',
				message: 'Message',
				notifyCompletion: 'Notify upon completion',
				toInternal: 'To: Internal',
			},
			snapshotData: {
				toTpoUsers: [],
				ccLenderUsers: [],
				message: '',
				notifyUponCompletionLenderUsers: [],
			},
		});
	}
}
