import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

import { NgResourceObject } from 'commons/interfaces';
import { QuestionnaireDocument, DocumentName } from '../../questionnaires.interface';

@Component({
	templateUrl: './questionnaire-document.component.html',
	selector: 'questionnaire-document',
})
export class QuestionnaireDocumentComponent {
	@Input() document: NgResourceObject<QuestionnaireDocument>;
	@Input() canRemove: boolean = true;
	@Output() removeClicked = new EventEmitter<number>();

	documentName = DocumentName;

	constructor(protected questionnairesService: LenderManageQuestionnaireService) {}

	get documentTemplateLink(): string {
		return this.questionnairesService.getDocumentTemplateLink(this.document.investorClientDocumentId);
	}

	remove(investorClientDocumentId: number): void {
		this.removeClicked.emit(investorClientDocumentId);
	}
}
