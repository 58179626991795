<div class="channel-card" *ngIf="channel">
    <div class="card-heading container" (click)="showCardContent()" [class.collapsed]="!showContent">
        <div class="flex-row">
            <h3 class="flex-grow">
                {{ channel.channelName }}
            </h3>
            <div class="flex-row gap-sm">
                <dot-menu
                    [menuItems]="channelMenuItems"
                ></dot-menu>
                <span class="glyphicon glyphicon-chevron-down"></span>
            </div>
        </div>
    </div>
    <div class="container remote-resource" [class.resolved]="resolved">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <label class="control-label">Relationship Status</label>
                </div>
                <div class="col-sm-6">
                    <label class="control-label">Status Date</label>
                </div>
            </div>
            <div class="row" [ngSwitch]="channel.hasActiveRelationshipInOtherChannel && showContent && !canEditApprovalStatus">
                <ng-container *ngSwitchCase="true">
                    <div class="col-sm-6">
                        <div class="flex-row align-top">
                            <div class="flex-grow">
                                <div [ngClass]="getLabel(channel.approvalStatus)">
                                    {{channel.approvalStatus?.name || '&ndash;'}}
                                </div>
                            </div>
                            <button *ngIf="hasEditPermission && channel.invitable && !channel.approvalStatus" class="btn btn-icon btn-tertiary" (click)="showEstablishRSModal()">
                                <span class="glyphicon glyphicon-pencil"></span>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault [ngSwitch]="editingStatus">
                    <div class="col-sm-6" *ngSwitchDefault>
                        <div [ngClass]="getLabel(channel.approvalStatus)">
                            {{channel.approvalStatus?.name || '&ndash;'}}
                        </div>
                        <button class="btn btn-icon btn-tertiary pull-right" *ngIf="canEditApprovalStatus" (click)="toggleEditingStatus()">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                    </div>
                    <div class="col-sm-6" *ngSwitchCase="true">
                        <div class="row">
                            <div class="col-xs-8">
                                <ng-select
                                    [items]="availableStatuses"
                                    bindLabel="name"
                                    [clearable]="false"
                                    placeholder="Select Status"
                                    [(ngModel)]="newApprovalStatus"
                                ></ng-select>
                            </div>
                            <div class="col-xs-4" style="display: flex;">
                                <button class="btn btn-icon btn-tertiary" (click)="saveApprovalStatus(newApprovalStatus)">
                                    <span class="glyphicon glyphicon-ok"></span>
                                </button>
                                <button class="btn btn-tertiary btn-icon" (click)="cancelStatus()">
                                    <span class="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="col-sm-6">
                    {{(channel.approvalStatusChangedDate | date: 'MM/dd/yyyy') || '&ndash;'}}
                </div>
            </div>
            <div *ngIf="showContent" @showSimple>
                <hr>
                <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label">Invite Status</label>
                    </div>
                    <div class="col-sm-6">
                        <label class="control-label">Renewal Date</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                    <span *ngIf="channel.inviteStatus">
                        <a uiSref="client.:id.account.channels.invite.:channelId.history" [uiParams]="{ channelId: channel.channelId, id: clientId }">
                            {{channel.inviteStatus.name}}
                        </a>
                    </span>
                        <span *ngIf="!channel.inviteStatus">
                        &ndash;
                    </span>
                    </div>
                    <div class="col-sm-6" *ngIf="!editingRenewalDate">
                        {{(channel.approvalRenewalDate | date: 'MM/dd/yyyy') || '&ndash;'}}
                        <button class="btn btn-icon btn-tertiary pull-right" *ngIf="canEditRenewalDate" (click)="toggleEditingRenewalDate()">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                    </div>
                    <div class="col-sm-6" *ngIf="editingRenewalDate">
                        <div class="row">
                            <div class="col-xs-8">
                                <input bsDatepicker
                                       class="form-control"
                                       [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                                       [minDate]="minDate"
                                       placeholder="mm/dd/yyyy"
                                       [(ngModel)]="newRenewalDate"
                                >
                            </div>
                            <div class="col-xs-2" style="display: flex;">
                                <button class="btn btn-icon btn-tertiary"
                                        (click)="saveField('approvalRenewalDate', formatDate(newRenewalDate))">
                                    <span class="glyphicon glyphicon-ok"></span>
                                </button>
                                <button class="btn btn-icon btn-tertiary" (click)="cancelRenewalDate()">
                                    <span class="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label">Assigned</label>
                    </div>
                    <div class="col-sm-6">
                        <label class="control-label">Status Reason</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6" *ngIf="!editingAccountExecutive">
                        {{channel.accountExecutive.name || '&ndash;'}}
                        <button class="btn btn-icon btn-tertiary pull-right" *ngIf="canEdit" (click)="toggleEditingAccountExecutive()">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                    </div>
                    <div class="col-sm-6" *ngIf="editingAccountExecutive">
                        <div class="row">
                            <div class="col-xs-8">
                                <ng-select [items]="accountExecutives"
                                           bindLabel="fullName"
                                           [clearable]="false"
                                           placeholder="Select Account Executive"
                                           [(ngModel)]="newAccountExecutive"
                                           [compareWith]="compareAccountExecutives"
                                >
                                </ng-select>
                            </div>
                            <div class="col-xs-2" style="display: flex;">
                                <button class="btn btn-icon btn-tertiary" (click)="confirmAssigneeUpdate()">
                                    <span class="glyphicon glyphicon-ok"></span>
                                </button>
                                <button class="btn btn-icon btn-tertiary" (click)="cancelAccountExecutive()">
                                    <span class="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <status-reason
                            [channelId]="channel.channelId"
                            [tpoId]="tpoId"
                            [status]="channel.statusReason"
                            [inlineEditStyle]="true"
                            (onUpdate)="saveStatusReason($event)"
                            [canEdit]="canEdit"
                        ></status-reason>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label">Channel UID</label>
                    </div>
                    <div class="col-sm-6">
                        <label class="control-label">Channel SID</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div
                            [popover-edit]
                            [value]="channel.channelUID"
                            [fieldName]="'channelUID'"
                            [readOnly]="!canEdit"
                            [maxlength]="20"
                            [viewControlClasses]="'col-sm-2'"
                            [requiredValidator]="false"
                            (saveTriggered)="saveChannelUID($event)"
                        >
                            {{channel.channelUID || '&ndash;'}}
                        </div>
                    </div>
                    <div class="col-sm-6">
                        {{channel.channelSID || '&ndash;'}}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label">Eligible to Apply</label>
                    </div>
                    <div class="col-sm-6">
                        <label class="control-label">Prospecting</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div
                            [inline-date-edit]
                            [fieldName]="'eligibilityDate'"
                            [minDate]="tomorrowDate"
                            [readOnly]="!canEditEligibilityDate"
                            [value]="channel.eligibilityDate"
                            [viewControlClasses]="'col-xs-8'"
                            (saveTriggered)="saveEligibilityDate($event)"
                        >
                            {{ (channel.eligibilityDate | date: 'MM/dd/yyyy') || '&ndash;' }}
                        </div>
                    </div>
                    <div class="col-sm-6" [ngSwitch]="channel.prospectStatusForUser">
                        <prospecting-status *ngSwitchDefault [canAdd]="false" [prospectInfo]="channel" class="control-value"></prospecting-status>
                        <span *ngSwitchCase="'NONE'" class="control-value">&ndash;</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
