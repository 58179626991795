import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TpoChannelOverview } from 'comergence/customers/tpo-services/tpo-service.interface';
import { TpoAddEditChannelModalComponent } from 'comergence/customers/tpo-services/tpo-add-edit-channel-modal.component';
import { ThirdPartyOversightService } from 'comergence/customers/services/customer-services/third-party-oversight.service';
import { UIRouterGlobals } from '@uirouter/core';
import { ConfirmModalComponent } from 'commons/components/modals';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShareChannelModalComponent } from 'comergence/customers/tpo-services/share/share-channel-modal.component';

@Component({
    templateUrl: 'tpo-channel-information.component.html',
    selector: 'tpo-channel-information',
})

export class TpoChannelInformationComponent implements OnInit {

    customerType: string;
    organizationId: number;
    channels: TpoChannelOverview[];
    resolving: boolean;
    bsModalRef: BsModalRef;

    @Input('reload')
    reload$ : Subject<void>;

    @Input() canManageCustomers: boolean;

    private readonly destroy$: Subject<void> = new Subject();

    constructor(
        public bsModalService: BsModalService,
        public tpoService: ThirdPartyOversightService,
        public globals: UIRouterGlobals,
        public modalService: BsModalService,
    ) {
        this.customerType = this.globals.params.customerType;
        this.organizationId = this.globals.params.organizationId;
    }

    ngOnInit() {
        this.loadChannels();
        this.reload$.pipe(takeUntil(this.destroy$)).subscribe( () => {
            this.loadChannels();
        })

    }

    async loadChannels() {
        try {
            this.resolving = true;
            this.channels = await this.tpoService.getTpoChannelsList(this.organizationId).toPromise();
        } finally {
            this.resolving = false;
        }
    }

    openEditModal(channelId) {
        const initialState = {
            channelId: channelId,
            organizationId: this.organizationId,
            customerType: this.customerType,
            callbackOnSave: () => {
                this.loadChannels();
            },
        };
        this.bsModalService.show(
            TpoAddEditChannelModalComponent,
            {
                initialState,
                class: 'modal-md modal-new',
            });
    }

    setChannelStatus(isActive, channelId) {
        const getStatus = (isActive ? 'Deactivate' : 'Activate');
        const initialState = {
            title: `${getStatus} Channel`,
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            message: `Are you sure you want to ${getStatus} this Channel? `,
            onConfirm: async (): Promise<void> => {
                this.bsModalRef.content.resolving = true;
                try {
                    if(isActive) {
                        await this.tpoService.deactivateChannel(this.organizationId, channelId).toPromise();
                    }
                    else {
                        await this.tpoService.activateChannel(this.organizationId, channelId).toPromise();
                    }
                }
                catch ({ error: { message }, message: httpError }) {
                    this.bsModalRef.content.errorText = message;
                }
                finally {
                    this.bsModalRef.content.resolving = false;
                    this.bsModalRef.hide();
                    this.loadChannels();
                }
            },
        };
        this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    shareChannel(channelId) {
        const { organizationId } = this;
        const initialState: Partial<ShareChannelModalComponent> = {
            organizationId,
            channelId,
        };
        this.bsModalService.show(
            ShareChannelModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
