import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable({
	providedIn: 'root',
})
export class SharedSocialMediaService {
	mediaLinks: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');

		const mediaLinksConfig = {
			url: `${apiPath}/:mediaType/links/:entityIdType/:entityId`,
			params: {},
		};

		const mediaLinkConfig = {
			url: `${mediaLinksConfig.url}/:mediaLinkId`,
			params: {
				...mediaLinksConfig.params,
			},
		};

		this.mediaLinks = PagedResource(
			mediaLinksConfig.url,
			mediaLinksConfig.params,
			{
				add: {
					method: 'POST',
				},
				remove: {
					url: mediaLinkConfig.url,
					params: mediaLinkConfig.params,
					method: 'DELETE',
				},
				acceptRemoval: {
					url: `${mediaLinkConfig.url}/removal-acceptance`,
					params: mediaLinkConfig.params,
					method: 'PUT',
				},
				declineRemoval: {
					url: `${mediaLinkConfig.url}/removal-denial`,
					params: mediaLinkConfig.params,
					method: 'PUT',
				},
				restore: {
					url: `${mediaLinkConfig.url}/restore`,
					params: mediaLinkConfig.params,
					method: 'PUT',
				},
				addRss: {
					url: `${mediaLinkConfig.url}/rss`,
					params: mediaLinkConfig.params,
					method: 'POST',
				},
				removeRss: {
					url: `${mediaLinkConfig.url}/rss`,
					params: mediaLinkConfig.params,
					method: 'DELETE',
				},
			},
		);
	}
}
