<div class="channel-relationship">
	<div class="clearfix tabbed-header">
		<h2>Application Status History</h2>
	</div>
	<br/>
	<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
		<div class="row-fluid header">
			<span class="col-sm-3">
				<b>Date</b>
			</span>
			<span class="col-sm-5">
				<b>Action</b>
			</span>
			<span class="col-sm-3">
				<b>Action By</b>
			</span>
		</div><!--end:header-->
		<div class="row-fluid" *ngFor="let item of list | async"> <!--(| orderBy:'-activityDateTime')-->
			<span class="col-sm-3">
				{{item.activityDateTime | date : 'MM/dd/yyyy HH:mm'}}
			</span>
			<span class="col-sm-5">
				{{item.infos[0].description}}
			</span>
			<span class="col-sm-3">
				{{item.actionBy.fullName}}
			</span>
		</div>
	</div>
</div>
