<div class="individuals-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Individuals</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<header class="row text-muted">
			<div class="col-md-8">Name</div>
			<div class="col-md-4">NMLS</div>
		</header>
		<div class="text-bigger">
			<div class="row" *ngFor="let owner of owners">
				<div class="col-md-8">
					<a uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: owner?.contactId }"
					   target="_blank">{{owner?.contactFullName}}
					</a>
				</div>
				<div class="col-md-4">
					<a uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: owner?.contactId }"
					   *ngIf="owner?.nmlsId" target="_blank"> {{owner.nmlsId}}
					</a>
					{{!owner?.nmlsId ? '&ndash;' : ''}}
				</div>
			</div>
			<div class="row"></div>
		</div>
	</div>
</div>
