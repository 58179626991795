import { Component, Input, AfterViewInit, Injector, Host, OnInit, forwardRef } from '@angular/core';
import { Validators } from '@angular/forms';

import { StateService, UIRouter } from '@uirouter/core';

import { RecurlyService } from 'commons/recurly';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { AppChannel, TpoApplicationService } from './application.service';
import { ApplicationCommonComponent } from 'tpo/application/common/application-common.component';

@Component({
	templateUrl: './app-payment.component.html',
})
export class AppPaymentComponent implements AfterViewInit, OnInit {
	@Input() recurlyConfig;
    appChannel: AppChannel;
	isPaying: boolean = false;
	isPaid: boolean = false;
	isAppCodePaying: boolean = false;
	payWith: 'CODE' | 'CARD' = null;

	appCodeForm: RealmFormGroup = new RealmFormGroup({
		appCode: new RealmFormControl(
			'appCode',
			{
				label: 'Application Code',
				updateOn: 'change',
			},
			Validators.required,
		),
	});

	paymentForm: RealmFormGroup = new RealmFormGroup({
		tokenId: new RealmFormControl(
			'tokenId',
			{
				label: 'Token',
			},
		),
		card: new RealmFormControl(
			'card',
			{
				label: 'Card',
				updateOn: 'change',
			},
		),
		first_name: new RealmFormControl(
			'first_name',
			{
				label: 'First Name',
			},
			Validators.required,
		),
		last_name: new RealmFormControl(
			'last_name',
			{
				label: 'Last Name',
			},
			Validators.required,
		),
	});
	constructor(
        protected readonly appComponent: ApplicationCommonComponent,
		private readonly router: UIRouter,
		private readonly recurly: RecurlyService,
		private readonly applicationService: TpoApplicationService,
	) {
	}

    async ngOnInit() {
        this.appChannel = await this.appComponent.application;
        if (this.appChannel.paid) {
            return this.appComponent.loadApplication();
        }
    }

    next() {
		this.appComponent.loadApplication();
	}

	cleanInfo() {
		this.appCodeForm.reset();
		this.paymentForm.reset();
	}

	ngAfterViewInit() {
        // This configure has to be called then actual <form> element was already rendered
		this.recurly.configure(this.recurlyConfig);

		this.appCodeForm.valueChanges.subscribe((form) => {
			const hasValue = form.appCode;
			if (hasValue && !this.payWith) {
				this.paymentForm.reset();
			}

			this.payWith = hasValue ? 'CODE' : null;
		});

		this.paymentForm.valueChanges.subscribe((form) => {
			const hasValue = form.card || form.first_name || form.last_name;

			if (hasValue) {
				this.appCodeForm.reset();
			}

			this.payWith = hasValue ? 'CARD' : null;
		});
	}

    get channelId(): number {
        return this.router.globals.params.channelId;
    }

    async payWithAppCode(): Promise<void> {
		this.isAppCodePaying = true;
        try {
            await this.applicationService.savePayment(this.channelId, this.appCodeForm.value).toPromise();
            this.next();
        } catch ({ error }) {
            this.appCodeForm.setServerError(error);
        } finally {
            this.isAppCodePaying = false;
        }
	}

	async pay(): Promise<void> {
        const { tokenId } = this.paymentForm.value;
        this.isPaying = true;
        try {
            await this.applicationService.savePayment(this.channelId, { tokenId }).toPromise();
            this.next();
        } catch ({ error }) {
            this.paymentForm.setServerError(error);
        } finally {
            this.isPaying = false;
        }
	}
}
