import { Component, Input } from '@angular/core';

import { Question } from '../../../questionnaires.interface';

@Component({
	selector: 'questions-group',
	templateUrl: './questions-group.component.html',
})
export class QuestionsGroupComponent {
	@Input() group: Question;
}
