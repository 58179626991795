import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';

declare let apiPath: string;

export enum PROFILE_SECTION {
    AFFILIATES = 'AF',
    INVESTORS = 'IV',
    WAREHOUSE = 'WH',
    AGENCY = 'AG',
    INSURANCE_BONDS = 'IB',
    UNDERWRITING = 'UW',
    TECHNOLOGY_OPERATIONS = 'TO',
}

export type SectionReview = {
    expired: boolean;
    reviewDate: number;
    reviewer: string;
}

@Injectable()
export class LenderSectionReviewService {
    constructor(
        private readonly userService: UserService,
        private readonly http: RealmHttpClient,
    ) {
    }

    private get organizationId(): number {
        return this.userService.profile.organization.organizationId;
    }

    getSection(code: PROFILE_SECTION, organizationId = this.organizationId): Observable<SectionReview> {
        return this.http.request<SectionReview>(
            'GET',
            `${apiPath}/organizations/${organizationId}/profile-review/${code}`,
        );
    }

    updateSection(code: PROFILE_SECTION, organizationId = this.organizationId): Observable<SectionReview> {
        return this.http.request<SectionReview>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/profile-review/${code}`,
        );
    }
}
