<div class="gray-head flex-head">
	<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
		<span>{{title}}</span>
	</back-bread>
</div>

<div class="document-history rows-bordered remote-resource" [class.resolved]="!resolving">
	<div class="row-fluid header">
		<div class="col-sm-3"><b class="two-liners">Date</b></div>
		<div class="col-sm-3"><b class="two-liners">Action</b></div>
		<div class="col-sm-3"><b class="two-liners">Action by</b></div>
		<div class="col-sm-3"><b class="two-liners">Document</b></div>
        <div class="col-sm-3" *ngIf="lenderId"><b class="two-liners">Last Reviewed</b></div>
	</div>
	<div class="row-fluid" *ngFor="let item of list | async">
		<div class="col-sm-3 va-m">{{item.date | date : 'MM/dd/yyyy HH:mm'}}</div>
		<div class="col-sm-3 va-m">
			<ng-container [ngSwitch]="true">
				<a *ngSwitchCase="item.canViewRequestDetails" (click)="showRequestHistoryModal(item)">{{item.action}}</a>
				<a *ngSwitchCase="item.canViewWaiveDetail" (click)="showWaiveHistoryModal(item)">{{item.action}}</a>
				<ng-container *ngSwitchDefault>{{item.action}}</ng-container>
			</ng-container>
		</div>
		<div class="col-sm-3 va-m">{{item.actionBy  || '&ndash;'}}</div>
		<div class="col-sm-3 va-m" *ngIf="User.isTpo; else link">{{item.fileName || '&ndash;'}}</div>
		<ng-template #link>
			<div class="col-sm-3 va-m">
				<a *ngIf="item.fileName; else dash" [href]="getDownloadLink(item)">{{item.fileName}}</a>
				<ng-template #dash>&ndash;</ng-template>
			</div>
		</ng-template>
        <div class="col-sm-3 va-m" *ngIf="lenderId">
            <span *ngIf="item.financialDocumentId && !item.reviewedBy && hasRequestPermission" class="btn btn-primary" (click)="markAsReviewed(item)">Mark Reviewed</span>
            <div *ngIf="item.financialDocumentId && item.reviewedBy">
                <span class="col-sm-2">
                    <div>&nbsp;</div>
                    <div class="glyphicon glyphicon-ok-sign glyphicon-object-align-vertical text-success medium-icon"></div>
                </span>
                <span class="col-sm-10">
                    <div class="text-small font-bold">Reviewed by</div>
                    <div class="text-small text-muted">{{item.reviewedBy}}</div>
                    <div class="text-small text-muted">{{item.reviewedDate | date : 'MM/dd/yyyy HH:mm'}}</div>
                </span>
            </div>
        </div>
	</div>

	<div class="row-fluid no-records" *ngIf="!resolving && !(list | async)?.length">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No records</div>
	</div>

	<br>

	<paginator [sizes]="[10, 25, 50]"></paginator>
</div>
