import { find } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { OwnershipVerificationModalComponent } from 'tpo/home/modals/ownership-verification';

type ListsToLoad = 'share' | 'invites' | 'documents' | 'questionnaires' | 'applications';

const sectionsToCheck: ListsToLoad[] = [ 'share', 'invites', 'documents', 'questionnaires', 'applications' ];

@Component({
	templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit{
	User: UserProfile;

	nextToLoad: ListsToLoad | null = null;
	loadedLists: Record<ListsToLoad, boolean> = {
        share: false,
        invites: false,
        documents: false,
        questionnaires: false,
        applications: false,
	};

	listPermissions: Record<ListsToLoad, boolean> = {
        share: false,
        invites: false,
        documents: false,
        questionnaires: false,
        applications: false,
	};

	constructor(
		userService: UserService,
		private modalService: BsModalService,
	) {
		this.User = userService.profile;

		this.listPermissions = {
            share: this.User?.can('TPO_APPLICATIONS'),
            invites: this.User?.can('TPO_APPLICATIONS') && !this.User?.ownershipVerificationRequired,
            documents: this.User?.can('TPO_DOCUMENTS') && this.User?.hasOutstandingRequiredDocuments,
            questionnaires: this.User?.can('TPO_APPLICATIONS'),
            applications: this.User?.can('TPO_APPLICATIONS') && !this.User?.ownershipVerificationRequired,
		};

		// in case when no section allowed, remove loader right away
		this.startToLoadNextSection();
	}

	ngOnInit(): void {
		if (this.User.ownershipVerificationRequired) {
			this.modalService.show(
				OwnershipVerificationModalComponent,
				{
					class: 'modal-md modal-new verify-ownership',
					backdrop: 'static',
					ignoreBackdropClick: true,
					keyboard: false,
				}
			);
		}
	}

	setListLoaded = (key: ListsToLoad, value: boolean): void => {
		if (!value) {
			return;
		}

		this.loadedLists[key] = value;

		this.startToLoadNextSection();
	}

	startToLoadNextSection = (): void => {
		const notLoadedYet = find(sectionsToCheck, (key) => {
			return this.listPermissions[key] && !this.loadedLists[key];
		});

		this.nextToLoad = notLoadedYet ?? null;
	}

	isSectionEnabled = (sectionName: ListsToLoad): boolean => {
		return this.listPermissions[sectionName] && (this.loadedLists[sectionName] || this.nextToLoad === sectionName);
	}
}
