<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
    <div class="row-fluid header">
        <div class="col-md-4">
            <b>Individual Name</b>
        </div>
        <div class="col-md-2">
            <b class="two-liners">Individual NMLS ID</b>
        </div>
        <div class="col-md-2">
            <b class="two-liners">Licenses</b>
        </div>
        <div class="col-md-4">
            <b class="two-liners">Federal Registration</b>
        </div>
    </div>
    <div *ngIf="!resolving && ((list | async)?.length > 0); else: noIndividuals">
        <div class="row-fluid client table-row" *ngFor="let item of list | async" >
            <div class="col-md-4 row ">
                <div class="text-bigger text-muted">{{item.fullName || '&ndash;'}}</div>
            </div>
            <div class="col-md-2 solid">
                <a *ngIf="item.individualNmlsId"
                   target="_blank"
                   uiSref="nmls.individual.:nmlsId"
                   [uiParams]="{ nmlsId: item.individualNmlsId }"
                >
                <span>{{item.individualNmlsId || '&ndash;'}}</span>
                </a>
            </div>
            <div class="col-md-2 solid-word">
                <span>{{item.licenseCount || 0}}</span>
            </div>
            <div class="col-md-4">
                <span>{{item.isFederallyRegistered? 'Yes' : 'No' || '&ndash;'}}</span>
            </div>
        </div>
    </div>
    <ng-template #noIndividuals>
        <div class="row-fluid">
            <span class="col-md-12 text-muted text-center text-bigger">No individuals found</span>
        </div>
    </ng-template>
    <br>
    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>
