import { isFunction } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Please enter a valid percent between 0 and 100';

type NumberTemplateStringFn = (value: string | number | null) => string;

export function PercentageValidator(messageTemplate: string | NumberTemplateStringFn = ''): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
        const isValid = !value || (value >= 0 && value <= 100);
        const message = isFunction(messageTemplate) ? messageTemplate(value) : messageTemplate;

        return isValid ? null : {
            num: {
                value,
                message: message || defaultMessage,
            },
        };
    };
}
