import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './approve-modal.component.html',
})

export class ApproveLibraryDocumentReplaceModalComponent {
	fromDoc: any = {};
	toDoc: any = {};
	onConfirm: (params?) => any;

	constructor(
		public modalRef: BsModalRef
	) {}
}
