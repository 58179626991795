export enum LicenseClass {
	Company = 'COMPANY',
	Individual = 'INDIVIDUAL',
}

export interface License {
	licenseClass: LicenseClass;
	licenseType: string;
	licenseNumber: string;
	name: string;
	status: string;
	isAuthorized: boolean;
	regulator: string;
	branchNmlsId: string;
	companyNmlsId?: string;
	individualNmlsId?: string;
	renewedThrough: string;
	issueDate: number;
	statusDate: number;
}
