<ng-container [ngSwitch]="hasAccess">
	<ng-template [ngSwitchCase]="true">
		<h2 *ngIf="newMenu">Inventory</h2>
		<section class="social-accounts-inventory remote-resource" [class.resolved]="list?.$resolved">
			<div class="filters-rows">
				<div class="row-fluid filter-labels">
					<div class="col-sm-2">
						<span class="text-muted">Search</span>
					</div>
					<div class="col-sm-2">
						<span class="text-muted">State</span>
					</div>
					<div class="col-sm-2">
						<span class="text-muted">Branch</span>
					</div>
					<div class="col-sm-2">
						<span class="text-muted">Account Type</span>
					</div>
					<div class="col-sm-2">
						<span class="text-muted">Entity</span>
					</div>
					<div class="col-sm-a"></div>
				</div>
				<div class="row-fluid filter-selectors">
					<div class="col-sm-2">
						<search-list placeholder="Name or NMLS ID" [callback]="setExportStatusToFalse"></search-list>
					</div>
					<div class="col-sm-2 remote-resource" [class.resolved]="filters.states.$resolved" [class.curtain]="!hasIndividual">
						<ng-select
								[ngModel]="params.states"
								(change)="setFilter({ states: $event })"
								[items]="filters.states.$promise | async"
								bindValue="shortName"
								bindLabel="name"
								[multiple]="true"
								[clearable]="true"
								placeholder="All States"
						>
						</ng-select>
					</div>
					<div class="col-sm-2 remote-resource" [class.resolved]="filters.locationNmlsIds.$resolved" [class.curtain]="!params.states">
						<ng-select
								[ngModel]="params.locationNmlsIds"
								(change)="setFilter({ locationNmlsIds: $event })"
								[items]="filters.locationNmlsIds.$promise | async"
								bindValue="id"
								bindLabel="displayName"
								[multiple]="true"
								[disabled]="!params.states"
								[clearable]="true"
								placeholder="All Branches"
						>

							<ng-template ng-label-tmp let-item="item" let-clear="clear">
								<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
								{{item.city | titlecase}}&nbsp;•&nbsp;{{item.state}}&nbsp;•&nbsp;{{item.zip}}
							</ng-template>
							<ng-template ng-option-tmp let-item="item">
								{{item.city | titlecase}}&nbsp;•&nbsp;{{item.state}}&nbsp;•&nbsp;{{item.zip}}
								<div *ngIf="item.nmlsId" class="text-muted text-smaller">
									NMLS ID: {{item.nmlsId}}
								</div>
							</ng-template>
						</ng-select>
					</div>
					<div class="col-sm-2 remote-resource" [class.resolved]="filters.accountTypes.$resolved">
						<ng-select
								[ngModel]="params.accountTypes"
								(change)="setFilter({ accountTypes: $event })"
								[items]="filters.accountTypes.$promise | async"
								bindValue="id"
								bindLabel="name"
								[multiple]="true"
								[clearable]="true"
								placeholder="All Account Types"
						>
						</ng-select>
					</div>
					<div class="col-sm-2">
						<ng-select
								[ngModel]="params.entityTypes"
								(change)="setFilter({ entityTypes: $event })"
								[items]="contactTypes"
								bindValue="type"
								bindLabel="name"
								[multiple]="true"
						>
						</ng-select>
					</div>
					<div class="col-sm-a"></div>
					<div class="col-sm-2">
						<a class="export pull-right" [aDisabled]="noMatches" [class.processing]="exportStatus" (click)="export()">
							<i class="c-icon c-icon-{{exportStatus ? 'help' : 'download-alt'}}"></i>&nbsp;&nbsp;Export
							<div class="export-tooltip">
								Your export is being processed. <br>
								We will notify you via Email when it's ready. <br>
								You can retrieve your file by clicking. <br>
								<div class="text-muted">
									<i class="c-icon c-icon-user-gear"></i>&nbsp;→&nbsp;
									<a uiSref="export">Export</a>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="rows-bordered">
				<div class="row-fluid header">
					<div class="col-sm-2">
						<b>Name</b>
					</div>
					<div class="col-sm-2">
						<b>Account Type</b>
					</div>
					<div class="col-sm-3">
						<b>URL</b>
					</div>
					<div class="col-sm-5">
						<b>Branch</b>
					</div>
				</div>

				<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
					<span class="col-sm-12 text-center va-m">Filters are applied. <a (click)="resetFilters();">Reset filters</a> to see all results</span>
				</div>

				<div class="row-fluid" *ngFor="let item of list">
					<div class="col-sm-2 va-m" [ngSwitch]="!!item.contactId">
						<a *ngSwitchCase="true" uiSref="people.contacts.:contactId.information" [uiParams]="{ contactId: item.contactId }">{{item.name}}</a>
						<span *ngSwitchCase="false">{{item.name}}</span>
						<p class="text-muted text-smaller" *ngIf="item.nmlsId">NMLS ID: {{item.nmlsId}}</p>
					</div>
					<div class="col-sm-2 va-m">
						<sm-network-icon
								[config]="{ networkCode: item.networkCode }"
								[minified]="true"
								[showTitle]="true"
						></sm-network-icon>
					</div>
					<div class="col-sm-3 va-m">
						<div class="url" [class.blue]="regLink.test(item.link)" [innerHTML]="item.link | textToUrl: { regex:regLink, displayProtocol: false }"></div>
					</div>
					<div class="col-sm-5 va-m">
						<span *ngIf="!item.locations.length">&#8212;</span>
						<div class="row">
							<div class="col-sm-6 horizontal-flex-group" *ngFor="let location of item.locations; let i = index">
								<div>
									{{location.city | titlecase}}&nbsp;•&nbsp;{{location.state}}&nbsp;•&nbsp;{{location.zip}}
									<div *ngIf="location.nmlsId" class="text-muted text-smaller">
										NMLS ID: {{location.nmlsId}}
									</div>
								</div>
								<div *ngIf="item?.count && i === 1" class="text-muted">
									+{{item?.count}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid" *ngIf="noMatches">
					<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
				</div>
				<div class="row-fluid" *ngIf="!list.length && !noMatches">
					<span class="col-md-12 text-muted text-center text-bigger">No accounts</span>
				</div>

				<br>
				<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
			</div>
		</section>
	</ng-template>
	<ng-template [ngSwitchCase]="false">
		<no-permissions title="Inventory">
			<img src="/static/images/promo/sm-get-started/Icons_monitoring_off.svg" alt="Inventory"/>
		</no-permissions>
	</ng-template>
</ng-container>
