<div class="users-management">
	<div class="content">
		<div class="roles">
			<div class="gray-head flex-head">
				<ul class="breadcrumbs">
					<li>
						<a uiSref="users-management.roles">User Management</a>
						<span *ngIf="actionType === 'new'" class="separator">&nbsp;/&nbsp;&nbsp;</span>
					</li>
					<li *ngIf="actionType === 'new'">
						<span>Create</span>
					</li>
				</ul>
				<h2 class="title-label">
					<span>{{title}}</span>
					&nbsp;
					<span *ngIf="viewMode.showStatusLabel && roleResource.$resolved" class="label min-100" [ngClass]="{'label-success': roleViewData.isActive, 'label-default': !roleViewData.isActive}">
						{{roleViewData.isActive ? 'Active' : 'Inactive'}}
					</span>
				</h2>
			</div>
			<section class="remote-resource" [class.resolved]="roleResource.$resolved && !submitted">
				<form [formGroup]="roleForm" (onValidSubmit)="save()">
					<form-errors [form]="roleForm"></form-errors>

					<div class="row-fluid margin-bottom-md" *ngIf="canManage && viewMode.showActionBtn">
						<div class="col-md-4">
							<button type="button" [disabled]="editing" (click)="changeStatus()" class="btn btn-primary">
								{{roleViewData.isActive ? 'Inactivate Role' : 'Activate Role'}}
							</button>
							<button type="button" [disabled]="editing" class="btn btn-default" uiSref="users-management.roles.:actionType.:roleId" [uiParams]="{roleId: roleId, actionType: 'copy'}" [uiOptions]="{inherit: false, reload: true}">
								Copy Role
							</button>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-8">
							<h3>{{isNew ? viewMode.title : 'Role Information'}}</h3>
						</div>
						<div class="col-md-4 btn-group-lg text-right">
							<div *ngIf="editing && canManage">
								<button type="submit" class="btn btn-primary" [disabled]="submitted">Save</button>
								<button type="button" class="btn btn-default" [disabled]="submitted" (click)="cancelEditing()">Cancel</button>
							</div>
							<div *ngIf="!editing && canManage">
								<button type="button" class="btn btn-primary" (click)="toggleEditMode()">
									<span class="glyphicon glyphicon-pencil"></span> Edit
								</button>
							</div>
						</div>
					</div>
					<!--<div class="alert alert-danger" role="alert" form-errors></div>-->
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="editing && roleForm.get('name').invalid && roleForm.get('name').touched">
								<label class="col-sm-5 control-label control-value text-right" [ngClass]="{required: editing === true}">Role Name</label>
								<div class="col-sm-7">
									<span *ngIf="!editing" class="control-value">{{roleViewData.name}}</span>
									<input *ngIf="editing" name="name" class="form-control" formControlName="name" maxlength="100" tabindex="1" />
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="editing && roleForm.get('description').invalid && roleForm.get('description').touched">
								<label class="col-sm-5 control-label control-value text-right">Description</label>
								<div class="col-sm-7">
									<span *ngIf="!editing" class="control-value">{{roleViewData.description || '&ndash;'}}</span>
									<textarea *ngIf="editing" name="description" class="col-sm-12 resize-vertical" formControlName="description" rows="2" maxlength="225" tabindex="1"></textarea>
								</div>
							</div>
						</div>
					</div>
					<br>

                    <permission-sections [editing]="editing" [permissionState]="formData.permissionsMap"></permission-sections>

					<div class="btn-group-lg text-right">
						<div *ngIf="editing">
							<button type="submit" class="btn btn-primary" [disabled]="submitted">Save</button>
							<button type="button" class="btn btn-default" [disabled]="submitted" (click)="cancelEditing()">Cancel</button>
						</div>
					</div>
				</form>
			</section>
		</div>
	</div>
</div>
