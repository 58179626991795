import { NgResourceArray } from 'commons/interfaces';

export enum QuestionnaireStatus {
	InProgress = 'IP',
	Completed = 'C',
	Scheduled = 'S',
	Cancelled = 'CN',
}

export type QuestionnaireApplicationType = 'New' | 'Renewal';

interface QuestionnaireChannel {
	id: number;
	name: string;
}

export interface QuestionnairesListEntry {
	id: number | null;
	name: string;
	statusDate: number | null;
	applicationId?: number;
	applicationInProgress?: boolean;
	applicationType: QuestionnaireApplicationType | null;
	channel: QuestionnaireChannel | null;
	questionnaireAssignmentId: number | null;
	lender: {
		lenderId: number;
		lenderName: string;
	};
	status: {
		id: QuestionnaireStatus;
		name: string;
	};
	cancelable: boolean;
}

export type QuestionnairesList = NgResourceArray<QuestionnairesListEntry>;

export interface AssignableQuestionnaireEntry {
	id: number;
	name: string;
	description: string | null;
	questionsNumber: number;
}

export type AssignableQuestionnaireList = NgResourceArray<AssignableQuestionnaireEntry>;

export interface QuestionnaireLenderContactsEntry {
	realmUserId: number;
	firstName: string;
	lastName: string;
	email: string | null;
	// computed field for ng-select
	fullName?: string;
}

export interface QuestionnaireContactsEntry extends QuestionnaireLenderContactsEntry {
	contactId: number;
}

export type QuestionnaireContactsList = NgResourceArray<QuestionnaireContactsEntry>;
export type QuestionnaireLenderContactsList = NgResourceArray<QuestionnaireLenderContactsEntry>;

export interface QuestionnaireAssignPayload {
	toContactIds?: number[];
	ccRealmUserIds?: number[];
	message?: string;
	notifyUponCompletionRealmUserIds?: number[];
	questionnaireId?: number;
	scheduledDate?: string;
	tpoId?: number;
}

export interface SnapshotData {
	assignmentUsers: QuestionnaireContactsList;
	ccInternalUsers: QuestionnaireLenderContactsList;
	message: string;
	toInternalUsers: QuestionnaireLenderContactsList;
	scheduledDate: string;
}

export type snapshotTitles = Record<snapshotKeys, string>;

export type snapshotKeys =
	| 'notifyAssignment'
	| 'toClient'
	| 'ccInternal'
	| 'message'
	| 'notifyCompletion'
	| 'toInternal'
	| 'scheduled'
	| 'date';
