import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { MonitoringScheduleData, MonitoringSchedulePrice, MonitoringScheduleService } from 'tpo/social-compliance/schedule/schedule.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';
import {
    GlobalNotification,
    GlobalNotificationsService,
    GlobalNotificationType,
} from 'global-elements/notication-center/notifications.service';

// Use this if you want only one section to be editable simultaneously
const FOCUS_MODE = true;

@Component({
    templateUrl: './schedule.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpoComplianceScheduleComponent implements OnInit, OnDestroy {
    helpLink = '/help/authenticated/tpo/smc/compliance-schedule-settings';
    User: UserProfile;
    hasViewAllPermission = false;
    loading = false;
    settings: MonitoringScheduleData;
    priceUpdate$ = new Subject<MonitoringScheduleData>();
    price$ = new Subject<MonitoringSchedulePrice>();
    priceLoading = false;
    focusOn: string | null = null;
    private destroy$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private scheduleService: MonitoringScheduleService,
        private notificationsService: GlobalNotificationsService,
        private _cd: ChangeDetectorRef,
    ) {
        this.User = userService.profile;
        this.hasViewAllPermission = this.User.isComergence || this.User.can('TPO_SMC_VIEW_ALL');
    }

    async ngOnInit(): Promise<void> {
        //skip loading if user doesn't have permissions
        if (!this.hasViewAllPermission) return ;

        this.loading = true;
        this.settings = await this.scheduleService.get().toPromise();
        this.price$.next(this.settings.estimatedFee);
        this.priceUpdate$.pipe(
            distinctUntilChanged(isEqual),
            takeUntil(this.destroy$),
        ).subscribe(settings => {
            this.updatePrice(settings);
        });
        this.loading = false;
        this._cd.markForCheck();
    }

    async updatePrice(setting: MonitoringScheduleData): Promise<void> {
        this.priceLoading = true;
        try {
            this.price$.next(await this.scheduleService.updatePrice(setting).toPromise());
        } catch (e) {
        }
        this.priceLoading = false;
        this._cd.markForCheck();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    focusSection(name: string, focus: boolean) {
        this.focusOn = focus ? name : null;
    }

    isVisible(name: string | null) {
        if (!FOCUS_MODE) return true;

        this._cd.markForCheck();
        return this.focusOn === null || this.focusOn === name;
    }

    showNotification({ message, type }: Partial<GlobalNotification>) {
        message ||= 'Updated settings will be applied to the next order';
        type ||= GlobalNotificationType.POSITIVE;

        this.notificationsService.add({ message, type })
    }
}
