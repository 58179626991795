import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { CommentsResourceInterface } from 'commons/components/comments';

import { FindingsService, FindingResource } from '../findings.service';

@Injectable()
export class FindingService {
	finding: FindingResource;

	constructor(
		public stateService: StateService,
		public findingsService: FindingsService,
	) {}

	getFinding = (forceReload: boolean = false) => {
		const { findingId } = this.stateService.params;
		return this.findingsService.finding.get({ findingId });
	}

	getCommentsResource = (): CommentsResourceInterface => {
		const { findingId } = this.stateService.params;

		return {
			query: (listParams) => {
				const params = { ...listParams, findingId };
				return this.findingsService.comments.query(params);
			},
			add: (params, data, successHandler, ererrorHandlerror) => {
				return this.findingsService.comments.add({ findingId }, data, successHandler, ererrorHandlerror);
			},
		};
	}

	getTaskCommunicationResource = (): CommentsResourceInterface => {
		const { findingId } = this.stateService.params;

		return {
			query: (listParams) => {
				const params = { ...listParams, findingId };
				return this.findingsService.taskCommunication.query(params);
			},
			add: (params, data, successHandler, ererrorHandlerror) => {
				return this.findingsService.taskCommunication.add({ findingId }, data, successHandler, ererrorHandlerror);
			},
		};
	}
}
