<div class="order-payment">
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
			<span>Payment</span>
			<smHelpLink [url]="helpLink"></smHelpLink>
		</back-bread>
	</div>

	<div class="remote-resource" [class.resolved]="payment.$resolved && !isPaying">
		<h3>Review Your Order</h3>
		<div class="contacts-holder">
			<div class="col-sm-4 contact-details" *ngFor="let item of selectedContacts">
				{{item.fullName}}
				<small *ngIf="item.nmlsId">
					<span class="text-muted">
						NMLS ID: {{item.nmlsId}}
					</span>
				</small>
				<span class="unselect">
					<span (click)="removeContact(item)" class="glyphicon glyphicon-remove"></span>
				</span>
			</div>

		</div>
		<br>

		<form [formGroup]="paymentForm" (onValidRecurlySubmit)="pay()" [skipRecurly]="!ccRequired" tokenPath="payingToken">
			<div>
				<h3>Settings</h3>
				<div class="rows-striped">
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row">
								<label class="col-sm-7 control-label required">Auto Close Audits with no Findings</label>

								<div class="col-sm-5 control-label">
									<label class="radio-inline">
										<input type="radio" name="autocloseData.autoClose" [formControl]="paymentForm.get('autocloseData.autoClose')" [value]="true"> On
									</label>
									<label class="radio-inline">
										<input type="radio" name="autocloseData.autoClose" [formControl]="paymentForm.get('autocloseData.autoClose')" [value]="false"> Off
									</label>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="paymentForm.showFieldError('autocloseData.autoCloseStatusId')">
								<label class="col-sm-6 control-label" [class.required]="paymentForm.get('autocloseData.autoClose').value">Change Audit Status to</label>

								<div class="col-sm-6">
									<ng-select
										*ngIf="autocloseStatuses?.$resolved"
										[formControl]="paymentForm.get('autocloseData.autoCloseStatusId')"
										[items]="autocloseStatuses"
										[searchable]="false"
										bindValue="id"
										bindLabel="name"
										placeholder="Choose option"
										tabindex="1">
									</ng-select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br>
			<section class="flex-row space-between" *ngIf="ccRequired">
				<h3>
					Credit Card Information
				</h3>

				<div class="required-block-lg text-muted" *ngIf="shouldPay">
					<span class="control-label required"></span> &ndash; Required fields
				</div>
			</section>

			<div class="clearfix">
				<div style="overflow: hidden; height: 0;">
					<input type="password" name="password"/>
					<input type="text" name="fakeusernameremembered"/>
				</div>

				<form-errors [form]="paymentForm"></form-errors>

				<div class="rows-striped" *ngIf="ccRequired">
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" [recurlyError]="paymentForm.get('card')" [class.has-error]="paymentForm.get('card').showError">
								<label class="col-sm-3 control-label required">Card</label>

								<div class="col-sm-9">
									<div data-recurly="card"><div class="form-control"></div></div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<small>The CVV number is the 3 digit number located on the back of your Visa or Master card.
								For American Express, it is a 4 digit number in the small print located on the front of your card.
							</small>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="paymentForm.get('first_name').showError">
								<label class="col-sm-3 control-label required">First Name</label>

								<div class="col-sm-9">
									<span><input type="text" class="form-control" data-recurly="first_name" formControlName="first_name" tabindex="1" placeholder=""></span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="paymentForm.get('last_name').showError">
								<label class="col-sm-3 control-label required">Last Name</label>

								<div class="col-sm-9">
									<span><input type="text" class="form-control" data-recurly="last_name" formControlName="last_name" tabindex="1" placeholder=""></span>
								</div>
							</div>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row">
								<label class="col-sm-3 control-label">Cost Center</label>
								<div class="col-sm-9">
									<span>
										<input type="text" maxlength="30" class="form-control" formControlName="costCenter" tabindex="5" autocomplete="off">
									</span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row">
								<label class="col-sm-3 control-label">PO Number</label>
								<div class="col-sm-9">
									<input type="text" maxlength="30" class="form-control" formControlName="poNumber" tabindex="8" autocomplete="off">
								</div>
							</div>
						</div>
					</div>
				</div>
				<br>
				<div class="payment flex-row space-between" [class.isPaying]="isPaying">
					<div class="flex-grow">
						<label class="checkbox-inline">
							<input type="checkbox" formControlName="agree" />
							I have read and agree with <a href="/help/authenticated/tpo/smc/terms-and-services" target="_blank">Terms of Use</a>
						</label>
					</div>
                    <div class="flex-row gap">
                        <span *ngIf="shouldPay"><span>Amount Due:</span> <span> {{payment.price || 0 | currency}}</span></span>
                        <button
								*ngIf="!isPaid"
								type="submit"
								class="btn btn-lg btn-primary payBtn"
								[disabled]="isPaying || !paymentForm.get('agree').value"
								tabindex=9>
								{{shouldPay ? 'Pay' : 'Place Order'}}
						</button>
                        <a (click)="cancelOrder()" *ngIf="!isPaid" class="btn btn-lg btn-default" [class.disabled]="isPaying" tabindex=10>Cancel</a>
                        <span class="done" *ngIf="isPaid"><span class="glyphicon glyphicon-ok"></span> Done!</span>
                    </div>
				</div>
			</div>
		</form>
	</div>
</div>
