<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">&times;</button>
	<h4 class="modal-title">Change Expiration Date</h4>
</div>
<form [formGroup]="editForm" novalidate (onValidSubmit)="confirm()">
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<form-errors [form]="editForm"></form-errors>
		<div class="rows-white">
			<div class="row form-group" [class.has-error]="editForm.showFieldError('expirationDate')">
				<label for="expirationDate" class="col-sm-3 control-label">Expiration Date</label>
				<div class="col-sm-4">
					<input
						class="form-control"
						autocomplete="off"
						id="expirationDate"
						tabindex="1"
						formControlName="expirationDate"
						placeholder="mm/dd/yyyy"
						[minDate]="today"
						#dp="bsDatepicker"
						(blur)="updateValue(dp.isOpen)"
						(onHidden)="updateValue()"
						trigger="mousedown"
						bsDatepicker>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<button type="submit" class="btn btn-primary" [disabled]="editForm.invalid">Save</button>
		<button type="button" (click)="cancel()" class="btn btn-default">Cancel</button>
	</div>
</form>
