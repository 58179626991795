<span class="text-center">
    <span *ngIf="(isUserCommergence || hasReviewFindingPermission) && (!isUpdated)">
        <button
            *ngIf="!reviewed"
            class="btn btn-primary"
            (click)="markReviewed()"
        >
            Mark Reviewed
        </button>
    </span>
    <span class="updated text-left" *ngIf="isUpdated">
        <i class="c-icon c-icon-circle-exclamation text-big medium-icon icon-warning va-m"></i>
        Update
    </span>
    <span *ngIf="reviewed" class="reviewed mark-reviewed">
        <span class="text text-left">
            <i class="glyphicon glyphicon-ok-sign medium-icon text-success"></i>
            <span class="main">Reviewed by</span>
            <small class="text-muted">{{reviewer.name}}<span>&nbsp;on {{reviewer.reviewDate | date:'MM/dd/yyyy'}}</span></small>
        </span>
    </span>
</span>
