import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { includes } from 'lodash';

import {
	AllDirectionalInvestorShareRequests,
	DirectionalInvestorShareRequest,
} from 'commons/interfaces/investor-share-requests.type';
import { InvestorChannelSharingTableActionButtonConfig } from 'lender/channel-shares/investor-channel-sharing-table-config.type';

import { InvestorSharesAccessRulesService } from 'commons/services/system-rules/system-access-rules/investor-shares-access-rules.service';
import { InvestorShareRequestsResourceService } from 'commons/services/investor-share-requests-resource.service';
import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals';


@Component({
	templateUrl: './investor-channel-shares.component.html',
})
export class InvestorChannelSharesComponent implements OnInit {
	static readonly ACTIVE_SHARING_STATUS: string = 'Active';
	static readonly PENDING_SHARING_STATUS: string = 'Pending';
	static readonly DECLINED_SHARING_STATUS: string = 'Declined';

	static readonly CANCELLABLE_INCOMING_STATUSES: string[] = [
		InvestorChannelSharesComponent.ACTIVE_SHARING_STATUS,
	];

	static readonly CANCELLABLE_OUTGOING_STATUSES: string[] = [
		InvestorChannelSharesComponent.ACTIVE_SHARING_STATUS,
		InvestorChannelSharesComponent.PENDING_SHARING_STATUS
	];

	static readonly RESENDABLE_INCOMING_STATUSES: string[] = [
		InvestorChannelSharesComponent.DECLINED_SHARING_STATUS,
	];

	readonly incomingTableActionButtons: InvestorChannelSharingTableActionButtonConfig[] = [
		{
			baseId: 'cancel',
			text: (shareRequest: DirectionalInvestorShareRequest) => 'Cancel',
			style: (shareRequest: DirectionalInvestorShareRequest) => 'btn btn-danger-wired',
			shouldDisplay: (shareRequest: DirectionalInvestorShareRequest) => includes(InvestorChannelSharesComponent.CANCELLABLE_INCOMING_STATUSES, shareRequest.sharingStatus),
			clicked: (shareRequest: DirectionalInvestorShareRequest) => this.cancelIncomingShare(shareRequest),
		},
	];
	readonly outgoingTableActionButtons: InvestorChannelSharingTableActionButtonConfig[] = [
		{
			baseId: 'cancel',
			text: (shareRequest: DirectionalInvestorShareRequest) => 'Cancel',
			style: (shareRequest: DirectionalInvestorShareRequest) => 'btn btn-danger-wired',
			shouldDisplay: (shareRequest: DirectionalInvestorShareRequest) => includes(InvestorChannelSharesComponent.CANCELLABLE_OUTGOING_STATUSES, shareRequest.sharingStatus),
			clicked: (shareRequest: DirectionalInvestorShareRequest) => this.cancelOutgoingShare(shareRequest),
		},
		{
			baseId: 'resend',
			text: (shareRequest: DirectionalInvestorShareRequest) => 'Resend',
			style: (shareRequest: DirectionalInvestorShareRequest) => 'btn btn-primary',
			shouldDisplay: (shareRequest: DirectionalInvestorShareRequest) => includes(InvestorChannelSharesComponent.RESENDABLE_INCOMING_STATUSES, shareRequest.sharingStatus),
			clicked: (shareRequest: DirectionalInvestorShareRequest) => this.resendOutgoingShare(shareRequest),
		},
	];

	allDirectionalInvestorShareRequests: AllDirectionalInvestorShareRequests;
	investorId: number;

	resolved: boolean = true;

	private modalRef: BsModalRef;

	constructor(
		private readonly investorSharesAccessRulesService: InvestorSharesAccessRulesService,
		private readonly investorShareRequestsResourceService: InvestorShareRequestsResourceService,
		private readonly modalService: BsModalService,
		private readonly userService: UserService,
	) {}

	// Override
	async ngOnInit(): Promise<void> {
        const userHasManageInvestorSharingPermission: boolean = this.investorSharesAccessRulesService.userHasAccessToInvestorShares(this.userService.profile);
		if (!userHasManageInvestorSharingPermission) {
			throw new HttpErrorResponse({ status: 403 });
		}

		this.investorId = this.userService.profile.organization.id;

		try {
			this.resolved = false;

			await this.fetchAllDirectionalShareRequests();
		} finally {
			this.resolved = true;
		}
	}

	cancelIncomingShare = async (shareRequest: DirectionalInvestorShareRequest): Promise<void> => {
		await this.performActionOnShareRequest(
			shareRequest,
			async (investorId: number, shareRequest: DirectionalInvestorShareRequest): Promise<DirectionalInvestorShareRequest> =>
				await this.investorShareRequestsResourceService.cancelIncomingShareRequest(investorId, shareRequest.shareRequestId).toPromise(),
		);
	}

	cancelOutgoingShare = async (shareRequest: DirectionalInvestorShareRequest): Promise<void> => {
		await this.performActionOnShareRequest(
			shareRequest,
			async (investorId: number, shareRequest: DirectionalInvestorShareRequest): Promise<DirectionalInvestorShareRequest> =>
				await this.investorShareRequestsResourceService.cancelOutgoingShareRequest(investorId, shareRequest.shareRequestId).toPromise(),
		);
	}

	resendOutgoingShare = async (shareRequest: DirectionalInvestorShareRequest): Promise<void> => {
		try {
			this.resolved = false;

			await this.investorShareRequestsResourceService.resendOutgoingShareRequest(this.investorId, shareRequest.shareRequestId).toPromise();

			this.resolved = true;

			await this.fetchAllDirectionalShareRequests();
		} finally {
			this.resolved = true;
		}

	}

	private async fetchAllDirectionalShareRequests(): Promise<void> {
		try	{
			this.resolved = false;

			this.allDirectionalInvestorShareRequests = await this.investorShareRequestsResourceService
				.getAllDirectionalInvestorShareRequests(this.investorId)
				.toPromise();
		} finally {
			this.resolved = true;
		}
	}

	private async performActionOnShareRequest(
		shareRequest: DirectionalInvestorShareRequest,
		actionHttp: (investorId: number, shareRequest: DirectionalInvestorShareRequest) => Promise<DirectionalInvestorShareRequest>,
	): Promise<void> {
		const initialState = {
			title: `${shareRequest.targetChannel.channelName}.. Cancel Sharing`,
			message: `
				<div class="alert alert-danger">
					This action cannot be undone.
				</div>
				Are you sure you would like to continue?
			`,
			confirmText: 'Continue',
			onConfirm: async (): Promise<void> => {
				try	{
					this.modalRef.content.resolving = true;

					await actionHttp(this.investorId, shareRequest);
					
					this.modalRef.content.resolving = false;
					this.modalRef.hide();

					await this.fetchAllDirectionalShareRequests();
				} catch (e) {
					this.modalRef.content.errorText = (e.data?.message || e.error?.message || e.statusText);
				} finally {
					this.modalRef.content.resolving = false;
				}

			},
		};

		this.modalRef = this.modalService.show(
			ConfirmModalComponent,
			{
				initialState,
            	class: 'modal-smd modal-new',
			},
		);
	}
}
