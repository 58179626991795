<svg [attr.viewBox]="[0, 0, diameter, diameter].join(' ')" [attr.width]="diameter" [attr.height]="diameter">
    <circle
        fill="none"
        stroke="currentcolor"
        stroke-linecap="round"
        [attr.stroke-dasharray]="[length / 2, length].join(' ')"
        [attr.stroke-width]="thickness"
        [attr.r]="radius"
        [attr.cx]="diameter / 2"
        [attr.cy]="diameter / 2"
    >
        <animate
            attributeName="stroke-dasharray"
            [attr.values]="[length * 0.1, length * 0.9, length * 0.1].join(';')"
            [attr.dur]="duration + 's'"
            keyTimes="0; 0.5; 1"
            calcMode="spline"
            keySplines="0.2 0 0.8 1; 0.2 0 0.8 1"
            repeatCount="indefinite"/>
        <animateTransform
            attributeName="transform"
            type="rotate"
            [attr.from]="[0, diameter / 2, diameter / 2].join(' ')"
            [attr.to]="[360, diameter / 2, diameter / 2].join(' ')"
            [attr.dur]="duration / 3 + 's'"
            repeatCount="indefinite"/>
    </circle>
</svg>
