<section class="remote-resource" [class.resolved]="alertSummary && !resolving && alertDetailsResolved">
    <h2>
        Alert Details
        <div class="pull-right">
            <alert-details-view-record [alertRecord]="alertRecord" (resolved)="setAlertDetailsResolved($event)"></alert-details-view-record>
        </div>
    </h2>

    <div class="rows-striped remote-resource" [class.resolved]="alertSummary">
        <div class="row-fluid">
            <div class="col-md-5 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Company Name</label>
                    <div class="col-md-7">
                        <a uiSref="client.:id.account.information" [uiParams]="{ id: alertSummary?.tpoId }">
                            <span class="control-value">{{ alertSummary?.companyName || '&ndash;' }}</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Alert Status</label>
                    <div class="col-md-7">
                        <ng-container [ngSwitch]="editingStatus">
                            <ng-container *ngSwitchDefault>
                                <span class="control-value">
                                    <b>{{ selectedStatus?.name || '&ndash;' }}</b>
                                    &nbsp;&nbsp;
                                    <a *ngIf="userCanEditAlerts" (click)="editAlertStatus(alertSummary?.alertStatus)">Edit</a>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="true">
                                <span class="flex-row gap">
                                    <ng-select
                                        [(ngModel)]="selectedStatus"
                                        [items]="alertStatuses"
                                        bindLabel="name"
                                        placeholder="Select Value"
                                        class="flex-grow"
                                    >
                                    </ng-select>
                                    <button [disabled]="!selectedStatus" class="btn btn-icon btn-tertiary" (click)="saveAlert()">
                                        <span class="glyphicon glyphicon-ok"></span>
                                    </button>
                                    <a class="btn btn-icon btn-tertiary" (click)="cancelAlertStatusEdit()">
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </a>
                                </span>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Company NMLS ID</label>
                    <div class="col-md-7">
                        <span class="control-value">{{ alertSummary?.companyNmlsId || '&ndash;' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Alert ID</label>
                    <div class="col-md-7">
                        <span class="control-value">
                            {{ alertSummary?.alertId || '&ndash;' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Individual Name</label>
                    <div class="col-md-7">
                        <span class="control-value">{{ alertSummary?.individualName || '&ndash;' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Alert Date</label>
                    <div class="col-md-7">
                        <span class="control-value">
                            {{ (alertSummary?.createdDate ? (alertSummary.createdDate | date:'MM/dd/yyyy HH:mm') : '&ndash;') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Individual NMLS ID</label>
                    <div class="col-md-7">
                        <span class="control-value">{{ alertSummary?.individualNmlsId || '&ndash;' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Alert Subtype</label>
                    <div class="col-md-7">
                        <span class="control-value">
                            {{ alertSummary?.alertCategory || '&ndash;' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-5 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Branch NMLS ID</label>
                    <div class="col-md-7">
                        <span class="control-value">{{ alertSummary?.branchNmlsId || '&ndash;' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Alert Type</label>
                    <div class="col-md-7">
                        <span class="control-value">
                            {{ alertSummary?.alertType || '&ndash;' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
