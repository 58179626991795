import { Component, Input } from '@angular/core';


@Component({
    selector: '[scorecard-summary-owners-default-text],scorecard-summary-owners-default-text',
	templateUrl: './scorecard-summary-owners-default-text.component.html',
    host: { 'class': 'row-fluid' },
})
export class ScorecardSummaryOwnerDefaultTextComponent {
    @Input() text: string;
}