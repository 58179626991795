<div class="gray-head">
    <h2>Customers</h2>
</div>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-sm-2">
                <div class="text-light">Search</div>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-sm-2">
                <search-list placeholder="Name"></search-list>
            </div>
            <div class="horizontal-flex-group pull-right" *ngIf="canManageCustomers">
                <a class="btn btn-primary"
                   uiSref="customers.:customerType.new"
                   [uiParams]="{ customerType: 'customer' }"
                >Create Customer</a>
            </div>
        </div>
    </div>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-a" columnName="organizationName">Customer</sortable-header>
            <sortable-header class="col-sm-a" columnName="companyPhone">Company Phone</sortable-header>
            <sortable-header class="col-sm-a" columnName="displayName">Display Name</sortable-header>
            <sortable-header class="col-sm-a" columnName="contactPhone">Contact Phone</sortable-header>
            <sortable-header class="col-sm-a" columnName="contactName">Contact Name</sortable-header>
            <sortable-header class="col-sm-a" columnName="status">Status</sortable-header>
        </div>
        <reset-filters></reset-filters>
        <div *ngIf="!resolving && ((list | async)?.length > 0); else noCustomers">
            <a
                *ngFor="let item of list | async"
                class="row-fluid clickable table-row"
                uiSref="customers.:customerType.:organizationId"
                [uiParams]="{ customerType: 'customer', organizationId: item.organizationId }"
            >
                <div columnName="organizationName" class="col-md-a">
                    <strong class="two-liners">{{item.organizationName || '&ndash;'}}</strong>
                    <div class="text-light text-smaller" *ngIf="item.state">{{item.state}}</div>
                </div>
                <div columnName="companyPhone" class="col-md-a">
                    <div class="col-md-a">{{item.organizationPhoneNumber || '&ndash;'}}</div>
                </div>
                <div columnName="displayName" class="col-md-a">
                    <div class="col-md-a">{{item.displayName || '&ndash;'}}</div>
                </div>
                <div columnName="contactPhone" class="col-md-a">
                    <div class="col-md-a">{{item.contactPhone || '&ndash;'}}</div>
                </div>
                <div columnName="contactName" class="col-md-a">
                    <div class="col-md-a">{{item.contactName || '&ndash;'}}</div>
                </div>
                <div columnName="status" class="col-md-a">
                    <ng-container *ngIf="statusConfig[item.statusCode]; let status">
                        <span class="label" [ngClass]="'label-' + status.color">{{status.title}}</span>
                    </ng-container>
                </div>
            </a>
        </div>
        <ng-template #noCustomers>
            <div class="col-sm-12 text-center text-light text-bigger">
                {{filtersApplied ? 'No matches found' : 'No customers'}}
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
