<div class="google-business-profile">
	<div class="flex-row space-between align-top">
		<h3>Google Business Profile</h3>

        <sm-connect-button [networkCode]="networkCode" (connectFinish)="onConnectFinish()">
            <span class="glyphicon glyphicon-plus"></span> Connect
        </sm-connect-button>
	</div>

	<div class="remote-resource" [class.resolved]="!resolving">
		<div class="rows-bordered">
			<div class="row-fluid header">
                <sortable-header class="col-sm-6" columnName="account" >Account</sortable-header>
                <sortable-header class="col-sm-2" columnName="status">Status</sortable-header>
                <div class="col-md-a"></div>
			</div>
		</div>

		<div class="rows-bordered">
			<div *ngFor="let account of list | async" class="row-fluid">
				<div class="col-sm-6">
                    <div class="flex-row gap">
                        <sm-icon [config]="{
                            networkCode: account.networkCode || networkCode,
                            profilePictureUrl: account.profilePictureUrl
                         }"></sm-icon>
                        <a [aDisabled]="!account.link" [href]="account.link" target="_blank">
                            {{account.name}}
                        </a>
                    </div>
				</div>
                <div class="col-sm-2 va-m">
                    <company-monitoring-status
                        [status]="account?.lastConnectionStatus"
                        [isRemovalRequested]="account?.isRemovalRequested"
                    ></company-monitoring-status>
                </div>
                <div class="col-md-a va-m">
                    <div class="flex-row">
                        <a *ngIf="User.can('TPO_PB_MANAGE_TEAM_ACCESS')" uiSref="social-accounts.publishing-permissions" [uiParams]="{ linkId: account.linkId }">
                            Team Access ({{account.publishersCount}})
                        </a>

                        <span class="flex-grow"><!-- whitespace divider--></span>

                        <sm-connect-button
                            *ngIf="account?.lastConnectionStatus === 'DISCONNECTED' && !account?.isRemovalRequested"
                            [networkCode]="networkCode"
                            (connectFinish)="onConnectFinish()"
                            [entry]="account">
                            Reconnect
                        </sm-connect-button>
                    </div>
                </div>
			</div>
			<div class="row-fluid" *ngIf="!resolving && !((list | async)?.length > 0)">
				<span class="col-sm-12 text-center text-light text-bigger va-m">
					No accounts found
				</span>
			</div>
		</div>
		<br>
		<paginator [sizes]="[10,25,50]"></paginator>
	</div>
</div>
