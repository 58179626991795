import { Component, Input, ViewChild } from '@angular/core';
import { ChannelInfo } from 'lender/clients/$id/channels/channels.service';
import { ChannelLocationsComponent } from 'lender/clients/$id/channels/single/locations/channel-locations.component';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: './single-channel.component.html',
})
export class SingleChannelComponent {
    @Input() channelInfo: ChannelInfo;
    @Input() lenderInfo: AccountInformation;
    @ViewChild(ChannelLocationsComponent, { static: true }) private locationsComponent: ChannelLocationsComponent;

    updateLocations(): void {
        this.locationsComponent.updateList();
    }
}
