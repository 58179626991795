<div *ngIf="menuItems.length" dropdown>
    <a dropdownToggle (click)="menuClicked($event)" class="btn btn-icon btn-tertiary">
        <span class="c-icon c-icon-dot-3 text-white"></span>
    </a>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <li role="menuitem" *ngFor="let menuItem of menuItems" [ngSwitch]="!!menuItem.click">
            <a *ngSwitchCase="true" (click)="menuItemClicked(menuItem, $event)">{{ menuItem.title }}</a>
            <a *ngSwitchDefault [uiSref]="menuItem.sref" [uiParams]="menuItem.params">{{ menuItem.title }}</a>
        </li>
    </ul>
</div>
