<div>
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
			<span>Audit Details</span>
			<a *ngIf="User.isComergence && User.can('CCM_REMOVE_AUDIT')" class="btn btn-danger-wired" (click)="remove()">
				Remove
			</a>
			<smHelpLink *ngIf="User.isTpo" [url]="helpLink"></smHelpLink>
		</back-bread>

		<div class="col-min">
			<ul class="tab-menu col-min">
				<li uiSrefActive="active">
					<a uiSref=".information"><b>Information</b></a>
				</li>
				<li uiSrefActive="active" *ngIf="User.can('TPO_SMC_VIEW_ALL') || User.isComergence">
					<a uiSref=".social-accounts"><b>Accounts</b></a>
				</li>
				<li uiSrefActive="active" *ngIf="User.can('TPO_SMC_VIEW_ALL') || User.isComergence">
					<a uiSref=".checklist"><b>Checklist</b></a>
				</li>
				<li uiSrefActive="active" class="b-underline">
					<a uiSref=".archive">
						<b>Feed</b>
						<span *ngIf="harvestingStatus && harvestingStatus.harvestingCompleted" class="c-icon c-icon-check text-success"></span>
					</a>
				</li>
				<li uiSrefActive="active">
					<a uiSref=".audit-log"><b>Audit Log</b></a>
				</li>
			</ul>
		</div>
	</div>
	<ui-view></ui-view>
</div>
