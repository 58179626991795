import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { ProspectsService } from 'lender/prospects/prospects.service';
import { ProspectDetailsComponent } from 'lender/prospects/details/details.component';
import { CustomFieldsModule } from 'shared/fields';
import { ProspectRelatedContactsComponent } from 'lender/prospects/related-contacts/related-contacts.component';
import { ProspectSingleContactRowComponent } from 'lender/prospects/related-contacts/single-contact-row.component';
import { ProspectsListComponent } from 'lender/prospects/list/prospects-list.component';
import { ActivitiesModule } from 'lender/crm/activities/activities.module';
import { ProspectsHistoryListComponent } from 'lender/prospects/history/prospect-history.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
        CustomFieldsModule,
        ActivitiesModule,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
    ],
    declarations: [
        ProspectDetailsComponent,
        ProspectRelatedContactsComponent,
        ProspectSingleContactRowComponent,
        ProspectsListComponent,
        ProspectsHistoryListComponent,
    ],
    providers: [
        ProspectsService,
    ],
    exports: [],
})
export class ProspectsModule {
}
