import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { InvestorChannelSharesComponent } from 'lender/channel-shares/investor-channel-shares/investor-channel-shares.component';
import { InvestorChannelSharingTableComponent } from 'lender/channel-shares/investor-channel-sharing-table/investor-channel-sharing-table.component';
import { InvestorChannelSharingTableActionButtonComponent } from 'lender/channel-shares/investor-channel-sharing-table-action-button/investor-channel-sharing-table-action-button.component';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,

		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	declarations: [
		InvestorChannelSharesComponent,
		InvestorChannelSharingTableComponent,
		InvestorChannelSharingTableActionButtonComponent,
	],
	providers: [
	],
})
export class InvestorChannelSharesModule {}
