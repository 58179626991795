import { transform, pickBy, isNil } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ExportMixin {
    params: any;
    columns: any;
    url: string;

    export(all?) {
        const params = this.getExportParams();

        if (!all) {
            params.columns = transform(Array.from(this.columns.values()), (result, value: any, key) => {
                if (value.enabled) {
                    result.push(value.id);
                }

                return result;
            }, []).join(',');
        }

        const queryString = new HttpParams({ fromObject: pickBy(params, (val, key) => (!isNil(val) || key == 'columns' )) }).toString();

        window.open(`${this.url}?${queryString}`, '_blank');
    }

    getExportParams() {
        return { ...this.params };
    }
}
