import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { CustomerList, ServiceChannel } from 'comergence/customers/services/customer-list/customer.service.interface';
import { CustomerInformation } from 'comergence/customers/services/customer-list/customer-information.service.interface';

declare let apiPath: string;

@Injectable()
export class CustomerService {

    constructor(
         private  http: RealmHttpClient,
    ) {

    }
    public getCustomersList(listParam): Observable<PagedData<CustomerList[]>> {
        return this.http.pagedRequest<CustomerList[]>(
            'GET' ,
            `${apiPath}/customers` ,
             listParam);
    }

    public getCustomer(customerType: string, organizationId: number): Observable<CustomerInformation> {
        return this.http.request<CustomerInformation>(
            'GET',
            `${apiPath}/customers/${customerType}/${organizationId}`
        );
    }

    public updateStatus(customerType: string, organizationId: number, status): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/customers/${customerType}/${organizationId}/status`,
            null,
            status
        );
    }

    public getActiveServiceAndChannels(organizationId: number): Observable<ServiceChannel> {
        return this.http.request<ServiceChannel>(
            'GET',
            `${apiPath}/customers/${organizationId}/services/active-services-and-channels`
        );
    }

    public updateCustomer(customerType: string, organizationId: number, customer): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/customers/${customerType}/${organizationId}`,
            null,
            customer
        );
    }

    public addCustomer( customerType: string, customer: CustomerInformation ): Observable<CustomerInformation> {
        return this.http.request<CustomerInformation>(
            'POST',
            `${apiPath}/customers/${customerType}`,
            null,
            customer
        );
    }
}
