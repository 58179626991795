<div class="remote-resource" [class.resolved]="resolved && isAttachmentsRendered">
	<div class="post-row">
		<div class="column profile-image">
			<i [style.background-image]="sanitize(profilePictureUrl)"></i>
		</div>
		<div class="profile-name">
			<a class="account-name" [aDisabled]="!webLink" href="{{webLink}}" target="_blank"><b>{{name}}</b></a>
		</div>
		<img class="unselectable" src="/static/images/posts-preview/instagram/ig-dots.svg" alt="instagram-dots"/>
	</div>

	<publisher-attachments-preview
		class="unselectable"
		[networkCode]="networkCode"
		[attachments]="attachments"
		[carouselScaling]="true"
		(onIsRendered)="attachmentsLoaded($event)"
	></publisher-attachments-preview>

	<footer>
		<div class="icon-bar unselectable">
			<img src="/static/images/posts-preview/instagram/ig-heart.svg" alt="instagram-heart"/>
			<img src="/static/images/posts-preview/instagram/ig-comment.svg" alt="instagram-comment"/>
			<img src="/static/images/posts-preview/instagram/ig-share.svg" alt="instagram-share"/>
			<img src="/static/images/posts-preview/instagram/ig-save.svg" alt="instagram-save"/>
		</div>

		<div class="post-text" *ngIf="textTruncated">
			<a class="account-name" [aDisabled]="!webLink" href="{{webLink}}" target="_blank"><b>{{name}}</b></a>&nbsp;
			<formatted-text [text]="textTruncated | textToUrl"></formatted-text>
		</div>

		<div class="add-comment unselectable">
			<i [style.background-image]="sanitize(profilePictureUrl)"></i>
			<span class="muted-light">Add a comment...</span>
			<img src="/static/images/posts-preview/instagram/ig-emoticons.png" alt="instagram-emoticons">
		</div>

		<small class="muted-light unselectable">1 second ago</small>
	</footer>
</div>
