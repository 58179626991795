import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UIRouterModule} from '@uirouter/angular';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {TpoStatusModule} from 'tpo/status';
import {SidenavMenuComponent} from './sidenav-menu/sidenav-menu.component';
import {TopNavMenuComponent} from './topnav-menu/topnav-menu.component';
import {TpoTopNavComponent} from './role-topnav/tpo-topnav.component';
import {CustomerTopNavComponent} from './role-topnav/customer-topnav.component';
import {LenderTopNavComponent} from './role-topnav/lender-topnav.component';
import {ComergenceTopNavComponent} from './role-topnav/comergence-topnav.component';
import {TpoUserNavComponent} from './role-usernav/tpo-usernav.component';
import {UserNavMenuComponent} from './usernav-menu/usernav-menu.component';
import {GenericMenuComponent} from 'commons/components/navigation/menu/generic-menu.component';
import { DotMenuComponent } from 'commons/components/navigation/dots-menu/dot-menu.component';
import { SideMenuComponent } from 'commons/components/navigation/menu/side/side-menu.component';
import { MenuLabelDirective } from 'commons/components/navigation/menu/menu-label.directive';
import { CustomerUsernavComponent } from 'commons/components/navigation/role-usernav/customer-usernav.component';
import { ComergenceUsernavComponent } from 'commons/components/navigation/role-usernav/comergence-usernav.component';

const components = [
	SidenavMenuComponent,
	TopNavMenuComponent,
    // TPO
	TpoTopNavComponent,
    TpoUserNavComponent,
    // LENDER/CUSTOMER
	CustomerTopNavComponent,
    CustomerUsernavComponent,
    // COMERGENCE
    ComergenceTopNavComponent,
    ComergenceUsernavComponent,
    // OUTDATED?
	LenderTopNavComponent,

	UserNavMenuComponent,
	DotMenuComponent,
	GenericMenuComponent,
	SideMenuComponent,
	MenuLabelDirective,
];

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		BsDropdownModule.forRoot(),
		TpoStatusModule,
	],
	declarations: [
		...components,
	],
	exports: [
		...components,
	],
})
export class NavigationModule {
}
