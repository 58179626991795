import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults, findIndex } from 'lodash';
import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NgResourceArray } from 'commons/interfaces';

import { Folder } from '../../templates.service';

@Component({
	templateUrl: './remove-folder-modal.component.html',
})
export class RemoveFolderModalComponent implements OnInit {
	folders: NgResourceArray<Folder>;
	folderId: number;
	onConfirm: (folderId: number) => any;
	onCancel: (params?) => any;
	resolving: boolean;

	removeFolderForm: RealmFormGroup = new RealmFormGroup({
		folder: new RealmFormControl(
			'folder',
			{
				label: 'Sharing',
				updateOn: 'change',
			},
			Validators.required,
		),
	});

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: false,
		});
	}

	async ngOnInit(): Promise<void> {
		await this.folders.$promise;
		if (this.folderId) {
			const index = findIndex(this.folders, { id: this.folderId });
			this.folders.splice(index, 1); // remove current folder from the list
		}

		const rootFolder: Folder = {
			id: null,
			name: 'None',
			assetSharing: {
				sharingType: {
					id: 'NV',
					name: 'None',
				},
				shareTo: [],
			},
		}
		this.folders.unshift(rootFolder);

		this.resolving = false;
	}

	submit = (): void => this.onConfirm(this.removeFolderForm.value.folder.id);

	hasError = (controlName: string): boolean => {
		const { invalid, touched } = this.removeFolderForm.get(controlName);
		return invalid && touched;
	};
}
