<span *ngIf="isExtendedView === false">{{outputLabel || '&ndash;'}}</span>
<div *ngIf="isExtendedView">
	<div *ngIf="selectedType === TypeNames.USERS || selectedType === TypeNames.NONE">
		{{inputLabel || (typeFromConfig && typeFromConfig.name)}}
	</div>
	<div *ngIf="selectedType === TypeNames.GROUP" class="share-to-selector view">
		<div class="horizontal-flex-group entry-item" *ngFor="let item of entries">
			<i class="c-icon c-icon-users space-big group-icon">&nbsp;</i>
			<div class="full-width">
				{{item.name}}
			</div>
		</div>
	</div>
</div>
