import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';

import { TpoMobileRegistrationService, MobileRegistration } from './tpo-mobile-registration.service';

@Component({
	selector: 'tpo-mobile-registration',
	templateUrl: './tpo-mobile-registration.component.html',
})
export class TpoMobileRegistrationComponent implements OnInit {
	User: UserProfile;
	mobileRegistration: NgResourceArray<MobileRegistration>;
	isNotificationSet: boolean;

	@Input() nmlsId: string | number;
	@Input() username: string;

	constructor(
		{ profile }: UserService,
		private readonly tpoMobileRegistrationService: TpoMobileRegistrationService,
		private readonly cd: ChangeDetectorRef,
		private readonly $state: $stateProvider,
	) {
		this.User = profile;
	}

	ngOnInit() {
		this.initMobileRegistrationValidation();
	}

	async initMobileRegistrationValidation(): Promise<void> {
		if (this.nmlsId && this.User.can('TPO_MOBILE_REGISTRATION_VERIFICATION')) {
			await (
				this.mobileRegistration = this.tpoMobileRegistrationService.list({ individualNmlsId: this.nmlsId })
			).$promise;
			this.cd.detectChanges();
		}
	}

	updateMobileRegistration = (action, index) => {
		this.mobileRegistration.$resolved = false;
		this.tpoMobileRegistrationService.update({
				action, individualNmlsId: this.nmlsId,
				registrationId: this.mobileRegistration[index].id,
			}, () => {
				if (action === 'VERIFY') {
					this.showNotification(`The mobile user ${this.username} is verified`);
					this.mobileRegistration = [];
				} else {
					this.mobileRegistration.splice(index, 1);
				}
				this.mobileRegistration.$resolved = true;
				this.cd.detectChanges();
			},
		);
	}

	showNotification(message) {
		const timeout = 5000;
		this.$state.notify(this.$state.current.name, {
			notification: {
				type: 'alert-success',
				message,
				timeout,
			},
		});

		this.isNotificationSet = true;

		setTimeout(() => {
			this.isNotificationSet = false;
		}, timeout);
	}
}
