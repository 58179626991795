<h2 *ngIf="isNew" class="contact-new-header">Create contact</h2>
<form
    class="remote-resource"
    [class.resolved]="!loading"
    [formGroup]="contactForm"
    (onValidSubmit)="triggerSave()"
>
    <tpo-mobile-registration *ngIf="contact?.nmlsId && user?.isTpo && !isNew"
                             [nmlsId]="contact?.nmlsId"
                             [username]="contact?.firstName + ' ' + contact?.lastName">
    </tpo-mobile-registration>

    <div class="flex-row mb10">
        <h3 class="flex-grow">General Information</h3>
        <div class="flex-none" *ngIf="contact.contactId && hasPermission('MANAGE_CONTACT')" [isEditable]="editing">
            <ng-container view>
				<a *ngIf="!contact.nmlsId && !contact.isPrevious" (click)="showRemoveDialog()" class="btn btn-link" tabindex=14>Remove Contact</a>
				<a *ngIf="!contact.nmlsId && contact.isPrevious" (click)="showRestoreDialog()" class="btn btn-link" tabindex=14>Restore Contact</a>
                <a (click)="edit()" class="btn btn-primary" tabindex=14><span class="glyphicon glyphicon-pencil"></span> Edit</a>
            </ng-container>

            <ng-container edit>
                <button type="submit" class="btn btn-primary" tabindex=14>Save</button>
                <a (click)="cancelEdit()" class="btn btn-default" tabindex=14>Cancel</a>
            </ng-container>
        </div>
    </div>

    <form-errors *ngIf="editing" [form]="contactForm"></form-errors>

    <div class="rows-striped">

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">NMLS ID</label>
                    <div class="col-sm-7">
						<span class="control-value">
							<a [uiSref]="accountPrefix + 'account.licenses.nmls.:nmlsId'" [uiParams]="{ nmlsId: contact.nmlsId }"
                               [aDisabled]="!contact.nmlsId">
								{{contact.nmlsId || '&ndash;'}}
							</a>
						</span>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">Street</label>
                    <div class="col-sm-7" [isEditable]="hasField('address')">
                        <span view class="control-value">{{contact.address || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('address')" tabindex=10 class="form-control" maxlength="100" formControlName="address">
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row" has-error>
                    <label class="col-sm-5 control-label" [class.required]="hasField('firstName')">First Name</label>
                    <div class="col-sm-7" [isEditable]="hasField('firstName')">
                        <span view class="control-value">{{contact.firstName || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('firstName')" tabindex=10 class="form-control" maxlength="100" formControlName="firstName">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">Suite</label>
                    <div class="col-sm-7" [isEditable]="hasField('suite')">
                        <span view class="control-value">{{contact.suite || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('suite')" tabindex=10 class="form-control" maxlength="50" formControlName="suite">
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row" has-error>
                    <label class="col-sm-5 control-label" [class.required]="hasField('lastName')">Last Name</label>
                    <div class="col-sm-7" [isEditable]="hasField('lastName')">
                        <span view class="control-value">{{contact.lastName || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('lastName')" tabindex=10 class="form-control" maxlength="100" formControlName="lastName">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row" has-error>
                    <label class="col-sm-5 control-label" [class.required]="hasField('zip')">Zip</label>
                    <div class="col-sm-7" [isEditable]="hasField('zip')">
                        <span view class="control-value">{{contact.zip || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('zip')" tabindex=10 class="form-control" maxlength="20" formControlName="zip">
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">Middle Name</label>
                    <div class="col-sm-7" [isEditable]="hasField('middleName')">
                        <span view class="control-value">{{contact.middleName || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('middleName')" tabindex=10 class="form-control" maxlength="100" formControlName="middleName">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">State</label>
                    <div class="col-sm-7" [isEditable]="hasField('state')">
                        <span view class="control-value">{{contact.state || '&ndash;'}}</span>
                        <ng-select
                            edit
                            *ngIf="hasField('state')"
                            formControlName="state"
                            [items]="states | async"
                            [loading]="loadingValue.states"
                            bindValue="name"
                            bindLabel="name"
                            [clearable]="true"
                            placeholder="Select state"
                            tabindex=13>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row" has-error>
                    <label class="col-sm-5 control-label" [class.required]="hasField('email')">Email</label>
                    <div class="col-sm-7" [isEditable]="hasField('email')">
                        <span view class="control-value">{{contact.email || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('email')" tabindex=10 class="form-control" maxlength="100" formControlName="email">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">City</label>
                    <div class="col-sm-7" [isEditable]="hasField('city')">
                        <span view class="control-value">{{contact.city || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('city')" tabindex=10 class="form-control" maxlength="50" formControlName="city">
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row" has-error>
                    <label class="col-sm-5 control-label">Phone</label>
                    <div class="col-sm-7" [isEditable]="hasField('phone')">
                        <span view class="control-value">{{contact.phone || '&ndash;' | tel}}</span>
                        <input edit *ngIf="hasField('phone')" phoneFormatter tabindex=10 class="form-control" formControlName="phone">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row" has-error>
                    <label class="col-sm-5 control-label" [class.required]="hasField('officerDesignation')">Designation</label>
                    <div class="col-sm-7" [isEditable]="hasField('officerDesignation')">
                        <span view class="control-value">{{contact.officerDesignation?.join(', ') || '&ndash;'}}</span>
                        <ng-select
                            edit
                            *ngIf="hasField('officerDesignation')"
                            formControlName="officerDesignation"
                            [items]="designations | async"
                            [loading]="loadingValue.designations"
                            [multiple]="true"
                            bindValue="name"
                            bindLabel="name"
                            placeholder="Select designation(s)"
                            tabindex=13>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>

        <div class="row-fluid">
            <div class="col-md-5">
                <div class="form-group row">
                    <label class="col-sm-5 control-label">Title</label>
                    <div class="col-sm-7" [isEditable]="hasField('contactTitle')">
                        <span view class="control-value">{{contact.contactTitle || '&ndash;'}}</span>
                        <input edit *ngIf="hasField('contactTitle')" tabindex=10 class="form-control" maxlength="20" formControlName="contactTitle">
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group row" [isEditable]="editing">
                    <ng-container view>
                        <label class="col-sm-5 control-label">Notifications</label>
                        <div class="col-sm-7">
                            <span class="control-value">
                                {{contact.isPrimaryContact ? 'Yes' : 'No'}}
                            </span>
                        </div>
                    </ng-container>

                    <ng-container edit>
                        <label class="col-sm-5 control-label">Notifications</label>
                        <div class="col-sm-1">
                            <label class="control-label">
                                <input *ngIf="editing" type="checkbox" formControlName="isPrimaryContact" (click)="confirmPrimaryChange()">
                            </label>
                        </div>
                        <div class="col-sm-6">
                            <span class="glyphicon glyphicon-question-sign text-muted form-aligned"
                                  tooltip="The Primary Contact will receive notifications regarding required or outstanding actions."></span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row-fluid" *ngIf="!isNew">
            <div class="col-md-5">
                <div class="form-group row">
                    <ng-container *ngIf="shouldShowOwnership; else userRoleSection">
                        <label class="col-sm-5 control-label">Ownership, %</label>
                        <div class="col-sm-7">
						    <span class="control-value">
							    {{contact.ownershipPercentage ? ( contact.ownershipPercentage | number ) : '&ndash;'}}
						    </span>
                       </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row" *ngIf="contact.userStatus">
                    <label class="col-md-5 control-label">Report Access</label>
                    <div class="col-md-7" [isEditable]="hasField('reportingGroups')">
                        <ng-container view>
                            <div class="control-value">
                                {{contact.reportingGroups | reportingGroups}}
                            </div>
                        </ng-container>

                        <ng-container edit>
                            <ng-select
                                edit
                                *ngIf="hasField('reportingGroups')"
                                formControlName="reportingGroups"
                                [items]="reportingGroups | async"
                                [loading]="loadingValue.reportingGroups"
                                [multiple]="true"
                                [clearable]="true"
                                [closeOnSelect]="false"
                                bindLabel="name"
                                placeholder="None"
                                tabindex=11
                            >
                            </ng-select>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-1 va">
                <a *ngIf="hasField('reportingGroups')" class="btn btn-tertiary" href="/help/authenticated/tpo/report-access" target="_blank">
                    <i class="glyphicon glyphicon-question-sign"></i> Help
                </a>
            </div>
        </div>
        <div *ngIf="
            (shouldShowOwnership || isNew) && (user?.isTpo || user?.isComergence)"
            class="row-fluid"
        >
            <div class="col-md-5">
                <div class="form-group row">
                    <ng-container *ngTemplateOutlet="userRoleSection"></ng-container>
                </div>
            </div>
            <div class="col-md-5 control-label">
                <ng-container *ngIf="user.isTpo && isNew">
                    <i class="c-icon c-icon-circle-exclamation alert-danger"></i>&nbsp;
                    <b>If the contact you’re adding is an owner, User Role MUST be selected.</b>
                </ng-container>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
    <ng-template #userRoleSection>
        <label class="col-sm-5 control-label">User Role</label>
        <div class="col-sm-7" [isEditable]="hasField('role')">
            <span view class="control-value">{{ contact.role ? contact.role.name : '&ndash;' }}</span>
            <ng-select
                edit
                *ngIf="hasField('role')"
                formControlName="role"
                [items]="roles"
                bindLabel="name"
                [clearable]="true"
                placeholder="Select role"
                tabindex=13
            >
            </ng-select>
        </div>
    </ng-template>

    <div class="flex-row mb10">
        <h2 class="flex-grow"></h2>
        <div class="flex-none" *ngIf="isNew && hasPermission('MANAGE_CONTACT')" [isEditable]="editing">
            <ng-container edit>
                <button type="submit" class="btn btn-primary" tabindex=14>Save</button>
                <a (click)="cancelEdit()" class="btn btn-default" tabindex=14>Cancel</a>
            </ng-container>
        </div>
    </div>
</form>
