import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './warning-modal.component.html',
})

export class WarningModalComponent {
	message: string;
	errorText: string;
	title: string;
	confirmText: string;
	cancelText: string;
	onConfirm: (params?) => any;
	onCancel: (params?) => any;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: '<span class="glyphicon glyphicon-exclamation-sign"></span> WARNING',
			confirmText: 'Confirm',
			cancelText: 'Cancel',
			onCancel: () => {
				this.modalRef.hide();
			},
			message: '<div class="text-center text-big">The action <span class="text-danger">CANNOT</span> be undone!</div>',
			resolving: false,
		});
	}
}
