import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { CardPreviewComponent } from 'commons/card/card-preview.component';
import { CardComponent } from 'commons/card/card.component';
import { ColumnsComponent } from 'commons/card/columns.component';
import { CardPersistantPartDirective } from 'commons/card/card.templates';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        CardPreviewComponent,
        CardComponent,
        ColumnsComponent,
        CardPersistantPartDirective,
    ],
    exports: [
        CardComponent,
        ColumnsComponent,
    ],
})
export class CardModule {}
