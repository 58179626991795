import { Component, inject, signal, viewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserProfile, UserService } from '../../../../angularjs-providers/user.provider';
import { NewWarehouseModalComponent } from './new-warehouse-modal/new-warehouse-modal.component';
import { NewWarehousesService, Warehouse } from 'shared/account/information/new-warehouse/warehouses.service';
import { firstValueFrom } from 'rxjs';
import { UIRouterGlobals } from '@uirouter/core';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';

@Component({
    templateUrl: './warehouses.component.html',
})
export class NewWarehousesComponent {
    public user: UserProfile = inject(UserService).profile;
    modalRef: BsModalRef;
    list = signal<Warehouse[]>([]);
    resolved = signal<boolean>(false);
    canManage: boolean = this.user.isTpo;
    sectionReview = viewChild(SectionReviewComponent);
    public readonly sectionType = PROFILE_SECTION.WAREHOUSE;

    constructor(
        protected modalService: BsModalService,
        protected warehousesService: NewWarehousesService,
        protected globals: UIRouterGlobals,
    ) {
        this.loadWarehouses();
    }

    get tpoId(): number {
        const { isTpo } = this.user;
        if (isTpo) return this.user.organization.id;

        return this.globals.params.id;
    }

    async loadWarehouses() {
        try {
            this.resolved.set(false);
            this.list.set(await firstValueFrom(this.warehousesService.getWarehouses(this.tpoId)));
        }
        finally {
            this.resolved.set(true);
        }
    }

    addWarehouse() {
        const initialState = {
            tpoId: this.tpoId,
            onConfirm: () => {
                this.loadWarehouses();
                this.updateReview()
            },
        };
        this.modalRef = this.modalService.show(
            NewWarehouseModalComponent,
            {
                initialState,
                class: 'modal-smd modal-new',
                backdrop: 'static',
            },
        );
    }

    updateReview() {
        this.sectionReview().updateReview();
    }
}
