import { Component, Input } from '@angular/core';

import { ScorecardFindingCategory } from 'shared/due-diligence/scorecard/scorecard-finding-category.interface';
import { ScorecardFindingCategoryMetrics } from 'shared/due-diligence/scorecard/scorecard-findings-metrics.interface';


@Component({
    selector: '[scorecard-summary-findings-metrics-row],scorecard-summary-findings-metrics-row',
	templateUrl: './scorecard-summary-findings-metrics-row.component.html',
    host: { 'class': 'row-fluid' },
})
export class ScorecardSummaryFindingsMetricsRowComponent {
    @Input() tpoId: (number | string);
    @Input() category: ScorecardFindingCategory;
    @Input() metrics: ScorecardFindingCategoryMetrics;
}
