import { ViewportScroller } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

type ScrollPosition = [ number, number ];

const STORAGE_NAME = 'scrollPosition';

@Injectable()
export class ScrollService implements OnDestroy {
	private storage: Storage;
	private restore = true;
	private unsubscribe$ = new Subject<void>();

	constructor(
		private viewportScroller: ViewportScroller,
	) {
		this.storage = window.sessionStorage;
		this.viewportScroller.setHistoryScrollRestoration('manual');

		fromEvent(window, 'scroll')
			.pipe(
				debounceTime(250),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(() => {
				this.storeScrollPosition();
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	updateScrollPosition(): void {
		if (this.restore) {
			// Initial load
			this.restore = false;
			this.restoreScrollPosition();
		} else {
			// Filters/pagination update
			this.scrollTop();
		}
	}

	scrollTop(): void {
		const [ x, y ] = this.viewportScroller.getScrollPosition();
		if (y > 0) {
			this.viewportScroller.scrollToPosition([ 0, 0 ]);
		}
	}

	storeScrollPosition(): void {
		const currentScrollPosition = this.viewportScroller.getScrollPosition();
		this.storage.setItem(
			STORAGE_NAME,
			JSON.stringify(currentScrollPosition)
		);
	}

	restoreScrollPosition(): void {
		const { storedScrollPosition } = this;
		if (storedScrollPosition) {
			this.viewportScroller.scrollToPosition(storedScrollPosition);
		}
	}

	private get storedScrollPosition(): ScrollPosition {
		return JSON.parse(
			this.storage.getItem(STORAGE_NAME)
		);
	}
}
