import { UserProfile } from 'angularjs-providers/user.provider';

const getComergencePermissions = (user: UserProfile, env) => ({
	permissions: new Map([
		[ 'CCM_MANAGE_CCM_USERS', { title: 'CCM User Management' } ],
		[ 'CCM_MANAGE_DOCUMENT_LIBRARY', { title: 'Manage Document Library' } ],
		[ 'CCM_SEARCH_USERS', { title: 'Search Users' } ],
		[ 'CCM_MANAGE_CUSTOM_FIELDS', { title: 'Custom Fields' } ],
        [ 'CCM_DESKTOP_SUPPORT', { title: 'Desktop Support' } ],
        [ 'CCM_GLOBAL_SEARCH', { title: 'Global Search' } ],

		[ 'CCM_VIEW_CUSTOMER', { title: 'View Customer' } ],
		[ 'CCM_MANAGE_CUSTOMERS', { title: 'Manage Customer', dependsOn: ['CCM_VIEW_CUSTOMER'] } ],
		[ 'CCM_MANAGE_CUSTOMER_USERS', { title: 'Manage User', dependsOn: ['CCM_VIEW_CUSTOMER', 'CCM_MANAGE_CUSTOMERS']  } ],

		[ 'CCM_EDIT_RESPONDENT_ID', { title: 'Edit Respondent ID' } ],
		[ 'CCM_DATA_MATCH', { title: 'Data Match' } ],
		[ 'CCM_UPLOAD_PRODUCTION_DATA', { title: 'Upload Production Data' } ],

		[ 'CCM_VIEW_APPLICATIONS', { title: 'View Applications' } ],
		[ 'CCM_VIEW_REVIEWS', { title: 'View Due Diligence' } ],
		[ 'CCM_MANAGE_CLIENT_USERS', { title: 'Manage User' } ],
		// [ 'CCM_MANAGE_MCR', { title: 'Manage Mortgage Call Report', visible: () => (env.endpoints_mcr_enabled === 'true') } ],

		[ 'CCM_EDIT_SOCIAL_MEDIA_CONTACTS', { title: 'Edit Social Media Contacts' } ],
		[ 'CCM_SMC_VIEW_ALL', { title: 'View All' } ],
		[ 'CCM_SMC_MANAGE_DIGITAL_MEDIA', { title: 'Manage Digital Media' } ],
		[ 'CCM_SMC_EDIT_BILLING', { title: 'Manage Billing' } ],
		[ 'CCM_SMC_MANAGE_TRIGGERS', { title: 'Manage Triggers', dependsOn: ['CCM_SMC_VIEW_ALL'] } ],
		[ 'CCM_REMOVE_AUDIT', { title: 'Remove Audits' } ],
	]),
	grouping: new Map([
		[ 'Administration', {
			title: 'Administration',
			columns: [
				[ 'CCM_MANAGE_CCM_USERS', 'CCM_MANAGE_DOCUMENT_LIBRARY', 'CCM_DESKTOP_SUPPORT' ],
				[ 'CCM_SEARCH_USERS', 'CCM_MANAGE_CUSTOM_FIELDS', 'CCM_GLOBAL_SEARCH' ],
			],
		}],
		[ 'Data', {
			title: 'Data',
			columns: [
				[ 'CCM_UPLOAD_PRODUCTION_DATA', 'CCM_EDIT_RESPONDENT_ID' ],
				[ 'CCM_DATA_MATCH' ],
			],
		}],
		[ 'Social Media Compliance', {
			title: 'Social Media Compliance',
			columns: [
				[ 'CCM_EDIT_SOCIAL_MEDIA_CONTACTS', 'CCM_SMC_VIEW_ALL', 'CCM_SMC_MANAGE_DIGITAL_MEDIA' ],
				[ 'CCM_SMC_EDIT_BILLING', 'CCM_SMC_MANAGE_TRIGGERS', 'CCM_REMOVE_AUDIT' ],
			],
		}],
		[ 'Customer', {
			title: 'Customer',
			columns: [
				[ 'CCM_VIEW_CUSTOMER', 'CCM_MANAGE_CUSTOMERS' ],
				[ 'CCM_MANAGE_CUSTOMER_USERS' ],
			],
		}],
		[ 'Clients', {
			title: 'Clients',
			columns: [
				[ 'CCM_VIEW_APPLICATIONS', 'CCM_VIEW_REVIEWS' ],
				[ 'CCM_MANAGE_CLIENT_USERS' /* 'CCM_MANAGE_MCR' */ ],
			],
		}],
	]),
});

export { getComergencePermissions };
