import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';

import { ReviewsService } from 'shared/reviews/reviews.service';
import { ContactService } from 'shared/contact/contact.service';
import { FindingsService } from 'shared/findings/findings.service';

import { NewFindingComponent } from '../common';

@Component({
	templateUrl: '../common/new-finding.component.html',
})
export class NewReviewFindingComponent extends NewFindingComponent {
	constructor(
		public sanitizer: DomSanitizer,
		public stateService: StateService,
		public historyLogService: HistoryLogService,
		public userService: UserService,
		public reviewsService: ReviewsService,
		public contactService: ContactService,
		public findingsService: FindingsService,
	) {
		super(
			sanitizer,
			stateService,
			historyLogService,
			userService,
			reviewsService,
			contactService,
			findingsService,
		);
	}

	get params() {
		const { reviewId } = this.stateService.params;
		return {
			reviewId,
		};
	}

	async getReview() {
		return this.reviewsService.getReview(
			this.params,
		).$promise;
	}

	async init() {
		this.resolved = false;

		const review = await this.getReview();
		this.contactName = review.contactName;
		this.mediaLinks = await this.getMediaLinks(review);

		this.resolved = true;
	}

	create(finding): Promise<any> {
		return this.findingsService.finding.createFromReview(
			this.params,
			finding,
		).$promise;
	}

	cancel(): void {
		// find a state that does not contain findingId and is not fullPage
		const backState = this.historyLogService.findBackState([ '**.findings.new.**' ], false);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }

		window.history.back();
	}
}
