import { Component, Input } from '@angular/core';

@Component({
	selector: 'company-monitoring-status',
	templateUrl: './monitoring-status.component.html',
})
export class CompanyMonitoringStatusComponent {
	@Input() status: string;
	@Input() isRemovalRequested: boolean;
}
