<div class="modal-header">
	<button type="button" *ngIf="!resolving" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
	<h4 class="modal-title text-center" [innerHTML]="title"></h4>
</div>
<div class="remote-resource" [class.resolved]="!resolving">
	<div class="modal-body">
		<div class="alert alert-danger" *ngIf="errorText" [innerHTML]="errorText"></div>
		<div [outerHTML]="message"></div>
	</div>
	<div class="modal-footer">
		<div class="btn-group-lg  text-center">
			<button type="button" (click)="onConfirm()" class="btn btn-danger">{{confirmText}}</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
		</div>
	</div>
</div>
