import { Component } from '@angular/core';

@Component({
    templateUrl: './error-40x.component.html',
})
export class Error40xComponent {
    ok = () => {};
    status: number;
    timeoutMin: number;
}
