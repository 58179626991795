import { NgResourceObject } from 'commons/interfaces';

export enum Mode {
	MASONRY = 'masonry',
	CAROUSEL = 'carousel',
}
export enum Layout {
	HORIZONTAL = 'horizontal',
	VERTICAL = 'vertical',
	EVEN = 'even',
}
export enum Scale {
	SMALL = 'small',
	LARGE = 'large',
}
export type Grid = [ number, number ]; // grid template columns and rows
export type Ratio = [ number, number ]; // horizontal and vertical ratio
export type Ratios = Ratio[];
export interface TemplateConfig {
	grid: Grid;
	ratios: Ratios;
}
export interface AttachmentsConfig {
	minHeightRatio: number; // proportion to container width
	maxHeightRatio: number; // proportion to container width
	maxImages: number;
	excessAllowed?: boolean;
	gifValidationType: 'VIDEO' | 'IMAGE';
	mode: Mode;
	aspectRatio?: number;
	[imagesAmount: number]: {
		[layout in Layout]?: {
		[scale in Scale]?: TemplateConfig;
	} | TemplateConfig;
	} | TemplateConfig;
}

export interface Attachment extends NgResourceObject<File> {
	id: number;
	name: string;
	imageUrl: string;
}

export type AttachmentClassExpression = boolean | ((attachment: Attachment) => boolean);

export type AttachmentClasses = (
	string
	| string[]
	| { [className: string]: AttachmentClassExpression }
);
