import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { reduce, forEach } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator, PhoneValidator, UrlValidator, FileSizeValidator } from 'commons/validators';
import { CodeValueService as CodeValue } from 'shared/code-value.service';

import {
	CompanyListingsService,
	ListingGroup,
	timeToDate,
} from '../../company-listings.service';

const DAY_NAMES = {
	'1': 'Sunday',
	'2': 'Monday',
	'3': 'Tuesday',
	'4': 'Wednesday',
	'5': 'Thursday',
	'6': 'Friday',
	'7': 'Saturday',
};

//Partial for correct IDE hints
export const getTimepickerConfig = () => Object.assign(new TimepickerConfig(), ({
	minuteStep: 15,
} as Partial<TimepickerConfig>));

@Component({
	template: '',
})
export abstract class CommonListingSettingsComponent implements OnInit {
	User: UserProfile;
	details: any;
	states: any;
	editingInfo: boolean = false;
	form = new RealmFormGroup({
		businessName: new RealmFormControl(
			'businessName',
			{ label: 'Business name' },
			[ Validators.required, MaxLengthValidator(90) ],
		),
		address: new RealmFormControl(
			'address',
			{ label: 'Address' },
			[ Validators.required, MaxLengthValidator(250) ],
		),
		city: new RealmFormControl(
			'city',
			{ label: 'City' },
			[ Validators.required, MaxLengthValidator(50) ],
		),
		state: new RealmFormControl(
			'state',
			{ label: 'State' },
			[ Validators.required ],
		),
		zip: new RealmFormControl(
			'zip',
			{ label: 'Zip' },
			[ MaxLengthValidator(20) ],
		),
		phoneNumber: new RealmFormControl(
			'phoneNumber',
			{ label: 'Phone' },
			[ PhoneValidator() ],
		),
		website: new RealmFormControl(
			'website',
			{ label: 'Website' },
			[ UrlValidator(), MaxLengthValidator(250) ],
		),
		description: new RealmFormControl(
			'description',
			{ label: 'Description' },
			[ MaxLengthValidator(4000) ],
		),
		logo: new RealmFormControl(
			'logo',
			{ label: 'Logo' },
			[ FileSizeValidator(20 * Math.pow(2, 20)) ],
		),
		coverImage: new RealmFormControl(
			'coverImage',
			{ label: 'Cover Photo' },
			[ FileSizeValidator(20 * Math.pow(2, 20)) ],
		),
		regularHours: new RealmFormGroup({
			'1': new RealmFormArray([]),
			'2': new RealmFormArray([]),
			'3': new RealmFormArray([]),
			'4': new RealmFormArray([]),
			'5': new RealmFormArray([]),
			'6': new RealmFormArray([]),
			'7': new RealmFormArray([]),
		}),
	});
	//shortcut for regular hours
	regularHours = this.form.get('regularHours') as RealmFormGroup;
	days = [ '2', '3', '4', '5', '6', '7', '1' ];
	enabledDays = {};
	imagePreview: { logo?: string, coverImage?: string } = {};
	DAY_NAMES = DAY_NAMES;
	fileReader = new FileReader();

	constructor(
		public stateService: StateService,
		public modalService: BsModalService,
		userService: UserService,
		public CodeValueService: CodeValue,
		public companyListingsService: CompanyListingsService,
	) {
		this.User = userService.profile;
		this.states = this.CodeValueService.get({ code: 'State' });
	}

	ngOnInit(): void {
		this.details = this.getDetails();

		//nullify discrepancies while details are loading
		this.details.discrepancies = [];
	}

	abstract getDetails(): any;

	abstract saveDetails(formData): any;

	startEdit(): void {
		this.enabledDays = reduce(this.days, (acc, day) => {
			acc[day] = false;
			this.getDayForm(day).clear();
			forEach(this.details.regularHours[day], (time) => {
				acc[day] = true;
				this.addTimeToDay(day, time);
			});
			return acc;
		}, {});
		this.form.reset(this.details);
		this.editingInfo = true;
	}

	cancelEdit(): void {
		this.form.reset();
		this.imagePreview = {};
		this.editingInfo = false;
	}

	toggleDay(day: string): void {
		this.enabledDays[day] = !this.enabledDays[day];
		const enabled = this.enabledDays[day];

		if (enabled) {
			this.addTimeToDay(day);
		} else {
			this.getDayForm(day).clear();
		}
	}

	getDayForm(day: string): RealmFormArray {
		return (this.regularHours.get(day) as RealmFormArray);
	}

	onImageSelect(targetName, fileList: FileList) {
		//multiselect is disabled so it's safe
		const file = fileList[0];
		this.form.patchValue({ [targetName]: file });

		this.fileReader.onload = (event: ProgressEvent<FileReader>) => {
			this.imagePreview[targetName] = event.target.result;
		};

		this.fileReader.readAsDataURL(file);
	}

	addTimeToDay(
		day: string,
		value = { startTime: timeToDate('9:00'), endTime: timeToDate('18:00') },
	): void {
		const { startTime, endTime } = value;
		const dayGroup = this.getDayForm(day);
		const dayName = DAY_NAMES[day];

		dayGroup.push(new RealmFormGroup({
			startTime: new RealmFormControl(
				'startTime',
				{
					label: `From`,
					value: startTime,
					updateOn: 'change',
				},
				[ Validators.required ],
			),
			endTime: new RealmFormControl(
				'endTime',
				{
					label: `To`,
					value: endTime,
					updateOn: 'change',
				},
				[ Validators.required ],
			),
		}));
	}

	removeTimeFromDay(day: string, index: number): void {
		const dayGroup = this.getDayForm(day);
		dayGroup.removeAt(index);
	}

	save(): void {
		this.details.$resolved = false;

		return this.saveDetails(
			this.form.getRawValue(),
		).$promise
			.then((response: ListingGroup) => {
				this.updateDetails(response);
				this.cancelEdit();
			}, ({ data }): void => {
				this.details.$resolved = true;
				this.form.setServerError(data);
			});
	}

	updateDetails(response: ListingGroup) {
		Object.assign(
			this.details,
			response,
		);
	}
}
