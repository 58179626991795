<section
    class="segment-filter segment-filter-edit segment-filter-view remote-resource"
    [class.resolved]="criteriaResolved"
    [formGroup]="listForm"
>
    <ng-container *ngIf="isNew || editing || (orClauses.length > 0)" formGroupName="segmentFilter">
        <h3>List Criteria</h3>
        <div *ngIf="(orClauses.length > 0)" class="rows-striped">
            <div *ngIf="orClauses" class="row-fluid">
                <div class="col-md-12">
                    <div *ngFor="let orClause of orClauses; let ii = index; let lastOrClause = last">
                        <div *ngFor="let andClause of orClause.controls; let lastAndClause = last"
                            class="clauses-group"
                            [class.clause-field-not-found]="andClause.restorationMissingMapping"
                        >
                            <strong>
                                {{ andClause.printOutTypeLabel }}
                            </strong>
                            <strong>
                                {{ andClause.printOutFieldLabel }}
                            </strong>
                            <span>
                                {{ andClause.printOutOperationLabel }}
                            </span>
                            <strong *ngIf="((isNew || editing) && andClause.shouldDisplayValueFormControl) || ((!isNew && !editing) && andClause.hasDtoValue)">
                                '{{ andClause.printOutFieldValue }}'
                            </strong>
                            <span *ngIf="!lastAndClause" class="and-separator">and</span>
    					</div>
                        <span *ngIf="!lastOrClause" class="or-separator">&nbsp;or</span>
    				</div>
    			</div>
    		</div>
	    </div>
        <br />
        <ng-container *ngIf="isNew || editing">
            <div *ngFor="let orClause of orClauses; let ii = index" class="clauses-group" [formGroupName]="ii">
                <div class="rows-striped">
                    <div class="row-fluid">
                        <div class="col-md-12">
                            <div *ngFor="let andClause of orClause.controls; let jj = index" class="row-fluid clause" [formGroupName]="jj">
                                <div class="col-md-2 form-group" has-error>
                                    <ng-select
                                        edit
                                        formControlName="fieldType"
                                        [items]="criteria.fieldTypes"
                                        bindLabel="title"
                                        placeholder="Select Type"
                                        tabindex="1"
                                        (change)="andClause.fieldTypeChanged(true)"
                                    >
                                    </ng-select>
                                </div>
                                <div class="col-md-2 form-group" has-error>
                                    <ng-select
                                        *ngIf="andClause.get('fieldType') as fieldType"
                                        edit
                                        formControlName="fieldName"
                                        [items]="fieldType.value ? criteria.fields[fieldType.value.id] : []"
                                        bindLabel="title"
                                        placeholder="Select Field"
                                        (change)="andClause.fieldNameChanged(true)"
                                    >
                                    </ng-select>
                                </div>
                                <div class="col-md-2 form-group" has-error>
                                    <ng-select
                                        *ngIf="andClause.get('fieldName') as fieldName"
                                        edit
                                        formControlName="operation"
                                        [items]="fieldName.value ? criteria.operations[fieldName.value.typeId] : []"
                                        bindLabel="title"
                                        placeholder="Select Operation"
                                        (change)="andClause.operationChanged(true)"
                                    >
                                    </ng-select>
                                </div>
                                <ng-container *ngIf="andClause.get('value') as value">
                                    <div *ngIf="value.shouldDisplay" class="col-md-2 form-group" has-error>
                                        <ng-select
                                            *ngIf="value.valueType === 'CATEGORY'"
                                            edit
                                            formControlName="value"
                                            [items]="value.valueOptions"
                                            bindValue="id"
                                            bindLabel="title"
                                            placeholder="Select Value"
                                            (change)="andClause.fieldValueChanged(true)"
                                        >
                                        </ng-select>
                                        <input
                                            *ngIf="value.isFreeText"
                                            edit
                                            type="text"
                                            class="form-control"
                                            formControlName="value"
                                            placeholder="Value"
                                            (change)="andClause.fieldValueChanged(true)"
                                        />
                                        <input
                                            *ngIf="value.isDate"
                                            edit
                                            class="form-control"
                                            formControlName="value"
                                            bsDatepicker
                                            placeholder="MM/DD/YYYY"
                                            (change)="andClause.fieldValueChanged(true)"
                                        />
                                    </div>
                                </ng-container>
                                <div class="col-md-a">
                                    <a (click)="removeAndCondition(ii, jj)">Remove</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <a (click)="addAndClause(orClause)">+ AND Condition</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b *ngIf="orClauses.length != ii + 1">OR</b>
            </div>
            <button type="button" class="btn btn-default" (click)="addOrClause()">{{ addAndConditionButtonLabel }}</button>
        </ng-container>
    </ng-container>
</section>
