import { Component, forwardRef, Input, OnInit } from '@angular/core';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';

import {
    ScorecardComment,
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments.interface';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';


@Component({
    templateUrl: './scorecard-finding-details-comments.component.html',
    selector: 'finding-comments',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardFindingDetailsCommentsComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardFindingDetailsCommentsComponent) },
    ],
})
export class ScorecardFindingDetailsCommentsComponent extends NewPagedListComponent<ScorecardComment> implements OnInit {
    static listName = 'findingCommentsList';
    @Input() tpoId: number;
    @Input() findingId: number;

    constructor(
        public uiRouter: UIRouter,
        private readonly scorecardService: ScorecardService,
    ) {
        super(uiRouter);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
    }

    loadList(params: ListParams): Observable<PagedData<ScorecardComment[]>> {
        return this.scorecardService.getScorecardFindingComments(this.tpoId, this.findingId, params);
    }

    refreshPage() {
        this.updateList();
    }
}
