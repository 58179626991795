export const InviteStatuses = new Map([
	['SENT', {
		id: 'SENT',
		name: 'Sent',
		color: 'warning',
	}],
	['ACCEPTED', {
		id: 'ACCEPTED',
		name: 'Accepted',
		color: 'success',
	}],
	['DECLINED', {
		id: 'DECLINED',
		name: 'Declined',
		color: 'danger',
	}],
	['CANCELLED', {
		id: 'CANCELLED',
		name: 'Cancelled',
		color: 'danger',
	}],
]);
