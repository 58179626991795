<div class="finding">
    <div class="remote-resource gray-head flex-head" [class.resolved]="!configurationDataIsLoading">
        <h2 *ngIf="ownerInformation">
            {{ownerInformation.fullName}}
        </h2>
    </div>

    <div *ngIf="keyedScorecardFindingCategories" class="remote-resource" [class.resolved]="!resolving && !markingFindingAsReviewed">
        <div class="rows-bordered">
            <div class="row-fluid header">
               <span columnName="categoryId" class="col-sm-7">
					<b>Category</b>
				</span>
                <sortable-header columnName="createdDate" class="col-sm-3 text-left">
					Date Added
				</sortable-header>
                <sortable-header columnName="reviewed" class="col-sm-2 text-center">
                    Reviewed By
                </sortable-header>
            </div>

            <div class="row-fluid findings" *ngFor="let item of list | async">
               <span class="col-sm-7 text-bigger text-muted">
                   <span *ngIf="isUserCommergence" class="glyphicon" [ngClass]="item.isVisible ? 'glyphicon-eye-open' : 'glyphicon-eye-close'">&nbsp;</span>
                   <a [uiSref]="sref" [uiParams]="{sType: item.categoryId, sid: item.id}">
                        <span>{{keyedScorecardFindingCategories[item.categoryId].title}}</span>
                   </a>
               </span>
                <span class="col-sm-3">
                    <span>{{item.createdDate | date : 'MM/dd/yyyy'}}</span>
                </span>
                <span class="col-sm-2 text-center">
                    <scorecard-finding-summary-mark-reviewed
                        [isUserCommergence]="isUserCommergence"
                        [hasReviewFindingPermission]="hasReviewFindingPermission"
                        [findingId]="item.id"
                        [isUpdated]="item.isUpdated"
                        [reviewed]="item.reviewed"
                        [reviewer]="item.reviewer"
                        [tpoId]="tpoId"
                        [lenderId]="lenderId"
                        [isUserLender]="isUserLender"
                        [hasEditAndViewAlertPermission]="hasEditAndViewAlertPermission"
                        (findingReviewed)="reloadList()"
                        (resolving)="setMarkingReviewed($event)">
                    </scorecard-finding-summary-mark-reviewed>
                </span>
            </div>
            <div class="row-fluid" *ngIf="(list | async)?.length == 0">
                <span class="col-md-12 text-muted text-center text-bigger">No matches found</span>
            </div>
            <br>
            <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
        </div>
    </div>
</div>
