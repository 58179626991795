import { Injectable } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ChannelInfo } from 'lender/clients/$id/channels/channels.service';

import { ChannelPurchaseModalComponent } from 'lender/clients/$id/channels/single/details/purchase-modal/purchase.modal';


@Injectable({
	providedIn: 'root',
})
export class PurchaseRelationshipModalService {
    constructor(
        private readonly modalService: BsModalService,
    ) {
    }

    showPurchaseModal(
        channelId: number,
        tpoId: number,
        afterSave: (channelInfo: ChannelInfo) => any,
    ): BsModalRef {
        const initialState: Partial<ChannelPurchaseModalComponent> = {
            tpoId,
            channelId,
            afterSave,
        };

        const modalRef: BsModalRef = this.modalService.show(ChannelPurchaseModalComponent, {
            initialState,
            class: 'modal-md modal-new',
        });

        return modalRef;
    }
}
