import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealmHttpClient } from 'commons/services/http';

export interface Status {
    id: string;
    name: string;
}

export interface TransitionsMap {
    [key: string]: Transition;
}

export interface Transition {
    changeRenewal: boolean;
    name: string;
    renewal: boolean;
    availability: Status[];
}

declare let apiPath: string;

@Injectable()
export class RelationshipStatusBadgeService {

    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getBadge(status: Status): string {
        if (!status) {
            return '';
        }
        switch (status.id) {
            case 'A':
                return 'label label-success text-large';
            case 'T':
            case 'C':
            case 'WD':
            case 'D':
                return 'label label-danger';
            case 'P':
            case 'PR':
                return 'label label-warning';
            default:
                return 'label label-default';
        }
    }

    getStatusTransitions(): Observable<TransitionsMap> {
        return this.http.request<any>(
            'GET',
            `${apiPath}/applications/approval-statuses/transitions`,
        );
    }
}
