<div class="remote-resource" [class.resolved]="list.$resolved">
    <notification></notification>
    <div *ngIf="userProfile?.can('CCM_MANAGE_DOCUMENT_LIBRARY')" class="filters-row">
        <div class="row-fluid">
            <div class="col-sm-10 text-right">
                <a uiSref="documents.new" class="btn btn-primary">Add Document</a>
            </div>
        </div>
    </div>
	<div class="rows-bordered">
		<div class="row-fluid no-border" *ngFor="let item of list">
			<comergence-document-list-item [document]="item"></comergence-document-list-item>
		</div>

		<div class="row-fluid no-border" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center va-m text-bigger text-muted">No documents</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
