import { Component, Input } from '@angular/core';

import {
    TPOFinancialDocumentField,
} from 'commons/services/financial-documents.interface';

@Component({
    selector: 'tpo-financial-summary',
    templateUrl: './tpo-financial-summary.component.html',
})
export class TpoFinancialSummaryComponent {
    @Input() tpoFinancialDocuments: TPOFinancialDocumentField[];
}
