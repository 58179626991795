<section
	class="remote-resource"
	[ngClass]="{ resolved: (!UserService?.profile?.isLender || (channels?.$resolved && roles?.$resolved && licensesOptions?.$resolved && managers?.$resolved)) && user?.$resolved,
		preview: !editable }"
>
	<div class="alert text-left {{pageNotification?.type}}" *ngIf="pageNotification">
		<span [ngSwitch]="pageNotification.type">
			<i *ngSwitchCase="alert-success" class="glyphicon glyphicon-ok-sign">&nbsp;</i>
		</span>
		<span>{{pageNotification?.message}}</span>
	</div>

	<form [formGroup]="userForm" id="userForm" (onValidSubmit)="save()">
		<div class="clearfix mb20" *ngIf="!settingsView && hasPermission('MANAGE_USERS')">
			<div class="pull-left" *ngIf="!isNew && hasPermission('MANAGE_USERS')">
				<button type="button" class="btn btn-default" (click)="resetPassword()" [disabled]="passwordResent || editable"
					*ngIf="hasPermission('MANAGE_USERS') && (user?.status?.id === userStatus.ACTIVE)">Reset Password</button>

				<button type="button" class="btn btn-danger-wired" (click)="deactivate()" [disabled]="editable || doingaction"
					*ngIf="hasPermission('MANAGE_USERS') && (user?.status?.id === userStatus.ACTIVE)">Deactivate User</button>

				<button type="button" class="btn btn-success-wired" (click)="activate()" [disabled]="editable"
					*ngIf="hasPermission('MANAGE_USERS') && (user?.status?.id === userStatus.INACTIVE)">Activate User</button>
			</div>

			<div class="pull-right" *ngIf="hasPermission('EDIT_USERS')">
				<a class="btn btn-primary"
					*ngIf="!editable && !isNew"
					uiSref="users-management.users.:actionType.:id"
					[uiParams]="{ id: user?.id, actionType: 'edit' }"
				>
					<span class="glyphicon glyphicon-pencil"></span>Edit
				</a>

				<input type="submit" class="btn btn-primary" *ngIf="editable" tabindex="16" value="Save">

				<a class="btn btn-default"
					*ngIf="editable && !isNew"
					uiSref="users-management.users.:actionType.:id"
					[uiParams]="{ id: user?.id, actionType: 'view' }"
				>
					Cancel
				</a>

				<a class="btn btn-default"
					*ngIf="editable && isNew"
					uiSref="users-management.users"
				>
					Cancel
				</a>
			</div>
		</div>

		<form-errors [form]="userForm"></form-errors>

		<div class="mb20">
			<h3>User Information</h3>
		</div>

		<div class="rows-striped ">
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('systemRoleId')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('systemRoleId')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<ng-select
										*ngIf="roles?.$resolved"
										formControlName="systemRoleId"
										[items]="roles"
										bindValue="id"
										bindLabel="name"
										[clearable]="true"
										placeholder="Choose System Role"
										tabindex="3">
									</ng-select>
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{getRoleById(user?.systemRoleId)}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('email')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('email')}}</label>
						<div [ngClass]="{ 'col-sm-6': !editable, 'col-sm-8': editable }">
							<span [class.control-value]="!editable">
								<editable-component [editable]="editable">
									<ng-template #editTemplate>
										<input type="email"
											formControlName="email"
											class="form-control"
											maxlength="100"
											tabindex="6"
											autocomplete="off"
										/>
									</ng-template>
									<ng-template #viewTemplate>
										<a
											*ngIf="user?.email && !user?.isEmailBounced"
											href="mailto:{{user?.email}}"
										>
											{{user?.email || '&ndash;'}}
										</a>
										<span *ngIf="!user?.email || user?.isEmailBounced">{{user?.email || '&ndash;'}}</span>
										<span *ngIf="user?.isEmailBounced">
											&nbsp;<span
												tooltip="Bounce Email"
												placement="top-right"
												class='text-danger glyphicon glyphicon-exclamation-sign'
											></span>
										</span>
									</ng-template>
								</editable-component>
							</span>
						</div>
						<div class="col-sm-2" *ngIf="user?.isEmailBounced && !editable && !unbouncing">
							<a class="btn btn-primary" (click)="unbounceEmail()">Release</a>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('firstName')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('firstName')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="firstName" formControlName="firstName" class="form-control" maxlength="50" tabindex="4" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.firstName || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('phone')">
						<label class="col-sm-3 control-label">{{getLabel('phone')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input phoneFormatter type="text" name="phone" formControlName="phone" class="form-control" tabindex="7" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{(user?.phone || '&ndash;') | tel}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('lastName')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('lastName')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="lastName" formControlName="lastName" class="form-control" maxlength="50" tabindex="5" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.lastName || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('isComergenceRepresentative')">
						<label class="col-sm-3 control-label">{{getLabel('isComergenceRepresentative')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<div class="control-value">
										<input type="checkbox"
											name="isComergenceRepresentative"
											formControlName="isComergenceRepresentative"
											class="form-control checkbox-input"
											tabindex="8"
										>
									</div>
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										<span class="glyphicon"
											[ngClass]="{'glyphicon-ok text-success': user.isComergenceRepresentative,
												'glyphicon-remove text-danger': !user.isComergenceRepresentative}"
											aria-hidden="true"
										></span>
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6"></div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('reportingLicense')">
						<label class="col-sm-3 control-label">{{getLabel('reportingLicense')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<ng-select
										*ngIf="licensesOptions?.$resolved"
										formControlName="reportingLicense"
										[items]="licensesOptions"
										[clearable]="false"
										bindValue="shortName"
										bindLabel="name"
										tabindex="9">
									</ng-select>
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.reportingLicense}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</section>
