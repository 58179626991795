<div class="card-header" (click)="toggle()">
    <div class="card-header-title">
        <ng-content select="[title]"></ng-content>
    </div>
    <div class="card-header-buttons">
        <div class="collapse-button"><span class="c-icon c-icon-chevron-left"></span></div>
    </div>
</div>
<div class="card-contents" *ngIf="persistantTemplate() || this.expanded()" @showBox>
    <ng-template [ngTemplateOutlet]="persistantTemplate()"></ng-template>
    <div *ngIf="this.expanded()" class="expandable" @showBox>
        <ng-content></ng-content>
    </div>
</div>
