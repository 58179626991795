import { Directive, HostListener, Input } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { NotificationModalComponent } from 'commons/components/modals';

import electronicSignatureModalConfig from './electronicSignatureModalConfig';

@Directive({
	selector: '[documentLink]',
})
export class DownloadDocumentDirective {
	@Input() documentLink: string;
	@Input() modalConfig?: ModalOptions = electronicSignatureModalConfig;
	modalRef: BsModalRef;

	constructor(
		public modalService: BsModalService,
	) {}

	@HostListener('click') onClick(): void {
		if (this.documentLink) {
			const documentWindow = window.open(this.documentLink, '_blank');
			documentWindow.addEventListener('load', () => {
				documentWindow.document.body.style.display = 'none';
				try {
					const response = JSON.parse(documentWindow.document.body.textContent);
					if (response?.errorCode === 'INVALID_CONNECTION') {
						documentWindow.close();
						this.showElectronicSignatureModal();
					}
				} catch (err) {}
			});
		}
	}

	showElectronicSignatureModal(): void {
		this.modalRef = this.modalService.show(NotificationModalComponent, this.modalConfig);
	}
}
