import { Component, OnInit } from '@angular/core';

import { UIRouter } from '@uirouter/core';

import { tap } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import { ScorecardService } from '../services/scorecard.service';
import { ScorecardMetricsAnnotatorService } from '../services/scorecard-metrics-annotator.service';
import { ScorecardUtilityService } from '../services/scorecard-utility.service';

import { SortedOwnerFindings } from '../scorecard-owners.interface';
import { ScorecardFindingCategoryMetrics, ScorecardFindingSection, ScorecardFindingsMetrics } from '../scorecard-findings-metrics.interface';
import { KeyedScorecardFindingCategories, ScorecardFindingCategories } from '../scorecard-finding-category.interface';


@Component({
	templateUrl: './scorecard-summary.component.html',
})
export class ScorecardSummaryComponent implements OnInit {
    tpoId: (number | string);
    isCCM: boolean = false;

    ownerFindings: SortedOwnerFindings;

    scorecardFindingCategories: ScorecardFindingCategories;
    keyedScorecardFindingCategories: KeyedScorecardFindingCategories;
    scorecardFindingsMetrics: ScorecardFindingsMetrics;

    resolved: boolean = true;

    constructor(
        private readonly scorecardService: ScorecardService,
        private readonly scorecardMetricsAnnotatorService: ScorecardMetricsAnnotatorService,
        private readonly scorecardUtilityService: ScorecardUtilityService,
        private readonly uiRouter: UIRouter,
        private readonly userService: UserService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.uiRouter.globals.params.id;

        this.isCCM = this.userService.profile.isComergence;

        this.resolved = false;
        [
            this.ownerFindings,
            this.scorecardFindingCategories,
            this.scorecardFindingsMetrics,
        ] = await Promise.all([
            this.scorecardService.getSortedScorecardOwners(this.tpoId)
                .pipe(
                    tap((owners: SortedOwnerFindings) => {
                        owners.Current = owners.currentOwners;
                        owners.Previous = owners.previousOwners;
                    })
                )
                .toPromise(),
            this.scorecardService.getScorecardCategories(this.tpoId).toPromise(),
            this.scorecardService.getScorecardSummaryMetrics(this.tpoId)
                .pipe(
                    tap((findingMetrics: ScorecardFindingsMetrics) => {
                        findingMetrics.sections.forEach((section: ScorecardFindingSection) => {
                            // TODO: Remove this stuff when the LIC summary page is reimplemented
                            section.metrics.forEach((metrics: ScorecardFindingCategoryMetrics) => {
                                this.scorecardMetricsAnnotatorService.annotateMetrics(metrics, this.isCCM, this.tpoId);
                            });
                        });
                    })
                )
                .toPromise(),
        ]);
        this.resolved = true;

        this.keyedScorecardFindingCategories = this.scorecardUtilityService.keyCategoriesByCode(this.scorecardFindingCategories);
    }
}
