export enum DocumentStatus {
	NOT_REQUESTED = 'NOT_REQUESTED',
	REQUESTED = 'REQUESTED',
	UPLOADED = 'UPLOADED',
	WAIVED = 'WAIVED',
	INACTIVE = 'INACTIVE',
}

export interface Document {
	id: number;
	libraryId?: number;
	name: string;
	description: string;
	hasTemplate?: boolean;
	isUploadable?: boolean;
	docusignTemplate?: DocusignTemplate;
}

export interface ClientDocument extends Document {
	isCSD?: boolean;
	isActive?: boolean;
	isExpired?: boolean;
	documentState: DocumentStatus;
	hasUploadedDocument?: boolean;
    hasUnreviewedDocuments?: boolean;
	// DocuSign
	isFullySigned?: boolean;
	lastHistoryAction?: string;
	lastHistoryActionDate?: number;

	$pending?: boolean;
	$success?: boolean;
	$error?: string;
    reviewedBy?: string;
    reviewedDate?: number;
}

export interface ClientLibraryDocument extends ClientDocument {
	requestsCount: number;
}

export interface DocusignTemplate {
	docusignTemplateId: number;
	templateId: string;
}
