import { Component, Input } from '@angular/core';

import _ from 'lodash';

import { ScorecardFindingCategoryCode } from 'shared/due-diligence/scorecard/scorecard-finding-category.interface';


@Component({
    selector: '[scorecard-summary-findings-metrics-count],scorecard-summary-findings-metrics-count',
	templateUrl: './scorecard-summary-findings-metrics-count.component.html',
    host: { 'class': 'col-sm-a' },
})
export class ScorecardSummaryFindingsMetricsCountComponent {
    @Input() tpoId: (number | string);
    @Input() notReviewedCount: number;
    @Input() totalCount: number;
    @Input() categoryCode: ScorecardFindingCategoryCode;
    @Input() sref: string;
    @Input() srefParams: any;
}
