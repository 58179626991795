import { Component, forwardRef, OnInit } from '@angular/core';
import { CustomerService } from 'comergence/customers/services/customer-list/customer.service';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { CustomerList } from 'comergence/customers/services/customer-list/customer.service.interface';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { statusConfig } from './statusConfig';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: './customer-list.component.html',
    styles: ['.table-row {padding: 10px; border-bottom: 1px solid #E6E7EC}'],
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => CustomerListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => CustomerListComponent) },
    ],
})
export class CustomerListComponent extends NewPagedListComponent<CustomerList> implements OnInit{
    static listName = 'customerListComponent';
    statusConfig = statusConfig;
    private user: UserProfile;
    canManageCustomers: boolean;

    constructor(
        router: UIRouter,
        private customerService: CustomerService,
        private userService: UserService
    )
    {
        super(router);
        this.customerService = customerService;
        this.user = this.userService.profile;
        this.canManageCustomers = this.user?.can('CCM_MANAGE_CUSTOMERS');
        this.ngOnInit();
    }

    ngOnInit(): void {
         super.ngOnInit();
    }

    protected loadList(params: ListParams): Observable<PagedData<CustomerList[]>> {
        return this.customerService.getCustomersList(params);
    }
}
