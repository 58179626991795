import { cloneDeep } from 'lodash';
import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PagedData } from 'commons/services/http';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';

import { UserService } from 'angularjs-providers/user.provider';

import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { RelationStatusColors } from 'lender/relation-status-colors';

import { ChannelInfo, ChannelsService } from 'lender/clients/$id/channels/channels.service';
import { LocationListItem } from './locations.interface';
import { ActionBy } from '../../applications/channel-application.interface';
import { Availability } from '../../../../clients.interface';

@Component({
	selector: 'channel-location-list',
	templateUrl: './channel-locations.component.html',
	host: { class: 'channel-locations' },
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ChannelLocationsComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => ChannelLocationsComponent) },
	],
})
export class ChannelLocationsComponent extends NewPagedListComponent<LocationListItem> implements OnInit {
	static listName = 'channelLocationList' as const;

	@Input() channelInfo: ChannelInfo;

	initialList: LocationListItem[];
	canEditStatus = false;
	canEditAssigment = false;
	tpoId: number;
	channelId: number;
	relationStatusColors = RelationStatusColors;
	accountExecutives: Observable<ActionBy[]>;
	availableStatuses: Availability[];

	constructor(
		router: UIRouter,
		{ params: { id } }: UIRouterGlobals,
		{ profile: User }: UserService,
		public channelsService: ChannelsService,
		private readonly globalNotificationsService: GlobalNotificationsService,
	) {
		super(router);
		this.defaultParams.size = 25;
		this.tpoId = id;

		this.canEditStatus = User.can('EDIT_LOCATION_STATUS');
		this.canEditAssigment = User.can('EDIT_LOCATION_ASSIGNMENT');
	}

	ngOnInit() {
		const { channelId } = this.channelInfo;
		this.channelId = channelId;
		this.accountExecutives = this.channelsService.getAccountExecutives(channelId);
		this.availableStatuses = [
			//{"id": "P", "name": "Pending"},  //Temporary
			{'id': 'A', 'name': 'Active'},
			{'id': 'I', 'name': 'Inactive'},
			{'id': 'S', 'name': 'Suspended'},
			{'id': 'W', 'name': 'Watchlist'},
			{'id': 'T', 'name': 'Terminated'},
		];

		super.ngOnInit();
	}

	protected loadList(params: ListParams): Observable<PagedData<LocationListItem[]>> {
		return this.channelsService.getChannelLocations(this.tpoId, this.channelId, params).pipe(
			tap(pagedData => this.initialList = cloneDeep(pagedData.data))
		);
	}

	// getAvailableStatuses(): Observable<Availability[]> {
	// 	const currentStatusId = this.channelInfo.approvalStatus.id;
	// 	return this.channelsService.getStatusTransitions().pipe(
	// 		map((transitions) => transitions[currentStatusId].availability)
	// 	);
	// }

	async save(item: LocationListItem & { editing: boolean; loading: boolean }, i: number): Promise<void> {
		const {
			tpoChannelLocationApprovalId,
			nmlsId,
			approvalStatus,
			assigned,
		} = item;

		item.loading = true;
		try {
			await this.channelsService.setChannelLocation(this.tpoId, this.channelId, {
				tpoChannelLocationApprovalId,
				nmlsId,
				approvalStatus,
				...(assigned ? {
					assigned: {
						id: assigned.id,
						fullName: assigned.personalName,
					},
				} : {}),
			}).toPromise();
			this.initialList[i] = cloneDeep(item);

			item.editing = false;
		} catch ({ error: { message } }) {
			this.globalNotificationsService.add({
				type: GlobalNotificationType.ERROR,
				message,
			});
		}
		item.loading = false;
	}

	assignedChange(locationItem: LocationListItem, { id, fullName, userName }: ActionBy): void {
		locationItem.assigned = { id, personalName: fullName, userName };
	}

	edit(item: LocationListItem & { editing: boolean }): void {
		item.editing = true;
	}

	cancel(item: LocationListItem & { editing: boolean }, i: number): void {
		item.assigned = cloneDeep(this.initialList[i].assigned);
		item.approvalStatus = cloneDeep(this.initialList[i].approvalStatus);
		item.editing = false;
	}
}
