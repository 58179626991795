import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { DocumentScheduleSettingsCardModule } from 'shared/document-schedule-settings-card/document-schedule-settings-card.module';

import { FinancialsManagementHistoryService } from './services/financials-management-history.service';
import { FinancialDocumentScheduleSettingsService } from 'shared/financial-documents/financial-document-schedule-settings.service';

import { ManageFinancialDocumentsComponent } from 'shared/financial-documents/management/manage-financial-documents.component';
import { FinancialsManagementHistoryComponent } from './management/history/financials-management-history.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
        DocumentScheduleSettingsCardModule,
    ],
	declarations: [
		ManageFinancialDocumentsComponent,
        FinancialsManagementHistoryComponent,
	],
	providers: [
        FinancialsManagementHistoryService,
		FinancialDocumentScheduleSettingsService,
	],
})
export class FinancialDocumentsModule {}
