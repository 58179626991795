import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { NgResourceObject } from 'commons/interfaces';

import { ComergenceDocument, ComergenceLibraryDocument } from '../comergence-documents.interface';
import { ComergenceDocumentsService } from '../comergence-documents.service';


@Component({
	templateUrl: './comergence-document.component.html',
})
export class ComergenceDocumentComponent implements OnInit {
	title: string;

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		private documentsService: ComergenceDocumentsService,
	) {}

	async ngOnInit(): Promise<void> {
		const {
			documentId,
			libraryId,
		} = this.uiRouterGlobals.params;
		try {
			const { name }: NgResourceObject<ComergenceDocument | ComergenceLibraryDocument> = /*documentId
				?*/ await this.documentsService.getLibraryDocument({ documentId }).$promise;
				// : await this.documentsService.getClientLibraryDocument({ libraryId }).$promise;
			this.title = name;
		} catch (e) {}
	}
}
