<h3>Social Media</h3>

<div class="social-accounts-company remote-resource" [class.resolved]="!resolving">
    <div class="flex-row gap mb20">
        <div class="w250">
            <search-list placeholder="Account Name, Network, URL"></search-list>
        </div>
        <div class="flex-grow text-right" *ngIf="canManage || isCurrentUser">
            <button type="button" class="btn btn-primary"
                    (click)="newSocialMediaLink.toggleShow(true)"
                    *ngIf="!newSocialMediaLink.show"
            >
                <span class="glyphicon glyphicon-plus"></span>
                Add Link
            </button>
        </div>
    </div>

    <div class="clearfix mb20" [hidden]="!newSocialMediaLink.show">
        <new-sm-link #newSocialMediaLink [save]="addLink"></new-sm-link>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header [ngClass]="columnClass.account" columnName="account">Account</sortable-header>
            <sortable-header [ngClass]="columnClass.lastConnectionStatus" columnName="lastConnectionStatus" *ngIf="!isLender">Status</sortable-header>
            <div [ngClass]="columnClass.rss" *ngIf="isComergence"><b>RSS</b></div>
            <div [ngClass]="columnClass.actions"><b>&nbsp;</b></div>
        </div>

        <reset-filters class="row-fluid"></reset-filters>

        <div class="row-fluid" [class.danger]="socialAccountLink?.ui?.isDangerClass" *ngFor="let socialAccountLink of list | async">
            <div class="va-m" [ngClass]="columnClass.account">
                <div class="flex-row gap">
                    <sm-icon
                        [config]="socialAccountLink?.data"
                        [showRssStatus]="socialAccountLink?.data?.monitoringStatus === 'on'"
                    ></sm-icon>
                    <div class="flex text-overflow">
                        <ng-container
                            *ngIf="socialAccountLink?.data?.networkCode !== 'RS' && socialAccountLink?.data?.link; then companyLinkAnchor; else companyLinkName"></ng-container>
                        <ng-template #companyLinkAnchor>
                            <a [href]="socialAccountLink?.data?.link | hrefReady" target="_blank">{{socialAccountLink?.data?.name}}</a>
                        </ng-template>
                        <ng-template #companyLinkName>
                            {{socialAccountLink?.data?.name}}
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="va-m" [ngClass]="columnClass.lastConnectionStatus" *ngIf="!isLender">
                <company-monitoring-status
                    [status]="socialAccountLink?.data?.lastConnectionStatus"
                    [isRemovalRequested]="socialAccountLink?.data?.isRemovalRequested"
                ></company-monitoring-status>
            </div>
            <div class="va-m" [ngClass]="columnClass.rss" *ngIf="isComergence">
                <manage-rss
                    [params]="socialMediaServiceParams"
                    [link]="socialAccountLink"
                    [canManage]="canManageRss"
                    (reload)="updateList()"
                ></manage-rss>
            </div>
            <div class="va-m" [ngClass]="columnClass.actions">
                <div class="flex-row right gap">
                    <div class="medium-icon-holder" *ngIf="socialAccountLink?.data?.isRemovedFromSourceSystem && !isLender">
							<span class="glyphicon medium-icon text-danger glyphicon-remove-sign va-m"
                                  tooltip="Removed from {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}}"
                            ></span>
                        {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}} Removed
                    </div>

                    <ng-container
                        *ngIf="isComergence && canManage && socialAccountLink.data?.isRemovalRequested"
                        [ngTemplateOutlet]="manageLinkControls"
                        [ngTemplateOutletContext]="{ link: socialAccountLink }"
                    ></ng-container>

                    <ng-container
                        *ngIf="!!socialAccountLink?.data?.publisherStatus || canRemove && socialAccountLink?.ui?.hasActionDropdown || isCurrentUser && socialAccountLink?.data?.networkCode === 'YT'; then dropdownActive; else dropdownDisabled"></ng-container>
                    <ng-template #dropdownActive>
                        <div class="dropdown" dropdown>
                            <a class="dropdown-toggle btn btn-icon btn-tertiary text-bigger text-very-light" dropdownToggle>
                                <span class="c-icon c-icon-dot-3"></span>
                            </a>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                                [ngSwitch]="socialAccountLink?.data?.publisherStatus">
                                <li role="menuitem" *ngIf="canRemove && socialAccountLink?.ui?.hasActionDropdown">
                                    <a (click)="removeLink(socialAccountLink)">Remove</a>
                                </li>
                                <li role="menuitem" *ngSwitchCase="'A'">
                                    <a (click)="removeCurrentPublisherAccess(socialAccountLink)">Remove Access</a>
                                </li>
                                <li role="menuitem" *ngSwitchCase="'R'">
                                    <a (click)="requestAccess(socialAccountLink, true)">Cancel Request</a>
                                </li>
                                <li role="menuitem" *ngIf="isCurrentUser && socialAccountLink?.data?.networkCode === 'YT'">
                                    <a href="https://myaccount.google.com/permissions?pli=1" target="_blank">
                                        Revoke Access
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                    <ng-template #dropdownDisabled>
                        <span class="btn btn-icon btn-icon-disabled"></span>
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
				<span class="col-sm-12 text-center text-bigger text-muted va-m">
					{{noMatches ? 'No accounts found' : 'No accounts connected'}}
				</span>
        </div>
    </div>

    <br>
    <paginator [sizes]="[10,25,50]"></paginator>
</div>

<ng-template #manageLinkControls let-link="link">
    <div class="flex-row right gap">
        <button type="button" class="btn btn-danger" (click)="manageLink(link, 'acceptRemoval')">Remove</button>
        <button type="button" class="btn btn-secondary" (click)="manageLink(link, 'declineRemoval')">Keep</button>
    </div>
</ng-template>
