<form [formGroup]="alertForm">
    <div [isEditable]="editing">
        <div view>
            <span class="glyphicon"
                  [class.glyphicon-ok]="alert.enabled"
                  [class.text-success]="alert.enabled"
                  [class.glyphicon-remove]="!alert.enabled"
                  [class.text-danger]="!alert.enabled"
            ></span>
            {{alert.alertCategory}}
            <span *ngIf="shouldShowPercentage"> >{{alert.settingValue}}% </span>
        </div>
        <div edit class="row-fluid">
            <div class="col-sm-10">
                {{alert.alertCategory}}
                <ng-container *ngIf="shouldShowPercentage">
                    >
                    <label class="form-group weight-normal" has-error>
                        <input
                            class="form-control percent-field"
                            type="text"
                            maxlength="3"
                            formControlName="settingValue"
                            validFloat="1.0-0"
                            (change)="onPercentChanged()"
                        />
                    </label>
                    %
                </ng-container>
            </div>
            <div class="col-sm-2">
                <span class="pull-right">
                    <input type="checkbox" formControlName="enabled" (change)="onAlertChanged()"/>
                </span>
            </div>
        </div>
    </div>
</form>
