import { Injectable } from '@angular/core';
import { union } from 'lodash';

import { UserProfile } from 'angularjs-providers/user.provider';
import { RealmFormArray, RealmFormControl } from 'commons/forms';
import {
    ContactList,
    ContactListDesignatedOption,
    ContactListSegmentFilter,
    ContactListSegmentFilterAndClause,
    ContactListSegmentFilterOrClause,
} from '../investor-crm-contact-list.interface';
import { AndClauseFormGroup } from '../contact-list-container/investor-crm-contact-list/or-clause-manager/AndClauseFormGroup';
import { OrClauseFormArray } from '../contact-list-container/investor-crm-contact-list/or-clause-manager/OrClauseFormArray';
import { ValueFormControl } from '../contact-list-container/investor-crm-contact-list/or-clause-manager/ValueFormControl';
import { DateFormatterService } from './date-formatter.service';


@Injectable()
export class InvestorCrmContactListBuilderService {
    static readonly DATE_FORMAT: string = 'MM/DD/YYYY';

    constructor(
        private readonly dateFormatterService: DateFormatterService,
    ) {
    }

    buildContactList(
        userProfile: UserProfile,
        listNameFormControl: RealmFormControl,
        designatedOptionFormControl: RealmFormControl,
        selectedDesignatedUsersControl: RealmFormControl,
        orClauseFormArray: RealmFormArray,
    ): ContactList {
        const name: string = listNameFormControl.value;
        const designatedOption: ContactListDesignatedOption = this.buildContactListDesignatedOption(
            userProfile,
            designatedOptionFormControl,
            selectedDesignatedUsersControl
        );
        const segmentFilter: ContactListSegmentFilter = this.buildSegmentFilter(orClauseFormArray);

        const contactList: ContactList = {
            name,
            designatedOption,
            segmentFilter,
        };

        return contactList;
    }

    buildContactListDesignatedOption(
        userProfile: UserProfile,
        designatedOptionFormControl: RealmFormControl,
        selectedDesignatedUsersControl: RealmFormControl,
    ): ContactListDesignatedOption {
        const enabled: boolean = designatedOptionFormControl.value;
        const designatedUsers: number[] = (
            enabled ?
            union((selectedDesignatedUsersControl.value as number[]), [ userProfile.id ]) :
            []
        );

        const designatedOption: ContactListDesignatedOption = {
            enabled,
            designatedUsers,
        };

        return designatedOption;
    }

    buildSegmentFilter(
        orClauseFormArrays: RealmFormArray,
    ): ContactListSegmentFilter {
        const orClauses: ContactListSegmentFilterOrClause[] = orClauseFormArrays.controls
            .map((individualOrClauseFormArray: OrClauseFormArray) => this.buildOrClause(individualOrClauseFormArray));
        
        const segmentFilter: ContactListSegmentFilter = {
            orClauses: orClauses,
        };

        return segmentFilter;
    }

    buildOrClause(
        orClauseFormArray: RealmFormArray,
    ): ContactListSegmentFilterOrClause {
        const andClauses: ContactListSegmentFilterAndClause[] = orClauseFormArray.controls
            .map((andClauseFormGroup: AndClauseFormGroup) => this.buildAndClause(andClauseFormGroup));

        const orClause: ContactListSegmentFilterOrClause = {
            andClauses: andClauses,
        };

        return orClause;
    }

    buildAndClause(
        andClauseFormGroup: AndClauseFormGroup,
    ): ContactListSegmentFilterAndClause {
        const fieldType: string = andClauseFormGroup.getFieldTypeFormControl().value.id;
        const fieldName: string = andClauseFormGroup.getFieldFormControl().value.id;
        const operation: string = andClauseFormGroup.getOperationFormControl().value.id;

        const andClause: ContactListSegmentFilterAndClause = {
            fieldType,
            fieldName,
            operation,
        };
        const valueFormControl: ValueFormControl = (andClauseFormGroup.get(AndClauseFormGroup.VALUE_FIELD_NAME) as ValueFormControl);
        if (valueFormControl.isDate) {
            andClause.fieldValue = this.dateFormatterService.formatDate(valueFormControl.value, InvestorCrmContactListBuilderService.DATE_FORMAT);
        } else if (valueFormControl.shouldDisplay) {
            andClause.fieldValue = `${valueFormControl.value}`;
        }

        return andClause;
    }
}
