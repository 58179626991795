import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';

import { UserService } from 'angularjs-providers/user.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { ConfirmModalComponent } from 'commons/components/modals';

import { CodeValueService as CodeValue } from 'shared/code-value.service';
import { TpoIssuesStatusService } from 'tpo/status';

import { CommonListingSettingsComponent, getTimepickerConfig } from '../../../../../common';
import { CompanyListingsService, ListingGroup } from '../../../../../company-listings.service';

@Component({
	templateUrl: './details.component.html',
	providers: [ { provide: TimepickerConfig, useFactory: getTimepickerConfig } ],
})
export class ListingGroupListingDetailsComponent extends CommonListingSettingsComponent implements OnInit {

	settingsUpdating = false;
	modalRef: BsModalRef;

	constructor(
		stateService: StateService,
		modalService: BsModalService,
		userService: UserService,
		CodeValueService: CodeValue,
		companyListingsService: CompanyListingsService,
		public $state: $stateProvider,
		private tpoIssuesStatusService: TpoIssuesStatusService,
	) {
		super(stateService, modalService, userService, CodeValueService, companyListingsService);
	}

	getDetails(): any {
		return this.companyListingsService.listingGroupListingsDetails(this.getParams());
	}

	saveDetails(formData): any {
		return this.companyListingsService.saveListingGroupListingsDetails(this.getParams(), formData);
	}

	getParams() {
		const { listingGroupId, listingDetailsId } = this.stateService.params;
		return { listingGroupId, listingDetailsId };
	}

	toggleGroupSetting() {
		const { syncWithGroup } = this.details;

		if (!syncWithGroup) {
			const initialState = {
				title: 'Confirm',
				confirmText: 'Submit',
				message: `You are about to submit a data change.<br>
				          This listing will be managed by your group settings.`,
				onConfirm: () => {
					this.settingsUpdating = true;
					this.sendGroupToggle(!syncWithGroup);
					this.modalRef.hide();
				},
				onCancel: () => {
					this.settingsUpdating = false;
					this.modalRef.hide();
				},
			};

			this.modalRef = this.modalService.show(ConfirmModalComponent, {
				initialState,
				class: 'modal-smd modal-new',
				backdrop: 'static',
			});
		} else {
			this.sendGroupToggle(!syncWithGroup);
		}
	}

	sendGroupToggle(syncWithGroup: boolean) {
		this.settingsUpdating = true;
		this.companyListingsService.patchListingGroupListingsDetails(this.getParams(), { syncWithGroup })
			.$promise
			.then((newDetails) => {
				if (syncWithGroup) {
					this.tpoIssuesStatusService.socialMedia.listings.groups.update();
				}

				this.details = newDetails;
			})
			.finally(() => {
				this.settingsUpdating = false;
			});
	}

	updateDetails(response: ListingGroup) {
		// this.tpoStatusService.listings.groups.update(); should be called if state reload will go away to update alert status
		this.details.$resolved = true;
		this.$state.notify(
			this.stateService.current.name,
			{
				notification: {
					type: 'alert-success',
					message: 'Your changes have been submitted.',
					timeout: 10000,
				},
			},
			{
				reload: true,
			},
		);
	}
}
