import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'tpo-contact-menu',
    templateUrl: '../../../commons/components/navigation/menu/side/side-menu-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorContactMenuComponent extends SideMenuContainerComponent {
    private profile: UserProfile;

    constructor(
        changeDetector: ChangeDetectorRef,
        router: UIRouterGlobals,
        { profile, profile$ }: UserService,
    ) {
        super(changeDetector, router);
        this.menuUpdate$ = combineLatest([profile$, router.success$]).pipe(
            takeUntil(this.destroyed$),
        );

        this.profile = profile;
    }

    ngOnInit() {
        super.ngOnInit();
        this.populateMenu();
    }

    populateMenu = () => {
        const thisRoute = this.router.current.name.replace(/(\:contactId).*/, '$1');
        const skipSocialMedia = thisRoute.match(/(crm|nmls)\./);
        this.menuItems = [
            {
                title: 'General information',
                link: `${thisRoute}.information`,
            },
            ... skipSocialMedia ? [] : [{
                title: 'Social Accounts',
                items: [
                    {
                        title: 'Social Media',
                        link: `${thisRoute}.social-media`,
                    },
                    {
                        title: 'Web Presence',
                        link: `${thisRoute}.web-presence`,
                    },
                ],
            }],
            ...(!this.profile.crmEnabled ? [] : [{
                title: 'Lists',
                link: `${thisRoute}.lists`,
            }]),
            {
                title: 'Audit Log',
                link: `${thisRoute}.audit-log`,
            },
        ];
        this.changeDetector.detectChanges();
    }
}
