import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

declare let apiPath: string;

export type CommonValue = {
    shortName: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class CommonValuesService {
    constructor(public http: RealmHttpClient) {
    }

    get<T = CommonValue>(code: string): Observable<T[]> {
        return this.http.request<T[]>(
            'get',
            `${apiPath}/code-value/${code}`,
        );
    }

    getStates(): Observable<CommonValue[]> {
        return this.get('State');
    }

    getDesignations(removeNA = false): Observable<CommonValue[]> {
        return this.get('OfficerDesignation').pipe(
            map(designations => {
                return designations
                    .sort(({ shortName: l }, { shortName: r }) =>
                        (l == 'OO' ? 1 : r == 'OO' ? -1 : -l.localeCompare(r, 'en-US'))
                    )
                    .filter(({ shortName }) => shortName !== 'NA' || !removeNA);
            }),
        );
    }
}
