import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator, PhoneValidator } from 'commons/validators';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { UserProfile } from 'angularjs-providers/user.provider';
import { ZipResolveService } from 'shared/zip-resolve.service';

@Injectable({ providedIn: 'root' })
export class ContactFormGenerator {

    constructor(private zipResolveService: ZipResolveService) {
    }

    private formAdd = (form: RealmFormGroup) =>
        (name, state, validator?) => form.addControl(name, new RealmFormControl(name, state, validator));

    public get = (contact: FullTpoContact, user: UserProfile, isNew: boolean = false): RealmFormGroup => {
        const form = new RealmFormGroup({});
        const addControl = this.formAdd(form);
        const isCRM = user.isLender;

        if (!contact.nmlsId && !contact.ownershipPercentage) {
            addControl('firstName', { label: 'First Name' }, Validators.required);
            addControl('lastName', { label: 'Last Name' }, Validators.required);
            addControl('middleName', { label: 'Middle Name' });
        }

        addControl('address', { label: 'Street' });
        addControl('suite', { label: 'Suite' });
        addControl('city', { label: 'City' });
        addControl('state', { label: 'State' });
        addControl('zip', { label: 'Zip' }, isCRM ? [] : Validators.required);
        addControl('email', { label: 'Email' }, Validators.compose([...isCRM ? [] : [Validators.required], Validators.email]));
        !isCRM && addControl('officerDesignation', { label: 'Designation' }, DropdownRequiredValidator);
        addControl('phone', { label: 'Phone' }, PhoneValidator());
        addControl('contactTitle', { label: 'Title' });
        !isCRM && addControl('isPrimaryContact', { label: 'Notifications', updateOn: 'change' });

        if (isNew && ((user.isTpo && user.can('TPO_USERS')) || user.isComergence)) {
            addControl('role', { label: 'Role' });
        }

        (user.isTpo/* || user.isComergence*/) && addControl('reportingGroups', { label: 'Report Access' });

        form.patchValue(contact);

        form.get('zip').valueChanges.subscribe((code) => {
            if (code && code !== form.value.zip) {
                this.zipResolveService.get({ code }, (result) => {
                    if (result.zip) {
                        const { city, state } = result;
                        form.patchValue({ city, state });
                    }
                });
            }
        });

        return form;
    };
}
