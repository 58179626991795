<div *ngIf="!newMenu">
	<div class="gray-head flex-head">
		<h2>
			Social Accounts
		</h2>

		<div class="col-min">
			<ul class="tab-menu col-min">
				<li *ngFor="let tab of tabs" [class.active]="stateService?.current?.name.indexOf(tab.url) > -1">
					<a *ngIf="!tab.permission || User?.can(tab.permission)" [uiSref]="tab.url" [uiParams]="getParams(tab)">
						<b>{{tab.title}}</b>
					</a>
				</li>
			</ul>
		</div>
	</div>

	<div ui-view></div>
</div>

<tpo-social-media-menu *ngIf="newMenu" class="social-compliance">
    <ui-view>
        <landing-page title="Social Accounts" [items]="landingItems"></landing-page>
    </ui-view>
</tpo-social-media-menu>
