<tpo-social-media-menu>
	<div
		*ngIf="(activeItem && !activeItem?.permissionKey) || User.can(activeItem?.permissionKey); else noPermission"
		class="social-compliance-reviews"
	>
		<ng-container [ngTemplateOutlet]="reviewsView"></ng-container>
	</div>
</tpo-social-media-menu>

<ng-template #reviewsView>
	<div class="gray-head flex-head" *ngIf="activeItem?.tabs">
		<h2>Settings</h2>
		<div class="col-min">
			<ul class="tab-menu">
				<ng-container *ngFor="let item of activeItem.tabs">
					<li uiSrefActive="active">
						<a uiSref="{{item.sref}}">
							<b>{{item.title}}</b>
						</a>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
	<ui-view></ui-view>
</ng-template>

<ng-template #noPermission>
	<no-permissions [title]="activeItem?.title">
		<img src="/static/images/promo/sm-get-started/Icon_settings_off.svg" [alt]="activeItem?.title"/>
	</no-permissions>
</ng-template>
