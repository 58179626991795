import { assign, first } from 'lodash';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { AdvertisingModalComponent } from '../advertising-modal';
import { ReviewsResourceService } from '../../reviews-resource.service';
import { IndividualsModalComponent } from '../individuals-modal';
import { AccountDisconnectedModalComponent } from '../account-disconnected-modal';
import { Review } from '../../reviews.interface';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

@Component({
	selector: 'review',
	templateUrl: './review.component.html',
})
export class ReviewComponent {
	@Input() review: Review;
	openedReplyForms: { [key: number]: boolean; } = {};

	User: UserProfile;
	modalRef: BsModalRef;
	resolved: boolean = true;
	canPublishReview: boolean = false;
	// used to block multiple review reply tabs open
	replyReviewLoading: boolean = false;

	@Output() onAddReply: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		public userService: UserService,
		public stateService: StateService,
		public modalService: BsModalService,
		public reviewsResource: ReviewsResourceService,
	) {
		this.User = userService.profile;
        this.canPublishReview = userService.profile.hasFeature(PACKAGE_FEATURE.MARKETING);
	}

	post(review: Review): void {
		const { reviewId, networkId } = review;

		if (!this.canPublishReview) {
			this.reviewsResource.review.sendMarketingEmail();
			this.showAdvertisingModal();
			return
		}

		if (networkId === 'GMB' || networkId === 'FB' || networkId === 'YLP' || review.providerCode === 'ZLW') {
			this.resolved = false;

			assign(review, {
				chosenScore: first(review.scores),
			});

			this.reviewsResource.review.preparePost({ reviewId }, review).$promise.then((response) => {
				this.stateService.go('social-compliance.publisher.posts.new', { review: JSON.stringify(response) });
			});
		} else {
			this.stateService.go('.:reviewId', { reviewId });
		}
	}

	showAdvertisingModal(): void {
		this.modalRef = this.modalService.show(AdvertisingModalComponent, {
			class: 'modal-smd modal-new',
		});
	}

	showAccountDisconnectedModal(review: Review): void {
		this.modalRef = this.modalService.show(AccountDisconnectedModalComponent, {
			initialState: {
				contactId: this.User.contactId,
				icon: {
					networkCode: 'AD',
					reviewerAvatarUrl: review.reviewerAvatarUrl,
					title: !review?.networkId && review.providerName,
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	showIndividualsModal = (owners): void => {
		this.modalRef = this.modalService.show(IndividualsModalComponent, {
			initialState: {
				owners,
				resolving: false,
			},
			class: 'modal-smd modal-new',
		});
	};

	onOpenReplyForm(reviewId): void {
		if (!this.review.hasConnectedListing) {
			this.openedReplyForms[reviewId] = false;
			this.showAccountDisconnectedModal(this.review);
			return;
		}

		this.openedReplyForms[reviewId] = true;
	}

	onReply(): void {
		this.openedReplyForms = {};
		this.onAddReply.emit();
	}

	openReplyOnSite(): void {
		if (this.replyReviewLoading) {
			return;
		}

		this.replyReviewLoading = true;

		this.reviewsResource.review.get({
			reviewId: this.review.reviewId,
		}, (response: Review) => {
			if (response.revieweeProfileUrl) {
				window.open(response.revieweeProfileUrl, '_blank');
			}

			this.replyReviewLoading = false;
		}, () => {
			this.replyReviewLoading = false;
		});
	}
}
