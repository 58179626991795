<div class="remote-resource" [class.resolved]="resolved">
    <h3 class="flex-row">
        <span class="flex-grow">Subscription</span>
    </h3>
    <div class="rows-striped">
        <div class="row-fluid">
            <div class="col-sm-2">
                <label class="control-label">Product</label>
            </div>
            <div class="col-sm-3">
                <span class="control-value">{{ billingSubscriptionDetails?.productName || 'None' }}</span>
            </div>

            <div class="col-sm-1"></div>

            <div class="col-sm-2">
                <label class="control-label">Subscription</label>
            </div>
            <div class="col-sm-5">
                <span class="control-value" [ngSwitch]="billingSubscriptionDetails?.isSubscribed">
                    <ng-container *ngSwitchDefault>
                        <span class="glyphicon glyphicon-remove text-danger"></span> Off
                    </ng-container>
                    <ng-container *ngSwitchCase="true">
                        <span class="glyphicon glyphicon-ok text-success"></span> On
                    </ng-container>
                </span>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-sm-2">
                <label class="control-label">Tier</label>
            </div>
            <div class="col-sm-3">
                <span class="control-value">{{ billingSubscriptionDetails?.tierNumber || '-' }}</span>
            </div>

            <div class="col-sm-1"></div>
            <div class="col-sm-2"></div>
            <div class="col-sm-5"></div>
        </div>
    </div>
</div>
