import { Component, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { first, finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { FinancialDocumentResourceService } from 'commons/services/financial-documents-resource.service';
import {
	FinancialDocumentHistoryItem,
	TPOFinancialDocumentType,
	TPO_FINANCIAL_DOCUMENT_TYPE_MAP as titlesMap,
} from 'commons/services/financial-documents.interface';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';

import { RequestFinancialDocumentHistoryModalComponent } from './request-financial-document-history-modal';
import { WaiveFinancialHistoryModalComponent } from './waive-financial-history-modal/waive-financial-history-modal.component';


declare let apiPath: string;

@Component({
	templateUrl: 'tpo-financial-document-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => FinancialDocumentHistoryComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => FinancialDocumentHistoryComponent) },
	],
})
export class FinancialDocumentHistoryComponent extends NewPagedListComponent<FinancialDocumentHistoryItem> {
	static listName = 'financialDocumentHistory';
	tpoId: number;
	documentType: TPOFinancialDocumentType;
	User: UserProfile;
	title: typeof titlesMap[TPOFinancialDocumentType];
	lenderId: number;
	modalRef: BsModalRef;
    hasRequestPermission: boolean;

	constructor(
		router: UIRouter,
		{ profile }: UserService,
		private readonly financialDocumentService: FinancialDocumentResourceService,
		private readonly modalService: BsModalService,
	) {
		super(router);

		this.User = profile;
		this.lenderId = this.User.isLender ? profile.organization.id : null;
		this.tpoId = this.User.isTpo ? profile.organization.id : router.globals.params.id;
		this.documentType = router.globals.params.documentType.toUpperCase();
		this.title = titlesMap[this.documentType];
        this.hasRequestPermission = this.User.can('REQUEST_FINANCIALS');
	}

	protected loadList(listParams: ListParams): Observable<PagedData<FinancialDocumentHistoryItem[]>> {
		return this.financialDocumentService.getFinancialHistoryList({
			...(this.User.isTpo ? {} : { lenderId: this.lenderId }),
			tpoId: this.tpoId,
			financialDocumentType: this.documentType,
			listParams,
		});
	}

	getDownloadLink = ({ historyId }: FinancialDocumentHistoryItem): string => {
		return `${apiPath}/lenders/${this.lenderId}/tpos/${this.tpoId}`
			+`/financial-documents/${this.documentType}/history/${historyId}/document`;
	};

	showRequestHistoryModal({ historyId }: FinancialDocumentHistoryItem) {
		this.resolving = true;
		this.financialDocumentService.getDocumentHistoricalRequest({
			...(this.User.isTpo ? {} : { lenderId: this.lenderId }),
			tpoId: this.tpoId,
			financialDocumentType: this.documentType,
			historyId,
		}).pipe(first()).subscribe((snapshotData) => {
			this.resolving = false;

			this.modalRef = this.modalService.show(
				RequestFinancialDocumentHistoryModalComponent,
				{
					initialState: { snapshotData },
					class: 'modal-new modal-smd request-history-modal',
				},
			);
		});
	}

	showWaiveHistoryModal({ historyId }: FinancialDocumentHistoryItem): void {
		this.resolving = true;
		this.financialDocumentService.getFinancialWaiveHistory({
			lenderId: this.lenderId,
			tpoId: this.tpoId,
			financialDocumentType: this.documentType,
			historyId,
		}).pipe(
			first(),
			finalize(() => {
				this.resolving = false;
			}),
		).subscribe((snapshotData) => {
			this.modalRef = this.modalService.show(
				WaiveFinancialHistoryModalComponent,
				{
					initialState: { snapshotData },
					class: 'modal-new modal-smd request-history-modal',
				},
			);
		});
	}

    async markAsReviewed(item) {
		this.resolving = true;
		try {
			await this.financialDocumentService.markDocumentReviewed(this.lenderId, this.tpoId, item.financialDocumentId).toPromise();
			this.updateList();
		} finally {
			this.resolving = false;
		}
    }

    updateList(params: ListParams = this.params) {
        super.updateList(params);
    }
}
