import { Component, Input } from '@angular/core';

import { AlertId } from 'lender/alerts/alert.interface';


@Component({
    selector: 'alert-ids',
	templateUrl: './alert-ids.component.html',
})
export class AlertIdsComponent {
	@Input() alertIds: AlertId[] = [];
}
