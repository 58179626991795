import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { Reply } from '../../../../reviews.interface';

@Component({
	selector: 'review-comments',
	templateUrl: './review-comments.component.html',
})
export class ReviewCommentsComponent implements OnInit {
	@Input() replies: Reply[];
	@Input() reviewId: number;
	@Input() permission: boolean;
	@Input() max?: number = 5;
	@Input() companyName: string;
	limit: number;

	openedReplyForms: { [key: number]: boolean; } = {};

	@Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();

	get isInnerReplies(): boolean {
		return !!this.replies[0]?.parentId;
	}

	ngOnInit(): void {
		this.limit = this.isInnerReplies ? this.replies.length : this.max;
	}

	toggleComments = (): number => this.limit = this.limit === this.replies.length ? this.max : this.replies.length;
}
