<div 
    class="finding remote-resource"
    [class.resolved]="!findingIsLoading && !markingFindingAsReviewed && !savingFinding"
>
    <scorecard-finding-details-header
        [isCCM]="isCCM"
        [isLender]="isLender"
        [hasReviewFindingPermission]="hasReviewFindingPermission"
        [hasEditAndViewAlertPermission]="hasEditAndViewAlertPermission"
        [tpoId]="tpoId"
        [lenderId]="lenderId"
        [finding$]="finding$"
        [sref]="sref"
        [srefParams]="srefParams"
        (resolvingChanged)="setMarkingReviewed($event)"
        (findingUpdated)="loadFinding()"
    ></scorecard-finding-details-header>

    <div *ngIf="finding$ | async" class="content">
        <scorecard-finding-details-view-edit
            [isCCM]="isCCM"
            [tpoId]="tpoId"
            [finding$]="finding$"
            [saveSuccessful$]="saveSuccessful$"
            [serverErrorMessage$]="serverErrorMessage$"
            [detailRows]="detailRows"
            (findingSavePrepared)="save($event)"
        >
        </scorecard-finding-details-view-edit>
    </div>
</div>

<finding-comments
    [tpoId]="tpoId"
    [findingId]="findingId"
>
</finding-comments>
