import { Component, Input, TemplateRef } from '@angular/core';
import { ColumnSplitterService } from '../../services/utils/column-splitter.service';

@Component({
    selector: 'two-column-card-container',
    templateUrl: './two-column-card-container.component.html',
})
export class TwoColumnCardContainerComponent {
    @Input() card: TemplateRef<any>;

    private _items: any[] = [];
    @Input() set items(items: any[]) {
        this._items = items;

        this.itemColumns = this.cardContainerService.splitIntoColumns(this.items, 2);
    }
    get items(): any[] {
        return this._items;
    }

    itemColumns: any[][] = [];

    constructor(protected readonly cardContainerService: ColumnSplitterService) {
    }

}
