<section class="social-compliance-publisher-group-sharing-campaigns">
	<h3>
		Campaigns
		<div class="pull-right">
			<button type="button" class="btn btn-primary" *ngIf="User.can('TPO_PB_MANAGE_CAMPAIGNS')"
					(click)="toggleAddCampaigns(true)"
					[disabled]="showAddCampaigns"
			>
				<span class="glyphicon glyphicon-plus small-icon"></span>
				Add
			</button>
		</div>
	</h3>

	<form-errors [form]="addCampaignsForm"></form-errors>

	<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
		<div class="row-fluid header">
			<div class="col-sm-5" [ngClass]="getSortClass('name')" (click)="setSort('name')">
				<b>Campaign</b>
			</div>
			<div class="col-sm-a" [ngClass]="getSortClass('status')" (click)="setSort('status')">
				<b>Status</b>
			</div>
			<div class="col-sm-2"></div>
		</div>

		<form class="row-fluid highlighted" *ngIf="showAddCampaigns"
			  [formGroup]="addCampaignsForm"
			  (onValidSubmit)="submitAddCampaigns()"
		>
			<div class="col-sm-5 va-m" [class.has-error]="addCampaignsForm.get('campaignIds').touched && addCampaignsForm.get('campaignIds').invalid">
				<ng-select
					[loading]="!addCampaignsCandidates?.$resolved"
					formControlName="campaignIds"
					[items]="addCampaignsCandidates?.$promise | async"
					bindValue="id"
					bindLabel="name"
					[searchable]="true"
					[multiple]="true"
					[closeOnSelect]="false"
					placeholder="Select Campaigns"
				></ng-select>
			</div>
			<div class="col-sm-a va-m"></div>
			<div class="col-sm-3 va-m text-right">
				<button type="submit" class="btn btn-primary">Save</button>
				<button type="button" class="btn btn-secondary" (click)="toggleAddCampaigns(false)">Cancel</button>
			</div>
		</form>

		<ng-container *ngFor="let item of list">
			<div class="row-fluid" *ngIf="item.id !== removeCampaignId">
				<div class="col-sm-5 va-m">
					<ng-container *ngIf="User.can('TPO_PB_VIEW_CAMPAIGNS'); then campaignAnchor; else campaignName"></ng-container>
					<ng-template #campaignAnchor>
						<a uiSref="social-compliance.publisher.campaigns.:campaignId" [uiParams]="{ campaignId: item.id }" target="_blank">{{item.name}}</a>
					</ng-template>
					<ng-template #campaignName>
						{{item.name}}
					</ng-template>
				</div>
				<div class="col-sm-a va-m">
					<span class="label label-{{campaignStatuses[item.status]?.color || 'default'}}">{{item.status}}</span>
				</div>
				<div class="col-sm-2 va-m text-right">
					<div class="dropdown" dropdown *ngIf="User.can('TPO_PB_MANAGE_CAMPAIGNS')">
						<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
							<span class="c-icon c-icon-dot-3"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li role="menuitem">
								<a (click)="removeCampaign(item.id)">Remove</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row-fluid row-remove" *ngIf="item.id === removeCampaignId">
				<div class="col-sm-a va-m">
					Are you sure you want to remove this campaign?
				</div>
				<div class="col-sm-3 va-m text-right">
					<button type="button" class="btn btn-primary" (click)="confirmRemoveCampaign()">Remove</button>
					<button type="button" class="btn btn-secondary" (click)="resetRemoveCampaign()">Cancel</button>
				</div>
			</div>
		</ng-container>

		<div class="row-fluid" *ngIf="!list.length">
			<div class="col-sm-12 text-center text-bigger text-muted">No campaigns</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[5,10,25,50]"></new-paging>
</section>
