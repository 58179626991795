<div class="horizontal-flex-group">
    <div class="dropdown" dropdown [insideClick]="true">
        <button class="dropdown-toggle btn btn-primary" dropdownToggle [class.disabled]="!someAuditsAreSelected">
            Manage
            <span class="glyphicon glyphicon-chevron-down text-smaller"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li>
                <a (click)="onAssignClicked()">Assigned</a>
            </li>
            <li>
                <a (click)="onStatusClicked()">Audit Status</a>
            </li>
        </ul>
    </div>
    <div>
        <a class="btn btn-secondary" (click)="onCancelClicked()">
            Cancel
        </a>
    </div>
</div>
