<h2 class="flex-row">
    <span class="flex-grow">{{questionnaire?.name}}</span>
</h2>
<div class="rows-bordered counter no-margin" [class.top-border]="!questions[0]?.group">
    <single-question
        class="ordered-group form-group"
        *ngFor="let question of questions; index as i;"
        [questionnaireId]="questionnaire?.id"
        [question]="question"
        [show]="visibility[i]"
		[shouldSendData]="false"
		(change)="calculateVisibility(); checkValidity();"
		(saved)="answerChange();"
    ></single-question>
</div>