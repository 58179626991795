<div class="gray-head flex-head">
	<back-bread class="flex-grow">Question Bank</back-bread>
</div>

<div class="from-bank remote-resource" [class.resolved]="!resolving">
	<div class="row">
		<div class="col-md-2">
			<div class="text-muted">Question Category</div>
		</div>
	</div>
	<div class="row-fluid">
		<div class="col-md-2 va-m">
			<ng-select
				[ngModel]="params.categories"
				(change)="setCategories($event)"
				[items]="categories.$promise | async"
				[loading]="!categories.$resolved"
				[multiple]="true"
				placeholder="All Categories"
				bindValue="id"
				bindLabel="name"
			></ng-select>
		</div>
		<div class="col-md-2 va-m">
			Selected: <b>{{checkedCount}}</b>&nbsp;&nbsp;
			<a *ngIf="checkedCount" (click)="clear()">Clear</a>
		</div>
		<div class="col-md-8 va-m">
			<div class="flex-row right">
				<span class="control-label required">Place</span>
				<ng-select
					class="place-select"
					[items]="afterOptions"
					[(ngModel)]="place"
					bindValue="i"
					bindLabel="name"
					[clearable]="false"
				></ng-select>
				<span
					*ngIf="hints && hints[place] as hint"
					class="text-primary glyphicon glyphicon-eye-open medium-icon"
					[tooltip]="templateRef" placement="bottom"
				>
					<ng-template #templateRef>
						<span class="text-default" [innerHTML]="hint"></span>
					</ng-template>
				</span>
				<button type="button" class="btn btn-primary" (click)="add()" [disabled]="!checkedCount">
					Add Questions
				</button>
				<a
					type="button"
					class="btn btn-secondary"
					uiSref="questionnaires.:questionnaireId.questions"
					[uiParams]="{ action: 'edit', questionnaireId: questionnaireId }"
				>Cancel</a>
			</div>
		</div>
	</div>

	<div class="questionnaire-list">
		<div *ngIf="!list.length" class="question-container text-muted text-center text-bigger">
			All questions from this category were added to current Questionnaire
		</div>

		<div
			*ngFor="let item of list"
			class="question-container flex-row align-top gap"
		>
			<input (change)="checkQuestion(item.id, $event?.target?.checked)" [checked]="checkedIds[item.id]" type="checkbox">
			<div class="flex">
				<questionnaire-question
					[isBankQuestion]="true"
					[question]="item"
				></questionnaire-question>
			</div>
		</div>
	</div>
</div>
