<div class="gray-head flex-head">
	<h2 class="title-label">Open Requests</h2>
</div>

<div class="document-history rows-bordered remote-resource" [class.resolved]="!resolving">
	<div class="row-fluid header">
		<sortable-header class="col-sm-3" columnName="date">Request date</sortable-header>
		<sortable-header class="col-sm-3" columnName="isAudited">Document type</sortable-header>
		<sortable-header class="col-sm-3" columnName="period">Document period</sortable-header>
		<sortable-header class="col-sm-3" columnName="requestedBy">Requested by</sortable-header>
		<div *ngIf="canWaive" class="col-f-100"></div>
	</div>
	<div class="row-fluid" *ngFor="let item of list | async">
		<div class="col-sm-3 va-m">
			{{item.date | date : 'MM/dd/yyyy HH:mm'}}
		</div>
		<div class="col-sm-3 va-m">
			{{item.isAudited ? 'Audited' : 'Unaudited'}}
		</div>
		<div class="col-sm-3 va-m">
			{{item.period}} {{item.year}}
		</div>
		<div class="col-sm-3 va-m">
			{{item.requestedBy || '&ndash;'}}
		</div>
		<div *ngIf="canWaive" class="col-f-100">
			<button class="btn btn-default" type="button" (click)="waive(item)">Waive</button>
		</div>
	</div>

	<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No Requests</div>
	</div>

	<br>

	<paginator [sizes]="[10, 25, 50]"></paginator>
</div>
