import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import {
    CcmDataMatchChannel,
    CcmDataMatchHistoryInterface,
    CcmDataMatchReport,
    CcmDataMatchCustomer,
} from 'comergence/data-match/ccm-data-match.interface';

declare let apiPath: string;

@Injectable()
export class CcmDataMatchService {
    constructor(
        private http: RealmHttpClient,
    ) {}

    getDataMatchHistoryList(listParam): Observable<PagedData<CcmDataMatchHistoryInterface[]>> {
        return this.http.pagedRequest<CcmDataMatchHistoryInterface[]>(
            'GET',
            `${apiPath}/datamatch`,
            listParam
        );
    }

    getCustomerSearchResults(searchString: string): Observable<CcmDataMatchCustomer[]> {
        const params = {
            q: searchString,
        };

        return this.http.request<CcmDataMatchCustomer[]>(
            'GET',
            `${apiPath}/datamatch/lender-hint`,
            params
        );
    }

    getLenderChannels(lenderId: number): Observable<CcmDataMatchChannel[]> {
        return this.http.request<CcmDataMatchChannel[]>(
            'GET',
            `${apiPath}/v2/lender/${lenderId}/datamatch/channels`
        );
    }

    createNewDataMatch(
        file: File,
        dataMatch: CcmDataMatchReport
    ): Observable<CcmDataMatchReport> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('dataMatchJson', JSON.stringify(dataMatch));

        return this.http.uploadFileWithForm(
            'POST',
            `${apiPath}/datamatch`,
            'file',
            'dataMatchJson',
            file,
            dataMatch
        );
    }

    getDataMatchReport(reportId:number): Observable<CcmDataMatchReport> {
        return this.http.request<CcmDataMatchReport>(
            'GET',
            `${apiPath}/datamatch/${reportId}`
        );
    }

    updateReportComment(id:number, updatedReport: CcmDataMatchReport): Observable<CcmDataMatchReport> {
        return this.http.request<CcmDataMatchReport>(
            'PUT',
            `${apiPath}/datamatch/${id}`,
            null,
            updatedReport
        );
    }

    reverifyReportContent(id:number, reverifyReport: CcmDataMatchReport): Observable<CcmDataMatchReport> {
        return this.http.request<CcmDataMatchReport>(
            'PUT',
            `${apiPath}/datamatch/${id}/verification`,
            null,
            reverifyReport
        )
    }

    updateClientRelationStatus(id: number, relationshipStatus: CcmDataMatchReport): Observable<CcmDataMatchReport> {
        return this.http.request<CcmDataMatchReport>(
            'PUT',
            `${apiPath}/datamatch/${id}/load`,
            null,
            relationshipStatus
        );
    }

}
