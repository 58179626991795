import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class TpoAccountResourceService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/tpos/:tpoId/company-information`;
		const defaultParams = User.profile.organization.type === 'TPO' ? {tpoId: User.profile.organization.id} : {};

		this.resource = $resource(`${basePath}`, {...defaultParams}, {
			get: {method: 'get'},
		});
	}

	get = (...args) => this.resource.get(...args);
}
