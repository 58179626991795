<div class="docusign remote-resource" [class.resolved]="docuSignStatus.$resolved">
	<div class="mb10" *ngIf="docuSignStatus.$resolved">
		<ng-container [ngSwitch]="!!docuSignStatus.status">
			<ng-container *ngIf="docusignTokenStatus | async">
				<button type="button" class="btn btn-secondary" (click)="connect()">Reconnect</button>&nbsp;
			</ng-container>
			<ng-container *ngSwitchCase="true">
				<button type="button" class="btn btn-secondary" (click)="disconnect()">Revoke</button>&nbsp;&nbsp;
			</ng-container>
			<ng-container *ngSwitchCase="false">
				<button type="button" class="btn btn-primary" (click)="connect()">Connect</button>&nbsp;&nbsp;
				<span>
					Connect only the user account that has access to the Docusign templates you wish to use for this application.
					Please visit our <a target="_blank" [href]="helpRef">help page</a> for more information.
				</span>
			</ng-container>
		</ng-container>
	</div>

	<div class="docusign-header flex-row mb10">
		<h4>DocuSign Integration</h4>&nbsp;&nbsp;&nbsp;
		<ng-container [ngSwitch]="docuSignStatus.status">
			<span class="label label-success text-smaller" *ngSwitchCase="docuSignStatuses.CONNECTED">Active</span>
			<span class="label label-danger text-smaller" *ngSwitchDefault>Not Connected</span>
		</ng-container>
	</div>

	<div class="rows-striped">
		<div class="row-fluid">
			<div class="col-sm-6 va-m">
				<div class="row">
					<span class="col-sm-2 solid text-light">User Name</span>
					<span class="col-sm-10">{{docuSignStatus.userName || '&ndash;'}}</span>
				</div>
			</div>
			<div class="col-sm-6 va-m">
				<div class="row">
					<span class="col-sm-1 solid text-light">Email</span>
					<span class="col-sm-11">
						&nbsp;&nbsp;
						<a *ngIf="docuSignStatus.userEmail as email" href="mailto:{{email}}">{{email}}</a>
						<span *ngIf="!docuSignStatus.userEmail">&ndash;</span>
					</span>
				</div>
			</div>
		</div>
		<div class="row-fluid">
			<div class="col-sm-6 va-m">
				<div class="row">
					<span class="col-sm-2 text-light">Account</span>
					<span class="col-sm-10">{{docuSignStatus.accountName || '&ndash;'}}</span>
				</div>
			</div>
			<div class="col-sm-6"></div>
		</div>
	</div>
</div>
