import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { LandingPageComponent } from 'shared/social-media/landing-page/landing-page.component';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

import scheduleSendIcon from '~static/images/material/two-tone/schedule_send_black_24dp.svg';
import sourceIcon from '~static/images/material/two-tone/source_black_24dp.svg';
import campaignIcon from '~static/images/material/two-tone/campaign_black_24dp.svg';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: '../../../shared/social-media/landing-page/landing-page.component.html',
})
export class MarketingComponent extends LandingPageComponent {
    title = 'Marketing';
    buttons = [
        {
            sref: 'social-compliance.publisher.posts.new',
            title: 'Create Post',
        },
    ];

    constructor(
        userService: UserService,
        modalService: BsModalService,
        stateService: StateService,
    ) {
        super(modalService, stateService, userService);
        this.items = [
            {
                icon: scheduleSendIcon,
                isMaterial: true,
                title: 'Schedule Post',
                sref: 'social-compliance.publisher.posts.new',
            },
            {
                icon: campaignIcon,
                isMaterial: true,
                title: 'Campaigns',
                sref: 'social-compliance.publisher.campaigns',
                button: 'Go to Campaigns',
            },
            {
                icon: sourceIcon,
                isMaterial: true,
                title: 'Curated Content',
                sref: 'social-compliance.publisher.library.content',
            },
        ];
    }

    get enabled(): boolean {
        return this.userService.profile.hasFeature(PACKAGE_FEATURE.MARKETING);
    }
}
