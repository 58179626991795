<form-errors [form]="form"></form-errors>

<div class="rows-striped">
	<div class="row-fluid remote-resource" [class.resolved]="!resolving">
		<div class="col-md-12">
			<form
				class="flex-row gap"
				[formGroup]="form"
				(onValidSubmit)="submit()"
			>
				<sm-link-input
					class="flex"
					[formControlRef]="formControl"
					(reset)="formControl.reset()"
				></sm-link-input>
				<div>
					<button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
					<button type="button" class="btn btn-default" (click)="toggleShow(false)">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</div>
