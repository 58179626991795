import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum GlobalNotificationType {
    ERROR = 'error',
    INFO = 'info',
    POSITIVE = 'success',
    NEGATIVE = 'warning',
}

export enum GlobalNotificationMessages {
    ERROR = 'Oops! It looks like something went wrong. Please try again. If you continue to receive this error, please notify Comergence',
}

export class GlobalNotification {
    type: GlobalNotificationType = GlobalNotificationType.INFO;
    message: string;
    timeout: number | null = 5000;
}

@Injectable({
    providedIn: 'root',
})
export class GlobalNotificationsService {
    private _notifications: Set<GlobalNotification> = new Set<GlobalNotification>([]);
    private timeouts: Map<GlobalNotification, ReturnType<typeof setTimeout>> = new Map();

    public notifications$: Subject<GlobalNotification[]> = new Subject();

    add(notificationData: Partial<GlobalNotification>): void {
        const notification: GlobalNotification = Object.assign(new GlobalNotification(), notificationData);
        this._notifications.add(notification);
        this.notifications$.next(Array.from(this._notifications.values()));

        if (notification.timeout) {
            this.timeouts.set(
                notification,
                setTimeout(() => {
                    this.dismiss(notification);
                }, notification.timeout),
            );
        }
    }

    dismiss(notification: GlobalNotification): void {
        this._notifications.delete(notification);
        this.notifications$.next(Array.from(this._notifications.values()));

        if (this.timeouts.has(notification)) {
            clearTimeout(this.timeouts.get(notification));
            this.timeouts.delete(notification);
        }
    }
}
