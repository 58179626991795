import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { DocumentsServiceInterface } from 'shared/supporting-documents/documents.service.interface';
import { linkTemplate } from 'shared/supporting-documents/helpers';

import { CommentsResourceInterface } from 'commons/components/comments';
import {
	NgPagedResource,
	NgResourceArray,
	NgResourceObject,
} from 'commons/interfaces';

import {
    AuditFinding,
    AuditReview,
    AuditComment,
    AuditMessage,
    Assigned, BulkedAuditUpdate,
} from './audits.service.interface';
import { RealmHttpClient } from '../../../commons/services/http';

declare let apiPath: string;

@Injectable()
export class AuditsService implements DocumentsServiceInterface {
	private auditListResource: any;
	private auditResource: any;
	private auditCommentsResource: any;
	private assigneesResource: any;
	private reviewStatusesResource: any;
	private auditStatusesResource: any;

	documents: any;
	private auditDocumentsProps: {
		url: string,
		params: any,
	}

	constructor(
		injector: Injector,
		{ profile: User }: UserService,
        private readonly http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const resourceConfig = {
			url: `${apiPath}${User.isTpo ? '/tpos/:tpoId' : ''}/social-media-compliance`,
			params: {
				...(User.isTpo && { tpoId: User.organization.id }),
			},
		};
		const auditListResourceConfig = {
			url: `${resourceConfig.url}/review`,
		};
		const auditResourceConfig = {
			url: `${auditListResourceConfig.url}/:reviewId`,
		};
		const feedResourceConfig = {
			url: `${auditResourceConfig.url}/archive`,
		};
		const feedPostResourceConfig = {
			url: `${feedResourceConfig.url}/:smAccountId/:type/:id/:version`,
		};

		this.auditDocumentsProps = {
			url: `${auditResourceConfig.url}/document/:docId`,
			params: {
				...resourceConfig.params,
			},
		};

		this.auditListResource = PagedResource(
			auditListResourceConfig.url,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.assigneesResource = $resource(
			`${auditResourceConfig.url}/assignee`,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.reviewStatusesResource = $resource(
			`${auditResourceConfig.url}/review-status`,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.auditStatusesResource = $resource(
			`${auditResourceConfig.url}/audit-status`,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				create: {
					method: 'POST',
				},
			},
		);

		this.auditResource = PagedResource(
			auditResourceConfig.url,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
				},
				delete: {
					method: 'DELETE',
				},
				canNotify: {
					url: `${auditResourceConfig.url}/notifiable`,
					method: 'GET',
				},
				harvestingStatus: {
					url: `${auditResourceConfig.url}/harvestingStatus`,
					method: 'GET',
				},
				updateReviewStatus: {
					url: `${auditResourceConfig.url}/review-status`,
					method: 'PUT',
				},
				updateAuditStatus: {
					url: `${auditResourceConfig.url}/audit-status`,
					method: 'PUT',
				},
				updateAssignee: {
					url: `${auditResourceConfig.url}/assignee`,
					method: 'PUT',
				},
				auditFindings: {
					url: `${auditResourceConfig.url}/finding`,
					method: 'GET',
					isArray: true,
					transformResponse: (data, headers): [] => {
						const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
						// @Notice - this part isn't correct, fix in with interceptor
						parsedData?.length && (parsedData[0].$taskCount = parseFloat(headers('remediation-count')));
						return parsedData;
					},
				},
			},
		);

		this.auditCommentsResource = PagedResource(
			`${auditResourceConfig.url}/:type`,
			{ ...resourceConfig.params, reviewId: '@reviewId', type: '@type' }, {
			add: {
				method: 'POST',
			},
		});

		this.documents = $resource(
			this.auditDocumentsProps.url,
			{ ...this.auditDocumentsProps.params },
			{
				list: {
					method: 'GET',
					isArray: true,
				},
				history: {
					url: `${this.auditDocumentsProps.url}/history`,
					method: 'GET',
					isArray: true,
				},
				one: { method: 'get' },
				create: {
					method: 'POST',
					headers: {'Content-Type': undefined},
					transformRequest: ({ file, properties }) => {
						const formData = new FormData();
						formData.append('properties', new Blob([JSON.stringify(properties)], {
							type: 'application/json',
						}));
						if (file) {
							formData.append('file', file);
						}
						return formData;
					},
				},
				update: { method: 'PUT' },
				replace: {
					url: `${this.auditDocumentsProps.url}/file`,
					method: 'POST',
					headers: { 'Content-Type': undefined },
					transformRequest: (item) => {
						const data = new FormData();
						data.append('file', item.file);
						delete item.file;
						return data;
					},
				},
			},
		);
	}

	list = (...args) => this.auditListResource.get(...args);
	assignees = (...args) => this.assigneesResource.get(...args);
	reviewStatuses = (...args) => this.reviewStatusesResource.get(...args);
	auditStatuses = (...args) => this.auditStatusesResource.get(...args);
	createAuditStatus = (...args) => this.auditStatusesResource.create(...args);
	audit = (...args): NgResourceObject<AuditReview> => this.auditResource.get(...args);
	auditDelete = (...args) => this.auditResource.delete(...args);
	canNotify = (...args) => this.auditResource.canNotify(...args);
	auditHarvestingStatus = (...args) => this.auditResource.harvestingStatus(...args);
	updateReviewStatus = (...args) => this.auditResource.updateReviewStatus(...args);
	updateAuditStatus = (...args) => this.auditResource.updateAuditStatus(...args);
	updateAssignee = (...args) => this.auditResource.updateAssignee(...args);
	auditFindings = (...args): NgPagedResource<AuditFinding> & { $tasksTotal?: number } => (
		this.auditResource.auditFindings(...args)
	);
	getCommentsResource = (reviewId: number, type: 'comment' | 'message'): CommentsResourceInterface => {
		return {
			query: (listParams): Required<NgResourceArray<AuditComment | AuditMessage>> => {
				const params = { ...listParams, reviewId, type };
				return this.auditCommentsResource.query(params);
			},
			add: (listParams, data, successHandler, ererrorHandlerror): NgResourceObject<Comment> => {
				const params = { ...listParams, reviewId, type };
				return this.auditCommentsResource.add(params, data, successHandler, ererrorHandlerror);
			},
		};
	};
	getDocumentHistory = (...args) => this.documents.history(...args);
	getDocumentDownloadLink = (params) => {
		return linkTemplate(
			`${this.auditDocumentsProps.url}/file`,
			{ ...this.auditDocumentsProps.params, ...params }
		);
	};
	getDocumentHistoryDownloadLink = (params) => {
		return linkTemplate(
			`${this.auditDocumentsProps.url}/history/:id/file`,
			{ ...this.auditDocumentsProps.params, ...params }
		);
	};

    getAssignedUsers(tpoId: number) {
        return this.http.request<Assigned[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/social-media-compliance/review/assignee/bulk-update`,
        )
    }

    bulkUpdateAssignedUser(auditIds: number[], selectedUserId: number, tpoId: number) {
        return this.http.request(
            'PUT',
            `${apiPath}/tpos/${tpoId}/social-media-compliance/review/assignee/bulk-update`,
            null,
            {
                reviewIds: auditIds,
                assigneeId: selectedUserId,
            },
        )
    }

    bulkUpdateAuditStatus(bulkedAudits: BulkedAuditUpdate[], tpoId: number) {
        return this.http.request(
            'PUT',
            `${apiPath}/tpos/${tpoId}/social-media-compliance/review/bulk-update`,
            null,
            {
                individualBulkedAuditUpdates: bulkedAudits
            },
        )
    }
}
