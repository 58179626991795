import { map, find, forEach } from 'lodash';

import { NgResourceArray } from 'commons/interfaces';

import { AnswerOption, AnswerOptionFormValue, QuestionnaireDocumentAvailable } from 'lender/questionnaires/manage/questionnaires.interface';

const getSelectedDocumentObject = (
	answer: AnswerOptionFormValue,
	availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = []
): QuestionnaireDocumentAvailable[] | null => {
	if (!answer._hasDocument) {
		return null;
	}

	const selectedDocument = [];
	forEach(answer.documents, (answerDocumentId) => {
		const selectedId = find(availableDocuments, (availableDocument) => availableDocument.investorClientDocumentId === answerDocumentId);
		if (selectedId) selectedDocument.push(selectedId);
	});

	return selectedDocument.length ? selectedDocument : null;
}

export const getAnswersForSave = (
	answers: AnswerOptionFormValue[],
	availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = []
): AnswerOption[] => {
	return map(answers, (answer) => {
		const result = {
			...answer,
			documents: getSelectedDocumentObject(answer, availableDocuments),
		};

		if (!result._skipLogic) {
			result.nextQuestionId = null;
		}

		delete result._hasDocument;
		delete result._skipLogic;

		return result;
	});
};
