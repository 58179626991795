import { findIndex, last, cloneDeep } from 'lodash';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIRouterGlobals } from '@uirouter/core';

import { RealmFormControl, RealmFormGroup, RealmFormArray } from 'commons/forms';
import { NgResourceArray } from 'commons/interfaces';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { getAfterOptions, getHints } from './question-places';

import { afterOption, AnswerOption, Question, QuestionType, QuestionnaireDocumentAvailable } from '../../../questionnaires.interface';
import { LenderManageQuestionnaireService } from '../../../questionnaires.service';
import { getAnswersForSave } from '../get-answers-for-save';

@Component({
	selector: 'question-new',
	templateUrl: './new.component.html',
})
export class QuestionNewComponent implements OnInit {
	@Input() hasOwnLoader = true;
	@Input() list: NgResourceArray<Question>;
	@Input() availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = [];

	@Output() listChange = new EventEmitter<NgResourceArray<Question>>();
	@Input() disabled: boolean;
	@Output('editing') _editing = new EventEmitter<boolean>();
	@Output('onListSaveLoaded') _onListSaveLoaded = new EventEmitter<boolean>();
	editing: boolean;
	isGroup: boolean = false;

	question: Question = {
		answers: [],
		categoryId: null,
		id: 0,
		questionBankQuestionId: null,
		questionType: null,
		text: '',
		usageCount: null,
	};
	afterOptions: afterOption[] = [{ i: 0, name: 'First' }];
	hints: Record<number, string>;
	form: RealmFormGroup = new RealmFormGroup({
		text: new RealmFormControl(
			'text',
			{ label: 'Question Text' },
			Validators.required,
		),
		questionType: new RealmFormControl(
			'questionType',
			{ label: 'Question Type' },
			Validators.required,
		),
		place: new RealmFormControl(
			'place',
			{ label: 'Place', value: this.afterOptions[0].i }
		),
	});
	User: UserProfile;
	questionnaireId: number;

	get isSelect(): boolean {
		const { questionType } = this.form.value;
		return [
			QuestionType.SINGLE_SELECT,
			QuestionType.MULTI_SELECT,
		].includes(questionType);
	}

	get place(): RealmFormControl {
		return this.form.get('place') as RealmFormControl;
	}

	constructor(
		{ profile }: UserService,
		{ params: { questionnaireId } }: UIRouterGlobals,
		protected questionnairesService: LenderManageQuestionnaireService,
	) {
		this.User = profile;
		this.questionnaireId = questionnaireId;
	}

	ngOnInit(): void {
		this.afterOptions = [this.afterOptions[0], ...getAfterOptions(this.list)];
		this.hints = getHints(this.afterOptions);

		this.place.patchValue(last(this.afterOptions).i);
	}

	edit(isGroup = false): void {
		this.isGroup = isGroup;

		this.editing = true;
		this.form.reset();
		this._editing.emit(true);
		this.ngOnInit();
	}

	cancel(): void {
		this.editing = false;
		this._editing.emit(false);
	}

	async save(): Promise<void> {
		const { place, answers } = this.form.value;

		const control = this.form.get('answers') as RealmFormArray;
		if (control) {
			control.controls.forEach(answerForm => {
				answerForm.markAsTouched();
			});

			// trigger validation one more time
			control.patchValue(answers);

			if (!control.valid) {
				return;
			}
		}

		const answersForSave = getAnswersForSave(answers, this.availableDocuments);
		const newQuestion = this.getNewQuestion(answersForSave);

		const list = cloneDeep(this.list);

		const indexNew = findIndex(list, { _number: place }) + 1;
		list.splice(indexNew, 0, newQuestion);
		let index = 1;
		for (const question of list) {
			if (question.questionType !== QuestionType.GROUP) {
				question._number = index++;
			}
		}

		this._setLoaded(false);
		try {
			this.list = await this.questionnairesService.updateQuestions(
				{ questionnaireId: this.questionnaireId },
				list,
			).$promise;
			this.cancel();
		} catch (error) {
			this.form.setServerError(error.data || error);
		}
		this._setLoaded(true);
		this.listChange.emit(this.list);
	}

	getNewQuestion(answersForSave: AnswerOption[] | null = null): Question {
		const { text, questionType, place } = this.form.value;

		return {
			answers: answersForSave,
			questionType,
			text,
			_number: place + 1,
		} as Question;
	}

	_setLoaded(isLoaded: boolean): void {
		if (this.hasOwnLoader) {
			this.list.$resolved = isLoaded;
		}

		this._onListSaveLoaded.emit(isLoaded);
	}
}
