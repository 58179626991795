import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { QuestionBankCategoryWithCount } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

@Component({
	templateUrl: './remove-empty-modal.component.html',
})
export class RemoveEmptyBankCategoryModalComponent {
	onCancel: (params?) => void;
	onConfirm: (params?) => void;

	resolving: boolean = false;

	category: QuestionBankCategoryWithCount;

	constructor(
		public modalRef: BsModalRef,
		protected questionnairesService: LenderManageQuestionnaireService,
	) {}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.onCancel();
	}

	confirm = async (): Promise<void> => {
		if (this.resolving) {
			return;
		}

		this.resolving = true;

		try {
			await this.questionnairesService.removeBankCategory({
				categoryId: this.category.id,
			}).$promise;

			this.onConfirm();
			this.onCancel();
		} catch (error) {
			this.resolving = false;
		}
	}
}
