import { NgModule } from '@angular/core';
import { TpoAddEditChannelModalComponent } from 'comergence/customers/tpo-services/tpo-add-edit-channel-modal.component';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TpoChannelInformationComponent } from 'comergence/customers/tpo-services/tpo-channel/tpo-channel-information.component';
import { ReportingSwitcherModule } from 'shared/reporting-switcher';
import { TpoServiceStatusComponent } from 'comergence/customers/tpo-services/tpo-service-status.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShareChannelModalComponent } from 'comergence/customers/tpo-services/share/share-channel-modal.component';

@NgModule(
    {
        imports: [
            ComponentsModule,
            DirectivesModule,
            CommonModule,
            ReactiveFormsModule,
            ReportingSwitcherModule,
            BsDropdownModule.forRoot(),
        ],
        declarations: [
            TpoAddEditChannelModalComponent,
            TpoChannelInformationComponent,
            TpoServiceStatusComponent,
            ShareChannelModalComponent,
        ],
        providers: [],

    }
)

export class TpoServiceModule {

}
