import { NgResourceArray } from 'commons/interfaces';
import { map } from 'lodash';

export const getTextValue = (
	currentValue: string,
	placeholder: string,
	caretPosition: number,
): string => {
	if (!currentValue.length) {
		return placeholder;
	}

	const prefix = currentValue.substring(0, caretPosition);
	const postfix = currentValue.substring(caretPosition);
	return `${prefix}${placeholder}${postfix}`;
};

export const transformDynamicFieldsResponse = (response: string): DynamicField[] => {
	const rawData: DynamicFieldRaw[] = JSON.parse(response);
	const result: DynamicField[] = [];

	for (const entry of rawData) {
		result.push({
			placeholder: `{{${entry.value}}}`,
			label: entry.value,
		});
	}

	return result;
};

type DynamicFieldRaw = {
	value: string;
}

export type DynamicField = {
	placeholder: string;
	label: string;
}

export const wrapDynamicFieldsToHtml = (text: string = '', dynamicFields: NgResourceArray<DynamicField>): string => {
	const keys = map(dynamicFields, 'placeholder');
	const regex = new RegExp(`(${keys.join('|')})`, 'g');
	return text?.replace(regex, '<span class="highlight">$1</span>');
}

