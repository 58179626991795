<div class="add-contact remote-resource" [class.resolved]="!savingContact">
    <div *ngIf="!addingContact; else addingContactContainer" class="row-fluid">
        <div *ngIf="userCanAddContactToList" class="col-md-6">
            <button *ngIf="!listResolving" type="button" class="btn btn-primary" (click)="enterAddContactMode()">
                Add Contact
            </button>
        </div>
        <div *ngIf="userCanExportContactList" class="col-md-6 text-right">
            <a class="btn btn-default" [class.disabled]="listResolving" [href]="exportDownloadUrl">
                Export
            </a>
        </div>
    </div>
    <ng-template #addingContactContainer>
        <div>
            <form name="addContactForm"
                class="form-inline"
            >
				<div class="rows-striped">
					<div class="row-fluid">
                        <div class="col-md-3">
                            <div class="flex-row gap">
                                <label class="control-label">Add Contact:</label>
                                <add-contact-search
                                    class="flex-grow"
                                    [organizationId]="organizationId"
                                    [contactListId]="contactListId"
                                    (contactSelected)="setSelectedContact($event)"
                                >
                                </add-contact-search>
                            </div>
                        </div>

                        <div class="col-md-6 clearfix">
                            <div class="pull-right">
                                <button type="button" class="btn btn-primary" [disabled]="!selectedContact" (click)="addContact()">
                                    Save
                                </button>
                                <button type="button" class="btn btn-primary-wired" (click)="exitAddContactMode()">
                                    Cancel
                                </button>
                            </div>
                        </div>
					</div>
				</div>
			</form>
        </div>
    </ng-template>
</div>
