import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';


@Component({
	templateUrl: './renewal-modal.component.html',
})
export class RenewalModalComponent {
	onSave: (date: string) => void;
	onCancel: (params?) => any;
	resolving: boolean;
	minDate: Date;
	dateFormatter = new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	selectedDate: Date;

	constructor(public modalRef: BsModalRef) {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: false,
			minDate: tomorrow,
		});
	}

	save() {
		const formattedDate = this.dateFormatter.format(this.selectedDate);
		this.onSave(formattedDate);
	}
}
