import { ListParams } from 'commons/components/new-list/list.component';


export const TPO_FINANCIAL_DOCUMENT_TYPE_MAP = {
    FINANCIAL_STATEMENT: 'Financial Statements',
    BALANCE_SHEET: 'Balance Sheet',
    INCOME_PROFIT_LOSS_STATEMENT: 'Income (Profit / Loss) Statement',
    STATEMENT_OF_CASH_FLOWS: 'Statement of Cash Flows',
} as const;

export type TPOFinancialDocumentType = keyof typeof TPO_FINANCIAL_DOCUMENT_TYPE_MAP;

export const FinancialQuarters = ['Q1', 'Q2', 'Q3', 'Q4', 'ANNUAL'] as const;

export type TPOFinancialDocumentState = ('UNDEFINED' | 'REQUESTED' | 'UPLOADED');

export type TPOFinancialDocumentPeriod = typeof FinancialQuarters[number];

export interface TPOFinancialDocument {
    financialDocumentId: number;
    name: string;
    description: string;
    type: TPOFinancialDocumentType;
    financialDocumentState: TPOFinancialDocumentState;
    audited: boolean;
    effectiveStartDate: number;
    effectiveEndDate: number;
    requestedByName: string;
    requestedDate: number;
    uploaded: boolean;
    requestorCount?: number;
    hasOpenRequests: boolean;
    fields: TPOFinancialDocumentField[] | [];
    fieldConfig: TPOFinancialDocumentField[] | [];
    period: TPOFinancialDocumentPeriod;
    reviewedBy: string;
    reviewedDate: string;
    hasUnreviewedDocuments: boolean;
}

export interface TPOFinancialDocumentField {
    name: string;
    code: string;
    displayOrder: number;
    value: number;
}

export interface TPOFinancialDocumentFields {
    FINANCIAL_STATEMENT: TPOFinancialDocumentField[];
    BALANCE_SHEET: TPOFinancialDocumentField[];
    INCOME_PROFIT_LOSS_STATEMENT: TPOFinancialDocumentField[];
    STATEMENT_OF_CASH_FLOWS: TPOFinancialDocumentField[];
}

export interface TPOFinancialDocumentUpload {
    isAudited: boolean;
    period: TPOFinancialDocumentPeriod;
    year: number;
    fields: TPOFinancialDocumentField[];
}

export interface FinancialHistoryRequestData {
    isAudited: boolean;
    period: TPOFinancialDocumentPeriod;
    year: number;
    message: string;
    toRealmUsers: string[];
    ccRealmUsers: string[];
    notifyUponCompletionRealmUsers: string[];
}

export interface FinancialNotificationRequest {
    ccRealmUserIds?: number[];
    dueDate?: string;
    message?: string;
    notifyUponCompletionRealmUserIds?: number[];
    toRealmUserIds?: number[];
    isAudited: boolean;
    year: number;
    period: TPOFinancialDocumentPeriod;
}

export interface FinancialDocumentHistoryItem {
    action: string;
    actionBy: string;
    date: number;
    fileName: string;
    financialDocumentId: number;
    hasUploadedDocument: boolean;
    historyId: number;
    requestId: number;
    lenderId: number;
    canViewRequestDetails: boolean;
    canViewWaiveDetail: boolean;
    reviewedBy: string;
    reviewedDate: number;
}

export interface FinancialDocumentOpenRequestItem {
    date: number;
    isAudited: boolean;
    period: string;
    requestId: number;
    requestedBy: string;
    year: number;
}

export interface FinancialHistoryParams {
    lenderId?: number,
    tpoId: number,
    financialDocumentType: TPOFinancialDocumentType,
    listParams: ListParams,
}

export interface RequestFinancialHistoryModalParams extends Omit<FinancialHistoryParams, 'listParams'> {
    historyId: number;
    financialDocumentType: TPOFinancialDocumentType;
}

export interface WaiveData {
    comment: string;
    isAudited: boolean;
    period: string;
    year: number;
}

export interface ClientFinancialSettings {
    globalSettings: ClientFinancialSettingsData[];
    clientSettings: ClientFinancialSettingsData[];
}

export interface ClientFinancialSettingsData {
    channelId: number;
    name: string;
    settings: ClientFinancialSettingsItem[];
    editing?: boolean;
}

export interface ClientFinancialSettingsItem {
    title: string;
    settingId?: number;
    period?: string;
    isAudited?: boolean;
}

export interface ClientFinancialSettingOption {
    title: string;
    disabled?: boolean;
    isApplicable: boolean;
    isAudited: boolean;
    period: string;
    settingId: number;
}

export interface FulfilledFinancialDocumentRequest {
    year: number;
    period: string;
    audited: boolean;
    documentId: number;
}
