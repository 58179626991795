import { NgModule } from '@angular/core';
import { SearchClientsListComponent } from 'shared/global-search/clients/clients.component';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { GlobalSearchService } from 'shared/global-search/global-search.service';
import { ComponentsModule } from 'commons/components/components.module';
import { GlobalSearchHeaderComponent } from 'shared/global-search/global-search-header.component';
import { SearchIndividualsListComponent } from 'shared/global-search/individulals/individuals.component';
import { SearchUsersListComponent } from 'shared/global-search/users/users.component';
import { PipesModule } from 'commons/pipes/pipes.module';
import { GlobalSearchDataService } from 'shared/global-search/global-search-data.service';

const components = [
    GlobalSearchHeaderComponent,
    SearchClientsListComponent,
    SearchIndividualsListComponent,
    SearchUsersListComponent,
];

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule,
        ComponentsModule,
        PipesModule,
    ],
    declarations: [
        ...components,
    ],
    providers: [
        GlobalSearchService,
        GlobalSearchDataService,
    ],
})
export class GlobalSearchModule {}
