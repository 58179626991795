<notification *ngIf="isNotificationSet"></notification>
<section class="mobile-registration remote-resource" [class.resolved]="mobileRegistration?.$resolved" *ngIf="mobileRegistration?.length">
	<h3>Identity Verification Request</h3>
	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-sm-3">
				<b>Email</b>
			</div>
			<div class="col-sm-3">
				<b>Date</b>
			</div>
			<div class="col-sm-3">
				<b>Phone number</b>
			</div>
			<div class="col-sm-3"></div>
		</div>

		<ng-container *ngFor="let item of mobileRegistration; let i = index">
			<div class="row-fluid alert-warning" *ngIf="item?.id">
				<div class="col-sm-3 va-m">
					<a href="mailto:{{item?.email}}">{{item?.email}}</a>
				</div>
				<div class="col-sm-3 va-m text-black">
					{{item?.date | date : 'MM/dd/yyyy'}}
				</div>
				<div class="col-sm-3 va-m text-black">
					{{item?.phoneNumber || '&ndash;' | tel}}
				</div>
				<div class="col-sm-3 va-m text-right">
					<a class="btn btn-primary" (click)="updateMobileRegistration('VERIFY', i)">Verify</a>
					<a class="btn btn-default" (click)="updateMobileRegistration('REJECT', i)">Reject</a>
				</div>
			</div>
		</ng-container>
	</div>
</section>
