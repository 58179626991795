import { Component, EventEmitter, Output } from '@angular/core';
import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { Question } from '../../../../questionnaires.interface';
import { QuestionsGroupComponent } from '../';

@Component({
	selector: 'questions-group-edit',
	templateUrl: './questions-group-edit.component.html',
})
export class QuestionsGroupEditComponent extends QuestionsGroupComponent {
	@Output('update') _update = new EventEmitter<Question>();
	@Output('discard') _discard = new EventEmitter<void>();
	form: RealmFormGroup;

	getFormControl(name: string): RealmFormControl {
		return this.form.get(name) as RealmFormControl;
	}

	ngOnInit(): void {
		const { text } = this.group;
		this.form = new RealmFormGroup({
			text: new RealmFormControl(
				'text',
				{ label: 'Group Name', value: text },
				Validators.required,
			),
		});
	}

	save = (): void => {
		const { text } = this.form.value;
		this._update.emit({
			...this.group,
			text,
		});
	};

	discard = (): void => {
		this._discard.emit();
	};
}
