<columns [count]="2">
    <ng-container *ngFor="let item of list; last as last">
        <ng-template #cell>
            <card>
                <span title>Card title</span>
                <ng-template persistant *ngIf="last" >{{ item.title }} - persistant</ng-template>
                <div class="form-row">
                    <div class="form-cell">
                        <label class="control-label">Label 1</label>
                        <div class="control-value">Value 1</div>
                    </div>
                    <div class="form-cell">
                        <label class="control-label">Label 2</label>
                        <div class="control-value">Value 2</div>
                    </div>
                </div>
                <hr>
                <div class="form-row">
                    <div class="form-cell">
                        <label class="control-label">Label 3</label>
                        <div class="control-value">Value 3</div>
                    </div>
                </div>
            </card>
        </ng-template>
    </ng-container>
</columns>
