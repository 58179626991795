import { AbstractResolver } from './abstract-resolver.class';

declare const document: any;

export class ScriptResolver extends AbstractResolver {
	createInstance() {
		const element = document.createElement('script');
		element.type = 'text/javascript';
		element.src = this.url;
		return element;
	}
}
