import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class InvitesResourceService {
	public list: any;
	public invite: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');

		const basePath = {
			url: `${apiPath}/tpos/:organizationId`,
			params: {
				organizationId: User.profile.organization.id,
			},
		};
		const invitesPath = {
			url: `${basePath.url}/invites`,
			params: {
				...basePath.params,
			},
		};
		const invitePath = {
			url: `${invitesPath.url}/:inviteId`,
			params: {
				...invitesPath.params,
				inviteId: '@inviteId',
			},
		};

		this.list = $resource(
			invitesPath.url,
			invitesPath.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.invite = $resource(
			invitePath.url,
			invitePath.params,
			{
				accept: {
					url: `${invitePath.url}/accepted`,
					method: 'PUT',
				},
				decline: {
					url: `${invitePath.url}/declined`,
					method: 'PUT',
				},
			},
		);
	}
}
