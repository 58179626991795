<div class="questionnaires-list">
	<div class="gray-head flex-row">
		<back-bread class="flex">Manage Categories</back-bread>
		<div class="col-min" *ngIf="User.can('MANAGE_GLOBAL_QUESTIONNAIRE')">
			<button type="button" (click)="create()" class="btn btn-primary">Add Category</button>
		</div>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
		<div class="row-fluid header">
			<span class="col-sm-7">
				<b>Category Name</b>
			</span>
			<span class="col-sm-2 text-center">
				<b>Number of Questions</b>
			</span>
			<span class="col-sm-3"></span>
		</div>
		<div class="row-fluid" *ngFor="let entry of list">
			<span class="col-sm-7">
				{{entry.name}}
			</span>
			<span class="col-sm-2 text-center">
				{{entry.questionCount}}
			</span>
			<span class="col-sm-3 text-right">
				<a
					(click)="remove(entry)"
					*ngIf="entry.editable && User.can('MANAGE_GLOBAL_QUESTIONNAIRE')"
				>Remove</a>
				&nbsp;&nbsp;&nbsp;
				<button
					type="button"
					class="btn btn-default"
					(click)="rename(entry)"
					*ngIf="entry.editable && User.can('MANAGE_GLOBAL_QUESTIONNAIRE')"
				>Rename</button>
			</span>
		</div>
		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak va-m">No categories</span>
		</div>
	</div>
</div>
