<h3 class="col-md-a">{{ INSURANCE_BONDS_NAMES[type] }}</h3>
<div class="rows-bordered">
    <div class="row-fluid header">
        <div class="col-sm-4">Carrier Name</div>
        <div class="col-sm-3">Amount</div>
        <div class="col-sm-3">Phone</div>
        <div class="col-sm-3">Expiration</div>
        <div class="col-f-100 text-right button-holder">
            <button *ngIf="userCanAddEdit"
                type="button"
                class="btn btn-primary"
                (click)="addItem()"
            >
                <span class="glyphicon glyphicon-plus small-icon"></span>
                Add
            </button>
        </div>
    </div>
    <insurance-bonds-item
        *ngFor="let item of list"
        class="row-fluid"
        [accountInformation]="accountInformation"
        [userCanAddEdit]="userCanAddEdit"
        [item]="item"
        (onNewItemCancel)="cancelNewItem($event)"
        (afterSave)="afterSave.emit()"
    ></insurance-bonds-item>
    <div class="row-fluid" *ngIf="!list.length">
        <div class="col-md-12 text-bigger text-center text-muted">No record added</div>
    </div>
</div>
<br>
