import { Component, Input } from '@angular/core';

import { NavigationMenuItem } from '../../../utils/navigation/navigation.interface';


@Component({
	selector: 'abstract-navigation-menu',
    template: '',
})
export abstract class AbstractNavigationMenuComponent {
	private _items: NavigationMenuItem[];
	@Input() public set items(items: NavigationMenuItem[]) {
		this._items = items;
	}
	public get items(): NavigationMenuItem[] {
		return this._items;
	}

	public constructor() {}
}
