import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { TpoComplianceScheduleComponent } from './schedule.component';
import { MonitoringScheduleService } from './schedule.service';
import { TpoAuditScheduleComponent } from './partials/audit-schedule/tpo-audit-schedule.component';
import { TpoScheduleSelectionPoolComponent } from './partials/audit-schedule/selection-pool.component';
import { TpoScheduleAuditNewNmlsComponent } from './partials/audit-new-nmls.component';
import { TpoScheduleAuditAutoCloseComponent } from './partials/audit-auto-close.component';
import { SelectionPoolFileModalComponent } from './partials/audit-schedule/selection-pool-file-modal';
import { SelectionPoolResetModalComponent } from 'tpo/social-compliance/schedule/partials/audit-schedule/reset-modal/reset-modal.component';
import { ScheduleManageContactsComponent } from 'tpo/social-compliance/schedule/partials/audit-schedule/manage-contacts/manage-contacts.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		PipesModule,
	],
	declarations: [
        TpoComplianceScheduleComponent,
        TpoAuditScheduleComponent,
        TpoScheduleSelectionPoolComponent,
		SelectionPoolFileModalComponent,
        TpoScheduleAuditNewNmlsComponent,
        TpoScheduleAuditAutoCloseComponent,
        SelectionPoolResetModalComponent,
        ScheduleManageContactsComponent,
	],
	providers: [
        MonitoringScheduleService,
	],
})
export class TpoSCScheduleModule {}
