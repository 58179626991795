import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class ActivityResourceHistoryService {
	public history: any;

	constructor(
		public injector: Injector
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const path = `${apiPath}/crm/activity/:activityId/history`;

		//https://r5-dev.ccmdev.com/swagger-ui.html#!/activity45resource/getHistoryUsingGET
		this.history = PagedResource(path, { activityId: '@activityId' });
	}
}
