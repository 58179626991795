import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';
import { AssetSharing } from '../../templates.service';

@Component({
	templateUrl: './groups-modal.component.html',
})
export class GroupsModalComponent {
	assetSharing: AssetSharing;
	onCancel: (params?) => any;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: false,
		});
	}
}
