<span class="col-sm-4">
    <a *ngIf="!userIsTpo"
        [uiSref]="company.accountHomeUiSref + '.information'"
        [uiParams]="company.uiSrefParams"
        target="_blank">
        {{ company.companyName || '&ndash;' }}
    </a>
    <span *ngIf="userIsTpo">
        {{ company.companyName || '&ndash;' }}
    </span>
</span>
<span class="col-md-1">
    <a *ngIf="!company.endDate && !userIsTpo"
        [uiSref]="company.accountHomeUiSref + '.licenses'"
        [uiParams]="licensesSrefParams"
    >
        Licenses
    </a>
</span>
<span class="col-md-1">
    <a *ngIf="!company.endDate && !userIsTpo"
        [uiSref]="company.accountHomeUiSref + '.locations'"
        [uiParams]="company.uiSrefParams"
    >
        Locations
    </a>
</span>
<span class="col-md-2">
    <ng-container class="col-sm-2" *ngIf="!company.endDate && !userIsTpo" [ngSwitch]="companyUiSrefIncludesTpo">
        <a *ngSwitchCase="true" uiSref="tpo.:id.contacts.:contactId.social-media"
            [uiParams]="company.uiSrefParams"
        >
            Social Accounts
        </a>
        <div *ngSwitchDefault>
            <a *ngIf="company.accountHomeUiSref?.includes('nmls')"
               uiSref="nmls.client.:id.contacts.:contactId.social-media"
               [uiParams]="{id: company.companyNmlsId, contactId: company.contactId}"
            >
                Social Accounts
            </a>
            <a *ngIf="!company.accountHomeUiSref?.includes('nmls')"
               uiSref="client.:id.contacts.:contactId.social-media"
               [uiParams]="company.uiSrefParams"
            >
                Social Accounts
            </a>
        </div>
    </ng-container>
</span>
<span class="col-md-1">
    {{ company.companyNmlsId || '&ndash;' }}
</span>
<span class="col-sm-3">
    {{ company.startDate | date:'MM/dd/yyyy' }} &ndash; {{ (company.endDate ? (company.endDate | date:'MM/dd/yyyy') : 'current time') }}
</span>
