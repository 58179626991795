import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'formatted-text',
	templateUrl: './formatted-text.component.html',
})
export class FormattedTextComponent implements OnChanges {
	static setLineBreaks(text: string): string {
		if (!text) {
			return '';
		}

		return text
			.replace(new RegExp('\r\n', 'g'), '<br />')
			.replace(new RegExp('\n', 'g'), '<br />');
	}

	@Input()
	text: string = '';

	formattedText: string = '';

	ngOnChanges() {
		if (!this.text) {
			this.formattedText = '';
			return;
		}

		this.formattedText = FormattedTextComponent.setLineBreaks(`${this.text}`);
	}
}
