export function startOfDay(date: Date): Date {
    return new Date(new Date(date).setHours(0, 0, 0, 0))
}

export function endOfDay(date: Date): Date {
    return new Date(new Date(date).setHours(23, 59, 59, 999))
}

export function startOfToday(): Date {
    return startOfDay(new Date())
}

export function endOfToday(): Date {
    return endOfDay(new Date())
}

export function startOfTomorrow(): Date {
    return startOfDay(new Date(new Date().setDate(new Date().getDate() + 1)))
}

export function endOfTomorrow(): Date {
    return endOfDay(new Date(new Date().setDate(new Date().getDate() + 1)))
}
