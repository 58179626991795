import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

@Component({
	templateUrl: './account-assignment-modal.component.html',
})
export class AccountAssignmentModalComponent {
	form = new RealmFormGroup({
		accountAssigned: new RealmFormControl(
			'accountAssigned',
			{ label: 'Account Assigned' },
			DropdownRequiredValidator,
		),
		changeForLocations: new RealmFormControl(
			'changeForLocations',
			{ label: 'Change for Locations' },
			DropdownRequiredValidator,
		),
	});
	accountExecutives;
	resolving: boolean;
	assign: (formData: Record<string, any>) => Promise<void>;

	constructor(
		public modalRef: BsModalRef,
	) {}

	onCancel(): void {
		this.modalRef.hide();
	}

	onSubmit(): void {
		const { form } = this;

		this.resolving = true;
		this.assign(form.value)
			.then(
				(): void => {
					this.modalRef.hide();
				},
				({ data }): void => {
					form.setServerError(data);
				},
			)
			.finally((): void => {
				this.resolving = false;
			});
	}
}
