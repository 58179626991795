import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService as UService } from 'angularjs-providers/user.provider';
import { StaticValuesService } from 'angularjs-providers/static-values.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { SharedUserManagementResourceService } from 'shared/user-management/user-management-resource.service';
import { RolesResourceService } from 'shared/roles/roles-resource.service';
import { BounceEmailService } from 'shared/bounce-email.service';

import { LenderUsersResourceService } from 'lender/user-management/users-resource.service';
import { LenderUserDetailsComponent } from 'lender/user-management';

import { ComergenceUserDetailsForm } from './ccm-user-details.form';
import { ReportingLicensesService } from './ccm-user-licenses.service';
import { UserManagementCapabilitiesService } from 'commons/services/user-capabilities/user-management-capabilities.service';

@Component({
	templateUrl: './ccm-user-details.component.html',
	selector: 'comergence-user-details',
})
export class ComergenceUserDetailsComponent extends LenderUserDetailsComponent {
	userForm = ComergenceUserDetailsForm;
	unbouncing: boolean = false;
	licensesOptions: any;

	constructor(
		public UserService: UService,
		public RolesResource: RolesResourceService,
		public StaticValues: StaticValuesService,
		public LenderUsersService: LenderUsersResourceService,
		public $state: $stateProvider,
		public modalService: BsModalService,
		public UserManagement: SharedUserManagementResourceService,
		public BouncedEmails: BounceEmailService,
		public licensesService: ReportingLicensesService,
		userManagementService: UserManagementCapabilitiesService,

	) {
		super(
			UserService,
			RolesResource,
			StaticValues,
			LenderUsersService,
			$state,
			modalService,
			UserManagement,
			userManagementService,
		);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loadLicenses();
	}

	loadLicenses() {
		this.licensesOptions = this.licensesService.getLicensesOptions();
	}

	unbounceEmail() {
		if (!this.user || !this.user.isEmailBounced) {
			return;
		}

		this.unbouncing = true;

		this.BouncedEmails.unbounce({
			email: this.user.email,
		}, () => {
			this.user.isEmailBounced = false;
			this.unbouncing = false;
		}, () => {
			this.unbouncing = false;
		});
	}
}
