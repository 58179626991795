import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

@Component({
	templateUrl: './archive.component.html',
})
export class ArchiveComponent {
	items: Array<{ sref: string; title: string; }>;
	helpLink = '/help/authenticated/tpo/smc/archive';
	User: UserProfile;

	constructor(
		public state: StateService,
		userService: UserService,
	) {
		this.User = userService.profile;
		this.items = [
			{
				sref: 'social-compliance.archive.connected',
				title: 'Archive',
			},
            {
				sref: 'social-compliance.archive.feed',
				title: 'Feed',
			},
		];
	}
}
