<div>
	<div class="gray-head flex-row">
		<back-bread class="flex">{{title}}</back-bread>
		<ul class="tab-menu align-self-bottom" *ngIf="tabs">
			<li
				*ngFor="let tab of tabs"
				uiSrefActiveEq="active"
			>
				<a uiSref="{{tab.state}}">
					<b>{{tab.title}}</b>
				</a>
			</li>
		</ul>
	</div>
	<ui-view></ui-view>
</div>
