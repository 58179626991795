import { Component } from '@angular/core';
import { CommonPostComponent } from 'tpo/social-compliance/publisher/posts/$postType/$postId/posts-preview/common-post.component';

@Component({
	selector: 'gmb-post',
	templateUrl: './gmb-post.component.html',
})
export class GmbPostComponent extends CommonPostComponent {
	regLink = /(?:(?:(?:https?:)\/\/)?(www\.)?)((?:(?:(?:[@A-Za-z0-9]+)\.)+)(?:(?:[A-Za-z]+)\b)(?:(?:\/\S*)*))/g;
}
