import { Injectable } from '@angular/core';

import { TextEntity } from 'lender/clients/$id/channels/channels.service';
import { difference } from 'lodash';


export const ALL_RELATIONSHIP_STATUSES: string[] = [ 'A', 'P', 'C', 'I', 'D', 'S', 'W', 'PR', 'RS', 'T', 'WD' ];

export const ACTIVE_RELATIONSHIP_STATUSES: string[] = [ 'A', 'I', 'S', 'W', 'RS' ];
export const NON_ACTIVE_RELATIONSHIP_STATUSES: string[] = difference(ALL_RELATIONSHIP_STATUSES, ACTIVE_RELATIONSHIP_STATUSES);

export const RED_RELATIONSHIP_STATUSES: string[] = [ 'T', 'D', 'C', 'WD' ];
export const NON_RED_RELATIONSHIP_STATUSES: string[] = difference(ALL_RELATIONSHIP_STATUSES, RED_RELATIONSHIP_STATUSES);

export const NON_EDITABLE_RELATIONSHIP_STATUSES: string[] = ['T', 'PR', 'P', 'C', 'D'];
export const EDITABLE_RELATIONSHIP_STATUSES: string[] = difference(ALL_RELATIONSHIP_STATUSES, NON_EDITABLE_RELATIONSHIP_STATUSES);

@Injectable({
	providedIn: 'root',
})
export class InvestorUserRelationshipService {
	relationshipIsActive(relationshipStatus: TextEntity): boolean {
        const hasActiveRelationship: boolean = ACTIVE_RELATIONSHIP_STATUSES.includes(relationshipStatus?.id);

		return hasActiveRelationship;
	}

	relationshipIsRed(relationshipStatus: TextEntity): boolean {
        const hasUnestablishedRelationship: boolean = RED_RELATIONSHIP_STATUSES.includes(relationshipStatus?.id);

		return hasUnestablishedRelationship;
	}

	relationshipExists(relationshipStatus: TextEntity): boolean {
		const relationshipExists: boolean = !!(relationshipStatus?.id);

		return relationshipExists;
	}

    relationshipIsEditable(relationshipStatus: TextEntity): boolean {
        const relationshipIsEditable: boolean = !NON_EDITABLE_RELATIONSHIP_STATUSES.includes(relationshipStatus?.id);

        return relationshipIsEditable;
    }
}
