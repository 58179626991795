import { Injectable } from "@angular/core";

import { UserProfile } from "angularjs-providers/user.provider";


@Injectable()
export class InvestorSharesAccessRulesService {
    userHasAccessToInvestorShares(userProfile: UserProfile) {
        const userHasManageInvestorSharingPermission: boolean = userProfile?.can('MANAGE_INVESTOR_SHARING');

        return userHasManageInvestorSharingPermission;
    }
}
