import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';

export abstract class ScopeService {
	$broadcast: (name, args?) => any;
	$emit: (name, args?) => any;
	$on: (name, listener) => any;
}

export const ScopeServiceProvider = {
	provide: ScopeService,
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('$scope'),
	deps: ['$injector'],
};

export const $scopeProvider = {
	provide: '$scope',
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('$scope'),
	deps: ['$injector'],
};
