import { pick, unionBy, cloneDeep } from 'lodash';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { FindingsService } from 'shared/findings/findings.service';

import { NgResourceArray } from 'commons/interfaces';
import { Comment } from 'commons/components/sm-post/sm-post.interface';

import { TPOArchiveService } from 'tpo/social-compliance/archive/archive.service';

@Component({
	selector: 'sm-post-comments',
	templateUrl: './sm-post-comments.component.html',
})
export class SocialMediaPostCommentsComponent implements OnInit {
	commentsResource: NgResourceArray<Comment>;

	@Input() post: any;
	@Input() global: boolean = false;
	@Input() expandAllCommentsSubject?: Subject<void>;

	@Output() onMoreComments: EventEmitter<{ id: number }> = new EventEmitter();

	constructor(
		public globalArchiveService: TPOArchiveService,
		public localArchiveService: FindingsService,
	) {}

	ngOnInit(): void {
		this.expandAllCommentsSubject?.subscribe(() => {
			if (!this.post?.isAllCommentsVisible) {
				this.expandAllComments(this.post);
			}
		});
	}

	public expandAllComments = (post): void => {
		if (post.comments.loadedComments) { // Don't load content again, just use loaded comments
			post.comments.content = post.comments.loadedComments;
			post.isAllCommentsVisible = true;
			this.onMoreComments.emit({ id: post.id });
		} else {
			const commentsCopy = cloneDeep(post.comments.content.slice(0, 5)); // @SEE RM-12487
			const params = pick(post, ['id', 'smAccountId', 'contentType', 'version']) as any;

			if (this.global) {
				this.commentsResource = this.globalArchiveService.getComments(params);
			} else {
				params.type = params.contentType;
				delete params.contentType;
				this.commentsResource = this.localArchiveService.getPostComments(params);
			}

			post.$resolved = false;
			this.commentsResource.$promise.then((data) => {
				post.comments.content = unionBy(commentsCopy, data, 'id');
				post.$resolved = true;
				post.isAllCommentsVisible = true;
				this.onMoreComments.emit({ id: post.id });
			});
		}
	}

	collapseAllComments = (post): void => {
		post.comments.loadedComments = post.comments.content; // save loaded comments, for expand case
		post.comments.content = post.comments.content.slice(0, 5);
		post.isAllCommentsVisible = false;
		this.onMoreComments.emit({ id: post.id });
	}
}
