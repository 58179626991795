<div class="comments-list remote-resource" [class.resolved]="(showLoader === false) || resolved">
	<div *ngIf="permissions.canAdd">
		<ng-container *ngTemplateOutlet="formTemplate?formTemplate:defaultAddFormTemplate;context:getFormContext()"></ng-container>
	</div>

	<div class="rows-bordered">
		<div class="row" *ngFor="let comment of list">
			<ng-container *ngTemplateOutlet="commentTemplate?commentTemplate:defaultCommentTemplate;context:getCommentContext(comment)"></ng-container>
		</div>
	</div>
	<div class="row-fluid" *ngIf="resolved && !list.length">
		<span class="col-md-12 text-muted text-center text-bigger">No {{entityName | lowercase}}s</span>
	</div>
	<br>
	<new-paging *ngIf="list?.$total" class="no-border" [(list)]="list" [(params)]="params$" [sizes]="sizes"></new-paging>
</div>

<ng-template #defaultAddFormTemplate
	let-addForm="addForm"
	let-add="methods.add"
	let-entityName="entityName"
	let-editing="editing"
	let-setEditMode="methods.setEditMode"
	let-resetFormOnEmptyComment="methods.resetFormOnEmptyComment"
>
	<form [formGroup]="addForm" id="addCommentForm" class="add-comment-form" (onValidSubmit)="add()" autocomplete="off" role="presentation">
		<form-errors [form]="addForm"></form-errors>

		<div class="add-form-inline horizontal-flex-group mb15 remote-resource" [class.resolved]="!submitted">
			<div class="full-width">
				<textarea
					class="form-control no-resize"
					formControlName="comment"
					rows="{{editing ? 5 : 1}}"
					maxlength="2000"
					placeholder="Add your {{entityName | lowercase}} here"
					(focus)="setEditMode(true)"
					(blur)="resetFormOnEmptyComment()"
				>
				</textarea>
			</div>

            <div *ngIf="!inlineStyle">
                <button [disabled]="submitted || !addForm.value.comment" type="submit" class="btn btn-primary btn-icon">
                    <span class="c-icon c-icon-paper-plane"></span>
                </button>
            </div>

		</div>
        <div *ngIf="inlineStyle && editing">
            <button type="submit" class="btn btn-primary">Add Comment</button>
            <button type="button" class="btn btn-default" (click)="setEditMode(false)">Cancel</button>
        </div>
	</form>
</ng-template>

<ng-template #defaultCommentTemplate let-comment="comment">
	<div>
		<div class="horizontal-flex-group">
			<div class="full-width">
				<b>{{comment.createdBy}}</b>
			</div>
			<div class="text-light">
				{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}
			</div>
		</div>
		<p class="comment">{{comment.comment}}</p>
	</div>
</ng-template>
