<full-page-layout class="social-compliance-publisher-post-template view" (onClose)="close()">

	<ng-template #headerTemplate>
		<h4 class="page-title">Post Editor</h4>
	</ng-template>

	<ng-template #contentTemplate>
		<div class="panel">
			<div class="panel-body">

				<h3 class="panel-title">
					{{template.summary}}
				</h3>

				<section class="remote-resource" [class.resolved]="template.$resolved">

					<div class="form-group" *ngIf="template.tags?.length">
						<label class="control-label">Tags</label>
						<tag-input
							[(ngModel)]="template.tags"
							disable="true"
						></tag-input>
					</div>

					<div class="form-group" *ngIf="template.text">
						<label class="control-label">Post</label>
						<span class="display-block">
							<formatted-text [text]="template.text"></formatted-text>
						</span>
					</div>

					<div class="form-group" *ngIf="template.attachments?.length">
						<post-attachments
							[post]="template"
							[editable]="false"
							[config]="attachment.config"
							(error)="attachment.error($event)">
						</post-attachments>
					</div>

<!--					<div class="form-group campaigns" *ngIf="template.$resolved && template.campaigns?.length">-->
<!--						<label class="control-label">Campaigns</label>-->
<!--						<div class="campaigns-container">-->
<!--							<div class="campaign-container flex-row" *ngFor="let item of template.campaigns">-->
<!--								<div class="icon-container">-->
<!--									<span class="campaign-color" [style.background-color]="'#' + item.colorCode"></span>-->
<!--								</div>-->
<!--								<div class="flex">-->
<!--									<a uiSref="social-compliance.publisher.campaigns.:campaignId"-->
<!--									   [uiParams]="{ campaignId: item.id }">{{item.name}}</a>-->
<!--									<div class="campaign-dates">-->
<!--										{{item.startDate}} - {{item.endDate}}-->
<!--									</div>-->
<!--								</div>-->
<!--								<div class="campaign-status">-->
<!--									<span class="text-smaller label label-{{campaignStatuses[item.status]?.color}}">-->
<!--										{{item.status}}-->
<!--									</span>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->

					<div class="form-group">
						<label class="control-label">Sharing</label>
						<campaign-share-to-view
							[selectedType]="template?.assetSharing?.sharingType?.id"
							[inputLabel]="template?.assetSharing?.sharingType?.name"
							[entries]="template?.assetSharing?.shareTo"
							[isExtendedView]="true"
						></campaign-share-to-view>
					</div>

<!--					<div class="form-group">-->
<!--						<label class="control-label">Available Accounts</label>-->
<!--						<template-network-list-->
<!--							[resolved]="!!template?.$resolved"-->
<!--							[entries]="template?.targetNetworkCodes"-->
<!--						></template-network-list>-->
<!--					</div>-->

				</section>

			</div>
			<div class="panel-footer">
				<div class="pull-left">
					<button type="button" class="btn btn-default btn-icon" (click)="remove()" *ngIf="canManage">
						<span class="glyphicon glyphicon-trash"></span>
					</button>
				</div>
				<div class="pull-right">
					<button type="button" class="btn btn-default" (click)="edit()" *ngIf="canManage">
						<span class="glyphicon glyphicon-pencil"></span>
						Edit
					</button>
					<a type="button" class="btn btn-primary" uiSref="social-compliance.publisher.posts.new" [uiParams]="{ templateId: template.id }">
						Create Post
					</a>
				</div>
			</div>
		</div>
		<posts-preview
			*ngIf="template?.$resolved && dynamicFields.$resolved"
			[class.hide-logo]="true"
			[text]="template.text"
			[resolvedDynamicContent]="null"
			[attachments]="template.attachments"
			[attachmentsErrors]="attachmentsErrors"
			[linkPreview]="linkPreview"
			[accounts]="previewAccounts"
		></posts-preview>
	</ng-template>
</full-page-layout>
