import { NgModule } from '@angular/core';

import { GlobalSearchRulesService } from './global-search-rules.service';
import { InvestorUserRelationshipService } from './investor-user/investor-user-relationship.service';
import { InvestorUserTpoChannelService } from './investor-user/investor-user-tpo-channel.service';
import { UserManagementCapabilitiesService } from 'commons/services/user-capabilities/user-management-capabilities.service';


@NgModule({
    imports: [
    ],
    providers: [
        GlobalSearchRulesService,
        InvestorUserRelationshipService,
        InvestorUserTpoChannelService,
        UserManagementCapabilitiesService,
    ],
})
export class UserCapabilitiesModule {}
