<div class="rows-striped">
    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">NMLS ID</label>
                <div class="col-sm-7">
					<span class="control-value">
                        <a uiSref="^client.:id.account.licenses.nmls.:nmlsId" [uiParams]="{ nmlsId: contact.nmlsId }"
                           [aDisabled]="!contact.nmlsId">
                            {{contact.nmlsId || '&ndash;'}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Street</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.address || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">First Name</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.firstName || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Suite</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.suite || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Last Name</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.lastName || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Zip</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.zip || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Middle Name</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.middleName || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">State</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.state || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Email</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.email || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">City</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.city || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Phone</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.phone || '&ndash;' | tel}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Designation</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.officerDesignation?.join(', ') || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <div class="row-fluid">
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Title</label>
                <div class="col-sm-7">
                    <span class="control-value">{{contact.contactTitle || '&ndash;'}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group row">
                <label class="col-sm-5 control-label">Notifications</label>
                <div class="col-sm-7">
                    <span class="control-value">
                        {{contact.isPrimaryContact ? 'Yes' : 'No'}}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>
