import { ChangeDetectionStrategy, Component, inject, input, model, output } from '@angular/core';
import { NewWarehousesService, Warehouse } from 'shared/account/information/new-warehouse/warehouses.service';
import { warehouseForm } from 'shared/account/information/new-warehouse/warehouse.form';
import { NewInlineEditComponent } from 'commons/components/inline-edit/inline-edit.component';
import { firstValueFrom } from 'rxjs';
import { ToggleComponent } from 'commons/components/toggle';

@Component({
    selector: 'warehouse-card',
    templateUrl: './warehouse-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarehouseCardComponent {
    canManage = input<boolean>(false);
    expanded = input<boolean>(false);
    tpoId = input.required<number>();
    warehouse = model<Warehouse>(null);
    update = output();

    warehousesService = inject(NewWarehousesService);

    protected form = warehouseForm();
    protected minLength = 10;

    protected inlineConfig = (name: string) => {
        const control = this.form.get(name);
        const warehouse = this.warehouse();

        return {
            name,
            control,
            value: { [name]: warehouse?.[name] },
        };
    };

    protected patch = async (value, inlineRef: NewInlineEditComponent) => {
        const { id } = this.warehouse();
        inlineRef.resolved.set(false);
        try {
            await firstValueFrom(this.warehousesService.updateWarehouse(this.tpoId(), id, value));
            this.warehouse.update(oldValue => ({ ...oldValue, ...value }));
            this.update.emit();
            inlineRef.cancel();
        } catch ({ error }) {
            inlineRef.setServerErrors(error);
            inlineRef.resolved.set(true);
        }
    };

    protected patchToggle = async (value, toggleRef: ToggleComponent) => {
        const { id } = this.warehouse();
        toggleRef.loading = true;
        try {
            await firstValueFrom(this.warehousesService.updateWarehouse(this.tpoId(), id, value));
            this.warehouse.update(oldValue => ({ ...oldValue, ...value }));
            this.update.emit();
        } finally {
            toggleRef.loading = false;
        }
    };
}
