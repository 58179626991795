<div class="remote-resource list-management" [class.resolved]="list.$resolved">
	<div class="filters-rows">
		<div class="row filter-labels">
			<div class="col-sm-2">
				<div class="text-muted">Search</div>
			</div>
			<div class="col-sm-2">
				<span class="text-muted">Channel</span>
			</div>
			<div class="col-sm-2">
				<span class="text-muted">State</span>
			</div>
			<div class="col-sm-2">
				<span class="text-muted">Relationship Status</span>
			</div>
			<div class="col-sm-a"></div>
		</div>
		<div class="row filter-selectors">
			<div class="col-sm-2">
				<search-list placeholder="Name, NMLS ID"></search-list>
			</div>
			<div class="col-sm-2">
				<ng-select
					[multiple]="true"
					[(ngModel)]="params.channelName"
					[items]="filters.channelName.$promise | async"
					[loading]="!filters.channelName.$resolved"
					bindValue="id"
					bindLabel="name"
					(change)="setFilter({ channelName: $event })"
					placeholder="All Channels"
				></ng-select>
			</div>
			<div class="col-sm-2">
				<ng-select
					[multiple]="true"
					[(ngModel)]="params.state"
					[items]="filters.state.$promise | async"
					bindValue="shortName"
					bindLabel="name"
					(change)="setFilter({ state: $event })"
					placeholder="All States"
				></ng-select>
			</div>
			<div class="col-sm-2">
				<ng-select
					[multiple]="true"
					[(ngModel)]="params.approvalStatus"
					[items]="filters.approvalStatus.$promise | async"
					[loading]="!filters.approvalStatus.$resolved"
					bindValue="id"
					bindLabel="name"
					(change)="setFilter({ approvalStatus: $event })"
					placeholder="All Statuses"
				></ng-select>
			</div>
			<div class="col-sm-2">
				<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
			</div>
			<div class="col-sm-2">
				<div class="flex-row right gap">
					<div class="dropdown" dropdown [insideClick]="true">
						<button type="button" class="btn btn-tertiary btn-icon" dropdownToggle tooltip="Manage Columns" placement="top">
							<span class="c-icon c-icon-list-bullet"></span>
						</button>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li *ngFor="let column of getColumns()">
								<a class="link-option" (click)="toggleColumn(column.id)">
									<span class="checkbox-holder">
										<input type="checkbox" [checked]="column.enabled">
										{{column.name}}
									</span>
								</a>
							</li>
						</ul>
					</div>

					<div class="dropdown" dropdown *ngIf="User.can('EXPORT_CLIENTS')">
						<button type="button" class="btn btn-tertiary btn-icon" dropdownToggle tooltip="Export" placement="top">
							<span class="c-icon c-icon-download-alt"></span>
						</button>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li><a class="link-option" (click)="export(true)">Export all available fields</a></li>
							<li><a class="link-option" (click)="export()">Export selected fields</a></li>
						</ul>
					</div>

					<div class="relative">
						<button
								type="button"
								class="btn btn-primary btn-icon"
								(click)="goBulk()"
								tooltip="Edit"
								placement="top"
						>
							<span class="glyphicon glyphicon-pencil"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="moreFilters">
			<div class="row filter-labels">
				<div class="col-sm-2">
					<span class="text-muted">Assigned</span>
				</div>
				<div class="col-sm-2">
					<span class="text-muted">Status Reason</span>
				</div>
				<div class="col-sm-2">
					<span class="text-muted">Custom Field</span>
				</div>
				<div class="col-sm-2" *ngIf="params.customFieldId">
					<span class="text-muted">Custom Field Value</span>
				</div>
			</div>
			<div class="row filter-selectors">
				<div class="col-sm-2">
					<ng-select
							[multiple]="true"
							[(ngModel)]="params.accountExecutive"
							[items]="filters.accountExecutive.$promise | async"
							[loading]="!filters.accountExecutive.$resolved"
							bindValue="id"
							bindLabel="name"
							(change)="setFilter({ accountExecutive: $event })"
							placeholder="All Assigned"
					></ng-select>
				</div>
				<div class="col-sm-2">
					<ng-select
							[multiple]="true"
							[(ngModel)]="params.statusReason"
							[items]="filters.statusReason.$promise | async"
							[loading]="!filters.statusReason.$resolved"
							bindValue="id"
							bindLabel="reason"
							(change)="setFilter({ statusReason: $event })"
							placeholder="All Reasons"
					></ng-select>
				</div>
				<div class="col-sm-2">
					<ng-select #customFieldSelect
							[multiple]="false"
							[clearable]="true"
							[(ngModel)]="params.customFieldId"
							[items]="filters.customFieldId.$promise | async"
							[loading]="!filters.customFieldId.$resolved"
							bindValue="id"
							bindLabel="fieldName"
							(change)="setFilter({ customFieldId: $event, customFieldValue: null })"
							placeholder="All Custom Fields"
					></ng-select>
				</div>

				<div class="col-sm-2" *ngIf="customFieldSelect.selectedValues[0] as selectedItem">
					<ng-container [ngSwitch]="selectedItem?.controlTypeId">
						<ng-container *ngSwitchCase="'YesNo'" [ngTemplateOutlet]="ngSelect"></ng-container>
						<ng-container *ngSwitchCase="'MultipleSelection'" [ngTemplateOutlet]="ngSelect"></ng-container>
						<ng-container *ngSwitchCase="'Droplist'" [ngTemplateOutlet]="ngSelect"></ng-container>

						<input
							*ngSwitchCase="'Date'" class="form-control input-date"
							bsDatepicker
							(bsValueChange)="setFilter({ customFieldValue: dateFormatter.format($event) })"
							placeholder="mm/dd/yyyy"
						>
						<div *ngSwitchDefault class="form-group no-margin has-feedback left-position">
							<input
								[(ngModel)]="params.customFieldValue"
								class="form-control"
								type="search"
								(input)="setCustomFieldValue($event)"
							>
							<a
								*ngIf="params.customFieldValue"
								class="form-control-feedback glyphicon glyphicon-remove"
								(click)="setFilter({ customFieldValue: null })"
							></a>
						</div>
					</ng-container>

					<ng-template #ngSelect>
						<ng-select
						   [(ngModel)]="params.customFieldValue"
						   [items]="selectedItem?.valuesList || ['Yes', 'No']"
						   (change)="setFilter({ customFieldValue: $event })"
						   [multiple]="true"
						   [closeOnSelect]="false"
						   placeholder="Please Select"
						></ng-select>
					</ng-template>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-3" columnName="clientName">Client</sortable-header>
			<sortable-header class="col-sm-a" columnName="channelName">Channel</sortable-header>
			<sortable-header class="col-sm-a" columnName="approvalStatus">Relationship Status</sortable-header>
			<ng-container *ngFor="let column of getColumns()">
				<sortable-header class="col-sm-a" isColumnEnabled [columnName]="column.id">
					<span class="two-liners">{{column.name}}</span>
				</sortable-header>
			</ng-container>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<div class="col-sm-12 va-m text-center">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</div>
		</div>
		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-sm-3">
				<a uiSref="client.:id.account.information" [uiParams]="{ id: item.tpoId }">{{item.clientName}}</a>
				<div class="text-light text-smaller">NMLS ID: {{item.nmlsId}}</div>
			</div>
			<div class="col-sm-a">
				<a uiSref="client.:id.account.channels" [uiParams]="{ id: item.tpoId }">{{item.channelName || '&ndash;'}}</a>
			</div>
			<div class="col-sm-a text-bigger"><approval-status [status]="item.approvalStatus"></approval-status></div>
			<div class="col-sm-a" isColumnEnabled columnName="statusReason">{{item.statusReason || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="statusDate">{{(item.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="location">
				<div>{{[ item.state, item.city ].join(', ') || '&ndash;'}}</div>
				<div>{{item.zip}}</div>
			</div>
			<div class="col-sm-a" isColumnEnabled columnName="accountExecutive">{{item.accountExecutive || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="renewalDate">{{(item.renewalDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="phone">{{(item.phone | tel) || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="clientUID">{{item.clientUID || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="businessEntity">{{item.businessEntity || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="stateOfInc">{{item.stateOfInc || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="compFax">{{item.compFax || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="primaryFederalRegulator">{{item.primaryFederalRegulator || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="registrationStatus">{{item.registrationStatus || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="fiscalYearEnd">{{item.fiscalYearEnd || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="businessTax">{{item.businessTax || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="dateFormed">{{(item.dateFormed | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="mersId">{{item.mersId || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="fdicNcuaCert">{{item.fdicNcuaCert || '&ndash;'}}</div>
		</div>
		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-muted text-bigger">
				{{(filtersApplied || filters.q) ? 'No matches found' : 'No clients'}}
			</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
