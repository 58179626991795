<div class="rows-bordered">
	<div class="row-fluid header">
		<div class="col-md-6">
			<b>Folder</b>
		</div>
	</div>

	<a class="row-fluid clickable"
	   *ngFor="let postType of postTypes | keyvalue: sortNull"
	   uiSref=".:{{sRefName}}" [uiParams]="getParams(postType.value.id)"
	>
		<div class="col-md-a va-m">
			<a class="icon-holder">
				<span class="{{ postType.value.icon }} medium-icon branded-text"></span>
				{{ postType.value.title }}
			</a>
		</div>
	</a>
</div>
