<div *ngFor="let item of items; let i = index">
	<ng-template
		[ngTemplateOutlet]="additionalContentTemplate"
		[ngTemplateOutletContext]="{ item: item }">
	</ng-template>

	<label class="checkbox-inline text-muted">
		<input type="checkbox" [checked]="selected[i]" (change)="onChange($event, item)"/>

		<ng-template #defaultLabelTemplate>
			{{item[bindLabel]}}
		</ng-template>

		<ng-template
			[ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
			[ngTemplateOutletContext]="{ item: item }">
		</ng-template>
	</label>
</div>
