<ng-container *ngxInit="(canAdd && prospectInfo.canUserAddProspect) as canAddProspect">
	<a
		*ngIf="prospectInfo.prospectStatusForUser !== 'NONE' || canAddProspect"
		[aDisabled]="!canAddProspect"
		(click)="addProspect()" class="prospecting-status"
	>
		<span
			  class="star"
			  [ngClass]="{
				'gold': prospectInfo.prospectStatusForUser === 'MINE',
				'grey': prospectInfo.prospectStatusForUser === 'ELSE',
				'empty': prospectInfo.prospectStatusForUser === 'NONE'
			  }"
		>
			<i class="plus" *ngIf="canAddProspect"></i>
		</span>
	</a>
</ng-container>