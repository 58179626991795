<div class="tpo-financial-document-modal">
    <form *ngIf="uploadFinancialDocumentForm" id="form" [formGroup]="uploadFinancialDocumentForm" (onValidSubmit)="uploadFinancialDocument()">
        <div class="modal-header">
            <h3 class="tpo-financial-document-modal-header">
                Upload {{ documentTypeLabel }}
	            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">&times;</button>
            </h3>
        </div>
        <div class="modal-body remote-resource" [class.resolved]="!resolving">
			<form-errors [form]="uploadFinancialDocumentForm"></form-errors>
			<div class="flex-row align-top gap-l">
                <div class="flex-grow">
			        <uploader
				        #uploader
				        (select)="onFileSelect($event)"
				        [config]="{ accept: '.doc, .docx, .xls, .xlsx, .pdf' }"
				        [customTemplate]="selectedFile.value ? fileTemplate : null"
			        >
				        <ng-template #fileTemplate>
					        <div class="uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
						        &nbsp;&nbsp;&nbsp;&nbsp;<span class="flex">
							        <span class="c-icon c-icon-file text-muted"></span>&nbsp;&nbsp;&nbsp;&nbsp;
							            {{ selectedFile.value.name }}
						            </span>
						        <label class="btn btn-secondary required">
                                    Upload File
							        <input
								        type="file"
								        (input)="uploader.onFileInput($event)"
								        [multiple]="uploader.config.multiple"
								        [accept]="uploader.config.accept"
							        >
						        </label>&nbsp;&nbsp;&nbsp;&nbsp;
					        </div>
				        </ng-template>
			        </uploader>
                    <br />
                    <div class="flex-column">
			            <label class="control-label required">Type</label>
                        <div class="flex-column">
			                <div class="flex-row gap-l">
				                <label class="radio-inline">
					                <input type="radio" formControlName="audited" [value]="true"> Audited
    				            </label>
				                <br />
				                <label class="radio-inline">
					                <input type="radio" formControlName="audited" [value]="false"> Unaudited
				                </label>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div class="flex-column flex-grow">
                        <div class="flex-row">
                            <div class="flex-column form-group tpo-financial-document-modal-period-year-hack" has-error>
                                <label class="control-label required">Period</label>
								<ng-select
									class="period-select"
									formControlName="period"
									[items]="periods"
									[clearable]="false"
									placeholder="Choose Period"
									tabindex="3"
								>
								</ng-select>
                            </div>
                            <div class="flex-column form-group" has-error>
                                <label class="control-label required">Year</label>
                                <ng-select
									class="period-select"
									formControlName="year"
									[items]="years"
									[clearable]="false"
									placeholder="Choose Year"
									tabindex="3"
                                    (change)="resetPeriod()"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>
			    </div>
                <ng-container *ngIf="documentFields?.length > 0">
                    <div class="flex-divider stretch"></div>
                    <div class="flex-grow rows-white">
                        <div class="row-fluid">
                            <h4 class="col-sm-12">
                                Related Financial Metrics
                            </h4>
                        </div>
                        <ng-container *ngFor="let field of documentFields">
                            <div class="row-fluid">
                                <div class="col-sm-6">
                                    <div class="form-group" has-error>
                                        <label class="col-sm-6 tpo-financial-document-modal-padding control-label required">
                                            {{ uploadFinancialDocumentForm.get(field.code).label }}
                                        </label>
                                        <div class="col-sm-6 tpo-financial-document-modal-padding">
                                            <input type="text" class="form-control" validFloat="1.2-2" [formControlName]="field.code" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
		<div class="modal-footer flex-row form-group" [class.curtain]="resolving" has-error>
			<div class="flex-row flex-grow">
				<input id="attestationCheckbox"
					   type="checkbox"
					   formControlName="attestation"
					   tabindex="1"
					   (click)="setAttestation($event.currentTarget.checked)"
				>
				<label for="attestationCheckbox" class="control-label checkbox-inline required">
					I attest that the document and data provided are accurate and true
				</label>
			</div>

			<div>
				<button type="submit" form="form" class="btn btn-primary" [disabled]="!attestation">Save</button>
				<button class="btn btn-default" (click)="closeModal()">Cancel</button>
			</div>
		</div>
    </form>
</div>
