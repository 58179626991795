import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceObject } from 'commons/interfaces';
import { RealmHttpClient } from 'commons/services/http';

import { PROFILE_SECTION, SectionReview } from 'tpo/account/section-review/section-review.service';

declare let apiPath: string;

export type AppChannel = {
    applicationId: number;
    applicationType: string;
    channel: {
        applicationPrice: number;
        id: number;
        name: string;
    };
    id: number;
    investor: {
        id: number;
        name: string;
    };
    paid: boolean;
    paidBy: string;
	submitted: boolean;
}

export interface ApplicationListRow {
	completed: boolean;
	reviewDate: string;
	reviewer: string
	status: string;
}

export interface QuestionnaireRow extends ApplicationListRow {
	hasQuestionnaire: boolean;
	tpoQuestionnaireId: number;
}

export interface AccountExecutive {
	changedByInvestor: boolean;
	email: string;
	firstName: string;
	fullName: string;
	id: number;
	lastName: string;
	phone: string;
	userName: string;
}

export interface AccountExecutiveValues extends Omit<AccountExecutive, 'changedByInvestor'> {
	externalId: number;
}

export type OwnershipRow = ApplicationListRow;
export type CompanyInformationRow = ApplicationListRow;

export type ReviewBySection = Record<PROFILE_SECTION, SectionReview>;

@Injectable()
export class TpoApplicationService {
	application: any;
	payment: any;

	constructor(
        private readonly injector: Injector,
        private readonly User: UserService,
        private readonly http: RealmHttpClient,

    ) {
		const $resource = injector.get('$injector').get('$resource');

		const resourceConfig = {
			url: `${apiPath}/tpo-organization-channels/:id`,
			params: {},
		};

		this.application = $resource(
			resourceConfig.url,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
				},
			},
		);

		this.payment = $resource(
			`${resourceConfig.url}/payment`,
			resourceConfig.params,
			{
				save: {
					method: 'PUT',
				},
			},
		);
	}

	getApplication = (...args): NgResourceObject<any> => this.application.get(...args);

	// savePayment = (...args) => this.payment.save(...args);

    savePayment = (channelId: number, body: unknown): Observable<void> =>
        this.http.request<void>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/application/payment`,
            {},
            body,
        );

    // /api/rest/organization-channels/{channelId}/application
    getAppChannel = (channelId: number): Observable<AppChannel> =>
        this.http.request<AppChannel>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/application`,
        ).pipe(
            shareReplay(1),
        );

    getAppReviews = (channelId: number): Observable<ReviewBySection> =>
        this.http.request<ReviewBySection>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/application/profile-review`,
        ).pipe(
            shareReplay(1),
        );

	getAppCompanyInformation = (channelId: number): Observable<CompanyInformationRow> =>
		this.http.request<CompanyInformationRow>(
			'GET',
			`${apiPath}/organization-channels/${channelId}/application/company-information`,
		).pipe(
			shareReplay(1),
		);

	// https:/r5-dev.ccmdev.com/swagger-ui/index.html#/channel-application-resource/getApplicationQuestionnaireUsingGET_1
    getAppQuestionnaires = (channelId: number): Observable<QuestionnaireRow> =>
        this.http.request<QuestionnaireRow>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/application/questionnaire`,
        ).pipe(
            shareReplay(1),
        );

	// https:/r5-dev.ccmdev.com/swagger-ui/index.html#/channel-application-resource/getOwnershipInfoUsingGET
    getAppOwnership = (channelId: number): Observable<OwnershipRow> =>
        this.http.request<OwnershipRow>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/application/ownership`,
        ).pipe(
            shareReplay(1),
        );

	// https://r5-dev.ccmdev.com/swagger-ui/index.html#/channel-application-resource/getAccountExecutiveUsingGET
	getAppAccountExecutive = (channelId: number): Observable<AccountExecutive> =>
		this.http.request<AccountExecutive>(
			'GET',
			`${apiPath}/organization-channels/${channelId}/application/account-executive`,
		);

	// https://r5-dev.ccmdev.com/swagger-ui/index.html#/channel-application-resource/getAccountExecutiveUsingGET
	updateAppAccountExecutive = (channelId: number, accountExecutive: AccountExecutive,): Observable<AccountExecutive> =>
		this.http.request<AccountExecutive>(
			'PUT',
			`${apiPath}/organization-channels/${channelId}/application/account-executive`,
			null,
			accountExecutive,
		);

	getAppAccountExecutiveValues = (channelId: number): Observable<AccountExecutiveValues> =>
		this.http.request<AccountExecutiveValues>(
			'GET',
			`${apiPath}/organization-channels/${channelId}/account-executives`,
		).pipe(
			shareReplay(1),
		);

	submitApplication = (channelId: number): Observable<void> =>
		this.http.request<void>(
			'PUT',
			`${apiPath}/organization-channels/${channelId}/application/submission`,
		);
}
