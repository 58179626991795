import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { NgResourceArray } from 'commons/interfaces';

import { TpoDocument, TpoLibraryDocument } from 'tpo/documents/documents.interface';

@Component({
	templateUrl: './upload-from-library-modal.component.html',
})
export class TpoDocumentUploadFromLibraryModalComponent implements OnInit {
	entry: any;
	document: TpoDocument;
	library: NgResourceArray<TpoLibraryDocument>;
	selectedDocument: TpoLibraryDocument;
	resolving = false;
	onConfirm: (doc: TpoLibraryDocument) => void;
	onCancel: () => void;

	constructor(
		public modalRef: BsModalRef,
	) {}

	async ngOnInit(): Promise<void> {
		this.library.$promise;
	}

	select(doc: TpoLibraryDocument) {
		this.selectedDocument = doc;
	}
}
