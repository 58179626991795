import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals';
import { DocumentComponent } from 'shared/documents';

import { TpoLibraryDocument } from '../../../documents.interface';
import { TpoDocumentsService } from '../../../documents.service';

import { TpoLibraryDocumentRequestsModalComponent } from './requests-modal';

@Component({
	selector: 'tpo-library-document-list-item',
	templateUrl: './item.component.html',
})
export class TpoLibraryDocumentListItemComponent extends DocumentComponent<TpoLibraryDocument> {
	showRequests: boolean;
	modalRef: BsModalRef;

	get templateDownloadLink(): string {
		const {
			libraryId,
		} = this.document;
		return this.documentsService.getTemplateDownloadLink({
			libraryId,
		});
	}

	constructor(
		private stateService: StateService,
		private userService: UserService,
		private documentsService: TpoDocumentsService,
		private modalService: BsModalService,
	) {
		super(userService);
	}

	toggleShowRequests(showRequests = !this.showRequests): void {
		this.showRequests = showRequests;
	}

	showRequestsModal(): void {
		const { document } = this;
		const initialState = {
			document,
		};
		this.modalRef = this.modalService.show(
			TpoLibraryDocumentRequestsModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
				backdrop: 'static',
			},
		);
	}

	// TODO: use shared upload logic
	async confirmUpload(file: File): Promise<void> {
		return new Promise((resolve, reject): void => {
			const { name } = this.document;
			const initialState = {
				title: 'Upload Document',
				message: `
					<div class="modal-message">
						<div class="well bigger">You are about to upload <strong>${file.name}</strong> to this document</div>
						<span class="glyphicon glyphicon-arrow-down"></span>
						<div class="document-card">${name}</div>
					</div>
				`,
				confirmText: 'Confirm',
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});
	}

	async onFileSelect(files: FileList): Promise<void> {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		try {
			await this.confirmUpload(file);
			this.modalRef.content.resolving = true;
			try {
				const { libraryId } = this.document;
				this.document = await this.documentsService.uploadLibraryDocument(
					{ libraryId },
					{ file },
				).$promise;

				this.actionSuccess();
			} catch (error) {
				document.$error = error.data?.message;
			}
		} catch (e) {}
		this.closeModal();
	}

	showHistory(): void {
		const { libraryId } = this.document;
		this.stateService.go('.:libraryId.history', { libraryId });
	}

	private actionSuccess(): void {
		this.document.$success = true;
		this.userService.getUser();
		setTimeout(() => {
			this.document.$success = false;
		}, 5000);
	}

	private closeModal(): void {
		this.modalRef?.hide();
	}
}
