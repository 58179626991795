import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';
import { SnapshotData, snapshotTitles } from '../questionnaires.interface';

@Component({
	templateUrl: './snapshot-modal.component.html',
})
export class SnapshotModalComponent {
	resolving: boolean;
	snapshotTitles: snapshotTitles;
	snapshotData: SnapshotData;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			resolving: false,
			snapshotTitles: {
				notifyAssignment: 'Notify',
				toClient: 'To: Client',
				ccInternal: 'CC: Internal',
				message: 'Message',
				notifyCompletion: 'Notify upon completion',
				toInternal: 'To: Internal',
				scheduled: 'Scheduled',
				date: 'Date',
			},
			snapshotData: {
				assignmentUsers: [],
				ccInternalUsers: [],
				message: '',
				toInternalUsers: [],
				scheduledDate: '',
			},
		});
	}
}
