import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { ScorecardFinding } from '../../scorecard-finding.interface';


@Component({
    selector: 'scorecard-finding-details-header',
    templateUrl: './scorecard-finding-details-header.component.html',
})
export class ScorecardFindingDetailsHeaderComponent {
    @Input() isCCM: boolean;
    @Input() isLender: boolean;
    @Input() hasReviewFindingPermission: boolean;
    @Input() hasEditAndViewAlertPermission: boolean;
    @Input() tpoId: number;
    @Input() lenderId: number;
    @Input() finding$: Subject<ScorecardFinding>;
    @Input() sref: string;
    @Input() srefParams: any;
    @Output() resolvingChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() findingUpdated: EventEmitter<void> = new EventEmitter();

    changeResolving(resolving: boolean): void {
        this.resolvingChanged.emit(resolving);
    }

    updateFinding(): void {
        this.findingUpdated.emit();
    }
}
