<div class="groups-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Sharing</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<header class="row text-muted">
			<div class="col-md-8">Name</div>
			<div class="col-md-4">Members</div>
		</header>
		<div class="text-bigger">
			<div class="row" *ngFor="let group of assetSharing.shareTo">
				<div class="col-md-8">
					<a uiSref="social-compliance.publisher.groups.:groupId" [uiParams]="{ groupId: group.id }"
					   target="_blank">
						<i class="c-icon c-icon-users icon-light-gray soc-icon"></i>{{group.name}}
					</a>
				</div>
				<div class="col-md-4">{{group?.membersCount || '&ndash;'}}</div>
			</div>
			<div class="row" *ngIf="['NV', 'AV'].includes(assetSharing.sharingType.id)">
				<div class="col-md-8">
					<i class="c-icon c-icon-users icon-light-gray soc-icon"></i>{{assetSharing.sharingType.name}}
				</div>
				<div class="col-md-4">&ndash;</div>
			</div>
			<div class="row"></div>
		</div>
	</div>
</div>
