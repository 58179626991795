import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { BrowserModule } from '@angular/platform-browser';
import { InvestorContactDetailsComponent } from 'lender/new-contacts/details/investor-contact-details.component';
import { InvestorContactMenuComponent } from 'lender/new-contacts/details/investor-contact-menu.component';
import { InvestorGeneralInformationComponent } from 'lender/new-contacts/details/general-information/investor-general-information.component';
import { InvestorContactsService } from 'lender/new-contacts/investor-contacts.service';
import { SimpleGeneralInformationComponent } from 'lender/new-contacts/details/general-information/non-crm/simple-general-information.component';
import { CustomFieldsModule } from 'shared/fields';
import { RelatedAccountModule } from 'lender/contacts/related-account';
import { CRMModule } from 'lender/crm';
import { ActivitiesModule } from 'lender/crm/activities/activities.module';


@NgModule({
	imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
        CustomFieldsModule,
        RelatedAccountModule,
        ActivitiesModule,
        CRMModule,
	],
    declarations: [
        InvestorContactDetailsComponent,
        InvestorContactMenuComponent,
        InvestorGeneralInformationComponent,
        SimpleGeneralInformationComponent,
    ],
	providers: [
        InvestorContactsService,
	],
})
export class InvestorContactsModule {
}
