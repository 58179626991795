<div class="remote-resource" [class.resolved]="resolving">
    <h3 class="col-md-a">Investor Information</h3>
    <div class="rows-bordered">
        <div class="row-fluid header bordered">
            <div class="col-md-3">Investor Name</div>
            <div class="col-md-2 text-right">Brokered Vol.</div>
            <div class="col-md-2 text-right">Sold Vol.</div>
            <div class="col-md-3">Contact</div>
            <div class="col-md-2">Phone</div>
        </div>
        <div *ngFor="let item of investorsList" class="row-fluid table-row">
            <div class="col-md-3">{{item.investorName}}</div>
            <div class="col-md-2 text-right">{{(item.brokerVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}</div>
            <div class="col-md-2 text-right">{{(item.soldVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}</div>
            <div class="col-md-3">{{item.contact || '&ndash;'}}</div>
            <div class="col-md-2">{{(item.phone | tel) || '&ndash;'}}</div>
        </div>
        <div class="row-fluid" *ngIf="!investorsList?.length">
            <div class="col-md-12 text-bigger text-center text-muted">No records added</div>
        </div>
    </div>
</div>
