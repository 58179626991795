import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'tpo-contact-menu',
    templateUrl: '../../../commons/components/navigation/menu/side/side-menu-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcmContactMenuComponent extends SideMenuContainerComponent {
    private profile: UserProfile;

    constructor(
        changeDetector: ChangeDetectorRef,
        router: UIRouterGlobals,
        { profile, profile$ }: UserService,
    ) {
        super(changeDetector, router);
        this.profile = profile;
        this.menuUpdate$ = combineLatest([profile$, router.success$]).pipe(
            takeUntil(this.destroyed$),
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.populateMenu();
    }

    populateMenu = async () => {
        const thisRoute = 'tpo.:id.contacts.:contactId';

        this.menuItems = [
            {
                title: 'General Information',
                link: `${thisRoute}.information`,
            },
            {
                title: 'Social Accounts',
                items: [
                    {
                        title: 'Social Media',
                        link: `${thisRoute}.social-media`,
                    },
                    {
                        title: 'Web Presence',
                        link: `${thisRoute}.web-presence`,
                    },
                    {
                        title: 'Removed',
                        link: `${thisRoute}.removed`,
                    },
                ],
            },
            ...(this.profile.can('CCM_SMC_VIEW_ALL') ? [{
                title: 'Compliance',
                items: [
                    {
                        title: 'Audits',
                        link: `${thisRoute}.compliance.audits`,
                    },
                    {
                        title: 'Findings',
                        link: `${thisRoute}.compliance.findings`,
                    },
                ],
            }] : []),
            {
                title: 'User Settings',
                link: `${thisRoute}.settings`,
            },
            {
                title: 'Audit Log',
                link: `${thisRoute}.audit-log`,
            },
        ];
        this.changeDetector.detectChanges();
    }
}
