import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { NgResourceObject } from 'commons/interfaces';

import { ReviewsResourceService } from '../../../reviews-resource.service';

import zillowExampleImage from '~static/images/reviews/zillow_example.jpg';

@Component({
	selector: 'zillow-settings',
	templateUrl: './zillow.component.html',
})
export class ReviewSettingsZillowComponent implements OnInit {
	editMode: boolean;
	modalRef: BsModalRef;

	zillowData: NgResourceObject<{
		avgScore?: number;
		companyName?: string;
		partnerId?: string;
		profileUrl?: string;
		totalReviews?: number;
	}>;
	exampleImage = zillowExampleImage;

	zillowForm: RealmFormGroup = new RealmFormGroup({
		partnerId: new RealmFormControl(
			'partnerId',
			{ label: 'Partner ID', updateOn: 'change' },
			[Validators.required],
		),
		companyName: new RealmFormControl(
			'companyName',
			{ label: 'Company Name', updateOn: 'change' },
			[Validators.required],
		),
	});

	constructor(
		public reviewsResource: ReviewsResourceService,
		public modalService: BsModalService,
	) {}

	ngOnInit(): void {
		this.reviewsResource.zillowSettings.get().$promise.then((data) => {
			this.zillowData = data;
		});
	}

	edit(): void {
		this.zillowForm.patchValue({
			partnerId: this.zillowData.partnerId,
			companyName: this.zillowData.companyName,
		});
		this.editMode = true;
	}

	cancelEdit(): void {
		this.editMode = false;
		this.zillowForm.reset();
	}

	async save(): Promise<void> {
		this.zillowData.$resolved = false;
		try {
			const method = this.zillowData.partnerId ? 'update' : 'save';
			this.zillowData = await this.reviewsResource.zillowSettings[method](this.zillowForm.getRawValue()).$promise;
			this.cancelEdit();
		} catch (e) {
			this.zillowForm.setServerError(e.data || e);
			this.zillowData.$resolved = true;
		}
	}

	remove(): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Remove Settings',
				message: 'You will no longer receive Zillow reviews in your account by removing your credentials. ' +
					'Your loan officers will also stop receiving their reviews on Zillow while you are not connected.',
				confirmText: 'Confirm',
				onConfirm: async (): Promise<void> => {
					this.modalRef.content.resolving = true;

					try {
						await this.reviewsResource.zillowSettings.remove().$promise;
					} catch (e) {
						this.zillowForm.setServerError(e.data || e);
					}

					this.modalRef.hide();
					this.zillowData = { $resolved: true };
					this.cancelEdit();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	hasError = (controlName: string): boolean => {
		const { invalid, touched } = this.zillowForm.get(controlName);
		return invalid && touched;
	};
}
