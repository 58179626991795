import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { UserService } from 'angularjs-providers/user.provider';

import { Channel } from 'lender/manage-channels/manage-channel-card.interface';

import { DocumentScheduleSettingsStrategy } from 'shared/document-schedule-settings-card/strategy.interface';
import {
    DocumentCardChannelScheduleSettings,
    DocumentCardChannelScheduleSettingsEntry,
    DocumentCardDetails,
} from 'shared/document-schedule-settings-card/document-card.interface';
import { FinancialDocumentType } from 'shared/financial-documents/financial-document-type.interface';

import { FinancialDocumentScheduleSettingsService } from 'shared/financial-documents/financial-document-schedule-settings.service';
import { NotificationModalComponent } from '../../../commons/components/modals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';


export const PERIOD_DISPLAYS: Map<string, string> = new Map().set('ANNUAL', 'Annually').set('QUARTER', 'Quarterly');
export const AUDITED_DISPLAYS: Map<boolean, string> = new Map().set(true, 'Audited').set(false, 'Unaudited');

@Component({
    templateUrl: 'manage-financial-documents.component.html',
})
export class ManageFinancialDocumentsComponent implements OnInit {
    public lenderId: number;
    public organizationId: number;

    allChannels: Channel[];

    financialDocumentTypes: FinancialDocumentType[] = [];
    readonly financialDocumentParams: any = {};

    resolving: boolean = false;
    private modalRef: BsModalRef;

    private shouldDisplayHistory$: Subject<boolean> = new BehaviorSubject(false);

    constructor(
        private readonly automatedRequestSettingsService: FinancialDocumentScheduleSettingsService,
        private readonly manageChannelService: ManageChannelService,
        private readonly userService: UserService,
        private readonly modalService: BsModalService,
        private readonly stateService: StateService,
    ) {
    }

    // Override
    ngOnInit(): void {
        this.lenderId = this.userService.profile.organization.id;
        this.organizationId = this.userService.profile.organization.organizationId;

        this.financialDocumentParams.lenderId = this.lenderId;
        this.financialDocumentParams.organizationId = this.organizationId;

        const hasViewGlobalFinancialPermission: boolean = this.userService.profile.can('TPO_VIEW_GLOBAL_FINANCIAL');
        this.shouldDisplayHistory$.next(hasViewGlobalFinancialPermission);

        if (hasViewGlobalFinancialPermission) {
            this.retrieveInitialData();
        } else {
            this.showAccessDeniedModal();
        }
    }

    private async retrieveInitialData(): Promise<void> {
        try {
            this.resolving = true;
            [
                this.allChannels,
                this.financialDocumentTypes,
            ] = await Promise.all([
                this.manageChannelService.getChannelList(this.lenderId, true).toPromise(),
                this.automatedRequestSettingsService.getFinancialDocumentTypes().toPromise(),
            ]);
        }
        finally {
            this.resolving = false;
        }
    }

    getFinancialDocumentScheduleSettings = async (summary: FinancialDocumentType, params): Promise<DocumentCardDetails> => {
        const scheduleSettings: DocumentCardDetails = await this.automatedRequestSettingsService
            .getFinancialDocumentScheduleSettings(params.lenderId, summary.id)
            .toPromise();

        this.mapChannels(scheduleSettings);

        return scheduleSettings;
    }

    updateFinancialDocumentScheduleSettings = async (
        summary: FinancialDocumentType,
        update: DocumentCardDetails,
        params
    ): Promise<any> => {
        const updatedScheduleSettings: DocumentCardDetails = await this.automatedRequestSettingsService.saveFinancialDocumentScheduleSettings(update.lenderId, summary.id, params.channelId, params.settings).toPromise();
        this.mapChannels(updatedScheduleSettings);
        return updatedScheduleSettings.channelsSettingsByIds.get(params.channelId);
    }

    readonly financialDocumentStrategy: DocumentScheduleSettingsStrategy<FinancialDocumentType, DocumentCardDetails> = {
        shouldDisplayHistory: () => this.shouldDisplayHistory$,
        getDetails: this.getFinancialDocumentScheduleSettings,
        updateScheduleSettings: this.updateFinancialDocumentScheduleSettings,
    };

    private mapChannels(documentCardDetails: DocumentCardDetails): void {
        documentCardDetails.channelsSettingsByIds = new Map();
        if (!documentCardDetails.channels) {
            documentCardDetails.channels = [];
        }

        documentCardDetails.channels.forEach((channelSettings: DocumentCardChannelScheduleSettings) => {
            documentCardDetails.channelsSettingsByIds.set(channelSettings.channelId, channelSettings);

            channelSettings.settings.forEach((entry: DocumentCardChannelScheduleSettingsEntry) => {
                entry.periodDisplay = PERIOD_DISPLAYS.get(entry.period);
                entry.auditedDisplay = AUDITED_DISPLAYS.get(entry.isAudited);
            });
        });
    }

    showAccessDeniedModal(): void {
        this.modalRef = this.modalService.show(
            NotificationModalComponent,
            {
                initialState: {
                    title: 'Sorry! Access Denied',
                    notification: '<div class="denied-lock"></div>' +
                        'You currently do not have the necessary permissions to access this section.<br><br>' +
                        'If you believe this is an error, please contact your Administrator to manage your permissions.',
                    confirmText: 'Ok',
                    onConfirm: () => {
                        this.modalRef.hide();
                        this.stateService.go('home');
                    },
                },
                class: 'modal-smd modal-new modal-403',
            },
        );
    }
}
