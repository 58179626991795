import { Component, HostBinding, QueryList, ViewChildren } from '@angular/core';
import { TpoQuestionnaire, TpoQuestionnaireQuestion, TpoQuestionnaireService } from 'tpo/questionnaire/questionnaire.service';
import { UIRouter } from '@uirouter/core';
import { TpoQuestionnaireQuestionComponent } from 'tpo/questionnaire/question/question.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'commons/components/modals';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { TpoDocumentListComponent } from 'tpo/documents';
import { QuestionnaireStatus } from 'shared/questionnaires/questionnaires.interface';
import { reject } from 'lodash';

declare let window: Window;

@Component({
    templateUrl: './questionnaire.component.html',
    host: {
        '[class]': '"remote-resource application"',
    },
})
export class TpoQuestionnaireComponent {
    questionnaire: TpoQuestionnaire;
    questions: TpoQuestionnaireQuestion[] = [];
    visibility: boolean[] = [];
    finished = true;
    isValid: boolean = true;
    isTouched: boolean = false;
    isCompleted: boolean = true;
    isApplication: boolean = false;
    private maps = {
        id2index: {},
        index2id: {},
    };
    @HostBinding('class.resolved') resolved = false;
    @ViewChildren(TpoQuestionnaireQuestionComponent) questionComponents!: QueryList<TpoQuestionnaireQuestionComponent>;

    private modalRef: BsModalRef;

    constructor(
        private router: UIRouter,
        private tpoQuestionnaireService: TpoQuestionnaireService,
        private modalService: BsModalService,
        private notificationsService: GlobalNotificationsService,
    ) {
        this.init();
    }

    async init(): Promise<void> {
        const { questionnaireId } = this.router.globals.params;

        try {
            this.questionnaire = await this.tpoQuestionnaireService.getQuestionnaire(questionnaireId).toPromise();
            this.isCompleted = this.questionnaire?.status?.id === QuestionnaireStatus.Completed;
            this.isApplication = Boolean(this.questionnaire?.applicationId)
            if (this.isCompleted && !this.isApplication) {
                window.location.replace(`/tpos/questionnaires/${this.questionnaire?.id}/print`);
                return;
            }
            this.questions = await this.tpoQuestionnaireService.getQuestions(questionnaireId).toPromise();
        } catch (e) {
            this.resolved = true;
            throw e;
        }
        this.resolved = true;
        this.initCache();
    }

    initCache(): void {
        this.maps = this.questions.reduce(({ id2index, index2id }, { id }, index) => {
            id2index[id] = index;
            index2id[index] = id;
            return { id2index, index2id };
        }, this.maps);
        this.calculateVisibility();
    }

    protected calculateVisibility(): void {
        const finishIndex = this.questions.length;
        let nextIndex = 0;
        this.visibility = this.questions.map((question, index) => {
            if (nextIndex !== index) {
                return false;
            }

            if (!question.useSkipLogic) {
                nextIndex = index + 1;
                return true;
            }

            const id = this.getNextQuestionId(question);
            //Finish questionnaire
            switch (id) {
                case -1:
                    nextIndex = finishIndex;
                    break;
                case undefined:
                    nextIndex = Infinity;
                    break;
                case null:
                    nextIndex = index + 1;
                    break;
                default:
                    nextIndex = this.maps.id2index[id];
            }

            return true;
        });
        this.finished = nextIndex === finishIndex;
    }

    ngAfterViewInit() {
        this.questionComponents.changes.subscribe((update) => {
            this.checkValidity();
        });
    }

    checkValidity() {
        this.isValid = this.questionComponents.reduce((acc, { valid }) => (acc && valid), true as boolean);
    }

    answerChange() {
        this.isCompleted = false;
    }

    setValidity() {
        this.isTouched = true;
        this.questionComponents.forEach(control => control.update());
    }

    async submit() {
        this.setValidity();
        if (this.isValid && this.finished) {
            const { stateService, globals: { params: { channelId, questionnaireId } } } = this.router;

            try {
                await this.showConfirmationModal();
            } catch (e) {
                //dialog closed/cancelled
                return ;
            }

            try {
                this.resolved = false;
                const { requestedDocumentsCount } = await this.tpoQuestionnaireService.saveQuestionnaire(questionnaireId, this.questions).toPromise();

                // if is application questionnaire and has application context we should not redirect to documents
                if (this.isApplication && channelId) {
                    return stateService.go('new-application', { channelId });
                }

                // If any documents requested - go to documents.
                if (requestedDocumentsCount && !this.isApplication) {
                    const { questionnaireId, lender: { lenderId } } = this.questionnaire;
                    return stateService.go('documents.list', {
                        [TpoDocumentListComponent.listName]: {
                            lenderId,
                            questionnaireId,
                        },
                    });
                }

                // Else go to questionnaire list
                return stateService.go('^');
            } catch ({ error: { message } }) {
                this.resolved = true;
                this.notificationsService.add({
                    message,
                    type: GlobalNotificationType.ERROR,
                });
            }
        }
    }

    private async showConfirmationModal(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.isApplication) {
                return resolve();
            }

            const initialState = {
                title: 'Complete Questionnaire',
                confirmText: 'Done',
                message: 'Are you sure you are done answering your questionnaires? Once you select “Done,” you will not be able to change your answers.',
                onConfirm: async () => {
                    this.modalRef.hide();
                    resolve();
                },
                onCancel: () => {
                    this.modalRef.hide();
                    reject();
                },
            };
            this.modalRef = this.modalService.show(ConfirmModalComponent, {
                initialState,
                class: 'modal-smd modal-new',
                backdrop: 'static',
            });
        });
    }

    private getNextQuestionId({ answer, answers }: TpoQuestionnaireQuestion): number {
        const { nextQuestionId } = answers.find(({ text }) => text === answer) || {};
        return nextQuestionId;
    }
}
