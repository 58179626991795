<section class="remote-resource" [class.resolved]="alertChannels">
    <h2>Channels</h2>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-4">
                <b>Channel Name</b>
            </span>
            <span class="col-sm-4">
                <b>Channel SID</b>
            </span>
            <span class="col-sm-4">
                <b>Channel UID</b>
            </span>
        </div>

        <div *ngFor="let alertChannel of alertChannels" class="row-fluid">
            <span class="col-sm-4">
                <label>{{ alertChannel.channelName }}</label>
            </span>
            <span class="col-sm-4">
                {{ alertChannel.channelSID || '&ndash;' }}
            </span>
            <span class="col-sm-4">
                {{ alertChannel.channelUID || '&ndash;' }}
            </span>
        </div>

        <div *ngIf="!alertChannels || !alertChannels.length" class="row-fluid">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Channels</span>
        </div>
    </div>
</section>
