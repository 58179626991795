import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';
import {
	ClientDocumentDetails,
	ClientDocumentDetailsFormData,
} from 'lender/clients/$id/documents/client-documents.interface';
import { linkTemplate } from 'shared/supporting-documents/helpers';
import { transformDocumentToDocumentResource } from 'lender/documents/documents.service';

declare let apiPath: string;

@Injectable()
export class BulkRequestService {

	private baseUrl = `${apiPath}/v2/lenders/:lenderId/organization-channels/:channelId/docmanagement`
	private baseParams: { lenderId?: number } = {};

	public _service: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		this.baseParams.lenderId = user.profile.organization.id;

		this._service = $resource(
			`${this.baseUrl}`,
			this.baseParams,
			{
				getDocuments: {
					url: `${this.baseUrl}/global-documents/requestable-documents`,
					method: 'GET',
					isArray: true,
				},
				requestGlobal: {
					url: `${this.baseUrl}/global-documents/:documentId/requested`,
					method: 'POST',
				},
				requestClient: {
					url: `${this.baseUrl}/client-documents`,
					method: 'POST',
					headers: {
						'Content-Type': undefined,
					},
					transformRequest: ({ templateFile, tpoIds, ...rest }: ClientDocumentDetailsFormData & { tpoIds: number[] }) => {
						const formData = new FormData();
						const payload = {
							tpoIds,
							documentDetailsV2VO: transformDocumentToDocumentResource(rest),
						}
						const documentDetailsBlob = new Blob([ JSON.stringify(payload) ], { type: 'application/json' });
						formData.append('documentAssignmentString', documentDetailsBlob);
						if ( templateFile ) {
							formData.append('file', templateFile);
						}
						return formData;
					},
				},
			},
		);
	}

	documentsList = (...args): NgResourceArray<ClientDocumentDetails> => this._service.getDocuments(...args);
	requestGlobal = (...args): NgResourceArray<ClientDocumentDetails> => this._service.requestGlobal(...args);
	requestClient = (...args): NgResourceArray<ClientDocumentDetails> => this._service.requestClient(...args);

	getTemplateDownloadLink = (params): string => {
		return linkTemplate(
			`${apiPath}/v2/lenders/:lenderId/docmanagement/:documentId/template`,
			{
				...this.baseParams,
				...params,
			},
		);
	};
}
