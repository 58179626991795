import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';
import { NgResourceArray } from 'commons/interfaces';

import { ActivitiesService, RelatedProspect } from '../../../activities.service';
import { cloneDeep } from 'lodash';

@Component({
	templateUrl: './related-prospect.component.html',
	selector: 'related-prospect',
})
export class RelatedProspectComponent implements OnChanges {
	@Input() prospect: RelatedProspect;
	@Output() prospectChange: EventEmitter<RelatedProspect> = new EventEmitter<RelatedProspect>();
	prospectInitial: RelatedProspect;

	@Input() resolved: boolean;
	@Output() resolvedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() editing: boolean;
	@Output() editingChange = new EventEmitter<boolean>();

	@Input() notEditableOnCreate: boolean;
	@Input() curtain: boolean;
	@Input() parentForm: RealmFormGroup;
	@Input() accountNmlsId?: number;

	isNew: boolean;
	activityId: number;
	modalRef: BsModalRef;

	relatedProspectHints: NgResourceArray<RelatedProspect> = [];
	relatedLoading: boolean;
	relatedProspectInput: Subject<string> = new Subject<string>();

	relatedProspectForm: RealmFormGroup = new RealmFormGroup({
		related: new RealmFormControl(
			'related',
			{
				label: 'Prospect',
				updateOn: 'submit',
			},
		),
	});

	constructor(
		public activitiesService: ActivitiesService,
		public stateService: StateService,
		{ params }: UIRouterGlobals,
		public modalService: BsModalService,
	) {
		this.isNew = stateService.includes('**.activities.new.**');
		!this.isNew && (this.activityId = params.activityId);
		this.subscribeRelated();
	}

	ngOnChanges({ resolved }: SimpleChanges): void {
		if (resolved?.currentValue && !this.relatedProspectForm.get('related').value) {
			if (this.isNew && !this.notEditableOnCreate) {
				this.parentForm.addControl('relatedProspectForm', this.relatedProspectForm);
				this.edit();
			} else {
				this.setInitial();
			}
		}
	}

	async save(): Promise<void> {
		const { id, nmlsId, companyName } = this.relatedProspectForm.get('related').value || {};
		if (nmlsId) {
			this.resolved = false;
			this.resolvedChange.emit(false);

			try {
				this.prospectChange.emit({ nmlsId, companyName });
				this.accountNmlsId = nmlsId;
				this.prospect = (await this.activitiesService.prospectUpdate(this.activityId, id, nmlsId).$promise).relatedProspect;
				this.setInitial();
			} catch (e) {
				this.relatedProspectForm.setServerError(e.data);
			}

			this.resolved = true;
			this.resolvedChange.emit(true);
			this.cancelEdit();
		} else if (this.prospect) {
			this.remove();
		} else {
			this.cancelEdit();
		}
	}

	setEditing(editing: boolean): void {
		this.editing = editing;
		this.editingChange.emit(editing);
	}

	cancelEdit(): void {
		this.setEditing(false);
		this.prospect = cloneDeep(this.prospectInitial);
		this.relatedProspectForm.reset();
		this.formInit();
	}

	edit(): void {
		this.setEditing(true);
	}

	reset(nmlsId: number): void {
		if (this.prospect?.nmlsId != nmlsId && this.accountNmlsId !== nmlsId) {
			this.prospect = null;
			this.prospectChange.emit(null);
			this.setInitial();
		}
		this.accountNmlsId = nmlsId;
	}

	async prospectDelete(): Promise<void> {
		if (this.prospect) {
			try {
				const activity = await this.activitiesService.prospectDelete(this.activityId).$promise;
				this.prospect = activity.relatedProspect;
				this.setInitial();
				this.setEditing(false);
			} catch (e) {
				this.relatedProspectForm.setServerError(e.data);
			}
		}
	}

	remove(): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: `Delete Related Prospect`,
				message: `Are you sure you want to delete the Related Prospect?`,
				confirmText: 'Delete',
				onConfirm: (): void => {
					this.modalRef.content.resolving = true;
					this.prospectDelete().finally(() => {
						this.modalRef.content.resolving = false;
						this.modalRef.hide();
					});
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	subscribeRelated(): void {
		let search = false;
		this.relatedProspectInput
			.subscribe((term) => {
				this.relatedProspectHints = [];
				search = term && term.length > 2;
				this.relatedLoading = search;
			});

		this.relatedProspectInput
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				switchMap(async (term) => {
					if (search) {
						this.relatedProspectForm.reset();
						try {
							return await this.activitiesService.prospectSearch(term, this.accountNmlsId).$promise;
						} catch (err) {
							this.relatedProspectForm.setServerError(err.data);
						}
					}
					return [];
				}),
			)
			.subscribe((prospects) => {
				this.relatedProspectHints = prospects;
				this.relatedLoading = false;
			});
	}

	formInit = (): void => this.relatedProspectForm.get('related').patchValue(this.prospect);

	onSelect(prospect: RelatedProspect): void {
		if (this.isNew) {
			this.prospect = prospect;
			this.prospectChange.emit(prospect);
			this.setInitial();
		}
		this.relatedProspectHints = [];
	}

	setInitial(): void {
		this.formInit();
		this.prospectInitial = cloneDeep(this.prospect);
	}
}
