import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults, findIndex } from 'lodash';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NgResourceArray } from 'commons/interfaces';

import { Folder } from '../../templates.service';

@Component({
	templateUrl: './move-template-modal.component.html',
})
export class MoveTemplateModalComponent implements OnInit {
	folders: NgResourceArray<Folder>;
	folderId: number;
	onConfirm: (folderId: number) => any;
	onCancel: (params?) => any;
	resolving: boolean;

	folderForm: RealmFormGroup = new RealmFormGroup({
		folder: new RealmFormControl(
			'folder',
			{
				label: 'Folder',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: true,
		});
	}

	async ngOnInit(): Promise<void> {
		await this.folders.$promise;
		const rootFolder: Folder = {
			id: null,
			name: 'None',
			assetSharing: {
				sharingType: {
					id: 'NV',
					name: 'None',
				},
				shareTo: [],
			},
		}
		this.folders.unshift(rootFolder);

		if (this.folderId) {
			const index = findIndex(this.folders, { id: this.folderId });
			this.folders.splice(index, 1); // remove current folder from the list
		}
		this.resolving = false;
	}

	submit(): void {
		const { folder } = this.folderForm.value;
		this.onConfirm(folder.id);
	}

	hasError = (controlName: string): boolean => {
		const { invalid, touched } = this.folderForm.get(controlName);
		return this.folders?.$resolved && invalid && touched;
	};
}
