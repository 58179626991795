import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { ClientBulkRequestInterface } from './list/client-financials-bulk-request/channel-bulk-request.interface';
import { ShareOrganizationChannel, ChannelOptions, AssigneeOptions, InvestorOptions } from './list/share-modal/share-clients.interface';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RealmHttpClient } from '../../commons/services/http';

declare let apiPath: string;

@Injectable()
export class ClientsService {
	public _clients: any;
	public _approvalStatuses: any;
	public _lenderAccountExecutives: any;
	public _lenderChannels: any;
	public _channel: any;
	public _channelAccountExecutives: any;
	public _statusReasons: any;
	public _customFields: any;

	constructor(
		public injector: Injector,
		public user: UserService,
        public http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const lenderResourceProps = {
			url: `${apiPath}/lenders/:lenderId`,
			params: {
				lenderId: user.profile.organization.id,
			},
		};
		const clientCustomFieldsResourceProps = {
			url: `${apiPath}/organizations/:organizationId/custom-fields/Company`,
			params: {
				organizationId: user.profile.organization.organizationId,
			},
		};
		const channelResourceProps = {
			url: `${apiPath}/organization-channels/:channelId`,
			params: {},
		};
		this._clients = PagedResource(
			`${lenderResourceProps.url}/clients`,
			lenderResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getAll: {
					url: `${lenderResourceProps.url}/clients/assignment`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		// TODO: move to a generic common service
		const approvalStatusesUrl = `${apiPath}/applications/approval-statuses`;
		this._approvalStatuses = $resource(
			approvalStatusesUrl,
			null,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				transitions: {
					method: 'GET',
					url: `${approvalStatusesUrl}/transitions`,
				},
			},
		);

		// TODO: move to a lender common service
		this._lenderAccountExecutives = $resource(
			`${lenderResourceProps.url}/account-executives`,
			lenderResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// TODO: move to a lender common service
		this._lenderChannels = $resource(
			`${lenderResourceProps.url}/channels`,
			lenderResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// TODO: move to a lender common service
		this._statusReasons = $resource(
			`${lenderResourceProps.url}/status-reasons`,
			lenderResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			}
		);

		// TODO: move to a lender common service
		this._customFields = $resource(
			`${clientCustomFieldsResourceProps.url}`,
			clientCustomFieldsResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			}
		);

		// TODO: move to a generic common service
		this._channel = $resource(
			channelResourceProps.url,
			channelResourceProps.params,
			{
				assign: {
					method: 'PUT',
				},
				bulkUpdateChannelRelationship: {
					method: 'PUT',
					url: `${channelResourceProps.url}/bulkUpdateChannelRelationship`,
					params: { channelId: '@channelId' },
				},
				// https://r5-dev.ccmdev.com/swagger-ui.html#!/organization45channel45resource/bulkUpdateRenewalDateUsingPUT
				bulkUpdateRenewalDate: {
					method: 'PUT',
					url: `${channelResourceProps.url}/bulk-update-renewal-date`,
					params: { channelId: '@channelId' },
				},
			},
		);

		// TODO: move to a generic common service
		this._channelAccountExecutives = $resource(
			`${channelResourceProps.url}/account-executives`,
			channelResourceProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	list = (...args) => this._clients.get(...args);
	listAll = (...args) => this._clients.getAll(...args);
	approvalStatuses = (...args) => this._approvalStatuses.get(...args);
	statusesTransitions = (...args) => this._approvalStatuses.transitions(...args);
	bulkUpdateChannelRelationship = (...args) => this._channel.bulkUpdateChannelRelationship(...args);
	bulkUpdateRenewalDate = (...args) => this._channel.bulkUpdateRenewalDate(...args);
	lenderAccountExecutives = (...args) => this._lenderAccountExecutives.get(...args);
	lenderChannels = (...args) => this._lenderChannels.get(...args);
	lenderStatusReasons = (...args) => this._statusReasons.get(...args);
	customFields = (...args) => this._customFields.get(...args);
	channelAccountExecutives = (...args) => this._channelAccountExecutives.get(...args);
	assign = (...args) => this._channel.assign(...args);

    makeBulkRequestForMultipleDocuments(investorId: number, channelId: number, requestData: ClientBulkRequestInterface) : Observable<void> {
        return this.http.request<void>(
            'POST',
            `${apiPath}/lenders/${investorId}/channels/${channelId}/financial-documents/bulk-request`,
            null,
            requestData,
        );
    }

    checkChannelShareable(investorId: number, channelId: number) : Observable<boolean> {
        return this.http.request<{ shareable: boolean }>(
            'GET',
            `${apiPath}/lenders/${investorId}/channels/${channelId}/shareable`,
        ).pipe(
            shareReplay(1),
            map(({ shareable },
        ) => shareable));
    }

    getInvestorOptionsForShare(channelId: number): Observable<InvestorOptions[]> {
        return this.http.request<InvestorOptions[]>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/share/investor-options`,
        );
    }

    getChannelOptionsForShare(channelId: number, investorId: number): Observable<ChannelOptions[]> {
        return this.http.request<ChannelOptions[]>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/share/investors/${investorId}/channel-options`,
        );
    }

    getAssigneeOptionsForShare(channelId: number, investorId: number, channelToId: number): Observable<AssigneeOptions[]> {
        return this.http.request<AssigneeOptions[]>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/share/investors/${investorId}/channels/${channelToId}/assignee-options`,
        );
    }

   shareOrganizationChannel(channelId: number, shareChannelRequest: ShareOrganizationChannel): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/share`,
            null,
            shareChannelRequest,
        );
    }
}
