import {
	Component,
	ContentChild,
	Input,
	TemplateRef,
} from '@angular/core';

@Component({
	selector: 'editable-component',
	templateUrl: './editable.component.html',
})
export class EditableComponent {

	@Input() editable: boolean;

	@Input() view?: any;

	@ContentChild('viewTemplate', {static: true}) viewTemplate?: TemplateRef<any>;
	@ContentChild('editTemplate', {static: true}) editTemplate: TemplateRef<any>;
}
