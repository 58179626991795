import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './notification-modal.component.html',
})
export class NotificationModalComponent {
	notification: string;
	title: string;
	confirmText: string;
	onConfirm: (params?) => any;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: 'Notification Title',
			confirmText: 'Ok',
			notification: '<div class="alert alert-danger">Developer should always fill at least <b>notification</b> property</div>',
			resolving: false,
		});
	}
}
