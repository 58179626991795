import { defaults, map, filter, pickBy, values } from 'lodash';
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';

import { GmbLocation } from '../connectors/gmb-connector/gmb-connector.interface';

type ExtendedGmbLocation = GmbLocation & { checked: boolean, computedAddress: string }

@Component({
	templateUrl: './gmb-locations.modal.component.html',
})
export class GmbLocationsModalComponent implements OnInit {
	public title: string;
	public message: string;
	public confirmText: string;
	public cancelText: string;
	public onConfirm: (locationNames: string[]) => void;
    public onCancel: () => void;
    public resolving: boolean;
    public locations$: Observable<GmbLocation[]>;
    public locations: ExtendedGmbLocation[];
    protected checkedAll = false;
	private saveEnabled = false;

	constructor(
		public modalRef: BsModalRef,
		public cd: ChangeDetectorRef,
	) {
		defaults(this, {
			title: 'Google Business Profile',
			confirmText: 'Save',
			cancelText: 'Cancel',
			submitDisabled: true,
			resolving: true,
		});
	}

    public ngOnInit(): void {
        this.locations$.pipe(
            first(),
            catchError((e) => {
                console.error('Error in GmbLocationsModalComponent:', e);
                return throwError(e);
            }),
            finalize(() => {
                this.resolving = false;
            })
        ).subscribe((locations: GmbLocation[]) => {
            this.locations = map(locations, (location: ExtendedGmbLocation) => {
                const allowAddressKeys = ['address', 'locality',  'administrativeArea',  'postalCode',  'regionCode'];
                const notNullableAllowProps = pickBy(location.address, (value, key) => allowAddressKeys.includes(key) && value);

                return {
                    ...location,
                    checked: false,
                    computedAddress: values(notNullableAllowProps).join(', '),
                }
            });
        })
    }

	protected syncChecked(): void {
        this.saveEnabled = false;
        this.checkedAll = this.locations.reduce((acc, { checked }) => {
            this.saveEnabled = this.saveEnabled || checked;
            return acc && checked;
        }, true);
	}

    protected checkAll(): void {
        this.saveEnabled = this.checkedAll;
        this.locations = map(this.locations, (location: ExtendedGmbLocation) => ({ ...location, checked: this.checkedAll }));
	}

    protected save(): void {
        const checkedLocations = filter(this.locations, 'checked');
        const locationNames = map(checkedLocations, 'name');
        this.onConfirm(locationNames)
    }
}
