import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { StateService } from '@uirouter/core';
import { GlobalSearchService } from 'shared/global-search/global-search.service';
import { Transition } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalSearchDataService } from 'shared/global-search/global-search-data.service';

@Component({
    templateUrl: './global-search-header.component.html',
    styles: ['.gray-head.tab-menu {margin: 0;padding: 0;list-style: none;white-space: nowrap; color: #808080}',
        '.b {font-weight: bold; color: black}',
    ],
})
export class GlobalSearchHeaderComponent implements OnInit {
    public queryString: string;
    public isCCM: boolean;
    public hasSearchUserPermission: boolean;
    public clientCount: number;
    public individualCount: number;
    public userCount: number;
    public hasGlobalSearchPermission: boolean;
    public hasAccessToTab: boolean;
    protected destroy$: Subject<void> = new Subject();

    constructor(
        private user: UserService,
        public stateService: StateService,
        private globalSearchService: GlobalSearchService,
        private trans: Transition,
        private globalSearchDataService: GlobalSearchDataService,
        private readonly cd: ChangeDetectorRef,
    ) {
        this.user = user;
        this.stateService = stateService;
    }

    ngOnInit() : void {
        this.isCCM = this.user.profile.isComergence;
        this.hasSearchUserPermission = this.user.profile.can('CCM_SEARCH_USERS');
        this.hasGlobalSearchPermission = this.user.profile.can('CCM_GLOBAL_SEARCH');
        this.hasAccessToTab = ((this.isCCM && this.hasGlobalSearchPermission) || !this.isCCM);

        this.getQueryString();
        this.getCount();
    }

    getCount() {
        this.globalSearchDataService.totalCountClients$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.clientCount = data;
            this.cd.detectChanges();
        });
        this.globalSearchDataService.totalCountIndividuals$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.individualCount = data;
            this.cd.detectChanges();
        });
        this.globalSearchDataService.totalCountUsers$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.userCount = data;
            this.cd.detectChanges();
        });
    }

    private getQueryString(): void {
        this.queryString = this.trans.params().q;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get clientIsActive(): boolean {
        return this.stateService.includes('search.clients');
    }
    get individualsIsActive(): boolean {
        return this.stateService.includes('search.individuals');
    }
    get userIsActive(): boolean {
        return this.stateService.includes('search.users');
    }
}
