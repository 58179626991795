import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { CustomFieldsModule } from 'shared/fields';
import { CRMModule } from 'lender/crm';

import { LenderContactsResourceService } from 'lender/contacts/lender-contacts-resource.service';
import { LenderNMLSContactsResourceService } from 'lender/contacts/lender-nmls-contacts-resource.service';
import { RelatedAccountModule } from 'lender/contacts/related-account';

import { LenderContactsPageComponent } from './contacts-page.component';
import { LenderContactsListComponent } from './contacts/list.component';
import { LenderOwnersListComponent } from './owners/list.component';
import { LenderContactsBoardComponent } from './contacts/board.component';
import { NewContactsModule } from 'shared/new-contacts';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		ComponentsModule,
		DirectivesModule,
		FormsModule,
		ReactiveFormsModule,
		CustomFieldsModule,
		CRMModule,
		RelatedAccountModule,
        NewContactsModule,
	],
	declarations: [
		LenderContactsPageComponent,
		LenderContactsListComponent,
		LenderOwnersListComponent,
		LenderContactsBoardComponent,
	],
	providers: [
		LenderContactsResourceService,
		LenderNMLSContactsResourceService,
	],
})
export class LenderContactsModule {
}
