<ng-container *ngIf="User.can('TPO_SMC_VIEW_ALL'); then contentTpl; else accessDeniedTpl"></ng-container>

<ng-template #contentTpl>
	<div class="social-compliance-settings-audit-checks">
		<div class="gray-head flex-head">
			<h2>Audit Checklist</h2>

			<div class="col-min">
				<ul class="tab-menu col-min">
					<li uiSrefActive="active" *ngFor="let tab of tabs">
						<a [uiSref]="tab.sref">
							<b>{{tab.name}}</b>
						</a>
					</li>
				</ul>
			</div>
		</div>

		<div ui-view></div>
	</div>
</ng-template>

<ng-template #accessDeniedTpl>
	<no-permissions title="Audit Checklist">
		<img src="/static/images/promo/sm-get-started/Icon_settings_off.svg" alt="Audit Checklist" />
	</no-permissions>
</ng-template>
