<div class="activities-list">
	<h2 *ngIf="!embedded || embedded === 'COMPANY'">Activities</h2>
	<h3 *ngIf="['CONTACT', 'PROSPECT'].includes(embedded)">Related Activities</h3>
	<notification></notification>
	<div class="filters-rows" *ngIf="someFilterAvailable || urlNew">
		<div class="row-fluid filter-labels">
			<div *ngIf="filtersAvailable.search"
				 class="col-sm-{{isCRM ? '2': 'a'}}"
			>
				<div class="text-muted">Search</div>
			</div>
			<div *ngIf="filtersAvailable.priority" class="col-sm-{{isCRM ? '2': 'a'}}">
				<div class="text-muted">Priority</div>
			</div>
			<div *ngIf="filtersAvailable.assignedTo" class="col-sm-{{isCRM ? '2': 'a'}}">
				<div class="text-muted">Assigned to</div>
			</div>
			<div *ngIf="filtersAvailable.status" class="col-sm-{{isCRM ? '2': 'a'}}">
				<div class="text-muted">Status</div>
			</div>
			<div *ngIf="filtersAvailable.indent"
				 class="col-sm-{{isCRM ? 'a': '2'}}"
			></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div *ngIf="filtersAvailable.search"
				 class="col-sm-{{isCRM? '2' : (['CONTACT', 'PROSPECT'].includes(embedded) ? '3' : 'a')}}">
				<search-list placeholder="Activity Name"></search-list>
			</div>
			<div *ngIf="filtersAvailable.priority" class="col-sm-{{isCRM ? '2': 'a'}}">
				<ng-select
						[(ngModel)]="params.priorityId"
						(change)="setFilter({ priorityId: $event })"
						[items]="filters.priorityId?.$promise | async"
						[loading]="!filters.priorityId?.$resolved"
						bindValue="shortName"
						bindLabel="name"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All">
				</ng-select>
			</div>
			<div *ngIf="filtersAvailable.assignedTo" class="col-sm-{{isCRM ? '2': 'a'}}">
				<ng-select
						[(ngModel)]="params.assignedToId"
						(change)="setFilter({ assignedToId: $event })"
						[items]="filters.assignedToId?.$promise | async"
						[loading]="!filters.assignedToId?.$resolved"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All">
				</ng-select>
			</div>
			<div *ngIf="filtersAvailable.status" class="col-sm-{{isCRM ? '2': 'a'}}">
				<ng-select
						[(ngModel)]="params.statusId"
						(change)="setFilter({ statusId: $event })"
						[items]="filters.statusId?.$promise | async"
						[loading]="!filters.statusId?.$resolved"
						bindValue="shortName"
						bindLabel="name"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All">
				</ng-select>
			</div>
			<div *ngIf="!embedded" class="col-sm-{{isCRM ? '2': 'a'}}">
				<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
			</div>
			<div *ngIf="['CONTACT', 'PROSPECT'].includes(embedded)" class="col-sm-a"></div>
			<div class="col-sm-2" *ngIf="urlNew">
				<div class="pull-right">
					<a class="btn btn-primary" [uiSref]="urlNew">Create Activity</a>
				</div>
			</div>
		</div>
		<div class="row-fluid filter-labels" *ngIf="filtersAvailable && moreFilters">
			<div class="col-sm-2">
				<div class="text-muted">Due Date After</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Due Date Before</div>
			</div>
			<div class="col-sm-a"></div>
		</div>
		<div class="row-fluid filter-selectors" *ngIf="filtersAvailable && moreFilters">
			<div class="col-sm-2" *ngIf="moreFilters">
				<input type="text" class="form-control"
					   bsDatepicker
					   [bsConfig]="bsConfig"
					   [bsValue]="params.dueDateAfter"
					   [maxDate]="params.dueDateBefore"
					   (bsValueChange)="setFilter({dueDateAfter: $event})"
					   placeholder="mm/dd/yyyy">
			</div>
			<div class="col-sm-2" *ngIf="moreFilters">
				<input type="text" class="form-control"
					   bsDatepicker
					   [bsConfig]="bsConfig"
					   [bsValue]="params.dueDateBefore"
					   [minDate]="params.dueDateAfter"
					   (bsValueChange)="setFilter({dueDateBefore: $event})"
					   placeholder="mm/dd/yyyy">
			</div>
			<div class="col-sm-a"></div>
		</div>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="activities?.$resolved">
		<div class="row-fluid header">
			<sortable-header class="col-sm-{{isCRM ? '3': '4'}}" columnName="name">Activity Name</sortable-header>
			<sortable-header class="col-sm-a" columnName="status">Status</sortable-header>
			<sortable-header class="col-sm-a" columnName="assignee">Assigned to</sortable-header>
			<sortable-header class="col-sm-a" columnName="createdDate">Created Date</sortable-header>
			<sortable-header class="col-sm-a" columnName="dueDate">Due Date</sortable-header>
			<sortable-header class="col-sm-a" columnName="priority">Priority</sortable-header>
		</div>

		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<span class="col-sm-12 text-center va-m">Filters are applied.
				<a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>

		<a *ngFor="let item of activities" class="row-fluid clickable"
			[uiSref]="url" [uiParams]="{ activities: null, activityId: item.id }"
		>
			<div class="col-sm-{{isCRM ? '3': '4'}}">
				<a class="text-bigger">{{item.name}}</a>
				<br>
				<span *ngIf="item.relatedAccount" class="text-muted">{{item.relatedAccount.name}}</span>
			</div>
			<span class="col-sm-a">
				<span class="text-bigger">
					<span class="label label-{{statusColors[item.status.id] || 'default'}}">
						{{item.status.name || '&ndash;'}}
					</span>
				</span>
			</span>
			<span class="col-sm-a">
				{{item.assignedTo.name}}
			</span>
			<span class="col-sm-a">
				{{item.createdDate | date:'MM/dd/yyyy'}}
			</span>
			<span class="col-sm-a" [ngClass]="{'text-danger font-bold': item.dueDate < currentDate}">
				{{item.dueDate | date:'MM/dd/yyyy'}}
			</span>
			<span class="col-sm-a">
				{{item.priority.name}}
			</span>
		</a>

		<div class="row-fluid" *ngIf="activities?.$resolved && !activities.length">
			<div class="col-sm-12 text-center text-light text-bigger">
				{{(filtersApplied || filters.q) ? 'No matches found' : 'No activities'}}
			</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="activities" [(params)]="params$" [sizes]="pageSizes"></new-paging>
	</div>
</div>
