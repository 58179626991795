import { Component, HostBinding } from '@angular/core';
import { ActivePackage, ActivePackageDetails, SmPackageService } from 'tpo/social-media/sm-package.service';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'sm-subscription',
    templateUrl: 'subscription.component.html',
})
export class SubscriptionComponent {
    activePackage: ActivePackage;
    packageDetails: ActivePackageDetails = {
        'price': 0,
        'addonsPrices': {
            'compliance-audits': 0,
            'reporting': 0,
        },
        'tier': -Infinity,
        'canSubscribe': false,
        'isSubscribed': false,
    };
    marketingSection: string[] = [
        'Entire content lifecycle coverage - Create, Approve, Schedule and Measure',
        'Canva and Caption AI built in',
        'Content approval workflow for Marketing and Compliance teams',
        'Ability to include emojis and hashtags',
        'Utilize platform specific captions',
        'Get content recommendations and recycle content automatically',
        'Analytics and reports to measure results',
        'Find your most engaging content',
        'Analyze your average engagement',
        'Social Listening and earned media tracking',
        'A complete activity feed for on and off platform posts',
        'Social Inbox to manage customer communications',
        '"Like", Delete or hide platform comments directly'
    ];
    complianceManagementSection1 = 'Meet all regulatory obligations for Social Media Monitoring.';
    complianceManagementSection2: string[] = [
        'Configurable to enforce your organization\'s policies and procedures',
        'Track findings and identify compliance training needs',
        'Ability to remediate and provide evidence for regulators'
    ];

    @HostBinding('class.remote-resource') __remote_resource = true;
    @HostBinding('class.resolved') resolved = false;

    constructor(
        private packageService: SmPackageService,
        private userService: UserService,
    ) {
        this.loadDetails();
    }

    async loadDetails() {
        const { profile: { organization: { id } } } = this.userService;
        this.activePackage = await this.packageService.getActivePackage(id).toPromise();
        this.packageDetails = await this.packageService.getActivePackageDetails(id).toPromise();
        this.resolved = true;
    }
}
