import { from, Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { Loader } from '@googlemaps/js-api-loader';

declare let window: Window;

const loader = new Loader({
    apiKey: window.env.googlemaps_api_browserKey,
    version: '3',
    libraries: [],
});
// On demand maps API loading
export const googleLoader$: Observable<typeof google> = of(null).pipe(
    switchMap(() => from(loader.load())),
    shareReplay(1),
);
