import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class CompanyResourceService {
	resource: any;

	constructor(public injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/tpo/company-contacts/current`;

		this.resource = $resource(`${basePath}`, {tpoId: '@tpoId', id: '@id'}, {
			get: {
				method: 'get',
				url: `${apiPath}/tpos/:tpoId/company-contacts/:id`,
			},
			save: {
				method: 'put',
			},
		});
	}

	get = (...args) => this.resource.get(...args);
	save = (...args) => this.resource.save(...args);
}
