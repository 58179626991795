export const statusConfig = {
    A: {
        color: 'success',
        title: 'Active',
    },
    I: {
        color: 'default',
        title: 'Inactive',
    },
};
