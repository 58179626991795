import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { NotificationModalComponent, ConfirmModalComponent } from 'commons/components/modals';

import {
	QuestionnaireParams,
	Question,
	QuestionnaireDocumentAvailable,
} from '../../questionnaires.interface';
import { LenderManageQuestionnaireService } from '../../questionnaires.service';
import { SkipLogic, getSkipLogic } from './get-skip-logic';

@Component({
	selector: 'questionnaire-list',
	templateUrl: './list.component.html',
})
export class QuestionnaireListComponent implements OnInit {
	@Input() hasOwnLoader = true;
	@Output('onQuestionsCantBeReached') _onQuestionsCantBeReached = new EventEmitter<(string | number)[]>();
	@Output('onLoaded') _onLoaded = new EventEmitter<boolean>();

	user: UserProfile;
	params: QuestionnaireParams;
	list: NgResourceArray<Question> = [];
	editId: Question['id'] = null;

	skipLogic: SkipLogic;

	isDeletableCheckInProgress = false;
	isCreating = false;

	availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = [];

	modalRef: BsModalRef;

	constructor(
		uiRouter: UIRouter,
		userService: UserService,
		public modalService: BsModalService,
		private questionnairesService: LenderManageQuestionnaireService,
	) {
		this.params = uiRouter.globals.params;
		this.user = userService.profile;

		if (userService.profile.can('MANAGE_GLOBAL_QUESTIONNAIRE')) {
			// load documents only if user can manage them
			this.availableDocuments = this.questionnairesService.availableDocuments({
				questionnaireId: this.params.questionnaireId,
				questionLevel: true,
			});
		}
	}

	async ngOnInit(): Promise<void> {
		await this.load();
	}

	async load(): Promise<void> {
		const { questionnaireId } = this.params;
		this._setLoaded(false);
		try {
			this.list = await this.questionnairesService.questions({ questionnaireId }).$promise;
			this.calcSkipLogic();
		} catch (error) {
			// error handlers
		}
		this._setLoaded(true);
	}

	_setLoaded(isLoaded: boolean): void {
		if (this.hasOwnLoader) {
			this.list.$resolved = isLoaded;
		}

		this._onLoaded.emit(isLoaded);
	}

	private async updateRemote(): Promise<void> {
		const { questionnaireId } = this.params;
		this._setLoaded(false);
		try {
			this.list = await this.questionnairesService.updateQuestions({ questionnaireId }, this.list).$promise;
			this.calcSkipLogic();
		} catch (error) {
			// error handlers
		}
		this._setLoaded(true);
	}

	private async move(
		questionId: number | string,
		direction: 'UP' | 'DOWN',
	): Promise<void> {
		const { questionnaireId } = this.params;

		const params = {
			questionId,
			questionnaireId,
		};

		const data = {
			questionId,
			direction,
		};

		this._setLoaded(false);
		try {
			this.list = await this.questionnairesService.moveQuestion(params, data).$promise;
			this.calcSkipLogic();
		} catch(e) {
			this.showMoveDeprecated(e.data?.message);
		}
		this._setLoaded(true);
	}

	moveUp = (item: Question): void => {
		this.move(item.id, 'UP');
	};

	moveDown = (item: Question): void => {
		this.move(item.id, 'DOWN');
	};

	toggleEdit = (item: Question, edit?: boolean): void => {
		if (edit === undefined) {
			edit = this.editId !== item.id;
		}

		this.editId = edit ? item.id : null;
	};

	update = async (item: Question, update: Partial<Question>): Promise<void> => {
		Object.assign(item, update);
		this.updateRemote();
	};

	remove = async (questionId: number | string): Promise<void> => {
		const { questionnaireId } = this.params;

		const params = {
			questionId,
			questionnaireId,
		};

		this._setLoaded(false);
		try {
			this.list = await this.questionnairesService.deleteQuestion(params).$promise;
			this.calcSkipLogic();
		} catch(e) {}
		this._setLoaded(true);
	};

	checkIsQuestionRemovable = async (question: Question): Promise<void> => {
		if (this.isDeletableCheckInProgress) {
			return;
		}

		const { questionnaireId } = this.params;

		const params = {
			questionId: question.id,
			questionnaireId,
		};

		this.isDeletableCheckInProgress = true;

		try {
			// empty response if is ok to remove
			await this.questionnairesService.checkIsQuestionRemovable(params).$promise;

			this.showRemoveConfirmation(question);
		} catch(e) {
			this.showRemoveDeprecated(e.data?.message);
		}
	};

	showRemoveConfirmation = async (question: Question): Promise<void> => {
		this.isDeletableCheckInProgress = false;

		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: 'Confirm to Proceed',
				message: `Are you sure you want to delete this ${question._number ? 'question' : 'group'}?<br><b>"${question.text}"</b>`,
				onConfirm: async () => {
					this.remove(question.id);
					this.modalRef.hide();
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	showRemoveDeprecated(notification: string): void {
		this.isDeletableCheckInProgress = false;
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Cannot Remove Question',
				notification,
			},
			class: 'modal-smd modal-403',
		});
	}

	showMoveDeprecated(notification: string): void {
		this.modalRef = this.modalService.show(NotificationModalComponent, {
			initialState: {
				title: 'Cannot Move Question',
				notification,
			},
			class: 'modal-smd modal-403',
		});
	}

	calcSkipLogic(): void {
		this.skipLogic = getSkipLogic(this.list);
		this._onQuestionsCantBeReached.emit(this.skipLogic.questionsCantBeReached);
	}
}
