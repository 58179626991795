import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'card-preview',
    templateUrl: './card-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPreviewComponent {
    list = Array(5).fill(true).map((_, index) => ({ title: `Card ${index + 1}` }));
}
