import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
	selector: '[aDisabled]',
})

export class ADisabledDirective {
	@Input('aDisabled') disabled: boolean;
	@HostBinding('class.disabled') public disabledClass: any;
	@HostBinding('attr.a-disabled') public disabledAttribute: any;

	ngOnChanges() {
		this.disabledClass = this.disabled;
		this.disabledAttribute = this.disabled;
	}
}
