<ng-template #panelTemplate>
	<form class="panel" [formGroup]="postForm" (onValidSubmit)="save()">
		<div class="panel-body">

			<div class="alert alert-danger" *ngIf="removedAccounts()">
				The accounts previously selected have been removed. Please update the accounts you wish to publish.
			</div>

			<form-errors [form]="postForm"></form-errors>

			<h3 class="panel-title">{{title}}</h3>

			<section class="remote-resource" [class.resolved]="isResolved()">

				<div class="form-group">
					<label class="control-label required">Accounts</label>
					<div class="pull-right" *ngIf="!noAccounts()">
						<button type="button" class="btn btn-link btn-small" (click)="openPrivacySettingsModal()">Privacy</button>
					</div>
					<ng-select
						class="social-compliance-publisher-post-accounts"
						formControlName="linkIds"
						[items]="accounts.options$ | async"
						bindValue="linkId"
						bindLabel="name"
						[loading]="!accounts.options?.$resolved"
						[multiple]="true"
						[closeOnSelect]="false"
						placeholder="Select Accounts"
					>
						<ng-template ng-multi-label-tmp let-items="items">
							&nbsp;{{items.length}} selected
						</ng-template>

						<ng-template ng-option-tmp let-item="item">
							<div class="flex-row gap">
								<sm-icon
									[config]="item"
									[minified]="true"
									[showTooltip]="false"
								></sm-icon>
								<div class="flex text-overflow ng-option-label">{{item.name}}</div>
								<div class="text-big" *ngIf="item.ownerType">
									<span class="label label-primary">{{item.ownerType}}</span>
								</div>
							</div>
						</ng-template>
					</ng-select>
				</div>

				<div class="form-group" *ngIf="template.show && post.showFullPostEditor">
					<a uiSref="^publisher.library.templates">Templates</a>
				</div>

				<div class="form-group">
					<editable-component [editable]="post.isEditable || (post.showFullPostEditor && !post.templated)">

						<ng-template #editTemplate>
							<textarea
								class="form-control resize-vertical"
								formControlName="text"
								rows="4"
								maxlength="63100"
								placeholder="Enter Post"
							>
							</textarea>
							<!-- <formatted-text
								*ngIf="viewText?.postfix"
								[text]="viewText?.postfix | textToUrl"
							></formatted-text> -->
							<limits
								[accounts]="preview.accounts"
								[textLength]="viewText?.fullText.length || 0">
							</limits>
						</ng-template>

						<ng-template #viewTemplate>
							<formatted-text [text]="post.text"></formatted-text>
						</ng-template>

					</editable-component>
				</div>

				<div class="form-group" *ngIf="!post?.rssItemId && (!post.templated || post.attachments?.length) || post.isEditable">
					<post-attachments
						[post]="post"
					  	[editable]="(!post.templated && post.showFullPostEditor) || post.isEditable"
					  	[config]="attachment.config"
					  	(error)="attachment.error($event)"
					  	(status)="attachment.status($event)">
					</post-attachments>
				</div>

				<div class="form-group" *ngIf="(post.isEditable || post.canEdit) && campaigns.canManage">
					<label class="control-label">Campaigns</label>
					<ng-select
						class="social-compliance-publisher-post-campaigns"
						formControlName="campaigns"
						[items]="campaigns.options$ | async"
						bindValue="id"
						bindLabel="name"
						[searchable]="true"
						[multiple]="true"
						[closeOnSelect]="false"
						placeholder="Select Campaigns"
						appendTo="body"
					></ng-select>
				</div>

<!--				<div class="form-group" *ngIf="postForm.get('publish')">-->
<!--					<label class="control-label">Publish</label>-->
<!--					<ng-select-->
<!--						formControlName="publish"-->
<!--						[items]="publish.options"-->
<!--						bindValue="id"-->
<!--						bindLabel="title"-->
<!--						[searchable]="false"-->
<!--						[clearable]="false"-->
<!--						appendTo="body"-->
<!--					>-->
<!--					</ng-select>-->
<!--				</div>-->

				<div class="form-group" *ngIf="postForm.get('scheduledDate') && postForm.get('scheduledTime') && postForm.get('scheduledTimezone') && publish.scheduling">
					<label class="control-label required">Schedule Date</label>
					<div class="flex-row schedule-date-time">
						<div class="flex-grow">
							<input type="text" class="form-control"
								   formControlName="scheduledDate"
								   bsDatepicker
								   [bsConfig]="bsConfig"
								   placement="right"
								   placeholder="mm/dd/yy"
							/>
						</div>
						<timepicker formControlName="scheduledTime"></timepicker>
					</div>
					<ng-select
						formControlName="scheduledTimezone"
						[items]="publish.timezones"
						bindValue="id"
						bindLabel="text"
						[searchable]="false"
						[clearable]="false"
						placeholder="Select Timezone"
						appendTo="body"
					>
					</ng-select>
				</div>

			</section>
		</div>
		<div class="panel-footer" *ngIf="postForm.get('publish')">
			<div class="pull-left" *ngIf="!isNew">
				<button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
			</div>
			<div class="pull-right">
<!--				<button type="button" class="btn btn-default"-->
<!--						*ngIf="!publish.scheduling"-->
<!--						[disabled]="!isResolved() || attachment.resolving"-->
<!--						(click)="submit(true)"-->
<!--				>-->
<!--					Save Draft-->
<!--				</button>-->
				<button type="button" class="btn btn-primary"
						[disabled]="!isResolved() || attachment.resolving"
						(click)="submit()"
				>
					{{publish.scheduling ? 'Schedule' : 'Publish'}}
				</button>
				<button type="submit" #postFormSubmit class="hidden"></button>
			</div>
		</div>
	</form>
</ng-template>

<full-page-layout *ngIf="!embedded" class="social-compliance-publisher-post edit" (onClose)="close()">

	<ng-template #headerTemplate>
		<h4 class="page-title">Post Editor</h4>
	</ng-template>

	<ng-template #contentTemplate>
		<ng-container *ngTemplateOutlet="panelTemplate"></ng-container>
		<posts-preview
			[class.hide-logo]="preview.accounts.length"
			[accounts]="preview.accounts"
			[text]="viewText?.fullText || ''"
			[resolvedDynamicContent]="resolvedDynamicContent"
			[attachments]="post.attachments"
			[attachmentsErrors]="attachmentsErrors"
			[previewDate]="sentDate"
			[linkPreview]="linkPreview"
		></posts-preview>
	</ng-template>

</full-page-layout>

<ng-container *ngIf="embedded">
	<div class="social-compliance-publisher-post edit">
		<ng-container *ngTemplateOutlet="panelTemplate"></ng-container>
	</div>
</ng-container>
