<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
	<h4 class="modal-title">Delete Custom Field</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div class="alert alert-danger text-center">Warning</div>
	Are you sure you want to delete this custom text field from your {{entityName}} page?
	<br>
	<br>
	Once deleted, the data will be permanently deleted from the system, and this action cannot be undone.
	<br>
	<br>
	<label class="flex-row text-normal">
		<input [(ngModel)]="agreed" type="checkbox">&nbsp; Yes, I want to delete the custom field.
	</label>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="text-right">
		<button type="button" (click)="onConfirm()" class="btn btn-primary" [disabled]="!agreed">Yes</button>
		<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
	</div>
</div>
