import { find } from 'lodash';
import { Attachment } from 'commons/components/attachments-preview/attachments-preview.interface';
import { Dimension } from 'commons/interfaces';

export const getKey = (type: string, errorType: string): string => {
	// for example 'IMAGE' => 'Image' or 'VIDEO' => 'Video'
	const typeCorrectCase = type.charAt(0).toUpperCase() + type.substr(1).toLowerCase();
	// for example max-Size => maxImageSize or maxVideoSize
	return errorType.replace('-', typeCorrectCase);
};

export const lastIndex = (array: any[] | FileList): number => array.length - 1;

export const setSubType = (attachment: { name, size, type, subtype }): void => {
	const isGif = (name): boolean => /^.+\.(GIF)$/i.test(name);
	if (isGif(attachment.name)) {
		// set the new content type! if back-end will add the new type(GIF) we can remove this code.
		attachment.subtype = 'GIF';
	} else {
		attachment.subtype = attachment.type;
	}
};

export const getImageExtension = (type: string | 'image/jpeg'): string | 'image.jpeg' => (
	type.includes('image') && type.replace('image/', 'image.')
);

export const configTest = (config: GlobalLimits, name: string): GlobalLimits | false => (
	find(config.formats, (format) => format.test(name)) && config
);

export interface GlobalLimits {
	formats: RegExp[];
	maxSize: number;
	type: string;
}

export interface ProcessedAttachment extends Attachment {
	name: string;
	size: number;
	subtype: string;
	dimension: Dimension;
	loaded: boolean;
}

export interface AttachmentError { $error?, networksMessages? }
