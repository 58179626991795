<div class="document-card client-document lender-client-library-document" [class.remote-resource]="resolving">
	<div class="front-face stretch gap-m">
		<div [ngClass]="statusClassNames"></div>
		<div class="details">
			<div class="title">
				<a href="{{getLink()}}"
				   [aDisabled]="!(tpoFinancialDocument.uploaded && investorId && isOnGoing)"
				   target="_blank"
				>
					{{ tpoFinancialDocument.name }}
				</a>
			</div>

			<show-more class="description text-muted" [text]="tpoFinancialDocument.description"></show-more>
		</div>
		<div class="indications gap-sm">
			<ng-container *ngIf="!investorId && tpoFinancialDocument.requestorCount">
				<span class="label label-danger-wired text-smaller">
					Requested by {{tpoFinancialDocument.requestorCount}} Investor{{tpoFinancialDocument.requestorCount !== 1 ? 's' : ''}}
				</span>
				&nbsp;
			</ng-container>

			<span *ngIf="hasAuditedStatus" class="label label-default text-smaller">
				{{ auditedText }}
			</span>

			<span *ngIf="tpoFinancialDocument.period" class="info-text text-smaller" [ngClass]="{ 'kill-padding-left': !hasAuditedStatus }">
				{{ tpoFinancialDocument.period }}
			</span>

			 <a
				class="btn btn-tertiary"
				uiSref=".:documentType.history"
				[uiParams]="{ documentType: tpoFinancialDocument.type | lowercase }"
			>
				<span class="c-icon c-icon-back-in-time"></span> History
			</a>

            <span *ngIf="investorId && hasRequestPermission && tpoFinancialDocument.hasUnreviewedDocuments"
                  class="c-icon c-icon-attention icon-warning"
                  [tooltip]="'Unreviewed Documents'"
            ></span>

			<!-- TODO -->
			<!-- <span class="info-text text-smaller">
				Document requested by {{ tpoFinancialDocument.requestedByName }} - {{ tpoFinancialDocument.requestedDate | date:'MM/dd/yyyy' }}
			</span> -->
		</div>
		<div class="flex-row gap-sm">
			<div class="actions flex-column right gap-sm">
				<ng-container *ngIf="User.isTpo">
					<a
						class="btn btn-primary w-full"
						(click)="openUploadFileModal()"
					>
						Upload File
					</a>
					<a
						*ngIf="tpoFinancialDocument.hasOpenRequests"
						class="btn btn-default w-full"
						uiSref=".:documentType.requests"
						[uiParams]="{ documentType: tpoFinancialDocument.type | lowercase }"
					>
						Open Requests
					</a>
				</ng-container>
				<span *ngIf="documentCanBeReviewed && !tpoFinancialDocument.reviewedBy" class="btn btn-primary w-full" (click)="markAsReviewed()">Mark Reviewed</span>
				<div *ngIf="documentCanBeReviewed && tpoFinancialDocument.reviewedBy">
					<div class="row-fluid">
						<span class="flex-column pull-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<span class="flex-column pull-left">
							<div class="glyphicon glyphicon-ok-sign glyphicon-object-align-vertical text-success medium-icon"></div>
						</span>
						<span class="pull-left small-left-padding">
							<div class="text-smaller font-bold">Reviewed by</div>
							<div class="text-smaller text-muted">{{tpoFinancialDocument.reviewedBy}}</div>
							<div class="text-smaller text-muted">{{tpoFinancialDocument.reviewedDate | date : 'MM/dd/yyyy HH:mm'}}</div>
						</span>
					</div>
				</div>
			</div>
			<div
				class="dropdown"
				dropdown container="body"
				placement="bottom right"
				*ngIf="User.isLender &&
					  (investorId && hasRequestPermission && isOnGoing ||
					  tpoFinancialDocument.hasOpenRequests ||
					  hasRequestPermission && isOnGoing)"
			>
				<a class="btn btn-icon btn-tertiary text-bigger dropdown-toggle" dropdownToggle>
					<span class="c-icon c-icon-dot-3"></span>
				</a>
				<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
					<li role="menuitem" *ngIf="investorId && hasRequestPermission && isOnGoing">
						<a (click)="request()">Request</a>
					</li>
					<li role="menuitem" *ngIf="tpoFinancialDocument.hasOpenRequests">
						<a
							uiSref=".:documentType.requests"
							[uiParams]="{ documentType: tpoFinancialDocument.type | lowercase }"
						>
							Open Requests
						</a>
					</li>
					<li role="menuitem" *ngIf="hasRequestPermission && isOnGoing">
						<a (click)="openClientFinancialSettings()">Client Financial Settings</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
