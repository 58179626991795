import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

export interface AccessibleCategories {
	code: string;
	label: string;
}

@Injectable()
export class InvestorClientCommentsService {
	comments: any;
	accessibleCategories: any;

	constructor(injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const basePath = `${apiPath}/tpos/:tpoId`;

		this.comments = PagedResource(`${basePath}/comment`, { tpoId: '@tpoId' }, {
			query: { method: 'GET', isArray: true },
			add: { method: 'POST' },
			commentType: {
				method: 'GET',
				isArray: true,
				url: `${apiPath}/commenttype`,
			},
		});

		this.accessibleCategories = $resource(`${basePath}/comment/accessible-categories`, { tpoId: '@tpoId' });
	}
}
