import { Component, Input } from '@angular/core';
import { RealmFormGroup } from 'commons/forms';
import { ContactListCriteria } from '../../../investor-crm-contact-list.interface';
import { InvestorContactListClauseManager } from '../or-clause-manager/InvestorContactListClauseManager';
import { OrClauseFormArray } from '../or-clause-manager/OrClauseFormArray';


@Component({
    selector: 'investor-crm-contact-list-criteria',
    templateUrl: './investor-crm-contact-list-criteria.component.html',
})
export class InvestorCrmContactListCriteriaComponent {
    @Input() criteriaResolved: boolean;
    @Input() listForm: RealmFormGroup;
    @Input() isNew: boolean;
    @Input() editing: boolean;
    @Input() orClauseManager: InvestorContactListClauseManager;
    @Input() criteria: ContactListCriteria;

    get orClauses(): OrClauseFormArray[] {
        return this.orClauseManager.orClauses;
    }

    get addAndConditionButtonLabel(): string {
        return (this.orClauseManager.orClauseCount ? '+ OR Condition' : 'Add Condition');
    }

    addOrClause(): void {
        this.orClauseManager.addOrClause(true);
    }

    addAndClause(orClause: OrClauseFormArray): void {
        this.orClauseManager.addAndClauseToOrClause(orClause);
    }

    removeAndCondition(orClauseIndex: number, andClauseIndex: number): void {
        this.orClauseManager.removeAndClauseFromOrClause(orClauseIndex, andClauseIndex);
    }
}
