import { Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { CcmTpoService } from 'comergence/tpo/ccm-tpo.service';
import { SmPackage } from './invoicing-settings/invoicing-settings.service';

@Component({
    templateUrl: './social-media.component.html',
})
export class SocialMediaBillingComponent {
    organizationId: number;
    smPackage: SmPackage;

    constructor(
        { params }: UIRouterGlobals,
        protected ccmTpoService: CcmTpoService,
    ) {
        const { id: tpoId } = params;
        this.ccmTpoService.getTpo(tpoId)
            .subscribe(({ organizationId }) => this.organizationId = organizationId);
    }

    setSmPackage(smPackage: SmPackage): void {
        this.smPackage = smPackage;
    }
}
