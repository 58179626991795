import { Component, Input } from '@angular/core';

@Component({
	selector: 'form-show-password',
	templateUrl: './show-password.component.html',
})
export class FormShowPasswordComponent {
	@Input()
	form: any;

	@Input()
	control: string;

	@Input()
	maxlength: number = 1000;

	@Input()
	class: string = '';

	@Input()
	tabindex: string;

	fieldType: string = 'password';

	toggleType() {
		this.fieldType = (this.fieldType === 'text') ? 'password' : 'text';
	}
}
