import { Component, Input } from '@angular/core';
import { CcmDataMatchReport } from 'comergence/data-match/ccm-data-match.interface';


@Component({
    selector: 'ccm-data-match-hq-statistics-section',
    templateUrl: 'ccm-data-match-hq-statistics-section.component.html',
})
export class CcmDataMatchHqStatisticsSectionComponent {
    @Input() dataMatchStatistics: CcmDataMatchReport;

    constructor() {}
}
