<ng-container *ngIf="hasViewPermissions; then validPermissions; else invalidPermissions"></ng-container>
<ng-template #validPermissions>
	<div class="gray-head">
		<h2>{{(details.$promise | async)?.name}}</h2>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
		<div class="row-fluid header">
			<div class="col-md-3">Date</div>
			<div class="col-md-6">Action</div>
			<div class="col-md-3">Action By</div>
		</div>

		<div
			*ngFor="let item of list | async"
			class="row-fluid"
			[class.clickable]="item.oldValue"
			(click)="item.oldValue && showHistoryModal(item)"
		>
			<div class="col-md-3 va-m">{{item.date | date: 'MM/dd/yyyy HH:mm'}}</div>
			<div class="col-md-6 va-m" [class.text-link]="item.oldValue">{{item.action}}</div>
			<div class="col-md-3 va-m">{{item.actionBy}}</div>
		</div>

		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
			<div class="col-sm-12 text-center text-muted text-bigger">No data</div>
		</div>

		<br>
		<paginator [sizes]="[10,25,50]"></paginator>
	</div>
</ng-template>
<ng-template #invalidPermissions>
	<no-permissions title="Triggers">
		<img src="/static/images/promo/sm-get-started/Icon_settings_off.svg" alt="Triggers"/>
	</no-permissions>
</ng-template>
