import { Component, Input, OnInit } from '@angular/core';
import { InvestorsService } from 'tpo/account/investors/investors.service';
import { InvestorInformation } from 'tpo/account/investors/investors.interface';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
    selector: 'investor-information-table',
    templateUrl: 'investor-information-table.component.html',
})
export class InvestorsInformationTableComponent implements OnInit {
    @Input() lenderInfo: AccountInformation;
    resolving: boolean;
    investorsList: InvestorInformation[];

    constructor(
        public investorsService: InvestorsService,
    ) {}

    async ngOnInit(): Promise<void> {
        try {
            this.investorsList = await this.investorsService.getInvestorsInformation(this.lenderInfo.identifier.tpoId).toPromise();
        } finally {
            this.resolving = true;
        }
    }
}

