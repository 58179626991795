import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { BrowserModule } from '@angular/platform-browser';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';
import { CcmContactMenuComponent } from 'comergence/new-contacts/details/ccm-contact-menu.component';
import { CcmContactGeneralInformationComponent } from 'comergence/new-contacts/details/general-information/ccm-company-general.component';
import { NewContactsModule } from 'shared/new-contacts';

@NgModule({
	imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        PipesModule,
        NewContactsModule,
	],
	declarations: [
        CcmContactDetailsComponent,
        CcmContactMenuComponent,
        CcmContactGeneralInformationComponent,
	],
	providers: [
	],

})
export class CcmContactsModule {
}
