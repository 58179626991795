import { Component, Input } from '@angular/core';

@Component({
    selector: 'no-permissions',
	templateUrl: './no-permissions.component.html',
})
export class NoPermissionsComponent {
	@Input() title: string;
	@Input() permissionMessage? = 'You have no permissions to access this functionality';
}
