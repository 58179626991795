<div>
	<ul class="breadcrumbs" *ngIf="enabled">
		<li>
			<a (click)="goBack()" class="history-back"><span class="glyphicon glyphicon-chevron-left"></span> Back</a>
		</li>
	</ul>
	<h2 [ngClass]="[opts.headerClass]">
		<ng-content select="*"></ng-content>
	</h2>
</div>
