import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { ProspectsService } from 'lender/prospects/prospects.service';
import { TpoContactType } from 'shared/new-contacts/contacts.interface';
import { RelatedProspectContact } from 'lender/prospects/prospects.interface';

@Component({
    selector: 'single-contact-row',
    templateUrl: './single-contact-row.component.html',
    host: {
        'class': 'remote-resource',
    },
})
export class ProspectSingleContactRowComponent implements OnInit {
    @Input() public editable = false;
    @Input() public item: RelatedProspectContact;
    @Input() public prospectId: number;
    @Output('remove') public onRemove = new EventEmitter<void>();
    @HostBinding('class.resolved') public resolved = true;
    contactSref: string;
    contactSrefParams: Params;
    protected askRemove = false;

    constructor(
        private readonly prospectService: ProspectsService,
    ) {
    }

    ngOnInit(): void {
        this.fillContactSref();
    }

    private fillContactSref() {
        const {
            contactId,
            companyNmlsId,
            contactType,
            relatedAccount: { tpoId: id } = {},
        } = this.item;

        if (contactType == TpoContactType.CRM) {
            this.contactSref = 'crm.contacts.:contactId.information';
            this.contactSrefParams = { contactId };
            return;
        }

        if (id) {
            this.contactSref = 'client.:id.contacts.:contactId.information';
            this.contactSrefParams = { contactId, id };
            return;
        }

        this.contactSref = 'nmls.client.:id.contacts.:contactId.information';
        this.contactSrefParams = { contactId, id: companyNmlsId };
    }


    protected async remove(): Promise<void> {
        const { organizationContactId } = this.item;
        this.resolved = false;
        try {
            await this.prospectService.removeContact(this.prospectId, organizationContactId).toPromise();
            this.resolved = true;
            this.onRemove.emit();
        } catch (e) {
            this.resolved = true;
            throw e;
        }
    }
}
