import { TransitionService, StateService, UIRouter } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Mixin } from 'utils/mixin.decorator';

import { UserService } from 'angularjs-providers/user.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { SharedSocialMediaService } from 'shared/social-media/social-media.service';

import { SocialAccountsBasicListComponent } from '../basic-list';

import { SocialConnectResourceService } from '../social-connect-resource.service';
import { ConnectSmMixin } from '../../connect';

@Mixin([ConnectSmMixin])
export abstract class SocialAccountsConnectComponent extends SocialAccountsBasicListComponent {
	accessToken: any;

	// IConnectMixin interface requirements
	networks;
	openConnectWindow;
	connectCommon;
	hideModal;
	connectModalState;
	connecting;
	connect;
	reconnect;
	connectFacebook;
	connectFacebookPage;
	noFacebookPagesToConnect;
	showFacebookPagesSelection;
	connectLinkedIn;
	connectLinkedInPages;
	openLinkedInPagesWindow;
	openNoLinkedInPagesWindow;
	showConnectInstagramModal;
	facebookUserShouldLogout;
	connectSelectedFacebookPages;
	showFacebookMortgageRelatedSelection;
	getStatusesForConnect;
	connectGMB;
	showGMBPages;
	finishGMBConnect;
	callbacks;
	onMessage;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		userService: UserService,
		$state: $stateProvider,
		modalService: BsModalService,
		sharedSocialMediaService: SharedSocialMediaService,
        uiRouter: UIRouter,
		public socialConnectService: SocialConnectResourceService,
	) {
		super(
			transitionService,
			stateService,
			userService,
			$state,
			modalService,
			sharedSocialMediaService,
            uiRouter,
		);
	}
}
