import { Component } from '@angular/core';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './advertising-modal.component.html',
})
export class AdvertisingModalComponent {
	onCancel: (params?) => any;
	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => this.modalRef.hide(),
		});
	}
}
