import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { BaseConnectorAdapter } from '../base-connector.adapter';
import { SmTypes } from '../connector.interface';
import { GmbLocationsModalComponent } from '../../gmb-locations-modal';
import { GmbLocation } from './gmb-connector.interface';

declare let apiPath: string;

@Injectable()
export class GmbConnectorAdapter extends BaseConnectorAdapter {
    public User: UserProfile;
    public modalRef: BsModalRef;
    private entry: unknown;
    static openedWindow: Window | null;
    protected override windowParams = { width: 600, height: 700 } as const;
    protected override connectType = SmTypes.DM as const;

    private readonly url = '/googlemb/dm-connect-flow';
    private get windowName() {
        return `connect:/${ this.url }` as const;
    }

    constructor(
        private modalService: BsModalService,
        private http: RealmHttpClient,
        { profile }: UserService,
    ) {
        super();
        this.User = profile;
    }

    public connect(entry?: unknown): void {
        this.entry = entry;
        if (GmbConnectorAdapter.openedWindow?.focus) {
            GmbConnectorAdapter.openedWindow.focus();
        }

        const { width, height } = this.windowParams;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        GmbConnectorAdapter.openedWindow = window.open(this.url, this.windowName, `width=${ width },height=${ height },top=${ top },left=${ left }`);
        window.addEventListener('message', this.onMessage);
    }

    protected onMessage = (event: MessageEvent): void => {
        const { linkId = null } = this.entry as { linkId: string } || { linkId: null };
        const isReconnect = !!linkId;

        if (GmbConnectorAdapter.openedWindow !== event.source) {
            return;
        }

        try {
            const { connected } = JSON.parse(event.data);
            GmbConnectorAdapter.openedWindow?.close();

            if (connected) {
                if (!isReconnect) {
                    this.modalRef = this.showGMBLocationsModal()
                } else {
                    this.finishGMBReconnect(linkId);
                }
            }
        } catch (error) {
            console.error('onMessage Error', error);
            console.warn('onMessage Error (Event)', event);
        }
        window.removeEventListener('message', this.onMessage);
        GmbConnectorAdapter.openedWindow?.addEventListener('close', this.onWindowClose);
    };

    protected onWindowClose() {
        GmbConnectorAdapter.openedWindow = null;
    }

    private showGMBLocationsModal() {
        return this.modalService.show(GmbLocationsModalComponent, {
            class: 'modal-smd modal-new',
            initialState: {
                locations$: this.getGMBLocations(),
                onConfirm: (locationNames: string[]) => {
                    this.hideModal();
                    this.finishGMBConnect(locationNames).subscribe(() => {
                        this.onConnectFinish$.next();
                    });
                },
                onCancel: () => this.hideModal(),
            },
        });
    }

    private hideModal(): void {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }

    private get apiPrefix() {
        return `${apiPath}/${this.connectType}/${this.User.organization.nmlsId}/googlemb`;
    }
    
    private getGMBLocations(): Observable<GmbLocation[]> {
        return this.http.request<GmbLocation[]>(
            'GET',
            `${this.apiPrefix}/locations/profile`,
        );
    }

    private finishGMBConnect(names: string[]): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${this.apiPrefix}/connect/finish`,
            null,
            { names },
        );
    }

    private finishGMBReconnect(linkId): void {
        this.http.request<void>(
            'POST',
            `${this.apiPrefix}/reconnect/finish`,
            null,
            { linkId },
        ).subscribe(() => {
            this.onConnectFinish$.next();
        });
    }

    public cleanUp(): void {
        window.removeEventListener('message', this.onMessage);
        try {
            GmbConnectorAdapter.openedWindow?.close();
        } catch (e) {}
    }
}
