import { Injector, Injectable } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class ComergenceCustomerResourceService {
	customer: any;

	constructor(
		public injector: Injector,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const basePath = `${apiPath}/customers/:customerType/:organizationId`;

		this.customer = PagedResource(basePath, {}, {
			list: {
				method: 'get',
				isArray: true,
			},
			add: {
				method: 'post',
				params: {customerType: '@customerType'},
			},
			save: {
				method: 'put',
				params: {customerType: '@customerType', organizationId: '@organizationId'},
			},
			get: {
				method: 'get',
				params: {customerType: '@customerType', organizationId: '@organizationId'},
			},
			setStatus: {
				url: basePath + '/status',
				method: 'put',
			},
		});
	}

	list = (...args) => this.customer.list(...args);
	add = (...args) => this.customer.add(...args);
	save = (...args) => this.customer.save(...args);
	get = (...args) => this.customer.get(...args);
	setStatus = (...args) => this.customer.setStatus(...args);
}
