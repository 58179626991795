import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedData, RealmHttpClient } from 'commons/services/http';

import { FullTpoContact, TableauReportingGroup } from 'shared/new-contacts/contacts.interface';
import { ContactServiceInterface } from 'shared/new-contacts/information/contact-service.interface';

import { TpoContactsListParams, TpoContact } from './contacts.interface';

declare let apiPath: string;

@Injectable()
export class TpoContactsService implements ContactServiceInterface {
    protected user: UserProfile;
    protected params: {
        tpoId?: number,
        organizationId?: number;
    } = {};

    constructor(
        protected http: RealmHttpClient,
        { profile }: UserService,
    ) {
        this.user = profile;
        if (this.user.isTpo) {
            this.params.tpoId = this.user.organization.id;
            this.params.organizationId = this.user.organization.organizationId;
        }
    }

    getList = (
        params: TpoContactsListParams,
        tpoId = this.params.tpoId
    ): Observable<PagedData<TpoContact[]>> =>
        this.http.pagedRequest<TpoContact[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/contacts`,
            params,
        );

    grantAccess(contact: FullTpoContact, contactId: number, tpoId: number = this.params.tpoId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'post',
            `${apiPath}/tpos/${tpoId}/contacts/${contactId}/user-access`,
            {},
            contact
        );
    }

    getContact(contactId: number, tpoId: number = this.params.tpoId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'get',
            `${apiPath}/tpos/${tpoId}/contacts/${contactId}`,
        );
    }

    saveNewContact(contact: FullTpoContact, tpoId: number = this.params.tpoId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'post',
            `${apiPath}/tpos/${tpoId}/contacts`,
            {},
            contact,
        );
    }

    saveExistingContact(contact: FullTpoContact, tpoId: number = this.params.tpoId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'put',
            `${apiPath}/tpos/${tpoId}/contacts`,
            {},
            contact,
        );
    }

    removeContact(contact: FullTpoContact, tpoId: number = this.params.tpoId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'delete',
            `${apiPath}/tpos/${tpoId}/contacts/${contact.contactId}/status`,
            {},
            contact,
        );
    }

    restoreContact(contact: FullTpoContact, tpoId: number = this.params.tpoId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'post',
            `${apiPath}/tpos/${tpoId}/contacts/${contact.contactId}/status`,
            {},
            contact,
        );
    }

    getReportingGroups(organizationId: number = this.params.organizationId): Observable<TableauReportingGroup[]> {
        return this.http.request<TableauReportingGroup[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/reporting/groups`,
        );
    }
}
