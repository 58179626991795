import { each, reduce } from 'lodash';
import * as LRUCache from 'lru-cache';

import { CacheProvider } from './interfaces/cache-provider.interface';
import { CacheOptions } from './interfaces/cache-options.interface';
import { CacheEntity } from './interfaces/cache-entity.interface';
import { CacheStats } from './interfaces/cache-stats.interface';

import { cacheEntities, cachesToSnapshot } from './cache-entities';
import { realmCacheSnapshots } from './cache-snapshots';

import { normalizeOptions, getConfigsToLoad, getCacheName } from './helpers';

class Cache {
	// do not allow to create caches if main cache factory is disposed
	disposed: boolean = false;

	constructor() {
		this.restore();
	}

	get = (options: CacheOptions): CacheProvider => {
		if (this.disposed || !options) {
			return null;
		}

		const { name, target, max, dump } = normalizeOptions(options);
		const record: CacheEntity = cacheEntities.get(name);

		if (record && record.cache) {
			return record.cache;
		}

		const newCache = new LRUCache(max);

		if (dump) {
			newCache.load(dump);
		}

		cacheEntities.set(name, { cache: newCache, target, max });

		// stage to snapshot
		if (target !== 'in-memory') {
			cachesToSnapshot.push(name);
		}

		return newCache;
	}

	stats = (): CacheStats => {
		const caches = Array.from(cacheEntities);

		const data = {
			cachesToSnapshot,
			totalCaches: cacheEntities.size,
			load: reduce(caches, (acc, [ name, { cache, max, target } ]) => {
				return [
					...acc,
					{
						name,
						currentSize: cache.length,
						max,
						target,
					},
				];
			}, []),
		};

		return data;
	}

	// TODO: cover it with tests when we have some data in localStorage caches
	cleanAllData() {
		this.disposed = true;
		// stop sync timer
		realmCacheSnapshots.dispose();

		// restore configs from localStorage and sessionStorage and put in memory
		const configs = getConfigsToLoad();
		each(configs, (cacheOptions: CacheOptions) => {
			const nameToDelete = getCacheName(cacheOptions.name);
			const target = cacheOptions.target;

			window[target].removeItem(nameToDelete);
		});

		this.dispose();

		// remove cache names staged to snapshot
		cachesToSnapshot.splice(0, cachesToSnapshot.length);
	}

	dispose = (): void => {
		this.disposed = true;

		// stop sync timer
		realmCacheSnapshots.dispose();

		// then reset current in-memory caches
		cacheEntities.forEach((cacheEntity) => {
			cacheEntity.cache.reset();
		});

		cacheEntities.clear();
	}

	restore = (): void => {
		this.disposed = false;

		// restore configs from localStorage and sessionStorage and put in memory
		const configs = getConfigsToLoad();
		each(configs, (cacheOptions: CacheOptions) => {
			this.get(cacheOptions);
		});

		// start timer to make dumps
		realmCacheSnapshots.restore();
	}
}

const realmCache = new Cache();

export { realmCache };
