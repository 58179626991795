export const UploadResourceProps = {
	method: 'POST',
	headers: {
		'Content-Type': undefined,
	},
	transformRequest: ({ file }) => {
		const formData = new FormData();
		formData.append('file', file);
		return formData;
	},
};
