import { Component, OnInit } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChannelInfo, ChannelsService } from 'lender/clients/$id/channels/channels.service';
import { Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Availability } from 'lender/clients/clients.interface';

@Component({
    templateUrl: './purchase.modal.html',
})
export class ChannelPurchaseModalComponent implements OnInit {
    resolving = true;
    minDate = new Date();
    availableStatuses: Availability[];
    channelId: number;
    tpoId: number;
    afterSave: (channelInfo: ChannelInfo) => void;

    form = new RealmFormGroup({
        restoreToStatusId: new RealmFormControl('restoreToStatusId', { label: 'Relationship status' }, Validators.required),
        restoreDate: new RealmFormControl('restoreDate', { label: 'Renewal Date' }, Validators.required),
    });

    //DI
    constructor(
        protected readonly channelsService: ChannelsService,
        readonly modalRef: BsModalRef,
    ) {
    }

    async ngOnInit() {
        try {
            this.availableStatuses = await this.channelsService.getRenewableStatuses().toPromise();
        } catch (e) {
            this.modalRef.hide();
            throw e;
        }
        this.resolving = false;
    }

    async save() {
        this.resolving = true;
        const { restoreDate: inputRestoreDate, restoreToStatusId } = this.form.value
        const restoreDate = new DatePipe('en-US').transform(inputRestoreDate, 'MM/dd/yyyy');
        try {
            const updatedChannelInfo = await this.channelsService.restoreChannel(this.tpoId, this.channelId, {restoreToStatusId, restoreDate}).toPromise();
            this.modalRef.hide();
            this.afterSave(updatedChannelInfo);
        } catch ({error}) {
            return this.form.setServerError(error);
        } finally {
            this.resolving = false;
        }
    }
}
