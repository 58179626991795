<div class="remote-resource" [class.resolved]="list.$resolved">

	<!-- notification></notification -->
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<div class="text-muted">Partner</div>
			</div>
			<div class="col-sm-3">
				<div class="text-muted">Channels</div>
			</div>
			<div class="col-sm-3">
				<div class="text-muted">Questionnaire</div>
			</div>
			<div class="col-sm-3"></div>
		</div>

		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<ng-select
					[(ngModel)]="params.lenderId"
					(change)="setFilter({ lenderId: $event })"
					[items]="filters.lenderId.$promise | async"
					[loading]="!filters.lenderId.$resolved"
					bindValue="id"
					bindLabel="name"
					placeholder="All Partners"
				></ng-select>
			</div>
			<div class="col-sm-3">
				<ng-select
					[disabled]="!params.lenderId"
					[(ngModel)]="params.channelId"
					(change)="setFilter({ channelId: $event })"
					[items]="filters.channelId?.$promise | async"
					[clearable]="true"
					[closeOnSelect]="false"
					[multiple]="true"
					bindValue="id"
					bindLabel="name"
					placeholder="Select"
				></ng-select>
			</div>
			<div class="col-sm-3">
				<ng-select
					[disabled]="!params.lenderId"
					[ngModel]="params.questionnaireId"
					(change)="setFilter({ questionnaireId: $event })"
					[items]="filters.questionnaireId?.$promise | async"
					[clearable]="true"
					bindValue="id"
					bindLabel="name"
					placeholder="Select"
				></ng-select>
			</div>
			<div class="col-sm-3"></div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<span class="col-sm-12 text-center va-m">Filters are applied.
				<a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>

		<div class="row-fluid no-border" *ngFor="let item of list">
			<tpo-document-list-item
				[document]="item"
				(reloadList)="reloadList()"
			></tpo-document-list-item>
		</div>

		<div class="row-fluid no-border" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center va-m text-bigger text-muted">No documents</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
