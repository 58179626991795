import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { merge } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FileRequiredValidator } from 'commons/validators';

import { DocumentsServiceInterface } from '../documents.service.interface';

@Component({
	templateUrl: './document.modal.component.html',
})
export class SupportingDocumentEditModalComponent implements OnInit {
	User: any;
	params: any;
	isNew: boolean;
	title: string;
	document: any;
	documentService: DocumentsServiceInterface;
	onSuccess: (document) => void;
	documentForm = new RealmFormGroup({
		name: new RealmFormControl(
			'name',
			{ label: 'Document Name' },
			Validators.compose([
				Validators.required,
				Validators.maxLength(200),
			]),
		),
		description: new RealmFormControl(
			'description',
			{ label: 'Description' },
			Validators.compose([
				Validators.maxLength(1000),
			]),
		),
	});

	constructor(
		userService: UserService,
		public modalRef: BsModalRef,
		public stateService: StateService,
	) {
		this.User = userService.profile;
	}

	ngOnInit(): void {
		const {
			id: docId,
		} = this.document;

		this.isNew = !docId;

		if (this.isNew) {
			this.title = 'Add Internal Document';

			this.document = { $resolved: true };

			this.documentForm.addControl('file', new RealmFormControl(
				'file',
				{label: 'File'},
				FileRequiredValidator,
			));

			this.documentForm.addControl('fileName', new RealmFormControl(
				'fileName',
				{label: ''},
			));
		} else {
			this.title = 'Edit Document';
			this.params.docId = docId;
			this.documentForm.patchValue(this.document);
		}
	}

	submitDocumentDetailsForm(): void {
		const {
			document,
			documentForm: form,
		} = this;

		const documentData = {
				...document,
				...form.getRawValue(),
			};

		if (this.isNew) {
			const {
				name,
				description,
				file,
			} = documentData;

			if (!file) {
				form.setServerError({
					message: 'Please upload a file',
				});

				return;
			}

			document.$resolved = false;
			this.documentService.documents.create(
				this.params,
				{
					file,
					properties: {
						name,
						description,
					},
				},
			).$promise
				.then((newDocument) => {
					this.onSuccess(newDocument);
					this.modalRef.hide();
				})
				.catch(({ data: response }) => {
					form.setServerError(response);
				})
				.finally(() => {
					document.$resolved = true;
				});
		} else {
			document.$resolved = false;
			this.documentService.documents.update(this.params, documentData).$promise
				.then((newDocument) => {
					merge(document, newDocument);
					this.modalRef.hide();
				})
				.catch(({ data: response }) => {
					form.setServerError(response);
				})
				.finally(() => {
					document.$resolved = true;
				});
		}
	}

	// TODO: share document upload logic
	setDocument(files: FileList): void {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		if (this.isNew) {
			const formData = this.documentForm.getRawValue();

			if (!formData.name) {
				formData.name = file.name;
			}
			this.documentForm.patchValue({name: file.name});

			merge(formData, {
				file,
				fileName: file.name,
			});

			this.documentForm.patchValue(formData);
		}
	}

	hideError(): void {
		delete this.document.$error;
	}
}
