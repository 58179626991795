import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { TpoQuestionnaireService } from 'tpo/questionnaire/questionnaire.service';
import { TpoQuestionnaireComponent } from 'tpo/questionnaire/questionnaire.component';
import { TpoQuestionnaireQuestionComponent } from 'tpo/questionnaire/question/question.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        ReactiveFormsModule,
    ],
    declarations: [
        TpoQuestionnaireComponent,
        TpoQuestionnaireQuestionComponent,
    ],
    providers: [
      TpoQuestionnaireService,
    ],
    exports: [TpoQuestionnaireQuestionComponent],
})
export class TpoQuestionnaireModule {
}
