<div *ngIf="hasAccessToTab" class="gray-head flex-head">
    <div class="col-fit">
        <h2>Search: {{queryString}}</h2>
    </div>
    <div class="col-min">
        <ul class="col-min tab-menu">
            <li class="tab-menu-item"
                [class.active]="clientIsActive"
                [class.b]="clientIsActive"
            >
                <a uiSref="search.clients" [uiParams]="{ q: queryString }">
                    Clients
                    <span *ngIf="clientIsActive && clientCount">({{clientCount}})</span>
                </a>

            </li>
            <li class="tab-menu-item"
                [class.active]="individualsIsActive"
                [class.b]="individualsIsActive"
            >
                <a uiSref="search.individuals" [uiParams]="{ q: queryString }">
                    Individuals
                    <span *ngIf="individualsIsActive && individualCount">({{individualCount}})</span>
                </a>
            </li>
            <li *ngIf="isCCM && hasSearchUserPermission && hasGlobalSearchPermission"
                class="tab-menu-item"
                [class.active]="userIsActive"
                [class.b]="userIsActive"
            >
                <a uiSref="search.users" [uiParams]="{ q: queryString }">
                    Users
                    <span *ngIf="userIsActive && userCount">({{userCount}})</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<ui-view></ui-view>
