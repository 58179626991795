<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm && onConfirm(); modalRef.hide()">&times;</button>
	<h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div [innerHTML]="notification"></div>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div>
		<button type="button" (click)="onConfirm && onConfirm(); modalRef.hide()" class="btn btn-primary">{{confirmText}}</button>
	</div>
</div>
