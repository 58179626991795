import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { NgResource } from 'commons/interfaces';

import { Question, BankQuestionInUseStats } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

type RemoveTarget = 'bank' | 'everywhere';

@Component({
	templateUrl: './remove-question-modal.component.html',
})
export class RemoveBankQuestionModalComponent implements OnInit {
	onCancel: (params?) => void;
	onConfirm: (params?) => void;
	resolving: boolean = true;

	questionId: Question['id'];
	questionName: Question['text'] = '';

	stats: NgResource<BankQuestionInUseStats> = {
		inUse: [],
		skipLogic: [],
	};

	constructor(
		public modalRef: BsModalRef,
		protected questionnairesService: LenderManageQuestionnaireService,
	) {}

	ngOnInit(): void {
		this.getStats();
	}

	getStats = async (): Promise<void> => {
		try {
			this.stats = await this.questionnairesService.getInUseBankQuestion({
				questionId: this.questionId,
			}).$promise;
		} catch (error) {
			// error handling?
		} finally {
			this.resolving = false;
		}
	}

	get canRemoveEverywhere(): boolean {
		return !!this.stats.$resolved && !this.stats.skipLogic.length && !!this.stats.inUse.length;
	}

	get canRemoveFromBank(): boolean {
		return !!this.stats.$resolved && !this.stats.skipLogic.length && !this.stats.inUse.length;
	}

	get removeIsForbidden(): boolean {
		return !!this.stats.$resolved && !!this.stats.skipLogic.length;
	}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.onCancel();
	}

	confirm = async (target: RemoveTarget): Promise<void> => {
		if (this.resolving) {
			return;
		}

		this.resolving = true;

		try {
			await this.questionnairesService.removeBankQuestion({
				target,
				questionId: this.questionId,
			}).$promise;

			this.onConfirm();
			this.onCancel();
		} catch (error) {
			// error handling?
			this.resolving = false;
		}
	}
}
