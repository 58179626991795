<ccm-customers-menu>
    <div class="contact-menu-header flex-row" header>
        <div class="flex-grow">
            <div class="ellipsis">
                {{ customerInformation?.organizationName }}
            </div>
        </div>
    </div>
    <ui-view></ui-view>
</ccm-customers-menu>
