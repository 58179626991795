import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceObject } from 'commons/interfaces';

import {
	QuestionnaireAction,
	QuestionnaireParams,
	QuestionnaireItem,
	QuestionnaireUsage,
} from '../questionnaires.interface';
import { LenderManageQuestionnaireService } from '../questionnaires.service';
import { EditQuestionnaireForm } from './edit.form';
import { DotMenuItem } from 'commons/components/navigation/dots-menu/dot-menu.items';
import { QuestionnaireComponent } from 'lender/questionnaires/manage/questionnaire';

@Component({
	templateUrl: './questionnaire-edit.component.html',
})
export class EditQuestionnairesComponent implements OnInit {
	questionsCantBeReached: (string | number)[] = [];
	User: UserProfile;
	active: NgResourceObject<QuestionnaireUsage>;
	form = EditQuestionnaireForm;
	isNew = false;
	isClone = false;
	resolving = false;
	params: QuestionnaireParams;
    menu: DotMenuItem[];

	listLoaded = true;
	toggleLoading = false;

	get resolved(): boolean {
		return this.listLoaded && !this.resolving;
	}

	get item(): NgResourceObject<QuestionnaireItem> {
		return this.parent.item;
	}

	set editing(value: boolean) {
        this.parent.editing = value;
	}
	get editing(): boolean {
		return this.parent.editing;
	}

	constructor(
		protected uiRouter: UIRouter,
		protected userService: UserService,
		protected questionnaireService: LenderManageQuestionnaireService,
        protected parent: QuestionnaireComponent,
        protected cd: ChangeDetectorRef,
	) {
		this.User = userService.profile;
		this.params = this.uiRouter.globals.params;
        this.populateMenu()
	}

    populateMenu() {
        const canManage = this.User.can('MANAGE_GLOBAL_QUESTIONNAIRE');
        const baseRoute = 'questionnaires.:questionnaireId.questions';
        this.menu = [];
        canManage && this.menu.push({
            title: 'Edit',
            click: () => {
                this.doEdit();
            },
        });
        this.menu.push({
            title: 'Preview',
            sref: `${baseRoute}.preview`,
        });
        canManage && this.menu.push({
            title: 'Clone',
            sref: baseRoute,
            params: { action: 'clone' },
        });
    }

	async ngOnInit(): Promise<void> {
		switch ( this.params.action ) {
			case QuestionnaireAction.CLONE:
				this.isClone = true;
				break;
			case QuestionnaireAction.NEW:
				this.isNew = true;
				this.doEdit();
				break;
		}

		if ( !this.isNew ) {
			await this.load();
			if ( this.isClone ) {
				this.doEdit();
			}
		}
	}

	doEdit(): void {
		const { name = '', description = '' } = this.item || {};
		const prefix = this.isClone ? 'Copy_' : '';
		this.editing = true;

		const computedName = `${prefix}${name}`;
		const computedDescription = description ? `${prefix}${description}` : '';

		this.form.reset({
			name: computedName,
			description: computedDescription,
		});
	}

	async save(): Promise<void> {
		const { questionnaireId, action } = this.params
		const formData = this.form.value;
		this.resolving = true;
		try {
			switch ( action ) {
				case QuestionnaireAction.NEW: {
					const { id } = await this.questionnaireService.add({ questionnaireId }, formData).$promise;
					this.switchTo(id);
					break;
				}

				case QuestionnaireAction.CLONE: {
					const { id } = await this.questionnaireService.clone({ questionnaireId }, formData).$promise;
					this.switchTo(id);
					break;
				}

				case QuestionnaireAction.EDIT: {
					this.parent.update(await this.questionnaireService.save({ questionnaireId }, formData).$promise);
					this.cancel();
					break;
				}
			}
		} catch ( error ) {
			if ( error.data ) {
				this.form.setServerError(error.data)
			}
		}
		this.resolving = false;
        this.cd.detectChanges();
	}

	cancel(): void {
        this.editing = false;
		if ( this.isNew || this.isClone ) {
			window.history.back();
		}
	}

	switchTo(questionnaireId: number): void {
		const { stateService } = this.uiRouter;
		stateService.go(
			'questionnaires.:questionnaireId.questions',
			{
				questionnaireId,
				action: QuestionnaireAction.EDIT,
			},
			{ location: 'replace' },
		);
	}

	async load(): Promise<void> {
		const { questionnaireId } = this.params
		this.resolving = true;
		try {
			this.active = await this.questionnaireService.usage({ questionnaireId }).$promise;
		} catch ( error ) {
			// server error happened
		}
		this.resolving = false;
	}

	setQuestionsCantBeReached(newQuestions: (string | number)[]): void {
		this.questionsCantBeReached = newQuestions;
	}

	setListLoaded(isLoaded: boolean): void {
		this.listLoaded = isLoaded;
	}

	async toggleStatus(): Promise<void> {
		this.toggleLoading = true;
		const newStatus = this.item.status === 'A' ? 'I' : 'A';
		const { status } = await this.questionnaireService.toggleStatus(this.item.id, newStatus).toPromise();
		this.item.status = status;
		this.toggleLoading = false;
	}
}
