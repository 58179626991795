import { Injectable } from '@angular/core';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';

import { RelatedAccount, RelatedAccountResourceInterface } from './related-account/related-account.interface';
import { LenderContactsResourceService } from './lender-contacts-resource.service';

@Injectable({ providedIn: 'root'})
export class LenderContactService {
	constructor(
		public contactsResource: LenderContactsResourceService,
	) {}

	// Related Account
	getRelatedAccountResource = (contactId: number): RelatedAccountResourceInterface => {
		return {
			search: (q: string): NgResourceArray<RelatedAccount> => this.contactsResource.relatedAccount.hints({ q }),
			update: (nmlsId: number): NgResourceObject<Contact> => (
				this.contactsResource.relatedAccount.update({ contactId, nmlsId })
			),
			delete: (): NgResourceObject<Contact & { relatedAccount: null }> => (
				this.contactsResource.relatedAccount.delete({ contactId })
			),
		};
	};
}

export interface Contact {
	address?: string;
	canBeActivated?: boolean;
	city?: string;
	commaSeparatedDesignationIds?: string;
	contactId?: number;
	contactTitle?: string;
	contactType?: string;
	email?: string;
	firstName?: string;
	fullName?: string;
	isActive?: boolean;
	isEmailBounced?: boolean;
	isMobileUser?: boolean;
	isOwner?: boolean;
	isOwnerVerified?: boolean;
	isPrevious?: boolean;
	isPrimaryContact?: boolean;
	isRealmUserActivated?: boolean;
	isRealmUserActive?: boolean;
	isRealmUserLocked?: boolean;
	isSSNVerified?: boolean;
	lastName?: string;
	middleName?: string;
	nmlsId?: number;
	officerDesignation?: string[];
	organizationContactId?: number;
	organizationReportingEnabled?: boolean;
	ownershipPercentage?: number;
	phone?: string;
	realmUserId?: number;
	realmUserStatusId?: string;
	relatedAccount?: RelatedAccount;
	role?: {
		id?: number;
		isEditable?: boolean;
		name?: string;
	};
	ssn?: string;
	state?: string;
	status?: {
		id?: string;
		name?: string;
	};
	street?: string;
	suite?: string;
	tpoIndividualContactId?: number;
	individualNmlsId?: number;
	userReportingEnabled?: boolean;
	userStatus?: {
		id?: string;
		name?: string;
	};
	zip?: string;
}
