<div class="review-header-wrapper flex-row gap-m">
    <h2>Underwriting</h2>
    <section-review  [section]="sectionType" class="flex-grow"></section-review>
</div>
<div class="remote-resource" [class.resolved]="resolving">
    <h3>
        Contract Underwriting Services
    </h3>
    <div class="rows-bordered">
        <div class="row-fluid header bordered">
            <div class="col-md-4">Company Name</div>
            <div class="col-md-3">Phone</div>
            <div class="col-md-4">Contact</div>
            <div class="col-f-140">Start Date</div>
            <div class="col-f-140">End Date</div>
            <div class="col-f-100 text-right button-holder">
                <button *ngIf="userCanAddEdit" type="button" class="btn btn-primary" (click)="addService('underwriting')">
                    <span class="glyphicon glyphicon-plus small-icon"></span>
                    Add
                </button>
            </div>
        </div>
        <div *ngFor="let item of filterByServiceType('underwriting')" class="row-fluid">
            <underwriting-service-item
                [item]="item"
                [accountInformation]="lenderInfo"
                (onNewItemCancel)="cancelNewService($event)"
                [userCanAddEdit]="userCanAddEdit"
                (afterSave)="sectionReview.updateReview()"
            ></underwriting-service-item>
        </div>
        <div class="row-fluid" *ngIf="!listContainsUnderwritingServices">
            <div class="col-md-12 text-bigger text-center text-muted">No records added</div>
        </div>
    </div>

    <br>

    <h3>
        Contract Processing Services
    </h3>
    <div class="rows-bordered">
        <div class="row-fluid header bordered">
            <div class="col-md-4">Company Name</div>
            <div class="col-md-3">Phone</div>
            <div class="col-md-4">Contact</div>
            <div class="col-f-140">Start Date</div>
            <div class="col-f-140">End Date</div>
            <div class="col-f-100 text-right button-holder">
                <button *ngIf="userCanAddEdit" type="button" class="btn btn-primary" (click)="addService('processing')">
                    <span class="glyphicon glyphicon-plus small-icon"></span>
                    Add
                </button>
            </div>
        </div>
        <div *ngFor="let item of filterByServiceType('processing')" class="row-fluid">
            <underwriting-service-item
                [item]="item"
                [accountInformation]="lenderInfo"
                (onNewItemCancel)="cancelNewService($event)"
                [userCanAddEdit]="userCanAddEdit"
                (afterSave)="sectionReview.updateReview()"
            ></underwriting-service-item>
        </div>
        <div class="row-fluid" *ngIf="!listContainsProcessingServices">
            <div class="col-md-12 text-bigger text-center text-muted">No records added</div>
        </div>
    </div>
</div>

