import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResource, NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { linkTemplate } from 'shared/supporting-documents/helpers';

import {
	Channel,
	ChannelResource,
	GlobalDocument,
	GlobalDocumentDetails,
	GlobalDocumentDetailsFormData,
	GlobalHistoryDocument,
	Questionnaire,
	DocuSignTemplate,
	DocuSignStatus,
} from './documents.interface';

declare let apiPath: string;

const transformChannelToChannelResource = ({ id, name }: Channel): ChannelResource => ({
	channelId: id,
	channelName: name,
});

const transformChannelResourceToChannel = ({ channelId, channelName }: ChannelResource): Channel => ({
	id: channelId,
	name: channelName,
});

export const transformDocumentToDocumentResource = ({ channelsSettings, ...rest }: GlobalDocumentDetails): GlobalDocumentDetails<ChannelResource> => ({
	...rest,
	channelsSettings: channelsSettings.map(transformChannelToChannelResource),
});

const transformDocumentResourceToDocument = ({ channelsSettings, ...rest }: GlobalDocumentDetails<ChannelResource>): GlobalDocumentDetails => ({
	...rest,
	channelsSettings: channelsSettings.map(transformChannelResourceToChannel),
});

const transformDocumentFormData = ({ templateFile, ...rest }: GlobalDocumentDetailsFormData) => {
	const formData = new FormData();
	const documentDetailsBlob = new Blob(
		[ JSON.stringify(transformDocumentToDocumentResource(rest)) ],
		{ type: 'application/json' },
	);
	formData.append('documentString', documentDetailsBlob);
	if ( templateFile ) {
		formData.append('file', templateFile);
	}
	return formData;
};

@Injectable()
export class GlobalDocumentsService {
	private documents: any;
	private document: any;
	private documentChannels: any;
	private documentQuestionnaires: any;
	private documentHistory: any;
	private docusign: any;
	// documentTemplate: any;

	private baseUrl = `${apiPath}/v2/lenders/:lenderId`;
	private docusignUrl = `${apiPath}/lenders/:lenderId/docmanagement/docusign`;
	private baseParams = {
		lenderId: null,
	};

	constructor(
		private injector: Injector,
		private user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.baseParams = {
			...this.baseParams,
			lenderId: user.profile.organization.id,
		};

		const documentsResourceConfig = {
			url: `${this.baseUrl}/docmanagement`,
			params: {
				...this.baseParams,
			},
		};
		const documentResourceConfig = {
			url: `${documentsResourceConfig.url}/:documentId`,
			params: {
				...documentsResourceConfig.params,
				documentId: '@documentId',
			},
		};


		this.documents = PagedResource(
			documentsResourceConfig.url,
			documentsResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				create: {
					method: 'POST',
					headers: {
						'Content-Type': undefined,
					},
					transformRequest: transformDocumentFormData,
					cancellable: true,
				},
			},
		);

		this.document = $resource(
			documentResourceConfig.url,
			documentResourceConfig.params,
			{
				get: {
					method: 'GET',
					transformResponse: (data, getHeaders, code) => {
						if (code === 200) {
							return transformDocumentResourceToDocument(JSON.parse(data));
						}
					},
				},
				update: {
					method: 'PUT',
					headers: {
						'Content-Type': undefined,
					},
					transformRequest: transformDocumentFormData,
					transformResponse: (data, getHeaders, code) => {
						if (code === 200) {
							return transformDocumentResourceToDocument(JSON.parse(data));
						}
						return data;
					},
				},
				discontinue: {
					method: 'DELETE',
				},
			}
		);

		this.documentChannels = $resource(
			`${documentResourceConfig.url}/channel/:channelId/requested`,
			{ ...this.baseParams },
			{
				request: {
					method: 'POST',
				},
			}
		);

		// this.documentTemplate = $resource(`${this.basePath}/:id/template`, { ...this.defaults }, {
		// 	delete: { method: 'DELETE' },
		// 	save: {
		// 		method: 'POST',
		// 		headers: {
		// 			'Content-Type': undefined,
		// 		},
		// 		transformRequest: (item) => {
		// 			const data = new FormData();
		// 			data.append('file', item.file);
		// 			return data;
		// 		},
		// 	},
		// });

		this.documentQuestionnaires = $resource(
			`${documentResourceConfig.url}/questionnaires`,
			{ ...documentResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			}
		);

		this.documentHistory = PagedResource(
			`${documentResourceConfig.url}/history`,
			{ ...documentResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			}
		);

		this.docusign = PagedResource(this.docusignUrl, this.baseParams, {
			status: {
				method: 'GET',
				url: `${this.docusignUrl}/status`,
			},
			templates: {
				method: 'GET',
				isArray: true,
				url: `${this.docusignUrl}/templates`,
			},
			disconnect: {
				method: 'PUT',
				url: `${this.docusignUrl}/disconnect`,
			},
		});
	}

	getDocuments = (...args): NgResourceArray<GlobalDocument> => this.documents.get(...args);
	createDocument = (...args): NgResourceObject<GlobalDocumentDetails> => this.documents.create(...args);
	getDocument = (...args): NgResourceObject<GlobalDocumentDetails> => this.document.get(...args);
	updateDocument = (...args): NgResourceObject<GlobalDocumentDetails> => this.document.update(...args);
	discontinueDocument = (...args): NgResource => this.document.discontinue(...args);
	getDocumentQuestionnaires = (...args): NgResourceArray<Questionnaire> => this.documentQuestionnaires.get(...args);
	getDocumentHistory = (...args): NgResourceArray<GlobalHistoryDocument> => this.documentHistory.get(...args);
	requestDocumentOnChannel = (...args): NgResourceArray<void> => this.documentChannels.request(...args);
	getDocusignStatus = (...args): NgResourceObject<DocuSignStatus> => this.docusign.status(...args);
	getDocuSignTemplates = (...args): NgResourceArray<DocuSignTemplate> => this.docusign.templates(...args);
	disconnectDocuSign = (...args): NgResource => this.docusign.disconnect(...args);

	/**
	 * @param {Object} params
	 * @param {number} [params.lenderId]
	 * @param {number} [params.documentId]
	 * @param {number} [params.historyId]
	 */
	getTemplateDownloadLink = (params): string => {
		const { historyId } = params;
		return linkTemplate(
			`${this.baseUrl}/docmanagement/:documentId${historyId ? '/history/:historyId' : ''}/template`,
			{
				...this.baseParams,
				...params,
			},
		);
	};

	getDocuSignDocumentDownloadLink = (id: string): string => (
		linkTemplate(`${this.docusignUrl}/templates/:id/document`, { ...this.baseParams, id })
	);
}
