import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmHttpClient } from 'commons/services/http';

import { PoolData } from './partials/audit-schedule/selection-pool-file-modal/selection-pool-file-modal.interface';

declare let apiPath: string;

export type MonitoringScheduleDataSectionCommon = {
    enabled: boolean;
};

export type MonitoringScheduleCodeValue = {
    code: string;
    label: string;
};

export type MonitoringSchedulePrice = {
    invoicing: boolean;
    price: number;
    pricePerReview: number;
}

export type OrderCloseStatus = {
    id: number;
    name: string;
}

export const SelectionPoolContactType = {
    NMLS: 'nmls',
    NON_NMLS: 'non-nmls',
}

export type HintedContact = {
    contactName: string;
    nmlsId: number;
    contactId: number;
}


export type MonitoringScheduleData = {
    auditNewNmlsIds: MonitoringScheduleDataSectionCommon;
    auditSchedule: MonitoringScheduleDataSectionCommon & {
        selectionPool?: {
            includeAllNmlsContacts: boolean;
            includeNonNmlsContacts: boolean;
            nmlsContactsCount: number;
            nonNmlsContactsCount: number;
        };
        auditScheduleSettings?: {
            amount: number;
            nextOrderDate: string;
            amountType: MonitoringScheduleCodeValue;
            frequency: MonitoringScheduleCodeValue;
            reviewFrequency: MonitoringScheduleCodeValue;
        },
    };
    autoCloseAudits: MonitoringScheduleDataSectionCommon & {
        autoCloseStatus: OrderCloseStatus;
    };
    estimatedFee: MonitoringSchedulePrice;
    generatedOrderId?: number;
    isFirstOrder?: boolean;
    isLastScheduledOrderFailed?: boolean;
};

@Injectable()
export class MonitoringScheduleService {
    private basePath: string;
    private schedulePath: string;

    constructor(
        UserService: UserService,
        private http: RealmHttpClient,
    ) {
        const { profile: { organization: { id: tpoId } } } = UserService;
        this.basePath = `${apiPath}/tpos/${tpoId}/social-media-compliance`;
        this.schedulePath = `${this.basePath}/schedule`;
    }

    get(): Observable<MonitoringScheduleData> {
        return this.http.request<MonitoringScheduleData>('GET', `${this.schedulePath}`);
    }

    update(settings: Partial<MonitoringScheduleData> | unknown, queryParams: { startNow?: boolean } = {}): Observable<MonitoringScheduleData> {
        return this.http.request<MonitoringScheduleData>('PATCH', `${this.schedulePath}`, queryParams, settings);
    }

    updatePrice(settings: MonitoringScheduleData): Observable<MonitoringSchedulePrice> {
        return this.http.request<MonitoringSchedulePrice>('POST', `${this.schedulePath}/price`, {}, settings);
    }

    private _getCodeValue(url): Observable<MonitoringScheduleCodeValue[]> {
        return this.http.request<MonitoringScheduleCodeValue[]>('GET', url).pipe(shareReplay(1));
    }

    getAmountTypes(): Observable<MonitoringScheduleCodeValue[]> {
        return this._getCodeValue(`${this.schedulePath}/amount-type`);
    }

    getReviewFrequencies(): Observable<MonitoringScheduleCodeValue[]> {
        return this._getCodeValue(`${this.schedulePath}/review-frequency`);
    }

    getFrequencies(): Observable<MonitoringScheduleCodeValue[]> {
        return this._getCodeValue(`${this.schedulePath}/frequency`);
    }

    getAutoCloseStatuses(): Observable<OrderCloseStatus[]> {
        return this.http.request<OrderCloseStatus[]>('GET', `${this.basePath}/order/autoCloseStatuses`);
    }

    uploadSelectionPoolFile<T = MonitoringScheduleData>
    ({ type, file }: PoolData): Observable<T> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.request<T>('POST', `${this.schedulePath}/selectionPool`, { type }, formData);
    }

    getSelectionPoolFileLink(): string {
        return `${this.schedulePath}/selectionPool/export`;
    }

    resetSelectionPool<T = MonitoringScheduleData>
    (type: typeof SelectionPoolContactType[keyof typeof SelectionPoolContactType]): Observable<T> {
        return this.http.request<T>('DELETE', `${this.schedulePath}/selectionPool/${type}`);
    }

    findContacts<T = HintedContact[]>(search, excludedContactIds: number[] = []): Observable<T> {
        return this.http.request<T>('POST', `${this.schedulePath}/contacts`, { q: search }, { excludedContactIds });
    }

    updateSelectionPool<T = MonitoringScheduleData>(payload): Observable<T> {
        return this.http.request<T>('POST', `${this.schedulePath}/contacts/updateStatus`, {}, payload);
    }
}
