import { Component, ChangeDetectorRef } from '@angular/core';
import { CommonPartialScheduleComponent } from 'tpo/social-compliance/schedule/partials/common-partial.component';
import { UserService } from 'angularjs-providers/user.provider';
import { MonitoringScheduleService, OrderCloseStatus } from 'tpo/social-compliance/schedule/schedule.service';
import { Observable } from 'rxjs';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import moment from 'moment';

@Component({
    selector: 'tpo-audit-auto-close',
    templateUrl: './audit-auto-close.component.html',
})
export class TpoScheduleAuditAutoCloseComponent extends CommonPartialScheduleComponent {
    dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    statuses: Observable<OrderCloseStatus[]>;
    form = new RealmFormGroup({
        autoCloseStatus: new RealmFormControl(
            'autoCloseStatus',
            {
                label: 'Auto-close status',
                updateOn: 'change',
            },
        ),
    });

    constructor(
        userService: UserService,
        public scheduleService: MonitoringScheduleService,
        private cd: ChangeDetectorRef,
    ) {
        super(userService);
        this.statuses = this.scheduleService.getAutoCloseStatuses();
    }

    async toggleSection(enabled: boolean): Promise<void> {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        this.loading = true;
        const settings = await this.scheduleService.update({
            ...this.settings,
            autoCloseAudits: { ...this.settings.autoCloseAudits, enabled },
        }).toPromise();
        this.updateSettings(settings)
        this.priceUpdate.next(settings);

        const rawDate = settings.auditSchedule.auditScheduleSettings.nextOrderDate ?moment(settings.auditSchedule.auditScheduleSettings.nextOrderDate).toDate() : null;
        const formattedDate = this.dateFormatter.format(rawDate);
        const message = settings.auditSchedule.auditScheduleSettings.nextOrderDate ?
            `Updated settings will be applied to the next order on ${formattedDate}` :
            `Updated settings will be applied to the next order`;
        this.notifyEmitter.emit({ message });
        this.loading = false;
    }


    resetForm(): void {
        this.form.reset(this.settings.autoCloseAudits);
    }

    toEdit(): void {
        // Redirect to subscription if not enabled
        if (!this.redirectIfNoCompliance()) return;

        this.editing = true;
        this.resetForm();
    }

    cancelEdit(): void {
        this.editing = false;
        this.resetForm();
    }

    async formSubmit(): Promise<void> {
        const autoCloseAudits = this.form.value;
        this.loading = true;
        this.cd.detectChanges();
        const settings = await this.scheduleService.update({
            ...this.settings,
            autoCloseAudits: { ...this.settings.autoCloseAudits, ...autoCloseAudits },
        }).toPromise();
        this.updateSettings(settings)
        this.priceUpdate.next(settings);
        const formattedDate = this.dateFormatter.format(moment(settings.auditSchedule.auditScheduleSettings.nextOrderDate).toDate());
        const message = settings.auditSchedule.auditScheduleSettings.nextOrderDate ?
            `Updated settings will be applied to the next order on ${formattedDate}` :
            `Updated settings will be applied to the next order`;
        this.notifyEmitter.emit({ message });
        this.cancelEdit();
        this.loading = false;
    }
}
