import { join, uniq, uniqBy, find, filter, forEach, keys, isNull, map } from 'lodash';
import { Component, Input } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';

import {
	socialMediaNetworksConfig,
	DefaultNetworks,
	DefaultNetwork,
	SocialMediaNetworksConfig,
} from 'commons/components/sm-icons';
import {
	DynamicField,
	wrapDynamicFieldsToHtml,
} from 'tpo/social-compliance/publisher/library/templates/$templateId/template-text-edit/utils';

@Component({
	selector: 'posts-preview',
	templateUrl: './posts-preview.component.html',
})
export class PostsPreviewComponent {
	@Input() accounts: Array<DefaultNetwork> = DefaultNetworks;
	@Input() dynamicFields?: NgResourceArray<DynamicField> = [];
	@Input() set text(value: string) {
		if (this.dynamicFields.length) {
			this._text = wrapDynamicFieldsToHtml(value, this.dynamicFields);
		} else {
			this._text = value
		}
	}
	get text() {
		return this._text;
	}
	private _text: string;
	@Input() attachments: any[];
	@Input() attachmentsErrors: [ { networkCode: string, error: string }? ];
	@Input() postType: string;
	@Input() previewDate: Date;
	@Input() linkPreview: {
		entries: unknown[];
		$resolved: boolean;
	};
	/**
	 * @description
	 * Set of texts with replaced dynamic fields.
	 * @example
	 * { TW: 'My name is: User name. My phone is: 12345.', GMB: 'My name is: GMB Name. My Phone is: 67890.' }
	 * @notice
	 * Null means no dynamic fields, show text with dynamic fields placeholders.
	 * Useful in templates preview, where dynamic fields shown with placeholders.
	 */
	@Input() resolvedDynamicContent: Record<string, string> | null = {};

	networksConfig: SocialMediaNetworksConfig = socialMediaNetworksConfig;

	constructor(public User: UserService) {}

	commonError = (): boolean => !!find(this.attachments, (attachment) => attachment.$error);

	getErrors(item: DefaultNetwork): string {
		const errorsMessages: string[] = [];
		if (this._text && this._text.length > this.networksConfig[item.networkCode].signLimit) {
			errorsMessages.push(`This message exceeds the number of characters that is allowed.`);
		}

		if (this.commonError()) { // common validations errors in preview
			const errors = filter(this.attachments, (attachment) => attachment.$error);
			forEach(uniqBy(errors, '$error'), (element) => {
				if (element.hasOwnProperty('networksMessages')) {
					if (element.networksMessages[item.networkCode]) {
						errorsMessages.push(`${element.networksMessages[item.networkCode]}`);
					}
				} else {
					errorsMessages.push(`${element.$error}`);
				}
			});
		}

		if (this.attachmentsErrors && this.attachmentsErrors.length) {
			const errors = filter(this.attachmentsErrors, { networkCode: item.networkCode });
			forEach(uniqBy(errors, 'error'), (element) => {
				errorsMessages.push(`${element.error}`);
			});
		}

		return join(uniq(errorsMessages), '\n');
	}

	getLinkPreview(linkPreviews, networkCode: string) {
		if (!linkPreviews.length) {
			return {
				$resolved: true,
			};
		}

		const foundLinkPreview = find(linkPreviews, (linkData) => {
			return linkData.networkCode === networkCode;
		});

		if (!foundLinkPreview) {
			return {
				$resolved: true,
			};
		}

		return {
			...foundLinkPreview,
			$resolved: true,
		};
	}

	get isTextResolved(): boolean {
		// do not block text resolve if no dynamic fields
		if (isNull(this.resolvedDynamicContent)) {
			return true;
		}

		return this.linkPreview.$resolved || (keys(this.resolvedDynamicContent).length > 0);
	}

	getText(networkCode: string | undefined): string {
		if (!this.isTextResolved) {
			return ' ';
		}

		if (!networkCode || isNull(this.resolvedDynamicContent)) {
			return this.text;
		}

		return this.resolvedDynamicContent[networkCode] ?? this.text;
	}
}
