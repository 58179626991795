<div class="col-md-a">
    <span class="control-value">Company Information</span>
</div>
<div class="col-md-a">
    <span class="control-value">
        <span *ngIf="companyInformation.completed" class="label label-success-wired">Completed</span>
        <span *ngIf="!companyInformation.completed" class="label label-danger-wired">Required</span>
    </span>
</div>
<div class="col-md-a">
    <span class="control-value text-muted">N/A</span>
</div>
<div class="col-md-a">
   <span class="control-value text-muted">N/A</span>
</div>
<div class="col-f-120 button-holder text-right">
    <a uiSref="account.information" [uiParams]="{ channelId: channelId }" class="btn btn-default">
        <span class="glyphicon glyphicon-pencil"></span>Update
    </a>
</div>
