import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './field-remove-modal.component.html',
})

export class FieldRemoveModalComponent {
	entityName: string;
	agreed: string;
	onConfirm: (params?) => unknown;
	onCancel: (params?) => unknown;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			agreed: false,
			resolving: false,
		});
	}
}
