import { Pipe, PipeTransform } from '@angular/core';

export interface TruncatePipeConfig {
	wordWrap: boolean;
	ellipsis: string;
}

const DEFAULT_CONFIG: TruncatePipeConfig = {
	wordWrap: false,
	ellipsis: '...',
};

@Pipe({
	name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
	transform(
		value: string | null | undefined,
		limit: number,
		config?: Partial<TruncatePipeConfig>
	): string | null {
		if (!value) {
			return null;
		}

		const {
			wordWrap,
			ellipsis,
		} = {
			...DEFAULT_CONFIG,
			...config,
		};

		const trimmedValue = value.trim();
		let truncatedValue = trimmedValue.substr(0, limit);
		if (wordWrap) {
			truncatedValue = truncatedValue.substr(0, truncatedValue.lastIndexOf(' '));
		}

		return `${truncatedValue.trimEnd()}${trimmedValue.length > truncatedValue.length ? ellipsis : ''}`;
	}
}
