import {orderBy, keys, values} from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
	transform(items: any[], orderObject: object): any[] {
		if (!items) { return []; }

		if (!orderObject) { return items; }

		return orderBy(items, keys(orderObject), values(orderObject));
	}
}
