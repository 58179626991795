<ul class="nav navbar-nav">
    <li *ngFor="let item of items" [ngClass]="{ 'active': (item.isActive && item.isActive()), 'dropdown': item.items?.length }" dropdown>
        <ng-container *ngIf="item.items?.length; else notNested">
            <a class="dropdown-toggle" dropdownToggle>
                <span>{{ item.title }}</span>
                <span class="icon-alert" *ngIf="item.hasAlert | async"></span>
                <span class="caret"></span>
            </a>
            <ul class="dropdown-menu">
                <li *ngFor="let subItem of item.items" [ngClass]="{ 'active': (subItem.isActive && subItem.isActive()) }">
                    <ng-container [ngTemplateOutlet]="actualALink" [ngTemplateOutletContext]="{ item: subItem }"></ng-container>
                </li>
            </ul>
        </ng-container>
        <ng-template #notNested>
            <ng-container [ngTemplateOutlet]="actualALink" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </ng-template>
    </li>
</ul>

<ng-template #actualALink let-item="item">
    <ng-container *ngIf="!item.rawHref; else rawHref">
        <a uiSref="{{ item.sref }}" [uiParams]="item.srefParams" [uiOptions]="item.srefOptions">
            {{ item.title }}
            <span class="icon-alert" *ngIf="item.hasAlert | async"></span>
        </a>
    </ng-container>
    <ng-template #rawHref>
        <a [href]="item.sref" [target]="item.rawHrefTarget">
            {{ item.title }}
            <span class="icon-alert" *ngIf="item.hasAlert | async"></span>
        </a>
    </ng-template>
</ng-template>
