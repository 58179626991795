<public-task-sign-in follow="/social-compliance/tasks"></public-task-sign-in>

<h2>Tasks</h2>

<div class="remote-resource" [class.resolved]="list.$resolved">
	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-a" columnName="fullname">Name</sortable-header>
			<sortable-header class="col-sm-a" columnName="remediationStatus">Status</sortable-header>
			<sortable-header class="col-sm-a" columnName="statusDate">Status Date</sortable-header>
			<sortable-header class="col-sm-a" columnName="dueDate">Due Date</sortable-header>
			<sortable-header class="col-sm-a" columnName="findingStatus">Finding Status</sortable-header>
		</div>

		<a
			*ngFor="let item of list"
			class="row-fluid clickable"
			uiSref=".:findingId"
			[uiParams]="{ findingId: item.findingId }"
		>
			<div class="col-sm-a">
				<div *ngIf="item.name">{{item.name || '&ndash;'}}</div>
				<div class="text-muted" *ngIf="item.nmlsId">NMLS ID: {{item.nmlsId}}</div>
			</div>
			<div class="col-sm-a va-m">
				<div
					class="label"
					[class.label-warning]="[ 'Open', 'Reopened' ].includes(item.remediationStatus)"
					[class.label-success]="item.remediationStatus == 'Completed'"
				>
					<span class="text-capitalize text-lowercase">{{item.remediationStatus}}</span>
				</div>
			</div>
			<div class="col-sm-a va-m">
				{{(item.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}
			</div>
			<div class="col-sm-a va-m">
				{{item.dueDate || '&ndash;'}}
			</div>
			<div class="col-sm-a va-m">
				{{item.findingStatus || '&ndash;'}}
			</div>
		</a>

		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-bigger text-muted">All tasks have been closed</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
