import { Validators } from '@angular/forms';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NumberValidator, PhoneValidator } from 'commons/validators';

export const warehouseForm = () => (new RealmFormGroup({
    companyName: new RealmFormControl(
        'companyName',
        {
            label: 'Company',
        },
        Validators.required,
    ),
    contactName: new RealmFormControl(
        'contactName',
        {
            label: 'Contact Name',
        },
    ),
    email: new RealmFormControl(
        'email',
        {
            label: 'Email',
        },
        Validators.email,
    ),
    phone: new RealmFormControl(
        'phone',
        {
            label: 'Phone',
        },
        PhoneValidator(),
    ),
    approvedLineAmount: new RealmFormControl(
        'approvedLineAmount',
        {
            label: 'Line Amount',
        },
        Validators.required,
    ),
    sellLoans: new RealmFormControl(
        'sellLoans',
        {
            label: 'Sells Loans',
        },
        Validators.required,
    ),
    relationshipStartDate: new RealmFormControl(
        'relationshipStartDate',
        {
            label: 'Start Date',
        },
        Validators.required,
    ),
    relationshipEndDate: new RealmFormControl(
        'relationshipEndDate',
        {
            label: 'End Date',
        },
    ),
    minimumTNWRequired: new RealmFormControl(
        'minimumTNWRequired',
        {
            label: 'Minimum TNW Required',
        },
        NumberValidator(),
    ),
    minimumLiquidityRequired: new RealmFormControl(
        'minimumLiquidityRequired',
        {
            label: 'Minimum Liquidity Required',
        },
        NumberValidator(),
    ),
    maximumLeverageAllowed: new RealmFormControl(
        'maximumLeverageAllowed',
        {
            label: 'Maximum Leverage Allowed',
        },
        NumberValidator(),
    ),
    minimumProfitability: new RealmFormControl(
        'minimumProfitability',
        {
            label: 'Minimum Profitability',
        },
        NumberValidator(),
    ),
}));
