import { Component, Input, ContentChild, TemplateRef } from '@angular/core';

@Component({
	selector: 'checklist',
	templateUrl: './checklist.component.html',
})
export class ChecklistComponent {

	@Input() items: any[];
	@Input() bindState: string;
	@Input() bindLabel: string;
	@ContentChild('additionalContentTemplate', { read: TemplateRef, static: false }) additionalContentTemplate: TemplateRef<any>;
}
