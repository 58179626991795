<div class="gray-head">
    <h2 class="pull-left">{{finding?.name}}</h2>
</div>

<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
    <div class="row-fluid header">
        <span class="col-md-3"><b class="two-liners">Date</b></span>
        <span class="col-md-5"><b class="two-liners">Action</b></span>
        <span class="col-md-4"><b class="two-liners">Action By</b></span>
    </div>
    <div class="row-fluid" *ngFor="let entry of list | async">
        <div class="col-md-3">{{entry.createdDate | date : 'MM/dd/yyyy HH:mm'}}</div>
        <div class="col-md-5">{{entry.action}}</div>
        <div class="col-md-4">{{entry.actionBy}}</div>
    </div>
    <div class="row-fluid" *ngIf="!(list | async)?.length && !resolving">
        <span class="col-sm-12 text-muted text-center text-bigger text-weak">No records</span>
    </div>
    <br>
    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>

