<h2>Application Comments</h2>

<comments #commentsComponent
	[resource]="commentsResource"
	[permissions]="{ canAdd: true }"
	[addForm]="commentsForm"
    [sizes]="[10, 20, 50]"
    [defaultFiltersParams]="{ size: 20 }"
    (onAdd)="commentsForm.get('commentType').patchValue('PUBLIC')"
>
	<ng-template #formTemplate
                 let-addForm="addForm"
                 let-add="methods.add"
                 let-entityName="entityName"
                 let-editing="editing"
                 let-submitted="submitted"
                 let-setEditMode="methods.setEditMode">
		<form [formGroup]="addForm" id="addCommentForm" class="add-comment-form"
			  (onValidSubmit)="add()" autocomplete="off" role="presentation">
			<div class="mb15 remote-resource" [class.resolved]="!submitted">
				<div class="form-group full-width">
					<textarea
						class="form-control no-resize"
						formControlName="comment"
						rows="{{editing ? 5 : 1}}"
						maxlength="2000"
						placeholder="Add your {{entityName | lowercase}} here"
						(focus)="setEditMode(true)"
					></textarea>
				</div>

				<div class="form-group row" *ngIf="editing">
					<div class="col-md-3">
						<ng-select
							[items]="commentTypes?.$promise | async"
							[loading]="!commentTypes?.$resolved"
							formControlName="commentType"
							bindLabel="label"
							bindValue="code"
							[searchable]="false"
							[clearable]="false"
							required
						></ng-select>
					</div>

					<div class="col-md-9 control-value" *ngIf="commentTypes?.$resolved">
						{{typeDescriptions[addForm.value.commentType]}}
					</div>
				</div>

				<div class="btn-group-lg" *ngIf="editing">
					<button type="submit" class="btn btn-primary"
							[disabled]="submitted || !addForm.value.comment">
						Add {{entityName | titlecase}}
					</button>
					<button
						type="button"
						class="btn btn-default"
						(click)="setEditMode(false); addForm.patchValue({ commentType: 'PUBLIC' })"
					>
						Cancel
					</button>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #commentTemplate let-comment="comment">
		<div>
			<div class="horizontal-flex-group">
				<div class="full-width">
					<b>{{comment.createdBy}}&nbsp;</b>
				</div>
				<div class="text-light text-smaller row">
					<span class="col-md-5">{{comment.commentType}}</span>
					<span class="col-md-7 text-nowrap">{{comment.createdDate | date:'MM/dd/yyyy HH:mm'}}</span>
				</div>
			</div>
			<p class="comment">{{comment.comment}}</p>
		</div>
	</ng-template>
</comments>