<ng-container *ngIf="!edit">
	<div class="flex-row align-top gap">
		<div class="flex" [ngSwitch]="item.questionType">
			<ng-container *ngSwitchCase="QuestionType.GROUP">
				<questions-group
					[group]="item"
				></questions-group>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<questionnaire-question
					[question]="item"
					[isBankQuestion]="isBankQuestion"
				></questionnaire-question>
			</ng-container>
		</div>

		<div *ngIf="showInUseLink" class="in-use-holder">
			<button *ngIf="item.usageCount > 0" class="btn btn-link" (click)="showInUse()">
				In Use ({{item.usageCount}})
			</button>
		</div>

		<div *ngIf="user.can('MANAGE_GLOBAL_QUESTIONNAIRE')" class="horizontal-flex-group right">
			<div
				[tooltip]="isEditDisabled ? 'Edit this question in Question Bank' : null"
				container="body"
				placement="top"
			>
				<button
					class="btn btn-tertiary btn-icon"
					(click)="toggleEdit(true)"
					[disabled]="isEditDisabled"
				>
					<span class="c-icon c-icon-pencil"></span>
				</button>
			</div>
			<button class="btn btn-tertiary btn-icon" (click)="remove()">
				<span class="glyphicon glyphicon-trash"></span>
			</button>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="edit">
	<div [ngSwitch]="item.questionType">
		<ng-container *ngSwitchCase="QuestionType.GROUP">
			<questions-group-edit
				[group]="item"
				(update)="update($event)"
				(discard)="toggleEdit(false)"
			></questions-group-edit>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<questionnaire-question-edit
				[question]="item"
				[skipLogic]="skipLogic"
				[availableDocuments]="availableDocuments"
				[categories]="categories"
				[isBankQuestion]="isBankQuestion"
				[updateError]="updateError"
				(update)="update($event)"
				(discard)="toggleEdit(false)"
			></questionnaire-question-edit>
		</ng-container>
	</div>
</ng-container>
