<div class="social-media-groups">
	<div class="flex-head">
		<h2 class="col-fit">
			Group Sharing
		</h2>
		<div class="col-min">
			<button type="button" class="btn btn-primary" (click)="createGroup()" *ngIf="User.can('TPO_PB_MANAGE_GROUPS')">
				Create
			</button>
		</div>
	</div>

	<section class="remote-resource" [class.resolved]="groups.$resolved">
		<div class="filters-rows">
			<div class="row filter-labels">
				<div class="col-md-3">
					<div class="text-muted">
						Search
					</div>
				</div>
			</div>
			<div class="row filter-selectors">
				<div class="col-md-3">
					<search-list placeholder="Group, Name or NMLS ID"></search-list>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<div class="col-md-3" [ngClass]="getSortClass('name')" (click)="setSort('name')">
					<b>Name</b>
				</div>
				<div class="col-md-a" [ngClass]="getSortClass('membersCount')" (click)="setSort('membersCount')">
					<b>Members</b>
				</div>
				<div class="col-md-a" [ngClass]="getSortClass('campaignsCount')" (click)="setSort('campaignsCount')">
					<b>Campaigns</b>
				</div>
				<div class="col-md-a" [ngClass]="getSortClass('templatesCount')" (click)="setSort('templatesCount')">
					<b>Templates</b>
				</div>
				<div class="col-md-2" [ngClass]="getSortClass('updatedBy')" (click)="setSort('updatedBy')">
					<b>Updated by</b>
				</div>
			</div>

			<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
				<div class="col-md-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
			</div>

			<div class="row-fluid" *ngFor="let item of groups">
				<div class="col-md-3">
					<i class="c-icon c-icon-users icon-light-gray soc-icon"></i> &nbsp;&nbsp;
					<a *ngIf="item.id && item.name" uiSref=".:groupId" [uiParams]="{ groupId: item.id, page: null, size: null, sort: null, q: null }">
                        {{item.name}}
                    </a>
					<span *ngIf="!item.id">{{item.name || '&ndash;'}}</span>
				</div>
				<div class="col-md-a">
					{{item.membersCount || '&ndash;'}}
				</div>
				<div class="col-md-a">
					{{item.campaignsCount || '&ndash;'}}
				</div>
				<div class="col-md-a">
					{{item.templatesCount || '&ndash;'}}
				</div>
				<div class="col-md-2">
					{{item.updatedBy || '&ndash;'}}
				</div>
			</div>

			<div class="row-fluid" *ngIf="noMatches">
				<div class="col-md-12 text-center text-bigger text-muted">No matches found</div>
			</div>

			<div class="row-fluid" *ngIf="!groups.length && !noMatches">
				<div class="col-md-12 text-center text-bigger text-muted">No groups</div>
			</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="groups" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</section>

</div>
