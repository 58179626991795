import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { uniqueId } from 'lodash';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { PhoneValidator } from 'commons/validators';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { InsuranceBond, InsuranceBondService } from 'shared/account/information/insurance-bonds/insurance-bonds.service';


@Component({
    selector: 'insurance-bonds-item',
    templateUrl: './insurance-bonds-item.component.html',
})
export class InsuranceBondsItemComponent implements OnInit {
    @Input() public accountInformation: AccountInformation;
    @Input() public userCanAddEdit: boolean;
    @Input() public item: InsuranceBond;
    @Output() public onNewItemCancel: EventEmitter<InsuranceBond> = new EventEmitter();
    @Output() public afterSave: EventEmitter<void> = new EventEmitter<void>();

    editing = false;
    resolving = false;

    readonly formId: string = `insuranceBondForm-${uniqueId()}`;
    dateFormat: string = 'MM/dd/yyyy';
    numOnly: RegExp = new RegExp('^[0-9]+$');
    insuranceBondForm = new RealmFormGroup({
        carrierName: new RealmFormControl(
            'carrierName',
            {
                label: 'Carrier Name',
            },
            Validators.required,
        ),
        amount: new RealmFormControl(
            'amount',
            {
                label: 'Amount',
            },
            [
                Validators.required,
                Validators.pattern(this.numOnly),
            ],
        ),
        phone: new RealmFormControl(
            'phone',
            {
                label: 'Phone',
            },
            [
                Validators.maxLength(10),
                PhoneValidator(),
            ],
        ),
        expiration: new RealmFormControl(
            'expiration',
            {
                label: 'Expiration',
            },
        ),
    });

    constructor(
        private readonly insuranceBondService: InsuranceBondService,
    ) {
    }

    // Override
    ngOnInit() {
        if (this.isNewItem) {
            this.editing = true;
        }
    }

    get isNewItem(): boolean {
        return !this.item.id;
    }

    get itemIsExpired(): boolean {
        return (this.item.expiration && (this.item.expiration < Date.now()));
    }

    doCancel() {
        this.editing = false;

        if (this.isNewItem) {
            this.onNewItemCancel.next(this.item);
        }
    }

    doEdit() {
        const patch = ({ ...this.item } as any);
        if (this.item.expiration) {
            patch.expiration = moment(this.item.expiration).toDate();
        }

        this.insuranceBondForm.patchValue(patch);
        this.editing = true;
    }

    async doSave(): Promise<void> {
        const insuranceBondToSave: InsuranceBond = {
            ...this.item,
            ...this.insuranceBondForm.value,
        };
        this.resolving = true;

        try  {
            const savedItem: InsuranceBond = await this.insuranceBondService
                .saveInsuranceBond(this.accountInformation.identifier, insuranceBondToSave)
                .toPromise();
            this.afterSave.emit();
            Object.assign(this.item, savedItem);

            this.editing = false;
        } catch (e) {
            this.insuranceBondForm.setServerError(e.error);
        } finally {
            this.resolving = false;
        }
    }
}
