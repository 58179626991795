<div>
    <br>
    <br>
    <div class="col-md-12 blocks">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
            <div class="text-center">
                <img src="/static/images/promo/sm-get-started/Archive_icon.svg" alt="Archive"/>
            </div>
            <br>
            <h3>Archive</h3>
            <p class="text-muted">
                You have no permissions to access this functionality
            </p>
        </div>
        <div class="col-md-4"></div>
    </div><!--end:blocks-->
</div>
