import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { RegulationsModalComponent } from 'shared/reviews/$reviewId/checklist/modals';
import { AuditChecklistService } from 'shared/reviews/$reviewId/checklist/checklist.service';

interface RegulationModalInputs {
	observationId?: number;
	tpoId?: number;
}

type GetParamsF = () => RegulationModalInputs;

@Component({
	selector: 'show-regulations-icon',
	templateUrl: './show-regulations-icon.component.html',
})
export class ShowRegulationsIconComponent {
	modalRef: BsModalRef;
	@Input() observationId: number;

	@Input()
	getParams: GetParamsF;

	constructor(
		public auditChecklistService: AuditChecklistService,
		public modalService: BsModalService,
	) {}

	showRegulations(): void {
		const initialState = {
			observationId: this.observationId,
			...(this.getParams ? this.getParams() : {}),
		};

		this.modalRef = this.modalService.show(RegulationsModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}
}
