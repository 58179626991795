import { ChangeDetectorRef, Injectable, OnDestroy, OnInit, Optional, ɵɵdirectiveInject } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { StateService } from 'angularjs-providers/state.provider';
import { Subject } from 'rxjs';
import { ContactServiceInterface } from 'shared/new-contacts/information/contact-service.interface';
import { ContactFormGenerator } from 'tpo/people/contacts/details/general-information/contact-form.generator';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { TpoContactType, FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { takeUntil } from 'rxjs/operators';
import { RealmFormGroup } from 'commons/forms';
import { hasContactPermission } from 'tpo/contacts/contact-permissions';
import { RolesResourceService } from 'shared/roles/roles-resource.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'commons/components/modals';

@Injectable()
export abstract class SharedGeneralInformationComponent implements OnInit, OnDestroy {
    public destroyed$: Subject<void> = new Subject();
    public contact: FullTpoContact = {} as FullTpoContact;
    public user: UserProfile;
    public loading: boolean = true;
    public contactForm: RealmFormGroup = new RealmFormGroup({});
    public editing = false;
    public isNew: boolean = false;

    public roles: any[] = [];

    protected contactFormGenerator: ContactFormGenerator = ɵɵdirectiveInject(ContactFormGenerator);
    protected router: UIRouter = ɵɵdirectiveInject(UIRouter);
    protected routerGlobals: UIRouterGlobals = ɵɵdirectiveInject(UIRouterGlobals);
    protected stateService: StateService = ɵɵdirectiveInject(StateService);
    protected cd: ChangeDetectorRef = ɵɵdirectiveInject(ChangeDetectorRef);
    protected rolesResourceService: RolesResourceService = ɵɵdirectiveInject(RolesResourceService);
    protected modalService: BsModalService = ɵɵdirectiveInject(BsModalService);

	protected modalRef: BsModalRef;

    constructor(
        @Optional() protected detailsComponent: SharedContactDetailsComponent,
        protected contactsService: ContactServiceInterface,
    ) {
        const { profile } = ɵɵdirectiveInject(UserService);
        this.user = profile;
    }

    public async ngOnInit(): Promise<void> {
        if (this.detailsComponent) {
            // Viewing/Editing an existing contact

            this.detailsComponent.contact.pipe(
                takeUntil(this.destroyed$),
            ).subscribe(contact => {
                this.loading = false;
                this.contact = contact;
                this.cd.markForCheck();
            });
        } else {
            // Creating a new contact

            await this.loadNewData();

            this.loading = false;
            this.contact = {
                ...this.getNewContactData(),
            };
            this.isNew = true;

            this.edit();

            this.cd.detectChanges();
        }
    }

    protected async loadNewData(): Promise<void> {
    }

    public ngOnDestroy(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}

        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    public initEditForm(): void {
        this.contactForm = this.contactFormGenerator.get(this.contact, this.user, this.isNew);
    }

    public edit() {
        this.editing = true;
        this.initEditForm();
    }

    public cancelEdit() {
        this.editing = false;
    }

    public hasPermission(permission: string): boolean {
        return hasContactPermission(this.user, permission);
    }

    public async triggerSave(): Promise<void> {
        const contactData = { ...this.contact, ...this.contactForm.getRawValue() };
        const tpoId = this.getTpoId();
        this.loading = true;
        try {
            if (this.isNew) {
                const savedContact: FullTpoContact = await this.contactsService.saveNewContact(contactData, tpoId).toPromise();

                this.isNew = false;
                this.cancelEdit();

                this.newContactSaved(savedContact);
            } else {
                const savedContact: FullTpoContact = await this.contactsService.saveExistingContact(contactData, tpoId).toPromise();

                this.detailsComponent.setContact(savedContact);
                this.cancelEdit();
            }
        } catch ({ error: { message }, message: httpError }) {
            this.contactForm.setServerError({ message: message || httpError });
            this.loading = false;
        }
        this.cd.detectChanges();
    }

	public showRemoveDialog() {
		const initialState = {
			title: 'Remove Contact',
            message: this.getRemoveContactMessage(),
			confirmText: 'Remove',
			onConfirm: async (): Promise<void> => {
				this.modalRef.content.resolving = true;
                try {
                    const removedContact: FullTpoContact = await this.contactsService.removeContact(
                        this.contact,
                        this.getTpoId(),
                    ).toPromise();
                    if (removedContact) {
                        this.detailsComponent.setContact(removedContact);
                    }

                    this.modalRef.hide();
                    this.contactRemoved();
                } catch ({ error: { message }, message: httpError }) {
                    this.modalRef.content.errorText = message;
                }
                this.modalRef.content.resolving = false;
			},
		};
		this.modalRef = this.modalService.show(ConfirmModalComponent, {initialState, class: 'modal-smd modal-edit modal-new'});
	}

	public showRestoreDialog() {
		const initialState = {
			title: 'Restore Contact',
			message: `Are you sure you want to restore contact <b>${this.contact.fullName}</b>?<br>This contact will not be able login until you grant access.`,
			confirmText: 'Restore',
			onConfirm: async (): Promise<void> => {
				this.modalRef.content.resolving = true;
                try {
                    const restoredContact: FullTpoContact = await this.contactsService.restoreContact(this.contact, this.getTpoId()).toPromise();
                    this.detailsComponent.setContact(restoredContact);
                    this.modalRef.hide();
                } catch ({ error: { message }, message: httpError }) {
                    this.modalRef.content.errorText = message;
                }
                this.modalRef.content.resolving = false;
			},
		};
		this.modalRef = this.modalService.show(ConfirmModalComponent, {initialState, class: 'modal-smd modal-edit modal-new'});
	}

    public abstract getTpoId(): number;

    public abstract getNewContactData(): Partial<FullTpoContact>;

    public abstract getRemoveContactMessage(): string;

    public abstract newContactSaved(savedContact: FullTpoContact): void;

    public abstract contactRemoved(): void;

    hasField = (fieldName: string): boolean =>
        this.editing && this.contactForm.contains(fieldName);

    public get shouldShowOwnership(): boolean {
        return (!this.isNew && this.hasPermission('MANAGE_OWNERS'));
    }
}
