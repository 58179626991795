<span class="col-sm-8 text-bigger text-muted">
    <span class="control-value">{{ category.title || category.code }}</span>
</span>
<scorecard-summary-findings-metrics-count
    [tpoId]="tpoId"
    [notReviewedCount]="metrics.notReviewedCompanyFindingsCount"
    [totalCount]="metrics.totalCompanyFindingsCount"
    [categoryCode]="category.code"
    [sref]="metrics.sref"
    [srefParams]="metrics.companySrefParams"
>
</scorecard-summary-findings-metrics-count>
<scorecard-summary-findings-metrics-count
    [tpoId]="tpoId"
    [notReviewedCount]="metrics.notReviewedIndividualFindingsCount"
    [totalCount]="metrics.totalIndividualFindingsCount"
    [categoryCode]="category.code"
    [sref]="metrics.sref"
    [srefParams]="metrics.individualSrefParams"
>
</scorecard-summary-findings-metrics-count>