import { Component } from '@angular/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AbstractNavigationMenuComponent } from '../abstract-navigation-menu.component';

@Component({
	selector: 'usernav-menu',
	templateUrl: './usernav-menu.component.html',
})
export class UserNavMenuComponent extends AbstractNavigationMenuComponent {
	User: UserProfile;

	constructor({ profile }: UserService) {
		super();

		this.User = profile;
	}
}
