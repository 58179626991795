import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FileSizeValidator } from 'commons/validators';
import { AddEditFindingData } from '../../scorecard-add-findings/scorecard-add-finding.interface';

import { ScorecardFinding, ScorecardFindingDetailRow, ScorecardFindingSupportingDocument } from '../../scorecard-finding.interface';

import { ScorecardFindingFormService } from '../../services/scorecard-finding-form.service';


@Component({
    selector: 'scorecard-finding-details-view-edit',
    templateUrl: './scorecard-finding-details-view-edit.component.html',
})
export class ScorecardFindingDetailsViewEditComponent implements OnInit, OnDestroy {
    @Input() isCCM: boolean;
    @Input() tpoId: number;
    @Input() finding$: Subject<ScorecardFinding>;
    @Input() saveSuccessful$: Subject<void>;
    @Input() serverErrorMessage$: Subject<string>;
    @Input() detailRows: ScorecardFindingDetailRow[];
    @Output() findingSavePrepared: EventEmitter<AddEditFindingData> = new EventEmitter();

    finding: ScorecardFinding;
    private originalSupportingDocument: ScorecardFindingSupportingDocument[] = [];

    editing: boolean = false;

    editFindingForm: RealmFormGroup = new RealmFormGroup(
        {
            isVisible: new RealmFormControl(
                'isVisible',
                {
                    label: 'Visibility',
                    value: true,
                    updateOn: 'change',
                },
                [ Validators.required ],
            ),
            notify: new RealmFormControl(
                'notify',
                {
                    label: 'Notify Customer',
                    value: true,
                    updateOn: 'change',
                },
            ),
            statement: new RealmFormControl(
                'statement',
                {
                    label: 'Statement',
                    updateOn: 'blur',
                },
            ),
            summary: new RealmFormControl(
                'summary',
                {
                    label: 'Summary',
                    updateOn: 'blur',
                },
            ),
            files: new RealmFormControl(
                'files',
                {
                    label: 'Supporting Documents',
                    value: [],
                    updateOn: 'change',
                },
                [ FileSizeValidator(20 * Math.pow(2, 20)) ],
            ),
            filesToDelete: new RealmFormControl(
                'filesToDelete',
                {
                    label: 'Files to Delete',
                    value: [],
                    updateOn: 'change',
                },
            ),
        },
        {
            validators: this.scorecardFindingFormService.confirmationValidator,
        },
    );

    isNotify: boolean = true;

    private readonly destroyed$: Subject<void> = new Subject();

    constructor(
        private readonly scorecardFindingFormService: ScorecardFindingFormService,
    ) {
    }

    //Override
    ngOnInit(): void {
        this.finding$
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((finding: ScorecardFinding) => {
                this.finding = finding;
                this.originalSupportingDocument = [...this.finding.supportingDocuments];

                this.resetForm(false);
            });
        this.saveSuccessful$
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                this.saveSuccessful();
            });
        this.serverErrorMessage$
            .pipe(
                takeUntil(this.destroyed$),
            )
            .subscribe((serverErrorMessage: string) => {
                this.editFindingForm.setServerError({ message: serverErrorMessage });
            });
    }

    //Override
    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    enterEdit(): void {
        this.editing = true;
    }

    cancelEdit(): void {
        this.editing = false;

        this.resetForm();
    }

    prepareSave(): void {
        const formData: any = this.editFindingForm.getRawValue();
        const summary: string = formData.summary;
        const notify: boolean = formData.notify;
        const isVisible: boolean = formData.isVisible;
        const filesToDelete: number[] = formData.filesToDelete;
        const newFiles: File[] = formData.files;

        const formValue: AddEditFindingData = {
            summary,
            notify,
            isVisible,
            filesToDelete,
            newFiles,
        }

        this.findingSavePrepared.emit(formValue);
    }

    resetForm(revertFindingSupportingDocuments: boolean = true): void {
        if (revertFindingSupportingDocuments) {
            this.finding.supportingDocuments = [ ...this.originalSupportingDocument ];
        }

        this.editFindingForm.reset({
            isVisible: this.finding.isVisible,
            notify: true,
            summary: this.finding.summary,
            filesToDelete: [],
            files: [],
        });
    }

    notifyChange(): void {
        this.isNotify = !this.isNotify;
        if (this.isNotify) {
            this.editFindingForm.get('statement').reset();
        }
    }

    removeOldFile(fileToRemove: ScorecardFindingSupportingDocument): void {
        const removedNewFile: ScorecardFindingSupportingDocument = this.scorecardFindingFormService.removeFile(
            <ScorecardFindingSupportingDocument>fileToRemove,
            this.finding.supportingDocuments,
        );

        if (removedNewFile) {
            this.editFindingForm.controls.filesToDelete.value.push(removedNewFile.id);
        }
    }

    addNewFile(file: FileList): void {
        this.scorecardFindingFormService.addNewFile(
            this.editFindingForm.controls.files.value,
            file,
        );
    }

    removeNewFile(fileToRemove: File): void {
        this.scorecardFindingFormService.removeFile(
            <File>fileToRemove,
            this.editFindingForm.controls.files.value,
        );
    }

    private saveSuccessful(): void {
        this.cancelEdit();
    }
}
