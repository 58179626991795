import { fromPairs, map, pick, every } from 'lodash';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MarketLocation, MarketLocationParams, MarketSrefParams } from './market.interface';

export const LOCATION_PARAMS_KEYS = ['cityName', 'msa', 'hintText', 'countyName', 'objectType', 'stateCode', 'zip'] as const;

@Injectable()
export class MarketDataService {
	private location$ = new BehaviorSubject<[Partial<MarketLocation>, boolean]>([null, false]);
	private locationSrefParams$ = new BehaviorSubject<MarketSrefParams>(null);

	setLocation(location: Partial<MarketLocation>, skipRedirect = false) {
		this.location$.next([location, skipRedirect]);
	}

	getLocation$(): BehaviorSubject<[Partial<MarketLocation>, boolean]> {
		return this.location$;
	}

	getLocation(): Partial<MarketLocation> {
		return this.location$.value[0];
	}

	resetLocation(): void {
		this.location$.next([null, false]);
	}

	isEmptyParams(params: MarketLocationParams): boolean {
		const locationParams = this.getLocationParams(params);
		return every(locationParams, val => !val);
	}

	getLocationParams (location: Partial<MarketLocation> & MarketLocationParams): MarketLocationParams {
		return pick(location, LOCATION_PARAMS_KEYS);
	}

	getLocationEmptyParams (): Record<keyof MarketLocationParams, null> {
		return fromPairs(
			map(LOCATION_PARAMS_KEYS, key => [key, null])
		) as Record<keyof MarketLocationParams, null>;
	}

	getSrefParams$(): BehaviorSubject<MarketSrefParams> {
		return this.locationSrefParams$;
	}

	getSrefParams(): MarketSrefParams {
		return this.locationSrefParams$.value;
	}

	setSrefParams(params: MarketSrefParams): void {
		this.locationSrefParams$.next(params);
	}
}
