<div [ngClass]="{'row-fluid': true}" *ngIf="list.$pagesTotal > 1 || list.$page || sizes">
	<div class="col-sm-12 pagination">
		<div class="pull-left" *ngIf="(list.$pagesTotal > 1) || list.$page || (list.$total > minSize)">
			<button type="button" class="btn btn-tertiary"
				(click)="prev()"
				[disabled]="list.$page == 0"
			>
				Previous
			</button>

			<button type="button" class="btn btn-tertiary page"
				*ngFor="let pageEntry of pagesToDisplay"
				(click)="setCurrent(pageEntry)"
				[disabled]="pageEntry == '|'"
				[ngClass]="{active: list.$page === pageEntry}"
			>
				{{pageEntry != '|' ? pageEntry + 1 : '&hellip;'}}
			</button>

			<button type="button" class="btn btn-tertiary"
				(click)="next()"
				[disabled]="list.$page >= list.$pagesTotal - 1"
			>
				Next
			</button>
		</div>

		<div *ngIf="sizes.length && list.$total > minSize" class="pull-right size">
			<span class="text-muted">Show entries</span>
			<div class="size-control">
				<ng-select
					[ngModel]="params.getValue().size"
					(change)="setSize($event)"
					[items]="sizes"
					[clearable]="false"
					appendTo="body">
				</ng-select>
			</div>
		</div>
	</div>
</div>
