import { Component } from '@angular/core';
import { StateService } from 'angularjs-providers/state.provider';

import logo from '~static/images/comergence.svg';

@Component({
	selector: 'global-logo',
	templateUrl: './logo.component.html',
})
export class GlobalLogoComponent {
    logoUrl = logo;
	constructor(public state: StateService) {}
}
