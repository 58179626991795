import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GlobalDocument } from '../../../documents.interface';
import { GlobalDocumentsService } from '../../../documents.service';

@Component({
	templateUrl: './discontinue-modal.component.html',
})
export class DocumentDiscontinueModalComponent {
	document: GlobalDocument;
	onConfirm?: () => void;
	onCancel?: () => void;
	saving = false;
	error = '';

	constructor(
		private documentsService: GlobalDocumentsService,
		private modalRef: BsModalRef,
	) {}

	async confirm(): Promise<void> {
		this.saving = true;
		try {
			const { id: documentId } = this.document;
			await this.documentsService.discontinueDocument({ documentId }).$promise;
			this.hide();
			this.onConfirm && this.onConfirm();
		} catch (e) {
			if (e.data?.message) {
				this.error = e.data.message;
			}
		}
		this.saving = false;
	}

	cancel(): void {
		this.hide();
		this.onCancel && this.onCancel();
	}

	private hide(): void {
		this.modalRef.hide();
	}
}
