import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ChannelsService, Person } from 'lender/clients/$id/channels/channels.service';
import { Availability } from 'lender/clients/clients.interface';
import { Validators } from '@angular/forms';
import { DropdownRequiredValidator } from 'commons/validators';
import { DatePipe } from '@angular/common';
import { ChannelCardService, EstablishRSData } from 'lender/clients/channels/channel-card.service';
import { ChannelsSummary } from 'lender/clients/channels/channels-summary.interface';

@Component({
    templateUrl: './establish.modal.html',
})
export class ChannelEstablishRSModalComponent implements OnInit {
    resolving = true;
    minDate = new Date();
    channelId: number;
    tpoId: number;
    investorId: number;
    form = new RealmFormGroup({
        approvalStatus: new RealmFormControl('approvalStatus', { label: 'Relationship status' }, DropdownRequiredValidator),
        approvalRenewalDate: new RealmFormControl('approvalRenewalDate', { label: 'Renewal Date' }, Validators.required),
        accountExecutiveId: new RealmFormControl('accountExecutiveId', { label: 'Assigned' }, DropdownRequiredValidator),
    });
    availableStatuses: Availability[];
    accountExecutives: Person[];
    success: (channel: ChannelsSummary) => void = () => {};

    constructor(
        public readonly modalRef: BsModalRef,
        protected readonly channelsService: ChannelsService,
        protected readonly channelCardService: ChannelCardService,
    ) {
    }

    async save() {
        const { approvalRenewalDate: rawDate, ...value } = this.form.value;
        const approvalRenewalDate = new DatePipe('en-US').transform(rawDate, 'MM/dd/yyyy');
        const formData = { approvalRenewalDate, ...value } as EstablishRSData;
        this.resolving = true;

        try {
            const channelSummary = await this.channelCardService.establishRelationship(this.investorId,this.tpoId, this.channelId, formData).toPromise();
            this.modalRef.hide();
            this.success(channelSummary);
        } catch ({ error }) {
            this.form.setServerError(error);
        } finally {
            this.resolving = false;
        }
    }

    async ngOnInit() {
        try {
            [
                this.availableStatuses,
                this.accountExecutives,
            ] = await Promise.all([
                this.channelsService.getRenewableStatuses().toPromise(),
                this.channelsService.getAccountExecutives(this.channelId).toPromise(),
            ]);
        } finally {
            this.resolving = false;
        }
    }
}
