import { UserProfile } from 'angularjs-providers/user.provider';

const getTPOPermissions = (user: UserProfile, env) => ({
	permissions: new Map([
		[ 'TPO_APPLICATIONS', {
			title: 'Applications',
			dependsOn: ['TPO_DOCUMENTS', 'TPO_USERS'],
		} ],
		[ 'TPO_DOCUMENTS', { title: 'Documents' } ],
		[ 'TPO_CONTACTS', { title: 'Contacts' } ],
		[ 'TPO_USERS', { title: 'Users', dependsOn: ['TPO_CONTACTS'] } ],
		[ 'TPO_OWNERS', { title: 'Owners', dependsOn: ['TPO_CONTACTS'] } ],
		[ 'TPO_ROLES_AND_PERMISSIONS', { title: 'Roles and Permissions' } ],
		[ 'TPO_SMC_VIEW_ALL', { title: 'View All' } ],

		[ 'TPO_SMC_ORDER_REVIEWS', {
			title: 'Order Audits',
			dependsOn: ['TPO_SMC_VIEW_ALL'],
		} ],

		[ 'TPO_SMC_ORDER_MONITORING', {
			title: 'Order Real-Time Alerts',
			dependsOn: ['TPO_SMC_VIEW_ALL'],
		} ],

		[ 'TPO_SMC_MANAGE_DIGITAL_MEDIA', { title: 'Company Social Accounts' } ],

        [ 'TPO_SMC_VIEW_SOCIAL_INVENTORY', { title: 'View Social Inventory' } ],

		[ 'TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE', {
			title: 'Manage Compliance',
			dependsOn: ['TPO_SMC_VIEW_ALL'],
		} ],

		[ 'TPO_SMC_MANAGE_TRIGGERS', {
			title: 'Manage Triggers',
			dependsOn: ['TPO_SMC_VIEW_ALL'],
		} ],

		[ 'TPO_SMC_MANAGE_CHECKLIST_SETTINGS', {
			title: 'Manage Checklist Settings',
			dependsOn: ['TPO_SMC_VIEW_ALL'],
		} ],
		[ 'TPO_PB_MANAGE_TEAM_ACCESS', { title: 'Manage Team Access' } ],
		[ 'TPO_TEAM_PUBLISH', { title: 'Team Publish' } ],
		[ 'TPO_PB_MANAGE_TEMPLATES', { title: 'Manage Templates' } ],
		[ 'TPO_MOBILE_REGISTRATION_VERIFICATION', { title: 'Mobile Registration Verification' } ],
		// [ 'TPO_PB_VIEW_CAMPAIGNS', { title: 'View Campaigns' } ],
		// [ 'TPO_PB_MANAGE_CAMPAIGNS', { title: 'Manage Campaigns', dependsOn: ['TPO_PB_VIEW_CAMPAIGNS'] } ],
		[ 'TPO_PB_VIEW_REVIEW', { title: 'View Company Reviews' } ],
		[ 'TPO_PB_REVIEW_PUBLISH', { title: 'Publish Company Reviews', dependsOn: ['TPO_PB_VIEW_REVIEW'] } ],
		[ 'TPO_PB_MANAGE_REVIEW_SETTINGS', { title: 'Manage Company Reviews Settings', dependsOn: ['TPO_PB_VIEW_REVIEW'] } ],
		[ 'TPO_PB_VIEW_GROUPS', { title: 'View Groups' } ],
		[ 'TPO_PB_MANAGE_GROUPS', { title: 'Manage Groups', dependsOn: ['TPO_PB_VIEW_GROUPS'] } ],
		[ 'TPO_FINANCIALS', { title: 'Financials' } ],
	]),
    sections: new Map([
        ['Permissions', { groups: ['Permissions Administrative'] }],
        ['Counterparty', { groups: ['Counterparty Administrative'] }],
        ['Social Media', {
            groups: ['Social Media Administrative', 'Social Media Compliance', 'Social Media Publisher'],
        }],
		['Financials', { groups: ['Financials'] }],
    ]),
    grouping: new Map([
        ['Permissions Administrative', {
            title: 'Administrative',
            columns: [
                ['TPO_CONTACTS'],
                ['TPO_ROLES_AND_PERMISSIONS', 'TPO_USERS', 'TPO_OWNERS'],
            ],
        }], ['Counterparty Administrative', {
            title: 'Administrative',
            columns: [
                ['TPO_APPLICATIONS'],
                ['TPO_DOCUMENTS'],
            ],
        }],
        ['Social Media Administrative', {
            title: 'Administrative',
            columns: [
                ['TPO_SMC_MANAGE_DIGITAL_MEDIA'],
                ['TPO_MOBILE_REGISTRATION_VERIFICATION', 'TPO_SMC_VIEW_SOCIAL_INVENTORY'],
            ],
        }],
        ['Social Media Compliance', {
            title: 'Compliance',
            columns: [
                ['TPO_SMC_VIEW_ALL', 'TPO_SMC_ORDER_REVIEWS', 'TPO_SMC_ORDER_MONITORING'],
                ['TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE', 'TPO_SMC_MANAGE_TRIGGERS', 'TPO_SMC_MANAGE_CHECKLIST_SETTINGS'],
            ],
        }],
        ['Social Media Publisher', {
            title: 'Marketing',
            columns: [
                ['TPO_PB_MANAGE_TEAM_ACCESS', 'TPO_TEAM_PUBLISH', 'TPO_PB_MANAGE_TEMPLATES'],
                ['TPO_PB_VIEW_GROUPS', 'TPO_PB_MANAGE_GROUPS', 'TPO_PB_VIEW_REVIEW', 'TPO_PB_REVIEW_PUBLISH', 'TPO_PB_MANAGE_REVIEW_SETTINGS'],
            ],
        }],
		['Financials', {
			title: 'Financials',
			columns: [
				['TPO_FINANCIALS'],
			],
		}],
    ]),
});

export { getTPOPermissions };
