import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { NonQMFileUploadHistory } from 'comergence/non-qm/non-qm.interface';

declare let apiPath: string;

@Injectable()
export class NonQmService {
	constructor(
		public http: RealmHttpClient,
	) {}

	nonQM(file: File): Observable<any> {
		return this.http.uploadFileWithForm(
			'POST',
			`${apiPath}/nqm-upload`,
			'file',
			null,
			file,
			null
		);
	}

    getNonQMFileUploadHistory(listParam): Observable<PagedData<NonQMFileUploadHistory[]>> {
        return this.http.pagedRequest<NonQMFileUploadHistory[]>(
            'GET',
            `${apiPath}/nqm-upload/upload-history`,
            listParam
        );
    }
}
