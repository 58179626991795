<form class="additional-fields remote-resource" [ngClass]="{resolved: groupedFields.$resolved, curtain: curtain}"
	  [formGroup]="additionalFieldsForm" (onValidSubmit)="save()">

	<div class="flex-row actions" *ngIf="hasFields">
		<span *ngIf="editPermission && !editing && !isNew" class="right">
			<button
				type="button"
				class="btn btn-primary"
				(click)="edit()"
			>
				<span class="glyphicon glyphicon-pencil text-smaller"></span> Edit
			</button>
		</span>
		<span class="right" *ngIf="editing && !isNew">
			<button type="submit" class="btn btn-primary" tabindex="20">
				Save
			</button>
			<a class="btn btn-default" (click)="cancelEdit()" tabindex="20">
				Cancel
			</a>
		</span>
	</div>

	<ng-container *ngIf="groupedFields.groups?.length || groupedFields.fields?.length; else noRecords">
		<ng-container *ngIf="groupedFields.fields?.length">
			<h3>{{heading || '&nbsp;'}}</h3>

			<form-errors [form]="additionalFieldsForm"></form-errors>

			<div class="rows-striped fields-without-group">
				<div class="row" *ngFor="let row of groupedFieldsRows?.fields;">
					<div *ngFor="let i of row;" class="col-sm-6">
						<fields-editable
							*ngIf="i || i === 0"
							[parentForm]="additionalFieldsForm"
							[field]="groupedFields.fields[i]"
							[editing]="editing"
							[tabindex]="10 + i"
							labelAlign="right"
						></fields-editable>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="groupedFields.groups?.length">
			<ng-container *ngFor="let group of groupedFieldsRows.groups; index as groupIndex">
				<h3>{{group.name}}</h3>
				<form-errors *ngIf="!groupedFields.fields?.length && groupIndex === 0" [form]="additionalFieldsForm"></form-errors>

				<div class="rows-striped">
					<div class="row" *ngFor="let row of group.fields;">
						<div *ngFor="let i of row;" class="col-sm-6">
							<fields-editable
								[parentForm]="additionalFieldsForm"
								[field]="groupedFields.groups[groupIndex]?.fields[i]"
								[editing]="editing"
								[tabindex]="10 + i"
								labelAlign="right"
							></fields-editable>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-template #noRecords>
		<br><br>
		<div class="rows-striped">
			<div class="row-fluid">
				<span class="col-sm-12 va-m text-muted text-center">
					No Records Added.&nbsp;
					<a uiSref="fields.:entityType" [uiParams]="{entityType: entityTypeId}">Configure</a>
				</span>
			</div>
		</div>
	</ng-template>
</form>
