import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';

import { ChannelApplicationHistoryList } from './channel-application.interface';

declare let apiPath: string;

@Injectable()
export class ChannelApplicationsService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {}

    getHistory(applicationId: number): Observable<PagedData<ChannelApplicationHistoryList[]>> {
        return this.http.pagedRequest<ChannelApplicationHistoryList[]>(
            'GET',
            `${apiPath}/applications/${applicationId}/history`,
        );
    }
}
