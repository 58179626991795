import { Pipe, PipeTransform } from '@angular/core';

// TODO: make global
interface Address {
	address: string,
	address1: string,
	address2: string,
	city: string,
	state: string,
	zip: string
}

@Pipe({
	name: 'address',
})
export class AddressPipe implements PipeTransform {
	transform(value: Partial<Address> | null | undefined): string | null {
		if (!value) {
			return null;
		}

		const { address, address1, address2, city, state, zip } = value;
        return [...(address ? [address] : [address1, address2]), city, state, zip]
			.filter(Boolean)
			.join(', ')
			|| null;
	}
}
