import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator } from 'commons/validators';

import { ListingGroup } from '../../../../company-listings.service';

@Component({
	templateUrl: './rename-listing-group-modal.component.html',
})
export class RenameListingGroupModalComponent implements OnInit {
	form: RealmFormGroup;
	listingGroup: ListingGroup;
	save: (listingGroup: Partial<ListingGroup>) => Promise<void>;
	resolved = true;

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		const { name } = this.listingGroup;

		this.form = new RealmFormGroup({
			name: new RealmFormControl(
				'name',
				{
					label: 'Name',
					value: name,
					updateOn: 'change',
				},
				[
					Validators.required,
					MaxLengthValidator(300),
				],
			),
		});
	}

	get isSubmitDisabled() {
		const {
			form,
			listingGroup: {
				name,
			},
		} = this;
		return (
			form.invalid ||
			form.get('name').value === name
		);
	}

	submit(): void {
		this.resolved = false;
		this.save(
			this.form.value,
		)
			.then(() => {
				this.modalRef.hide();
			})
			.finally(() => {
				this.resolved = true;
			});
	}

	close(): void {
		this.modalRef.hide();
	}
}
