<div class="text-black text-bigger text-center">
    <span>
        Bookmark any page as your preferred landing page once you login. For more information on how to <br/>
        &nbsp; &nbsp; bookmark on various browsers, click <a [href]="bookmarkHelpLink" target="_blank">here</a>
    </span>
    <br/>
    <span>
        All metrics and analytics are accessible in Reports. For more information on reporting, click
        <a [href]="reportingHelpLink" target="_blank">here</a>
    </span>
</div>
