<div [ngClass]="{ 'row-fluid': true, 'remote-resource': true, 'resolved': !saving}" *ngIf="hasAccess">
    <div class="col-md-6">&nbsp;</div>
    <div class="col-md-6">
        <div class="form-group row">
            <label class="col-sm-5 control-label text-right">Client UID</label>
            <div class="col-sm-7">
                <span *ngIf="!editingClientUID" class="control-value">
                    {{ details?.clientUID || '&ndash;' }}
                    <button *ngIf="canEdit" type="button" class="btn btn-primary pull-right" (click)="editClientUID()">
                        <span class="glyphicon glyphicon-pencil"></span>
                        Edit
                    </button>
                </span>
                <div *ngIf="editingClientUID" id="clientUIDInput">
                    <div class="form-group row">
                        <input class="form-control w120"
                                [(ngModel)]="editingClientUIDValue"
                                maxlength="20"
                                (keydown.enter)="$event.stopPropagation(); $event.preventDefault()"
                                (keyup.enter)="$event.stopPropagation(); $event.preventDefault(); saveClientUID()" />
                    </div>
                    <div *ngIf="errorMessage" class="form-group row alert alert-danger">
                        {{errorMessage}}
                    </div>
                    <div class="form-group row">
                        <button type="button" class="btn btn-primary" (click)="saveClientUID()">Save</button>
                        <button type="button" class="btn btn-secondary" (click)="cancelEditClientUID()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
