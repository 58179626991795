<div class='document-card'>
	<div class="front-face">
		<div class="details">
			<div class="title">
                {{document.name}}
			</div>

			<show-more class="description text-muted" [text]="document.description"></show-more>
		</div>

		<div class="flex-row gap-sm">
    		<div class="indications">
                <span *ngIf="!document.isActive" class="label label-danger-wired text-smaller">
                    Inactive
                </span>
                <a *ngIf="document.hasTemplate && templateDownloadLink"
                        class="btn btn-tertiary"
                        [documentLink]="templateDownloadLink">
                    <span class="c-icon c-icon-file"></span> Template
                </a>

                <a class="btn btn-tertiary" (click)="showHistory()">
                    <span class="c-icon c-icon-back-in-time"></span> History
                </a>
            </div>
        </div>

		<div class="actions">
			<ng-container>
                <div class="flex-column right gap-sm">
                    <a class="btn btn-secondary"
                            uiSref="documents.:documentId.edit"
                            [uiParams]="{ documentId: document.id }">
                        Manage
                    </a>
                </div>
			</ng-container>
		</div>
	</div>
</div>
