export const statusConfig = {
    P: {
        color: 'warning',
        title: 'In Progress',
    },
    N: {
        color: 'success',
        title: 'New',
    },
    C: {
        color: 'default',
        title: 'Review Complete',
    },
    Q: {
        color: 'warning',
        title: 'QA',
    },
};
