import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { linkTemplate } from 'shared/supporting-documents/helpers';
import { UploadResourceProps } from 'lender/clients/$id/documents/common';

import {
	ComergenceDocument,
	ComergenceHistoryDocument,
	ComergenceLibraryDocument,
	ComergenceLibraryHistoryDocument,
    ComergenceLinkedDocumentCount,
} from './comergence-documents.interface';


export interface ComergenceDocumentWithFile extends ComergenceDocument {
    templateFile: File;
    templateRemoved: boolean;
    templateChanged: boolean;
}

declare let apiPath: string;

@Injectable()
export class ComergenceDocumentsService {
	private readonly baseUrl = `${apiPath}/v2`;
	// private baseUrl = `${apiPath}/v2/tpos/:tpoId`;
	private baseParams = {
		tpoId: null,
	};
	private readonly libraryDocumentsUrl = `${this.baseUrl}/library-document`;
	private readonly libraryDocumentUrl = `${this.libraryDocumentsUrl}/:documentId`;
	private readonly clientLibraryDocumentsUrl = `${this.baseUrl}/client-library-documents`;
	private readonly clientLibraryDocumentUrl = `${this.clientLibraryDocumentsUrl}/:libraryId`;

	private libraryDocuments: any;
    private linkedLibraryDocuments: any;
	private libraryDocument: any;
    private discontinuedLibraryDocument: any;
	private libraryDocumentHistory: any;
	// private clientLibraryDocuments: any;
	private clientLibraryDocument: any;
	private clientLibraryDocumentRequests: any;
	private clientLibraryDocumentLibraryHistory: any;
	private clientLibraryDocumentHistory: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.baseParams = {
			...this.baseParams,
			tpoId: user.profile.organization.id,
		};

		this.libraryDocuments = PagedResource(
			this.libraryDocumentsUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

        this.linkedLibraryDocuments = PagedResource(
            `${this.libraryDocumentUrl}/linked-documents/count`,
            { ...this.baseParams },
            {
                count: {
                    method: 'GET',
                },
            },
        );

		this.libraryDocument = $resource(
			this.libraryDocumentUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
				},
                create: {
                    method: 'POST',
                    headers: {
                        'Content-Type': undefined,
                    },
                },
                update: {
                    method: 'PUT',
                    headers: {
                        'Content-Type': undefined,
                    },
                    params: {
                        ...this.baseParams,
                        documentId: '@documentId',
                    },
					transformRequest: (comergenceDocumentWithFile: ComergenceDocumentWithFile) => {
                        return this.transformDocumentFormData(comergenceDocumentWithFile);
                    },
                },
				upload: {
					url: `${this.libraryDocumentUrl}/uploadedDocument`,
					...UploadResourceProps,
				},
				uploadFromLibrary: {
					url: `${this.libraryDocumentUrl}/uploadedDocument`,
					params: {
						...this.baseParams,
						from: 'library',
					},
					method: 'POST',
				},
			},
		);

        this.discontinuedLibraryDocument = $resource(
            this.libraryDocumentUrl,
            { ...this.baseParams },
            {
                discontinue: {
                    url: `${this.libraryDocumentUrl}/discontinue`,
                    method: 'POST',
                },
                activate: {
                    url: `${this.libraryDocumentUrl}/activate`,
                    method: 'POST',
                },
            },
        );

		this.libraryDocumentHistory = PagedResource(
			`${this.libraryDocumentUrl}/history`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// this.clientLibraryDocuments = PagedResource(
		// 	this.clientLibraryDocumentsUrl,
		// 	{ ...this.baseParams },
		// 	{
		// 		get: {
		// 			method: 'GET',
		// 			isArray: true,
		// 		},
		// 		getUploaded: {
		// 			url: `${this.clientLibraryDocumentsUrl}/uploaded-client-library-documents`,
		// 			method: 'GET',
		// 			isArray: true,
		// 		},
		// 	},
		// );

		this.clientLibraryDocument = $resource(
			this.clientLibraryDocumentUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
				},
				upload: {
					url: `${this.clientLibraryDocumentUrl}/uploadedDocument`,
					...UploadResourceProps,
				},
			},
		);

		this.clientLibraryDocumentRequests = PagedResource(
			`${this.clientLibraryDocumentUrl}/requests`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.clientLibraryDocumentLibraryHistory = PagedResource(
			`${this.clientLibraryDocumentUrl}/library-history`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.clientLibraryDocumentHistory = PagedResource(
			`${this.clientLibraryDocumentUrl}/history`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

    // These actually need to be fields (rather than using method syntax) to be assignable for ListComponents.
	getLibraryDocuments = (...args): NgResourceArray<ComergenceDocument> => this.libraryDocuments.get(...args);
    getLinkedLibraryDocumentCount = (...args): NgResourceObject<ComergenceLinkedDocumentCount> => this.linkedLibraryDocuments.count(...args);
	getLibraryDocument = (...args): NgResourceObject<ComergenceDocument> => this.libraryDocument.get(...args);
	createLibraryDocument = (...args): NgResourceObject<ComergenceDocument> => this.libraryDocument.create(...args);
	updateLibraryDocument = (...args): NgResourceObject<ComergenceDocument> => this.libraryDocument.update(...args);
	uploadLibraryDocument = (...args): NgResourceObject<ComergenceDocument> => this.libraryDocument.upload(...args);
	uploadLibraryDocumentFromLibrary = (...args): NgResourceObject<ComergenceDocument> => this.libraryDocument.uploadFromLibrary(...args);
	discontinueLibraryDocument = (...args): NgResourceObject<ComergenceDocument> => this.discontinuedLibraryDocument.discontinue(...args);
	activateLibraryDocument = (...args): NgResourceObject<ComergenceDocument> => this.discontinuedLibraryDocument.activate(...args);
	getLibraryDocumentHistory = (...args): NgResourceArray<ComergenceHistoryDocument> => this.libraryDocumentHistory.get(...args);
	// getClientLibraryDocuments = (...args): NgResourceArray<ComergenceLibraryDocument> => this.clientLibraryDocuments.get(...args);
	// getClientUploadedLibraryDocuments = (...args): NgResourceArray<ComergenceLibraryDocument> => this.clientLibraryDocuments.getUploaded(...args);
	// getClientLibraryDocument = (...args): NgResourceObject<ComergenceLibraryDocument> => this.clientLibraryDocument.get(...args);
	uploadClientLibraryDocument = (...args): NgResourceObject<ComergenceLibraryDocument> => this.clientLibraryDocument.upload(...args);
	getClientLibraryDocumentRequests = (...args): NgResourceArray<any> => this.clientLibraryDocumentRequests.get(...args);
	getClientLibraryDocumentLibraryHistory = (...args): NgResourceArray<ComergenceLibraryHistoryDocument> => this.clientLibraryDocumentLibraryHistory.get(...args);
	getFromClientLibraryDocumentHistory = (...args): NgResourceArray<ComergenceLibraryHistoryDocument> => this.clientLibraryDocumentHistory.get(...args);

	/**
	 * @param {Object} params
	 * @param {number} [params.tpoId]
	 * @param {number} [params.documentId]
	 * @param {number} [params.libraryId]
	 */
	public getTemplateDownloadLink(params): string {
		const url: string = params.libraryId ? this.clientLibraryDocumentUrl : this.libraryDocumentUrl;
		const result: string = linkTemplate(
			`${url}/template`,
			{
				...this.baseParams,
				...params,
			});
        
        return result;
	}

    private transformDocumentFormData(comergenceDocumentWithFile: ComergenceDocumentWithFile): FormData {
        const {
            templateFile,
            ...comergenceDocumentWithoutFile
        } = comergenceDocumentWithFile;

        const formData: FormData = new FormData();

        const documentManagementBlob: Blob = new Blob(
            [
                JSON.stringify(comergenceDocumentWithoutFile),
            ],
            {
                type: 'application/json',
            });
        formData.append('docManagementString', documentManagementBlob);

        if (templateFile) {
            formData.append('file', templateFile);
        }

        return formData;
    }
}
