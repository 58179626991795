import {Component, inject} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UIRouter } from '@uirouter/core';

import  {UserService } from "angularjs-providers/user.provider";

import { PasswordChangeModalComponent } from 'login/password-change-modal/password-change-modal.component';

@Component({
    templateUrl: './setup-password.component.html',
})
export class SetupPasswordComponent {
    userService = inject(UserService);

    private modalRef: BsModalRef;
    constructor(
        private readonly modalService: BsModalService,
        { globals: { params: { token: resetToken } }, stateService }: UIRouter,
    ) {
        const initialState: Partial<PasswordChangeModalComponent> = {
            onReset: () => {
                this.userService.redirectToLogin();
            },
            resetToken,
            static: true,
            title: 'Create Password',
        };
        this.modalRef = this.modalService.show(PasswordChangeModalComponent, {
            initialState,
            class: 'modal-new modal-smd',
            backdrop: 'static',
        });
    }
}
