import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

declare let apiPath: string;

export enum APPROVAL_TYPE {
    CLIENT_SHARE = 'CLIENT_SHARE',
    DATAMATCH_UPLOAD = 'DATAMATCH_UPLOAD',
}

export interface ShareRequest {
    id: number;
    datMatchItemId: number;
    approvalType: APPROVAL_TYPE;
    investorFromName: string;
    investorToName: string;
}

export interface ApprovalDetails extends ShareRequest {
    message: string;
}

@Injectable()
export class TpoShareRequestsService {
    constructor(
        private readonly http: RealmHttpClient,
        private readonly userService: UserService,
    ) {}

    get tpoId(): number {
        return this.userService.profile.organization.id;
    }

    getShareRequests(): Observable<ShareRequest[]> {
        return this.http.request<ShareRequest[]>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/share-approvals`,
        );
    }

    getInvestorShareApprovalDetails(shareRequestId: number, approvalType: APPROVAL_TYPE): Observable<ApprovalDetails> {
        return this.http.request<ApprovalDetails>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/share-approvals/${approvalType}/${shareRequestId}`,
        );
    }

    acceptShareApprovalRequest(shareRequestId: number, approvalType: APPROVAL_TYPE): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/tpos/${this.tpoId}/share-approvals/${approvalType}/${shareRequestId}/accepted`,
        );
    }

    declineShareApprovalRequest(shareRequestId: number, approvalType: APPROVAL_TYPE): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/tpos/${this.tpoId}/share-approvals/${approvalType}/${shareRequestId}/declined`,
        );
    }
}
