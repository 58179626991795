<div class="remote-resource" [class.resolved]="!resolving">
	<h2>
        Application Summary
        <span class="block text-smaller va-m" *ngIf="summary?.status">
            <span class="dot" [ngClass]="'dot-' + (applicationStatusColors[summary.status.id] || 'warning')"></span>
            {{summary.status.name || '&ndash;'}}
        </span>
        <span class="col-f-150 text-right col-actions pull-right">
            <div class="flex-row gap">
                <div class="flex-grow"></div>
                <ng-container *ngIf="summary?.inProgress" [ngSwitch]="hasStatusPermission">
                    <ng-container *ngSwitchCase="true">
                        <application-transition-dropdown
                            [applicationStatusId]="summary.status.id"
                            [appStatuses]="appStatuses"
                            (appStatusSelected)="setAppStatus(summary, $event)"
                        >
                        </application-transition-dropdown>
                    </ng-container>
                    <div *ngSwitchDefault>
                        <a class="btn btn-primary btn-dropdown" tooltip="You don't have permissions" placement="bottom">
                            <span>Status</span>
                            <span class="caret"></span>
                        </a>
                    </div>
                </ng-container>
                <application-link-dropdown
                    [userProfileCanPrintPackage]="hasPrintPackagePermission"
                    [application]="summary"
                    [userProfileOrganizationId]="lenderId"
                >
                </application-link-dropdown>
            </div>
        </span>
	</h2>
    <div>
        <div class="rows-bordered gap-l">
            <div class="row-fluid header">
                <div class="col-sm-5">
                    <b>Application &nbsp;</b>
                    <span class="text-medium" *ngIf="summary?.applicationInfo">
                        <span class="label label-danger" *ngIf="summary.applicationInfo.applicationStatus === 'REQUIRED'">Required</span>
                        <span class="label label-success" *ngIf="summary.applicationInfo.applicationStatus === 'COMPLETE'">Completed</span>
                    </span>
                </div>
                <div class="col-sm-5"><b>Status</b></div>
                <div class="col-sm-2"><b>Date</b></div>
            </div>
            <div class="row-fluid gap-l" *ngIf="summary?.applicationInfo?.questionnaire">
                <div class="col-sm-5">Questionnaire</div>
                <div class="col-sm-5 text-medium">
                    <span class="label label-warning-wired" *ngIf="summary.applicationInfo.questionnaire.status === 'REQUIRED'">In Progress</span>
                    <span class="label label-success-wired" *ngIf="summary.applicationInfo.questionnaire.status === 'COMPLETE'">Completed</span>
                </div>
                <div class="col-sm-2">{{(summary.applicationInfo.questionnaire.statusDate | date: 'MM/dd/yyyy') || '&ndash;'}}</div>
            </div>
            <div class="row-fluid gap-l" *ngIf="!summary?.applicationInfo?.questionnaire">
                <div class="col-sm-5">Questionnaire</div>
                <div class="col-sm-5 text-medium">
                    <span class="label label-success-wired">Completed</span>
                </div>
                <div class="col-sm-2">&ndash;</div>
            </div>
            <div class="row-fluid gap-l" *ngIf="summary?.applicationInfo?.applicationSubmission">
                <div class="col-sm-5">Submit Application</div>
                <div class="col-sm-5 text-medium">
                    <span class="label label-danger-wired" *ngIf="summary.applicationInfo.applicationSubmission.status === 'REQUIRED'">Required</span>
                    <span class="label label-success-wired" *ngIf="summary.applicationInfo.applicationSubmission.status === 'COMPLETE'">Completed</span>
                </div>
                <div class="col-sm-2">{{(summary.applicationInfo.applicationSubmission.statusDate | date: 'MM/dd/yyyy') || '&ndash;'}}</div>
            </div>
        </div>

        <br>
        <br>

        <div *ngIf="summary?.profileReview">
            <application-summary-profile-information
                [summary]="summary">
            </application-summary-profile-information>
        </div>

        <br>
        <br>

        <div *ngIf="summary?.ownershipInfo">
            <div class="rows-bordered">
                <div class="row-fluid header">
                    <div class="col-sm-5">
                        <b>Ownership Requirements >= {{summary.ownershipInfo.ownershipReqirements | number}}</b>
                        &nbsp;
                        <span class="text-muted text-big glyphicon glyphicon-question-sign application-summary-ownership"
                              placement="top"
                              tooltip="Any owner listed that has ownership percentage more or equal than ownership requirements must verify."
                        ></span>
                        &nbsp;&nbsp;
                        <span class="text-medium">
                            <span class="label label-danger" *ngIf="summary.ownershipInfo.ownershipStatus === 'REQUIRED'">Required</span>
                            <span class="label label-success" *ngIf="summary.ownershipInfo.ownershipStatus === 'COMPLETE'">Completed</span>
                        </span>
                    </div>
                    <div class="col-sm-3"><b>Ownership</b></div>
                    <div class="col-sm-2"><b>Status</b></div>
                    <div class="col-sm-2"><b>Date</b></div>
                </div>
                <div class="row-fluid" *ngFor="let owner of summary.ownershipInfo.owners">
                    <div class="col-sm-5">{{owner.fullName}}</div>
                    <div class="col-sm-3">{{owner.ownership | number}}%</div>
                    <div class="col-sm-2 text-medium">
                        <span class="label label-danger-wired" *ngIf="owner.status === 'NOT_VERIFIED'">Not Verified</span>
                        <span class="label label-success-wired" *ngIf="owner.status === 'VERIFIED'">Verified</span>
                    </div>
                    <div class="col-sm-2">{{(owner.statusDate | date: 'MM/dd/yyyy') || '&ndash;'}}</div>
                </div>
                <div class="row-fluid" *ngIf="!summary.ownershipInfo.owners.length">
                    <div class="col-sm-12 text-muted">No individual owners added</div>
                </div>
            </div>
        </div>

        <div class="rows-bordered" *ngIf="summary?.documentsInfo">
            <br>

            <div class="row-fluid header">
                <span class="col-sm-12">
                    <b>Documents</b>
                    &nbsp;&nbsp;
                    <span class="text-medium">
                        <span class="label label-danger" *ngIf="summary.documentsInfo.documentsStatus === 'REQUIRED'">Required</span>
                        <span class="label label-success" *ngIf="summary.documentsInfo.documentsStatus === 'COMPLETE'">Completed</span>
                    </span>
                    &nbsp;&nbsp;
                    <a class="display-inline" (click)="toggleDocuments()" *ngIf="user.can('VIEW_CLIENT_DOCUMENTS')">{{showDocuments ? 'Hide Details' : 'View Details'}}</a>
                </span>
            </div>

            <ng-container *ngIf="showDocuments">
                <br>

                <client-document-list
                    [inline]="true"
                    [filters]="{channelId: summary.channel.id, activeOnly: true}"
                    [tpoId]="summary.tpo.id"
                ></client-document-list>
            </ng-container>
        </div>
    </div>
</div>
