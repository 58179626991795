import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient } from 'commons/services/http';

import { Entity } from 'tpo/documents/documents.interface';
import { TextEntity } from 'lender/clients/$id/channels/channels.service';

declare let apiPath: string;

export enum APPLICATION_STATUS {
    APPROVED = 'AP',
    CANCELLED = 'CN',
    DECLINED = 'DE',
    DOC_REQUIRED = 'DR',
    LENDER_REVIEW = 'LR',
    NOT_SUBMITTED = 'NS',
    UNDER_REVIEW = 'UR',
    WITHDRAWN = 'WD',
}

export type ApplicationStatus = TextEntity & { id: APPLICATION_STATUS };

// TODO: This is extracted out to be used with app-status.modal. What should it actually be called?
export type LenderApplicationSummary = {
    applicationId: number;
    channel: Entity & {
        description: string;
    };
    clientName: string;
    nmlsId: number;
    tpoId: number;
    hasPrintPackage: boolean;
    inProgress: boolean;
};

export type LenderApplication = LenderApplicationSummary & {
    accountExecutive: {
        id: number;
        isAccountExecutive: boolean;
        name: string;
        status:  string;
    };
    alternativeAssignee: Entity;
    applicationStatus: ApplicationStatus;
    applicationType: string;
    approvalStatus: TextEntity;
    ccRealmUserIds: number[];
    channelUID: string;
    clientUID: string;
    commentsCount: number;
    createdDate: number;
    customerOrganizationId: number;
    customerType: string;
    expirationDate: number;
    isPrintVersionAvailable: boolean;
    lenderId: number;
    lenderName: string;
    maxRenewalDate: number;
    nextRenewalDate: number;
    notificationMessage: string;
    notificationUserIds: number[];
    notify: boolean;
    optionalStatus: Entity;
    renewalDate: string;
    statusDate: number;
    statusReason: string;
};

@Injectable()
export class ComergenceApplicationsService {
	constructor(
        public http: RealmHttpClient,
    ) {}
	getAllLenders(): Observable<Entity[]> {
		return this.http.request<Entity[]>(
			'GET',
			`${apiPath}/lenders/all/base`,
		);
	}

	getApplicationsStatuses(): Observable<ApplicationStatus[]> {
		return this.http.request<ApplicationStatus[]>(
			'GET',
			`${apiPath}/applications/application-statuses`,
		);
	}

	getApplications(params): Observable<PagedData<LenderApplication[]>> {
		return this.http.pagedRequest<LenderApplication[]>(
			'GET',
			`${apiPath}/applications`,
			params
		);
	}
}
