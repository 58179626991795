import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
	templateUrl: './contacts-page.component.html',
})
export class LenderContactsPageComponent {
	isOwnershipSection: boolean = false;
    @Input('lenderInfo') public company: AccountInformation;

	constructor(
        stateService: StateService,
    ) {
		// non-registered client has no owners section
		this.isOwnershipSection = !stateService.includes('nmls.client.:id.**');
	}
}
