import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SocialMediaPost } from '../sm-post.interface';

@Component({
	selector: 'sm-post-message',
	templateUrl: './sm-post-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaPostMessageComponent {
	@Input() post: SocialMediaPost;
    @Input() displayWebLink = false;

    get message() {
        return this.post.message;
    }
}
