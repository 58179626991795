import { Injectable } from '@angular/core';

import { CommentsResourceInterface } from 'commons/components/comments';
import { NgResourceArray, NgResourceObject, NgResource } from 'commons/interfaces'
import { CodeValue, FilterDefault } from 'commons/components/list/list.interface';

import { CustomFieldsElement } from 'shared/fields/custom-fields.service';
import {
	RelatedAccount,
	RelatedAccountResourceInterface,
} from 'lender/contacts/related-account/related-account.interface';

import { ActivitiesResourceService } from './activities-resource.service';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';

@Injectable()
export class ActivitiesService {
	private activities: any;
    private params = {}
	constructor(
		public activitiesResource: ActivitiesResourceService,
	) {
		this.activities = this.activitiesResource.activities;
	}

	context = {
		activities: (): any => {
            this.params = {};
            return this.activities = this.activitiesResource.activities;
        },
		prospect: (prospectId: number): any => {
            this.params = { prospectId };
            return this.activities = this.activitiesResource.activities;
        },
        client: (nmlsId: number): any => {
            this.params = { nmlsId };
            return this.activities = this.activitiesResource.clientActivities;
        },
		contact: (organizationContactId: number): any => {
            this.params = { organizationContactId };
            this.activities = this.activitiesResource.contactActivities;
        },
	}

	query = (queryParams?: ActivityQueryParams): NgResourceArray<Activity> => this.activities.query({ ...this.params, ...queryParams });
	get = (id: number): NgResourceObject<Activity> => this.activities.get({ ...this.params, id });
	update = (dto: Activity): NgResourceObject<Activity> => this.activities.update({ ...this.params, ...dto });
	create = (dto: Activity & { customFieldValues: CustomFieldsElement[] }): NgResourceObject<Activity> => (
		this.activities.create({ ...this.params, ...dto })
	);
	delete = (id: number): NgResource => this.activities.delete({ ...this.params, id });
	assignedTo = (): NgResourceArray<FilterDefault> => this.activities.assignedTo(this.params);
	assignedFilter = (): NgResourceArray<FilterDefault> => this.activities.assignedFilter(this.params);
	companyInfo = (clientId: number, isRegisteredTPO: boolean): NgResourceObject<CompanyInfo> => {
		const companyPath = isRegisteredTPO ? 'tpos' : 'nmls/company'
		return this.activitiesResource.clientActivities.findCompany({ companyPath, clientId })
	};
	priorities = (): NgResourceArray<CodeValue> => this.activitiesResource.resources.priorities();
	statuses = (): NgResourceArray<CodeValue> => this.activitiesResource.resources.statuses();

	// Related Account
	getRelatedAccountResource = (id: number): RelatedAccountResourceInterface => {
		return {
			search: (q: string): NgResourceArray<RelatedAccount> => this.activitiesResource.relatedAccount.hints({ q }),
			update: (nmlsId: number): NgResourceObject<Activity> => (
				this.activitiesResource.relatedAccount.update({ id, nmlsId })
			),
			delete: (): NgResourceObject<Activity & { relatedAccount: null }> => (
				this.activitiesResource.relatedAccount.delete({ id })
			),
		};
	};

	// Related Contact
	contactSearch = (q: string): NgResourceArray<RelatedContact> => this.activitiesResource.relatedContact.query({ q });
	contactUpdate = (activityId: number, organizationContactId: number): NgResourceObject<Activity> => (
		this.activitiesResource.relatedContact.update({ id: activityId, organizationContactId })
	);
	contactDelete = (activityId: number): NgResourceObject<Activity> => (
		this.activitiesResource.relatedContact.delete({ id: activityId })
	);

	// Related Prospect
	prospect = (id: number): NgResourceObject<RelatedProspect> => this.activitiesResource.relatedProspect.prospect({ id });
	prospectSearch = (q: string, nmlsId?: number, defaultParams?: { size: 5, prospectState: 'O' }): NgResourceArray<RelatedProspect> => {
		const params = { q, ...defaultParams, ...(nmlsId ? { nmlsId } : null) };
		return this.activitiesResource.relatedProspect.query(params);
	};
	prospectUpdate = (activityId: number, prospectId: number, nmlsId: number): NgResourceObject<Activity> => (
		this.activitiesResource.relatedProspect.update({ id: activityId, prospectId, nmlsId }, null)
	);
	prospectDelete = (activityId: number): NgResourceObject<Activity> => (
		this.activitiesResource.relatedProspect.delete({ id: activityId })
	);

	// Comments
	getCommentsResource = (id: number): CommentsResourceInterface => {
		return {
			query: (listParams): Required<NgResourceArray<Comment>> => {
				const params = { ...listParams, id };
				return this.activitiesResource.comments.query(params);
			},
			add: (listParams, data, successHandler, ererrorHandlerror): NgResourceObject<Comment> => {
				const params = { ...listParams, id };
				return this.activitiesResource.comments.add(params, data, successHandler, ererrorHandlerror);
			},
		};
	};
	commentType = (): NgResourceArray<CommentType> => this.activitiesResource.comments.commentType();
}

export interface Activity {
	assignedTo?: {
		id: number;
		name: string;
	};
	createdDate?: number;
	dueDate?: number;
	id?: number;
	name?: string;
	priority?: {
		id: string;
		name: string;
	};
	status?: {
		id: string;
		name: string;
	};
	description?: string;
	relatedAccount?: RelatedAccount;
	relatedContact?: RelatedContact;
	relatedProspect?: RelatedProspect;
}

export interface RelatedContact {
	companyName?: string
	contactName?: string;
	contactId?: number;
	companyNmlsId?: number;
	individualNmlsId?: number;
	nmlsId?: number;
	organizationContactId?: number;
	tpoIndividualContactId?: number;
	lenderIndividualContactId?: number;
	tpoId?: number | null;
	contactType?: string;
	isDeleted?: boolean;
}

export interface RelatedProspect {
	address?: string;
	assigneeName?: string;
	assigneeUserId?: number;
	channelName?: string;
	city?: string;
	companyName?: string;
	editable?: boolean;
	editableStatus?: boolean;
	id?: number;
	locationCompanyName?: string;
	locationName?: string;
	locationNmlsId?: string;
	locationType?: string;
	nmlsId?: number;
	pendingApplicationsExists?: boolean;
	prospectState?: string;
	prospectStatus?: string;
	state?: string;
	tpoId?: number;
	zip?: string
}

interface ActivityQueryParams {
	nmlsId?: number;
	q?: string;
	statusId?: number;
	priorityId?: number;
	assignedToId?: string[];
	dueDateBefore?: string[];
	dueDateAfter?: string;
	prospectId?: string[];
	organizationContactId?: number[];
	page?: number;
	size?: number;
	sort?: string[];
}

export interface ProfileAccessLevel {
	id: string;
	name: string;
}

export interface CompanyInfo {
	businessEntity: string;
	businessTaxId: number;
	city: string;
	clientSID: number;
	clientUID: number;
	companyAddress: string;
	companyName: string;
	dateFormed: string | '10/10/2002';
	fDICNCUACertificationNumber: number | null;
	fax: number | null;
	fiscalYearEnd: string | '12/31';
	hasMcr: boolean;
	latitude: number;
	longitude: number;
	mersId: number | null;
	nmlsId: number;
	nmlsRemovedDate: string | null;
	organizationId: number;
	ownership99PercentRequired: boolean;
	phone: number;
	primaryFederalRegulator: string | null;
	profileAccessLevel: ProfileAccessLevel;
	relationshipStatus: ProfileAccessLevel;
	registrationDate: string | '01/30/2018';
	registrationStatus: string | null;
	state: string;
	stateOfIncorporation: string;
	tickerSymbol: string;
	validTickerSymbol: boolean;
	websites: [];
	zip: number;
}
