import { Component, OnInit } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Statuses } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-finding-summary.interface';
import { ScorecardMarkReviewedService } from 'shared/due-diligence/scorecard/services/scorecard-mark-reviewed-service';

@Component({
    templateUrl: 'finding-alert-status-modal.component.html',
})
export class FindingAlertStatusModalComponent implements OnInit {
    findingAlertsForm: RealmFormGroup = new RealmFormGroup({
        alertStatus: new RealmFormControl(
            'alert',
            {
                label: 'Status',
                updateOn: 'change',
            },
            [
                Validators.required,
            ],
        ),
    });
    currentAlertStatus: string;
    findingId: (string | number);
    lenderId: number;
    resolving: boolean;
    callbackOnSave: () => void;
    statuses = Statuses;

    constructor(
        private readonly modalRef: BsModalRef,
        private readonly scorecardMarkReviewedService: ScorecardMarkReviewedService,
    ) {
    }

    ngOnInit() {
        this.loadStatus();
    }

    close(): void {
        this.callbackOnSave();
        this.modalRef.hide();
    }

    loadStatus() {
        this.findingAlertsForm.patchValue({alertStatus: this.currentAlertStatus});
    }

    async onConfirm() {
        try {
            const alertStatus: string = this.findingAlertsForm.value;
            await this.scorecardMarkReviewedService.updateAlertStatus(this.lenderId, this.findingId, alertStatus).toPromise();
            this.close();
        }  catch ({ error: { message }, message: httpError }) {
            this.findingAlertsForm.setServerError({ message: message || httpError });
        } finally {
            this.resolving = false;
        }
    }
}
