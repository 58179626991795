import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults, every, forEach, reduce } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';

enum PageType {
	BRAND = 'BRAND',
	SHOWCASE = 'SHOWCASE',
}

interface Page {
	address: any; // FIXME
	name: string;
	networkCode: string;
	previouslyConnected: boolean;
	profilePictureUrl: string;
	referenceId: string;
	type: PageType;
}

interface PagesGroup {
	id: PageType;
	title: string;
	pages: Page[];
}

@Component({
	templateUrl: './linkedin-pages-modal.component.html',
})
export class LinkedInPagesModalComponent implements OnInit, OnDestroy {
	User: UserProfile;
	title: string;
	message: string;
	pagesGroups: PagesGroup[] = [
		{
			id: PageType.BRAND,
			title: 'Pages',
			pages: [],
		},
		{
			id: PageType.SHOWCASE,
			title: 'Showcase Pages',
			pages: [],
		},
	];
	form = new RealmFormGroup(
		this.pagesGroups.reduce((acc, { id: pagesGroupId }) => ({
			...acc,
			[pagesGroupId]: new RealmFormGroup({
				pages: new RealmFormGroup({}),
				all: new RealmFormControl(
					'all',
					{
						value: true,
						updateOn: 'change',
					},
				),
			}),
		}), {}),
	);

	set pages(pages: Page[]) {
		pages.forEach((page) => {
			const {
				previouslyConnected,
				referenceId,
				type: pageType,
			} = page;

			// add to model
			const pagesGroup: PagesGroup = this.pagesGroups.find(({ id: pagesGroupId }) => pagesGroupId === pageType);
			if (pagesGroup) {
				pagesGroup.pages.push(page);
			}

			// add to form controls
			const pagesGroupControl = this.form.get([ pageType, 'pages' ]) as RealmFormGroup;
			if (pagesGroupControl) {
				pagesGroupControl.addControl(
					referenceId,
					new RealmFormControl(
						referenceId,
						{
							value: true,
							disabled: previouslyConnected,
							updateOn: 'change',
						},
					),
				);
			}
		});

		forEach(this.form.controls, (groupControl: RealmFormGroup) => {
			const pagesGroupControl = groupControl.get('pages') as RealmFormGroup;
			const pagesGroupAllControl = groupControl.get('all') as RealmFormControl;
			const disabled = every(pagesGroupControl.controls, (pageControl: RealmFormControl) => pageControl.disabled);
			if (disabled) {
				pagesGroupAllControl.disable();
			}
		});
	}

	confirmText: string;
	cancelText: string;
	onConfirm: (selectedPages: Page[]) => void;
	onCancel: () => void;
	resolving: boolean;
	private unsubscribe$ = new Subject<void>();

	constructor(
		public modalRef: BsModalRef,
		{ profile: User }: UserService,
	) {
		this.User = User;

		defaults(this, {
			title: 'Company Social Media',
			message: `Confirm by selecting ${this.User.organization.name} LinkedIn pages you wish to connect.`,
			confirmText: 'Save',
			cancelText: 'Cancel',
			resolving: false,
		});
	}

	ngOnInit(): void {
		forEach(this.form.controls, (groupControl: RealmFormGroup) => {
			const pagesGroupControl = groupControl.get('pages') as RealmFormGroup;
			const pagesGroupAllControl = groupControl.get('all') as RealmFormControl;

			pagesGroupControl.valueChanges
				.pipe(
					takeUntil(this.unsubscribe$),
				)
				.subscribe(() => {
					const pagesGroupAllSelection = every(pagesGroupControl.getRawValue(), Boolean);
					pagesGroupAllControl.setValue(pagesGroupAllSelection, { emitEvent: false });
				});

			pagesGroupAllControl.valueChanges
				.pipe(
					takeUntil(this.unsubscribe$),
				)
				.subscribe((pagesGroupAllSelection) => {
					forEach(pagesGroupControl.controls, (pageControl: RealmFormControl) => {
						if (pageControl.enabled) {
							pageControl.setValue(pagesGroupAllSelection, { emitEvent: false });
						}
					});
				});
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	onSubmit(): void {
		const selectedPages: Page[] = reduce(this.form.getRawValue(), (acc, pagesGroupValue, groupId: string) => {
			const pagesGroup: PagesGroup = this.pagesGroups.find(({ id }) => id === groupId);
			if (pagesGroup) {
				return reduce(pagesGroupValue.pages, (acc, selected, pageId: string) => {
					if (selected) {
						const page: Page = pagesGroup.pages.find(({ referenceId }) => referenceId === pageId);
						if (page) {
							return acc.concat(page);
						}
					}
					return acc;
				}, acc);
			}
			return acc;
		}, []);
		this.onConfirm(selectedPages);
	}
}
