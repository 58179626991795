import { Component, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'smHelpLink',
	templateUrl: './sm-help-link.component.html',
})
export class SocialMediaHelpLinkComponent {
	@Input() url: string;
	@HostBinding('class.sm-help-link') true;
}
