import { Component, Input } from '@angular/core';

@Component({
    selector: 'investor-info-block',
    templateUrl: './investor-info-block.component.html',
})
export class InvestorInfoBlockComponent {
    @Input() bookmarkHelpLink: string;
    @Input() reportingHelpLink: string;
}
