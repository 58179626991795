<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<div class="row-fluid header">
		<span class="col-md-3"><b class="two-liners">Date</b></span>
		<span class="col-md-6"><b class="two-liners">Action</b></span>
		<span class="col-md-3"><b class="two-liners">Action By</b></span>
	</div>
	<div class="row-fluid" *ngFor="let entry of list">
		<div class="col-md-3">
			{{entry.actionAt | date : 'MM/dd/yyyy HH:mm'}}
		</div>
		<div class="col-md-6">
			<div *ngFor="let action of entry.actions">{{action}}</div>
		</div>
		<div class="col-md-3">{{entry.actionBy}}</div>
	</div>

	<div class="row-fluid" *ngIf="!list?.length">
		<span class="col-sm-12 text-center text-muted text-bigger">No audit logs found</span>
	</div>
</div>
