import { orderBy } from 'lodash';
import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class CustomFieldsResourceService {
	public fields: any;
	public field: any;
	public types: any;
	public prospectSystemStatuses: any;
	public groups: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');

		const investorBasePath = `${apiPath}/lenders/:lenderId`;
		const investorDefaultParams = { lenderId: User.profile.organization.id };

		const basePath = `${apiPath}/organizations/:organizationId`;
		const fieldsPath = `${basePath}/custom-fields`;
		const defaultParams = { organizationId: User.profile.organization.organizationId };

		// https://r5-dev.ccmdev.com/swagger-ui.html#/custom45field45resource
		const entityFieldsPath = `${fieldsPath}/:entityTypeId`;
		const groupsPath = `${basePath}/custom-field-groups`;
		this.fields = $resource(fieldsPath, defaultParams, {
			entityFields: {
				method: 'GET',
				isArray: true,
				url: `${entityFieldsPath}`,
				transformResponse: (response) => orderBy(JSON.parse(response), 'displayOrder'),
			},
			values: {
				method: 'GET',
				isArray: true,
				url: `${entityFieldsPath}/:entityId`,
			},
			valuesNew: {
				method: 'GET',
				url: `${groupsPath}/:entityTypeId/:entityId/layout`,
			},
			updateValues: {
				method: 'PUT',
				url: `${entityFieldsPath}/:entityId`,
				params: { entityTypeId: '@entityTypeId', entityId: '@entityId' },
				isArray: true,
			},
			updateValuesNew: {
				method: 'PUT',
				url: `${groupsPath}/:entityTypeId/:entityId/layout`,
			},
			add: {
				method: 'POST',
				url: `${entityFieldsPath}`,
				params: { entityTypeId: '@entityTypeId' },
			},
			layout: {
				method: 'GET',
				url: `${groupsPath}/:entityTypeId/layout`,
				params: { entityTypeId: '@entityTypeId' },
			},
			layoutUpdate: {
				method: 'PUT',
				url: `${groupsPath}/:entityTypeId/layout`,
				params: { entityTypeId: '@entityTypeId' },
			},
		});

		const fieldPath = `${fieldsPath}/field/:id`;
		this.field = $resource(fieldPath, defaultParams, {
			update: {
				method: 'PUT',
				params: { id: '@id' },
			},
			action: {
				method: 'PUT',
				url: `${fieldPath}/:action`,
				params: { id: '@id', action: '@action' },
			},
		});

		this.types = $resource(`${apiPath}/code-value/UIControlType`, {}, {
			get: {
				cacheConfig: {
					url: `${apiPath}/code-value/state`,
				},
				method: 'GET',
				isArray: true,
			},
		});

		this.prospectSystemStatuses = $resource(`${investorBasePath}/prospect-statuses`, investorDefaultParams, {
			update: {
				method: 'PUT',
				isArray: true,
			},
			events: {
				method: 'GET',
				isArray: true,
				url: `${investorBasePath}/prospect-events`,
			},
		});

		this.groups = $resource(`${groupsPath}/:entityTypeId`, defaultParams, {
			add: {
				method: 'POST',
				params: { entityTypeId: '@entityTypeId' },
			},
			update: {
				method: 'PATCH',
				url: `${groupsPath}/groups/:groupId`,
				params: { groupId: '@groupId' },
			},
			remove: {
				method: 'DELETE',
				url: `${groupsPath}/groups/:groupId`,
				params: { groupId: '@groupId' },
			},
			action: {
				method: 'PUT',
				url: `${groupsPath}/:id/:action`,
				params: { id: '@id', action: '@action' },
			},
		});
	}
}
