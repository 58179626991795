import { Injectable } from '@angular/core';
import { SharedLicenseListComponent } from 'shared/account/licenses';
import { ScorecardFindingCategoryMetrics } from '../scorecard-findings-metrics.interface';
import { ScorecardFindingsSummaryComponent } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-findings-summary.component';


export const LICENSES_INVESTOR_SREF: string = 'client.:id.account.licenses';
export const LICENSES_CCM_SREF: string = 'tpo.:id.account.licenses';
export const NON_LICENSES_INVESTOR_SREF: string = 'client.:id.reviews.scorecard.finding.:sType';
export const NON_LICENSES_CCM_SREF: string = 'tpo.:id.scorecard.finding.:sType';

@Injectable()
export class ScorecardMetricsAnnotatorService {
    annotateMetrics(
        metrics: ScorecardFindingCategoryMetrics,
        isCCM: boolean,
        tpoId: (number | string),
    ): void {
        const authorization: any = 'false';

        let sref: string;
        let findingForLabel: string;
        let companyFindingFor: any;
        let individualFindingFor: any;

        if (metrics.categoryCode === 'LIC') {
            findingForLabel = SharedLicenseListComponent.listName;
            companyFindingFor = {
                authorization,
            };
            individualFindingFor = {
                authorization,
                class: 'INDIVIDUAL',
            };

            if (isCCM) {
                sref = LICENSES_CCM_SREF;
            } else {
                sref = LICENSES_INVESTOR_SREF;
            }
        } else {
            findingForLabel = ScorecardFindingsSummaryComponent.listName;
            individualFindingFor = {findingFor: 'I'};

            if (isCCM) {
                sref = NON_LICENSES_CCM_SREF;
            } else {
                sref = NON_LICENSES_INVESTOR_SREF;
            }
        }

        metrics.sref = sref;

        metrics.companySrefParams = {
            id: tpoId,
            sType: metrics.categoryCode,
            [findingForLabel]: companyFindingFor,
        };

        metrics.individualSrefParams = {
            id: tpoId,
            sType: metrics.categoryCode,
            [findingForLabel]: individualFindingFor,
        };
    }
}
