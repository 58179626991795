import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable, of } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { ContactServiceInterface } from 'shared/new-contacts/information/contact-service.interface';

declare let apiPath: string;

@Injectable()
export class InvestorContactsService implements ContactServiceInterface {
    protected user: UserProfile;
    protected params: { lenderId?: number } = {};

    constructor(
        protected http: RealmHttpClient,
        { profile }: UserService,
    ) {
        this.user = profile;
        if (this.user.isLender) {
            this.params.lenderId = this.user.organization.id;
        }
    }

    getContact(contactId: number, tpoId: number = null, lenderId: number = this.params.lenderId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'get',
            `${apiPath}/lenders/${lenderId}/contacts/${contactId}`,
            { tpoId },
        );
    }

    getNmlsContact(contactId: number, nmlsId: number = null, lenderId: number = this.params.lenderId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'get',
            `${apiPath}/lenders/${lenderId}/contacts/${contactId}`,
            { nmlsId },
        );
    }

    saveNewContact(contact: FullTpoContact, tpoId: number, lenderId: number = this.params.lenderId): Observable<FullTpoContact> {
        return this.http.request<FullTpoContact>(
            'post',
            `${apiPath}/lenders/${lenderId}/contacts`,
            {},
            contact,
        );
    }

    saveExistingContact(contact: FullTpoContact, tpoId: number, lenderId: number = this.params.lenderId): Observable<FullTpoContact> {
        const { contactId } = contact;
        return this.http.request<FullTpoContact>(
            'put',
            `${apiPath}/lenders/${lenderId}/contacts/${contactId}`,
            { tpoId },
            contact,
        );
    }

    removeContact(contact: FullTpoContact, tpoId: number, lenderId: number = this.params.lenderId): Observable<FullTpoContact> {
        const { contactId } = contact;
        return this.http.request<FullTpoContact>(
            'delete',
            `${apiPath}/lenders/${lenderId}/contacts/${contactId}`,
            { tpoId },
            contact,
        );
    }

    restoreContact(contact: FullTpoContact, tpoId: number, lenderId: number = this.params.lenderId): Observable<FullTpoContact> {
        // TODO ?
        return of({});
    }
}
