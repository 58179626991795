<contacts-import-steps></contacts-import-steps>
<form class="remote-resource" [class.resolved]="!saving && !loading" [formGroup]="form" (onValidSubmit)="import()">
    <form-errors [form]="form"></form-errors>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-md-3">Update Field</span>
            <span class="col-md-5">Uploaded Columns</span>
            <span class="col-md-4">Sample Data</span>
        </div>
        <ng-container *ngFor="let column of COLUMNS_AVAILABLE; last as isLast">
            <div class="row-fluid form-group" has-error [class.no-border]="isLast">
                <label class="col-md-3 control-label" [class.required]="REQUIRED_COLUMNS.includes(column)">{{ COLUMN_LABELS[column] }}</label>
                <div class="col-md-5">
                    <ng-select
                        placeholder="Select column"
                        [items]="possibleMappingValues$[column] | async"
                        [loading]="loading"
                        [clearable]="true"
                        bindLabel="fileColumnName"
                        [formControlName]="column">
                    </ng-select>
                </div>
                <div class="col-md-4">
                    <div class="ellipsis">{{ (columnsSampleData[column] || [])[0] || '&ndash;' }}</div>
                    <div class="ellipsis">{{ (columnsSampleData[column] || [])[1] || '&ndash;' }}</div>
                    <div class="ellipsis">{{ (columnsSampleData[column] || [])[2] || '&ndash;' }}</div>
                </div>
            </div>
        </ng-container>
    </div>
    <br>
    <div class="text-right">
        <button type="submit" class="btn btn-primary">Import</button>
        <a class="btn btn-secondary" uiSref="people.contacts">Cancel</a>
    </div>
</form>
