import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { CompanyPartial, SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { InvestorContactsService } from 'lender/new-contacts/investor-contacts.service';
import { ReplaySubject } from 'rxjs';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';

@Component({
    templateUrl: '../../../tpo/people/contacts/details/tpo-contact-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorContactDetailsComponent extends SharedContactDetailsComponent {
    private company$: ReplaySubject<CompanyPartial> = new ReplaySubject(1);
    public isNonRegistered = false;

    constructor(
        { profile }: UserService,
        private router: UIRouter,
        protected contactsService: InvestorContactsService,
    ) {
        super();
        const { contactId = null, id = null } = this.router.globals.params;
        this.isNonRegistered = router.stateService.includes('nmls.**');
        this.user = profile;
        this.company = this.company$.asObservable();
        if (this.isNonRegistered) {
            this.contactsService.getNmlsContact(contactId, id).subscribe(contact => this.setContact(contact));
        } else {
            this.contactsService.getContact(contactId, id).subscribe(contact => this.setContact(contact));
        }
    }

    public setContact(contact: FullTpoContact) {
        super.setContact(contact);
        const { id = null } =  this.router.globals.params;
        const { organization: { organizationId } } = this.user;
        const { relatedAccount: { name = null, nmlsId, tpoId } = {} } = contact;
        this.isNonRegistered = !tpoId;
        if (this.isNonRegistered) {
            this.accountSref = 'nmls.client.:id.account.information';
            this.accountSrefParams = { id: nmlsId };
        } else {
            this.accountSref = 'client.:id.account.information';
            this.accountSrefParams = { id: tpoId };
        }
        this.company$.next({ name, organizationId, ...(this.isNonRegistered ? { nmlsId: id } : { tpoId: id }) });
    }
}
