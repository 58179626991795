import { NgModule } from '@angular/core';
import { CcmDataMatchHistoryComponent } from 'comergence/data-match/data-match-history/ccm-data-match-history.component';
import { CcmDataMatchService } from 'comergence/data-match/ccm-data-match.service';
import { ComponentsModule } from 'commons/components/components.module';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'commons/directives/directives.module';
import { CcmDataMatchReportViewEditComponent } from 'comergence/data-match/data-match-report-view-edit/ccm-data-match-report-view-edit.component';
import { PipesModule } from 'commons/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CcmDataMatchResultSectionComponent } from 'comergence/data-match/data-match-report-view-edit/data-match-result-section/ccm-data-match-result-section.component';
import { CcmDataMatchHqStatisticsSectionComponent } from 'comergence/data-match/data-match-report-view-edit/data-match-hq-section/ccm-data-match-hq-statistics-section.component';
import { CcmDataMatchHqVerificationResultsSectionComponent } from 'comergence/data-match/data-match-report-view-edit/data-match-hq-section/ccm-data-match-hq-verification-results-section.component';
import { NewDataMatchComponent } from './new-data-match/new-data-match.component';
import { CcmDataMatchUpdateRelationshipStatusComponent } from 'comergence/data-match/data-match-report-view-edit/data-match-update-relationship-status/ccm-data-match-update-relationship-status.component';

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        DirectivesModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        CcmDataMatchHistoryComponent,
        CcmDataMatchReportViewEditComponent,
        CcmDataMatchResultSectionComponent,
        CcmDataMatchHqStatisticsSectionComponent,
        CcmDataMatchHqVerificationResultsSectionComponent,
        NewDataMatchComponent,
        CcmDataMatchUpdateRelationshipStatusComponent,
    ],
    providers: [
        CcmDataMatchService,
    ],
})
export class CcmDataMatchModule {}
