import * as moment from 'moment';
import { find, reduce, includes } from 'lodash';
import { Subscription } from 'rxjs';
import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { UserService } from 'angularjs-providers/user.provider';

import { CustomRequiredValidator } from 'commons/validators';
import { RealmFormGroup } from 'commons/forms';

import { getForm, messageLengthValidator, messageCharsAmount } from './assign-modal.form';
import { QuestionnairesService } from '../questionnaires.service';
import {
	AssignableQuestionnaireList,
	QuestionnaireContactsList,
	QuestionnaireLenderContactsList,
	QuestionnaireAssignPayload,
	QuestionnairesListEntry,
} from '../questionnaires.interface';

const getTomorrowDate = (): Date => {
	const tomorrow = new Date();
	// add 1 day to today
	tomorrow.setDate(new Date().getDate() + 1);

	return tomorrow;
};

@Component({
	templateUrl: './assign-modal.component.html',
})
export class AssignQuestionnairesModalComponent implements OnInit, OnDestroy {
	// modal inputs
	questionnaire: QuestionnairesListEntry;
	schedule = false;
	reload: () => void;
	getResourceParams: () => { [key: string]: unknown };

	get questionnaireAssignmentId(): number {
		return (
			this.questionnaire
			&& !this.questionnaire.id
			&& this.questionnaire.questionnaireAssignmentId
		);
	}

	isQuestionnaireConfirmed = false;

	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
		adaptivePosition: true,
	};
	tomorrow = getTomorrowDate();

	// modal props
	saving: boolean = false;
	resolved: boolean = false;

	questionnaireId$: Subscription;
	notifyUponAssignment$: Subscription;
	notifyUponCompletion$: Subscription;
	isScheduled$: Subscription;

	description: string = '';
	messageCharsAmount: number = messageCharsAmount;

	assignForm: RealmFormGroup = getForm();
	questionnaires: AssignableQuestionnaireList = [];
	contacts: QuestionnaireContactsList = [];
	cc: QuestionnaireLenderContactsList = [];

	constructor(
		public modalRef: BsModalRef,
		public questionnairesService: QuestionnairesService,
		public User: UserService,
	) {}

	ngOnInit(): void {
		this.isQuestionnaireConfirmed = (
			// skip questionnaire selection on edit action
			!!this.questionnaireAssignmentId

			// skip questionnaire selection on schedule action
			|| this.schedule
		);

		if (this.schedule) {
			// FIXME: interface is incomplete
			this.assignForm.get('questionnaireId').setValue(this.questionnaire['questionnaireId']);
			this.assignForm.get('_is_scheduled').setValue(true);
		}

		this.loadResources();

		this.questionnaireId$ = this.assignForm.get('questionnaireId').valueChanges
			.subscribe((questionnaireId) => {
				if (questionnaireId) {
					const selectedQuestions = find(this.questionnaires, (entry) => {
						return entry.id === questionnaireId;
					});

					this.description = (selectedQuestions && selectedQuestions.description) || '';
					return;
				}

				this.description = '';
			});

		this.notifyUponAssignment$ = this.assignForm.get('_notify_upon_assignment').valueChanges
			.subscribe((notifyUponAssignment) => {
				const messageControl = this.assignForm.get('message');
				const toContactIdsControl = this.assignForm.get('toContactIds');

				const messageValidators = notifyUponAssignment ? Validators.compose([
					Validators.required,
					messageLengthValidator,
				]) : messageLengthValidator;

				const toContactIdsValidators = notifyUponAssignment ? Validators.required : null;

				this._updateControlValidators(messageControl, messageValidators);
				this._updateControlValidators(toContactIdsControl, toContactIdsValidators);
			});

		this.notifyUponCompletion$ = this.assignForm.get('_notify_upon_completion').valueChanges
			.subscribe((notifyUponCompletion) => {
				const notifyUponCompletionRealmUserIdsControl = this.assignForm.get('notifyUponCompletionRealmUserIds');
				const validators = notifyUponCompletion ? Validators.required : null;

				this._updateControlValidators(notifyUponCompletionRealmUserIdsControl, validators);
			});

		this.isScheduled$ = this.assignForm.get('_is_scheduled').valueChanges
			.subscribe((isScheduled) => {
				const scheduledDateControl = this.assignForm.get('scheduledDate');
				const validators = isScheduled ? CustomRequiredValidator('Please select <b>Schedule Date</b>') : null;

				this._updateControlValidators(scheduledDateControl, validators);
			});
	}

	_updateControlValidators(
		control: AbstractControl,
		validators: ValidatorFn | ValidatorFn[] | null,
	): void {
		control.setValidators(validators);
		control.updateValueAndValidity();
	}

	_filterExistingIds = (
		inputIds: number[] | null,
		collection: unknown[],
		idKey: string,
	): number[] | null => {
		if (!inputIds) {
			return null;
		}

		const ids = inputIds ?? [];

		return reduce(collection, (acc, entry) => {
			const id = parseInt(`${entry[idKey]}`, 10);
			const isMatch = includes(ids, id);
			if (!isMatch) {
				return acc;
			}

			return [ ...acc, id ];
		}, [] as number[]);
	};

	ngOnDestroy(): void {
		this.questionnaireId$.unsubscribe();
		this.notifyUponAssignment$.unsubscribe();
		this.notifyUponCompletion$.unsubscribe();
		this.isScheduled$.unsubscribe();
	}

	async loadResources(): Promise<void> {
		const params = this.getResourceParams();
		try {
			const [
				questionnaires,
				contacts,
				cc,
				assignment,
			] = await Promise.all([
				this.questionnairesService.assignableQuestionnaires.get(params).$promise,
				this.questionnairesService.contacts.get(params).$promise,
				this.questionnairesService.lenderContacts.get({
					...params,
					lenderId: this.User.profile.organization.id,
				}).$promise,
				this.questionnaireAssignmentId
					? this.questionnairesService.list.getAssignment({
						...params,
						assignmentId: this.questionnaireAssignmentId,
					}).$promise
					: Promise.resolve(null),
			]);

			this.questionnaires = questionnaires;

			// need to create value for ng-select, since it does not support calc values
			this.cc = cc.map((entry) => {
				return {
					...entry,
					fullName: `${entry.firstName} ${entry.lastName}`,
				};
			});

			this.contacts = contacts.map((entry) => {
				return {
					...entry,
					fullName: `${entry.firstName} ${entry.lastName}`,
				};
			});

			if (this.questionnaireAssignmentId) {
				// filter out users not present in lists
				const mappedData: QuestionnaireAssignPayload = {
					...assignment,
					toContactIds: this._filterExistingIds(assignment.toContactIds, this.contacts, 'contactId'),
					ccRealmUserIds: this._filterExistingIds(assignment.ccRealmUserIds, this.cc, 'realmUserId'),
					notifyUponCompletionRealmUserIds: this._filterExistingIds(assignment.notifyUponCompletionRealmUserIds, this.cc, 'realmUserId'),
				};

				const data = {
					...mappedData,
					_notify_upon_assignment: mappedData.toContactIds?.length || mappedData.message || mappedData.ccRealmUserIds?.length,
					_notify_upon_completion: mappedData.notifyUponCompletionRealmUserIds?.length,
					_is_scheduled: mappedData.scheduledDate,
				};

				this.assignForm.patchValue(data);
			}
		} catch (e) {
			this.assignForm.setServerError({
				message: 'An error happened on data load. Please, try to reopen this modal',
			});
		} finally {
			this.resolved = true;
		}
	}

	onSave(): void {
		this.saving = true;

		const {
			questionnaireId,
			_notify_upon_assignment,
			toContactIds,
			ccRealmUserIds,
			message,
			_notify_upon_completion,
			notifyUponCompletionRealmUserIds,
			_is_scheduled,
			scheduledDate,
		} = this.assignForm.value;

		const params: QuestionnaireAssignPayload = {
			questionnaireId,
			message: _notify_upon_assignment ? message : undefined,
			toContactIds: _notify_upon_assignment ? toContactIds : undefined,
			ccRealmUserIds: _notify_upon_assignment ? ccRealmUserIds : undefined,
			notifyUponCompletionRealmUserIds: _notify_upon_completion ? notifyUponCompletionRealmUserIds : undefined,
			scheduledDate: _is_scheduled ? moment(scheduledDate).format('MM/DD/YYYY') : undefined,
			...this.getResourceParams(),
		};

		const method = this.questionnaireAssignmentId ? 'update' : 'assign';
		const additionalParams = this.questionnaireAssignmentId
			? {
				assignmentId: this.questionnaireAssignmentId,
				...this.getResourceParams(),
			}
			: null;

		this.questionnairesService.list[method](
			additionalParams,
			params,
			() => {
				this.modalRef.hide();
				this.reload();
			},
			({ data: response }) => {
				this.saving = false;
				this.assignForm.setServerError(response);
			},
		);
	}

	onCancel = (): void => {
		this.modalRef.hide();
	};

	get title(): string {
		if (!this.isQuestionnaireConfirmed) {
			return 'Assign Questionnaire';
		}

		if (this.questionnaire?.name) {
			return this.questionnaire?.name;
		}

		const selectedQuestionnaireId = this.assignForm.get('questionnaireId').value;
		if (!selectedQuestionnaireId || !this.questionnaires.$resolved) {
			return '';
		}

		const selectedQuestionnaire = find(this.questionnaires, (questionnaire) => {
			return questionnaire.id === parseInt(`${selectedQuestionnaireId}`, 10);
		});

		return selectedQuestionnaire?.name ?? '';
	}

	toDetailsStep(): void {
		if (this.isQuestionnaireConfirmed || !this.assignForm.get('questionnaireId').value) {
			return;
		}

		// open sections
		this.assignForm.get('_notify_upon_assignment').setValue(true);
		this.assignForm.get('_notify_upon_completion').setValue(true);
		// go to second step
		this.isQuestionnaireConfirmed = true;
	}

	getCharsCount = (): number => Math.max(0, (this.messageCharsAmount - (this.assignForm.value?.message?.length ?? 0)));
}
