import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';

declare let apiPath;

export type SmPackage = {
    invoicing: boolean;
    packageId: number;
    packageName: string;
}

@Injectable({ providedIn: 'root' })
export class CcmInvoicingSettingsService {

    constructor(
        protected http: RealmHttpClient,
    ) {
    }

    list<T = SmPackage[]>(organizationId: number): Observable<T> {
        return this.http.request<T>(
            'get',
            `${apiPath}/organizations/${organizationId}/billing/packages`,
        );
    }

    get<T = SmPackage>(organizationId: number): Observable<T> {
        return this.http.request<T>(
            'get',
            `${apiPath}/organizations/${organizationId}/billing/package`,
        );
    }

    update<T = SmPackage>(object: T, organizationId: number): Observable<T> {
        return this.http.request<T>(
            'put',
            `${apiPath}/organizations/${organizationId}/billing/package`,
            null,
            object,
        );
    }
}
