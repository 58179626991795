import { Component, forwardRef } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { ListComponent } from 'commons/components/list';

import { TaskListBaseComponent } from '../../common';
import { TasksService } from '../../tasks.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => PublicTaskListComponent) },
	],
})
export class PublicTaskListComponent extends TaskListBaseComponent {
	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		tasksService: TasksService,
	) {
		super(
			transitionService,
			stateService,
			tasksService,
		);
		this.defaultFilters.remediationStatus = [ 'Open', 'Reopened' ];
	}
}
