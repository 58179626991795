<div class="modal-content remote-resource" [class.resolved]="!resolving">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">Notify owners</h4>
	</div>
	<div class="modal-body">
		<p class="text-center text-bigger">
			Would you like to resend a verification email to provide credentials for all unverified owners?
		</p>
	</div>
	<div class="modal-footer">
		<div class="text-right">
			<a class="btn btn-lg btn-primary" (click)="verify()">Yes</a>
			<a class="btn btn-lg btn-default" (click)="modalRef.hide()">No</a>
		</div>
	</div>
</div><!-- /.modal-content -->
