import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { CampaignsModule } from '../campaigns';

// Services
import { TemplatesResourceService } from './templates-resource.service';
import { TemplatesService } from './templates.service';
import { PostsService } from '../posts/posts.service';
import { CampaignsResourceService } from '../campaigns';
import { SocialMediaRSSResourceService } from './content';

// Components
import {
	TemplatesListComponent,
	FolderTemplatesListComponent,
	TemplateEditComponent,
	TemplateViewComponent,
	TemplateUpdateModalComponent,
	FolderModalComponent,
	RemoveFolderModalComponent,
	GroupsModalComponent,
	MoveTemplateModalComponent,
	TemplateNetworkSelectComponent,
	TemplateNetworkListComponent,
	TemplateTextEditComponent,
} from './templates';
import { AttachmentsModule } from '../attachments';
import { PublisherPostPreviewModule } from '../posts/$postType/$postId/posts-preview/post-preview.module';

import {
	ContentListComponent,
	PublishedListComponent,
	LibraryContentManageFeedComponent,
} from './content';
import { LibraryMenuComponent } from 'tpo/social-compliance/publisher/library/library-menu.component';

const components = [
	TemplatesListComponent,
	FolderTemplatesListComponent,
	TemplateEditComponent,
	TemplateViewComponent,
	ContentListComponent,
	PublishedListComponent,
	LibraryContentManageFeedComponent,
	TemplateUpdateModalComponent,
	FolderModalComponent,
	RemoveFolderModalComponent,
	GroupsModalComponent,
	MoveTemplateModalComponent,
	TemplateNetworkSelectComponent,
	TemplateNetworkListComponent,
	TemplateTextEditComponent,
    LibraryMenuComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		PipesModule,
		AttachmentsModule,
		PublisherPostPreviewModule,
		CampaignsModule,
		BsDropdownModule.forRoot(),
	],
	declarations: [
		...components,
	],
	providers: [
		TemplatesResourceService,
		TemplatesService,
		PostsService,
		CampaignsResourceService,
		SocialMediaRSSResourceService,
	],
})
export class LibraryModule {}
