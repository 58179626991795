import { Component } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';

const requiredConditionValidator = (): ValidatorFn =>
	(formGroup: RealmFormGroup) => {
		const { controls } = formGroup;
		if (controls) {
			const selected = Object.keys(controls).find((key) => !!controls[key].value);
			if (!selected) {
				return {
					requiredConditionValidator: {
						message: 'At least one condition should be selected',
					},
				};
			}
		}
		return null;
	};

@Component({
	templateUrl: './request-document-modal.component.html',
})
export class RequestDocumentModalComponent extends ConfirmModalComponent {
	title = 'Assigned Document Request';
	message = 'Choose the conditions for this document request:';
	form = new RealmFormGroup(
		{
			assignedButNotWaived: new RealmFormControl(
				'assignedButNotWaived',
				{
					label: 'Assigned but not waived',
					updateOn: 'change',
				},
			),
			permanentlyWaived: new RealmFormControl(
				'permanentlyWaived',
				{
					label: 'Permanently waived',
					updateOn: 'change',
				},
			),
			oneTimeWaived: new RealmFormControl(
				'oneTimeWaived',
				{
					label: 'One-time waived',
					updateOn: 'change',
				},
			),
		},
		[
			requiredConditionValidator(),
		],
	);
	confirmText = 'Request';

	confirm(): void {
		const {
			assignedButNotWaived,
			permanentlyWaived,
			oneTimeWaived,
		} = this.form.value;
		this.onConfirm({
			assignedButNotWaived,
			permanentlyWaived,
			oneTimeWaived,
		});
	}
}
