<form [formGroup]="issuesForm" class="remote-resource issues-list" [class.resolved]="issues.$resolved && !resolving" (onValidSubmit)="submit()">

	<form-errors [form]="issuesForm"></form-errors>

	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<!-- div [className]="filterConfig.container" *ngFor="let filterConfig of filtersConfig">
				<ng-container *ngIf="filterConfig.filter; let filter">
					<div class="text-muted">{{filter.label}}</div>
				</ng-container>
			</div -->
			<div class="col-sm-12"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<!-- div [className]="filterConfig.container" *ngFor="let filterConfig of filtersConfig">
				<ng-container *ngIf="filterConfig.filter; let filter">
					<ng-select
						[multiple]=true
						(change)="setFilter(filter.name)($event)"
						[items]="filter.items.$promise | async"
						[loading]="!filter.items.$promise"
						[bindValue]="filter.bindValue"
						[bindLabel]="filter.bindLabel"
						[placeholder]="filter.placeholder"
					></ng-select>
				</ng-container>
			</div -->
			<div class="col-sm-12">
				<div class="pull-right">
					<ng-container *ngIf="editing; then editButtonsTpl; else viewButtonsTpl"></ng-container>
				</div>
			</div>
		</div>
	</div>

	<div class="rows-bordered list-holder">
		<div class="row-fluid header">
			<div class="col-sm-2">{{ groupColumnConfig.label }}</div>
			<div class="col-sm-10">Observations</div>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
		</div>

		<ng-container *ngIf="editing; then editContentTpl; else viewContentTpl"></ng-container>
		<ng-template #editContentTpl>
			<div class="row-fluid" *ngFor="let issue of issues; let issueIndex = index">
				<div class="col-sm-2 group-label">
					{{ issue[groupColumnConfig.fieldName] }}
					<div><a (click)="toggleSelectAll(issueIndex)">{{issuesAllSelected[issueIndex] ? 'Unselect' : 'Select'}} All</a></div>
				</div>
				<div class="col-sm-10">
					<select-checkboxes
						[control]="issuesForm.at(issueIndex)"
						[items]="issue.checklistObservations"
						bindValue="organizationChecklistId"
						bindLabel="observation"
						(check)="checkboxClick(issueIndex)"
					>
						<ng-template #additionalContentTemplate let-item="item">
							<show-regulations-icon
								[observationId]="item.observationId"
							></show-regulations-icon>
						</ng-template>
					</select-checkboxes>
				</div>
			</div>
		</ng-template>
		<ng-template #viewContentTpl>
			<div class="row-fluid" *ngFor="let issue of issues">
				<div class="col-sm-2 group-label">
					{{ issue[groupColumnConfig.fieldName] }}
				</div>
				<div class="col-sm-10">
					<checklist
						[items]="issue.checklistObservations"
						bindState="turnedOn"
						bindLabel="observation"
					>
						<ng-template #additionalContentTemplate let-item="item">
							<show-regulations-icon
								[observationId]="item.observationId"
							></show-regulations-icon>
						</ng-template>
					</checklist>
				</div>
			</div>
		</ng-template>

		<div class="row-fluid" *ngIf="issues.$resolved && !issues.length">
			<div class="col-sm-12 text-center text-muted text-bigger">
				{{filtersApplied || filters.q ? 'No matches found' : 'No data'}}
			</div>
		</div>
	</div>

	<br>

	<div class="pull-right">
		<ng-container *ngIf="editing; then editButtonsTpl"></ng-container>
	</div>
</form>

<ng-template #viewButtonsTpl>
	<button type="button" class="btn btn-primary" (click)="toggle(true)" *ngIf="User.can('TPO_SMC_MANAGE_CHECKLIST_SETTINGS')">
		<span class="glyphicon glyphicon-pencil"></span>
		Edit
	</button>
</ng-template>

<ng-template #editButtonsTpl>
	<button type="submit" class="btn btn-primary">Save</button>
	<button type="button" class="btn btn-secondary" (click)="toggle(false)">Cancel</button>
</ng-template>
