import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class TPOFindingsResourceService {
	public findingsBoard: any;
	public findingsBoardFilters: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const boardPath = `${apiPath}/tpos/:tpoId/social-media-compliance/findings-board`;
		const defaultParams = { tpoId: User.profile.organization.id };

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/sm45findings45board45resource/getFindingsBoardUsingGET
		this.findingsBoard = PagedResource(boardPath, defaultParams, {
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/sm45findings45board45resource
		this.findingsBoardFilters = $resource(boardPath, defaultParams, {
				accountTypes: {
					method: 'GET',
					isArray: true,
					url: `${boardPath}/account-types`,
				},
				entities: {
					method: 'GET',
					isArray: true,
					url: `${boardPath}/entities`,
				},
				statuses: {
					method: 'GET',
					isArray: true,
					url: `${boardPath}/statuses`,
				},
				openFindingsCount: {
					method: 'GET',
					url: `${boardPath}/open-findings-count`,
					transformResponse: (value) => {
						if (value) {
							return { value };
						}
					},
				},
				locations: {
					method: 'GET',
					isArray: true,
					url: `${boardPath}/locations`,
				},
			},
		);
	}
}
