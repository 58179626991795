<div class="rows-bordered">
    <div class="row-fluid header">
        <span class="col-sm-8">
            <b>{{ findingsMetricsSection.sectionTitle }}</b>
        </span>
        <span class="col-sm-a text-center">
            <b>Company</b>
        </span>
        <span class="col-sm-a text-center">
            <b>Individual</b>
        </span>
    </div>
    <scorecard-summary-findings-metrics-row *ngFor="let metrics of findingsMetricsSection.metrics"
        [tpoId]="tpoId"
        [category]="keyedScorecardFindingCategories[metrics.categoryCode]"
        [metrics]="metrics"
    >
    </scorecard-summary-findings-metrics-row>
</div>
