import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { ContactAuditLog } from './contact-audit-log.interface';

declare let apiPath: string;

@Injectable()
export class NewContactAuditLogService {

    constructor(
        private http: RealmHttpClient,
    ) {}

    getList = ({ contactId, ...params }): Observable<PagedData<ContactAuditLog[]>> =>
        this.http.pagedRequest<ContactAuditLog[]>(
            'GET',
            `${apiPath}/contacts/${contactId}/history`,
            params,
        );
}
