import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class MonitoringSettingsResourceService {
	company: any;
	individuals: any;
	settings: any;
	billing: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const monitoringPath = {
			url: `${apiPath}/tpos/:tpoId/social-media-monitoring/settings`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const individualsMonitoringPath = {
			url: `${monitoringPath.url}/individuals`,
			params: {
				...monitoringPath.params,
			},
		};
		const contactMonitoringPath = {
			url: `${individualsMonitoringPath.url}/:contactId`,
			params: {
				...individualsMonitoringPath.params,
				contactId: '@contactId',
			},
		};
		const contactMonitoringStatusPath = {
			url: `${contactMonitoringPath.url}/on-monitoring-status`,
			params: {
				...contactMonitoringPath.params,
			},
		};
		const contactAccountMonitoringStatusPath = {
			url: `${contactMonitoringPath.url}/links/:linkId/on-monitoring-status`,
			params: {
				...contactMonitoringPath.params,
				linkId: '@linkId',
			},
		};
		const monitoringBillingPath = {
			url: `${apiPath}/organizations/:organizationId/billing/social-media-monitoring`,
			params: {
				organizationId: user.profile.organization.organizationId,
			},
		};

		this.individuals = {
			list: PagedResource(
				individualsMonitoringPath.url,
				individualsMonitoringPath.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
					getEnabledCount: {
						url: `${individualsMonitoringPath.url}/monitoring-stats/on-monitoring-status`,
						method: 'GET',
						transformResponse: (value: number): any => {
							if (value !== null) {
								return { value };
							}
						},
					},
				},
			),
			contact: $resource(
				contactMonitoringPath.url,
				contactMonitoringPath.params,
				{
					getContactMonitoringStatus: {
						url: contactMonitoringStatusPath.url,
						method: 'GET',
					},
					updateContactMonitoringStatus: {
						url: contactMonitoringStatusPath.url,
						method: 'PUT',
					},
					getAccounts: {
						url: `${contactMonitoringPath.url}/accounts`,
						method: 'GET',
						isArray: true,
					},
					updateAccountMonitoringStatus: {
						url: contactAccountMonitoringStatusPath.url,
						params: contactAccountMonitoringStatusPath.params,
						method: 'PUT',
					},
				},
			),
			settings: $resource(
				`${individualsMonitoringPath.url}/meta-settings`,
				individualsMonitoringPath.params,
				{
					get: {
						method: 'GET',
					},
					update: {
						method: 'PUT',
					},
				},
			),
		};

		this.settings = $resource(
			monitoringPath.url,
			monitoringPath.params,
			{
				get: {
					method: 'GET',
				},
			},
		);

		this.billing = $resource(
			monitoringBillingPath.url,
			monitoringBillingPath.params,
			{
				get: {
					method: 'GET',
				},
			},
		);
	}
}
