<div>
    <h2 class="flex-row gap">
        <span>
            <ng-container *ngIf="packageDetails?.isSubscribed; else notSubscribed">
                <a href="{{ packageDetails?.accountManagementUrl }}" target="_blank">Manage Subscription</a>
            </ng-container>
            <ng-template #notSubscribed>
                {{ !packageDetails.canSubscribe ? 'Subscription' : 'Subscribe Now' }}
            </ng-template>
        </span>
        <span *ngIf="packageDetails?.hasPastDueInvoice" class="on-page-alert">
            <span class="icon-alert"></span>
            Payment Failed
        </span>
        <span *ngIf="packageDetails.isSubscribed" class="badge badge-success">
            Active
        </span>
        <div class="flex-grow text-right">
            <span *ngIf="!packageDetails.isSubscribed && packageDetails.canSubscribe">
                <a class="btn btn-lg btn-primary" uiSref=".payment">Subscribe Now</a>
            </span>
            <!--<request-demo-button *ngIf="resolved" [activePackage]="activePackage" [activePackageDetails]="packageDetails"></request-demo-button>-->
        </div>
    </h2>
</div>

<div class="flex-row border-blocks">
    <div class="flex-grow">
        <h2 class="flex-row gap">
            <span class="flex-grow">Social Media Marketing</span>
            <span *ngIf="packageDetails?.price as price">${{ price }}/Month</span>
        </h2>

<!--        <p><strong>Social Studio Includes:</strong></p>-->

        <ul class="marked">
            <li *ngFor="let item of marketingSection">{{item}}</li>
        </ul>
		<br>
		<br>
		<p>Note: no reporting license is required for Social Media Marketing reporting.</p>
    </div>
    <ng-container [ngSwitch]="!!packageDetails?.addonsPrices">
        <div class="flex-grow" *ngSwitchCase="true">
            <h2>Compliance Management System</h2>

			<p>{{complianceManagementSection1}}</p>
			<br>
			<ul class="marked">
				<li *ngFor="let item of complianceManagementSection2">{{item}}</li>
			</ul>

            <h3 class="flex-row gap">
                <span class="flex-grow">Reporting</span>
                <span *ngIf="packageDetails?.addonsPrices['reporting'] as price">${{ price }} per User</span>
            </h3>

            <p>You are only charged at the end of each billing period for users you grant a reporting license.</p>

            <h3 class="flex-row gap">
                <span class="flex-grow">Compliance Audit</span>
                <span *ngIf="packageDetails?.addonsPrices['compliance-audits'] as price">${{ price }} per Audit</span>
            </h3>

            <p>You can automate your audit schedule to launch multiple audits per order. When creating your audit schedule, you will see the estimated cost per order.</p>

           <!-- <div class="text-center">
                <request-demo-button *ngIf="resolved" [activePackage]="activePackage" [activePackageDetails]="packageDetails"></request-demo-button>
            </div>-->
        </div>

        <div class="flex-grow" *ngSwitchDefault>
            <p>Leverage all the power of social media with the only social media solution designed from the ground up specifically for mortgage originators.</p>

            <ul class="marked">
                <li>Increase your leads from local search (SEO)</li>
                <li>Build your brand online and manage your digital reputation</li>
                <li>Increase operational efficiency and compliance with a single solution</li>
                <li>Amplify your reach by leveraging curated content or access your content library and distribute to your workforce</li>
                <li>Set up and schedule campaigns that make it easy to set it and forget it</li>
                <li>Keep track of your digital inventory and be audit ready, any moment, any branch, any individual!</li>
            </ul>

            <br>

            <!--<div class="text-center">
                <request-demo-button *ngIf="resolved" [activePackage]="activePackage" [activePackageDetails]="packageDetails"></request-demo-button>
            </div>-->
        </div>
    </ng-container>

</div>
