<div class="remote-resource" [class.resolved]="resolved">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
        <h4 class="modal-title">Authorization Request <ng-container *ngIf="request"> - {{ request.requestorInvestorName }}</ng-container></h4>
    </div>
    <div class="modal-body">
        <div class="well">
            <div>If you accept, clients will be added to the requested channel.</div>
            <div>If you decline, no action will be taken.</div>
        </div>
        <form [formGroup]="form">
            <div>
                <p class="flex-row">
                    <b>Investor Invite:</b>
                </p>
                {{ request?.message }}
            </div>
            <br><br>
            <label class="checkbox-inline">
                <input type="checkbox" formControlName="check"/>
                By checking this box, you are authorizing to add the shared clients to your channel
            </label>
        </form>
    </div>
    <div class="modal-footer text-right">
        <button class="btn btn-primary" [disabled]="this.form.invalid" (click)="finalize(true)">Accept</button>
        <button class="btn btn-danger-wired" (click)="finalize(false)">Decline</button>
    </div>
</div>
