import {
    Directive,
    ContentChild,
    AfterContentInit,
    HostBinding,
    AfterContentChecked, OnDestroy, ChangeDetectorRef,
} from '@angular/core';
import { FormControlName, FormControlDirective } from '@angular/forms';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RealmFormControl } from 'commons/forms';

@Directive({
    selector: '[has-error].form-group',
})
export class HasErrorDirective implements AfterContentInit, AfterContentChecked, OnDestroy {
    @ContentChild(FormControlDirective, { static: false }) formControl: FormControlDirective;
    @ContentChild(FormControlName, { static: false }) formControlName: FormControlName;
    @HostBinding('class.has-error') hasError: boolean = false;
    private old: { formControl: FormControlDirective, formControlName: FormControlName } = {
        formControl: null,
        formControlName: null,
    };
    private subscription: Subscription;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngAfterContentInit(): void {
        this.initHandler();
    }

    ngAfterContentChecked() {
        const { formControl, formControlName, old } = this;
        if (old.formControl !== formControl || old.formControlName !== formControlName) {
            this.initHandler();
            old.formControl = formControl;
            old.formControlName = formControlName;
        }
    }

    initHandler() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }

        const { control } = (this.formControl || this.formControlName || { control: null }) as { control: RealmFormControl };

        if (control) {
            this.hasError = control.invalid && control.touched;
            this.subscription = control.statusChanges.pipe(
                delay(1),
            ).subscribe((status) => {
                this.hasError = control.invalid && control.touched;
                this.cd.detectChanges();
            });
        } else {
            this.hasError = false;
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }


}
