import { Component } from '@angular/core';
import { CommonPostComponent } from 'tpo/social-compliance/publisher/posts/$postType/$postId/posts-preview/common-post.component';

@Component({
	selector: 'linkedin-post',
	templateUrl: './linkedin-post.component.html',
})
export class LinkedInPostComponent extends CommonPostComponent {
	regLink = /(?:(?:(?:https?:)\/\/)|(?:www\.))((?:(?:(?:[@A-Za-z0-9]+)\.)+)(?:(?:[A-Za-z]+)\b)(?:(?:\/\S*)*))|((?:(?:(?:[@A-Za-z0-9]+)\.(?:com|net|org)(?:\.(?![a-z])|(?!\.)))+))/g;
}
