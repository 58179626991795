import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { ScorecardService } from './services/scorecard.service';
import { ScorecardFindingService } from './services/scorecard-finding.service';
import { ScorecardFindingFormService } from './services/scorecard-finding-form.service';
import { ScorecardMetricsAnnotatorService } from './services/scorecard-metrics-annotator.service';
import { ScorecardUtilityService } from './services/scorecard-utility.service';

import { ScorecardSummaryComponent } from './scorecard-summary/scorecard-summary.component';
import { ScorecardSummaryOwnersSectionComponent } from './scorecard-summary/owners-section/scorecard-summary-owners-section.component';
import { ScorecardSummaryOwnerRowComponent } from './scorecard-summary/owners-section/owners-row/scorecard-summary-owners-row.component';
import { ScorecardSummaryOwnerDefaultTextComponent } from './scorecard-summary/owners-section/default-text/scorecard-summary-owners-default-text.component';
import { ScorecardSummaryFindingsMetricsSectionComponent } from './scorecard-summary/findings-metrics-section/scorecard-summary-findings-metrics-section.component';
import { ScorecardSummaryFindingsMetricsRowComponent } from './scorecard-summary/findings-metrics-section/finding-metrics-row/scorecard-summary-findings-metrics-row.component';
import { ScorecardSummaryFindingsMetricsCountComponent } from './scorecard-summary/findings-metrics-section/finding-metrics-row/metrics-count/scorecard-summary-findings-metrics-count.component';
import {
    ScorecardCommentsListComponent,
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments-list.component';
import {
    ScorecardCommentsFormComponent,
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments-form/scorecard-comments-form.component';
import { ScorecardSummaryOwnerComponent } from 'shared/due-diligence/scorecard/scorecard-summary-owners/scorecard-summary-owner.component';
import {
    ScorecardReviewsHistoryListComponent,
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-review-history/scorecard-review-history-list.component';

import { ScorecardSupportingDocumentsComponent } from './scorecard-supporting-documents/scorecard-supporting-documents.component';
import { ScorecardAddNewFindingComponent } from 'shared/due-diligence/scorecard/scorecard-add-findings/scorecard-add-new-finding.component';

import { ScorecardReviewsTableComponent } from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-reviews-table.component';
import { ScorecardReviewItemComponent } from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-review-items/scorecard-review-item.component';
import { ScorecardFindingsSummaryComponent } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-findings-summary.component';
import { ScorecardFindingsSummaryHeaderComponent } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-findings-summary-header.component';
import { ScorecardFindingSummaryMarkReviewedComponent } from './mark-reviewed-button/scorecard-finding-summary-mark-reviewed.component';
import { ScorecardFindingComponent } from './scorecard-finding/scorecard-finding.component';
import { ScorecardFindingDetailsHeaderComponent } from './scorecard-finding/details-header/scorecard-finding-details-header.component';
import { ScorecardFindingDetailsViewEditComponent } from './scorecard-finding/details-view-edit/scorecard-finding-details-view-edit.component';
import { ScorecardFindingDetailsComponent } from './scorecard-finding/details-view-edit/details/scorecard-finding-details.component';
import { ScorecardFindingDetailRowComponent } from './scorecard-finding/details-view-edit/details/detail-row/scorecard-finding-detail-row.component';
import { ScorecardFindingDetailsSupportingDocumentsComponent } from './scorecard-finding/details-view-edit/supporting-documents/scorecard-finding-details-supporting-documents.component';
import { ScorecardFindingDetailsExternalDocumentLinkComponent } from './scorecard-finding/details-view-edit/supporting-documents/external-document-link/scorecard-finding-details-external-document-link.component';
import {
    ScorecardFindingDetailsCommentsFormComponent,
} from 'shared/due-diligence/scorecard/scorecard-finding/details-comments/comments-form/scorecard-finding-details-comments-form.component';
import {
    ScorecardFindingDetailsCommentsComponent,
} from 'shared/due-diligence/scorecard/scorecard-finding/details-comments/scorecard-finding-details-comments.component';
import {
    ScorecardFindingHistoryComponent,
} from 'shared/due-diligence/scorecard/scorecard-finding-history/scorecard-finding-history.component';
import {
    FindingAlertStatusModalComponent,
} from 'shared/due-diligence/scorecard/scorecard-findings-summary/finding-alert-status-modal/finding-alert-status-modal.component';
import {
    ScorecardMarkReviewedService,
} from 'shared/due-diligence/scorecard/services/scorecard-mark-reviewed-service';
import {
    ScorecardHeaderComponent,
} from 'shared/due-diligence/scorecard/scorecard-header/scorecard-header.component';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		ReactiveFormsModule,
	],
	providers: [
		ScorecardService,
		ScorecardFindingService,
		ScorecardFindingFormService,
		ScorecardMetricsAnnotatorService,
		ScorecardUtilityService,
        ScorecardMarkReviewedService,
	],
	declarations: [
		ScorecardSummaryComponent,
		ScorecardSummaryOwnersSectionComponent,
		ScorecardSummaryOwnerRowComponent,
		ScorecardSummaryOwnerDefaultTextComponent,
		ScorecardSummaryFindingsMetricsSectionComponent,
		ScorecardSummaryFindingsMetricsRowComponent,
		ScorecardSummaryFindingsMetricsCountComponent,
        ScorecardReviewsTableComponent,
        ScorecardReviewItemComponent,
        ScorecardReviewsHistoryListComponent,
        ScorecardCommentsListComponent,
        ScorecardCommentsFormComponent,
        ScorecardSummaryOwnerComponent,
		ScorecardSupportingDocumentsComponent,
        ScorecardAddNewFindingComponent,
        ScorecardFindingsSummaryComponent,
        ScorecardFindingsSummaryHeaderComponent,
        ScorecardFindingSummaryMarkReviewedComponent,
		ScorecardFindingComponent,
		ScorecardFindingDetailsHeaderComponent,
		ScorecardFindingDetailsViewEditComponent,
		ScorecardFindingDetailsComponent,
		ScorecardFindingDetailRowComponent,
		ScorecardFindingDetailsSupportingDocumentsComponent,
		ScorecardFindingDetailsExternalDocumentLinkComponent,
        ScorecardFindingDetailsCommentsComponent,
        ScorecardFindingDetailsCommentsFormComponent,
        ScorecardFindingHistoryComponent,
        FindingAlertStatusModalComponent,
        ScorecardHeaderComponent,
	],
})
export class ScorecardModule {}
