import { Component, OnInit } from '@angular/core';
import { CcmDataMatchReport } from 'comergence/data-match/ccm-data-match.interface';
import { CcmDataMatchService } from 'comergence/data-match/ccm-data-match.service';
import { UIRouterGlobals } from '@uirouter/core';
import { statusConfig } from '../statusConfig';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';


@Component({
    templateUrl:'ccm-data-match-report-view-edit.component.html',
})
export class CcmDataMatchReportViewEditComponent implements OnInit {
    resolving: boolean = false;
    isEditing: boolean;
    dataMatchReport: CcmDataMatchReport;
    reportId: number;
    statusConfig = statusConfig;
    isLoaded: boolean = false;
    isApplicable: boolean;
    commentForm = new RealmFormGroup({
        comment: new RealmFormControl(
            'comment',
            {
                label: 'Comments',
            },
        ),
    });

    constructor(
        private ccmDataMatchService: CcmDataMatchService,
        private routerGlobals: UIRouterGlobals,
    ){}

    ngOnInit() {
        this.reportId = this.routerGlobals.params.id;
        this.getReportView();
    }

    async getReportView() {
        try {
            this.resolving = true;
            const dataMatchReportView = await this.ccmDataMatchService.getDataMatchReport(this.reportId).toPromise();
            this.checkPreCondition(dataMatchReportView);
            this.dataMatchReport = dataMatchReportView;
        }
        catch(e) {

        }
        finally {
            this.resolving = false;
        }
    }

    downloadFile(id: number) {
        window.open(`/api/rest/datamatch/${id}/inputfile`, '_blank' );
    }

    async updateComment() {
        const dataMatchUpdateComment: CcmDataMatchReport = {...this.dataMatchReport};
        dataMatchUpdateComment.comment = this.commentForm.get('comment').value;

        try {
            this.resolving = true;
            const dataMatchUpdate: CcmDataMatchReport = await this.ccmDataMatchService.updateReportComment(this.reportId, dataMatchUpdateComment).toPromise();
            this.checkPreCondition(dataMatchUpdate);
            this.dataMatchReport = dataMatchUpdate;
        }
        catch (e) {
            this.commentForm.setServerError(e);
        }
        finally {
            this.resolving = false;
            this.isEditing = false;
        }
    }

    cancelEdit() {
        this.isEditing = false;
        this.commentForm.reset();
    }

    setEditing(value: boolean) {
        this.commentForm.patchValue(this.dataMatchReport);
        this.isEditing = value;
    }

    reverifyDataMatch(dataMatchReverify: CcmDataMatchReport) {
        this.checkPreCondition(dataMatchReverify);
        this.dataMatchReport = dataMatchReverify;
    }

    reload(value: boolean) {
        this.resolving = value;
    }

    checkPreCondition(dataMatchReportView: CcmDataMatchReport) {
        this.isLoaded = dataMatchReportView.status !== 'Loaded';
        this.isApplicable = !!dataMatchReportView.lender && !!dataMatchReportView.channel;
    }

    relationshipStatusRestored(dataMatchRestoredRelation: CcmDataMatchReport) {
        this.checkPreCondition(dataMatchRestoredRelation);
        this.dataMatchReport = dataMatchRestoredRelation;
    }
}
