import { Pipe, PipeTransform } from '@angular/core';

export interface TextToUrlPipeConfig {
	regex: RegExp;
	displayProtocol: boolean;
}

const regLinkDefault = /(?:(?:(?:https?:)\/\/)?(www\.)?)((?:(?:(?:[@A-Za-z0-9\-]+)\.)+)(?:(?:[A-Za-z]+)\b)(?:(?:\/\S*)*))/g;

// const DEFAULT_CONFIG: TextToUrlPipeConfig = {
// 	regex: /(?:(?:(?:https?:)\/\/)?(www\.)?)((?:(?:(?:[@A-Za-z0-9\-]+)\.)+)(?:(?:[A-Za-z]+)\b)(?:(?:\/\S*)*))/g,
// 	displayProtocol: true,
// };

@Pipe({
	name: 'textToUrl',
})
export class TextToUrlPipe implements PipeTransform {
	transform(
		str: string,
		config: {
			regex?: any,
			displayOnlyLink?: boolean,
			displayProtocol?: boolean,
			includeEmails?: boolean,
		} = {}): string {

		const {
			regex = regLinkDefault,
			displayOnlyLink = true,
			displayProtocol = true,
			includeEmails = false,
		} = config;

		if (!str) { return ''; }
		const matches = str.match(regex);
		if (!displayOnlyLink && matches && matches[0].length === str.length) {
			return '';
		} else {
			return str.replace(regex, (match, www, domain) => {
				const isEmailMatched = `${match}`.indexOf('@') > -1;

				if (isEmailMatched && !includeEmails) {
					// return plain text if email flag is not set
					return match;
				}

				if (isEmailMatched && includeEmails) {
					return `<a href="mailto:${match}" target="_blank">${match}</a>`;
				}

				const link = displayProtocol ? match : domain;
				return `<a href="//${www ?? ''}${domain ?? ''}" target="_blank">${link}</a>`;
			});
		}
	}
}
