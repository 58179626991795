import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ScorecardSummaryOwner } from 'shared/due-diligence/scorecard/scorecard-summary-owners/scorecard.interface';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { FINDING_TYPES, FindingType } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-finding-summary.interface';
import { UserService } from 'angularjs-providers/user.provider';
import { ScorecardMarkReviewedService } from 'shared/due-diligence/scorecard/services/scorecard-mark-reviewed-service';


@Component({
    templateUrl: 'scorecard-findings-summary.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardFindingsSummaryComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardFindingsSummaryComponent) },
    ],
})
export class ScorecardFindingsSummaryComponent extends NewPagedListComponent<ScorecardSummaryOwner> implements OnInit {
    static listName = 'scorecardFindingsSummaryComponent';
    readonly findingTypeList: FindingType[] = FINDING_TYPES;

    tpoId: number;
    findingId: string;
    isUserCommergence: boolean;
    hasReviewFindingPermission: boolean;
    sref: string;
    isUserLender: boolean;
    lenderId: number;
    hasEditAndViewAlertPermission: boolean;

    markingFindingAsReviewed: boolean = false;

    constructor(
        router: UIRouter,
        private readonly scorecardService: ScorecardService,
        private readonly scorecardMarkReviewedService: ScorecardMarkReviewedService,
        private readonly userService: UserService,
    ) {
        super(router);
    }

    ngOnInit(): void {
        this.defaultParams.findingFor = 'T';
        super.ngOnInit();
        this.isUserCommergence = this.userService.profile.isComergence;
        this.hasReviewFindingPermission = this.userService.profile.can('REVIEW_FINDINGS');
        this.lenderId = this.userService.profile.organization.id;
        this.isUserLender = this.userService.profile.isLender;
        this.hasEditAndViewAlertPermission = this.scorecardMarkReviewedService.checkAlertPermission(this.userService);

        if (this.isUserCommergence) {
            this.sref = 'tpo.:id.scorecard.finding.:sType.:sid';
        } else {
            this.sref = 'client.:id.reviews.scorecard.finding.:sType.:sid'
        }

        this.tpoId = this.router.globals.params.id;
        this.findingId = this.router.globals.params.sType;
    }

    loadList(params: ListParams): Observable<PagedData<ScorecardSummaryOwner[]>> {
        return this.scorecardService.getFindingsSummary(this.tpoId, params, this.findingId);
    }

    changeFindingForType(newType: FindingType) : void {
        this.setFilter({findingFor: newType.key});
        this.resetSearch();
    }

    reloadList() {
        this.updateList();
    }

    setMarkingReviewed(value: boolean) {
        this.markingFindingAsReviewed = value;
    }
}
