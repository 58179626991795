import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

const EditQuestionnaireForm = new RealmFormGroup({
	name: new RealmFormControl(
		'name',
		{ label: 'Questionnaire Name' },
		Validators.required,
	),
	description: new RealmFormControl(
		'name',
		{ label: 'Questionnaire Description' },
	),
});

export { EditQuestionnaireForm };
