import { Injectable } from '@angular/core';
import {
    ChannelAlertsGrouped,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.interface';

@Injectable()
export class AlertsSortingService {

    private orderOfOwnershipFields: string[] = [
        'Owner Added',
        'Ownership Change',
        'Owner Removed',
    ];

    private orderOfCompanyRecords: string[] = [
        'Affiliate Added',
        'Affiliate Changes',
        'Bankruptcy',
        'CFPB',
        'Federal Criminal and Civil',
        'FDIC',
        'FHFA',
        'HUD LDP',
        'Judgement and Liens',
        'Mortgage and Financial Sanctions',
        'NCUA',
        'OCC',
        'OFAC',
        'SAM',
        'Secretary of State',
    ];

    private orderOfCompanyNMLS: string[] = [
        'Branch Address Change',
        'Branch License Not Authorized',
        'Branch License Authorized',
        'Company Address Change',
        'Company Name Change',
        'License Authorized',
        'License Not Authorized',
        'Trade Name Added (Branch)',
        'Trade Name Added (Company)',
    ]

    private orderOfIndividualRecords: string[] = [
        'Bankruptcy',
        'CFPB',
        'Federal Criminal and Civil',
        'FDIC',
        'FHFA',
        'HUD LDP',
        'Individual Address Change',
        'Individual Name Change',
        'Individual Sponsor Change',
        'Judgement and Liens',
        'License Authorized',
        'License Not Authorized',
        'Mortgage and Financial Sanctions',
        'NCUA',
        'OCC',
        'OFAC',
        'SAM',
        'Foreclosure',
        'State and Local Criminal',
    ]

    constructor() {}

    public orderAlertGroup(alertGroup: ChannelAlertsGrouped) {
        if(alertGroup.groupName == 'Individual Records') {
            return this.orderIndividualRecords(alertGroup);
        } else if(alertGroup.groupName == 'Company Records') {
            return this.orderCompanyRecords(alertGroup);
        } else if(alertGroup.groupName == 'Ownership') {
            return this.orderOwnership(alertGroup);
        } else if(alertGroup.groupName == 'Company NMLS') {
            return this.orderCompanyNMLS(alertGroup);
        }
    }

    private orderIndividualRecords(alertGroup: ChannelAlertsGrouped): ChannelAlertsGrouped {
        return {
            groupName: alertGroup.groupName,
            alerts: alertGroup.alerts.sort( (a,b) => {
                const indexOfA = this.orderOfIndividualRecords.indexOf(a.alertCategory);
                const indexOfB = this.orderOfIndividualRecords.indexOf(b.alertCategory);
                if(indexOfA == indexOfB) {
                    return 0;
                } else if(indexOfA > indexOfB) {
                    return 1;
                } else return -1;
            }),
        };
    }

    private orderCompanyRecords(alertGroup: ChannelAlertsGrouped): ChannelAlertsGrouped {
        return {
            groupName: alertGroup.groupName,
            alerts: alertGroup.alerts.sort( (a,b) => {
                const indexOfA = this.orderOfCompanyRecords.indexOf(a.alertCategory);
                const indexOfB = this.orderOfCompanyRecords.indexOf(b.alertCategory);
                if(indexOfA == indexOfB) {
                    return 0;
                } else if(indexOfA > indexOfB) {
                    return 1;
                } else return -1;
            }),
        };
    }

    private orderOwnership(alertGroup: ChannelAlertsGrouped) {
        return {
            groupName: alertGroup.groupName,
            alerts: alertGroup.alerts.sort( (a,b) => {
                const indexOfA = this.orderOfOwnershipFields.indexOf(a.alertCategory);
                const indexOfB = this.orderOfOwnershipFields.indexOf(b.alertCategory);
                if(indexOfA == indexOfB) {
                    return 0;
                } else if(indexOfA > indexOfB) {
                    return 1;
                } else return -1;
            }),
        };
    }

    private orderCompanyNMLS(alertGroup: ChannelAlertsGrouped): ChannelAlertsGrouped {
        return {
            groupName: alertGroup.groupName,
            alerts: alertGroup.alerts.sort( (a,b) => {
                const indexOfA = this.orderOfCompanyNMLS.indexOf(a.alertCategory);
                const indexOfB = this.orderOfCompanyNMLS.indexOf(b.alertCategory);
                if(indexOfA == indexOfB) {
                    return 0;
                } else if(indexOfA > indexOfB) {
                    return 1;
                } else return -1;
            }),
        };
    }
}
