<h2>{{channel?.name || '&nbsp;'}}</h2>
<div class="well warning">
    You are about to send an invitation to this client.
    The client must accept your invitation in order to create an application.
    If your organization pays for the application fee, you will not be charged until the client accepts your invitation.
    Please note, only users who can manage applications will be available in your contact list to invite.
</div>
<br>
<form [formGroup]="form" (onValidSubmit)="save()">
    <form-errors [form]="form"></form-errors>

    <div class="rows-white">
        <div class="row-fluid form-group" has-error>
            <div class="col-sm-2">
                <label class="control-label required">Contact Name</label>
            </div>
            <div class="col-sm-3">
                <ng-select
                    [items]="inviteContacts"
                    [loading]="!inviteContacts"
                    formControlName="contactId"
                    bindLabel="fullName"
                    bindValue="id"
                    [clearable]="false"
                    placeholder="Choose contact"
                    tabindex=1
                    required>
                </ng-select>
            </div>
            <div class="col-sm-7"></div>
        </div>

        <div class="row-fluid form-group" has-error>
            <div class="col-sm-2">
                <label class="control-label required">Assigned</label>
            </div>
            <div class="col-sm-3">
                <ng-select
                    [items]="assignees"
                    [loading]="!assignees"
                    formControlName="accountExecutiveId"
                    bindLabel="fullName"
                    bindValue="id"
                    [clearable]="false"
                    placeholder="Select Assigned"
                    tabindex="2"
                    required>
                </ng-select>
            </div>
            <div class="col-sm-7"></div>
        </div>

        <div class="row-fluid form-group" has-error>
            <div class="col-sm-2">
                <label class="control-label required">Message</label>
            </div>
            <div class="col-sm-10">
                <textarea class="resize-vertical form-control" formControlName="message" [maxlength]="maxChars" tabindex="3" rows="6"></textarea>
                <div class="text-smaller text-muted ng-binding">Characters left: {{ charsLeft }}</div>
            </div>
        </div>

        <div class="flex-row">
            <div class="flex-grow"></div>
            <div>
                <button class="btn btn-primary" type="submit">Send Invitation</button>
                <a class="btn btn-default" uiSref="^.^">Cancel</a>
            </div>
        </div>

    </div>
</form>
