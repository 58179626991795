import { Component, Input } from '@angular/core';

import { DirectionalInvestorShareRequest } from 'commons/interfaces/investor-share-requests.type';
import { InvestorChannelSharingTableActionButtonConfig } from 'lender/channel-shares/investor-channel-sharing-table-config.type';


@Component({
	selector: 'investor-channel-sharing-table-action-button',
	templateUrl: './investor-channel-sharing-table-action-button.component.html',
})
export class InvestorChannelSharingTableActionButtonComponent {
    @Input() actionButtonConfig: InvestorChannelSharingTableActionButtonConfig;
	@Input() shareRequest: DirectionalInvestorShareRequest;

	get style(): string {
		return this.actionButtonConfig?.style(this.shareRequest);
	}

	get text(): string {
		return this.actionButtonConfig?.text(this.shareRequest);
	}

	clicked(): void {
		this.actionButtonConfig?.clicked(this.shareRequest);
	}
}
