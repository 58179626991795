import { RecurlyService } from './abstract-recurly-service.class';

declare const recurly: any;

export const RecurlyProvider = {
	provide: RecurlyService,
	useFactory: () => {
		return recurly;
	},
	deps: [],
};
