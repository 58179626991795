import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator, MaxLengthValidator } from 'commons/validators';

import {
	ListingGroup,
	ListingGroupListing,
	ListingGroupNmlsLocation,
} from '../../../../../company-listings.service';

@Component({
	templateUrl: './move-listing-modal.component.html',
})
export class MoveListingModalComponent implements OnInit, OnDestroy {
	createMode: boolean;
	form: RealmFormGroup;
	listing: ListingGroupListing | ListingGroupNmlsLocation;
	listingGroupsList: any;
	move: (listingGroup: ListingGroup) => Promise<void>;
	create: (listingGroup: ListingGroup) => Promise<void>;
	resolved = true;
	unsubscribe$ = new Subject<void>();

	get title(): string {
		return this.createMode ? 'Create New Group' : 'Move to Group';
	}

	get listingGroupControl() {
		return this.form.get('listingGroup');
	}

	get nameControl() {
		return this.form.get('name');
	}

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit(): void {
		this.initForm();
		this.toggleCreateMode(false);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	initForm(): void {
		this.form = new RealmFormGroup({
			listingGroup: new RealmFormControl(
				'listingGroup',
				{
					label: 'Group',
					updateOn: 'change',
				},
				[
					DropdownRequiredValidator,
				],
			),
			name: new RealmFormControl(
				'name',
				{
					label: 'Name',
					updateOn: 'change',
				},
				[
					Validators.required,
					MaxLengthValidator(300),
				],
			),
		});

		this.nameControl.valueChanges
			.pipe(
				filter(() => this.nameControl.enabled),
				takeUntil(this.unsubscribe$),
			)
			.subscribe((name) => {
				this.listingGroupControl.setValue(
					{
						...this.listingGroupControl.value,
						name,
					},
					{ emitEvent: false },
				);
			});
	}

	toggleCreateMode(
		createMode = !this.createMode,
	): void {
		if (createMode) {
			const isNmls = !!(this.listing as ListingGroupNmlsLocation).locationNmlsId;
			let listingGroup: Partial<ListingGroup>;

			if (isNmls) {
				const {
					locationName: name,
					address,
					city,
					state,
					zip,
				} = this.listing as ListingGroupNmlsLocation;

				listingGroup = {
					name,
					address,
					city,
					state,
					zip,
					phoneNumber: null,
					website: null,
					coverImage: null,
				};
			} else {
				const {
					name,
					businessName,
					address,
					city,
					state,
					zip,
					phoneNumber,
					website,
					description,
					coverImage,
				} = this.listing as ListingGroupListing;

				listingGroup = {
					name,
					businessName,
					address,
					city,
					state,
					zip,
					phoneNumber,
					website,
					description,
					coverImage,
				};
			}

			const { name, businessName } = listingGroup;

			this.nameControl.enable({ emitEvent: false });
			this.form.reset({
				listingGroup,
				name: name || businessName,
			});
		} else {
			this.nameControl.disable({ emitEvent: false });
			this.form.reset();
		}

		this.createMode = createMode;
	}

	search(
		value: string,
		item: ListingGroup,
	): boolean {
		const regexp = new RegExp(value, 'i');
		return (
			regexp.test(item.businessName) ||
			regexp.test(item.name) ||
			regexp.test(item.address) ||
			regexp.test(item.city) ||
			regexp.test(item.state) ||
			regexp.test(item.zip)
		);
	}

	submit(): void {
		const { listingGroup } = this.form.value;

		this.resolved = false;
		(this.createMode ? this.create : this.move)(listingGroup)
			.then(() => {
				this.modalRef.hide();
			})
			.finally(() => {
				this.resolved = true;
			});
	}

	close(): void {
		this.modalRef.hide();
	}
}
