import {isFunction, isUndefined} from 'lodash';
import {Component, Input} from '@angular/core';

@Component({
	templateUrl: './permission.component.html',
	selector: 'permission',
})

export class PermissionComponent {
	@Input() map: any = {};
	@Input() editing: boolean;
	@Input() permissionConfig: any = {};

	visible: boolean;
	disabled: boolean;

	ngOnInit() {
		this.visible = this.getVisibleFlag(this.permissionConfig.visible);
		this.disabled = this.getDisableFlag(this.permissionConfig.disabled);
	}

	togglePermission() {
		const newVal = this.map[this.permissionConfig.key];

		// check flags for dependant fields
		if (newVal && this.permissionConfig.dependsOn) {
			this.permissionConfig.dependsOn.forEach((name) => (this.map[name] = newVal));
		}

		// uncheck flags if required field unchecked
		// NOTICE: requiredFor is calculated field, check in permission-section.component
		if (!newVal && this.permissionConfig.requiredFor && this.permissionConfig.requiredFor.length) {
			this.permissionConfig.requiredFor.forEach((name) => (this.map[name] = newVal));
		}
	}

	public getVisibleFlag(visibility) {
		// if visiblity is not set -> show
		if (isUndefined(visibility)) {
			return true;
		}

		if (isFunction(visibility)) {
			return visibility();
		}

		return !!visibility;
	}

	public getDisableFlag(disabled) {
		// if visiblity is not set -> show
		if (isUndefined(disabled)) {
			return false;
		}

		if (isFunction(disabled)) {
			return disabled();
		}

		return !!disabled;
	}
}
