<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">License Information</h4>
</div>
<div class="modal-body">
	<div class="rows-striped">
		<div class="row-fluid">
			<div class="col-md-7">
				<div class="form-group row">
					<label class="col-sm-5 control-label text-right">License Number</label>
					<div class="col-sm-7 control-value">{{license.licenseNumber || '&ndash;'}}</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-6 control-label text-right">{{license.licenseClass == 'COMPANY' ? 'Company' : 'Individual'}} NMLS ID</label>
					<div class="col-sm-6 control-value">{{license.licenseClass == 'COMPANY' ? license.companyNmlsId || '&ndash;' : license.individualNmlsId || '&ndash;'}}</div>
				</div>
			</div>
		</div>
		<div class="row-fluid">
			<div class="col-md-7">
				<div class="form-group row">
					<label class="col-sm-5 control-label text-right">License Type</label>
					<div class="col-sm-7 control-value">{{license.licenseType}}</div>
				</div>
			</div>
			<div class="col-md-5">
				<ng-container [ngSwitch]="license.licenseClass">
					<div class="form-group row" *ngSwitchCase="'COMPANY'">
						<label class="col-sm-6 control-label text-right">Branch NMLS ID</label>
						<div class="col-sm-6 control-value">{{license.branchNmlsId || '&ndash;'}}</div>
					</div>
					<div class="form-group row" *ngSwitchCase="'INDIVIDUAL'">
						<label class="col-sm-6 control-label text-right">Individual Name</label>
						<div class="col-sm-6 control-value">{{license.name || '&ndash;'}}</div>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="row-fluid">
			<div class="col-md-7">
				<div class="form-group row">
					<label class="col-sm-5 control-label text-right">License State</label>
					<div class="col-sm-7 control-value">{{license.regulator || '&ndash;'}}</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-6 control-label text-right">Issue Date</label>
					<div class="col-sm-6 control-value">{{license.issueDate || '&ndash;'}}</div>
				</div>
			</div>
		</div>
		<div class="row-fluid">
			<div class="col-md-7">
				<div class="form-group row">
					<label class="col-sm-5 control-label text-right">Status</label>
					<div class="col-sm-7 control-value">
						{{license.status}}
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-6 control-label text-right">Status Date</label>
					<div class="col-sm-6 control-value">{{license.statusDate || '&ndash;'}}</div>
				</div>
			</div>
		</div>
		<div class="row-fluid">
			<div class="col-md-7">
				<div class="form-group row">
					<label class="col-sm-5 control-label text-right">Authorization</label>
					<div class="col-sm-7 control-value">
						<span class="glyphicon" [ngClass]="license.isAuthorized ? 'text-success glyphicon-ok-sign' : 'text-danger glyphicon-remove'"></span>
						{{license.isAuthorized ? '' : 'Not'}} Authorized
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-6 control-label text-right">Renewed Through</label>
					<div class="col-sm-6 control-value">{{license.renewedThrough || '&ndash;'}}</div>
				</div>
			</div>
		</div>
	</div>
</div>
