import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AbstractRoleNavComponent } from '../abstract-role-nav.component';
import { NavigationMenuItem } from 'utils/navigation/navigation.interface';

@Component({
	selector: 'tpo-usernav',
	templateUrl: './role-usernav.component.html',
})
export class TpoUserNavComponent extends AbstractRoleNavComponent {
	isTPOLimited: boolean;

	constructor(
		stateService: StateService,
		userService: UserService,
	) {
		super(stateService, userService);

		this.isTPOLimited = userService.profile.organization.type === 'TPO_LIMITED';
	}

    // Override
    protected rebuildMenuItems(userProfile: UserProfile): NavigationMenuItem[] {
        const menuItems: NavigationMenuItem[] = [];

        menuItems.push({
            sref: 'people.contacts.:contactId.settings',
            title: 'My Settings',
            srefParams: { contactId: userProfile.contactId },
        });

        menuItems.push({
            sref: 'people.contacts.:contactId.social-media',
            title: 'My Social',
            srefParams: { contactId: userProfile.contactId },
        });

        if (userProfile.can('TPO_ROLES_AND_PERMISSIONS')) {
            menuItems.push({
                sref: '#/users-management/roles',
                rawHref: true,
                title: 'Role Permissions',
            });
        }

        menuItems.push({
            sref: '#/payments',
            rawHref: true,
            title: 'Payments',
        });

        menuItems.push({
            sref: '#/export',
            rawHref: true,
            title: 'Export',
        });

        menuItems.push({
            sref: '/b2c/logout',
            rawHref: true,
            title: 'Logout',
        });

        return menuItems;
    }

}
