import { Component, Input, OnInit } from '@angular/core';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import { ScorecardUtilityService } from '../services/scorecard-utility.service';
import { KeyedScorecardFindingCategories, ScorecardFindingCategories } from '../scorecard-finding-category.interface';

@Component({
    selector: 'finding-category-summary-header',
    templateUrl: 'scorecard-findings-summary-header.component.html',
})
export class ScorecardFindingsSummaryHeaderComponent implements OnInit {
    @Input() findingId: string;
    @Input() tpoId: number;
    resolving: boolean;
    scorecardFindingCategories: ScorecardFindingCategories;
    keyedScorecardFindingCategories: KeyedScorecardFindingCategories;

    constructor(
        private readonly scorecardService: ScorecardService,
        private readonly scorecardUtilityService: ScorecardUtilityService,
    )
    {
    }

    async ngOnInit(): Promise<void> {
        try {
            this.resolving = true;
            this.scorecardFindingCategories = await this.scorecardService.getScorecardCategories(this.tpoId).toPromise();
        }
        finally {
            this.resolving = false;
        }

        this.keyedScorecardFindingCategories = this.scorecardUtilityService.keyCategoriesByCode(this.scorecardFindingCategories);
    }
}
