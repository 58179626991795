import { Component, Input, OnInit } from '@angular/core';

import { EmploymentHistory } from '../../interfaces/employment-history.interface';


@Component({
    selector: 'nmls-individual-companies-company',
    templateUrl: './nmls-individual-companies-company.component.html',
})
export class NmlsIndividualCompaniesCompanyComponent implements OnInit {
    @Input() userIsTpo: boolean;
    @Input() company: EmploymentHistory;
    @Input() individualNmlsId: number;

    companyUiSrefIncludesTpo: boolean = false;

    licensesSrefParams: any = {};

    // Override
    ngOnInit(): void {
        this.companyUiSrefIncludesTpo = (
            this.company.accountHomeUiSref ?
            this.company.accountHomeUiSref.includes('tpo') :
            false
        );

        if (!this.company.endDate && !this.userIsTpo) {
            this.licensesSrefParams = {
                ...this.company.uiSrefParams,
                comergenceLicensesList: {
                    searchNmlsId: this.individualNmlsId,
                    class: 'INDIVIDUAL',
                },
            };
        }
    }
}
