import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { NgResourceObject } from 'commons/interfaces';

import { ClientLibraryDocument } from '../library-documents.interface';
import { ClientLibraryDocumentsService } from '../library-documents.service';

@Component({
	templateUrl: './document.component.html',
})
export class ClientLibraryDocumentComponent implements OnInit {
	title: string;

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		private documentsService: ClientLibraryDocumentsService,
	) {}

	async ngOnInit(): Promise<void> {
		const {
			id: tpoId,
			libraryId,
		} = this.uiRouterGlobals.params;
		if (libraryId) {
			const document: NgResourceObject<ClientLibraryDocument> = await this.documentsService.getDocument({
				tpoId,
				libraryId,
			}).$promise;
			if (document) {
				const { name } = document;
				this.title = name;
			}
		}
	}
}
