<form autocomplete="off">
	<ng-select
		placeholder="Email or Name"
		[(ngModel)]="selectedContact"
		[ngModelOptions]="{ standalone: true }"
		[items]="contactsFound"
		[loading]="isLoading"
		[typeahead]="contactsSearchInput$"
		(change)="setSelectedContact()"
	>
		<ng-template ng-label-tmp let-contact="item">
			<ng-container>
				<span class="text">{{ contact.name }}</span>
				<!-- <span class="label label-primary">{{ contact.name }}</span> -->
			</ng-container>
		</ng-template>

		<ng-template ng-option-tmp let-contact="item">
			<div class="item-inner flex-row space-between" [class.disabled]="contact.added || contact.unsubscribed">
				<div class="contact">
					<span class="text email">{{ contact.email }}</span>
					<span class="text name">{{ contact.name }}</span>
				</div>
				<div class="labels">
					<span *ngIf="contact.unsubscribed" class="label label-danger">UNSUBSCRIBED</span>
					<span *ngIf="!contact.unsubscribed && contact.added" class="label label-primary pull-right">ON THE LIST</span>
				</div>
			</div>
		</ng-template>

		<ng-template ng-typetosearch-tmp>
			<div class="ng-option message disabled">
				Please enter at least {{ MINIMUM_SEARCH_TEXT_CHARACTERS }} characters to start search
			</div>
		</ng-template>
		<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
			<div class="ng-option message disabled" *ngIf="searchTerm.length > MINIMUM_SEARCH_TEXT_CHARACTERS">
				No matches found
			</div>
			<div class="ng-option message disabled" *ngIf="searchTerm.length <= MINIMUM_SEARCH_TEXT_CHARACTERS">
				Please enter at least {{ MINIMUM_SEARCH_TEXT_CHARACTERS }} characters to start search
			</div>
		</ng-template>
		<ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
			<div class="ng-option message disabled">
				Searching for "{{ searchTerm }}"
			</div>
		</ng-template>
	</ng-select>
</form>