import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'tpo-people-menu',
    templateUrl: '../../commons/components/navigation/menu/side/side-menu-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpoPeopleMenuComponent extends SideMenuContainerComponent {
    private profile: UserProfile;

    constructor(
        cd: ChangeDetectorRef,
        router: UIRouterGlobals,
        { profile, profile$ }: UserService,
    ) {
        super(cd, router);

        this.menuUpdate$ = combineLatest([profile$, router.success$]).pipe(
            takeUntil(this.destroyed$),
        );
        this.profile = profile;
    }

    ngOnInit() {
        super.ngOnInit();
        this.populateMenu();
    }

    populateMenu = () => {
        const thisRoute = 'people';
        this.menuItems = [
            {
                title: 'Contacts',
                link: `${thisRoute}.contacts`,
            },
            ...(this.profile.can('TPO_OWNERS') ? [{
                title: 'Ownership',
                link: `${thisRoute}.ownership`,
            }] : []),
            ...(this.profile.can('TPO_CONTACTS') ? [{
                title: 'Imports',
                link: `${thisRoute}.imports`,
            }] : []),
        ];
        this.changeDetector.markForCheck();
    }
}
