import { reduce, find, filter, every, findIndex, throttle } from 'lodash';
import {
	Component,
	Input,
	ContentChild,
	TemplateRef,
	ViewChild,
	ElementRef,
	EventEmitter,
	Output,
	OnChanges,
	ChangeDetectorRef,
} from '@angular/core';

import { InfiniteScrollEntry } from './scroll-entry';

// TODO:
// 1) visibleElements, topHeight, bottomHeight can be calculated as subscription to elements.
//    Think how to add observable array and react on it's changes
// 2) calculateVisibility and resetPaddings called to often, try to reduce amount of calls by debounce or better strategy for InfiniteScrollEntry.resolved
// 3) recreate method which allows to recalculate curtain InfiniteScrollEntry size,
//    theoretically it works in 'isEqual' comparison, so if data changed -> calc sizes as new element happens
//    but maybe it should be possible to explicitly call some method here and pass resource entry for recalculation
// 4) use hostBindings to set height for top and bottom holder
@Component({
	selector: 'infinite-scroll',
	templateUrl: './infinite-scroll.component.html',
	host: {
		'(window:resize)': 'calculateVisibility($event)',
		'(window:scroll)': 'calculateVisibilityThrottled($event)',
	},
})
export class InfiniteScrollComponent implements OnChanges {
	@Output()
	onNextBatch: EventEmitter<any> = new EventEmitter();

	@Input()
	list: any = [];

	@Input()
	shouldResetList: boolean = false;

	@Input()
	lastPageReached: boolean = false;

	@Input()
	options: any = {};

	@Input()
	padding: number = 0;

	@Input()
	threshold: number = 2;

	@ContentChild('entryTemplate', {static: true})
	entryTemplate: TemplateRef<any>;

	// we need link for top element to get content top position
	@ViewChild('topElement', { read: ElementRef, static: true })
	topElement: ElementRef;

	topHeight: number = 0;
	bottomHeight: number = 0;
	elements: InfiniteScrollEntry[] = [];

	visibleElements: InfiniteScrollEntry[] = [];

	allowedBatchRequest: boolean = false;

	constructor(
		private cd: ChangeDetectorRef,
	) {}

	ngOnChanges(changes): void {
		const list = changes.list;
		const accumulator = this.shouldResetList ? [] : this.elements;

		this.elements = reduce(this.list, (memo, entry) => {
			const newEntry = new InfiniteScrollEntry({
				id: entry.id,
				data: entry,
				visible: true,
			});

			const existingElement = find(this.elements, (element: InfiniteScrollEntry) => {
				return element.isEqual(newEntry);
			});

			if (existingElement) {
				return memo;
			}

			return [ ...memo, newEntry ];
		}, accumulator);

		this.visibleElements = filter(this.elements, (element: InfiniteScrollEntry) => (element.visible));

		// GOTCHA: we have several inputs, so we have explicitly select when new request to server allowed
		// this will skip reactions on 'shouldResetList' or 'lastPageReached' changes
		// first list value by default is null, so we can show loader on UI,
		// after first call we have some list, it could be an empty array, which is truthy clause in case below -> hide loader after initial call till onNextBatch emit
		if (list && list.currentValue) {
			this.allowedBatchRequest = true;
		}
	}

	updateHeight(id, element): void {
		setTimeout(() => {
			const i = findIndex(this.elements, { id });
			const { scrollHeight } = element;

			this.elements[i].height = scrollHeight;
			this.elements[i].innerHeight = scrollHeight;
			this.calculateVisibilityThrottled();
		});
	}

	resetPaddings(): void {
		let lastVisibleIndex = +Infinity;

		// console.log('Reset Paddings: --------------------------');

		const result = reduce(this.elements, (memo, element, index) => {
			if (element.visible && element.resolved) {
				lastVisibleIndex = index;
			}

			if (element.visible) {
				return memo;
			}

			if (index < lastVisibleIndex) {
				return {
					...memo,
					topHeight: memo.topHeight + element.height,
				};
			}

			return {
				...memo,
				bottomHeight: memo.bottomHeight + element.height,
			};
		}, { topHeight: 0, bottomHeight: 0 });

		this.topHeight = result.topHeight;
		this.bottomHeight = result.bottomHeight;
	}

	calculateVisibility = (): void => {
		const topElementOffset = Math.round(this.topElement.nativeElement.getBoundingClientRect().top);
		const visibleHeight = window.innerHeight + this.padding;

		const { lastVisibleIndex } = reduce(this.elements, (acc, element, index) => {
			const isVisible = (acc.currentTop < visibleHeight) && (acc.currentTop + element.height >= -this.padding);

			// element is visible if it is not resolved(to calculate and cache it's height)
			// or when it is within viewport
			element.visible = !element.resolved || isVisible;

			return {
				currentTop: acc.currentTop + element.height,
				lastVisibleIndex: isVisible ? index : acc.lastVisibleIndex,
			};
		}, { currentTop: topElementOffset, lastVisibleIndex: 0 });

		this.resetPaddings();

		this.visibleElements = filter(this.elements, (element: InfiniteScrollEntry) => (element.visible));
		const isAllResolved = every(this.elements, (element: InfiniteScrollEntry) => (element.resolved));

		// Requesting new batch when there's only "threshold" items left to display
		if (this.allowedBatchRequest && isAllResolved && (lastVisibleIndex + this.threshold > this.elements.length - 1)) {
			this.onNextBatch.emit();
			// next batch request can be done after list update
			this.allowedBatchRequest = false;
		}
	}

	/* tslint:disable-next-line */
	calculateVisibilityThrottled = throttle(this.calculateVisibility, 100);

	setEntryHeight({ scrollEntry, innerHeight, height }): void {
		// do not calculate size if entry is already calculated
		if (scrollEntry.resolved) {
			return;
		}

		scrollEntry.resolved = true;
		scrollEntry.innerHeight = innerHeight;
		scrollEntry.height = height;
		this.calculateVisibility();
		this.cd.detectChanges();
	}
}
