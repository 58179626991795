import {
	Component,
	ContentChild,
	EventEmitter,
	Output,
	TemplateRef,
} from '@angular/core';

@Component({
	selector: 'full-page-layout',
	templateUrl: './full-page-layout.component.html',
})
export class FullPageLayoutComponent {

	@ContentChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
	@ContentChild('contentTemplate', {static: true}) contentTemplate: TemplateRef<any>;

	@Output() onClose = new EventEmitter<boolean>();

	close(): void {
		this.onClose.emit();
	}
}
