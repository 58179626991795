import { Component, Input, OnInit } from '@angular/core';

import { FindingChecklistService } from './checklist.service';

@Component({
	selector: 'finding-checklist',
	templateUrl: './checklist.component.html',
})
export class FindingChecklistComponent implements OnInit {
	list: any = [];

	@Input() finding: any;

	constructor(
		public findingChecklistService: FindingChecklistService,
	) {}

	ngOnInit(): void {
		const {
			reviewId,
			id: findingId,
		} = this.finding;
		const params = {
			reviewId,
			findingId,
		};

		this.loadList(params);
	}

	loadList(params) {
		this.list.$resolved = false;
		return this.findingChecklistService.getChecklist(params).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}
}
