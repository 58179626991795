import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { CodeValue } from 'commons/components/list/list.interface';

import { FeedContext } from './feed.interface';

declare let apiPath: string;

@Injectable()
export class FeedService {
	private feedResource: any;
	private currentFeedUrl: string;
	private feedGlobalUrl: string;
	private feedAuditUrl: string;
	private feedPostResourceConfig: any;
	private currentArchiveUrl: string;
	private resourceConfig: {
		url: string;
		params: Record<string, unknown> | {};
	};

	constructor(
		injector: Injector,
		{ profile: User }: UserService,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.resourceConfig = {
			url: `${apiPath}${User.isTpo ? '/tpos/:tpoId' : ''}/social-media-compliance`,
			params: {
				...(User.isTpo && { tpoId: User.organization.id }),
			},
		};

		this.feedGlobalUrl = `${this.resourceConfig.url}/global-archive/feed`
		this.feedAuditUrl = `${this.resourceConfig.url}/review/:reviewId/archive`

		this.feedResource = () => PagedResource(
			this.currentFeedUrl,
			this.resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				count: {
					url: `${this.currentFeedUrl}/count`,
					method: 'GET',

				},
				reviewPost: {
					url: `${this.feedPostResourceConfig.url}/review`,
					method: 'PUT',
				},
				postHistory: {
					// FIXME: should be same resource
					url: (
						User.isComergence ?
							`${this.feedPostResourceConfig.url}/history` :
							`${this.currentArchiveUrl}/:smAccountId/:type/:id/:version/history`
					),
					method: 'GET',
					isArray: true,
				},
				// TODO: move to generic service
				addLink: {
					url: `${apiPath}/social-media/review-link`,
					method: 'POST',
				},
				individuals: {
					url: `${this.resourceConfig.url}/global-archive/archive/filter/reviewed-individuals`,
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	setContext(context: FeedContext): void {
		switch (context) {
			case FeedContext.GLOBAL:
				this.currentFeedUrl = this.feedGlobalUrl;
				this.currentArchiveUrl = `${this.resourceConfig.url}/global-archive/archive`;
				break;
			case FeedContext.AUDIT:
				this.currentFeedUrl = this.feedAuditUrl;
				this.currentArchiveUrl = `${this.resourceConfig.url}/archive`;
				break;
		}

		this.feedPostResourceConfig = {
			url: `${this.currentFeedUrl}/:smAccountId/:type/:id/:version`,
		};
	}

	get = (...args) => this.feedResource().get(...args);
	count = (...args) => this.feedResource().count(...args);
	post = {
		review: (...args) => this.feedResource().reviewPost(...args),
		addLink: (...args) => this.feedResource().addLink(...args),
		history: (...args) => this.feedResource().postHistory(...args),
	}
	reviewedIndividuals = (...arg): NgResourceArray<CodeValue> => this.feedResource().individuals(...arg)
}
