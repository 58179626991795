<div class="relationship-status-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Change Relationship Status</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<div class="alert alert-danger text-center">You can only update statuses for active relationships.</div>
		<p>Please choose a new Relationship status for the selected clients:</p>
		<br>
		<div class="statuses">
			<div>Current Status</div>
			<div>Selected Clients</div>
			<div>New Status</div>

			<ng-container *ngFor="let status of selectedClientsIdsByStatuses | keyvalue">
				<ng-container *ngIf="transitions[status.key] as transition">
					<div>
						<span class="text-smaller label label-{{relationStatusColors[status.key] || 'default'}}">
							{{transition.name}}
						</span>
					</div>
					<b>{{status.value.length}}</b>
					<ng-select
					   [multiple]="false"
					   [(ngModel)]="selectedStatusesToChange[status.key]"
					   (ngModelChange)="$event || clearSelectedStatus(status.key)"
					   [items]="transition.availability"
					   bindValue="id"
					   bindLabel="name"
					   [disabled]="!transition.availability.length"
					   placeholder="Please Select"
					></ng-select>
				</ng-container>
			</ng-container>
		</div>
		<label class="confirmation text-smaller" *ngIf="isTerminatedSelected()">
			<input class="va-m" type="checkbox" [(ngModel)]="isConfirmed">&nbsp;
			I acknowledge that updating a relationship status to Terminated cannot be undone.
		</label>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button
				type="button"
				[disabled]="isSaveDisabled()"
				(click)="save()"
				class="btn btn-primary"
			>Save</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>