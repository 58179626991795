<div class="gray-head">
    <h2>
        Manage Financials
    </h2>
</div>
<br>
<div class="remote-resource" [class.resolved]="!resolving">
    <document-card-container
        [strategy]="financialDocumentStrategy"
        [allChannels]="allChannels"
        [financialDocumentParams]="financialDocumentParams"
        [documentSummaries]="financialDocumentTypes"
    ></document-card-container>
</div>
