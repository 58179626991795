import { Directive, Output, Input, ElementRef, EventEmitter } from '@angular/core';

import { OnValidSubmitDirective } from 'commons/directives/forms';

import { RecurlyService } from './abstract-recurly-service.class';

@Directive({
	selector: '[onValidRecurlySubmit][formGroup]',
})
export class OnValidRecurlySubmitDirective extends OnValidSubmitDirective {
	@Output('onValidRecurlySubmit')
	onValidSubmit: EventEmitter<any> = new EventEmitter();

	@Input() tokenPath?: string = 'tokenId';
	@Input() skipRecurly?: boolean = false;

	constructor(elementRef: ElementRef, public recurly: RecurlyService) {
		super(elementRef);
	}

	triggerSubmit() {
		// if we need to skip recurly check;
		if (this.skipRecurly) {
			super.triggerSubmit();
			return;
		}

		this.recurly.token(this.elementRef.nativeElement, (error, token) => {
			if (error) {
				const {message} = error;
				this.formGroup.setServerError({message});
			} else {
				this.formGroup.get(this.tokenPath).setValue(token.id);
				super.triggerSubmit();
			}
		});
	}
}
