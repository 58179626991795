import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FileRequiredValidator } from 'commons/validators';
import { FinancialDocumentResourceService } from 'commons/services/financial-documents-resource.service';
import {
    TPOFinancialDocumentField,
    TPOFinancialDocumentPeriod,
    TPOFinancialDocumentType,
    TPOFinancialDocumentUpload,
} from 'commons/services/financial-documents.interface';

import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
	templateUrl: './tpo-financial-document-upload-modal.component.html',
})
export class TpoFinancialDocumentUploadModalComponent implements OnInit {
    readonly periods = [ 'Q1', 'Q2', 'Q3', 'Q4', 'ANNUAL' ];
    readonly years = [ new Date().getFullYear(), (new Date().getFullYear() - 1), (new Date().getFullYear() - 2) ];

    tpoId: number;
    type: TPOFinancialDocumentType;
    documentFields: TPOFinancialDocumentField[];
    onUploaded: (uploadedDocument: TPOFinancialDocumentUpload) => void;

    documentTypeLabel: string;

	resolving: boolean = false;

    attestation: boolean = false;
    uploadFinancialDocumentForm: RealmFormGroup;

	public constructor(
        public readonly modalRef: BsModalRef,
        private readonly financialDocumentResourceService: FinancialDocumentResourceService,
        private readonly globalNotificationsService: GlobalNotificationsService,
        private cd: ChangeDetectorRef,
    ) {
	}

    // Override
    public ngOnInit(): void {
        this.documentTypeLabel = this.financialDocumentResourceService.getDocumentTypeLabel(this.type);

        const controls = {
            selectedFile: new RealmFormControl(
                'selectedFile',
                {
                    label: this.documentTypeLabel,
                },
                FileRequiredValidator,
            ),
            audited: new RealmFormControl(
                'audited',
                {
                    label: 'Type',
                },
                Validators.required,
            ),
            period: new RealmFormControl(
                'period',
                {
                    label: 'Period',
                },
                Validators.required,
            ),
            year: new RealmFormControl(
                'year',
                {
                    label: 'Year',
                },
                Validators.required,
            ),
            attestation: new RealmFormControl(
                'attestation',
                {
                    label: 'Attestation',
                },
                Validators.required,
            ),
        };
        this.documentFields.forEach((field: TPOFinancialDocumentField) => {
            controls[field.code] = new RealmFormControl(
                field.code,
                {
                    label: field.name,
                },
                Validators.required,
            );
        });
        this.uploadFinancialDocumentForm = new RealmFormGroup(controls);
    }

    public closeModal(): void {
        this.uploadFinancialDocumentForm.reset();
        this.modalRef.hide();
    }

	get selectedFile() {
		return this.uploadFinancialDocumentForm.get('selectedFile') as RealmFormControl;
	}

	async onFileSelect(files: FileList): Promise<void> {
		if (!files.length) {
            return;
        }

		this.selectedFile.setValue(files[0]);
	}

    public setAttestation(value: boolean): void {
        this.attestation = value;
    }

    public async uploadFinancialDocument(): Promise<void> {
        const formValue = this.uploadFinancialDocumentForm.getRawValue();
        const isAudited: boolean = formValue.audited;
        const period: TPOFinancialDocumentPeriod = formValue.period;
        const year: number = formValue.year;
        const fields: TPOFinancialDocumentField[] = this.documentFields
            .map((originalField: TPOFinancialDocumentField) => {
                const fieldForUpload: TPOFinancialDocumentField = { ...originalField };

                const rawValue: string = formValue[fieldForUpload.code];
                fieldForUpload.value = Number(rawValue);

            return fieldForUpload;
        });

        const details: TPOFinancialDocumentUpload = {
            isAudited: isAudited,
            period: period,
            year: year,
            fields: fields,
        };
        this.resolving = true;

        try {
            const uploadedDocument = await this.financialDocumentResourceService.uploadFinancialDocument(
                this.tpoId,
                this.type,
                formValue.selectedFile,
                details
            ).toPromise();

            this.onUploaded(uploadedDocument);

            this.resolving = false;

            this.globalNotificationsService.add({
                type: GlobalNotificationType.POSITIVE,
                message: `Successfully uploaded ${this.documentTypeLabel}`,
            });

            this.closeModal();
        } catch (errorResponse) {
            this.resolving = false;
            if (errorResponse.status === 403) {
                this.closeModal();

                throw errorResponse;
            } else {
                this.uploadFinancialDocumentForm.setServerError(errorResponse.error);
                this.cd.detectChanges();
            }
        }
    }
    resetPeriod() {
        const periodField = this.uploadFinancialDocumentForm.get('period') as RealmFormControl;
        if (periodField.serverError) {
            periodField.serverError = '';
            periodField.updateValueAndValidity();
        }
    }
}
