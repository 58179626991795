<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
	<a
		*ngFor="let item of list"
		class="row-fluid clickable"
		uiSref="^.:listingDetailsId"
		[uiParams]="{ listingDetailsId: item.listingDetailsId }"
	>
		<div class="col-sm-6 va-m">
			<div class="flex-row gap-m">
				<div class="list-item-image" [bgImage]="item.coverImage"></div>
				<div class="flex">
					<div>
						<span [class.has-discrepancy] = "item.discrepancies?.includes('businessName')">{{item.businessName}}</span>
					</div>
					<div class="text-muted">
						<span [class.has-discrepancy] = "item.discrepancies?.includes('fullAddress')">{{item | address}}</span>
					</div>
					<div class="text-muted" *ngIf="item.phoneNumber">
						<span [class.has-discrepancy] = "item.discrepancies?.includes('phoneNumber')">{{item.phoneNumber | tel}}</span>
					</div>
					<div class="text-muted" *ngIf="item.website">
						<span [class.has-discrepancy] = "item.discrepancies?.includes('website')">{{item.website}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-2 text-center va-m text-bigger">
			<span class="label label-{{item.syncWithGroup ? 'success' : 'danger'}}">
				{{item.syncWithGroup ? 'On' : 'Off'}}
			</span>
		</div>
		<div class="col-sm-1 va-m">
			<sm-network-icon [config]="item"></sm-network-icon>
		</div>
		<div class="col-sm-3 text-right va-m">
			<ng-container *ngIf="User.can('TPO_SMC_MANAGE_DIGITAL_MEDIA')">
				<ng-container *ngIf="item.isSuggestion">
					<a class="btn btn-primary disabled">Link</a>
					<a class="btn btn-secondary disabled">Dismiss</a>
				</ng-container>
				<ng-container *ngIf="!item.isSuggestion">
					<div dropdown class="dropdown pull-right clearfix" (click)="cancelBubble($event)">
						<a dropdownToggle class="dropdown-toggle btn btn-icon btn-tertiary text-bigger">
							<span class="c-icon c-icon-dot-3"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li role="menuitem">
								<a (click)="moveListing(item)">Move to Group</a>
							</li>
						</ul>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</a>
</div>
