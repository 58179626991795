<nav class="navbar navbar-default" role="navigation">
    <div class="container flex-row gap">
        <div class="logo-holder">
            <div class="navbar-header">
                <a class="navbar-brand clearfix">
                    <img src="/static/images/comergence-nopad.svg" height="40"/>
                </a>
            </div>
        </div>

        <div class="main-menu-holder flex-grow" [ngSwitch]="User?.organization?.type">
            <tpo-topnav *ngSwitchCase="'TPO'"></tpo-topnav>
            <customer-topnav *ngSwitchCase="'CUSTOMER'"></customer-topnav>
            <comergence-topnav *ngSwitchCase="'COMERGENCE'"></comergence-topnav>
        </div>

        <div class="navbar-form" *ngIf="hasGlobalSearchPermission">
            <global-search></global-search>
        </div>

        <ng-container [ngSwitch]="User?.organization?.type">
            <div class="flex-row gap-l" *ngSwitchCase="'TPO'">
                <div class="flex-row gap-sm" *ngIf="User.isTpo && inApplication">
                    <a class="btn btn-primary" uiSref="new-application.summary" [uiParams]="{ channelId : channelId }">Back to Application</a>
                    <a [uiSref]="stateName" [uiParams]="{ channelId: null }" class="btn btn-tertiary btn-icon"><span class="c-icon c-icon-close"></span></a>
                </div>
                <tpo-usernav></tpo-usernav>
            </div>
            <customer-usernav *ngSwitchCase="'CUSTOMER'"></customer-usernav>
            <comergence-usernav *ngSwitchCase="'COMERGENCE'"></comergence-usernav>
        </ng-container>
    </div>
</nav>
