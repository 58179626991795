<section class="remote-resource" [class.resolved]="!otherNamesResolving">
    <h3>Individual Other Name</h3>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-3">
                <b>First Name</b>
            </span>
            <span class="col-sm-3">
                <b>Middle Name</b>
            </span>
            <span class="col-sm-3">
                <b>Last Name</b>
            </span>
            <span class="col-sm-3">
                <b>Name Type</b>
            </span>
        </div>
        <div class="row-fluid" *ngFor="let otherName of otherNames">
            <span class="col-sm-3">
                {{ otherName.firstName || '&ndash;' }}
            </span>
            <span class="col-md-3">
                {{ otherName.middleName || '&ndash;' }}
            </span>
            <span class="col-sm-3">
                {{ otherName.lastName || '&ndash;' }}
            </span>
            <span class="col-sm-3">
                {{ otherName.type || '&ndash;' }}
            </span>
        </div>
        <div class="row-fluid" *ngIf="!otherNames.length">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Other Names</span>
        </div>
    </div>
</section>
