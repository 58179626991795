<h3>Supporting Documents</h3>

<ul *ngIf="!editing" class="list-unstyled supporting-docs">
    <li *ngFor="let document of finding.supportingDocuments">
        <scorecard-finding-details-external-document-link
            [tpoId]="tpoId"
            [finding]="finding"
            [document]="document"
        >
        </scorecard-finding-details-external-document-link>
    </li>
    <li *ngIf="finding && !finding.supportingDocuments.length" class="text-center">
        <span class="text-muted text-bigger">No documents</span>
    </li>
</ul>

<scorecard-supporting-documents
    *ngIf="editing"
    [files]="finding.supportingDocuments"
    [allowAddNew]="false"
    [allowReplace]="false"
    (fileRemoved)="removeOldFile($event)"
></scorecard-supporting-documents>
<scorecard-supporting-documents
    *ngIf="editing"
    [files]="newFiles"
    [allowAddNew]="true"
    [allowReplace]="false"
    (fileAdded)="addNewFile($event)"
    (fileRemoved)="removeNewFile($event)"
></scorecard-supporting-documents>
