import { Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';

import { InvestorCrmContactListService } from '../../services/investor-crm-contact-list.service';
import { BaseInvestorCrmContactListComponent } from '../base-investor-crm-contact-list.component';


@Component({
    templateUrl: './investor-crm-contact-list-contacts.component.html',
})
export class InvestorCrmContactListContactsComponent extends BaseInvestorCrmContactListComponent {
    public constructor(
        investorCrmContactListService: InvestorCrmContactListService,
        routerGlobals: UIRouterGlobals,
        userService: UserService,
    ) {
        super(investorCrmContactListService, routerGlobals, userService);
    }

    // Override
    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        await super.retrieveExistingList();
    }
}
