import { Injectable, Injector } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';

declare let apiPath: string;

interface Status {
	value: boolean;
}

@Injectable()
export class DocusignConnectivityResourceService {
	constructor(
		public http: RealmHttpClient,
	) {
	}

	getConnectivityStatus = (): Observable<Status> => this.http.request<Status>(
        'GET',
        `${apiPath}/flags/docusignDisconnected`,
    );
}
