import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';


@Component({
	templateUrl: './tpo-role-permissions.component.html',
})

export class TpoRolePermissionsComponent {
	public constructor(
            public stateService: StateService) {
	}
}
