<div class="gray-head">
    <h2>
        Manage Channels
    </h2>
</div>
<br>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <channel-card
                    #leftSideChannelCards
                    *ngFor="let channel of oddIndexChannel"
                    [channel]="channel"
                    [questionnaires]="questionnaires"
                >
                </channel-card>
            </div>
            <div class="col-md-12 col-lg-6">
                <channel-card
                    #rightSideChannelCards
                    *ngFor="let channel of evenIndexChannel"
                    [channel]="channel"
                    [questionnaires]="questionnaires"
                >
                </channel-card>
            </div>
        </div>
    </div>
</div>
