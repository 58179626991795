<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">Add to Channel</h4>
</div>
<form [formGroup]="form" (onValidSubmit)="save()">
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form-errors [form]="form"></form-errors>
        <div class="form-group" has-error>
            <label class="control-label required">{{ form.getFieldLabel('approvalStatus') }}</label>
            <ng-select
                formControlName="approvalStatus"
                [items]="availableStatuses"
                bindLabel="name"
                [clearable]="false"
                placeholder="Select Status"></ng-select>
        </div>
        <div class="form-group" has-error>
            <label class="control-label required">{{ form.getFieldLabel('approvalRenewalDate') }}</label>
            <div class="flex-row">
                <input class="form-control flex-grow"
                       bsDatepicker
                       [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                       [minDate]="minDate"
                       formControlName="approvalRenewalDate"
                       placeholder="mm/dd/yyyy">
                <div class="flex-grow"></div>
            </div>
        </div>
        <div class="form-group" has-error>
            <label class="control-label required">{{ form.getFieldLabel('accountExecutiveId') }}</label>
            <ng-select
                formControlName="accountExecutiveId"
                [items]="accountExecutives"
                bindLabel="fullName"
                bindValue="id"
                [clearable]="false"
                placeholder="Select Status"></ng-select>
        </div>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div class="text-right">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" (click)="modalRef.hide()" class="btn btn-default">Cancel</button>
        </div>
    </div>
</form>
