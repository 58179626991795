import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ExtendedUserProfile } from 'commons/interfaces/extended-user-profile.type';
import { ReadOnlyUserProfile } from 'commons/interfaces/read-only-user-profile.type';

import { RealmHttpClient } from 'commons/services/http';


declare let apiPath: string;

@Injectable()
export class UserManagementResourceService {
    constructor(
		private readonly http: RealmHttpClient,
    ) {
    }

    getExtendedUserProfile(organizationId: number, userId: number): Observable<ExtendedUserProfile> {
        return this.http.request<ExtendedUserProfile>(
            'GET',
            `${apiPath}/organizations/${organizationId}/users/${userId}`,
        );
    }

    getReadOnlyUserProfile(userId: number): Observable<ReadOnlyUserProfile> {
        return this.http.request<ReadOnlyUserProfile>(
            'GET',
            `${apiPath}/crm/read-only-users/${userId}`,
        );
    }

    createReadOnlyUserProfile(readOnlyUserProfile: ReadOnlyUserProfile): Observable<ReadOnlyUserProfile> {
        return this.http.request<ReadOnlyUserProfile>(
            'POST',
            `${apiPath}/crm/read-only-users/user`,
            null,
            readOnlyUserProfile,
        );
    }
}
