<div class="flex-row">
    <h2 class="flex-grow">Application Details</h2>
    <h3 *ngIf="appChannel">
        {{appChannel.investor.name}} <span class="text-muted">{{appChannel.channel.name}}</span>
    </h3>
</div>
<br>

<div class="rows-bordered remote-resource mb30" [class.resolved]="!appComponent.resolved || resolved">
    <div class="row-fluid header">
        <div class="col-md-a">Account Information</div>
        <div class="col-md-a">Status</div>
        <div class="col-md-a">Completed Date</div>
        <div class="col-md-a">Reviewed By</div>
        <div class="col-f-120 button-holder"></div>
    </div>

    <div company-information-row class="row-fluid" [companyInformation]="companyInformation" [channelId]="channelId"></div>

    <div questionnaire-row class="row-fluid" [questionnaires]="questionnaires" [channelId]="channelId"></div>

    <div class="row-fluid" *ngFor="let section of sections">
        <ng-container *ngIf="reviewBySection[section.id] as review">
            <div class="col-md-a">
                <span class="control-value">{{section.label}}</span>
            </div>
            <div class="col-md-a">
                <span class="control-value" [ngSwitch]="review.expired">
                    <span *ngSwitchCase="true" class="label label-danger-wired">Review Required</span>
                    <span *ngSwitchDefault class="label label-success-wired">Completed</span>
                </span>
            </div>
            <div class="col-md-a">
                <span class="control-value">{{ (review.reviewDate | date: 'MM/dd/yyyy') || '&ndash;' }}</span>
            </div>
            <div class="col-md-a">
                <span class="control-value">{{ review.reviewer || '&ndash;' }}</span>
            </div>
            <div class="col-f-120 button-holder text-right">
                <a [uiSref]="section.sref" [uiParams]="{ channelId: channelId }" class="btn btn-default">
                    <span class="glyphicon glyphicon-pencil"></span>Update
                </a>
            </div>
        </ng-container>
    </div>

    <div ownership-row class="row-fluid" [ownership]="ownership" [channelId]="channelId"></div>
</div>

<account-executive class="mb30 display-block"></account-executive>

<div *ngIf="!appChannel?.submitted" class="remote-resource text-right" [class.resolved]="!appComponent.resolved || resolved">
    <button [class.disabled]="!canSubmit" (click)="submit()" class="btn btn-primary">Submit Application</button>
</div>
