<div class="audit-status-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
		<h4 class="modal-title">Change Review Status</h4>
	</div>

	<form [formGroup]="form" (onValidSubmit)="submit()">
		<div class="modal-body remote-resource" [class.resolved]="resolved">

			<form-errors [form]="form"></form-errors>

			<p>Please select Review Status to set for this Review.</p>

			<div class="form-group row">
				<div class="col-sm-9">
					<ng-select
						[multiple]="false"
						[clearable]="false"
						[items]="statuses.$promise | async"
						[loading]="!statuses.$resolved"
						bindValue="code"
						bindLabel="label"
						[placeholder]="statusControlPlaceholder"
						[formControl]="statusControl"
					></ng-select>
				</div>
			</div>
		</div>

		<div class="modal-footer" [class.curtain]="!resolved">
			<button type="submit" class="btn btn-primary" [disabled]="form.invalid">Save</button>
			<button type="button" class="btn btn-default" (click)="close()">Cancel</button>
		</div>
	</form>
</div>
