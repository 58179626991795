import { Component, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { find, pull, union } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { CommentsPermissionsInterface, CommentsResourceInterface } from 'commons/components/comments';
import { NgResource } from 'commons/interfaces';

import { FindingDetail, Issue } from './finding.interface';
import { FindingService } from './finding.service';
import { FindingTabs, Tab } from './finding.tabs';
import { FindingModelService } from './finding-model.service';

@Component({
	templateUrl: './finding.component.html',
})
export class FindingComponent implements OnDestroy {
	User: UserProfile;
	tabs: Tab[] = [
		FindingTabs.DETAILS,
		FindingTabs.ISSUES,
		FindingTabs.AUDIT_LOG,
	];
	TABS = FindingTabs;
	activeTab: Tab;
	finding: NgResource<FindingDetail>;
	issueInEdit$: BehaviorSubject<Issue> = new BehaviorSubject(null);
	subscriptions: Subscription = new Subscription();

	constructor(
		public stateService: StateService,
		public historyLogService: HistoryLogService,
		public userService: UserService,
		public findingService: FindingService,
		public findingModelService: FindingModelService,
	) {
		this.User = userService.profile;

		this.finding = findingService.getFinding();
		this.finding.$promise
			.then((finding) => {
				if (this.User.isTpo && finding.hasRemediation) {
					this.addTab(FindingTabs.TASK);
					// if we are going from Tasks
					this.selectTabFromState();
				}
			});

		if (
			(
				this.User.isTpo && this.User.can('TPO_SMC_VIEW_ALL')
				|| this.User.isComergence
			)
		) {
			this.addTab(FindingTabs.CHECK_LIST);
		}

		if (!(this.User.isTpo || this.User.isPublicAccess) || this.User.can('TPO_SMC_VIEW_ALL')) {
			this.setActiveTab(FindingTabs.DETAILS);
		} else if (this.User.isTpo && !this.User.can('TPO_SMC_VIEW_ALL') || this.User.isPublicAccess) {
			// Allow user to see Task he is assigned to
			this.tabs = [FindingTabs.TASK];
			this.setActiveTab(FindingTabs.TASK);
		}

		this.selectTabFromState();

		this.subscriptions.add(
			this.issueInEdit$.subscribe((issue) => {
				this.highlightIssue(issue);
			})
		);
		this.subscriptions.add(
			this.findingModelService.onHighlightIssue$.subscribe((issue) => {
				this.highlightIssue(issue);
			})
		);
		this.subscriptions.add(
			this.findingModelService.onHighlightIssue$.subscribe((issue) => {
				this.highlightIssue(issue);
			})
		);
		this.subscriptions.add(
			this.findingModelService.onAddIssue$.subscribe((issue) => {
				this.addIssue(issue);
			})
		);
	}

	selectTabFromState() {
		const { tab } = this.stateService.params;

		const foundTab: Tab = find(this.TABS, { id: tab });
		if (foundTab) {
			this.addTab(foundTab);
			this.setActiveTab(foundTab);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	isActiveTab = (tab: Tab): boolean => (
		this.activeTab === tab
	)

	setActiveTab = (tab: Tab): void => {
		this.activeTab = tab;
	}

	createTask = (): void => {
		this.addTab(FindingTabs.TASK);
		this.setActiveTab(FindingTabs.TASK);
	}

	cancelTaskCreation = (): void => {
		this.removeTab(FindingTabs.TASK);
		this.setActiveTab(FindingTabs.DETAILS);
	}

	addTab(tab: Tab) {
		this.tabs = union(this.tabs, [tab]);
	}

	removeTab(tab: Tab) {
		pull(this.tabs, FindingTabs.TASK);
	}

	close(): void {
		// find a state that does not contain findingId and is not fullPage
		const backState = this.historyLogService.findBackState([
			'**.:findingId.**', '**.findings.new.**', '**.new-finding.**',
		], false);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }

		this.stateService.go(
			this.stateService.includes('public.**') ? 'login' : 'home'
		);
	}

	reloadFinding() {
		this.findingService.getFinding(true);
	}

	canEditIssue() {
		return !this.findingModelService.isEditingIssue;
	}

	addIssue(issue): void {
		// skip adding if already
		if (this.canEditIssue()) {
			this.finding.issues.push(issue);
			this.editIssue(issue);
		}
	}

	editIssue(issue): void {
		// skip editing if already
		if (this.canEditIssue()) {
			this.findingModelService.isEditingIssue = true;
			this.issueInEdit$.next(issue);
		}
	}

	cancelEdit(): void {
		this.findingModelService.isEditingIssue = false;
		this.issueInEdit$.next(null);
	}

	removeIssue(issue): void {
		pull(this.finding.issues, issue);
	}

	highlightIssue(issue: Issue): void {
		this.findingModelService.issueInFocus$.next(issue);
		// Only navigating on an existing issue
		if (issue) {
			this.setActiveTab(FindingTabs.ISSUES);
		}
	}

	get commentsResource(): CommentsResourceInterface {
		return this.findingService.getCommentsResource();
	}

	get commentsPermissions(): CommentsPermissionsInterface {
		return {
			canAdd: (this.User.organization.type === 'COMERGENCE' && this.User.can('CCM_SMC_VIEW_ALL')) ||
					(this.User.organization.type === 'TPO' && this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')),
		};
	}

	get taskCommunicationResource(): CommentsResourceInterface {
		return this.findingService.getTaskCommunicationResource();
	}

	get taskCommunicationPermissions(): CommentsPermissionsInterface {
		return {
			canAdd: this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') ||
				(this.finding && (this.User.contactId === this.finding.contact.contactId) && !this.User.isPublicAccess),
		};
	}
}
