import { TimezonesList, TimezonesService } from 'commons/services/compatibility/timezones.service';

export const TimezonesServiceProvider = {
	provide: 'TimezonesService',
    useExisting: TimezonesService,
};
export const TimezonesListProvider = {
	provide: 'TimezonesList',
    useValue: TimezonesList,
};
