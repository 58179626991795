<div class="global-document">
	<div class="remote-resource" [class.resolved]="!resolving">
		<form
			[formGroup]="form"
			id="form"
			(onValidSubmit)="submit()"
			class="remote-resource"
			[class.resolved]="!resolving"
		>
			<form-errors [form]="form"></form-errors>

			<div class="horizontal-flex-group mb15">
				<h3 class="full-width">Document Information</h3>
				<ng-container *ngIf="!isNew">
					<ng-container *ngIf="!editing && canManage && document?.isActive">
						<a class="btn btn-danger-wired" (click)="discontinue()">Discontinue</a>
						<a class="btn btn-primary no-space" (click)="enterEditing()">
							<span class="glyphicon glyphicon-pencil"></span>Edit
						</a>
					</ng-container>
					<ng-container *ngIf="editing">
						<button
							class="btn btn-primary"
							type="submit"
							[disabled]="state === documentStates.DOCUSIGN && docuSignStatus.status !== docuSignStatusesEnum.CONNECTED"
						>Save</button>
						<a (click)="cancelEditing()" class="btn btn-default no-space">Cancel</a>
					</ng-container>
				</ng-container>
			</div>
			<div class="rows-striped mb30" *ngIf="editing">
				<div class="row-fluid">
					<div class="col-md-12">
						<div class="form-group row">
							<label class="coldp-sm-4 control-label">Type</label>
							<div class="coldp-sm-4">
								<label class="control-label ng-select-aligned radio-inline">
									<input
										name="type"
										tabindex="1"
										type="radio"
										[value]="documentStates.GLOBAL"
										[ngModel]="state"
										[ngModelOptions]="{ standalone: true }"
										(change)="setState(documentStates.GLOBAL)"
									> Uploaded Document
								</label>
							</div>
							<div class="coldp-sm-4">
								<label class="control-label radio-inline">
									<input
										name="type"
										tabindex="1"
										type="radio"
										[value]="documentStates.LIBRARY"
										[ngModel]="state"
										[ngModelOptions]="{ standalone: true }"
										(change)="setState(documentStates.LIBRARY)"
									> Add From Library
								</label>
							</div>
							<div class="coldp-sm-4">
								<label
									*ngIf="!isNew || docuSignStatus.status === docuSignStatusesEnum.CONNECTED"
									class="control-label radio-inline docusign-radio-element">
									<input
										name="type"
										tabindex="1"
										type="radio"
										[value]="documentStates.DOCUSIGN"
										[ngModel]="state"
										[ngModelOptions]="{ standalone: true }"
										(change)="setState(documentStates.DOCUSIGN)"
									> DocuSign
								</label>
							</div>
							<div class="coldp-sm-8"></div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-12">
						<div class="form-group row" has-error>
							<label class="coldp-sm-4 control-label required">{{form.getFieldLabel('name')}}</label>
							<div class="coldp-sm-8" [ngSwitch]="state">
								<input *ngSwitchCase="documentStates.GLOBAL"
									type="text"
									formControlName="name"
									maxlength="100"
									class="form-control"
									tabindex="3"
								/>

								<ng-select *ngSwitchCase="documentStates.LIBRARY"
									formControlName="libraryId"
									(change)="selectLibraryDoc($event)"
									[items]="libraryDocs?.$promise | async"
									[loading]="!libraryDocs?.$resolved"
									bindValue="id"
									bindLabel="name"
									[clearable]="true"
									placeholder="Select Document"
									tabindex="3"
								></ng-select>

								<ng-select *ngSwitchCase="documentStates.DOCUSIGN"
									formControlName="docuSignTemplateId"
									(change)="selectDocuSignTemplate($event)"
									[items]="docuSignTemplates?.$promise | async"
									[loading]="!docuSignTemplates?.$resolved"
									bindValue="templateId"
									bindLabel="name"
									[clearable]="true"
									placeholder="Select Document"
									tabindex="3"
								></ng-select>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-12" *ngIf="state === documentStates.GLOBAL">
						<div class="form-group row" has-error>
							<label class="coldp-sm-4 control-label required">{{form.getFieldLabel('description')}}</label>
							<div class="coldp-sm-20">
								<textarea
									formControlName="description"
									class="form-control"
									rows="2"
									maxlength="2000"
									tabindex="4"
								></textarea>
							</div>
						</div>
					</div>
					<div class="col-md-12" *ngIf="state === documentStates.LIBRARY || state === documentStates.DOCUSIGN">
						<div class="form-group row">
							<label class="coldp-sm-4 control-label">{{form.getFieldLabel('description')}}</label>
							<div class="coldp-sm-20 control-label">
								<div class="ng-select-aligned">
									{{form.value.description || '&ndash;'}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="rows-striped mb30" *ngIf="!editing">
				<div class="row-fluid">
					<div class="col-md-2 control-label">Type</div>
					<div class="col-md-10 control-value" [ngSwitch]="state">
						<ng-container *ngSwitchCase="documentStates.GLOBAL">Uploaded Document</ng-container>
						<ng-container *ngSwitchCase="documentStates.LIBRARY">From Library</ng-container>
						<ng-container *ngSwitchCase="documentStates.DOCUSIGN">DocuSign</ng-container>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-2 control-label">Document Name</div>
					<div class="col-md-10 control-value">{{ document?.name }}</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-2 control-label">Description</div>
					<div class="col-md-10 control-value">{{ document?.description }}</div>
				</div>
			</div>

			<div *ngIf="!editing && document?.hasTemplate" class="mb30">
				<h3>Template</h3>

				<div class="document-template">
					<div class="front-face flex-row gap">
						<a class="title flex" [href]="getOwnDocumentLink()" target="_blank">
							<span class="c-icon c-icon-file text-muted"></span>
							&nbsp;{{document.templateName || document.name}}
						</a>
					</div>
				</div>
			</div>

			<div *ngIf="editing && state === documentStates.LIBRARY && form?.value.hasTemplate" class="mb30">
				<h3>Template</h3>

				<div class="document-template">
					<div class="front-face flex-row gap">
						<a class="title flex" [href]="getLibraryDocumentLink()" target="_blank">
							<span class="c-icon c-icon-file text-muted"></span>
							&nbsp;{{form?.value.templateName}}
						</a>
					</div>
				</div>
			</div>

			<div *ngIf="editing && state === documentStates.DOCUSIGN && docuSignDocument?.name" class="mb30">
				<h3>Template</h3>

				<div class="document-template">
					<div class="front-face flex-row gap">
						<a class="title flex" [href]="docuSignDocument.link" target="_blank">
							<span class="c-icon c-icon-file text-muted"></span>
							&nbsp;{{docuSignDocument.name}}
						</a>
					</div>
				</div>
			</div>

			<div *ngIf="editing && state === documentStates.GLOBAL" class="mb30">
				<h3>Template</h3>

				<div class="document-template" (fileDrop)=onPick($event)>
					<div class="front-face">
						<ng-container *ngIf="form?.value.hasTemplate; else noFile">
							<div class="flex-row gap">
								<span class="title flex">
									<span class="c-icon c-icon-file text-muted"></span>
									{{form?.value.templateName}}
								</span>
								<button type="button" class="btn btn-tertiary btn-icon" (click)="clearTemplate()">
									<span class="glyphicon glyphicon-trash"></span>
								</button>
								<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Replace' }"></ng-container>
							</div>
						</ng-container>
						<ng-template #noFile>
							<div class="flex-row center gap">
								<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Upload File' }"></ng-container>
								<span class="text-muted">or Drag and Drop here</span>
							</div>
						</ng-template>
						<ng-template #fileInput let-buttonText="buttonText">
							<label class="file-input">
								<span class="btn btn-primary">{{buttonText}}</span>
								<input type="file" (input)="onPick($event.target.files)" [multiple]="false" />
							</label>
						</ng-template>
					</div>
				</div>
			</div>

			<!--div class="mb30" *ngIf="libraryDocs?.$resolved && form?.value?.type === 'library' && form?.value?.docLibraryId && form?.value?.fileName">
				<h3>Template</h3>

				<div class="document-template horizontal-flex-group">
					<div class="name full-width">
						<span class="c-icon c-icon-file"></span>
						<a target="_blank" [href]="getTemplateDocumentLink()">{{form?.value?.fileName}}</a>
					</div>
				</div>
			</div>

			<div *ngIf="form?.value?.type === 'global'" class="mb30">
				<h3>Template</h3>

				<uploader (select)="onPick($event)" *ngIf="!form?.get('file').value"></uploader>

				<div class="document-template horizontal-flex-group" *ngIf="form?.get('file').value">
					<div class="name full-width">
						<span class="c-icon c-icon-file"></span> {{form?.get('file')?.value?.name}}
					</div>
					<span class="glyphicon glyphicon-trash clickable space-bigger" (click)="removeFile()"></span>
					<label class="file-handler no-space">
						<span class="btn btn-default">Replace</span>
						<input type="file" (input)="onPick($event)" [multiple]="false">
					</label>
				</div>
			</div-->

			<h4 class="clearfix">Channels Settings</h4>

			<div class="rows-bordered form-group mb30" *ngIf="editing">
				<div class="row-fluid header">
					<span class="col-sm-12"><b class="two-liners">Channel</b></span>
				</div>
				<div class="row-fluid" *ngFor="let channel of channels">
					<span class="col-sm-8 va-m channel-element">
						<label class="checkbox-inline">
							<input type="checkbox"
								   [value]="channel"
								   [checked]="selectedChannels[channel.id]"
								   (change)="toggleChannel(channel)">
							{{channel.name}}
						</label>
					</span>
				</div>
				<div class="row-fluid" *ngIf="!channels?.length">
					<span class="col-md-12 text-muted text-center text-bigger va-m">No channels</span>
				</div>
			</div>

			<div class="rows-bordered mb30" *ngIf="!editing">

				<div class="row-fluid header">
					<span class="col-sm-12"><b class="two-liners">Channel</b></span>
				</div>
				<div class="row-fluid" *ngFor="let item of document?.channelsSettings">
					<span class="col-sm-10 va-m channel-element">{{item.name}}</span>
					<span class="col-sm-2 va-m text-right">
						<button
							type="button"
							class="btn btn-default"
							(click)="request(item)"
							[disabled]="item.$requested"
							*ngIf="document.isActive"
						>Request</button>
					</span>
				</div>
				<div class="row-fluid" *ngIf="!document?.channelsSettings?.length">
					<span class="col-md-12 text-muted text-center text-bigger va-m">No channels</span>
				</div>
			</div>

			<h4 class="clearfix">Related Questionnaires</h4>

			<div class="rows-bordered mb30">
				<div class="row-fluid header">
					<span class="col-sm-12"><b class="two-liners">Questionnaire name</b></span>
				</div>
				<a *ngFor="let entry of relatedQuestionnaires"
				   class="row-fluid clickable"
				   uiSref="questionnaires.:questionnaireId.questions"
				   [uiParams]="{ action: 'edit', questionnaireId: entry.id }"
				>
					<span class="col-sm-12 va-m questionnaire-element">
						<a
							uiSref="questionnaires.:questionnaireId.questions"
							[uiParams]="{ action: 'edit', questionnaireId: entry.id }"
						>
							{{entry.name}}
						</a>
					</span>
				</a>
				<div class="row-fluid" *ngIf="!relatedQuestionnaires?.length">
					<span class="col-md-12 text-muted text-center text-bigger va-m">No questionnaires</span>
				</div>
			</div>

			<div class="horizontal-flex-group right" *ngIf="editing">
				<button
					class="btn btn-primary"
					type="submit"
					[disabled]="state === documentStates.DOCUSIGN && docuSignStatus.status !== docuSignStatusesEnum.CONNECTED"
				>Save</button>
				<button *ngIf="isNew" type="button" uiSref="documents" class="btn btn-default">Cancel</button>
				<button *ngIf="!isNew" type="button" (click)="cancelEditing()" class="btn btn-default">Cancel</button>
			</div>
		</form>
	</div>
</div>
