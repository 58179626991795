import { Component, HostBinding, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { AppChannel, TpoApplicationService } from 'tpo/application/application.service';

@Component({
    templateUrl: './application-common.component.html',
    host: {
        'class': 'remote-resource display-block application',
    },
})
export class ApplicationCommonComponent implements OnInit {
    application: Promise<AppChannel>;
    @HostBinding('class.resolved') resolved = false;

    constructor(
        private readonly router: UIRouter,
        private readonly applicationService: TpoApplicationService,
    ) {
    }

    ngOnInit(): void {
        this.loadApplication()
    }

    get channelId(): number {
        return this.router.globals.params.channelId;
    }

    public async loadApplication() {
        this.resolved = false;
        this.application = this.applicationService.getAppChannel(this.channelId).toPromise();
        await this.switchScreen();
        this.resolved = true;
    }

    private async switchScreen() {
        const paymentRoute = 'new-application.payment', summaryRoute = 'new-application.summary';
        const { stateService } = this.router;
        try {
            const { paid } = await this.application;
            if (paid && !stateService.is(summaryRoute)) {
                return stateService.go(summaryRoute, {}, { location: 'replace' });
            }


            if (!paid && !stateService.is(paymentRoute)) {
                return stateService.go(paymentRoute, {}, { location: 'replace' });
            }
        } catch (exception) {
            const {status} = exception
            if ([500].includes(status)) {
                throw exception;
            }
        }
    }
}
