import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { DropdownRequiredValidator, NumberValidator } from 'commons/validators';
import { TpoChannelInformation,  TpoChannelOverview } from 'comergence/customers/tpo-services/tpo-service.interface';
import { ThirdPartyOversightService } from 'comergence/customers/services/customer-services/third-party-oversight.service';

@Component({
    templateUrl: 'tpo-add-edit-channel-modal.component.html',
})

export class TpoAddEditChannelModalComponent implements OnInit {
    resolving: boolean;
    templates: [{ displayName: string; value: string }, { displayName: string; value: string }];
    public readonly isEditing = true;
    organizationId: number;
    customerType: string;
    channelId: number;
    channel: TpoChannelOverview;
    callbackOnSave: () => void;

    tpoAddEditForm = new RealmFormGroup({
         name: new RealmFormControl(
             'name',
             {
                 label: 'Channel Name',
             },
             Validators.required,
         ),
        template: new RealmFormControl(
            'template',
            {
                label: 'Channel Template',
            },
            DropdownRequiredValidator,
        ),
        applicationFee: new RealmFormControl(
            'applicationFee',
            {
                label: 'Application Fee',
            },
            [
                Validators.required,
                NumberValidator(),
            ]
        ),
        visibleToApply: new RealmFormControl(
            'visibleToApply',
            {
                label: 'Visible to Apply',
            },
            Validators.required,
        ),
    });

    constructor(
        private modalRef: BsModalRef,
        private thirdPartOverSightService: ThirdPartyOversightService,
    )
    {
        this.templates = [
            { value: 'BASIC', displayName: 'Basic' },
            { value: 'EXTENDED', displayName: 'Extended' },
        ];
    }

    ngOnInit() {
        if (this.channelId) {
           this.loadChannel();
        }
    }

    async loadChannel(): Promise<void> {
        this.resolving = true;

        try {
            this.channel = await this.thirdPartOverSightService.getChannelInformation(this.organizationId, this.channelId).toPromise();
            this.tpoAddEditForm.patchValue(this.channel);
        } catch ({ error: { message }, message: httpError }) {
            this.tpoAddEditForm.setServerError({ message: message || httpError });
        } finally {
            this.resolving = false;
        }
    }

    close(): void {
        this.modalRef.hide();
    }

    submit() {
        const channel = this.tpoAddEditForm.value
        if (!this.channel) {
            this.addNewChannel(channel);
        } else {
             this.updateChannel(channel);
        }
    }

    private async addNewChannel(newChannel: TpoChannelInformation) {
        try {
            this.resolving = true;
            await this.thirdPartOverSightService.createTpoChannel(this.organizationId,newChannel).toPromise();
            this.callbackOnSave();
            this.close();
        } catch ({ error: { message }, message: httpError }) {
            this.tpoAddEditForm.setServerError({ message: message || httpError });
        } finally {
            this.resolving = false;
        }
    }

    private async updateChannel(updatedChannel: TpoChannelInformation) {
        try {
            this.resolving = true;
            await this.thirdPartOverSightService.updateChannel(this.channelId, this.organizationId, updatedChannel).toPromise();
            this.callbackOnSave();
        } catch ({ error: { message }, message: httpError }) {
            this.tpoAddEditForm.setServerError({ message: message || httpError });
        } finally {
            this.resolving = false;
            this.close();
        }
    }
}
