import { Component, forwardRef } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { Observable } from 'rxjs';
import { ListComponent} from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { PagedData } from 'commons/services/http';
import { LandingPageContactList } from 'lender/crm/contact/investor-crm-contact-lists/investor-crm-contact-list.interface';
import { InvestorCrmContactListService } from 'lender/crm/contact/investor-crm-contact-lists/services/investor-crm-contact-list.service';


@Component({
    templateUrl: 'investor-crm-contact-list-landing-page.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => InvestorCrmContactListLandingPageComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => InvestorCrmContactListLandingPageComponent) },
    ],
})
export class InvestorCrmContactListLandingPageComponent extends NewPagedListComponent<LandingPageContactList> {
    readonly userProfile: UserProfile;
    canManageContactList: boolean;
    static listName = 'crmContactList';

    constructor(
        router: UIRouter,
        private readonly userService: UserService,
        private readonly investorCrmContactListService: InvestorCrmContactListService,
    )
    {
        super(router);
        this.userProfile = this.userService.profile;
        this.canManageContactList = this.userProfile.can('MANAGE_CONTACT_LIST');
    }

    protected loadList(params: ListParams):  Observable<PagedData<LandingPageContactList[]>> {
        return this.investorCrmContactListService.getLandingPageContactList(this.userProfile.organization.organizationId, params);
    }
}
