<div class="alerts-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<form-errors [form]="alertsForm"></form-errors>
		<form [formGroup]="alertsForm" id="alertsForm" (onValidSubmit)="onConfirm(this.alertsForm.get('status').value)">
			<label class="text-normal">Please select Alert Status to assign for alert.</label>
			<ng-select
				placeholder="Choose Status"
				formControlName="status"
				[items]="statuses"
				bindValue="id"
				bindLabel="name"
				[clearable]="true"
				required>
			</ng-select>
		</form>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button type="submit" form="alertsForm" class="btn btn-primary">{{confirmText}}</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
		</div>
	</div>
</div>
