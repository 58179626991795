import { forEach } from 'lodash';

import { socialMediaNetworksConfig } from 'commons/components/sm-icons';

export class MediaLinkHelper {
	static processLinkTitles(links) {
		forEach(links, (mediaLink) => {
			mediaLink.title = MediaLinkHelper.getLinkTitle(mediaLink);
		});
		return links;
	}

	static getLinkTitle({ name, link, networkCode }) {
		return name === link
			?
			name
			:
			[socialMediaNetworksConfig[networkCode].title, name].join(', ');
	}
}
