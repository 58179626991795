import { NgModule } from '@angular/core';
import { UrlPipe } from 'commons/pipes/url.pipe';
import { AddressPipe } from 'commons/pipes/address.pipe';
import { TelPipe } from 'commons/pipes/tel.pipe';
import { FilterPipe } from 'commons/pipes/filter.pipe';
import { OrderByPipe } from 'commons/pipes/order-by.pipe';
import { ArrayFilterPipe } from 'commons/pipes/array-filter.pipe';
import { HumanSizePipe } from 'commons/pipes/human-size.pipe';
import { TruncatePipe } from 'commons/pipes/truncate.pipe';
import { NumbersRangePipe } from 'commons/pipes/numbers-range.pipe';
import { BulletpointsPipe } from 'commons/pipes/bulletpoints.pipe';
import { UrlSanitizer } from 'commons/pipes/url-sanitizer.pipe';
import { SanitizeHtml } from 'commons/pipes/sanitize-html.pipe';
import { ArrayUniqueByPipe } from 'commons/pipes/array-uniq-by.pipe';
import { HrefReadyPipe } from 'commons/pipes/hrefReady.pipe';
import { TextToUrlPipe } from 'commons/pipes/textToUrl.pipe';
import { EntityNamesPipe } from 'commons/pipes/entity-names.pipe';
import { LenderLogoPipe } from 'commons/pipes/lender-logo.pipe';
import { EmptyPipe } from 'commons/pipes/empty.pipe';

const pipes = [
    UrlPipe,
    TelPipe,
    FilterPipe,
    OrderByPipe,
    ArrayFilterPipe,
    HumanSizePipe,
    TruncatePipe,
    NumbersRangePipe,
    BulletpointsPipe,
    UrlSanitizer,
    SanitizeHtml,
    ArrayUniqueByPipe,
    HrefReadyPipe,
    TextToUrlPipe,
    EntityNamesPipe,
    AddressPipe,
    LenderLogoPipe,
    EmptyPipe,
];

@NgModule({
	imports: [],
	declarations: [
		...pipes,
	],
	exports: [
		...pipes,
	],
	providers: [
        ...pipes,
	],
})

export class PipesModule {}
