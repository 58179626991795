import { Injectable } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ChannelsService } from 'lender/clients/$id/channels/channels.service';

import { ConfirmModalComponent } from 'commons/components/modals';


@Injectable({
	providedIn: 'root',
})
export class RenewalApplicationModalService {
    constructor(
        private readonly channelsService: ChannelsService,
        private readonly modalService: BsModalService,
    ) {
    }

    showRenewalModal(
        channelId: number,
        tpoId: number,
        successAction: () => any,
        errorAction?: (error: any) => any,
    ): BsModalRef {
        const modalRef: BsModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: {
                title: 'Confirm to Proceed',
                message: 'You are about to launch a renewal application for this client. Would you like to proceed?',
                confirmText: 'Yes',
                cancelText: 'No',
                onConfirm: async (): Promise<void> => {
                    modalRef.content.resolving = true;

                    try {
                        await this.channelsService.renewApplication(tpoId, channelId).toPromise();
                        successAction();
                    } catch (e) {
                        if (!!errorAction) {
                            errorAction(e);
                        }
                    }

                    modalRef.hide();
                    modalRef.content.resolving = false;
                },
            },
            class: 'modal-smd modal-new',
        });

        return modalRef;
    }
}
