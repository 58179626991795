import { ScriptResolver } from './scripts-resolver.class';
// import { StylesheetResolver } from './stylesheet-resolver.class';
import { RecurlyPublicKeyService } from './recurly-public-key.service';

const FILES = [
	'https://js.recurly.com/v4/recurly.js',
	// 'https://js.recurly.com/v4/recurly.css',
];

let loaded = false;
let cachedPromise: Promise<any>;

RecurlyResolver.$inject = ['$transition$'];

export function RecurlyResolver($transition$) {
	const RecurlyKeyService = new RecurlyPublicKeyService($transition$.injector());

	if (!loaded) {
		const loadList = [
			new ScriptResolver(FILES[0]).promise,
			// new StylesheetResolver(FILES[1]).promise,
		];
		cachedPromise = new Promise<any>((resolve, reject) => {
			Promise.all(loadList).then(() => {
				RecurlyKeyService.get((data) => {
					resolve({
						publicKey: data.publicKey,
						fields: {
							all: {
								style: {
									fontFamily: 'Arial, sans-serif',
									fontSize: '14px',
									fontColor: '#333',
									placeholder: {
										color: 'rgba(51, 51, 51, 0.7)',
									},
									// lineHeight: 16,
								},
								tabIndex: 1,
							},
							card: {
								// displayIcon: false,
								style: {
									invalid: {
										fontColor: '#DD534F',
									},
								},
							},
							number: {
								style: {
								},
							},
						},
					});
				}, () => reject());
			}, () => reject());
		});
		loaded = true;
	}
	return cachedPromise;
}
