import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';

declare let apiPath;

export interface BillingSubscriptionDetails {
    productName: string;
    isSubscribed: boolean;
    tierNumber: number;
}

@Injectable({ providedIn: 'root' })
export class CcmBillingSubscriptionDetailsResourceService {
    constructor(
        protected http: RealmHttpClient,
    ) {
    }

    getBillingSocialMediaSubscriptionDetails(organizationId: number): Observable<BillingSubscriptionDetails> {
        return this.http.request<BillingSubscriptionDetails>(
            'get',
            `${apiPath}/organizations/${organizationId}/billing/social-media/subscription`,
        );
    }
}
