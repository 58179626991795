<div class="widget-details">
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
			<span>{{widget?.name}}</span>
		</back-bread>
	</div>

	<section class="remote-resource" [class.resolved]="widget.$resolved">
		<h3>
			Embed Code
			<span class="pull-right">
				<button type="button" class="btn btn-secondary btn-icon" (click)="remove()">
					<span class="glyphicon glyphicon-trash"></span>
				</button>
				<a type="button" class="btn btn-primary" uiSref=".edit">
					<span class="glyphicon glyphicon-pencil text-smaller"></span> Edit
				</a>
			</span>
		</h3>
		<div>1. Copy and paste this code on your website where you want the widget to appear.</div>
		<br>
		<embed-code>
			{{ getWidgetCode(widget?.hash) }}
		</embed-code>
		<br>
		<div>2. Place this code immediately before the clothing &lt;/body&gt; tag on the page with the widget. As an
			alternative, you can add this code anywhere on this page. Please note, this code can only be used once per
			page, even if you have more than one widget.
		</div>
		<br>
		<embed-code>
			{{ scriptCode }}
		</embed-code>
	</section>

	<section>
		<h3>Preview</h3>
		<div *ngIf="widget?.hash" class="comergence-widget" [attr.data-comergence-widget-id]="widget?.hash"></div>
	</section>

	<section class="remote-resource" [class.resolved]="sources.$resolved">
		<h3>Sources</h3>

		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-a" columnName="account">Account</sortable-header>
				<sortable-header class="col-sm-3" columnName="source">Source</sortable-header>
				<sortable-header class="col-sm-2" columnName="nmlsId">NMLS ID</sortable-header>
			</div>

			<div *ngFor="let source of sources" class="row-fluid">
				<div class="col-sm-a">
					<div class="flex-row gap">
						<sm-icon
							[config]="{
								networkCode: source?.networkCode || source?.providerCode || 'ER',
								profilePictureUrl: source?.profilePictureUrl,
								title: (source?.providerCode && source?.providerName) ? source?.providerName : (!source?.networkCode && source.name)
							}"
						></sm-icon>
						<div class="flex text-overflow">
							<ng-container *ngIf="source?.linkText; then linkAnchor; else linkName"></ng-container>
							<ng-template #linkAnchor>
								<a [href]="source?.linkText | hrefReady" target="_blank">{{source.name}}</a>
							</ng-template>
							<ng-template #linkName>{{source.name}}</ng-template>
						</div>
					</div>
				</div>
				<div class="col-sm-3 va-m">
					<span [ngSwitch]="source.networkCode">
						<ng-container *ngSwitchCase="'FB'">Facebook</ng-container>
						<ng-container *ngSwitchCase="'GMB'">Google</ng-container>
						<ng-container *ngSwitchCase="'YLP'">Yelp</ng-container>
						<ng-container *ngSwitchDefault>{{source.providerName}}</ng-container>
					</span>
				</div>
				<div class="col-sm-2 va-m">
					<span>{{source.individualNMLSId || source.companyNMLSId || '&ndash;'}}</span>
				</div>
			</div>

			<div class="row-fluid" *ngIf="sources.$resolved && !sources.length">
				<div class="col-sm-12 text-center text-light text-bigger">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No reviews'}}
				</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="sources" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</section>
</div>
