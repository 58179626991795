import { Component } from '@angular/core';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './account-disconnected-modal.component.html',
})
export class AccountDisconnectedModalComponent {
	title: string;
	content: string;
	btnText: string;
	icon: Icon;
	contactId: number;
	onCancel: (params?) => any;
	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: 'Account is Disconnected',
			content: 'Please reconnect your account',
			btnText: 'Manage Accounts',
			onCancel: () => this.modalRef.hide(),
		});
	}
}

interface Icon {
	networkCode: string;
	reviewerAvatarUrl: string;
	title: string | boolean;
}
