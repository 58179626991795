import { omit } from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { UserService } from 'angularjs-providers/user.provider';

import { Entity } from 'tpo/documents/documents.interface';
import { Person, TextEntity } from 'lender/clients/$id/channels/channels.service';
import { ApplicationSummary } from 'lender/applications/application-summary.interface';
import { TpoContact, TpoContactsListParams } from 'tpo/people/contacts/contacts.interface';
import { ListParams } from 'commons/components/new-list/list.component';

declare let apiPath: string;

export enum APPLICATION_STATUS {
    APPROVED = 'AP',
    CANCELLED = 'CN',
    DECLINED = 'DE',
    DOC_REQUIRED = 'DR',
    LENDER_REVIEW = 'LR',
    NOT_SUBMITTED = 'NS',
    UNDER_REVIEW = 'UR',
    WITHDRAWN = 'WD',
}

export type ApplicationStatus = TextEntity & { id: APPLICATION_STATUS };

const transformFileRequest = (item) => {
	const data = new FormData();
	const fields = JSON.stringify(omit(item, [ 'file' ]));
	const mainData = new Blob([ fields ], {
		type: 'application/json',
	});
	data.append('docManagementString', mainData);
	data.append('file', item.file);
	return data;
};

export type ApprovalStatusTransitions = Record<string, {
    availability: TextEntity[];
    name: string;
    renewal: boolean;
    changeRenewal: boolean;
}>;

export type ApplicationStatusTransitions = Record<APPLICATION_STATUS, {
    availability: ApplicationStatus[];
    name: string;
}>;

// TODO: This is extracted out to be used with app-status.modal. What should it actually be called?
export type LenderApplicationSummary = {
    applicationId: number;
    channel: Entity & {
        description: string;
    };
    clientName: string;
    nmlsId: number;
    tpoId: number;
    hasPrintPackage: boolean;
    inProgress: boolean;
};

export type LenderApplication = LenderApplicationSummary & {
    accountExecutive: {
        id: number;
        isAccountExecutive: boolean;
        name: string;
        status:  string;
    };
    alternativeAssignee: Entity;
    applicationStatus: ApplicationStatus;
    applicationType: string;
    approvalStatus: TextEntity;
    ccRealmUserIds: number[];
    channelUID: string;
    clientUID: string;
    commentsCount: number;
    createdDate: number;
    customerOrganizationId: number;
    customerType: string;
    expirationDate: number;
    isPrintVersionAvailable: boolean;
    lenderId: number;
    lenderName: string;
    maxRenewalDate: number;
    nextRenewalDate: number;
    notificationMessage: string;
    notificationUserIds: number[];
    notify: boolean;
    optionalStatus: Entity;
    renewalDate: string;
    statusDate: number;
    statusReason: string;
};

@Injectable()
export class LenderApplicationsResourceService {
	public list: any;
	public application: any;
	public filters: any;

	constructor(
        public injector: Injector,
        public User: UserService,
        public http: RealmHttpClient,
    ) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const basePath = `${apiPath}/lenders/:organizationId/applications`;
		const filtersPath = `${apiPath}/applications`;
		const lenderId = User.profile.organization.id;
		const defaults = { organizationId: lenderId };

		this.list = PagedResource(`${basePath}`, { ...defaults }, {
			query: {
				method: 'get',
				isArray: true,
			},
		});

		this.application = $resource(
			`${apiPath}/applications/:applicationId/summary`,
			{},
			{
				get: {
					method: 'get',
				},
				setExpirationDate: {
					url: `${apiPath}/applications/:applicationId/expiration-date`,
					method: 'put',
				},
			},
		);

		this.filters = $resource(`${filtersPath}`, {}, {
			applicationStatus: {
				url: `${filtersPath}/application-statuses`,
				method: 'get',
				isArray: true,
			},
			optionalStatus: {
				url: `${apiPath}/lenders/${lenderId}/application-optional-statuses`,
				method: 'get',
				isArray: true,
			},
			channelName: {
				url: `${apiPath}/lenders/:organizationId/channels`,
				method: 'get',
				params: {activeOnly: true, accessibleOnly: true, ...defaults},
				isArray: true,
			},
			accountExecutive: {
				url: `${apiPath}/lenders/:organizationId/account-executives`,
				method: 'get',
				params: {...defaults},
				isArray: true,
			},
			alternativeAssignee: {
				url: `${apiPath}/lenders/:organizationId/applications/alternative-assignees`,
				method: 'get',
				params: {...defaults},
				isArray: true,
			},
		});
	}

	query = (...args) => this.list.query(...args);
	setExpirationDate = (...args) => this.application.setExpirationDate(...args);

    getList = (
        params: ListParams,
    ): Observable<PagedData<TpoContact[]>> =>
        this.http.pagedRequest<TpoContact[]>(
            'GET',
            `${apiPath}/lenders/${this.User.profile.organization.id}/applications`,
            params,
        );

    createOptionalStatus(status: Entity): Observable<Entity> {
        return this.http.request<Entity>(
            'POST',
            `${apiPath}/lenders/${this.User.profile.organization.id}/application-optional-statuses`,
            {},
            status,
        );
    }

    setOptionalStatus(applicationId: number, status: Entity): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/applications/${applicationId}/optional-status`,
            {},
            status
        );
    }

    setAssignedStatus(applicationId: number, status: Entity): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/applications/${applicationId}/alternative-assignee`,
            {},
            status
        );
    }

    getApplicationStatusTransitions = (): Observable<ApplicationStatusTransitions> => this.http.request<ApplicationStatusTransitions>(
      'GET',
      `${apiPath}/applications/statuses/transitions`
    );

    getApprovalStatusTransitions = (): Observable<ApprovalStatusTransitions> => this.http.request<ApprovalStatusTransitions>(
      'GET',
      `${apiPath}/applications/approval-statuses/transitions`
    );

    getApplication = <T = LenderApplication>(applicationId: number): Observable<T> => this.http.request<T>(
        'GET',
        `${apiPath}/applications/${applicationId}`
    );

    getRequestedDocuments = (applicationId: number): Observable<boolean> => this.http.request<{ count: number }>(
        'POST',
        `${apiPath}/applications/${applicationId}/documents-verification`,
    ).pipe(map(({ count }) => (count > 0)));

    saveApplication = <T extends LenderApplication = LenderApplication>(applicationId: number, body: T): Observable<T> => this.http.request<T>(
        'PUT',
        `${apiPath}/applications/${applicationId}`,
        {},
        body,
    );

    getApplicationUsers = (): Observable<Person[]> => this.http.request<{realmUserId: number, firstName: string, lastName: string}[]>(
        'GET',
        `${apiPath}/applications/lender-users`
    ).pipe(
        map(users => users.map(({realmUserId, firstName, lastName}) => ({id: realmUserId, fullName: `${firstName} ${lastName}`}))),
    );

    getNotificationUsers = (tpoId: number): Observable<Person[]> => this.http.request<{userId: number, fullName: string}[]>(
        'GET',
        `${apiPath}/tpos/${tpoId}/contacts/notify-contacts`
    ).pipe(
        map(users => users.map(({userId: id, fullName}) => ({id, fullName}))),
    );

    getApplicationSummary(applicationId: number): Observable<ApplicationSummary> {
        return this.http.request<ApplicationSummary>(
            'GET',
            `${apiPath}/applications/${applicationId}/summary`,
        );
    }

    getAppAlternativeAssignee = (applicationId: number): Observable<Entity[]> => (
         this.http.request<Entity[]>(
            'GET',
            `${apiPath}/applications/${applicationId}/alternative-assignees`,
        )
    ).pipe(shareReplay(1));
}
