import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { socialMediaNetworksConfig, allowedNetworks } from 'commons/components/sm-icons';

import defaultProfileImg from '~static/images/social-media/other.png';

@Component({
	selector: 'post',
	templateUrl: './post.component.html',
})
export class PostComponent {
	@Input() name: string;
	@Input() profilePictureUrl: string;
	@Input() webLink: string;
	@Input() networkCode: allowedNetworks | string;
	@Input() text: string;
	@Input() attachments: any;
	@Input() previewDate: Date;
	@Input() linkPreview: {
		$resolved: any,
		description?: string,
		domain?: string,
		image?: string,
		network?: string,
		title?: string,
		type?: string,
		url?: string,
	};
	@Input() resolved: boolean = false;

	textTruncated: string;
	networksConfig = socialMediaNetworksConfig;

	constructor(
		public sanitizer: DomSanitizer,
	) {}

	sanitize = (url: string | null): SafeStyle => {
        let urls = `url(${defaultProfileImg})`;
        if (url) {
            urls = `url(${url}), ${urls}`;
        }

        return this.sanitizer.bypassSecurityTrustStyle(urls)
    };

	ngOnChanges(): void {
		const { signLimit } = this.networksConfig[this.networkCode];
		this.textTruncated = (this.text || '').substring(0, signLimit);
	}

}
