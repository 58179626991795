import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { Entity } from 'tpo/documents/documents.interface';
import { shareReplay } from 'rxjs/operators';
import { CommentsResourceInterface } from 'commons/components/comments';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';
import {
    ContactHint,
    InvestorChannels,
    ProspectAssignment,
    ProspectDetails,
    ProspectHistory,
    ProspectsListItem,
    ProspectStateValue,
    ProspectStatus,
    RelatedProspectContact,
    CustomFieldFilter,
} from 'lender/prospects/prospects.interface';

import { ListParams } from 'commons/components/new-list/list.component';

declare let apiPath: string;

@Injectable()
export class ProspectsService {
    protected lenderId: number;
    protected organizationId: number;
    constructor(
        { profile }: UserService,
        private readonly http: RealmHttpClient,
    ) {
        this.lenderId = profile.organization.id;
        this.organizationId = profile.organization.organizationId;
    }

    get baseUrl(): string {
        return `${apiPath}/lenders/${this.lenderId}/prospect-board`
    }

    get(prospectId: number): Observable<ProspectDetails> {
        return this.http.request<ProspectDetails>(
            'GET',
            `${this.baseUrl}/${prospectId}`,
        );
    }

    getList(params): Observable<PagedData<ProspectsListItem[]>> {
        return this.http.pagedRequest<ProspectsListItem[]>(
            'GET',
            `${this.baseUrl}`,
            params
        );
    }

    getAssignee(prospectId: number): Observable<Entity[]> {
        return this.http.request<Entity[]>(
            'GET',
            `${this.baseUrl}/${prospectId}/assignee-options`,
        ).pipe(shareReplay(1));
    }

    getStatuses(): Observable<ProspectStatus[]> {
        return this.http.request<ProspectStatus[]>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/prospect-statuses`,
        ).pipe(shareReplay(1));
    }

    save(prospect: ProspectDetails): Observable<ProspectDetails> {
        return this.http.request<ProspectDetails>(
            'PUT',
            `${this.baseUrl}/${prospect.id}`,
            {},
            prospect,
        );
    }

    getStateValues(): Observable<ProspectStateValue[]> {
        return this.http.request<ProspectStateValue[]>(
            'GET',
            `${apiPath}/code-value/CRMProspectState`,
        ).pipe(shareReplay(1));
    }

    getCustomFields(): Observable<CustomFieldFilter[]> {
        return this.http.request<CustomFieldFilter[]>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/custom-fields/CRMProspect`,
        ).pipe(shareReplay(1));
    }

    getContacts<T = RelatedProspectContact[]>(prospectId: number, params): Observable<PagedData<T>> {
        return this.http.pagedRequest<T>(
            'GET',
            `${this.baseUrl}/${prospectId}/relatedContact`,
            params,
        );
    }

    searchContact<T = ContactHint[]>(prospectId: number, q: string): Observable<T> {
        return this.http.request<T>(
            'GET',
            `${this.baseUrl}/${prospectId}/relatedContactHint`,
            { q },
        );
    }

    setStatus(prospectId: number, name: string): Observable<ProspectDetails> {
        return this.http.request<ProspectDetails>(
            'PUT',
            `${this.baseUrl}/${prospectId}/status`,
            {},
            { name },
        );
    }

    setAssigned(prospectId: number, assigneeUserId: number): Observable<ProspectDetails> {
        return this.http.request<ProspectDetails>(
            'PUT',
            `${this.baseUrl}/${prospectId}/assignee`,
            {},
            { id: assigneeUserId },
        );
    }

    addContact(prospectId: number, contactId: number): Observable<void> {
        return this.http.request(
            'PUT',
            `${this.baseUrl}/${prospectId}/relatedContact/${contactId}`,
            { id: prospectId },
        );
    }

    removeContact(prospectId: number, contactId: number): Observable<void> {
        return this.http.request(
            'DELETE',
            `${this.baseUrl}/${prospectId}/relatedContact/${contactId}`,
        );
    }

    getCommentResource(prospectId: number): CommentsResourceInterface {
        const url = `${this.baseUrl}/${prospectId}/comment`;
        return {
            query: (params) => {
                const $promise = this.http.request(
                    'GET',
                    url,
                    params,
                ).toPromise();
                return { $promise };
            },
            add: (params, body, successHandler: (data?: object) => void, errorHandler) => {
                const $promise = this.http.request(
                    'POST',
                    url,
                    {},
                    body,
                ).toPromise().then(successHandler, ({ error: data }) => errorHandler({ data }));
            },
        };
    }
    // @TODO: remove this and use from common src/lender/common/services/common-investor-comments.service.ts
    getCommentTypes<T = CommentType[]>() {
        return this.http.request<T>(
            'GET',
            `${apiPath}/commenttype`,
        )
    }

    getProspectsChannels( activeOnly: boolean, accessibleOnly: boolean): Observable<InvestorChannels[]> {
        return this.http.request<InvestorChannels[]>(
            'GET',
            `${apiPath}/lenders/${this.lenderId}/channels`,
            {
                activeOnly,
                accessibleOnly,
            }
        ).pipe(shareReplay(1));
    }

    getProspectsAssignee(): Observable<Entity[]> {
        return this.http.request<Entity[]>(
            'GET',
            `${this.baseUrl}/assignee-options`,
        ).pipe(shareReplay(1));
    }

    getProspectsStatuses(): Observable<ProspectStatus[]> {
        return this.http.request<ProspectStatus[]>(
            'GET',
            `${this.baseUrl}/statuses`,
        ).pipe(shareReplay(1));
    }

    getChannelAssigneeOptions(id: number): Observable<Entity[]> {
        return this.http.request<Entity[]>(
            'GET',
            `${this.baseUrl}/${id}/channel-assignee-options`,
        ).pipe(shareReplay(1));
    }

    bulkReassign(params: ProspectAssignment): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${this.baseUrl}/bulk-reassign`,
            {},
            params,
        );
    }

    getHistoryList(prospectId: number, params: ListParams): Observable<PagedData<ProspectHistory[]>> {
        return this.http.pagedRequest<ProspectHistory[]>(
            'GET',
            `${this.baseUrl}/${prospectId}/history`,
            params,
        );
    }
}
