import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

import { NgResourceObject } from 'commons/interfaces';

import { WaiveData, modalTitles } from '../../../documents/client-documents.interface';

@Component({
	templateUrl: './waive-document-history-modal.component.html',
})
export class WaiveDocumentHistoryModalComponent {
	snapshotTitles: modalTitles;
	snapshotData: NgResourceObject<WaiveData>;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			resolving: false,
			snapshotData: {
				comment: null,
				waiveType: null,
			},
		});
	}
}
