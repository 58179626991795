import { UserProfile } from 'angularjs-providers/user.provider';

const getLenderPermissions = (user: UserProfile, env) => ({
	permissions: new Map([
		[ 'VIEW_USERS', { title: 'View Users' } ],
		[ 'MANAGE_USERS', { title: 'Manage User', dependsOn: ['VIEW_USERS'] } ],
		[ 'EXPORT_USERS', { title: 'Export Users', dependsOn: ['VIEW_USERS'] } ],
		[ 'VIEW_ROLES', { title: 'View Roles' } ],
		[ 'MANAGE_ROLES', { title: 'Manage Roles', dependsOn: ['VIEW_ROLES'] } ],

		[ 'MANAGE_SOLUTION_CENTER', { title: 'Manage Solution Center' } ],

		[ 'MANAGE_CUSTOM_FIELDS', { title: 'Manage Custom Fields' } ],
        [ 'MANAGE_INVESTOR_SHARING', { title: 'Manage Investor Sharing' } ],
		[ 'VIEW_APPLICATION_CODES', { title: 'View Application Code' } ],
		[ 'MANAGE_APPLICATION_CODES', { title: 'Manage Application Code', dependsOn: ['VIEW_APPLICATION_CODES'] } ],

		[ 'VIEW_GLOBAL_DOCUMENTS', { title: 'View Global' } ],
		[ 'MANAGE_GLOBAL_DOCUMENTS', { title: 'Manage Global', dependsOn: ['VIEW_GLOBAL_DOCUMENTS'] } ],
		[ 'VIEW_CLIENT_DOCUMENTS', { title: 'View Client' } ],
		[ 'MANAGE_CLIENT_DOCUMENTS', { title: 'Manage Client', dependsOn: ['VIEW_CLIENT_DOCUMENTS'] } ],
		[ 'UPLOAD_CLIENT_DOCUMENTS', { title: 'Upload Client', dependsOn: ['VIEW_CLIENT_DOCUMENTS'] } ],
		[ 'VIEW_INTERNAL_DOCUMENTS', { title: 'View Internal' } ],
		[ 'MANAGE_INTERNAL_DOCUMENTS', { title: 'Manage Internal', dependsOn: ['VIEW_INTERNAL_DOCUMENTS'] } ],
		[ 'VIEW_DOCUMENT_LIBRARY', { title: 'View Document Library' } ],
		[ 'REQUEST_DOCUMENT_LIBRARY_DOC', { title: 'Request Document From Library', dependsOn: ['VIEW_DOCUMENT_LIBRARY'] } ],

		[ 'VIEW_SCORECARDS', { title: 'View Scorecard' } ],
		[ 'REVIEW_FINDINGS', { title: 'Review Findings', dependsOn: ['VIEW_SCORECARDS'] } ],
		[ 'VIEW_ALERTS', { title: 'View Alerts', dependsOn: ['VIEW_SCORECARDS'] } ],
		[ 'EDIT_ALERTS', { title: 'Edit Alerts', dependsOn: ['VIEW_SCORECARDS', 'REVIEW_FINDINGS', 'VIEW_ALERTS'] } ],

		[ 'EDIT_STATUS', { title: 'Edit Status' } ],
		[ 'INVITE_TO_APPLY', { title: 'Invite to Apply' } ],
		[ 'EXPORT_APPLICATIONS', { title: 'Export Application' } ],
		[ 'PRINT_PACKAGE', { title: 'Print Package' } ],

		[ 'VIEW_GLOBAL_QUESTIONNAIRE', { title: 'View Global Questionnaires' } ],
		[ 'MANAGE_GLOBAL_QUESTIONNAIRE', { title: 'Manage Global Questionnaires', dependsOn: ['VIEW_GLOBAL_QUESTIONNAIRE'] } ],
		[ 'VIEW_CLIENT_QUESTIONNAIRE', { title: 'View Client Questionnaires' } ],
		[ 'ASSIGN_QUESTIONNAIRE', { title: 'Assign Client Questionnaires', dependsOn: ['VIEW_CLIENT_QUESTIONNAIRE'] } ],

		[ 'EDIT_AA_ASSIGNMENT', { title: 'Edit Assignment' } ],
		[ 'EDIT_CLIENT_UID', { title: 'Edit Client UID' } ],
		[ 'EXPORT_CLIENTS', { title: 'Export Clients' } ],
		[ 'EDIT_RELATIONSHIP_STATUS', { title: 'Edit Relationship Status' } ],
		[ 'EDIT_LOCATION_STATUS', { title: 'Edit Location Status' } ],
		[ 'EDIT_LOCATION_ASSIGNMENT', { title: 'Edit Location Assignment' } ],

		[ 'VIEW_CHANNEL_SETTINGS', { title: 'View Channel Settings' } ],
		[ 'MANAGE_CHANNEL_SETTINGS', { title: 'Manage  Channel Settings', dependsOn: ['VIEW_CHANNEL_SETTINGS'] } ],

		[ 'VIEW_PRIVATE_COMMENTS', { title: 'View Private' } ],
		[ 'ADD_PRIVATE_COMMENTS', { title: 'Add Private', dependsOn: ['VIEW_PRIVATE_COMMENTS'] } ],

		// [ 'VIEW_TERRITORIES', { title: 'View Territories', disabled: () => (!user?.crmEnabled) } ],
		// [ 'MANAGE_TERRITORIES', { title: 'Manage Territories', dependsOn: ['VIEW_TERRITORIES'], disabled: () => (!user.crmEnabled) } ],
		[ 'ADD_PROSPECT', { title: 'Add Prospect', disabled: () => (!user?.crmEnabled) } ],
		[ 'ASSIGN_PROSPECT', { title: 'Assign Prospect', dependsOn: ['ADD_PROSPECT'], disabled: () => (!user?.crmEnabled) } ],
		[ 'MANAGE_CONTACT_LIST', { title: 'Manage Contact List', disabled: () => (!user?.crmEnabled) } ],
		[ 'EXPORT_CONTACT_LIST', { title: 'Export Contact List', disabled: () => (!user?.crmEnabled) } ],
		[ 'ADD_CONTACT_TO_LIST', { title: 'Add Contact to Contact List', disabled: () => (!user?.crmEnabled) } ],
		[ 'DELETE_CONTACT_FROM_LIST', { title: 'Delete Contact from Contact List', disabled: () => (!user?.crmEnabled) } ],

		[ 'VIEW_FINANCIALS', { title: 'View Financials' } ],
		[ 'REQUEST_FINANCIALS', { title: 'Request Financials', dependsOn: ['VIEW_FINANCIALS'] } ],
        [ 'TPO_VIEW_GLOBAL_FINANCIAL', { title: 'View Global Financials' } ],
        [ 'TPO_MANAGE_GLOBAL_FINANCIALS', { title: 'Manage Global Financials', dependsOn: ['TPO_VIEW_GLOBAL_FINANCIAL'] } ],
	]),
    sections: new Map([
        ['User Permissions', { groups: ['User Permissions'] }],
		['Solution Center', {
			groups: ['Solution Center']
		}],
        ['Third Party Oversight', {
            enabled: () => user.isLender,
            groups: ['Administration', 'Manage Documents', 'Scorecard and Alerts', 'Manage Applications', 'Questionnaires', 'Manage Clients', 'Channel Settings', 'Manage Comments', 'Marketing', 'Financials'],
        }],
    ]),
	grouping: new Map([
		[ 'User Permissions', {
			title: 'User Permissions',
			columns: [
				[ 'VIEW_USERS', 'MANAGE_USERS', 'EXPORT_USERS' ],
				[ 'VIEW_ROLES', 'MANAGE_ROLES' ],
			],
		}],
		[ 'Solution Center', {
			title: 'Administration',
			columns: [
				[ 'MANAGE_SOLUTION_CENTER' ],
			],
		}],
		[ 'Administration', {
			title: 'Administration',
			columns: [
				[ 'MANAGE_CUSTOM_FIELDS', 'MANAGE_INVESTOR_SHARING' ],
				[ 'VIEW_APPLICATION_CODES', 'MANAGE_APPLICATION_CODES' ],
			],
		}],
		[ 'Manage Documents', {
			title: 'Manage Documents',
			columns: [
				[ 'VIEW_GLOBAL_DOCUMENTS', 'MANAGE_GLOBAL_DOCUMENTS', 'VIEW_CLIENT_DOCUMENTS', 'MANAGE_CLIENT_DOCUMENTS', 'UPLOAD_CLIENT_DOCUMENTS' ],
				[ 'VIEW_INTERNAL_DOCUMENTS', 'MANAGE_INTERNAL_DOCUMENTS', 'VIEW_DOCUMENT_LIBRARY', 'REQUEST_DOCUMENT_LIBRARY_DOC' ],
			],
		}],
		[ 'Scorecard and Alerts', {
			title: 'Scorecard and Alerts',
			columns: [
				[ 'VIEW_SCORECARDS', 'REVIEW_FINDINGS' ],
				[ 'VIEW_ALERTS', 'EDIT_ALERTS' ],
			],
		}],
		[ 'Manage Applications', {
			title: 'Manage Applications',
			columns: [
				[ 'EDIT_STATUS', 'INVITE_TO_APPLY' ],
				[ 'EXPORT_APPLICATIONS', 'PRINT_PACKAGE' ],
			],
		}],
		[ 'Questionnaires', {
			title: 'Questionnaires',
			columns: [
				[ 'VIEW_GLOBAL_QUESTIONNAIRE', 'MANAGE_GLOBAL_QUESTIONNAIRE' ],
				[ 'VIEW_CLIENT_QUESTIONNAIRE', 'ASSIGN_QUESTIONNAIRE' ],
			],
		}],
		[ 'Manage Clients', {
			title: 'Manage Clients',
			columns: [
				[ 'EDIT_AA_ASSIGNMENT', 'EDIT_CLIENT_UID', 'EXPORT_CLIENTS' ],
				[ 'EDIT_RELATIONSHIP_STATUS', 'EDIT_LOCATION_STATUS', 'EDIT_LOCATION_ASSIGNMENT' ],
			],
		}],
		[ 'Channel Settings', {
			title: 'Channel Settings',
			columns: [
				[ 'VIEW_CHANNEL_SETTINGS' ],
				[ 'MANAGE_CHANNEL_SETTINGS' ],
			],
		}],
		[ 'Manage Comments', {
			title: 'Manage Comments',
			columns: [
				[ 'VIEW_PRIVATE_COMMENTS' ],
				[ 'ADD_PRIVATE_COMMENTS' ],
			],
		}],
		[ 'Marketing', {
			title: 'Marketing',
			columns: [
				// [ 'VIEW_TERRITORIES', 'MANAGE_TERRITORIES', 'ADD_PROSPECT', 'ASSIGN_PROSPECT' ],
				[ 'ADD_PROSPECT', 'ASSIGN_PROSPECT' ],
				[ 'MANAGE_CONTACT_LIST', 'EXPORT_CONTACT_LIST', 'ADD_CONTACT_TO_LIST', 'DELETE_CONTACT_FROM_LIST' ],
			],
		}],
        [ 'Financials', {
            title: 'Financials',
            columns: [
                [ 'VIEW_FINANCIALS', 'REQUEST_FINANCIALS' ],
                [ 'TPO_VIEW_GLOBAL_FINANCIAL', 'TPO_MANAGE_GLOBAL_FINANCIALS' ],
            ],
        }],
	]),
});

export { getLenderPermissions };
