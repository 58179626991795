import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class AccountInformationResourceService {
	public readonly clientDetails: any;
    public readonly canAccessClientUID: any;
    public readonly clientUID: any;
	public readonly nmlsClientDetails: any;

	constructor(
		public injector: Injector,
		public User: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');

		const defaultParams = User.profile.organization.type === 'TPO' ? { tpoId: User.profile.organization.id } : {};

		this.clientDetails = $resource(`${apiPath}/tpos/:tpoId/company-information`, {
			...defaultParams,
		}, {
			get: {
				method: 'GET',
			},
			update: {
				method: 'POST',
			},
		});

        this.canAccessClientUID = $resource(`${apiPath}/tpos/:tpoId/company-information/can-access-client-uid`, {
        }, {
            get: {
                method: 'GET',
            },
        });

        this.clientUID = $resource(`${apiPath}/tpos/:tpoId/company-information/client-uid`, {
        }, {
            set: {
                method: 'PUT',
            },
        });

		this.nmlsClientDetails = $resource(`${apiPath}/nmls/company/:nmlsId/company-information`, {
			...defaultParams,
		}, {
			get: {
				method: 'GET',
			},
		});
	}
}
