<div class="edit-questionnaire">
	<div class="remote-resource" [class.resolved]="resolved">
		<div class="alert alert-warning text-center" *ngIf="questionsCantBeReached.length && !isClone">
			<b>Question{{questionsCantBeReached.length > 1 ? 's' : ''}} {{questionsCantBeReached.join(', ')}} will not be shown</b>
		</div>

		<form [formGroup]="form" (onValidSubmit)="save()" autocomplete="off" role="presentation" class="mb20">
			<div class="flex-row mb20 relative" *ngIf="!editing">
                <div class="flex-grow">
					<label class="control-label">Status:</label>&nbsp;
					<toggle
						[value]="item?.status === 'A'"
						(toggle)="toggleStatus()"
						[loading]="toggleLoading"
					></toggle>
				</div>
                <div *ngIf="menu.length" dropdown>
                    <a dropdownToggle class="btn btn-icon btn-tertiary"><span class="c-icon c-icon-dot-3"></span></a>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                        <li role="menuitem" *ngFor="let item of menu" [ngSwitch]="!!item.click">
                            <a *ngSwitchCase="true" (click)="item.click()">{{item.title}}</a>
                            <a *ngSwitchDefault [uiSref]="item.sref" [uiParams]="item.params">{{item.title}}</a>
                        </li>
                    </ul>
                </div>
			</div>
			<div>
				<form-errors [form]="form"></form-errors>
				<div class="rows-striped" *ngIf="!editing">
					<div class="row">
						<label class="col-sm-3 control-label">Questionnaire Description</label>
						<div class="col-sm-9 control-value">{{ item?.description || '&ndash;' }}</div>
					</div>
					<div class="row">
						<label class="col-sm-3 control-label">Channels</label>
						<div class="col-sm-9 control-value">{{ active?.usedIn || '&ndash;' }}</div>
					</div>
				</div>
				<div class="rows-striped" *ngIf="editing">
					<div class="row form-group" has-error>
						<label class="col-sm-3 control-label required">Questionnaire Name</label>
						<div class="col-sm-4">
							<input class="form-control" autocomplete="off" formControlName="name" maxlength="100">
						</div>
					</div>
					<div class="row form-group" has-error>
						<label class="col-sm-3 control-label">Questionnaire Description</label>
						<div class="col-sm-9">
							<textarea
								class="form-control resize-vertical"
								autocomplete="off"
								formControlName="description"
								rows="3"
								maxlength="200"
							></textarea>
						</div>
					</div>
				</div>

				<div *ngIf="editing" class="text-right">
					<button type="submit" class="btn btn-primary">Save</button>
					<button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
				</div>
			</div>
		</form>

		<ng-container *ngIf="!editing && !isClone && !isNew">
			<questionnaire-list
				[hasOwnLoader]="false"
				(onLoaded)="setListLoaded($event)"
				(onQuestionsCantBeReached)="setQuestionsCantBeReached($event)"
			></questionnaire-list>
		</ng-container>
	</div>
</div>
