import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';

// Services
import { NonQmService } from './non-qm.service';

// Components
import { NonQmComponent } from './non-qm.component';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
	],
	declarations: [
		NonQmComponent,
	],
	providers: [
		NonQmService,
	],
})
export class NonQmModule {}
