import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator } from 'commons/validators';

import { QuestionBankCategoryWithCount } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

@Component({
	templateUrl: './manage-modal.component.html',
})
export class ManageBankCategoryModalComponent implements OnInit {
	onCancel: (params?) => void;
	onConfirm: (params?) => void;

	resolving: boolean = false;

	category?: QuestionBankCategoryWithCount;
	title = '';

	form = new RealmFormGroup({
		name: new RealmFormControl(
			'name',
			{ label: 'Category Name' },
			Validators.compose([ Validators.required, MaxLengthValidator(255) ]),
		),
	});

	constructor(
		public modalRef: BsModalRef,
		protected questionnairesService: LenderManageQuestionnaireService,
	) {}

	ngOnInit() {
		if (!this.category) {
			this.title = 'New Category';
			return;
		}

		const { name } = this.category;
		this.form.patchValue({ name });
		this.title = 'Rename Category';
	}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.onCancel();
	}

	confirm = async (): Promise<void> => {
		if (this.resolving) {
			return;
		}

		this.resolving = true;

		if (this.category) {
			return this.update();
		}

		return this.add();
	}

	add = async (): Promise<void> => {
		try {
			await this.questionnairesService.addBankCategory(this.form.value).$promise;

			this.onConfirm();
			this.onCancel();
		} catch ({ data: response }) {
			this.form.setServerError(response);
			this.resolving = false;
		}
	}

	update = async (): Promise<void> => {
		try {
			await this.questionnairesService.updateBankCategory({
				categoryId: this.category.id,
			}, {
				...this.category,
				...this.form.value,
			}).$promise;

			this.onConfirm();
			this.onCancel();
		} catch ({ data: response }) {
			this.form.setServerError(response);
			this.resolving = false;
		}
	}

	getFormControl(name: string): RealmFormControl {
		return this.form.get(name) as RealmFormControl;
	}
}
