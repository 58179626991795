<ng-form [formGroup]="assignForm">
    <div class="form-group">
        <label class="checkbox-inline">
            <input
                type="checkbox"
                formControlName="_notify_upon_assignment"
                tabindex="1"
            >
            {{ assignForm?.getFieldLabel('_notify_upon_assignment') }}
        </label>
    </div>

    <div *ngIf="assignForm.get('_notify_upon_assignment').value">
        <div class="form-group" has-error>
            <label for="toClients" class="control-label required">
                {{ assignForm?.getFieldLabel('toClients') }}
            </label>
            <div>
                <ng-select
                    id="toClients"
                    formControlName="toClients"
                    [items]="contacts"
                    bindLabel="fullName"
                    [searchable]="true"
                    [multiple]="true"
                    [loading]="!resolved"
                    placeholder="Select Users"
                    [tabindex]="2"
                ></ng-select>
            </div>
        </div>

        <div class="form-group" has-error>
            <label for="ccRealmUserIds" class="control-label">
                {{ assignForm?.getFieldLabel('ccRealmUserIds') }}
            </label>
            <div>
                <ng-select
                    id="ccRealmUserIds"
                    [items]="cc"
                    bindValue="realmUserId"
                    bindLabel="fullName"
                    [searchable]="true"
                    [multiple]="true"
                    [loading]="!resolved"
                    formControlName="ccRealmUserIds"
                    placeholder="Select Users"
                    [tabindex]="3"
                ></ng-select>
            </div>
        </div>

        <div class="form-group" has-error>
            <label for="message" class="control-label required">
                {{ assignForm?.getFieldLabel('message') }}
            </label>
            <div>
                <textarea
                    id="message"
                    class="form-control resize-vertical"
                    formControlName="message"
                    [rows]="9"
                    [maxlength]="messageCharsAmount"
                    [tabindex]="4"
                    placeholder="Enter message"
                ></textarea>
            </div>
            <div class="characters text-muted text-smaller">Symbols: {{getCharsCount()}}</div>
        </div>
    </div>

    <div class="form-group">
        <label class="checkbox-inline">
            <input
                type="checkbox"
                formControlName="_notify_upon_completion"
                tabindex="5"
            >
            {{ assignForm?.getFieldLabel('_notify_upon_completion') }}
        </label>
    </div>

    <div *ngIf="assignForm.get('_notify_upon_completion').value">
        <div class="form-group" has-error>
            <label for="notifyUponCompletionRealmUserIds" class="control-label required">
                {{ assignForm?.getFieldLabel('notifyUponCompletionRealmUserIds') }}
            </label>
            <div>
                <ng-select
                    id="notifyUponCompletionRealmUserIds"
                    [items]="cc"
                    bindValue="realmUserId"
                    bindLabel="fullName"
                    [searchable]="true"
                    [multiple]="true"
                    formControlName="notifyUponCompletionRealmUserIds"
                    placeholder="Select Users"
                    [tabindex]="6"
                ></ng-select>
            </div>
        </div>
    </div>
</ng-form>
