<ng-container *ngIf="!!shareRequests">
	<div>
		<h2>
			{{ header }}
		</h2>
	</div>

	<br />

	<div>
		<div class="rows-bordered">
			<div class="row-fluid header">
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-3">
							Investor
						</div>
						<div class="col-md-2">
							Sharing Status
						</div>
						<div class="col-md-2">
							Source Channel
						</div>
						<div class="col-md-2">
							Destination Channel
						</div>
						<div class="col-md-3">
							Contact Name
						</div>
					</div>
				</div>
				<div class="col-md-2">
				</div>
			</div>
		</div>
		<div class="rows-bordered">
			<div *ngFor="let shareRequest of shareRequests" class="row-fluid">
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-3">
							{{ shareRequest[otherInvestorDesignation].organization.organizationName }}
						</div>
						<div class="col-md-2">
							{{ shareRequest.sharingStatus }}
						</div>
						<div class="col-md-2">
							{{ shareRequest.sourceChannel.channelName }}
						</div>
						<div class="col-md-2">
							{{ shareRequest.targetChannel.channelName }}
						</div>
						<div class="col-md-3">
							{{ shareRequest.contactName }}
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<ng-container *ngFor="let actionButtonConfig of actionButtonConfigs">
						<investor-channel-sharing-table-action-button *ngIf="actionButtonConfig.shouldDisplay(shareRequest)"
							[actionButtonConfig]="actionButtonConfig"
							[shareRequest]="shareRequest"
						></investor-channel-sharing-table-action-button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>
