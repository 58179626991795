<div [ngClass]="className" (fileDrop)=upload($event)>
	<div class="front-face">
		<div [ngClass]="statusClassName"></div>

		<div class="details">
			<div class="title">
				<ng-container [ngSwitch]="!!downloadLink">
					<ng-container *ngSwitchCase="true">
						<a [href]="downloadLink" target="_blank">{{document.name}}</a>
					</ng-container>
					<ng-container *ngSwitchDefault>
						{{document.name}}
					</ng-container>
				</ng-container>
			</div>

			<show-more class="description text-muted" [text]="document.description"></show-more>
		</div>

		<div class="indications">
			<ng-template [ngTemplateOutlet]="indicationsTemplate"></ng-template>
		</div>

		<div class="actions">
			<ng-container [ngSwitch]="!!document.$success">
				<ng-container *ngSwitchCase="true">
					<div class="success">
						<span class="glyphicon glyphicon-ok-sign"></span>
						<span>Done</span>
					</div>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<ng-template [ngTemplateOutlet]="actionsTemplate"></ng-template>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div class="back-face flex-row center gap">
		<span>{{document.$error}}</span>
		<button type="button" class="btn btn-primary" (click)="discardError()">OK</button>
	</div>
</div>
