<h2 class="gray-head">Due Diligence</h2>
<div class="row">
    <div class="col-md-2">
        <span class="text-muted">
            Status
        </span>
    </div>
    <div class="col-md-2">
        <span class="text-muted">
            Status Date From
        </span>
    </div>
    <div class="col-md-2">
        <span class="text-muted">
            Status Date To
        </span>
    </div>
    <div class="col-md-6">
        <span class="text-muted">
            Assigned
        </span>
    </div>
</div>
<div class="row">
    <div class="col-md-2">
        <ng-select
            [multiple]="true"
            tabindex="1"
            [items]="statusesList"
            bindLabel="name"
            bindValue="id"
            placeholder="Select status"
            (change)="setStatusFilter($event)"
            [ngModel]="params.status"
        >
        </ng-select>
    </div>
    <div class="col-md-2">
        <input type="text"
               class="form-control"
               bsDatepicker
               [bsConfig]="bsConfig"
               [value]="toDatePickerReadableFormat(params.dateFrom) | date: 'MM/dd/YYYY'"
               placeholder="mm/dd/yyyy"
               (bsValueChange)="setFromDateFilter($event)"
        />
    </div>
    <div class="col-md-2">
        <input type="text"
               class="form-control"
               bsDatepicker
               [bsConfig]="bsConfig"
               [value]="toDatePickerReadableFormat(params.dateTo) | date: 'MM/dd/YYYY'"
               placeholder="mm/dd/yyyy"
               (bsValueChange)="setToDateFilter($event)"
        />
    </div>
    <div class="col-md-2">
        <ng-select
            tabindex="4"
            [items]="assignedUsersList"
            bindLabel="name"
            bindValue="id"
            placeholder="All"
            [clearable]="false"
            (change)="setAssignedFilter($event)"
            [ngModel]="params.assigned"
        >
        </ng-select>
    </div>
    <div class="col-md-4">
        <a
            *ngIf="filtersApplied"
            (click)="resetFilters()"
        >
            Reset Filters
        </a>
    </div>
</div>
<br>
<div class="rows-bordered">
    <div class="row-fluid header">
        <div class="col-md-3"></div>
        <sortable-header class="col-md-3" columnName="statusId">Status</sortable-header>
        <sortable-header class="col-md-2" columnName="statusDate">Status Date</sortable-header>
        <sortable-header class="col-md-3" columnName="assignedFullName">Assigned</sortable-header>
        <sortable-header class="col-md-1" columnName="id">Review ID</sortable-header>
    </div>
</div>
<reset-filters></reset-filters>
<div class="rows-bordered">
    <div *ngIf="!resolving && resolved && ((list | async)?.length > 0); else noResults">
        <div
            *ngFor="let item of list | async"
            class="row-fluid table-row"
        >
            <due-diligence-item
                [statusTransitions]="statusTransitions"
                [assignedUsers]="assignedUsersList"
                [item]="item"
                (refreshPage)="updateList()"
            >
            </due-diligence-item>
        </div>
    </div>
    <ng-template #noResults>
        <div class="col-sm-12 text-center text-light text-bigger table-row remote-resource" [class.resolved]="!resolving && resolved">
            {{filtersApplied ? 'No matches found' : 'No results found'}}
        </div>
    </ng-template>
    <br>
    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>
