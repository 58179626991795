import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { UIRouterGlobals } from '@uirouter/core';
import { Subject } from 'rxjs';

import { StateService } from 'angularjs-providers/state.provider';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FileSizeValidator, MaxLengthValidator } from 'commons/validators';
import { ConfirmModalComponent } from 'commons/components/modals';

import { ComergenceDocument, ComergenceLinkedDocumentCount } from '../../../comergence-documents.interface';
import { ComergenceDocumentsService, ComergenceDocumentWithFile } from '../../../comergence-documents.service';

function ExpirationDaysValidator(): ValidatorFn {
	return ({ value }: AbstractControl): { [key: string]: any } | null => {
		const isEmpty: boolean = (
			value === undefined
			|| value === null
			|| value === ''
		);
		const isValid: boolean = (
			/^\d+$/.test(value)
			&& value >= 1
			&& value <= 9999
		);
		return (
			isEmpty
			|| isValid
		)
			? null
			: {
				expirationDays: {
					value,
					message: 'The number of days for expiration cannot be less than 1 and greater than 9999 days',
				},
			};
	};
}

@Component({
	templateUrl: './comergence-global-document-details.component.html',
})
export class ComergenceGlobalDocumentDetailsComponent implements OnInit, OnDestroy {
	user: UserProfile;
	params: any;

	readonly idFormControl: RealmFormControl = new RealmFormControl(
		'id',
		{},
	);
	readonly nameFormControl: RealmFormControl = new RealmFormControl(
		'name',
		{
			label: 'Document Name',
			updateOn: 'change',
		},
		[
			Validators.required,
			MaxLengthValidator(100),
		]);
	readonly descriptionFormControl: RealmFormControl = new RealmFormControl(
		'description',
		{
			label: 'Description',
			updateOn: 'change',
		},
		[
			Validators.required,
			MaxLengthValidator(2000),
		]);
	readonly expirationDaysFormControl: RealmFormControl = new RealmFormControl(
		'expirationDays',
		{
			label: 'Expires in',
			updateOn: 'change',
		},
		[
			Validators.required,
			ExpirationDaysValidator(),
		]);
	readonly hasTemplateFormControl: RealmFormControl = new RealmFormControl(
		'hasTemplate',
		{});
	readonly templateNameFormControl: RealmFormControl = new RealmFormControl(
		'templateName',
		{});
	readonly templateFileFormControl: RealmFormControl = new RealmFormControl(
		'templateFile',
		{},
		[
			FileSizeValidator(20 * Math.pow(2, 20)),
		]);
	readonly documentDetailsFormGroup: RealmFormGroup = new RealmFormGroup({
		id: this.idFormControl,
		name: this.nameFormControl,
		description: this.descriptionFormControl,
		expirationDays: this.expirationDaysFormControl,
		hasTemplate: this.hasTemplateFormControl,
		templateName: this.templateNameFormControl,
		templateFile: this.templateFileFormControl,
	});
	form = new RealmFormGroup({ documentDetails: this.documentDetailsFormGroup });
	formCache;

	resolved = true;
	gettingLinkedDocumentCount = false;
	discontinuingDocument = false;
	activatingDocument = false;
	unsubscribe$ = new Subject<void>();

	public canEdit = false;
	private create = false;
	private edit = false;

	document: ComergenceDocument;
	templateDownloadLink = '';
	private templateFileBackup = '';
	private templateRemoved = false;
	private templateChanged = false;

	private modalRef: BsModalRef;

	get title(): string {
		return this.create
			? 'Add New Document'
			: this.document?.name || 'Document Information';
	}

	constructor(
		private readonly stateService: StateService,
		private readonly routerGlobals: UIRouterGlobals,
		private readonly userService: UserService,
		private readonly comergenceDocumentsService: ComergenceDocumentsService,
		private readonly modalService: BsModalService,
	) {
		this.user = this.userService.profile;

		const {
			id: tpoId,
			documentId,
		} = this.routerGlobals.params;

		this.params = {
			tpoId,
			...(documentId && { documentId }),
		};

		this.create = !documentId;
	}

	async ngOnInit(): Promise<void> {
		if (!this.create) {
			await this.retrieveDocument();

			this.canEdit = this.user.can('CCM_MANAGE_DOCUMENT_LIBRARY');

			this.initEditForm();
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	get isViewing(): boolean {
		return !(this.create || this.edit);
	}

	public toggleEdit(edit: boolean): void {
		this.edit = edit;
	}

	public cancelEdit(): void {
		if (this.create) {
			this.stateService.go('^');
		} else {
			if (this.document) {
				const {
					name,
					description,
					expirationDays,
				} = this.document;
				this.form.patchValue({
					documentDetails: {
						name,
						description,
						expirationDays,
					},
				});
			}

			if (
				this.templateRemoved
				|| this.templateChanged
			) {
				this.templateNameFormControl.setValue(this.templateFileBackup);
				this.templateRemoved = false;
				this.templateChanged = false;
			}

			this.toggleEdit(false);
		}
	}

	public setTemplate(input?): void {
		this.templateFileBackup = this.templateNameFormControl.value;

		let hasTemplate;
		let templateName;
		let templateFile;

		if (input) {
			templateFile = input.target
				? input.target.files[0]
				: input[0];
		}

		if (templateFile) {
			hasTemplate = true;
			templateName = templateFile.name.replace(/\.\w+$/, '');
		}

		this.templateRemoved = false;
		this.templateChanged = false;
		if (this.document?.hasTemplate) {
			if (!hasTemplate) {
				this.templateRemoved = true;
			} else {
				this.templateChanged = true;
			}
		} else if (hasTemplate) {
			this.templateChanged = true;
		}

		this.documentDetailsFormGroup.patchValue({
			hasTemplate,
			templateName,
			templateFile,
		});
	}

	public async discontinueOrActivate(): Promise<void> {
		if (this.document) {
			if (this.document.isActive) {
				return await this.discontinue();
			} else {
				return await this.activate();
			}
		}
	}

	private async discontinue(): Promise<void> {
		const linkedDocumentCount: ComergenceLinkedDocumentCount = await this.getLinkedLibraryDocumentCount();
		try {
			await this.showDiscontinueModal(linkedDocumentCount);

			// Reaching this point means the user confirmed.
			this.document = await this.discontinueLibraryDocument();

			this.closeModal();
			this.returnToDocumentsList('discontinued');
		} catch (e) {
			this.closeModal();
		}
	}

	private async activate(): Promise<void> {
		try {
			await this.showActivateModal();

			// Reaching this point means the user confirmed.
			this.document = await this.activateLibraryDocument();

			this.closeModal();
			this.returnToDocumentsList('activated');
		} catch (e) {
			this.closeModal();
		}
	}

	public async submit(): Promise<void> {
		this.resolved = false;

		try {
			if (this.create) {
				await this.saveNewDocument();
			} else if (this.edit) {
				await this.saveExistingDocument();
			}
		} catch (e) {
			this.form.setServerError(e.data || e);
		}

		this.resolved = true;
	}

	private initEditForm(): void {
		this.resolved = false;

		try {
			const {
				id: documentId,
				libraryId,
				name,
				description,
				expirationDays,
				fileName: templateName,
			} = this.document;

			this.form.patchValue({
				documentDetails: {
					libraryId,
					name,
					description,
					expirationDays,
					templateName,
				},
			})

			this.templateDownloadLink = this.comergenceDocumentsService.getTemplateDownloadLink({
				documentId,
				libraryId,
			});

			this.templateRemoved = false;
			this.templateChanged = false;
			this.templateFileBackup = null;
		} catch (e) {
		}

		this.resolved = true;
	}

	private async retrieveDocument(): Promise<void> {
		this.resolved = false;

		const { documentId } = this.params;
		this.document = await this.comergenceDocumentsService.getLibraryDocument({
			...this.params,
			documentId,
		}).$promise;

		this.resolved = true;
	}

	// TODO: move to service resource transformRequest
	private get formData(): FormData {
		const {
			documentDetails: {
				name,
				description,
				expirationDays,
				templateFile,
			},
		} = this.form.getRawValue();
		const rawFormData = {
			name,
			description,
			expirationDays,
		};
		const formData: FormData = new FormData();
		formData.append('docManagementString', JSON.stringify(rawFormData));
		formData.append('file', templateFile);
		return formData;
	}

	private async saveNewDocument(): Promise<void> {
		const payload: FormData = this.formData;
		this.document = await this.comergenceDocumentsService.createLibraryDocument(
			payload,
		).$promise;

		this.returnToDocumentsList('created');
	}

	private async saveExistingDocument(): Promise<void> {
		const {
			document: {
				id,
				documentState,
			},
			templateRemoved,
			templateChanged,
		} = this;
		const {
			documentDetails: {
				name,
				description,
				expirationDays,
				templateFile,
			},
		} = this.form.getRawValue();
		const payload: ComergenceDocumentWithFile = {
			id,
			name,
			description,
			expirationDays,
			templateFile,
			templateRemoved,
			templateChanged,
			documentState,
		};

		try {
			this.document = await this.comergenceDocumentsService.updateLibraryDocument(
				this.params,
				payload).$promise;

			this.initEditForm();

			this.cancelEdit();
		} catch (e) {
			if (e.data) {
				this.form.setServerError(e.data);
			}
		}
	}

	private async getLinkedLibraryDocumentCount(): Promise<ComergenceLinkedDocumentCount> {
		this.gettingLinkedDocumentCount = true;

		const linkedDocumentCount: ComergenceLinkedDocumentCount = await
			this.comergenceDocumentsService.getLinkedLibraryDocumentCount(this.params).$promise;

		this.gettingLinkedDocumentCount = false;

		return linkedDocumentCount;
	}

	private async showDiscontinueModal(linkedDocumentCount: ComergenceLinkedDocumentCount): Promise<void> {
		const linkedDocumentCountMessage = this.createLinkedDocumentCountMessage(linkedDocumentCount);
		const discontinuePromise: Promise<void> = new Promise((resolve, reject): void => {
			const initialState = {
				title: 'Discontinue Document',
				message: linkedDocumentCountMessage,
				confirmText: 'Confirm',
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});

		return discontinuePromise;
	}

	private async showActivateModal(): Promise<void> {
		const confirmationMessage = 'Are you sure you want to activate this Document?';
		const activatePromise: Promise<void> = new Promise((resolve, reject): void => {
			const initialState = {
				title: 'Activate Document',
				message: confirmationMessage,
				confirmText: 'Confirm',
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});

		return activatePromise;
	}

	private async discontinueLibraryDocument(): Promise<ComergenceDocument> {
		this.discontinuingDocument = true;

		try {
			const discontinuedDocument: ComergenceDocument = await this.comergenceDocumentsService.discontinueLibraryDocument(this.params, null).$promise;

			return discontinuedDocument;
		} finally {
			this.discontinuingDocument = false;
		}
	}

	private async activateLibraryDocument(): Promise<ComergenceDocument> {
		this.activatingDocument = true;

		try {
			const activatedDocument: ComergenceDocument = await this.comergenceDocumentsService.activateLibraryDocument(this.params, null).$promise;

			return activatedDocument;
		} finally {
			this.activatingDocument = false;
		}
	}

	private closeModal(): void {
		this.modalRef?.hide();
	}

	private createLinkedDocumentCountMessage(linkedDocumentCount: ComergenceLinkedDocumentCount): string {
		if (linkedDocumentCount.linkedDocumentsCount > 0) {
			const noun = ((linkedDocumentCount.linkedDocumentsCount == 1) ? 'lender is' : 'lenders are');
			const message = `Hold up! ${linkedDocumentCount.linkedDocumentsCount} ${noun} currently linked to this document. Are you sure you want to discontinue?`;

			return message;
		} else {
			const message = 'Are you sure you want to discontinue this Document?';

			return message;
		}
	}

	private returnToDocumentsList(notificationType: string): void {
		const href = this.stateService.href('documents.:documentId', { documentId: this.document.id });
		const link = `<a class="underlined text-success" href="${href}">View Document</a>`;
		const notificationMessage = `<b>${this.document.name}</b> was ${notificationType} successfully. ${link}`;

		this.stateService.notify('documents.list', {
			notification: {
				type: 'alert-success',
				message: notificationMessage,
				timeout: 10000,
			},
		});
	}
}
