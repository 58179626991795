import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// toggle size is defined in commons/components/toggle/toggle.component.less
const StandardSizes = {
    xs: 14,
    toggle: 20,
    small: 28,
    big: 64,
};
type Sizes = keyof typeof StandardSizes | number;
type Color = 'primary' | 'warning' | 'danger' | 'success' | 'white';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    @Input() set size(size: Sizes) {
        if (StandardSizes[size]) {
            this.diameter = StandardSizes[size];
        } else {
            this.diameter = typeof size == 'string' ? parseFloat(size) : size;
        }
    }

    public diameter: number = StandardSizes.small;

    get radius() {
        return this.diameter / 2 - this.thickness / 2;
    }

    get length() {
        return this.radius * Math.PI * 2;
    }

    constructor(private cd: ChangeDetectorRef) {
    }

    @Input('thickness') set thicknessSetter(size: string | number) {
        this.thickness = typeof size == 'string' ? parseFloat(size) : size;
    }

    public thickness: number = 2;
    public duration = 2.4;

    @HostBinding('class') _class = 'color-primary';

    @Input() set color(color: Color) {
        this._class = `color-${color}`;
    }

}
