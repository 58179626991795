import { RealmFormArray } from 'commons/forms';
import { ContactList, ContactListField, ContactListOption, ContactListSegmentFilterAndClause, ContactListSegmentFilterOrClause, FilterOperationFieldType } from '../../../investor-crm-contact-list.interface';
import { OrClauseFormArray } from './OrClauseFormArray';


export class InvestorContactListClauseManager {
    constructor(
        private readonly orClausesFormArray: RealmFormArray
    ) {
    }

    get orClauseCount(): number {
        return this.orClausesFormArray.length;
    }

    get orClauses(): OrClauseFormArray[] {
        return (this.orClausesFormArray.controls as OrClauseFormArray[]);
    }

    configureFrom(
        contactList: ContactList,
        fieldTypesById: Map<string, ContactListOption>,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>,
    ): void {
        // Clear out existing conditions

        while (this.orClauses.length > 0) {
            const orClause: OrClauseFormArray = this.orClauses[0];
            while (orClause.andClauseCount > 0) {
                this.removeAndClauseFromOrClause(0, 0);
            }
        }

        // Configure from an existing list

        contactList.segmentFilter.orClauses.forEach((orClauseDto: ContactListSegmentFilterOrClause) => {
                this.createOrClauseFrom(
                    orClauseDto,
                    fieldTypesById,
                    fieldsById_ByFieldTypeId,
                    operationsById_ByTypeId
                );
            });
    }

    createOrClauseFrom(
        orClauseDto: ContactListSegmentFilterOrClause,
        fieldTypesById: Map<string, ContactListOption>,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>,
    ): void {
        const orClauseFormArray: OrClauseFormArray = this.addOrClause(false);
        orClauseDto.andClauses.forEach((andClauseDto: ContactListSegmentFilterAndClause) => {
                orClauseFormArray.createAndClauseFrom(
                    andClauseDto,
                    fieldTypesById,
                    fieldsById_ByFieldTypeId,
                    operationsById_ByTypeId
                );
            });
    }

    addOrClause(addDefaultAndClause: boolean): OrClauseFormArray {
        const orClauseFormGroup: OrClauseFormArray = new OrClauseFormArray(addDefaultAndClause);
        this.orClausesFormArray.push(orClauseFormGroup);

        return orClauseFormGroup;
    }

    addAndClauseToOrClause(orClause: OrClauseFormArray): void {
        orClause.addAndClause();
    }

    removeAndClauseFromOrClause(orClauseIndex: number, andClauseIndex: number): void {
        const orClauseFormArray: OrClauseFormArray = (this.orClausesFormArray.controls[orClauseIndex] as OrClauseFormArray);
        orClauseFormArray.removeAndClause(andClauseIndex);

        if (orClauseFormArray.andClauseCount == 0) {
            this.orClausesFormArray.removeAt(orClauseIndex);
        }
    }
}
