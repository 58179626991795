import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';

import { LenderHomeComponent } from './lender-home.component';
import { LenderHomeService } from './lender-home.service';
import { RequestReviewModalComponent } from './sections/share-requests/request-review.modal';
import { InvestorHomeShareRequestsComponent } from './sections/share-requests/investor-home-share-requests.component';
import { InvestorInfoBlockComponent } from './sections/info-block/investor-info-block.component';
import { InvestorTerminatedLendersAcceptComponent } from './sections/terminated-lenders-accept/investor-terminated-lenders-accept.component';
import {
    InvestorTerminatedLendersAcceptModalComponent
} from './sections/terminated-lenders-accept/investor-terminated-lenders-accept.modal.component';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ApplicationsModule } from 'lender/applications';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ComponentsModule,
        PipesModule,
        ApplicationsModule,
    ],
    providers: [
        LenderHomeService,
    ],
    declarations: [
        LenderHomeComponent,
        InvestorHomeShareRequestsComponent,
        RequestReviewModalComponent,
        InvestorInfoBlockComponent,
        InvestorTerminatedLendersAcceptComponent,
        InvestorTerminatedLendersAcceptModalComponent,
    ],
})
export class LenderHomeModule {}
