
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { DropdownRequiredValidator } from 'commons/validators';

const ExpirationDateEditForm = new RealmFormGroup({
	expirationDate: new RealmFormControl(
		'expirationDate',
		{label: 'Expiration Date', updateOn: 'change'},
		DropdownRequiredValidator,
	),
});

export { ExpirationDateEditForm };
