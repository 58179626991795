<client-document
	[document]="document"
	[downloadLink]="document.hasUploadedDocument && downloadLink"
	[templateDownloadLink]="templateDownloadLink"
	(upload)="!document.$pending && canUpload && onFileSelect($event)"
	(showHistory)="showHistory()"
>
	<div class="flex-row right gap-sm remote-resource" [class.resolved]="!resolving">
        <div *ngIf="canManageClientDocs && user.isLender">
            <button *ngIf="!document.reviewedBy && document.hasUploadedDocument" class="btn btn-primary w-auto" (click)="markDocumentReviewed()">
                Mark Reviewed
            </button>
            <div *ngIf="document.reviewedBy">
             <span class="col-sm-3">
                    <div class="glyphicon glyphicon-ok-sign glyphicon-object-align-vertical text-success medium-icon"></div>
                </span>
                <span class="col-sm-9 remove-right-padding">
                    <div class="text-small font-bold">Reviewed by</div>
                    <div class="text-small text-muted">{{document.reviewedBy}}</div>
                    <div class="text-small text-muted w-auto">{{document.reviewedDate | date: 'MM/dd/yyyy HH:mm'}}</div>
                </span>
            </div>
        </div>

		<div *ngIf="actions.size" class="dropdown" dropdown container="body" [insideClick]="true">
			<a dropdownToggle class="dropdown-toggle btn btn-icon btn-tertiary text-bigger">
				<span class="c-icon c-icon-dot-3"></span>
			</a>
			<ul *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-btn-icon client-document-dropdown-menu" role="menu">
				<li role="menuitem" *ngIf="actions.has('MANAGE')">
					<a (click)="showDetails()">Manage</a>
				</li>

				<li role="menuitem" *ngIf="actions.has('UPLOAD')">
					<label class="file-input">
						Upload
						<input
							type="file"
							[multiple]="false"
							(input)="onFileSelect($event.target.files)"
						/>
					</label>
				</li>

				<li role="menuitem" *ngIf="actions.has('WAIVE')">
					<a (click)="waiveDocument()">Waive</a>
				</li>

                <li role="menuitem" *ngIf="actions.has('REQUEST')">
                    <a (click)="request()">Request</a>
                </li>
			</ul>
		</div>

	</div>
    &nbsp;
</client-document>
