import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from 'tpo/documents/documents.interface';
import { TextEntity } from 'lender/clients/$id/channels/channels.service';

@Pipe({
    name: 'entityNames',
})
export class EntityNamesPipe implements PipeTransform {
    /* Function is too performance heavy */
    transform(items: (Entity|TextEntity)[]): string {
        if (!items?.length) return '–';

        return items.map(v => v.name).join(', ');
    }
}
