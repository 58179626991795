<div class="documents comergence">
	<div class="gray-head">
        <h2>Document Library</h2>
    </div>

    <div class="alert alert-success text-center" *ngIf="notifications.docAdded">
        <span class="glyphicon glyphicon-ok-sign"></span>&nbsp;
        <span>
            <b>{{ notifications.docAdded.name }}</b> was added successfully. 
            <a class="underlined text-success" uiSref="doclibrary.:id.edit({ id: notifications.docAdded.id })">
                View Document
            </a>
        </span>
    </div>
    <div class="alert alert-success text-center" *ngIf="notifications.docDeactivated">
        <span class="glyphicon glyphicon-ok-sign"></span>&nbsp;
        <span>
            <b>{{ notifications.docDeactivated.name }}</b> was discontinued successfully. 
            <a class="underlined text-success" uiSref="doclibrary.:id.edit({ id: notifications.docDeactivated.id })">
                View Document
            </a>
        </span>
    </div>
    <div class="alert alert-success text-center" *ngIf="notifications.docActivated">
        <span class="glyphicon glyphicon-ok-sign"></span>&nbsp;
        <span>
            <b>{{ notifications.docActivated.name }}</b> was activated successfully. 
            <a class="underlined text-success" uiSref="doclibrary.:id.edit({ id: notifications.docActivated.id })">
                View Document
            </a>
        </span>
    </div>

	<ui-view></ui-view>
</div>
