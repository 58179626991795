import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: './upload-image-modal.component.html',
})
export class UploadImageModalComponent {
	upload: (params?) => any;
	resolving: boolean = false;
	error: string;

	constructor(
		public modalRef: BsModalRef,
	) {}

	onPick(files) {
		this.error = '';
		for (const file of files) {
			this.resolving = true;
			this.upload(file).$promise
				.then(
					(data) => {
						this.modalRef.hide();
					},
					(error) => {
						this.error = error.status > 0 ? error.data.message : 'Connection error';
					},
				)
				.finally(() => {
					this.resolving = false;
				});
		}
	}
}
