<ng-container *ngFor="let section of sections">
    <h3>{{section.title}}</h3>
    <permissions-section
        [editing]="editing"
        [groupsToShow]="section.groups"
        [permissionState]="permissionState"
        [permissionType]="permissionType"
    ></permissions-section>
    <br>
</ng-container>
