import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

// Right from Angular code
function isEmptyInputValue(value: any): boolean {
	return value == null || value.length === 0;
}

/**
 * Provides custom
 * @param messageTemplate a template string where {field} will be replaced with field(s) label
 * @constructor
 */
export function CustomRequiredValidator(messageTemplate: string = 'Please enter {field}'): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		return isEmptyInputValue(control.value) ?  {
			customRequired: {
				value: control.value,
				template: messageTemplate,
			},
		} : null;
	};
}
