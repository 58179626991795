import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SharedProspectingModule } from 'shared/prospecting/prospecting.module';

import { ApplicationsCommentsService, ApplicationsCommentsComponent } from './comments';
import { ApplicationsHistoryComponent } from './history/applications-history.component';
import { ChannelApplicationsService } from './channel-applications.service';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        SharedProspectingModule,
        PipesModule,
    ],
    declarations: [
        ApplicationsCommentsComponent,
        ApplicationsHistoryComponent,
    ],
    providers: [
        ChannelApplicationsService,
        ApplicationsCommentsService,
    ],
})
export class ChannelApplicationModule {}
