<div class="gray-head flex-head">
	<div class="col-fit">
		<ul class="breadcrumbs">
			<li>
				<a uiSref="users-management.users">User Management</a>
			</li>
		</ul>
		<h2 class="title-label">
			<span>New User</span>
		</h2>
	</div>
</div>

<div>
	<create-read-only-user-details
		[currentUserProfile]="currentUserProfile"
		[isNew]="isNew"
		(userCreated)="userCreated($event)"
	>
	</create-read-only-user-details>
</div>
