<div class="data-match content">
	<div class="gray-head">
		<h2 class="clearfix">
			<span>New Upload</span>
		</h2>
	</div>
	<div class="data-match-upload remote-resource" [class.resolved]="!isSaving">
		<div class="input-details">
			<h3>Input Details</h3>
			<form [formGroup]="dataMatchForm" name="inputDetails" id="inputDetails" (onValidSubmit)="uploadDataMatch()">
                <form-errors [form]="dataMatchForm"></form-errors>
				<div class="rows-striped">
					<div class="row-fluid">
						<div class="col-md-1"></div>
						<div class="col-md-9">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group row">
										<label class="col-md-4 control-label text-right">Customer</label>
										<div class="col-md-8">
                                            <ng-select
                                                formControlName="lender"
                                                placeholder="Search by Company Name"
                                                [items]="customers"
                                                [loading]="customerSearchLoading"
                                                [typeahead]="customerSearchInput"
                                                (change)="customerSelected($event)"
                                            >
                                                <ng-template ng-label-tmp let-item="item">
                                                    <i class="text-light glyphicon glyphicon-search"></i>&nbsp;
                                                    <span class="text">
                                                        {{ item.name }}
                                                    </span>
                                                </ng-template>

                                                <ng-template ng-option-tmp let-item="item">
                                                    {{ item.name }}
                                                </ng-template>

                                                <ng-template ng-typetosearch-tmp>
                                                    <div class="ng-option disabled">
                                                        Please enter at least 2 characters to start search
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                                    <div *ngIf="searchTerm.length > 1" class="ng-option disabled">
                                                        No account found for "{{searchTerm}}"
                                                    </div>
                                                    <div *ngIf="searchTerm.length <= 1" class="ng-option disabled">
                                                        Please enter at least 2 characters to start search
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
                                                    <div class="ng-option disabled">
                                                        Searching for "{{ searchTerm }}"
                                                    </div>
                                                </ng-template>
                                            </ng-select>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group row">
										<label class="col-md-6 control-label text-right">Channel</label>
										<div
                                            class="col-md-6 remote-resource"
                                            [class.resolved]="!channelsLoading"
                                            [class.curtain]="!dataMatchForm.value.lender"
                                        >
                                            <ng-select
                                                formControlName="channel"
                                                placeholder=""
                                                bindLabel="name"
                                                [items]="channels"
                                                tabindex="2"
                                            >
                                            </ng-select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-2"></div>
					</div>
					<div class="row-fluid">
						<div class="col-md-1"></div>
						<div class="col-md-9">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group row">
										<label class="col-md-4 control-label text-right required">Company Name</label>
										<div class="col-md-8">
											<input type="text"
                                                formControlName="customer"
                                                class="form-control"
                                                maxlength="250"
                                                required
                                                tabindex="1"
                                            />
										</div>
									</div>
								</div>
								<div class="col-md-6">
								</div>
							</div>
						</div>
						<div class="col-md-2"></div>
					</div>
					<div class="row-fluid">
						<div class="col-md-1"></div>
						<div class="col-md-9">
							<div class="form-group row">
								<label class="col-md-2 control-label text-right">Comments</label>
								<div class="col-md-10">
									<textarea
                                        formControlName="comment"
                                        class="form-control"
                                        rows="4"
                                        maxlength="2000"
                                    ></textarea>
								</div>
							</div>
						</div>
						<div class="col-md-2"></div>
					</div>
					<div class="form-group row-fluid">
						<div class="col-md-1"></div>
						<div class="col-md-9">
							<div class="form-group row">
								<label class="col-md-2 control-label text-right required">File</label>
								<div class="col-md-10">
                                    <uploader
                                        #uploader
                                        (select)="fileSelected($event)"
                                        [config]="{ accept: '.csv' }"
                                        [customTemplate]="dataMatchForm.controls.file.value ? fileTemplate : null"
                                    >
                                        <ng-template #fileTemplate>
                                            <div class="uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<span class="flex">
                                                    <span class="c-icon c-icon-file text-muted"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {{ dataMatchForm.controls.file.value.name }}
                                                    </span>
                                                <label class="btn btn-secondary required">
                                                    {{ dataMatchForm.controls.file.value ? 'Replace File' : 'Upload File' }}
                                                    <input
                                                        type="file"
                                                        (input)="uploader.onFileInput($event)"
                                                        [multiple]="uploader.config.multiple"
                                                        [accept]="uploader.config.accept"
                                                    >
                                                </label>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </ng-template>
                                    </uploader>
								</div>
							</div>
						</div>
						<div class="col-md-2"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-1"></div>
					<div class="col-md-9">
						<div *ngIf="!isSaving" class="pull-right">
							<button type="submit" class="btn btn-primary run-verification-btn">
								Verify
							</button>
						</div>
					</div>
					<div class="col-md-2"></div>
				</div>
			</form>
		</div>
	</div>
</div>

