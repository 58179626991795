<div class="row" *ngIf="!isLoaded" >
    <div class="col-md-12">
        <div class="result-controls pull-right">
            <button class="btn btn-primary" type="button" [disabled]="preConditionDisabledLoadClient">
                Load Clients
            </button>
            <button type="button" class="btn btn-tertiary btn-icon" (click)="exportResults()" [disabled]="isLoaded">
                <span class="glyphicon glyphicon-download-alt"></span>
            </button>
        </div>
    </div>
</div>
<div *ngIf="isLoaded">
    <div class="row">
        <div class="col-md-12">
            <h4>Update Relationship Status
                <label class="text-danger">*</label></h4>
        </div>
    </div>
    <form [formGroup]="form" (onValidSubmit)="updateRelationshipStatus()">
        <form-errors [form]="form"></form-errors>
        <div class="rows-striped" >
            <div class="row-fluid">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <label class="col-md-7 form-group checkbox-inline">
                        <input type="checkbox" formControlName="terminate" value="T" (change)="setCheckboxListValue($event)">
                        &nbsp;Terminated Clients to Active
                    </label>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-6">
                    <label class="col-md-6 form-group checkbox-inline">
                        <input type="checkbox" formControlName="cancelled" value="C" (change)="setCheckboxListValue($event)">
                        &nbsp;Cancelled Clients to Active
                    </label>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <label class="col-md-7 form-group checkbox-inline text-justify">
                        <input type="checkbox" formControlName="declined" value="D" (change)="setCheckboxListValue($event)">
                        &nbsp;Declined Clients to Active
                    </label>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-6">
                    <label class="col-md-7 form-group checkbox-inline text-justify">
                        <input type="checkbox" formControlName="noUpdate"(change)="noUpdateCheckboxChange($event)" [checked]="isNoUpdateSelected">
                        &nbsp;No Updates
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="result-controls pull-right">
                    <button class="btn btn-primary" type="submit" [disabled]="isLoadClientButtonDisabled">
                        Load Clients
                    </button>
                    <!--Why do we need this button?-->
                    <button type="button" class="btn btn-tertiary btn-icon" [disabled]="isLoaded">
                        <span class="glyphicon glyphicon-download-alt"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-2"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="form-group no-margin" has-error>
                        <label class="control-label required">Message</label>
                        <textarea
                            #messageInput
                            class="form-control no-resize"
                            rows="7"
                            maxlength="4000"
                            formControlName="message"></textarea>
                        <text-count [control]="messageInput"></text-count>
                    </div>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </form>
</div>
