import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { NgResourceObject } from 'commons/interfaces';

declare const apiPath: string;

interface Exclamation {
	exclamation: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class ListingsStatusResourceService {
	public alertsResource: any;
	public discrepancyResource: any;

	constructor(
		injector: Injector,
		user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');

		// configs
		const baseConfig = {
			url: `${apiPath}/tpos/:tpoId`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const exclamationsResourceConfig = {
			url: `${baseConfig.url}/company-listing-groups/exclamations`,
			params: {
				...baseConfig.params,
			},
		};

		// resources
		this.alertsResource = $resource(
			`${exclamationsResourceConfig.url}/alerts`,
			exclamationsResourceConfig.params,
			{
				get: {
					method: 'GET',
				},
			},
		);
		this.discrepancyResource = $resource(
			`${exclamationsResourceConfig.url}/discrepancy`,
			exclamationsResourceConfig.params,
			{
				get: {
					method: 'GET',
				},
			},
		);
	}

	getAlerts = (...args): NgResourceObject<Exclamation> => this.alertsResource.get(...args);
	getDiscrepancy = (...args): NgResourceObject<Exclamation> => this.discrepancyResource.get(...args);
}
