import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
} from '@angular/core';
import { identity, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StateService, TransitionOptions } from '@uirouter/core';
import { MenuLabelDirective } from 'commons/components/navigation/menu/menu-label.directive';
import { RawParams } from '@uirouter/core/lib/params/interface';

export type SimpleRoute = string;

export interface ExtendedRoute {
	sref: string;
	params?: RawParams;
	options?: TransitionOptions;
	target?: string;
	exclude?: AnyRoute[]; // Ignore matched {sref} if any of these match
    type?: LINK_TYPE.ROUTE;
}

export type AnyRoute = SimpleRoute | ExtendedRoute;

export interface RawLink {
	href: string;
	target?: string;
    type?: LINK_TYPE.HREF;
}

export type OnClickLink = {
    click: () => void;
    type?: LINK_TYPE.CLICK;
}
export type AnyLink = AnyRoute | OnClickLink | RawLink;

export enum LINK_TYPE {
    ROUTE = 'route',
    CLICK = 'click',
    HREF = 'href',
}

export interface GenericMenuItem<T = AnyLink> {
	title: string;
	link?: T; // Leave empty to be filled with first child {link}
	items?: GenericMenuItem<T>[];
	activeRoutes?: T[]; // You don't need to add {link} of itself all of it's children
}

@Component({
	selector: 'generic-menu',
	templateUrl: './generic-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMenuComponent<U extends AnyLink = AnyLink, T extends GenericMenuItem<U> = GenericMenuItem<U>> implements OnInit, OnDestroy {
	@Input() updateOn: Observable<unknown>; //current implementation subscribes only on init and doesn't resubscribe

	protected _items: T[];
	get items(): T[] {
		return this._items;
	}

	@Input('items') set itemsConfig(items: T[]) {
		this._items = this.traverseChildren(items);
		this.changeDetector.markForCheck();
	}

	destroyed$ = new Subject<void>();
    LINK_TYPE = LINK_TYPE;

	protected traverseChildren(items: T[] = []): T[] {
		return items.map(item => this.traverseChildrenInner(item));
	}

	protected traverseChildrenInner({ link, items = [], activeRoutes = [], title, ...rest }: T): T {
		if (!link && !items.length && !activeRoutes) {
			throw new Error(`Menu item "${title}" isn't fully configured`);
		}

		items = this.traverseChildren(items as T[]) as T[];
		activeRoutes = this.filterRoutes([
			...(link ? [link] : []),
			...activeRoutes,
			...this.collectChildrenRoutes(items as T[]),
		]);

		link = this.extendLink(link || items[0]?.link);

		return { link, items, activeRoutes, title, ...rest } as T;
	}

	private collectChildrenRoutes = (items: T[]): U[] =>
		[].concat(...items.map(({ activeRoutes }: T) => activeRoutes));

	private filterRoutes = (links: U[]): U[] => {
		return links
			.filter((link) => typeof link === 'string' || (link as unknown as ExtendedRoute)?.sref)
			.map(this.extendLink);
	}

	private extendLink = (link): U => {
        if (typeof link === 'string') {
            return { sref: link, type: LINK_TYPE.ROUTE } as ExtendedRoute as U;
        }
        if (link?.sref) {
            return { ...link, type: LINK_TYPE.ROUTE } as ExtendedRoute as U;
        }
        if (link?.click) {
            return { ...link, type: LINK_TYPE.CLICK } as OnClickLink as U;
        }
        if (link?.href) {
            return { ...link, type: LINK_TYPE.HREF } as RawLink as U;
        }
    };

	@ContentChild(MenuLabelDirective, { read: TemplateRef, static: true }) labelTemplate: TemplateRef<any>;

	constructor(
		private changeDetector: ChangeDetectorRef,
		public stateService: StateService,
	) {
	}

	ngOnInit() {
		if (this.updateOn instanceof Observable) {
			this.updateOn.pipe(takeUntil(this.destroyed$)).subscribe(() => {
				// console.info('Menu force update')
				this.changeDetector.markForCheck();
			});
		}
	}

	isActive = ({ activeRoutes }: T) =>
		(activeRoutes as unknown as ExtendedRoute[])
			.map(link => {
				const { exclude = [] } = link;
				return this.isActiveMapper(link) && !this.isRouteMatched(exclude)
			})
			.some(identity);

	isRouteMatched = (routes: AnyRoute[]): boolean =>
		routes
			.map(link => this.isActiveMapper(this.extendLink(link) as unknown as ExtendedRoute))
			.some(identity);

	private isActiveMapper = ({ sref, params, options }: ExtendedRoute) =>
		sref && this.stateService.includes(sref, params, options);

	ngOnDestroy() {
		this.destroyed$.next(null);
		this.destroyed$.complete();
	}
}
