import { Component, Input, OnInit } from '@angular/core';

import { ScorecardFinding, ScorecardFindingSupportingDocument } from 'shared/due-diligence/scorecard/scorecard-finding.interface';


@Component({
    selector: 'scorecard-finding-details-external-document-link',
    templateUrl: './scorecard-finding-details-external-document-link.component.html',
})
export class ScorecardFindingDetailsExternalDocumentLinkComponent implements OnInit {
    @Input() tpoId: number;
    @Input() finding: ScorecardFinding;
    @Input() document: ScorecardFindingSupportingDocument;

    href: string = '';

    // Override
    ngOnInit(): void {
        if (this.document.externalDocumentURL) {
            this.href = this.document.externalDocumentURL;
        } else {
            this.href = `/api/rest/tpos/${this.tpoId}/findings/${this.finding.id}/supporting-document/${this.document.id}`;
        }
    }
}
