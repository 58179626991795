import { Component, Input } from '@angular/core';

import { LenderApplicationSummary } from '../applications.service';


@Component({
    selector: 'application-link-dropdown',
	templateUrl: './application-link-dropdown.component.html',
})
export class ApplicationLinkDropdownComponent {
    @Input() userProfileCanPrintPackage: boolean;
    @Input() application: LenderApplicationSummary;
    @Input() userProfileOrganizationId: number;
}
