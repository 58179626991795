<ng-select
	#shareElement
	class="share-to-selector"
	[(ngModel)]="selected"
	(change)="setSelection($event, shareElement)"
	[items]="items"
	bindValue="id"
	bindLabel="name"
	[multiple]="true"
	[closeOnSelect]="false"
	[placeholder]="placeholder"
>
	<ng-template ng-option-tmp let-item="item" let-index="index">
		<div class="entry horizontal-flex-group" [ngClass]="{'bordered': (index + 1) === sepcialOptionsAmount, 'group': item.type === 'group'}">
			<i *ngIf="item.type === 'group'" class="c-icon c-icon-users space-bigger group-icon">&nbsp;</i>
			<div class="full-width">
				{{item.name}}
			</div>
		</div>
	</ng-template>
</ng-select>
