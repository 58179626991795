import { omit, pick, isEmpty } from 'lodash';
import { Component, forwardRef, OnInit, OnDestroy } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { CompaniesListItem, GlobalMarketParams, MarketLocationParams } from '../market.interface';
import { MarketService } from '../market.service';
import { MarketDataService, LOCATION_PARAMS_KEYS } from '../market-data.service';

@Component({
	templateUrl: 'companies-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CompaniesListComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => CompaniesListComponent) },
	],
})
export class CompaniesListComponent extends NewPagedListComponent<CompaniesListItem> implements OnInit, OnDestroy {
	static listName = 'companiesList' as const;

	protected destroyed$: Subject<void> = new Subject();
	locationParams: MarketLocationParams;
	hasProspectPermission: boolean;
	User: UserProfile;

	constructor(
		public router: UIRouter,
		{ profile: User }: UserService,
		private readonly marketService: MarketService,
		private readonly dataService: MarketDataService,
	) {
		super(router);

		this.User = User;
		this.globalParams.push(...LOCATION_PARAMS_KEYS);
		this.nonResettableParams.push(...LOCATION_PARAMS_KEYS);
		this.hasProspectPermission = User.isLender && User.crmEnabled && User.can('ADD_PROSPECT');
		this.locationParams = this.dataService.getLocationParams(router.globals.params as GlobalMarketParams);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.dataService.getLocation$().pipe(
			skip(1),
			takeUntil(this.destroyed$),
		).subscribe(([location, skipRedirect]: [MarketLocationParams, boolean]) => {
			if (location === undefined) {
				this.dataService.setSrefParams(null);
				!skipRedirect && this.router.stateService.transitionTo(
					'crm.market.msa',
					this.dataService.getLocationEmptyParams(),
				);
				return;
			}

			this.locationParams = location;
			!skipRedirect && this.router.stateService.transitionTo(
				'crm.market.companies',
				this.dataService.getLocationParams(this.locationParams),
			);
		});
	}

	protected loadList(listParams: ListParams): Observable<PagedData<CompaniesListItem[]>> {
		this.setLocationSrefParams();

		return this.marketService.getCompaniesList({
			...this.locationParams,
			...listParams,
		});
	}

	hasLocation(params: MarketLocationParams ): boolean {
		return !this.dataService.isEmptyParams(params);
	}

	setLocationSrefParams() {
		const nonDefaultParams = this.pickNonDefaultParams(this.params);
		const filteredListParams = pick(nonDefaultParams, Object.keys(this.defaultParams));
		const listParams = omit(filteredListParams, ['page', 'size', 'sort']);

		if (this.hasLocation(this.locationParams)) {
			this.dataService.setSrefParams({
				...this.locationParams,
				...(!isEmpty(listParams) ? { listParams } : {}),
			});
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();

		this.destroyed$.next();
		this.destroyed$.complete();
	}
}