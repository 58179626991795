import { Component } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'lender-home',
    templateUrl: 'lender-home.component.html',
})
export class LenderHomeComponent {
    userIsLender: boolean = false;
    canViewRequests: boolean = false;

    get count(): number {
        return this.counts.requests + this.counts.approvals;
    }

    counts = {
        requests: Infinity,
        approvals: 0,
    }


    constructor(
        private readonly userService: UserService,
    )
    {
        this.userIsLender = this.userService.profile.isLender;
        this.canViewRequests = this.userService.profile.can('EDIT_RELATIONSHIP_STATUS');
        this.counts.approvals = this.canViewRequests ? Infinity : 0;
    }

    protected readonly Infinity = Infinity;
}
