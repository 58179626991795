import {uniqBy} from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

export const filterUniqueByPipe = (items: any[], property: string) => {
	if (!items) { return []; }
	return uniqBy(items, property) ;
};

@Pipe({
	name: 'arrayUniqueBy',
})
export class ArrayUniqueByPipe implements PipeTransform {
	transform = filterUniqueByPipe;
}
