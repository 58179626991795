<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">Change Optional Status</h4>
</div>
<form [formGroup]="form" (onValidSubmit)="save()">
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form-errors [form]="form"></form-errors>
        <ng-container *ngIf="!isNew; else createFieldsTemplate;">
            <p>Please select Optional Status to assign for this application.</p>

            <div class="form-group no-margin" has-error>
                <ng-select
                        [items]="statusList"
                        [addTag]="addStatus"
                        addTagText="Create"
                        bindLabel="name"
                        [clearable]="false"
                        placeholder="Select"
                        formControlName="optionalStatus"
                ></ng-select>
            </div>
        </ng-container>
        <ng-template #createFieldsTemplate>
            <p class="mb20">Create the name of your status.</p>

            <div class="form-group row" has-error> <!--TODO: try to use ngFor-->
                <label class="col-sm-5 control-label required">
                    {{form.getFieldLabel('newStatusName')}}
                </label>
                <div class="col-sm-7">
                    <input class="form-control" formControlName="newStatusName">
                </div>
            </div>

            <div class="form-group row" has-error>
                <label class="col-sm-5 control-label required">
                    {{form.getFieldLabel('confirmNewStatusName')}}
                </label>
                <div class="col-sm-7">
                    <input (paste)="false" class="form-control" formControlName="confirmNewStatusName">
                </div>
            </div>
        </ng-template>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div class="clearfix">
            <div class="pull-left">
                <button type="button" class="btn btn-default" (click)="toggleIsNewMode()">
                    {{isNew ? 'Back' : 'Create New Status'}}
                </button>
            </div>
            <div class="pull-right">
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" (click)="modalRef.hide()" class="btn btn-default">Cancel</button>
            </div>
        </div>
    </div>
</form>
