<div class="gray-head flex-head">
    <div class="col-fit">
        <h2>
            User Management
        </h2>
    </div>
    <div class="col-min">
        <ul class="col-min tab-menu">
            <li class="tab-menu-item"
                [class.active]="stateService.is('users-management.users')"
                [class.b]="stateService.is('users-management.users')"
            >
                <a uiSref="users-management.users">
                    Users
                </a>
            </li>
            <li class="tab-menu-item"
                [class.active]="!stateService.is('users-management.users')"
                [class.b]="!stateService.is('users-management.users')"
            >
                <a uiSref="users-management.roles">
                    Roles
                </a>
            </li>
        </ul>
    </div>
</div>
<ui-view></ui-view>
