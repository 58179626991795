import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first, defaults, forEach } from 'lodash';
import { Thumbnails } from 'tpo/social-compliance/publisher/attachments/tpo-publisher-attachment.service';
import { NgResource } from 'commons/interfaces';

@Component({
	templateUrl: './edit-thumbnail-modal.component.html',
})
export class EditThumbnailModalComponent implements OnInit {
	thumbnailsResponse: Promise<Thumbnails>;
	attachmentId: number;
	tpoId: number;
	postType: number;
	selectedThumbnails: { [attachmentId: number]: number };
	manuallyThumbnails: { [attachmentId: number]: number };
	onConfirm: (selectedThumbnailId: number, uploadedManuallyThumbnails: number, getUrl: (id) => any) => void;
	uploadManually: ($event) => any;

	onCancel: () => any;
	manuallyThumbnail: { id?: number, $resolved?: boolean, url?: string } = {};
	selectedThumbnailId: number;
	manuallyAnimation: boolean = false;
	resolving: boolean;

	thumbnails: Array<{ id: number, $resolved: boolean, url: string }> = [];
	defaultImage: string;
	validFileTypes = [
		'image/png',
		'image/jpeg',
		'.jpe',
	];

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			onCancel: () => {
				this.modalRef.hide();
			},
			uploadManually: () => null,
			selectedThumbnailId: null,
			selectedThumbnails: null,
			manuallyThumbnail: {},
			resolving: true,
		});
	}

	manuallyUploading(response: NgResource<Thumbnails>): void {
		this.manuallyAnimation = true;

		response.$promise.then((data: Thumbnails) => {
			const { uploadedManuallyThumbnailId: id } = data;
			this.manuallyThumbnail.id = id;
			this.manuallyThumbnail.url = this.getUrl(id);
			this.selectedThumbnailId = id;
			this.manuallyAnimation = false;
		});
	}

	ngOnInit(): void {
		this.thumbnailsResponse.then((data: Thumbnails) => {
			this.resolving = false;
			this.selectedThumbnailId = this.selectedThumbnails && this.selectedThumbnails[this.attachmentId] || data.selectedThumbnailId;
			this.manuallyThumbnail.id = this.manuallyThumbnails && this.manuallyThumbnails[this.attachmentId] || data.uploadedManuallyThumbnailId;

			this.manuallyThumbnail = {
				$resolved: false,
				id: this.manuallyThumbnail.id,
				url: this.getUrl(this.manuallyThumbnail.id),
			};

			if (data.thumbnailIds.length) {
				forEach(data.thumbnailIds, (id) => {
					this.thumbnails.push({
							id,
							$resolved: false,
							url: this.getUrl(id),
						},
					);
				});
			} else {
				const url = this.defaultImage;
				const thumbnail = {
					id: null,
					$resolved: false,
					url,
				};
				this.thumbnails = [thumbnail, thumbnail, thumbnail];
			}
		});
	}

	removeManualThumbnail(): void {
		this.selectedThumbnailId = null;
		this.manuallyThumbnail = {};
	}

	isUploading = (): boolean => (this.manuallyThumbnail?.id && !this.manuallyThumbnail?.$resolved) || this.manuallyAnimation;
	getUrl = (id): string => `/api/rest/tpos/${this.tpoId}/${this.postType}/attachments/video-thumbnails/${id}`;

	setResolved(preview): void {
		preview.$resolved = true;
	}

	validFileType(data: EventTarget | DataTransfer): boolean {
		const { files } = data as HTMLInputElement;
		const isValid = this.validFileTypes.indexOf(first(files).type) !== -1;

		if (!isValid) {
			alert(`You can only upload an image in the format of: PNG, JPG, JPEG, JFIF, PJPEG, PJP, JPE`);
			return false;
		}

		return true;
	}
}
