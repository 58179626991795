import { Component, Input, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';

import { CustomFieldsElement, CustomField } from 'shared/fields/custom-fields.service';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { getParsedValue } from 'commons/directives/forms';
import { PhoneValidator } from 'commons/validators';

interface FieldTypeState {
	processValue?: (value) => unknown;
	validators?: ValidatorFn[],
}

export const fieldStates: { [fieldType: string]: FieldTypeState } = {
	MultipleSelection: {
		processValue: (value): string => JSON.parse(value),
	},
	PhoneNumber: {
		validators: [PhoneValidator()],
	},
	Number: {
		validators: [],
	},
	Date: {
		processValue: (value): Date => new Date(value),
	},
	Email: {
		validators: [Validators.email],
	},
};

@Component({
	templateUrl: './fields-editable.component.html',
	selector: 'fields-editable',
})
export class FieldsEditableComponent implements OnInit {
	@Input() parentForm?: RealmFormGroup = new RealmFormGroup({});
	@Input() field: CustomFieldsElement;
	@Input() editing: boolean;
	@Input() tabindex?: number;
	@Input() labelAlign?: 'left' | 'center' | 'right' = 'left';

	customField: CustomField | CustomFieldsElement;

	ngOnInit(): void {
		this.customField = this.field?.customField || this.field;
		if (this.customField) {
			this.createFieldControl();
		}
	}

	createFieldControl(): void {
		const { value } = this.field;
		const { fieldName, controlTypeId, required } = this.field.customField || this.field;
		const { processValue, validators } = fieldStates[controlTypeId] || {};
		this.parentForm.addControl(fieldName, new RealmFormControl(
				fieldName, {
					label: fieldName,
					value: value && processValue ? processValue(value) : value,
				},
				required ? [Validators.required, ...validators || []] : validators || null,
			)
		);
	}

	hasError = (controlName: string): boolean => {
		return this.parentForm.get(controlName)?.invalid && this.parentForm.get(controlName)?.touched;
	};

	stringify = (value: string[]): string => value.length ? JSON.stringify(value) : null;

	toDateStringFormat = (date: Date, format: string): string => date ? moment(date).format(format) : null;

	parseUSNumber = (value: string): string => {
		const pure = value.replace(/,/g, '');
		const num = parseFloat(pure);
		return isNaN(num) ? null : num.toString();
	};

	getInputValue = ({ target }: Event): string => (target as HTMLInputElement).value;

	setPhoneNumber = (field: CustomFieldsElement, ev: Event): void => {
		field.value = getParsedValue((ev.target as HTMLInputElement).value);
	};
}
