import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient, RealmPagedParams } from 'commons/services/http';
import { AccountIdentifier } from 'shared/account/company-information/account-information.service';
import { Observable } from 'rxjs';

declare let apiPath;

export interface DBA {
    nameType: string;
    nmlsId: number;
    otherTradeName: string;
    regulator?: string;
}

@Injectable()
export class DbaService {
    constructor(private http: RealmHttpClient) {
    }

    getList<T extends DBA[] = DBA[]>(identifier: AccountIdentifier, params: RealmPagedParams): Observable<PagedData<T>> {
        return this.http.pagedRequest<T>(
            'GET',
            `${apiPath}/tpos/company/locations/dbas`,
            { ...identifier, ...params },
        );
    }
}
