import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Assigned, Audit } from '../../audits.service.interface';
import { AuditsService } from '../../audits.service';

@Component({
    templateUrl: './audit-assigned-user-modal.component.html',
})
export class AuditAssignedUserModalComponent implements OnInit {

    audits: Audit[] = [];
    tpoId: number;
    callbackOnSave: () => void;

    resolved: boolean = false;
    users: Assigned[];
    selectedUser: any;

    constructor(
        private modalRef: BsModalRef,
        private auditsService: AuditsService,
    ) {}

    async ngOnInit() {
        try {
            this.users = await this.auditsService.getAssignedUsers(this.tpoId).toPromise();
        } finally {
            this.resolved = true;
        }
    }


    close(): void {
        this.modalRef.hide();
    }

    async submit() {
        try {
            this.resolved = false;
            let auditIds: number[] = this.audits.map(audit => audit.id);
            await this.auditsService.bulkUpdateAssignedUser(auditIds, this.selectedUser.id, this.tpoId).toPromise();
        } finally {
            this.resolved = true;
            this.callbackOnSave();
            this.modalRef.hide();
        }
    }


}
