import { Injectable } from '@angular/core';
import { isArray } from 'lodash';

import {
    KeyedScorecardFindingCategories,
    ScorecardFindingCategories,
    ScorecardFindingCategory,
} from '../scorecard-finding-category.interface';
import { ScorecardFindingDetailRow, ScorecardFindingDetailsV2 } from '../scorecard-finding.interface';


// TODO: Should this be split apart to avoid being a dumping ground for random scorecard-related methods?
@Injectable()
export class ScorecardUtilityService {
    keyCategoriesByCode(unkeyed: ScorecardFindingCategories): KeyedScorecardFindingCategories {
        const keyed: KeyedScorecardFindingCategories = <any>{};
        unkeyed.categories.forEach((category: ScorecardFindingCategory) => keyed[category.code] = category);

        return keyed;
    }

    annotateArrayValues(details: ScorecardFindingDetailsV2[]): void {
        details.forEach(detail => detail.isArray = isArray(detail.value));
    }

    /**
     * d1 d2 d3 d4 d5
     * =>
     * d1   d2
     * d3   d4
     * d5
     */
    splitDetailsIntoRows(details: ScorecardFindingDetailsV2[]): ScorecardFindingDetailRow[] {
        // We may want to parameterize this some day.
        const columnCount: number = 2;
        const rowSize: number = Math.floor(details.length / columnCount);
        const evenSplit: boolean = ((details.length % columnCount) == 0);
        const rowCount: number = (evenSplit ? rowSize : (rowSize + 1));

        const rows: ScorecardFindingDetailRow[] = [];
        for (let ii = 0; ii < rowCount; ii++) {
            const rowItems: ScorecardFindingDetailsV2[] = [];

            for (let jj = 0; ((ii + (jj * rowCount)) < details.length); jj++) {
                const index: number = (ii + (jj * rowCount));
                if (index > details.length) {
                    continue;
                }

                rowItems.push(details[index]);
            }

            const row: ScorecardFindingDetailRow = {
                items: rowItems,
            };
            rows.push(row);
        }

        return rows;
    }
}
