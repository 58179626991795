import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { linkTemplate } from 'shared/supporting-documents/helpers';
import { UploadResourceProps } from 'lender/clients/$id/documents/common';

import {
	RequiredDocumentsPartner,
	TpoDocument,
	TpoHistoryDocument,
	TpoLibraryDocument,
	TpoLibraryHistoryDocument,
	Entity,
} from './documents.interface';
import { WaiveData } from 'lender/clients/$id/documents/client-documents.interface';

declare let apiPath: string;

@Injectable()
export class TpoDocumentsService {
	private documents: any;
	private document: any;
	private documentHistory: any;
	private libraryDocuments: any;
	private libraryDocument: any;
	private libraryDocumentRequests: any;
	private libraryDocumentHistory: any;
	private fromLibraryDocumentHistory: any;

	private baseUrl = `${apiPath}/v2/tpos/:tpoId`;
	private baseParams = {
		tpoId: null,
	};
	private documentsUrl = `${this.baseUrl}/docmanagement`;
	private documentUrl = `${this.documentsUrl}/:documentId`;
	private libraryDocumentsUrl = `${this.baseUrl}/client-library-documents`;
	private libraryDocumentUrl = `${this.libraryDocumentsUrl}/:libraryId`;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.baseParams = {
			...this.baseParams,
			tpoId: user.profile.organization.id,
		};

		this.documents = PagedResource(
			this.documentsUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getPartners: {
					url: `${this.documentUrl}/partners`,
					method: 'GET',
					isArray: true,
				},
				getChannels: {
					url: `${this.documentUrl}/partners/:lenderId/channels`,
					method: 'GET',
					isArray: true,
				},
				getQuestionnaires: {
					url: `${this.documentUrl}/partners/:lenderId/questionnaires`,
					method: 'GET',
					isArray: true,
				},
				getRequiredDocumentsPartners: {
					url: `${this.documentUrl}/required-documents-partners`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.document = $resource(
			this.documentUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
				},
				upload: {
					url: `${this.documentUrl}/uploadedDocument`,
					...UploadResourceProps,
				},
				uploadFromLibrary: {
					url: `${this.documentUrl}/uploadedDocument`,
					params: {
						...this.baseParams,
						from: 'library',
					},
					method: 'POST',
				},
			},
		);

		this.documentHistory = PagedResource(
			`${this.documentUrl}/history`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				waive: {
					url: `${this.documentUrl}/history/:historyId/waive`,
					method: 'GET',
				},
			},
		);

		this.libraryDocuments = PagedResource(
			this.libraryDocumentsUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				getUploaded: {
					url: `${this.libraryDocumentsUrl}/uploaded-client-library-documents`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.libraryDocument = $resource(
			this.libraryDocumentUrl,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
				},
				upload: {
					url: `${this.libraryDocumentUrl}/uploadedDocument`,
					...UploadResourceProps,
				},
			},
		);

		this.libraryDocumentRequests = PagedResource(
			`${this.libraryDocumentUrl}/requests`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.libraryDocumentHistory = PagedResource(
			`${this.libraryDocumentUrl}/library-history`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.fromLibraryDocumentHistory = PagedResource(
			`${this.libraryDocumentUrl}/history`,
			{ ...this.baseParams },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getDocuments = (...args): NgResourceArray<TpoDocument> => this.documents.get(...args);
	getPartners = (...args): NgResourceArray<Entity> => this.documents.getPartners(...args);
	getChannels = (...args): NgResourceArray<Entity> => this.documents.getChannels(...args);
	getQuestionnaires = (...args): NgResourceArray<Entity> => this.documents.getQuestionnaires(...args);
	getRequiredDocumentsPartners = (...args): NgResourceArray<RequiredDocumentsPartner> => this.documents.getRequiredDocumentsPartners(...args);
	getDocument = (...args): NgResourceObject<TpoDocument> => this.document.get(...args);
	uploadDocument = (...args): NgResourceObject<TpoDocument> => this.document.upload(...args);
	uploadDocumentFromLibrary = (...args): NgResourceObject<TpoDocument> => this.document.uploadFromLibrary(...args);
	getDocumentHistory = (...args): NgResourceArray<TpoHistoryDocument> => this.documentHistory.get(...args);
	getDocumentWaiveHistory = (...args): NgResourceObject<WaiveData> => this.documentHistory.waive(...args);
	getLibraryDocuments = (...args): NgResourceArray<TpoLibraryDocument> => this.libraryDocuments.get(...args);
	getUploadedLibraryDocuments = (...args): NgResourceArray<TpoLibraryDocument> => this.libraryDocuments.getUploaded(...args);
	getLibraryDocument = (...args): NgResourceObject<TpoLibraryDocument> => this.libraryDocument.get(...args);
	uploadLibraryDocument = (...args): NgResourceObject<TpoLibraryDocument> => this.libraryDocument.upload(...args);
	getLibraryDocumentRequests = (...args): NgResourceArray<any> => this.libraryDocumentRequests.get(...args);
	getLibraryDocumentHistory = (...args): NgResourceArray<TpoLibraryHistoryDocument> => this.libraryDocumentHistory.get(...args);
	getFromLibraryDocumentHistory = (...args): NgResourceArray<TpoLibraryHistoryDocument> => this.fromLibraryDocumentHistory.get(...args);

	/**
	 * @param {Object} params
	 * @param {number} [params.tpoId]
	 * @param {number} [params.documentId]
	 * @param {number} [params.libraryId]
	 */
	getTemplateDownloadLink = (params): string => {
		const { libraryId } = params;
		const url = libraryId ? this.libraryDocumentUrl : this.documentUrl;
		return linkTemplate(
			`${url}/template`,
			{
				...this.baseParams,
				...params,
			},
		);
	};
}
