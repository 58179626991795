<div class="customer-status-modal remote-resource" [class.resolved]="!resolving">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close bold" (click)="close()">&times;</button>
        <span class="modal-title">Deactivate Customer</span>
    </div>

    <div *ngIf="organizationName" class="modal-body">

        <div>You are about deactivate <strong>{{organizationName}}</strong>.
            <div *ngIf="servicesChannel">
                <span *ngIf="servicesChannel.channelsNames.length || servicesChannel.servicesNames.length">
                    The following channels and/or services will be deactivated:
                </span>
            </div>
        </div>

        <div *ngIf="servicesChannel">
            <div *ngIf="servicesChannel.channelsNames.length">
                <br>
                <div class="font-bold">Channels</div>
                <br>
                <ul class="dashed-list">
                    <li *ngFor="let item of servicesChannel.channelsNames">{{item}}</li>
                </ul>
            </div>

            <div *ngIf="servicesChannel.servicesNames.length">
                <br>
                <div class="font-bold">Services</div>
                <br>
                <ul class="dashed-list">
                    <li *ngFor="let item of servicesChannel.servicesNames">{{item}}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-lg btn-primary" (click)="deactivate();">Deactivate</button>
        <button type="button" class="btn btn-lg btn-default" (click)="close()">Cancel</button>
    </div>

</div>
