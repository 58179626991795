import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { QuestionBankCategoryWithCount } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

@Component({
	templateUrl: './remove-with-questions-modal.component.html',
})
export class RemoveBankCategoryWithQuestionsModalComponent {
	onCancel: (params?) => void;
	onConfirm: (params?) => void;

	resolving: boolean = false;

	category: QuestionBankCategoryWithCount;
	destinationCategories: QuestionBankCategoryWithCount[];

	form = new RealmFormGroup({
		destinationCategoryId: new RealmFormControl(
			'destinationCategoryId',
			{ label: 'Category' },
			Validators.required,
		),
	});

	constructor(
		public modalRef: BsModalRef,
		protected questionnairesService: LenderManageQuestionnaireService,
	) {}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.onCancel();
	}

	confirm = async (): Promise<void> => {
		if (this.resolving) {
			return;
		}

		this.resolving = true;

		try {
			await this.questionnairesService.removeBankCategory({
				...this.form.value,
				categoryId: this.category.id,
			}).$promise;

			this.onConfirm();
			this.onCancel();
		} catch (error) {
			this.resolving = false;
		}
	}

	getFormControl(name: string): RealmFormControl {
		return this.form.get(name) as RealmFormControl;
	}
}
