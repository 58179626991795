import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { linkTemplate } from 'shared/supporting-documents/helpers';

import {
	ClientLibraryDocument,
	ClientLibraryHistoryDocument,
} from './library-documents.interface';
import { RequestableDocumentService } from 'lender/clients/$id/documents/client-documents.service';
import { DocumentContactsEntry, IDocumentLenderContactsEntry } from 'lender/clients/$id/documents/client-documents.interface';
import { Observable } from 'rxjs';
import { RealmHttpClient } from 'commons/services/http';

declare let apiPath: string;

@Injectable()
export class ClientLibraryDocumentsService implements RequestableDocumentService {
	private documents: any;
	private document: any;
	private documentHistory: any;
	private tpoUsers: any;
	private lenderUsers: any;

	private baseUrl = `${apiPath}/v2/lenders/:lenderId/tpos/:tpoId`;
	private baseParams = {
		lenderId: null,
		tpoId: '@tpoId',
	};

	constructor(
		public injector: Injector,
		public user: UserService,
        private http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		this.baseParams = {
			...this.baseParams,
			lenderId: user.profile.organization.id,
		};

		const documentsResourceConfig = {
			url: `${this.baseUrl}/client-library-documents`,
			params: {
				...this.baseParams,
			},
		};
		const documentResourceConfig = {
			url: `${documentsResourceConfig.url}/:libraryId`,
			params: {
				...documentsResourceConfig.params,
				libraryId: '@libraryId',
			},
		};
		const clientLibraryDocumentsResourceConfig = {
			url: `${this.baseUrl}/client-library-documents`,
			params: { ...this.baseParams },
		};

		this.documents = PagedResource(
			documentsResourceConfig.url,
			documentsResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.document = $resource(
			documentResourceConfig.url,
			documentResourceConfig.params,
			{
				get: {
					method: 'GET',
				},
				request: {
					url: `${documentResourceConfig.url}/requested`,
					method: 'POST',
				},
			},
		);

		this.documentHistory = PagedResource(
			`${documentResourceConfig.url}/history`,
			{ ...documentResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				requests: {
					url: `${documentResourceConfig.url}/history/request`,
					method: 'GET',
				},
			},
		);

		this.tpoUsers = $resource(
			`${clientLibraryDocumentsResourceConfig.url}/tpo-users`,
			{ ...clientLibraryDocumentsResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.lenderUsers = $resource(
			`${clientLibraryDocumentsResourceConfig.url}/lender-users`,
			{ ...clientLibraryDocumentsResourceConfig.params },
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getDocuments = (...args): NgResourceArray<ClientLibraryDocument> => this.documents.get(...args);
	getDocument = (...args): NgResourceObject<ClientLibraryDocument> => this.document.get(...args);
	requestDocument = (...args): NgResourceObject<ClientLibraryDocument> => this.document.request(...args);
	getDocumentHistory = (...args): NgResourceArray<ClientLibraryHistoryDocument> => this.documentHistory.get(...args);
	getDocumentRequestsHistory = (...args): NgResourceObject<any> => this.documentHistory.requests(...args);
	getClientLibraryTpoUsers = (...args): NgResourceArray<any> => this.tpoUsers.get(...args);
	geClientLibraryLenderUsers = (...args): NgResourceArray<any> => this.lenderUsers.get(...args);

    public getTpoNotificationUsers<T = IDocumentLenderContactsEntry>({ tpoId }): Observable<T[]> {
        const lenderId = this.user.profile.organization.id;
        return this.http.request<T[]>(
            'GET',
            `${apiPath}/v2/lenders/${lenderId}/tpos/${tpoId}/client-library-documents/tpo-users`,
        )


    }

    public getLenderNotificationUsers<T = DocumentContactsEntry>({ tpoId }): Observable<T[]> {
        const lenderId = this.user.profile.organization.id;
        return this.http.request<T[]>(
            'GET',
            `${apiPath}/v2/lenders/${lenderId}/tpos/${tpoId}/client-library-documents/lender-users`,
        )
    }

	/**
	 * @param {Object} params
	 * @param {number} [params.lenderId]
	 * @param {number} [params.tpoId]
	 * @param {number} [params.libraryId]
	 * @param {number} [params.historyId]
	 * @param {'template'|'uploadedDocument'} [params.type]
	 */
	getDownloadLink = (params): string => {
		const { historyId } = params;
		return linkTemplate(
			`${this.baseUrl}/client-library-documents/:libraryId${historyId ? '/library-history/:historyId' : ''}/:type`,
			{
				...this.baseParams,
				...params,
			},
		);
	};
}
