<section class="remote-resource" [class.resolved]="list.$resolved">
	<div class="issue-list">
		<issue *ngFor="let item of list" [issue]="item"></issue>
	</div>

	<div class="row" *ngIf="list.$resolved && !list.length && (true || !finding.hasScreenshot)">
		<div class="col-sm-12 text-center text-muted" [class.text-center]="!finding.hasScreenshot">
			{{finding.hasScreenshot ? 'Click the image to the right to add an issue.' : 'No issues'}}
		</div>
	</div>
</section>
