import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';

export class PagedResourceService {
	get: any;
	put: any;
	// tslint:disable-next-line:no-empty
	constructor(url: any, params: any, actions: any) {}
}

export const PagedResourceServiceProvider = {
	provide: PagedResourceService,
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('PagedResource'),
	deps: ['$injector'],
};
