import { Observable, ReplaySubject, Subject } from 'rxjs';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { UserProfile } from 'angularjs-providers/user.provider';
import { Injectable, OnDestroy } from '@angular/core';

export type CompanyPartial = {
    name: string;
    organizationId: number;
    tpoId? :number;
    nmlsId? :number;
}

@Injectable()
export class SharedContactDetailsComponent implements OnDestroy {
    public destroyed$: Subject<void> = new Subject();
    public contact: ReplaySubject<FullTpoContact> = new ReplaySubject<FullTpoContact>(1);
    public user: UserProfile;
    public company: Observable<CompanyPartial>;
    public accountSref: string;
    public accountSrefParams: Record<string, unknown> = {};

    public setContact(contact: FullTpoContact) {
        this.contact.next(contact);
    }

    public ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }
}
