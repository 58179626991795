import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { ClientDocumentComponent, DownloadDocumentDirective } from './client-document';
import { ClientDocumentCardComponent } from './client-document-card';
import { ClientLibraryDocumentComponent } from './client-library-document';
import { DocumentStatusComponent } from './document-status';

const components = [
	ClientDocumentComponent,
	ClientDocumentCardComponent,
	ClientLibraryDocumentComponent,
	DocumentStatusComponent,
	DownloadDocumentDirective,
];

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ComponentsModule,
	],
	declarations: [
		...components,
	],
	exports: [
		...components,
	],
})
export class SharedDocumentsModule {}
