<form [formGroup]="issueForm" class="vertical-flex form-vertical modal-body remote-resource" [class.resolved]="resolved" (onValidSubmit)="save()">
	<ng-container *ngIf="initialized">
		<div class="flex-fixed">
			<form-errors [form]="issueForm"></form-errors>

			<div class="modal-issue-header">
				<div class="issue-number">{{issue.number || '?'}}</div>
				<strong>Selected observations: {{issueForm.get('observations').value?.length || 0}}</strong>
			</div>

			<div class="form-group">
				<textarea
					class="form-control no-resize"
					formControlName="description"
					[rows]="expanded ? 5 : 1"
					maxlength="1000"
					placeholder="Description"
					(focus)="setDescriptionFocus(true)"
					(blur)="setDescriptionFocus(false)"
				></textarea>
			</div>
			<div class="grouped-input" [class.curtain]="resolved && !values.observations?.$resolved">
				<div class="form-group input-6 has-feedback left-position">
					<input class="form-control"
					       type="search"
					       [ngModel]="search"
					       [ngModelOptions]="{ standalone: true }"
					       (keyup)="keyUp$.next($event.target.value)"
					       maxlength="75"
					       placeholder="Search"
					/>
					<a class="form-control-feedback"
					   [ngClass]="{'c-icon c-icon-search': !search, 'glyphicon glyphicon-remove': search}"
					   (click)="this.search$.next(null)"
					></a>
				</div>
				<div class="form-group input-6">
					<ng-select
						formControlName="groupType"
						[items]="values.groups.$promise | async"
						bindValue="id"
						bindLabel="groupName"
						[loading]="!values.groups.$resolved"
						placeholder="Select Group"
						(change)="loadObservations()"
					></ng-select>
				</div>
			</div>
		</div>

		<div class="flex-grow remote-resource" [class.resolved]="!resolved || values.observations?.$resolved">
			<div class="form-group flex-scroll observations-list" [class.has-error]="issueForm.get('groupType').showError">
				<ng-container [ngSwitch]="!!(search || issueForm.value?.groupType)">
					<ng-container *ngSwitchCase="true">
						<div class="font-bold list-name">Checklist</div>
						<select-checkboxes
							[control]="issueForm.get('observations')"
							[items]="values.observations?.checklist"
						>
							<ng-template #labelTemplate let-item="item">
								{{item.observation}}
								<div>
									<span class="label label-tertiary text-smaller">{{item.findingType}}</span>
								</div>
							</ng-template>
						</select-checkboxes>
						<span *ngIf="!values.observations?.checklist?.length && values.observations?.$resolved" class="text-muted">
							No results found
						</span>

						<div class="font-bold list-name">Non-Checklist</div>
						<select-checkboxes
							[control]="issueForm.get('observations')"
							[items]="values.observations?.other"
						>
							<ng-template #labelTemplate let-item="item">
								{{item.observation}}
								<div>
									<span class="label label-tertiary text-smaller">{{item.findingType}}</span>
								</div>
							</ng-template>
						</select-checkboxes>
						<span *ngIf="!values.observations?.other?.length && values.observations?.$resolved" class="text-muted">
							No results found
						</span>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<select-checkboxes
							[control]="issueForm.get('observations')"
							[items]="values.observations.$promise | async"
						>
							<ng-template #labelTemplate let-item="item">
								{{item.observation}}
								<div>
									<span class="label label-tertiary text-smaller">{{item.findingType}}</span>
								</div>
							</ng-template>
						</select-checkboxes>
					</ng-container>
				</ng-container>
			</div>
		</div>

		<div class="flex-fixed flex-footer">
			<a class="btn btn-secondary btn-icon" *ngIf="!isNew" (click)="confirmRemove()"><span class="glyphicon glyphicon-trash"></span></a>
			<div class="pull-right">
				<button type="submit" class="btn btn-primary" [disabled]="!(values.groups.$resolved && values.observations.$resolved)">Save</button>
				<a class="btn btn-secondary" (click)="cancelEditing()">Cancel</a>
			</div>
		</div>
	</ng-container>
</form>
