import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { DropdownRequiredValidator } from 'commons/validators';
import { ThirdPartyOversightService } from 'comergence/customers/services/customer-services/third-party-oversight.service';
import { Observable, of, Subject } from 'rxjs';
import { InvestorBaseInfo, ShareChannelRequest, TpoChannelOverview } from 'comergence/customers/tpo-services/tpo-service.interface';
import { distinctUntilChanged, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserInformation } from 'comergence/customers/services/customer-services/user-information.interface';

@Component({
    templateUrl: './share-channel-modal.component.html',
})

export class ShareChannelModalComponent implements OnInit, OnDestroy {
    resolving: boolean;
    channelId: number;
    organizationId: number;
    callbackOnSave: () => void;

    investors: Observable<InvestorBaseInfo[]> = this.thirdPartOverSightService.getAllInvestors().pipe(
        shareReplay(1),
    );
    channels: Observable<TpoChannelOverview[]> = of([]);
    users: Observable<UserInformation[]> = of([]);
    loading = {
        investors: true,
        channels: false,
        users: false,
    };
    destroy$: Subject<void> = new Subject();

    form = new RealmFormGroup({
        investor: new RealmFormControl(
            'investor',
            {
                label: 'Investor',
                updateOn: 'change',
                disabled: true,
            },
            DropdownRequiredValidator,
        ),
        requestedChannelId: new RealmFormControl(
            'requestedChannelId',
            {
                label: 'Channel',
                updateOn: 'change',
            },
            DropdownRequiredValidator,
        ),
        requestedUserId: new RealmFormControl(
            'requestedUserId',
            {
                label: 'User',
                updateOn: 'change',
            },
            DropdownRequiredValidator,
        ),
        message: new RealmFormControl(
            'message',
            {
                label: 'Message',
                updateOn: 'change',
            },
            Validators.required,
        ),
    });


    constructor(
        private modalRef: BsModalRef,
        private thirdPartOverSightService: ThirdPartyOversightService,
    ) {

    }

    ngOnInit() {
        this.investors.subscribe(() => {
            this.loading.investors = false;
            this.disableField('investor', false);
        });

        const investorValueChanges = this.form.get('investor').valueChanges;

        this.channels = investorValueChanges.pipe(
            distinctUntilChanged(),
            switchMap(organizationId => {
                this.loading.channels = true;
                this.disableField('requestedChannelId', true);
                this.form.patchValue({ requestedChannelId: null });
                if (!organizationId) {
                    return of([]);
                }
                return this.thirdPartOverSightService.getTpoChannelsList(organizationId, { activeOnly: true });
            }),
            tap(() => {
                this.loading.channels = false;
                this.disableField('requestedChannelId', false);
            }),
            takeUntil(this.destroy$),
            shareReplay(1),
        );

        this.users = investorValueChanges.pipe(
            distinctUntilChanged(),
            switchMap(organizationId => {
                this.loading.users = true;
                this.disableField('requestedUserId', true);
                this.form.patchValue({ requestedUserId: null });
                if (!organizationId) {
                    return of([]);
                }
                return this.thirdPartOverSightService.getInvestorUsers(organizationId);
            }),
            tap(() => {
                this.loading.users = false;
                this.disableField('requestedUserId', false);
            }),
            takeUntil(this.destroy$),
            shareReplay(1),
        );
    }

    disableField(field: string, disable: boolean) {
        const fieldControl = this.form.get(field);
        return disable ? fieldControl.disable() : fieldControl.enable();
    }

    close(): void {
        this.modalRef.hide();
    }

    async submit() {
        const { requestedChannelId, requestedUserId, message } =  this.form.value as ShareChannelRequest;
        try {
            this.resolving = true;
            await this.thirdPartOverSightService.shareChannel(this.organizationId, this.channelId, { requestedChannelId, requestedUserId, message }).toPromise();
            this.close();
        } catch ({error}) {
            this.form.setServerError(error);
        } finally {
            this.resolving = false;
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
