import { ColumnConfig } from 'commons/mixins';

export const columnsConfig: ColumnConfig[] = [
	{
		id: 'auditCreatedDate',
		name: 'Audit Create Date',
		isDefault: true,
	},
	{
		id: 'reviewStatus',
		name: 'Review Status',
		isDefault: true,
	},
	{
		id: 'auditStatus',
		name: 'Audit Status',
		isDefault: true,
	},
	{
		id: 'reviewStatusDate',
		name: 'Review Status Date',
	},
	{
		id: 'auditStatusDate',
		name: 'Audit Status Date',
	},
	{
		id: 'assigned',
		name: 'Assigned',
	},
];
