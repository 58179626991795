<h2>Channels</h2>

<div class="remote-resource"  [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-sm-4">Channel Name</div>
            <div class="col-sm-4">Status</div>
            <div class="col-sm-4">Assigned</div>
        </div>
        <ng-container *ngIf="((list | async)?.length > 0); else noItems">
            <div class="row-fluid"
                 *ngFor="let item of list | async"
            >
                <single-locations-channel
                    [channel]="item"
                    [statuses]="statusTransitions[item.approvalStatus?.id]?.availability"
                    [isLocationHeadquarter]="isLocationHeadquarter"
                    [userCanEditAssigned]="userCanEditAssigned"
                    [userCanEditStatus]="userCanEditStatus"
                    [tpoId]="tpoId"
                    [lenderId]="lenderId"
                    [nmlsId]="nmlsId"
                ></single-locations-channel>
            </div>
        </ng-container>
        <ng-template #noItems>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    No channels found
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
