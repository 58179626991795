import { Injectable, Injector } from '@angular/core';
import { map, isArray } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { SocialAccountLink } from 'tpo/social-accounts/company/link.class';

declare let apiPath: string;

@Injectable()
export class ContactSocialAccountsService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const basePath = `${apiPath}/tpos/:tpoId/contacts/:contactId/social-media-links/:linkListType`;
		const defaultParams = {
			linkListType: 'SOCIAL_ACCOUNTS',
			...User.profile.organization.type === 'TPO' ? {tpoId: User.profile.organization.id} : {},
		};
		const { isTpo, isComergence } = this.User.profile;

		this.resource = PagedResource(`${basePath}`, defaultParams, {
			list: {
				method: 'get',
				isArray: true,
				transformResponse(response) {
					const result = JSON.parse(response);
					return isArray(result) ? map(result, (entry) => (new SocialAccountLink({
						data: entry,
						isTpo,
						isComergence,
					}))) : result;
				},
			},
		});
	}

	list = (...args) => this.resource.list(...args);
}
