import { Component, Input, OnInit } from '@angular/core';
import { LocationChannel } from 'shared/account/locations/single/channels/locations-channels.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { AccountExecutive, AccountExecutiveDetails } from 'lender/clients/channels/channels-summary.interface';
import { RelationshipStatusBadgeService, Status } from 'commons/services/utils/relationship-status-badge.service';
import { LocationsService } from 'shared/account/locations/locations.service';


@Component({
    templateUrl: './single-locations-channel.component.html',
    selector: 'single-locations-channel',
})
export class SingleLocationsChannelComponent implements OnInit {
    @Input() channel: LocationChannel;
    @Input() isLocationHeadquarter: boolean;
    @Input() userCanEditStatus: boolean;
    @Input() userCanEditAssigned: boolean;
    @Input() statuses: Status[];
    @Input() lenderId: number;
    @Input() tpoId: number;
    @Input() nmlsId: number;

    accountExecutives: AccountExecutiveDetails[];
    resolved: boolean = true;
    editing: boolean;

    get statusIsNotEmpty() {
        return this.statuses?.length > 0;
    }

    get isStatusEditable() {
        return this.userCanEditStatus && this.isEditable && this.editing && this.statusIsNotEmpty;
    }

    get isAssignedEditable() {
        return this.userCanEditAssigned && this.isEditable && this.editing;
    }

    get isEditable() {
        return ((this.userCanEditStatus && this.statusIsNotEmpty) || this.userCanEditAssigned) && !this.isLocationHeadquarter && this.channel.accessible;
    }

    locationChannelForm = new RealmFormGroup({
        approvalStatus: new RealmFormControl(
            'approvalStatus',
            {
                label: 'Approval Status',
            }
        ),
        assigned: new RealmFormControl(
            'assigned',
            {
                label: 'Assigned',
            }
        ),
    })

    constructor(
        private locationsService: LocationsService,
        public statusLabelService: RelationshipStatusBadgeService,
    ) {
    }

    ngOnInit() {
        // channel.approvalStatus.id will only be null in cases where statuses is also
        if(this.statuses?.length != 0 && !this.statuses?.find(status => status.id == this.channel.approvalStatus.id)) {
            this.statuses?.push(this.channel.approvalStatus);
        }
        this.locationChannelForm.patchValue({
            assigned: this.channel.accountExecutive,
            approvalStatus: this.channel.approvalStatus,
        });
    }

    editChannel() {
        this.loadAccountExecutives();
        this.editing = true;
    }

    async saveChannel() {
        try {
            this.resolved = false;
            let newLocationChannels: LocationChannel[] = [{
                ...this.locationChannelForm.value,
                nmlsId: this.nmlsId,
                tpoChannelLocationApprovalId: this.channel.tpoChannelLocationApprovalId,
            }];
            await this.locationsService.saveLocationChannel(this.lenderId, this.tpoId, this.channel.organizationChannelId, newLocationChannels).toPromise();
            this.channel.approvalStatus = this.locationChannelForm.value.approvalStatus;
            if(this.locationChannelForm.controls.assigned.touched) {
                this.channel.accountExecutive = {
                    id: this.locationChannelForm.value.assigned.id,
                    name: this.locationChannelForm.value.assigned.fullName
                };
            }
            this.channel.label = this.statusLabelService.getBadge(this.channel.approvalStatus);
            this.editing = false;
        } finally {
            this.resolved = true;
        }
    }

    cancelEdit() {
        this.editing = false;
    }

    compareAccountExecutives(item: AccountExecutiveDetails, selected: AccountExecutive): boolean {
        return ((item.id == selected.id) && (item.fullName == selected.name));
    }

    async loadAccountExecutives() {
        if(!this.accountExecutives) {
            try {
                this.resolved = false;
                this.accountExecutives = await this.locationsService.getAccountExecutives(this.channel.organizationChannelId).toPromise();
            } finally {
                this.resolved = true;
            }
        }
    }
}
