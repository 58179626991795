<div>
    <div class="gray-head flex-head">
        <div class="col-fit remote-resource" [class.resolved]="!mloNameResolving">
            <h2>
                {{ mloName?.firstName }} {{ mloName?.lastName }} <span class="text-medium text-muted">NMLS ID: {{ nmlsId }}</span>
            </h2>
        </div>
    </div>

    <nmls-individual-companies
        [userProfile]="userProfile"
        [companiesResolving]="companiesResolving"
        [companies]="companies"
        [individualNmlsId]="nmlsId"
    >
    </nmls-individual-companies>

    <nmls-individual-other-names
        [otherNamesResolving]="otherNamesResolving"
        [otherNames]="otherNames"
    >
    </nmls-individual-other-names>

    <nmls-individual-financial-sanctions
        [financialSanctionsResolving]="financialSanctionsResolving"
        [financialSanctions]="financialSanctions"
    >
    </nmls-individual-financial-sanctions>
</div>
