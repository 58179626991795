import { Component, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { defaults } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

@Component({
	templateUrl: './gmb-listings.modal.component.html',
})

export class GMBListingsModalComponent {
	title: string;
	message: string;
	confirmText: string;
	cancelText: string;
	list: any;
	onConfirm: (params?: any) => any;
	onCancel: (params?: any) => any;
	resolving: boolean;
	User: any;
	$all: boolean = false;
	saveEnabled: boolean = false;

	constructor(
		public modalRef: BsModalRef,
		public ref: ChangeDetectorRef,
		userService: UserService,
	) {
		this.User = userService.profile;

		defaults(this, {
			title: 'Google My Business',
			confirmText: 'Save',
			cancelText: 'Cancel',
			submitDisabled: true,
			resolving: false,
		});
	}

	checkAll() {
		setTimeout(() => {
			this.saveEnabled = false;
			this.$all = this.list.reduce((acc, item) => {
				this.saveEnabled = this.saveEnabled || item.$selected;
				return acc && item.$selected;
			}, true);
		});
	}

	selectAll() {
		setTimeout(() => {
			this.saveEnabled = this.$all;
			this.list.forEach((item) => {
				item.$selected = this.$all;
			});
		});
	}
}
