import { isEqual } from 'lodash';
import { ElementRef } from '@angular/core';

export class InfiniteScrollEntry {
	id: number | string;
	data: any = {};
	height: number = 0;
	innerHeight: number = 0;
	visible: boolean = false;
	resolved: boolean = false;

	constructor({ id = 0, data = {}, height = 0, innerHeight = 0, visible = false, resolved = false }) {
		this.id = id;
		this.data = data;
		this.height = height;
		this.innerHeight = innerHeight;
		this.visible = visible;
		this.resolved = resolved;
	}

	setElementHeight(domElement: ElementRef) {
		const element = domElement.nativeElement;
		const style = getComputedStyle(element);

		const margins = parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);

		this.innerHeight = element.offsetHeight;
		this.height = element.offsetHeight + margins;
	}

	isEqual(entry: InfiniteScrollEntry) {
		return this.id === entry.id && isEqual(this.data, entry.data);
	}
}
