import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ClientDocumentsModule } from 'lender/clients';
import { InsuranceBondService } from 'shared/account/information/insurance-bonds/insurance-bonds.service';
import { InsuranceBondsComponent } from 'shared/account/information/insurance-bonds/insurance-bonds.component';
import { InsuranceBondsSectionComponent } from 'shared/account/information/insurance-bonds/insurance-bonds-section/insurance-bonds-section.component';
import { InsuranceBondsItemComponent } from 'shared/account/information/insurance-bonds/insurance-bonds-section/insurance-bonds-item/insurance-bonds-item.component';
import { TpoAccountModule } from 'tpo/account';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		ClientDocumentsModule,
		TpoAccountModule,
	],
    declarations: [
        InsuranceBondsComponent,
        InsuranceBondsSectionComponent,
        InsuranceBondsItemComponent,
    ],
    providers: [
        InsuranceBondService,
    ],
})
export class SharedInsuranceBondsModule {
}
