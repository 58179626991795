import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from 'commons/services/error/error-modal.service';

type HttpPromiseError = {
    rejection?: { name: 'HttpErrorResponse'} & HttpErrorResponse;
}

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(
        private readonly ems: ErrorModalService,
    ) {
    }

    handleError(error: Error | HttpPromiseError) {
        if ((<HttpPromiseError>error)?.rejection instanceof HttpErrorResponse || (<HttpPromiseError>error)?.rejection?.name == 'HttpErrorResponse') {
            error = new HttpErrorResponse((<HttpPromiseError>error).rejection);
        }

        if (error instanceof HttpErrorResponse) {
            const { status } = error;

            return this.ems.handleHttpError(status);
        }

        // Throw error if not caught here
        throw error;
    }

}
