import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { FinancialDocumentType } from 'shared/financial-documents/financial-document-type.interface';
import {
    DocumentCardChannelScheduleSettingsEntry,
    DocumentCardDetails,
} from 'shared/document-schedule-settings-card/document-card.interface';
import { ChannelBulkRequestInterface } from 'shared/document-schedule-settings-card/card/channels/bulk-request-modal/channel-bulk-request.interface';


declare let apiPath: string;

@Injectable()
export class FinancialDocumentScheduleSettingsService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getFinancialDocumentTypes(): Observable<FinancialDocumentType[]> {
        return this.http.request<FinancialDocumentType[]>(
            'GET',
            `${apiPath}/config/financial-document-types`,
        );
    }

    getFinancialDocumentScheduleSettings(lenderId: number, documentTypeId: number): Observable<DocumentCardDetails> {
        return this.http.request<DocumentCardDetails>(
            'GET',
            `${apiPath}/lenders/${lenderId}/financial-documents/${documentTypeId}/schedule-settings`,
        );
    }

    saveFinancialDocumentScheduleSettings(lenderId: number, documentTypeId: number, channelId: number, scheduleSetting: DocumentCardChannelScheduleSettingsEntry): Observable<DocumentCardDetails> {
        return this.http.request<DocumentCardDetails>(
            'PUT',
            `${apiPath}/lenders/${lenderId}/financial-documents/${documentTypeId}/channels/${channelId}/schedule-settings`,
            null,
            scheduleSetting,
        );
    }

    makeFinancialDocumentBulkRequest(lenderId: number, documentTypeId: number, channelId: number, bulkRequest: ChannelBulkRequestInterface): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${apiPath}/lenders/${lenderId}/financial-documents/${documentTypeId}/channels/${channelId}/bulk-request`,
            null,
            bulkRequest,
        );
    }
}
