// TODO: extract to a generic file
import { allowedNetworks } from 'commons/components/sm-icons';

import { AttachmentsConfig, Mode } from './attachments-preview.interface';

// TODO: RM-27622 - define YT config
export const attachmentsConfig: { [network in allowedNetworks]?: AttachmentsConfig } = {
	/*
	[allowedNetworks.FACEBOOK_PROFILE]: {
		minHeightRatio: 1 / 3.672, // FIXME: investigate correctness
		maxHeightRatio: 1 / 0.685, // FIXME: investigate correctness
		maxImages: 5,
		excessAllowed: true,
		gifValidationType: 'VIDEO',
		mode: Mode.MASONRY,
		aspectRatio: 1 / 0.47230, // FIXME: investigate correctness
		2: {
			vertical: {
				large: [
					[ 1, 0.5 ],
					[ 1, 0.5 ],
				],
				small: [
					[ 1, 0.333 ],
					[ 1, 0.333 ],
				],
			},
			horizontal: [
				[ 0.5, 1 ],
				[ 0.5, 1 ],
			],
			even: [
				[ 0.5, 0.5 ],
				[ 0.5, 0.5 ],
			],
		},
		3: {
			vertical: {
				large: [
					[ 1, 0.666 ],
					[ 0.5, 0.333 ],
					[ 0.5, 0.333 ],
				],
				small: [
					[ 1, 0.333 ],
					[ 1, 0.333 ],
					[ 1, 0.333 ],
				],
			},
			horizontal: {
				large: [
					[ 0.666, 1 ],
					[ 0.333, 0.5 ],
					[ 0.333, 0.5 ],
				],
				small: [
					[ 0.333, 1 ],
					[ 0.333, 1 ],
					[ 0.333, 1 ],
				],
			},
			even: [
				[ 1, 0.5 ],
				[ 0.5, 0.5 ],
				[ 0.5, 0.5 ],
			],
		},
		4: {
			vertical: [
				[ 1, 0.666 ],
				[ 0.333, 0.333 ],
				[ 0.333, 0.333 ],
				[ 0.333, 0.333 ],
			],
			horizontal: [
				[ 0.666, 1 ],
				[ 0.333, 0.333 ],
				[ 0.333, 0.333 ],
				[ 0.333, 0.333 ],
			],
			even: [
				[ 0.5, 0.5 ],
				[ 0.5, 0.5 ],
				[ 0.5, 0.5 ],
				[ 0.5, 0.5 ],
			],
		},
		5: [
			[ 0.5, 0.5 ],
			[ 0.5, 0.5 ],
			[ 0.333, 0.333 ],
			[ 0.333, 0.333 ],
			[ 0.333, 0.333 ],
		],
	},
	 */
	[allowedNetworks.FACEBOOK_PAGE]: {
		minHeightRatio: 1 / 100,
		maxHeightRatio: 3 / 2,
		maxImages: 4,
		excessAllowed: true,
		gifValidationType: 'VIDEO', // FB validates gif files as videos
		mode: Mode.MASONRY,
		2: {
			vertical: {
				grid: [ 1, 2 ],
				ratios: [
					[ 1, 1 / 2 ],
					[ 1, 1 / 2 ],
				],
			},
			horizontal: {
				grid: [ 2, 1 ],
				ratios: [
					[ 1 / 2, 1 ],
					[ 1 / 2, 1 ],
				],
			},
			even: {
				grid: [ 2, 1 ],
				ratios: [
					[ 1 / 2, 1 ],
					[ 1 / 2, 1 ],
				],
			},
		},
		3: {
			vertical: {
				grid: [ 2, 2 ],
				ratios: [
					[ 1, 1 / 2 ],
					[ 1 / 2, 1 / 2 ],
					[ 1 / 2, 1 / 2 ],
				],
			},
			horizontal: {
				grid: [ 2, 2 ],
				ratios: [
					[ 1 / 2, 1 ],
					[ 1 / 2, 1 / 2 ],
					[ 1 / 2, 1 / 2 ],
				],
			},
			even: {
				grid: [ 3, 1 ],
				ratios: [
					[ 1 / 3, 1 ],
					[ 1 / 3, 1 ],
					[ 1 / 3, 1 ],
				],
			},
		},
		4: {
			vertical: {
				grid: [ 3, 5 ],
				ratios: [
					[ 1, 3 / 5 ],
					[ 1 / 3, 2 / 5 ],
					[ 1 / 3, 2 / 5 ],
					[ 1 / 3, 2 / 5 ],
				],
			},
			horizontal: {
				grid: [ 5, 3 ],
				ratios: [
					[ 3 / 5, 1 ],
					[ 2 / 5, 1 / 3 ],
					[ 2 / 5, 1 / 3 ],
					[ 2 / 5, 1 / 3 ],
				],
			},
			even: {
				grid: [ 2, 2 ],
				ratios: [
					[ 1 / 2, 1 / 2 ],
					[ 1 / 2, 1 / 2 ],
					[ 1 / 2, 1 / 2 ],
					[ 1 / 2, 1 / 2 ],
				],
			},
		},
	},
	[allowedNetworks.INSTAGRAM]: {
		minHeightRatio: 1,
		maxHeightRatio: 1,
		maxImages: 10,
		gifValidationType: 'IMAGE', // IG validates gif files as images
		mode: Mode.CAROUSEL,
	},
	[allowedNetworks.LINKED]: {
		minHeightRatio: 1 / 10,
		maxHeightRatio: 10 / 8,
		maxImages: 5,
		excessAllowed: true,
		gifValidationType: 'IMAGE', // LI validates gif files as images
		mode: Mode.MASONRY,
		2: {
			grid: [ 2, 1 ],
			ratios: [
				[ 1 / 2, 1 ],
				[ 1 / 2, 1 ],
			],
		},
		3: {
			grid: [ 2, 5 ],
			ratios: [
				[ 1, 3 / 5 ],
				[ 1 / 2, 2 / 5 ],
				[ 1 / 2, 2 / 5 ],
			],
		},
		4: {
			grid: [ 3, 5 ],
			ratios: [
				[ 1, 3 / 5 ],
				[ 1 / 3, 2 / 5 ],
				[ 1 / 3, 2 / 5 ],
				[ 1 / 3, 2 / 5 ],
			],
		},
		5: {
			grid: [ 6, 5 ],
			ratios: [
				[ 3 / 6, 3 / 5 ],
				[ 3 / 6, 3 / 5 ],
				[ 2 / 6, 2 / 5 ],
				[ 2 / 6, 2 / 5 ],
				[ 2 / 6, 2 / 5 ],
			],
		},
	},
	[allowedNetworks.TWITTER]: {
		minHeightRatio: 1 / 10,
		maxHeightRatio: 1 / 1.78,
		maxImages: 4,
		excessAllowed: false,
		gifValidationType: 'VIDEO', // TW validates gif files as videos
		mode: Mode.MASONRY,
		2: {
			grid: [ 2, 1 ],
			ratios: [
				[ 1 / 2, 1 ],
				[ 1 / 2, 1 ],
			],
		},
		3: {
			grid: [ 2, 2 ],
			ratios: [
				[ 1 / 2, 1 ],
				[ 1 / 2, 1 / 2 ],
				[ 1 / 2, 1 / 2 ],
			],
		},
		4: {
			grid: [ 2, 2 ],
			ratios: [
				[ 1 / 2, 1 / 2 ],
				[ 1 / 2, 1 / 2 ],
				[ 1 / 2, 1 / 2 ],
				[ 1 / 2, 1 / 2 ],
			],
		},
	},
	[allowedNetworks.GOOGLE_MY_BUSINESS]: {
		minHeightRatio: 1 / 1.33,
		// RM-28066: it seems GMB changed ratio for single image
		maxHeightRatio: 1,
		maxImages: 1,
		excessAllowed: false,
		gifValidationType: 'VIDEO', // GMB validates gif files as videos
		mode: Mode.MASONRY,
	},
};

// TODO: RM-27622 - define default config
export const defaultConfig: AttachmentsConfig = {
	...attachmentsConfig[allowedNetworks.FACEBOOK_PAGE],
};
