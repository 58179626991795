import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService, TransitionService, StateObject } from '@uirouter/core';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';
import { RELATION } from 'shared/models/Relation';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ScopeService } from 'angularjs-providers/scope.provider';

@Component({
	templateUrl: './client-documents.component.html',
})
export class ClientDocumentsComponent implements OnDestroy, OnInit {
	User: UserProfile;
	state: StateObject;
	hasAccess: any;
	RELATION = RELATION;
	_transitionDereg: any;
    @Input() lenderInfo: AccountInformation;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public userService: UserService,
		public $scope: ScopeService,
	) {
		this.User = userService.profile;
		this.state = this.stateService.$current;

		if (this.state.name === 'client.:id.documents') {
			this.navigateToAvailableTab();
		}
		this._transitionDereg = this.transitionService.onSuccess({ to: 'client.:id.documents' }, (transition) => {
			this.navigateToAvailableTab();
		});
	}

    ngOnInit() {
        this.hasAccess = this.lenderInfo.hasAccess;
    }

	ngOnDestroy(): void {
		this._transitionDereg();
	}

	navigateToAvailableTab() {
		const User = this.User;
		const state = this.stateService;

		if (User.can('VIEW_CLIENT_DOCUMENTS')) {
			state.go('client.:id.documents.list', {}, { location: 'replace' });
		} else if (User.can('VIEW_INTERNAL_DOCUMENTS')) {
			state.go('client.:id.documents.internal-documents', {}, { location: 'replace' });
		} else if (User.can('VIEW_DOCUMENT_LIBRARY')) {
			state.go('client.:id.documents.library', {}, { location: 'replace' });
		} else {
			return this.$scope.$emit('USER:403', 'Client Documents');
		}
	}
}
