<div class="remote-resource" [class.resolved]="!resolving">
    <div class="col-md-3">
        <a class="two-liners text-bigger"
           uiSref="tpo.:id.account.information"
           [uiParams]="{ id: item.tpoId }"
        >
            {{item.companyName || '&ndash;'}}</a>
        <div class="text-light">NMLS ID:{{item.nmlsId}}</div>
    </div>
    <div class="col-md-3">
        <form [formGroup]="statusForm">
            <div [isEditable]="editingStatus">
                <div view>
                    <ng-container *ngIf="statusConfig[item.statusId]; let status">
                        <span class="label" [ngClass]="'label-' + status.color">{{status.title}}</span>
                    </ng-container>
                    <div class="pull-right">
                        <button class="btn btn-icon" *ngIf="editable" (click)="toggleEditingStatus()">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                    </div>
                </div>
                <div edit class="row">
                    <div class="col-sm-9 pull-left">
                        <ng-select
                            formControlName="status"
                            [items]="statuses"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="Select status"
                            tabindex="1"
                            [clearable]="false"
                        ></ng-select>
                    </div>

                    <div class="pull-right">
                        <button class="btn btn-icon" (click)="saveStatus()">
                            <span class="glyphicon glyphicon-ok"></span>
                        </button>
                        <button class="btn btn-icon" (click)="toggleEditingStatus()">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="col-md-2">
        {{item.statusDate | date: 'MM/dd/YYYY' || '&ndash;'}}
    </div>
    <div class="col-md-3">
        <form [formGroup]="assignedForm">
            <div [isEditable]="editingAssignee">
                <div view>
                    {{item.assignedFullName || '&ndash;'}}
                    <div class="pull-right">
                        <button class="btn btn-icon" *ngIf="editable" (click)="toggleEditingAssignee()">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                    </div>
                </div>
                <div edit class="row">
                    <div class="col-sm-9 pull-left">
                        <ng-select
                            formControlName="assignedFullName"
                            [items]="assignedUsers"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="Select User"
                            tabindex="2"
                            [clearable]="false"
                        ></ng-select>
                    </div>

                    <div class="pull-right">
                        <button class="btn btn-icon" (click)="saveAssignee()">
                            <span class="glyphicon glyphicon-ok"></span>
                        </button>
                        <button class="btn btn-icon" (click)="toggleEditingAssignee()">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-1">
        <a class="col-md-a"
           uiSref="tpo.:id.scorecard.reviews"
           [uiParams]="{ id: item.tpoId }"
        >
            {{item.id || '&ndash;'}}</a>
    </div>
</div>
