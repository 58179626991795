<div class="gray-head flex-head">
    <h2>
        History
    </h2>
</div>

<div class="remote-resource" [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-4">
                Date
            </div>
            <div class="col-md-4">
                Action
            </div>
            <div class="col-md-4">
                Action By
            </div>
        </div>
    </div>

    <div *ngIf="!resolving && ((list | async)?.length > 0); else noHistory" class="rows-bordered">
        <div *ngFor="let item of list | async" class="row-fluid">
            <div class="col-md-4">
                {{item.date | date: 'MM/dd/yyyy HH:mm'}}
            </div>
            <div class="col-md-4">
                {{item.action}}
            </div>
            <div class="col-md-4">
                {{item.actionBy}}
            </div>
        </div>
    </div>
    <ng-template #noHistory class="row-fluid">
        <div class="col-sm-12 text-center text-light text-bigger">
            No records found
        </div>
    </ng-template>
    <br>
    <paginator [sizes]="[10,25,50]"></paginator>
</div>


