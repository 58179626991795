
export const msaListColumns = [
	{
		id: 'msaCode',
		title: 'MSA Code',
		class: 'col-md-1',
	},
	{
		id: 'msaName',
		title: 'MSA',
		class: 'col-md-3',
	},
	{
		id: 'companies',
		title: 'Companies',
		class: 'col-md-1 text-right',
	},
	{
		id: 'volume',
		title: 'Volume',
		class: 'col-md-2 text-right',
		filter: 'currency',
	},
	{
		id: 'loanAmount',
		title: 'Loan Amount',
		class: 'col-md-a text-right',
		filter: 'currency',
	},
	{
		id: 'rate',
		title: 'Rate',
		class: 'col-md-a text-right',
		filter: 'percent',
	},
	{
		id: 'price',
		title: 'Price',
		class: 'col-md-a text-right',
		filter: 'number:2.2',
	},
	{
		id: 'fico',
		title: 'FICO',
		class: 'col-md-a text-right',
		filter: 'number',
	},
	{
		id: 'ltv',
		title: 'LTV',
		class: 'col-md-a text-right',
		filter: 'percent',
	},
	{
		id: 'dtiRatio',
		title: 'DTI Ratio',
		class: 'col-md-a text-right',
		filter: 'percent',
	},
] as const;
