<section [formGroup]="listForm">
    <h3>
        General Information
        <div *ngIf="userCanManageContactLists" class="pull-right">
            <div *ngIf="!editing && !isNew">
                <button *ngIf="!isCloning" type="button" class="btn btn-link">
                    <a uiSref="^.clone">Clone List</a>
                </button>
                <button type="button" class="btn btn-link delete-list-button" (click)="showDeleteListModal()">
                    Delete List
                </button>
                <button type="button" class="btn btn-primary" (click)="changeEdit(true)">
                    <span class="glyphicon glyphicon-pencil"></span>
                    Edit
                </button>
            </div>
            <div *ngIf="editing || isNew">
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-default" (click)="changeEdit(false)">Cancel</button>
            </div>
        </div>
    </h3>
    <form-errors [form]="listForm"></form-errors>
    <div class="rows-striped">
        <div class="row-fluid">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <label class="col-md-5 control-label text-right required">List Name</label>
                    <div class="col-md-7" [isEditable]="editing || isNew">
                        <div view class="control-value">{{ formListNameValue }}</div>
                        <input edit type="text" class="form-control" name="name" formControlName="name" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngIf="!isNew && existingContactList?.createdDate" class="form-group row">
                    <label class="col-md-5 control-label text-right">Created</label>
                    <div class="col-md-7" [isEditable]="false">
                        <div view class="control-value">
                            {{ existingContactList.createdDate | date : 'MM/dd/yyyy' }}
                            {{ existingContactList.createdBy ? (' by ' + existingContactList.createdBy) : '' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <label class="col-md-5 control-label text-right required">Visibility</label>
                    <div class="col-md-7" [isEditable]="editing || isNew">
                        <div view class="control-value">{{ !formDesignatedOptionValue ? 'All Users' : 'Designated Users' }}</div>
                        <div edit>
                            <label class="radio-inline">
                                <input type="radio" formControlName="visibility" [value]="false" (click)="setVisibility(false)"> All Users
                            </label>
                            <label class="radio-inline">
                                <input type="radio" formControlName="visibility" [value]="true" (click)="setVisibility(true)"> Designated Users
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngIf="(editing || isNew) && formDesignatedOptionValue" class="form-group row" has-error>
                    <label class="col-md-5 control-label text-right required">Select Users</label>
                    <div class="col-md-7 remote-resource" [class.resolved]="availableUsersResolved">
                        <ng-select
                            formControlName="selectedDesignatedUsers"
                            [items]="availableUsers"
                            bindLabel="personalName"
                            bindValue="id"
                            placeholder="Select Users"
                            [multiple]="true"
                            [clearable]="true"
                            [closeOnSelect]="false"
                            tabindex="3"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isCloning" class="row-fluid">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <label class="col-md-5 control-label text-right multiline required">
                        <span>Include contacts from cloned list that do not meet list criteria</span>
                    </label>
                    <div class="col-md-7">
                            <label class="radio-inline">
                                <input type="radio" formControlName="includeManuallyAdded" [value]="true"> Yes
                            </label>
                            <label class="radio-inline">
                                <input type="radio" formControlName="includeManuallyAdded" [value]="false"> No
                            </label>
                    </div>
                </div>
            </div>
            <div class="col-md-6"></div>
        </div>
        <!-- <div class="row-fluid" ng-include="'views/lender/crm/contact/lists/clone/fields.html'" ng-if="state.includes('**.clone')"></div> -->
    </div>
</section>
