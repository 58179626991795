<div class="social-compliance content" ui-view>
	<div class="gray-head flex-head">
		<h2>
			<span>Monitoring Schedule</span>
			<smHelpLink [url]="helpLink"></smHelpLink>
		</h2>
	</div>

	<ng-container [ngSwitch]="hasViewAllPermission">
		<div *ngSwitchCase="true">
            <tpo-audit-schedule
                [class.curtain]="!isVisible('main')"
                [settings]="settings"
                [priceUpdate]="priceUpdate$"
                [price]="price$"
                [priceLoading]="priceLoading"
                [(loading)]="loading"
                (editing)="focusSection('main', $event)"
                (notify)="showNotification($event)"
            ></tpo-audit-schedule>
            <br>
            <tpo-schedule-selection-pool
                [class.curtain]="!isVisible('pool')"
                [settings]="settings"
                [priceUpdate]="priceUpdate$"
                (editing)="focusSection('pool', $event)"
                (notify)="showNotification($event)"
				[(loading)]="loading"
			></tpo-schedule-selection-pool>
            <br>
            <h2>Settings</h2>
            <tpo-audit-new-nmls
                [class.curtain]="!isVisible('new-mmls')"
                [settings]="settings"
                [priceUpdate]="priceUpdate$"
                (editing)="focusSection('new-mmls', $event)"
                (notify)="showNotification($event)"
            ></tpo-audit-new-nmls>
            <br>
            <tpo-audit-auto-close
                [class.curtain]="!isVisible('auto-close')"
                [settings]="settings"
                [priceUpdate]="priceUpdate$"
                (editing)="focusSection('auto-close', $event)"
                (notify)="showNotification($event)"
            ></tpo-audit-auto-close>
            <br>
        </div>

		<div *ngSwitchCase="false">
			<no-permissions title="Monitoring Schedule">
				<img src="/static/images/promo/sm-get-started/Icons_schedule_off.svg" alt="Monitoring Schedule"/>
			</no-permissions>
		</div>
	</ng-container>
</div>
