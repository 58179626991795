<div class="modal-header">
	<button
			type="button"
			class="close pull-right"
			aria-label="Close"
			(click)="modalRef.hide()"
	>&times;</button>
	<h4 class="modal-title">Request Document</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.type}}</span><br>
		<span>{{snapshotData.isAudited ? 'Audited' : 'Unaudited'}}</span>
	</div>
	<br>

	<div class="flex-row gap-l">
		<div>
			<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.period}}</span><br>
			<span>{{snapshotData.period}}</span>
		</div>

		<div>
			<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.year}}</span><br>
			<span>{{snapshotData.year}}</span>
		</div>
	</div>
	<br>

	<div>
		<b class="inline-mb-6">Notify</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.toClient}}</span><br>
		<div class="fake-select text-smaller" *ngIf="snapshotData?.toRealmUsers?.length; else dash">
			<span *ngFor="let user of snapshotData.toRealmUsers">{{user}}</span>
		</div>
	</div>
	<br>
	<div>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.ccInternal}}</span><br>
		<div class="fake-select text-smaller">
			<ng-container *ngIf="snapshotData?.ccRealmUsers?.length; else dash">
				<span *ngFor="let user of snapshotData.ccRealmUsers">{{user}}</span>
			</ng-container>
		</div>
	</div>
	<br>
	<div class="message">
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.message}}</span><br>
		<div *ngIf="snapshotData?.message as msg; else dash" [innerHTML]="msg+'<br>'"></div>
	</div>
	<br>
	<div>
		<b class="inline-mb-6">{{snapshotTitles.notifyCompletion}}</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.toInternal}}</span><br>
		<div class="fake-select text-smaller" *ngIf="snapshotData?.notifyUponCompletionRealmUsers?.length; else dash">
			<span *ngFor="let user of snapshotData.notifyUponCompletionRealmUsers">{{user}}</span>
		</div>
	</div>
	<br>
	<ng-template #dash><div class="text-medium">&ndash;</div></ng-template>
</div>
<div class="modal-footer">
	<div>
		<button type="button" (click)="modalRef.hide()" class="btn btn-primary">
			Close
		</button>
	</div>
</div>
