<div class="social-compliance-add-finding">
	<div class="gray-head flex-head">
		<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
			<span>Add Finding</span>
		</back-bread>
	</div>

	<form [formGroup]="findingForm" class="remote-resource" [class.resolved]="resolved" (onValidSubmit)="submit()">
		<form-errors [form]="findingForm"></form-errors>

		<div class="rows-white">
			<div class="row">
				<label class="col-md-3 control-label">Contact</label>
				<div class="col-md-9 control-value">{{contactName}}</div>
			</div>

			<div class="row" [class.has-error]="findingForm.showFieldError('uiId')">
				<label class="col-md-3 control-label required">{{findingForm.getFieldLabel('uiId')}}</label>
				<div class="col-md-6">
					<div [hidden]="newSocialMediaLink.show">
						<ng-select
							#mediaLinkSelectElement
							formControlName="uiId"
							[items]="mediaLinks?.$promise | async"
							[loading]="!mediaLinks?.$resolved"
							bindValue="uiId"
							bindLabel="title"
							[clearable]="false"
							(change)="setAccount($event)"
							placeholder="Select Account"
						>
							<ng-template ng-label-tmp ng-option-tmp let-item="item">
								<div class="flex-row gap">
									<sm-icon
										[config]="item"
										[minified]="true"
										[showTooltip]="false"
									></sm-icon>
									<div class="text-overflow ng-option-label">{{item.name}}</div>
								</div>
							</ng-template>

							<ng-template ng-footer-tmp>
								<div class="ng-dropdown-footer-items">
									<div class="ng-option" (click)="newSocialMediaLink.toggleShow(true)">
										<span class="text-big text-light">+</span>
										Add Link
									</div>
								</div>
							</ng-template>
						</ng-select>
					</div>

					<div [hidden]="!newSocialMediaLink.show">
						<new-social-media-link
							#newSocialMediaLink
							[formControlRef]="findingForm.get('newSocialMediaLink')"
							[show$]="newSocialMediaLinkShow$"
						></new-social-media-link>
					</div>
				</div>
			</div>

			<div class="row">
				<label class="col-md-3 control-label">{{findingForm.getFieldLabel('originalPostDate')}}</label>
				<div class="col-md-2">
					<input type="text" class="form-control"
					       formControlName="originalPostDate"
					       bsDatepicker
					       placement="bottom left"
					       [bsConfig]="bsDatepickerConfig"
					       placeholder="mm/dd/yy"
					/>
				</div>
			</div>

			<div class="row">
				<label class="col-md-3 control-label">{{findingForm.getFieldLabel('additionalInformation')}}</label>
				<div class="col-md-9">
					<textarea class="form-control resize-vertical" formControlName="additionalInformation" rows="5" maxlength="2000"></textarea>
				</div>
			</div>

			<div class="row form-group" [class.has-error]="findingForm.showFieldError('screenshot')">
				<label class="col-md-3 control-label required">{{findingForm.getFieldLabel('screenshot')}}</label>

				<div class="col-md-9">
					<ng-container *ngIf="previewImageUrl; then previewImage; else uploadImage"></ng-container>
					<ng-template #uploadImage>
						<div class="document-upload" [class.error]="screenshotError" (fileDrop)=onSelectImage($event)>
							<div class="front-face flex-row center gap">
								<label class="file-input">
									<span class="btn btn-primary">Upload</span>
									<input type="file" (input)="onSelectImage($event.target.files)" [multiple]="false" />
								</label>
								<span class="text-muted">or Drag and Drop here</span>
							</div>

							<div class="back-face flex-row center gap">
								<span>{{screenshotError}}</span>
								<button type="button" class="btn btn-primary" (click)="hideError()">OK</button>
							</div>
						</div>
					</ng-template>
					<ng-template #previewImage>
						<div>
							<a class="btn btn-danger-wired" (click)="clearImage()">Remove</a>
						</div>
						<div class="finding-sreenshot-preview">
							<img [src]="previewImageUrl">
						</div>
					</ng-template>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12 text-right">
					<button type="submit" class="btn btn-primary">Next</button>
					<a class="btn btn-secondary" (click)="cancel()">Cancel</a>
				</div>
			</div>

		</div>

	</form>
</div>
