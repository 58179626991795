import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import monitoringIcon from '~static/images/material/two-tone/search_black_24dp.svg';
import orderIcon from '~static/images/material/two-tone/content_paste_black_24dp.svg';
import calendarIcon from '~static/images/material/two-tone/calendar_month_black_24dp.svg';

import { UserService } from 'angularjs-providers/user.provider';

import { LocalStorageService } from 'commons/services';
import { LandingPageComponent } from 'shared/social-media/landing-page/landing-page.component';

@Component({
	templateUrl: '../../../shared/social-media/landing-page/landing-page.component.html',
})
export class GetStartedComponent extends LandingPageComponent {
    title = 'Compliance';
	helpLink = '/help/authenticated/tpo/smc/get-started';

	constructor(
		modalService: BsModalService,
        stateService: StateService,
		userService: UserService,
		private localStorageService: LocalStorageService,
	) {
        super(modalService, stateService, userService);
        const { profile: User } = userService;
        this.items = [
            {
                title: 'Order an Audit',
                sref: '^.orders.new',
                icon: orderIcon,
                isMaterial: true,
                description: 'Order a complete Social Media audit of one or all of your loan officers.',
                button: 'Create Order',
                noAccess: !(User.can('TPO_SMC_VIEW_ALL')),
                onEnter: () => {
                    this.localStorageService.save('tpoNewOrderFromState', 'get-started');
                },
            },
            {
                title: 'Monitoring Schedule',
                sref: '^.compliance-schedule',
                icon: calendarIcon,
                isMaterial: true,
                description: 'Automate social media audits to meet the needs of your compliance management program.',
                button: 'Manage',
            },
        ]
	}

}
