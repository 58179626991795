import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {

	@Input('copy-clipboard')
	public payload: string;

	@Output('copied')
	public copied: EventEmitter<string> = new EventEmitter<string>();

	@HostListener('click', ['$event'])
	public onClick(event: MouseEvent): void {

		event.preventDefault();
		if (!this.payload)
			return;

		const listener = (event: ClipboardEvent): void => {
			const clipboard = event.clipboardData || window['clipboardData'];
			clipboard.setData('text', this.payload.toString());
			event.preventDefault();

			this.copied.emit(this.payload);
		};

		document.addEventListener('copy', listener, false);
		document.execCommand('copy');
		document.removeEventListener('copy', listener, false);
	}
}
