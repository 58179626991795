<div class="reviews-settings-images">
	<h3>Images</h3>
	<div class="remote-resource wrapper" [class.resolved]="!loading">
		<div class="review-details">
			<header class="row">
				<div class="col-md-6">
					<div class="field">
						<span class="text-muted">Reviewed by</span> {{'{'}}name{{'}'}}
					</div>
				</div>
				<div class="col-md-6 text-right nmlsId">
				<span class="text-muted">
					NMLS ID: {{'{'}}number{{'}'}}
				</span>
				</div>
			</header>
			<div class="review-content text-center">
				<div class="image-holder" *ngIf="defaultImage?.id" [style.background-image]="sanitize(defaultImage.id)"></div>
				<div class="score">
					<h3>{{'{'}}Score name{{'}'}}</h3>
				</div>
				<reviews rating="5"></reviews>
				<div class="field loNmlsId">
				<span>
					{{'{'}}Individual Name{{'}'}}, NMLS ID: {{'{'}}number{{'}'}}
				</span>
				</div>
			</div>
			<footer class="row">
				<div class="col-md-6">
					<div class="data-provider">
						Data provider logo
					</div>
				</div>
				<div class="col-md-6 text-right">
					<img src="/static/images/comergence-nopad.svg" alt="Optimal Blue">
				</div>
			</footer>
		</div><!--end:review-details-->
		<div class="review-lender-logos">
			<div class="default" *ngIf="defaultImage?.id" [style.background-image]="sanitize(defaultImage.id)">
				<span class="icon-holder">
					<span class="c-icon c-icon-flag"></span>
				</span>
			</div>
			<div *ngFor="let image of filter(availableImages, { deletable: true })" [style.background-image]="sanitize(image.id)">
				<span class="icon-holder" dropdown>
					<span class="c-icon c-icon-dot-3" dropdownToggle></span>
					<ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
						<li role="menuitem">
							<a (click)="setDefault(image)" class="dropdown-item">Set as Default</a>
						</li>
						<li role="menuitem">
							<a (click)="removeImage(image)" class="dropdown-item">Remove</a>
						</li>
  					</ul>
				</span>
			</div>
			<div class="add-image" (click)="addImage()" *ngIf="maxItems > availableImages?.length">
				<span>Add Image</span>
			</div>
			<div *ngFor="let i of range((maxItems > (availableImages?.length + 1)) ? (maxItems - (availableImages?.length + 1)) : 0)"></div>
		</div><!--end:review-lender-logos -->
	</div>
</div>
