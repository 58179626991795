<span class="col-sm-6 control-value">
    {{ owner.name }}
</span>
<span class="col-sm-4 clearfix scorecard-summary control-value">
    <span *ngIf="owner.ownershipPercentage" class="pull-left">
        {{ owner.ownershipPercentage }}%
    </span>
    <span *ngIf="owner.type === 'I' && !owner.ssnVerified" class="label label-danger-wired pull-left">
        Not Verified
    </span>
</span>
<span class="col-sm-2">
    <div *ngIf="owner.type === 'I'" class="badge-holder control-value flex-row center gap-sm align-bottom">
        <span *ngIf="owner.notReviewed" class="badge badge-danger">
            {{ owner.notReviewed }}
        </span>
        <a [uiSref]="link" [uiParams]="linkParams">
            ({{ owner.total }})
        </a>
    </div>
</span>
