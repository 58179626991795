import { Component } from '@angular/core';
import { LandingPageConfig } from 'shared/social-media/landing-page/landing-page.component';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';

import hubIcon from '~static/images/material/two-tone/hub_black_24dp.svg';
import markUnreadIcon from '~static/images/material/two-tone/mark_unread_chat_alt_black_24dp.svg';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: './listings.component.html',
})
export class ListingsComponent {
    landingItems: LandingPageConfig[] = [];

    constructor(
        private userService: UserService,
    ) {

        this.landingItems = [
            {
                icon: hubIcon,
                isMaterial: true,
                sref: 'listings.company',
                title: 'Connect Listings',
                button: 'Connect',
            },
            {
                icon: markUnreadIcon,
                isMaterial: true,
                sref: 'listings.alerts',
                title: 'Alerts',
                button: 'View Alerts',
            },
        ];
    }

    enabled(): boolean {
        return this.userService.profile?.hasFeature(PACKAGE_FEATURE.LISTINGS);
    }
}
