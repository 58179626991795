import { Injectable } from '@angular/core';

import { ConnectSmMixin } from './connect-sm.mixin';

import { FacebookConnector, GmbConnector, InstagramConnector, LinkedInConnector, TwitterConnector, YoutubeConnector } from './connectors';

@Injectable()
export class IndividualConnectSmMixin extends ConnectSmMixin {
	initNetworks() {
		this.networks = {
			FB: new FacebookConnector({
				click: (entry: null) => this.connectFacebook(entry),
			}),
			IG: new InstagramConnector({
				click: () => this.showConnectInstagramModal(),
			}),
			TW: new TwitterConnector({
				url: '/connect/twitter?forceConnect=true',
				click: () => this.connectCommon(this.networks.TW),
			}),
			YT: new YoutubeConnector({
				url: '/youtube/sm-connect',
				click: () => this.connectCommon(this.networks.YT),
			}),
			LI: new LinkedInConnector({
				url: '/connect/linkedin?forceConnect=true',
				click: () => this.connectCommon(this.networks.LI),
			}),
			GMB: new GmbConnector({
				url: '/googlemb/sm-connect-flow',
				click: (entry = null) => {
					this.connectGMB(this.networks.GMB, entry);
				},
			}),
		};

		this.connectModalState = {
			resolving: false,
			services: [
				this.networks.GMB,
				this.networks.FB,
				this.networks.TW,
				this.networks.LI,
				this.networks.YT,
				this.networks.IG,
			],
		};
	}
}
