import { BulkRequestSnapshotService } from './financials/bulk-request-snapshot.service';
import { InvestorShareRequestsResourceService } from './investor-share-requests-resource.service';
import { InvestorSharesAccessRulesService } from 'commons/services/system-rules/system-access-rules/investor-shares-access-rules.service';
import { LocalStorageService } from './localstorage.service';
import { ScrollService } from './scroll.service';
import { AbstractStatusService } from './status.service';
import { OrganizationQuestionnaireService } from './organization-questionnaire.service';
import { UserManagementResourceService } from 'commons/services/user-management-resource.service';

const services = [
	BulkRequestSnapshotService,
	InvestorShareRequestsResourceService,
	InvestorSharesAccessRulesService,
	LocalStorageService,
	UserManagementResourceService,
];

export {
	LocalStorageService,
	ScrollService,
	AbstractStatusService,
	OrganizationQuestionnaireService,
	// legacy
	services,
};
