<form autocomplete="off">
	<ng-select
			[(ngModel)]="locationsInputValue"
			[ngModelOptions]="{standalone: true}"
			[items]="locationsHints"
			(change)="onSelect($any($event))"
			[loading]="loading"
			[typeahead]="locationsInput"
			placeholder="Search by state, county, city, zip, MSA code"
	>
		<ng-template ng-label-tmp let-item="item">
			<ng-container *ngIf="item.hintText && item.objectType; else msaTmp">
				<span class="text">{{item.hintText}}</span>
				<span class="label label-primary">{{item.objectType}}</span>
			</ng-container>
			<ng-template #msaTmp>
				<span class="text">{{item.msa}}</span>
				<span class="label label-primary">{{item.objectType}}</span>
			</ng-template>
		</ng-template>

		<ng-template ng-option-tmp let-item="item">
			<span class="text">{{item.hintText}}</span>
			<span class="label label-primary pull-right">{{item.objectType}}</span>
		</ng-template>

		<ng-template ng-typetosearch-tmp>
			<div class="ng-option message disabled">
				Please enter at least 2 characters to start search
			</div>
		</ng-template>
		<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
			<div class="ng-option message disabled" *ngIf="searchTerm.length > 2">
				No matches found
			</div>
			<div class="ng-option message disabled" *ngIf="searchTerm.length <= 2">
				Please enter at least 2 characters to start search
			</div>
		</ng-template>
		<ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
			<div class="ng-option message disabled">
				Searching for "{{searchTerm}}"
			</div>
		</ng-template>
	</ng-select>
</form>