import { Injectable } from '@angular/core';
import { MonitoringSettingsResourceService } from 'tpo/status/monitoring-settings-resource.service';

interface ISettings {
	status: string;
}
interface IBilling {
	invoicing: boolean;
}
export interface MonitoringContact {
	contactId: string;
	fullName: string;
	isMobileUser: boolean;
	linksCount: number;
	monitoringStatus: string;
	networks: Record<string, boolean>;
	nmlsId: number;
}

@Injectable()
export class MonitoringSettingsService {
	settings: ISettings;
	billing: IBilling;

	activeStatuses: string[] = [
		'Active',
		'Cancelling',
	];

	constructor(
		public monitoringSettingsResourceService: MonitoringSettingsResourceService,
	) {}

	getSettings(force: boolean = false): ISettings {
		if (!this.settings || force) {
			this.settings = this.monitoringSettingsResourceService.settings.get();
		}

		return this.settings;
	}

	clearSettings(): void {
		this.settings = null;
	}

	getBilling(): IBilling {
		if (!this.billing) {
			this.billing = this.monitoringSettingsResourceService.billing.get();
		}

		return this.billing;
	}

	clearBilling(): void {
		this.billing = null;
	}

	isActive = (): boolean => (
		(this.settings && this.activeStatuses.includes(this.settings.status)) ||
		(this.billing && this.billing.invoicing === true)
	)
}
