export type RealmParams = Record<string, any>;

export type RealmPagedParams = {
	page?: number;
	size?: number;
	sort?: string;
} & RealmParams;


export interface PaginationData {
	page: number;
	pagesTotal?: number;
	total: number;
}

export class PagedData<T> {
	pagination: PaginationData;
	data: T;

    public static createEmptyPage<T>(data: T): PagedData<T> {
        const pagedData: PagedData<T> = new PagedData();
        pagedData.pagination = {
            page: 0,
            pagesTotal: 0,
            total: 0,
        };
        pagedData.data = data;

        return pagedData;
    }
}
