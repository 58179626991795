import { Component, forwardRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService, TransitionService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { StaticValuesService } from 'angularjs-providers/static-values.provider';
import { UserService as UService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { PublishingPermissionsResourceService } from './publishing-permissions-resource.service';
import { PublishingPermissionsComponent } from './publishing-permissions.component';

@Component({
	templateUrl: './publishing-permissions.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => CompanyPublishingPermissionsComponent) },
	],
})
export class CompanyPublishingPermissionsComponent extends PublishingPermissionsComponent {
	isCompany: boolean = true;
	newEntry: {
		mediaLinkId?: number,
		users?: Array<{
			id?: number,
			fullName?: string,
		}>,
		permissionCode?: string,
	} = {};

	entryAddForm: RealmFormGroup = new RealmFormGroup({
		fullName: new RealmFormControl(
			'fullName',
			{
				label: 'User',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
		permission: new RealmFormControl(
			'permission',
			{
				label: 'Permission',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		publishingPermissionsResourceService: PublishingPermissionsResourceService,
		UserService: UService,
		StaticValues: StaticValuesService,
		modalService: BsModalService,
	) {
		super(
			transitionService,
			stateService,
			publishingPermissionsResourceService,
			UserService,
			StaticValues,
			modalService,
		);
	}

	fillNewEntry(): void {
		const { linkId: mediaLinkId } = this.stateService.params;
		const { permission: permissionCode, fullName: users } = this.entryAddForm.value;
		this.newEntry = {
			mediaLinkId,
			permissionCode,
			users,
		};
	}

	setupResources(): void {
		const { linkId, id } = this.stateService.params;
		const tpoId = this.User.organization.type === 'TPO' ? this.User.organization.id : id;

		this.canManage = this.User.can('TPO_PB_MANAGE_TEAM_ACCESS');

		this.permissionParams = {
			tpoId,
			linkId,
		};
		this.createParams = {
			tpoId,
		};
		this.permissionsResource = this.publishingPermissionsResourceService.company;
	}
}
