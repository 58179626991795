export const reviewStatuses = {
	NS: {
		color: 'danger',
		title: 'Not Started',
	},
	A: {
		color: 'warning',
		title: 'Assigned',
	},
	IP: {
		color: 'warning',
		title: 'In Progress',
	},
	QA: {
		color: 'warning',
		title: 'In QA',
	},
	C: {
		color: 'success',
		title: 'Completed',
	},
	PF: {
		color: 'danger',
		title: 'Payment Failed',
	},
};
