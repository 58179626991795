<form [formGroup]="form" class="flex-row align-top gap" (onValidSubmit)="save()">
	<div class="flex">
		<div>
			<form-errors [form]="form"></form-errors>

			<div class="form-group row">
				<label class="col-sm-2 control-label required">{{getFormControl('text').label}}</label>
				<div class="col-sm-10">
					<textarea
						*ngIf="!question.questionBankQuestionId"
						class="form-control resize-vertical"
						formControlName="text"
						rows="4"
						maxlength="2000"
						autocomplete="off"
					></textarea>
					<span *ngIf="question.questionBankQuestionId">{{question.text}}</span>
				</div>
			</div>

			<div class="form-group row" *ngIf="isBankQuestion">
				<label class="col-sm-2 control-label aligned-label required">{{getFormControl('categoryId').label}}</label>
				<div class="col-sm-3">
					<ng-select
						formControlName="categoryId"
						[items]="categories"
						[clearable]="false"
						placeholder="Select Category"
						bindValue="id"
						bindLabel="name"
					></ng-select>
				</div>
			</div>

			<div class="form-group row" *ngIf="!isGroup">
				<label class="col-sm-2 control-label aligned-label required">{{getFormControl('questionType').label}}</label>
				<div class="col-sm-3">
					<ng-select
						*ngIf="!question.questionBankQuestionId"
						formControlName="questionType"
						[items]="questionTypes"
						bindValue="id"
						bindLabel="title"
						placeholder="Select Type"
						[clearable]="false"
					></ng-select>
					<span *ngIf="question.questionBankQuestionId">{{questionType}}</span>
				</div>
			</div>
		</div>

		<div class="answer-options counter alpha" formArrayName="answers" *ngIf="!isGroup && hasAnswers">
			<hr>

			<div class="row text-muted">
				<div class="control-value"
					[class.col-md-2]="isYesNoQuestionType"
					[class.col-md-4]="isSkipLogicColumn && !isYesNoQuestionType"
					[class.col-md-5]="!isSkipLogicColumn && !isYesNoQuestionType && isAttachedDocumentsColumn"
					[class.col-md-6]="!isSkipLogicColumn && !isYesNoQuestionType && !isAttachedDocumentsColumn"
				>
					Answer Options
				</div>
				<div
					class="col-md-2 control-value"
					*ngIf="isSkipLogicColumn"
				>
					Skip Logic
				</div>
				<div class="col-md-2 control-value">
					Explanation Required
				</div>
				<div
					class="control-value"
					[class.col-md-6]="isYesNoQuestionType"
					[class.col-md-4]="isSkipLogicColumn && !isYesNoQuestionType"
					[class.col-md-5]="!isSkipLogicColumn && !isYesNoQuestionType"
					*ngIf="isAttachedDocumentsColumn"
				>
					Document
				</div>
			</div>
			<div class="row form-group" [formGroup]="answerOption" *ngFor="let answerOption of answers.controls; let index = index">
				<div
					class="va-m questions-holder"
					[class.col-md-2]="isYesNoQuestionType"
					[class.col-md-4]="isSkipLogicColumn && !isYesNoQuestionType"
					[class.col-md-5]="!isSkipLogicColumn && !isYesNoQuestionType && isAttachedDocumentsColumn"
					[class.col-md-6]="!isSkipLogicColumn && !isYesNoQuestionType && !isAttachedDocumentsColumn"
				>
					<div class="flex-row gap" [class.countee]="isSelectQuestionType">
						<ng-container *ngIf="isSelectEditable">
							<input
								type="text"
								class="flex form-control"
								formControlName="text"
								maxlength="200"
								autocomplete="off"
							>
							<div>
								<button type="button" class="btn btn-tertiary btn-icon" (click)="removeAnswerOption(index)" tooltip="Remove">
									<span class="glyphicon glyphicon-trash"></span>
								</button>
							</div>
						</ng-container>

						<span *ngIf="!isSelectEditable">
							{{answerOption.get('text').value}}
						</span>
					</div>
				</div>
				<div class="col-md-2 va-m skip-logic-holder"
					*ngIf="isSkipLogicColumn"
				>
					<div class="horizontal-flex-group">
						<input
							[disabled]="isNew"
							type="checkbox"
							(click)="toggleNextQuestion(answerOption, index)"
							[checked]="answerOption.get('_skipLogic').value"
						>

						<ng-select
							*ngIf="skipLogic && answerOption.get('_skipLogic').value"
							formControlName="nextQuestionId"
							[items]="skipLogic.skipOptions[question.id]"
							bindValue="id"
							bindLabel="title"
							placeholder="Select Question"
							[clearable]="false"
						></ng-select>

						<span
							class="tooltip-container"
							*ngIf="answerOption.get('_skipLogic').value && answerOption.get('nextQuestionId').value"
						>
							<span
								class="text-primary glyphicon glyphicon-eye-open"
								*ngIf="getHelp(answerOption.get('nextQuestionId').value)"
								[tooltip]="tooltipTmpl"
								placement="right"
							></span>
							<ng-template #tooltipTmpl>
								<div class="long-tooltip" [innerHTML]="getHelp(answerOption.get('nextQuestionId').value)"></div>
							</ng-template>
						</span>
					</div>
				</div>
				<div class="col-md-2 va-m explanation-holder">
					<div class="horizontal-flex-group">
						<input *ngIf="!question.questionBankQuestionId" type="checkbox" formControlName="explanationRequired">
						<span *ngIf="question.questionBankQuestionId">{{answerOption.get('explanationRequired').value ? 'YES' : 'NO'}}</span>
					</div>
				</div>
				<div
					class="va-m attached-documents"
					[class.col-md-6]="isYesNoQuestionType"
					[class.col-md-4]="isSkipLogicColumn && !isYesNoQuestionType"
					[class.col-md-5]="!isSkipLogicColumn && !isYesNoQuestionType"
					*ngIf="isAttachedDocumentsColumn"
				>
					<div class="horizontal-flex-group">
						<input
							type="checkbox"
							(click)="toggleDocument(answerOption, index)"
							[checked]="answerOption.get('_hasDocument').value"
						>

						<ng-select
							*ngIf="answerOption.get('_hasDocument').value"
							formControlName="documents"
							[items]="availableDocuments"
							bindValue="investorClientDocumentId"
							bindLabel="documentName"
							placeholder="Select"
							[searchable]="true"
							[closeOnSelect]="false"
							[multiple]="true"
						></ng-select>
					</div>
				</div>
			</div>
			<div class="row text-muted" *ngIf="isSelectEditable">
				<div class="col-md-4 control-value">
					<div class="shifted">
						<button type="button" class="btn btn-sm btn-secondary add-answer-option" (click)="addAnswerOption()">Add Answer</button>
					</div>
				</div>
			</div>
		</div>

		<ng-content select="[form-footer]"></ng-content>
	</div>

	<div *ngIf="hasOwnSubmitDiscardButtons">
		<button type="submit" class="btn btn-tertiary btn-icon">
			<span class="glyphicon glyphicon-ok"></span>
		</button>
		<button class="btn btn-tertiary btn-icon" (click)="discard()">
			<span class="glyphicon glyphicon-remove"></span>
		</button>
	</div>
</form>
