import { Component, forwardRef, OnInit } from '@angular/core';

import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import {
    ScorecardReviewHistory,
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-reviews.interface';


@Component({
    templateUrl: './scorecard-review-history-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardReviewsHistoryListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardReviewsHistoryListComponent) },
    ],
})
export class ScorecardReviewsHistoryListComponent extends NewPagedListComponent<ScorecardReviewHistory> implements OnInit {
    static listName = 'reviewsHistoryList';
    reviewId: number;

    constructor(
        public uiRouter: UIRouter,
        private readonly scorecardService: ScorecardService,
    ) {
        super(uiRouter);
    }

    async ngOnInit(): Promise<void> {
        this.reviewId = this.uiRouter.globals.params.reviewId;

        super.ngOnInit();
    }

    loadList(params: ListParams): Observable<PagedData<ScorecardReviewHistory[]>> {
        return this.scorecardService.getReviewHistory(this.reviewId, params);
    }
}
