<section class="social-compliance-review-checklist">
	<h3>Observations</h3>
	<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
		<div class="row-fluid header">
			<div class="col-sm-2">Group</div>
			<div class="col-sm-10">Observations</div>
		</div>

		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-sm-2">
				<div>{{item.group}}</div>
				<div *ngIf="item.isAuthorized === false" class="status-icon-holder">
					<span class="c-icon c-icon-cross text-danger"></span>&nbsp;&nbsp;Not Authorized
				</div>
				<div *ngIf="item.isAuthorized === true" class="status-icon-holder">
					<span class="c-icon c-icon-check text-success"></span>&nbsp;&nbsp;Authorized
				</div>
			</div>
			<div class="col-sm-10">
				<div class="observation" *ngFor="let observation of item.checklistObservations">
					<div class="horizontal-flex-group align-top">
						<show-regulations-icon
							[observationId]="observation.observationId"
						></show-regulations-icon>
						<span>{{observation.observation}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-muted text-bigger">No data</div>
		</div>
	</div>
</section>
