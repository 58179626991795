<div class="modal-header">
	<button
		type="button"
		class="close pull-right"
		aria-label="Close"
		(click)="modalRef.hide()"
	>&times;</button>
	<h4 class="modal-title">Assign Questionnaire</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="true">
	<div>
		<b class="inline-mb-6">{{snapshotTitles.notifyAssignment}}</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.toClient}}</span><br>
		<div class="fake-select text-smaller" *ngIf="snapshotData?.assignmentUsers?.length; else dash">
			<span *ngFor="let user of snapshotData.assignmentUsers">{{user.firstName}} {{user.lastName}}</span>
		</div>
	</div>
	<br>
	<div>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.ccInternal}}</span><br>
		<div class="fake-select text-smaller">
			<ng-container *ngIf="snapshotData?.ccInternalUsers?.length; else dash">
				<span *ngFor="let user of snapshotData.ccInternalUsers">{{user.firstName}} {{user.lastName}}</span>
			</ng-container>
		</div>
	</div>
	<br>
	<div class="message">
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.message}}</span><br>
		<div *ngIf="snapshotData?.message as msg; else dash" [innerHTML]="msg+'<br>'"></div>
	</div>
	<br>
	<div>
		<b class="inline-mb-6">{{snapshotTitles.notifyCompletion}}</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.toInternal}}</span><br>
		<div class="fake-select text-smaller" *ngIf="snapshotData?.toInternalUsers?.length; else dash">
			<span *ngFor="let user of snapshotData.toInternalUsers">{{user.firstName}} {{user.lastName}}</span>
		</div>
	</div>
	<br>
	<div class="scheduled">
		<b class="inline-mb-6">{{snapshotTitles.scheduled}}</b><br>
		<span class="text-muted text-smaller inline-mb-6">{{snapshotTitles.date}}</span><br>
		<span *ngIf="snapshotData?.scheduledDate as date; else dash">{{date}}</span>
	</div>

	<ng-template #dash><div class="text-medium">&ndash;</div></ng-template>
</div>
<div class="modal-footer">
	<div>
		<button type="button" (click)="modalRef.hide()" class="btn btn-primary">
			Close
		</button>
	</div>
</div>
