export abstract class AbstractResolver {
	promise: Promise<any>;

	constructor(public url) {
		this.promise = new Promise((resolve, reject) => {
			const element = this.createInstance();
			element.onload = () => resolve({});
			element.onerror = (error: any) => reject();
			document.getElementsByTagName('head')[0].appendChild(element);
		});
	}

	abstract createInstance();
}
