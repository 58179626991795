<form [formGroup]="form" class="flex-row align-top gap" (onValidSubmit)="save()">
	<div class="flex">
		<form-errors [form]="form"></form-errors>

		<div class="form-group row">
			<label class="col-sm-2 control-label aligned-label text-right required">{{getFormControl('text').label}}</label>
			<div class="col-sm-10">
				<input
					type="text"
					class="form-control"
					formControlName="text"
					maxlength="2000"
					autocomplete="off"
				>
			</div>
		</div>
	</div>

	<div>
		<button type="submit" class="btn btn-tertiary btn-icon">
			<span class="glyphicon glyphicon-ok"></span>
		</button>
		<button class="btn btn-tertiary btn-icon" (click)="discard()">
			<span class="glyphicon glyphicon-remove"></span>
		</button>
	</div>
</form>
