import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { RealmStateDeclaration } from 'routes/sharedRoutes';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { GlobalSearchRulesService } from 'commons/services/user-capabilities/global-search-rules.service';

@Component({
    selector: 'global-navbar',
    templateUrl: './global-navbar.component.html',
})
export class GlobalNavbarComponent {
    stateName: string;
    inApplication = false;
    User: UserProfile;
    protected channelId: number;
    hasGlobalSearchPermission: boolean = false;

    constructor(
        { success$ }: UIRouterGlobals,
        { profile$ }: UserService,
        private readonly globalSearchRulesService: GlobalSearchRulesService,
        private readonly cd: ChangeDetectorRef,
    ) {
        profile$.subscribe((User) => {
            this.User = User;
            this.hasGlobalSearchPermission = this.globalSearchRulesService.userMayPerformSearch(this.User);
            this.cd.markForCheck();
        });

        // no need to unsubscribe since it dies with app
        success$.subscribe((t) => {
            const { name } = t.targetState().state() as RealmStateDeclaration;
            const { channelId } = t.params();
            this.channelId = channelId;
            this.stateName = name;
            this.inApplication = !!channelId && !!name.match(/^(account|people)(\..+)?$/);
            this.cd.markForCheck();
        });
    }
}
