<div class="social-accounts-company audit-accounts remote-resource" [class.resolved]="list?.$resolved && !connecting">
	<div class="clearfix" *ngIf="isCurrentUser && !isReviewCompleted" [ngSwitch]="publisherRequests?.$resolved">
		<h3>Social Accounts</h3>
	</div>

	<div class="flex-row gap mb20">
		<segmented-controls
			[items]="availableStates"
			[value]="selectedState"
			(selected)="changeState({ state: $event })"
		></segmented-controls>

		<div class="w250">
			<search-list placeholder="Account Name, Network, URL"></search-list>
		</div>

		<div
			*ngIf="!isReviewCompleted && (canManage || isCurrentUser)"
			class="right"
		>
			<button
				*ngIf="!newSocialMediaLink.show"
				type="button"
				class="btn btn-secondary"
				(click)="newSocialMediaLink.toggleShow(true)"
			>
				<span class="glyphicon glyphicon-plus"></span>
				Add Link
			</button>
		</div>
	</div>

	<notification
		*ngIf="isNotificationSet"
	></notification>

	<div
		class="clearfix mb20"
		[hidden]="!newSocialMediaLink.show"
	>
		<new-sm-link
			#newSocialMediaLink
			[save]="addLink"
		></new-sm-link>
	</div>

	<div>
		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header
					[ngClass]="columnClass.account"
					columnName="account"
				>
					Account
				</sortable-header>
				<sortable-header
					*ngIf="!isLender && !isRemoved"
					[ngClass]="columnClass.lastConnectionStatus"
					columnName="lastConnectionStatus"
				>
					<span class="two-liners">Status</span>
				</sortable-header>
				<div
					*ngIf="isComergence && !isRemoved"
					[ngClass]="columnClass.rss"
				>
					RSS
				</div>
				<div [ngClass]="columnClass.actions"></div>
			</div>

			<div
				*ngIf="filtersApplied"
				class="row-fluid yellow-bg no-border"
			>
				<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
			</div>

			<div
				*ngFor="let socialAccountLink of list"
				class="row-fluid"
				[class.danger]="!isRemoved && socialAccountLink?.ui?.isDangerClass"
			>
				<div class="va-m" [ngClass]="columnClass.account">
					<div class="flex-row gap">
						<sm-icon
							[config]="socialAccountLink?.data"
							[showRssStatus]="(isSA || isWP) && (socialAccountLink?.data?.monitoringStatus === 'on' || socialAccountLink?.data?.rssLink)"
						></sm-icon>
						<div class="flex text-overflow">
							<ng-container *ngIf="socialAccountLink?.data?.networkCode !== 'RS' && socialAccountLink?.data?.link; then companyLinkAnchor; else companyLinkName"></ng-container>
							<ng-template #companyLinkAnchor>
								<a [href]="socialAccountLink?.data?.link | hrefReady" target="_blank">{{socialAccountLink?.data?.name}}</a>
							</ng-template>
							<ng-template #companyLinkName>
								{{socialAccountLink?.data?.name}}
							</ng-template>
						</div>
					</div>
				</div>
				<div class="va-m" [ngClass]="columnClass.lastConnectionStatus"  *ngIf="!isLender && !isRemoved">
					<company-monitoring-status
						[status]="socialAccountLink?.data?.lastConnectionStatus"
						[isRemovalRequested]="socialAccountLink?.data?.isRemovalRequested"
					></company-monitoring-status>
				</div>
				<div class="va-m" [ngClass]="columnClass.rss" *ngIf="isComergence && !isRemoved">
					<manage-rss
						[params]="socialMediaServiceParams"
						[link]="socialAccountLink"
						[canManage]="canManageRss"
						(reload)="loadList(params)"
					></manage-rss>
				</div>
				<div class="va-m" [ngClass]="columnClass.actions">
					<div class="flex-row right gap" *ngIf="isSA">
						<div class="medium-icon-holder" *ngIf="socialAccountLink?.data?.isRemovedFromSourceSystem && !isLender">
							<span class="glyphicon medium-icon text-danger glyphicon-remove-sign va-m"
							      tooltip="Removed from {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}}"
							></span>
							{{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}} Removed
						</div>

						<ng-container
							*ngIf="isComergence && canManage && socialAccountLink.data?.isRemovalRequested"
							[ngTemplateOutlet]="manageLinkControls"
							[ngTemplateOutletContext]="{ link: socialAccountLink }"
						></ng-container>

						<ng-container *ngIf="!isReviewCompleted && (!!socialAccountLink?.data?.publisherStatus || canRemove && socialAccountLink?.ui?.hasActionDropdown); then dropdownActive; else dropdownDisabled"></ng-container>
						<ng-template #dropdownActive>
							<div class="dropdown" dropdown>
								<a class="dropdown-toggle btn btn-icon btn-tertiary text-bigger text-very-light" dropdownToggle>
									<span class="c-icon c-icon-dot-3"></span>
								</a>
								<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [ngSwitch]="socialAccountLink?.data?.publisherStatus">
									<li role="menuitem" *ngIf="canRemove && socialAccountLink?.ui?.hasActionDropdown">
										<a (click)="removeLink(socialAccountLink)">Remove</a>
									</li>
									<li role="menuitem" *ngSwitchCase="'A'">
										<a (click)="removeCurrentPublisherAccess(socialAccountLink)">Remove Access</a>
									</li>
									<li role="menuitem" *ngSwitchCase="'R'">
										<a (click)="requestAccess(socialAccountLink, true)">Cancel Request</a>
									</li>
								</ul>
							</div>
						</ng-template>
						<ng-template #dropdownDisabled>
							<span class="btn btn-icon btn-icon-disabled"></span>
						</ng-template>
					</div>

					<div *ngIf="isWP" class="flex-row right gap">
						<div *ngIf="socialAccountLink?.data?.isRemovedFromSourceSystem && !isLender">
							<span class="glyphicon medium-icon text-danger glyphicon-remove-sign va-m"
							      tooltip="Removed from {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}}"
							></span>
							{{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}} Removed
						</div>

						<ng-container
							*ngIf="isComergence && canManage && socialAccountLink.data?.isRemovalRequested"
							[ngTemplateOutlet]="manageLinkControls"
							[ngTemplateOutletContext]="{ link: socialAccountLink }"
						></ng-container>

						<ng-container *ngIf="canRemove && socialAccountLink?.ui?.hasActionDropdown; then dropdownActive; else dropdownDisabled"></ng-container>
						<ng-template #dropdownActive>
							<div class="dropdown" dropdown>
								<a class="dropdown-toggle btn btn-icon btn-tertiary text-bigger text-very-light" dropdownToggle>
									<span class="c-icon c-icon-dot-3"></span>
								</a>
								<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
									<li role="menuitem">
										<a (click)="removeLink(socialAccountLink)">Remove</a>
									</li>
								</ul>
							</div>
						</ng-template>
						<ng-template #dropdownDisabled>
							<span class="btn btn-icon btn-icon-disabled"></span>
						</ng-template>
					</div>

					<div class="flex-row right gap" *ngIf="isRemoved && canRestore">
						<div class="dropdown" dropdown>
							<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
								<span class="c-icon c-icon-dot-3"></span>
							</a>
							<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
								<li role="menuitem">
									<a (click)="restoreLink(socialAccountLink)">Restore</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="row-fluid" *ngIf="list.$resolved && !list.length">
				<span class="col-sm-12 text-center text-bigger text-muted va-m">
					{{filtersApplied ? 'No accounts found' : 'No accounts connected'}}
				</span>
			</div>
		</div>

		<br/>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>

<ng-template #manageLinkControls let-link="link">
	<div class="flex-row right gap">
		<button type="button" class="btn btn-danger" (click)="manageLink(link, 'acceptRemoval')">Remove</button>
		<button type="button" class="btn btn-secondary" (click)="manageLink(link, 'declineRemoval')">Keep</button>
	</div>
</ng-template>
