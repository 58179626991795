<full-page-layout class="social-compliance-publisher-post-template edit" (onClose)="close()">

	<ng-template #headerTemplate>
		<h4 class="page-title">Post Editor</h4>
	</ng-template>

	<ng-template #contentTemplate>
		<form class="panel" [formGroup]="templateForm" (onValidSubmit)="save()">
			<div class="panel-body">

				<form-errors [form]="templateForm"></form-errors>

				<h3 class="panel-title">{{title}}</h3>

				<section [ngClass]="{ 'remote-resource': template, 'resolved': template.$resolved && !resolving && sharingComponentReady }">

					<div class="form-group">
						<label class="control-label required">Title</label>
						<input type="text" class="form-control" formControlName="summary" maxlength="2000" />
					</div>

					<div class="form-group">
						<label class="control-label">Tags</label>
						<tag-input
							formControlName="tags"
							[modelAsStrings]="true"
							[separatorKeyCodes]="[188, 186]"
							placeholder="Enter Tag"
							secondaryPlaceholder="Enter Tag"
						>
							<tag-input-dropdown
								[appendToBody]="false"
								[minimumTextLength]="1"
								[autocompleteObservable]="tags.fetchOptions"
							></tag-input-dropdown>
						</tag-input>
						<div class="control-description">Use tags to organize your templates library</div>
					</div>

					<div class="form-group">
						<label class="control-label">Folder</label>
						<ng-select class="first-item-border"
							formControlName="folder"
							[items]="folders?.$promise | async"
							[loading]="!folders?.$resolved"
							[clearable]="false"
							bindLabel="name">
						</ng-select>
					</div>

					<div class="form-group">
						<template-text-edit
							*ngIf="template?.$resolved"
							[text]="templateForm?.value?.text"
							(onDynamicFields)="updateDynamicFields($event)"
							(textChange)="changeText($event)"
						></template-text-edit>
					</div>

					<div class="form-group">
						<post-attachments
							[post]="template"
							[editable]="true"
							[config]="attachment.config"
							(error)="attachment.error($event)"
							(status)="attachment.status($event)">
						</post-attachments>
					</div>

					<div class="form-group" *ngIf="campaigns.canManage">
						<label class="control-label">Campaigns</label>
						<div class="remote-resource" [class.resolved]="campaigns.options?.$resolved">
							<ng-select class="campaigns"
								formControlName="campaigns"
								[items]="campaigns.options?.$promise | async"
								bindLabel="name"
								[searchable]="true"
								[multiple]="true"
								[closeOnSelect]="false"
								placeholder="Select Campaigns">
							</ng-select>
						</div>
					</div>

					<div class="form-group">
						<label class="control-label required">Sharing</label>
						<campaign-share-to-edit
							*ngIf="template?.$resolved"
							[value]="templateForm?.value?.assetSharing"
							(change)="setAssetSharing($event)"
							(ready)="setSharingReady($event)"
						></campaign-share-to-edit>
					</div>

<!--					<div class="form-group">-->
<!--						<label class="control-label">Account Types</label>-->
<!--						<template-network-select-->
<!--							*ngIf="template?.$resolved"-->
<!--							[value]="templateForm?.value?.targetNetworkCodes"-->
<!--							(change)="selectNetworks($event)"-->
<!--							(ready)="setNetworksReady($event)"-->
<!--						></template-network-select>-->
<!--					</div>-->

					<div class="form-group">
						<label class="checkbox-inline">
							<input type="checkbox" [checked]="!template.isEditable" (change)="setEditable($event)" />
							{{templateForm.getFieldLabel('isEditable')}}
						</label>
					</div>
				</section>
			</div>
			<div class="panel-footer">
				<div class="pull-left" *ngIf="!isNew">
					<button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
				</div>
				<div class="pull-right">
					<button type="submit" class="btn btn-primary" [disabled]="!template?.$resolved || resolving || attachment.resolving">Save</button>
				</div>
			</div>
		</form>
		<posts-preview
			*ngIf="template?.$resolved && dynamicFields.length"
			[class.hide-logo]="true"
			[dynamicFields]="dynamicFields"
			[text]="templateForm.get('text').value"
			[resolvedDynamicContent]="null"
			[attachments]="template.attachments"
			[attachmentsErrors]="attachmentsErrors"
			[linkPreview]="linkPreview"
			[accounts]="previewAccounts"
		></posts-preview>
	</ng-template>

</full-page-layout>
