<div class="rows-striped">
	<div class="row" *ngFor="let section of sections">
		<div class="col-md-4 text-muted">{{section.title}}</div>
		<div class="col-md-8 row">
			<div class="col-md-6">
				<div *ngFor="let permission of section.columns[0]">
					<permission [map]="permissionState" [editing]="editing" [permissionConfig]="permission">{{permission.title}}</permission>
				</div>
			</div>
			<div class="col-md-6">
				<div *ngFor="let permission of section.columns[1]">
					<permission [map]="permissionState" [editing]="editing" [permissionConfig]="permission">{{permission.title}}</permission>
				</div>
			</div>
		</div>
	</div>
</div>
