<div class="contacts">
	<div class="gray-head flex-head">
		<h2 class="col-min middle">
			Contacts
		</h2>

		<div *ngIf="hasPermission('MANAGE_CONTACT')" class="pull-right bulk-buttons">
			<a class="btn btn-primary" fixedSref=".new" [uiParams]="{contactId: 'new'}">Add Contact</a>
		</div>
	</div>

	<notification></notification>

	<div>
		<div class="row">
			<div class="col-md-12 text-right">
				<ul class="inline-toggle normalize"  style="line-height: 28px">
					<li *ngFor="let item of filters.previous" [class.active]="params.previous == item.value" (click)="setFilter({previous: item.value})">{{item.title}}</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="alphabetic-filter">
					<ul>
						<li><a (click)="setFilter({letter: null})" [class.active]="!params.letter">All</a></li>
						<li *ngFor="let l of filters.letter">
							<a (click)="setFilter({letter: l.letter})" [class.active]="params.letter == l.letter" [class.text-muted]="!l.isAvailable" [aDisabled]="!l.isAvailable">{{l.letter}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<br>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="contacts.$resolved">
		<div class="row-fluid header">
			<div class="col-md-4">
				<search-list placeholder="Search by any parameter"></search-list>
			</div>
			<span class="col-md-2"><b class="two-liners">Designation</b></span>
			<span class="col-md-2"><b class="two-liners">Location</b></span>
			<span class="col-md-2"><b class="two-liners">Phone</b></span>
			<span class="col-md-2"><b class="two-liners">Email</b></span>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
		</div>

		<div class="row-fluid" *ngFor="let item of contacts">
			<span class="col-sm-4 loan-margin" [ngSwitch]="item.contactType">
                <ng-container *ngSwitchCase="TYPE.CRM">
				    <a fixedSref=".:contactId.information" [uiParams]="{contactId: item.contactId, id: item.relatedAccount && (item.relatedAccount.tpoId || item.relatedAccount.nmlsId)}" class="text-bigger">{{item.fullName}}</a>
                </ng-container>

                <ng-container *ngSwitchDefault  [ngSwitch]="item.relatedAccount.tpoId > 0">
    				<i *ngIf="item.isMobileUser" class="c-icon c-icon-phone" placement="top" tooltip="Mobile User"></i>
				    <span *ngIf="item.individualNmlsId" class="c-icon c-icon-loan text-light" tooltip="Loan Originator" placement="top"></span>

                    <a *ngSwitchCase="true" fixedSref="client.:id.contacts.:contactId.information" [uiParams]="{contactId: item.contactId, id: item.relatedAccount.tpoId}" class="text-bigger">{{item.fullName}}</a>
                    <a *ngSwitchDefault fixedSref="nmls.client.:id.contacts.:contactId.information" [uiParams]="{contactId: item.contactId, id: item.relatedAccount.nmlsId}" class="text-bigger">{{item.fullName}}</a>
                </ng-container>
                <br>
                <div *ngIf="item.relatedAccount.tpoId">
                    <a class="light-link" fixedSref="client.:id.account.information" [uiParams]="{id: item.relatedAccount.tpoId}"><span class="text-light"><b>{{item.relatedAccount.name}}</b> NMLS ID:{{item.relatedAccount.nmlsId}}</span></a>
                </div>
                <div *ngIf="!item.relatedAccount.tpoId && item.relatedAccount.nmlsId">
                    <a class="light-link" fixedSref="nmls.client.:id.account.information" [uiParams]="{id: item.relatedAccount.nmlsId}"><span class="text-light"><b>{{item.relatedAccount.name}}</b> NMLS ID:{{item.relatedAccount.nmlsId}}</span></a>
                </div>

                <!-- TODO: check contact-list template, it has .lender-individual route, normalize crm routes -->
			</span>
			<span class="col-md-2">
				<span class="text-light">{{item.officerDesignation?.join(', ') || '&ndash;'}}</span>
			</span>
			<span class="col-md-2">
				{{item?.fullAddress || '&ndash;'}}<br>
				{{item?.zip}}
			</span>
			<span class="col-md-2">{{item.phone || '&ndash;' | tel}}</span>
			<span class="break-word col-md-2">
				<a [aDisabled]="!item.email" href="mailto:{{item.email}}">{{item.email || '&ndash;'}}</a>
			</span>
		</div>

		<div class="row-fluid" *ngIf="noMatches">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>

		<div class="row-fluid" *ngIf="!contacts.length && !noMatches">
			<span class="col-md-12 text-muted text-center text-bigger">No contacts</span>
		</div>

		<br>
		<new-paging class="no-border" [(list)]="contacts" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
