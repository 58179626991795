import { trim } from 'lodash';
import { ValidatorFn, ValidationErrors } from '@angular/forms';

import { RealmFormArray } from 'commons/forms';

export function QuestionAnswersValidator(checkCommas: boolean): ValidatorFn {
	return (control: RealmFormArray): ValidationErrors | null => {
		let emptyError = false;
		let commaError = false;
		let uniqueError = false;
		const uniqueValues = [];

		if (!control.controls?.length) {
			control.markAsTouched();
			return {
				required: {
					value: control.value,
					template: 'Please enter <b>Answer Options</b>',
				},
			}
		}

		control.controls.forEach((control) => {
			const value = control.value;

			// untouched fields are not validated
			// you have to call control.markAsTouched() if you need to check them
			if (control.untouched) {
				return;
			}

			if (!trim(value.text)) {
				emptyError = true;
			} else if (uniqueValues.indexOf(value.text) >= 0) {
				uniqueError = true;
			} else {
				if (value.text.indexOf(',')!== -1) {
					commaError = true;
				}
				uniqueValues.push(value.text);
			}
		});

		if (emptyError) {
			control.markAsTouched();
			return {
				emptyError: {
					value: control.value,
					template: `<b>${control.label}</b> should not have empty values`,
				},
			};
		}

		if (checkCommas && commaError) {
			control.markAsTouched();
			return {
				commaError: {
					value: control.value,
					template: `<b>${control.label}</b> should not have commas in values`,
				},
			};
		}

		if (uniqueError) {
			control.markAsTouched();
			return {
				uniqueError: {
					value: control.value,
					template: `<b>${control.label}</b> should have only unique values`,
				},
			};
		}

		return null;
	};
}
