import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class OriginatorsResourceService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		// console.log('create ContactsResourceService');
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/tpos/:tpoId/individual-contacts/originators/:nmlsId`;
		const defaultParams = User.profile.organization.type === 'TPO' ? {tpoId: User.profile.organization.id} : {};
		this.resource = $resource(`${basePath}`, {...defaultParams}, {
			list: {method: 'get', isArray: true},
		});
	}

	list = (...args) => this.resource.list(...args);
}
