import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { PagedData, PaginationData, RealmParams, RealmPagedParams } from 'commons/services/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { pickBy } from 'lodash';

export type HttpRequestOptions = {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    reportProgress?: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class RealmHttpClient {
	constructor(protected http: HttpClient) {
	}

	request<T>(method: string, url: string, rawParams: RealmParams = null, body: unknown = null): Observable<T> {
		const params = this.processParams(rawParams);

		return this.http.request<T>(method, url, {
			observe: 'body',
			params,
			body,
			withCredentials: true,
		});
	}

    fullRequest(method: string, url: string, rawParams: RealmParams = null, body: unknown = null, options: HttpRequestOptions = {}): Observable<HttpResponse<string>> {
        const params = this.processParams(rawParams);

        return this.http.request(method, url, {
            observe: 'response',
            responseType: 'text',
            withCredentials: true,
            params,
            body,
            ...options,
        });
    }


	requestWithHeaders<T>(method: string, url: string, rawParams: RealmParams = null, body: unknown = null, headers: Record<string, string | string[]>): Observable<T> {
		const params = this.processParams(rawParams);

		return this.http.request<T>(method, url, {
			observe: 'body',
			params,
			body,
            headers,
			withCredentials: true,
		});
	}

	pagedRequest<T>(method: string, url: string, rawParams: RealmPagedParams = null, body: unknown = null): Observable<PagedData<T>> {
		const params = this.processParams(rawParams);

		return this.http.request<T>(method, url, {
			observe: 'response',
			params,
			body,
			withCredentials: true,
		}).pipe(
			switchMap<HttpResponse<T>, Observable<PagedData<T>>>((response, index): Observable<PagedData<T>> => {
				const { body: data, headers } = response;
				const pagination: PaginationData = {
					page: parseFloat(headers.get('X-Meta-Current-Page')),
					pagesTotal: parseFloat(headers.get('X-Meta-Total-Pages')),
					total: parseFloat(headers.get('X-Meta-Total-Elements')),
				}

				return of({
					pagination,
					data,
				});
			}),
		);
	}

	uploadFileWithForm<T, U>(
		method: ('POST' | 'PUT' | 'PATCH'),
		url: string,
		filePartName: string,
		formPartName: string,
		file: File,
		form: T,
		requestParams?
	): Observable<U> {
        const formData = new FormData();
        formData.append(filePartName, file);

		const formPart = new Blob(
			[ JSON.stringify(form) ],
			{
				type: 'application/json',
			}
		);
        formData.append(formPartName, formPart);

		if (!requestParams) {
			requestParams = {};
		}

        return this.request(
            method,
            url,
            requestParams,
            formData
        );
	}

	processParams(rawParams: RealmParams): RealmParams {
		const filteredParams = pickBy(rawParams, value => !!value);
		return { ...filteredParams, noCache: Date.now() };
	}
}
