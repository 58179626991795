import { mapValues, map, omitBy, omit } from 'lodash';
import { Component, OnInit, forwardRef } from '@angular/core';
import { UIRouter, TransitionService, StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { Mixin } from 'utils/mixin.decorator';

import { UserService } from 'angularjs-providers/user.provider';

import { ColumnsManagerMixin, ExportMixin } from 'commons/mixins';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { PagedData } from 'commons/services/http';
import { FilterDefault } from 'commons/components/list/list.interface';

import {
    LenderApplication,
} from 'lender/applications/applications.service';
import { Entity } from 'tpo/documents/documents.interface';
import { ApplicationStatusColors } from 'lender/applications/application-status-colors';
import { RelationStatusColors } from 'lender/relation-status-colors';


import { ApplicationTypes } from 'lender/applications/application-types';

import { columnsConfig } from './columns-config';
import { ComergenceApplicationsService } from 'comergence/applications/applications.service';

@Mixin([ ColumnsManagerMixin, ExportMixin ])
@Component({
	templateUrl: './applications-list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ComergenceApplicationsListComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => ComergenceApplicationsListComponent) },
	],
})
export class ComergenceApplicationsListComponent extends NewPagedListComponent<LenderApplication> implements OnInit, ColumnsManagerMixin {
	static listName = 'comergenceApplications' as const;

	applicationStatusColors = ApplicationStatusColors;
	relationStatusColors = RelationStatusColors;
	filters: {
		applicationStatus: Observable<FilterDefault[]>;
		applicationType: FilterDefault[];
		lenderId: Observable<Entity[]>;
		createdAfter?: Date;
		createdBefore?: Date;
	}
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};

	modalRef: BsModalRef;

	StorageService: typeof ColumnsManagerMixin.prototype.StorageService;
	localStorageName = 'CCMApplicationsCols';
	columns: typeof ColumnsManagerMixin.prototype.columns = new Map();
	columns$: typeof ColumnsManagerMixin.prototype.columns$;
    columnValues: () => [];
	prepareColumns: typeof ColumnsManagerMixin.prototype.prepareColumns;
	toggleColumn: typeof ColumnsManagerMixin.prototype.toggleColumn;
	getColumnVisibility: typeof ColumnsManagerMixin.prototype.getColumnVisibility;

	// ExportMixin
	url: typeof ExportMixin.prototype.url;
	export: typeof ExportMixin.prototype.export;
    lenderId: number;

	moreFilters: boolean = false;
    hasStatusPermission: boolean = false;

	constructor(
		router: UIRouter,
		public transitionService: TransitionService,
		public stateService: StateService,
		public User: UserService,
		public service: ComergenceApplicationsService,
		public modalService: BsModalService,
	) {
		super(router);
		this.prepareFilters();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.readColumnsConfig();
	}

	prepareFilters() {
		this.filters = {
			applicationStatus: this.service.getApplicationsStatuses().pipe(share()),
			applicationType: ApplicationTypes,
			lenderId: this.service.getAllLenders().pipe(share()),
			createdAfter: undefined,
			createdBefore: undefined,
		};

		this.defaultParams = {
			...this.defaultParams,
			applicationStatus: [],
			applicationType: [],
			lenderId: [],
			createdAfter: undefined,
			createdBefore: undefined,
		};
	}

	setFilter(filters, resetPage: boolean = true): void {
		const processedFilters = mapValues(filters, (value: any, key: string) => {
			switch (key) {
				case 'createdAfter':
				case 'createdBefore':
					return value == 'Invalid Date' ? this.setFilter({ key: null }) : value;
				default:
					return map(value || [], 'id');
			}
		});

		super.setFilter(processedFilters, resetPage);
	}

	readColumnsConfig() {
		this.prepareColumns(columnsConfig);
	}

	getClearParams(queryParams): ListParams {
		return omitBy(omit(queryParams, '#', '$inherit'), (value) => (!value));
	}

	processParams(params): ListParams {
		return mapValues(this.getClearParams(params), (value, key) => {
			if (['createdAfter', 'createdBefore'].indexOf(key) !== -1) {
				return value?.getTime() || value;
			} else {
				return value;
			}
		});
	}

	protected loadList(params: ListParams): Observable<PagedData<LenderApplication[]>> {
		return this.service.getApplications(this.processParams(params));
	}
}
