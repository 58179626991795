<section class="remote-resource" [class.resolved]="commentTypes && comments && !isSaving">
    <div class="comments-list">
        <div class="clearfix">
            <div class="pull-left">
                <h2>Comments</h2>
            </div>
        </div>

        <div>
            <form
                [formGroup]="commentForm"
                (onValidSubmit)="saveComment()"
            >
                <form-errors [form]="commentForm"></form-errors>

                <div class="form-group">
                    <div class="control-value">
                        <textarea
                            formControlName="comment"
                            maxlength="2000"
                            class="form-control no-resize"
                            [rows]="isEditing ? 5 : 1"
                            placeholder="Add your comment here"
                            (focus)="startEditing()"
                        >
                        </textarea>
                    </div>

                    <div *ngIf="isEditing" class="form-group row">
                        <ng-select
                            formControlName="commentType"
                            [items]="commentTypes"
                            class="col-md-3"
                            bindLabel="label"
                            [clearable]="false"
                            [searchable]="false"
                        >
                        </ng-select>

                        <div class="col-md-9 control-value">
                            {{ typeDescription }}
                        </div>
                    </div>
                </div>

                <div *ngIf="isEditing" class="btn-group-lg">
                    <input type="submit" value="Add Comment" class="btn btn-primary">
                    <button type="button" class="btn btn-default" (click)="cancelEditing()">Cancel</button>
                </div>
            </form>
            <br>
        </div>

        <div class="rows-bordered remote-resource" [class.resolved]="comments">
            <div *ngFor="let comment of comments" class="row">
                <alert-comment [comment]="comment"></alert-comment>
            </div>
        </div>
        <div *ngIf="!comments || !comments.length" class="row-fluid">
            <span class="col-md-12 text-muted text-center text-bigger">No Comments</span>
        </div>
        <br>
    </div>
</section>
