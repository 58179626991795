import { filter, some, values, includes } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { RealmFormControl } from 'commons/forms';

import { UserService } from 'angularjs-providers/user.provider';

import { TpoPaymentsService } from './payments.service';
import { Payment } from './payments.interface';


@Component({
	templateUrl: './payments.component.html',
})
export class TpoPaymentsComponent implements OnInit {
	payments$: Observable<Payment[]>;
	tpoId: number;

	searchControl = new RealmFormControl(
		'searchTerm',
		{
			label: 'Search by any parameter',
			updateOn: 'change',
		},
	);
	filteredPayments: Observable<Payment[]>;

	constructor(
		{ profile }: UserService,
		private readonly paymentsService: TpoPaymentsService,
	) {
		this.tpoId = profile.organization.id;
	}

	async ngOnInit(): Promise<void> {
		this.payments$ = this.paymentsService.payments(this.tpoId);
		this.filteredPayments = this.searchControl.valueChanges.pipe(
			startWith(''),
			debounceTime(400),
			distinctUntilChanged(),
			switchMap(val => this.filterData(val)),
		);
	}

	filterData(val: string): Observable<Payment[]> {
		if (!val) {
			return this.payments$;
		} else {
			return this.payments$.pipe(
				map((payments) => filter(payments, (payment) =>
					some(values(payment), (prop) =>
						prop && includes(prop.toString().toLowerCase(), val.toLowerCase()),
					),
				)),
			);
		}
	}
}
