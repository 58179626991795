import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ForgotModalComponent } from 'login/forgot-modal/forgot-modal.component';
import { ForgotEntityType } from 'login/registration/registration.service';

@Component({
    template: '',
})
export class ForgotPageComponent {
    constructor(
        public modalRef: BsModalRef,
        private readonly modalService: BsModalService,
    ) {
        this.showForgotModal('password');
    }

    showForgotModal(entity: ForgotEntityType) {
        const initialState: Partial<ForgotModalComponent> = { entity };
        this.modalRef = this.modalService.show(
            ForgotModalComponent,
            {
                initialState,
                class: 'modal-new modal-cmd',
                ignoreBackdropClick: true,
                keyboard: false,
                animated: false,
            }
        );
    }
}
