import { Component } from '@angular/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';

@Component({
	templateUrl: './waive-financial-modal.component.html',
})
export class WaiveFinancialModalComponent extends ConfirmModalComponent {
	title = 'Waive Financial Document';
	guidanceText = 'All open requests for the below type and period will be waived:';
	type = '';
	messageCharsAmount = 4000;

	form = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{
				updateOn: 'change',
			},
		),
	});
}
