<div class="remote-resource"  [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-md-3">
                <span class="text-muted small">Search</span>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-md-3">
                <search-list placeholder="Name or NMLS ID"></search-list>
            </div>
        </div>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-6" columnName="" [active]="false">Name</sortable-header>
            <sortable-header class="col-sm-a" columnName="" [active]="false">NMLS ID</sortable-header>
            <div class="col-sm-a text-right">Volume</div>
            <div class="col-sm-a text-right">Units</div>
        </div>
        <reset-filters class="row-fluid"></reset-filters>
        <ng-container *ngIf="((list | async)?.length > 0); else noItems">
            <div class="row-fluid"
                *ngFor="let item of list | async"
            >
                <div class="col-md-6">
                    <a [aDisabled]="!contactsAvailable" [uiSref]="(User.isTpo ? 'people' : '^:id') + '.contacts.:contactId.information'" [uiParams]="{ contactId: item.contactId }">
                        {{ item.name }}
                    </a>
                </div>
                <div class="col-md-a">
                    <a [aDisabled]="!item.nmlsId" uiSref="^account.licenses.nmls.:nmlsId" [uiParams]="{ nmlsId: item.nmlsId }">{{item.nmlsId || '&ndash;'}}</a>
                </div>
                <div class="col-md-a text-right" [ngSwitch]="!User.isLender || User.crmEnabled">
                    <ng-container *ngSwitchCase="true">
                        {{(item?.volume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span class="glyphicon glyphicon-lock text-light" tooltip="{{promoText}}" placement="left"></span>
                    </ng-container>
                </div>
                <div class="col-md-a text-right" [ngSwitch]="!User.isLender || User.crmEnabled">
                    <ng-container *ngSwitchCase="true">
                        {{item.units || '&ndash;'}}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span class="glyphicon glyphicon-lock text-light" tooltip="{{promoText}}" placement="left"></span>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #noItems>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    {{filtersApplied ? 'No matches found' : 'No records added'}}
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
