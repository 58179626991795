import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AlertRecord, AlertStatus, AlertSummary } from 'lender/alerts/alert.interface';
import { LenderAlertsService } from 'lender/alerts/alerts.service';


@Component({
    selector: 'alert-details',
	templateUrl: './alert-details.component.html',
})
export class AlertDetailsComponent implements OnInit {
    @Input() userCanEditAlerts: boolean;
	@Input() lenderId: number;
	@Input() alertStatuses: AlertStatus[];
    @Input() alertRecord: AlertRecord;

    private _alertSummary: AlertSummary;
    @Input() set alertSummary(alertSummary: AlertSummary) {
        this._alertSummary = alertSummary;

        if (this._alertSummary) {
            this.selectedStatus = this.alertStatusesById.get(this._alertSummary.alertStatus);
        }
    }
    get alertSummary(): AlertSummary {
        return this._alertSummary;
    }

    @Output() readonly alertStatusUpdated: EventEmitter<AlertStatus> = new EventEmitter();

    readonly alertStatusesById: Map<string, AlertStatus> = new Map();

	resolving: boolean = false;
    alertDetailsResolved: boolean = true;
    editingStatus: boolean = false;
    selectedStatus: AlertStatus;

	constructor(
		private readonly alertsService: LenderAlertsService,
    ) {
    }

    // Override
    ngOnInit(): void {
        this.alertStatuses.forEach((alertStatus: AlertStatus) => this.alertStatusesById.set(alertStatus.id, alertStatus));
    }

    editAlertStatus(): void {
        this.editingStatus = true;
    }

    cancelAlertStatusEdit(): void {
        this.selectedStatus = this.alertStatusesById.get(this._alertSummary.alertStatus);

        this.editingStatus = false;
    }

    async saveAlert(): Promise<void> {
		try {
			this.resolving = true;

			const modifiedAlertSummary: AlertSummary = {
				...this.alertSummary,
			};
			modifiedAlertSummary.alertStatus = this.selectedStatus.id;

			await this.alertsService.updateAlertStatus(this.lenderId, modifiedAlertSummary).toPromise();

            this.alertStatusUpdated.next(this.selectedStatus);
            this.cancelAlertStatusEdit();
		} finally {
            this.resolving = false;
		}
    }

    setAlertDetailsResolved(resolved: boolean): void {
        this.alertDetailsResolved = resolved;
    }
}
