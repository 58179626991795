import { AbstractControl, ValidatorFn } from '@angular/forms';

type UrlValidator = {
	url: {
		value: string | number | null,
		message: string,
	},
} | null;

const defaultMessage: string = 'Please enter valid url.';

export function UrlValidator(message: string = ''): ValidatorFn {
	return (control: AbstractControl): UrlValidator => {
		const isInvalid = control.value && !`${control.value}`.match(/^https?:\/\/\S+$/);

		return isInvalid ? {
			url: {
				value: control.value,
				message: message || defaultMessage,
			},
		} : null;
	};
}
