<div>
	<div class="pseudo-tabel aligned-center mb15">
		<div class="pseudo-row">
			<h2 class="pseudo-cell" *ngIf="!isPreviewMode">
				Company Information
				<a
					*ngIf="User.isLender || User.isComergence"
					class="btn btn-tertiary"
					uiSref=".history"
				>
					<span class="c-icon c-icon-back-in-time"></span> History
				</a>
			</h2>
			<div class="pseudo-cell text-right" *ngIf="details?.registrationDate && !isPreviewMode">
				<span class="text-muted">Registration Date:</span> {{details?.registrationDate}}
				<div *ngIf="details?.nmlsRemovedDate"><span class="text-muted">NMLS Removed Date:</span> {{details?.nmlsRemovedDate | date}}</div>
			</div>
		</div>
	</div>

	<form [formGroup]="detailsForm" (onValidSubmit)="save()">
		<div class="horizontal-flex-group right" [class.mb15]="!isPreviewMode">
			<div *ngIf="!editing && permission && canEditForm">
				<button type="button" class="btn btn-primary" (click)="toggleMode()">
					<span class="glyphicon glyphicon-pencil"></span> Edit
				</button>
			</div>

			<div *ngIf="editing">
				<button type="submit" [disabled]="saving"  class="btn btn-primary" tabindex="6">Save</button>
				<a class="btn btn-default" (click)="cancel()" tabindex="7">Cancel</a>
			</div>
		</div>

		<form-errors [form]="detailsForm"></form-errors>

		<div class="remote-resource" [class.resolved]="!saving">
			<div class="rows-striped">
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Company Name</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.companyName || '&ndash;'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Ticker Symbol</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.tickerSymbol || '&ndash;'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Business Entity</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.businessEntity || '&ndash;'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">State of Incorporation</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.stateOfIncorporation || '&ndash;'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Address</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.companyAddress || '&ndash;'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Primary Federal Regulator</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.primaryFederalRegulator || '&ndash;'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">City</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.city || '&ndash;'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Registration Status</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.registrationStatus || '&ndash;'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">State</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.state || '&ndash;'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Fiscal Year End</label>

							<div class="col-sm-7">
								<span class="control-value">{{(details?.fiscalYearEnd | date : 'MM/dd/yyyy') || '&ndash;'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid">
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Zip</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.zip || '&ndash;'}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row">
							<label class="col-sm-5 control-label text-right">Date Formed</label>

							<div class="col-sm-7">
								<span class="control-value">{{details?.dateFormed ? details?.dateFormed.split(" ")[0] : '&ndash;'}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid" *ngIf="hasAccess && details?.registrationDate">
					<div class="col-md-6">
						<div class="form-group row" [class.has-error]="hasError('phone')">
							<label class="col-sm-5 control-label text-right" [class.required]="editing">Company Phone</label>

							<div class="col-sm-7">
								<editable-component [editable]="editing" [view]="details?.phone | tel">
									<ng-template #editTemplate>
										<input phoneFormatter class="form-control" formControlName="phone" tabindex="1" />
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div *ngIf="permission" class="form-group row" [class.has-error]="hasError('businessTaxId')">
							<label class="col-sm-5 control-label text-right"
								   [class.required]="editing">Business Tax ID</label>
							<div class="col-sm-7">
								<editable-component [editable]="editing" [view]="details?.businessTaxId || '&ndash;'">
									<ng-template #editTemplate>
										<input
                                            class="form-control"
                                            formControlName="businessTaxId"
                                            maxlength="9"
                                            tabindex="3"
                                        />
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>

				</div>
				<div class="row-fluid" *ngIf="hasAccess && details?.registrationDate">
					<div class="col-md-6">
						<div class="form-group row" [class.has-error]="hasError('fax')">
							<label class="col-sm-5 control-label text-right">Company Fax</label>

							<div class="col-sm-7">
								<editable-component [editable]="editing" [view]="details?.fax || '&ndash;' | tel">
									<ng-template #editTemplate>
										<input phoneFormatter class="form-control" formControlName="fax" tabindex="2" />
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row" [class.has-error]="hasError('mersId')">
							<label class="col-sm-5 control-label text-right">MERS ID</label>

							<div class="col-sm-7">
								<editable-component [editable]="editing" [view]="details?.mersId || '&ndash;'">
									<ng-template #editTemplate>
										<input class="form-control" formControlName="mersId" maxlength="50" tabindex="4" />
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
				</div>
				<div class="row-fluid" *ngIf="hasAccess && details?.registrationDate">
					<div class="col-md-6">
						<div class="form-group row" *ngIf="details?.clientSID">
							<label class="col-sm-5 control-label text-right">Client SID</label>
							<div class="col-sm-7 control-value">
								{{details.clientSID || '&ndash;'}}
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group row" [class.has-error]="hasError('fDICNCUACertificationNumber')">
							<label class="col-sm-5 control-label text-right">FDIC / NCUA Certificate</label>

							<div class="col-sm-7">
								<editable-component [editable]="editing" [view]="details?.fDICNCUACertificationNumber || '&ndash;'">
									<ng-template #editTemplate>
										<input class="form-control" formControlName="fDICNCUACertificationNumber" maxlength="50" tabindex="5" />
									</ng-template>
								</editable-component>
							</div>
						</div>
					</div>
				</div>

                <div class="row-fluid">
                    <client-uid [details]="details" [userProfile]="User"></client-uid>
                </div>
			</div>
		</div>
	</form>
	<div class="bottom-toolbar" *ngIf="editing">
		<div class="text-muted">
			<span class="control-label required"></span> &ndash; Required fields
		</div>
	</div>
</div>

<additional-fields
	*ngIf="User.isLender || User.isComergence"
	[entityTypeId]="entityTypeId"
	[entityId]="details.nmlsId"
></additional-fields>
