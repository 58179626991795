<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">Manage Individuals</h4>
</div>
<form [formGroup]="form" novalidate (onValidSubmit)="save()">
    <div class="modal-body remote-resource social-compliance" [class.resolved]="!resolving">
        <form-errors [form]="form"></form-errors>
        <div class="row">
            <div class="col-md-12 text-muted">Select contacts you want to include or exclude from audit random selection</div>
        </div>
        <div class="row">
            <div class="col-md-5 searchable-entity">
                <ng-select
                    [items]="excludedContactsHints$ | async"
                    (change)="onSelect($event)"
                    [clearable]="false"
                    [loading]="typeaheadLoading"
                    [typeahead]="contactInput"
                    formControlName="contacts"
                    placeholder="Search by Name or NMLS ID">

                    <ng-template ng-option-tmp let-item="item">
                        {{item.contactName}} <div *ngIf="item.nmlsId" class="text-light text-smaller">NMLS ID: {{item.nmlsId}}</div>
                    </ng-template>

                    <ng-template ng-typetosearch-tmp>
                        <div class="ng-option disabled">
                            Please enter at least 3 characters to start search
                        </div>
                    </ng-template>

                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        <div class="ng-option disabled" *ngIf="searchTerm?.length > 2">
                            No contacts found for "{{searchTerm}}"
                        </div>
                        <div class="ng-option disabled" *ngIf="searchTerm?.length <= 2">
                            Please enter at least 3 characters to start search
                        </div>
                    </ng-template>

                    <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
                        <div class="ng-option disabled">
                            Searching for "{{searchTerm}}"
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <ng-container *ngIf="selectedContacts.length">
            <br>
            <div class="schedule-selected-contacts clearfix">
                <div class="col-md-6" *ngFor="let contact of selectedContacts;">
                    {{contact.contactName}} <span class="text-light text-smaller pad-left" *ngIf="contact.nmlsId">NMLS ID: {{contact.nmlsId}}</span>

                    <a class="remove-excluded pad-left" (click)="removeExcluded(contact)">&times;</a>
                </div>
            </div>
            <br>
            <div class="form-group no-margin" has-error>
                <label class="control-label required">Action to Apply to Selected Contacts</label>
                <div>
                    <label class="radio-inline">
                        <input type="radio" formControlName="selectionPoolStatus" [value]="types.INCLUDE"> Include in Random Selection
                    </label>
                    <br>
                    <label class="radio-inline">
                        <input type="radio" formControlName="selectionPoolStatus" [value]="types.EXCLUDE"> Exclude from Random Selection
                    </label>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div>
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</form>
