import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { ClientDocument, DocumentStatus } from '../documents.interface';
import { DocumentComponent } from '../document/document.component';

@Component({
	selector: 'client-document-card',
	templateUrl: './client-document-card.component.html',
})
export class ClientDocumentCardComponent<T extends ClientDocument> extends DocumentComponent<T> {
	@Input('className')
	private _className?: string | string[];

	@Input()
	downloadLink?: string;

	@ContentChild('indications', { static: true })
	indicationsTemplate: TemplateRef<any>;

	@ContentChild('actions', { static: true })
	actionsTemplate?: TemplateRef<any>;

	@Output('upload')
	private _upload = new EventEmitter<FileList>();

	get className(): string[] {
		return [
			'document-card client-document', // original className
			...(
				this._className
					? Array.isArray(this._className)
						? this._className
						: [ this._className ]
					: []
			), // propagated className
			'remote-resource',
			...(this.document.$pending ? [] : [ 'resolved' ]),
			...(this.document.$error ? [ 'error' ] : []),
		];
	}

	get statusClassName(): string[] {
		const {
			isActive,
			isExpired,
			documentState: status,
			isFullySigned,
		} = this.document;
		return [
			'status',
			...(
				isActive
					? (
						status === DocumentStatus.REQUESTED
						|| isExpired
							? [ 'status-red' ]
							: status === DocumentStatus.UPLOADED
								? [
									'status-green',
									// DocuSign
									...(isFullySigned === false ? [ 'half-green' ] : []),
								]
								: []
					)
					: []
			),
		];
	}

	upload(fileList: FileList): void {
		this._upload.emit(fileList);
	}

	discardError(): void {
		delete this.document.$error;
	}
}
