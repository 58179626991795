import { Component } from '@angular/core';

interface Tab {
	state: string;
	title: string;
}

@Component({
	templateUrl: './documents.component.html',
})
export class TpoDocumentsComponent {
	tabs: Tab[] = [
		{
			state: '.list',
			title: 'My Partners',
		},
		{
			state: '.library',
			title: 'My Library',
		},
	];
}
