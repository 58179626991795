import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RealmFormGroup } from 'commons/forms';
import { ContactList, ContactListDesignatedUser } from '../../../investor-crm-contact-list.interface';


@Component({
    selector: 'investor-crm-contact-list-general-information',
    templateUrl: './investor-crm-contact-list-general-information.component.html',
})
export class InvestorCrmContactListGeneralInformationComponent {
    @Input() userCanManageContactLists: boolean;
    @Input() listForm: RealmFormGroup;
    @Input() isNew: boolean;
    @Input() isCloning: boolean;
    @Input() editing: boolean;
    @Output() readonly editingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() availableUsersResolved: boolean;
    @Input() availableUsers: ContactListDesignatedUser[];
    @Input() existingContactList: ContactList;

    @Output() deleteListModal: EventEmitter<void> = new EventEmitter();

    get formListNameValue(): string {
        return this.listForm.get('name').value;
    }

    get formDesignatedOptionValue(): boolean {
        return this.listForm.get('visibility').value;
    }

    setVisibility(visibility: boolean): void {
        const selectedDesignatedUsersControl: AbstractControl = this.listForm.get('selectedDesignatedUsers');
        if (visibility) {
            selectedDesignatedUsersControl.reset(this.existingContactList?.designatedOption?.designatedUsers);
            selectedDesignatedUsersControl.enable();
        } else {
            selectedDesignatedUsersControl.disable();
        }
    }

    changeEdit(editing: boolean): void {
        this.editingChange.next(editing);
    }

    showDeleteListModal(): void {
        this.deleteListModal.next();
    }
}

