import { Component, Input, OnInit } from '@angular/core';

import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { CommonProductionService } from 'shared/production/production.service';

@Component({
    templateUrl: './production.component.html',
})
export class CommonProductionComponent implements OnInit {
    nmlsId: number;
    isNqmEnabled: boolean
    @Input() lenderInfo: AccountInformation;

    constructor(
        private readonly productionService: CommonProductionService,
    ) {}

    async ngOnInit(){
       this.nmlsId = this.lenderInfo.nmlsId;
       const { isNqm } = await this.productionService.getNqmStatus(this.nmlsId).toPromise();
       this.isNqmEnabled = isNqm;
    }
}
