<div *ngIf="attachments?.length"
	 class="attachments attachments-fix-holder"
	 [class.resolved]="isOnlyErrors() || isRendered"
>
	<ng-content select="[header]"></ng-content>

	<attachments-preview
		*ngIf="!isOnlyErrors() && loadedAttachments"
		[networkCode]="networkCode"
		[attachments]="loadedAttachments"
		[attachmentNgClass]="this.attachmentClasses"
		[carouselScaling]="carouselScaling"
		(isRendered)="setIsRendered($event)"
		(onAttachmentClick)="onAttachmentClick.emit($event)"
	></attachments-preview>

	<ng-content select="[footer]"></ng-content>
</div>
