import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ExportResourceInterface } from './export-resource.interface';

declare let apiPath: string;

@Injectable()
export class ExportService {
	export: ExportResourceInterface;

	constructor(
		injector: Injector,
		userService: UserService,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');

		const User = userService.profile;

		const basePath = `${apiPath}/tpos/:tpoId/export`;

		this.export = PagedResource(
			basePath,
			{ tpoId: User.organization.id },
			{
				list: {
					method: 'GET',
					isArray: true,
				},
				types: {
					url: `${basePath}/types`,
					method: 'GET',
					isArray: true,
				},
				// download: {
				// 	url: `${basePath}/:exportTaskId/download`,
				// 	method: 'GET',
				// },
			},
		);

		this.export.download = ({ exportTaskId }) => {
			const link = `${apiPath}/tpos/${User.organization.id}/export/${exportTaskId}/download`;
			const windowReference = window.open(link, '_blank');

			return {
				$promise: new Promise<void>((resolve) => {
					const intervalTimer = setInterval(() => {
						if (!windowReference.closed) {
							return;
						}

						clearInterval(intervalTimer);
						resolve();
					}, 1000);
				}),
			};
		};
	}
}
