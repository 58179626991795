import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ChannelCardDetail,
    Channel,
    ChannelsSettingDetail,
    ApplicationExpiration,
} from 'lender/manage-channels/manage-channel-card.interface';
import { UserInformation } from 'comergence/customers/services/customer-services/user-information.interface';
import {
    ChannelAlert,
    ChannelAlertConfig,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.interface';
import { GenericHistoryEntry } from 'commons/components/history/generic-history-entry.interface';

declare let apiPath;

@Injectable()
export class ManageChannelService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    getChannelDetails(channelId: number): Observable<Channel> {
        return this.http.request<Channel>(
            'GET',
            `${apiPath}/organization-channels/${channelId}`,
        )
    }

    getChannelList(lenderId: number, activeOnly: boolean = false): Observable<Channel[]> {
        const params = { activeOnly };
        return this.http.request<Channel[]>(
            'GET',
            `${apiPath}/lenders/${lenderId}/channels`,
            params,
        );
    }

    getOrganizationalChannelDetails(channelId: number): Observable<ChannelCardDetail> {
        return this.http.request<ChannelCardDetail>(
            'GET',
            `${apiPath}/organization-channel-card/${channelId}/settings`,
        );

    }

    updateOrganizationalChannelSettings(channelId: number, channelSettings: ChannelsSettingDetail): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/settings`,
            null,
            channelSettings,
        );
    }

    updateOrganizationalChannelApplicationExpirations(
        channelId: number,
        applicaitonExpirationSettings: ApplicationExpiration
    ): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/settings/application-expiration`,
            null,
            applicaitonExpirationSettings,
        );
    }

    getUsers(organizationId: number) {
        return this.http.request<UserInformation[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/users`,
        );
    }

    getAlerts(channelId: number) {
        return this.http.request<ChannelAlert[]>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/alert-settings-v2`
        );
    }

    getAlertConfig() {
        return this.http.request<ChannelAlertConfig>(
            'GET',
            `${apiPath}/organization-channels/channel-config`,
        );
    }

    updateAlerts(channelId: number, alerts: ChannelAlert[]) {
        return this.http.request<ChannelAlert[]>(
            'PUT',
            `${apiPath}/organization-channels/${channelId}/alert-settings-v2`,
            null,
            alerts
        );
    }

    getChannelHistory(listParam, channelId: number): Observable<PagedData<GenericHistoryEntry[]>> {
        return this.http.pagedRequest<GenericHistoryEntry[]>(
            'GET',
            `${apiPath}/organization-channels/${channelId}/history`,
            listParam
        );
    }
}
