import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class TasksResourceService {
	public tasks: any;
	public findingStatus: any;

	constructor(
		public injector: Injector,
		public User: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const config = {
			url: `${apiPath}/tpos/:tpoId/social-media-compliance`,
			params: {
				tpoId: User.profile?.organization?.id,
			},
		};

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/social45media45compliance45resource/getTpoRemediationBoardUsingGET
		this.tasks = PagedResource(
			`${config.url}/remediations`,
			config.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		// https://r5-dev.ccmdev.com/swagger-ui.html#/social45media45compliance45finding45status45resource
		this.findingStatus = $resource(
			`${config.url}/finding-status`,
			config.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}
}
