import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ApplicationStatus, ApplicationStatusTransitions, APPLICATION_STATUS } from '../applications.service';


@Component({
    selector: 'application-transition-dropdown',
	templateUrl: './application-transition-dropdown.component.html',
})
export class ApplicationTransitionDropdownComponent {
    @Input() applicationStatusId: APPLICATION_STATUS;
    @Input() appStatuses: ApplicationStatusTransitions;
    @Output() appStatusSelected: EventEmitter<ApplicationStatus> = new EventEmitter();

    statusActionName = {
        AP: 'Approve',
        DE: 'Decline',
        CN: 'Cancel',
        DR: 'Document Required',
    }

    setAppStatus(status: ApplicationStatus) {
        this.appStatusSelected.emit(status);
    }
}
