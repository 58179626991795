
const Modes = {
	'new': {
		title: 'New User',
		editMode: true,
	},
	'edit': {
		editMode: true,
	},
	'view': {
		editMode: false,
	},
	'login-history': {
		editMode: false,
	},
	'audit-trail': {
		editMode: false,
	},
};

type ModeType = keyof typeof Modes;

export { Modes, ModeType };
