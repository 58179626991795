import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { ComponentsModule } from 'commons/components/components.module';
import { CustomFieldsModule } from 'shared/fields';

import { RelatedAccountModule } from 'lender/contacts/related-account';

import {
    ActivitiesListComponent,
    ActivityComponent,
    ActivityDetailsComponent,
    RelatedContactComponent,
    RelatedProspectComponent,
    ActivityHistoryComponent,
    ActivityHistoryService,
    ActivityResourceHistoryService,
} from '.';

import { ActivitiesResourceService } from './activities-resource.service';
import { ActivitiesService } from './activities.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        CustomFieldsModule,
        RelatedAccountModule,
    ],
    declarations: [
        ActivitiesListComponent,
        ActivityComponent,
        ActivityDetailsComponent,
        ActivityHistoryComponent,
        RelatedContactComponent,
        RelatedProspectComponent,
    ],
    providers: [
        ActivitiesResourceService,
        ActivitiesService,
        ActivityResourceHistoryService,
        ActivityHistoryService,
    ],
    exports: [
        ActivitiesListComponent,
    ],
})

export class ActivitiesModule {
}
