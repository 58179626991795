<div class="review-header-wrapper flex-row gap-m">
    <h2>Insurance & Bonds</h2>
    <section-review  [section]="sectionType" class="flex-grow"></section-review>
</div>
<div class="remote-resource" [class.resolved]="resolved">
    <insurance-bonds-section
        *ngFor="let type of sections"
        [accountInformation]="lenderInfo"
        [type]="type"
        [list]="sectionLists[type]"
        (afterSave)="sectionReview.updateReview()"
    ></insurance-bonds-section>
</div>
