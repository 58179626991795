import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    AssignedUser,
    DueDiligenceReview,
    Status,
    StatusTransition,
    StatusTransitionInformation,
} from 'comergence/due-diligence/due-diligence-list.interface';
import { DueDiligenceListService } from 'comergence/due-diligence/due-diligence-list.service';
import { statusConfig } from '../statusConfig';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';


@Component({
    templateUrl: './due-diligence-item.component.html',
    selector: 'due-diligence-item',
})
export class DueDiligenceItemComponent implements OnInit {
    @Input() item: DueDiligenceReview;
    @Input() statusTransitions: StatusTransition[];
    @Input() assignedUsers: AssignedUser[];
    @Output() refreshPage: EventEmitter<void> = new EventEmitter<void>();
    statusConfig = statusConfig;

    editingStatus: boolean = false;
    editingAssignee: boolean = false;
    resolving: boolean = false;
    editable: boolean = false;

    statuses: Status[];

    assignedForm = new RealmFormGroup({
        assignedFullName: new RealmFormControl(
            'assignedFullName',
            {
                label: 'Assigned',
            },
        ),
    });

    statusForm = new RealmFormGroup({
        status: new RealmFormControl(
            'status',
            {
                label: 'Status',
            },
        ),
    });

    constructor(
        public dueDiligenceListService: DueDiligenceListService,
    ) {

    }

    ngOnInit() {
        this.assignedForm.patchValue({ assignedFullName: this.item.assignedId });
        this.statusForm.patchValue({ status: this.item.statusId });

        this.populateListOfStatuses()
    }

    populateListOfStatuses() {
        const reviewStatusTransition: StatusTransitionInformation = this.statusTransitions[this.item.statusId];
        const currentStatus: Status = { id: this.item.statusId, name: this.item.statusName };
        if(reviewStatusTransition.availability.length) {
            this.editable = true;
        }
        this.statuses = reviewStatusTransition.availability.concat(currentStatus);
    }

    toggleEditingStatus() {
        this.editingStatus = !this.editingStatus;
        if(!this.editingStatus) {
            this.statusForm.patchValue({ status: this.item.statusId });
        }
    }

    toggleEditingAssignee() {
        this.editingAssignee = !this.editingAssignee;
        if(!this.editingAssignee) {
            this.assignedForm.patchValue({ assignedFullName: this.item.assignedId });
        }
    }

    async saveStatus() {
        this.resolving = true;
        try {
            await this.dueDiligenceListService.updateStatus(this.item.id, {id: this.statusForm.get('status').value}).toPromise();
            this.emitRefreshEvent();
        } finally {
            this.resolving = false;
        }
    }

    async saveAssignee() {
        this.resolving = true;
        try {
            await this.dueDiligenceListService.updateAssignedUser(this.item.id, {id: this.assignedForm.get('assignedFullName').value}).toPromise();
            this.emitRefreshEvent();
        } finally {
            this.resolving = false;
        }
    }

    emitRefreshEvent() {
        this.refreshPage.emit();
    }

}
