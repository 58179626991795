import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';
import { RealmHttpClient } from 'commons/services/http';


declare let apiPath: string;

@Injectable()
export class InvitesResourceService {
	public list: any;
	public filters: any;
	public invite: any;

	constructor(
		public injector: Injector,
		public User: UserService,
		private readonly http: RealmHttpClient,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const basePath = {
			url: `${apiPath}/lenders/:organizationId`,
			params: {
				organizationId: User.profile.organization.id,
			},
		};

		const additionalParams = {
			includeHistorical: true,
			useVisibilityRules: false,
			includeInvitees: true,
		};

		this.list = PagedResource(
			`${basePath.url}/invite-board`,
			{
				...basePath.params,
				...additionalParams,
			},
			{
				get: {
					method: 'get',
					isArray: true,
				},
			},
		);

		this.filters = {
			channels: $resource(
				`${basePath.url}/channels`,
				{
					...basePath.params,
				},
				{
					get: {
						method: 'get',
						isArray: true,
					},
				},
			),
			states: $resource(
				`${apiPath}/code-value/:code`,
				{
					code: 'state',
				},
				{
					get: {
						cacheConfig: {
							url: `${apiPath}/code-value/state`,
						},
						method: 'get',
						isArray: true,
					},
				},
			),
			accountExecutives: $resource(
				`${basePath.url}/account-executives`,
				{
					...basePath.params,
					...additionalParams,
				},
				{
					get: {
						method: 'get',
						isArray: true,
					},
				},
			),
		};

		this.invite = $resource(
			`${basePath.url}/channels/:channelId/tpos/:tpoId/invites`,
			{
				...basePath.params,
			},
			{
				cancel: {
					method: 'delete',
				},
			},
		);
	}

    cancelInvite(
        investorId: number,
        channelId: number,
		tpoId: number,
    ): Observable<void> {
        return this.http.request(
            'DELETE',
            `${apiPath}/lenders/${investorId}/channels/${channelId}/tpos/${tpoId}/invites`,
            null,
        );
    }
}
