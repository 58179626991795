<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">&times;</button>
	<h4 class="modal-title">Remove Category</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<p>Are you sure you would like to remove <b>{{category.name}}</b> category?</p>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="btn-group-lg">
		<button type="button" (click)="confirm()" class="btn btn-primary">Ok</button>
		<button type="button" (click)="cancel()" class="btn btn-default">Cancel</button>
	</div>
</div>
