import { Injectable } from '@angular/core';
import { Glob, StateService, UIRouterGlobals } from '@uirouter/core';
import { RawParams } from '@uirouter/core/lib/params/interface';
import { RealmStateDeclaration } from 'routes/sharedRoutes';

0;
const ROUTES_IN_LIST = 50; // 50 is the Limit of accessible history entries in most browsers

type HistoryState = {
    name: string;
    params: RawParams;
}

const getParams = (params: RawParams) => Object.fromEntries(
    Object.entries(params)
        .filter(([, value]) => value !== null),
);

@Injectable({ providedIn: 'root' })
export class HistoryLogService {
    protected history: HistoryState[] = history.state?.list || [];

    constructor(
        { success$ }: UIRouterGlobals,
        private readonly stateService: StateService,
    ) {
        success$.subscribe((transition) => {
            if (history.state?.list) {
                this.history = [...history.state.list];
                // console.log('HISTORY LOAD:', this.history.map(({ name }) => name));
                return;
            }

            const { location } = transition.options();
            const originalTransition = transition.originalTransition();
            const name = originalTransition.from().name;
            const params = getParams(originalTransition.params('from'));
            if (name === 'login' || location !== true) {
                //Skip login screen or when we don't write to history
                return;
            }
            const list = [
                ...this.history.slice(-ROUTES_IN_LIST + 1),
                ...(name ? [{ name, params }] : []),
            ];
            history.replaceState({ list }, '');
            this.history = list;
            // console.log('HISTORY WRITE:', this.history.map(({ name }) => name));
        });
    }

    clear = () => {
        this.history = [];
    };

    findBackState = (matchCriteria: string | string[] = [], fullPageView: boolean = true): HistoryState => {
        if (!matchCriteria) {
            console.warn('findBackState should have meaningful criteria');
            return null;
        }

        const matchArray = typeof matchCriteria == 'string' ? [matchCriteria] : matchCriteria;
        const globAray = matchArray.map(match => Glob.fromString(match)).filter(glob => !!glob);
        const foundReverseIndex = [...this.history].reverse().findIndex(state => {
            const { name, isFullPageView = false } = <RealmStateDeclaration>this.stateService.get(state.name) || {};
            if (!name || !fullPageView && isFullPageView)
                return false;

            const matchedGlob = globAray.some(glob => glob.matches(name));
            // console.log(`MATCHING HISTORY ${name} to criteria ["${matchArray.join('", "')}"] : ${matchedGlob}`);
            return !matchedGlob;
        });
        if (foundReverseIndex === -1)
            return null;

        return this.history[this.history.length - 1 - foundReverseIndex];
    };

    goBackTo = ({ name }: HistoryState) => {
        const lastIndex = [...this.history].reverse().findIndex(({ name: fName }) => name == fName);
        // console.log(`${name} history.go(${-lastIndex - 1})`);
        history.go(-lastIndex - 1);
    };
}

export const HistoryLogServiceProvider = HistoryLogService;
