import { Component, Input } from '@angular/core';

import { QuestionnaireRow } from 'tpo/application/application.service';

@Component({
    templateUrl: './questionnaire-row.component.html',
    selector: '[questionnaire-row]',
})
export class AppSummaryQuestionnaireRowComponent {
    @Input() questionnaires:QuestionnaireRow = {} as QuestionnaireRow;
    @Input() channelId: number;
    constructor() {}
}
