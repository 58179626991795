import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';

declare let window;

@Component({
	selector: 'public-task-sign-in',
	templateUrl: './public.sign-in.component.html',
})
export class PublicSignInComponent {
	@Input()
	follow: string = null;


	constructor(
		private stateService: StateService,
	) {
	}

	signIn() {
		window.accessToken = null;
		this.stateService.go('login', { back: this.follow }).finally(() => {
			setTimeout(() => {
				window.location.reload();
			}, 1);
		});
	}
}
