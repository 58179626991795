import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { HintedContact, MonitoringScheduleData, MonitoringScheduleService } from 'tpo/social-compliance/schedule/schedule.service';
import { debounceTime, distinctUntilChanged, filter, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DropdownRequiredValidator } from 'commons/validators';
import { PoolTypes } from 'tpo/social-compliance/schedule/partials/audit-schedule/selection-pool-file-modal';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    templateUrl: './manage-contacts.component.html',
})
export class ScheduleManageContactsComponent implements OnDestroy {
    resolving = false;
    typeaheadLoading = false;
    types = PoolTypes;
    updateCount: (schedule: MonitoringScheduleData) => void = () => {};
    form: RealmFormGroup = new RealmFormGroup({
        contacts: new RealmFormControl(
            'contacts',
            {
                label: 'Contacts',
                updateOn: 'change',
                value: [],
            },
            DropdownRequiredValidator,
        ),
        selectionPoolStatus: new RealmFormControl(
            'selectionPoolStatus',
            {
                label: 'Action to Apply to Selected Contacts',
                updateOn: 'change',
                value: [],
            },
            DropdownRequiredValidator,
        ),
    });

    selectedContacts: HintedContact[] = [];
    contactInput = new EventEmitter<string>();
    excludedContactsHints$: BehaviorSubject<HintedContact[]> = new BehaviorSubject([]);
    private destroyed$ = new Subject<void>();

    constructor(
        public modalRef: BsModalRef,
        private notificationsService: GlobalNotificationsService,
        private scheduleService: MonitoringScheduleService,
    ) {

    }

    ngOnInit() {
        this.contactInput.pipe(
            tap(search => {
                this.resetHint();
                this.typeaheadLoading = search?.length > 2;
            }),
            distinctUntilChanged(),
            debounceTime(400),
            filter(search => search?.length > 2),
            switchMap((search) => {
                return this.scheduleService.findContacts(search, this.selectedContacts.map(({ contactId }) => contactId));
            }),
            shareReplay(1),
            takeUntil(this.destroyed$),
        ).subscribe((contacts) => {
            this.excludedContactsHints$.next(contacts);
            this.typeaheadLoading = false;
        });
    }

    resetHint() {
        this.excludedContactsHints$.next([]);
    }


    removeExcluded(contact: HintedContact) {
        this.selectedContacts.splice(this.selectedContacts.findIndex(item => item == contact), 1);
        this.form.patchValue({ contacts: this.selectedContacts });
    }

    onSelect(contact: HintedContact) {
        this.selectedContacts.push(contact);
        this.form.patchValue({ contacts: this.selectedContacts });
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    async save() {
        this.resolving = true;
        try {
            const schedule = await this.scheduleService.updateSelectionPool(this.form.value).toPromise();
            this.notificationsService.add({
                type: GlobalNotificationType.POSITIVE,
                message: 'Selection Pool List updated',
            });
            this.updateCount(schedule);
            this.modalRef.hide();
        } catch (e) {
            this.form.setServerError(e.data || e);
        }
        this.resolving = false;
    }
}
