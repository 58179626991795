import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';

import { ChannelApplicationHistoryList } from '../../applications/channel-application.interface';
import { ChannelInfo, ChannelsService } from 'lender/clients/$id/channels/channels.service';

@Component({
    templateUrl: './channel-audit-log.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ChannelAuditLogComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ChannelAuditLogComponent) },
    ],
})
export class ChannelAuditLogComponent extends NewPagedListComponent<ChannelApplicationHistoryList> implements OnInit {
    static listName = 'auditLogList' as const;

    @Input() channelInfo: ChannelInfo;
    @Input() canEditStatus = false;

    tpoId: number;
    channelId: number;

    constructor(
        router: UIRouter,
        { params: { id } }: UIRouterGlobals,
        public channelsService: ChannelsService,
    ) {
        super(router);
        this.tpoId = id;
    }

    ngOnInit() {
        const { channelId } = this.channelInfo;
        this.channelId = channelId;

        super.ngOnInit();
    }

    protected loadList(params: ListParams): Observable<PagedData<ChannelApplicationHistoryList[]>> {
        return this.channelsService.getChannelActivityLogs(this.tpoId, this.channelId, params);
    }
}
