<div class="gray-head flex-head">
    <div class="col-fit">
        <h2 class="ng-binding">
            {{ location.name }}
            <span class="text-smaller label label-default va-t" *ngIf="location.headquarter">Headquarter</span>
            <div class="text-medium text-muted ng-binding">{{ location | address }}</div>
        </h2>
    </div>
    <div class="col-min">
        <ul class="tab-menu">
            <li uiSrefActive="active">
                <a uiSref=".originators"><b>Originators</b></a>
            </li>
            <li uiSrefActive="active">
                <a uiSref=".licenses"><b>Licenses</b></a>
            </li>
            <li uiSrefActive="active">
                <a uiSref=".dba"><b>DBA</b></a>
            </li>
            <li uiSrefActive="active" *ngIf="isInvestor">
                <a uiSref=".channels"><b>Channels</b></a>
            </li>
        </ul>
    </div>
</div>
<div ui-view></div>
