import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep, isEqual, reduce, values, forEach } from 'lodash';

@Component({
	templateUrl: './enroll-accounts-modal.component.html',
})
export class EnrollAccountsModalComponent {
	resource: any;
	resourceCopy: any;
	accountTypes: Array<{
		name: string;
		enrolled: boolean;
		indexes: number[];
	}>;
	disableSaveBtn: boolean = true;

	constructor(public modalRef: BsModalRef) {}

	onConfirm() {}

	ngOnInit() {
		this.accountTypes = values(reduce(this.resource, (acc, value, index) => {
			const { ownerType, connected, enrolled } = value;

			if (enrolled || connected) {
				if (!acc[ownerType]) {
					acc[ownerType] = {
						name: ownerType,
						indexes: [],
					};
				}

				acc[ownerType].indexes.push(index);
			}

			return acc;
		}, {}));

		// this.calculateGroupEnroll();

		this.resourceCopy = cloneDeep(this.resource);
	}

	calculateGroupEnroll() {
		forEach(this.accountTypes, (type) => {
			type.enrolled = reduce(type.indexes, (acc, i: any) => ( acc && this.resource[i].enrolled ), true);
		});
	}

	hasChanges(): void {
		// this.calculateGroupEnroll();
		this.disableSaveBtn = isEqual(this.resource, this.resourceCopy);
	}
}
