<div class="widgets-list">
	<div class="flex-head">
		<h2 class="col-fit">Widgets</h2>
		<div class="col-min">
			<a class="btn btn-primary w100" uiSref=".new">New Widget</a>
		</div>
	</div>
	<br>
	<div class="remote-resource" [class.resolved]="widgets.$resolved">
		<div class="filters-rows">
			<div class="row-fluid filter-labels">
				<div class="col-sm-2">
					<div class="text-muted">Entity</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Search</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Account type</div>
				</div>
				<div class="col-sm-2"></div>
				<div class="col-sm-2"></div>
				<div class="col-sm-2"></div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="col-sm-2">
					<ng-select
						[(ngModel)]="params.entityTypes"
						(change)="setFilter({ entityTypes: $event })"
						[items]="filters.entityTypes"
						bindValue="id"
						bindLabel="name"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All Entities">
					</ng-select>
				</div>
				<div class="col-sm-2">
					<search-list placeholder="Name, NMLS"></search-list>
				</div>
				<div class="col-sm-2">
					<ng-select
						[(ngModel)]="params.accountTypes"
						(change)="setFilter({ accountTypes: $event })"
						[items]="filters.accountTypes.$promise | async"
						bindValue="id"
						bindLabel="displayName"
						[multiple]="true"
						[clearable]="true"
						[closeOnSelect]="false"
						placeholder="All Types">
					</ng-select>
				</div>
				<div class="col-sm-2"></div>
				<div class="col-sm-2"></div>
				<div class="col-sm-2"></div>
			</div>
		</div>
		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-4" columnName="name">Name</sortable-header>
				<sortable-header class="col-sm-8" columnName="createdBy">Created By</sortable-header>
			</div>

			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<span class="col-sm-12 text-center va-m">Filters are applied.
					<a (click)="resetFilters()">Reset filters</a> to see all results
				</span>
			</div>

			<a *ngFor="let widget of widgets" class="row-fluid clickable"
			   uiSref=".:widgetId" [uiParams]="{ widgetId: widget.widgetId }"
			>
				<div class="col-sm-4 va-m">
					<span>{{widget.name}}</span>
				</div>
				<div class="col-sm-8 va-m">
					<span>{{widget.createdBy}}</span>
				</div>
			</a>

			<div class="row-fluid" *ngIf="widgets.$resolved && !widgets.length">
				<div class="col-sm-12 text-center text-light text-bigger">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No widgets'}}
				</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="widgets" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</div>
