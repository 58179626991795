import { Injectable } from '@angular/core';

import { NgResourceArray } from 'commons/interfaces';

import { DynamicField } from 'tpo/social-compliance/publisher/library/templates/$templateId/template-text-edit/utils';
import { PostsResourceService } from './posts-resource.service';

@Injectable()
export class PostsService {
	constructor(
		public postsResource: PostsResourceService,
	) {}

	getDynamicFields = (): NgResourceArray<DynamicField> => this.postsResource.dynamicFields.query();
}
