import { Component } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

interface Tab {
	name: string;
	sref: string;
}

@Component({
	templateUrl: './checklist-settings.component.html',
})
export class ChecklistSettingsComponent {
	User: any;
	tabs: Tab[] = [
		{
			name: 'Regulations',
			sref: '.regulations',
		},
		{
			name: 'State Checks',
			sref: '.state-checks',
		},
	];

	constructor(
		userService: UserService,
	) {
		this.User = userService.profile;
	}
}
