import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { RealmHttpClient } from 'commons/services/http';
import { Comment, CreateCommentDTO } from 'lender/common/interfaces/comment.interface';
import { Observable } from 'rxjs';
import {
    AlertChannel,
    AlertEvent,
    AlertHistoryItem,
    AlertId,
    AlertRecord,
    AlertStatus,
    AlertSummary,
} from './alert.interface';


export const ALERT_STATUSES: AlertStatus[] = [
	{
		id: 'NEW',
		name: 'New',
	},
	{
		id: 'UNDER_REVIEW',
		name: 'Under Review',
	},
	{
		id: 'CLOSED',
		name: 'Closed',
	},
];


declare let apiPath: string;

@Injectable()
export class LenderAlertsService {
	lenderAlerts: any;
	resource: any;

	constructor(
		public injector: Injector,
		public User: UserService,
		private readonly http: RealmHttpClient,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const defaultParams = {lenderId: User.profile.organization.id};
		const lenderPath = `${apiPath}/lenders/:lenderId`;
		const alertsPath = `${lenderPath}/alerts`;
		const filtersPath = `${alertsPath}/filters`;

		// r5-dev.ccmdev.com/swagger-ui.html#!/lender45alerts45resource/getAlertsPageUsingGET
		this.lenderAlerts = PagedResource(alertsPath, defaultParams, {
			list: {
				method: 'get',
				isArray: true,
			},
			update: {
				method: 'put',
			},
		});

		this.resource = $resource(lenderPath, defaultParams, {
			channels: {
				url: `${lenderPath}/channels`,
				method: 'GET',
				isArray: true,
			},
			types: {
				url: `${filtersPath}/types`,
				method: 'GET',
				isArray: true,
			},
			subtypes: {
				url: `${filtersPath}/categories/new`,
				method: 'GET',
				isArray: true,
			},
			licenseTypes: {
				url: `${filtersPath}/licenseTypes`,
				method: 'GET',
				isArray: true,
			},
		});
	}

	list = (...args) => this.lenderAlerts.list(...args);
	update = (...args) => this.lenderAlerts.update(...args);
	channels = (...args) => this.resource.channels(...args);
	types = (...args) => this.resource.types(...args);
	subtypes = (...args) => this.resource.subtypes(...args);
	licenseTypes = (...args) => this.resource.licenseTypes(...args);

	getStatuses(): AlertStatus[] {
		return ALERT_STATUSES;
	}

	getAlert(
		lenderId: number,
		alertId: number
	): Observable<AlertSummary> {
		return this.http.request(
			'GET',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}`,
		);
	}

	updateAlertStatus(
		lenderId: number,
		alertSummary: AlertSummary
	): Observable<void> {
		return this.http.request(
			'PUT',
			`${apiPath}/lenders/${lenderId}/alerts/${alertSummary.alertId}/status`,
			{},
			alertSummary
		);
	}

	getEvents(
		lenderId: number,
		alertId: number
	): Observable<AlertEvent[]> {
		return this.http.request(
			'GET',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}/details`,
		);
	}

	getChannels(
		lenderId: number,
		alertId: number
	): Observable<AlertChannel[]> {
		return this.http.request(
			'GET',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}/channels`,
		);
	}

	getIds(
		lenderId: number,
		alertId: number
	): Observable<AlertId[]> {
		return this.http.request(
			'GET',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}/ids`,
		);
	}

	getHistory(
		lenderId: number,
		alertId: number
	): Observable<AlertHistoryItem[]> {
		return this.http.request(
			'GET',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}/history`,
		);
	}

	getComments(
		lenderId: number,
		alertId: number
	): Observable<Comment[]> {
		return this.http.request(
			'GET',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}/comment`,
		);
	}

	createComment(
		lenderId: number,
		alertId: number,
		comment: CreateCommentDTO
	): Observable<Comment> {
		return this.http.request(
			'POST',
			`${apiPath}/lenders/${lenderId}/alerts/${alertId}/comment`,
			{},
			comment
		);
	}

    getAlertRecord(
        lenderId: number,
        alertId: number,
    ): Observable<AlertRecord> {
        return this.http.request(
            'GET',
            `${apiPath}/lenders/${lenderId}/alerts/${alertId}/record`,
        );
    }
}
