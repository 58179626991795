
import { isFunction } from 'lodash';
import { Component, Input, Host } from '@angular/core';

import { ListComponent } from 'commons/components/list/list.component';

// TODO: think about custom q input, so it is possible to have formatted input,
// and no dependency in template on listComponent
@Component({
	selector: 'search-list',
	templateUrl: './search-list.component.html',
})
export class SearchListComponent {
	@Input()
	placeholder: string = 'Search';

    @Input()
    disabled: boolean = false;

	@Input()
	callback: () => void;

	@Input()
	maxlength: number = 75;

	constructor(@Host() public listComponent: ListComponent) {
	}

	// mappings in case of api change for typescript
	setSearch = ($event) => {
		this.listComponent.setSearch($event);

		if (isFunction(this.callback)) {
			this.callback();
		}
	}

	resetSearch = () => {
		this.listComponent.resetSearch();

		if (isFunction(this.callback)) {
			this.callback();
		}
	}
}
