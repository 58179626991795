<div class="alert alert-warning text-center" *ngIf="accounts?.length">
	All social networks support different
	<a class="text-alert" href="/help/authenticated/tpo/publisher/attachments" target="_blank">media formats</a>
</div>

<div *ngFor="let item of accounts | arrayUniqueBy: 'networkCode'">
	<div [id]="item.networkCode" class="alert alert-danger alert-limits"
		 *ngIf="(attachmentsErrors && attachmentsErrors.length) || commonError() ||
		  (this.text && this.text.length > this.networksConfig[item.networkCode].signLimit)">{{getErrors(item)}}</div>

	<post [name]="item?.name"
		  [webLink]="item?.link"
		  [networkCode]="item?.networkCode"
		  [profilePictureUrl]="item?.profilePictureUrl"
		  [text]="getText(item?.networkCode)"
		  [previewDate]="previewDate"
		  [attachments]="attachments"
		  [linkPreview]="getLinkPreview(linkPreview.entries, item.networkCode)"
		  [resolved]="linkPreview.$resolved">
	</post>
</div>
