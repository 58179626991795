<section>
	<h3>Audits</h3>
	<div class="remote-resource" [class.resolved]="!resolving">
		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header class="col-sm-1" columnName="id">ID</sortable-header>
				<sortable-header class="col-sm-3" columnName="assigned">Assigned</sortable-header>
				<sortable-header class="col-sm-a" columnName="reviewStatus">Review Status</sortable-header>
				<sortable-header class="col-sm-a" columnName="reviewStatusDate">Status Date</sortable-header>
				<sortable-header class="col-sm-a" columnName="auditStatus">Audit Status</sortable-header>
				<sortable-header class="col-sm-a" columnName="auditStatusDate">Audit Date</sortable-header>
			</div>
			<a
				*ngFor="let item of list | async"
				class="row-fluid clickable"
				uiSref="social-compliance.orders.:id.reviews.:reviewId.information"
				[uiParams]="{ id: item.orderId, reviewId: item.id }"
			>
				<div class="col-sm-1 va-m">
					{{item.id}}
				</div>
				<div class="col-sm-3 va-m">{{item.assigned}}</div>
				<div class="col-sm-a va-m text-bigger">
					<ng-container *ngIf="reviewStatuses[item.reviewStatus] as reviewStatus">
						<span class="label" [ngClass]="'label-' + reviewStatus.color">{{reviewStatus.title}}</span>
					</ng-container>
				</div>
				<div class="col-sm-a va-m">{{item.reviewStatusDate | date:'MM/dd/yyyy'}}</div>
				<div class="col-sm-a va-m">{{item.auditStatus}}</div>
				<div class="col-sm-a va-m">{{item.auditStatusDate | date:'MM/dd/yyyy'}}</div>
			</a>
			<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
				<div class="col-sm-12 text-center text-light text-bigger">No audits</div>
			</div>
			<br>
		</div>
	</div>
    <paginator [sizes]="[ 5, 10, 25 ]"></paginator>
</section>
