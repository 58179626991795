<div class="flex-row">
    <h2 class="flex-grow">Payment</h2>
    <h3 *ngIf="appChannel">{{appChannel.investor.name}} <span class="text-muted">{{appChannel.channel.name}}</span></h3>
</div>
<div class="remote-resource" [class.resolved]="appChannel && !isPaying">
	<div>
		<div class="well text-center text-bigger">Enter Your Payment Information or Application Code</div>
		<div class="app-code-payment" *ngIf="payWith != 'CARD'">
			<h3 class="headline">Application Code</h3>
			<div class="row">
				<form class="col-sm-12" [formGroup]="appCodeForm" novalidate (onValidSubmit)="payWithAppCode()">
					<form-errors [form]="appCodeForm"></form-errors>
					<div class="input-group col-sm-3" [class.has-error]="appCodeForm.get('appCode').showError">
						<input type="text" class="form-control" tabindex="0" formControlName="appCode" required>
						<span class="input-group-btn">
							<button type="submit" class="btn btn-sm btn-primary" tabindex="2" [disabled]="isAppCodePaying">Apply</button>
						</span>
					</div>
				</form>
			</div>
		</div>
		<br/>
		<span class="text-muted" *ngIf="isAppCodePaying">Applying...</span>
		<a (click)="cleanInfo()" *ngIf="!isAppCodePaying && payWith == 'CODE'">Pay with Credit Card</a>
		<a (click)="cleanInfo()" *ngIf="payWith == 'CARD'">Pay with Application Code</a>
		<br/>

		<hr class="or" *ngIf="!payWith && !appChannel?.paid"/>

		<div class="credit-card-payment clearfix" [class.hidden]="payWith == 'CODE'">
			<h3 class="headline">Credit Card Information</h3>

			<form [formGroup]="paymentForm" (onValidRecurlySubmit)="pay()">
				<div class="alert alert-danger" role="alert" *ngIf="false">
					Can not connect to Payment Service. Try in few minutes once again.
				</div>
				<form-errors [form]="paymentForm"></form-errors>

				<div class="rows-striped">
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" [recurlyError]="paymentForm.get('card')" [class.has-error]="paymentForm.get('card').showError">
								<label class="col-sm-3 control-label required">Card</label>

								<div class="col-sm-9">
									<div data-recurly="card"></div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<small>The CVV number is the 3 digit number located on the back of your Visa or Master card.
								For American Express, it is a 4 digit number in the small print located on the front of your card.
							</small>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="paymentForm.get('first_name').showError">
								<label class="col-sm-3 control-label required">First Name</label>

								<div class="col-sm-9">
									<span><input type="text" class="form-control" data-recurly="first_name" formControlName="first_name" tabindex="1" placeholder=""></span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" [class.has-error]="paymentForm.get('last_name').showError">
								<label class="col-sm-3 control-label required">Last Name</label>

								<div class="col-sm-9">
									<span><input type="text" class="form-control" data-recurly="last_name" formControlName="last_name" tabindex="1" placeholder=""></span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<br>
				<div class="required-block-lg pull-left text-muted">
					<span class="control-label required"></span> &ndash; Required fields
				</div>

				<div class="pull-right payment" [class.isPaying]="isPaying">
					<span>Amount Due:</span> <span> {{appChannel?.channel?.applicationPrice || 0 | currency}}</span>
					<button *ngIf="!isPaid" type="submit" class="btn btn-primary btn-lg payBtn" [disabled]="isPaying" tabindex=6>Pay</button>
					<span class="done" *ngIf="isPaid"><span class="glyphicon glyphicon-ok"></span> Done!</span>
				</div>
			</form>
		</div>
	</div>
</div>
