import { find } from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';
import { QuestionAnswersValidator } from 'commons/validators/question-answers.validator';
import { NgResourceArray } from 'commons/interfaces';
import { UserService } from 'angularjs-providers/user.provider';

import {
	QuestionType,
	AnswerOption,
	Question,
	QuestionnaireDocumentAvailable,
	QuestionBankCategory,
} from 'lender/questionnaires/manage/questionnaires.interface';

import { SkipLogic, SkipQuestion } from '../get-skip-logic';
import questionTypes from './question-types';
import { QuestionComponent } from './question.component';
import { getAnswers } from './get-answers';
import { getAnswersForSave } from '../get-answers-for-save';

@Component({
	selector: 'questionnaire-question-edit',
	templateUrl: './question-edit.component.html',
})
export class QuestionEditComponent extends QuestionComponent implements OnInit, OnChanges, OnDestroy {
	questionTypes = questionTypes;
	types = QuestionType;

	@Output('update') _update = new EventEmitter<Question>();
	@Output('discard') _discard = new EventEmitter<void>();

	@Input() form = new RealmFormGroup({
		text: new RealmFormControl(
			'text',
			{ label: 'Question Text' },
			Validators.required,
		),
		questionType: new RealmFormControl(
			'questionType',
			{ label: 'Question Type' },
			Validators.required,
		),
	});
	@Input() isNew = false;
	@Input() isGroup = false;
	@Input() skipLogic: SkipLogic | null = null;
	// Indicates special type of question: Bank question.
	// Result: remove skip logic, documents functionality in edit mode
	@Input() isBankQuestion = false;
	@Input() categories: NgResourceArray<QuestionBankCategory> = [];
	@Input() availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = [];
	@Input() updateError: ServerErrorResponse;

	constructor(
		protected userService: UserService,
	) {
		super();
	}

	getFormControl(name: string): RealmFormControl {
		return this.form.get(name) as RealmFormControl;
	}

	get isAttachedDocumentsColumn(): boolean {
		if (this.isBankQuestion) {
			return false;
		}

		const { questionType } = this.form.value;

		return this.userService.profile.can('MANAGE_GLOBAL_QUESTIONNAIRE')
		&& this.availableDocuments.$resolved
		&& [
			QuestionType.SINGLE_SELECT,
			QuestionType.MULTI_SELECT,
			QuestionType.YES_NO,
		].includes(questionType);
	}

	get answers(): RealmFormArray {
		return this.form.get('answers') as RealmFormArray;
	}

	get isSelectQuestionType(): boolean {
		const { questionType } = this.form.value;
		return [
			QuestionType.SINGLE_SELECT,
			QuestionType.MULTI_SELECT,
		].includes(questionType);
	}

	get isSelectEditable(): boolean {
		const { questionBankQuestionId } = this.question;
		return (
			!questionBankQuestionId
			&& this.isSelectQuestionType
		);
	}

	get hasAnswers(): boolean {
		return !!this.form.get('answers');
	}

	get hasOwnSubmitDiscardButtons(): boolean {
		return !this.isNew || this.isBankQuestion;
	}

	get isSkipLogicColumn(): boolean {
		return !this.isBankQuestion && this.isSkipLogicVisible;
	}

	get isYesNoQuestionType(): boolean {
		const { questionType } = this.form.value;
		return questionType === QuestionType.YES_NO;
	}

	// Notice: skip logic is related to form field, instead of saved questionType(as in parent component)
	get isSkipLogicVisible(): boolean {
		const formQuestionType = this.form.get('questionType')?.value;
		const questionConfig = questionTypes.find(({ id }) => id === formQuestionType);
		return !!questionConfig?.skipLogic;
	}

	private unsubscribe$ = new Subject<void>();

	ngOnChanges(changes: SimpleChanges): void {
		const { updateError } = changes;

		if (updateError?.currentValue) {
			this.form.setServerError(updateError?.currentValue);
		}
	}

	ngOnInit(): void {
		const label = this.isGroup ? 'Group Name' : 'Question Name';
		(this.form.get('text') as RealmFormControl).label = label;

		if (this.isBankQuestion) {
			this.form.addControl('categoryId', new RealmFormControl(
				'categoryId',
				{ label: 'Question Category' },
				Validators.required,
			));
		}

		this.form.get('questionType').valueChanges
			.pipe(
				distinctUntilChanged(),
				takeUntil(this.unsubscribe$),
			)
			.subscribe((questionType: QuestionType) => {
				this.form.removeControl('answers');
				const answerOptions = getAnswers(this.question, questionType);

				if (answerOptions) {
					const validator = this.getAnswersValidator(questionType);
					const control = new RealmFormArray([], validator);
					control.label = 'Answer Options';

					this.form.addControl('answers', control);
					answerOptions.forEach(this.addAnswerOption);
				}
			});

		const { text, questionType, categoryId } = this.question;
		this.form.patchValue({ text, questionType, categoryId });

		if (this.isGroup) {
			this.form.get('questionType').patchValue(QuestionType.GROUP);
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	addAnswerOption = ({ text, explanationRequired, nextQuestionId, _skipLogic, documents }: Partial<AnswerOption> = {}): void => {
		this.answers.push(
			new RealmFormGroup({
				text: new RealmFormControl(
					'text',
					{ value: text },
				),
				explanationRequired: new RealmFormControl(
					'explanationRequired',
					{ value: !!explanationRequired },
				),
				nextQuestionId: new RealmFormControl(
					'nextQuestionId',
					{ value: nextQuestionId },
				),
				_skipLogic: new RealmFormControl(
					'_skipLogic',
					{ value: _skipLogic },
				),
				_hasDocument: new RealmFormControl(
					'_hasDocument',
					{ value: !!documents?.length },
				),
				documents: new RealmFormControl(
					'documents',
					{ value: documents?.map((document) => document.investorClientDocumentId) },
				),
			}),
		);
	};

	removeAnswerOption = (index: number): void => {
		this.answers.removeAt(index);
	};

	save = (): void => {
		const { text, questionType, categoryId, answers } = this.form.value;

		const control = this.form.get('answers') as RealmFormArray;
		if (control) {
			control.controls.forEach(answerForm => {
				answerForm.markAsTouched();
			});

			// trigger validation one more time
			control.patchValue(answers);

			if (!control.valid) {
				return;
			}
		}

		const answersForSave = getAnswersForSave(answers, this.availableDocuments);

		const result = {
			...this.question,
			text,
			questionType,
			answers: answersForSave,
		};

		if (this.isBankQuestion) {
			result.categoryId = categoryId;
		}

		this._update.emit(result);
	};

	discard = (): void => {
		this._discard.emit();
	};

	toggleNextQuestion(answerForm: AbstractControl, index: number): void {
		const skipLogicValue = answerForm.get('_skipLogic').value;

		// is currently enabled => disable
		if (skipLogicValue) {
			answerForm.patchValue({
				_skipLogic: false,
			});
			return;
		}

		const savedQuestion = this.question.answers[index];
		const savedModelNextQuestion = answerForm.get('nextQuestionId').value || savedQuestion?._nextQuestion?.id || this.skipLogic.skipOptions[this.question.id]?.[0]?.id;

		answerForm.patchValue({
			_skipLogic: true,
			nextQuestionId: savedModelNextQuestion,
		});
	}

	getHelp(id: string | number): string {
		const selectedSkipOption = find(this.skipLogic.skipOptions[this.question.id], (option: SkipQuestion) => {
			return parseInt(`${option.id}`, 10) === parseInt(`${id}`, 10);
		});

		if (!selectedSkipOption) {
			return '';
		}

		return this.skipLogic.hints[selectedSkipOption.index] ?? '';
	}

	getAnswersValidator(questionType: QuestionType): ValidatorFn | null {
		const isValidator = [ QuestionType.MULTI_SELECT, QuestionType.SINGLE_SELECT ].includes(questionType);
		const checkCommasInAnswers = questionType === QuestionType.MULTI_SELECT;
		return isValidator ? QuestionAnswersValidator(checkCommasInAnswers) : null;
	}

	toggleDocument(answerForm: AbstractControl, index: number): void {
		const hasDocumentValue = answerForm.get('_hasDocument').value;

		// is currently enabled => disable
		if (hasDocumentValue) {
			answerForm.patchValue({
				_hasDocument: false,
			});
			return;
		}

		const savedQuestion = this.question.answers[index];
		const selectedDocument = answerForm.get('documents').value || savedQuestion?.documents?.map((document) => document.investorClientDocumentId);

		answerForm.patchValue({
			_hasDocument: true,
			documents: selectedDocument,
		});
	}
}
