<ng-container *ngFor="let reply of replies | slice:0:limit;">
	<div class="comment">
		<div class="row flex-row">
			<div class="col-md-10">
				<b>{{reply.from || '&ndash;'}}</b>&nbsp;
				<span class="text-muted">{{reply.date | date : "MMMM d, yyyy 'at' HH:mm a"}}</span>
			</div>
			<div class="col-md-2 text-right">
				<div class="inline-menu" *ngIf="permission && reply.repliedByListing && !replyComponent.isEditing" dropdown>
					<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
						<span class="c-icon c-icon-dot-3"></span>
					</a>
					<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
						<li><a class="dropdown-item" (click)="replyComponent.openEditForm(reply.text, reply.id)">Edit</a></li>
					</ul>
				</div>
			</div>
		</div>
		<show-more *ngIf="!replyComponent.isEditing" [maxHeight]="65" [text]="reply?.text"></show-more>

		<review-reply #replyComponent
				replyBtnText="Reply"
				[reviewId]="reviewId"
				[replyId]="reply.id"
				[additionalParams]="{ parentId: reply.parentId || reply.id}"
				[permission]="permission"
				[openedFormsList]="openedReplyForms"
				[charsAmount]="8000"
				(onAdd)="onAdd.emit()">

			<ng-template #replyViewTemplate
						 let-openForm="methods.openForm"
						 let-permission="permission"
						 let-replyBtnText="replyBtnText">
				<button *ngIf="permission" class="btn btn-tertiary text-muted" (click)="openForm()">
					<span class="c-icon c-icon-message"></span>&nbsp;{{replyBtnText}}
				</button>
			</ng-template>

			<ng-template #replyFormTemplate
						 let-addReplyForm="addReplyForm"
						 let-replyBtnText="replyBtnText"
						 let-submitted="submitted"
						 let-isInvalid="isInvalid"
						 let-charsAmount="charsAmount"
						 let-isEditing="isEditing"
						 let-add="methods.add"
						 let-cancelEdit="methods.cancelEdit"
						 let-getCharsCount="methods.getCharsCount">
				<form *ngIf="permission" [formGroup]="addReplyForm" id="addReplyForm" (onValidSubmit)="add()"
					  [class.editing]="isEditing" autocomplete="off" role="presentation">
					<form-errors [form]="addReplyForm"></form-errors>

					<div class="mb15 remote-resource" [class.resolved]="!submitted">
						<div class="full-width mb10">
							<label *ngIf="!isEditing" for="reply-area">{{companyName}}</label>
							<textarea id="reply-area"
									class="form-control resize-vertical"
									formControlName="text"
									[rows]="1"
									[maxlength]="charsAmount"
									placeholder="Type here">
							</textarea>
						</div>

						<div class="characters mb10 text-muted text-smaller">Characters: {{getCharsCount()}}</div>
						<div>
							<span class="btn btn-default" (click)="cancelEdit()">Cancel</span>

							<button [disabled]="isInvalid" type="submit" class="btn btn-primary">
								{{isEditing ? 'Save' : replyBtnText}}
							</button>
						</div>
					</div>
				</form>
			</ng-template>

		</review-reply>
	</div>

	<review-comments *ngIf="reply?.replies?.length"
					 [replies]="reply.replies"
					 [reviewId]="reviewId"
					 [permission]="permission"
					 [companyName]="companyName"
					 (onAdd)="onAdd.emit()">
	</review-comments>
</ng-container>
<a class="toggle" *ngIf="replies.length > max && !isInnerReplies" (click)="toggleComments()">
	<br>
	{{ replies.length === limit ?
		'Collapse comments' :
		'View ' + (replies.length - max) + ' more comment' + (replies.length - max === 1 ? '' : 's') }}
</a>
