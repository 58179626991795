import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

@Component({
	templateUrl: './assignee-modal.component.html',
})
export class AssigneeModalComponent implements OnInit {
	form: RealmFormGroup;
	review: any;
	assignees: any;
	update: (assignee: any) => Promise<any>;
	resolved = true;

	get assigneeControl(): RealmFormControl {
		return this.form.get('assignee') as RealmFormControl;
	}

	get assigneeControlPlaceholder(): string {
		return `Select ${this.assigneeControl.label}`;
	}

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.form = new RealmFormGroup({
			assignee: new RealmFormControl(
				'assignee',
				{
					label: 'Assignee',
					value: this.review.assigned,
					updateOn: 'change',
				},
				DropdownRequiredValidator,
			),
		});
	}

	submit(): void {
		const { assignee } = this.form.value;

		this.resolved = false;
		this.update(assignee)
			.then(
				() => {
					this.close();
				},
				({ data }) => {
					this.form.setServerError(data);
				}
			)
			.finally(() => {
				this.resolved = true;
			})
	}

	close(): void {
		this.modalRef.hide();
	}
}
