import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

import { SharedReportingSwitcherService } from '../reporting-switcher.service';

interface ReportingStatusInterface {
	enabled: boolean;
	$resolved: boolean;
}

@Component({
	templateUrl: './reporting-switcher-section.component.html',
	selector: 'reporting-switcher-section',
})
export class ReportingSwitcherSectionComponent implements OnInit, OnChanges {
	editing: boolean = false;
	saving: boolean = false;
	enabled?: boolean;
	settings: ReportingStatusInterface;

	@Input()
	canEdit: boolean;

	@Input()
	isInvestor: boolean;

	@Input()
	id?: number;

	@Input()
	statusCode?: any;

	reportSettingsForm = new RealmFormGroup({
		enabled: new RealmFormControl(
			'enabled',
			{ label: 'Service' },
			Validators.compose([ Validators.required ]),
		),
	});

	constructor(
		public reportingService: SharedReportingSwitcherService,
		public stateService: StateService,
	) {}

	ngOnInit() {
		this.getService().get(this.getParams()).$promise
			.then((data: ReportingStatusInterface) => {
				this.settings = data;
				const field = this.reportSettingsForm.get('enabled');
				field.setValue(data.enabled);
			});
	}

	ngOnChanges({ statusCode }: SimpleChanges) {
		if (statusCode && !statusCode.firstChange && statusCode.currentValue === 'INACTIVE' &&
			statusCode.previousValue === 'ACTIVE') {
			this.getService().get(this.getParams()).$promise
				.then((data: ReportingStatusInterface) => {
					this.settings = data;
					const field = this.reportSettingsForm.get('enabled');
					field.setValue(data.enabled);
				});
		}
	}

	toggleMode() {
		this.editing = !this.editing;

		const field = this.reportSettingsForm.get('enabled');
		field.setValue(this.settings.enabled);
	}

	cancel() {
		this.reportSettingsForm.reset();
		this.reportSettingsForm.serverError = '';

		this.toggleMode();

		this.reportSettingsForm.markAsPristine();
	}

	save() {
		if (this.saving) {
			return;
		}

		this.saving = true;
		const enabled = this.reportSettingsForm.value.enabled;

		return this.getService().update(this.getParams(), {
			enabled,
		}, () => {
			this.settings.enabled = enabled;
			this.saving = false;
			this.toggleMode();
		}, ({ data: response }) => {
			this.reportSettingsForm.setServerError(response);
			this.saving = false;
		});
	}

	getLabel() {
		if (!this.settings || !this.settings.$resolved) {
			return '';
		}

		return this.settings.enabled ? 'On' : 'Off';
	}

	getService() {
		return this.isInvestor ? this.reportingService.investorReportingStatus : this.reportingService.lenderReportingStatus;
	}

	getParams() {
		return {
			organizationId: this.stateService.params.organizationId || this.id,
		};
	}
}
