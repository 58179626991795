<div class="internal-document">
	<div class="gray-head">
		<back-bread>{{title}}</back-bread>
	</div>

	<form [formGroup]="documentDetailsForm" class="remote-resource" [class.resolved]="document.$resolved" (onValidSubmit)="submitDocumentDetailsForm()">
		<form-errors [form]="documentDetailsForm"></form-errors>

		<div class="rows-striped">
			<div class="row">
				<label for="name" class="col-sm-2 control-label required">
					{{documentDetailsForm.getFieldLabel('name')}}
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" id="name" formControlName="name" maxlength="200">
				</div>
			</div>
			<div class="row">
				<label for="description" class="col-sm-2 control-label required">
					{{documentDetailsForm.getFieldLabel('description')}}
				</label>
				<div class="col-sm-10">
					<textarea
						class="form-control resize-vertical"
						id="description"
						formControlName="description"
						rows="3"
						maxlength="1000"
					></textarea>
				</div>
			</div>
		</div>

		<ng-container *ngIf="isNew">
			<div class="document-template" [class.error]="document.$error" (fileDrop)=setDocument($event)>
				<div class="front-face">
					<ng-container *ngIf="document.fileName; else noFile">
						<div class="flex-row gap">
							<span class="title flex">
								<span class="c-icon c-icon-file text-muted"></span>
								{{document.fileName}}
							</span>
							<button type="button" class="btn btn-tertiary btn-icon" (click)="removeDocument()">
								<span class="glyphicon glyphicon-trash"></span>
							</button>
							<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Replace' }"></ng-container>
						</div>
					</ng-container>
					<ng-template #noFile>
						<div class="flex-row center gap">
							<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Upload File' }"></ng-container>
							<span class="text-muted">or Drag and Drop here</span>
						</div>
					</ng-template>
					<ng-template #fileInput let-buttonText="buttonText">
						<label class="file-input">
							<span class="btn btn-primary">{{buttonText}}</span>
							<input type="file" (input)="setDocument($event.target.files)" [multiple]="false" />
						</label>
					</ng-template>
				</div>

				<div class="back-face flex-row center gap">
					<span>{{document.$error}}</span>
					<button type="button" class="btn btn-primary" (click)="discardError()">OK</button>
				</div>
			</div>
		</ng-container>

		<br>

		<div class="row">
			<div class="col-sm-12">
				<div class="pull-right">
					<button type="submit" class="btn btn-primary">Save</button>
					<a class="btn btn-secondary" uiSref="^">Cancel</a>
				</div>
			</div>
		</div>
	</form>
</div>
