import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { INSURANCE_BONDS_TYPE, INSURANCE_BONDS_NAMES } from 'shared/account/information/insurance-bonds/insurance-bonds-types';
import { InsuranceBond } from 'shared/account/information/insurance-bonds/insurance-bonds.service';

@Component({
    selector: 'insurance-bonds-section',
    templateUrl: './insurance-bonds-section.component.html',
})
export class InsuranceBondsSectionComponent {
    @Input() public accountInformation: AccountInformation;
    @Input() public type: INSURANCE_BONDS_TYPE;
    @Input() public list: InsuranceBond[];
    @Output() public afterSave: EventEmitter<void> = new EventEmitter<void>();

    INSURANCE_BONDS_NAMES = INSURANCE_BONDS_NAMES;

    constructor(
        private readonly userService: UserService,
    ) {
    }

    get userCanAddEdit(): boolean {
        return this.userService.profile.can('TPO_APPLICATIONS');
    }

    addItem() {
        this.list.push({
            id: null,
            insuranceBondType: this.type,
            amount: null,
            phone: null,
            carrierName: null,
            expiration: null,
        });
    }

    cancelNewItem(item: InsuranceBond): void {
        if (item.id) {
            return;
        }

        const itemIndex: number = this.list.indexOf(item);
        if (itemIndex != -1) {
            this.list.splice(itemIndex, 1);
        }
    }
}
