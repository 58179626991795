import { Component, Input } from '@angular/core';

import {
	IconNames,
	IconSizes,
	IconSizeObject,
} from './icons.interface';

const sizes: Record<IconSizes, number> = {
	smaller: 12,
	medium: 14,
	bigger: 16,
	big: 18,
	huge: 32,
};

// TODO: think about implement registered icons,
// https://material.angular.io/components/icon/overview
@Component({
	selector: 'icon-svg',
	templateUrl: './icons.component.html',
})
export class IconsComponent {

	@Input() name: IconNames;
	@Input() width?: number = 14;
	@Input() height?: number = 14;
	@Input() size?: number | IconSizes;
	@Input() class: string = '';

	get viewSize(): IconSizeObject {
		if (!this.size) {
			return {
				width: this.width,
				height: this.height,
			};
		}

		// get size from IconSizes or use number size
		const size = sizes[this.size] || this.size;

		return {
			width: size,
			height: size,
		};
	}
}
