<div class="gap-l" *ngIf="section">
    <div class="col-sm-5">
        <a [uiSref]="sectionUrl" [uiParams]="{id: clientId}">
            <span>{{sectionLabel}}</span>
        </a>
    </div>
    <div class="col-sm-5 text-medium">
        <span class="control-value" [ngSwitch]="section.expired">
            <span *ngSwitchCase="null" class="label label-default-wired">Not Applicable</span>
            <span *ngSwitchCase="true" class="label label-danger-wired">Review Required</span>
            <span *ngSwitchDefault class="label label-success-wired">Completed</span>
        </span>
    </div>
    <div class="col-sm-2">{{(section.reviewDate | date: 'MM/dd/yyyy') || '&ndash;'}}</div>
</div>
