import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, ContentChild, TemplateRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ViewTemplateDirective } from 'commons/directives/view-template.directive';
import { ConfirmModalComponent } from 'commons/components/modals';

import { SupportDocumentHistoryComponent } from './history/document-history.component';
import { SupportingDocumentEditModalComponent } from '..';
import { DocumentsServiceInterface } from '../documents.service.interface';

@Component({
	selector: 'supporting-document',
	templateUrl: './supporting-document.component.html',
})
export class SupportingDocumentComponent {
	@Input('documentClass') private _className?: string;
	@Input() document: any;
	@Input('params') idParams: any;
	@Input('service') documentService: DocumentsServiceInterface;
	@Output() change = new EventEmitter<void>();
	@ViewChild('file', { static: false }) file: ElementRef;

	User: any;
	modalRef: BsModalRef;
	fn: { [key: string]: (...args) => any }

	@ContentChild(ViewTemplateDirective, { read: TemplateRef, static: true }) viewTemplate: TemplateRef<any>;
	thisContext: SupportingDocumentComponent;

	constructor(
		public stateService: StateService,
		public userService: UserService,
		public modalService: BsModalService,
	) {
		this.User = userService.profile;
		this.thisContext = this;

		this.fn = {
			link: () => this.getDownloadLink(),
			history: () => this.viewHistory(),
			edit: () => this.editDocument(),
			select: (files) => this.onFileSelect(files),
		};
	}

	get uploadClassName(): string[] {
		return [
			...(this.document.$error ? [ 'error' ] : []),
		];
	}

	get className(): string[] {
		return [
			'small-document-card supporting-document', // original className
			...(this._className ? [ this._className ] : []), // propagated className
			...this.uploadClassName,
		];
	}

	getDownloadParams() {
		const { document: { id: docId } } = this;

		return {
			docId,
			...this.idParams,
		};
	}

	getDownloadLink(): string {
		return this.documentService.getDocumentDownloadLink(this.getDownloadParams());
	}

	canUpload(): boolean {
		const {
			$uploading,
			$error,
		} = this.document;
		const User = this.User;
		return (User.isComergence && User.can('CCM_SMC_VIEW_ALL')) || (User.isTpo && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')) && !$uploading && !$error;
	}

	viewHistory(): void {
		const { document, documentService } = this;
		const initialState = { document, documentService, params: this.idParams };

		this.modalRef = this.modalService.show(SupportDocumentHistoryComponent, { initialState, class: 'modal-md modal-new' });
	}

	editDocument(): void {
		const { document, documentService } = this;
		const initialState = { document, documentService, params: this.idParams };

		this.modalRef = this.modalService.show(SupportingDocumentEditModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	confirmFileUpload(file: File): Promise<void> {
		const { document } = this;

		return new Promise((resolve, reject): void => {
			const initialState = {
				title: 'Upload Document',
				message: '<div class="modal-message">' +
					`<p>You are about to upload <strong>${file.name}</strong> to this document</p>` +
					'<span class="glyphicon glyphicon-arrow-down"></span>' +
					`<div class="document-card"><strong>${document.name}</strong></div>` +
					'</div>',
				confirmText: 'Upload',
				onConfirm: () => {
					this.modalRef.hide();
					resolve();
				},
				onCancel: () => {
					this.modalRef.hide();
					reject();
				},
			};

			this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new confirm-file-upload-modal' });
		});
	}

	onFileSelect(files: FileList): void {
		const { document } = this;

		if (!this.canUpload() || files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		this.confirmFileUpload(file)
			.then(
				() => {
					document.$uploading = true;
					this.documentService.documents.replace(
						this.getDownloadParams(),
						{ file },
					).$promise
						.then(
							(data) => {
								Object.assign(document, data);

								document.$uploaded = true;
								setTimeout(() => {
									delete document.$uploaded;
								}, 2000);
							},
							({ data }) => {
								document.$error = data.message;
							},
						)
						.finally(() => {
							delete document.$uploading;
							this.file.nativeElement.value = null; // reset file selection
						});
				},
				() => {},
			);
	}

	hideError() {
		delete this.document.$error;
	}
}
