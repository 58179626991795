import { Component, forwardRef, OnInit } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { HistoryInformation } from 'comergence/customers/investor-marketing-service/investor-marketing.interface';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { InvestorMarketingService } from 'comergence/customers/services/customer-services/investor-marketing-service.service';
import { PagedData } from 'commons/services/http';
import { Observable } from 'rxjs';
import { ListComponent } from 'commons/components/list';

@Component({
    styles: ['.table-row {padding: 10px; border-bottom: 1px solid #E6E7EC}'],
    selector: 'investor-marketing-service-history',
    templateUrl: 'investor-marketing-service-history.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => InvestorMarketingServiceHistoryComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => InvestorMarketingServiceHistoryComponent) },
    ],
})
export class InvestorMarketingServiceHistoryComponent extends NewPagedListComponent<HistoryInformation> implements OnInit {

    organizationId: number;
    static listName = 'historyComponent';

    constructor(
        router: UIRouter,
        public uiRouter: UIRouterGlobals,
        private investorMarketingService: InvestorMarketingService,

    ){
        super(router);
        this.organizationId = this.uiRouter.params.organizationId;
    }

    protected  loadList(params: ListParams): Observable<PagedData<HistoryInformation[]>> {
        return this.investorMarketingService.getHistoryList(this.organizationId, params);
    }

}
