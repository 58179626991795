<div>
	<div class="gray-head flex-head">
		<back-bread>
			<span>{{title}}</span>
		</back-bread>

		<div class="col-min">
			<ul class="tab-menu col-min">
				<li *ngFor="let tab of tabs" [class.active]="getCurrentTab(tab)">
					<a [uiSref]="tab.url" [uiParams]="getParams(tab)">
						<b>{{tab.title}}</b>
					</a>
				</li>
			</ul>
		</div>
	</div>

	<ui-view #uiView></ui-view>
</div>
