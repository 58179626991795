import { Component } from '@angular/core';
import { PROSPECT_STATE, ProspectDetails, ProspectStatus } from 'lender/prospects/prospects.interface';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { UIRouter } from '@uirouter/core';
import { map, tap } from 'rxjs/operators';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Entity } from 'tpo/documents/documents.interface';
import { Observable } from 'rxjs';
import { find } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'commons/components/modals';
import { CommentsResourceInterface } from 'commons/components/comments';
import { Validators } from '@angular/forms';
import { MaxLengthValidator } from 'commons/validators';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';
import { ProspectsService } from 'lender/prospects/prospects.service';

enum PROSPECT_SECTION {
    NONE = null,
    DETAILS = 0,
    FIELDS= 1,
    ACTIVITIES,
    CONTACTS,
    COMMENTS,
}

@Component({
    templateUrl: './details.component.html',
})
export class ProspectDetailsComponent {
    public prospect: ProspectDetails;
    stateValues: Record<PROSPECT_STATE, string> = {} as Record<PROSPECT_STATE, string>;
    editing: PROSPECT_SECTION = PROSPECT_SECTION.NONE;
    resolved = false;
    PROSPECT_SECTION = PROSPECT_SECTION;
    assigneeList: Observable<Entity[]>;
    statusList: Observable<ProspectStatus[]>;
    form = new RealmFormGroup({
        prospectStatus: new RealmFormControl('prospectStatus', { label: 'Status', updateOn: 'change' }),
        assigneeUserId: new RealmFormControl('assigneeUserId', { label: 'Assigned', updateOn: 'change', value: null }),
    });
    User: UserProfile;
    protected modalRef: BsModalRef;
    commentResource: CommentsResourceInterface;
    commentTypes: Observable<CommentType[]>;
    typeDescriptions: { [code: string]: CommentType };
    commentsForm: RealmFormGroup = new RealmFormGroup({
        comment: new RealmFormControl(
            'comment',
            { label: 'Comment', updateOn: 'change' },
            Validators.compose([ Validators.required, MaxLengthValidator(2000) ]),
        ),
        commentType: new RealmFormControl(
            'commentType',
            { label: 'commentType', value: 'PUBLIC', updateOn: 'change' },
            [Validators.required],
        ),
    });

    constructor(
        private readonly userService: UserService,
        private readonly uiRouter: UIRouter,
        private readonly prospectsService: ProspectsService,
        private readonly modalService: BsModalService,
    ) {
        this.loadProspect();
        this.User = this.userService.profile;
    }

    private async loadProspect(): Promise<void> {
        const { id: prospectId } = this.uiRouter.globals.params;
        this.commentResource = this.prospectsService.getCommentResource(prospectId);
        this.commentTypes = this.prospectsService.getCommentTypes().pipe(
            tap((types) => {
                this.typeDescriptions = types.reduce((result, value) => {
                    result[value.code] = value.description;
                    return result;
                }, {});
            })
        );
        let prospect: ProspectDetails;
        [
            prospect,
            this.stateValues,
        ] = await Promise.all([
            this.prospectsService.get(prospectId).toPromise(),
            this.prospectsService.getStateValues().pipe(
                map(values =>
                    values.reduce((prev, { shortName, name }) => ({ ...prev, [shortName]: name }), this.stateValues),
                ),
            ).toPromise(),
        ]);
        this.assigneeList = this.prospectsService.getAssignee(prospectId);
        this.statusList = this.prospectsService.getStatuses().pipe(
            map(statuses => ([
                ...(this.prospect.prospectStatus == 'New' ? [{ name: 'New', stateId: PROSPECT_STATE.OPEN }] : []), //Add new status to list if it's new
                ...statuses,
            ])),
        );
        this.updateProspect(prospect);
        this.resolved = true;
    }

    updateProspect(prospect: ProspectDetails) {
        this.prospect = prospect;
    }

    get stateClass(): string {
        if (!this.prospect?.prospectState)
            return '';

        switch (this.prospect.prospectState) {
            case PROSPECT_STATE.OPEN:
                return 'label label-success';
            case PROSPECT_STATE.CONVERTED:
                return 'label label-warning';
            default:
                return 'label label-danger';
        }
    }

    edit(section: PROSPECT_SECTION): void {
        this.editing = section;
        if ([PROSPECT_SECTION.DETAILS, PROSPECT_SECTION.NONE].includes(section)) {
            this.form.reset(this.prospect);
        }
    }

    async save(): Promise<void> {
        const formData = this.form.value;
        try {
            await this.confirmSave();
        } catch (e) {
            return ;
        }

        try {
            this.resolved = false;
            this.prospect = await this.prospectsService.save({
                ...this.prospect,
                ...formData,
            }).toPromise();
            this.editing = PROSPECT_SECTION.NONE;
        } catch ({error}) {
            this.form.setServerError(error);
        } finally {
            this.resolved = true;
        }
    }

    async confirmSave(): Promise<void> {
        const { prospectStatus: name } = this.form.value
        const { stateId: state } = find(await this.statusList.toPromise(), { name }) || {};
        return new Promise((resolve, reject) => {
            if (!(this.prospect.pendingApplicationsExists && state === PROSPECT_STATE.CONVERTED)) {
                return resolve();
            }

            const initialState: Partial<ConfirmModalComponent> = {
                title: 'Update Prospect',
                confirmText: 'Yes',
                cancelText: 'No',
                message: `There are application(s) that have not been approved yet, would you still like to update the status?`,
                onConfirm: async () => {
                    resolve();
                    this.modalRef.hide();
                },
            };

            this.modalRef = this.modalService.show(ConfirmModalComponent, {
                initialState,
                class: 'modal-smd modal-new',
            });

            this.modalRef.onHide.subscribe(() => {
                reject();
            });
        });
    }
}
