<ng-template #viewControls>
    <div class="text-right button-holder va-m {{ viewControlClasses }}">
        <a *ngIf="!readOnly && !isEditing" class="btn btn-icon btn-tertiary" (click)="startEditing()">
            <span class="glyphicon glyphicon-pencil"></span>
        </a>
    </div>
</ng-template>

<ng-template #popoverContents>
    <form [formGroup]="form" (onValidSubmit)="triggerSave()" (keyup.escape)="handleEscapeKey()">
        <div class="popover-edit flex-row gap">
            <div has-error class="flex-grow">
                <textarea *ngIf="useTextArea; else textOrNumberInput"
                    name="value"
                    formControlName="value"
                    [maxlength]="maxlength"
                    [cdkTrapFocusAutoCapture]="isEditing"
                    [cdkTrapFocus]="isEditing"
                    rows="4"
                    tabindex="1"
                    class="resize-vertical form-control"
                ></textarea>
                <ng-template #textOrNumberInput>
                    <input
                        [type]="inputType"
                        name="value"
                        formControlName="value"
                        [maxlength]="maxlength"
                        [cdkTrapFocusAutoCapture]="isEditing"
                        [cdkTrapFocus]="isEditing"
                        tabindex="1"
                        class="form-control"
                    />
                </ng-template>
            </div>
            <!-- TODO: Factor in button positioning -->
            <div class="text-right va-m button-holder">
                <button type="submit" class="btn btn-icon btn-tertiary">
                    <span class="glyphicon glyphicon-ok"></span>
                </button>
                <a class="btn btn-icon btn-tertiary" (click)="cancelEditing()">
                    <span class="glyphicon glyphicon-remove"></span>
                </a>
            </div>
        </div>
    </form>
</ng-template>

<div
    #popoverContainer
    [id]="popoverContainerId"
    (keyup.escape)="handleEscapeKey()"
    class="{{ popoverContainerClasses }} "
>
    <ng-container *ngIf="controlsOnLeftSide"><ng-container *ngTemplateOutlet="viewControls"></ng-container></ng-container>

    <div
        #bsPopover="bs-popover"
        [popover]="popoverContents"
        container="#{{ containerId }}"
        [placement]="relativePosition"
        [adaptivePosition]="adaptivePosition"
        [popoverTitle]="title"
        [outsideClick]="clickOutsideCancels"
        [triggers]="''"
        class="{{ popoverContentClasses }} control-value"
    >
        <ng-content *ngIf="displayValue"></ng-content>
    </div>

    <ng-container *ngIf="!controlsOnLeftSide"><ng-container *ngTemplateOutlet="viewControls"></ng-container></ng-container>
</div>
