import { Contact } from 'lender/contacts/lender-contacts.service';

export enum TpoContactType {
    REGISTERED = 'individual',
    NMLS = 'originator',
    CRM = 'lender_individual', //only for Investor
}

export type TpoGenericStatus = {
    id: string;
    name: string;
}

export type TableauReportingGroup = {
    tableauGroupId: number;
    name: string;
}

export type FullTpoContact = Omit<Contact, 'status' | 'userStatus' | 'contactType'> & {
    contactType?: TpoContactType;
    status?: TpoGenericStatus;
    userStatus?: TpoGenericStatus;
    reportingGroups?: TableauReportingGroup[];
}
