import { Component } from '@angular/core';

import { LenderUserComponent } from 'lender/user-management';


@Component({
	templateUrl: './ccm-user.component.html',
})
export class ComergenceUserComponent extends LenderUserComponent {
}
