<scorecard-header></scorecard-header>

<div class="remote-resource" [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-2" columnName="id">Review ID</sortable-header>
            <sortable-header class="col-sm-4" columnName="statusName">Status</sortable-header>
            <sortable-header class="col-sm-2" columnName="statusDate">Status Date</sortable-header>
            <sortable-header class="col-sm-4" columnName="assignedFullName">Assigned</sortable-header>
        </div>
        <div *ngIf="!resolving && ((list | async)?.length > 0); else noReviews">
            <div class="row-fluid table-row" *ngFor="let item of list | async">
                <scorecard-review-item
                    [reviewItem]="item"
                    [assignedUsers]="userAssignees"
                    [statusTransitions]="statusTransitions"
                    (refreshPage)="refreshPage()"
                >
                </scorecard-review-item>
            </div>
        </div>
        <ng-template #noReviews>
            <div class="col-sm-12 text-center text-light text-bigger row-fluid">
                No Reviews
            </div>
        </ng-template>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
