<div class="social-compliance post-history-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<span class="modal-title">Post history</span>
	</div>
	<div class="modal-body">
		<div class="archive">
			<div
				class="rows-white post-list remote-resource"
				[class.resolved]="!resolving"
			>
				<div
					*ngFor="let item of list"
					class="row-fluid post-container"
				>
					<sm-post>
						<ng-container *ngIf="!item.deleted; else postRemovedTpl">
							<sm-post-message [post]="item"></sm-post-message>
							<sm-post-attachments [post]="item"></sm-post-attachments>
						</ng-container>
						<ng-template #postRemovedTpl>
							<p><b>Post removed</b></p>
						</ng-template>

						<span class="text-smaller text-muted">
							{{item.lastModifiedDate | date:'MM/dd/yyyy'}}
						</span>
					</sm-post>
				</div>
			</div>
		</div>
	</div>
</div>
