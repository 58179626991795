import { Component } from '@angular/core';

@Component({
	templateUrl: './clients-list.component.html',
})
export class ClientsListComponent {

	bulkMode = false;

	constructor() {
	}

	toggleBulkMode(): void {
		this.bulkMode = !this.bulkMode;
	}
}
