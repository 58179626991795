import { defaults } from 'lodash';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator, FileRequiredValidator } from 'commons/validators';

import { PoolData, PoolTypes } from './selection-pool-file-modal.interface';

@Component({
	templateUrl: './selection-pool-file-modal.component.html',
})
export class SelectionPoolFileModalComponent {
	types = PoolTypes;
	form = new RealmFormGroup({
		type: new RealmFormControl(
			'type',
			{ label: 'Pool Type' },
            DropdownRequiredValidator,
		),
		file: new RealmFormControl(
			'file',
			{ label: 'File' },
			FileRequiredValidator,
		),
	});
    onConfirm: () => void;
	onSave: (data: PoolData) => void;
	resolving: boolean;
	helpLink = 'https://o.comergencesupport.com/en/support/solutions/articles/8000105567-monitoring-schedule-nmls-selection-pool-update';
	get file() {
		return this.form.get('file') as RealmFormControl;
	}

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			resolving: false,
		});
	}

	onPick(files: FileList) {
		if (!files.length) { return; }
		this.file.setValue(files[0]);
	}
}
