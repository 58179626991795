
<div class="required-documents-partners-holder" [ngClass]="{ 'remote-resource': hasOwnLoader, 'resolved': list.$resolved }">
	<div class="required-documents-partners" *ngIf="list.$resolved && list.length">
		<div class="row-fluid">
			<div class="title-holder coldp-md-8">
				<h3>Required Documents</h3>
			</div>
			<div class="coldp-md-16 links-column">
				<div class="links-holder">
					<div
						class="link"
						*ngFor="let partner of list"
					>
						<a
							[href]="partner.link"
						>
							{{partner.name}}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
