import { isFunction } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Phone should be 10 digits';

type PhoneTemplateStringFn = (value: string | number | null) => string;

export function PhoneValidator(messageTemplate: string | PhoneTemplateStringFn = ''): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
        const isValid = !value || !!`${value}`.replace(/\W/g, '').match(/^\d{10}$/);
        const message = isFunction(messageTemplate) ? messageTemplate(value) : messageTemplate;

        return isValid ? null : {
            phone: {
                value,
                message: message || defaultMessage,
            },
        };
    };
}
