import { Component } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { DocumentComponent } from 'shared/documents';

import { GlobalDocument } from '../../documents.interface';
import { GlobalDocumentsService } from '../../documents.service';

@Component({
	selector: 'lender-global-document',
	templateUrl: './item.component.html',
})
export class GlobalDocumentListItemComponent extends DocumentComponent<GlobalDocument> {
	documentTemplateModalConfig = {
		initialState: {
			title: 'Document Template',
			notification: 'Please check your DocuSign settings.',
			confirmText: 'Close',
		},
		class: 'modal-smd modal-new modal-min-body',
	}

	get documentTemplateLink(): string {
		const {
			id: documentId,
		} = this.document;
		return this.documentsService.getTemplateDownloadLink({ documentId });
	}

	constructor(
		userService: UserService,
		private documentsService: GlobalDocumentsService,
	) {
		super(userService);
	}
}
