<div class="flex-row">
    <h3 class="flex-grow">Related Contacts</h3>
</div>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-sm-2">
                <div class="text-light">Search</div>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-sm-2">
                <search-list placeholder="Name"></search-list>
            </div>
        </div>
    </div>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-md-3"><b>Name</b></span>
            <span class="col-md-a"><b>Designation</b></span>
            <span class="col-md-a"><b>Title</b></span>
            <span class="col-md-a"><b>Location</b></span>
            <span class="col-md-a"><b>Phone</b></span>
            <span class="col-md-a"><b>Email</b></span>
            <span class="col-f-120 button-holder text-right" *ngIf="editable">
                <button class="btn btn-primary" *ngIf="!linking" (click)="linking = true">Link Contact</button>
            </span>
        </div>
        <form [formGroup]="form" class="row-fluid" *ngIf="linking" (onValidSubmit)="addContact()">
            <div class="col-md-3 form-group" has-error>
                <ng-select formControlName="contactId"
                           bindValue="organizationContactId"
                           [loading]="contactHintsLoading"
                           [items]="contactHints"
                           [typeahead]="contactSearch$"
                           placeholder="Search by name">

                    <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <span class="text">{{item.name}}</span>
                        <span class="text-light" *ngIf="item.designation">, {{item.designation}}</span>
                        <span class="text-light small" *ngIf="item.title">, {{item.title}}</span>
                    </ng-template>

                    <ng-template ng-typetosearch-tmp>
                        <div class="ng-option message disabled">
                            Please enter at least 3 characters to start search
                        </div>
                    </ng-template>

                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                        <div class="ng-option message disabled" *ngIf="searchTerm?.length > 2">
                            No matches found
                        </div>
                        <div class="ng-option message disabled" *ngIf="searchTerm?.length <= 2">
                            Please enter at least 3 characters to start search
                        </div>
                    </ng-template>

                    <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
                        <div class="ng-option message disabled">
                            Searching for "{{searchTerm}}"
                        </div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-9 text-right button-holder">
                <button type="submit" class="btn btn-primary">Link</button>
                <button type="button" (click)="cancelLink()" class="btn btn-default">Cancel</button>
            </div>
        </form>
        <reset-filters class="row-fluid"></reset-filters>
        <ng-container *ngIf="(list | async) as rawList">
            <div class="row-fluid" *ngFor="let item of rawList">
                <single-contact-row class="col-md-12" [prospectId]="prospectId" [item]="item" [editable]="editable" (remove)="updateList()"></single-contact-row>
            </div>
            <div class="row-fluid" *ngIf="!rawList.length">
                <div class="col-sm-12 text-center text-light text-bigger control-value">
                    {{filtersApplied ? 'No matches found' : 'No contacts'}}
                </div>
            </div>
        </ng-container>
        <new-paging [(list)]="list" [(params)]="params$" [sizes]="[5,10,25]"></new-paging>
    </div>
</div>
