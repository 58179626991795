import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CcmInvoicingSettingsService, SmPackage } from 'comergence/tpo/billing/social-media/invoicing-settings/invoicing-settings.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'ccm-invoicing-settings',
    templateUrl: './invoicing-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': '"remote-resource"',
    },
})
export class CcmSmInvoicingSettingsComponent {
    @HostBinding('class.resolved') resolved = false;

    private _orgId: number = null;
    public product: SmPackage;
    public editing = false;
    public invoicing = false;
    public form = new RealmFormGroup({
        product: new RealmFormControl(
            'product',
            {
                label: 'Product',
            },
            Validators.required,
        ),
    });
    public packages: Observable<SmPackage[]>;
    public loadingValues = {
        packages: true,
    }

    @Input('organizationId') set organizationId(organizationId: number) {
        this._orgId = organizationId;
        if (organizationId) {
            this.initLoading();
        }
    }

    get organizationId(): number {
        return this._orgId;
    }

    @Output()
    smPackage: EventEmitter<SmPackage> = new EventEmitter();

    constructor(
        protected _cd: ChangeDetectorRef,
        protected invoicingSettingsService: CcmInvoicingSettingsService,
        public userService: UserService,
    ) {
    }

    resetInvoicing(): void {
        const { invoicing = false } = this.product || {};
        this.invoicing = invoicing;
    }

    setInvoicing({ invoicing }: SmPackage): void {
        this.invoicing = invoicing;
    }

    async initLoading(): Promise<void> {
        try {
            this.setProductFromRequest(this.invoicingSettingsService.get(this.organizationId));
        } catch (e) {
        }
    }

    enterEdit(): void {
        const { product } = this;
        this.editing = true;
        this.form.reset({ product });
        if (!this.packages) {
            this.loadingValues.packages = true;
            this.packages = this.invoicingSettingsService.list(this.organizationId).pipe(
                tap(() => {
                    this.loadingValues.packages = false;
                })
            );
        }
    }

    cancelEdit(): void {
        const { product } = this;
        this.editing = false;
        this.form.reset({ product });
        this.resetInvoicing();
    }

    async save(): Promise<void> {
        const { product } = this.form.value;
        try {
            this.setProductFromRequest(this.invoicingSettingsService.update(product, this.organizationId));
            this.cancelEdit();
        } catch ({ error: { message }, message: httpError }) {
            this.form.setServerError({ message: message || httpError });
        }
    }

    private async setProductFromRequest(product$: Observable<SmPackage>): Promise<void> {
        this.resolved = false;
        this.product = await product$.toPromise();
        this.resetInvoicing();
        this.resolved = true;
        this._cd.markForCheck();

        this.smPackage.next(this.product);
    }
}
