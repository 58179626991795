import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { MaxLengthValidator, UrlValidator } from 'commons/validators';

@Component({
	templateUrl: './add-link-modal.component.html',
})
export class AddLinkModalComponent implements OnInit {
	link: string;
	form: RealmFormGroup;
	addLink: (link: string) => Promise<any>;
	resolved = true;

	constructor(
		public modalRef: BsModalRef,
	) {}

	ngOnInit() {
		this.initForm();
	}

	initForm(): void {
		this.form = new RealmFormGroup({
			link: new RealmFormControl(
				'link',
				{
					label: 'Link',
					value: this.link,
				},
				[
					Validators.required,
					MaxLengthValidator(255),
					UrlValidator(),
				],
			),
		});
	}

	submit() {
		const { link } = this.form.value;

		this.resolved = false;
		this.addLink(
			link
		)
			.then(
				() => {
					this.modalRef.hide();
				},
				({ data }) => {
					this.form.setServerError(data);
				},
			)
			.finally(() => {
				this.resolved = true;
			});
	}
}
