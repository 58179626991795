import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';


export interface OrderPaymentAlert {
	mostRecentOrderPaymentFailed: boolean;
}

declare let apiPath: string;

@Injectable()
export class OrdersService {
	_orders: any;
	_statuses: any;

	constructor(
		private readonly http: RealmHttpClient,
		injector: Injector,
		userService: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const User = userService.profile;

		const baseParams = {
			...(User.isTpo && { tpoId: User.organization.id }),
		};
		const baseUrl = `${apiPath}${User.isTpo ? '/tpos/:tpoId' : ''}/social-media-compliance/order/:orderId`;
		const reviewsUrls = `${baseUrl}/review`;

		this._orders = PagedResource(
			baseUrl,
			baseParams,
			{
				get: {
					method: 'GET',
				},
				list: {
					method: 'GET',
					isArray: true,
				},
				reviews: {
					url: reviewsUrls,
					method: 'GET',
					isArray: true,
				},
				getStatus: {
					url: `${baseUrl}/status`,
					method: 'GET',
					isArray: true,
				},
				saveStatus: {
					url: `${baseUrl}/status`,
					method: 'PUT',
				},
			},
		);

		this._statuses = $resource(
			`${baseUrl}/statuses`,
			baseParams,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	get = (...args) => this._orders.get(...args);
	list = (...args) => this._orders.list(...args);
	reviews = (...args) => this._orders.reviews(...args);
	statuses = (...args) => this._statuses.get(...args);
	getStatus  = (...args) => this._orders.getStatus(...args);
	saveStatus  = (...args) => this._orders.saveStatus(...args);

	public getTpoOrderPaymentAlert(tpoId: number): Observable<OrderPaymentAlert> {
		return this.http.request(
			'GET',
			`${apiPath}/tpos/${tpoId}/social-media-compliance/order-payment-alert`
		);
	}
}
