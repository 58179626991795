<h4>HQ Statistics</h4>
<div class="rows-striped">
    <div class="row-fluid total">
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">Unique Records</label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchStatistics.result.hQCount}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6"></div>
    </div>
    <div class="row-fluid">
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">Registered in Comergence</label>
                <div class="col-md-5">
                    <div class="control-value">
                        <span>{{dataMatchStatistics.result.registeredHQCount}}</span>
                        <span *ngIf="dataMatchStatistics.result.hasRegisteredHQInvalidForUpload">&nbsp;
                            <i class="glyphicon glyphicon-warning-sign text-danger"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">Registered in Comergence</label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchStatistics.result.registeredHQPercent}}%</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row-fluid">
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">Pass count</label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchStatistics.result.passCount}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <label class="col-md-7 control-label text-right">Fail count</label>
                <div class="col-md-5">
                    <span class="control-value">{{dataMatchStatistics.result.failCount}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
