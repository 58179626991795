import { NgModule } from '@angular/core';
import { NewWarehousesService } from 'shared/account/information/new-warehouse/warehouses.service';
import { NewWarehousesComponent } from 'shared/account/information/new-warehouse/warehouses.component';
import { NewWarehouseModalComponent } from 'shared/account/information/new-warehouse/new-warehouse-modal/new-warehouse-modal.component';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { TpoAccountModule } from 'tpo/account';
import { CardModule } from 'commons/card/card.module';
import { WarehouseCardComponent } from 'shared/account/information/new-warehouse/card/warehouse-card.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        TpoAccountModule,
        CardModule,
    ],
    declarations: [
        NewWarehousesComponent,
        NewWarehouseModalComponent,
        WarehouseCardComponent,
    ],
    providers: [
        NewWarehousesService,
    ],
})
export class SharedNewWarehousesModule {
}
