import { Component, OnInit } from '@angular/core';
import { ThirdPartyOversightService } from 'comergence/customers/services/customer-services/third-party-oversight.service';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { TpoServiceInformation } from 'comergence/customers/tpo-services/tpo-service.interface';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { CustomerInformation } from 'comergence/customers/services/customer-list/customer-information.service.interface';
import { CustomerService } from 'comergence/customers/services/customer-list/customer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TpoAddEditChannelModalComponent } from 'comergence/customers/tpo-services/tpo-add-edit-channel-modal.component';
import { ConfirmModalComponent } from 'commons/components/modals';
import { Subject } from 'rxjs';
import { CcmServiceStatusService } from '../ccm-service-status.service';

@Component({
    templateUrl: 'tpo-service-status.component.html',
})
export class TpoServiceStatusComponent implements OnInit {
    public organizationID: number;
    public tpoServiceInformation: TpoServiceInformation;
    resolving: boolean;
    private user: UserProfile;
    canManageCustomers: boolean;
    customer: CustomerInformation;
    public customerType: string;
    modalRef: BsModalRef;
    stateIsCCMCustomerTPOService: boolean;
    stateIsCCMCustomerChannels: boolean;
    channelReload$: Subject<void> = new Subject<void> ();

    constructor(
        private routerGlobals: UIRouterGlobals,
        public thirdPartyService: ThirdPartyOversightService,
        private userService: UserService,
        private customerService: CustomerService,
        public modalService: BsModalService,
        public stateService: StateService,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
    ) {
        this.user = this.userService.profile;
        this.canManageCustomers = this.user.can('CCM_MANAGE_CUSTOMERS');
        this.stateIsCCMCustomerTPOService = this.stateService.is('customers.:customerType.:organizationId.services.tpo');
        this.stateIsCCMCustomerChannels = this.stateService.is('customers.:customerType.:organizationId.channels');
    }

    ngOnInit(): void {
        this.customerType = this.routerGlobals.params.customerType;
        this.organizationID = this.routerGlobals.params.organizationId;
        this.loadCustomer();
        this.loadTpoService();
    }

    async loadTpoService(): Promise<void> {
        this.resolving = true;

        try {
            this.tpoServiceInformation = await this.thirdPartyService.getTpoService(this.organizationID).toPromise();
        }
        finally {
            this.resolving = false;
        }
    }

    createChannel() {
        const initialState = {
            organizationId: this.organizationID,
            callbackOnSave: () => {
                this.doSave();
            },
        };
        this.modalService.show(
            TpoAddEditChannelModalComponent,
            {
                initialState,
                class: 'modal-md modal-new',
            }
        );
    }
    private async loadCustomer() {
            try {
                this.resolving = true;
                this.customer = await this.customerService.getCustomer(this.customerType, this.organizationID).toPromise();
            }
            finally {
                this.resolving = false;
            }
    }

    setTpoServiceStatus(status: string) {
        let statusTitle;
        if (status == 'INACTIVE') {
            statusTitle = 'Deactivate';
        }
        else {
            statusTitle = 'Activate';
        }
        const initialState = {
            title: `${statusTitle}  ${this.tpoServiceInformation.INVESTOR.name}`,
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            message: `You are about to ${statusTitle.toLowerCase()} ${this.tpoServiceInformation.INVESTOR.name} Service<br>
                     Are you sure you want to proceed?`,
            onConfirm: async (): Promise<void> => {
                this.modalRef.content.resolving = true;
                try {
                    const statusCode = {statusCode: status};
                    await this.thirdPartyService.updateStatus(this.organizationID, this.tpoServiceInformation.INVESTOR.serviceCode, statusCode).toPromise();
                    this.modalRef.hide();
                    this.loadTpoService();

                    this.ccmServiceStatusService.fetchServiceStatuses();
                }
                catch ({error: {message}, message: httpError}) {
                    this.modalRef.content.errorText = message;
                }
                finally {
                    this.modalRef.content.resolving = false;
                }
            },
        };

        this.modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

    private doSave() {
            this.channelReload$.next();
    }
}
