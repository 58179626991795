import { ChangeDetectionStrategy,  Component, forwardRef, Host, Inject, Optional } from '@angular/core';
import { TpoContactsService } from 'tpo/people/contacts/contacts.service';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { CommonValuesService } from 'shared/services/common-values.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TpoContactGeneralInformationComponent } from 'tpo/people/contacts/details/general-information/general-information.component';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';

@Component({
    templateUrl: '../../../../tpo/people/contacts/details/general-information/general-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcmContactGeneralInformationComponent extends TpoContactGeneralInformationComponent {
    constructor(
        @Optional() @Inject(forwardRef(() => CcmContactDetailsComponent)) detailsComponent: SharedContactDetailsComponent,
        contactsService: TpoContactsService,
        commonValues: CommonValuesService,
        modalService: BsModalService,
    ) {
        super(detailsComponent, null, contactsService, commonValues, modalService);
        this.accountPrefix = 'tpo.:id.'
    }

    // Override
    public getTpoId = () => this.routerGlobals.params.id;

    // Override
    protected getContactListHref(): string {
        return '^.^.contacts';
    }
}
