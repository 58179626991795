<div class="alert-card remote-resource" [class.resolved]="!resolving">
    <div class="pull-right" *ngIf="canEditAlerts">
        <button
            class="btn btn-primary"
            *ngIf="!editing"
            [class.disabled]="editingDisabled"
            (click)="toggleEditing()"
        >
            <span class="glyphicon glyphicon-pencil"></span>
            Edit
        </button>
        <button
            class="btn btn-primary"
            *ngIf="editing"
            [class.disabled]="isSavingDisabled()"
            (click)="save()"
        >
            Save
        </button>
        <button
            class="btn btn-primary-wired"
            *ngIf="editing"
            [class.disabled]="editingDisabled"
            (click)="cancel()"
        >
            Cancel
        </button>
    </div>
    <div *ngFor="let group of channelAlertGrouping">
        <h4 class="alert-header">
            {{group.groupName}}
        </h4>
        <div class="row">
            <div class="col-md-6">
                <div *ngFor="let alert of group.alerts; index as n">
                    <p *ngIf="(n % 2) == 0">
                        <manage-channel-alerts-item
                            [alert]="alert"
                            [editing]="editing"
                            (isFormCurrentlyValid)="setAlertFormValid($event)"
                            (alertChanged)="valueEdited($event)"
                        >
                        </manage-channel-alerts-item>
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngFor="let alert of group.alerts; index as n">
                    <p *ngIf="(n % 2) != 0">
                        <manage-channel-alerts-item
                            [alert]="alert"
                            [editing]="editing"
                            (isFormCurrentlyValid)="setAlertFormValid($event)"
                            (alertChanged)="valueEdited($event)"
                        >
                        </manage-channel-alerts-item>
                    </p>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>
