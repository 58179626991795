import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { ProspectingService } from 'shared/prospecting/prospecting.service';

import { ProspectingStatusComponent } from './prospecting-status';
import { AddProspectModalComponent } from './add-prospect-modal';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	declarations: [
		ProspectingStatusComponent,
		AddProspectModalComponent,
	],
	providers: [
		ProspectingService,
	],
    exports: [
		ProspectingStatusComponent,
		AddProspectModalComponent,
    ],
})
export class SharedProspectingModule {}
