<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">&times;</button>
	<h4 class="modal-title">Confirm to Proceed</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div *ngIf="removeIsForbidden">
		This question is configured with skip logic in Questionnaires:
		<div *ngFor="let q of stats.skipLogic">
			<a
				target="_blank"
				uiSref="questionnaires.:questionnaireId.questions"
				[uiParams]="{ action: 'edit', questionnaireId: q.id }"
			>{{q.name}}</a>
		</div>
		You must first remove skip logic from this question in all questionnaires in order to proceed.
	</div>

	<div *ngIf="canRemoveEverywhere">
		<div class="mb15">
			Would you like to remove this Question only from Bank or all Questionnaires where it is in use:
			<div *ngFor="let q of stats.inUse">
				<a
					target="_blank"
					uiSref="questionnaires.:questionnaireId.questions"
					[uiParams]="{ action: 'edit', questionnaireId: q.id }"
				>{{q.name}}</a>
			</div>
		</div>
		<div class="alert alert-quote framed">"{{questionName}}"</div>
	</div>

	<div *ngIf="canRemoveFromBank">
		<div class="mb15">Are you sure you want to delete this question?</div>
		<div class="alert alert-quote framed">"{{questionName}}"</div>
	</div>

</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="btn-group-lg text-center">
		<button type="button" *ngIf="canRemoveFromBank || canRemoveEverywhere" (click)="confirm('bank')" class="btn btn-primary">Delete from Bank</button>
		<button type="button" *ngIf="canRemoveEverywhere" (click)="confirm('everywhere')" class="btn btn-default">Delete from Bank and Questionnaires</button>
		<button type="button" *ngIf="!removeIsForbidden" (click)="cancel()" class="btn btn-default">Cancel</button>
		<button type="button" *ngIf="removeIsForbidden" (click)="cancel()" class="btn btn-primary">Ok</button>
	</div>
</div>
