import { find, first } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { FilterDefault } from 'commons/components/list/list.interface';

import { ProspectingService } from 'shared/prospecting/prospecting.service';
import { StatusInfo } from 'shared/prospecting/prospecting.interface';

@Component({
	templateUrl: './add-prospect-modal.component.html',
})
export class AddProspectModalComponent implements OnInit {
	statusInfo: StatusInfo
	resolving: boolean;
	onConfirm: (params?) => any;

	channels: Observable<FilterDefault[]>
	assigments: Observable<FilterDefault[]>
	prospectForm = new RealmFormGroup({
		channel: new RealmFormControl(
			'channel',
			{
				label: 'Channel',
				updateOn: 'change',
			},
		),
		assigment: new RealmFormControl(
			'assigment',
			{
				label: 'Assigment',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
	});
	hasAssignPermission: boolean;
	User: UserProfile;

	get channel(): RealmFormControl {
		return this.prospectForm.get('channel') as RealmFormControl;
	}
	get assigment(): RealmFormControl {
		return this.prospectForm.get('assigment') as RealmFormControl;
	}

	constructor(
		public modalRef: BsModalRef,
		public prospectingService: ProspectingService,
		{ profile: User }: UserService,
	) {
		this.User = User;
		this.hasAssignPermission = User.isLender && User.crmEnabled && User.can('ASSIGN_PROSPECT');
	}

	async ngOnInit(): Promise<void> {
		const { companyNmlsId, locationNmlsId } = this.statusInfo;
		this.channels = this.prospectingService.getProspectChannels(companyNmlsId, locationNmlsId).pipe(share());

		const firstChannelId = first(await this.channels.toPromise())?.id;
		this.channel.setValue(firstChannelId);
		await this.setAssigments(firstChannelId);
		if (!this.hasAssignPermission) {
			this.assigment.disable();
		}
    }

	async setAssigments(channelId): Promise<void> {
		const assigments = await (
			this.assigments = this.prospectingService.getProspectAssignees(channelId).pipe(share())
		).toPromise();
		const name = `${this.User.firstName} ${this.User.lastName}`;
		const activeAssigmentId = (
			find(assigments, { name }) || first(assigments)
		)?.id;
		this.assigment.setValue(activeAssigmentId);
	}
}
