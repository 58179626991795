import { NgModule } from '@angular/core';
import { AccountInformationModule } from 'shared/account/company-information';
import { TpoFinancialsModule } from 'shared/account/financials/tpo-financials.module';
import { SharedDbaModule } from 'shared/account/dba/dba.module';
import { FederalRegistrationsModule } from './federal-registrations/federal-registrations.module';
import { SharedLocationsModule } from 'shared/account/locations/locations.module';
import { SharedInsuranceBondsModule } from 'shared/account/information/insurance-bonds/insurance-bonds.module';
import { SharedLicensesModule } from 'shared/account/licenses';
import { SharedTechOpsModule } from 'shared/account/information/tech-ops/tech-ops.module';
import { SharedNewWarehousesModule } from './information/new-warehouse/warehouses.module';

@NgModule({
    imports: [
        AccountInformationModule,
        SharedLicensesModule,
        TpoFinancialsModule,
        SharedDbaModule,
        SharedLocationsModule,
        FederalRegistrationsModule,
        SharedInsuranceBondsModule,
        SharedNewWarehousesModule,
        SharedTechOpsModule,
    ],
})
export class SharedAccountModule {
}
