import { Component, Input } from '@angular/core';

@Component({
	selector: 'reviews',
	templateUrl: './reviews.component.html',
})
export class ReviewsComponent {
	@Input()
	size: number = 5;

	@Input()
	rating: number;
}
