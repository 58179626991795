import { Component, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { first, finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { WaiveFinancialModalComponent } from 'shared/account/financials/waive-financial-modal';

import { FinancialDocumentResourceService } from 'commons/services/financial-documents-resource.service';
import { FinancialDocumentOpenRequestItem, TPOFinancialDocumentType } from 'commons/services/financial-documents.interface';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';

@Component({
	templateUrl: 'tpo-financial-requests-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => FinancialDocumentRequestsHistoryComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => FinancialDocumentRequestsHistoryComponent) },
	],
})
export class FinancialDocumentRequestsHistoryComponent extends NewPagedListComponent<FinancialDocumentOpenRequestItem> {
	static listName = 'financialDocumentRequestsHistory';
	tpoId: number;
	documentType: TPOFinancialDocumentType;
	User: UserProfile;
	lenderId: number;
	canWaive: boolean
	modalRef: BsModalRef;

	constructor(
		router: UIRouter,
		{ profile }: UserService,
		private readonly financialDocumentService: FinancialDocumentResourceService,
		private readonly modalService: BsModalService,
		private readonly notificationService: GlobalNotificationsService,

	) {
		super(router);

		this.User = profile;
		this.canWaive = this.User.isLender && this.User.can('REQUEST_FINANCIALS');
		this.lenderId = this.User.isLender ? profile.organization.id : null;
		this.tpoId = this.User.isTpo ? profile.organization.id : router.globals.params.id;
		this.documentType = router.globals.params.documentType.toUpperCase();
	}

	updateList(params = this.params): void {
		this.forceUpdate$.next(params);
	}

	async waive({ requestId, isAudited, period, year }: FinancialDocumentOpenRequestItem): Promise<void> {
		this.modalRef = this.modalService.show(
			WaiveFinancialModalComponent,
			{
				initialState: {
					onConfirm: ({ comment }) => {
						this.modalRef.content.resolving = true;
						this.financialDocumentService.waiveFinancialDocument(this.documentType, this.lenderId, this.tpoId, requestId, comment)
							.pipe(
								first(),
								finalize(() => {
									this.modalRef.content.resolving = false;
									this.modalRef.hide();
									this.updateList();
								}),
							)
							.subscribe({
								next: () => {
									this.notificationService.add({
										type: GlobalNotificationType.POSITIVE,
										message: `All open financial requests for this type and period were waived successfully`,
										timeout: 10000,
									});
								},
								error: () => {
									this.notificationService.add({
										type: GlobalNotificationType.ERROR,
										message: `Waive unsuccessful, try again. If continues, contact customer support`,
										timeout: 10000,
									});
								},
							});
					},
					type: `${isAudited ? 'Audited' : 'Unaudited'} ${period} ${year}`,
				},
				class: 'modal-smd modal-new',
				backdrop: 'static',
			},
		);
	}

	protected loadList(listParams: ListParams): Observable<PagedData<FinancialDocumentOpenRequestItem[]>> {
			return this.financialDocumentService.getDocumentRequestsHistory({
				...(this.User.isTpo ? {} : { lenderId: this.lenderId }),
				tpoId: this.tpoId,
				financialDocumentType: this.documentType,
				listParams,
			});
	}
}