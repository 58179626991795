import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { PasswordPolicy } from 'shared/models/PasswordPolicy';

declare let apiPath: string;

export type ResetPasswordPolicy = {
    firstName: string;
    pinSet: boolean;
    passwordPolicy: PasswordPolicy;
}

@Injectable()
export class UserSetPasswordService {
    constructor(private readonly http: RealmHttpClient) {
    }

    get(token): Observable<ResetPasswordPolicy> {
        return this.http.request<ResetPasswordPolicy>(
            'GET',
            `${apiPath}/user/reset-password/${token}`,
        )
    }

    save(token, body: unknown) {
        return this.http.request(
            'PUT',
            `${apiPath}/user/reset-password/${token}`,
            {},
            body
        )
    }
}
