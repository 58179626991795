import { omitBy, isNil } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIRouterGlobals, StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResource, NgResourceArray, NgResourceObject } from 'commons/interfaces';

import {
	Channel,
	GlobalDocumentDetails,
	Questionnaire,
	DocuSignTemplate,
	DocuSignStatus,
	DocuSignStatuses,
} from '../../documents.interface';
import { GlobalDocumentsService } from '../../documents.service';
import { InvestorLibraryDocumentsService } from '../../library-documents.service';
import { InvestorChannelsService } from '../../channels.service';
import { ApproveLibraryDocumentReplaceModalComponent } from './approve-library-doc-replace-modal';
import { DocumentDiscontinueModalComponent } from './discontinue-modal';
import { RequestDocumentModalComponent } from './request-document-modal';
import { DocumentDetailsForm } from './form';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

interface Params {
	documentId?: number;
}

enum DocumentStates {
	GLOBAL = 'global',
	LIBRARY = 'library',
	DOCUSIGN = 'docuSign',
}

@Component({
	templateUrl: './details.component.html',
})
export class GlobalDocumentDetailsComponent implements OnInit {
	params: Params = {};
	document: NgResourceObject<GlobalDocumentDetails> = null;
	relatedQuestionnaires: NgResourceArray<Questionnaire>;
	libraryDocs: NgResourceArray<any> = null;
	docuSignStatus: NgResourceObject<DocuSignStatus>;
	docuSignStatusesEnum = DocuSignStatuses;
	docuSignTemplates: NgResourceArray<DocuSignTemplate>;
	docuSignDocument: { name: string, link: string };
	channels: NgResourceArray<any> = null;
	selectedChannels: Record<number, boolean> = {};
	form = DocumentDetailsForm;
	resolving = false;
	editing = false;
	isNew = false;
	documentStates = DocumentStates;
	state: DocumentStates = this.documentStates.GLOBAL;
	canManage = false;

	// this property is used to cache value when user switches between global, library and docuSign types
	dataCache: { global, library, docuSign: DocuSignTemplate } = {
		global: { name: '', description: '', file: null, templateName: null, hasTemplate: false },
		library: { name: '', description: '', file: null, templateName: null, hasTemplate: false, libraryId: null },
		docuSign: { name: '', description: '', templateId: null, valid: null },
	};

	modalRef: BsModalRef;

	constructor(
		private stateService: StateService,
		uiRouterGlobals: UIRouterGlobals,
		private UserService: UserService,
        private notificationService: GlobalNotificationsService,
		private documentsService: GlobalDocumentsService,
		private libraryDocumentsService: InvestorLibraryDocumentsService,
		private investorChannelsService: InvestorChannelsService,
		private modalService: BsModalService,
	) {
		const { documentId } = uiRouterGlobals.params;
		Object.assign(this.params, { documentId });
	}

	async ngOnInit(): Promise<void> {
		this.resetForm();

		const { documentId } = this.params;
		this.canManage = this.UserService.profile.can('MANAGE_GLOBAL_DOCUMENTS');

		if (documentId) {
			//load document
			this.resolving = true;
			this.document = await this.documentsService.getDocument({ documentId }).$promise;
			this.relatedQuestionnaires = await this.documentsService.getDocumentQuestionnaires({ documentId }).$promise;
			this.state = this.getCurrentState();
			this.resolving = false;
			this.loadAdditional();
		} else {
			this.isNew = true;
			this.state = this.documentStates.GLOBAL;
			this.enterEditing();
		}
	}

	getCurrentState(): DocumentStates {
		if (this.document.hasOwnProperty('docusignTemplate')) {
			return this.documentStates.DOCUSIGN;
		}
		if (this.document.hasOwnProperty('libraryId')) {
			return this.documentStates.LIBRARY;
		}
		return this.documentStates.GLOBAL;
	}

	async enterEditing(): Promise<void> {
		this.resolving = true;
		try {
			await this.loadAdditional();
		} catch (e) {}
		this.resolving = false;
		this.setState(this.state);
		if (!this.isNew) {
			this.form.reset({
				...this.document,
				docuSignTemplateId: this.document.docusignTemplate?.templateId,
			});
			const { name, docuSignTemplateId } = this.form.getRawValue()
			this.docuSignDocument = {
				name,
				link: this.documentsService.getDocuSignDocumentDownloadLink(docuSignTemplateId),
			};
			this.selectedChannels = this.document.channelsSettings.reduce((acc: Record<number, boolean>, { id }) => {
				acc[id] = true;
				return acc;
			}, {});
		}
		this.editing = true;
	}

	cancelEditing(): void {
		this.editing = false;
		this.resetForm();
		this.state = this.getCurrentState();
	}

	async loadAdditional(): Promise<NgResource<unknown>[]> {
		if (!this.libraryDocs) {
			const { libraryId: includeLibraryId } = this.document || {};
			this.libraryDocs = this.libraryDocumentsService.getLibraryDocuments({ includeLibraryId });
			this.channels = this.investorChannelsService.getChannels({ activeOnly: true });
		}

		if (!this.docuSignTemplates) {
			await (this.docuSignStatus = this.documentsService.getDocusignStatus()).$promise;
			if (this.docuSignStatus.status === this.docuSignStatusesEnum.CONNECTED) {
				this.docuSignTemplates = this.documentsService.getDocuSignTemplates();
			} else {
				this.docuSignTemplates = [];
				this.docuSignTemplates.$resolved = true;
			}
		}

		return Promise.all([
			this.libraryDocs.$promise,
			this.channels.$promise,
		]);
	}

	setState(state: DocumentStates): void {
		const prevState = this.state;
		this.state = state;
		const { channelsSettings } = this.form.value;
		this.dataCache[prevState] = this.form.value;
		switch (state) {
			case this.documentStates.GLOBAL:
				this.form.get('libraryId').disable();
				this.form.get('docuSignTemplateId').disable();
				this.form.get('name').setValidators(Validators.required);
				this.form.get('description').setValidators(Validators.required);
				this.form.reset({ ...this.dataCache.global, channelsSettings });
				break;
			case this.documentStates.LIBRARY:
				this.form.get('libraryId').enable();
				this.form.get('docuSignTemplateId').disable();
				this.form.get('name').clearValidators();
				this.form.get('description').clearValidators();
				this.form.reset({ ...this.dataCache.library, channelsSettings });
				break;
			case this.documentStates.DOCUSIGN:
				this.form.get('libraryId').disable();
				this.form.get('docuSignTemplateId').enable();
				this.form.get('name').clearValidators();
				this.form.get('description').clearValidators();
				this.form.reset({ ...this.dataCache.docuSign, channelsSettings });
				this.docuSignDocument = null;
				break;
		}
	}

	selectDocuSignTemplate({ name, description, templateId }: Partial<DocuSignTemplate> = {}): void {
		this.form.patchValue({ name, description });
		this.docuSignDocument = { name, link: this.documentsService.getDocuSignDocumentDownloadLink(templateId) };
	}

	selectLibraryDoc(doc): void {
		const { name, description, templateName, hasTemplate } = doc || {};
		this.form.patchValue({ name, description, templateName, hasTemplate });
	}

	toggleChannel({ id }): void {
		this.selectedChannels[id] = !this.selectedChannels[id];
		const channelsSettings = this.channels.filter((channel) => this.selectedChannels[channel.id]);
		this.form.patchValue({ channelsSettings });
	}

	resetForm(): void {
		this.form.markAsPristine();
		this.form.serverError = '';
		this.form.reset();
	}

	submit(): void {
		const { libraryId: oldLibraryId } = this.document || {};
		const { libraryId } = this.form.value;

		if (!this.isNew && this.state === this.documentStates.LIBRARY && libraryId != oldLibraryId) {
			return this.approveLibraryDocChange();
		}

		this.save();
	}

	async save(): Promise<void> {
		let { ...documentDetails } = this.form.value;
		if (this.state === this.documentStates.DOCUSIGN) {
			documentDetails.templateName = documentDetails.name;
			documentDetails.docusignTemplate = {
				templateId: documentDetails.docuSignTemplateId,
			};
			documentDetails = omitBy(documentDetails, isNil);
		}

		this.resolving = true;
		try {
			if (this.isNew) {
				const { id: documentId, name } = await this.documentsService.createDocument(documentDetails).$promise;
				const href = this.stateService.href('documents.:documentId', { documentId });
				const link = `<a class="underlined text-success" href="${href}">View Document</a>`;
                this.notificationService.add({
                    type: GlobalNotificationType.POSITIVE,
                    message: `<b>${name}</b> was created successfully. ${link}`,
                    timeout: 10000,
                });
				this.stateService.go('documents');

			} else {
				const { documentId } = this.params;
				this.document = await this.documentsService.updateDocument({ documentId }, documentDetails).$promise;
				this.cancelEditing();
			}
		} catch (e) {
			if (e.data) {
				this.form.setServerError(e.data);
			}
		}
		this.resolving = false;
	}

	onPick(filesOrEvent): void {
		const templateFile = filesOrEvent.target ? filesOrEvent.target.files[0] : filesOrEvent[0];

		if (!templateFile) {
			return;
		}

		const templateName = templateFile.name.replace(/\.\w+$/, '');
		this.form.patchValue({
			hasTemplate: true,
			templateName,
			templateFile,
		});
	}

	clearTemplate(): void {
		this.form.patchValue({
			hasTemplate: false,
			templateName: null,
			templateFile: null,
		});
	}

	getOwnDocumentLink(): string {
		const { documentId } = this.params;
		return this.documentsService.getTemplateDownloadLink({ documentId });
	}

	getLibraryDocumentLink(): string {
		const { libraryId } = this.form.value;
		return this.libraryDocumentsService.getDocumentTemplateLink({ libraryId });
	}

	approveLibraryDocChange = (): void => {
		const initialState = {
			fromDoc: this.document,
			toDoc: this.form.value,
			onConfirm: () => {
				this.save();
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(ApproveLibraryDocumentReplaceModalComponent, {
			initialState,
			class: 'modal-new modal-smd choose-from-library',
			backdrop: 'static',
		});
	};

	discontinue(): void {
		const { document } = this;
		const initialState = {
			document,
			onConfirm: () => {
                this.notificationService.add({
                    type: GlobalNotificationType.NEGATIVE,
                    message: `<b>${document.name}</b> was discontinued.`,
                });
				this.stateService.go('documents');
			},
		};

		this.modalRef = this.modalService.show(
			DocumentDiscontinueModalComponent,
			{
				initialState,
				class: 'warning',
				backdrop: 'static',
			},
		);
	}

	request(item: Channel): void {
		const initialState = {
			onConfirm: async (payload) => {
				const { id: documentId } = this.document;
				const { id: channelId } = item;
				this.modalRef.content.resolving = true;
				try {
					await this.documentsService.requestDocumentOnChannel(
						{
							documentId,
							channelId,
						},
						payload,
					).$promise;
					item.$requested = true;
					this.modalRef.hide();
				} catch (e) {
					this.modalRef.content.errorText = e.data?.message;
				}
				this.modalRef.content.resolving = false;
			},
			onCancel: () => {
				this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(
			RequestDocumentModalComponent,
			{
				initialState,
				class: 'modal-new modal-smd',
				backdrop: 'static',
			},
		);
	}
}
