import { Component } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { ConfirmModalComponent } from 'commons/components/modals';
import { DocumentComponent } from 'shared/documents';

import { InternalDocumentsService } from '../../../internal-documents.service';

@Component({
	selector: 'internal-document',
	templateUrl: './item.component.html',
})
export class InternalDocumentsListItemComponent extends DocumentComponent<any> {
	get canUpload(): boolean {
		return (
			this.user.can('MANAGE_INTERNAL_DOCUMENTS')
		);
	}

	get downloadLink(): string {
		const { id: tpoId } = this.stateService.params;
		const { internalDocumentId: documentId } = this.document;
		return this.internalDocumentsService.getDownloadLink({
			tpoId,
			documentId,
			type: 'uploadedDocument',
		});
	}

	modalRef: BsModalRef;

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		public stateService: StateService,
		userService: UserService,
		public internalDocumentsService: InternalDocumentsService,
		public modalService: BsModalService,
	) {
		super(userService);
	}

	// TODO: use shared upload logic
	async confirmUpload(file: File): Promise<void> {
		return new Promise((resolve, reject): void => {
			const { name } = this.document;
			const initialState = {
				title: 'Upload Document',
				message: `
					<div class="modal-message">
						<div class="well bigger">You are about to upload <strong>${file.name}</strong> to this document</div>
						<span class="glyphicon glyphicon-arrow-down"></span>
						<div class="document-card">${name}</div>
					</div>
				`,
				onConfirm: resolve,
				onCancel: reject,
			};
			this.modalRef = this.modalService.show(
				ConfirmModalComponent,
				{
					initialState,
					class: 'modal-smd modal-new confirm-file-upload-modal',
					backdrop: 'static',
				},
			);
		});
	}

	async onFileSelect(files: FileList): Promise<void> {
		const { document } = this;

		if (files.length === 0) {
			return;
		}

		if (files.length > 1) {
			document.$error = 'You can upload only one file at once.';
			return;
		}

		const file = files[0];

		if (file.size > 20 * Math.pow(2, 20)) { // 20Mb
			document.$error = 'You can upload only a file less than 20 Mb.';
			return;
		}

		try {
			await this.confirmUpload(file);
			this.modalRef.content.resolving = true;
			try {
				const {
					id: tpoId,
				} = this.stateService.params;
				const {
					internalDocumentId: docId,
				} = document;
				this.document = await this.internalDocumentsService.one.upload(
					{ docId, tpoId },
					{ file },
				).$promise

				this.actionSuccess();
			} catch (error) {
				document.$error = error.data?.message;
			}
		} catch (e) {}
		this.closeModal();
	}

	private actionSuccess(): void {
		this.document.$success = true;
		setTimeout(() => {
			this.document.$success = false;
		}, 5000);
	}

	private closeModal(): void {
		this.modalRef?.hide();
	}
}
