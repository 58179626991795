<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">&times;</button>
	<h4 class="modal-title">Questionnaires</h4>
</div>
<div class="modal-body remote-resource max-h300" [class.resolved]="!resolving">

	<div *ngFor="let q of stats.inUse">
		<a
			target="_blank"
			uiSref="questionnaires.:questionnaireId.questions"
			[uiParams]="{ action: 'edit', questionnaireId: q.id }"
		>{{q.name}}</a>
	</div>

	<div *ngIf="stats.$resolved && (!stats.inUse || !stats.inUse.length)">Question is not assigned to any Questionnaire</div>

</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="btn-group-lg text-center">
		<button type="button" (click)="cancel()" class="btn btn-primary">Ok</button>
	</div>
</div>
