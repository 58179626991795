export const dataURLtoFile = (dataURL: string, filename?: string): File => {
	const arr = dataURL.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		name = filename || 'file';

	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	// create typed blob part for our file
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	try {
		// for normal browsers
		return new File([ u8arr ], name, { type: mime });
	} catch(e) {
		// IE11 does not support new File,
		// so use Blob instead
		// Blob is almost a File, it needs name and lastModifiedDate
		const file = new Blob([ u8arr ], { type: mime }) as any;
		file.name = name;
		file.lastModifiedDate = new Date();
		return file as File;
	}
};
