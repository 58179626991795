import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { InvitesModule } from 'tpo/invites';
import { ApplicationsModule } from 'tpo/applications';
import {
	OwnershipVerificationService,
	OwnershipVerificationModalComponent,
} from './modals/ownership-verification';
import { RequiredDocumentsPartnersListComponent } from './required-documents-partners/required-documents-partners-list.component';
import { ClientAssignedQuestionnairesListComponent } from './client-assigned-questionnaires/client-assigned-questionnaires-list.component';
import { TpoShareRequestsComponent } from './share-requests/tpo-share-requests.component';
import { RequestReviewModalComponent } from './share-requests/request-review-modal/request-review.modal.component';
import { HomeComponent } from './home.component';
import { TpoShareRequestsService } from './share-requests/tpo-share-requests.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule,
		PipesModule,
		DirectivesModule,
		InvitesModule,
		ApplicationsModule,
	],
	providers: [
		OwnershipVerificationService,
        TpoShareRequestsService,
	],
	declarations: [
		OwnershipVerificationModalComponent,
		HomeComponent,
		RequiredDocumentsPartnersListComponent,
		ClientAssignedQuestionnairesListComponent,
        TpoShareRequestsComponent,
        RequestReviewModalComponent,
	],
})
export class HomeModule {}
