import { Component, Input, Output, EventEmitter, Injector, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NetworkCodes } from './connectors/connector.interface';
import { GmbConnectorAdapter } from './connectors/gmb-connector/gmb-connector.adapter';
import { FbConnectorAdapter } from './connectors/fb-connector/fb-connector.adapter';

const smConnectorAdaptersMap = {
	[NetworkCodes.GMB]: GmbConnectorAdapter,
	[NetworkCodes.FB]: FbConnectorAdapter,
}

type SmConnectorAdapter = InstanceType<typeof smConnectorAdaptersMap[keyof typeof smConnectorAdaptersMap]>

@Component({
	selector: 'sm-connect-button',
	templateUrl: './sm-connect-button.component.html',
})
export class SmConnectButtonComponent implements OnInit, OnDestroy {
	@Input() networkCode: NetworkCodes;
	@Input() entry?: unknown;
	@Output() connectFinish = new EventEmitter<void>();
	connectorAdapter: SmConnectorAdapter;
	User: UserProfile;
	destroyed$ = new Subject<void>();

	constructor(
		{ profile }: UserService,
		private injector: Injector
	) {
		this.User = profile;
	}

	public ngOnInit(): void {
		const AdapterClass = smConnectorAdaptersMap[this.networkCode];
		if (!AdapterClass) {
			console.error(`No adapter found for ${this.networkCode}`);
			return;
		}

		this.connectorAdapter = this.injector.get<SmConnectorAdapter>(AdapterClass);
	}

    public connect(entry?: unknown) {
        this.connectorAdapter?.connect(entry);
        // Ignore multiple events triggerred by the same action
        this.connectorAdapter.onConnectFinish$
            .pipe(
                take(1),
                takeUntil(this.destroyed$),
            )
            .subscribe(() => {
                this.connectFinish.emit();
            });
    }

	public ngOnDestroy(): void {
        this.cleanUp();
		this.destroyed$.next();
		this.destroyed$.complete();
	}

    public cleanUp(): void {
        this.connectorAdapter?.cleanUp();
    }
}
