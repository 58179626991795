import { Pipe, PipeTransform } from '@angular/core';
import { TableauReportingGroup } from 'shared/new-contacts/contacts.interface';

@Pipe({
    name: 'reportingGroups',
})
export class ReportingGroupPipe implements PipeTransform {
    transform(items: TableauReportingGroup[]): string {
        if (!items?.length) {
            return 'None';
        }

        return (items).map(({ name }) => name).join(', ');
    }
}
