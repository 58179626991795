import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmModalComponent } from './confirm-modal.component';

@Component({
	templateUrl: './sm-confirm-modal.component.html',
})

export class SmConfirmModalComponent extends ConfirmModalComponent {
	account: any;

	constructor(modalRef: BsModalRef) {
		super(modalRef);
	}
}
