import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

export const messageCharsAmount = 4000;
export const messageLengthValidator = Validators.maxLength(messageCharsAmount);

export const getForm = (): RealmFormGroup => {
	return new RealmFormGroup({
		questionnaireId: new RealmFormControl(
			'questionnaireId',
			{
				label: 'Questionnaire',
				updateOn: 'change',
			},
			[
				Validators.required,
			],
		),
		_notify_upon_assignment: new RealmFormControl(
			'_notify_upon_assignment',
			{
				label: 'Notify',
				updateOn: 'change',
			},
		),
		toContactIds: new RealmFormControl(
			'toContactIds',
			{ label: 'To: Client' },
		),
		ccRealmUserIds: new RealmFormControl(
			'ccRealmUserIds',
			{
				label: 'CC: Internal',
				updateOn: 'change',
			},
		),
		message: new RealmFormControl(
			'message',
			{
				label: 'Message',
				updateOn: 'change',
			},
			messageLengthValidator,
		),
		_notify_upon_completion: new RealmFormControl(
			'_notify_upon_completion',
			{
				label: 'Notify upon completion',
				updateOn: 'change',
			},
		),
		notifyUponCompletionRealmUserIds: new RealmFormControl(
			'notifyUponCompletionRealmUserIds',
			{
				label: 'To: Internal',
				updateOn: 'change',
			},
		),
		_is_scheduled: new RealmFormControl(
			'_is_scheduled',
			{
				label: 'Schedule',
				updateOn: 'change',
			},
		),
		scheduledDate: new RealmFormControl(
			'scheduledDate',
			{
				label: 'Date',
				updateOn: 'change',
			},
		),
	});
};
