import { Component } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { NavigationMenuItem } from 'utils/navigation/navigation.interface';
import { DocumentsDocusignConnectivityStatus } from '../status'

const {
	featureToggle_docusign_enabled: docusignEnabled,
} = window.env;

@Component({
	templateUrl: './documents.component.html',
})
export class DocumentsComponent {
	tabs: NavigationMenuItem[];

	constructor(
		userService: UserService,
		private readonly documentsDocusignConnectivityStatus: DocumentsDocusignConnectivityStatus,
	) {
		const docusignStatus$ = this.documentsDocusignConnectivityStatus.get();
		const user = userService.profile;
		this.tabs = [
			{ sref: 'documents.list', title: 'Documents' },
			...(
				(
					docusignEnabled === 'true'
					&& user.can('MANAGE_GLOBAL_DOCUMENTS')
				)
					? [ { sref: 'documents.docusign', title: 'DocuSign', hasAlert: docusignStatus$ } ]
					: []
			),
		];
	}
}
