<div class="issue-title">
	<div class="issue-number">{{issue.number || '?'}}</div>
	Observations
</div>

<ul class="observations">
	<li *ngFor="let item of issue.observations">
		<span class="observation-name">{{item.observation}}</span>
		<a (click)="showRegulations(item.id)">Regulations</a>
	</li>
	<li class="text-muted font-italic">{{issue.description}}</li>
</ul>

<div *ngIf="canManage">
	<button
		type="button"
		class="btn btn-secondary"
		(click)="enterEditing()"
		[disabled]="findingModelService.isEditingIssue"
	>
		<span class="glyphicon glyphicon-pencil small-icon"></span>Edit
	</button>
</div>
