import { Component } from '@angular/core';

import { CodeValueService } from 'shared/code-value.service';

import { ChecklistSettingsService } from '../checklist-settings.service';

@Component({
	templateUrl: './state-checks.component.html',
})
export class ChecklistSettingsStateChecksComponent {
	filtersConfig: any;
	fetchIssues: any;
	updateIssues: any;
	groupColumnConfig = {
		label: 'State',
		fieldName: 'group',
	};

	constructor(
		checklistSettingsService: ChecklistSettingsService,
	) {
		this.filtersConfig = [
			// {
			// 	container: 'col-sm-6',
			// 	filter: {
			// 		label: 'States',
			// 		name: 'goupId',
			// 		items: checklistSettingsService.groups.get({ isFederallyRegistered: false }),
			// 		bindValue: 'shortName',
			// 		bindLabel: 'name',
			// 		placeholder: 'All States',
			// 	},
			// },
		];
		this.fetchIssues = checklistSettingsService.stateChecks.get;
		this.updateIssues = checklistSettingsService.stateChecks.update;
	}
}
