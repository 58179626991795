<client-document
	[document]="document"
	[templateDownloadLink]="templateDownloadLink"
	(upload)="!document.$pending && canUpload && onFileSelect($event)"
	(showHistory)="showHistory()"
>
	<div class="flex-column right gap-sm">
		<ng-container *ngIf="canUpload">
			<label class="file-input">
				<span class="btn btn-primary w-full" (click)="inputElement.value = null">Upload Document</span>
				<input #inputElement type="file" [multiple]="false" (input)="onFileSelect(inputElement.files)"/>
			</label>

			<a
				*ngIf="!document.libraryId"
				class="btn btn-secondary w-full"
				(click)="chooseFromLibrary()"
			>
				Choose from Library
			</a>
		</ng-container>

		<button
			*ngIf="canSign"
			type="button"
			class="btn btn-primary w-full"
			(click)="sign()"
		>
			DocuSign
		</button>
	</div>
</client-document>
