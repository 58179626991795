import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: './scorecard-header.component.html',
    selector: 'scorecard-header',
})
export class ScorecardHeaderComponent implements OnInit {
    tpoId: number;
    isCCM: boolean = false;
    baseSref: string;
    baseSrefParams;

    tabs;

    constructor(
        private readonly uiRouter: UIRouter,
        private readonly userService: UserService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.uiRouter.globals.params.id;
        this.isCCM = this.userService.profile.isComergence;

        this.assignBaseUiSref();
        this.assignTabs();
    }

    assignBaseUiSref() {
        if (this.isCCM) {
            this.baseSref = 'tpo.:id.scorecard'
        } else {
            this.baseSref = 'client.:id.reviews.scorecard'
        }
        this.baseSrefParams = { id: this.tpoId }
    }

    assignTabs() {
        if (this.isCCM) {
            this.tabs = [
                {
                    state: this.baseSref + '.summary',
                    title: 'Summary',
                },
                {
                    state: this.baseSref + '.reviews',
                    title: 'Due Diligence',
                },
                {
                    state: this.baseSref + '.comments',
                    title: 'Comments',
                },
            ];
        } else {
            this.tabs = [
                {
                    state: this.baseSref + '.summary',
                    title: 'Summary',
                },
                {
                    state: this.baseSref + '.comments',
                    title: 'Comments',
                },
            ];
        }
    }
}
