import { Component, forwardRef } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { UIRouter } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { ContactImportItem, ContactsImportService } from 'tpo/people/contacts/import/import.service';

@Component({
    selector: 'contact-imports-list',
    templateUrl: './imports-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ContactImportsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ContactImportsListComponent) },
    ],
})
export class ContactImportsListComponent extends NewPagedListComponent<ContactImportItem> {
    static listName = 'contactImportsList';

    constructor(
        router: UIRouter,
        private readonly importService: ContactsImportService,
    ) {
        super(router);
    }

    protected loadList(params: ListParams): Observable<PagedData<ContactImportItem[]>> {
        return this.importService.getImportList(params);
    }
}
