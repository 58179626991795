import { pick } from 'lodash';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
	OnChanges,
	OnDestroy,
	ContentChild,
	TemplateRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';

import { MaxLengthValidator } from 'commons/validators';
import { RealmFormGroup, RealmFormControl } from 'commons/forms';
import { ReviewsResourceService } from '../../../reviews-resource.service';

@Component({
	selector: 'review-reply',
	templateUrl: './reply.component.html',
})
export class ReviewsReplyComponent implements OnInit, OnChanges, OnDestroy {
	@Input() text: string = '';
	@Input() reviewId: number;
	@Input() replyId?: number;
	@Input() charsAmount = 1024;
	@Input() rows = 5;
	@Input() permission: boolean;
	@Input() replyBtnText?: string = 'Respond';
	@Input() openedFormsList?: { [key: number]: boolean; } = {};
	@Input() additionalParams?: { [param: string]: unknown; } = {};

	textSubscription: Subscription;
	submitted: boolean = false;
	originalText = '';
	textPristine = true;
	isEditing = false;

	addReplyForm: RealmFormGroup = new RealmFormGroup({
		text: new RealmFormControl(
			'text',
			{ label: 'Text', value: '', updateOn: 'change' },
		),
	});

	@Output() onOpen: EventEmitter<number> = new EventEmitter<number>();
	@Output() onAdd: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancel: EventEmitter<number> = new EventEmitter<number>();

	@ContentChild('replyViewTemplate', {static: true}) replyViewTemplate: TemplateRef<unknown>;
	@ContentChild('replyFormTemplate', {static: true}) replyFormTemplate: TemplateRef<unknown>;

	constructor(
		public reviewsResource: ReviewsResourceService,
	) {
		this.replyId = this.reviewId;
	}

	get vewContext(): {} {
		const props = pick(this, [
			'addReplyForm',
			'text',
			'textPristine',
			'submitted',
			'reviewId',
			'replyId',
			'rows',
			'charsAmount',
			'permission',
			'replyBtnText',
			'isEditing',
			'isInvalid',
			'additionalParams',
		]);
		const methods = pick(this, ['add', 'openForm', 'openEditForm', 'cancelEdit', 'isFormOpened', 'getCharsCount']);
		return { ...props, methods };
	}

	get isInvalid(): boolean {
		return this.submitted || !this.addReplyForm.value.text || this.textPristine
	}

	ngOnInit(): void {
		this.textSubscription = this.addReplyForm.valueChanges.subscribe((values) => {
			const { text } = values;

			this.textPristine = (text === this.originalText);
		});

		this.addReplyForm.get('text').setValidators(
			Validators.compose([ MaxLengthValidator(this.charsAmount) ]),
		);
	}

	ngOnChanges(changes): void {
		const { text } = changes;
		if (text) {
			this.originalText = this.getNormalizedText(text.currentValue);
			this.resetAddForm();
		}
	}

	ngOnDestroy(): void {
		if (this.textSubscription) {
			this.textSubscription.unsubscribe();
		}
	}

	add = (): void => {
		if (this.isInvalid) {
			return;
		}

		this.submitted = true;
		const params = {
			reviewId: this.reviewId,
			...(this.isEditing ? { replyId: this.replyId } : null),
		};

		const data = { ...this.addReplyForm.value, ...this.additionalParams };

		this.reviewsResource.review[this.isEditing ? 'editReply' : 'reply'](params, data,
			() => this.onAdd.emit(),
			/* error */
			({ data: response }) => {
				this.submitted = false;
				this.addReplyForm.setServerError(response);
			},
		);
	}

	resetAddForm = (): void => {
		this.addReplyForm.reset();
		this.addReplyForm.setValue({
			text: this.originalText,
		});
		this.addReplyForm.markAsPristine();
		this.addReplyForm.markAsUntouched();

		this.addReplyForm.serverError = '';
	}

	getNormalizedText(inputText: string | null): string {
		const text = inputText || '';
		return `${text}`.replace(/<br\/>/g, '\n');
	}

	cancelEdit = (): void => {
		if (this.submitted) {
			return;
		}
		if (this.isEditing) {
			this.isEditing = false;
			this.addReplyForm.patchValue({ text: this.originalText = this.text = ''})
		}

		this.resetAddForm();
		this.openedFormsList[this.replyId] = false;
		this.onCancel.emit(this.replyId);
	}

	getCharsCount = (): number => Math.max(0, (this.charsAmount - this.addReplyForm.value.text.length));

	openForm = (): void => {
		this.openedFormsList[this.replyId] = true;
		this.onOpen.emit(this.replyId);
	}

	openEditForm(editingText: string, editingId: number): void {
		this.isEditing = true;
		this.replyId = editingId;
		this.addReplyForm.patchValue({ text: this.originalText = this.text = editingText})

		this.openForm()
	}

	isFormOpened = (): boolean => this.openedFormsList[this.replyId];
}
