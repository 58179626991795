import { Component, EventEmitter, Input, Output } from '@angular/core';
import { findIndex, assign, find, forEach, get, keys, pick, map, pull, set, first } from 'lodash';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { HumanSizePipe } from 'commons/pipes/human-size.pipe';
import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { socialMediaNetworksConfig, DefaultNetworkCodes } from 'commons/components/sm-icons';
import { SrcInfo } from 'commons/directives/src-blob.directive';
import { NgResourceArray } from 'commons/interfaces';

import { TpoPublisherAttachmentService, Thumbnails } from './tpo-publisher-attachment.service';
import {
    PublisherAttachmentsPreviewService,
    ThumbnailFrame,
} from '../posts/$postType/$postId/posts-preview/publisher-attachments-preview/publisher-attachments-previews.service';
import { AttachmentsConfig } from './attachments.config';
import { EditThumbnailModalComponent } from './edit-thumbnail-modal';
import {
	getKey,
	setSubType,
	lastIndex,
	getImageExtension,
	configTest,
	GlobalLimits,
	ProcessedAttachment,
	AttachmentError,
} from './attachments.helper';

declare let window: Window;

const postTypes = {
	false: 'social-media-posts',
	true: 'social-media-post-templates',
};

const errorTypes = {
	size: 'max-Size',
	format: 'allowed-Formats',
	count: 'max-Count',
};

/* Global Validator, one time usage */
export const PostValidator = (control: RealmFormGroup): { [key: string]: any } | null => {
	const post = control.value;

	const filesPerType: any = {};
	if (post.mediaLink) {
		filesPerType.LINK = 1;
	}
	forEach(post.attachments, (item) => {
		if (!item.type) { return; }
		set(filesPerType, item.type, get(filesPerType, item.type, 0) + 1);
	});

	// maxCount for the video
	const result = !(keys(filesPerType).length > 999 || get(filesPerType, 'VIDEO', 0) > 999); //@Notice: temporary hide such validation

	return result ? null : {
		total: {
			value: control.value,
			message: 'You can only attach 1 files (video, media link or image) and only images may have multiple attachments.',
		},
	};
};

@Component({
	selector: 'post-attachments',
	templateUrl: './attachments.component.html',
})
export class AttachmentsComponent {
	@Input() post: {
		attachments: NgResourceArray<ProcessedAttachment> & AttachmentError[],
		selectedThumbnails: { [attachmentId: number]: number },
		uploadedManuallyThumbnails: { [attachmentId: number]: number },
		templated: any[],
		$promise: Promise<any>,
	};

	@Input() editable?: boolean = false;

	@Input() config: AttachmentsConfig;

	postTypes = postTypes;
	uploadingCurrently: number = 0;

	networksConfig = socialMediaNetworksConfig;
	allowedNetworks = DefaultNetworkCodes;
	range: [number, number, number] = [15, 50, 85]; // percent of duration
	defaultImg: string = this.previewsService.getDefaultThumbnail();
	modalRef: BsModalRef;

	@Output() status? = new EventEmitter<boolean>();

	networkErrors: [{
		networkCode: string,
		error: string,
	}?] = [];

	@Output() error = new EventEmitter<[{ networkCode: string, error: string }?]>();

	constructor(
		public attachmentService: TpoPublisherAttachmentService,
		public humanPipe: HumanSizePipe,
		public modalService: BsModalService,
		public previewsService: PublisherAttachmentsPreviewService,
	) {}

	remove(attachment: ProcessedAttachment): void {

		if (!attachment.$resolved && attachment.$cancelRequest) {
			attachment.$cancelRequest();
		}

		pull(this.post.attachments, attachment);
		this.previewsService.updatePreviews();

		this.attachmentsValidation();

		if (this.post.attachments.length === 0) {
			this.error.emit(this.networkErrors);
		}
	}

	updateCounter(add: boolean = false): void {
		if (add) {
			this.uploadingCurrently++;
		} else {
			this.uploadingCurrently--;
		}
		this.status.emit(this.uploadingCurrently > 0);
	}

	getGlobalLimits(): { imageLimits: GlobalLimits, videoLimits: GlobalLimits } {
		const networkLimits = pick(this.networksConfig, this.allowedNetworks);
		const imageLimits = {
			formats: map(networkLimits, 'allowedImageFormats.value'),
			maxSize: Math.max(...map(networkLimits, 'maxImageSize.value')),
			type: 'IMAGE',
		};
		const videoLimits = {
			formats: map(networkLimits, 'allowedVideoFormats.value'),
			maxSize: Math.max(...map(networkLimits, 'maxVideoSize.value')),
			type: 'VIDEO',
		};

		return { imageLimits, videoLimits };
	}

	onPick(files: FileList): void {
		const postType = postTypes[this.config.template + ''];
		const { imageLimits, videoLimits } = this.getGlobalLimits();

		forEach(files, (file, index: number) => {
			const { name, size } = file;
			// @Notice: check if it's a video or image
			const fileTypeConfig = configTest(imageLimits, name) || configTest(videoLimits, name);

			if (fileTypeConfig) {
				const { type, maxSize } = fileTypeConfig;
				if (size <= maxSize) {
					this.updateCounter(true);
					const attachment: ProcessedAttachment = this.attachmentService.attach(
						{ type, postType },
						file,
						({ id: attachmentId }) => {
							attachment.size = size;
							attachment.name = name;
							if (type === 'VIDEO') {
								this.previewsService.getVideoPreviews(file, this.range).then((previews: ThumbnailFrame[] | null) => {
									if (previews) {
										this.attachmentService.uploadThumbnails(
											{ attachmentId, postType },
											previews,
											(thumbnails) => {
												this.setUrl(attachment);
												this.previewsService.setCachedThumbnails(attachmentId, thumbnails);
												this.previewsService.updatePreviews();
											}
										);
									} else {
										attachment.imageUrl = this.defaultImg;
										this.previewsService.updatePreviews();
									}
								});
							} else {
								this.setUrl(attachment);
								this.previewsService.updatePreviews();
							}
							setSubType(attachment);
							this.updateCounter();

						},
						(error) => {
							this.updateCounter();
							assign(
								attachment,
								this.errorAttachment(error.status > 0 ? error.data.message : 'Connection error.'),
							);
						},
					);
					// set attachment type while loading
					assign(
						attachment,
						{ type },
					);
					this.post.attachments.push(attachment);
				} else {
					this.post.attachments.push(this.errorAttachment(
						`File size exceeds ${this.humanPipe.transform(maxSize)}.`, errorTypes.size, type
					));
				}
			} else {
				this.post.attachments.push(this.errorAttachment('Unsupported format.'));
			}

			if (index === lastIndex(files)) {
				this.attachmentsValidation();
			}
		});
	}

	setUrl(attachment: ProcessedAttachment): string {
		const { tpoId, template } = this.config;
		return attachment.imageUrl = `/api/rest/tpos/${tpoId}/${this.postTypes[template.toString()]}/attachments/${attachment.id}/full`;
	}

	editThumbnail(attachmentId: number): void {
		const postType = this.postTypes[this.config.template + ''];
		const initialState = {
			thumbnailsResponse: this.previewsService.getCachedThumbnails(attachmentId),
			attachmentId,
			tpoId: this.config.tpoId,
			postType,
			selectedThumbnails: this.post.selectedThumbnails,
			manuallyThumbnails: this.post.uploadedManuallyThumbnails,
			defaultImage: this.defaultImg,

			onConfirm: (selectedThumbnailId: number, uploadedManuallyThumbnailId: number, getUrl: (id) => string): void => {
				const i = findIndex(this.post.attachments, { id: attachmentId });
				this.post.attachments[i].imageUrl = selectedThumbnailId ? getUrl(selectedThumbnailId) : this.defaultImg;
				this.previewsService.getCachedThumbnails(attachmentId)
					.then((thumbnails) => this.previewsService.setCachedThumbnails(attachmentId, {
						...thumbnails,
						selectedThumbnailId,
						uploadedManuallyThumbnailId,
					}));
				this.post.selectedThumbnails = { ...this.post.selectedThumbnails, [attachmentId]: selectedThumbnailId };
				this.post.uploadedManuallyThumbnails = {
					...this.post.uploadedManuallyThumbnails,
					[attachmentId]: uploadedManuallyThumbnailId,
				};
				this.previewsService.updatePreviews();
				this.modalRef.hide();
			},

			uploadManually: (files: FileList): Thumbnails => {
				return this.attachmentService.uploadThumbnail({ attachmentId, postType }, first(files));
			},
		};

		this.modalRef = this.modalService.show(EditThumbnailModalComponent, {
			initialState,
			class: 'modal-smd modal-new modal-restore-link',
			backdrop: 'static',
		});
	}

	errorAttachment($error: string, errorType: string = null, type = null): AttachmentError {
		if (!errorType || !type) { return { $error }; }

		const errorKey = getKey(type, errorType);

		const networksMessages = {};
		forEach(this.allowedNetworks, (networkCode) => {
			networksMessages[networkCode] = this.networksConfig[networkCode][errorKey].error;
		});

		return { $error, networksMessages };
	}

	setAttachmentInfo({ error, type, size }: SrcInfo, index: number): void {
		const attachment = this.post.attachments[index];
		if (error) {
			attachment.loaded = true;
			return;
		}

		if (attachment?.name) { return; } // if name exists, it's onPick event

		if (attachment?.type === 'IMAGE') {
			attachment.name = getImageExtension(type);
			attachment.size = size;

			setSubType(this.post.attachments[index]);
		} else if (attachment?.type === 'VIDEO') {
			attachment.name = 'video.mp4';
			size && (attachment.size = size);
		}
		if (index === lastIndex(this.post.attachments)) {
			this.attachmentsValidation();
		}
	}

	checkFormat(networkCode: string, { name, subtype }: ProcessedAttachment): void {
		if (!subtype) { return; }
		const key = getKey(subtype, errorTypes.format);

		const { value, error } = this.networksConfig[networkCode][key];
		if (name && !value.test(name)) {
			this.networkErrors.push({ networkCode, error });
		}
	}

	checkSize(networkCode: string, { size, subtype }: ProcessedAttachment): void {
		if (!subtype) { return; }
		const key = getKey(subtype, errorTypes.size);

		const { value, error } = this.networksConfig[networkCode][key];
		if (size > value) {
			this.networkErrors.push({ networkCode, error });
		}
	}

	checkCount(): void {
		for (const networkCode of this.allowedNetworks) {
			for (const subtype of [ 'IMAGE', 'VIDEO', 'GIF' ]) {
				const key = getKey(subtype, errorTypes.count);
				const { value, error } = this.networksConfig[networkCode][key];
				if (find(this.post.attachments, { subtype }) && this.post.attachments.length > value) {
					this.networkErrors.push({ networkCode, error });
				}
			}
		}
	}

	checkDimensions(networkCode: string, width: number, height: number): void {
		if (!width || !height) { return; }
		const { value, error } = this.networksConfig[networkCode].imageDimension || {};
		if (width < value?.min.width || height < value?.min.height
			|| width > value?.max.width || height > value?.max.height) {
			this.networkErrors.push({
				networkCode,
				error: `The dimension of the uploaded image are not supported by Google My Business: ${width}x${height}px. ${error}`,
			});
		}
	}

	attachmentsValidation(): void {
		Promise.all([
			this.post.$promise,
			...map(this.post.attachments, (attachment: ProcessedAttachment) => attachment.$promise),
		])
			.then(async () => {
				/* @Notice: back-end supports only 2 types of data: video and images.
					Some sm networks like a FB parse the gif file(1 file) like a video in the same time, sm like
					TW or LI parse gif like the image. Sounds like we need to support 1 more content type(GIF).
					Back-end doesn't want to add it on their side.
					See the "setSubType() function" and line 355 (attachment.subtype !== 'GIF' && ...)
					to fix the back-end issue I have added the subtype prop. and I am using it for the content validation. */
				for (const attachment of this.post.attachments) {
					const { width, height } = attachment.subtype === 'IMAGE' && attachment.dimension || {};
					for (const networkCode of this.allowedNetworks) {
						attachment.subtype === 'IMAGE' && this.checkDimensions(networkCode, width, height);
						attachment.subtype !== 'GIF' && this.checkFormat(networkCode, attachment);

						this.checkSize(networkCode, attachment);
					}
				}
				this.checkCount();
				this.networkWarningsEmit();
			});
	}

	networkWarningsEmit(): void {
		this.error.emit(this.networkErrors);
		this.networkErrors = [];
	}
}
