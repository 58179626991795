<div [ngClass]="className" (fileDrop)="onFileSelect($event)">
	<div class="front-face">
		<ng-container *ngTemplateOutlet="viewTemplate || defaultViewTemplate; context: { document: document, fn: fn }"></ng-container>
		<ng-template #defaultViewTemplate let-document="document" let-fn="fn">
			<div class="flex-row gap">
				<div class="title flex flex-row gap-sm">
					<span class="c-icon c-icon-file text-muted"></span>
					<a class="flex" [href]="fn.link()" target="_blank">{{document.name}}</a>
				</div>

				<a class="btn btn-tertiary btn-icon" (click)="fn.history()" *ngIf="!(document.$uploading || document.$uploaded)">
					<span class="c-icon c-icon-back-in-time text-bigger"></span>
				</a>

				<ng-container *ngIf="!(document.$uploading || document.$uploaded) && ((User.isComergence && User.can('CCM_SMC_VIEW_ALL')) || (User.isTpo && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')))">
					<a class="btn btn-tertiary btn-icon" (click)="fn.edit()">
						<span class="glyphicon glyphicon-pencil"></span>
					</a>

					<label class="file-input" *ngIf="!document.$uploading && !document.$uploaded">
						<span class="btn btn-primary">Upload</span>
						<input type="file" #file (input)="fn.select($event.target.files)" [multiple]="false">
					</label>
				</ng-container>

				<div class="uploading" *ngIf="document.$uploading">
					<loader></loader>
				</div>

				<div class="uploaded" *ngIf="document.$uploaded">
					<span class="glyphicon glyphicon-ok-sign"></span>
					<span>Done</span>
				</div>
			</div>
		</ng-template>
	</div>

	<div class="back-face flex-column center text-center">
		<span>{{document.$error}}</span>
		<button type="button" class="btn btn-primary" (click)="hideError()">OK</button>
	</div>
</div>
