import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ChannelsService, Person } from 'lender/clients/$id/channels/channels.service';
import { UIRouter } from '@uirouter/core';
import { Entity } from 'tpo/documents/documents.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { UserService } from 'angularjs-providers/user.provider';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    templateUrl: './invite.component.html',
    host: {
        'class': 'remote-resource',
    },
})
export class ChannelInviteComponent implements OnInit {
    @HostBinding('class.resolved') resolved = false;
    @Input() lenderInfo: AccountInformation;
    inviteContacts!: Person[];
    assignees!: Person[];
    channel!: Entity;
    charsLeft: number;
    readonly maxChars = 2000;
    form = new RealmFormGroup({
        accountExecutiveId: new RealmFormControl('accountExecutiveId', { label: 'Assigned' }, Validators.required),
        contactId: new RealmFormControl('contactId', { label: 'Contact Name' }, Validators.required),
        message: new RealmFormControl('message', { label: 'Message', updateOn: 'change' }, Validators.required),
    });

    constructor(
        private readonly user: UserService,
        private readonly router: UIRouter,
        private readonly channelsService: ChannelsService,
        private readonly notificationsService: GlobalNotificationsService
    ) {
    }

    getParams() {
        const { identifier: { tpoId } } = this.lenderInfo;
        const { channelId } = this.router.globals.params;
        return { tpoId, channelId };
    }

    async ngOnInit() {
        if (!this.user.profile.can('INVITE_TO_APPLY')) {
            throw new HttpErrorResponse({ status: 403 });
        }
        const { channelId, tpoId } = this.getParams();
        const { id: accountExecutiveId, organization: { name } } = this.user.profile;

        this.form.get('message').valueChanges.subscribe(value => {
            this.charsLeft = this.maxChars - (value || '').length;
        });

        [
            this.inviteContacts,
            this.assignees,
            this.channel,
        ] = await Promise.all([
            this.channelsService.getInviteContacts(tpoId).toPromise(),
            this.channelsService.getAccountExecutives(channelId).toPromise(),
            this.channelsService.getBase(channelId).toPromise(),
        ]);

        this.resolved = true;
        this.form.reset({
            accountExecutiveId,
            message: `${name} has invited your company to apply. Your invitation will be listed on your Comergence homepage once you login. You must click 'Accept' to launch and start your application. If you choose to 'Decline', your invitation will be removed. Thank you.`,
        });
    }

    async save() {
        //Prevent double-click
        if (!this.resolved) {
            return;
        }
        const { channelId, tpoId } = this.getParams();

        try {
            this.resolved = false;
            await this.channelsService.sendInvite(tpoId, channelId, this.form.value).toPromise();
            this.notificationsService.add({
                type: GlobalNotificationType.POSITIVE,
                message: `Invite to  <b>${this.channel.name}</b> was sent successfully.`,
            })
            this.router.stateService.go('^.^');
        } catch (exception) {
            const { error, status } = exception;
            if ([403,404].includes(status)) throw exception; //Shows global 403/404 modals
            this.form.setServerError(error);
        } finally {
            this.resolved = true;
        }
    }
}
