import { Component, forwardRef, OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { mapValues, map, filter } from 'lodash';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';

import { TasksService } from '../tasks.service';
import { TaskListBaseComponent } from '../common';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TaskListComponent) },
	],
})
export class TaskListComponent extends TaskListBaseComponent implements OnInit {
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	User: UserProfile = this.userService.profile;
	moreFilters: boolean = false;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		tasksService: TasksService,
		public userService: UserService,
	) {
		super(
			transitionService,
			stateService,
			tasksService,
		);
	}

	ngOnInit(): void {
		this.configureFilters();
		this.setInitialFilters()
			.finally(() => super.ngOnInit());
	}

	configureFilters(): void {
		this.filters = {
			remediationStatus: [
				{ title: 'Open' },
				{ title: 'Reopened' },
				{ title: 'Completed' },
			],
			findingStatus: this.tasksService.getFindingStatuses(),
		};

		this.defaultFilters = {
			...this.defaultFilters,
			remediationStatus: [],
			findingStatus: [],
			dueDateFrom: null,
			dueDateTo: null,
			completedDateFrom: null,
			completedDateTo: null,
		};
	}

	setInitialFilters(): Promise<void> {
		return this.filters.findingStatus.$promise.then((status) => {
			const inProgress = filter(status, { correspondingCondition: 'In Progress' });
			// @Notice we can't use the setFilter here because loadList will be executed twice
			!this.stateService.params[this.listName] && (this.stateService.params[this.listName] = {
				remediationStatus: this.User.can('TPO_SMC_VIEW_ALL') ? [ 'Completed' ] : [ 'Open', 'Reopened' ],
				findingStatus: map(inProgress, 'status'),
			});
		});
	}

	loadList(params) {
		this.list.$resolved = false;
		return this.tasksService.list(
			this.processParams(params),
		).$promise
			.then((data) => {
				this.list = data;
			})
			.finally(() => {
				this.list.$resolved = true;
			});
	}

	processParams(params) {
		return mapValues(
			this.getClearParams(params),
			(value, key) => {
				switch (key) {
					case 'dueDateFrom':
					case 'dueDateTo':
					case 'completedDateFrom':
					case 'completedDateTo':
						return value.getTime();
					default:
						return value;
				}
			},
		);
	}

	setFilter(filters, resetPage = true): void {
		const processedFilter = mapValues(filters, (value: any, key: string) => {
			switch (key) {
				case 'remediationStatus':
					return map(value || [], 'title');
				case 'findingStatus':
					return map(value || [], 'status');
				default:
					return value;
			}
		});

		super.setFilter(processedFilter, resetPage);
	}

	toggleFilters(): void {
		this.moreFilters = !this.moreFilters;
	}
}
