import { Component } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { GlobalNotification, GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

import warningIcon from '~static/images/material/two-tone/warning_black_48dp.svg';
import errorIcon from '~static/images/material/two-tone/error_black_48dp.svg';
import infoIcon from '~static/images/material/two-tone/feedback_black_48dp.svg';
import { Observable } from 'rxjs';

@Component({
    selector: 'info-message-holder',
    templateUrl: './notification-center.component.html',
    animations: [
        trigger('show', [
            transition(':enter', [
                style({
                    opacity: 0,
                    marginLeft: '100%',
                }),
                animate('0.3s ease-in-out', style({
                    marginLeft: '*',
                    opacity: 1,
                })),
            ]),
            transition(':leave', [
                style({
                    zIndex: -1,
                    opacity: 1,
                    transformOrigin: '95% 0',
                }),
                animate('0.3s ease-in-out', style({
                    height: 0,
                    opacity: 0,
                    transform: 'scale(0.1) translateY(-50px)',
                })),
            ]),
        ]),
    ],
})
export class GlobalNotificationCenterComponent {
    classes = {
        [GlobalNotificationType.ERROR]: 'server-error',
        [GlobalNotificationType.NEGATIVE]: 'server-warning',
        [GlobalNotificationType.POSITIVE]: 'server-success',
        [GlobalNotificationType.INFO]: 'server-info',
    };

    TYPE = GlobalNotificationType;

    icons = {
        [GlobalNotificationType.ERROR]: errorIcon,
        [GlobalNotificationType.NEGATIVE]: infoIcon,
        [GlobalNotificationType.POSITIVE]: infoIcon,
        [GlobalNotificationType.INFO]: infoIcon,
    };

    public notifications$: Observable<GlobalNotification[]>;

    constructor(public notificationService: GlobalNotificationsService) {
        this.notifications$ = this.notificationService.notifications$.asObservable();
    }
}
