<div class="col-md-a">
    <span class="control-value">Questionnaire</span>
</div>
<div class="col-md-a">
    <span class="control-value">
        <span *ngIf="questionnaires.completed" class="label label-success-wired">Completed</span>
        <span *ngIf="!questionnaires.completed" class="label label-warning-wired">In Progress</span>
    </span>
</div>
<div class="col-md-a">
    <span class="control-value">
        {{ (questionnaires.reviewDate | date: 'MM/dd/yyyy') || '&ndash;' }}
    </span>
</div>
<div class="col-md-a">
    <span class="control-value">
        {{ questionnaires.reviewer || '&ndash;' }}
    </span>
</div>
<div class="col-f-120 button-holder text-right">
    <a
        [class.disabled]="!questionnaires.hasQuestionnaire"
        uiSref="account.questionnaires.:questionnaireId"
        [uiParams]="{ questionnaireId: questionnaires.tpoQuestionnaireId, channelId: channelId }"
        class="btn btn-default"
    >
        <span class="glyphicon glyphicon-pencil"></span>Update
    </a>
</div>
