<div class="team-access">
	<div class="sm-publishing-permissions">
		<div class="gray-head flex-head">
			<back-bread class="col-fit" [opts]="{headerClass: 'title-label'}">
				<span>Team Access</span>
			</back-bread>
		</div>

		<section [ngClass]="{ 'remote-resource': permissions, 'resolved': permissions.$resolved && account.$resolved }">
			<div class="filters-rows">
				<div class="row filter-labels">
					<div class="col-md-3">
						<div class="text-muted small">
							Search
						</div>
					</div>
				</div>
				<div class="row filter-selectors">
					<div class="col-md-3">
						<search-list placeholder="Name"></search-list>
					</div>

					<div class="col-sm-4 va-m">
						<div class="company horizontal-flex-group">
							<div class="icon-container space-bigger">
								<sm-icon [config]="account" [minified]="true"></sm-icon>
							</div>
							<div class="link-container text-overflow-ellipsis">
								<ng-container *ngIf="account.networkCode !== 'RS' && account.link; then accountLinkAnchor; else accountLinkName"></ng-container>
								<ng-template #accountLinkAnchor>
									<a [href]="account.link" target="_blank">{{account.name}}</a>
								</ng-template>
								<ng-template #accountLinkName>
									{{account.name}}
								</ng-template>
								<span *ngIf="account.$resolved && !account.link" class="text-muted">no link added</span>
								<div class="text-muted">
									<span *ngIf="(account.status == 'NOT_VERIFIED' || account.status == 'INSUFFICIENT_account')">Disconnected</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 pull-right">
						<div class="va-m text-right" *ngIf="canManage">
							<a *ngIf="isCompany && !editing && !isAddFormVisible" [aDisabled]="!publisherPermissions.$resolved"
							(click)="setEdit(true)"
							class="btn btn btn-default"><span class="glyphicon glyphicon-pencil"></span> Edit</a>

							<a *ngIf="!editing && !isAddFormVisible" [aDisabled]="!publisherPermissions.$resolved || !nonPermissionPublishers.$resolved"
							(click)="setAddUsersFormVisible(true)" class="btn btn btn-primary">
									<span class="glyphicon glyphicon-plus small-icon"></span> Add Users
							</a>

							<a *ngIf="editing" [aDisabled]="!isSomethingChangedOnEdit" (click)="saveAccounts()" class="btn btn-primary" tabindex="1" role="button">Save</a>
							<a *ngIf="editing" (click)="setEdit(false)" class="btn btn-default" tabindex="2">Cancel</a>
							<button *ngIf="isAddFormVisible" type="submit" form="entryAddForm" class="btn btn-primary">Save</button>
							<a *ngIf="isAddFormVisible" class="btn btn-primary-wired" (click)="!saving && setAddUsersFormVisible(false)">Cancel</a>
						</div>
					</div>
				</div>
			</div>

			<div class="rows-bordered published-list social-media">
				<div class="row-fluid header">
					<div class="col-md-8">
						Name
					</div>
					<div class="col-md-4" *ngIf="isCompany">
						Permission
					</div>
				</div>

				<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
					<div class="col-md-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
				</div>

				<div *ngIf="isAddFormVisible" class="row-fluid active-row">
					<form class="header-form" [formGroup]="entryAddForm" id="entryAddForm" (onValidSubmit)="create()">
						<form-errors [form]="entryAddForm"></form-errors>
						<div class="col-md-8 va-m">
							<div class="row">
								<div class="col-md-6">
									<ng-select
										placeholder="Select Users"
										formControlName="fullName"
										*ngIf="nonPermissionPublishers.$resolved"
										[items]="nonPermissionPublishers.$promise | async"
										bindLabel="fullName"
										[multiple]="true"
										tabindex="1"
										[closeOnSelect]="false"
										[clearable]="true"
										required></ng-select>
								</div>
								<div class="col-md-6"></div>
							</div>
						</div>
						<div class="col-md-4 va-m" *ngIf="isCompany">
							<div class="row">
								<div class="col-md-8">
									<ng-select
										placeholder="Select Permission"
										formControlName="permission"
										*ngIf="nonPermissionPublishers.$resolved"
										[items]="publisherPermissions.$promise | async"
										bindValue="shortName"
										bindLabel="name"
										tabindex="2"
										[clearable]="false"
										required></ng-select>
								</div>
								<div class="col-md-4"></div>
							</div>
						</div>
					</form>
				</div>
				<div class="row-fluid" *ngFor="let item of permissions">
					<div class="va-m" [ngClass]="'col-md-' + (isCompany ? 8 : 10)">
						<contact-link [data]="item"></contact-link>
					</div>
					<div class="col-md-2 va-m" *ngIf="isCompany">
						<span class="label" [ngClass]="item.permission.id !== 'RO' ? 'label-success' : 'label-default'" *ngIf="permissions.$resolved  && !editing">
							{{item.permission.name}}
						</span>
						<select class="form-control" [(ngModel)]="editAccounts[item.id].permission.id"
								(ngModelChange)="this.isSomethingChangedOnEdit = editAccounts[item.id].changed;"
								*ngIf="editing && editAccounts[item.id] && publisherPermissions.$resolved"
								(click)="setChanged(item.id)">
							<option *ngFor="let value of publisherPermissions" [ngValue]="value.shortName"> {{value.name}} </option>
						</select>
					</div>
					<div class="col-md-2 va-m text-right controls-section">
						<div  class="inline-menu" *ngIf="!saving && !editing && canManage" dropdown>
							<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
								<span class="c-icon c-icon-dot-3"></span>
							</a>
							<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
								<li>
									<a class="dropdown-item" (click)="removeAccount(item)">Revoke</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row-fluid" *ngIf="noMatches">
					<div class="col-md-12 text-center text-bigger text-muted">No matches found</div>
				</div>

				<div class="row-fluid" *ngIf="!permissions.length && !noMatches">
					<div class="col-md-12 text-center text-bigger text-muted">No users found</div>
				</div>
			</div>
			<br>
			<new-paging class="no-border" [(list)]="permissions" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</section>
	</div>
</div>
