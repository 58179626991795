import { Injectable } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';
import { cloneDeep } from 'lodash';

import { getPermissionsConfig } from 'shared/roles/role/permissions-config';

declare let window: Window;

export type UserType = 'tpo' | 'lender' | 'comergence';

export type PermissionConfig = {
    title: string;
    dependsOn: string[];
    disabled: () => boolean;
};

export type PermissionSectionConfig = {
    enabled: () => boolean;
    groups: string[];
};

export type PermissionGroupConfig = {
    title: string;
    columns: string[];
};

export type PermissionsConfig = {
    permissions: Map<string, PermissionConfig>;
    sections?: Map<string, PermissionSectionConfig>;
    grouping: Map<string, PermissionGroupConfig>;
}

@Injectable()
export class RawPermissionsService {
    constructor(private User: UserService) {
    }

    get(type: UserType): PermissionsConfig {
        const { profile: user } = this.User;
        const { env } = window;

        switch (type) {
            case 'tpo':
                return cloneDeep(getPermissionsConfig.tpo(user, env)) as unknown as PermissionsConfig;
            case 'lender':
                return cloneDeep(getPermissionsConfig.lender(user, env)) as unknown as PermissionsConfig;
            case 'comergence':
                return cloneDeep(getPermissionsConfig.comergence(user, env)) as unknown as PermissionsConfig;
            default:
                if (user?.isTpo) {
                    return this.get('tpo');
                }

                if (user?.isCustomer) {
                    return this.get('lender');
                }

                return this.get('comergence');
        }
    }
}
