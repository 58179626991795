<div class="flex-head">
	<h2 class="col-fit">
		Publisher
	</h2>
	<div class="col-min">
		<a class="btn btn-primary" uiSref=".new">Create Post</a>
	</div>
</div>

<section class="social-settings">
	<notification></notification>
	<posts-type-list></posts-type-list>
</section>
