import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactList, ContactListClone, ContactListContact, ContactListCriteria, ContactListDesignatedUser, ContactListOption, FilterOperationFieldType, LandingPageContactList } from '../investor-crm-contact-list.interface';


declare let apiPath;

@Injectable()
export class InvestorCrmContactListService {
    constructor(private http: RealmHttpClient) {
    }

    // Resources

    getLandingPageContactList(organizationId: number, params): Observable<PagedData<LandingPageContactList[]>> {
        return this.http.pagedRequest<LandingPageContactList[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/contact-lists`,
            params
        );
    }

    getContactList(organizationId: number, listId: number): Observable<ContactList> {
        return this.http.request(
            'GET',
            `${apiPath}/organizations/${organizationId}/contact-lists/${listId}`,
        );
    }

    getContactListUsers(organizationId: number): Observable<ContactListDesignatedUser[]> {
        return this.http.request(
            'GET',
            `${apiPath}/organizations/${organizationId}/contact-lists/users`,
        );
    }

    getContactListCriteria(organizationId: number): Observable<ContactListCriteria> {
        return this.http
            .request(
                'GET',
                `${apiPath}/organizations/${organizationId}/contact-lists/criteria`,
            )
            .pipe(
                map((criteria: ContactListCriteria) => {
                    const keys: FilterOperationFieldType[] = (Object.keys(criteria.operations) as FilterOperationFieldType[]);
                    keys.forEach((key: FilterOperationFieldType) => {
                        criteria.operations[key].forEach((operation: ContactListOption<FilterOperationFieldType>) => {
                            operation.key = key;
                        });
                    });

                    return criteria;
                })
            );
    }

    createContactList(organizationId: number, contactList: ContactList): Observable<ContactList> {
        return this.http.request(
            'POST',
            `${apiPath}/organizations/${organizationId}/contact-lists`,
            {},
            contactList
        );
    }

    saveContactList(organizationId: number, contactList: ContactList): Observable<ContactList> {
        return this.http.request(
            'PUT',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactList.id}`,
            {},
            contactList
        );
    }

    updateContactList(organizationId: number, contactList: ContactList): Observable<ContactList> {
        return this.http.request(
            'PUT',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactList.id}/update`,
            {},
            contactList
        );
    }

    deleteContactList(organizationId: number, contactListId: number): Observable<void> {
        return this.http.request(
            'DELETE',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactListId}`,
        );
    }

    cloneContactList(organizationId: number, contactListClone: ContactListClone): Observable<ContactList> {
        return this.http.request(
            'POST',
            `${apiPath}/organizations/${organizationId}/contact-lists/clone`,
            {},
            contactListClone
        );
    }

    getContactListContacts(
        organizationId: number,
        contactListId: number,
        params: any
    ): Observable<PagedData<ContactListContact[]>> {
        return this.http.pagedRequest(
            'GET',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactListId}/contacts`,
            params,
        );
    }

    searchContacts(
        organizationId: number,
        contactListId: number,
        params: any
    ): Observable<ContactListContact[]> {
        return this.http.request(
            'GET',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactListId}/autocomplete`,
            params,
        );
    }

    addContactToList(
        organizationId: number,
        contactListId: number,
        organizationContactId: number
    ): Observable<void> {
        return this.http.request(
            'PUT',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactListId}/contacts/${organizationContactId}`,
        );
    }

    deleteContactFromList(
        organizationId: number,
        contactListId: number,
        organizationContactId: number
    ): Observable<void> {
        return this.http.request(
            'DELETE',
            `${apiPath}/organizations/${organizationId}/contact-lists/${contactListId}/contact/${organizationContactId}`,
        );
    }
}
