import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TpoContactsService } from 'tpo/people/contacts/contacts.service';
import { UIRouterGlobals } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { SharedContactDetailsComponent } from 'shared/new-contacts/shared-contact-details.component';
import { CcmTpoService } from 'comergence/tpo/ccm-tpo.service';
import { map, shareReplay } from 'rxjs/operators';

@Component({
    templateUrl: '../../../tpo/people/contacts/details/tpo-contact-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcmContactDetailsComponent extends SharedContactDetailsComponent {
    public accountSref = 'tpo.:id.account.information';

    constructor(
        { profile }: UserService,
        { params }: UIRouterGlobals,
        protected contactsService: TpoContactsService,
        protected ccmTpoService: CcmTpoService,
    ) {
        super();
        const { contactId = null, id: tpoId } = params;
        this.user = profile;
        this.contactsService.getContact(contactId, tpoId).subscribe(contact => this.setContact(contact));
        this.company = this.ccmTpoService.getTpo(tpoId).pipe(
            map(({ companyName: name, organizationId }) => ({ name, organizationId })),
            shareReplay(1),
        );
    }
}
