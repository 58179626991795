import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';

export class ModalService extends Function {
}

export const ModalServiceProvider = {
	provide: ModalService,
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('$modal'),
	deps: ['$injector'],
};
