import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class SocialMediaRSSResourceService {
	rss: any;
	feeds: any;
	feedEntries: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const rssProps = {
			url: `${apiPath}/tpos/:tpoId/social-media-rss`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};

		const feedsProps = {
			url: `${apiPath}/tpo/:tpoId/rss-feed/entries`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};

		this.rss = $resource(
			rssProps.url,
			rssProps.params,
			{
				query: {
					method: 'GET',
					isArray: true,
				},
				add: {
					method: 'POST',
				},
				remove: {
					url: `${rssProps.url}/:id`,
					params: {
						...rssProps.params,
						id: '@id',
					},
					method: 'DELETE',
				},
				entry: {
					url: `${apiPath}/tpo/:tpoId/rss-feed/entry/:entryId`,
					params: {
						...rssProps.params,
						entryId: '@entryId',
					},
					method: 'GET',
				},
			},
		);

		this.feeds = $resource(
			feedsProps.url,
			feedsProps.params,
			{
				get: {
					method: 'GET',
					isArray: true,
					url: `${apiPath}/tpo/:tpoId/rss-feed/entry/:contentId/posts`,
					params: {
						...rssProps.params,
						contentId: '@contentId',
					},
				},
			},
		);

		this.feedEntries = PagedResource(
			feedsProps.url,
			feedsProps.params,
			{
				query: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}
}
