import { Component, OnChanges, OnInit, Input, Output, EventEmitter, SimpleChange, ViewChild, ElementRef } from '@angular/core';

import { NgResourceArray } from 'commons/interfaces';
import { PostsService } from 'tpo/social-compliance/publisher/posts/posts.service';
import {
	getTextValue,
	DynamicField,
} from 'tpo/social-compliance/publisher/library/templates/$templateId/template-text-edit/utils';

@Component({
	selector: 'template-text-edit',
	templateUrl: './text-edit.component.html',
})
export class TemplateTextEditComponent implements OnChanges, OnInit {
	@Input()
	text: string | null | undefined;

	@Output()
	textChange: EventEmitter<string> = new EventEmitter();

	@Output()
	ready: EventEmitter<boolean> = new EventEmitter();

	@Output() onDynamicFields = new EventEmitter<NgResourceArray<DynamicField>>();

	@ViewChild('templateTextEditElement', { read: ElementRef }) templateTextEditElement: ElementRef<HTMLTextAreaElement>;

	lastCaretPosition = 0;
	value = '';

	static dynamicFields: NgResourceArray<DynamicField> = [];
	static dynamicFieldsResolved = false;
	// copy to get access to DynamicFields in template
	dynamicFields: NgResourceArray<DynamicField> = [];

	constructor(
		public postsService: PostsService,
	) {
    }

	ngOnChanges(changes: { text: SimpleChange }): void {
		const { text } = changes;

		if (text?.currentValue && this.value !== text?.currentValue) {
			this.value = text?.currentValue ?? '';
		}
	}

    private updateDynamicFields  = () => {
        this.dynamicFields = [...TemplateTextEditComponent.dynamicFields];
        this.onDynamicFields.emit(this.dynamicFields);
        this.ready.emit(true);
    }

	async ngOnInit(): Promise<void> {
        if (TemplateTextEditComponent.dynamicFieldsResolved) {
            return this.updateDynamicFields();
        }

		// NOTICE: dynamic fields are the same for all Lenders,
		// no need to refetch it
		// change this logic if dynamic fields different for different Lenders
		TemplateTextEditComponent.dynamicFields = await this.postsService.getDynamicFields().$promise;
        TemplateTextEditComponent.dynamicFieldsResolved = true;
        this.updateDynamicFields();
	}

	setText(value: string | null | undefined): void {
		this.value = value;
		this.textChange.emit(value);
	}

	trackCaret(caretPosition: number): void {
		this.lastCaretPosition = caretPosition ?? 0;
	}

	insertDynamicField(placeholder: string): void {
		const newText = getTextValue(this.value, placeholder, this.lastCaretPosition);
		this.setText(newText);
		// this.lastCaretPosition = newText.length;

		this.templateTextEditElement?.nativeElement?.focus();
		setTimeout(() => {
			if (!this.templateTextEditElement?.nativeElement) {
				return;
			}

			const caretPosition = this.lastCaretPosition + placeholder.length;
			this.templateTextEditElement.nativeElement.selectionEnd = caretPosition;
			this.lastCaretPosition = caretPosition;
		}, 0);
	}
}
