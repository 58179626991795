import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { getRequestForm } from 'lender/clients/$id/documents/common/request-document-modal/request-document-modal.form';


export const getForm = (): RealmFormGroup => {
    const form = getRequestForm();

    form.addControl(
        'isAudited',
        new RealmFormControl(
            'isAudited', {
                label: 'Type',
                updateOn: 'change',
            },
            Validators.required,
        ),
    );

    form.addControl(
        'period',
        new RealmFormControl(
            'period', {
                label: 'Period',
                updateOn: 'change',
            },
            Validators.required,
        ),
    );

    form.addControl(
        'year',
        new RealmFormControl(
            'year', {
                label: 'Year',
                updateOn: 'change',
            },
            Validators.required,
        ),
    );

    return form;
};
