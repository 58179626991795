import { find, filter, map, pick, last } from 'lodash';
import { Component, OnChanges, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { GroupsResourceService } from 'tpo/social-compliance/publisher/groups/groups-resource.service';

import { ShareToTypes, TypeNames } from './share-to.types';

const defaultSelection = { ...find(ShareToTypes, (type) => (type.isDefault)) };
const groupSelection = { ...find(ShareToTypes, (type) => (type.id === TypeNames.GROUP)) };

@Component({
	selector: 'campaign-share-to-edit',
	templateUrl: './edit.component.html',
})
export class CampaignShareToEditComponent implements OnChanges, OnInit {
	@Input()
	value: any;

	@Input()
	placeholder: string = '';

	@Input()
	selectDefaultOnCreate: boolean = true;

	@Output()
	change: EventEmitter<any> = new EventEmitter();

	@Output()
	ready: EventEmitter<any> = new EventEmitter();

	groups: any = [];
	selected: any = [];
	items: any = [];

	sepcialOptionsAmount: number = 0;

	constructor(
		public groupsResource: GroupsResourceService,
	) {}

	ngOnChanges(changes) {
		const { value } = changes;

		if (!value.currentValue && value.firstChange && this.selectDefaultOnCreate) {
			// set default value to the form if nothing selected
			this.change.emit({
				sharingType: this.getRelatedFields(defaultSelection),
				shareTo: [],
			});
			return;
		}

		if (!value.currentValue) {
			this.selected = [];
			return;
		}

		if (value.currentValue.sharingType.id !== TypeNames.GROUP) {
			this.selected = [ value.currentValue.sharingType.id ];
			return;
		}

		this.selected = map(value.currentValue.shareTo, 'id');
	}

	ngOnInit() {
		this.groupsResource.groups.getAll({}, (data) => {
			this.items = this.prepareItems(data);
			this.ready.emit(true);
		}, () => {
			this.ready.emit(true);
		});
	}

	setSelection(data = [], ngSelector) {
		const lastValue = last(data);

		// array is empty
		if (!lastValue) {
			return this.change.emit(null);
		}

		// if none or all users was selected we have to send singleSelect
		if (lastValue.type === 'special') {
			ngSelector && ngSelector.close();
			return this.change.emit({
				sharingType: this.getRelatedFields(lastValue),
				shareTo: [],
			});
		}

		// if last value is a group
		// make sure no special values are selected
		const groups = filter(data, (entry) => (entry.type !== 'special'));

		this.change.emit({
			sharingType: groupSelection,
			shareTo: map(groups, (entry) => (this.getRelatedFields(entry))),
		});
	}

	prepareItems(groups) {
		const noneAndAll = filter(ShareToTypes, (entry) => (entry.id !== TypeNames.GROUP));
		const typedNoneAndAll = map(noneAndAll, (entry) => ({ ...entry, type: 'special' }));
		this.sepcialOptionsAmount = typedNoneAndAll.length;

		return map([ ...typedNoneAndAll, ...groups ], (entry) => {
			return {
				...this.getRelatedFields(entry),
				// special type already set, so if it's undefined we have a group entry
				type: entry.type || 'group',
			};
		});
	}

	getRelatedFields(entry) {
		return pick(entry, ['id', 'name']);
	}
}
