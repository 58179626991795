import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { TpoAccountModule } from 'tpo/account';
import { SharedTechOpsService } from 'shared/account/information/tech-ops/tech-ops.service';
import { SharedTechOpsComponent } from 'shared/account/information/tech-ops/tech-ops.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        TpoAccountModule,
    ],
    declarations: [
        SharedTechOpsComponent,
    ],
    providers: [
        SharedTechOpsService,
    ],
})
export class SharedTechOpsModule {
}
