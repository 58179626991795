<scorecard-header></scorecard-header>

<div class="remote-resource" [class.resolved]="resolved">
	<h3>
		Registration Date: {{ scorecardFindingsMetrics?.tpoRegistrationDate | date:'MM/dd/yyyy' }}
        <div *ngIf="isCCM" class="pull-right">
            <a uiSref="tpo.:id.scorecard.summary.add" class="btn btn-lg btn-primary">Add Finding</a>
        </div>
	</h3>
    <scorecard-summary-owners-section
        *ngIf="ownerFindings"
        [tpoId]="tpoId"
        [isCCM]="isCCM"
        [ownerFindings]="ownerFindings"
    ></scorecard-summary-owners-section>
</div>

<br />

<section *ngIf="scorecardFindingsMetrics" class="remote-resource" [class.resolved]="resolved">
    <ng-container *ngFor="let findingsMetricsSection of scorecardFindingsMetrics.sections; last as isLast">
        <scorecard-summary-findings-metrics-section
            [tpoId]="tpoId"
            [keyedScorecardFindingCategories]="keyedScorecardFindingCategories"
            [findingsMetricsSection]="findingsMetricsSection">
        </scorecard-summary-findings-metrics-section>

        <br *ngIf="!isLast" />
    </ng-container>
</section>
