import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';

import defaultImage from '~static/images/posts-preview/empty-image.png';

import { attachmentsConfig, defaultConfig } from './preview-config';
import {
	Attachment,
	AttachmentClasses,
	AttachmentClassExpression,
	AttachmentsConfig,
	Layout,
	Mode,
	Scale,
	TemplateConfig,
} from './attachments-preview.interface';

@Component({
	selector: 'attachments-preview',
	templateUrl: './attachments-preview.component.html',
	host: { 'class': 'attachments-preview' },
})
export class AttachmentsPreviewComponent implements OnInit {
	@Input() attachments: Attachment[];

	// TODO: move this logic outside to make component generic
	// @Deprecated
	@Input() set networkCode(networkCode: string) {
		if (networkCode !== undefined) {
			this._networkCode = networkCode;
			this._config = attachmentsConfig[networkCode] || defaultConfig;
		}
	}
	get networkCode() {
		return this._networkCode;
	}
	private _networkCode: string;

	@Input() set config (config: AttachmentsConfig) {
		if (config !== undefined) {
			this._config = config;
		}
	}
	get config() {
		return this._config;
	}
	private _config: AttachmentsConfig;

	@Input('weedOutGif') shouldFilterGif: boolean = true;
	@Input() defaultImage: string = defaultImage;
	@Input('attachmentNgClass') attachmentClasses?: AttachmentClasses;
	@Input() carouselScaling?: boolean;

	private attachmentsContainerMinHeight;
	private attachmentsContainerMaxHeight;
	images: HTMLImageElement[] = [];

	// carousel
	currentSlide = 0;
	private carouselAnimation: AnimationPlayer;

	@Output() isRendered = new EventEmitter<boolean>();
	@Output() onAttachmentClick = new EventEmitter<{ attachments, index }>();

	@ViewChild('attachmentsContainer', { read: ElementRef }) attachmentsContainer: ElementRef;
	@ViewChild('attachmentsContainer', { read: ViewContainerRef }) attachmentsViewContainer: ViewContainerRef;
	@ViewChild('carouselScalingTemplate', { read: TemplateRef }) carouselScalingTemplate: TemplateRef<any>;
	@ViewChild('carouselNavigationTemplate', { read: TemplateRef }) carouselNavigationTemplate: TemplateRef<any>;

	constructor(
		private renderer: Renderer2,
		private animationBuilder: AnimationBuilder,
		private elementRef: ElementRef,
	) {}

	ngOnInit(): void {
		this.isRendered.emit(false);

		if (this.attachments.length) {
			this.getAttachmentsPreview(this.attachments);
		}
	}

	private getAttachmentsPreview(attachments: Attachment[]): void {
		if (
			this.shouldFilterGif &&
			this.config.gifValidationType === 'VIDEO'
		) {
			attachments = this.filterGif(attachments);
		}

		this.loadImages(attachments)
			.then((images) => {
				// console.debug(
				// 	'Images loaded:', images.length,
				// 	'\nMode:', this.config.mode
				// );

				const attachmentsContainerElement = this.attachmentsContainer.nativeElement;

				// container size
				const attachmentsContainerWidth: number = attachmentsContainerElement.offsetWidth;
				this.attachmentsContainerMinHeight = Math.floor(attachmentsContainerWidth * this.config.minHeightRatio);
				this.attachmentsContainerMaxHeight = Math.floor(attachmentsContainerWidth * this.config.maxHeightRatio);

				this.setStyles(attachmentsContainerElement, {
					'min-height': `${this.attachmentsContainerMinHeight}px`,
					'max-height': `${this.attachmentsContainerMaxHeight}px`,
				});

				switch (this.config.mode) {
					case Mode.MASONRY: {
						// grid template
						const {
							grid: [ gridTemplateColumns, gridTemplateRows ],
							ratios: templateRatios,
						} = this.getTemplateConfig(images);

						this.addClasses(attachmentsContainerElement, [ 'masonry' ]);

						this.setStyles(attachmentsContainerElement, {
							'grid-template-columns': gridTemplateColumns === 1
								? 'minmax(0, 1fr)'
								: `repeat(${gridTemplateColumns}, minmax(0, 1fr))`,
							'grid-template-rows': gridTemplateRows === 1
								? `minmax(0, 1fr)`
								: `repeat(${gridTemplateRows}, minmax(0, 1fr))`,
							'grid-gap': '2px',
							// IE
							'-ms-grid-columns': gridTemplateColumns === 1
								? 'minmax(0, 1fr)'
								: Array(gridTemplateColumns).fill('minmax(0, 1fr)').join(' 2px '), // column gap
							'-ms-grid-rows': gridTemplateRows === 1
								? `minmax(0, 1fr)`
								: Array(gridTemplateRows).fill('minmax(0, 1fr)').join(' 2px '), // row gap
						});

						// IE
						const gridTemplate = Array(gridTemplateRows).fill(undefined)
							.map(() => Array(gridTemplateColumns).fill(undefined));
						images.forEach((imageElement: HTMLImageElement, index: number) => {
							const imageContainerElement: HTMLDivElement = this.renderer.createElement('div');
							const [ imageWidthRatio, imageHeightRatio ] = templateRatios[index];
							const imageGridColumns = Math.floor(gridTemplateColumns * imageWidthRatio);
							const imageGridRows = Math.floor(gridTemplateRows * imageHeightRatio);

							this.addClasses(imageContainerElement, [ 'attachments-item', ...this.getAttachmentClasses(attachments[index]) ]);
							this.setStyles(imageContainerElement, {
								'grid-column': `span ${imageGridColumns}`,
								'grid-row': `span ${imageGridRows}`,
							});
							this.setStyles(
								imageElement,
								images.length === 1
									? {
										'min-height': `${this.attachmentsContainerMinHeight}px`,
										'max-height': '100%',
									}
									: {
										'height': '100%',
									}
							);

							// IE
							gridTemplateLoop:
								for (let gridRowIndex = 0; gridRowIndex < gridTemplate.length; gridRowIndex++) {
									const gridRow = gridTemplate[gridRowIndex];
									for (let gridColumnIndex = 0; gridColumnIndex < gridRow.length; gridColumnIndex++) {
										const gridCell = gridRow[gridColumnIndex];
										if (gridCell === undefined) {
											// fill template position
											for (let _gridRowIndex = gridRowIndex; _gridRowIndex < gridRowIndex + imageGridRows; _gridRowIndex++) {
												for (let _gridColumnIndex = gridColumnIndex; _gridColumnIndex < gridColumnIndex + imageGridColumns; _gridColumnIndex++) {
													gridTemplate[_gridRowIndex][_gridColumnIndex] = index;
												}
											}

											const imageGridRow = gridRowIndex + 1;
											const imageGridColumn = gridColumnIndex + 1;
											this.setStyles(imageContainerElement, {
												'-ms-grid-column': imageGridColumn + (imageGridColumn - 1), // grid column with column gap compensation
												'-ms-grid-column-span': imageGridColumns + (imageGridColumns - 1), // grid columns with column gap compensation
												'-ms-grid-row': imageGridRow + (imageGridRow - 1),  // grid row with row gap compensation
												'-ms-grid-row-span': imageGridRows + (imageGridRows - 1),  // grid rows with row gap compensation
											});

											break gridTemplateLoop;
										}
									}
								}

							this.renderer.appendChild(imageContainerElement, imageElement);

							if (
								this.config.excessAllowed
								&& attachments.length > images.length
								&& index === (images.length - 1) // last element
							) {
								const excessElement: HTMLDivElement = this.getExcessElement(attachments, images.length);
								this.renderer.appendChild(imageContainerElement, excessElement);
							}

							this.renderer.appendChild(attachmentsContainerElement, imageContainerElement);
						});

						break;
					}
					case Mode.CAROUSEL: {
						this.addClasses(attachmentsContainerElement, [ 'carousel' ]);

						this.setStyles(attachmentsContainerElement, {
							'grid-template-columns': `repeat(${images.length}, ${attachmentsContainerWidth}px)`,
							'grid-template-rows': 'minmax(0, 1fr)',
							// IE
							'-ms-grid-columns': images.map(() => `${attachmentsContainerWidth}px`).join(' '),
							'-ms-grid-rows': 'minmax(0, 1fr)',
						});

						images.forEach((imageElement: HTMLImageElement, index: number) => {
							const imageContainerElement: HTMLDivElement = this.renderer.createElement('div');
							this.addClasses(imageContainerElement, [ 'attachments-item', ...this.getAttachmentClasses(attachments[index]) ]);
							this.setStyles(imageContainerElement, {
								'-ms-grid-column': index + 1,
							});
							this.setStyles(imageElement, {
								'height': '100%',
							});

							this.renderer.appendChild(imageContainerElement, imageElement);

							this.renderer.appendChild(attachmentsContainerElement, imageContainerElement);
						});

						if (this.carouselScaling) {
							this.attachmentsViewContainer.createEmbeddedView(this.carouselScalingTemplate);
						}

						if (images.length > 1) {
							this.attachmentsViewContainer.createEmbeddedView(this.carouselNavigationTemplate, { $implicit: images });
						}

						break;
					}
				}
			});
	}

	private filterGif(attachments: Attachment[]): Attachment[] {
		const isGif = (attachment: Attachment): boolean => attachment && /\.gif$/i.test(attachment.name);
		if (attachments.every(isGif)) {
			// if only gif files return first
			return attachments.slice(0, 1);
		}
		// TW, LI do not allow to attach files of multiple types to a single post
		return attachments.filter((attachment: Attachment) => !isGif(attachment));
	}

	private loadImages(attachments: Attachment[]): Promise<HTMLImageElement[]> {
		const images$: (Promise<HTMLImageElement>)[] = attachments
			.slice(0, Math.min(attachments.length, this.config.maxImages))
			.reduce<(Promise<HTMLImageElement>)[]>((acc, { imageUrl }, index) => ([
				...acc,
				new Promise<HTMLImageElement>((resolve) => {
					const image: HTMLImageElement = this.renderer.createElement('img');
					image.onload = (): void => {
						// cashed images emit onload immediately (before any subscriptions)
						setTimeout(() => {
							resolve(image);
						});
					};
					image.onerror = (): void => {
						image.src = this.defaultImage;
					};
					if (imageUrl) {
						image.src = imageUrl;
					} else {
						image.src = this.defaultImage;
					}
					image.onclick = (): void => {
						this.onAttachmentClick.emit({ attachments, index });
					};

					// consumed by carousel and test
					this.images.push(image);
				}),
			]), []);
		return Promise.all(images$)
			.finally(() => {
				this.isRendered.emit(true);
			});
	}

	private getAttachmentClasses(attachment: Attachment): string[] {
		if (
			typeof this.attachmentClasses === 'string'
			|| Array.isArray(this.attachmentClasses)
		) {
			return [].concat(this.attachmentClasses);
		}

		const evaluateExpression = (
			expression: AttachmentClassExpression,
			attachment: Attachment,
		): boolean => typeof expression === 'boolean' ? expression : expression(attachment);

		return (
			Object.entries(this.attachmentClasses).reduce((acc, [ className, expression ]) => ([
				...acc,
				...(evaluateExpression(expression, attachment) ? [ className ] : []),
			]), [])
		);
	}

	// masonry
	private getTemplateLayout(images: HTMLImageElement[]): Layout | undefined {
		const imageLayouts: Layout[] = images.map<Layout>(({ width, height }) => (
			width > height ?
				Layout.HORIZONTAL :
				width < height ?
					Layout.VERTICAL :
					Layout.EVEN
		));
		// horizontal images should be stacked vertically, vertical images should be stacked horizontally
		const imageLayoutToTemplateLayout = (imageLayout: Layout): Layout => {
			switch (imageLayout) {
				case Layout.HORIZONTAL:
					return Layout.VERTICAL;
				case Layout.VERTICAL:
					return Layout.HORIZONTAL;
				case Layout.EVEN:
					return Layout.EVEN;
			}
		};
		// TODO: apply getDominant
		return imageLayoutToTemplateLayout(imageLayouts[0]);
	}

	private getTemplateScale(images: HTMLImageElement[]): Scale {
		const imageScales = images.map<Scale>(({ width, height }) => {
			if (!this.config.aspectRatio) {
				return Scale.LARGE;
			}

			const aspectRatio = (
				width > height
					? width / height
					: height / width
			);
			return (
				aspectRatio > this.config.aspectRatio
					? Scale.LARGE
					: Scale.SMALL
			);
		});
		return this.getDominant<Scale>(imageScales);
	}

	private getTemplateConfig(images: HTMLImageElement[]): TemplateConfig {
		const templateLayout: Layout = this.getTemplateLayout(images);
		const templateScale: Scale = this.getTemplateScale(images);
		const defaultTemplateConfig: TemplateConfig = {
			grid: [ 1, 1 ],
			ratios: [ [ 1, 1 ] ],
		};
		return (
			this.config[images.length]?.[templateLayout]?.[templateScale]
			|| this.config[images.length]?.[templateLayout]
			|| this.config[images.length]
			|| defaultTemplateConfig
		);
	}

	private getExcessElement(
		attachments: Attachment[],
		renderedImages: number,
	): HTMLDivElement {
		const excessContainer: HTMLDivElement = this.renderer.createElement('div');
		excessContainer.className = 'excess';
		excessContainer.onclick = (): void => {
			this.onAttachmentClick.emit({ attachments, index: renderedImages - 1 });
		};

		const remainder = attachments.length - renderedImages;
		const excessText: HTMLElement = this.renderer.createText(`+${remainder}`);

		this.renderer.appendChild(
			excessContainer,
			excessText,
		);

		return excessContainer;
	}

	// carousel
	switchCarouselSlide(index: number): void {
		const containerWidth: number = this.elementRef.nativeElement.offsetWidth;
		const offset = index * containerWidth;
		this.carouselAnimation = this.animationBuilder
			.build([
				animate(
					`${Math.abs(this.currentSlide - index) * 200}ms ease-in`,
					style({ transform: `translateX(-${offset}px)` }),
				),
			])
			.create(this.attachmentsContainer.nativeElement);
		this.carouselAnimation.onDone(() => {
			this.currentSlide = index;

			if (this.carouselScaling) {
				this.updateCarouselSizing();
			}
		});

		this.carouselAnimation.play();
	}

	toggleCarouselScaling(): void {
		const currentSlideImage: HTMLImageElement = this.images[this.currentSlide];
		const currentSlideImageContainer: HTMLElement = currentSlideImage.parentElement;
		const isOriginal = currentSlideImageContainer.classList.contains('original');
		if (isOriginal) {
			this.renderer.removeClass(currentSlideImageContainer, 'original');
		} else {
			this.renderer.addClass(currentSlideImageContainer, 'original');
		}

		this.updateCarouselSizing();
	}

	private updateCarouselSizing(): void {
		const currentSlideImage: HTMLImageElement = this.images[this.currentSlide];
		const currentSlideImageContainer: HTMLElement = currentSlideImage.parentElement;
		const attachmentsContainerElement: HTMLElement = this.attachmentsContainer.nativeElement;
		const isOriginal = currentSlideImageContainer.classList.contains('original');

		if (isOriginal) {
			// image
			this.setStyles(currentSlideImage, {
				'height': 'auto',
			});

			// attachments container
			this.setStyles(attachmentsContainerElement, {
				'grid-template-rows': `${currentSlideImage.offsetHeight}px`,
				'-ms-grid-rows': `${currentSlideImage.offsetHeight}px`,
				'min-height': null,
				'max-height': null,
			});
		} else {
			// image
			this.setStyles(currentSlideImage, {
				'height': '100%',
			});

			// attachments container
			this.setStyles(attachmentsContainerElement, {
				'grid-template-rows': 'minmax(0, 1fr)',
				'-ms-grid-rows': 'minmax(0, 1fr)',
				'min-height': `${this.attachmentsContainerMinHeight}px`,
				'max-height': `${this.attachmentsContainerMaxHeight}px`,
			});
		}
	}

	private addClasses(element: HTMLElement, classNames: string[]): void {
		classNames.forEach((name) => {
			this.renderer.addClass(element, name);
		});
	}

	private setStyles(element: HTMLElement, styles: { [property: string]: string | number; }): void {
		Object.entries(styles).forEach(([ property, value ]) => {
			if (value === null) {
				this.renderer.removeStyle(element, property);
			} else {
				this.renderer.setStyle(element, property, value);
			}
		});
	}

	// utils
	private getDominant<T>(data: T[]): T {
		const [ [ dominant ] ] = data.reduce<any>(([ dominant, acc ], item) => {
			acc[item] = acc[item] + 1 || 1;

			if (!dominant || dominant[1] < acc[item]) {
				dominant = [ item, acc[item] ];
			}

			return [ dominant, acc ];
		}, [ undefined, {} ]);
		return dominant;
	}
}
