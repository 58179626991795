import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { PublishingPermissionsModule } from 'tpo/social-compliance/publishing-permissions';

// Services
import { SocialAccountsResourceService } from './social-accounts-resource.service';
import { SocialConnectResourceService } from './company/social-connect-resource.service';

// Components
import { SocialAccountsComponent } from './social-accounts.component';
import {
	SocialAccountsCompanyComponent,
	CompanyMonitoringStatusComponent,
	ManageRSSComponent,
	AddRSSModalComponent,
	EditRSSModalComponent,

} from './company';
import { SocialAccountsIndividualsComponent } from './individuals';
import { SocialAccountsInventoryComponent } from './inventory';
import { ConnectsModalComponent } from './connects-modals/connects-modal.component';
import {
	GMBListingsModalComponent,
	FacebookPagesModalComponent,
	FacebookMortgageRelatedModalComponent,
	LinkedInPagesModalComponent,
} from './connect';
import { ContactSocialAccountsService } from 'tpo/social-accounts/social-accounts.service';
import { SocialMediaModule } from 'shared/social-media';

const components = [
	SocialAccountsComponent,
	SocialAccountsCompanyComponent,
	CompanyMonitoringStatusComponent,
	ManageRSSComponent,
	ConnectsModalComponent,
	FacebookPagesModalComponent,
	FacebookMortgageRelatedModalComponent,
	LinkedInPagesModalComponent,
	GMBListingsModalComponent,
	AddRSSModalComponent,
	EditRSSModalComponent,
	SocialAccountsIndividualsComponent,
	SocialAccountsInventoryComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		TooltipModule.forRoot(),
		DirectivesModule,
		ComponentsModule,
		PipesModule,
		PublishingPermissionsModule,
        SocialMediaModule,
	],
	declarations: [
		...components,
	],
	providers: [
		SocialAccountsResourceService,
		SocialConnectResourceService,
        ContactSocialAccountsService,
	],
	exports: [
		CompanyMonitoringStatusComponent,
		ManageRSSComponent,
	],
})
export class SocialAccountsModule {}
