const TypeNames = {
	GROUP: 'GV',
	USERS: 'AV',
	NONE: 'NV',
};

const ShareToTypes = [{
	id: TypeNames.NONE,
	name: 'None',
	isDefault: true,
}, {
	id: TypeNames.USERS,
	name: 'All Users',
}, {
	id: TypeNames.GROUP,
	name: 'Group',
}];

export { ShareToTypes, TypeNames };
