import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class ApplicationsResourceService {
	public resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');

		const base = {
			url: `${apiPath}/tpo-organization-channels/:id`,
		};

		this.resource = $resource(
			base.url,
			{},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getList = (...args) => this.resource.get(...args)
}
