<client-library-document
	className="lender-client-library-document"
	[document]="document"
	[templateDownloadLink]="templateDownloadLink"
	(upload)="onFileSelect($event)"
	(showHistory)="showHistory()"
>
	<ng-template #indications>
		<span
			*ngIf="document.isActive && document.requestsCount"
			class="label text-smaller clickable"
			[ngClass]="{ 'label-danger-wired': !showRequests, 'label-danger': showRequests }"
			[ngStyle]="{ width: '160px', border: '1px solid' }"
			(mouseover)="toggleShowRequests(true)"
			(mouseout)="toggleShowRequests(false)"
			(click)="showRequestsModal()"
		>
			<ng-container *ngIf="!showRequests">Requested by {{ document.requestsCount | i18nPlural : { '=1': '1 Investor', 'other': '# Investors' } }}</ng-container>
			<ng-container *ngIf="showRequests">Show Investors</ng-container>
		</span>
	</ng-template>

	<ng-template #actions>
		<div class="flex-column right gap-sm">
			<ng-container *ngIf="user.can('TPO_DOCUMENTS') && document.isActive">
				<label class="file-input">
					<span class="btn btn-primary w-full">Upload Document</span>
					<input type="file" [multiple]="false" (input)="onFileSelect($event.target.files)"/>
				</label>
			</ng-container>
		</div>
	</ng-template>
</client-library-document>
