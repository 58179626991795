import { ValidatorFn } from '@angular/forms';

import { RealmFormControl } from '../forms/form-control.hoc';

export function ServerValidator(): ValidatorFn {
	return (control: RealmFormControl): {[key: string]: any} | null => {
		const isValid = !control.serverError;

		return isValid ? null : {
			server: {
				value: control.value,
				message: control.serverError,
			},
		};
	};
}
