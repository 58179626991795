import { Component } from '@angular/core';
import { CrmSettings } from 'comergence/customers/services/customer-services/crm-settings.interface';
import { StateService, UIRouter, UIRouterGlobals } from '@uirouter/core';
import { InvestorMarketingService } from 'comergence/customers/services/customer-services/investor-marketing-service.service';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { ThirdPartyOversightService } from 'comergence/customers/services/customer-services/third-party-oversight.service';
import { TpoServiceInformation } from 'comergence/customers/tpo-services/tpo-service.interface';
import { ConfirmModalComponent } from 'commons/components/modals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CcmServiceStatusService } from '../ccm-service-status.service';


@Component({
    templateUrl: './investor-marketing-status.component.html',
})
export class InvestorMarketingStatusComponent {
    user: UserProfile;
    crmSettings: CrmSettings;
    services: TpoServiceInformation;

    tpoStatusCode: string;
    investorMarketingStatusCode: string;

    organizationId: number;
    resolving: boolean;
    modalRef: BsModalRef;

    isNew: boolean;

    activationStatusMessage: string;
    activationStatus: boolean;

    constructor(
        private routerGlobals: UIRouterGlobals,
        protected router: UIRouter,
        private investorMarketingService: InvestorMarketingService,
        private tpoService: ThirdPartyOversightService,
        private userService: UserService,
        public modalService: BsModalService,
        public stateService: StateService,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
    )
    {
        this.user = this.userService.profile;
        this.organizationId = routerGlobals.params.organizationId;
        this.isNew = stateService.includes('**.services.crm.new');
    }

    async ngOnInit() {
        this.resolving = true;
        try {
            this.crmSettings = await this.investorMarketingService.getCrmSettings(this.organizationId).toPromise();
            this.services = await this.tpoService.getTpoService(this.organizationId).toPromise();
            this.tpoStatusCode = this.services.INVESTOR.statusCode;
            this.investorMarketingStatusCode = this.services.INVESTOR_MARKETING.statusCode;
        } finally {
            this.resolving = false;
        }
    }

    setInvestorMarketingServiceStatus(isActive: boolean) {
        let statusTitle;
        if (isActive) {
            statusTitle = 'Deactivate';
        }
        else {
            statusTitle = 'Activate';
        }
        const initialState = {
            title: `${statusTitle} Investor Marketing`,
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            message: `You are about to ${statusTitle.toLowerCase()} Investor Marketing Service<br>
                     Are you sure you want to proceed?`,
            onConfirm: async (): Promise<void> => {
                this.modalRef.content.resolving = true;
                try {
                    if (isActive) {
                        this.crmSettings = await this.investorMarketingService.deactivateInvestorMarketingService(this.organizationId).toPromise();
                    }
                    else {
                        this.crmSettings = await this.investorMarketingService.activateInvestorMarketingService(this.organizationId).toPromise();
                    }

                    this.ccmServiceStatusService.fetchServiceStatuses();

                    this.modalRef.hide();
                }
                catch ({error: {message}, message: httpError}) {
                    this.modalRef.content.errorText = message;
                }
                finally {
                    this.modalRef.content.resolving = false;
                    if(statusTitle == 'Deactivate') {
                        this.activationStatusMessage = 'Investor Marketing Service was deactivated';
                        this.activationStatus = false;
                    } else {
                        this.activationStatusMessage = 'Investor Marketing Service was reactivated';
                        this.activationStatus = true;
                    }
                }
            },
        };

        this.modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

}
