import { Injectable } from '@angular/core';

import { AbstractIssueStatus } from '../../abstractIssueStatus';
import { Observable } from 'rxjs';
import { HasPastDueInvoiceAlert, SmPackageService } from 'tpo/social-media/sm-package.service';
import { map } from 'rxjs/operators';
import { UserService } from 'angularjs-providers/user.provider';


@Injectable()
export class HasPastDueInvoiceAlertStatus extends AbstractIssueStatus {
    public constructor(
        private readonly smPackageService: SmPackageService,
        private readonly userService: UserService
    ) {
        super();
    }

    get(forceUpdate: boolean = false): Observable<boolean> {
        if (forceUpdate && this._initialized) {
            // reset to false when force update is triggered.
            this.subject.next(false);
        }

        return super.get(forceUpdate);
    }

    // Override
	public async updateValue(): Promise<boolean> {
        const tpoId: number = this.userService.profile.organization.id;

        return this.smPackageService.getHasPastDueInvoiceAlert(tpoId)
            .pipe(map((hasPastDueInvoiceAlert: HasPastDueInvoiceAlert) => hasPastDueInvoiceAlert.hasPastDueInvoice))
            .toPromise();
	}
}
