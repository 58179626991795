import { mapValues, map, reduce } from 'lodash';
import { Component, forwardRef } from '@angular/core';
import {  UIRouter } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list/list.component';

import { Review } from '../reviews.interface';
import { ReviewsResourceService } from '../reviews-resource.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';


@Component({
	templateUrl: './reviews-list.component.html',
	viewProviders: [
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => ReviewsListComponent) },
		// TODO: Used for SearchListComponent, change it's definition once old ListComponent will be removed
		{ provide: ListComponent, useExisting: forwardRef(() => ReviewsListComponent) },
	],
})
export class ReviewsListComponent extends NewPagedListComponent<Review> {
	static listName = 'reviews';
	useLocation = true;
	user: UserProfile;
	modalRef: BsModalRef;
	filters: Record<string, any>;
	filtersLoaded: boolean = false;

	constructor(
		router: UIRouter,
		{ profile }: UserService,
		public modalService: BsModalService,
		public reviewsResource: ReviewsResourceService,
	) {
		super(router);
		this.user = profile;

		this.filters = {
			sources: this.reviewsResource.getSources().pipe(share()),
		};

		this.filters.sources.subscribe({
			next: () => {
				this.filtersLoaded = true;
			},
		});

		this.defaultParams = {
			...this.defaultParams,
			notReplied: false,
			sources: [],
			size: 10,
		};
	}

	setFilter(filters: ListParams, resetPage: boolean = true) {
		const processedFilters = mapValues(filters, (value: any, key: string) => {
			if (key === 'sources') {
				return map(value || [], 'id');
			}

			return value;
		});

		super.setFilter(processedFilters, resetPage);
	}

	getParamsFromSources(queryParams: ListParams) {
		const { sources, ...rest } = queryParams;

		if (!sources || !sources.length) {
			return rest;
		}

		const sourcesNormalized = reduce(sources, (acc, id: string) => {
			const data = this.reviewsResource.decodeReviewSourceId(id);

			if (data.customer) {
				acc.customer.push(data.customer);
			}

			if (data.provider) {
				acc.provider.push(data.provider);
			}

			return acc;
		}, { provider: [], customer: [] });

		return {
			...rest,
			...sourcesNormalized,
		};
	}

	toggleNotRepliedFilter(): void {
		const { notReplied } = this.params$.getValue();
		this.setFilter({ notReplied: !notReplied });
	}

	getReviewType(review: Review): string {
		return review.networkId || review.providerCode;
	}

	updateList(params = this.params): void {
		this.params$.next(params);
	}

	protected loadList(params: ListParams): Observable<PagedData<Review[]>> {
		const normalizedParams = this.getParamsFromSources(params);
		return this.reviewsResource.getList(normalizedParams);
	}
}
