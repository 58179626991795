<form [formGroup]="form" class="remote-resource" [class.resolved]="!resolving" (onValidSubmit)="onSubmit()">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Change Account Assignment</h4>
	</div>
	<div class="modal-body">
		<form-errors [form]="form"></form-errors>

		<p>Please choose new Account Assignment to set for the selected Location:</p>
		<div class="row form-group">
			<div class="col-md-6">
				<ng-select
					formControlName="accountAssigned"
					[multiple]=false
					[items]="accountExecutives.$promise | async"
					[loading]="!accountExecutives.$resolved"
					bindLabel="fullName"
					placeholder="Choose Account Assigned"
				></ng-select>
			</div>
		</div>

		<p>Would you like to change Account Assignment for all related Locations?</p>
		<div class="form-group">
			<label class="radio-inline">
				<input type="radio" formControlName="changeForLocations" [value]="true"> Yes
			</label>
			<label class="radio-inline">
				<input type="radio" formControlName="changeForLocations" [value]="false"> No
			</label>
		</div>
	</div>
	<div class="modal-footer">
		<div class="btn-group">
			<button type="submit" class="btn btn-primary">Save</button>
			<button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
		</div>
	</div>
</form>
