<form [formGroup]="form" (onValidSubmit)="confirmSchedule()">
    <h2 class="flex-row gap">
        <span>Audit Schedule</span>
        <span class="flex-grow text-smaller">
            <toggle
                [ngModel]="settings?.auditSchedule.enabled"
                [ngModelOptions]="{standalone: true}"
                [disabled]="!toggleEnabled || editing"
                [loading]="loading"
                [tooltip]="permissionTooltipText"
                (ngModelChange)="toggleSection($event)"
                (click)="toggleClicked()"
            ></toggle>
        </span>
        <div *ngIf="toggleEnabled" [isEditable]="editing">
            <button
                view
                class="btn btn-primary"
                (click)="toEdit()"
                [disabled]="!settings?.auditSchedule.enabled"
                type="button"
            >Edit</button>
            <ng-container edit>
                <button
                    class="btn btn-default"
                    type="button"
                    (click)="settings.auditSchedule.enabled = initialAuditSchedule; cancelEdit()"
                >Cancel</button>
                <button class="btn btn-primary" type="submit">Save</button>
            </ng-container>
        </div>
    </h2>

    <form-errors [form]="form"></form-errors>

    <div
        class="rows-striped remote-resource"
        [ngClass]="{ curtain: !settings?.auditSchedule.enabled, resolved: !loading }"
    >
        <div class="rows-striped">
            <div class="row-fluid" [ngSwitch]="editing">
                <div class="col-md-5 form-group" has-error>
                    <div class="row-fluid">
                        <label class="col-md-5 control-label" [class.required]="editing">Random Selection</label>
                        <div class="col-md-7 control-value" *ngSwitchDefault>
                            <b>{{settings?.auditSchedule.auditScheduleSettings?.amount || 0}}{{settings?.auditSchedule.auditScheduleSettings?.amountType.code == 'P' ? '%' : ''}}</b>
                            of all Employees Randomly
                        </div>
                        <div class="col-md-7" *ngSwitchCase="true">
                            <span class="flex-row gap">
                                <input
                                    formControlName="amount"
                                    class="form-control flex-grow"
                                    [validFloat]="amountSettings.format"
                                    [maxlength]="amountSettings.length"
                                    tabindex="1"
                                >

                                <ng-select
                                    class="flex-grow"
                                    [items]="values.amountType | async"
                                    [loading]="!(values.amountType | async)"
                                    bindLabel="label"
                                    [clearable]="false"
                                    formControlName="amountType"
                                    tabindex=1
                                ></ng-select>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5 form-group" has-error>
                    <div class="row-fluid">
                        <label class="col-md-5 control-label" [class.required]="editing">Order Date</label>
                        <div class="col-md-7">
                            <span *ngSwitchDefault class="control-value" [ngSwitch]="settings?.auditSchedule.enabled">
                                <span *ngSwitchCase="true">
                                    {{settings?.auditSchedule.auditScheduleSettings?.nextOrderDate | date: dateFormat}}
                                </span>
                                <span *ngSwitchDefault>&ndash;</span>
                            </span>
                            <input
                                *ngSwitchCase="true"
                                tabindex="1"
                                class="form-control"
                                formControlName="nextOrderDate"
                                required
                                bsDatepicker
                                [bsConfig]="{dateInputFormat: mDateFormat}"
                                [minDate]="minDate"
                                [placeholder]="mDateFormat"
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>

            <div class="row-fluid" [ngSwitch]="editing">
                <div class="col-md-5 form-group">
                    <div class="row-fluid">
                        <label class="col-md-5 control-label" [class.required]="editing">Schedule Frequency</label>
                        <div class="col-md-7">
                            <span class="control-value" *ngSwitchDefault>
                               {{settings?.auditSchedule.auditScheduleSettings?.frequency.label || '&ndash;'}}
                            </span>
                            <ng-select *ngSwitchCase="true"
                                       class="flex-grow"
                                       [items]="values.frequency | async"
                                       [loading]="!(values.frequency | async)"
                                       bindLabel="label"
                                       [clearable]="false"
                                       formControlName="frequency"
                                       tabindex=1>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5 form-group" has-error>
                    <div class="row-fluid">
                        <label class="col-md-5 control-label">Estimated Amount</label>
                        <div class="col-md-7 control-value">
                            <ng-container *ngIf="(price | async) as currentPrice">
                                <ng-container [ngSwitch]="currentPrice?.invoicing">
                                    <b *ngSwitchDefault>{{( currentPrice.price || 0 ) | currency}}</b>
                                    <span *ngSwitchCase="true">Contracted</span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>

            <div class="row-fluid" [ngSwitch]="editing">
                <div class="col-md-5 form-group">
                    <div class="row-fluid">
                        <label class="col-md-5 control-label" [class.required]="editing">Exclusion Period</label>
                        <div class="col-md-7">
                            <span class="control-value" *ngSwitchDefault>
                               {{settings?.auditSchedule.auditScheduleSettings?.reviewFrequency.label || '&ndash;'}}
                            </span>
                            <ng-select
                                *ngSwitchCase="true"
                                class="flex-grow"
                                [items]="values.reviewFrequency | async"
                                [loading]="!(values.reviewFrequency | async)"
                                bindLabel="label"
                                [clearable]="false"
                                formControlName="reviewFrequency"
                                tabindex=1
                            ></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-7"></div>
            </div>
        </div>
    </div>
</form>
