import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';

import { CommonInvestorCommentsService } from './services/common-investor-comments.service';


@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
	],
	providers: [
		CommonInvestorCommentsService,
	],
})
export class InvestorCommonModule {}
