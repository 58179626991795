<h2 *ngIf="newMenu">Company</h2>
<div class="social-accounts-company remote-resource" [class.resolved]="list?.$resolved && !connecting">
	<div class="flex-row gap mb20">
		<segmented-controls
			[items]="availableStates"
			[value]="selectedState"
			(selected)="changeState({ state: $event })"
		></segmented-controls>

		<div class="w250">
			<search-list placeholder="Account Name, Network, URL"></search-list>
		</div>

		<div class="right" *ngIf="canManage">
			<button type="button" class="btn btn-secondary"
				(click)="newSocialMediaLink.toggleShow(true)"
				*ngIf="!newSocialMediaLink.show"
			>
				<span class="glyphicon glyphicon-plus"></span>
				Add Link
			</button>
		</div>
	</div>

	<notification *ngIf="isNotificationSet"></notification>

	<div class="clearfix mb20" [hidden]="!newSocialMediaLink.show">
		<new-sm-link #newSocialMediaLink
			[save]="addLink"
		></new-sm-link>
	</div>

	<div>
		<div class="rows-bordered">
			<div class="row-fluid header">
				<sortable-header [ngClass]="columnClass.account" columnName="account">Account</sortable-header>
				<sortable-header [ngClass]="columnClass.lastConnectionStatus" columnName="lastConnectionStatus" *ngIf="!isLender && !isRemoved"><span class="two-liners">Status</span></sortable-header>
				<div [ngClass]="columnClass.rss" *ngIf="isComergence && !isRemoved"><b>RSS</b></div>
				<div [ngClass]="columnClass.actions"><b>&nbsp;</b></div>
			</div>

			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
			</div>

			<div class="row-fluid" [class.danger]="!isRemoved && socialAccountLink?.ui?.isDangerClass" *ngFor="let socialAccountLink of list">
				<div class="va-m" [ngClass]="columnClass.account">
					<div class="flex-row gap">
						<sm-icon
							[config]="socialAccountLink?.data"
							[showRssStatus]="(isSA || isWP) && (socialAccountLink?.data?.monitoringStatus === 'on' || socialAccountLink?.data?.rssLink)"
						></sm-icon>
						<div class="flex text-overflow">
							<ng-container *ngIf="socialAccountLink?.data?.networkCode !== 'RS' && socialAccountLink?.data?.link; then companyLinkAnchor; else companyLinkName"></ng-container>
							<ng-template #companyLinkAnchor>
								<a [href]="socialAccountLink?.data?.link | hrefReady" target="_blank">{{socialAccountLink?.data?.name}}</a>
							</ng-template>
							<ng-template #companyLinkName>
								{{socialAccountLink?.data?.name}}
							</ng-template>
						</div>
					</div>
				</div>
				<div class="va-m" [ngClass]="columnClass.lastConnectionStatus"  *ngIf="!isLender && !isRemoved">
					<company-monitoring-status
						[status]="socialAccountLink?.data?.lastConnectionStatus"
						[isRemovalRequested]="socialAccountLink?.data?.isRemovalRequested"
					></company-monitoring-status>
				</div>
				<div class="va-m" [ngClass]="columnClass.rss" *ngIf="isComergence && !isRemoved">
					<manage-rss
						[params]="socialMediaServiceParams"
						[link]="socialAccountLink"
						[canManage]="canManageRss"
						(reload)="loadList(params)"
					></manage-rss>
				</div>
				<div class="va-m" [ngClass]="columnClass.actions">
					<div *ngIf="isSA" class="flex-row right gap">
						<a class="btn btn-primary"
						   (click)="reconnect(socialAccountLink?.data)"
						   *ngIf="
								isTpo &&
								canManage &&
								allowConnectionStatusCheck(socialAccountLink?.data) &&
								socialAccountLink?.data?.lastConnectionStatus === 'DISCONNECTED' &&
								!socialAccountLink?.data?.isRemovalRequested
							"
						>
							Reconnect
						</a>

						<span class="button icon-red solid"
						      *ngIf="
								allowConnectionStatusCheck(socialAccountLink?.data) &&
								socialAccountLink?.data?.lastConnectionStatus === 'CONNECTED' &&
								!socialAccountLink?.data?.lastPermissionsSufficient &&
								!User?.isLender
							"
						>
							Fix Permissions
						</span>

						<div *ngIf="socialAccountLink?.data?.isRemovedFromSourceSystem && !User?.isLender" class="medium-icon-holder">
							<span class="glyphicon medium-icon text-danger glyphicon-remove-sign va-m"
							      tooltip="Removed from {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}}"
							></span> {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}} Removed
						</div>

						<ng-container
							*ngIf="isComergence && canManage && socialAccountLink.data?.isRemovalRequested"
							[ngTemplateOutlet]="manageLinkControls"
							[ngTemplateOutletContext]="{ link: socialAccountLink }"
						></ng-container>

						<ng-container
								*ngIf="socialAccountLink?.data?.publisherStatus === 'A'
								|| canManage && socialAccountLink?.ui?.hasActionDropdown
								|| (socialAccountLink?.data?.networkCode === 'YT' && socialAccountLink?.data?.linkOwner); then dropdownActive; else dropdownDisabled">
						</ng-container>
						<ng-template #dropdownActive>
							<div class="dropdown" dropdown>
								<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
									<span class="c-icon c-icon-dot-3"></span>
								</a>
								<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
									<li role="menuitem" *ngIf="canManage && socialAccountLink?.ui?.hasActionDropdown">
										<a (click)="removeLink(socialAccountLink)">Remove</a>
									</li>
									<li role="menuitem" *ngIf="socialAccountLink?.data?.publisherStatus === 'A'">
										<a (click)="removeCurrentPublisherAccess(socialAccountLink)">Remove Access</a>
									</li>
									<li role="menuitem"
										*ngIf="socialAccountLink?.data?.networkCode === 'YT' && socialAccountLink?.data?.linkOwner">
										<a href="https://myaccount.google.com/permissions?pli=1" target="_blank">
											Revoke Access
										</a>
									</li>
								</ul>
							</div>
						</ng-template>
						<ng-template #dropdownDisabled>
							<span class="btn btn-icon btn-icon-disabled"></span>
						</ng-template>
					</div>

					<div *ngIf="isWP" class="flex-row right gap">
						<div class="medium-icon-holder" *ngIf="socialAccountLink?.data?.isRemovedFromSourceSystem && !User?.isLender">
							<span class="glyphicon medium-icon text-danger glyphicon-remove-sign va-m"
							      tooltip="Removed from {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}}"
							></span> {{socialAccountLink?.data?.sourceSystemCode || 'NMLS'}} Removed
						</div>

						<ng-container
							*ngIf="isComergence && canManage && socialAccountLink.data?.isRemovalRequested"
							[ngTemplateOutlet]="manageLinkControls"
							[ngTemplateOutletContext]="{ link: socialAccountLink }"
						></ng-container>

						<ng-container *ngIf="canManage && socialAccountLink?.ui?.hasActionDropdown; then dropdownActive; else dropdownDisabled"></ng-container>
						<ng-template #dropdownActive>
							<div class="dropdown" dropdown>
								<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
									<span class="c-icon c-icon-dot-3"></span>
								</a>
								<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
									<li role="menuitem">
										<a (click)="removeLink(socialAccountLink)">Remove</a>
									</li>
								</ul>
							</div>
						</ng-template>
						<ng-template #dropdownDisabled>
							<span class="btn btn-icon btn-icon-disabled"></span>
						</ng-template>
					</div>

					<div class="flex-row right gap" *ngIf="isRemoved && canManage">
						<div class="dropdown" dropdown>
							<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
								<span class="c-icon c-icon-dot-3"></span>
							</a>
							<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
								<li role="menuitem">
									<a (click)="restoreLink(socialAccountLink)">Restore</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="row-fluid" *ngIf="list.$resolved && !list.length">
				<span class="col-sm-12 text-center text-bigger text-muted va-m">
					{{filtersApplied ? 'No accounts found' : 'No accounts connected'}}
				</span>
			</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>

<ng-template #manageLinkControls let-link="link">
	<div class="flex-row right gap">
		<button type="button" class="btn btn-danger" (click)="manageLink(link, 'acceptRemoval')">Remove</button>
		<button type="button" class="btn btn-secondary" (click)="manageLink(link, 'declineRemoval')">Keep</button>
	</div>
</ng-template>
