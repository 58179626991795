import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { UserService } from 'angularjs-providers/user.provider';
import { QuestionnaireStatus } from 'shared/questionnaires/questionnaires.interface';

declare let apiPath: string;

export type TpoQuestionnaire = {
    id: number;
    questionnaireAssignmentId: number;
    applicationId?: number;
    name: string;
    statusDate: number;
    cancelable: false;
    questionnaireId: number;
    lender: {
        lenderId: number;
    };
    status: {
        id: QuestionnaireStatus;
        name: string;
    };
}

export type TpoQuestionnaireResult = {
    requestedDocumentsCount: number,
}

export enum QUESTION_TYPE {
    YES_NO = 'YN',
    PICK_LIST = 'PL',
    MULTISELECT = 'MS',
    FREE_FORM = 'FF',
    NUMBER = 'NUM',
    DOLLAR = 'USD',
    PERCENT = 'PCT',
    DATE = 'DT',
}

export type TpoQuestionnaireQuestionAnswer = {
    id: number;
    text: string;
    explanationRequired: boolean;
    nextQuestionId: number;
}

export type TpoQuestionnaireQuestion = {
    id: number;
    text: string;
    answer: string;
    explanation: string;
    displaySequence: number;
    lastModifiedDate: number;
    questionType: QUESTION_TYPE;
    useSkipLogic: boolean;
    nextQuestionId: number;
    selectedAnswerId: number;
    questionBankQuestionId: number;
    answers: TpoQuestionnaireQuestionAnswer[];
    group: boolean;
}


@Injectable()
export class TpoQuestionnaireService {
    constructor(
        private http: RealmHttpClient,
        private user: UserService,
    ) {
    }

    get tpoId(): number {
        return this.user.profile.organization.id;
    }

    getQuestionnaire<T extends TpoQuestionnaire = TpoQuestionnaire>(questionnaireId: number): Observable<T> {
        const { tpoId } = this;
        return this.http.request<T>(
            'GET',
            `${apiPath}/tpos/questionnaires/${questionnaireId}`,
            { tpoId },
        );
    }

    saveQuestionnaire<T extends TpoQuestionnaireResult = TpoQuestionnaireResult>(questionnaireId: number, questions: TpoQuestionnaireQuestion[]): Observable<T> {
        const { tpoId } = this;
        return this.http.request<T>(
            'PUT',
            `${apiPath}/tpos/questionnaires/${questionnaireId}/verification`,
            { tpoId },
            questions,
        );
    }

    getQuestions<T extends TpoQuestionnaireQuestion[] = TpoQuestionnaireQuestion[]>(questionnaireId: number): Observable<T> {
        const { tpoId } = this;
        return this.http.request<T>(
            'GET',
            `${apiPath}/tpos/questionnaires/${questionnaireId}/questions`,
            { tpoId },
        );
    }

    setQuestionAnswer<T extends TpoQuestionnaireQuestion = TpoQuestionnaireQuestion>(questionnaireId: number, questionId: number, body: TpoQuestionnaireQuestion): Observable<T> {
        const { tpoId } = this;
        return this.http.request<T>(
            'PUT',
            `${apiPath}/tpos/questionnaires/${questionnaireId}/questions/${questionId}`,
            { tpoId },
            body,
        );
    }
}
