<div>
	<div class="dynamic-content-selector">
		<div class="dropdown" dropdown>
			<span class="text-link" dropdownToggle>
				Dynamic Content&nbsp;
				<span class="c-icon c-icon-triangle-down"></span>
			</span>
			<ul *dropdownMenu class="dropdown-menu">
				<li *ngFor="let field of dynamicFields">
					<a class="link-option" (click)="insertDynamicField(field.placeholder)">
						{{field.label}}
					</a>
				</li>
			</ul>
		</div>
	</div>
	<textarea
		#templateTextEditElement
		class="form-control resize-vertical"
		rows="4"
		maxlength="2000"
		placeholder="Enter Post"
		name="template-text-edit"
		[value]="value"
		(input)="setText($event.target.value)"
		(keyup)="trackCaret($event.target.selectionStart)"
		(mouseup)="trackCaret(templateTextEditElement.selectionStart)"
	></textarea>
</div>
