import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ClientDocumentsModule } from 'lender/clients';
import { DbaService } from 'shared/account/dba/dba.service';
import { DbaListComponent } from 'shared/account/dba/dba-list.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        FormsModule,
        ClientDocumentsModule,
        PipesModule,
    ],
    declarations: [
        DbaListComponent,
    ],
    providers: [
        DbaService,
    ],
    exports: [
        DbaListComponent,
    ],
})
export class SharedDbaModule {
}
