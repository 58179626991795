import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';

import { PagedData } from 'commons/services/http';
import {
    GenericHistoryStrategy,
} from 'commons/components/history/generic-history-entry.interface';

import { BulkRequestSnapshotService } from 'commons/services/financials/bulk-request-snapshot.service';
import {
    ChannelBulkRequestModalComponent,
} from 'shared/document-schedule-settings-card/card/channels/bulk-request-modal/channel-bulk-request-modal.component';
import { FinancialsManagementHistoryService } from 'shared/financial-documents/services/financials-management-history.service';

import { ChannelHistory } from 'lender/manage-channels/manage-channel-card.interface';


@Component({
    templateUrl: 'financials-management-history.component.html',
})
export class FinancialsManagementHistoryComponent implements OnInit {
    static LIST_NAME: string = 'financialsManagementHistory';
    listName: string = FinancialsManagementHistoryComponent.LIST_NAME;

    @ViewChild('entryTemplate', { static: true }) historyEntryTemplate: TemplateRef<any>;

    documentTypeId: number;
    investorId: number;

    bsModalRef: BsModalRef;

    public historyStrategy: GenericHistoryStrategy;

    constructor(
        router: UIRouter,
        private readonly bulkRequestSnapshotService: BulkRequestSnapshotService,
        private readonly financialsManagementHistoryService: FinancialsManagementHistoryService,
        private readonly modalService: BsModalService,
        private readonly userService: UserService,
    ) {
        this.documentTypeId = router.globals.params.documentTypeId;
        this.investorId = this.userService.profile.organization.id;
    }

    ngOnInit() {
        this.historyStrategy = {
            getHistoryItems: this.getHistoryItems,
            historyEntryTemplate: this.historyEntryTemplate,
        };
    }

    getHistoryItems = (params): Observable<PagedData<ChannelHistory[]>> => {
        return this.financialsManagementHistoryService.getFinancialsManagementHistory(this.investorId, this.documentTypeId, params);
    };

    async openModal(bulkRequestId: number): Promise<void> {
        const bulkRequest = await this.bulkRequestSnapshotService.getBulkRequestSnapshot(this.investorId, bulkRequestId).toPromise();

        this.bsModalRef = this.modalService.show(ChannelBulkRequestModalComponent, {
            initialState: { bulkRequest: bulkRequest, hasSnapshot: true },
            class: 'modal-new modal-smd',
        });
    }
}
