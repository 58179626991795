import { Injectable } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';

import { ReviewWidgetResourceService } from './widgets-resource.service';

@Injectable()
export class ReviewWidgetService {
	constructor(
		public widgetResource: ReviewWidgetResourceService,
		public User: UserService,
	) {}

	list = (queryParams?): NgResourceArray<Widget> => this.widgetResource.widgets.query(queryParams);
	sources = (queryParams?): NgResourceArray<Source> => this.widgetResource.widgets.sources(queryParams);
	widget = (widgetId: number): NgResourceObject<Widget> => this.widgetResource.widget.get({ widgetId });
	widgetSources = (queryParams?): NgResourceArray<Source> => this.widgetResource.widget.sources(queryParams);
	widgetSourcesAll = (queryParams?): NgResourceArray<Source> => this.widgetResource.widget.sourcesAll(queryParams);
	save = (dto: Widget, success?, error?): NgResourceArray<Source> => (
		this.widgetResource.widgets.save(dto, success, error)
	);
	update = (dto: Widget, success?, error?): NgResourceArray<Source> => (
		this.widgetResource.widget.update(dto, success, error)
	);
	remove = (widgetId: number, success?, error?): any => (
		this.widgetResource.widget.remove({ widgetId }, success, error)
	);
	widgetsAccountTypes = (): NgResourceObject<AccountType> => this.widgetResource.widgets.accountTypes();
	widgetAccountTypes = (widgetId: number): NgResourceObject<AccountType> => this.widgetResource.widget.accountTypes({ widgetId });
}

export interface Widget {
	createdBy?: string;
	name: string;
	hash?: string;
	widgetId?: number;
	showLast?: number;
	sources?: Array<Source>,
}

export interface Source {
	mediaLinkId: number;
	linkText: string;
	individualNMLSId: number;
	companyNMLSId: number;
	name: string;
	networkCode: string;
	profilePictureUrl: string;
	compositeId: string;
	isSelected: boolean;
	providerName: string;
}

export interface AccountType {
	customerId: number;
	displayName: string;
	type: string;
}
