import { Component, HostBinding, ElementRef, ViewChild, OnDestroy, AfterViewInit, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';

import { Dimension, NgResource } from 'commons/interfaces';

import { FindingsService } from '../../findings.service';
import { FindingDetail, Issue } from '../../$findingId/finding.interface';
import { FindingModelService } from '../finding-model.service';

declare let apiPath: string;
declare let window: Window;

@Component({
	selector: 'finding-screenshot',
	templateUrl: './finding-screenshot.component.html',
	host: {
		'(window:resize)': 'onResize()',
	},
})
export class FindingScreenshotComponent implements OnInit, AfterViewInit, OnDestroy {
	zoom = true;
	issues: Issue[] = [];

	public left = 0;
	public scale = 1;
	public zoneSize: Dimension = null;
	public imageSize: Dimension = null;

	focusSubscription: Subscription;
	selectedIssue: Issue;

	@Input() finding: NgResource<FindingDetail>;
	@Input() screenshot?: string;
	@Input() allowZoom? = true;

	@HostBinding('class.remote-resource') true;
	@HostBinding('class.editable') canManage: boolean = false;
	@HostBinding('class.resolved') loaded: boolean = false;
	@ViewChild('image') image: ElementRef;

	constructor(
		public element: ElementRef,
		public userService: UserService,
		public findingModelService: FindingModelService,
		public findingsService: FindingsService,
	) {}

	ngOnInit(): void {
		this.issues = this.finding.issues;

		// No need to wait for finding, because this component is shown only if finding has a screenshot
		const { id: findingId } = this.finding;
		const User = this.userService.profile;
		const {
			isTpo,
			isPublicAccess,
			isComergence,
			organization: {
				id: tpoId,
			},
		} = User;
		const { accessToken } = window;
		const authParam = isPublicAccess ? `?realmLimitedAccessToken=${accessToken}` : '';

		this.screenshot ||= `${apiPath}${isTpo || isPublicAccess ? `/tpos/${tpoId}` : ``}/social-media-compliance/finding/${findingId}/screen${authParam}`;

		this.focusSubscription = this.findingModelService.issueInFocus$.subscribe((selectedIssue) => {
			this.selectedIssue = selectedIssue;
		});

		this.finding
			.$promise
			.then(() => {
				const isMonitoringFinding = !this.finding.reviewId;
				const reviewFinding = this.finding.reviewId && this.finding.reviewAuditStatus.condition !== 'C';

				this.canManage = (
					(isComergence && User.can('CCM_SMC_VIEW_ALL'))
					||
					(isTpo && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') && (isMonitoringFinding || reviewFinding))
				);
			});
	}

	ngOnDestroy(): void {
		this.focusSubscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		const imageEl = this.image.nativeElement;
		// waiting for image to load;
		const intervalPointer = setInterval(() => {
			if (imageEl.offsetWidth && imageEl.naturalHeight) {
				clearInterval(intervalPointer);
				this.recalculate();
			}
		}, 10);
	}

	toggleZoom() {
		this.zoom = !this.zoom;
		setTimeout(() => {
			this.recalculate();
		}, 0);
	}

	load() {
		this.loaded = true;
		this.findingModelService.onImageLoaded$.next(true);
	}

	recalculate() {
		this.setZoneSize();
		this.calculateScale();
	}

	setZoneSize() {
		const componentEl = this.element.nativeElement;
		const imageEl = this.image.nativeElement;
		setTimeout(() => {
			this.left = imageEl.offsetLeft;
		}, 0);
		this.imageSize = { width: imageEl.naturalWidth, height: imageEl.naturalHeight };
		this.zoneSize = { width: componentEl.clientWidth, height: componentEl.offsetHeight };
	}

	onResize() {
		this.recalculate();
	}

	calculateScale() {
		if (!this.imageSize) {
			this.scale = 1;
		} else if (!this.zoom) {
			this.scale = Math.min(1, this.zoneSize.width / this.imageSize.width, this.zoneSize.height / this.imageSize.height);
		} else {
			this.scale = Math.min(1, this.zoneSize.width / this.imageSize.width);
		}
	}

	selectIssue(issue: Issue) {
		const User = this.userService.profile;
		if (
			(User.can('CCM_SMC_VIEW_ALL') || User.can('TPO_SMC_VIEW_ALL'))
			&& !this.findingModelService.isEditingIssue
			&& !this.userService.profile.isPublicAccess
		) {
			this.findingModelService.onHighlightIssue$.next(issue);
		}
	}

	addIssue(event: MouseEvent) {
		if (this.canManage) {
			const coords = {
				screenPosX: Math.floor(event.offsetX / this.scale),
				screenPosY: Math.floor(event.offsetY / this.scale),
			};
			this.findingModelService.onAddIssue$.next(coords);
		}
	}
}
