import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

// TODO: rename to reflect actual functionality
@Pipe({
	name: 'urlSanitizer',
})
export class UrlSanitizer implements PipeTransform {
	constructor(
		public sanitizer: DomSanitizer
	) {}

	transform(value: string | null | undefined): SafeStyle | null {
		if (!value) {
			return null;
		}

		return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);
	}
}
