import {Component, OnDestroy} from '@angular/core';
import {StateService} from '@uirouter/core';
import { identity, Subject, combineLatest } from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import {TpoIssuesStatusService} from 'tpo/status';
import {NavigationMenuItem} from 'utils/navigation/navigation.interface';

// TODO: extract common class
@Component({
	templateUrl: './social-compliance.component.html',
})
export class SocialComplianceComponent implements OnDestroy {
	items: NavigationMenuItem[];
	User: UserProfile;
	newMenu: boolean;
	unsubscribe$ = new Subject<void>();

	constructor(
		public stateService: StateService,
		public userService: UserService,
		public readonly tpoIssuesStatusService: TpoIssuesStatusService,
	) {
		this.User = userService.profile;
		this.newMenu = this.User.isTpo;

		// Display new menu only for Lender
		if (!this.newMenu) {
			this.fillItems();
		}
	}

	fillItems(): void {
		this.items = [
			...(this.User.isTpo ? [{
				sref: '.get-started',
				title: 'Get Started',
			}] : []),
			...(this.User.smGlobalArchiveEnabled ? [{
				sref: '.archive',
				title: 'Archive',
			}] : []),
			{
				sref: '.reviews',
				title: 'Audits',
				isActive: () => (
					this.stateService.includes('**.reviews.**')
				),
			},
			{
				sref: '.orders',
				title: 'Orders',
				isActive: () => (
					this.stateService.includes('**.orders.**')
					&& !this.stateService.includes('**.reviews.**')
				),
			},
			...(this.User.isTpo ? [
				{
					sref: '.tasks',
					title: 'Tasks',
				},
				{
					sref: '.monitoring-board',
					title: 'Findings',
				},
				{
					sref: '.settings',
					title: 'Settings',
					isActive: () => (
						this.stateService.includes('**.settings')
						|| this.stateService.includes('**.compliance-schedule')
						|| this.stateService.includes('**.monitoring-settings.**')
						|| this.stateService.includes('**.triggers.**')
						|| this.stateService.includes('**.audit-checklist.**')
					),
					hasAlert:
						combineLatest([this.tpoIssuesStatusService.socialMedia.socialCompliance.schedule.get()])
							.pipe(
								map((results: boolean[]) => results.some(identity)),
								takeUntil(this.unsubscribe$),
							),
					items: [
						{
							sref: '.compliance-schedule',
							title: 'Compliance Schedule',
							hasAlert: this.tpoIssuesStatusService.socialMedia.socialCompliance.schedule.get(),
						},
						{
							sref: '.monitoring-settings',
							title: 'Monitoring Settings',
						},
						{
							sref: '.triggers',
							title: 'Keyword Triggers',
						},
						{
							sref: '.audit-checklist',
							title: 'Audit Checklist',
						},
					],
				},
			] : []),
			...(this.User.isComergence ? [
				{
					sref: '.triggers',
					title: 'Triggers',
				},
			] : []),
		];
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
