<div *ngIf="!isAddingCompany">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">Update Ownership</h4>
	</div>
	<form [formGroup]="ownershipForm" (onValidSubmit)="save()" id="OwnershipForm">
		<div class="modal-body remote-resource" [class.resolved]="resolved">
			<div class="well text-bigger">
				You must first add the contact and grant them access in order to update your ownership records.
				Once completed, select 'Add Individual' to add contacts with ownership interest.
			</div>

			<form-errors [form]="ownershipForm"></form-errors>

			<div class="rows-bordered ownership-actions">
				<div class="row-fluid">
					<div class="col-sm-12 text-right">
						<button type="button" class="btn btn-primary" [disabled]="isAddingIndividual" (click)="showAddIndividual()" tabindex=1><span
							class="glyphicon glyphicon-plus small-icon"></span> Add Individual
						</button>
						<button type="button" class="btn btn-primary" (click)="showAddCompany()" tabindex=2><span
							class="glyphicon glyphicon-plus small-icon"></span> Add Company
						</button>
					</div>
				</div>
			</div>
			<div class="rows-bordered max-h300 ownership-update-list" #ownershipList>
				<div class="row"
				     *ngFor="let item of newOwners; let i = index;"
				     [class.ssn-verify-owner]="(item.contactType == 'individual' && !item.isSSNVerified && item.email == User.email)"
				     [ngSwitch]="item.contactType">
					<span class="col-sm-6" *ngSwitchDefault>
						<span>{{item.firstName}} {{item.lastName}}</span><br>
						<span class="text-light text-smaller">{{item.officerDesignation?.join(', ')}}</span>
					</span>
					<span class="col-sm-6" *ngSwitchCase="'company'">
						<span>{{item.companyName}}</span><br>
						<span class="text-light text-smaller">{{ ([item.address, item.city, item.state, item.zip] | arrayFilter).join(', ') }}</span>
					</span>
					<span class="col-sm-6 text-center">
						<div class="form-group row" [class.has-error]="ownershipForm.get([i, 'ownershipPercentage']).invalid">
							<div class="col-sm-6 text-right">
								<a *ngIf="item.$isNew" class="control-value" (click)="remove(i)">Remove</a>
							</div>
							<label class="col-sm-3 control-label text-right">Ownership</label>
							<div class="col-sm-3">
								<div class="input-group">
									<input class="form-control" [formControl]="ownershipForm.get([i, 'ownershipPercentage'])" validFloat="1.0-1" maxlength="4" tabindex=3>
									<span class="input-group-addon">%</span>
								</div>
							</div>
						</div>
					</span>
					<div class="col-sm-12 ssn-warning " *ngIf="item.requestSsn">
                        <div class="form-group row has-feedback">
                            <label class="col-sm-5 control-label" [class.required]="accountCompanyInfo.ownership99PercentRequired">Enter your SSN to verify ownership</label>
                            <div class="col-sm-4" [class.has-error]="ownershipForm.get([i, 'ssn']).touched && ownershipForm.get([i, 'ssn']).invalid">
                                <input [type]="showSSN ? 'text' : 'password'" class="form-control" [formControl]="ownershipForm.get([i,'ssn'])"/>
                                <span class="c-icon c-icon-eye form-control-feedback" (click)="toggleSSN()"></span>
                            </div>
                            <span class="glyphicon glyphicon-info-sign clickable" (click)="showWarningInfo()"></span>
                        </div>
						<p *ngIf="showInfo">
							In connection with your registration application to participate in the Optimal Blue network of residential
							mortgage loan originators and service providers, Optimal Blue LLC ("Optimal Blue") performs background
							checks to comply with the Consumer Financial Protection Bureau's expectation that residential mortgage
							loan originators and their service providers take steps to ensure their compliance with Federal consumer
							financial law to protect the interests of consumers, and avoid consumer harm. By clicking the background
							authorization acceptance box in your application profile, you hereby authorize Optimal Blue to conduct
							background due diligence to share such information with residential mortgage loan originators and service
							providers you designate. Your background information to be obtained may include: professional history
							information, criminal record information, other public record information, and may be obtained through
							third party sources. A credit report is NOT included in the Optimal Blue due diligence process unless
							Optimal Blue separately obtains your consent to obtain your credit report. Optimal Blue will treat any
							information obtained through its due diligence process as confidential and will only share it as set forth
							in this authorization, in the Optimal Blue Privacy Policy and in any additional arrangements you may enter
							into with Optimal Blue.
						</p>
					</div>
				</div>
				<div class="row-fluid" *ngIf="!newOwners.length">
					<span class="col-sm-12 text-muted text-center text-bigger">No records added</span>
				</div>
			</div>
			<div class="rows-bordered ownership-update-list" *ngIf="isAddingIndividual">
				<div class="row-fluid">
					<div *ngIf="nonOwnersList.length">
						<span class="col-sm-6">
							<ng-select
								(change)="selectIndividual($event.contactId)"
								[items]="nonOwnersList"
								bindLabel="name"
								[clearable]="false"
								placeholder="Select Individual">
							</ng-select>
						</span>
						<span class="col-sm-6 ">
							<div class="form-group row">
								<div class="col-sm-6 text-right">
									<a (click)="cancelAdd()" class="control-value" tabindex=4>Cancel</a>
								</div>
								<label class="col-sm-3 control-label text-right">Ownership</label>
								<div class="col-sm-3">
									<div class="input-group">
										<input class="form-control" disabled>
										<span class="input-group-addon">%</span>
									</div>
								</div>
							</div>
						</span>
					</div>
					<div class="col-sm-12" *ngIf="nonOwnersList.length === 0">
						<div class="alert alert-warning text-center no-individuals">
							No contacts exist that can be added. Please add new contact first
							<a class="close" data-dismiss="alert" aria-label="Close" (click)="cancelAdd()"><span aria-hidden="true">&times;</span></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer" [class.curtain]="!resolved">
			<div class="text-right">
				<input type="submit" class="btn btn-primary" tabindex=6 value="Update" />
				<button type="button" (click)="modalRef.hide()" class="btn btn-default" tabindex=5>Cancel</button>
			</div>
		</div>
	</form>
</div>
<div *ngIf="isAddingCompany">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="cancelAdd()">&times;</button>
		<h4 class="modal-title">Add Company Ownership</h4>
	</div>
	<div class="remote-resource" [class.resolved]="states.$resolved">
		<div class="modal-body">
			<form name="SingleContact" id="SingleContact" (onValidSubmit)="addCompanyContact()" [formGroup]="contactForm">
				<form-errors [form]="contactForm"></form-errors>
				<div class="rows-white">

					<div class="row-fluid">
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('companyName').invalid && contactForm.get('companyName').touched">
								<label class="col-sm-5 control-label required">Company Name</label>
								<div class="col-sm-7">
									<input class="form-control" maxlength="100" formControlName="companyName">
								</div>
							</div>
						</div>
						<div class="col-md-1"></div>
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('address').invalid && contactForm.get('address').touched">
								<label class="col-sm-5 control-label required">Street</label>
								<div class="col-sm-7">
									<input class="form-control" maxlength="100" formControlName="address">
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid">
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('phone').invalid && contactForm.get('phone').touched">
								<label class="col-sm-5 control-label required">Phone</label>
								<div class="col-sm-7">
									<input phoneFormatter class="form-control" formControlName="phone">
								</div>
							</div>
						</div>
						<div class="col-md-1"></div>
						<div class="col-md-5">
							<div class="form-group row">
								<label class="col-sm-5 control-label">Suite</label>
								<div class="col-sm-7">
									<input class="form-control" maxlength="19" formControlName="suite">
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid">
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('fax').invalid && contactForm.get('fax').touched">
								<label class="col-sm-5 control-label">Fax</label>
								<div class="col-sm-7">
									<input phoneFormatter class="form-control" formControlName="fax">
								</div>
							</div>
						</div>
						<div class="col-md-1"></div>
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('city').invalid && contactForm.get('city').touched">
								<label class="col-sm-5 control-label required">City</label>
								<div class="col-sm-7">
									<input class="form-control" maxlength="50" formControlName="city">
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid">
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('businessTaxId').invalid && contactForm.get('businessTaxId').touched">
								<label class="col-sm-5 control-label required">Business Tax ID</label>
								<div class="col-sm-7">
									<input class="form-control" maxlength="9" formControlName="businessTaxId">
								</div>
							</div>
						</div>
						<div class="col-md-1"></div>
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('state').invalid && contactForm.get('state').touched">
								<label class="col-sm-5 control-label required">State</label>
								<div class="col-sm-7">
									<ng-select
										*ngIf="states?.$resolved"
										formControlName="state"
										[items]="states"
										bindValue="name"
										bindLabel="name"
										[clearable]="true"
										placeholder="Select state"
										tabindex=13>
									</ng-select>
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid">
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('ownershipPercentage').invalid && contactForm.get('ownershipPercentage').touched">
								<label class="col-sm-5 control-label">Ownership, %</label>
								<div class="col-sm-7">
									<input class="form-control" validFloat="1.0-1" maxlength="4" formControlName="ownershipPercentage">
								</div>
							</div>
						</div>
						<div class="col-md-1"></div>
						<div class="col-md-5">
							<div class="form-group row" [class.has-error]="contactForm.get('zip').invalid && contactForm.get('zip').touched">
								<label class="col-sm-5 control-label required">Zip</label>
								<div class="col-sm-7">
									<input class="form-control" maxlength="20" formControlName="zip">
								</div>
							</div>
						</div>
					</div>

					<br>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<div class="required-block pull-left text-muted">
				<span class="control-label required"></span> &ndash; Required fields
			</div>

			<div class="text-right">
				<button type="submit" form="SingleContact" class="btn btn-primary" tabindex=11>Save</button>
				<a (click)="cancelAdd()" class="btn btn-default" tabindex=11>Cancel</a>
			</div>
		</div>
	</div>
</div>
