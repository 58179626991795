import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

@Component({
	templateUrl: './individuals-modal.component.html',
})
export class IndividualsModalComponent {
	owners: Array<{
		mediaLinkId: number,
		contactId: number,
		nmlsId: number | null,
		contactFullName: string,
	}>;
	onCancel: () => any;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			individuals: [],
			onCancel: () => {
				this.modalRef.hide();
			},
			resolving: true,
		});
	}
}
