import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray, NgResourceObject } from 'commons/interfaces';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';

import { License } from 'shared/account/licenses/index';

declare let apiPath: string;

@Injectable()
export class SharedLicensesService {
	private filters;
	private licenses;
	private license;

	constructor(
		private readonly http: RealmHttpClient,
		public injector: Injector,
		public User: UserService
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const resourceConfig = {
			url: `${apiPath}/tpos/licenses/:class`,
			params: {
				class: 'all',
			},
		};

		this.filters = {
			statuses: $resource(
				`${resourceConfig.url}/statuses`,
				resourceConfig.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
			types: $resource(
				`${resourceConfig.url}/types`,
				resourceConfig.params,
				{
					get: {
						method: 'GET',
						isArray: true,
					},
				},
			),
		};

		this.licenses = PagedResource(
			resourceConfig.url,
			resourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.license = $resource(
			`${resourceConfig.url}/:licenseId`,
			resourceConfig.params,
			{
				get: {
					method: 'PUT',
				},
			},
		);
	}

	getStatusesFilter = (...args): NgResourceArray<any> => this.filters.statuses.get(...args);
	getTypesFilter = (...args): NgResourceArray<any> => this.filters.types.get(...args);
	getLicenses = (...args): NgResourceArray<License> => this.licenses.get(...args);
	getLicense = (...args): NgResourceObject<License> => this.license.get(...args);

    getLicenseNew(licenseId: number): Observable<License> {
        return this.http.request<License>(
            'GET',
            `${apiPath}/licenses/all/${licenseId}`,
        );
    }
}
