<div
	[tooltip]="disabledTooltip"
	data-placement="top"
>
	<button
		type="button"
		[ngClass]="class"
		[disabled]="disabled"
		(click)="notify()"
	>
		Notify
	</button>
</div>
