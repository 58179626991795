<ng-container *ngIf="hasPermissions(); then validPermissions; else invalidPermissions"></ng-container>
<ng-template #validPermissions>
	<h2>
		Audits
		<smHelpLink [url]="helpLink" *ngIf="User.isTpo"></smHelpLink>

		<div class="horizontal-flex-group pull-right">
            <div *ngIf="editing">
                <strong class="text-smaller">Selected Audits: {{selectedAudits.length}}</strong>
            </div>
			<div class="dropdown" dropdown [insideClick]="true">
					<a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Manage Columns" placement="top">
						<span class="c-icon c-icon-list-bullet"></span>
					</a>
					<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
						<li *ngFor="let column of columnValues()">
							<a class="link-option" (click)="toggleColumn(column.id)">
									<span class="checkbox-holder">
										<input type="checkbox" [checked]="column.enabled">
										{{column.name}}
									</span>
							</a>
						</li>
					</ul>
				</div>

            <div *ngIf="!editing && canEdit">
                <a (click)="toggleEditing()" class="btn btn-primary btn-icon">
                    <span class="glyphicon glyphicon-pencil"></span>
                </a>

                <a (click)="createOrder()"
                   *ngIf="User.can('TPO_SMC_ORDER_REVIEWS') && User.smEnabled"
                   class="btn btn-primary">New Order</a>
            </div>

            <div *ngIf="editing" dropdown>
                <audit-list-edit-dropdown
                    [someAuditsAreSelected]="someAuditsAreSelected"
                    (assignUser)="editAssignedUsers()"
                    (editStatus)="editStatus()"
                    (cancelEdit)="toggleEditing()"
                ></audit-list-edit-dropdown>
            </div>

		</div>
	</h2>
	<div class="remote-resource" [class.resolved]="list.$resolved">
		<div class="filters-rows">
			<div class="row filter-labels">
				<div class="col-sm-2">
					<div class="text-light">Search</div>
				</div>
				<div class="col-sm-2">
					<span class="text-light">Assigned</span>
				</div>
				<div class="col-sm-2">
					<span class="text-light">Review Status</span>
				</div>
				<div class="col-sm-2">
					<span class="text-light">Audit Status</span>
				</div>
				<div class="col-sm-2">
					<span class="text-light">Created After</span>
				</div>
				<div class="col-sm-2">
					<span class="text-light">Created Before</span>
				</div>
			</div>
			<div class="row filter-selectors">
				<div class="col-sm-2">
					<search-list placeholder="Name" [disabled]="editing"></search-list>
				</div>
				<div class="col-sm-2">
					<ng-select
						[multiple]="true"
						[(ngModel)]="params.assigned"
						[items]="filters.assigned.$promise | async"
						[loading]="!filters.assigned.$resolved"
						bindValue="id"
						bindLabel="name"
						(change)="setFilter({ assigned: $event })"
						placeholder="All Assigned"
                        [disabled]="editing"
					></ng-select>
				</div>
				<div class="col-sm-2">
					<ng-select
						[multiple]="true"
						[(ngModel)]="params.reviewStatus"
						[items]="filters.reviewStatus.$promise | async"
						[loading]="!filters.reviewStatus.$resolved"
						bindValue="code"
						bindLabel="label"
						(change)="setFilter({ reviewStatus: $event })"
						placeholder="All Statuses"
                        [disabled]="editing"
					></ng-select>
				</div>
				<div class="col-sm-2">
					<ng-select
						[multiple]="true"
						[(ngModel)]="params.auditStatus"
						[items]="filters.auditStatus.$promise | async"
						[loading]="!filters.auditStatus.$resolved"
						bindValue="status"
						bindLabel="status"
						(change)="setFilter({ auditStatus: $event })"
						placeholder="All Statuses"
                        [disabled]="editing"
					></ng-select>
				</div>
				<div class="col-sm-2 form-group">
					<input type="text" class="form-control"
						bsDatepicker
						[bsConfig]="bsConfig"
						[bsValue]="params.dateFrom"
						[maxDate]="params.dateTo"
						(bsValueChange)="setFilter({dateFrom: $event})"
						placeholder="mm/dd/yyyy"
                        [disabled]="editing"
					>
				</div>
				<div class="col-sm-2 form-group">
					<input type="text" class="form-control"
						bsDatepicker
						[bsConfig]="bsConfig"
						[bsValue]="params.dateTo"
						[minDate]="params.dateFrom"
						(bsValueChange)="setFilter({dateTo: $event})"
						placeholder="mm/dd/yyyy"
                        [disabled]="editing"
					>
				</div>
			</div>
		</div>

		<div class="rows-bordered">
			<div class="row-fluid header">
                <label *ngIf="editing" class="col-sm-check va-m">
                    <input type="checkbox" [checked]="selectedAudits.length && selectedAudits.length == list.length" (change)="onSelectAll($event)">
                </label>
				<sortable-header [active]="!editing" class="col-sm-a" columnName="contactName">Name</sortable-header>
				<ng-container *ngFor="let column of columnValues()">
					<sortable-header [active]="!editing" class="col-sm-a" isColumnEnabled [columnName]="column.id">
						<span class="two-liners">{{column.name}}</span>
					</sortable-header>
				</ng-container>
				<div *ngIf="User.isComergence" class="col-sm-1"></div>
			</div>
			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied && !editing">
				<div class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
			</div>
			<a
				*ngFor="let item of list"
				class="row-fluid clickable"
				uiSref="{{editing ? '' : 'social-compliance.orders.:id.reviews.:reviewId.information'}}"
				[uiParams]="{ id: item.orderId, reviewId: item.id }"
			>
                <label class="col-sm-check va-m" *ngIf="editing">
                    <input type="checkbox" [value]="item" [checked]="(someAuditsAreSelected && selectedAudits?.includes(item))" (change)="selectAudit(item)">
                </label>
				<div class="col-sm-a va-m">
					<strong>{{item.contactName}}</strong>
					<div class="text-light text-smaller" *ngIf="item.nmlsId">NMLS ID: {{item.nmlsId}}</div>
				</div>

				<div isColumnEnabled columnName="auditCreatedDate" class="col-sm-a va-m">
					{{(item.auditCreatedDate | date:'MM/dd/yyyy') || '&ndash;'}}
				</div>

				<div isColumnEnabled columnName="reviewStatus" class="col-sm-a va-m text-bigger">
					<ng-container *ngIf="statusesConfig[item.reviewStatus]; let reviewStatus">
						<span class="label" [ngClass]="'label-' + reviewStatus.color">{{reviewStatus.title}}</span>
					</ng-container>
				</div>

				<div isColumnEnabled columnName="auditStatus" class="col-sm-a va-m">
					<div class="col-sm-a va-m">{{item.auditStatus || '&ndash;'}}</div>
				</div>

				<div isColumnEnabled columnName="reviewStatusDate" class="col-sm-a va-m">
					<div class="col-sm-a va-m">
						{{(item.reviewStatusDate | date:'MM/dd/yyyy') || '&ndash;'}}
					</div>
				</div>

				<div isColumnEnabled columnName="auditStatusDate" class="col-sm-a va-m">
					<div class="col-sm-a va-m">
						{{(item.auditStatusDate | date:'MM/dd/yyyy') || '&ndash;'}}
					</div>
				</div>

				<div isColumnEnabled columnName="assigned" class="col-sm-a va-m">
					<div class="col-sm-a va-m">
						<div class="col-sm-a va-m">{{item.assigned || '&ndash;'}}</div>
					</div>
				</div>

				<div *ngIf="User.isComergence" class="col-sm-1 va-m">
					<i *ngIf="item.auditReady" class="dot dot-success block"></i>
				</div>
			</a>
			<div class="row-fluid" *ngIf="list.$resolved && !list.length && !editing">
				<div class="col-sm-12 text-center text-light text-bigger">
					{{(filtersApplied || filters.q) ? 'No matches found' : 'No audits'}}
				</div>
			</div>
			<br>
            <div [class.curtain]="editing">
                <new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
            </div>
		</div>
	</div>
</ng-template>
<ng-template #invalidPermissions>
	<no-permissions title="Audits">
		<img src="/static/images/promo/sm-get-started/Icon_reviews_off.svg" alt="Audit"/>
	</no-permissions>
</ng-template>
