<div class="move-listing-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
		<h4 class="modal-title">Rename Group</h4>
	</div>

	<form [formGroup]="form" (onValidSubmit)="submit()">
		<div class="modal-body remote-resource" [class.resolved]="resolved">
			<form-errors [form]="form"></form-errors>

			<div class="form-group">
				<input type="text" class="form-control" formControlName="name" maxlength="300"/>
			</div>
		</div>

		<div class="modal-footer" [class.curtain]="!resolved">
			<div class="btn-group-lg">
				<button type="submit" class="btn btn-primary" [disabled]="isSubmitDisabled">Save</button>
				<button type="button" (click)="close()" class="btn btn-default">Cancel</button>
			</div>
		</div>
	</form>
</div>
