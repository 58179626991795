import { maxBy, forEach, cloneDeep, assign, find } from 'lodash';
import { Component, Injector, TemplateRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { UserService as UService } from 'angularjs-providers/user.provider';

import { ReviewsResourceService } from '../../../reviews-resource.service';

@Component({
	templateUrl: './review-view.component.html',
})
export class ReviewViewComponent {
	review: any;
	nmlsIds: any;
	availableImages: any;
	score: any;
	rating: number;
	editing: boolean = false;
	tpoId: any;
	selectedImgId: number;
	modalRef: BsModalRef;
	User: any;
	$scope: any;

	constructor(
		public state: StateService,
		public historyLogService: HistoryLogService,
		public reviewsResource: ReviewsResourceService,
		public modalService: BsModalService,
		public UserService: UService,
		public injector: Injector,
	) {
		const { reviewId } = state.params;
		this.User = UserService.profile;
		this.tpoId = this.User.organization.id;
		this.$scope = this.injector.get('$scope');

		this.review = reviewsResource.review.get({reviewId}, (response) => {
			this.score = maxBy(response.scores, 'score');

			this.review.disabledSections = {
				reviewerName: false,
				loNmlsId: false,
			};

			if (!response.hasPostPermission) {
				this.$scope.$emit('USER:403', 'New review');
			}
		});

		this.nmlsIds = reviewsResource.externalReviews.get();
		this.availableImages = reviewsResource.availableImages.get((response) => {
			this.selectedImgId = (find(response, {deletable: false}) as any).id;

		});
	}

	changeViewMode() {
		this.editing = !this.editing;
	}

	toogleSection(sectionName) {
		this.review.disabledSections[sectionName] = !this.review.disabledSections[sectionName];
	}

	post() {
		const { reviewId } = this.state.params;
		const reviewCopy = cloneDeep(this.review);

		assign(reviewCopy, {
			chosenScore: this.score,
			pictureUrl: reviewCopy.lenderLogoURL,
			imageId: this.selectedImgId,
		});

		forEach(this.review.disabledSections, (value, key) => {
			if (value) {
				reviewCopy[key] = null;
				if (key === 'loNmlsId') {
					reviewCopy.loanOriginatorFullName = null;
				}
			}
		});

		this.review.$resolved = false;
		this.reviewsResource.review.preparePost(
			{ reviewId },
			reviewCopy,
		).$promise
			.then((response) => {
				const review = JSON.stringify(response);
				this.state.go('social-compliance.publisher.posts.new', { review });
			})
			.finally(() => {
				this.review.$resolved = true;
			});
	}

	showModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, {class: 'modal-smd review-modal'});
	}

	selectImg(imageId) {
		this.selectedImgId = imageId;
	}

	back = (): void => {
		const backState = this.historyLogService.findBackState('**.:reviewId', false);
        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }

		this.state.go('^');
	}
}
