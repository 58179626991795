import { Component } from '@angular/core';

import { LenderUserComponent } from 'lender/user-management';


@Component({
	templateUrl: './user.component.html',
})
export class ComergenceCustomerUserComponent extends LenderUserComponent {
	customerType: any;
	organizationId: any;

	ngOnInit() {
		// prevent direct link to create if no permission
		if (this.shouldNavigateUserBackToOriginalState()) {
			this.toOriginalState();

			return;
		}

		this.customerType = this.stateService.params.customerType;
		this.organizationId = this.stateService.params.organizationId;

		super.ngOnInit();

		if (this.isNew) {
			this.user.channelsAssignedIds = [];
		}
	}

	shouldNavigateUserBackToOriginalState(): boolean {
		const shouldNavigateUserBackToOriginalState: boolean = (
			this.isNew &&
			this.UserService &&
			this.UserService.profile &&
			!this.UserService.profile.can('CCM_MANAGE_CUSTOMER_USERS')
		);

		return shouldNavigateUserBackToOriginalState;
	}

	toOriginalState() {
		this.stateService.go('customers.:customerType.:organizationId.manage', {
			customerType: this.customerType,
			organizationId: this.organizationId,
		});
	}
}
