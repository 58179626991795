<div class="rows-bordered">
    <div class="row-fluid header">
        <div class="text-big col-sm-9">
            Channel Settings
        </div>
        <div class="col-sm-3 text-right">
            <button *ngIf="shouldDisplayHistory | async" class="btn btn-tertiary">
                <a uiSref="financials.document-type.:documentTypeId.history"
                    [uiParams]="{ documentTypeId: documentDetails.documentTypeId }"
                >
                    <span class="c-icon c-icon-back-in-time"></span> History
                </a>
            </button>
        </div>
    </div>
    <div>
        <ng-container *ngFor="let channel of allChannels; let last = last;">
            <div
                [channel-schedule-settings]
                [strategy]="strategy"
                [channel]="channel"
                [strategyParams]="strategyParams"
                [documentSummary]="documentSummary"
                [documentDetails]="documentDetails"
                class="row-fluid"
                [class.no-border]="last"
            >
            </div>

            <hr *ngIf="!last" />
        </ng-container>
    </div>
</div>
