<div class="edit-thumbnail-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Edit Thumbnail</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">

		<div *ngIf="(thumbnailsResponse | async)?.thumbnailIds?.length === 0" class="alert alert-danger">
			<span>We could not render video thumbnails for this video format. You can upload your image and networks will process thumbnail images according to their guidelines.</span>
		</div>

		<div class="thumbnails">
			<uploader
				#uploader
				[class.curtain]="isUploading()"
				(select)="manuallyUploading(uploadManually($event))"
				[customTemplate]="uploaderTemplate"
			>
				<ng-template #uploaderTemplate>
					<ng-container *ngIf="manuallyThumbnail?.id; else uploaderTemplate">
						<div
							class="thumbnail"
							[class.selected]="manuallyThumbnail?.id === selectedThumbnailId && !isUploading()"
							[class.no-generated]="(thumbnailsResponse | async)?.thumbnailIds?.length === 0"
							(click)="selectedThumbnailId = manuallyThumbnail?.id"
						>
							<i
								*ngIf="(thumbnailsResponse | async)?.thumbnailIds?.length === 0"
								class="remove c-icon c-icon-cancel"
								(click)="removeManualThumbnail()"
							></i>
							<img [src]="manuallyThumbnail?.url" (load)="setResolved(manuallyThumbnail)">
							<loader *ngIf="isUploading()"></loader>
						</div>
						<label [class.curtain]="isUploading()">
							<span class="btn btn-default">Upload Image</span>
							<input
								type="file"
								(input)="validFileType($event.target) && uploader.onFileInput($event)"
								[multiple]="false" [accept]="validFileTypes.toString()"
								[disabled]="isUploading()"
							>
						</label>
					</ng-container>
					<ng-template #uploaderTemplate>
						<div #custom
							class="thumbnail"
							(drop)="validFileType($event.dataTransfer) && uploader.onFileInput($event)"
							(click)="input.click()"
						>
							<i class="thumbnail-icon plus"></i>
							<loader *ngIf="manuallyAnimation" size="big"></loader>
						</div>
						<label
							[class.curtain]="isUploading()"
							(mouseenter)="custom.style.border = '2px solid rgba(102, 161, 221, 0.51)'"
							(mouseleave)="custom.style.border = null"
						>
							<span class="btn btn-default">Upload Image</span>
							<input type="file" #input
								[disabled]="isUploading()"
								(input)="validFileType($event.target) && uploader.onFileInput($event)"
								[multiple]="false" [accept]="validFileTypes.toString()"
							>
						</label>
					</ng-template>
				</ng-template>
			</uploader>
			<div *ngFor="let thumbnail of thumbnails" class="thumbnail"
				 [ngClass]="{
					 'selected': thumbnail?.id && thumbnail?.id === selectedThumbnailId,
					 'curtain attachment-video': !thumbnail?.id
				  }"
				 (click)="thumbnail?.id && (selectedThumbnailId = thumbnail?.id)">
				<img [src]="thumbnail?.url" (load)="setResolved(thumbnail)">
				<loader *ngIf="!thumbnail?.$resolved"></loader>
			</div>
		</div>

	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button [disabled]="isUploading()" (click)="onConfirm(selectedThumbnailId, manuallyThumbnail?.id, getUrl)"
					type="button" class="btn btn-primary">Save
			</button>
			<button (click)="onCancel()" type="button" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>
