import { cloneDeep } from 'lodash';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';
import { NgResourceObject, NgResourceArray } from 'commons/interfaces';

import {
	ActivitiesService,
	Activity,
	RelatedContact,
} from '../../../activities.service';
import { TpoContactType } from 'shared/new-contacts/contacts.interface';

@Component({
	templateUrl: './related-contact.component.html',
	selector: 'related-contact',
})
export class RelatedContactComponent implements OnChanges {
	@Input() contact: RelatedContact;
	@Output() contactChange: EventEmitter<RelatedContact> = new EventEmitter<RelatedContact>();
	contactInitial: RelatedContact;

	@Input() resolved: boolean;
	@Output() resolvedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() editing: boolean;
	@Output() editingChange = new EventEmitter<boolean>();

	@Input() notEditableOnCreate: boolean;
	@Input() curtain: boolean;
	@Input() parentForm: RealmFormGroup;

	isNew: boolean;
	activityId: number;
	modalRef: BsModalRef;
	isContactActivityDetails: boolean;

	relatedContactHints: NgResourceArray<RelatedContact> = [];
	relatedLoading: boolean;
	relatedContactInput: Subject<string> = new Subject<string>();

	relatedContactForm: RealmFormGroup = new RealmFormGroup({
		related: new RealmFormControl(
			'related',
			{
				label: 'Contact',
				updateOn: 'change',
			},
		),
	});
    public TYPE = TpoContactType;

	constructor(
		public activitiesService: ActivitiesService,
		public modalService: BsModalService,
		stateService: StateService,
		{ params }: UIRouterGlobals,
	) {
		this.isNew = stateService.includes('**.activities.new.**');
		this.isContactActivityDetails = stateService.includes('**.tpo.:id.:contactId.activities.**') ||
			stateService.includes('**.nmls.:id.:contactId.activities.**') ||
			stateService.includes('**.contacts.:contactId.activities.**');
		!this.isNew && (this.activityId = params.activityId);
		this.subscribeRelated();
	}

	ngOnChanges({ resolved }: SimpleChanges): void {
		if (resolved?.currentValue && !this.relatedContactForm.get('related').value) {
			if (this.isNew && !this.notEditableOnCreate) {
				this.parentForm.addControl('relatedContactForm', this.relatedContactForm);
				this.edit();
			} else {
				this.setInitial();
			}
		}
	}

	async save(): Promise<void> {
		const { organizationContactId } = this.relatedContactForm.get('related').value || {};
		if (organizationContactId) {
			this.resolved = false;
			this.resolvedChange.emit(false);

			try {
				this.contact = (
					await this.activitiesService.contactUpdate(this.activityId, organizationContactId).$promise
				).relatedContact;
				this.setInitial();
			} catch (e) {
				this.relatedContactForm.setServerError(e.data);
			}

			this.resolved = true;
			this.resolvedChange.emit(true);
			this.cancelEdit();
		} else if (this.contact) {
			this.remove();
		} else {
			this.cancelEdit();
		}
	}

	setEditing(editing: boolean): void {
		this.editing = editing;
		this.editingChange.emit(editing);
	}

	cancelEdit(): void {
		this.setEditing(false);
		this.contact = cloneDeep(this.contactInitial);
		this.relatedContactForm.reset();
		this.formInit();
	}

	edit(): void {
		this.setEditing(true);
	}

	remove(): void {
		this.modalRef = this.modalService.show(ConfirmModalComponent, {
			initialState: {
				title: `Delete Related Contact`,
				message: `Are you sure you want to delete the Related Contact?`,
				confirmText: 'Delete',
				onConfirm: (): void => {
					this.modalRef.content.resolving = true;
					this.activitiesService.contactDelete(this.activityId).$promise.then(
						(data: NgResourceObject<Activity>) => {
							this.contact = data.relatedContact;
							this.setInitial();
							this.modalRef.hide();
							this.modalRef.content.resolving = false;
							this.setEditing(false);
						},
						(err) => {
							this.modalRef.hide();
							this.relatedContactForm.setServerError(err.data);
						});
				},
			},
			class: 'modal-smd modal-new',
		});
	}

	subscribeRelated(): void {
		let search = false;
		this.relatedContactInput
			.subscribe((term) => {
				this.relatedContactHints = [];
				search = term && term.length > 2;
				this.relatedLoading = search;
			});

		this.relatedContactInput
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				switchMap(async (term) => {
					if (search) {
						this.relatedContactForm.reset();
						try {
							return await this.activitiesService.contactSearch(term).$promise;
						} catch (err) {
							this.relatedContactForm.setServerError(err.data);
						}
					}
					return [];
				}),
			)
			.subscribe((contacts) => {
				this.relatedContactHints = contacts;
				this.relatedLoading = false;
			});
	}

	onSelect(contact: RelatedContact): void {
		if (this.isNew) {
			this.contact = contact;
			this.contactChange.emit(contact);
			this.setInitial();
		}
		this.relatedContactHints = [];
	}

	formInit = (): void => this.relatedContactForm.get('related').patchValue(this.contact);

	setInitial(): void {
		this.formInit();
		this.contactInitial = cloneDeep(this.contact);
	}
}
