<div class="collapsible-card remote-resource" [class.resolved]="!isRetrievingDetail && !isSaving">
    <div class="card-title-bar" (click)="showCardContent()" [class.collapsed]="!showContent">
        {{ documentSummary.name }}
        <div class="pull-right">
            <span class="glyphicon glyphicon-chevron-down"></span>
        </div>
    </div>
    <div class="card-content">
        <document-card-summary
            [documentSummary]="documentSummary"
            [detailsLoaded]="!isRetrievingDetail && showContent"
        ></document-card-summary>
    </div>
    <div class="card-content" *ngIf="showContent && documentDetails">
        <document-card-channels
            [strategy]="strategy"
            [allChannels]="allChannels"
            [strategyParams]="strategyParams"
            [documentSummary]="documentSummary"
            [documentDetails]="documentDetails"
        ></document-card-channels>
    </div>
</div>
