<tpo-contact-menu>
    <div header class="contact-menu-header flex-row">
        <ng-container *ngIf="!(contact | async)">
            <loader color="white" size="36" thickness="3"></loader>
        </ng-container>
        <ng-container *ngIf="contact | async as contact">
            <img class="contact-icon flex-none" src="/static/images/people/contact-icon.svg">
            <div class="flex-grow">
                <div class="ellipsis">{{contact.fullName}}</div>
                <div class="text-muted text-small ellipsis"><a *ngIf="company | async as company" [uiSref]="accountSref" [uiParams]="accountSrefParams">{{ company?.name || '&nbsp;' }}</a></div>
            </div>
        </ng-container>
    </div>

    <ui-view class="new-contacts"></ui-view>
</tpo-contact-menu>
