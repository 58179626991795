import {isArray} from 'lodash';
import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class OwnersResourceService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/tpos/:tpoId/owners/:id`;
		const defaultParams = User.profile.organization.type === 'TPO' ? {tpoId: User.profile.organization.id} : {};

		this.resource = $resource(`${basePath}`, {...defaultParams}, {
			query: {method: 'get', isArray: true, transformResponse: this.transformResponse},
			update: {method: 'put', isArray: true, transformResponse: this.transformResponse},
			nonOwners: {method: 'get', url: `${apiPath}/tpos/:tpoId/not-owners`, isArray: true},
			verify: {method: 'post', url: `${basePath}/:contactId/request_verification`},
		});
	}
	transformResponse(response) {
		const result = JSON.parse(response);
		return isArray(result) ? result.map((item) => ({...item, previous: item.ownershipPercentage === 0})) : result;
	}

	query = (...args) => this.resource.query(...args);
	verify = (...args) => this.resource.verify(...args);
	update = (...args) => this.resource.update(...args);
	nonOwners = (...args) => this.resource.nonOwners(...args);
}
