import { ChangeDetectorRef, Component, Input } from '@angular/core';

import { merge } from 'lodash';
import { Subject } from 'rxjs';

import { ChannelCardDetail, Channel, ChannelsSettingDetail } from 'lender/manage-channels/manage-channel-card.interface';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { QuestionnaireItem } from 'lender/questionnaires/manage/questionnaires.interface';

@Component({
    templateUrl: 'channel-card.component.html',
    selector: 'channel-card',
})
export class ChannelCardComponent {
    @Input() channel: Channel;

    private _showContent: boolean = false;
    set showContent(show: boolean) {
        if (show && !this._showContent) {
            this.retrieveChannelDetail();
        }

        this._showContent = show;
        this.cd.detectChanges();
    }
    get showContent(): boolean {
        return this._showContent;
    }

    @Input() questionnaires: QuestionnaireItem[] = [];

    readonly channelCardApplicationDetail$: Subject<ChannelCardDetail> = new Subject();

    isRetrievingChannelDetail: boolean = false;
    isSaving: boolean = false;
    toggleLoading: boolean = false;
    channelDetail: ChannelCardDetail;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly manageChannelService: ManageChannelService,
    ) {
    }

    showCardContent() {
        this.showContent = !this.showContent;
    }

    async retrieveChannelDetail(): Promise<void> {
        try {
            this.isRetrievingChannelDetail = true;
            this.channelDetail = await this.manageChannelService.getOrganizationalChannelDetails(this.channel.id).toPromise();

            // Absolute hack. This is necessary because we have two versions of the channel floating around: the summary and details.
            // They don't have entirely-overlapping properties, and many of the properties that are editable are NOT in the summary version.
            // We don't get the details unless we need them for expanding the card, yet we HAVE to have them to "patch" the channel.
            this.channel = merge(
                {},
                this.channel,
                this.channelDetail.channelSettings,
            );

            this.channelCardApplicationDetail$.next(this.channelDetail);
        } finally {
            this.isRetrievingChannelDetail = false;
        }
    }

    async updateChannelDetail(channelSettings: ChannelsSettingDetail): Promise<void> {
        try {
            this.isSaving = true;

            await this.manageChannelService.updateOrganizationalChannelSettings(
                channelSettings.contact.channelId,
                channelSettings
            ).toPromise();
            await this.retrieveChannelDetail();
        } finally {
            this.isSaving = false;
        }
    }

    async patchAndUpdateDetails(pseudoPatch: any): Promise<void> {
        if (!this.channelDetail) {
            await this.retrieveChannelDetail();
        }

        const channelSettingsWithChange: ChannelsSettingDetail = merge(
            {},
            this.channelDetail.channelSettings,
            pseudoPatch
        );

        await this.updateChannelDetail(channelSettingsWithChange);
    }

    updateSaving(isSaving: boolean): void {
        this.isSaving = isSaving;
    }

    handleUpdatedChannelDetail() {
        this.retrieveChannelDetail();
    }
}
