<div class="attachments-container" #attachmentsContainer></div>

<ng-template #carouselScalingTemplate>
	<button type="button" class="carousel-scale" (click)="toggleCarouselScaling()">
		<svg viewBox="0 0 515 515">
			<circle cx="257" cy="257" r="257"/>
			<polyline points="277 122 392 122 392 230"/>
			<polyline points="122 277 122 392 230 392"/>
		</svg>
	</button>
</ng-template>

<ng-template #carouselNavigationTemplate>
	<div class="carousel-navigation">
		<ul>
			<li
				*ngFor="let image of images; let index = index"
				[class.active]="index === currentSlide"
				(click)="switchCarouselSlide(index)"
			></li>
		</ul>
	</div>
</ng-template>
