import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';

@Component({
	templateUrl: './owners-modal.component.html',
})
export class OwnersModalComponent {
	list: any = [];

	constructor(
		public modalRef: BsModalRef,
		public stateService: StateService,
	) {}

	onCancel() {
		this.modalRef.hide();
	}

	getContactData(contact) {
		return {
			...contact,
			contactName: contact.name,
		};
	}
}
