import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class SocialConnectResourceService {
	public resource: any;

	constructor(
		public injector: Injector,
		{ profile: User }: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/:connectType/connect`;
		const gmbPath = `${apiPath}/:connectType/:nmlsId/googlemb`;

		this.resource = $resource(
			`${basePath}/:networkType`,
			{
				networkType: '@id',
			},
			{
				connect: {
					method: 'POST',
				},
				getFacebookPages: {
					url: `${basePath}/preview-facebook-pages`,
					method: 'POST',
					params: {
						connectType: 'digital-media',
					},
					isArray: true,
				},
				getLinkedInPages: {
					url: `${basePath}/preview-linkedin-pages`,
					method: 'POST',
					params: {
						connectType: 'digital-media',
					},
					isArray: true,
				},
				setMortgageRelatedAccounts: {
					url: `${apiPath}/social-media/links/:idType/:id/facebook/mortgage-related`,
					method: 'PUT',
					// By default we update current authenticated user accounts
					// Once business requirements will change you should refactor this and connect modal.
					params: {
						idType: 'CONTACTID',
						id: User.contactId,
					},
				},
				getGMBLocations: {
					url: `${gmbPath}/locations/profile`,
					method: 'GET',
					isArray: true,
				},
				finishGMB: {
					url: `${gmbPath}/:connectFlow/finish`,
					method: 'POST',
				},
			}
		);
	}

	connect = (...args) => this.resource.connect(...args);
	getFacebookPages = (...args) => this.resource.getFacebookPages(...args);
	getLinkedInPages = (...args) => this.resource.getLinkedInPages(...args);
	getGMBLocations = (...args) => this.resource.getGMBLocations(...args);
	finishGMB = (...args) => this.resource.finishGMB(...args);
	setMortgageRelatedAccounts = (...args) => this.resource.setMortgageRelatedAccounts(...args);
}
