<div class="modal-header" [ngSwitch]="status">
    <button type="button" class="close" (click)="ok()">&times;</button>
    <h4 class="modal-title" *ngSwitchCase="401">Session Timeout</h4>
    <h4 class="modal-title" *ngSwitchCase="403">Sorry! Access Denied</h4>
    <h4 class="modal-title" *ngSwitchCase="404">Not Found</h4>
</div>
<div class="modal-body" [ngSwitch]="status">
    <ng-container *ngSwitchCase="401">
        <p>For security, your session has expired due to inactivity for more than {{timeoutMin}} minutes, please log in to continue.</p>
    </ng-container>
    <ng-container *ngSwitchCase="403">
        <div class="denied-lock"></div>
        <p>
            You currently do not have the necessary permissions to access this section.
        </p>
        <p>
            If you believe this is an error, please contact your Administrator to manage your permissions.
        </p>
    </ng-container>
    <ng-container *ngSwitchCase="404">
        <p>
            The data is no longer available
        </p>
    </ng-container>
</div>
<div class="modal-footer">
    <div class="text-center">
        <button type="button" (click)="ok()" class="btn btn-primary btn-lg">Ok</button>
    </div>
</div>
