import { ChangeDetectorRef, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { NgResourceObject } from '/commons/interfaces';

import { QuestionnaireItem, QuestionnaireTab, QuestionnaireAction, QuestionnaireTabs } from '../questionnaires.interface';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

@Component({
    templateUrl: './questionnaire.component.html',
})
export class QuestionnaireComponent {
    actions = QuestionnaireAction;
    tabs: QuestionnaireTab[] = QuestionnaireTabs;

    _questionnaireId = this.globals.params.questionnaireId;
    get questionnaireId(): number {
        return this._questionnaireId;
    }

    _item: NgResourceObject<QuestionnaireItem>;
    get item(): NgResourceObject<QuestionnaireItem> {
        return this._item;
    }

    _action = this.globals.params.action;
    get action(): QuestionnaireAction {
        return this._action;
    }

    _editing = false;
    get editing(): boolean {
        return this._editing;
    }

    set editing(value: boolean) {
        this._editing = value;
        this.cd.detectChanges();
    }

    destroy$ = new Subject<void>();
    constructor(
        protected globals: UIRouterGlobals,
        protected questionnaireService: LenderManageQuestionnaireService,
        protected cd: ChangeDetectorRef,
    ) {
        this.globals.params$.pipe(
            map(({ questionnaireId }) => (questionnaireId)),
            distinctUntilChanged(),
            takeUntil(this.destroy$),
        ).subscribe((questionnaireId) => {
            this._questionnaireId = questionnaireId;
            this.update();
            this.cd.detectChanges();
        });

        this.globals.params$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(({ action }) => {
            this._action = action;
            this.cd.detectChanges();
        });
    }

    update(item?: NgResourceObject<QuestionnaireItem>): NgResourceObject<QuestionnaireItem> {
        if (item) {
            return this._item = item;
        }

        const { questionnaireId } = this;
        return this._item = this.questionnaireService.get({ questionnaireId });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
