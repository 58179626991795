import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { UserService } from 'angularjs-providers/user.provider';


import { GlobalDocumentsService } from 'lender/documents/documents.service';
import { RequestDocumentModalComponent } from 'lender/clients/$id/documents/common';

import {
	ClientDocumentDetails,
	ClientDocumentDetailsFormData,
} from '../../client-documents.interface';
import { ClientDocumentsService } from '../../client-documents.service';
import { AbstractClientDocumentRequest, DocumentType } from './client-document-request.abstract';

@Component({
	templateUrl: './details.component.html',
})
export class ClientDocumentDetailsComponent extends AbstractClientDocumentRequest {
	modalRef: BsModalRef;

	constructor(
		routerGlobals: UIRouterGlobals,
		userService: UserService,
		private $state: $stateProvider,
		private modalService: BsModalService,
		private globalDocumentsService: GlobalDocumentsService,
		private clientDocumentsService: ClientDocumentsService,
	) {
		super(routerGlobals, userService)

		const { documentId } = this.params;

		this.documents = clientDocumentsService.getRequestableDocuments(this.params);
		this.channels = clientDocumentsService.getChannels(this.params);
		this.create = !documentId;
	}

	get templateDownloadLink(): string {
		const {
			id: documentId,
		} = this.getFormGroup('document').value ?? {};
		return this.globalDocumentsService.getTemplateDownloadLink({ documentId });
	}

	initEditForm = async (): Promise<void> => {
		this.resolved = false;
		try {
			const { documentId } = this.params;
			const document: ClientDocumentDetails = await this.clientDocumentsService.getClientSpecificDocument({
				...this.params,
				documentId,
			}).$promise;

			this.form.patchValue({
				type: DocumentType.CSD,
				document,
			});
		} catch (e) {

		}
		this.resolved = true;
	};

	showRequestDocumentModal(initialState: { reload: () => void; getResourceParams: () => Record<string, unknown>; }): void {
		this.modalRef = this.modalService.show(RequestDocumentModalComponent, {
			initialState,
			backdrop: 'static',
			class: 'modal-new modal-smd',
		});
	}

	async submit(): Promise<void> {
		this.resolved = false;
		try {
			const { id, ...documentDetails }: Partial<ClientDocumentDetailsFormData> = this.getFormGroup('documentDetails').getRawValue();
			const navigateToList = (): void => {
				this.$state.notify('^', {
					notification: {
						type: 'alert-success',
						message: `<b>${documentDetails.name}</b> was created successfully.`,
						timeout: 4000,
					},
				});
			};
			if (id) {
				const params = { ...this.params, documentId: id };
				if (this.create) {

					const initialState = {
						document: {
							...documentDetails,
							id,
						},
						reload: () => navigateToList(),
						getResourceParams: () => params,
						sendRequest: this.clientDocumentsService.document.request,
					};

					this.showRequestDocumentModal(initialState);
				} else {
					const document = await this.clientDocumentsService.updateClientSpecificDocument(
						params,
						documentDetails,
					).$promise;

					this.form.patchValue({ type: DocumentType.CSD, document });

					this.toggleEdit(false);
				}
			} else {
				const initialState = {
					sendRequest: this.clientDocumentsService.createClientSpecificDocument,
					reload: () => navigateToList(),
					getResourceParams: () => this.params,
					document: documentDetails,
				};
				this.showRequestDocumentModal(initialState);
				//await this.clientDocumentsService.createClientSpecificDocument(this.params, documentDetails).$promise;
			}
			this.form.reset(this.form.getRawValue());
		} catch ({ data }) {
			this.form.setServerError(data);
		}
		this.resolved = true;
	}

	cancel(): void {
		if (this.create) {
			this.$state.go('^');
		} else {
			this.toggleEdit(false);
			this.form.reset(this.form.getRawValue());
		}
	}
}
