import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Directive({
	selector: '[bgImage]',
})
export class BgImageDirective {
	@Input('bgImage') srcTarget: string;
	@Input('default') srcDefault?: string;
	@HostBinding('style.backgroundImage') public src: SafeStyle;

	constructor(
		public http: HttpClient,
		private sanitizer: DomSanitizer,
	) {}

	ngOnChanges() {
		if (this.srcTarget) {
			const url = this.srcTarget;

			const img = new Image();
			img.onload = (event) => {
				this.setImage(this.srcTarget);
			};
			img.onerror = () => {
				this.setImage(this.srcDefault);
			};
			img.src = this.srcTarget;
		} else {
			this.setImage(this.srcDefault);
		}
	}

	setImage(url?: string) {
		if (url) {
			this.src = this.sanitizer.bypassSecurityTrustStyle(`url("${url}")`);
		} else {
			this.src = null;
		}
	}
}
