export function hasContactPermission(user, permission) {
	if (!user) {
		return false;
	}

	const isTpo = user.isTpo;
	const isComergence = user.isComergence;
	const isLender = user.isLender;

	switch (permission) {
		case 'VIEW_TRAIL':
			return (isTpo && user.can('TPO_USERS')) || (isComergence) || (isLender && user.crmEnabled);
		case 'MANAGE_OWNERS':
			return (isTpo && user.can('TPO_OWNERS'));
		case 'MANAGE_CONTACT':
			return (isTpo && user.can('TPO_CONTACTS')) || (isComergence && user.can('CCM_MANAGE_CLIENT_USERS')) || (isLender && user.crmEnabled);
		case 'MANAGE_COMPANY_CONTACT':
			return (isTpo && user.can('TPO_CONTACTS')) || (isComergence && user.can('CCM_MANAGE_CLIENT_USERS'));
		case 'MANAGE_USER':
			return (isTpo && user.can('TPO_USERS')) || (isComergence && user.can('CCM_MANAGE_CLIENT_USERS'));
		case 'FILTER_CONTACT':
			return isTpo || isComergence || user.crmEnabled;
		case 'OWNERS_PREVIOUS':
			return isTpo || isLender || isComergence;
		case 'VERIFY_OWNER':
			return isTpo;
		default:
			return false;
	}
}
