import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ClientDocumentsModule } from 'lender/clients/$id/documents';
import { LenderApplicationsResourceService } from './applications.service';
import { ApplicationsListComponent } from './applications-list.component';
import { ApplicationSummaryComponent } from './application-summary/application-summary.component';
import { ApplicationExpirationDateModalComponent } from './expiration-date-modal/expiration-date-modal.component';
import { ApprovalStatusComponent } from 'lender/applications/approval-status/approval-status.component';
import { OptionalStatusComponent } from 'lender/applications/optional-status/optional-status.component';
import { ChangeOptionalStatusModal } from 'lender/applications/optional-status/change-optional-status.modal';
import { ApplicationChangeStatusModalComponent } from 'lender/applications/app-status-modal/app-status.modal';
import { ApplicationTransitionDropdownComponent } from './application-transition-dropdown/application-transition-dropdown.component';
import { ApplicationLinkDropdownComponent } from './application-link-dropdown/application-link-dropdown.component';
import { ApplicationSummaryProfileInformationComponent } from 'lender/applications/application-summary/application-summary-profile-information.component';
import { ApplicationSummaryProfileReviewComponent } from 'lender/applications/application-summary/application-summary-profile-review.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule,
		PipesModule,
		DirectivesModule,
		BsDropdownModule.forRoot(),
		ClientDocumentsModule,
	],
	declarations: [
        ApplicationsListComponent,
        ApplicationSummaryComponent,
        ApplicationExpirationDateModalComponent,
        ApprovalStatusComponent,
        OptionalStatusComponent,
        ChangeOptionalStatusModal,
        ApplicationChangeStatusModalComponent,
		ApplicationTransitionDropdownComponent,
		ApplicationLinkDropdownComponent,
        ApplicationSummaryProfileInformationComponent,
        ApplicationSummaryProfileReviewComponent,
	],
	exports: [
        ApprovalStatusComponent,
        OptionalStatusComponent,
	],
	providers: [
		LenderApplicationsResourceService,
	],
})

export class ApplicationsModule {}
