<section class="remote-resource" [class.resolved]="list.$resolved">
	<h3 class="flex-row gap">
		<span class="flex">
			Supporting Documents
		</span>
		<ng-container *ngIf="((User.isComergence && User.can('CCM_SMC_VIEW_ALL')) || (User.isTpo && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')))">
			<button type="button" class="btn btn-primary" (click)="addDocument()">
				<span class="glyphicon glyphicon-plus small-icon"></span>Add Document
			</button>
		</ng-container>
	</h3>

	<div class="rows-bordered">
		<div class="row-fluid no-border" *ngFor="let item of list">
			<supporting-document
				[document]="item"
				[service]="documentService"
				[params]="getIdParams()"
				(change)="reloadList"
			></supporting-document>
		</div>
	</div>

	<div class="row" *ngIf="list.$resolved && !list.length">
		<div class="col-sm-12 text-center text-muted text-bigger">
			No documents
		</div>
	</div>
</section>
