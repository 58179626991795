import { Component, Input } from '@angular/core';
import { Location } from 'shared/account/locations/locations.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: './licenses.component.html',
})
export class LocationLicensesComponent {
    @Input('locationInfo') location: Location;
    @Input() lenderInfo: AccountInformation;
}
