import { APPLICATION_STATUS } from 'lender/applications/applications.service';
import { Entity } from 'tpo/documents/documents.interface';


export interface ApplicationSummary {
    tpo: Tpo;
    channel:  Entity & {
        description: string;
    };
    applicationInfo: ApplicationInfo;
    ownershipInfo: OwnershipInfo;
    documentsInfo: DocumentsInfo;
    status: Status;
    profileReview: SectionReviews;
    inProgress: boolean;
    clientName: string;
    applicationId: number;
    companyNmlsId: number;
    nmlsId: number;
    tpoId: number;
    hasPrintPackage: boolean;
}

export interface Tpo {
    name: string;
    id: number;
}

export interface ApplicationInfo {
    applicationStatus: ApplicationSectionStatus;
    applicationSubmission: ApplicationSectionInfo;
    questionnaire: ApplicationSectionInfo;
    originationMethods: ApplicationSectionInfo;
}

export type ApplicationSectionStatus = ('COMPLETE' | 'REQUIRED');

export interface ApplicationSectionInfo {
    status: ApplicationSectionStatus;
    statusDate: number;
}

export interface OwnershipInfo {
    ownershipStatus: ApplicationSectionStatus;
    ownershipReqirements: number;
    owners: OwnerInfo[];
}

export interface OwnerInfo {
    fullName: string;
    ownership: number;
    status: OwnershipStatus;
    statusDate: number;
}

export type OwnershipStatus = ('VERIFIED' | 'NOT_VERIFIED');

export interface DocumentsInfo {
    documentsStatus: ApplicationSectionStatus;
    requiredDocumentsCount: number;
}

export interface Status {
    id: APPLICATION_STATUS;
    name: string;
}

export type SectionReviewType = ('AF' | 'AG' | 'Financials' | 'IB' | 'IV' | 'TO' | 'UW' | 'WH' | 'OM');

export type SectionReviews = {
    [index in SectionReviewType]: SectionReview;
}

export interface SectionReview {
    sectionCode: string;
    reviewer: string;
    reviewDate: number;
    expired: boolean;
}

export const profileReviewSections: Map<string, { label: string, sref: string }> = new Map([
    ['AF', { label: 'Affiliates', sref: 'client.:id.relationships.affiliates' }],
    ['AG', { label: 'Agency', sref: 'client.:id.relationships.agency' }],
    ['Financials', { label: 'Financials', sref: 'client.:id.financials' }],
    ['IB', { label: 'Insurance and Bonds', sref: 'client.:id.relationships.insuranceBonds' }],
    ['IV', { label: 'Investors', sref: 'client.:id.relationships.investors' }],
    ['TO', { label: 'Technology & Operations', sref: 'client.:id.relationships.tech-ops' }],
    ['UW', { label: 'Underwriting', sref: 'client.:id.relationships.underwriting' }],
    ['WH', { label: 'Warehouse', sref: 'client.:id.relationships.warehouse' }]
]);
