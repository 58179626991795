import { find, map, some } from 'lodash';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals';

import { AccountInformation } from 'shared/account/company-information/account-information.service';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { AgencyService } from 'tpo/account/agency/agency.service';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { AgencyInterface } from 'tpo/account/agency/agency.interface';

declare let apiPath: string;

const FileTypes = [
    {
        type: 'DOCUMENT',
        regexp: /^.+\.(DOC|DOCX|XLS|XLSX|PDF)$/i,
    },
];


@Component({
    selector: 'agency',
    styles: ['.table-row {padding: 10px; border-bottom: 1px solid #E6E7EC}', '.disabled-grey {color:#CCCCCC}'],
    templateUrl: 'agency.component.html',
})
export class AgencyComponent implements OnInit {
    @Input() lenderInfo: AccountInformation;
    @ViewChild(SectionReviewComponent, { static: true }) sectionReview: SectionReviewComponent;
    public readonly sectionType = PROFILE_SECTION.AGENCY;

    isLender: boolean = false;
    isComergence: boolean = false;
    modalRef: BsModalRef;
    user: UserProfile;
    editing: boolean;
    resolved: boolean;
    tpoId: number;
    labelDateFormat: string = 'MM/dd/YYYY';
    modelDateFormat: string = 'MM/DD/YYYY';

    destroyed$ = new Subject<void>();

    agenciesList: AgencyInterface[];

    agencyForm: RealmFormGroup = new RealmFormGroup({});
    uploadError: string;
    basePath: string;

    constructor(
        private agencyService: AgencyService,
        private userService: UserService,
        private modalService: BsModalService,
        private globalNotificationsService: GlobalNotificationsService,
    ) {
        this.agencyService = agencyService;
        this.user = userService.profile;
    }

    ngOnInit() {
        this.tpoId = this.lenderInfo.identifier.tpoId;
        this.basePath = `${apiPath}/tpos/${this.tpoId}/agencies`;
        if(this.user.isTpo) {
            this.isLender = true;
        }
        this.isComergence = this.user.isComergence;
        this.loadAgencies();
    }

    async loadAgencies(): Promise<void> {
        this.resolved = false;

        try {
            this.agenciesList = await this.agencyService.getAgencies(this.tpoId).toPromise();
            this.populateAgencyForm();
        } catch (e) {
            this.agencyForm.setServerError(e);
        } finally {
            this.resolved = true;
        }
    }

    edit(agency: AgencyInterface): void {
        this.editing = true;
        this.agencyForm.get(agency.agencyName).get('agencyEditing').setValue(true);
    }

    cancelChanges(agency: AgencyInterface): void {
        this.updateFormRow(agency);
        this.agencyForm.get(agency.agencyName).get('agencyEditing').setValue(false);
        this.editing = this.isSomeEditing();
    }

    updateFormRow(agency: AgencyInterface): void {
        const agencyRowForm = this.agencyForm.get(agency.agencyName) as RealmFormControl;
        agencyRowForm.patchValue(agency);
        agencyRowForm.patchValue({ approvalDate: this.toDate(agency.approvalDate) });
        agencyRowForm.patchValue({ endDate: this.toDate(agency.endDate) });
        agencyRowForm.patchValue({ agencyTypeId: agency.agencyTypeId });
        agencyRowForm.markAsUntouched();
    }

    isSomeEditing(): boolean {
        return some(this.agencyForm.getRawValue(), 'agencyEditing');
    }

    async saveChanges(agency: AgencyInterface): Promise<void> {
        if (!this.resolved) return; // Prevent double clicks
        this.resolved = false;
        const formattedAgency: AgencyInterface = this.formatAgencyInterface(this.agencyForm.get(agency.agencyName).value);
        try {
            const newAgency = await this.agencyService.updateAgency(this.tpoId, formattedAgency).toPromise();

            this.agenciesList = map(this.agenciesList, (agency) => {
                if (agency.agencyTypeId === newAgency.agencyTypeId) {
                    return newAgency;
                }
                return agency;
            });
            this.sectionReview.updateReview();
            this.updateFormRow(newAgency);
            this.agencyForm.get(newAgency.agencyName).get('agencyEditing').setValue(false);
            this.editing = this.isSomeEditing();
        } catch ({ error }) {
            return this.agencyForm.setServerError(error);
        } finally {
            this.resolved = true;
        }
    }

    formatAgencyInterface(newAgency: AgencyInterface & { agencyEditing: boolean }): AgencyInterface {
        if(newAgency.approvalDate) {
            newAgency.approvalDate = moment(newAgency.approvalDate).unix() * 1000;
        }
        if(newAgency.endDate) {
            newAgency.endDate = moment(newAgency.endDate).unix() * 1000;
        }
        newAgency.isApplicable = !newAgency.isApplicable;
        return newAgency;
    }

    toDate(dateString) {
        if(dateString) {
            return moment(dateString).toDate();
        }
        return null;
    }

    populateAgencyForm(): void {
        this.agencyForm = new RealmFormGroup({});
        this.agenciesList.forEach((agency) => {
            const agencyRowForm = new RealmFormGroup({
                agencyName: new RealmFormControl(
                    'agencyName',
                    {
                        label: 'Agency Name',
                    },
                ),
                approvalDate: new RealmFormControl(
                    'approvalDate',
                    {
                        label: 'Approval Date',
                    },
                ),
                endDate: new RealmFormControl(
                    'endDate',
                    {
                        label: 'End Date',
                    },
                ),
                idNumber: new RealmFormControl(
                    'idNumber',
                    {
                        label: 'ID Number',
                    },
                ),
                agencyTypeId: new RealmFormControl(
                    'agencyTypeId',
                    {
                        label: 'agencyTypeId',
                    },
                ),
                uploadedDocument: new RealmFormControl(
                    'uploadedDocument',
                    {
                        label: 'uploadedDocument',
                    },
                ),
                agencyEditing: new RealmFormControl(
                    'agencyEditing',
                    {
                        label: 'agencyEditing',
                    },
                ),
            });

            agencyRowForm.patchValue(agency);
            agencyRowForm.patchValue({ approvalDate: this.toDate(agency.approvalDate) });
            agencyRowForm.patchValue({ endDate: this.toDate(agency.endDate) });
            agencyRowForm.patchValue({ agencyTypeId: agency.agencyTypeId });
            agencyRowForm.patchValue({ uploadedDocument: agency.uploadedDocument });
            this.agencyForm.addControl(agency.agencyName, agencyRowForm);
        });
    }

    updateValidators(agency: AgencyInterface): void {
        const agencyRowForm = this.agencyForm.get(agency.agencyName) as RealmFormControl;
        const hasDateValue = agencyRowForm.get('approvalDate').value || agencyRowForm.get('endDate').value;

        agencyRowForm.get('idNumber').setValidators(hasDateValue ? [Validators.required] : []);
        agencyRowForm.get('idNumber').updateValueAndValidity();
    }

    async onFileSelect(agency: AgencyInterface, files: FileList) {
        this.uploadError = null;

        if (files.length === 0) {
            this.uploadError = 'You must select a file to upload.';
            this.agencyForm.setServerError( { message: this.uploadError });
            return;
        }

        const file = files[0];
        const fileTypeConfig = find(FileTypes, (config) => (
            config.regexp.test(file.name)
        ));

        switch (true) {
            case files.length > 1:
                this.uploadError = 'You can upload only one file at once.';
                break;
            case file.size > 20 * Math.pow(2, 20): // 20Mb
                this.uploadError = 'You can upload only a file less than 20 Mb.';
                break;
            case !fileTypeConfig:
                this.uploadError = 'Wrong file type, please use supported file types for upload: DOC, DOCX, XLS, XLSX, PDF';
                break;
        }

        if (this.uploadError) {
            this.agencyForm.setServerError( { message: this.uploadError });
            return;
        }

        this.modalRef = this.modalService.show(
            ConfirmModalComponent,
            {
                initialState: {
                    title: 'Upload Document',
                    message: `
                        <div class="modal-message">
                            <div class="well bigger">You are about to upload <strong>${file.name}</strong> to this document</div>
                            <span class="glyphicon glyphicon-arrow-down"></span>
                            <div class="document-card">${agency.agencyName}</div>
                        </div>
                    `,
                    confirmText: 'Confirm',
                    onConfirm: () => {
                        this.modalRef.hide();
                        this.uploadLetter(agency, file)
                    },
                },
                class: 'modal-smd modal-new confirm-file-upload-modal',
                backdrop: 'static',
            },
        );
    }

    uploadLetter(agency: AgencyInterface, file: File) {
        this.resolved = false;

        this.agencyService.uploadLetter(this.tpoId, agency.agencyTypeId, file).pipe(
            first()
        ).subscribe((data) => {
            this.globalNotificationsService.add({
                type: GlobalNotificationType.POSITIVE,
                message: `Successfully uploaded ${agency.agencyName}.`,
            });

            this.sectionReview.updateReview();
            this.loadAgencies();
        });
    }
}
