import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class AuditChecklistService {
	checklist: any;
	observations: any;

	constructor(
		injector: Injector,
		userService: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');

		const User = userService.profile;

		const basePath = `${apiPath}${User.isTpo ? '/tpos/:tpoId' : ''}/social-media-compliance/checklist`;
		const baseParams = {
			...(User.isTpo && { tpoId: User.organization.id }),
		};
		const checklistConfig = {
			url: `${basePath}/audit/:reviewId`,
			params: {
				...baseParams,
			},
		};

		this.checklist = $resource(
			checklistConfig.url,
			checklistConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.observations = $resource(
			`${apiPath}/tpos/:tpoId/social-media-compliance/checklist/observations/:observationId/regulations`,
			{
				...(User.isTpo && { tpoId: User.organization.id }),
				observationId: '@observationId',
			},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	getChecklist = (...params) => this.checklist.get(...params);
}
