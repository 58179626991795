import { NgModule } from '@angular/core';

import { GetStartedModule } from './get-started';
import { MarketingComponent } from 'tpo/social-compliance/marketing/marketing.component';
import { ReputationComponent } from 'tpo/social-compliance/reputation/reputation.component';
import { SocialMediaModule } from 'shared/social-media';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        ComponentsModule,
        SocialMediaModule,
    ],
    declarations: [
        MarketingComponent,
        ReputationComponent,
    ],
	exports: [
		GetStartedModule,
	],
})
export class SocialComplianceModule {}
