import { Component, ElementRef, Input } from '@angular/core';
import {isUndefined, range, map} from 'lodash';

@Component({
	selector: 'new-paging',
	templateUrl: './new-paging.component.html',

})
export class NewPagingComponent {
	@Input()
	list: any = [];

	@Input()
	sizes: any = [];

	@Input()
	params: any = {
		page: 0,
		size: 10,
	};

	pagesToDisplay: number[] = [];
	minSize: number = 10;

    constructor(private el: ElementRef) {
    }

	ngOnChanges(change) {
		this.minSize = Array.isArray(this.sizes) && this.sizes.length  ? Math.min(...this.sizes) : 10;

		if (change.list && change.list.currentValue.$resolved &&
                (isUndefined(change.list.currentValue.$pagesTotal) || isUndefined(change.list.currentValue.$page))) {
			throw new Error('PagedResource required for paging directive, check your controller');
		}

		if (change.list && change.list.currentValue.$resolved) {
			// 5 comes from orginal directive
			this.pagesToDisplay = this.calcPages(change.list.currentValue, 5);
		}
	}

	next() {
		const page = this.list.$page + 1;

		this.params.next({...this.params.getValue(), page });
	}

	prev() {
		const page = this.list.$page - 1;

		this.params.next({...this.params.getValue(), page });
	}

	setCurrent(page) {
		if (page === this.params.getValue().page) {
			return;
		}

		this.params.next({...this.params.getValue(), page });
	}

	setSize(size) {
		const oldSize = this.params.getValue().size;
		const oldPage = this.params.getValue().page;
		const page =    Math.floor(oldPage * oldSize / size);
		this.params.next({...this.params.getValue(), size, page});
	}

	calcPages(list, padding) {
		const pages = [];

		if (list.$pagesTotal) {
			const leftLimit = Math.max(list.$page - padding, 1);
			const rightLimit = Math.min(list.$page + padding, list.$pagesTotal - 2);

			// First page
			pages.push(0);

			// Separator
			if (leftLimit > 1) {
				pages.push('|');
			}

			// for $pagesTotal = 1 map(pageRange) adds empty second page
			if (list.$pagesTotal > 1) {
				// range does not include end value, so inc it
				const pageRange = range(leftLimit, rightLimit + 1);
				map(pageRange, (page) => pages.push(page));
			}

			// Separator
			if (rightLimit < list.$pagesTotal - 2) {
				pages.push('|');
			}

			// Last page
			if (list.$pagesTotal > 1) {
				pages.push(list.$pagesTotal - 1);
			}
		}

		return pages;
	}
}
