import { fill, get } from 'lodash';
import { Question, QuestionType } from '../../questionnaires.interface';

export type SkipQuestion = {
	index: number;
	id: string | number;
	title: string;
};

type AfterOption = {
	i: number;
	name: string;
};

export type SkipLogic = {
	skipQuestions: SkipQuestion[];
	hints: string[];
	afterOptions: AfterOption[];
	reachableQuestions: boolean[];
	dependentQuestions: boolean[];
	skipOptions: Record<string | number, SkipQuestion[]>;
	allQuestionsCanBeReached: boolean;
	questionsCantBeReached: (string | number)[];
}

export const getSkipLogic = (inputQuestions: Question[]): SkipLogic => {
	const result: SkipLogic = {
		skipQuestions: [],
		hints: [],
		afterOptions: [{ i: 0, name: 'First' }],
		reachableQuestions: fill(Array(inputQuestions.length), false),
		dependentQuestions: fill(Array(inputQuestions.length), false),
		skipOptions: {},
		allQuestionsCanBeReached: true,
		questionsCantBeReached: [],
	};

	for (let i = 0; i < inputQuestions.length; i++) {
		if (inputQuestions[i].questionType !== QuestionType.GROUP) {
			result.skipQuestions.push({
				index: i,
				id: inputQuestions[i].id,
				title: `${(result.skipQuestions.length + 1)}`,
			});
		}
	}

	result.skipQuestions.push({
		index: -1,
		id: -1,
		title: 'End',
	});

	if (result.reachableQuestions.length) {
		result.reachableQuestions[0] = true;
	}

	inputQuestions.forEach((question, qIndex) => {
		if (question.questionType !== QuestionType.GROUP) {
			const currentIndex = result.afterOptions.length;

			result.hints.push(`<span class="text-default"><b>${currentIndex}.</b> &nbsp;${question.text}</span>`);

			result.afterOptions.push({
				i: qIndex + 1,
				name: `After Question ${currentIndex}`,
			});

			result.skipOptions[question.id] = result.skipQuestions.slice(currentIndex);
		} else {
			result.hints.push(`<span class="text-default">Group <b>${question.text}</b></span>`);
		}

		if (qIndex + 1 < inputQuestions.length) {
			if (question.answers && question.answers.length) {
				question.answers.forEach((answer) => {
					const nqIndex = get(answer, '_nextQuestion.index');

					if (answer._skipLogic && nqIndex >= 0) {
						result.reachableQuestions[nqIndex] = result.reachableQuestions[nqIndex] || result.reachableQuestions[qIndex];
						result.dependentQuestions[nqIndex] = true;
					} else if (!answer._skipLogic) {
						result.reachableQuestions[qIndex + 1] = result.reachableQuestions[qIndex + 1] || result.reachableQuestions[qIndex];
					}
				});
			} else {
				result.reachableQuestions[qIndex + 1] = result.reachableQuestions[qIndex + 1] || result.reachableQuestions[qIndex];
			}
		}
		if (question.questionType === QuestionType.GROUP) {
			result.dependentQuestions[qIndex] = false;
			result.reachableQuestions[qIndex] = true;
		}
	});

	result.allQuestionsCanBeReached = result.reachableQuestions.reduce((result, value) => {
		return result && value;
	}, true);

	result.questionsCantBeReached = result.reachableQuestions.reduce((result, value, index) => {
		if (!value) {
			result.push(inputQuestions[index]._number);
		}

		return result;
	}, []);

	return result;
};

export default getSkipLogic;
