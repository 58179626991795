import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { merge, assign, isNil } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ConfirmModalComponent } from 'commons/components/modals/confirm-modal.component';
import { DropdownRequiredValidator } from 'commons/validators';

import { StatusModalComponent } from './status-modal';

import { FindingsService, FindingResource } from '../../findings.service';
import { MediaLinkHelper } from '../../utils/media-link.helper';
import { Assigned } from '../../../social-compliance/audits/audits.service.interface';
import { AuditsService  } from '../../../social-compliance';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'finding-details',
	templateUrl: './finding-details.component.html',
})
export class FindingDetailsComponent implements OnInit {
	User: any;
	bsDatepickerConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};
	canEdit = false;
	edit = false;
	findingDetailsForm = new RealmFormGroup({
		originalPostDate: new RealmFormControl(
			'originalPostDate',
			{ label: 'Date' },
		),
		modifiedPostDate: new RealmFormControl(
			'modifiedPostDate',
			{ label: 'Post Modified Date' },
		),
		mediaLink: new RealmFormControl(
			'mediaLink',
			{ label: 'Account' },
			DropdownRequiredValidator,
		),
		additionalInformation: new RealmFormControl(
			'additionalInformation',
			{ label: 'Additional Information' },
			Validators.maxLength(2000),
		),
        assigned: new RealmFormControl(
            'assigned',
            { label: 'Assigned'},
        ),
	});
	mediaLinks: any;
	modalRef: BsModalRef;

	@Input() finding: FindingResource;
	@Output() onClose = new EventEmitter<boolean>();
	@Output() onCreateTask = new EventEmitter<boolean>();
    assigned: Assigned[];

	constructor(
		public userService: UserService,
		public modalService: BsModalService,
		public findingsService: FindingsService,
        public auditsService: AuditsService,
	) {
		this.User = userService.profile;
	}

	ngOnInit(): void {
		this.finding.$promise
			.then((finding) => {
				this.canEdit = (
					this.User.isComergence && this.User.can('CCM_SMC_VIEW_ALL') ||
					this.User.isTpo && this.User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE') && (!finding.reviewId || finding.reviewAuditStatus.condition !== 'C')
				);

				if (finding.postKey) {
					this.findingDetailsForm.get('originalPostDate').disable();
					this.findingDetailsForm.get('modifiedPostDate').disable();
					this.findingDetailsForm.get('mediaLink').disable();
				}
			});
	}

	remove = (): void => {
        const { id: findingId } = this.finding;
		const remove = () =>
            this.findingsService.finding.remove({ findingId }).$promise
				.then(() => {
					this.modalRef.hide();
					this.onClose.emit();
				})
				.catch(({ data: response }) => {
					// error
				});

		const initialState = {
			message: `Are you sure you want to delete this finding?<br> This action cannot be undone.`,
			confirmText: 'Remove',
			cancelText: 'Cancel',
			title: 'Remove Finding',
			onConfirm() {
				this.resolving = true;
				remove()
					.finally(() => {
						this.resolving = false;
					});
			},
		};

		this.modalRef = this.modalService.show(ConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	get showCreateTask(): boolean {
		const {
			finding: {
				contact: {
					contactType,
				},
				hasRemediation,
			},
		} = this;
		return (
			this.User.isTpo &&
			contactType !== 'company' &&
			!hasRemediation
		);
	}

	createTask = (): void => {
		this.onCreateTask.emit();
	}

	editStatus = (): void => {
		const { finding } = this;
		const initialState = {
			finding,
		};

		this.modalRef = this.modalService.show(StatusModalComponent, { initialState, class: 'modal-smd modal-new' });
	}

	toggleEdit = (newEdit: boolean = !this.edit): void => {
        if (this.User.isTpo) {
            this.getAssigneeList();
        }
		this.edit = newEdit;

		if (newEdit) {
			const {
				originalPostDate,
				modifiedPostDate,
				mediaLink,
				additionalInformation,
                assigned,
			} = this.finding;

			this.findingDetailsForm.patchValue({
				originalPostDate: originalPostDate && moment(originalPostDate).toDate(),
				modifiedPostDate: modifiedPostDate && moment(modifiedPostDate).toDate(),
				mediaLink: mediaLink && {
					...mediaLink,
					title: MediaLinkHelper.getLinkTitle(mediaLink),
				},
				additionalInformation,
			});

            if (!isNil(assigned.id)) {
                this.findingDetailsForm.patchValue({
                    assigned: assigned,
                });
            }

			// load mediaLinks list only on edit
			// skip if already loaded
			if (!this.mediaLinks) {
				const {
					contact: {
						contactType,
						contactId,
						nmlsId,
					},
				} = this.finding;
				const mediaLinksType = contactType === 'company' ? 'digitalMedia' : 'socialMedia';
				const params = {
					type: contactId ? 'CONTACTID' : 'NMLSID',
					id: contactId || nmlsId,
				};

				this.mediaLinks = this.findingsService.mediaLinks[mediaLinksType].get(params);
			}
		} else {
			this.findingDetailsForm.reset();
		}
	}

	submit = (): void => {
		const {
			finding,
			findingDetailsForm: form,
		} = this;
		const {
			originalPostDate,
			modifiedPostDate,
			...formFields
		} = form.getRawValue();

		merge(
			finding,
			{
				...formFields,
				originalPostDate: originalPostDate && moment(originalPostDate).valueOf(),
				modifiedPostDate: modifiedPostDate && moment(modifiedPostDate).valueOf(),
			},
		);

		finding.$resolved = false;
        this.findingsService.finding.update(this.finding).$promise
			.then((data) => {
				assign(
					finding,
					data,
				);

				this.toggleEdit(false);
			})
			.catch(({ data: response }) => {
				form.setServerError(response);
			})
			.finally(() => {
				finding.$resolved = true;
			});
	}

    async getAssigneeList() {
        const tpoId: number = this.finding.contact.tpoId;
        this.assigned = await firstValueFrom(this.auditsService.getAssignedUsers(tpoId));
    }
}
