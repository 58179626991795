import {isString, reduce, find, each, indexOf, without, uniq} from 'lodash';
import {Component, Input} from '@angular/core';

import {UserService} from 'angularjs-providers/user.provider';

import { RawPermissionsService, UserType } from 'shared/roles/raw-permissions.service';

@Component({
	templateUrl: './permission-section.component.html',
	selector: 'permissions-section',
})
export class RolePermissionSectionComponent {
	@Input()
	groupsToShow: any;

	@Input()
	editing: boolean = false;

	@Input()
	permissionState: any;

	@Input()
	permissionType?: string = null;

	permissionsConfig: any;
	sections: any;

	constructor(
        public User: UserService,
        private rawPermissionsService: RawPermissionsService,
    ) {}

	ngOnInit() {
		const rawPermissions = this.getPermissions();
		this.permissionsConfig = this.calcTransitiveDependencies(rawPermissions);
		this.sections = this.getSections();
	}

	// config can have different dependencies on role, env, so prepare config for each role
    getPermissions() {
        return this.rawPermissionsService.get(this.permissionType?.toLocaleLowerCase() as UserType)
    }

	public getSections() {
		const groupsToShow = this.getGroupsToShow();

		return reduce(groupsToShow, (memo, groupKey) => {
			const group = this.permissionsConfig.grouping.get(groupKey);

			if (!group) {
				return memo;
			}

			const resultGroup = {
				title: group.title,
				columns: [
					this.getPermissionColumn(group.columns[0]),
					this.getPermissionColumn(group.columns[1]),
				],
			};

			return [...memo, resultGroup];
		}, []);
	}

	public getGroupsToShow = () => {
		if (!this.groupsToShow) {
			return Array.from(this.permissionsConfig.grouping.keys());
		}

		return isString(this.groupsToShow) ? [this.groupsToShow] : this.groupsToShow;
	}

	public getPermissionColumn = (permissions) => {
		return reduce(permissions, (memo, permissionName) => {
			const permissionConfig = this.permissionsConfig.permissions.get(permissionName);

			return [...memo, permissionConfig];
		}, []);
	}

	calcTransitiveDependencies(config) {
		// calc transitive dependencies like application > users > contacts
		each(Array.from(config.permissions), ([ permissionName, permissionConfig]) => {
			if (!permissionConfig.dependsOn) {
				config.permissions.set(permissionName, {
					...permissionConfig,
					key: permissionName,
				});
				return;
			}

			const dependencies = this._getDependencies(permissionConfig.dependsOn, Array.from(config.permissions));

			config.permissions.set(permissionName, {
				...permissionConfig,
				key: permissionName,
				dependsOn: dependencies,
			});
		});

		// when all transitive dependencies are calculated -> calc reverse dependency
		each(Array.from(config.permissions), ([ permissionName, permissionConfig]) => {
			const requiredFor = reduce(Array.from(config.permissions), (memo: string[], mapEntry: any) => {
				const [key, value] = mapEntry;

				if (!value.dependsOn) {
					return memo;
				}

				const required = find(value.dependsOn, (name) => (name === permissionName));
				if (!required) {
					return memo;
				}

				return [...memo, key];
			}, []);

			config.permissions.set(permissionName, {
				...permissionConfig,
				requiredFor,
			});
		});

		return config;
	}

	_getDependencies(dependsOn, permissionObjects) {
		return reduce(dependsOn, (memo, permissionName) => {
			const config = find(permissionObjects, ([ name, rest ]) => (name === permissionName));

			if (!config) {
				return memo;
			}

			const [ innerName, innerConfig ] = config;

			if (indexOf(memo, permissionName) > -1 && !innerConfig.dependsOn) {
				return memo;
			}

			if (!innerConfig.dependsOn) {
				return memo;
			}

			const newDependencies = without(innerConfig.dependsOn, ...memo);

			if (!newDependencies.length) {
				return memo;
			}

			return this._getDependencies(uniq([...memo, ...innerConfig.dependsOn]), permissionObjects);
		}, dependsOn);
	}
}
