import { Component, forwardRef } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';

import { ChannelApplicationsService } from '../channel-applications.service';
import { ChannelApplicationHistoryList } from '../channel-application.interface';


@Component({
	templateUrl: './applications-history.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => ApplicationsHistoryComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => ApplicationsHistoryComponent) },
	],
})
export class ApplicationsHistoryComponent extends NewPagedListComponent<ChannelApplicationHistoryList> {
	static listName = 'channelApplicationHistory';

	User: UserProfile;

	applicationId: number;

	constructor(
		router: UIRouter,
		{ params: { applicationId } }: UIRouterGlobals,
		{ profile }: UserService,
		public applicationsService: ChannelApplicationsService,
	) {
		super(router);

		this.User = profile;
		this.applicationId = applicationId;
	}

	protected loadList(params: ListParams): Observable<PagedData<ChannelApplicationHistoryList[]>> {
		return this.applicationsService.getHistory(this.applicationId);
	}
}
