<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">Add Prospect</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<form [formGroup]="prospectForm" id="prospectForm" (onValidSubmit)="onConfirm(prospectForm.getRawValue())">
		<form-errors [form]="prospectForm"></form-errors>

		<div class="form-group row">
			<label class="col-md-2 col-md-offset-2 control-label text-right">Company</label>
			<div class="col-md-5">
				{{statusInfo.companyName}}
			</div>
			<div class="col-md-a"></div>
		</div>
		<div class="form-group row">
			<label class="col-md-2 col-md-offset-2 control-label text-right">Location</label>
			<div class="col-md-5">
				{{statusInfo.location || statusInfo.locationAddress}}
			</div>
			<div class="col-md-a"></div>
		</div>
		<div class="form-group row">
			<label class="col-md-2 col-md-offset-2 control-label text-right">{{prospectForm.getFieldLabel('channel')}}</label>
			<div class="col-md-5">
				<ng-select
						[items]="channels | async"
						(change)="setAssigments($event?.id)"
						[multiple]="false"
						[clearable]="false"
						[loading]="!(channels | async)"
						formControlName="channel"
						bindLabel="name"
						bindValue="id"
						name="channel"
				>
				</ng-select>
			</div>
			<div class="col-md-a"></div>
		</div>
		<div class="form-group row">
				<label class="col-md-2 col-md-offset-2 control-label text-right">{{prospectForm.getFieldLabel('assigment')}}</label>
				<div class="col-md-5">
					<ng-select
							[items]="assigments | async"
							[multiple]="false"
							[clearable]="false"
							[loading]="!(assigments | async)"
							formControlName="assigment"
							bindLabel="name"
							bindValue="id"
							name="assigment"
					>
					</ng-select>
				</div>
				<div class="col-md-a"></div>
			</div>
	</form>
</div>

<div class="modal-footer" [class.curtain]="resolving">
	<button type="submit" form="prospectForm" class="btn btn-primary">Add Prospect</button>
	<button type="button" (click)="modalRef.hide()" class="btn btn-secondary">Cancel</button>
</div>

