import { TpoContactType } from 'shared/new-contacts/contacts.interface';

import { Entity } from 'tpo/documents/documents.interface';

import { CustomField } from 'shared/fields/custom-fields.service';

export enum LOCATION_TYPE {
    HQ = 'HQ',
    BRANCH = 'BR',
}

export enum PROSPECT_STATE {
    OPEN = 'O',
    CONVERTED = 'C',
    REMOVED = 'R',
    CLOSED = 'CL',
}

export type ProspectStateValue = {
    shortName: PROSPECT_STATE,
    name: string,
};

export type ProspectStatus = Entity & {
    stateId: PROSPECT_STATE,
};

export type ProspectDetails = {
    address: string;
    assigneeName: string;
    assigneeUserId: number;
    id: number;
    companyName: string;
    nmlsId: string;
    tpoId?: number;
    channelName: string;
    locationNmlsId: string;
    locationName: string;
    locationCompanyName: string;
    locationType: LOCATION_TYPE;
    city: string;
    state: string;
    zip: string;
    prospectStatus: string;
    prospectState: PROSPECT_STATE;
    editable: boolean;
    editableStatus: boolean;
    pendingApplicationsExists: boolean;
} & Partial<ProspectCommon>;

export type ContactHint = Pick<RelatedProspectContact, 'name' | 'organizationContactId' | 'title' | 'designation'>;

export interface ProspectsList {
    id: number;
    rowNum: number;
    rowCount: number;
    companyName: string;
    nmlsId: string;
    locationCompanyName: string;
    locationNmlsId: string;
    channelName: string;
    locationType: string;
    locationName: string;
    prospectStatus: string;
    prospectState: string;
    tpoId: number;
    channelsToInviteIds?: number[] | null;
    assigneeName: string;
    assigneeUserId?: string;
    editable?: boolean;
    editableStatus?: boolean;
    checked?: boolean;
}

export type ProspectCommon = {
    id: number;
    channelName: string;
    prospectState: PROSPECT_STATE;
    prospectStatus: string;
    editable?: boolean;
    editableStatus?: boolean;

    companyName: string;
    tpoId?: number;
    nmlsId: string;

    locationCompanyName: string;
    locationNmlsId: string;
    locationName: string;
    locationType: LOCATION_TYPE;

    assigneeName: string;
    assigneeUserId: number;

    brokerVolume: number;
    soldVolume: number;
    totalVolume: number;
    individualNmlsCount: number;
}

export type ProspectsListItem = ProspectCommon & {
    rowNum: number;
    rowCount: number;
    channelsToInviteIds?: number[] | null;
    checked?: boolean;
}

export type RelatedProspectContact = {
    contactId: number;
    organizationContactId: number;
    contactType: TpoContactType;
    companyNmlsId: number;
    individualNmlsId: number;
    name: string;
    designation: string;
    title: string;
    location: string;
    phone: string;
    email: string;
    relatedAccount: {
        tpoId: number
    };
    isContactRelevant: boolean;
    isContactDeleted: boolean;
}

export interface InvestorChannels {
    name: string,
    id: number,
    description: string;
    tpoPaysForRenewal: boolean,
    isActive: boolean,
    renewalScheduleMonths: number,
    ownershipVerificationPercentage: number,
    tpoPaysForApplication: boolean,
}

export interface ProspectAssignment {
    newAssigneeId: number;
    prospectIds: number[];
}

export interface ProspectHistory {
    action: string;
    actionBy: string;
    date: number;
}

export type CustomFieldFilter = Omit<CustomField, 'displayOrder'>;
