import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable({ providedIn: 'root' })
export class ZipResolveService {
    resource: any;

    constructor(public injector: Injector) {
        const $resource = injector.get('$injector').get('$resource');
        const basePath = `${apiPath}/zip/:code/address`;

        this.resource = $resource(`${basePath}`, {}, {
            get: { method: 'get' },
        });
    }

    get = (...args) => this.resource.get(...args);
}
