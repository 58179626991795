import { Injectable } from '@angular/core';

import { ScorecardFinding, ScorecardFindingDetailRow } from 'shared/due-diligence/scorecard/scorecard-finding.interface';
import { ScorecardUtilityService } from 'shared/due-diligence/scorecard/services/scorecard-utility.service';
import { ScorecardService } from 'shared/due-diligence/scorecard/services/scorecard.service';
import { ScorecardFindingCategoryCode } from 'shared/due-diligence/scorecard/scorecard-finding-category.interface';


export const INVESTOR_SCORECARD_FINDING_HISTORY_SREF: string = 'client.:id.reviews.scorecard.finding.:sType.:sid.history';
export const CCM_SCORECARD_FINDING_HISTORY_SREF: string = 'tpo.:id.scorecard.finding.:sType.:sid.history';

@Injectable()
export class ScorecardFindingService {
    constructor(
        private readonly scorecardService: ScorecardService,
        private readonly scorecardUtilityService: ScorecardUtilityService,
    ) {
    }

    determineHistorySref(
        isCCM: boolean,
    ): string {
        if (isCCM) {
            return CCM_SCORECARD_FINDING_HISTORY_SREF;
        }

        return INVESTOR_SCORECARD_FINDING_HISTORY_SREF;
    }

    determineHistorySrefParams(
        tpoId: number,
        categoryCode: ScorecardFindingCategoryCode,
        findingId: (string | number),
    ): any {
        return {
            id: tpoId,
            sType: categoryCode,
            sid: findingId,
        };
    }

    async retrieveFinding(
        tpoId: number,
        findingId: number,
    ): Promise<ScorecardFinding> {
        const finding: ScorecardFinding = await this.scorecardService.getFindingDetails(tpoId, findingId).toPromise();

        return finding;
    }

    annotateFindingAndSplitDetailsIntoRows(finding: ScorecardFinding): ScorecardFindingDetailRow[] {
        this.scorecardUtilityService.annotateArrayValues(finding.details);

        const detailRows: ScorecardFindingDetailRow[] = this.scorecardUtilityService.splitDetailsIntoRows(finding.details);

        return detailRows;
    }
}
