<div class="reporting-switcher remote-resource"
	 [class.resolved]="settings?.$resolved && !saving"
	 [class.curtain]="statusCode === 'INACTIVE'">
	<form [formGroup]="reportSettingsForm" id="reportSettingsForm" (ngSubmit)="save()">
		<h3 class="clearfix">
			<span class="pull-left">Reporting</span>
			<div class="pull-right" *ngIf="!editing">
				<button *ngIf="canEdit" type="button" class="btn btn-primary" (click)="toggleMode()">
					<span class="glyphicon glyphicon-pencil"></span> Edit
				</button>
			</div>
			<div class="pull-right" *ngIf="editing">
				<input type="submit" class="btn btn-primary" [disabled]="saving" value="Save">
				<button type="button" class="btn btn-default" (click)="cancel()" [disabled]="saving">
					Cancel
				</button>
			</div>
		</h3>

		<form-errors [form]="reportSettingsForm"></form-errors>

		<div class="rows-striped">
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row">
						<label class="col-sm-5 control-label text-right" [class.required]="editing">Service</label>
						<div class="col-sm-6">
							<editable-component [editable]="editing">
								<ng-template #editTemplate>
									<div class="control-value">
										<label class="radio-inline">
											<input type="radio" formControlName="enabled" [value]="true"> On
										</label>
										<label class="radio-inline">
											<input type="radio" formControlName="enabled" [value]="false"> Off
										</label>
									</div>
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										<span *ngIf="settings?.$resolved && settings?.enabled" class="glyphicon glyphicon-ok text-success" aria-hidden="true"></span>
										<span *ngIf="settings?.$resolved && !settings?.enabled" class="glyphicon glyphicon-remove text-danger" aria-hidden="true"></span>
										&nbsp;{{ getLabel() }}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">&nbsp;</div>
			</div>
		</div>
	</form>
</div>
