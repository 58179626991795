<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">Upload Image</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div class="alert alert-danger" *ngIf="error">
		<div class="form-error-inner">
			<div [innerHTML]="error"></div>
		</div>
	</div>
	<p>File format: PNG, JPEG, JPG.</p>
	<p>Image resolution: 170x170px.</p>
	<br>
	<uploader (select)="onPick($event)" [config]="{ multiple: false }"></uploader>
</div>
