import { reduce, find, sortBy, values } from 'lodash';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { AuditChecklistService } from '../checklist.service';

interface RawRegulation {
	findingIssueTypeId: number;
	findingType: string;
	relatedRegulation: string;
	isAuthorized: boolean | null;
}

interface GroupedRegulations {
	groupName: string;
	entries: any[];
}

enum Tabs {
	ALL = 'All',
	CHECKLIST = 'Checklist',
}

@Component({
	templateUrl: './regulations-modal.component.html',
})
export class RegulationsModalComponent {
	resolving: boolean = true;
	regulations: GroupedRegulations[] = [];

	observationId: number;
	checkListFindingId?: number;
	tpoId?: number;
	tabs = values(Tabs);
	activeTab: Tabs = Tabs.CHECKLIST;

	constructor(
		public modalRef: BsModalRef,
		public auditChecklistService: AuditChecklistService,
		public userService: UserService,
	) {}

	ngOnInit(): void {
		this.loadData();
	}

	loadData(): void {
		this.auditChecklistService.observations.get({
			tpoId: this.tpoId || this.userService.profile.organization.id,
			observationId: this.observationId,
			...(this.getCheckListFindingId()),
		}).$promise
			.then((data) => {
				this.regulations = reduce(data, (acc, regulation: RawRegulation) => {
					const entryExists = find(acc, (group: GroupedRegulations) => {
						return group.groupName === regulation.relatedRegulation;
					});

					if (entryExists) {
						entryExists.entries = sortBy([ ...entryExists.entries, {
							type: regulation.findingType,
							isAuthorized: regulation.isAuthorized,
						}]);
						return acc;
					}

					const newEntry = {
						groupName: regulation.relatedRegulation,
						entries: [{
							type: regulation.findingType,
							isAuthorized: regulation.isAuthorized,
						}],
					};

					return [ ...acc, newEntry ];
				}, ([] as GroupedRegulations[]));
			})
			.finally(() => {
				this.resolving = false;
			});
	}

	getCheckListFindingId = (): { checkListFindingId?: number } => {
		if (this.activeTab === Tabs.CHECKLIST && this.checkListFindingId) {
			return { checkListFindingId: this.checkListFindingId };
		}

		return {};
	}

	changeTab(tab: Tabs): void {
		this.resolving = true;
		this.activeTab = tab;
		this.loadData()
	}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.modalRef.hide();
	}
}
