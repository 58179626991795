import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { forkJoin, Observable } from 'rxjs';

import { UserService } from 'angularjs-providers/user.provider';

import { CommentType } from 'lender/common/interfaces/comment-type.interface';
import { Comment } from 'lender/common/interfaces/comment.interface';
import { CommonInvestorCommentsService } from 'lender/common/services/common-investor-comments.service';
import {
    AlertChannel,
    AlertEvent,
    AlertHistoryItem,
    AlertId,
    AlertRecord,
    AlertStatus,
    AlertSummary,
} from '../alert.interface';
import { LenderAlertsService } from '../alerts.service';


@Component({
	templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
	userCanEditAlerts: boolean;
	lenderId: number;
	tpoId: string;
	alertId: number;

	alertStatuses: AlertStatus[];
	alertSummary: AlertSummary;
	alertEvents: AlertEvent[];
	alertChannels: AlertChannel[];
	alertIds: AlertId[];
	alertHistoryItems: AlertHistoryItem[];
	commentTypes: CommentType[];
	comments: Comment[];
    alertRecord: AlertRecord;

	constructor(
		private readonly alertsService: LenderAlertsService,
		private readonly commonInvestorCommentsService: CommonInvestorCommentsService,
		private readonly uiRouterGlobals: UIRouterGlobals,
		private readonly userService: UserService,
	) {
	}

	// Override
	async ngOnInit(): Promise<void> {
		this.userCanEditAlerts = this.userService.profile.can('EDIT_ALERTS');
		this.lenderId = this.userService.profile.organization.id;
		this.tpoId = `${this.uiRouterGlobals.params.id}`;
		this.alertId = this.uiRouterGlobals.params.alertId;

		const alert$: Observable<AlertSummary> = this.alertsService.getAlert(this.lenderId, this.alertId);
		const events$: Observable<AlertEvent[]> = this.alertsService.getEvents(this.lenderId, this.alertId);
		const channels$: Observable<AlertChannel[]> = this.alertsService.getChannels(this.lenderId, this.alertId);
		const ids$: Observable<AlertId[]> = this.alertsService.getIds(this.lenderId, this.alertId);
		const historyItems$: Observable<AlertHistoryItem[]> = this.alertsService.getHistory(this.lenderId, this.alertId);
		const comments$: Observable<Comment[]> = this.alertsService.getComments(this.lenderId, this.alertId);

        const commentTypes$: Observable<CommentType[]> = this.commonInvestorCommentsService.getCommentTypes();
        const record$: Observable<AlertRecord> = this.alertsService.getAlertRecord(this.lenderId, this.alertId)

		this.alertStatuses = this.alertsService.getStatuses();
		// forkJoin only allows up to 6 typed inputs/outputs

		const requestResults: [
			AlertSummary,
			AlertEvent[],
			AlertChannel[],
			AlertId[],
			AlertHistoryItem[],
			Comment[],
		] = await forkJoin([
			alert$,
			events$,
			channels$,
			ids$,
			historyItems$,
			comments$,
		]).toPromise();

        const commentTypesAndRecordResults: [
            CommentType[],
            AlertRecord
        ] = await forkJoin([
            commentTypes$,
            record$,
        ]).toPromise();

		this.alertSummary = requestResults[0];
		this.alertEvents = requestResults[1];
		this.alertChannels = requestResults[2];
		this.alertIds = requestResults[3];
		this.alertHistoryItems = requestResults[4];
		this.comments = requestResults[5];

        this.commentTypes = commentTypesAndRecordResults[0];
        this.alertRecord = commentTypesAndRecordResults[1];
	}

	alertStatusUpdated(alertStatus: AlertStatus): void {
		this.alertSummary.alertStatus = alertStatus.id;
	}

	async reloadComments(): Promise<void> {
		this.comments = await this.alertsService.getComments(this.lenderId, this.alertId).toPromise();
	}
}
