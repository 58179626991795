import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { FindingsService } from '../../../findings.service';

@Component({
	templateUrl: './status-modal.component.html',
})
export class StatusModalComponent implements OnInit {
	finding: any;
	statuses: any;
	updateStatusForm = new RealmFormGroup({
		status: new RealmFormControl(
			'status',
			{},
			Validators.required,
		),
	});
	createStatusForm = new RealmFormGroup({
		status: new RealmFormControl(
			'status',
			{ label: 'New Status Name' },
			Validators.compose([
				Validators.required,
				Validators.maxLength(200),
			]),
		),
		correspondingCondition: new RealmFormControl(
			'correspondingCondition',
			{
				label: 'Condition',
				value: 'Closed',
			},
		),
	});
	resolving = false;

	constructor(
		public stateService: StateService,
		public modalRef: BsModalRef,
		public findingsService: FindingsService,
	) {
		this.statuses = findingsService.statuses.list();
	}

	ngOnInit(): void {
		const { finding } = this;
		const { status } = finding;
		this.updateStatusForm.patchValue({ status });
		this.enableUpdateStatusForm();
	}

	enableUpdateStatusForm(): void {
		this.updateStatusForm.enable();
		this.createStatusForm.disable();
	}

	enableCreateStatusForm(): void {
		this.updateStatusForm.disable();
		this.createStatusForm.enable();
	}

	submitStatusForm(): void {
		const { finding } = this;
		const { id: findingId } = finding;
		const params = { findingId };
		const {
			body,
			form,
		} = (() => {
			const {
				updateStatusForm,
				createStatusForm,
			} = this;

			if (updateStatusForm.enabled) {
				const {
					status,
				} = updateStatusForm.getRawValue();

				return {
					form: updateStatusForm,
					body: {
						...status,
					},
				};
			}

			if (createStatusForm.enabled) {
				const {
					status,
					correspondingCondition,
				} = createStatusForm.getRawValue();

				return {
					form: createStatusForm,
					body: {
						status,
						correspondingCondition,
					},
				};
			}

			throw new Error('Status modal component unexpected behavior');
		})();

		this.resolving = true;
		this.findingsService.finding.updateStatus(
			params,
			body,
		).$promise
			.then(({ status, statusDate }) => {
				Object.assign(finding, {
					status,
					statusDate,
				});
				this.modalRef.hide();
			})
			.catch(({ data: response }) => {
				form.setServerError(response);
			})
			.finally(() => {
				this.resolving = false;
			});
	}
}
