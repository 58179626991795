<div class="li-pages-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="onCancel();">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>

	<form [formGroup]="form" (onValidSubmit)="onSubmit()">
		<div class="modal-body max-h450 remote-resource" [class.resolved]="!resolving">
			<p>{{message}}</p>

			<ng-container *ngFor="let pagesGroup of pagesGroups">
				<div
					*ngIf="pagesGroup.pages.length"
					class="form-group"
					[formGroupName]="pagesGroup.id"
				>
					<h5 class="font-bold">{{pagesGroup.title}}</h5>

					<div class="rows-bordered">
						<div class="row header">
							<label class="col-md-12 flex-row gap">
								<input type="checkbox" formControlName="all"/>
								<span class="text-smaller text-normal text-muted">{{pagesGroup.title}}</span>
							</label>
						</div>

						<ng-container formGroupName="pages">
							<div class="row clickable" *ngFor="let page of pagesGroup.pages">
								<label class="col-md-12 flex-row gap">
									<input type="checkbox" [formControlName]="page.referenceId"/>
									<sm-icon [config]="page"></sm-icon>
									<div class="flex text-overflow">
										<div>{{page.name}}</div>
										<div class="text-muted" *ngIf="page.address">{{page.address}}</div>
									</div>
								</label>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="modal-footer" [class.curtain]="resolving">
			<div class="btn-group-lg">
				<button type="submit" class="btn btn-primary">{{confirmText}}</button>
				<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
			</div>
		</div>
	</form>
</div>
