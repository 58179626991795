import { Injectable, Injector } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class RecurlyPublicKeyService {
	resource: any;

	constructor(public injector: Injector) {
		const $resource = injector.get('$injector').get('$resource');
		const basePath = `${apiPath}/recurly/public-key`;

		this.resource = $resource(`${basePath}`, {}, {
			get: {method: 'get'},
		});
	}

	get = (...args) => this.resource.get(...args);
}
