import { Component, forwardRef, OnInit } from '@angular/core';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';

import {
    ScorecardComment,
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments.interface';
import { ScorecardService } from '../services/scorecard.service';


@Component({
    templateUrl: './scorecard-comments-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ScorecardCommentsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ScorecardCommentsListComponent) },
    ],
})
export class ScorecardCommentsListComponent extends NewPagedListComponent<ScorecardComment> implements OnInit {
    static listName = 'scorecardCommentsList';
    tpoId: number;

    constructor(
        public uiRouter: UIRouter,
        private readonly scorecardService: ScorecardService,
    ) {
        super(uiRouter);
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.uiRouter.globals.params.id;
        super.ngOnInit();
    }

    loadList(params: ListParams): Observable<PagedData<ScorecardComment[]>> {
        return this.scorecardService.getScorecardComments(this.tpoId, params);
    }

    refreshPage() {
        this.updateList();
    }
}
