<h2 *ngIf="directAccess">DBA</h2>

<div class="remote-resource"  [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-md-3">
                <span class="text-muted small">Search</span>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-md-3">
                <search-list placeholder="Name or NMLS ID"></search-list>
            </div>
        </div>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-5" columnName="otherTradeName">Name</sortable-header>
            <sortable-header class="col-sm-2" columnName="nmlsId">NMLS ID</sortable-header>
            <sortable-header class="col-sm-2" columnName="regulator">Regulator</sortable-header>
            <sortable-header class="col-sm-3" columnName="nameType">Name Type</sortable-header>
        </div>
        <reset-filters class="row-fluid"></reset-filters>
        <ng-container *ngIf="((list | async)?.length > 0); else noCustomers">
            <div class="row-fluid"
                *ngFor="let item of list | async"
            >
                <div class="col-md-5">{{item.otherTradeName}}</div>
                <div class="col-md-2">{{item.nmlsId}}</div>
                <div class="col-md-2">{{item.regulator}}</div>
                <div class="col-md-3">{{item.nameType}}</div>
            </div>
        </ng-container>
        <ng-template #noCustomers>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    {{filtersApplied ? 'No matches found' : 'No records added'}}
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
