import { Component } from '@angular/core';
import { Validators } from '@angular/forms';

import { StateService, UIRouterGlobals } from '@uirouter/core';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';


@Component({
    selector: 'global-search',
    templateUrl: './global-search.component.html',
})
export class GlobalSearchComponent {
    form = new RealmFormGroup({
        q: new RealmFormControl('q', { label: 'Search', updateOn: 'change' }, [Validators.required, Validators.minLength(3)]),
    });

    constructor(
        private readonly routerGlobals: UIRouterGlobals,
        private readonly stateService: StateService,
    ) {
        this.routerGlobals.success$.subscribe(t => {
            const isSearch = this.stateService.includes('search.**');
            if (!isSearch) {
                this.form.reset();
            } else {
                const { q } = t.params();
                this.form.reset({ q });
            }
        });
    }

    search() {
        if (this.form.valid) {
            this.stateService.go('search.clients', this.form.value, { reload: true });
        }
    }
}
