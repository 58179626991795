import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

@Component({
	templateUrl: './group-name-modal.component.html',
})
export class GroupNameModalComponent {
	title: string;
	onSubmit: (params?) => any;
	resolving: boolean;
	modalForm: RealmFormGroup = new RealmFormGroup(
		{
			name: new RealmFormControl(
				'name',
				{
					label: 'Name',
					updateOn: 'change',
				},
				Validators.required,
			),
		},
	);

	constructor(
		public modalRef: BsModalRef,
	) {}

	submit() {
		this.resolving = true;
		this.onSubmit(
			this.modalForm.getRawValue(),
		)
			.then(
				() => {
					this.modalRef.hide();
				},
				({ data }) => {
					this.modalForm.setServerError(data);
				},
			)
			.finally(() => {
				this.resolving = false;
			});
	}
}
