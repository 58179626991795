<div class="col-md-12 va-m remote-resource" [class.resolved]="resolved">
    <form [formGroup]="registrationPasswordForm" id="registrationPasswordForm" (onValidSubmit)="sendRegistrationRequest()">
        <form-errors [form]="registrationPasswordForm"></form-errors>
        <div class="row">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationPasswordForm.getFieldLabel('password')}}</label>
                &nbsp;
                <password-requirements-tooltip></password-requirements-tooltip>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="{{ passShowing ? 'text' : 'password'}}" name="password" formControlName="password" maxlength="75" class="form-control" tabindex="1"/>
                    </div>
                    <span class="c-icon c-icon-eye text-light password-icon" (click)="togglePassShowing()"></span>
                </div>
            </div>
        </div>
        <hr>
        <h3>Confirm Password</h3>
        <div class="row">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationPasswordForm.getFieldLabel('passwordConfirmation')}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="{{ passConfirmationShowing ? 'text' : 'password'}}" name="passwordConfirmation" formControlName="passwordConfirmation" maxlength="75" class="form-control" tabindex="2"/>
                    </div>
                    <span class="c-icon c-icon-eye text-light password-icon" (click)="togglePassConfirmationShowing()"></span>
                </div>
            </div>
        </div>
        <hr>
        <h3>
            Security Pin
            <span class="glyphicon glyphicon-question-sign text-light text-big" [tooltip]="'Comergence Support will use your 4 digit pin code to help verify your identity'"></span>
        </h3>
        <div class="row">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationPasswordForm.getFieldLabel('securityPin')}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="{{ pinShowing ? 'text' : 'password'}}" name="securityPin" formControlName="securityPin" maxlength="4" class="form-control" tabindex="3"/>
                    </div>
                    <span class="c-icon c-icon-eye text-light password-icon" (click)="togglePinShowing()"></span>
                </div>
            </div>
        </div>
        <br />
        <div class="pull-right">
            <button class="btn btn-primary-wired" (click)="goBack()" type="button">Back</button>
            <button class="btn btn-primary" type="submit" [disabled]="registrationPasswordForm.invalid">Register</button>
        </div>
    </form>
</div>
