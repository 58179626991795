import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { SocialMediaModule } from 'shared/social-media';

import { ReviewModule } from './list/review/review.module';
import { ReviewsResourceService } from './reviews-resource.service';
import { ReviewsComponent } from './reviews.component';
import {
	ReviewsListComponent,
	ReviewViewComponent,
	IndividualsModalComponent,
	AccountDisconnectedModalComponent,
	AdvertisingModalComponent,
} from './list';
import {
	ReviewSettingsAccountsComponent,
	ReviewsSettingsImagesComponent,
	UploadImageModalComponent,
	ReviewSettingsZillowComponent,
	GoogleBusinessComponent
} from './settings';
import { GoogleBusinessService } from './settings/accounts/google-business/google-business.service';
import {
	WidgetsListComponent,
	WidgetDetailsComponent,
	EmbedCodeComponent,
	EditWidgetComponent,
	ReviewWidgetService,
	ReviewWidgetResourceService,
} from './widgets';
import { SmConnectButtonModule } from './settings/accounts/sm-connect-button/sm-connect-button.module';
import { SocialAccountsModule } from 'tpo/social-accounts';

const components = [
	ReviewsComponent,
	ReviewsListComponent,
	ReviewViewComponent,
	ReviewSettingsAccountsComponent,
	ReviewsSettingsImagesComponent,
	ReviewSettingsZillowComponent,
	GoogleBusinessComponent,
	UploadImageModalComponent,
	WidgetsListComponent,
	WidgetDetailsComponent,
	EmbedCodeComponent,
	EditWidgetComponent,
	IndividualsModalComponent,
	AccountDisconnectedModalComponent,
	AdvertisingModalComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		BsDropdownModule.forRoot(),
		PipesModule,
		ReviewModule,
		SocialMediaModule,
		SmConnectButtonModule,
		SocialAccountsModule,
	],
	declarations: [
		...components,
	],
	providers: [
		ReviewsResourceService,
		ReviewWidgetService,
		ReviewWidgetResourceService,
		GoogleBusinessService,
	],
})
export class ReviewsModule {}
