<div class="remote-resource list-management" [class.resolved]="list.$resolved">

	<div class="filters-rows">
		<div class="flex-row">
			<div class="flex-grow">Selected Clients:  <strong>{{selection.length}}</strong></div>
			<div class="flex-row gap-sm">
                <div class="dropdown" dropdown [isDisabled]="!selection.length">
                    <a class="btn btn-primary btn-dropdown dropdown-toggle" dropdownToggle>
                        <span>Manage</span>
                        <span class="caret"></span>
                    </a>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
                        <li role="menuitem" *ngFor="let item of manageDropdownMenuItem">
                            <a (click)="openSelectedItemModal(item)">{{ item }}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <button class="btn btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<label class="col-sm-check">
				<input type="checkbox" [checked]="selection.length && selection.length == list.length" (change)="onSelectAll($event)">
			</label>
			<sortable-header class="col-sm-3" columnName="clientName">Client</sortable-header>
			<sortable-header class="col-sm-a" columnName="channelName">Channel</sortable-header>
			<sortable-header class="col-sm-a" columnName="approvalStatus">Relationship Status</sortable-header>
			<ng-container *ngFor="let column of columnValues()">
				<sortable-header class="col-sm-a" isColumnEnabled [columnName]="column.id">
					<span class="two-liners">{{column.name}}</span>
				</sortable-header>
			</ng-container>
		</div>
		<div class="row-fluid" *ngFor="let item of list" [class.highlighted]="selection.includes(item.tpoId)">
			<label class="col-sm-check">
				<input type="checkbox" [value]="item.tpoId" [checked]="selection.includes(item.tpoId)" (change)="onSelect($event, item.tpoId)">
			</label>
			<div class="col-sm-3">
				<a uiSref="client.:id.account.information" [uiParams]="{ id: item.tpoId }">{{item.clientName}}</a>
				<div class="text-light text-smaller">NMLS ID: {{item.nmlsId}}</div>
			</div>
			<div class="col-sm-a">
				<a uiSref="client.:id.account.channels" [uiParams]="{ id: item.tpoId }">{{item.channelName || '&ndash;'}}</a>
			</div>
			<div class="col-sm-a text-bigger"><approval-status [status]="item.approvalStatus"></approval-status></div>
			<div class="col-sm-a" isColumnEnabled columnName="statusReason">{{item.statusReason || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="statusDate">{{(item.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="location">
				<div>{{[ item.state, item.city ].join(', ') || '&ndash;'}}</div>
				<div>{{item.zip}}</div>
			</div>
			<div class="col-sm-a" isColumnEnabled columnName="accountExecutive">{{item.accountExecutive || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="renewalDate">{{(item.renewalDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="phone">{{(item.phone | tel) || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="clientUID">{{item.clientUID || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="businessEntity">{{item.businessEntity || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="stateOfInc">{{item.stateOfInc || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="compFax">{{item.compFax || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="primaryFederalRegulator">{{item.primaryFederalRegulator || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="registrationStatus">{{item.registrationStatus || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="fiscalYearEnd">{{item.fiscalYearEnd || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="businessTax">{{item.businessTax || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="dateFormed">{{(item.dateFormed | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="mersId">{{item.mersId || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="fdicNcuaCert">{{item.fdicNcuaCert || '&ndash;'}}</div>
		</div>
		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-muted text-bigger">
				{{(filtersApplied || filters.q) ? 'No matches found' : 'No clients'}}
			</div>
		</div>
	</div>
</div>
