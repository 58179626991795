<ng-container [ngSwitch]="canEdit">
    <div *ngSwitchCase="true">
        <div *ngIf="!inlineEditStyle" class="btn btn-tertiary inline-edit" (click)="showEditModal()">
            <span class="glyphicon glyphicon-pencil"></span>
            <span class="text-default">{{status?.reason || '&ndash;'}}</span>
        </div>
        <div *ngIf="inlineEditStyle">
            <span class="text-default">{{status?.reason || '&ndash;'}}</span>
            <button class="btn btn-tertiary btn-icon pull-right" (click)="showEditModal()">
                <span class="glyphicon glyphicon-pencil"></span>
            </button>
        </div>
    </div>
    <span *ngSwitchDefault>{{status?.reason || '&ndash;'}}</span>
</ng-container>
