import { CompatibilityInjectorService } from 'commons/services/compatibility/compat-injector.service';

export abstract class RootScopeService {
	$broadcast: (name, args?) => any;
	$emit: (name, args?) => any;
}

export const RootScopeServiceProvider = {
	provide: RootScopeService,
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('$rootScope'),
	deps: ['$injector'],
};

export const $rootScopeServiceProvider = {
	provide: '$rootScope',
	useFactory: ($injector: CompatibilityInjectorService) => $injector.get('$rootScope'),
	deps: ['$injector'],
};
