import { Component } from '@angular/core';
import { BackNavigationFilter } from 'commons/components/breadcrumbs/BackNavigationFilter';

import { GroupAlertsStatusService } from './group-alerts-status.service';

@Component({
	templateUrl: './listing-group-details.component.html',
	providers: [ GroupAlertsStatusService ],
})
export class CompanyListingGroupDetailsComponent {
	tabs = [
		{
			state: '.overview',
			title: 'Overview',
		},
		{
			state: '.settings',
			title: 'Settings',
		},
		{
			state: '.alerts',
			title: 'Alerts',
			exclamation$: this.groupAlertsStatusService.exclamation$,
		},
	];

    public readonly backNavigationFilter: BackNavigationFilter =
        BackNavigationFilter.consecutive(
            BackNavigationFilter.or([
                BackNavigationFilter.stateNamePattern('**.listings.company'),
                BackNavigationFilter.stateNamePattern('**.listings.company.**'),
            ])
        );

	constructor(
		private groupAlertsStatusService: GroupAlertsStatusService,
	) {}
}
