<div class="container">
    <div class="row-fluid">
        <div
            [id]="ownershipVerificationId"
            class="col-md-6 text-left"
        >
            <div class="row">
                <label class="control-label col-sm-6">Ownership Verification</label>
                <div
                    *ngIf="channel"
                    [popover-edit]
                    fieldName="ownershipVerificationPercentage"
                    [value]="channel.ownershipVerificationPercentage"
                    [externalContainerElementId]="ownershipVerificationId"
                    [maxlength]="3"
                    [validators]="ownershipPercentageValidators"
                    [readOnly]="!canManageChannelSettings"
                    (saveTriggered)="triggerPatch($event)"
                    [popoverContainerClasses]="'flex-row space-between align-top'"
                    class="col-sm-6"
                >
                    {{ channel.ownershipVerificationPercentage + '%' }}
                </div>
            </div>
        </div>
        <div class="col-md-6 text-right">
            <div class="row">
                <a uiSref="channel.:id.alerts" [uiParams]="{id: channel.id}" class="col-sm-a va-m">Manage Alerts</a>
                <button class="btn btn-tertiary col-sm-a">
                    <a uiSref="channel.:id.history" [uiParams]="{id: channel.id}">
                        <span class="c-icon c-icon-back-in-time"></span> History
                    </a>
                </button>
            </div>
        </div>
    </div>

    <div class="row-fluid">
        <div class="col-sm-12 text-left">
            <div class="row">
                <div class="col-sm-2">
                    <label class="control-label solid">Description</label>
                </div>
                <div
                    *ngIf="channel"
                    [popover-edit]
                    fieldName="description"
                    [relativePosition]="'bottom left'"
                    [adaptivePosition]="false"
                    [value]="channel.description"
                    [inputType]="'textarea'"
                    [maxlength]="300"
                    [requiredValidator]="false"
                    [validators]="descriptionValidators"
                    [readOnly]="!canManageChannelSettings"
                    (saveTriggered)="triggerPatch($event)"
                    class="col-sm-10"
                >
			        <show-more
                        *ngIf="showTruncatedDescription; else showFullDescription"
                        [text]="channel.description || '&ndash;'"
                        [showMoreText]="''"
                        [bypassShowMore]="true"
                    ></show-more>
                    <ng-template #showFullDescription>
                        {{ channel.description || '&ndash;' }}
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
