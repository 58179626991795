<p>{{question.text}}</p>
<div class="flex-row gap-m">
	<span class="control-label">Question Type</span>
	<b>{{questionType}}</b>
	<span class="label label-success text-smaller" *ngIf="question.questionBankQuestionId">From Bank</span>
</div>

<div class="flex">
	<div class="answer-list counter alpha" *ngIf="question.answers?.length">
		<div class="row-fluid text-muted">
			<div class="control-value"
				[class.coldp-md-6]="isSkipLogicColumn"
				[class.coldp-md-11]="!isSkipLogicColumn"
			>
				Answer Options
			</div>
			<div class="coldp-md-5 control-value" *ngIf="isSkipLogicColumn">Skip Logic To Question</div>
			<div class="coldp-md-5 control-value">Explanation Required</div>
			<div class="coldp-md-8 control-value" *ngIf="!isBankQuestion">Document</div>
		</div>
		<div class="row-fluid" *ngFor="let answer of question.answers">
			<div
				[class.coldp-md-6]="isSkipLogicColumn"
				[class.coldp-md-11]="!isSkipLogicColumn"
				[class.countee]="question.questionType === QuestionType.SINGLE_SELECT || question.questionType === QuestionType.MULTI_SELECT"
			>
				{{answer.text}}
			</div>
			<div class="coldp-md-5" *ngIf="isSkipLogicColumn">
				<span [class.text-muted]="!answer._skipLogic">{{ answer._nextQuestion?.title || '&ndash;'}}</span>
			</div>
			<div class="coldp-md-5"
				 [class.text-muted]="!answer.explanationRequired">{{answer.explanationRequired ? 'YES' : 'NO'}}</div>
			<div class="coldp-md-8 flex-row" *ngIf="!isBankQuestion">
				<div *ngIf="answer.documents?.length; else noDocuments" class="ellipsis mxw100">
					<div *ngFor="let document of answer.documents" [tooltip]="document.documentName">
						{{document.documentName}}
					</div>
				</div>
				<ng-template #noDocuments>&ndash;</ng-template>
			</div>
		</div>
	</div>
</div>
