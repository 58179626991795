import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DocumentCardContainerComponent } from './container/document-card-container.component';
import { DocumentCardComponent } from './card/document-card.component';
import { DocumentCardSummaryComponent } from './card/summary/document-card-summary.component';
import { ChannelsScheduleSettingsComponent } from './card/channels/document-card-channels.component';
import { ChannelScheduleSettingsEntryComponent } from './card/channels/channel-schedule-settings/channel-schedule-settings.component';
import { ChannelBulkRequestModalComponent } from 'shared/document-schedule-settings-card/card/channels/bulk-request-modal/channel-bulk-request-modal.component';


const components = [
    DocumentCardContainerComponent,
    DocumentCardComponent,
    DocumentCardSummaryComponent,
    ChannelsScheduleSettingsComponent,
    ChannelScheduleSettingsEntryComponent,
    ChannelBulkRequestModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
    ],
	declarations: [
        ...components,
	],
	exports: [
		...components,
	],
	providers: [
	],
})
export class DocumentScheduleSettingsCardModule {}
