<section class="social-compliance-publisher-templates">
	<h3>
		Templates
		<div class="pull-right" *ngIf="User.can('TPO_PB_MANAGE_TEMPLATES')">
			<button type="button" class="btn btn-primary"
			        (click)="toggleAddTemplates(true)"
			        [disabled]="showAddTemplates"
			>
				<span class="glyphicon glyphicon-plus small-icon"></span>
				Add
			</button>
		</div>
	</h3>

	<form-errors [form]="addTemplatesForm"></form-errors>

	<div class="rows-bordered remote-resource" [class.resolved]="list.$resolved">
		<div class="row-fluid header">
			<div class="col-sm-10" [ngClass]="getSortClass('summary')" (click)="setSort('summary')">
				<b>Template</b>
			</div>
			<div class="col-sm-2"></div>
		</div>

		<form class="row-fluid highlighted" *ngIf="showAddTemplates"
		      [formGroup]="addTemplatesForm"
		      (onValidSubmit)="submitAddTemplates()"
		>
			<div class="col-sm-5 va-m" [class.has-error]="addTemplatesForm.get('templates').invalid && addTemplatesForm.get('templates').touched">
				<ng-select
					[loading]="!addTemplatesCandidates?.$resolved"
					formControlName="templates"
					[items]="addTemplatesCandidates?.$promise | async"
					bindLabel="summary"
					[searchable]="true"
					[multiple]="true"
					[closeOnSelect]="false"
					placeholder="Select Templates"
				></ng-select>
			</div>
			<div class="col-sm-a va-m"></div>
			<div class="col-sm-3 va-m text-right">
				<button type="submit" class="btn btn-primary">Save</button>
				<button type="button" class="btn btn-secondary" (click)="toggleAddTemplates(false)">Cancel</button>
			</div>
		</form>

		<ng-container *ngFor="let item of list">
			<div class="row-fluid relative ">
				<div class="col-sm-10 va-m">
					<div class="preview">
						<div class="image-holder" [ngSwitch]="item.hasImageAttachment">
							<img *ngSwitchCase="true"
							     srcTest="/api/rest/tpos/{{tpoId}}/social-media-post-templates/{{item.id}}/image-preview">
							<i *ngSwitchCase="false" class="c-icon c-icon-user-rounded"></i>
						</div>
						<div class="description" *ngIf="item.summary || item.tags.length">
							{{ item.summary }}
						</div>
					</div>
				</div>
				<div class="col-sm-2 va-m text-right dropdown-menu-col-fix">
					<a class="btn btn-default" uiSref="social-compliance.publisher.library.templates.:templateId" [uiParams]="{ templateId: item.id }">View</a>
					&nbsp;
					<div class="dropdown display-inline" dropdown *ngIf="User.can('TPO_PB_MANAGE_TEMPLATES')">
						<a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
							<span class="c-icon c-icon-dot-3"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li role="menuitem">
								<a (click)="removeTemplate(item.id)">Remove</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="row-fluid row-remove-overlay" *ngIf="item.id === removeTemplateId">
					<div class="col-sm-9 va-m">
						Are you sure you want to remove this template?
					</div>
					<div class="col-sm-3 va-m text-right">
						<button type="button" class="btn btn-primary" (click)="confirmRemoveTemplate()">Remove</button>
						<button type="button" class="btn btn-secondary" (click)="resetRemoveTemplate()">Cancel</button>
					</div>
				</div>
			</div>
		</ng-container>

		<div class="row-fluid" *ngIf="!list.length">
			<div class="col-sm-12 text-center text-bigger text-muted">No templates</div>
		</div>
	</div>
	<br>
	<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[5,10,25,50]"></new-paging>
</section>
