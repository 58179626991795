import { Injectable } from '@angular/core';

import { NgResourceObject, NgResourceArray } from 'commons/interfaces';

import { TemplatesResourceService } from './templates-resource.service';
import { TypeNames } from 'tpo/social-compliance/publisher/campaigns/$campaignId/share-to/share-to.types';

@Injectable()
export class TemplatesService {
	constructor(
		public templatesResource: TemplatesResourceService,
	) {}

	template = (templateId: number, success?, error?): NgResourceObject<Template> => (
		this.templatesResource.template.get({ templateId }, success, error)
	);
    createTemplate = (...args) => this.templatesResource.template.create(...args);
    updateTemplate = (...args) => this.templatesResource.template.update(...args);
    removeTemplate = (...args) => this.templatesResource.template.remove(...args);
	create = (): NgResourceObject<Template> => {
        const result = {
            attachments: [],
            isEditable: true,
        } as NgResourceObject<Template>;
        Object.defineProperty(result, '$resolved', { enumerable: false, writable: true, value: true });
        Object.defineProperty(result, '$promise', { enumerable: false, value: Promise.resolve(result) });
        return result;
    };
	validate = (text): NgResourceObject<ValidateResponse> => this.templatesResource.template.validate({}, { text });
	tagHints = (text): NgResourceArray<Tag> => this.templatesResource.template.tags({ q: text });
	additionalInfo = (templateId: number, template: Template): NgResourceObject<{
		hasNonPublishedPosts: boolean,
		werePostRelatedFieldsChanged: boolean
	}> => (
		this.templatesResource.template.additionalInfo({ templateId }, template)
	);
	templateMove = (templateId: number, targetFolderId: number, success, error): NgResourceObject<Template> => (
		this.templatesResource.template.move(
			{ templateId },
			{ ...(targetFolderId !== null && { targetFolderId }) },
			success,
			error,
		)
	);

	templates = (params: TemplatesQueryParams): NgResourceArray<Post> => this.templatesResource.templates.query(params);
	tags = (): NgResourceArray<Tag> => this.templatesResource.templates.tags();

	folder = (folderId): NgResourceObject<Folder> => this.templatesResource.folder.get({ folderId });
	folders = (): NgResourceArray<Folder> => this.templatesResource.folder.query();
	folderCreate = (dto: Partial<Folder>, success, error): NgResourceObject<Folder> => (
		this.templatesResource.folder.create(dto, success, error)
	);
	folderEdit = (folderId, dto: Partial<Folder>, success, error): NgResourceObject<Folder> => (
		this.templatesResource.folder.edit({ folderId }, dto, success, error)
	);
	folderRemove = ( params: { folderId: number, moveToFolder?: number }, success?, error?): { success: boolean } => {
		const { folderId, moveToFolder } = params;
		return this.templatesResource.folder.remove(
			{ folderId, ...(moveToFolder !== undefined ? { moveToFolder, confirmation: true } : []) },
			success,
			error,
		)
	};

	templateNetworks = (): NgResourceArray<TemplateNetwork> => this.templatesResource.templateNetworks.query();
}

export interface ValidateResponse {
	hasTriggers: boolean;
	text: string;
}

interface TemplatesQueryParams {
	tagId?: number[];
	campaignId?: number[];
	folderId?: number[];
	q?: string;
	page?: number;
	size?: number;
	sort?: string[];
}

export interface Post {
	id: number;
	summary: string;
	hasImageAttachment: boolean;
	isVideoThumbnail: boolean;
	updatedDate: number;
	updatedBy: string;
	tags: [];
	type: 'folder' | 'template';
	folderName: string;
	name: string;
	text: string;
}

interface Template {
	assetSharing: AssetSharing;
	attachments: Array<{
		id: number;
		name: string;
		type: 'IMAGE' | 'VIDEO'
	}>,
	id: number;
	isApproved: boolean;
	mediaLink: string;
	selectedThumbnails: { [attachmentId: number]: number } | null;
	summary: string;
	tags: string[];
	text: string;
	uploadedManuallyThumbnails: { [attachmentId: number]: number } | null;
	templateTargetNetworkCodes?: string[];
	targetNetworkCodes?: string[];
	isEditable: boolean;
}

export interface Folder {
	id: number;
	name: string;
	assetSharing: AssetSharing;
}

export interface AssetSharing {
	sharingType: {
		id: string;
		name: string;
	};
	shareTo: Array<{
		id: number;
		name: string;
		membersCount?: number;
	}> | [];
}

interface Tag {
	id: number;
	name: string;
}

export interface TemplateNetwork {
	id: string;
	name: string;
}
