import { BehaviorSubject, Observable } from 'rxjs';

export abstract class AbstractIssueStatus {
	protected _initialized: boolean = false;
	private _subject: BehaviorSubject<boolean>;

	constructor() {

	}

	get(forceUpdate: boolean = false): Observable<boolean> {
        const subject = this.subject;
		if ( forceUpdate ) {
			this.update();
		}
		return subject;
	}

	async update() {
        // Don't block UI if status returns error.
        try {
            this.subject.next(await this.updateValue());
        } catch (e) {
            this.subject.next(false);
        }
	}

	protected get subject(): BehaviorSubject<boolean> {
		// Lazy init
		if ( !this._initialized ) {
			// Replay latest value on new subscribes is needed if we want to process value later with combineLatest
			this._initialized = true;
			this._subject = new BehaviorSubject(false);
			this.update();
		}
		return this._subject;
	}

	protected abstract updateValue(): Promise<boolean>;
}
