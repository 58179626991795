import { Component, HostBinding, HostListener, Input, OnInit, OnChanges, OnDestroy, Host } from '@angular/core';
import { Subscription } from 'rxjs';

import { ListComponent } from '../components/list/list.component';

@Component({
	selector: 'sortable-header[columnName]',
	template: `<b class="header-title"><ng-content></ng-content></b>`,
})
export class SortableHeaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input('columnName') columnName: string;
	@Input('active') active: boolean = true;
	@HostBinding('class.sortable') public classSortable: boolean = true;
	@HostBinding('class.not-active') public classDisabled: boolean = true;
	@HostBinding('class.asc') public classAsc: boolean = false;
	@HostBinding('class.desc') public classDesc: boolean = false;
	public unsubParams: Subscription;

	constructor(@Host() public listComponent: ListComponent) {
	}

	@HostListener('click') onClick() {
		if (!this.active) {
			return;
		}

		this.listComponent.setSort(this.columnName);
	}

	ngOnInit(): void {
		this.unsubParams = this.listComponent.params$.subscribe((value) => {
			this.classAsc = (value.sort === `${this.columnName},asc`);
			this.classDesc = (value.sort === `${this.columnName},desc`);
		});
	}

	ngOnChanges() {
		this.classDisabled = !this.active;
	}

	ngOnDestroy(): void {
		if (this.unsubParams) {
			this.unsubParams.unsubscribe();
		}
	}
}
