import { Directive, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ContactList } from '../investor-crm-contact-list.interface';
import { InvestorCrmContactListService } from '../services/investor-crm-contact-list.service';


@Directive()
export class BaseInvestorCrmContactListComponent implements OnInit {
    readonly userProfile: UserProfile;
    userCanManageContactLists: boolean = false;

    loadingContactList: boolean = false;
    existingContactList: ContactList;

    constructor(
        protected readonly investorCrmContactListService: InvestorCrmContactListService,
        protected readonly routerGlobals: UIRouterGlobals,
        protected readonly userService: UserService,
    ) {
        this.userProfile = this.userService.profile;
    }

    // Override
    ngOnInit(): void {
        this.userCanManageContactLists = this.userProfile.can('MANAGE_CONTACT_LIST');
    }

    async retrieveExistingList(): Promise<void> {
        this.loadingContactList = true;

        try {
            this.existingContactList = await this.investorCrmContactListService.getContactList(
                this.userProfile.organization.organizationId,
                this.routerGlobals.params.listId,
            ).toPromise();
        } finally {
            this.loadingContactList = false;
        }
    }
}
