<div class="review-findings">
	<h3 class="clearfix">
		<span class="pull-left">
			Findings <span class="text-muted" *ngIf="list.$resolved">{{ list.$total }}</span>&nbsp;&nbsp;
		</span>

		<span class="pull-left">
			Tasks <span class="text-muted" *ngIf="list.$resolved">{{this.list[0]?.$taskCount || 0}}</span>
		</span>

		<span class="flex-row gap pull-right">
			<task-notification-button
				*ngIf="review && showNotify"
				[tpoId]="review.tpoId"
				[nmlsId]="review.nmlsId"
				[contactId]="review.contactId"
				[contactName]="review.contactFirstName + ' ' + review.contactLastName"
				[disabled]="!canNotify"
				class="btn-secondary"
			></task-notification-button>

			<a
				*ngIf="canAddFinding"
				uiSref="social-compliance.findings.new"
				[uiParams]="{ reviewId: reviewId }"
				class="btn btn-primary"
			>
				<span class="glyphicon glyphicon-plus small-icon"></span> Add Finding
			</a>
		</span>
	</h3>

	<div class="rows-bordered remote-resource" [ngClass]="{resolved: list.$resolved}">
		<div class="row-fluid header">
			<sortable-header class="col-sm-a" columnName="link">Account</sortable-header>
			<sortable-header class="col-sm-1" columnName="findingId">ID</sortable-header>
			<sortable-header class="col-sm-2" columnName="findingStatus">Finding Status</sortable-header>
			<sortable-header class="col-sm-2" columnName="remediationStatus">Task Status</sortable-header>
		</div>

		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<div class="col-sm-12 text-center va-m">Filters are applied.
				<a (click)="resetFilters()">Reset filters</a> to see all results
			</div>
		</div>

		<a class="row-fluid list clickable" *ngFor="let item of list"
		   uiSref="social-compliance.findings.:actionType.:findingId"
		   [uiParams]="{findingId: item.id, actionType: 'edit'}">
			<div class="col-sm-a text-bigger va-m">
				<div class="account-holder">
					<sm-network-icon [config]="item.mediaLink" [minified]="true"></sm-network-icon>

					<div class="image-holder">
						<img *ngIf="item.hasScreenshot; else iconUser"
							 [src]="getSource(tpoId, item)"
							 class="screenshot-preview"
							 alt="screenshot-preview">
						<ng-template #iconUser><i class="c-icon c-icon-user-rounded"></i></ng-template>
					</div>

					<span class="link-holder">{{item.mediaLink.name || item.mediaLink.link}}</span>
				</div>
			</div>
			<div class="col-sm-1 va-m">
				<span>{{item.id || '&ndash;'}}</span>
			</div>
			<div class="col-sm-2 text-bigger va-m">
				<span *ngIf="!item.isRemoved" class="label mxw100 truncate"
					  [ngClass]="statuses[item?.status?.correspondingCondition]?.color">
					{{item.status?.status}}
				</span>
				<span *ngIf="item.isRemoved" class="label label-success">Removed</span>
			</div>
			<div class="col-sm-2 text-bigger va-m">
				<span [class.label]="item.remediationStatus"
					  [class.label-warning]="[ 'Open', 'Reopened' ].includes(item.remediationStatus)"
					  [class.label-success]="item.remediationStatus == 'Completed'">
					<span class="text-capitalize text-lowercase">{{item.remediationStatus || '&ndash;'}}</span>
				</span>

				<span *ngIf="item?.observations?.length" class="observation-icon glyphicon glyphicon-question-sign"
					  [popover]="observation"
					  placement="bottom"
					  triggers="mouseenter:mouseleave">
				</span>
				<ng-template #observation>
					<ul>
						<li *ngFor="let observation of item.observations">
							<div class="observation-text">{{observation}}</div>
						</li>
					</ul>
				</ng-template>
			</div>
		</a>
		<div class="row-fluid" *ngIf="list?.$resolved && !list.length">
			<div class="col-sm-12 text-center text-light text-bigger">
				{{(filtersApplied || filters.q) ? 'No matches found' : 'No findings'}}
			</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[5, 10, 25, 50]"></new-paging>
	</div>
</div>
