<header>
	<span class="text-muted">{{title}}</span>
	<button
		*ngIf="!current && !screenshotUrl && canManage"
		class="btn btn-primary"
		(click)="createPostImg()"
		[disabled]="screenshotLoading"
	>
		<span class="glyphicon glyphicon-plus small-icon"></span> Add Issue
	</button>
</header>
<div class="post-wrapper" [class.current]="current">
	<div #postContent *ngIf="current || !screenshotUrl; else postScreenshot" class="post-content">
		<ng-container *ngIf="post.$resolved">
			<ng-container *ngIf="current; then currentPostTpl; else postTpl"></ng-container>
			<ng-template #currentPostTpl>
				<ng-container *ngIf="!finding.mediaLink.linkId; then notMonitoredTpl; else currentPostTpl"></ng-container>
				<ng-template #currentPostTpl>
					<ng-container *ngIf="post.deleted; then deletedTpl; else currentPostTpl"></ng-container>
					<ng-template #currentPostTpl>
						<ng-container *ngIf="!post.isDiff; then noDiffTpl; else postTpl"></ng-container>
					</ng-template>
				</ng-template>
			</ng-template>
		</ng-container>
	</div>
	<div class="loader remote-resource" [class.resolved]="current || (post.$resolved && !screenshotLoading)"></div>
</div>

<ng-template #postScreenshot>
	<finding-screenshot
			[finding]="finding"
			[screenshot]="screenshotUrl"
			[allowZoom]="false"
	></finding-screenshot>
</ng-template>

<ng-template #postTpl>
	<sm-post>
		<sm-post-header [post]="post">
			<span *ngIf="post.hasTriggers" class="c-icon c-icon-notification"></span>
		</sm-post-header>

		<sm-post-message [post]="post"></sm-post-message>

		<sm-post-attachments [post]="post"></sm-post-attachments>

		<sm-post-comments #postComments
				[post]="post"
				[expandAllCommentsSubject]="expandAllCommentsSubject"
		></sm-post-comments>
	</sm-post>
</ng-template>

<ng-template #notMonitoredTpl>
	<div class="text-center">
		<p class="text-muted">This is not a monitored post. Only the original post is available for viewing.</p>
	</div>
</ng-template>

<ng-template #deletedTpl>
	<div class="text-center">
		<img src="/static/images/promo/smc/cross.svg"/>
		<br>
		<br>
		<p class="text-muted">Post has been deleted</p>
	</div>
</ng-template>

<ng-template #noDiffTpl>
	<div class="text-center">
		<img src="/static/images/promo/smc/question-mark.svg"/>
		<br>
		<br>
		<p class="text-muted">Post has not been updated</p>
	</div>
</ng-template>
