<ng-container *ngIf="[ 'WS', 'RS', 'RSS', 'OT' ].includes(config.networkCode) || config.isManual; then linkTpl; else connectedAccountTpl"></ng-container>
<ng-template #linkTpl>
	<sm-network-icon
		[config]="networkIconConfig"
		[className]="networkIconClassName"
		[showTooltip]="false"
	></sm-network-icon>

	<span *ngIf="showRssStatus && hasRss"
		class="c-icon c-icon-wifi sm-icon icon-light-green"
		[ngClass]="smIconBadgeClassName"
		[tooltip]="showTooltip && 'RSS'"
		placement="top"
	></span>
</ng-template>
<ng-template #connectedAccountTpl>
	<span
		[ngClass]="smIconProfileImageClassName"
		[style.background-image]="sanitize(config.profilePictureUrl)"
	></span>

	<sm-network-icon
		[config]="{ networkCode: config.networkCode, title: config.title }"
		[className]="smIconBadgeClassName"
		[showTooltip]="showTooltip"
	></sm-network-icon>
</ng-template>
