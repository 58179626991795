import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
} from '@angular/core';

interface UploaderConfig {
	multiple?: boolean;
	accept?: string;
}

const defaultConfig: UploaderConfig = {
	multiple: false,
	accept: '',
};

@Component({
	selector: 'uploader',
	templateUrl: './uploader.component.html',
})
export class UploaderComponent {
	@Input()
	set config(value) {
		this._config = {
			...defaultConfig,
			...value,
		};
	}
	get config() {
		return this._config;
	}
	private _config: UploaderConfig = defaultConfig;
	@Input() customTemplate: TemplateRef<any>;

	@Output() select = new EventEmitter<FileList>();

	onFileInput(e): void {
		this.select.emit(e.target.files);
		e.target.value = null; // needed to select the same file again
	}
}
