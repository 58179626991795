import { Component, Input, OnInit } from '@angular/core';

import { DocumentCardDetails, DocumentCardSummary } from 'shared/document-schedule-settings-card/document-card.interface';


@Component({
    selector: 'document-card-summary',
    templateUrl: 'document-card-summary.component.html',
})
export class DocumentCardSummaryComponent<S extends DocumentCardSummary, D extends DocumentCardDetails> implements OnInit {
    @Input() protected documentSummary: S;
    @Input() detailsLoaded: boolean = false;

    // Override
    ngOnInit(): void {
    }
}
