import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { TpoShareRequestsService, ApprovalDetails, APPROVAL_TYPE } from '../tpo-share-requests.service';

@Component({
    templateUrl: './request-review.modal.component.html',
})
export class RequestReviewModalComponent implements OnInit {
    public User: UserProfile;

    investorId: number;
    shareRequestId: number;
    approvalType: APPROVAL_TYPE;
    resolved = false;
    request: ApprovalDetails;
    form = new RealmFormGroup({
        check: new RealmFormControl(
            'check',
            {
                label: 'Check',
                updateOn: 'change',
            },
            Validators.requiredTrue,
        ),
    });
    onFinalize: () => void;

    constructor(
        { profile }: UserService,
        public readonly modalRef: BsModalRef,
        private readonly tpoShareRequestsService: TpoShareRequestsService,
    ) {
        this.User = profile;
    }

    async ngOnInit() {
        try {
            this.resolved = false;

            this.request = await this.tpoShareRequestsService.getInvestorShareApprovalDetails(this.shareRequestId, this.approvalType).toPromise();
        } finally {
            this.resolved = true;
        }
    }

    async accept(): Promise<void> {
        if (this.form.valid) {
            try {
                this.resolved = false;

                await this.tpoShareRequestsService.acceptShareApprovalRequest(this.shareRequestId, this.approvalType).toPromise();

                this.modalRef.hide();
                this.onFinalize?.();
            } finally {
                this.resolved = true;
            }
        }
    }

    async decline(): Promise<void> {
        try {
            this.resolved = false;

            await this.tpoShareRequestsService.declineShareApprovalRequest(this.shareRequestId, this.approvalType).toPromise();

            this.modalRef.hide();
            this.onFinalize?.();
        } finally {
            this.resolved = true;
        }
    }
}
