<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">&times;</button>
	<h4 class="modal-title">{{title}}</h4>
</div>

<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<form [formGroup]="form" id="manageBankCategoryForm" (onValidSubmit)="confirm()">
		<form-errors [form]="form"></form-errors>

		<div class="form-group row" has-error>
			<label class="col-sm-4 control-label text-right aligned-label required">{{getFormControl('name').label}}</label>
			<div class="col-sm-6">
				<input type="text" class="form-control" formControlName="name" maxlength="255" tabindex="1">
			</div>
		</div>
	</form>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="btn-group-lg">
		<input type="submit" form="manageBankCategoryForm" value="Save" class="btn btn-primary" />
		<button type="button" (click)="cancel()" class="btn btn-default">Cancel</button>
	</div>
</div>
