import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { UserService } from 'angularjs-providers/user.provider';

import { FilterDefault } from 'commons/components/list/list.interface';
import { NgResourceArray } from 'commons/interfaces';

declare let apiPath: string;

@Injectable()
export class LenderQuestionnairesListService {
	lenderQuestionnaires: any;
	resource: any;

	constructor(
		public injector: Injector,
		public User: UserService,
		protected http: HttpClient,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const defaultParams = { organizationId: User.profile.organization.organizationId };
		const boardPath = `${apiPath}/organization/:organizationId/questionnaires-board`;

		// https://r5-dev.ccmdev.com/swagger-ui.html#/questionnaire45board45resource
		this.lenderQuestionnaires = PagedResource(boardPath, defaultParams, {
			list: {
				method: 'get',
				isArray: true,
			},
		});

		this.resource = $resource(boardPath, defaultParams, {
			statuses: {
				url: `${boardPath}/statuses`,
				method: 'GET',
				isArray: true,
			},
			questionnaires: {
				url: `${boardPath}/questionnaires`,
				method: 'GET',
				isArray: true,
			},
		});
	}

	list = (params: QuestionnaireBoardParams): NgResourceArray<QuestionnaireBoard> => this.lenderQuestionnaires.list(params);
	statuses = (...args): NgResourceArray<string> => this.resource.statuses(...args);
	questionnaires = (...args): NgResourceArray<FilterDefault> => this.resource.questionnaires(...args);

	exportQuestionnaires(tpoQuestionnaireIds: number[]): Observable<HttpResponse<File>> {
		return this.http.post<File>(
			'/api/download/questionnaires/export',
			{ tpoQuestionnaireIds },
			{ responseType: 'blob' as 'json', observe: 'response' },
		);
	}
}

export interface QuestionnaireBoard {
	companyName: string;
	nmlsId: string;
	questionnaireName: string;
	questionnaireId: number;
	tpoQuestionnaireId: number;
	assignedDate: number;
	status: string | 'CN' | 'C' | 'IP';
	contactName: string;
	contactId: number;
	tpoId: number;
}

interface QuestionnaireBoardParams {
	organizationId?: number;
	status?: string[];
	questionnaire?: number;
	assignedAfter?: string; // formatted as YYYY-MM-DD
	assignedBefore?: string; // formatted as YYYY-MM-DD
}
