import { Component, Input } from '@angular/core';

import { MfsFindingSummary } from '../interfaces/financial-sanctions.interface';


@Component({
    selector: 'nmls-individual-financial-sanctions',
    templateUrl: './nmls-individual-financial-sanctions.component.html',
})
export class NmlsIndividualFinancialSanctionsComponent {
    @Input() financialSanctionsResolving: boolean;
    @Input() financialSanctions: MfsFindingSummary[];
}
