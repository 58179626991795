import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { CCMClientMenuComponent } from 'comergence/tpo/$id/client-menu.component';
import { CCMClientMenuContainerComponent } from 'comergence/tpo/$id/client-menu-container.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        CCMClientMenuComponent,
        CCMClientMenuContainerComponent,
    ],
    providers: [
    ],
})
export class CCMLenderProfile {
}
