import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

import { SmNetworkConnector } from '../connect/connectors';

@Component({
	templateUrl: './connects-modal.component.html',
})

export class ConnectsModalComponent {
	title: string;
	message: string;
	services: SmNetworkConnector[];
	resolving: boolean;
	companyName: string;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			title: 'Connect Account',
			message: 'To allow Optimal Blue access to your social media accounts, you must first connect and give authorization for each account.',
			services: [{
				name: 'Developer should always fill at least <b>services</b> property',
				icon: `c-icon c-icon-wifi`,
				click: () => null,
			}],
			resolving: false,
		});
	}
}
