import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ChannelAlert,
} from 'lender/manage-channels/manage-channel-alerts/manage-channel-alerts.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NumberValidator, PercentageValidator } from 'commons/validators';

@Component({
    templateUrl: 'manage-channel-alerts-item.component.html',
    selector: 'manage-channel-alerts-item',
})
export class ManageChannelAlertItemComponent implements OnInit {

    @Input() alert: ChannelAlert;
    @Input() set editing(editing: boolean) {
        if(this._editing && !editing) {
            this.alertForm.reset(this.alert);
        }
        this._editing = editing;
    }
    get editing() {
        return this._editing;
    }
    @Output() alertChanged: EventEmitter<ChannelAlert> = new EventEmitter<ChannelAlert>();
    @Output() isFormCurrentlyValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    alertForm: RealmFormGroup = new RealmFormGroup({
        id: new RealmFormControl(
            'id',
            {
                label: 'id',
            }
        ),
        alertCategoryCode: new RealmFormControl(
            'alertCategoryCode',
            {
                label: 'alertCategoryCode',
            }
        ),
        alertCategory: new RealmFormControl(
            'alertCategory',
            {
                label: 'alertCategory',
            }
        ),
        alertType: new RealmFormControl(
            'alertType',
            {
                label: 'alertType',
            }
        ),
        alertTypeCode: new RealmFormControl(
            'alertTypeCode',
            {
                label: 'alertTypeCode',
            }
        ),
        alertGroup: new RealmFormControl(
            'alertGroup',
            {
                label: 'alertGroup',
            }
        ),
        enabled: new RealmFormControl(
            'enabled',
            {
                label: 'enabled',
                updateOn: 'change',
            }
        ),
        settingValue: new RealmFormControl(
            'settingValue',
            {
                label: 'settingValue',
                updateOn: 'change',
            },
            [NumberValidator(), PercentageValidator()]
        ),
    });

    shouldShowPercentage: boolean;
    private _editing: boolean = false;

    constructor() {
    }

    ngOnInit() {
        this.shouldShowPercentage = (this.alert.alertCategory.toString() == 'Ownership Change');
        this.alertForm.patchValue(this.alert);
    }

    onAlertChanged() {
        if(this.alertForm.valid) {
            this.alertChanged.emit(this.alertForm.value);
        }
    }

    onPercentChanged() {
        this.isFormCurrentlyValid.emit(this.alertForm.valid);
        this.onAlertChanged();
    }
}
