import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';

import { Observable } from 'rxjs';
import {
    AssignedUser,
    DueDiligenceReview,
    Status,
    StatusTransition,
} from 'comergence/due-diligence/due-diligence-list.interface';

declare let apiPath;

@Injectable({ providedIn: 'root' })
export class DueDiligenceListService {

    constructor(
        protected http: RealmHttpClient,
    ) {
    }

    getDueDiligenceList(params): Observable<PagedData<DueDiligenceReview[]>> {
        return this.http.pagedRequest<DueDiligenceReview[]>(
            'GET',
            `${apiPath}/reviews`,
            params
        )
    }

    getAssignedUsers(): Observable<AssignedUser[]> {
        return this.http.request<AssignedUser[]>(
            'GET',
            `${apiPath}/reviews/assigned-users`
        )
    }

    getStatusTransitions(): Observable<StatusTransition[]> {
        return this.http.request<StatusTransition[]>(
            'GET',
            `${apiPath}/reviews/statuses/transitions`
        )
    }

    getStatuses(): Observable<Status[]> {
        return this.http.request<Status[]>(
            'GET',
            `${apiPath}/reviews/statuses`
        )
    }

    updateAssignedUser(reviewId: number, assignee: AssignedUser): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/reviews/${reviewId}/assignee`,
            null,
            assignee
        )
    }

    updateStatus(reviewId: number, status: Status): Observable<Status> {
        return this.http.request<Status>(
            'PUT',
            `${apiPath}/reviews/${reviewId}/status`,
            null,
            status
        )
    }
}
