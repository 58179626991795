import { ChangeDetectionStrategy, Component, computed, contentChildren, effect, input, TemplateRef } from '@angular/core';

@Component({
    selector: 'columns',
    templateUrl: './columns.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[style.--col-gap.px]': 'gap()',
    },
})
export class ColumnsComponent {
    children = contentChildren('cell', { read: TemplateRef });
    count = input.required<number>();
    gap = input<number>(30);
    protected columns = computed(() => {
        const count = this.count();
        const children = this.children();
        return children.reduce((acc, child, index) => {
            const columnIndex = index % count;
            acc[columnIndex].push(child);
            return acc;
        }, Array(count).fill(true).map(() => []));
    });
}
