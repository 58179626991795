<div class="remote-resource" [class.resolved]="list.$resolved">
	<h2 *ngIf="!limitedView">Licenses</h2>

	<div class="filters-rows" *ngIf="true">
		<div class="row-fluid filter-labels">
            <div class="col-sm-2">
                <span class="text-muted">Search</span>
            </div>
            <ng-container *ngIf="!limitedView">
                <div class="col-sm-2">
                    <span class="text-muted">Type</span>
                </div>
                <div class="col-sm-2">
                    <span class="text-muted">Status</span>
                </div>
                <div class="col-sm-2">
                    <span class="text-muted">Authorization</span>
                </div>
                <div class="col-sm-2">
                    <span class="text-muted">State</span>
                </div>
                <div class="col-sm-2"></div>
            </ng-container>
		</div>

		<div class="row-fluid filter-selectors">
            <ng-container *ngIf="!limitedView">
                <div class="col-sm-2">
                    <search-list placeholder="License"></search-list>
                </div>
                <div class="col-sm-2">
                    <ng-select
                        [(ngModel)]="params.type"
                        (change)="setFilter({ type: $event })"
                        [items]="filters.type?.$promise | async"
                        [loading]="!filters.type?.$resolved"
                        bindValue="id"
                        bindLabel="name"
                        placeholder="All"
                    ></ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select
                        [(ngModel)]="params.status"
                        (change)="setFilter({ status: $event })"
                        [items]="filters.status?.$promise | async"
                        [loading]="!filters.status?.$resolved"
                        bindValue="id"
                        bindLabel="name"
                        placeholder="All"
                    ></ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select
                        [(ngModel)]="params.authorization"
                        (change)="setFilter({ authorization: $event })"
                        [items]="filters.authorization"
                        bindValue="id"
                        bindLabel="name"
                        placeholder="All"
                    ></ng-select>
                </div>
                <div class="col-sm-2">
                    <ng-select
                        [(ngModel)]="params.state"
                        (change)="setFilter({ state: $event })"
                        [multiple]="true"
                        [items]="filters.state?.$promise | async"
                        [loading]="!filters.state?.$resolved"
                        bindValue="shortName"
                        bindLabel="name"
                        placeholder="All"
                    ></ng-select>
                </div>
                <div class="col-sm-2 text-right">
                    <segmented-controls
                        [items]="filters.class"
                        [value]="params.class"
                        (selected)="setFilter({ class: $event })"
                    ></segmented-controls>
                </div>
            </ng-container>
            <ng-container *ngIf="limitedView">
                <div class="col-sm-3">
                    <search-list placeholder="License or NMLSID"></search-list>
                </div>
                <div class="col-sm-a"></div>
            </ng-container>
		</div>
		<div class="row-fluid filter-selectors">
            <ng-container *ngIf="!limitedView">
                <div class="col-sm-2">
                    <div class="form-group no-margin has-feedback left-position">
                        <input class="form-control"
                            type="text"
                            [ngModel]="params.searchNmlsId"
                            (keyup)="setFilter({ searchNmlsId: $event.target.value })"
                            maxlength="75"
                            placeholder="NMLSID"
                        />
                        <a class="form-control-feedback"
                            [ngClass]="{ 'c-icon c-icon-search': !params.searchNmlsId, 'glyphicon glyphicon-remove': params.searchNmlsId }"
                            (click)="setFilter({ searchNmlsId: '' })"
                        ></a>
                    </div>
                </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-2"></div>
                <div class="col-sm-2"></div>
                <div class="col-sm-2"></div>
                <div class="col-sm-2"></div>
            </ng-container>
        </div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-sm-5"></div>
			<ng-container *ngIf="isIndividual">
				<div [ngClass]="['col-sm-4', getSortClass('name')]" (click)="setSort('name')"><b>Individual</b></div>
			</ng-container>
			<ng-container *ngIf="!isIndividual">
				<div [ngClass]="['col-sm-4', getSortClass('companyNmlsId')]" (click)="setSort('companyNmlsId')"><b>NMLS ID</b></div>
			</ng-container>
			<div [ngClass]="['col-sm-3', getSortClass('regulator')]" (click)="setSort('regulator')"><b>State</b></div>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<div class="col-sm-12 text-center">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results.
			</div>
		</div>

		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-sm-5">
				<div class="flex-column gap">
					<div>
						<a class="text-bigger" (click)="showLocation(item)">{{item.licenseNumber || 'No Number'}}</a>
						<ng-container *ngIf="item.licenseType"> / {{item.licenseType}}</ng-container>
					</div>
					<div class="flex-row gap">
						<div class="text-bigger">
							<span class="label label-default-wired">{{item.status}}</span>
						</div>
						<div class="text-muted">
							<span class="glyphicon" [ngClass]="item.isAuthorized ? 'glyphicon-ok-sign text-success' : 'glyphicon-remove text-danger'"></span>
							{{item.isAuthorized ? '' : 'Not'}} Authorized
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="isIndividual">
				<div class="col-sm-4">
					<span class="text-bigger">{{item.name}}</span>
					<ng-container *ngIf="item.individualNmlsId"> / <a uiSref=".nmls.:nmlsId" [uiParams]="{ nmlsId:item.individualNmlsId }">NMLS ID: {{item.individualNmlsId}}</a></ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="!isIndividual">
				<div class="col-sm-4">
					{{item.branchNmlsId || item.companyNmlsId || '&ndash;'}}
				</div>
			</ng-container>
			<div class="col-sm-3">
				{{item.regulator || '&ndash;'}}
			</div>
		</div>

		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 va-m text-center text-bigger text-muted">
				{{(filters.q || filtersApplied) ? 'No matches found' : 'No Licenses'}}
			</div>
		</div>

		<br />

		<new-paging
			*ngIf="list.$pagesTotal > 1"
			class="no-border"
			[(list)]="list"
			[(params)]="params$"
		></new-paging>
	</div>
</div>
