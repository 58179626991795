<div class="alerts">
	<div [class.gray-head]="!defaultFilters.tpoId">
		<h2>Alerts</h2>
	</div>

	<div class="filters-rows">
		<div class="row-fluid filter-labels" [class.curtain]="batchEditing">
			<div class="col-sm-{{defaultFilters.tpoId ? 3 : 2}}">
				<div class="text-muted">Search</div>
			</div>
			<div class="col-sm-2" *ngIf="!defaultFilters.tpoId">
				<div class="text-muted">Alert Status</div>
			</div>
			<div class="col-sm-2" *ngIf="!defaultFilters.tpoId">
				<div class="text-muted">Channel</div>
			</div>
			<div class="col-sm-a"></div>
		</div>

		<div class="row-fluid filter-selectors">
			<div class="col-sm-{{defaultFilters.tpoId ? 3 : 2}}" [class.curtain]="batchEditing">
				<search-list placeholder="Name, Type, ID"></search-list>
			</div>
			<div class="col-sm-2" *ngIf="!defaultFilters.tpoId" [class.curtain]="batchEditing">
				<ng-select
					[ngModel]="params.status"
					(change)="setFilter({ status: $event })"
					[items]="filters?.status"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Statuses">
				</ng-select>
			</div>
			<div class="col-sm-2 remote-resource" *ngIf="!defaultFilters.tpoId" [class.resolved]="filters?.channel?.$resolved"
				 [class.curtain]="batchEditing || !filters?.channel">
				<ng-select
					[ngModel]="params?.channel"
					(change)="setFilter({ channel: $event })"
					[items]="filters?.channel?.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Channels">
				</ng-select>
			</div>

			<div class="col-sm-a">
				<filters-toggle *ngIf="!defaultFilters.tpoId" [opened]="moreFilters" (toggle)="toggleFilters()" [disabled]="batchEditing"></filters-toggle>
			</div>
			<div class="col-sm-4 text-right" *ngIf="!defaultFilters.tpoId">
				<span *ngIf="batchEditing">Selected Alerts: <b>{{batchCount || 0}}</b>&nbsp;&nbsp;&nbsp;&nbsp;</span>
				<button type="button" class="btn btn-primary" *ngIf="!batchEditing && User.can('EDIT_ALERTS')"
						(click)="batchEditing = true; batch = false; batchAll()">Edit Status
				</button>
				<button type="button" class="btn btn-primary" *ngIf="batchEditing"
						(click)="proceed()" [disabled]="!batchCount">Proceed
				</button>
				<button type="button" class="btn btn-default" *ngIf="batchEditing"
						(click)="batchEditing = false">Cancel
				</button>
			</div>
		</div>
		<div class="row-fluid filter-labels" *ngIf="!defaultFilters.tpoId" [class.hidden]="!moreFilters" [class.curtain]="batchEditing">
			<div class="col-sm-2">
				<div class="text-muted">Created After</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Created Before</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Type</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Subtype</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">License Type</div>
			</div>
			<div class="col-sm-a"></div>
		</div>
		<div class="row-fluid filter-selectors" *ngIf="!defaultFilters.tpoId" [class.hidden]="!moreFilters" [class.curtain]="batchEditing">
			<div class="col-sm-2 form-group">
				<input type="text" class="form-control"
					   placeholder="mm/dd/yyyy"
					   [bsValue]="params?.createdDateFrom"
					   [maxDate]="params?.createdDateTo"
					   (bsValueChange)="setFilter({createdDateFrom: $event})"
					   bsDatepicker>
			</div>
			<div class="col-sm-2 form-group">
				<input type="text" class="form-control"
					   placeholder="mm/dd/yyyy"
					   [bsValue]="params?.createdDateTo"
					   [minDate]="params?.createdDateFrom"
					   (bsValueChange)="setFilter({createdDateTo: $event})"
					   bsDatepicker>
			</div>
			<div class="col-sm-2 remote-resource" [class.resolved]="filters?.typeId?.$resolved"
				 [class.curtain]="!filters?.typeId">
				<ng-select
					[ngModel]="params.typeId"
					(change)="setFilter({ typeId: $event })"
					[items]="filters?.typeId?.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="false"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Types">
				</ng-select>
			</div>
			<div class="col-sm-2 remote-resource" [class.resolved]="filters?.category?.$resolved"
				 [class.curtain]="!params?.typeId || !filters?.category.length">
				<ng-select
					[ngModel]="params.category"
					(change)="setFilter({ category: $event })"
					[items]="filters?.category?.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Types">
				</ng-select>
			</div>
			<div class="col-sm-2 remote-resource" [class.resolved]="filters?.licenseType?.$resolved"
				 [class.curtain]="!params?.typeId || !filters?.licenseType.length">
				<ng-select
					[ngModel]="params?.licenseType"
					(change)="setFilter({ licenseType: $event })"
					[items]="filters?.licenseType?.$promise | async"
					bindValue="id"
					bindLabel="name"
					[multiple]="true"
					[clearable]="true"
					[closeOnSelect]="false"
					placeholder="All Types">
				</ng-select>
			</div>
			<div class="col-sm-a"></div>
		</div>
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="list?.$resolved">
		<div class="row-fluid header">
			<span class="col-sm-check" *ngIf="batchEditing">
				<input type="checkbox" [(ngModel)]="batch" (ngModelChange)="batchAll()"/>
			</span>
			<sortable-header class="col-sm-{{defaultFilters.tpoId ? 2 : 3}}" columnName="alertCategory">Alert</sortable-header>
			<sortable-header class="col-sm-1" columnName="commentsCount">Comments</sortable-header>
			<sortable-header class="col-sm-2" columnName="alertType">Type</sortable-header>
			<sortable-header class="col-sm-1" columnName="alertId">Alert ID</sortable-header>
			<sortable-header class="col-sm-1" columnName="createdDate">Create Date</sortable-header>
			<sortable-header class="col-sm-1" columnName="alertStatus">Alert Status</sortable-header>
			<span class="col-sm-1" *ngIf="!defaultFilters.tpoId"></span>
		</div>
		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<span class="col-sm-12 text-center va-m">Filters are applied.
				<a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>
		<div class="row-fluid" *ngFor="let item of list;">
			<span class="col-sm-check" *ngIf="batchEditing">
				<input type="checkbox" [(ngModel)]="item.batchChecked" (ngModelChange)="unBatch()"/>
			</span>
			<span class="col-sm-{{defaultFilters.tpoId ? 2 : 3}} va-m">
				<a uiSref="client.:id.reviews.alerts.:alertId" [uiParams]="{ id: item.tpoId, alertId: item.alertId }">{{item?.alertCategory}}</a>
				<br/>
				<a class="light-link-backward" uiSref="client.:id.account.information" [uiParams]="{ id: item.tpoId }">
					<span class="text-light">
						{{item?.companyName}} NMLS ID:{{item?.companyNmlsId}}
					</span>
				</a>
			</span>
			<span class="col-sm-1 va-m">
				 {{item?.commentsCount}}
			</span>
			<span class="col-sm-2 va-m">
				{{item?.alertType}}
			</span>
			<span class="col-sm-1 va-m">
				{{item?.alertId}}
			</span>
			<span class="col-sm-1 va-m">
				{{item?.createdDate | date:'MM/dd/yyyy'}}
			</span>
			<span class="col-sm-1 va-m">
				{{statuses[item?.alertStatus] || '&ndash;'}}
			</span>
			<span class="col-sm-1 va-m" *ngIf="!defaultFilters.tpoId">
				<a uiSref="client.:id.reviews.scorecard.summary" [uiParams]="{id: item.tpoId}">Scorecard</a>
			</span>
		</div>

		<div class="row-fluid" *ngIf="!list.length && filters?.q">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>
		<div class="row-fluid" *ngIf="!list.length && !filters?.q">
			<span class="col-sm-12 text-muted text-center text-bigger">No alerts</span>
		</div>
		<br>
		<div [class.curtain]="batchEditing">
			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>
	</div>
</div>
