import { Component, Input, OnInit } from '@angular/core';

import { Channel } from 'lender/manage-channels/manage-channel-card.interface';
import { DocumentCardDetails, DocumentCardSummary } from 'shared/document-schedule-settings-card/document-card.interface';
import { DocumentScheduleSettingsStrategy } from 'shared/document-schedule-settings-card/strategy.interface';


@Component({
    selector: '[document-card]',
    templateUrl: 'document-card.component.html',
})
export class DocumentCardComponent<S extends DocumentCardSummary, D extends DocumentCardDetails> implements OnInit {
    @Input() strategy: DocumentScheduleSettingsStrategy<S, D>;
    @Input() allChannels: Channel[] = [];
    @Input() strategyParams: any = {};
    @Input() documentSummary: S;

    documentDetails: D;

    private _showContent: boolean = false;
    set showContent(show: boolean) {
        if (show && !this._showContent) {
            this.retrieveDetails();
        }
        this._showContent = show;
    }
    get showContent(): boolean {
        return this._showContent;
    }

    isRetrievingDetail: boolean = false;
    isSaving: boolean = false;

    // Override
    ngOnInit(): void {
    }

    showCardContent() {
        this.showContent = !this.showContent;
    }

    private retrieveDetails(): void {
        this.isRetrievingDetail = true;

        this.strategy.getDetails(this.documentSummary, this.strategyParams)
            .then(details => {
                this.documentDetails = details;
            })
            .finally(() => this.isRetrievingDetail = false);
    }
}
