import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { AbstractStatusService } from 'commons/services';
import { ListingsStatusResourceService } from './status-resource.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ListingsAlertsStatusService extends AbstractStatusService<boolean> {
	constructor(
		private listingsStatusResourceService: ListingsStatusResourceService,
	) {
		super();
	}

	get exclamation$(): Observable<boolean> {
		return super.getStatus$();
	}

	fetchStatus = () => (
		from(
			this.listingsStatusResourceService.getAlerts().$promise
		).pipe(
            map(({ exclamation }) => exclamation),
        )
	);

	public refresh() {
		super.refreshStatus();
	}
}
