import { NgModule } from '@angular/core';

import { ChannelsOtherActionsService } from './channel-other-actions.service';

import { ChannelSettingsModule } from './settings';

@NgModule({
	imports: [
		ChannelSettingsModule,
	],
	providers: [
		ChannelsOtherActionsService,
	],
})
export class ChannelsModule {}
