import { chain, map, reject } from 'lodash';
import { NgResourceArray } from 'commons/interfaces';
import { afterOption, Question, QuestionType } from 'lender/questionnaires/manage/questionnaires.interface';

export const getAfterOptions = (list: NgResourceArray<Question>): afterOption[] => {
	const questions: Question[] = reject(list, { questionType: QuestionType.GROUP });
	return map(questions, ({ text, id }, index) => {
		return { i: index + 1, id, name: `After Question ${index + 1}`, hint: `<b>${index+1}.</b> ${text}` };
	});
}

export const getHints = (afterOptions: afterOption[]): Record<number, string> => {
	return chain(afterOptions)
		.keyBy('i')
		.mapValues('hint')
		.value();
}
