import { Component, Input } from '@angular/core';
import { CcmDataMatchReport } from 'comergence/data-match/ccm-data-match.interface';


@Component({
    selector: 'ccm-data-match-result-section',
    templateUrl:'ccm-data-match-result-section.component.html',
})
export class CcmDataMatchResultSectionComponent {
    @Input() dataMatchResultSection : CcmDataMatchReport;

    constructor(){}
}
