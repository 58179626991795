import { Component, Input, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonProductionService, TotalVolume } from 'shared/production/production.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
    selector: 'production-total-volume',
    templateUrl: './total-volume.component.html',
})
export class ProductionTotalVolumeComponent implements OnInit {
    @ContentChild('productionTemplate', {static: true}) productionTemplate: TemplateRef<any>;

    @Input() lenderInfo?: AccountInformation;
    @Input() nmlsId: number;
    @Input() method = 'getTotal';

    totalVolume$: Observable<TotalVolume>;

    constructor(
        private readonly productionService: CommonProductionService,
    ) {}

    ngOnInit(): void {
        this.totalVolume$ = this.productionService[this.method](this.nmlsId, this.lenderInfo?.identifier);
    }
}
