<div class="gray-well flex-row" *ngFor="let item of requests$ | async">
    <div class="flex-grow" [ngSwitch]="item.approvalType">
        <ng-container *ngSwitchCase="APPROVAL_TYPE.CLIENT_SHARE">
            <b>{{ item.investorFromName }}</b> is requesting your permission to add you as a client to <b>{{ item.investorToName }}</b>.
            This action is part of a change that has occurred in the relationship between these two companies.
        </ng-container>
        <ng-container *ngSwitchCase="APPROVAL_TYPE.DATAMATCH_UPLOAD">
            <b>{{ item.investorToName }}</b> is requesting your permission to add you as a client.
        </ng-container>
    </div>
    <a class="btn btn-primary" (click)="reviewRequest(item)">Review</a>
</div>
