<div class="remote-resource" [class.resolved]="!resolving">
    <form [formGroup]="visibilityForm" id="visibilityForm" (onValidSubmit)="save()">
        <h3>
            Visibility
            <div class="pull-right">
                <button *ngIf="!isEditable && canManageCustomers" class="btn btn-primary" (click)="setEditing()"><span class="glyphicon glyphicon-pencil"></span>Edit</button>
                <div *ngIf="editing && !isNew">
                    <button class="btn btn-primary" type="submit">Save</button>
                    <button class="btn btn-primary-wired" type="button" (click)="cancelEdit()">Cancel</button>
                </div>
            </div>
        </h3>

        <form-errors [form]="visibilityForm"></form-errors>

        <div class="rows-striped">
            <div class="row-fluid">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-sm-3 control-label text-right" [class.required]="isEditable">{{visibilityForm.getFieldLabel('enabled')}}</label>
                        <div class="col-sm-6 control-value" *ngIf="crmSettings" [isEditable]="isEditable">
                            <div view>
                                {{visibilityForm.get('enabled').value ? 'Designated Users' : 'All Users'}}
                            </div>
                            <div edit>
                                <label class="radio-inline">
                                    <input type="radio" formControlName="enabled" name="enabled" [value]="false"/>
                                    All Users
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" formControlName="enabled" name="enabled" [value]="true"/>
                                    Designated Users
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-6">
                    <div class="form-group row" *ngIf="isEditable && visibilityForm.get('enabled').value">
                        <label class="col-sm-3 control-label text-right">Select Users</label>
                        <div class="col-sm-6">
                            <div [isEditable]="isEditable">
                                <div view class="control-value">{{visibilityForm.get('designatedUsers')}}</div>
                                <ng-select
                                    edit
                                    formControlName="designatedUsers"
                                    [items]="userList | async"
                                    bindLabel="personalName"
                                    placeholder="Select users"
                                    name="designatedUsers"
                                    [compareWith]="compareUsers"
                                    [closeOnSelect]="false"
                                    multiple="true"
                                    tabindex="9">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row-fluid" *ngIf="isNew && canManageCustomers && !crmSettings.created">
            <div class="pull-right">
                <button class="btn btn-primary" type="submit">Save</button>
                <button class="btn btn-primary-wired" type="button" uiSref="customers.:customerType.:organizationId.services.crm">Cancel</button>
            </div>
        </div>

        <div *ngIf="(tpoStatusCode === 'ACTIVE') && (!isEditable) && (visibilityForm.get('enabled').value)" class="remote-resource rows-bordered" [class.resolved]="!resolving">
            <div class="row-fluid header">
                <div class="col-sm-6">Name</div>
                <div class="col-sm-3">System Role</div>
                <div class="col-sm-3">Status</div>
            </div>
            <div class="row-fluid" *ngFor="let user of crmSettings.designatedOptions.designatedUsers">
                <div class="col-sm-6">{{user.personalName}}</div>
                <div class="col-sm-3">{{user.systemRole}}</div>
                <div class="col-sm-3">
                    <span *ngIf="user.status.name == 'ACTIVE'; else INACTIVE" class="label label-success">Active</span>
                    <ng-template #INACTIVE>
                        <span class="label label-danger">Inactive</span>
                    </ng-template>
                </div>
            </div>
            <div class="row-fluid" *ngIf="crmSettings.designatedOptions.designatedUsers.length == 0">
                <span class="col-md-12 text-center text-muted text-bigger">No Users Assigned</span>
            </div>
        </div>
    </form>
</div>
