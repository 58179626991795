import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	templateUrl: './filters-toggle.component.html',
	selector: 'filters-toggle',
})
export class FiltersToggleComponent {

	@Input() opened: boolean = false;
	@Input() disabled?: boolean = false;

	@Output() toggle: EventEmitter<any> = new EventEmitter();

	toggleFilters() {
		if (this.disabled) {
			return;
		}

		this.toggle.emit();
	}
}
