<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">{{ config.title || 'Change' + config.label }}</h4>
</div>
<form [formGroup]="form" (onValidSubmit)="submit()">
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form-errors [form]="form"></form-errors>

        <div [innerHTML]="config.message"></div>

        <div class="form-group no-margin" has-error>
            <ng-select
                [items]="items$ | async"
                [loading]="!(items$ | async)?.length"
                [bindLabel]="config.bindLabel"
                [bindValue]="config.bindValue"
                [placeholder]="config.placeholder"
                [clearable]="!!config.clearable"
                formControlName="select"
            ></ng-select>
        </div>
    </div>
    <div class="modal-footer text-right" [class.curtain]="resolving">
        <button type="submit" class="btn btn-primary">{{confirmText}}</button>
        <button type="button" (click)="modalRef.hide()" class="btn btn-default">{{cancelText}}</button>
    </div>
</form>
