import { Validators } from '@angular/forms';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

export const requestMessageCharsAmount = 4000;
export const getRequestForm = (): RealmFormGroup => {
    return new RealmFormGroup({
        _notify_upon_assignment: new RealmFormControl(
            '_notify_upon_assignment',
            {
                label: 'Notify',
                updateOn: 'change',
                value: true,
            },

        ),
        toClients: new RealmFormControl(
            'toClients',
            { label: 'To: Client' },
            Validators.required,
        ),
        ccRealmUserIds: new RealmFormControl(
            'ccRealmUserIds',
            {
                label: 'CC: Internal',
                updateOn: 'change',
            },
        ),
        message: new RealmFormControl(
            'message',
            {
                label: 'Message',
                updateOn: 'change',
            },
            [
                Validators.required,
                Validators.maxLength(requestMessageCharsAmount),
            ],
        ),
        _notify_upon_completion: new RealmFormControl(
            '_notify_upon_completion',
            {
                label: 'Notify upon completion',
                updateOn: 'change',
                value: true,
            },
        ),
        notifyUponCompletionRealmUserIds: new RealmFormControl(
            'notifyUponCompletionRealmUserIds',
            {
                label: 'To: Internal',
                updateOn: 'change',
            },
            Validators.required,
        ),
    });
};
