<div
	class="questionnaire-documents remote-resource"
	[class.resolved]="availableDocuments.$resolved && documents.$resolved"
>
	<notification *ngIf="isNotificationSet && documents.$resolved"></notification>

	<div *ngIf="!editing && User.can('MANAGE_GLOBAL_QUESTIONNAIRE')">
		<button type="button" class="btn btn-primary" (click)="edit()">
			<span class="glyphicon glyphicon-plus small-icon"></span> Documents
		</button>
		<button type="button" class="btn btn-secondary" (click)="addFinancialsModal()">
			<span class="glyphicon glyphicon-plus small-icon"></span> Financials
		</button>
	</div>

	<div class="flex-row add-documents" *ngIf="editing">
		<ng-select
			[(ngModel)]="selectedDocuments"
			[items]="availableDocuments"
			[loading]="!availableDocuments.$resolved"
			[multiple]="true"
			[closeOnSelect]="false"
			bindLabel="documentName"
			placeholder="Select Document"
		></ng-select>
		<button type="button" [disabled]="!selectedDocuments.length" class="btn btn-primary" (click)="update()">Add</button>
		<button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
	</div>

	<br>

	<div class="rows-bordered">
		<div class="row-fluid no-border" *ngFor="let item of documents">
			<questionnaire-document
				[document]="item"
				(removeClicked)="remove(item)"
				[canRemove]="User.can('MANAGE_GLOBAL_QUESTIONNAIRE') && item.documentType !== 'QUESTION'"
			></questionnaire-document>
		</div>
	</div>

	<ng-container *ngIf="documents.$resolved">
		<div class="rows-bordered">
			<div class="row-fluid flex-row no-records" *ngIf="!documents.length">
				<div class="col-sm-12 text-center text-light text-bigger">
					No Records
				</div>
			</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="documents" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</ng-container>
</div>
