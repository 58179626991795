import { Injectable } from '@angular/core';

import { AbstractIssueStatus } from '../abstractIssueStatus';
import { RealmHttpClient } from 'commons/services/http';
import { UserService } from 'angularjs-providers/user.provider';
import { map } from 'rxjs/operators';
import { Exclamation } from 'tpo/listings';

declare let apiPath: string;

@Injectable()
export class FinancialAlertStatus extends AbstractIssueStatus {

    public constructor(
        private readonly userService: UserService,
        private readonly http: RealmHttpClient,
    ) {
        super();
    }

    // Override
    protected async updateValue(): Promise<boolean> {
        const { id: tpoId } = this.userService.profile.organization;
        return this.http.request<Exclamation>('GET', `${apiPath}/tpos/${tpoId}/financial-documents/exclamations/requests`)
            .pipe(
                map((result) => result.exclamation),
            )
            .toPromise();
    }
}
