import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { IndividualNmlsComponent } from './individual/individual-nmls.component';
import { NmlsIndividualCompaniesComponent } from './individual/companies/nmls-individual-companies.component';
import { NmlsIndividualOtherNamesComponent } from './individual/other-names/nmls-individual-other-names.component';
import { NmlsIndividualFinancialSanctionsComponent } from './individual/financial-sanctions/nmls-individual-financial-sanctions.component';
import { NmlsIndividualCompaniesCompanyComponent } from './individual/companies/company/nmls-individual-companies-company.component';

import { IndividualNmlsService } from './individual/individual-nmls.service';


@NgModule({
	imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
	],
    declarations: [
        IndividualNmlsComponent,
        NmlsIndividualCompaniesComponent,
        NmlsIndividualOtherNamesComponent,
        NmlsIndividualFinancialSanctionsComponent,
        NmlsIndividualCompaniesCompanyComponent,
    ],
	providers: [
        IndividualNmlsService,
	],
})
export class NmlsModule {
}