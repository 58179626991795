import { pick, cloneDeep, map, toPairs, keys, pickBy } from 'lodash';
import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';
import { NgResource } from 'commons/interfaces';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { createRealmFormControl } from 'commons/forms/form-control.hoc';
import { MaxLengthValidator } from 'commons/validators';

import { RolesResourceService } from '../roles-resource.service';
import { LenderUsersResourceService, ExtendedUserProfile } from 'lender/user-management/users-resource.service';
import { Modes } from './modes';

import { DeactivationModalComponent } from './deactivation-deprecation-modal/deprecation-modal.component';

@Component({
	templateUrl: './role.component.html',
})
export class RoleComponent {
	roleId: number;
	actionType: string;
	isNew: boolean = false;
	viewMode: any = {};

	editing: boolean = false;
	submitted: boolean = false;
	extendedUserProfile: NgResource<ExtendedUserProfile>;

	roleResource: any = {};
	// data for view mode only
	roleViewData: any = {};
	// data for traverse in edit mode
	formData: any = {};
	roleForm = new RealmFormGroup({
		...createRealmFormControl('name', {label: 'Role Name'}, Validators.compose([
			Validators.required,
			MaxLengthValidator(100),
		])),
		...createRealmFormControl('description', {label: 'Description'}, MaxLengthValidator(225)),
	});

	modalRef: BsModalRef;

	title: string = '';
	canManage: boolean = false;

	constructor(
		public User: UserService,
		public RolesResource: RolesResourceService,
		public stateService: StateService,
		public $state: $stateProvider,
		public modalService: BsModalService,
		public LenderUsersService: LenderUsersResourceService,
	) {
		this.extendedUserProfile = this.LenderUsersService.get({
			userId: this.User.profile.id,
		});
	}

	ngOnInit() {
		this.roleForm.markAsPristine();
		const {roleId, actionType} = this.stateService.params;

		if (!Modes[actionType]) {
			this.stateService.go('users-management.roles');
			return;
		}

		this.roleId = roleId;
		this.actionType = actionType;
		this.isNew = !!roleId;

		this.viewMode = Modes[actionType];
		this.editing = this.viewMode.defaultEditing;

		if (!this.isNew) {
			const data = {
				name: '',
				description: '',
				permissionsMap: {},
				isEditable: true,
				$resolved: true,
			};

			this.successHandler(data);
			this.getViewStats();
		} else {
			this.RolesResource.get({roleId}).$promise
				.then(this.successHandler);
		}
	}

	save() {
		this.submitted = true;
		const data = { ...this.roleForm.value, permissionsMap: this.formData.permissionsMap };

		map(toPairs(data), ([fieldName, fieldValue]) => (this.roleResource[fieldName] = fieldValue));

		if (this.isNew && this.roleResource.id) {
            this.RolesResource.update({ roleId: this.roleId }, this.roleResource, this.saveSuccessHandler, this.errorHandler);
		} else {
			// no entity here, ask main resource
			this.RolesResource.save(data, this.saveSuccessHandler, this.errorHandler);
		}
	}

	saveSuccessHandler = (result) => {
		if (!this.isNew || this.actionType === 'copy') {
			const href = this.$state.href('users-management.roles.:actionType.:roleId', { roleId: result.id, actionType: 'edit' });
			const link = `<a class="underlined text-success" href="${href}">View Role</a>`;

			this.$state.notify('users-management.roles', {
				notification: {
					type: 'alert-success',
					message: `Role <b>${result.name}</b> was created successfully. ${link}`,
					timeout: 4000,
				},
			});
			return;
		}
		this.extendedUserProfile.$promise.then(({ systemRoleId }) => {
			if (+this.roleId === systemRoleId) {
				this.User.profile.permissions = keys(pickBy(result.permissionsMap));
			}
		});
		this.User.profile$.next(this.User.profile);
		this.editing = false;
		this.successHandler(result);
	}

	successHandler = (result) => {
		this.submitted = false;

		if (this.actionType === 'copy') {
			delete result.id;
			result.description = `This is a copy of the Role ${result.name}`;
			result.name = `Copy of ${result.name}`;
		}

		this.roleResource = result;
		this.roleViewData = pick(result, ['name', 'description', 'permissionsMap', 'isActive', 'isEditable']);
		this.formData = cloneDeep(this.roleViewData);
		this.roleForm.reset(this.formData);

		this.getViewStats();
	}

	errorHandler = ({ data: response }) => {
		this.submitted = false;
		this.roleForm.setServerError(response);
	}

	toggleEditMode() {
		this.editing = !this.editing;
	}

	cancelEditing() {
		if (this.submitted) {
			return;
		}

		if (this.actionType === 'copy') {
			return this.stateService.go('users-management.roles.:actionType.:roleId', {
				actionType: 'edit',
				roleId: this.roleId,
			});
		}

		if (this.actionType === 'new') {
			return this.stateService.go('users-management.roles');
		}

		this.editing = false;
		this.formData = cloneDeep(this.roleViewData);
		this.roleForm.reset(this.formData);
	}

	changeStatus() {
		const method = this.roleResource.isActive ? 'deactivate' : 'activate';

        this.roleResource.$resolved = false;
        this.RolesResource[method]({ roleId: this.roleId }, this.roleResource, (data) => {
			this.successHandler(data);
		}, (error) => {
            this.roleResource.$resolved = true;
			if (method === 'activate') {
				return;
			}

			this.showDeactivateDeprecationModal(error.data);
		});
	}

	showDeactivateDeprecationModal(data) {
		const initialState = {
			notification: data.message,
			hide: () => {
				this.modalRef && this.modalRef.hide();
			},
		};

		this.modalRef = this.modalService.show(DeactivationModalComponent, {initialState});
	}

	getViewStats() {
		this.title = this.getTitle();
		this.canManage = this.getCanManage();
	}

	getTitle() {
		return this.isNew ? this.roleViewData.name : this.viewMode.title;
	}

	getCanManage() {
		const user = this.User && this.User.profile;

		if (!user) {
			return false;
		}

		if (user.isTpo) {
			return this.roleViewData.isEditable;
		}

		if (user.isCustomer) {
			return user.can('MANAGE_ROLES');
		}

		if (user.isComergence) {
			return user.can('CCM_MANAGE_CCM_USERS');
		}

		return false;
	}
}
