import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuditChecklistModule } from 'shared/reviews/$reviewId/checklist/checklist.module';

import { FindingChecklistComponent } from './checklist.component';
import { FindingChecklistService } from './checklist.service';

@NgModule({
	imports: [
		CommonModule,
		AuditChecklistModule,
	],
	declarations: [
		FindingChecklistComponent,
	],
	providers: [
		FindingChecklistService,
	],
	exports: [
		FindingChecklistComponent,
	],
})
export class FindingChecklistModule {}
