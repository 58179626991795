<h3>Imports</h3>
<div class="text-right">
    <a class="btn btn-primary" uiSref="people.contacts-import.upload">Import</a>
</div>
<br>
<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
    <div class="row-fluid header">
        <div class="col-md-4"><b class="two-liners">Imported File</b></div>
        <div class="col-md-a"><b class="two-liners">Imported By</b></div>
        <div class="col-md-a"><b class="two-liners">Imported Date</b></div>
        <div class="col-md-a"><b class="two-liners">Status</b></div>
        <div class="col-md-a text-right"><b class="two-liners">Successfully Imported</b></div>
        <div class="col-md-a text-right"><b class="two-liners">Failed Contacts</b></div>
        <div class="col-f-40"></div>
    </div>
    <reset-filters></reset-filters>

    <div class="row-fluid" *ngFor="let item of list | async">
        <div class="col-md-4 control-value">
            <a [href]="item.downloadLink" target="_blank">{{ item.originalFileName }}</a>
        </div>
        <div class="col-md-a control-value">{{ item.importedBy }}</div>
        <div class="col-md-a control-value">{{ item.importedDate | date : 'MM/dd/yyyy' }}</div>
        <div class="col-md-a control-value">{{ item.status }}</div>
        <div class="col-md-a control-value text-right">{{ item.successCount || '&ndash;' }}</div>
        <div class="col-md-a control-value text-right" [class.text-danger]="item.failedCount > 0">{{ item.failedCount !== null ? item.failedCount : '&ndash;' }}</div>
        <div class="col-f-40 button-holder">
            <a *ngIf="item.failedCount" [href]="item.failedLink" target="_blank" class="btn btn-tertiary btn-icon">
                <span class="c-icon c-icon-download-alt"></span>
            </a>
        </div>
    </div>

    <div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
        <span class="col-md-12 text-muted text-bigger text-center control-value">
            {{ noMatches ? 'No matches found' : 'No records added' }}
        </span>
    </div>

    <br>
    <paginator class="no-border" [sizes]="[10,25,50]"></paginator>
</div>
