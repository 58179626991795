import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { RecurlyModule, RecurlyProvider } from 'commons/recurly';
import { TpoDocumentsModule } from 'tpo/documents';

import { TpoApplicationService } from './application.service';
import { AppPaymentComponent } from './app-payment.component';
import { ApplicationDocumentListComponent } from './documents';
import { ApplicationCommonComponent } from './common/application-common.component';
import { AppSummaryComponent } from './summary/app-summary.component';
import { AppSummaryQuestionnaireRowComponent } from './summary/questionnaire-row/questionnaire-row.component';
import { AppSummaryOwnershipRowComponent } from './summary/ownership-row/ownership-row.component';
import { AppSummaryCompanyInformationRowComponent } from './summary/company-information-row/company-information-row.component';
import { AppAccountExecutiveComponent } from './summary/account-executive/account-executive.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		RecurlyModule,
		TpoDocumentsModule,
	],
	declarations: [
        ApplicationCommonComponent,
        AppPaymentComponent,
        AppSummaryComponent,
        ApplicationDocumentListComponent,
		AppAccountExecutiveComponent,
		AppSummaryQuestionnaireRowComponent,
		AppSummaryOwnershipRowComponent,
		AppSummaryCompanyInformationRowComponent,
	],
	providers: [
		RecurlyProvider,
		TpoApplicationService,
	],
})
export class ApplicationModule {}
