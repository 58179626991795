<div class="tpo-add-edit-modal">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title text-center">Client Opt-in Request</h4>
    </div>

    <form class="remote-resource" [class.resolved]="!resolving" [formGroup]="form" (onValidSubmit)="submit()">
        <div class="alert alert-danger">
            Any client with the relationship status of Pending, Cancelled, Terminated or Declined will be ignored.
        </div>
        <div class="modal-body">
            <form-errors [form]="form"></form-errors>
            <div class="form-group" has-error>
                <label class="control-label required">Investor</label>
                <ng-select
                    [items]="investors | async"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    [loading]="loading.investors"
                    placeholder="Select Investor"
                    formControlName="investor"></ng-select>
            </div>
            <div class="form-group" has-error>
                <label class="control-label required">Channel</label>
                <ng-select
                    [items]="channels | async"
                    bindLabel="channelName"
                    bindValue="channelId"
                    [multiple]="false"
                    [loading]="loading.channels"
                    placeholder="Select Channel"
                    formControlName="channelToId"></ng-select>
            </div>
            <div class="form-group" has-error>
                <label class="control-label required">Assigned</label>
                <ng-select
                    [items]="users | async"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    [loading]="loading.users"
                    placeholder="Select User"
                    formControlName="assignedUserId"></ng-select>
            </div>
            <div class="form-group no-margin" has-error>
                <label class="control-label required">Message</label>
                <textarea
                    #messageInput
                    class="form-control no-resize"
                    rows="7"
                    maxlength="4000"
                    formControlName="message"></textarea>
                <text-count [control]="messageInput"></text-count>
            </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group pull-right">
                <button type="submit" class="btn btn-primary">Send</button>
                <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
