import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

import { MinimumNumberValidator, STRING_TO_DATE_CONVERTER } from 'commons/validators/minimum-number.validator';

import { ShortHandEditComponent } from 'commons/components/short-hand-edit/short-hand-edit.component';
import { NumberProviderFn } from 'commons/validators/validation-types';
import { endOfDay } from 'utils/date-utils';


@Component({
    selector: '[inline-date-edit]',
    templateUrl: 'inline-date-edit.component.html',
    host: {
        'class': 'row',
        'keyup.escape': 'handleEscapeKey()',
    },
})
export class InlineDateEditComponent extends ShortHandEditComponent<string> {
    @Input() dateInputFormat: string = 'MM/DD/YYYY';
    @Input() placeholder: string = 'mm/dd/yyyy';

    @Input() set minDate(minDate: Date) {
        if (!!this.bsConfig) {
            if (!!minDate) {
                this.bsConfig.minDate = endOfDay(minDate);
            } else {
                this.bsConfig.minDate = minDate;
            }
        }

        this.validatorsDirtied();
    }
    get minDate(): Date {
        return this.bsConfig?.minDate;
    }

    @ViewChild(BsDatepickerDirective, { static: true }) bsDatePicker!: BsDatepickerDirective;

    readonly bsConfig: Partial<BsDatepickerConfig> = {
    };

    constructor(
    ) {
        super(false);
    }

    // Override
    ngOnInit(): void {
        super.ngOnInit();
    }

    // Override
    protected handleStartEditingUI(): void {
    }

    // Override
    protected handleCancelEditingUI(): void {
    }

    // Override
    protected processValue(value: any): string {
        const formattedDated: string = this.formatDate(value);

        return formattedDated;
    }

    // Override
    protected rebuildInternalValidators(): ValidatorFn[] {
        const minDateValidators: ValidatorFn[] = [];
        if (!!this.bsConfig?.minDate) {
            this.bsConfig.minDate = endOfDay(this.bsConfig.minDate);
            const minDateValidator: ValidatorFn = this.buildMinDateValidator();

            minDateValidators.push(minDateValidator);
        }

        // TODO: Allow for maxDate eventually?

        const internalValidators: ValidatorFn[] = [
            ...super.rebuildInternalValidators(),
            ...minDateValidators,
        ];

        return internalValidators;
    }

	// Workaround for a datepicker not updating value when closed and value wasn't changed
    // Copied from ApplicationExpirationDateModalComponent
	updateValue() {
		if (!this.bsDatePicker.isOpen)  {
            this.form.markAsDirty();
			this.form.updateValueAndValidity({ emitEvent: true });
		}
	}

    // TODO: Refactor this out to a service and change it everywhere
    formatDate(inputRenewalDate: Date): string {
        return new DatePipe('en-US').transform(inputRenewalDate, 'MM/dd/yyyy')
    }

    private buildMinDateValidator(): ValidatorFn {
        const comparisonValueProvider: NumberProviderFn = () => this.bsConfig?.minDate?.getTime();

        const minDateValidator: ValidatorFn = MinimumNumberValidator<string>(STRING_TO_DATE_CONVERTER, comparisonValueProvider, true);

        return minDateValidator;
    }
}
