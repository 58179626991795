import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Observable } from 'rxjs';

import { Channel } from 'lender/manage-channels/manage-channel-card.interface';
import { DocumentCardDetails, DocumentCardSummary } from 'shared/document-schedule-settings-card/document-card.interface';
import { DocumentScheduleSettingsStrategy } from 'shared/document-schedule-settings-card/strategy.interface';


@Component({
    selector: 'document-card-channels',
    templateUrl: 'document-card-channels.component.html',
})
export class ChannelsScheduleSettingsComponent<S extends DocumentCardSummary, D extends DocumentCardDetails> implements OnInit, OnChanges {
    @Input() strategy: DocumentScheduleSettingsStrategy<S, D>;
    @Input() allChannels: Channel[] = [];
    @Input() strategyParams: any = {};
    @Input() documentSummary: S;
    @Input() documentDetails: D;

    shouldDisplayHistory: Observable<boolean>;

    // Override
    ngOnInit(): void {
        this.shouldDisplayHistory = this.strategy.shouldDisplayHistory();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.documentDetails = changes.documentDetails.currentValue;
    }
}
