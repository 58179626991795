import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { NgResource } from 'commons/interfaces';

import { Question, BankQuestionInUseStats } from 'lender/questionnaires/manage/questionnaires.interface';
import { LenderManageQuestionnaireService } from 'lender/questionnaires/manage/questionnaires.service';

@Component({
	templateUrl: './usage-question-modal.component.html',
})
export class BankQuestionUsageModalComponent implements OnInit {
	onCancel: (params?) => void;
	onConfirm: (params?) => void;
	resolving: boolean = true;

	questionId: Question['id'];

	stats: NgResource<BankQuestionInUseStats> = {
		inUse: [],
		skipLogic: [],
	};

	constructor(
		public modalRef: BsModalRef,
		protected questionnairesService: LenderManageQuestionnaireService,
	) {}

	ngOnInit(): void {
		this.getStats();
	}

	getStats = async (): Promise<void> => {
		try {
			this.stats = await this.questionnairesService.getInUseBankQuestion({
				questionId: this.questionId,
			}).$promise;
		} catch (error) {
			// error handling?
		} finally {
			this.resolving = false;
		}
	}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.onCancel();
	}
}
