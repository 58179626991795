import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivePackageDetails, SmPackageService } from 'tpo/social-media/sm-package.service';
import { RecurlyService } from 'commons/recurly';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'sm-subscription-payment',
    templateUrl: 'payment.component.html',
})
export class SubscriptionPaymentComponent implements OnInit {
    packageDetails: ActivePackageDetails = {
        'price': 0,
        'addonsPrices': {
            'compliance-audits': 0,
            'reporting': 0,
        },
        'tier': -Infinity,
        'canSubscribe': false,
        'isSubscribed': false,
    };

    @HostBinding('class.remote-resource') __remote_resource = true;
    @HostBinding('class.resolved') resolved = false;

    @Input() recurlyConfig;
    paymentForm: RealmFormGroup = new RealmFormGroup({
        tokenId: new RealmFormControl(
            'tokenId',
            {},
        ),
        card: new RealmFormControl(
            'card',
            {
                label: 'Card',
                updateOn: 'change',
            },
            Validators.required,
        ),
        first_name: new RealmFormControl(
            'first_name',
            {
                label: 'First Name',
            },
            Validators.required,
        ),
        last_name: new RealmFormControl(
            'last_name',
            {
                label: 'Last Name',
            },
            Validators.required,
        ),
        email: new RealmFormControl(
            'email',
            {
                label: 'Email',
            },
            [Validators.required, Validators.email],
        ),
        costCenter: new RealmFormControl(
            'costCenter',
            {
                label: 'Cost Center',
            },
        ),
        poNumber: new RealmFormControl(
            'poNumber',
            {
                label: 'PO Number',
            },
        ),
        agree: new RealmFormControl(
            'agree',
            {
                updateOn: 'change',
            },
        ),
    });

    constructor(
        private packageService: SmPackageService,
        private userService: UserService,
        private recurly: RecurlyService,
        private stateService: StateService,
        private notificationsService: GlobalNotificationsService,
    ) {
        this.loadDetails();
    }

    get tpoId(): number {
        return this.userService.profile.organization.id;
    }

    async loadDetails() {
        this.packageDetails = await this.packageService.getActivePackageDetails(this.tpoId).toPromise();
        this.resolved = true;
    }

    ngOnInit() {
        const config = {
            ...this.recurlyConfig,
        };

        this.recurly.configure(config);
    }

    async submit(): Promise<void> {
        const {
            tokenId: payingToken,
            email,
            costCenter,
            poNumber,
        } = this.paymentForm.getRawValue();

        this.resolved = false;

        try {
            await this.packageService.subscribe(this.tpoId, { payingToken, email, costCenter, poNumber }).toPromise();
            this.notificationsService.add({
                message: 'Successfully subscribed',
                type: GlobalNotificationType.POSITIVE,
            });
            this.userService.getUser(undefined, undefined, true);
            this.stateService.go('^');
        } catch ({ error: { message }, message: httpError }) {
            this.paymentForm.setServerError({ message: message || httpError });
        }

        this.resolved = true;
    }
}
