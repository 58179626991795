import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { assign } from 'lodash';

import { ConfirmModalComponent } from 'commons/components/modals';

@Component({
	templateUrl: '../../../../../../../../commons/components/modals/confirm-modal.component.html',
})

export class ListingLinkConfirmModalComponent extends ConfirmModalComponent {
	constructor(modalRef: BsModalRef) {
		super(modalRef);
		assign(this, {
			confirmText: 'Proceed',
			onCancel: () => {
				this.modalRef.hide();
			},
			message: 'You are about to move this listing to a new group. All settings for this specific listing will be retained. Please check the settings for this listing and group when done.',
			resolving: false,
		});
	}
}
