<div class="rows-bordered" [class.remote-resource]="loading">
	<div class="row-fluid header">
		<div class="col-md-a">Account Executive</div>
		<div class="col-md-a">Email</div>
		<div class="col-f-120 button-holder">Phone</div>

	</div>
	<div class="row-fluid">

		<div class="col-md-a">
			<editable-component class="w100" [editable]="editing">
				<ng-template #viewTemplate>
					<div class="flex-row">
						<button
							*ngIf="!accountExecutive?.changedByInvestor"
							class="btn btn-tertiary btn-icon btn-mr10"
							(click)="edit()"
						>
							<span class="glyphicon glyphicon-pencil text-muted"></span>
						</button>&nbsp;

						<span class="control-value">{{accountExecutive?.fullName || '&ndash;'}}</span>
					</div>
				</ng-template>

				<ng-template #editTemplate>
					<ng-select
							[(ngModel)]="accountExecutive"
							[items]="accountExecutiveValues | async"
							[loading]="!(accountExecutiveValues | async)"
							[clearable]="false"
							[compareWith]="compare"
							bindLabel="fullName"
							placeholder="Select Account"
					></ng-select>
				</ng-template>
			</editable-component>
		</div>

		<div class="col-md-a" *ngIf="editing">
			<button class="btn btn-tertiary btn-icon btn-mr10" (click)="save()">
				<span class="glyphicon glyphicon-ok text-muted"></span>
			</button>
			<button class="btn btn-tertiary btn-icon btn-mr10" (click)="cancel()">
				<span class="glyphicon glyphicon-remove text-muted"></span>
			</button>
		</div>

		<div class="col-md-a">
			<span class="control-value">{{accountExecutive?.email || '&ndash;'}}</span>
		</div>

		<div class="col-md-a" *ngIf="editing"></div>

		<div class="col-f-120 button-holder">
			<span class="control-value">{{accountExecutive?.phone || '&ndash;' | tel}}</span>
		</div>
	</div>
</div>