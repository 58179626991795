import { UsedNamesRecordInterface } from './used-names-record.interface';

const usedNames: Map<string, UsedNamesRecordInterface> = new Map();

export { usedNames };

const addRecord = (shortName: string, { listName, className }: UsedNamesRecordInterface): void => {
	usedNames.set(shortName, { listName, className });
};

export { addRecord };

const hasRecord = (shortName: string): boolean => {
	return usedNames.has(shortName);
};

export { hasRecord };

const getRecord = (shortName: string): UsedNamesRecordInterface => {
	return usedNames.get(shortName);
};

export { getRecord };

const dumpRecords = (): object => {
	const used = {};
	usedNames.forEach((value, key) => {
		used[key] = value;
	});

	return used;
};

export { dumpRecords };
