<div class="modal-header">
	<button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">Add Link</h4>
</div>
<form
	[formGroup]="form"
	(onValidSubmit)="submit()"
	autocomplete="off"
>
	<div class="modal-body remote-resource" [class.resolved]="resolved">
		<form-errors [form]="form"></form-errors>

		<p class="margin-bottom-lg">This link will be added to the individuals SM section</p>

		<div class="row">
			<div class="col-md-8">
				<input
					type="url"
					formControlName="link"
					maxlength="255"
					class="form-control"
				/>
			</div>
		</div>
	</div>
	<div class="modal-footer" [class.curtain]="!resolved">
		<div class="btn-group">
			<button type="submit" class="btn btn-primary">Save</button>
			<button type="button" class="btn btn-secondary" (click)="modalRef.hide()">Cancel</button>
		</div>
	</div>
</form>
