import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'url',
})
export class UrlPipe implements PipeTransform {
	transform(value: string | null | undefined): string | null {
		if (!value) {
			return null;
		}

		return `${value}`.replace(/^(.+:\/\/)?(.+)$/, (match, protocol = 'http://', path) => {
			return `${protocol}${path}`;
		});
	}
}
