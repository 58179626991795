<section class="remote-resource" [class.resolved]="list.$resolved">
	<div class="client-documents">
		<h3 class="flex-row gap">
			<span class="flex">
				Supporting Documents
			</span>
			<ng-container *ngIf="((User.isComergence && User.can('CCM_SMC_VIEW_ALL')) || (User.isTpo && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')))">
				<button type="button" class="btn btn-primary" (click)="addDocument()">
					<span class="glyphicon glyphicon-plus small-icon"></span>Add Document
				</button>
			</ng-container>
		</h3>

		<div class="rows-bordered">
			<div class="row-fluid no-border" *ngFor="let item of list">
				<supporting-document
					documentClass="client-document"
					[document]="item"
					[service]="documentService"
					[params]="getIdParams()"
					(change)="reloadList"
				>
					<ng-template viewTemplate let-document="document" let-fn="fn">
						<div class="flex-row stretch stretch gap-m">
							<div class="flex flex-column">
								<div class="title flex-row gap-sm">
									<span class="c-icon c-icon-file text-muted"></span>
									<a class="flex" [href]="fn.link()" target="_blank">{{document.name}}</a>
								</div>

								<show-more class="description text-muted" [text]="document.description"></show-more>

								<div class="indications flex-row align-self-bottom gap">
									<a
										*ngIf="!(document.$uploading || document.$uploaded)"
										class="btn btn-tertiary"
										(click)="fn.history()"
									>
										<span class="c-icon c-icon-back-in-time"></span> History
									</a>

									<span
										*ngIf="document.uploadedDocument"
										class="updated-by"
									>
										Uploaded by {{document.uploadedDocument?.uploadedBy}} on
										{{document.uploadedDocument?.uploadedDate | date: 'MM/dd/yyyy HH:mm'}}
									</span>
								</div>
							</div>

							<div class="actions flex-column right gap-sm">
								<ng-container *ngIf="!(document.$uploading || document.$uploaded) && ((User.isComergence && User.can('CCM_SMC_VIEW_ALL')) || (User.isTpo && User.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')))">
									<label class="file-input" *ngIf="!document.$uploading && !document.$uploaded">
										<span class="btn btn-primary">Upload</span>
										<input type="file" #file (input)="fn.select($event.target.files)" [multiple]="false">
									</label>

									<a class="btn btn-secondary" (click)="fn.edit()">
										<span class="glyphicon glyphicon-pencil"></span> Edit
									</a>
								</ng-container>

								<div class="uploading" *ngIf="document.$uploading">
									<loader></loader>
								</div>

								<div class="uploaded" *ngIf="document.$uploaded">
									<span class="glyphicon glyphicon-ok-sign"></span>
									<span>Done</span>
								</div>
							</div>
						</div>
					</ng-template>
				</supporting-document>
			</div>
		</div>

		<div class="row" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-muted text-bigger">
				No documents
			</div>
		</div>
	</div>
</section>
