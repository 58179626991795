import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ProspectingService } from 'shared/prospecting/prospecting.service';
import { ProspectInfo, ProspectInfoChanges } from 'shared/prospecting/prospecting.interface';

import { AddProspectModalComponent } from './../add-prospect-modal';


@Component({
	selector: 'prospecting-status',
	templateUrl: './prospecting-status.component.html',
})
export class ProspectingStatusComponent {
	modalRef: BsModalRef;

	@Input() prospectInfo: ProspectInfo;
	@Output() prospectInfoChange = new EventEmitter<ProspectInfo>();

	@Input() canAdd: boolean = true;

	constructor(
		public modalService: BsModalService,
		public prospectingService: ProspectingService,
	) {}

	addProspect(): void {
		const { companyName, location, locationAddress, companyNmlsId, locationNmlsId } = this.prospectInfo;

		this.modalRef = this.modalService.show(AddProspectModalComponent, {
			initialState: {
				statusInfo: { companyName, location, locationAddress, companyNmlsId, locationNmlsId },
				onConfirm: async ({ channel: channelId, assigment: assigneeId }): Promise<void> => {
					this.modalRef.content.resolving = true;

					try {
						const changes: ProspectInfoChanges = await this.prospectingService.createProspect({
							channelId,
							assigneeId,
							companyNmlsId,
							locationNmlsId,
						}).toPromise();

						this.prospectInfo = {
							...this.prospectInfo,
							...changes,
						};
						this.prospectInfoChange.emit(this.prospectInfo);

						this.modalRef.hide();
					} catch (e) {
						this.modalRef.content.resolving = false;
						this.modalRef.content.prospectForm.setServerError(e.data || e.error || e);
					}
				},
			},
			class: 'modal-new modal-md',
		});
	}
}
