import { Component } from '@angular/core';

@Component({
    selector: 'contacts-import-steps',
    templateUrl: './import-steps-component.html',
})
export class ContactImportStepsComponent {
    constructor() {
    }
}
