import { Injector, Injectable } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class SharedReportingSwitcherService {
	investorReportingStatus: any;
	lenderReportingStatus: any;

	constructor(
		public injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');

		this.investorReportingStatus = $resource(`${apiPath}/organizations/:organizationId/reporting`, {
			organizationId: '@organizationId',
		},
		{
			get: {
				method: 'get',
			},
			update: {
				method: 'put',
			},
		});

		this.lenderReportingStatus = $resource(`${apiPath}/organizations/:organizationId/billing/reporting`, {
			organizationId: '@organizationId',
		},
		{
			get: {
				method: 'get',
			},
			update: {
				method: 'put',
			},
		});
	}
}
