import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { RealmHttpClient } from 'commons/services/http';

import { Payment } from './payments.interface';

declare let apiPath: string;


@Injectable()
export class TpoPaymentsService {
	constructor(
		private readonly http: RealmHttpClient,
	) {}

	payments(tpoId: number): Observable<Payment[]> {
		return this.http.request<Payment[]>(
			'GET',
			`${apiPath}/tpos/${tpoId}/payments`,
		).pipe(
			shareReplay(1),
		);
	}
}
