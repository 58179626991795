import { isFunction, template } from 'lodash';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

const defaultMessage = (requiredLength: number, actualLength: number) => {
	return `Provided value exceeds ${requiredLength} sign${requiredLength > 1 ? 's' : ''} limit.`;
};

type MaxlengthTemplateStringFn = (value: string | number | null, maxLength: number) => string;

export function MaxLengthValidator(maxLength: number, messageTemplate: string | MaxlengthTemplateStringFn = ''): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} | null => {
		const validator = Validators.maxLength(maxLength);
		const validationMessage = validator(control);

		const message = isFunction(messageTemplate) ? messageTemplate(control.value, maxLength) : messageTemplate;

		if (!validationMessage) {
			return null;
		}

		const { requiredLength, actualLength } = validationMessage.maxlength;

		return {
			maxLength: {
				value: control.value,
				message: message || defaultMessage(requiredLength, actualLength),
				maxLength,
			},
		};
	};
}
