<div *ngIf="field" class="form-group row flex-row"
	 [class.has-error]="editing && hasError(customField?.fieldName)">

	<label class="col-sm-5 control-label text-{{labelAlign}}" [class.required]="editing && customField?.required">
		{{customField?.fieldName}}
	</label>
	<div class="col-sm-7" [class.horizontal-flex-group]="editing" [formGroup]="parentForm">
		<span *ngIf="!editing && field?.value && customField" [ngSwitch]="customField.controlTypeId" class="control-value">
			 <ng-container *ngSwitchCase="'MultipleSelection'">{{field.value | jsonParse}}</ng-container>

			 <ng-container *ngSwitchCase="'PhoneNumber'">{{field.value | tel}}</ng-container>

			 <ng-container *ngSwitchCase="'Number'">{{field.value | number: '1.0-2'}}</ng-container>

			 <ng-container *ngSwitchCase="'Dollar'">${{field.value | number: '1.2-2'}}</ng-container>

			 <ng-container *ngSwitchCase="'Percent'">{{field.value | number: '1.0-2'}}%</ng-container>

			 <ng-container *ngSwitchCase="'Email'">
				 <a href="mailto:{{field.value}}">{{field.value}}</a>
			 </ng-container>

			 <ng-container *ngSwitchDefault>{{field.value}}</ng-container>
		</span>
		<span *ngIf="!editing && !field?.value" class="control-value">&ndash;</span>

		<ng-container *ngIf="editing && customField">
			<ng-container [ngSwitch]="customField.controlTypeId">

				<ng-select *ngSwitchCase="'Droplist'" class="full-width"
						   [formControlName]="customField.fieldName"
						   [items]="customField.valuesList"
						   (change)="field.value = $event"
						   [tabindex]="tabindex"
						   [required]="customField.required"
						   placeholder="Select value">
				</ng-select>

				<ng-select *ngSwitchCase="'MultipleSelection'" class="full-width"
						   [formControlName]="customField.fieldName"
						   [items]="customField.valuesList"
						   (change)="field.value = stringify($event)"
						   [tabindex]="tabindex"
						   [required]="customField.required"
						   [multiple]="true"
						   [closeOnSelect]="false"
						   placeholder="Select value">
				</ng-select>

				<div class="full-width" *ngSwitchCase="'YesNo'">
					<label class="radio-inline">
						<input type="radio" value="Yes" [formControlName]="customField.fieldName"
							   (change)="field.value = getInputValue($event)"> Yes
					</label>
					<label class="radio-inline">
						<input type="radio" value="No" [formControlName]="customField.fieldName"
							   (change)="field.value = getInputValue($event)"> No
					</label>
				</div>

				<input *ngSwitchCase="'Date'" class="form-control input-date"
					   [formControlName]="customField.fieldName"
					   bsDatepicker
					   [bsValue]="parentForm.get(customField.fieldName)?.value"
					   (bsValueChange)="field.value = toDateStringFormat($event, 'MM/DD/YYYY')"
					   placeholder="mm/dd/yyyy"
					   [tabindex]="tabindex">

				<input *ngSwitchCase="'PhoneNumber'" phoneFormatter class="form-control"
					   [formControlName]="customField.fieldName" [required]="customField.required"
					   (change)="setPhoneNumber(field, $event)" [tabindex]=tabindex>

				<ng-container *ngSwitchCase="'Number'" [ngTemplateOutlet]="number"></ng-container>
				<ng-container *ngSwitchCase="'Dollar'" [ngTemplateOutlet]="number"></ng-container>
				<ng-container *ngSwitchCase="'Percent'" [ngTemplateOutlet]="number"></ng-container>
				<ng-template #number>
					<input class="form-control" validFloat="1.2-2"
						   [formControlName]="customField.fieldName" [required]="customField.required"
						   (change)="field.value = parseUSNumber(getInputValue($event)) || null" [tabindex]=tabindex>
				</ng-template>

				<input *ngSwitchDefault class="form-control"
					   [formControlName]="customField.fieldName" [required]="customField.required"
					   (change)="field.value = getInputValue($event) || null" [tabindex]=tabindex>

			</ng-container>
		</ng-container>

		<span class="text-muted text-big glyphicon glyphicon-question-sign"
			  *ngIf="editing && customField?.helpText"
			  [tooltip]="customField.helpText"
			  placement="top">
		</span>
	</div>

</div>
