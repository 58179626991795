import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'text-count',
    host: {
        'class': 'text-muted text-smaller',
    },
    template: `Symbols: {{ countString }}`,
})
export class TextCountComponent implements OnInit, OnDestroy {
    @Input() control: HTMLTextAreaElement;
    destroy$: Subject<void> = new Subject();
    countString: string;

    constructor() {
    }

    ngOnInit() {
        fromEvent(this.control, 'input').pipe(
            takeUntil(this.destroy$),
        ).subscribe(this.updateCount);
        this.updateCount();
    }

    updateCount = () => {
        const { value, maxLength } = this.control;
        this.countString = `${value.length}/${maxLength}`;
    };

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
