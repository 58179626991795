<div class="container">
    <div class="row">
        <div class="col-md-9">
            <label class="control-label text-smaller text-light">Description</label>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row-fluid">
        <div class="col-md-12 text-left">
			<show-more
                *ngIf="!detailsLoaded"
                [text]="documentSummary.description"
                [showMoreText]="''"
                [bypassShowMore]="true"
                class="description text-muted"
            ></show-more>
            <label *ngIf="detailsLoaded" class="control-label">{{ documentSummary.description }}</label>
        </div>
    </div>
</div>
