import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { PendingApplicationsListComponent } from './list/applications-list.component';
import { ApplicationsResourceService } from './application.service';
import { NotifyOwnershipVerificationModalComponent, NotifyVerificationService } from './modals/notify-ownership-verification';

@NgModule({
	imports: [
		CommonModule,
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	providers: [
		ApplicationsResourceService,
		NotifyVerificationService,
	],
	declarations: [
		PendingApplicationsListComponent,
		NotifyOwnershipVerificationModalComponent,
	],
	exports: [
		PendingApplicationsListComponent,
		NotifyOwnershipVerificationModalComponent,
	],
})
export class ApplicationsModule {}
