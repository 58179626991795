import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Gallery, GalleryItem, GalleryRef, ImageItem, VideoItem, YoutubeItem, VideoItemData } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

import { SocialMediaPost, SocialMediaPostAttachment } from '../sm-post.interface';

interface Attachment extends SocialMediaPostAttachment {
	imageUrl: string;
}

@Component({
	selector: 'sm-post-attachments',
	templateUrl: './sm-post-attachments.component.html',
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaPostAttachmentsComponent {
	networkCode: string;
	attachments: Attachment[];

	@Input()
	set post({
		networkId,
		attachments,
	}: SocialMediaPost) {
		this.networkCode = networkId;
		if (attachments?.length) {
			this.attachments = attachments.map((attachment) => ({
				...attachment,
				imageUrl: attachment.videoImageUrl || attachment.sourceUrl,
			}));
		} else {
			// resolve if no attachments
			setTimeout(() => {
				this.resolved.emit();
			});
		}
	}
	@Input() previewEnabled: boolean = true;
	@Output() isRendered = new EventEmitter<boolean>();
	@Output() resolved = new EventEmitter<boolean>();

	private galleryId = 'archiveGallery';
	private galleryRef: GalleryRef = this.gallery.ref(this.galleryId);

	constructor(
		public gallery: Gallery,
		public lightbox: Lightbox,
	) {}

	isShare = (attachment): boolean => attachment.type === 'share';
	isVideo = (attachment): boolean => attachment.type.includes('video');

	openFullScreenLightbox(
		items: any[],
		index: number
	): void {
		this.galleryRef.load(
			items.map(({ type, id, sourceUrl, videoImageUrl }): GalleryItem => {
				switch (type) {
					case 'share':
					case 'image':
					case 'photo':
						return new ImageItem({
							src: sourceUrl,
							thumb: sourceUrl,
						});
					case 'video':
						return new VideoItem({
							src: [{
								url: sourceUrl,
								type: 'video/mp4',
							}],
							thumb: videoImageUrl,
						} as VideoItemData);
					case 'youtube-video':
						return new YoutubeItem({
							src: id,
							thumb: videoImageUrl,
						});
					default:
						return new ImageItem({
							src: sourceUrl,
							thumb: sourceUrl,
						});
				}
			}),
		);

		this.lightbox.open(
			index,
			this.galleryId,
			{ panelClass: 'fullscreen' }
		);
	}
}
