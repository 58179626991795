import { keys, startsWith, reduce, replace } from 'lodash';

import { CacheOptions } from './interfaces/cache-options.interface';

const SYNC_PREFIX = 'realm_fe_storage:';

const getCacheName = (name: string): string => (`${SYNC_PREFIX}${name}`);

export { getCacheName };

const getTarget = (sensitive: boolean, target?: 'in-memory' | 'localStorage' | 'sessionStorage') => {
	if (sensitive) {
		return 'in-memory';
	}

	return target || 'in-memory';
};

const normalizeOptions = (options: CacheOptions): CacheOptions => {
	// define all options explicitly
	return {
		name: options.name,
		max: options.max,
		target: getTarget(options.sensitive, options.target),
		sensitive: !!options.sensitive,
		dump: options.dump,
	};
};

export { normalizeOptions };

const saveToStorage = (storage: 'localStorage' | 'sessionStorage', key: string, dump: CacheOptions) => {
	try {
		window[storage].setItem(key, JSON.stringify(dump));
	} catch (e) {
		/* tslint:disable-next-line no-console */
		console.warn(`CacheSnapshots: can't save to ${storage} for key: ${key}`);
	}
};

export { saveToStorage };

const getConfigsToLoad = (): CacheOptions[] => {
	return reduce([ 'localStorage', 'sessionStorage' ], (acc, storageName) => {
		const storage = window[storageName];
		const storageKeys = keys(storage);

		const configs = reduce(storageKeys, (memo, key) => {
			if (!startsWith(key, SYNC_PREFIX)) {
				return memo;
			}

			try {
				const dumpString = storage.getItem(key);
				const record = JSON.parse(dumpString);

				return [ ...memo, {
					name: replace(key, SYNC_PREFIX, ''),
					target: storageName,
					dump: record.dump,
					max: record.max,
				}];
			} catch (e) {
				/* tslint:disable-next-line no-console */
				console.warn(`CacheSnapshots: can't get dump for key: ${key}`);
			}

			return memo;
		}, []);

		return [ ...acc, ...configs ];
	}, []);
};

export { getConfigsToLoad };
