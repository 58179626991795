import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';

declare let apiPath: string;

@Injectable()
export class TpoMobileRegistrationService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const organization = User.profile.organization;
		const basePath = `${apiPath}/tpos/:tpoId/individual-contacts/originators/:individualNmlsId/mobile-registrations/`;
		this.resource = $resource(`${basePath}`, {tpoId: organization.id}, {
			list: {
				isArray: true,
				method: 'get',
				url: basePath + 'unverified',
				params: {individualNmlsId: '@individualNmlsId'},
			},
			update: {
				method: 'post',
				url: basePath + ':registrationId/verification',
				params: {individualNmlsId: '@individualNmlsId', registrationId: '@registrationId'},
			},
		});
	}

	list = (...args): NgResourceArray<MobileRegistration> => this.resource.list(...args);
	update = (...args) => this.resource.update(...args);
}

export interface MobileRegistration {
	date: number;
	email: string;
	id: number;
	phoneNumber: string;
}