import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DocumentStatus } from '../documents.interface';

@Component({
	selector: 'document-status',
	templateUrl: './document-status.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentStatusComponent {
	DocumentStatus = DocumentStatus;

	@Input() status: string;
}
