<section class="remote-resource" [class.resolved]="!companiesResolving">
    <h3>Companies</h3>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-8">
                <b>Company Name</b>
            </span>
            <span class="col-sm-1">
                <b>NMLS ID</b>
            </span>
            <span class="col-sm-3">
                <b>Dates</b>
            </span>
        </div>
        <div class="row-fluid" *ngFor="let company of companies">
            <nmls-individual-companies-company
                [userIsTpo]="userIsTpo"
                [company]="company"
                [individualNmlsId]="individualNmlsId"
            >
            </nmls-individual-companies-company>
        </div>
        <div class="row-fluid" *ngIf="!companies.length">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No Companies</span>
        </div>
    </div>
</section>
