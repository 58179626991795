import { Injectable } from '@angular/core';
import { ContactListCriteria, ContactListField, ContactListOption, FilterOperationFieldType } from '../investor-crm-contact-list.interface';


@Injectable()
export class InvestorCrmContactListCriteriaMapperService {
    mapCriteria(
        criteria: ContactListCriteria,
        fieldTypesById: Map<string, ContactListOption>,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>,
    ): void {
        this.mapFieldTypes(criteria, fieldTypesById);
        this.mapFields(criteria, fieldsById_ByFieldTypeId);
        this.mapOperations(criteria, operationsById_ByTypeId);
    }

    mapFieldTypes(
        criteria: ContactListCriteria,
        fieldTypesById: Map<string, ContactListOption>
    ): void {
        criteria.fieldTypes.forEach((option: ContactListOption) => fieldTypesById.set(option.id, option));
    }

    mapFields(
        criteria: ContactListCriteria,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>
    ): void {
        Object.keys(criteria.fields).forEach((fieldType: string) => {
            const fieldsByTypeId: Map<string, ContactListField> = new Map();
            criteria.fields[fieldType].forEach((field: ContactListField) => {
                fieldsByTypeId.set(field.id, field);

                this.mapFieldsValues(field);
            });

            fieldsById_ByFieldTypeId.set(fieldType, fieldsByTypeId);
        });
    }

    mapOperations(
        criteria: ContactListCriteria,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>
    ): void {
        Object.keys(criteria.operations).forEach((key: FilterOperationFieldType) => {
            const operationsById: Map<string, ContactListOption> = new Map();
            criteria.operations[key].forEach((option: ContactListOption<FilterOperationFieldType>) => operationsById.set(option.id, option));

            operationsById_ByTypeId.set(key, operationsById);
        });
    }

    mapFieldsValues(
        field: ContactListField
    ): void {
        field.valuesById = new Map();
        if (field.values) {
            field.values.forEach((value: ContactListOption) => field.valuesById.set(value.id, value));
        }
    }
}