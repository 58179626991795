import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { AffiliateInterface } from 'tpo/account/affiliates/affiliate.interface';
import { AffiliatesService } from 'tpo/account/affiliates/affiliates.service';
import { PROFILE_SECTION } from 'tpo/account/section-review/section-review.service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

export const TPO_AFFILIATE_LINK: string = 'account.relationships.affiliates.:affiliateId';
export const CCM_AFFILIATE_LINK: string = 'tpo.:id.relationships.affiliates.:affiliateId';
export const INVESTOR_AFFILIATE_LINK: string = 'client.:id.relationships.affiliates.:affiliateId';

@Component({
    templateUrl: './affiliates-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => AffiliatesListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => AffiliatesListComponent) },
    ],
})
export class AffiliatesListComponent extends NewPagedListComponent<AffiliateInterface> implements OnInit {
    static listName = 'affiliates';

    public readonly sectionType = PROFILE_SECTION.AFFILIATES;
    public user: UserProfile;
    public userIsCCM: boolean = false;
    labelDateFormat: string = 'MM/dd/YYYY';
    public individualAffiliateLink: string;

    tpoId: number;

    @Input() lenderInfo: AccountInformation;

    constructor(
        public router: UIRouter,
        private userService: UserService,
        private affiliatesService: AffiliatesService,
    )
    {
        super(router);
        this.affiliatesService = affiliatesService;
        this.user = this.userService.profile;

    }

    ngOnInit(): void {
        this.tpoId = this.user.isTpo ? this.user.organization.id : this.router.globals.params.id;
        this.defaultParams.tpoId = this.tpoId;
        this.nonResettableParams.push('tpoId');
        if(this.router.urlService.path().includes('tpo')) {
            this.userIsCCM = true;
        }

        if (this.user.isTpo) {
            this.individualAffiliateLink = TPO_AFFILIATE_LINK;
        } else if (this.userIsCCM) {
            this.individualAffiliateLink = CCM_AFFILIATE_LINK;
        } else {
            this.individualAffiliateLink = INVESTOR_AFFILIATE_LINK;
        }

        super.ngOnInit();
    }

    protected loadList(params: ListParams): Observable<PagedData<AffiliateInterface[]>> {
        return this.affiliatesService.getAffiliatesList(params);
    }

}
