import { NgModule } from '@angular/core';
import { TpoDocumentsModule } from '../documents';
import { WizardDocumentsComponent } from './steps/documents/wizard-documents.component';

const components = [
	WizardDocumentsComponent,
];

@NgModule({
	imports: [ TpoDocumentsModule ],
	declarations: [ ...components ],
	exports: [ ...components ],
})
export class WizardModule {}
