import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { SharedSocialMediaService } from 'shared/social-media/social-media.service';

@Component({
	templateUrl: './edit-modal.component.html',
})
export class EditRSSModalComponent {
	params: any;
	link: any;
	canDelete: boolean = false;
	onSave: (params?) => any;
	resolving: boolean = false;
	removeConfirmation: boolean = false;

	constructor(
		public modalRef: BsModalRef,
		private sharedSocialMediaService: SharedSocialMediaService,
	) {}

	remove() {
		if (this.resolving) {
			return;
		}

		this.resolving = true;
		this.sharedSocialMediaService.mediaLinks.removeRss({
			...this.params,
			mediaLinkId: this.link.data.linkId,
		}).$promise
			.then(() => {
				this.onSave();
				this.modalRef.hide();
			})
			.finally(() => {
				this.resolving = false;
			});
	}

	toggleRemoveConfirmation = (removeConfirmation = !this.removeConfirmation): void => {
		this.removeConfirmation = removeConfirmation;
	}
}
