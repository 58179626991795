import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import {
	CompanyListingsService,
	ListingGroup,
} from '../../../company-listings.service';
import {
	ListingGroupListingsComponent,
	RenameListingGroupModalComponent,
} from './';

@Component({
	templateUrl: './overview.component.html',
})
export class CompanyListingGroupDetailsOverviewComponent implements OnInit {
	User: UserProfile;
	details: any;
	showLinkItem: boolean = false;
	@ViewChild(ListingGroupListingsComponent) listings: ListingGroupListingsComponent;

	constructor(
		public stateService: StateService,
		public modalService: BsModalService,
		userService: UserService,
		public companyListingsService: CompanyListingsService,
	) {
		this.User = userService.profile;
	}

	ngOnInit(): void {
		const { listingGroupId } = this.stateService.params;
		this.details = this.companyListingsService.listingGroupDetails({ listingGroupId });
	}

	renameGroup(): void {
		const initialState = {
			listingGroup: this.details,
			save: (data: Partial<ListingGroup>) => {
				const { listingGroupId } = this.stateService.params;
				const params = {
					listingGroupId,
				};

				return this.companyListingsService.saveListingGroupPartial(
					params,
					data,
				).$promise
					.then((response: ListingGroup) => {
						Object.assign(
							this.details,
							response,
						);
					});
			},
		};
		this.modalService.show(
			RenameListingGroupModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			},
		);
	}

	updateNmlsId(nmlsId) {
		this.details.nmlsId = nmlsId;
	}

	showAddListing() {
		this.showLinkItem = true;
	}

	hideAddListing() {
		this.showLinkItem = false;
	}

	doAddListing() {
		this.listings.update();
		this.hideAddListing();
	}
}
