<div class="remote-resource lender-crm-contact-list-contacts" [class.resolved]="!resolving">
    <add-contact-list-contact
        [listResolving]="resolving"
        [organizationId]="organizationId"
        [contactListId]="existingContactList.id"
        [userCanAddContactToList]="userCanAddContactToList"
        [userCanExportContactList]="userCanExportContactList"
        [exportDownloadUrl]="exportDownloadUrl"
        (contactAdded)="updateList()"
    ></add-contact-list-contact>
    <br />
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-sm-2">
                <div class="text-light">Search</div>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-sm-2">
                <search-list placeholder="Email or Name"></search-list>
            </div>
        </div>
    </div>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-6">
                <b>Name</b>
            </div>
            <div class="col-md-a">
                <b>Title</b>
            </div>
            <div class="col-md-a">
                <b>Designation</b>
            </div>
            <span class="col-sm-a"></span>
        </div>
        <div class="col-md-a">
            <div class="row-fluid">
                <reset-filters></reset-filters>
            </div>
        </div>
        <div *ngIf="!resolving && ((list | async)?.length > 0); else noRecord">
            <div *ngFor="let contact of list | async" class="row-fluid table-row">
                <div columnName="name" class="col-md-6">
                    <a class="col-md-6"
                        uiSref="client.:id.contacts.:contactId.information"
                        [uiParams]="{ id: contact.tpoId, contactId: contact.contactId }"
                    >
                        {{ contact.name }}
                    </a>
                </div>
                <div columnName="title" class="col-md-a">
                    <span>{{ contact.title || '&ndash;' }}</span>
                </div>
                <div columnName="designation" class="col-md-a">
                    <span>{{ contact.designation || '&ndash;' }}</span>
                </div>
                <span class="col-sm-a text-right">
                    <span *ngIf="contact.manuallyAdded" class="glyphicon glyphicon-exclamation-sign green"></span>
                    <span *ngIf="!contact.manuallyAdded && contact.excludedFromCriteria"
                          class="glyphicon glyphicon-exclamation-sign red"
                          [tooltip]="'Contact result no longer matches list criteria'"
                          tooltipPlacement="left"
                    >
                    </span>
                    <a *ngIf="userCanDeleteContactFromList" class="btn btn-default btn-sm" (click)="removeFromList(contact)">
                        Remove
                    </a>
                </span>
            </div>
        </div>
        <ng-template #noRecord>
            <div class="col-sm-12 text-center text-light text-bigger">
                {{ filtersApplied ? 'No matches found' : 'No records added' }}
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
