import {
    AfterViewInit,
    Directive,
    ContentChild,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { FormControlDirective, ValidatorFn, Validators } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Subject } from 'rxjs';
import { isUndefined } from 'lodash';


@Directive()
export abstract class ShortHandEditComponent<T = (number | string)> implements OnInit, AfterViewInit, OnDestroy {
    @Input() uniqueBaseId: string = uuidv4();
    @Input() fieldName!: any;

    private _value!: T;
    @Input() set value(value: T) {
        this._value = value;

        this.form.controls.value.setValue(this.value);
    }
    get value(): T {
        return this._value;
    }

    @Input() displayValue: boolean = true;
    @Input() readOnly: boolean = false;

    @Input() viewControlClasses: string = '';
    @Input() controlsOnLeftSide: boolean = false;
    @Input() clickOutsideCancels: boolean = false;
    @Input() escKeyCancels: boolean = true;
    @Input() enterKeySaves: boolean = true;

    private _requiredValidator: boolean = true;
    @Input() set requiredValidator(requiredValidator: boolean) {
        this._requiredValidator = requiredValidator;

        this.validatorsDirtied();
    }
    get requiredValidator(): boolean {
        return this._requiredValidator;
    }

    private _allValidators: ValidatorFn[] = [];
    private _providedValidators: ValidatorFn[] = [];
    private _internalValidators: ValidatorFn[] = [];
    @Input() set validators(validators: ValidatorFn[]) {
        if (!validators) {
            validators = [];
        }

        this._providedValidators = validators;

        this.validatorsDirtied();
    }
    get validators(): ValidatorFn[] {
        return this._allValidators;
    }

    @Output() editChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() saveTriggered: EventEmitter<void> = new EventEmitter();

    @ContentChildren(FormControlDirective, { descendants: true }) formControls: QueryList<FormControlDirective>;
    @ContentChild(TemplateRef, { static: false }) viewTemplateRef: TemplateRef<any>;

    form: RealmFormGroup = new RealmFormGroup({
        value: new RealmFormControl(
            'value',
            {
                label: 'Value',
                updateOn: 'change',
            },
            Validators.required,
        ),
    });

    isEditing: boolean = false;
    isSaving: boolean = false;

    protected destroy$: Subject<void> = new Subject();

    constructor(required: boolean = true) {
        // Is this a hack? Probably, but we need to allow for requiredValidator to be set to false before the first time we set validators
        if (!isUndefined(required)) {
            this.requiredValidator = !!required;
        }
    }

    // Override
    ngOnInit(): void {
        this.validatorsDirtied();
    }

    // Override
    ngAfterViewInit(): void {
    }

    // Override
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    startEditing(): void {
        this.form.controls.value.setValue(this.value);
        this.isEditing = true;

        this.handleStartEditingUI();

        this.editChanged.emit(this.isEditing);
    }

    cancelEditing(): void {
        this.isEditing = false;

        this.handleCancelEditingUI();

        this.editChanged.emit(this.isEditing);
    }

    protected abstract handleStartEditingUI(): void;
    protected abstract handleCancelEditingUI(): void;

    async triggerSave(): Promise<void> {
        // TODO: Rework/rethink types here when we actually do patching
        const rawFormValue: any = this.form.getRawValue().value;
        const processedValue: T = this.processValue(rawFormValue);

        const pseudoPatch: any = {
            [this.fieldName]: processedValue,
        };

        try {
            this.isSaving = true;

            this.saveTriggered.emit(pseudoPatch);

            this.cancelEditing();
        } finally {
            this.isSaving = false;
        }
    }

    handleEscapeKey(): void {
        if (this.escKeyCancels && this.isEditing) {
            this.cancelEditing();
        }
    }

    protected processValue(value: any): T {
        return <T>value;
    }

    protected rebuildInternalValidators(): ValidatorFn[] {
        const internalValidators: ValidatorFn[] = [];
        if (this.requiredValidator) {
            internalValidators.push(Validators.required);
        }

        return internalValidators;
    }

    protected readonly validatorsDirtied = (): void => {
        this._internalValidators = this.rebuildInternalValidators();
        this._allValidators = [
            ...this._internalValidators,
            ...this._providedValidators,
        ];

        this.form.controls.value.setValidators(this._allValidators);
        this.form.updateValueAndValidity();
    };
}
