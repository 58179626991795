import { Injector, Injectable } from '@angular/core';

declare let apiPath: string;

@Injectable()
export class OrganizationResourceService {
	services: any;
	roles: any;
	investor: any;

	constructor(
		public injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');

		const servicesPath = `${apiPath}/customers/:organizationId/services`;
		this.services = $resource(servicesPath, {}, {
			get: { method: 'get' },
		});

		const rolesBasePath = `${apiPath}/organizations/:organizationId/roles`;
		this.roles = $resource(rolesBasePath, {}, {
			query: { method: 'get', isArray: true },
		});

		const investorPath = `${apiPath}/organizations/:organizationId/investor/channels/:channelId`;
		this.investor = $resource(investorPath, {}, {
			list: {method: 'get', isArray: true},
		});
	}

	getServices = (...args) => this.services.get(...args);
	queryRoles = (...args) => this.roles.query(...args);
	getInvestorChannels = (...args) => this.investor.list(...args);
}
