<div class="remote-resource" [class.resolved]="resolved">
    <form [formGroup]="form" (onValidSubmit)="submit()">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="close()">&times;</button>
            <h4 class="modal-title text-center">Manage Audit Status</h4>
        </div>
        <div class="modal-body">
            <div class="alert alert-danger">Please note: Any audits moved to a closed condition will automatically close any associated findings and tasks.</div>
            <form-errors [form]="form"></form-errors>
            <span>Please choose a new 'Audit Status' for the selected audits.</span>
            <div class="rows-white">
                <div class="row">
                    <div class="col-md-4">
                        <span class="text-light">Current Status</span>
                    </div>
                    <div class="col-md-4">
                        <span class="text-light">Selected Audits</span>
                    </div>
                    <div class="col-md-4">
                        <span class="text-light">New Status</span>
                    </div>
                </div>
            </div>
            <div class="rows-white" formArrayName="auditStatusControls" *ngFor="let control of auditStatusControls.controls">
                <div class="row">
                    <div class="col-md-4">
                        <span>{{control.get('oldStatusName').value}}</span>
                    </div>
                    <div class="col-md-4">
                        <b>{{control.get('selectedNumber').value}}</b>
                    </div>
                    <div class="col-md-4">
                        <ng-select
                            [items]="auditStatuses"
                            bindLabel="status"
                            [formControl]="control.get('status')"
                            [clearable]="false"
                            placeholder="Select Status"
                        ></ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group pull-right">
                <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
                <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

