<div>
	<div class="modal-header clearfix">
		<button type="button" class="close fixed-height pull-right" aria-label="Close" (click)="hide()">&times;</button>
		<h4 class="modal-title pull-left">Inactivate Role</h4>
	</div>
	<div class="modal-body">
		<p>
			{{notification}}
		</p>
	</div>
	<div class="modal-footer">
		<div class="text-center">
			<button type="button" (click)="hide()" class="btn btn-lg btn-primary">Ok</button>
		</div>
	</div>
</div>
