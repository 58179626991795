<div class="post-type">
	<div class="flex-head">
		<ul class="breadcrumbs">
			<li>
				<a uiSref="social-compliance.publisher">
					<span class="glyphicon glyphicon-chevron-left"></span>
					Publisher
				</a>
			</li>
		</ul>
		<h2>
			{{ postType.title || 'View Post' }}
		</h2>

		<div class="col-min">
			<a class="btn btn-primary" uiSref="^.new">Create Post</a>
		</div>
	</div>

	<section>

		<notification></notification>

		<div class="rows-bordered" [ngClass]="{'remote-resource': list, 'resolved': list.$resolved && accounts.$resolved}">
			<div class="row-fluid header">
				<div class="col-md-5">
					<search-list placeholder="Search"></search-list>
				</div>
				<div *ngFor="let column of postType.columns" [ngClass]="[column.class || 'col-md-a']">
					<b class="two-liners">{{ column.title }}</b>
				</div>
				<div class="col-md-2"></div>
			</div>

			<div class="row-fluid" *ngFor="let item of list">
				<div class="col-md-5 clearfix">
					<div class="preview horizontal-flex-group">
						<div class="image-holder" [class.attachment-video]="item.isVideoThumbnail">
							<div class="post-image"
								 *ngIf="item.imagePreviewLink"
								 [style.background-image]="item.imagePreviewLink | urlSanitizer"></div>
							<i *ngIf="!item.imagePreviewLink" class="c-icon c-icon-user-rounded"></i>
						</div>
						<div class="description" *ngIf="item.text">
							<show-more
								[text]="item.text"
								[maxHeight]="100"
								[showAtLeastOneWord]="true"
								[escapeText]="false"
							></show-more>
						</div>
					</div>
				</div>
				<div *ngFor="let column of postType.columns" class="va-m" [ngClass]="[column.class || 'col-md-a']">
					<div [ngSwitch]="column.id">
						<!-- Draft Date -->
						<div *ngSwitchCase="'updatedDate'">
							{{ item.updatedDate | date:'MM/dd/yyyy, hh:mm a' }}
						</div>
						<!-- Scheduled Date -->
						<div *ngSwitchCase="'scheduledDate'">
							<div [ngClass]="{ 'text-danger': overdue(item) }">
								{{ item.scheduledZoneTime }}
							</div>
						</div>
						<!-- Posted Date -->
						<div *ngSwitchCase="'postedDate'">
							{{ item.sentDate | date:'MM/dd/yyyy, hh:mm a' }}
						</div>
						<!-- Updated By -->
						<div *ngSwitchCase="'updatedBy'">
							{{ item.updatedBy }}
						</div>
					</div>
				</div>
				<div class="col-md-2 text-right va-m">
					<a class="btn btn-default" uiSref=".:postId" [uiParams]="{ postId: item.id }">View</a>
				</div>
			</div>

			<div class="row-fluid" *ngIf="!list.length && filters.q">
				<span class="col-md-12 text-muted text-center text-bigger text-weak">No matches found</span>
			</div>

			<div class="row-fluid" *ngIf="!list.length && !filters.q">
				<span class="col-md-12 text-muted text-center text-bigger">No draft posts</span>
			</div>

			<br />

			<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
		</div>

	</section>

</div>
