import { Component, forwardRef, Input } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { LocationsService } from 'shared/account/locations/locations.service';
import { LocationChannel } from 'shared/account/locations/single/channels/locations-channels.interface';
import { Observable } from 'rxjs';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import {
    RelationshipStatusBadgeService,
    TransitionsMap
} from 'commons/services/utils/relationship-status-badge.service';
import { map } from 'rxjs/operators';
import { AccountInformation } from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: './locations-channels.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => LocationsChannelsComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => LocationsChannelsComponent) },
    ],
})
export class LocationsChannelsComponent extends NewPagedListComponent<LocationChannel> {
    @Input()
    lenderInfo: AccountInformation;
    static listName = 'locations-channels';

    tpoId: number;
    lenderId: number;
    nmlsId: number;

    userCanEditStatus: boolean;
    userCanEditAssigned: boolean;
    isLocationHeadquarter: boolean;

    statusTransitions: TransitionsMap;

    // Note: lenderInfo can be used to get the TPOID, but it will get the NMLSID for the Company,
    // which will only be correct for HQ locations and not for branch.
    constructor(
        router: UIRouter,
        public locationsService: LocationsService,
        public userService: UserService,
        public statusLabelService: RelationshipStatusBadgeService,
    ) {
        super(router);
        this.nmlsId = router.globals.params.branchId;
        this.lenderId = this.userService.profile.organization.id;
        this.userCanEditAssigned = this.userService.profile.can('EDIT_LOCATION_ASSIGNMENT');
        this.userCanEditStatus = this.userService.profile.can('EDIT_LOCATION_STATUS');
    }

    async ngOnInit() {
        super.ngOnInit();
        this.tpoId = this.lenderInfo.identifier.tpoId;
        this.isLocationHeadquarter = this.lenderInfo.nmlsId == this.nmlsId;
        this.statusTransitions = await this.statusLabelService.getStatusTransitions().toPromise();
    }

    protected loadList(params: ListParams): Observable<any> {
        return this.locationsService.getChannelsList(this.nmlsId, this.tpoId, this.lenderId, params).pipe(
            map(data => {
                data.data.forEach((location: LocationChannel) => {
                        location.label = this.statusLabelService.getBadge(location.approvalStatus);
                    }
                );
                return data;
            })
        );
    }
}
