<div class="edit-values-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Update Custom Field Value(s)</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<p>You are about to make changes to your custom field values. Select one of the options below:</p>
		<label>
			<input type="radio"  name="has-update-records" (change)="hasUpdateRecords = true"> Update existing records
		</label>
		<label>
			<input type="radio" name="has-update-records" (change)="hasUpdateRecords = false"> Apply to new records only
		</label>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button
				class="btn btn-primary"
				type="button"
				[disabled]="!hasUpdateRecords && hasUpdateRecords !== false"
				(click)="onSave(hasUpdateRecords)"
			>Save</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>