import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { Subscription } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { IndividualNmlsService } from './individual-nmls.service';

import { EmploymentHistory } from './interfaces/employment-history.interface';
import { MloName } from './interfaces/mlo-name.interface';
import { OtherTradeName } from './interfaces/other-trade-name.interface';
import { MfsFindingSummary } from './interfaces/financial-sanctions.interface';


@Component({
    templateUrl: './individual-nmls.component.html',
})
export class IndividualNmlsComponent implements OnInit {
    nmlsId: number;
    userProfile: UserProfile;

    mloNameResolving: boolean = false;
    mloName: MloName;

    companiesResolving: boolean = false;
    companies: EmploymentHistory[] = [];

    otherNamesResolving: boolean = false;
    otherNames: OtherTradeName[] = [];

    financialSanctionsResolving: boolean = false;
    financialSanctions: MfsFindingSummary[] = [];

    constructor(
        private readonly individualNmlsService: IndividualNmlsService,
        private readonly routerGlobals: UIRouterGlobals,
        private readonly userService: UserService,
    ) {
    }

    // Override
    ngOnInit(): void {
        this.nmlsId = this.routerGlobals.params.nmlsId;
        this.userProfile = this.userService.profile;

        this.fetchMloName();
        this.fetchCompanies();
        this.fetchOtherNames();
        this.fetchFinancialSanctions();
    }

    private fetchMloName(): void {
        this.mloNameResolving = true;
        const subscription: Subscription = this.individualNmlsService
            .getMloName(this.nmlsId)
            .subscribe((mloName: MloName) => this.mloName = mloName);
        subscription.add(() => this.mloNameResolving = false);
    }

    private fetchCompanies(): void {
        this.companiesResolving = true;
        const subscription: Subscription = this.individualNmlsService
            .getEmploymentHistory(this.nmlsId)
            .subscribe((companies: EmploymentHistory[]) => {
                this.companies = companies;
                this.companies.forEach((employmentHistory: EmploymentHistory) => this.configureRouterValuesOnCompanies(employmentHistory));
            });
        subscription.add(() => this.companiesResolving = false);
    }

    private fetchOtherNames(): void {
        this.otherNamesResolving = true;
        const subscription: Subscription = this.individualNmlsService
            .getOtherTradeNames(this.nmlsId)
            .subscribe((otherNames: OtherTradeName[]) => this.otherNames = otherNames);
        subscription.add(() => this.otherNamesResolving = false);
    }

    private fetchFinancialSanctions(): void {
        this.financialSanctionsResolving = true;
        const subscription: Subscription = this.individualNmlsService
            .getMfsFindingSummaries(this.nmlsId)
            .subscribe((financialSanctions: MfsFindingSummary[]) => this.financialSanctions = financialSanctions);
        subscription.add(() => this.financialSanctionsResolving = false);
    }

    private configureRouterValuesOnCompanies(company: EmploymentHistory): void {
        if (company.tpoId) {
            const prefix: string = (this.userProfile.isComergence ? 'tpo' : 'client');
            company.accountHomeUiSref = `${prefix}.:id.account`;
            company.uiSrefParams = { id: company.tpoId, contactId: company.contactId };
        } else {
            company.accountHomeUiSref = 'nmls.client.:id.account';
            company.uiSrefParams = { id: company.companyNmlsId, contactId: company.contactId };
        }

        company.uiSrefParams.locationsList = { searchNmlsId: this.nmlsId };
    }
}
