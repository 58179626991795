<h2>Federal Registration</h2>

<div class="remote-resource"  [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-md-3">
                <span class="text-muted small">Search</span>
            </div>
            <div class="col-md-3">
                <span class="text-muted small">Authorization</span>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-md-3">
                <search-list placeholder="Name or NMLS ID"></search-list>
            </div>
            <div class="col-md-3">
				<ng-select
					[ngModel]="params.authorized"
					(change)="setFilter({ authorized: $event.value })"
                    [clearable]="false"
					[items]="authorizedValues"
					bindLabel="label"
					bindValue="value"
				></ng-select>
            </div>
        </div>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <sortable-header class="col-sm-5" columnName="name">Name</sortable-header>
            <sortable-header class="col-sm-2" columnName="status">Status</sortable-header>
            <sortable-header class="col-sm-3" columnName="authorization">Authorization</sortable-header>
            <sortable-header class="col-sm-2" columnName="startDate">Start Date</sortable-header>
        </div>
        <reset-filters class="row-fluid"></reset-filters>
        <ng-container *ngIf="((list | async)?.length > 0); else noCustomers">
            <div class="row-fluid"
                *ngFor="let item of list | async"
            >
                <div class="col-md-5">
                    {{ item.name }}<br>
                    <a uiSref="^.licenses.nmls.:nmlsId" [uiParams]="{ nmlsId: item.nmlsId }">
                        NMLS ID: {{ item.nmlsId }}
                    </a>
                </div>
                <div class="col-md-2">
                    <span class="label" [class.label-success]="item.status === 'Active'" [class.label-default]="item.status !== 'Active'">
                        {{ item.status }}
                    </span>
                </div>
                <div class="col-md-3">
                    <span class="solid">
                        <span class="glyphicon"
                            [class.text-success]="item.authorized"
                            [class.glyphicon-ok-sign]="item.authorized"
                            [class.text-danger]="!item.authorized"
                            [class.glyphicon-remove]="!item.authorized"
                        >
                    </span>
                    &nbsp;
                    {{ item.authorized ? '' : 'Not' }} Authorized
				</span>
                </div>
                <div class="col-md-2">
                    {{ item.startDate }}
                </div>
            </div>
        </ng-container>
        <ng-template #noCustomers>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    {{ filtersApplied ? 'No matches found' : 'No records added' }}
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
