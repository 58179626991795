<div class="msa-list">
	<div class="text-muted help-text" *ngIf="!isDetails">
		Select MSA code to see companies:
	</div>

	<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
		<div class="row-fluid header">
			<sortable-header
					*ngFor="let column of msaListColumns"
					class="{{column.class}}"
					columnName="{{column.id}}">
				{{column.title}}
			</sortable-header>
		</div>
		<div class="row-fluid" *ngFor="let item of list | async">
			<div *ngFor="let column of msaListColumns" class="{{column.class}}">
				<ng-container [ngSwitch]="column.id">
					<div *ngSwitchCase="'msaCode'">
						<a
							uiSref="crm.market.companies"
							[uiParams]="{ msa: item.msaCode, objectType: 'MSA', hintText: null }"
						>
							{{item.msaCode}}
						</a>
					</div>
					<div *ngSwitchDefault [ngSwitch]="column.filter">
						<ng-container *ngSwitchCase="'currency'">{{ (item[column.id] | currency : 'USD' : '$' : '1.0-0') || "&ndash;" }}</ng-container>
						<ng-container *ngSwitchCase="'percent'">{{ (item[column.id] | number: '1.2')+'%' || "&ndash;" }}</ng-container>
						<ng-container *ngSwitchCase="'number:2.2'">{{ (item[column.id] | number: '2.2') || "&ndash;" }}</ng-container>
						<ng-container *ngSwitchCase="'number'">{{ (item[column.id] | number) || "&ndash;" }}</ng-container>
						<ng-container *ngSwitchDefault>{{ item[column.id] || "&ndash;" }}</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length">
			<div class="col-md-12 text-center text-bigger text-muted">Please use Search to see Market information</div>
		</div>
		<br>
		<paginator [sizes]="[10, 25, 50]"></paginator>
	</div>
</div>
