import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { ComponentsModule } from 'commons/components/components.module';
import { CustomFieldsModule } from 'shared/fields';

import { RelatedAccountModule } from 'lender/contacts/related-account';
import { MarketModule } from './market/market.module';

import { DateFormatterService } from './contact/investor-crm-contact-lists/services/date-formatter.service';
import { InvestorCrmContactListService } from './contact/investor-crm-contact-lists/services/investor-crm-contact-list.service';
import { InvestorCrmContactListBuilderService } from './contact/investor-crm-contact-lists/services/investor-crm-contact-list-builder.service';
import { InvestorCrmCloneContactListBuilderService } from './contact/investor-crm-contact-lists/services/investor-crm-clone-contact-list-builder.service';
import { InvestorCrmContactListCriteriaMapperService } from './contact/investor-crm-contact-lists/services/investor-crm-contact-list-criteria-mapper.service';

import { InvestorCrmContactListComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list/investor-crm-contact-list.component';
import { InvestorCrmContactListGeneralInformationComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list/investor-crm-contact-list-general-information/investor-crm-contact-list-general-information.component';
import { InvestorCrmContactListCriteriaComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list/investor-crm-contact-list-criteria/investor-crm-contact-list-criteria.component';
import { InvestorCrmContactVisibilityListComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list/investor-crm-contact-list-visibility-list/investor-crm-contact-list-visibility-list.component';
import { InvestorCrmContactListLandingPageComponent } from 'lender/crm/contact/investor-crm-contact-lists/investor-crm-contact-list-landing-page/investor-crm-contact-list-landing-page.component';
import { InvestorCrmContactListContactsComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/investor-crm-contact-list-contacts.component';
import { ContactListContactsListComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/contact-list-contacts-list/contact-list-contacts-list.component';
import { AddContactListContactComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/contact-list-contacts-list/add-contact-list-contact/add-contact-list-contact.component';
import { AddContactSearchComponent } from './contact/investor-crm-contact-lists/contact-list-container/investor-crm-contact-list-contacts/contact-list-contacts-list/add-contact-list-contact/add-contact-search/add-contact-search.component';
import { ProspectsModule } from 'lender/prospects/prospects.module';
import { ActivitiesModule } from 'lender/crm/activities/activities.module';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PipesModule,
		DirectivesModule,
		ComponentsModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		CustomFieldsModule,
		RelatedAccountModule,
		MarketModule,
        ProspectsModule,
        ActivitiesModule,
	],
	declarations: [
		InvestorCrmContactListComponent,
		InvestorCrmContactListGeneralInformationComponent,
		InvestorCrmContactListCriteriaComponent,
		InvestorCrmContactVisibilityListComponent,
        InvestorCrmContactListLandingPageComponent,
		InvestorCrmContactListContactsComponent,
		ContactListContactsListComponent,
		AddContactListContactComponent,
		AddContactSearchComponent,
	],
	providers: [
		DateFormatterService,
		InvestorCrmContactListService,
		InvestorCrmContactListBuilderService,
		InvestorCrmCloneContactListBuilderService,
		InvestorCrmContactListCriteriaMapperService,
	],
	exports: [],
})

export class CRMModule {
}
