import { Injectable } from '@angular/core';

import { AbstractIssueStatus } from 'tpo/status/abstractIssueStatus';
import { DocusignConnectivityResourceService } from './docusign-connectivity-resource.service';

@Injectable()
export class DocumentsDocusignConnectivityStatus extends AbstractIssueStatus {
	public constructor(private readonly docusignConnectivityResourceService: DocusignConnectivityResourceService) {
		super();
	}

	// Override
	protected async updateValue(): Promise<boolean> {
		return (await this.docusignConnectivityResourceService.getConnectivityStatus().toPromise()).value;
	}
}
