<div class="gray-head flex-head">
	<div class="col-fit">
		<ul class="breadcrumbs">
			<li>
				<a uiSref="customers.:customerType.:organizationId.manage"
					[uiParams]="{ customerType: customerType, organizationId: organizationId }"
				>Manage Users</a>
			</li>
		</ul>
		<h2 class="title-label">
			<span>{{getUserName()}}</span>
			&nbsp;
            <span *ngIf="user.$resolved && !isNew" class="label text-capitalize label-{{LenderUserStatusColors[user?.status?.id] || 'default'}}">
				{{user?.status?.name | lowercase}}
			</span>
		</h2>
	</div>

	<div class="col-min" *ngIf="!isNew">
		<ul class="tab-menu col-min">
			<li [class.active]="'view' === modeName || 'edit' == modeName">
				<a uiSref="customers.:customerType.:organizationId.manage.:actionType.:id"
					[uiParams]="{ customerType: customerType, organizationId: organizationId, id: userId, actionType: 'view' }"
				>
					<b>User Information</b>
				</a>
			</li>
			<li [class.active]="'audit-trail' === modeName">
				<a uiSref="customers.:customerType.:organizationId.manage.:actionType.:id"
					[uiParams]="{ customerType: customerType, organizationId: organizationId, id: userId, actionType: 'audit-trail' }"
				>
					<b>Audit Trail</b>
				</a>
			</li>
			<li [class.active]="'login-history' === modeName">
				<a uiSref="customers.:customerType.:organizationId.manage.:actionType.:id"
					[uiParams]="{ customerType: customerType, organizationId: organizationId, id: userId, actionType: 'login-history' }"
				>
					<b>Login History</b>
				</a>
			</li>
		</ul>
	</div>
</div>

<div *ngIf="modeName !== 'login-history' && modeName !== 'audit-trail'">
	<comergence-customer-user-details
		[user]="user"
		[editable]="editable"
		[isNew]="isNew"
        (statusUpdateEmitter)="updateUser($event)"
	></comergence-customer-user-details>
</div>

<div *ngIf="modeName === 'login-history'">
	<user-login-log [params]="{userId: userId, type: modeName}"></user-login-log>
</div>

<div *ngIf="modeName === 'audit-trail'">
	<user-action-log [params]="{userId: userId, type: modeName}"></user-action-log>
</div>
