<div class="social-media-campaigns">

	<div class="gray-head flex-head">
		<h2 class="col-fit">Campaigns</h2>
		<div class="col-min" *ngIf="canManage">
			<a class="btn btn-primary" uiSref="social-compliance.publisher.campaigns.new">Create</a>
		</div>
	</div>

	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-md-3">
				<div class="text-muted small">
					Search
				</div>
			</div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-md-3">
				<search-list placeholder="Campaign Name"></search-list>
			</div>
			<div class="col-md-9">
				<button
					type="button"
					class="btn-toggle btn"
					[class.active]="params.enrolledOnly"
					(click)="toggleEnrolledFilter()">Enrolled</button>
			</div>
		</div>
	</div>

	<div class="rows-bordered" [ngClass]="{ 'remote-resource': campaigns, 'resolved': campaigns.$resolved }">
		<div class="row-fluid header">
			<div class="col-md-4">
				Name
			</div>
			<div class="col-md-2" [ngClass]="getSortClass('status')" (click)="setSort('status')"><b class="two-liners">Status</b></div>
			<div class="col-md-3" [ngClass]="getSortClass('updatedBy')" (click)="setSort('updatedBy')"><b class="two-liners">Updated By</b></div>
			<div class="col-md-3"></div>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<div class="col-md-12 text-center va-m">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</div>
		</div>

		<div class="row-fluid" *ngFor="let item of campaigns">
			<div class="col-md-4 va-m">
				<a uiSref="social-compliance.publisher.campaigns.:campaignId" [uiParams]="{ campaignId: item.id }">{{item.name || '&ndash;'}}</a>
			</div>
			<div class="col-md-2 va-m">
				<span class="label min-100 text-smaller label-success" *ngIf="item.status === 'Not Started'">Not Started</span>
				<span class="label min-100 text-smaller label-warning" *ngIf="item.status === 'In Progress'">In Progress</span>
				<span class="label min-100 text-smaller label-default" *ngIf="item.status === 'Ended'">Ended</span>
			</div>
			<div class="col-md-3 va-m">{{item.updatedBy || '&ndash;'}}</div>
			<div class="col-md-3 va-m">
				<button (click)="showModal(item.id)" *ngIf="!item.enrolledAccountsCount && item.canBeEnrolled"  class="btn btn-default">
					Enroll
				</button>
				<a (click)="showModal(item.id)" *ngIf="item.enrolledAccountsCount && item.canBeEnrolled">
					Enrolled ({{item.enrolledAccountsCount}} {{item.enrolledAccountsCount === 1 ? 'account' : 'accounts'}})
				</a>
			</div>
		</div>

		<div class="row-fluid" *ngIf="noMatches">
			<div class="col-md-12 text-center text-bigger text-muted">No matches found</div>
		</div>

		<div class="row-fluid" *ngIf="!campaigns.length && !noMatches">
			<div class="col-md-12 text-center text-bigger text-muted">No campaigns</div>
		</div>

		<br>
		<new-paging class="no-border" [(list)]="campaigns" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>

</div>
