import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class ActivitiesResourceService {
	private activityResource: any;
	public resources: any;
	public relatedAccount: any;
	public relatedContact: any;
	public relatedProspect: any;
	public comments: any;
	public activities: any;
	public clientActivities: any;
    public contactActivities: any;

    constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const activityPath = `${apiPath}/crm/activity/:id`;
		const valuePath =`${apiPath}/code-value`;

		// https://r5-dev.ccmdev.com/swagger-ui.html#!/activity45resource/getActivitiesUsingGET
		this.activityResource = (activitiesPath: string = activityPath) => {
			return PagedResource(activitiesPath, {
				id: '@id',
				nmlsId: '@nmlsId',
				organizationContactId: '@organizationContactId',
				companyPath: '@companyPath',
			}, {
				findCompany: {
					method: 'GET',
					url: `${apiPath}/:companyPath/:clientId/company-information`,
					params: { clientId: '@clientId' },
				},
				update: {
					method: 'PUT',
				},
				create: {
					method: 'POST',
				},
				assignedTo: {
					method: 'GET',
					isArray: true,
					url: `${activitiesPath}/assignedTo`,
				},
				assignedFilter: {
					method: 'GET',
					isArray: true,
					url: `${activitiesPath}/filter/assignedTo`,
				},
			});
		}
        this.activities = this.activityResource();
        this.clientActivities = this.activityResource(`${apiPath}/nmls/company/:nmlsId/activity/:id`);
        this.contactActivities = this.activityResource(`${apiPath}/generic-contact/:organizationContactId/activity/:id`);

		this.comments = PagedResource(`${activityPath}/comment`, { id: '@id' }, {
			add: {
				method: 'POST',
			},
			commentType: {
				method: 'GET',
				isArray: true,
				url: `${apiPath}/commenttype`,
			},
		});

		this.resources = $resource(valuePath, {}, {
			priorities: {
				method: 'GET',
				isArray: true,
				url: `${valuePath}/CRMActivityPriority`,
			},
			statuses: {
				method: 'GET',
				isArray: true,
				url: `${valuePath}/CRMActivityStatus`,
			},
		});

		const relatedAccountsPath = `${activityPath}/relatedAccount`;
		this.relatedAccount = $resource(relatedAccountsPath, { id: '@id' }, {
			hints: {
				method: 'GET',
				url: `${relatedAccountsPath}Hint`,
				isArray: true,
			},
			update: {
				method: 'PUT',
			},
		});

		const relatedContactsPath = `${activityPath}/relatedContact`;
		this.relatedContact = $resource(`${relatedContactsPath}Hint`, { id: '@id' }, {
			update: {
				method: 'PUT',
				url: `${relatedContactsPath}`,
			},
			delete: {
				method: 'DELETE',
				url: `${relatedContactsPath}`,
			},
		});

		const relatedProspectsPath = `${activityPath}/relatedProspect`;
		this.relatedProspect = $resource(`${relatedProspectsPath}`, { id: '@id' }, {
			prospect: {
				method: 'GET',
				url: `${apiPath}/lenders/:lenderId/prospect-board/:id`,
				params: { lenderId: User.profile.organization.id, id: '@id' },
			},
			query: {
				method: 'GET',
				url: `${apiPath}/lenders/:lenderId/prospect-board`,
				isArray: true,
				params: { lenderId: User.profile.organization.id },
			},
			update: {
				method: 'PUT',
				url: `${relatedProspectsPath}/:prospectId`,
				params: { prospectId: '@prospectId', nmlsId: '@nmlsId' },
			},
		});
	}
}
