import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertRecord } from 'lender/alerts/alert.interface';
import { StateService } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { License } from 'shared/account/licenses';
import { LicenseDetailsModalComponent } from 'shared/account/licenses/details-modal';
import { SharedLicensesService } from 'shared/account/licenses/licenses.service';

@Component({
    templateUrl: './alert-details-view-record.component.html',
    selector: 'alert-details-view-record',
})
export class AlertDetailsViewRecordComponent {
    @Input() alertRecord: AlertRecord;

    @Output() readonly resolved: EventEmitter<boolean> = new EventEmitter();
	modalRef: BsModalRef;

    constructor(
        private readonly stateService: StateService,
        private readonly sharedLicensesService: SharedLicensesService,
		private readonly modalService: BsModalService,
    ) {
    }

    async viewAlertRecord(): Promise<void> {
        switch (this.alertRecord.alertCategoryCode) {
            case 'CNMLS-TNA':
                this.stateService.go(
                    'client.:id.account.dba',
                    {
                        id: this.alertRecord.tpoId,
                    }
                );
                break;
            case 'CNMLS-CAC':
            case 'CNMLS-CNC':
                this.stateService.go(
                    'client.:id.account.information',
                    {
                        id: this.alertRecord.tpoId,
                    }
                );
                break;
            case 'INMLS-INC':
            case 'INMLS-ISC':
                this.stateService.go(
                    'nmls.individual.:nmlsId',
                    {
                        nmlsId: this.alertRecord.individualNmlsId,
                    }
                );
                break;
            case 'INMLS-IAC':
                this.stateService.go(
                    'client.:id.account.locations',
                    {
                        id: this.alertRecord.tpoId,
                    }
                );
                break;
            case 'AF-AA':
            case 'AF-CIA':
                this.stateService.go(
                    'client.:id.relationships.affiliates.:affiliateId',
                    {
                        id: this.alertRecord.tpoId,
                        affiliateId: this.alertRecord.affiliateId,
                    }
                );
                break;
            case 'CNMLS-LA':
            case 'CNMLS-LNA':
            case 'INMLS-LA':
            case 'INMLS-LNA':
                this.getLicenseAndOpenModal();
                break;
            case 'CS-OFAC':
            case 'CS-FCC':
            case 'CS-SOS':
            case 'CS-B':
            case 'CS-JL':
            case 'IS-OFAC':
            case 'IS-SLC':
            case 'IS-FCC':
            case 'IS-B':
            case 'IS-JL':
            case 'IS-FL':
            case 'CEA-HUDLDP':
            case 'CEA-MFS':
            case 'IEA-HUDLDP':
            case 'IEA-MFS':
            case 'CEA-SAM':
            case 'CEA-CFPB':
            case 'CEA-NCUA':
            case 'CEA-FDIC':
            case 'CEA-OCC':
            case 'CEA-FHFA':
            case 'IEA-SAM':
            case 'IEA-CFPB':
            case 'IEA-NCUA':
            case 'IEA-FDIC':
            case 'IEA-OCC':
            case 'IEA-FHFA':
                this.stateService.go('client.:id.reviews.scorecard.finding.:sType.:sid', {
                    id: this.alertRecord.tpoId,
                    sType: this.alertRecord.findingCategory ? this.alertRecord.findingCategory : 'OFAC',
                    sid: this.alertRecord.scorecardFindingID,
                });
                break;
            case 'OW-CAP':
            case 'OW-OA':
            case 'OW-OR':
                if (this.alertRecord.individualContactId) {
                    this.stateService.go(
                        'client.:id.contacts.:contactId.information',
                        {
                            id: this.alertRecord.tpoId,
                            contactId: this.alertRecord.individualContactId,
                        }
                    );
                }
                if (this.alertRecord.companyContactId) {
                    this.stateService.go(
                        'client.:id.contacts.company.:contactId',
                        {
                            id: this.alertRecord.tpoId,
                            contactId: this.alertRecord.companyContactId,
                        }
                    );
                }
                break;
            case 'BNMLS-LA':
            case 'BNMLS-LNA':
                this.getLicenseAndOpenModal();
                break;
            case 'BNMLS-BAC':
                this.stateService.go(
                    'client.:id.account.locations',
                    {
                        id: this.alertRecord.tpoId,
                    }
                );
                break;
            case 'BNMLS-TNA':
                this.stateService.go(
                    'client.:id.account.dba',
                    {
                        id: this.alertRecord.tpoId,
                    }
                );
                break;
        }
    }

	showLicenseDetails(license: License): void {
		const initialState = {
			license,
		};
		this.modalRef = this.modalService.show(
			LicenseDetailsModalComponent,
			{
				initialState,
				class: 'modal-vlg modal-new',
			},
		);
	}

    async getLicenseAndOpenModal() {
        this.resolved.emit(false);
        try {
            const license: License = await this.sharedLicensesService
                .getLicenseNew(this.alertRecord.entityLicenseId)
                .toPromise();
            this.showLicenseDetails(license);
        } finally {
            this.resolved.emit(true);
        }
    }
}
