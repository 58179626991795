import {
    ChangeDetectorRef, Directive, ElementRef, HostBinding,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { GenericSideMenuItem } from 'commons/components/navigation/menu/side/side-menu.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class SideMenuContainerComponent<T extends GenericSideMenuItem = GenericSideMenuItem> implements OnInit, OnDestroy {
    menuItems: T[];
    destroyed$ = new Subject<void>();
    menuUpdate$: Observable<unknown>;
    expanded: boolean = true;
    visible: boolean = false;

    @HostBinding('class.side-menu-element') _elementClass = true;
    @ViewChild('menuElement', { read: ElementRef }) menuElement: ElementRef;
    @ViewChild('toggleElement', { read: ElementRef }) toggleElement: ElementRef;

    constructor(
        protected changeDetector: ChangeDetectorRef,
        public router: UIRouterGlobals,
    ) {
    }

    ngOnInit() {
        this.router.success$.pipe(
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.visible = false;
            this.changeDetector.markForCheck();
        })
    }

    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    toggleExpanded() {
        this.expanded = !this.expanded;
    }

    showMenu() {
        this.visible = true;
    }

    hideIfVisible($event) {
        if (this.visible) {
            $event.preventDefault();
            this.toggleElement.nativeElement.blur();
        }
    }

    delayHideMenu($event) {
        const isInsideClick = this.menuElement.nativeElement.contains($event.relatedTarget);

        if (!isInsideClick) {
            this.visible = false;
        }
    }
}
