import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { RelatedAccountComponent } from './related-account.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		ComponentsModule,
		DirectivesModule,
		PipesModule,
	],
	declarations: [
		RelatedAccountComponent,
	],
	exports: [
		RelatedAccountComponent,
	],
})
export class RelatedAccountModule {}
