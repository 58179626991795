<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
	<h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div class="alert alert-danger" *ngIf="errorText" [innerHTML]="errorText"></div>
	<div [innerHTML]="message"></div>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="text-right">
		<button type="button" (click)="onConfirm()" class="btn btn-primary">{{confirmText}}</button>
		<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
	</div>
</div>
