import { Component } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { SmConfirmModalComponent } from 'commons/components/modals';

import {
	PublishingPermissionsResourceService,
	IPermissionsResourceService,
} from 'tpo/social-compliance/publishing-permissions';
import { ListParams, NewListComponent } from 'commons/components/new-list/list.component';
import { PublisherRequest } from 'tpo/social-compliance/publishing-permissions/publisher-request.interface';


@Component({
	templateUrl: './new-publisher-requests.component.html',
})
export class NewPublisherRequestsComponent extends NewListComponent<PublisherRequest> {
	static listName = 'newPublisherRequests';

	permissionsResource: IPermissionsResourceService;
	modalRef: BsModalRef;

	constructor(
        uiRouter: UIRouter,
		publishingPermissionsResourceService: PublishingPermissionsResourceService,
		public modalService: BsModalService,
	) {
		super(
			uiRouter,
		);

		this.permissionsResource = publishingPermissionsResourceService.individual;
	}

    // Override
	ngOnDestroy(): void {
		super.ngOnDestroy();

		this.modalRef?.hide();
	}

    // Override
    loadList(params: ListParams): Observable<PublisherRequest[]> {
		return this.permissionsResource.listRequests();
	}

	confirmAccess(
		account,
		positive: boolean = false
	): void {
		const {
			id,
			linkName,
			requestorName,
		} = account;
		const action = positive ? 'accept' : 'decline';
		const initialState = {
			title: `${action.toUpperCase()} Request`,
			message: positive
				? `By accepting this request you are granting <strong>${requestorName}</strong>? to post on your behalf for this account. You can revoke access at any time.`
				: `Are you sure you want to decline this Publisher Request for <strong>${requestorName}</strong> for this account?`,
			confirmText: action.toUpperCase(),
			account: {
				...account,
				name: linkName,
			},
			onConfirm: (): void => {
				const modalScope = this.modalRef.content;
				modalScope.resolving = true;
				modalScope.errorText = null;
				this.permissionsResource.requests[action]({ id }).$promise
					.then(
						() => {
							this.modalRef.hide();
							this.updateList();
						},
						({ status, data }) => {
							modalScope.resolving = false;
							modalScope.errorText = data.message;
						}
					);
			},
		};

		this.modalRef = this.modalService.show(SmConfirmModalComponent, { initialState, class: 'modal-smd modal-new' });
	}
}
