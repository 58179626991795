import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class LenderContactsResourceService {
	contact: any;
	relatedAccount: any;

	constructor(public injector: Injector, public User: UserService) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');
		const contactsPath = `${apiPath}/lenders/:lenderId/contacts`;
		const contactPath = `${contactsPath}/:contactId`;
		const defaultParams = { lenderId: User.profile.organization.id };

		// https://r5-dev.ccmdev.com/swagger-ui.html#/investor45marketing45contact45resource
		this.contact = PagedResource(`${contactPath}`, defaultParams, {
			query: { method: 'get', isArray: true },
			letters: { method: 'get', isArray: true, url: `${contactPath}/filter-options` },
		});

		const relatedAccountsPath = `${contactPath}/relatedAccount`;
		this.relatedAccount = $resource(relatedAccountsPath, { ...defaultParams, contactId: '@contactId' }, {
			hints: {
				method: 'GET',
				url: `${relatedAccountsPath}Hint`,
				isArray: true,
			},
			update: {
				method: 'PUT',
			},
		});
	}

	query = (...args) => this.contact.query(...args);
	get = (...args) => this.contact.get(...args);
	letters = (...args) => this.contact.letters(...args);
}
