import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';
import {
	Channel,
	ClientDocumentDetails,
} from 'lender/clients/$id/documents/client-documents.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator, FileSizeValidator, MaxLengthValidator } from 'commons/validators';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UIRouterGlobals } from '@uirouter/core';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

export enum DocumentType {
	CSD = 'Client Specific',
	GD = 'Global Documents',
}

@Injectable()
export abstract class AbstractClientDocumentRequest implements OnInit, OnDestroy {
	user: UserProfile;
	params: any;
	DocumentType = DocumentType;
	documentTypes = [
		{
			type: DocumentType.CSD,
			label: 'Client Specific',
		},
		{
			type: DocumentType.GD,
			label: 'Global Documents',
		},
	];
	documents: NgResourceArray<ClientDocumentDetails>;
	channels: NgResourceArray<Channel>;
	form = new RealmFormGroup({
		type: new RealmFormControl(
			'type',
			{ label: 'Type', updateOn: 'change' },
		),
		document: new RealmFormControl(
			'document',
			{ label: 'Document', updateOn: 'change' },
			[
				DropdownRequiredValidator,
			],
		),
		documentDetails: new RealmFormGroup({
			id: new RealmFormControl(
				'id',
				{},
			),
			name: new RealmFormControl(
				'name',
				{ label: 'Document Name' },
				[
					Validators.required,
					MaxLengthValidator(100),
				],
			),
			description: new RealmFormControl(
				'description',
				{ label: 'Description' },
				[
					Validators.required,
					MaxLengthValidator(2000),
				],
			),
			hasTemplate: new RealmFormControl(
				'hasTemplate',
				{},
			),
			templateName: new RealmFormControl(
				'templateName',
				{},
			),
			templateFile: new RealmFormControl(
				'templateFile',
				{},
				[
					FileSizeValidator(20 * Math.pow(2, 20)),
				],
			),
			channelsSettings: new RealmFormControl(
				'channelsSettings',
				{ label: 'Channels' },
				[
					DropdownRequiredValidator,
				],
			),
		}),
	});
	formCache;

	getFormControl = (controlName: string) => this.form.get(controlName) as RealmFormControl;

	getFormGroup = (groupName: string) => this.form.get(groupName) as RealmFormGroup;

	get type(): DocumentType {
		return this.getFormControl('type').value;
	}

	abstract get templateDownloadLink(): string;

	create: boolean;
	edit = false;

	get view(): boolean {
		return !(this.create || this.edit);
	}

	get title(): string {
		if (this.create) {
			return 'New Document Request';
		}
		return this.getFormControl('document').value?.name;
	}

	resolved = true;
	unsubscribe$ = new Subject<void>();

	constructor(
		routerGlobals: UIRouterGlobals,
		userService: UserService,
	) {
		this.user = userService.profile;
		const { id: tpoId, documentId } = routerGlobals.params;
		this.params = { tpoId, ...(documentId && { documentId }) };
	}

	ngOnInit(): void {
		this.getFormControl('type').valueChanges
			.pipe(
				distinctUntilChanged(),
				takeUntil(this.unsubscribe$),
			)
			.subscribe(this.setDocumentType);

		this.getFormControl('document').valueChanges
			.pipe(
				startWith(null),
				distinctUntilChanged(),
				takeUntil(this.unsubscribe$),
			)
			.subscribe(this.setDocumentDetails);

		if (this.create) {
			this.initCreateForm();
		} else {
			this.initEditForm();
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	setDocumentType = (type: DocumentType): void => {
		const documentFormControl = this.getFormControl('document');
		const documentDetailsFormGroup = this.getFormGroup('documentDetails');

		switch (type) {
			case DocumentType.CSD: {
				documentFormControl.disable({ emitEvent: false });

				documentDetailsFormGroup.enable({ emitEvent: false });
				this.setDocumentDetails(this.formCache);

				break;
			}
			case DocumentType.GD: {
				documentFormControl.enable({ emitEvent: false });

				this.formCache = documentDetailsFormGroup.value;
				this.setDocumentDetails(documentFormControl.value);
				documentDetailsFormGroup.disable({ emitEvent: false });

				break;
			}
		}
	};

	setDocumentDetails = (documentDetails: Document): void => {
		this.getFormGroup('documentDetails').reset(documentDetails || {}, { emitEvent: false });
	};

	setTemplate = (input?): void => {
		let hasTemplate,
			templateName,
			templateFile;

		if (input) {
			templateFile = input.target
				? input.target.files[0]
				: input[0];
		}

		if (templateFile) {
			hasTemplate = true;
			templateName = templateFile.name.replace(/\.\w+$/, '');
		}

		this.getFormGroup('documentDetails').patchValue({
			hasTemplate,
			templateName,
			templateFile,
		});
	};

	includesChannel = ({ id }: Channel): boolean => {
		const channelsSettingsControl = this.getFormControl('documentDetails.channelsSettings');
		const channelsSettings = channelsSettingsControl.value || [];
		return channelsSettings.find(((item) => item.id === id));
	};

	toggleChannel = ({ id }: Channel): void => {
		const channelsSettingsControl = this.getFormControl('documentDetails.channelsSettings');
		const channelsSettings = channelsSettingsControl.value || [];
		const updatedChannelsSettings = channelsSettings.find(((item) => item.id === id))
			? channelsSettings.filter(((item) => item.id !== id))
			: channelsSettings.concat({ id: id });
		channelsSettingsControl.reset(updatedChannelsSettings);
	};

	initEditForm = async (): Promise<void> => {

	};

	initCreateForm = (): void => {
		this.form.patchValue({
			type: DocumentType.CSD,
		});
	};


	abstract submit(): Promise<void>;

	toggleEdit(edit = !this.edit): void {
		this.edit = edit;
	}

	abstract cancel(): void;
}
