<div class="social-media-post-header">
	<div class="icon-container">
		<sm-icon [config]="iconConfig"></sm-icon>
	</div>

	<div class="title-container">
		<ng-container *ngIf="post.webLink; then accountLink; else accountName"></ng-container>
		<ng-template #accountLink>
			<a [href]="post.webLink" target="_blank">{{title}}</a>
            <a *ngIf="displayWebLink" [href]="post.webLink" target="_blank" class="text-muted">{{ post.webLink }}</a>
		</ng-template>
		<ng-template #accountName>
			{{title}}
		</ng-template>

		<div class="text-muted">
			{{post.createdDate | date: 'MMMM d, yyyy \'at\' HH:mm a'}}
		</div>
	</div>

	<ng-content></ng-content>
</div>
