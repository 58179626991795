<div class="gray-head">
    <h2 *ngIf="finding$ | async; let finding">
        <span class="pull-left">
            <span class="scorecard-finding-header-label">
                {{ finding.name }}
            </span>
            <!-- TODO: Eventually make a new component for these? -->
            <span *ngIf="finding.finding?.removedFromSourceSystem"
                class="label label-danger text-smaller scorecard-finding-header-content"
            >
                Removed
            </span>
            <span *ngIf="isCCM && finding.isVisible"
                class="label label-success text-smaller scorecard-finding-header-content"
            >
                Visible
            </span>
            <span *ngIf="isCCM && !finding.isVisible"
                class="label label-default text-smaller scorecard-finding-header-content"
            >
                Not Visible
            </span>
        </span>

        <span class="pull-right scorecard-finding-header-content mark-reviewed-button">
            <scorecard-finding-summary-mark-reviewed
                [isUserCommergence]="isCCM"
                [isUserLender]="isLender"
                [hasReviewFindingPermission]="hasReviewFindingPermission"
                [hasEditAndViewAlertPermission]="hasEditAndViewAlertPermission"
                [findingId]="finding.id"
                [isUpdated]="false"
                [reviewed]="finding.reviewed"
                [reviewer]="finding.reviewer"
                [tpoId]="tpoId"
                [lenderId]="lenderId"
                (resolving)="changeResolving($event)"
                (findingReviewed)="updateFinding()"
            ></scorecard-finding-summary-mark-reviewed>
        </span>
        <span class="pull-right">
            <a [uiSref]="sref"
                [uiParams]="srefParams"
                [uiOptions]="{ inherit: false, reload: true }"
                class="text-medium scorecard-finding-header-content"
            >
            History
        </a>&nbsp;&nbsp;
        </span>
    </h2>
</div>
