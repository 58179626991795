import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorModalService } from 'commons/services/error/error-modal.service';
import { AppInsightsStatisticsService, BERequest } from 'commons/services/3rd-party/app-insights/app-insights-stats.service';

declare let window: Window;

@Injectable()
export class PublicTokenInjector implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(
            window.accessToken
                ? req.clone({
                    headers: req.headers.set('REALM-LIMITED-ACCESS-TOKEN', window.accessToken),
                })
                : req
        );
    }
}

@Injectable()
export class AIRequestHandler implements HttpInterceptor {
    constructor(
        private readonly appInsightsStatisticsService: AppInsightsStatisticsService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const startTime = Date.now();
        const request = new Subject<BERequest>();
        this.appInsightsStatisticsService.trackRequest(request.asObservable());

        return next.handle(req).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    const { url } = response;
                    const duration = Date.now() - startTime;
                    request.next({ url, duration });
                    request.complete();
                }
            })
        );
    }
}

@Injectable()
export class HttpErrorHandler implements HttpInterceptor {
    constructor(private errorModalService: ErrorModalService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    const { url } = response;

                    // TODO: redo notification in a new angular
                    return this.errorModalService.postponeTimeout();
                }
            }),
            catchError(response => {
                if (response instanceof HttpErrorResponse) {
                    let { error, ...rest } = response;
                    if (typeof error === 'string') {
                        try {
                            error = JSON.parse(error);
                            response = new HttpErrorResponse({ ...rest, error });
                        } catch (e) {
                            // ignoring if false
                        }
                    }

                    if (typeof error !== 'object') {
                        error = {};
                        response = new HttpErrorResponse({ ...rest, error });
                    }

                    error.message = error.message || 'Oops! It looks like something went wrong. Please try again. If you continue to receive this error, please notify Comergence';
                }

                return throwError(response);
            }),
        );
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: PublicTokenInjector, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AIRequestHandler, multi: true },
];
