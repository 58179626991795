import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { NavigationMenuItem } from '../../../utils/navigation/navigation.interface';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash';


@Component({
	selector: 'abstract-role-nav',
    template: '',
})
export abstract class AbstractRoleNavComponent implements OnInit, OnDestroy {
    private _menuItems: NavigationMenuItem[] = [];
    public set menuItems(menuItems: NavigationMenuItem[]) {
        this._menuItems = menuItems;
    }
    public get menuItems(): NavigationMenuItem[] {
        return this._menuItems;
    }

    protected userProfileUnsubscribe$: Subject<void> = new Subject();

    public hackUsedByNewAngular: boolean = true;

    constructor(
            protected readonly stateService: StateService,
            protected readonly userService: UserService) {
    }

    // Override
    public ngOnInit(): void {
        this.userService.profile$
            .pipe(takeUntil(this.userProfileUnsubscribe$))
            .subscribe((userProfile: UserProfile) => this.menuItems = this.rebuildMenuItems(userProfile));
    }

    // Override
    public ngOnDestroy(): void {
        this.userProfileUnsubscribe$.next();
        this.userProfileUnsubscribe$.complete();
    }

    protected abstract rebuildMenuItems(userProfile: UserProfile): NavigationMenuItem[];

    protected coalesceIsActive(items: NavigationMenuItem[]): () => boolean {
        return () => {
            const result: boolean = items.some((item: NavigationMenuItem) => {
                if (!isNil(item.isActive) && item.isActive()) {
                    return true;
                } else if (this.stateService.includes(item.sref)) {
                    return true;
                }

                return false;
            });

            return result;
        };
    }

    protected coalesceHasAlert(items: NavigationMenuItem[]): Observable<boolean> {
        const hasAlerts: Observable<boolean>[] = items
            .filter((item: NavigationMenuItem) => !isNil(item.hasAlert))
            .map((item: NavigationMenuItem) => item.hasAlert);

        const joined: Observable<boolean> = this.coalesceHasAlertObservables(hasAlerts);

        return joined;
    }

    protected coalesceHasAlertObservables(hasAlerts: Observable<boolean>[]): Observable<boolean> {
        const joined: Observable<boolean> = combineLatest(hasAlerts).pipe(
            map((hasAlertValues: boolean[]) => {
                return hasAlertValues.some((hasAlertValue: boolean) => {
                    return hasAlertValue;
                });
            }));
        
        return joined;
    }
}
