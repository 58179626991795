import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import {
	DropdownRequiredValidator,
	FileSizeValidator,
} from 'commons/validators';

export const DocumentDetailsForm = new RealmFormGroup({
	name: new RealmFormControl(
		'name',
		{ label: 'Document Name' },
		Validators.required,
	),
	description: new RealmFormControl(
		'description',
		{ label: 'Description' },
		Validators.required,
	),
	channelsSettings: new RealmFormControl(
		'channelsSettings',
		{ label: 'Channel Settings' },
		DropdownRequiredValidator,
	),
	libraryId: new RealmFormControl(
		'libraryId',
		{ label: 'Document', disabled: true },
		DropdownRequiredValidator,
	),
	templateFile: new RealmFormControl(
		'file',
		{ label: 'Template' },
		FileSizeValidator(20 * Math.pow(2, 20)),
	),
	templateName: new RealmFormControl(
		'templateName',
		{ label: '' },
	),
	hasTemplate: new RealmFormControl(
		'hasTemplate',
		{ label: '' },
	),
	docuSignTemplateId: new RealmFormControl(
		'docuSignTemplateId',
		{ label: 'Document', disabled: true },
		DropdownRequiredValidator,
	),
});
