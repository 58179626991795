import { Component, forwardRef } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { NgResource } from 'commons/interfaces';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { TriggerHistoryModalComponent } from 'shared/social-compliance';

import { Trigger, TriggerHistory, TriggersService } from '../../triggers.service';

@Component({
	templateUrl: './trigger-history.component.html',
	viewProviders: [
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => TriggerHistoryComponent) },
	],
})
export class TriggerHistoryComponent extends NewPagedListComponent<TriggerHistory> {
	static listName = 'triggerHistoryList';

	User: UserProfile;
	triggerId: number;
	details: NgResource<Trigger>;
	modalRef: BsModalRef;

	get hasViewPermissions(): boolean {
		return this.User.can('TPO_SMC_VIEW_ALL') || this.User.can('CCM_SMC_VIEW_ALL');
	}

	constructor(
		public router: UIRouter,
		private triggersService: TriggersService,
		public modalService: BsModalService,
		{ params: { triggerId } }: UIRouterGlobals,
		{ profile }: UserService,
	) {
		super(router);
		this.User = profile;
		if (!this.hasViewPermissions) { return; }

		this.triggerId = triggerId;
		this.details = this.triggersService.get({ triggerId });
	}

	loadList(params: ListParams) {
		return this.triggersService.history(this.triggerId, params);
	}

	showHistoryModal(history: TriggerHistory): void {
		this.modalRef = this.modalService.show(TriggerHistoryModalComponent, {
			initialState: {
				history,
				onConfirm: () => {
					this.modalRef.hide();
				},
			},
			class: 'modal-md modal-new',
		});
	}
}
