import { find, map } from 'lodash';
import { Component, OnChanges, Input } from '@angular/core';

import { ShareToTypes, TypeNames } from './share-to.types';

@Component({
	selector: 'campaign-share-to-view',
	templateUrl: './view.component.html',
})
export class CampaignShareToViewComponent implements OnChanges {
	@Input()
	selectedType: string = '';

	@Input()
	entries: any = [];

	@Input()
    inputLabel: string = '';

	@Input()
	isExtendedView: boolean = false;

	outputLabel: string = '';

	TypeNames = TypeNames;
	ShareToTypes = ShareToTypes;
	typeFromConfig: any;

	ngOnChanges(): void {
		if (!this.selectedType) {
			this.outputLabel = '';
			return;
		}

		this.typeFromConfig = find(ShareToTypes, (typeObj) => (typeObj.id === this.selectedType));

		if (!this.typeFromConfig) {
			this.outputLabel = '';
			return;
		}

		// none, or all users selected -> use label from endpoints response
		if (this.selectedType === TypeNames.USERS || this.selectedType === TypeNames.NONE) {
			// label can come from server or we can use our own config
			this.outputLabel = this.inputLabel || this.typeFromConfig.name;
			return;
		}

		// otherwise collect group names and show
		const groupNames = map(this.entries, 'name');
		this.outputLabel = groupNames.join(', ');
	}
}
