<div class="market">
	<div class="companies-list">
		<market-filters [locationParams]="locationParams"></market-filters>
		<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
			<br>
			<div class="row-fluid header">
				<div class="col-f-40"></div>
				<div class="col-md-4">
					<b>Account</b>
				</div>
				<div class="col-md-a">
					<b>Location</b>
				</div>
				<div class="col-md-a">
					<b class="two-liners">Headquarter Phone</b>
				</div>
				<div class="col-md-a">
					<b>Contact Name</b>
				</div>
				<div class="col-md-a text-right">
					<b>Volume</b>
				</div>
				<div class="col-md-a text-right">
					<b>Ind. NMLS</b>
				</div>
				<div class="col-md-3"></div>
			</div>
			<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
				<div class="col-sm-12 va-m text-center">
					Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
				</div>
			</div>
			<div class="row-fluid" *ngFor="let item of list | async">
				<div class="col-f-40 col-w-logo va-m">
					<div class="flex-column center content-center flex-wrap">
						<div class="logo-image logo-img" *ngIf="item.tpoId"></div>
						<span *ngIf="item.isNqm" class="label label-primary-wired text-smaller">NQM</span>
					</div>
				</div>
				<div class="col-md-4">
					<a class="text-bigger" *ngIf="item.tpoId" uiSref="client.:id.account.information" [uiParams]="{ id: item.tpoId }">
						{{item.companyName}}
					</a>
					<a class="text-bigger" *ngIf="!item.tpoId" uiSref="nmls.client.:id.account.information" [uiParams]="{ id: item.companyNmlsId }">
						{{item.companyName}}
					</a>
					<div class="text-muted">
						NMLS ID: {{item.companyNmlsId}}
						<span class="label text-smaller" [ngClass]="item.locationType == 'HQ' ? 'label-default' : 'label-default-wired'">
							{{item.locationType == 'HQ' ? 'Headquarters' : 'Branch'}}
						</span>
					</div>
				</div>
				<div class="col-md-a">
					{{item.location || '&ndash;'}}
				</div>
				<div class="col-md-a">
					{{item.headquarterPhone || '&ndash;' | tel}}
				</div>
				<div class="col-md-a">
					{{item.contactName || '&ndash;'}}
				</div>
				<div class="col-md-a text-right">
					{{(item.totalVolume | currency : 'USD' : '$' : '1.0-0') || '&ndash;'}}
				</div>
				<div class="col-md-a text-right">
					{{item.individualNmlsCount || '&ndash;'}}
				</div>
				<div class="col-md-1">
					<div *ngIf="item.hasRelationship" class="text-center">
						<i class="c-icon c-icon-users"></i>
					</div>
				</div>
				<div class="col-md-1">
					<prospecting-status [canAdd]="hasProspectPermission" [prospectInfo]="item"></prospecting-status>
				</div>
				<div class="col-md-1 text-right">
					<a
						class="btn btn-default"
						*ngIf="item.tpoId && User.can('INVITE_TO_APPLY')"
						uiSref="client.:id.account.channels"
						target="_blank"
						[uiParams]="{ id: item.tpoId }"
					>
						Invite
					</a>
				</div>
			</div>
			<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && filtersApplied">
				<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
			</div>
			<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !filtersApplied">
				<div class="col-md-12 text-center text-bigger text-muted">Please use Search to see Market information</div>
			</div>
			<br>
			<paginator [sizes]="[10, 25, 50]"></paginator>
		</div>
	</div>
</div>
