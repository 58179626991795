import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';

import { TpoAccountMenuComponent } from 'tpo/account/tpo-account-menu.component';
import { CommonModule } from '@angular/common';
import { AgencyComponent } from 'tpo/account/agency/agency.component';
import { AgencyService } from 'tpo/account/agency/agency.service';
import { DirectivesModule } from 'commons/directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { InvestorsComponent } from 'tpo/account/investors/investors.component';
import { InvestorsService } from 'tpo/account/investors/investors.service';
import { InvestorItemComponent } from 'tpo/account/investors/investor-item/investor-item.component';
import { AffiliatesListComponent } from 'tpo/account/affiliates/affiliates-list.component';
import { AffiliatesAddEditViewComponent } from 'tpo/account/affiliates/affiliates-add-edit-view.component';
import { AffiliatesService } from 'tpo/account/affiliates/affiliates.service';
import { UnderwritingComponent } from 'tpo/account/underwriting/underwriting.component';
import { UnderwritingService } from 'tpo/account/underwriting/underwriting.service';
import { UnderwritingServiceItemComponent } from 'tpo/account/underwriting/underwriting-service-item.component';
import { PipesModule } from 'commons/pipes/pipes.module';
import { TpoAccountComponent } from './tpo-account.component';
import { LenderSectionReviewService } from 'tpo/account/section-review/section-review.service';
import { SectionReviewComponent } from 'tpo/account/section-review/section-review.component';
import { InvestorsInformationTableComponent } from 'tpo/account/investors/investor-information-table/investor-information-table.component';


const components = [
    TpoAccountMenuComponent,
    AgencyComponent,
    InvestorsComponent,
    InvestorItemComponent,
    AffiliatesAddEditViewComponent,
    AffiliatesListComponent,
    UnderwritingComponent,
    UnderwritingServiceItemComponent,
    TpoAccountComponent,
    SectionReviewComponent,
    InvestorsInformationTableComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        NgSelectModule,
    ],
    declarations: [
        ...components,
    ],
    providers: [
        AgencyService,
        AffiliatesService,
        InvestorsService,
        UnderwritingService,
        LenderSectionReviewService,
    ],
    exports: [
        SectionReviewComponent,
    ],
})
export class TpoAccountModule {
}
