import { Injectable } from '@angular/core';
import { RealmHttpClient, RealmParams } from 'commons/services/http';
import { AgencyInterface } from 'tpo/account/agency/agency.interface';
import { Observable } from 'rxjs';

declare let apiPath: string;

@Injectable()
export class AgencyService {

    constructor(
        private http: RealmHttpClient,
    ) {}

    getCurrentUser() {}

    uploadLetter(tpoId: number, agencyTypeId: number, file: File): Observable<File> {
        return this.http.uploadFileWithForm(
            'POST',
            `${apiPath}/tpos/${tpoId}/agencies/${agencyTypeId}/approval-letter`,
            'file',
            null,
            file,
            null
        );
    }

    getAgencies(tpoId: number): Observable<AgencyInterface[]> {
        return this.http.request<AgencyInterface[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/agencies`
        );
    }

    updateAgency(tpoId: number, agency: AgencyInterface): Observable<AgencyInterface>{
        return this.http.request<AgencyInterface>(
            'PUT',
            `${apiPath}/tpos/${tpoId}/agencies/${agency.agencyTypeId}`,
            {},
            agency
        )
    }

}
