import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClientDocument } from '../documents.interface';
import { DocumentComponent } from '../document/document.component';

@Component({
	selector: 'client-document',
	templateUrl: './client-document.component.html',
})
export class ClientDocumentComponent<T extends ClientDocument> extends DocumentComponent<T> implements OnInit {
	@Input('className')
	className?: string | string[];

	@Input()
	downloadLink?: string;

	@Input()
	templateDownloadLink?: string;

	@Input()
	discontinuedBadgeText?: string;

	@Output('upload')
	private _upload = new EventEmitter<FileList>();

	@Output('showHistory')
	private _showHistory = new EventEmitter<void>();

    canManageDocuments: boolean;

	upload(fileList: FileList): void {
		this._upload.emit(fileList);
	}

	showHistory(): void {
		this._showHistory.emit();
	}

    ngOnInit(): void {
        this.canManageDocuments = this.user.can('MANAGE_CLIENT_DOCUMENTS');
    }
}
