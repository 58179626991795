import {filter} from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'filter',
})
export class FilterPipe implements PipeTransform {
	/* Function is too performance heavy */
	transform(items: any[], searchParams: object/* | Function */): any[] {
		if (!items) { return []; }

		if (!searchParams) { return items; }

		return filter(items, searchParams);
	}
}
