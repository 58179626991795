<section class="social-compliance-publisher-finding-task remote-resource" [class.resolved]="!remediation || remediation.$resolved">
	<public-task-sign-in *ngIf="User.isPublicAccess" [follow]="'/social-compliance/findings/edit/' + stateService.params.findingId"></public-task-sign-in>
	<ng-container *ngIf="!remediation || remediation.$resolved">
		<form [formGroup]="remediationForm" class="form-vertical remote-resource" [class.resolved]="!resolving" (onValidSubmit)="submit()">
			<h3 class="flex-row gap">
				<span class="flex">
					{{title}}
				</span>
				<ng-container *ngIf="remediation && !User.isPublicAccess">
					<ng-container *ngIf="!edit">
						<task-notification-button
							*ngIf="finding?.contact && canNotify"
							[tpoId]="finding.contact.tpoId"
							[nmlsId]="finding.contact.nmlsId"
							[contactId]="finding.contact.contactId"
							[contactName]="finding.contact.contactFirstName + ' ' + finding.contact.contactLastName"
						></task-notification-button>
						<button
							*ngIf="canEdit"
							type="button"
							class="btn btn-secondary"
							(click)="toggleEdit(true)"
						>
							<span class="glyphicon glyphicon-pencil"></span> Edit
						</button>
					</ng-container>
					<ng-container *ngIf="!canEdit && isAssignee">
						<button type="button" class="btn btn-primary" (click)="markComplete()" *ngIf="remediation.status !== 'Completed'">
							Mark Complete
						</button>
						<button type="button" class="btn btn-primary" disabled *ngIf="remediation.status === 'Completed'">
							<span class="glyphicon glyphicon-ok"></span> Completed
						</button>
					</ng-container>
					<ng-container *ngIf="edit">
						<button type="button" class="btn btn-secondary btn-icon" (click)="remove()">
							<span class="glyphicon glyphicon-trash"></span>
						</button>
						<button type="submit" class="btn btn-primary">
							Save
						</button>
						<button type="button" class="btn btn-secondary" (click)="toggleEdit(false)">
							Cancel
						</button>
					</ng-container>
				</ng-container>
			</h3>

			<form-errors [form]="remediationForm"></form-errors>

			<div class="row form-group">
				<ng-container *ngIf="remediation">
					<div class="col-sm-6">
						<label for="status" class="control-label" [class.required]="edit">
							{{remediationForm.getFieldLabel('status')}}
						</label>
						<editable-component [editable]="edit">
							<ng-template #viewTemplate>
								<div class="control-value text-bigger">
									<span class="label" [ngClass]="{'label-warning': ['Open', 'Reopened'].includes(remediation.status), 'label-success': remediation.status == 'Completed'}">
										{{remediation.status}}
									</span>
								</div>
							</ng-template>
							<ng-template #editTemplate>
								<ng-select
									id="status"
									formControlName="status"
									[items]="statuses"
									bindLabel="title"
									[clearable]="false"
								></ng-select >
							</ng-template>
						</editable-component>
					</div>
				</ng-container>
				<div [ngClass]="remediation ? 'col-sm-6' : 'col-sm-12'">
					<label for="dueDate" class="control-label" [class.required]="edit">
						{{remediationForm.getFieldLabel('dueDate')}}
					</label>
					<editable-component [view]="(remediation?.dueDate | date: 'MM/dd/yyyy') || '&ndash;'" [editable]="edit">
						<ng-template #editTemplate>
							<input type="text" class="form-control"
								   id="dueDate"
								   formControlName="dueDate"
								   bsDatepicker
								   [bsConfig]="bsConfig"
								   [minDate]="isNew ? today : null"
								   placement="bottom left"
								   placeholder="mm/dd/yy"
								   autocomplete="off"
							/>
						</ng-template>
					</editable-component>
				</div>
			</div>

			<div class="form-group">
				<label class="control-label">
					Account
				</label>
				<div class="control-value horizontal-flex-group">
					<div *ngIf="finding.mediaLink" class="icon-container">
						<sm-icon [config]="finding.mediaLink"></sm-icon>
					</div>
					<div class="link-container text-overflow-ellipsis">
						<ng-container *ngIf="finding.mediaLink?.networkCode !== 'RS'; then linkAnchor; else linkName"></ng-container>
						<ng-template #linkAnchor>
							<a [href]="finding.mediaLink?.link | hrefReady" target="_blank">{{finding.mediaLink?.name}}</a>
						</ng-template>
						<ng-template #linkName>
							{{finding.mediaLink?.name || '&ndash;'}}
						</ng-template>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label class="control-label" [class.required]="edit">
					{{remediationForm.getFieldLabel('issues')}}
				</label>
				<editable-component [editable]="edit">
					<ng-template #viewTemplate>
						<ng-container *ngFor="let issue of remediation?.issues">
							<div class="issue">
								<ul class="observations">
									<li *ngFor="let observation of issue.observations" class="flex-row gap">
										<div class="issue-number">{{issue.number}}</div>
										<span class="observation-description flex">{{observation.observation}}</span>
									</li>
								</ul>
							</div>
						</ng-container>
					</ng-template>
					<ng-template #editTemplate>
						<ng-container *ngFor="let issue of finding?.issues">
							<div class="issue">
								<ul class="observations">
									<li *ngFor="let observation of issue.observations" class="flex-row gap">
										<div class="issue-number">{{issue.number}}</div>
										<label class="checkbox-inline flex-row">
											<input type="checkbox" [checked]="isObservationSelected(issue.id, observation.id)" (change)="onObservationChange($event, issue.id, observation.id)">
											{{observation.observation}}
										</label>
									</li>
								</ul>
							</div>
						</ng-container>
					</ng-template>
				</editable-component>
			</div>

			<ng-container *ngIf="!remediation">
				<div class="form-group">
					<label for="communication" class="control-label">
						{{remediationForm.getFieldLabel('communication')}}
					</label>
					<textarea class="form-control resize-vertical"
							  id="communication"
							  formControlName="communication"
							  rows="3"
							  maxlength="2000"
					></textarea>
				</div>

				<div class="clearfix">
					<div class="pull-right">
						<button type="submit" class="btn btn-primary">
							Save
						</button>
						<button type="button" class="btn btn-secondary" (click)="cancelTaskCreation()">
							Cancel
						</button>
					</div>
				</div>
			</ng-container>
		</form>
	</ng-container>
</section>
