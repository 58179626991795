<div class="client-document">
	<div class="gray-head">
		<back-bread>{{title}}</back-bread>
	</div>

	<form [formGroup]="form" class="remote-resource" [class.resolved]="resolved" (onValidSubmit)="submit()">
		<section class="document-details">
			<h3 class="flex-row">
				Document Information
				<div class="right">
					<ng-container [ngSwitch]="view">
						<ng-container *ngSwitchCase="true">
							<button type="button" class="btn btn-primary" (click)="toggleEdit(true)">
								<span class="glyphicon glyphicon-pencil"></span> Edit
							</button>
						</ng-container>
						<ng-container *ngSwitchCase="false">
							<button type="submit" class="btn btn-primary">Save</button>
							<button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
						</ng-container>
					</ng-container>
				</div>
			</h3>

			<form-errors [form]="form"></form-errors>

			<div class="rows-striped">
				<div class="row">
					<label class="col-sm-2 control-label">
						{{form.getFieldLabel('type')}}
					</label>
					<div class="col-sm-10">
						<ng-container [ngSwitch]="!create">
							<ng-container *ngSwitchCase="true">
								<span class="control-value">{{getFormControl('type').value}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="false">
								<ng-container *ngFor="let documentType of documentTypes">
									<label class="control-label radio-inline text-default">
										<input
											type="radio"
											[formControl]="getFormControl('type')"
											[value]="documentType.type"
										>
										{{documentType.label}}
									</label>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>
				</div>

				<ng-container [ngSwitch]="type">
					<ng-container *ngSwitchCase="DocumentType.CSD">
						<ng-container [ngSwitch]="view">
							<ng-container *ngSwitchCase="true">
								<div class="row">
									<label class="col-sm-2 control-label">
										{{form.getFieldLabel('documentDetails.name')}}
									</label>
									<div class="col-sm-10">
										<span class="control-value">{{getFormControl('documentDetails.name').value}}</span>
									</div>
								</div>
							</ng-container>
							<ng-container *ngSwitchCase="false">
								<div class="row form-group" has-error>
									<label class="col-sm-2 control-label required">
										{{form.getFieldLabel('documentDetails.name')}}
									</label>
									<div class="col-sm-5">
										<input
											type="text"
											class="form-control"
											[formControl]="getFormControl('documentDetails.name')"
											maxlength="100"
											required
										/>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="DocumentType.GD">
						<div class="row form-group" has-error>
							<label class="col-sm-2 control-label required">
								{{form.getFieldLabel('document')}}
							</label>
							<div class="col-sm-5">
								<ng-select
									[formControl]="getFormControl('document')"
									[items]="documents.$promise | async"
									[loading]="!documents.$resolved"
									bindLabel="name"
									[clearable]="true"
									placeholder="Select Document"
								></ng-select>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<ng-container [ngSwitch]="type">
					<ng-container *ngSwitchCase="DocumentType.CSD">
						<ng-container [ngSwitch]="view">
							<ng-container *ngSwitchCase="true">
								<ng-container [ngTemplateOutlet]="viewDescription"></ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="false">
								<div class="row form-group" has-error>
									<label class="col-sm-2 control-label required">
										{{form.getFieldLabel('documentDetails.description')}}
									</label>
									<div class="col-sm-10">
										<textarea
											class="form-control"
											[formControl]="getFormControl('documentDetails.description')"
											rows="2"
											maxlength="2000"
											required
										></textarea>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="DocumentType.GD">
						<ng-container [ngTemplateOutlet]="viewDescription"></ng-container>
					</ng-container>
				</ng-container>
				<ng-template #viewDescription>
					<div class="row" *ngIf="getFormControl('documentDetails.description').value as description">
						<label class="col-sm-2 control-label">
							{{form.getFieldLabel('documentDetails.description')}}
						</label>
						<div class="col-sm-10">
							<span class="control-value">{{description}}</span>
						</div>
					</div>
				</ng-template>
			</div>
		</section>

		<ng-container [ngSwitch]="type">
			<ng-container *ngSwitchCase="DocumentType.CSD">
				<ng-container [ngSwitch]="view">
					<ng-container *ngSwitchCase="true">
						<ng-container [ngTemplateOutlet]="viewTemplate"></ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="false">
						<section>
							<h3>Template</h3>
							<div class="document-template" (fileDrop)=setTemplate($event)>
								<div class="front-face">
									<ng-container *ngIf="getFormControl('documentDetails.templateName').value as fileName; else noFile">
										<div class="flex-row gap">
											<span class="title flex">
												<span class="c-icon c-icon-file text-muted"></span>
												{{fileName}}
											</span>
											<button type="button" class="btn btn-tertiary btn-icon" (click)="setTemplate(null)">
												<span class="glyphicon glyphicon-trash"></span>
											</button>
											<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Replace' }"></ng-container>
										</div>
									</ng-container>
									<ng-template #noFile>
										<div class="flex-row center gap">
											<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Upload File' }"></ng-container>
											<span class="text-muted">or Drag and Drop here</span>
										</div>
									</ng-template>
									<ng-template #fileInput let-buttonText="buttonText">
										<label class="file-input">
											<span class="btn btn-primary">{{buttonText}}</span>
											<input type="file" (input)="setTemplate($event.target.files)" [multiple]="false" />
										</label>
									</ng-template>
								</div>
							</div>
						</section>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase="DocumentType.GD">
				<ng-container [ngTemplateOutlet]="viewTemplate"></ng-container>
			</ng-container>
		</ng-container>
		<ng-template #viewTemplate>
			<section *ngIf="getFormControl('documentDetails.templateName').value as templateName">
				<h3>Template</h3>
				<div class="document-template flex-row gap">
					<span class="c-icon c-icon-file"></span>
					<div class="file-name flex">
						<a [href]="templateDownloadLink" target="_blank">{{templateName}}</a>
					</div>
				</div>
			</section>
		</ng-template>

		<section>
			<h3>Channels</h3>
			<div class="rows-bordered">
				<div class="row-fluid header">
					<div class="col-sm-12">
						<b>Channel</b>
					</div>
				</div>
				<ng-container *ngFor="let channel of channels">
					<div class="row-fluid" *ngIf="channel.id === params.channelId">
						<div class="col-sm-12">
							<label class="checkbox-inline">
								<input
									type="checkbox"
									[checked]="true"
									[disabled]="true"
								>
								{{channel.name}}
							</label>
						</div>
					</div>
				</ng-container>
			</div>
		</section>

		<section *ngIf="!view" class="flex-row">
			<div class="right">
				<button type="submit" class="btn btn-primary">Save</button>
				<button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
			</div>
		</section>
	</form>
</div>
