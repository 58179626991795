<div class="remote-resource" [class.resolved]="list?.$resolved">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{document.name}}</h4>
	</div>
	<div class="modal-body document-history-header">
		<div class="rows-bordered">
			<div class="row-fluid header">
				<span class="col-md-3"><b class="two-liners">Date</b></span>
				<span class="col-md-3"><b class="two-liners">Action</b></span>
				<span class="col-md-3"><b class="two-liners">Action By</b></span>
				<span class="col-md-3"><b class="two-liners">Document</b></span>
			</div>
		</div>
	</div>

	<div class="modal-body document-history-scrollable">
		<div class="rows-bordered">
			<div class="row-fluid" *ngFor="let entry of list">
				<div class="col-md-3">
					{{entry.actionAt | date : 'MM/dd/yyyy HH:mm'}}
				</div>
				<div class="col-md-3">
					<div *ngFor="let action of entry.actions">{{action}}</div>
				</div>
				<div class="col-md-3">{{entry.actionBy}}</div>
				<div class="col-md-3">
					<a target="_blank" *ngIf="entry.filename" [href]="entry.historyLink">{{entry.filename}}</a>
				</div>
			</div>

			<div class="row-fluid" *ngIf="!list?.length && list?.$resolved">
				<span class="col-sm-12 text-muted text-center text-bigger text-weak va-m">No matches found</span>
			</div>
		</div>
	</div>
	<div class="modal-footer" *ngIf="list.$pagesTotal > 1">
		<new-paging class="no-border" [(list)]="list" [(params)]="params$"></new-paging>
	</div>
</div>

