import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TpoServiceInformation } from 'comergence/customers/tpo-services/tpo-service.interface';


declare let apiPath: string;

@Injectable()
export class CcmServiceStatusService {
    private readonly servicesUpdated$: Subject<void> = new ReplaySubject(1);

    constructor(
        private readonly http: RealmHttpClient,
    ) {
    }

    public fetchServiceStatuses(): void {
        this.servicesUpdated$.next();
    }

    public getServiceInformation(organizationId: number | string): Observable<TpoServiceInformation> {
        return this.servicesUpdated$.pipe(
            switchMap(
                () => merge(
                    this.http.request<TpoServiceInformation>(
                        'GET',
                        `${apiPath}/customers/${organizationId}/services`
                    )
                ),
            ),
        );
    }
}
