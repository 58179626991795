<h4>Results</h4>
<div class="rows-striped">
    <div class="row-fluid">
        <div class="col-md-1"></div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-4 control-label text-right">HQ</label>
                        <div class="col-md-8">
                            <span class="control-value">{{dataMatchResultSection.result.hQPercent + '%'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-6 control-label text-right">Individuals</label>
                        <div class="col-md-6">
                            <span class="control-value">{{dataMatchResultSection.result.individualPercent + '%'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row-fluid">
        <div class="col-md-1"></div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-4 control-label text-right">Branches</label>
                        <div class="col-md-8">
                            <span class="control-value">{{dataMatchResultSection.result.branchesPercent + '%'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-6 control-label text-right">Non Matches</label>
                        <div class="col-md-6">
                            <span class="control-value">{{dataMatchResultSection.result.invalidNmlsIdsPercent + '%'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
