<section class="remote-resource" [class.resolved]="owners.$resolved">
	<h3>
		Ownership
		&nbsp;
		<segmented-controls
			*ngIf="hasPermission('OWNERS_PREVIOUS') && !isWizard"
			[items]="filters.previous"
			[value]="params.previous"
			(selected)="setFilter({previous: $event})"></segmented-controls>

		<div *ngIf="hasPermission('MANAGE_OWNERS')" class="pull-right">
			<button type="button" class="btn btn-primary" [disabled]="!accountCompanyInfo.$resolved" (click)="showUpdateOwnership()"><span class="glyphicon glyphicon-plus small-icon"></span> Update Ownership</button>
		</div>
	</h3>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<span [ngClass]="['col-sm-5', getSortClass('fullName')]" (click)="setSort('fullName')"><b>Name</b></span>
			<span [ngClass]="['col-sm-2', getSortClass('phone')]" (click)="setSort('phone')"><b>Phone</b></span>
			<span [ngClass]="['col-sm-3', getSortClass('email')]" (click)="setSort('email')"><b>Email</b></span>
			<span [ngClass]="['col-sm-2', getSortClass('ownershipPercentage')]" (click)="setSort('ownershipPercentage')"><b>Ownership %</b></span>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
		</div>

		<div class="row-fluid" *ngFor="let item of ownersFiltered[''+params.previous]">
			<span class="col-sm-5 loan-margin" *ngIf="item.contactType != 'company'">
				<i *ngIf="item.isMobileUser" class="c-icon c-icon-phone" placement="top" tooltip="Mobile User"></i>
				<span class="label label-not-verified" *ngIf="!item.isSSNVerified && item.ownershipPercentage > 0 && hasPermission('VERIFY_OWNER')" (click)="verifyOwner(verifyOwnerModal, item)">&nbsp;</span>
				<span class="label not-verified" *ngIf="!item.isSSNVerified && item.ownershipPercentage > 0 && !hasPermission('VERIFY_OWNER')">Not Verified</span>
				<span class="label label-verified" *ngIf="item.isSSNVerified">Verified</span>
				<!-- TODO: check contact-list template, it has .lender-individual route, normalize crm routes -->
				<a fixedSref=".:contactId.information" [uiParams]="{contactId: item.contactId}" class="text-bigger">{{item.fullName}}</a><br>
				<span class="text-light">{{item.officerDesignation?.join(', ')}}</span>
			</span>
			<span class="col-sm-5 loan-margin" *ngIf="item.contactType == 'company'">
				<a fixedSref=".company.:contactId" [uiParams]="{contactId: item.tpoCompanyContactId}" class="text-bigger">{{item.companyName}}</a><br>
				<span class="text-light">{{item?.fullAddress}}</span>
			</span>
			<span class="col-sm-2 ">{{item.phone || '&ndash;' | tel}}</span>
			<span [ngClass]="['break-word', 'col-md-3']">
				<a [aDisabled]="!item.email" href="mailto:{{item.email}}">{{item.email || '&ndash;'}}</a>
			</span>
			<span class="col-sm-1 text-center">
				{{item.ownershipPercentage ? (item.ownershipPercentage + '%') : '&ndash;'}}
			</span>
			<span class="col-sm-1 text-right">
				<div *ngIf="item.isPrimaryContact">
					<span class="glyphicon glyphicon-star action" tooltip="Notifications" placement="left"></span>
				</div>
				<contact-role [contact]="item"></contact-role>
			</span>
		</div>

		<div class="row-fluid" *ngIf="owners.length && (noMatches || (params.previous && !ownersFiltered[''+params.previous].length))">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>

		<div class="row-fluid" *ngIf="!owners.length && !noMatches">
			<span class="col-md-12 text-muted text-center text-bigger">No owners</span>
		</div>
	</div>
</section>

<ng-template #verifyOwnerModal>
	<div class="remote-resource" [class.resolved]="selectedOwner.$resolved">
		<div class="modal-header">
			<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		</div>
		<div class="modal-body">
			<p class="well text-center text-bigger" *ngIf="!verificationSent">
				Would you like to resend a verification email to provide credentials for {{selectedOwner.firstName}}&nbsp;{{selectedOwner.lastName}}?
			</p>
			<p class="success text-center" *ngIf="verificationSent">
				<span class="glyphicon glyphicon-ok-sign"></span>
				Verification email has been sent
			<p>
		</div>
		<div class="modal-footer">
			<div class="text-right" *ngIf="!verificationSent">
				<a class="btn btn-primary" (click)="sendNotification();">Yes</a>
				<a class="btn btn-default" (click)="modalRef.hide();">No</a>
			</div>
			<div class="text-right" *ngIf="verificationSent">
				<a class="btn btn-primary" (click)="modalRef.hide();">Ok</a>
			</div>
		</div>
	</div>
</ng-template>
