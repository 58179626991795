<div class="remote-resource" [class.resolved]="resolved">
    <form [formGroup]="affiliateForm" (onValidSubmit)="save()">
        <h2>
            {{companyName}}
            <div class="pull-right">
                <button *ngIf="!editing && userIsInvestor" type="button" class="btn btn-primary" (click)="enableEditing()">
                    <span class="glyphicon glyphicon-pencil"></span>Edit
                </button>
                <div class="pull-right" *ngIf="editing && !new">
                    <button class="btn btn-primary" type="submit">Save</button>
                    <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </h2>

        <br>

        <form-errors [form]="affiliateForm"></form-errors>

        <div class="rows-striped">
            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('businessType')}}</label>
                    <div>
                        <div class="col-sm-6 form-group" has-error [isEditable]="editing">
                            <span view class="control-value">{{affiliate?.businessType || '&ndash;'}}</span>
                            <ng-select edit
                                       name="businessType"
                                       formControlName="businessType"
                                       maxlength="100"
                                       [items]="businessTypes"
                                       placeholder="Select Business Type"
                                       bindLabel="name"
                                       bindValue="name"
                                       tabindex="1" >
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('entityName')}}</label>
                    <div class="col-sm-6 form-group" has-error>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{affiliate?.entityName || '&ndash;'}}</span>
                            <input edit type="text" name="entityName" formControlName="entityName" class="form-control" maxlength="100" tabindex="2" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('address1')}}</label>
                    <div class="col-sm-6 form-group" has-error>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{affiliate?.address1 || '&ndash;'}}</span>
                            <input edit type="text" name="address1" formControlName="address1" class="form-control" maxlength="100" tabindex="3" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('startDate')}}</label>
                    <div class="col-md-6 form-group" [isEditable]="editing" has-error>
                        <span view class="control-value">{{affiliate?.startDate | date: labelDateFormat || '&ndash;'}}</span>
                        <input edit
                               class="form-control"
                               formControlName="startDate"
                               bsDatepicker
                               tabindex="4"
                               [bsConfig] ="{dateInputFormat: modelDateFormat}"
                               placeholder="mm/dd/yyyy"
                        />
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right">{{affiliateForm.getFieldLabel('address2')}}</label>
                    <div class="col-sm-6">
                        <div [isEditable]="editing">
                            <span view class="control-value">{{affiliate?.address2 || '&ndash;'}}</span>
                            <input edit type="text" name="address2" formControlName="address2" class="form-control" maxlength="100" tabindex="5" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right">{{affiliateForm.getFieldLabel('endDate')}}</label>
                    <div class="col-md-6 form-group" [isEditable]="editing" has-error>
                        <span view class="control-value">{{affiliate?.endDate | date: labelDateFormat || '&ndash;'}}</span>
                        <input edit
                               class="form-control"
                               formControlName="endDate"
                               bsDatepicker
                               tabindex="6"
                               [bsConfig] ="{dateInputFormat: modelDateFormat}"
                               placeholder="mm/dd/yyyy"
                        />
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('city')}}</label>
                    <div class="col-sm-6 form-group" has-error>
                        <div [isEditable]="editing" >
                            <span view class="control-value">{{affiliate?.city || '&ndash;'}}</span>
                            <input edit type="text" name="city" formControlName="city" class="form-control" maxlength="100" tabindex="7" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('stateOfIncorporate')}}</label>
                    <div class="col-md-6 form-group" [isEditable]="editing" has-error>
                        <div view class="control-value">{{affiliate?.stateOfIncorporate || '&ndash;'}}</div>
                        <ng-select
                            edit
                            formControlName="stateOfIncorporate"
                            [items]="states | async"
                            [loading]="loadingValue.states"
                            bindValue="name"
                            bindLabel="name"
                            placeholder="Select state"
                            name="stateOfIncorporate"
                            tabindex="8"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('state')}}</label>
                    <div class="col-md-6 form-group" [isEditable]="editing" has-error>
                        <div view class="control-value">{{affiliate?.state || '&ndash;'}}</div>
                        <ng-select
                            edit
                            formControlName="state"
                            [items]="states | async"
                            [loading]="loadingValue.states"
                            bindValue="name"
                            bindLabel="name"
                            placeholder="Select state"
                            name="state"
                            tabindex="9"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('operatingStates')}}</label>
                    <div class="col-md-6 form-group" [isEditable]="editing" has-error>
                        <div view class="control-value">{{affiliate?.operatingStates || '&ndash;'}}</div>
                        <ng-select
                            edit
                            formControlName="operatingStates"
                            [items]="states | async"
                            [loading]="loadingValue.states"
                            bindValue="name"
                            bindLabel="name"
                            placeholder="Select state"
                            name="operatingStates"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            tabindex="10"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-md-6">
                    <label class="col-sm-5 control-label text-right" [class.required]="editing">{{affiliateForm.getFieldLabel('zip')}}</label>
                    <div class="col-sm-6 form-group" has-error>
                        <div [isEditable]="editing">
                            <span view class="control-value">{{affiliate?.zip || '&ndash;'}}</span>
                            <input edit type="text" name="zip" formControlName="zip" class="form-control" maxlength="50" tabindex="11" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
        </div>

        <h3>
            Ownership
            <div *ngIf="editing" class="pull-right">
                <button type="button" class="btn btn-primary" (click)="addCompanyOwner()">
                    <span class="glyphicon glyphicon-plus small-icon"></span> Add Company
                </button>
                <button type="button" class="btn btn-primary" (click)="addIndividualOwner()">
                    <span class="glyphicon glyphicon-plus small-icon"></span> Add Individual
                </button>
            </div>
        </h3>
        <div class="alert alert-warning text-center" *ngIf="editing && (!affiliateOwnerships.controls || affiliateOwnerships.controls.length == 0)" >Please add at least one owner</div>
        <div class="rows-bordered" formArrayName="affiliateOwnerships" *ngFor="let control of affiliateOwnerships.controls; let i = index">
            <div class="row-fluid">
                <label class="col-sm-2 control-label text-right" [class.required]="editing">
                    <span *ngIf="control.get('ownershipTypeID').value == 'CM'">Company </span>
                    Name
                </label>
                <div class="col-md-3 form-group" [isEditable]="editing" has-error>
                    <span view>{{control.get('ownershipFirstName').value || '&ndash;'}}</span>
                    <input edit type="text" [formControl]="control.get('ownershipFirstName')" class="form-control" maxlength="100"/>
                </div>
                <label class="col-sm-2 control-label text-right" [class.required]="editing">Ownership %</label>
                <div class="col-sm-3 form-group" has-error>
                    <div [isEditable]="editing">
                        <span view>{{control.get('ownershipPercentage').value || '&ndash;'}}</span>
                        <input edit type="text" [formControl]="control.get('ownershipPercentage')" class="form-control" validFloat="1.0-1" maxlength="4"/>
                    </div>
                </div>
                <div *ngIf="editing" class="col-sm-2"><a (click)="removeOwner(i)">Remove</a></div>
            </div>
        </div>

        <br>

        <div class="pull-right" *ngIf="editing && new">
            <button class="btn btn-primary" type="submit">Save</button>
            <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
        </div>
        <div *ngIf="editing">
            <span class="control-label required"></span> - Required field
        </div>
    </form>

</div>
