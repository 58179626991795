import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ChannelsService, ChannelSummary } from 'lender/clients/$id/channels/channels.service';
import { combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { Channel } from 'lender/documents/documents.interface';
import { UserService } from 'angularjs-providers/user.provider';


@Component({
    templateUrl: './channels-list.component.html',
    host: {
        '[class]': '"remote-resource"',
    },
})
export class ChannelsListComponent implements OnInit {
    @Input() lenderInfo: AccountInformation;
    @HostBinding('class.resolved') resolved = false;
    channels: ChannelSummary[] = [];
    availableChannels!: Channel[];
    canInvite = false;

    constructor(
        protected readonly channelsService: ChannelsService,
        { profile }: UserService,
    ) {
        this.canInvite = profile.can('INVITE_TO_APPLY');
    }

    ngOnInit() {
        this.loadChannels();
    }

    protected async loadChannels(): Promise<void> {
        const { isRegistered, identifier  } = this.lenderInfo;

        [this.availableChannels, this.channels] = await combineLatest([
            isRegistered ? this.channelsService.getAvailableChannels(identifier.tpoId) : of([]),
            this.channelsService.getList(identifier).pipe(
                map(list => list.sort(({ channel: { name: a } }, { channel: { name: b } }) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return 0;
                })),
            ),
        ]).pipe(
            tap(() => this.resolved = true),
        ).toPromise();
    }
}
