import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { SharedDocumentsModule } from 'shared/documents';

import { TpoPaymentsComponent } from './payments.component';
import { TpoPaymentsService } from './payments.service';


const components = [
	TpoPaymentsComponent,
];

@NgModule({
	imports: [	
		BrowserModule,
		CommonModule,
		FormsModule,
        ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		SharedDocumentsModule,
		PipesModule,
	],
	declarations: [	
		...components,
	],
	providers: [
		TpoPaymentsService,
	],
})
export class TpoPaymentsModule {}
