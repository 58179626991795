enum allowedNetworks {
	// FACEBOOK_PROFILE = 'FBI', // Facebook Individual
	FACEBOOK_PAGE = 'FB',
	LINKED = 'LI',
	TWITTER = 'TW',
	GOOGLE_MY_BUSINESS = 'GMB',
	INSTAGRAM = 'IG',
}

const name = 'User name';
interface DefaultNetwork { networkCode: allowedNetworks, name: string }
const DefaultNetworks: Array<DefaultNetwork> = [
	{ networkCode: allowedNetworks.FACEBOOK_PAGE, name },
	{ networkCode: allowedNetworks.LINKED, name },
	{ networkCode: allowedNetworks.TWITTER, name },
	{ networkCode: allowedNetworks.GOOGLE_MY_BUSINESS, name },
	{ networkCode: allowedNetworks.INSTAGRAM, name: 'Account name' },
];

const DefaultNetworkCodes = Object.values(allowedNetworks);

export { allowedNetworks, DefaultNetworks, DefaultNetwork, DefaultNetworkCodes, name };
