import { Injector, Injectable } from '@angular/core';
declare let apiPath: string;

@Injectable()
export class ApiSettingsResourceService {
	resource: any;

	constructor(
		public injector: Injector,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const path = `${apiPath}/organizations/:organizationId/api-client-info`;

		this.resource = $resource(path, {organizationId: '@organizationId'}, {
			update: {
				method: 'put',
			},
		});
	}

	get = (...args) => this.resource.get(...args);
	update = (...args) => this.resource.update(...args);

}
