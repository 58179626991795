import { Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { NgResourceObject } from 'commons/interfaces';

import { TpoLibraryDocument } from 'tpo/documents/documents.interface';
import { TpoDocumentsService } from 'tpo/documents/documents.service';

@Component({
	templateUrl: './document.component.html',
})
export class TpoLibraryDocumentComponent implements OnInit {
	title: string;

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		private documentsService: TpoDocumentsService,
	) {}

	async ngOnInit(): Promise<void> {
		const { libraryId } = this.uiRouterGlobals.params;
		if (libraryId) {
			const document: NgResourceObject<TpoLibraryDocument> = await this.documentsService.getLibraryDocument({ libraryId }).$promise;
			if (document) {
				const { name } = document;
				this.title = name;
			}
		}
	}
}
