import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { MaxLengthValidator, UrlValidator } from 'commons/validators';

import { SharedSocialMediaService } from 'shared/social-media/social-media.service';

@Component({
	templateUrl: './add-modal.component.html',
})
export class AddRSSModalComponent {
	params: any;
	link: any;
	onSave: (params?) => any;
	resolving: boolean = false;

	addForm = new RealmFormGroup({
		rssLink: new RealmFormControl(
			'rssLink',
			{ label: 'RSS link' },
			[
				Validators.required,
				UrlValidator(),
				MaxLengthValidator(255),
			],
		),
	});

	constructor(
		public modalRef: BsModalRef,
		private sharedSocialMediaService: SharedSocialMediaService,
	) {
		this.addForm.reset();
	}

	save() {
		if (this.resolving) {
			return;
		}

		this.resolving = true;
		this.sharedSocialMediaService.mediaLinks.addRss(
			{
				...this.params,
				mediaLinkId: this.link.data.linkId,
			},
			{
				...this.addForm.value,
			},
		).$promise
			.then(() => {
				this.onSave();
				this.modalRef.hide();
			})
			.catch(({ data }) => {
				this.addForm.setServerError(data);
			})
			.finally(() => {
				this.resolving = false;
			});
	}
}
