import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { LenderUsersResourceService } from '../users-resource.service';

import { Modes } from './modes';
import { LenderUserStatusColors } from './user-status-colors';

@Component({
	templateUrl: './user.component.html',
})
export class LenderUserComponent {
	userId: number;
	modeName: string;
	isNew: boolean = false;
	editable: boolean = false;
	viewMode: any = Modes.view;
	user: any = {};

	LenderUserStatusColors = LenderUserStatusColors;

	constructor(
		public UserService: UserService,
		public stateService: StateService,
		public LenderUsersService: LenderUsersResourceService,
	) {}

	ngOnInit() {
		const { id, actionType } = this.stateService.params;

		this.isNew = !id;
		this.modeName = actionType;
		this.userId = id;

		if (!Modes[actionType] || (this.isNew && (actionType === 'audit-trail' || actionType === 'login-history'))) {
			this.toOriginalState();
			return;
		}

		this.viewMode = Modes[actionType];
		this.editable = this.viewMode.editMode;

		if (this.isNew) {
            this.user = {};
            Object.defineProperty(this.user, '$resolved', { enumerable: false, writable: true, value: true });
            Object.defineProperty(this.user, '$promise', { enumerable: false, value: Promise.resolve(this.user) });

			if (this.UserService.profile.isLender) {
				this.user.channelsAssignedIds = [];
			}
		} else {
            this.user = this.LenderUsersService.user.get({ userId: id });
		}
	}

	getUserName() {
		if (this.isNew) {
			return this.viewMode.title;
		}

		return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
	}

	toOriginalState() {
		this.stateService.go('users-management.users');
	}

    updateUser(user): void {
        this.user = user;
    }
}
