import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class CampaignsResourceService {

	campaigns: any;
	availableCampaigns: any;
	campaign: any;
	campaignTemplates: any;
	availableTemplates: any;
	campaignMediaPosts: any;
	campaignPrevalidate: any;
	campaignParticipants: any;
	campaignParticipantLinks: any;
	enroll: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {

		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');

		const campaignsConfig = {
			url: `${apiPath}/tpos/:tpoId/social-media-campaigns`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const campaignConfig = {
			url: `${campaignsConfig.url}/:campaignId`,
			params: {
				...campaignsConfig.params,
				campaignId: '@campaignId',
			},
		};
		const campaignTemplatesConfig = {
			url: `${campaignConfig.url}/social-media-post-templates`,
			params: {
				...campaignConfig.params,
			},
		};
		const campaignTemplateConfig = {
			url: `${campaignTemplatesConfig.url}/:templateId`,
			params: {
				...campaignTemplatesConfig.params,
				templateId: '@templateId',
			},
		};
		const campaignMediaPostsConfig = {
			url: `${apiPath}/tpos/:tpoId/social-media-campaigns/:campaignId/social-media-posts/`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const campaignPrevalidateConfig = {
			url: `${apiPath}/tpos/:tpoId/social-media-campaigns/:campaignId/prevalidate`,
			params: {
				tpoId: user.profile.organization.id,
				campaignId: '@campaignId',
			},
		};
		const campaignParticipantsConfig = {
			url: `${campaignConfig.url}/participants`,
			params: {
				...campaignConfig.params,
			},
		};
		const campaignParticipantLinksConfig = {
			url: `${campaignParticipantsConfig.url}/:idType/:id`,
			params: {
				...campaignParticipantsConfig.params,
			},
		};

		this.campaigns = PagedResource(
			campaignsConfig.url,
			campaignsConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.availableCampaigns = $resource(
			`${campaignsConfig.url}/all-non-ended`,
			campaignsConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.campaign = $resource(
			campaignConfig.url,
			campaignConfig.params,
			{
				create: {
					url: campaignsConfig.url,
					method: 'POST',
				},
				get: {
					method: 'GET',
				},
				update: {
					method: 'PUT',
				},
			},
		);

		this.campaignTemplates = PagedResource(
			campaignTemplatesConfig.url,
			campaignTemplatesConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				add: {
					method: 'POST',
				},
				removalRequest: {
					url: campaignTemplateConfig.url,
					params: campaignTemplateConfig.params,
					method: 'GET',
				},
				remove: {
					url: campaignTemplateConfig.url,
					params: campaignTemplateConfig.params,
					method: 'DELETE',
				},
			},
		);

		this.availableTemplates = $resource(
			`${campaignConfig.url}/social-media-post-templates-for-adding`,
			campaignConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.campaignMediaPosts = PagedResource(
			campaignMediaPostsConfig.url,
			campaignMediaPostsConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.campaignPrevalidate = $resource(
			campaignPrevalidateConfig.url,
			campaignPrevalidateConfig.params,
			{
				update: {
					method: 'PUT',
				},
			},
		);

		this.campaignParticipants = PagedResource(
			campaignParticipantsConfig.url,
			campaignParticipantsConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.campaignParticipantLinks = $resource(
			campaignParticipantLinksConfig.url,
			campaignParticipantLinksConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.enroll = PagedResource(
			`${apiPath}/tpos/:tpoId/social-media-campaigns/:id/enrollments`, {
				tpoId: user.profile.organization.id,
			}, {
				get: {
					method: 'GET',
					isArray: true,
				},
				add: {
					method: 'PUT',
				},
			},
		);
	}
}
