import { Component, forwardRef, Input } from '@angular/core';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData, RealmParams } from 'commons/services/http';
import { FederalRegistration, FederalRegistrationsResourceService } from './federal-registrations-resource.service';


interface AuthorizedValueOption {
    label: string;
    value: string;
}

@Component({
    selector: 'federal-registrations-list',
    templateUrl: './federal-registrations-list.component.html',
    viewProviders: [
        // TODO: Used for SearchListComponent, change it's definition once old ListComponent will be removed
        { provide: ListComponent, useExisting: forwardRef(() => FederalRegistrationsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => FederalRegistrationsListComponent) },
    ],
})
export class FederalRegistrationsListComponent extends NewPagedListComponent<FederalRegistration> {
    static listName = 'federalRegistrationsListComponent';

    @Input('lenderInfo') private accountInformation: AccountInformation;

    authorizedValues: AuthorizedValueOption[] = [
        {
            label: 'All',
            value: '',
        },
        {
            label: 'Authorized',
            value: 'true',
        },
        {
            label: 'Not Authorized',
            value: 'false',
        },
    ];

    constructor(
        router: UIRouter,
        private readonly federalRegistrationsResourceService: FederalRegistrationsResourceService,
    ) {
        super(router);

        Object.assign(this.defaultParams, { size: 25, authorized: '' });
    }

    // Override
    protected loadList(params: ListParams): Observable<PagedData<FederalRegistration[]>> {
        return this.federalRegistrationsResourceService.getList(this.accountInformation, params);
    }
}
