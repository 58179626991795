import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

import { WaiveData } from 'commons/services/financial-documents.interface';

@Component({
	templateUrl: './waive-financial-history-modal.component.html',
})
export class WaiveFinancialHistoryModalComponent {
	snapshotData: WaiveData;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			resolving: false,
			snapshotData: {
				comment: null,
			},
		});
	}
}
