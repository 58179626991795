<div class="social-compliance-publisher-finding supporting-document-modal remote-resource" [class.resolved]="document.$resolved">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{ isNew ? 'Add Document' : 'Edit Document' }}</h4>
	</div>
	<form [formGroup]="documentForm" (onValidSubmit)="submitDocumentDetailsForm()">
		<div class="modal-body content">
			<form-errors [form]="documentForm"></form-errors>

			<ng-container *ngIf="isNew">
				<div class="document-upload form-group" [class.error]="document.$error" (fileDrop)=setDocument($event)>
					<div class="front-face">
						<ng-container *ngIf="documentForm.get('fileName').value as fileName; else noFile">
							<div class="flex-row gap">
								<span class="title flex">
									<span class="c-icon c-icon-file text-muted"></span>
									{{fileName}}
								</span>
								<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Replace' }"></ng-container>
							</div>
						</ng-container>
						<ng-template #noFile>
							<div class="flex-row center gap">
								<ng-container *ngTemplateOutlet="fileInput; context: { buttonText: 'Upload File' }"></ng-container>
								<span class="text-muted">or Drag and Drop here</span>
							</div>
						</ng-template>
						<ng-template #fileInput let-buttonText="buttonText">
							<label class="file-input">
								<span class="btn btn-primary">{{buttonText}}</span>
								<input type="file" (input)="setDocument($event.target.files)" [multiple]="false" />
							</label>
						</ng-template>
					</div>

					<div class="back-face flex-row center gap">
						<span>{{document.$error}}</span>
						<button type="button" class="btn btn-primary" (click)="hideError()">OK</button>
					</div>
				</div>
			</ng-container>

			<div class="form-group">
				<label for="name" class="control-label text-smaller required">{{documentForm.getFieldLabel('name')}}</label>
				<input type="text" class="form-control" id="name" formControlName="name" maxlength="200">
			</div>

			<div class="form-group">
				<label for="description" class="text-smaller text-normal text-muted">{{documentForm.getFieldLabel('description')}}</label>
				<textarea
					class="form-control resize-vertical"
					id="description"
					formControlName="description"
					rows="3"
					maxlength="1000"
				></textarea>
			</div>

		</div>
		<div class="modal-footer text-right">
			<button type="submit" class="btn btn-primary">Save</button>
			<a class="btn btn-secondary" (click)="modalRef.hide()">Cancel</a>
		</div>
	</form>
</div>
