<div class="remove-folder-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Remove Folder</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<form-errors [form]="removeFolderForm"></form-errors>
		<form [formGroup]="removeFolderForm" id="removeFolderForm" (onValidSubmit)="submit()">
			<div class="form-group" [class.has-error]="hasError('folder')">
				<span>The folder has to be empty before you can remove it.</span>
				<label for="folder" class="text-default control-label mb10">
					Move templates to another folder.
				</label>
				<ng-select
				   id="folder" class="first-item-border"
				   formControlName="folder"
				   [items]="folders?.$promise | async"
				   [loading]="!folders?.$resolved"
				   bindLabel="name"
				   [clearable]="false"
				   tabIndex="1"
				   placeholder="Select Folder"
				   required>
				</ng-select>
			</div>
		</form>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button type="submit" form="removeFolderForm" class="btn btn-primary">Remove folder</button>
			<button (click)="onCancel()" type="button" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>
