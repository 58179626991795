import { map, pick, reduce, startsWith } from 'lodash';
import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ReviewSourceDecodeIdInterface, ReviewSourceResponseInterface, ReviewSourceInterface } from '../';

declare let apiPath: string;

@Injectable()
export class ReviewWidgetResourceService {
	public widgets: any;
	public widget: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const widgetsPath = `${apiPath}/tpos/:tpoId/review-widget`;
		const widgetPath = `${widgetsPath}/:widgetId`;
		const defaultParams = { tpoId: User.profile.organization.id };

		// https://r5-dev.ccmdev.com/swagger-ui.html#/review45widget45resource
		this.widgets = PagedResource(widgetsPath, defaultParams, {
				sources: {
					method: 'GET',
					url: `${widgetsPath}/sources`,
					isArray: true,
				},
				accountTypes: {
					method: 'GET',
					url: `${widgetsPath}/accountTypes`,
					isArray: true,
					transformResponse: this.transformAccountTypes,
				},
			},
		);

		this.widget = PagedResource(widgetPath, {
				...defaultParams,
				...{ widgetId: '@widgetId' },
			}, {
				sources: {
					method: 'GET',
					url: `${widgetPath}/sources`,
					params: { widgetId: '@widgetId' },
					isArray: true,
				},
				sourcesAll: {
					method: 'GET',
					url: `${widgetPath}/sources/all`,
					params: { widgetId: '@widgetId' },
					isArray: true,
				},
				update: {
					method: 'PUT',
				},
				accountTypes: {
					method: 'GET',
					url: `${widgetsPath}/:widgetId/accountTypes`,
					isArray: true,
					transformResponse: this.transformAccountTypes,
				},
			},
		);
	}

	transformAccountTypes = (response): ReviewSourceInterface[] => {
		const data = JSON.parse(response) || [];
		return map(data, (entry: ReviewSourceResponseInterface) => {
			const commonData = pick(entry, 'displayName', 'type');

			return {
				...commonData,
				id: this.encodeReviewSourceId(entry),
			};
		});
	}

	encodeReviewSourceId = (entry: ReviewSourceResponseInterface) => {
		return entry.type === 'CUSTOMER' ? `c_${entry.customerId}` : `p_${entry.type}`;
	}

	decodeReviewSourceId = (id: string): ReviewSourceDecodeIdInterface => {
		const result: ReviewSourceDecodeIdInterface = {
			customer: null,
			provider: null,
		};

		if (startsWith(id, 'p_')) {
			result.provider = id.slice(2);
		}

		if (startsWith(id, 'c_')) {
			result.customer = parseInt(id.slice(2), 10);
		}

		return result;
	}

	getParamsFromSources(queryParams) {
		const { accountTypes, ...rest } = queryParams;

		if (!accountTypes || !accountTypes.length) {
			return rest;
		}

		const sourcesNormalized = reduce(accountTypes, (acc, id: string) => {
			const data = this.decodeReviewSourceId(id);

			if (data.customer) {
				acc.customer.push(data.customer);
			}

			if (data.provider) {
				acc.provider.push(data.provider);
			}

			return acc;
		}, { provider: [], customer: [] });

		return {
			...rest,
			...sourcesNormalized,
		};
	}
}
