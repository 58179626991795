import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';

import { ContactService } from 'shared/contact/contact.service';
import { FindingsItem, FindingsQueryParams } from 'shared/findings/$findingId/finding.interface';


export interface NewContactsFindingsListParams extends ListParams, FindingsQueryParams {
    tpoId: number;
    contactId: number;
}

@Component({
	templateUrl: './new-findings-list.component.html',
	viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => NewContactFindingsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => NewContactFindingsListComponent) },
	],
})
export class NewContactFindingsListComponent extends NewPagedListComponent<FindingsItem> implements OnInit {
	listName = 'newContactComplianceFindings';

	userProfile: UserProfile;

	constructor(
        uiRouter: UIRouter,
		public stateService: StateService,
		public userService: UserService,
		public contactService: ContactService,
	) {
		super(uiRouter);

		this.userProfile = userService.profile;
	}

	ngOnInit(): void {
		this.configureList();

		super.ngOnInit();
	}

    loadList(params: NewContactsFindingsListParams): Observable<PagedData<FindingsItem[]>> {
		return this.contactService.getFindings(params.tpoId, params.contactId, params);
	}

	private configureList(): void {
		const { id, contactId } = this.stateService.params;

		Object.assign(this.defaultParams, {
		// Object.assign(this.defaultFilters, {
			size: 25,
			tpoId: this.userProfile.isTpo ? this.userProfile.organization.id : id,
			contactId,
		});
	}
}
