<div class="contacts">
	<h2>
		Contacts
	</h2>

	<notification></notification>

	<lender-owners-list *ngIf="isOwnershipSection"></lender-owners-list>

	<lender-contacts-list [relatedAccount]="company"></lender-contacts-list>
</div>

