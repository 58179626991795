import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { DueDiligenceListComponent } from 'comergence/due-diligence/due-diligence-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {
    DueDiligenceItemComponent,
} from 'comergence/due-diligence/due-diligence-item/due-diligence-item.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        PaginationModule,
        NgSelectModule,
    ],
    declarations: [
        DueDiligenceListComponent,
        DueDiligenceItemComponent,
    ],
    providers: [
    ],
})
export class DueDiligenceListModule {
}
