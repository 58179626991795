<form *ngIf="finding"
	[formGroup]="editFindingForm"
	(onValidSubmit)="prepareSave()"
>
	<div *ngIf="isCCM && !editing" class="pull-right">
		<input class="btn btn-primary" type="button" value="Edit" (click)="enterEdit()">
	</div>

	<div *ngIf="editing">
		<form-errors [form]="editFindingForm"></form-errors>
		<div class="row-fluid">
			<span class="col-md-7 form-group va-m">
				<label class="text-normal radio-inline">
					<input type="radio" formControlName="isVisible" [value]="true"> Show
				</label>
				<label class="text-normal radio-inline">
					<input type="radio" formControlName="isVisible" [value]="false"> Hide
				</label>
			</span>

			<span class="col-md-2 va-m">
				<label *ngIf="editFindingForm.controls.isVisible.value" class="checkbox-inline">
					<input type="checkbox" formControlName="notify" tabindex=1 (change)="notifyChange()">
					Notify Customer
				</label>
			</span>
			<span class="col-md-3">
				<div class="pull-right">
					<input class="btn btn-primary" type="submit" value="Save">
					<input class="btn btn-default" type="button" value="Cancel" (click)="cancelEdit()">
				</div>
			</span>
		</div>
		<br>
		<div *ngIf="editFindingForm.controls.isVisible.value && !editFindingForm.controls.notify.value"
			class="alert alert-warning form-inline"
		>
			WARNING! Unchecking this box will prevent a lender from receiving an alert. To confirm, type in the statement exactly as shown,
			"Do Not Notify" to save and proceed.
			<br/><br/>
			<div class="form-group row" has-error>
				<label class="control-label required">&nbsp;&nbsp;&nbsp;Statement</label> &nbsp;&nbsp;
				<input class="form-control" formControlName="statement">
			</div>
		</div>
	</div>

	<div *ngIf="finding.isManual" class="finding-box">
		<h3>Summary</h3>

		<div *ngIf="!editing" class="preformatted">{{ finding.summary }}</div>
		<div *ngIf="editing" class="form-group row">
			<label class="col-sm-2 control-label text-right required">Summary</label>
			<div class="col-sm-10">
				<textarea formControlName="summary" class="form-control resize-vertical" rows="3" required maxlength="400"></textarea>
			</div>
		</div>
	</div>

	<div *ngIf="detailRows && (detailRows.length > 0)" class="finding-box">
		<scorecard-finding-details [detailRows]="detailRows">
		</scorecard-finding-details>
	</div>

	<div class="finding-box">
		<scorecard-finding-details-supporting-documents
			[tpoId]="tpoId"
			[editing]="editing"
			[finding]="finding"
			[newFiles]="editFindingForm.controls.files.value"
			(oldFileRemoved)="removeOldFile($event)"
			(newFileAdded)="addNewFile($event)"
			(newFileRemoved)="removeNewFile($event)"
		>
		</scorecard-finding-details-supporting-documents>
	</div>
</form>
