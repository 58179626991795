import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { TpoContactsService } from './contacts.service';

import { TpoContactsListComponent } from './list';
import { TpoContactMenuComponent } from './details/tpo-contact-menu.component';
import { TpoContactDetailsComponent } from './details/tpo-contact-details.component';
import { TpoContactGeneralInformationComponent } from './details/general-information/general-information.component';
import { TpoMobileRegistrationComponent } from 'tpo/contacts/tpo-mobile-registration/tpo-mobile-registration.component';
import { TpoMobileRegistrationService } from 'tpo/contacts/tpo-mobile-registration/tpo-mobile-registration.service';
import { CompanyResourceService } from 'tpo/people/contacts/company-details/company-details-resource.service';
import { NewContactsModule } from 'shared/new-contacts';
import { ContactsImportUploadComponent } from 'tpo/people/contacts/import/upload.component';
import { ContactsImportService } from 'tpo/people/contacts/import/import.service';
import { ContactImportStepsComponent } from 'tpo/people/contacts/import/import-steps-component';
import { ContactImportContainerComponent } from 'tpo/people/contacts/import/import.container';
import { ContactsImportMappingComponent } from 'tpo/people/contacts/import/mapping.component';
import { ContactImportsListComponent } from 'tpo/people/contacts/import/list/imports-list.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        NewContactsModule,
    ],
    providers: [
        TpoContactsService,
        TpoMobileRegistrationService,
        CompanyResourceService,
        ContactsImportService,
    ],
    declarations: [
        TpoContactsListComponent,
        TpoContactDetailsComponent,
        TpoContactGeneralInformationComponent,
        TpoContactMenuComponent,
        TpoMobileRegistrationComponent,
        ContactImportContainerComponent,
        ContactImportStepsComponent,
        ContactsImportUploadComponent,
        ContactsImportMappingComponent,
        ContactImportsListComponent,
    ],
    exports: [
        TpoContactsListComponent,
    ],
})
export class TpoContactsModule {}
