<form class="remote-resource" [ngClass]="{resolved: resolved, curtain: curtain}"
	  [formGroup]="relatedProspectForm" (onValidSubmit)="save()">
	<h3>Related Prospect
		<span class="activity-buttons">
			<button *ngIf="!isNew && prospect?.id && !editing" (click)="remove()"
					type="button" class="btn btn-secondary btn-icon">
				<span class="glyphicon glyphicon-trash"></span>
			</button>
			<button *ngIf="!editing && !isNew && prospect?.id" (click)="edit()"
					class="btn btn-primary">
				<span class="glyphicon glyphicon-pencil text-smaller"></span> Edit
			</button>

			<span *ngIf="editing && !isNew">
				<button type="submit" class="btn btn-primary" tabindex="20">Save</button>
				<button class="btn btn-default" (click)="cancelEdit()" tabindex="20">Cancel</button>
			</span>

			<button *ngIf="!editing && !prospect?.id" (click)="edit()"
					class="btn btn-primary">
				<span class="glyphicon glyphicon-plus small-icon"></span> Add
			</button>
		</span>
	</h3>
	
	<form-errors *ngIf="editing" [form]="relatedProspectForm"></form-errors>

	<div class="rows-striped">
		<div class="row-fluid">
			<div class="col-md-12">
				<div class="form-group row">
					<label class="coldp-sm-3 control-label">Prospect</label>
					<div [ngClass]="editing ? 'coldp-sm-14' : 'coldp-sm-18'">
						<div class="has-feedback" *ngIf="editing">
							<ng-select
									[items]="relatedProspectHints?.$promise | async"
									(change)="onSelect($event)"
									[loading]="relatedLoading"
									[typeahead]="relatedProspectInput"
									formControlName="related"
									placeholder="Search by Company Name, NMLS ID">

								<ng-template ng-label-tmp let-item="item">
									<i class="text-light glyphicon glyphicon-search"></i>&nbsp;
									<span class="text">{{item.companyName}}
										<span *ngIf="item.nmlsId" class="text-light small">, NMLS ID: {{item.nmlsId}}, </span>{{item.channelName}}
									</span>
								</ng-template>

								<ng-template ng-option-tmp let-item="item">
									{{item.companyName}}<br>
									<span *ngIf="item.nmlsId" class="text-light text-smaller">NMLS ID: {{item.nmlsId}}, </span>{{item.channelName}}
								</ng-template>

								<ng-template ng-typetosearch-tmp>
									<div class="ng-option disabled">
										Please enter at least 3 characters to start search
									</div>
								</ng-template>

								<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
									<div class="ng-option disabled" *ngIf="searchTerm.length > 2">
										No prospect found for "{{searchTerm}}"
									</div>
									<div class="ng-option disabled" *ngIf="searchTerm.length <= 2">
										Please enter at least 3 characters to start search
									</div>
								</ng-template>

								<ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
									<div class="ng-option disabled">
										Searching for "{{searchTerm}}"
									</div>
								</ng-template>

							</ng-select>
						</div>
						<div class="control-value" *ngIf="!editing && prospect">
							<a uiSref="crm.prospects.:id" [uiParams]="{id: prospect.id}" target="_blank">
								{{prospect.locationCompanyName}}
							</a>
							<span>
								<small>, NMLS ID: {{prospect.locationNmlsId}}</small>, {{prospect.channelName}}
							</span>
						</div>

						<span *ngIf="!prospect && !editing" class="control-value">&ndash;</span>
					</div>
					<div class="coldp-sm-3"></div>
				</div>
			</div>
		</div>
	</div>
</form>
