import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { NgResourceArray } from 'commons/interfaces';
import { linkTemplate } from 'shared/supporting-documents/helpers';

declare let apiPath: string;

@Injectable()
export class InvestorLibraryDocumentsService {
	private libraryDocuments: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {

		const $resource = injector.get('$injector').get('$resource');

		const libraryDocumentsResourceConfig = {
			url: `${apiPath}/v2/library-document/lenders/:lenderId`,
			params: {
				lenderId: user.profile.organization.id,
			},
		};

		this.libraryDocuments = $resource(
			libraryDocumentsResourceConfig.url,
			libraryDocumentsResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			});
	}

	getLibraryDocuments = (...args): NgResourceArray<any> => this.libraryDocuments.get(...args);

	/**
	 * @param {Object} params
	 * @param {number} [params.libraryId]
	 */
	getDocumentTemplateLink(params): string {
		return linkTemplate(
			`${apiPath}/v2/library-document/:libraryId/template`,
			{
				...params,
			},
		);
	}
}
