import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { TriggerHistory } from '../../triggers.service';

@Component({
	templateUrl: './trigger-history-modal.component.html',
})
export class TriggerHistoryModalComponent {
	title = 'Trigger Update';
	confirmText = 'Close';
	history: TriggerHistory;
	onConfirm: (params?) => any;
	resolving = false;

	constructor(public modalRef: BsModalRef) {}
}
