import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import { PostsTypeListComponent } from './posts-type-list.component';

@NgModule({
	imports: [
		CommonModule,
		DirectivesModule,
		ComponentsModule,
		PipesModule,
	],
	declarations: [
		PostsTypeListComponent,
	],
	exports: [
		PostsTypeListComponent,
	],
})
export class PostsTypeListModule {}
