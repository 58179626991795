<div
	class="sidenav-container"
	[class.narrow]="narrow"
	[class.visible]="visible"
	[class.collapsed]="!expanded"
>
	<div class="sidenav">
        <div class="sidenav-header-container">
            <div class="sidenav-header"></div>

            <a class="sidenav-switcher visible-switcher" (click)="toggleVisible()">
                <span class="c-icon c-icon-triangle-right"></span>
            </a>

            <a class="sidenav-switcher expanded-switcher" (click)="toggleExpanded()">
                <span class="c-icon c-icon-triangle-right"></span>
            </a>
        </div>

        <div class="sidenav-menu">
            <ng-template #menuListTemplate let-items>
                <ul>
                    <ng-container *ngFor="let item of items">
                        <li [class.sidenav-menu-item]="true"
                                [class.submenu]="(item.items?.length > 0)"
                                [class.custom-active]="(item.isActive && item.isActive())"
                                [class.custom-inactive]="(item.isActive && !item.isActive())"
                                [class.expanded]="(item.expandByDefault && item.expandByDefault())"
                                uiSrefActive="active">

                            <ng-container *ngIf="!item.rawHref; else rawHref">
                                <a uiSref="{{ item.sref }}" [uiParams]="item.srefParams" [uiOptions]="item.srefOptions">
                                    <span>{{ item.title }}</span>
                                    <span [class.icon-alert]="true" [class.icon-alert-hack-used-by-new-angular]="hackUsedByNewAngular" *ngIf="item.hasAlert | async"></span>
                                </a>
                            </ng-container>
                            <ng-template #rawHref>
                                <a [href]="item.sref" [target]="item.rawHrefTarget">
                                    <span>{{ item.title }}</span>
                                    <span [class.icon-alert]="true" [class.icon-alert-hack-used-by-new-angular]="hackUsedByNewAngular" *ngIf="item.hasAlert | async"></span>
                                </a>
                            </ng-template>

                            <ng-container *ngIf="item.items">
                                <ng-container [ngTemplateOutlet]="menuListTemplate" [ngTemplateOutletContext]="{ $implicit: item.items }">
                                </ng-container>
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </ng-template>
            <ng-container *ngIf="items?.length">
                <ng-container [ngTemplateOutlet]="menuListTemplate" [ngTemplateOutletContext]="{ $implicit: items }">
                </ng-container>
            </ng-container>
        </div>
	</div>

	<div class="content">
		<ng-content></ng-content>
	</div>
</div>
