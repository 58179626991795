import { NgModule } from '@angular/core';

import { RecurlyErrorContainer } from './recurly-error-container.directive';
import { RecurlyProvider } from './recurly.provider';
import { OnValidRecurlySubmitDirective } from './on-valid-recurly-submit.directive';
import { RecurlyPublicKeyService } from './recurly-public-key.service';

@NgModule({
	declarations: [
		RecurlyErrorContainer,
		OnValidRecurlySubmitDirective,
	],
	providers: [
		RecurlyProvider,
		RecurlyPublicKeyService,
	],
	exports: [
		RecurlyErrorContainer,
		OnValidRecurlySubmitDirective,
	],
})

export class RecurlyModule {}
