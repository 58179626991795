import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';
import { NgResourceArray } from 'commons/interfaces';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { Role } from './list/role.interface';

declare let apiPath: string;

@Injectable()
export class RolesResourceService {
	resource: any;
    rolesResource: any;

	constructor(
        public injector: Injector,
        public User: UserService,
        private http: RealmHttpClient,
    ) {
		const MemoResource = injector.get('$injector').get('MemoResource');
		const basePath = `${apiPath}/organizations/:organizationId/roles`;
		const defaultParams = { organizationId: User.profile.organization.organizationId };

		this.resource = MemoResource(`${basePath}`, {...defaultParams}, {
			list: {
				method: 'get',
				isArray: true,
				url: `${basePath}/counted`,
			},
			listNonCounted: {
				method: 'get',
				isArray: true,
			},
			get: {
				method: 'get',
				url: `${basePath}/:roleId`,
			},
			save: {
				method: 'POST',
			},
			update: {
				method: 'PUT',
				url: `${basePath}/:roleId`,
			},
			activate: {
				method: 'PUT',
				url: `${basePath}/:roleId/active`,
			},
			deactivate: {
				method: 'DELETE',
				url: `${basePath}/:roleId/active`,
			},
		});
	}

	list = (...args): NgResourceArray<Role> => this.resource.list(...args);
	listNonCounted = (...args) => this.resource.listNonCounted(...args);
	get = (...args) => this.resource.get(...args);
	save = (...args) => this.resource.save(...args);
	update = (...args) => this.resource.update(...args);
	activate = (...args) => this.resource.activate(...args);
	deactivate = (...args) => this.resource.deactivate(...args);

    public getSystemRolesForLenderContactCreation(
        tpoId: number,
    ): Observable<Role[]> {
        return this.http.request<Role[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/rolesForLenderContactCreation`,
            {}
        );
    }
}
