import { NgModule } from '@angular/core';

import { DocusignConnectivityResourceService } from './documents/docusign/docusign-connectivity-resource.service';
import { DocumentsDocusignConnectivityStatus } from './documents/docusign/documents-docusign-connectivity-status';
import { LenderIssuesStatusService } from './lender-issues-status.service'

@NgModule({
    imports: [],
	providers: [
		DocusignConnectivityResourceService,
		DocumentsDocusignConnectivityStatus,
		LenderIssuesStatusService,
	],
})
export class LenderStatusModule {}
