<form [formGroup]="agencyForm" class="remote-resource" [class.resolved]="resolved">

    <div class="review-header-wrapper flex-row gap-m">
        <h2>Agency</h2>
        <section-review [section]="sectionType" class="flex-grow"></section-review>
    </div>

    <form-errors *ngIf="editing || uploadError" [form]="agencyForm"></form-errors>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-md-4">
                <b>Type of Approval</b>
            </span>
            <span class="col-md-a">
                <b>Approval Date</b>
            </span>
            <span class="col-md-a">
                <b>End Date</b>
            </span>
            <span class="col-md-a">
                <b>ID Number</b>
            </span>
            <span class="col-f-80"></span>
        </div>

        <ng-container *ngIf="agenciesList">
            <form *ngFor="let item of agenciesList" class="row-fluid" [formGroup]="agencyForm.get(item.agencyName)" (onValidSubmit)="saveChanges(item)">
                <ng-container *ngxInit="agencyForm.get(item.agencyName) as agencyRow">
                    <div class="col-md-4 control-value" [class.disabled-grey]="agencyRow.get('approvalDate').disabled">
                        <span *ngIf="isLender; else investor"
                            [class.text-link]="item.uploadedDocument"
                            [tooltip]="item.uploadedDocument ? tooltipTpl : ''"
                            placement="right"
                        >
                            {{item.agencyName}}
                        </span>

                        <ng-template #investor>
                            <a
                                *ngIf="item.uploadedDocument"
                                [aDisabled]="isComergence"
                                href="{{basePath}}/{{item.agencyTypeId}}/approval-letter"
                            >
                                {{item.agencyName}}
                            </a>

                            <span *ngIf="!item.uploadedDocument">{{item.agencyName}}</span>
                        </ng-template>

                        <ng-template #tooltipTpl>
                             <div class="text-default">
                                 Uploaded by {{item.uploadedDocument.uploadedBy}}
                                 <br>
                                 {{item.uploadedDocument.uploadedDate | date: 'MM/dd/YYYY hh:mma'}}
                             </div>
                        </ng-template>
                    </div>
                    <div class="col-md-a form-group" has-error [isEditable]="agencyRow.get('agencyEditing').value">
                        <span view class="control-value">{{item.approvalDate | date: labelDateFormat}}</span>
                        <input
                            edit
                            class="form-control"
                            formControlName="approvalDate"
                            bsDatepicker
                            [bsConfig] ="{ dateInputFormat: modelDateFormat }"
                            (ngModelChange)="updateValidators(item)"
                        />
                    </div>
                    <div class="col-md-a form-group" has-error [isEditable]="agencyRow.get('agencyEditing').value">
                        <span view class="control-value">{{item.endDate | date: labelDateFormat}}</span>
                        <input
                            edit
                            class="form-control"
                            formControlName="endDate"
                            bsDatepicker
                            [bsConfig] ="{dateInputFormat: modelDateFormat}"
                            [minDate]="agencyRow.get('approvalDate').value"
                            (ngModelChange)="updateValidators(item)"
                        />
                    </div>
                    <div class="col-md-a form-group" has-error [isEditable]="agencyRow.get('agencyEditing').value">
                        <span view class="control-value">{{item.idNumber}}</span>
                        <input edit
                               class="form-control"
                               type="text"
                               formControlName="idNumber"
                               maxlength="20"
                        />
                    </div>
                    <div *ngIf="isLender && !agencyRow.get('agencyEditing').value" class="col-f-40">
                        <label>
                            <span class="btn btn-icon btn-tertiary">
                                <span class="glyphicon glyphicon-upload text-big"></span>
                            </span>
                            <input
                                class="hidden"
                                type="file"
                                [multiple]="false"
                                (input)="onFileSelect(item, $event.target.files)"
                            />
                        </label>
                    </div>
                    <div
                        *ngIf="isLender"
                        [isEditable]="agencyRow.get('agencyEditing').value"
                        class="col-f-{{agencyRow.get('agencyEditing').value ? 80 : 40}} text-right va-m button-holder"
                    >
                        <button view type="button" class="btn btn-icon btn-tertiary" (click)="edit(item)">
                            <span class="glyphicon glyphicon-pencil"></span>
                        </button>
                        <div class="flex-row" edit>
                                <button class="btn btn-icon btn-tertiary" type="submit">
                                    <span class="glyphicon glyphicon-ok"></span>
                                </button>
                                <a class="btn btn-icon btn-tertiary" (click)="cancelChanges(item)">
                                    <span class="glyphicon glyphicon-remove"></span>
                                </a>
                        </div>
                    </div>
                </ng-container>
            </form>
        </ng-container>
    </div>
</form>
