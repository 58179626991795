<div class="renewal-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
		<h4 class="modal-title">Update Renewal Date</h4>
	</div>
	<div class="modal-body remote-resource" [class.resolved]="!resolving">
		<div class="alert alert-danger text-center">
			Renewal dates changes can only be made for the following statuses: <br>
			Active, Inactive, Suspended, Watchlist, and Renewal Suspend
		</div>
		<p>Please select new renewal date:</p>
		<br>
		<div class="flex-column center">
			<span>New Renewal Date</span>
			<input
				class="form-control input-date"
				autocomplete="off"
				bsDatepicker
				(bsValueChange)="selectedDate = $event"
				[minDate]="minDate"
				placeholder="mm/dd/yyyy"
			>
		</div>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button
				type="button"
				[disabled]="!selectedDate || selectedDate < minDate"
				(click)="save()"
				class="btn btn-primary"
			>Save</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
		</div>
	</div>
</div>