import { Directive, Input, HostBinding, OnInit, OnDestroy, Host } from '@angular/core';
import { Subscription } from 'rxjs';

import { ListComponent } from 'commons/components/list/list.component';

// Intended to use with
@Directive({
	selector: '[isColumnEnabled][columnName]',
})
export class IsColumnEnabledDirective implements OnDestroy, OnInit {
	@Input('columnName') columnName: string;
	@HostBinding('class.hidden') disabled: boolean = true;
	public unsubColumns: Subscription;

	constructor(@Host() public listComponent: ListComponent) {
	}

	ngOnInit(): void {
		this.unsubColumns = (this.listComponent as any).columns$.subscribe((columns) => {
			const column = columns.get(this.columnName);
			this.disabled = !column || !column.enabled;
		});
	}

	ngOnDestroy(): void {
		this.unsubColumns.unsubscribe();
	}
}
