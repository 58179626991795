<div class="data-match content remote-resource" [class.resolved]="!resolving" >
    <div class="gray-head">
        <h2 class="clearfix">
            <span>#&nbsp;{{reportId}}</span>
        </h2>
    </div>
    <div class="data-match-result" *ngIf="dataMatchReport">
        <div class="input-details">
            <h3>Input Details</h3>
            <div class="rows-striped">
                <div class="row-fluid">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-4 control-label text-right">Company Name</label>
                                    <div class="col-md-8">
                                        <span class="control-value">{{dataMatchReport?.lender ? dataMatchReport.lender.name : '&ndash;'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-6 control-label text-right">Verification Date</label>
                                    <div class="col-md-6">
                                        <span class="control-value">{{(dataMatchReport?.verifiedDate | date:'MM/dd/YY') || '&ndash;'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-4 control-label text-right">Channel</label>
                                    <div class="col-md-8">
										<span class="control-value">{{dataMatchReport?.channel ? dataMatchReport?.channel.name : '&ndash;'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-6 control-label text-right">Verified By</label>
                                    <div class="col-md-6">
                                        <span class="control-value">{{dataMatchReport?.verifiedBy || '&ndash;'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-4 control-label text-right">Customer</label>
                                    <div class="col-md-8">
                                        <span class="control-value">{{dataMatchReport?.customer || '&ndash;'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-6 control-label text-right">Load Date</label>
                                    <div class="col-md-6">
                                        <span class="control-value">{{(dataMatchReport?.loadedDate | date:'MM/dd/yyyy') ||'&ndash;' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-4 control-label text-right">Status</label>
                                    <div class="col-md-8"*ngIf="statusConfig[dataMatchReport?.status]; let status">
                                        <span class="status label label-text-normal" [ngClass]="'label-' + status.color">{{status.title}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-6 control-label text-right">Loaded By</label>
                                    <div class="col-md-6">
                                        <span class="control-value">{{dataMatchReport?.loadedBy || '&ndash;'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <label class="col-md-4 control-label text-right">File</label>
                                    <div class="col-md-8">
                                        <div class="file control-value">
                                            <span class="glyphicon glyphicon-paperclip"></span>
                                            <a (click)="downloadFile(dataMatchReport.id)">{{dataMatchReport?.fileName}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-1"></div>
                    <div class="col-md-9">
                        <form [formGroup]="commentForm" id="commentForm" (onValidSubmit)="updateComment()">
                            <div class="form-group row">
                                <label class="col-md-2 control-label text-right">{{commentForm.getFieldLabel('comment')}}</label>
                                <div class="col-md-10">
                                    <div [isEditable]="isEditing">
                                        <span view class="control-value">{{dataMatchReport?.comment || '&ndash;'}}</span>
                                        <textarea edit name="comment" formControlName="comment" maxlength="2000" class="form-control" [rows]="4">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-2">
                        <div class="pull-right">
                            <div *ngIf="!isEditing">
                                <a *ngIf="isLoaded"(click)="setEditing(true)">Edit</a>
                            </div>
                            <div *ngIf="isEditing">
                                <button type="submit" class="btn btn-xs btn-primary" form="commentForm">Save</button>
                                <button type="button" class="btn btn-xs btn-default" (click)="cancelEdit()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="results">
            <div class="match">
                <ccm-data-match-result-section [dataMatchResultSection]="dataMatchReport"></ccm-data-match-result-section>
            </div>
            <div class="statistics">
                <ccm-data-match-hq-statistics-section [dataMatchStatistics]="dataMatchReport"></ccm-data-match-hq-statistics-section>
            </div>
            <br/>
            <div class="verification-results">
                <ccm-data-match-hq-verification-result-section [dataMatchHqVerificationResult]="dataMatchReport"
                [isLoaded]="isLoaded"
                [isApplicable]="isApplicable"
                [reportId]="reportId"
                (resolving)="reload($event)"
                (newReverifyEvent)="reverifyDataMatch($event)"></ccm-data-match-hq-verification-result-section>
            </div>
        </div>

        <div>
            <ccm-data-match-relationship-status-section
            [isApplicable]="isApplicable"
            [isLoaded]="isLoaded"
            [dataMatchReloadClient]="dataMatchReport"
            (newRelationshipToRestore)="relationshipStatusRestored($event)"
            (resolving)="reload($event)"
            [reportId]="reportId">
            </ccm-data-match-relationship-status-section>
        </div>
    </div>
</div>
