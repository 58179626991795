<div class="remote-resource" [class.resolved]="ownerInfo.$resolved">
	<div class="modal-header">
		<a class="pull-right" (click)="logout()">Logout</a>
		<span class="modal-title">Verify your ownership %</span>
	</div>
	<div class="modal-body">
		<div class="warning">
			<span class="glyphicon glyphicon-exclamation-sign"></span>
			<span>
				In connection with your registration application to participate in the Optimal Blue network of residential
				mortgage loan originators and service providers, Optimal Blue LLC ("Optimal Blue") performs background
				checks to comply with the Consumer Financial Protection Bureau's expectation that residential mortgage
				loan originators and their service providers take steps to ensure their compliance with Federal consumer
				financial law to protect the interests of consumers, and avoid consumer harm. By clicking the background
				authorization acceptance box in your application profile, you hereby authorize Optimal Blue to conduct
				background due diligence to share such information with residential mortgage loan originators and service
				providers you designate. Your background information to be obtained may include: professional history
				information, criminal record information, other public record information, and may be obtained through
				third party sources. A credit report is NOT included in the Optimal Blue due diligence process unless
				Optimal Blue separately obtains your consent to obtain your credit report. Optimal Blue will treat any
				information obtained through its due diligence process as confidential and will only share it as set forth
				in this authorization, in the Optimal Blue Privacy Policy and in any additional arrangements you may enter
				into with Optimal Blue.
			</span>
		</div>
		<form [formGroup]="ssnVerificationForm" id="ssnVerificationForm" name="ssnVerificationForm"
			  (onValidSubmit)="verify()">
			<form-errors [form]="ssnVerificationForm"></form-errors>
			<div class="row">
				<div class="col-sm-6">
					<div class="text-center your-information">
						<h3 class="bold">Your Information</h3>
						<div class="row">
							<div class="col-sm-5 control-label text-right">Company Name</div>
							<div class="col-sm-7 text-left">{{ownerInfo?.companyName}}</div>
						</div>
						<div class="row">
							<div class="col-sm-5 control-label text-right">First Name</div>
							<div class="col-sm-7 text-left">{{ownerInfo?.firstName}}</div>
						</div>
						<div class="row">
							<div class="col-sm-5 control-label text-right">Last Name</div>
							<div class="col-sm-7 text-left">{{ownerInfo?.lastName}}</div>
						</div>
						<div class="row">
							<div class="col-sm-5 control-label text-right">Ownership %</div>
							<div class="col-sm-7 text-left">{{ownerInfo?.ownership}}%</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="text-center ssn form-group" has-error>
						<h3 class="bold">Enter Your SSN to Verify</h3>
						<input class="form-control" maxlength="9" name="ssn" formControlName="ssn">
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer flex-row">

		<div class="flex-grow text-left">
			<div>If your information is incorrect, please contact responsible person:</div>
			<div class="contacts">{{ownerInfo?.primary?.firstName}} {{ownerInfo?.primary?.lastName}}&nbsp;&nbsp;<span
				class="glyphicon glyphicon-earphone"></span> {{ownerInfo?.primary?.phone | tel}} &nbsp;&nbsp;<span
				class="glyphicon glyphicon-envelope"></span>&nbsp;<a
				href="mailto:{{ownerInfo?.primary?.mail}}">{{ownerInfo?.primary?.mail}}</a></div>
		</div>
		<div class="text-right">
			<button type="submit" form="ssnVerificationForm" [disabled]="!ssnVerificationForm.valid" class="btn btn-primary">Verify</button>
		</div>
	</div>
</div>
