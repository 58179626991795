<div class="document-card">
	<div class="front-face flex-row stretch gap-m">
		<div class="flex">
			<div class="title">{{document.name}}</div>

			<show-more class="description text-muted" [text]="document.description"></show-more>

			<div class="indications flex-row gap-sm">
				<span
					*ngIf="document.libraryId"
					class="label label-default text-smaller"
				>
					From Library
				</span>

				<span
					*ngIf="document.docusignTemplate"
					class="label label-default text-smaller"
				>
					DocuSign
				</span>

				<span
					*ngIf="!document.isActive"
					class="label label-danger-wired text-smaller"
				>
					Discontinued
				</span>

				<a
					*ngIf="document.hasTemplate"
					class="btn btn-tertiary"
					[documentLink]="documentTemplateLink"
					[modalConfig]="documentTemplateModalConfig"
				>
					<span class="c-icon c-icon-file"></span> Template
				</a>

				<a
					class="btn btn-tertiary"
					uiSref="documents.:documentId.history"
					[uiParams]="{ documentId: document.id }"
				>
					<span class="c-icon c-icon-back-in-time"></span> History
				</a>
			</div>
		</div>

		<div class="actions flex-column right gap-sm">
			<a
				*ngIf="user?.can('MANAGE_GLOBAL_DOCUMENTS') && document.isActive"
				class="btn btn-primary-wired"
				uiSref="documents.:documentId"
				[uiParams]="{ documentId: document.id }"
			>
				Manage
			</a>
		</div>
	</div>
</div>
