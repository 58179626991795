import { Component, Input, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CommonProductionService, List } from 'shared/production/production.service';

@Component({
    selector: 'production-top-list',
    templateUrl: './top-list.component.html',
})
export class ProductionTopListComponent implements OnInit {
    @Input() nmlsId: number;
    @Input() type: string;
    @Input() title: string;

    prefix: string;
    showTableError = false;
    errorMsg = 'To access premium investor data upgrade your subscription.';

    list$: Observable<List[]>;
    mockEmptyList = Array(5);

    constructor(
        private readonly productionService: CommonProductionService,
        { params: { prefix } }: UIRouterGlobals,
    ) {
        this.prefix = prefix;
    }

    ngOnInit(): void {
        this.list$ = this.type === 'branches' ?
            this.productionService.getTopBranches(this.nmlsId).pipe(
                catchError(this.displayErrorMsg)
            ) :
            this.productionService.getTopLoanOfficers(this.nmlsId).pipe(
                catchError(this.displayErrorMsg)
            );
    }
    
    displayErrorMsg = (error): Observable<List[]> => {
        if (error.status === 403) {
            this.showTableError = true;
            return of(this.mockEmptyList);
        }

        return error

    }
}