import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Notify } from 'lender/manage-channels/manage-channel-card.interface';
import { UserService } from 'angularjs-providers/user.provider';
import { UserInformation } from 'comergence/customers/services/customer-services/user-information.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { Observable } from 'rxjs';

@Component({
    templateUrl: 'channel-manage-notifications-modal.component.html',
    selector: 'channel-manage-notifications-modal',
})
export class ChannelManageNotificationsModalComponent implements OnInit {
    onNotificationSave: (leftNotify: Notify, rightNotify: Notify, channelId: number) => Promise<void>;

    notificationsType: string;
    channelName: string;
    submissionNotification: Notify;
    lenderReviewNotification: Notify;
    organizationId: number;
    channelId: number;
    users: UserInformation[];

    showSubmissionEmail: boolean;
    addingSubmissionUser: boolean;
    showLenderReviewEmail: boolean;
    addingLenderReviewUser: boolean;

    canEditNotifications: boolean;
    isEditing: boolean;
    resolving: boolean;

    submissionForm: RealmFormGroup = new RealmFormGroup({
        accountEx: new RealmFormControl(
            'accountEx',
            {
                label: 'Assigned',
            },
        ),
        emailEnabled: new RealmFormControl(
            'emailEnabled',
            {
                label: 'Email Enabled',
            },
        ),
        email: new RealmFormControl(
            'email',
            {},
            Validators.email,
        ),
        users: new RealmFormControl(
            'users',
            {
                label: 'Users',
            },
        ),
    });

    lenderReviewForm: RealmFormGroup = new RealmFormGroup({
        accountEx: new RealmFormControl(
            'accountEx',
            {
                label: 'Assigned',
            },
        ),
        emailEnabled: new RealmFormControl(
            'emailEnabled',
            {
                label: 'Email Enabled',
            },
        ),
        email: new RealmFormControl(
            'email',
            {},
        ),
        users: new RealmFormControl(
            'users',
            {
                label: 'Users',
            },
        ),
    });

    constructor(
        public modalRef: BsModalRef,
        public user: UserService,
        public manageChannelService: ManageChannelService,
    ) {
    }

    async ngOnInit() {
        this.resolving = true;
        this.canEditNotifications = this.user.profile.can('MANAGE_CHANNEL_SETTINGS');
        this.organizationId = this.user.profile.organization.organizationId;
        this.setFormValues();
        this.users = await this.manageChannelService.getUsers(this.organizationId).toPromise();
        this.resolving = false;
    }

    setFormValues() {
        this.lenderReviewForm.patchValue(this.lenderReviewNotification);
        this.submissionForm.patchValue(this.submissionNotification);
        this.showLenderReviewEmail = this.lenderReviewNotification.emailEnabled;
        this.showSubmissionEmail = this.submissionNotification.emailEnabled;
        if(this.showLenderReviewEmail) {
            this.lenderReviewForm.controls['email'].setValidators([Validators.email, Validators.required]);
            this.lenderReviewForm.controls['email'].updateValueAndValidity();
        }
        if(this.showSubmissionEmail) {
            this.submissionForm.controls['email'].setValidators([Validators.email, Validators.required]);
            this.submissionForm.controls['email'].updateValueAndValidity();
        }
    }

    close() {
        this.modalRef.hide();
    }

    save() {
        if(this.submissionForm.controls['email'].invalid || this.lenderReviewForm.controls['email'].invalid) {
            if(this.submissionForm.controls['email'].invalid) {
                this.submissionForm.controls['email'].setErrors({error: {template: 'Please enter <b>email</b>'}});
                this.submissionForm.markAllAsTouched();
                this.submissionForm.updateValueAndValidity({onlySelf: true, emitEvent: true});
            }
            if(this.lenderReviewForm.controls['email'].invalid) {
                this.lenderReviewForm.controls['email'].setErrors({error: {template: 'Please enter <b>email</b>'}});
                this.lenderReviewForm.markAllAsTouched();
                this.lenderReviewForm.updateValueAndValidity({onlySelf: true, emitEvent: true});
            }
        } else {
            this.resolving = true;
            this.onNotificationSave(this.submissionForm.value, this.lenderReviewForm.value, this.channelId);
        }

    }

    toggleEditing() {
        if(this.isEditing) {
            this.lenderReviewForm.reset(this.lenderReviewNotification);
            this.submissionForm.reset(this.submissionNotification);
            this.showLenderReviewEmail = this.lenderReviewNotification.emailEnabled;
            this.showSubmissionEmail = this.submissionNotification.emailEnabled;
            this.close();
        }
        this.isEditing = !this.isEditing;
    }

    findByUserId(id: number) {
        return this.users.find(user => {
            return user.id == id
        })
    }

    isSubmissionEmptyAndDisabled(): boolean {
        return (!this.submissionNotification.emailEnabled && !this.submissionNotification.users.length && !this.submissionNotification.accountEx);
    }

    isLenderReviewEmptyAndDisabled(): boolean {
        return (!this.lenderReviewNotification.emailEnabled && !this.lenderReviewNotification.users.length && !this.lenderReviewNotification.accountEx);
    }

    toggleRightAddingUser() {
        this.addingLenderReviewUser = !this.addingLenderReviewUser;
    }

    addRightUser(newUser: UserInformation) {
        const tempUserList: number[] = [];
        this.lenderReviewForm.controls['users'].value.forEach(user => {
            if(user != newUser.id) {
                tempUserList.push(user);
            }
        });
        tempUserList.push(newUser.id);
        this.lenderReviewForm.patchValue({users: tempUserList});
        this.toggleRightAddingUser();
    }

    removeRightUser(userId: number) {
        const tempUserList: number[] = [];
        this.lenderReviewForm.controls['users'].value.forEach(user => {
            if(user != userId) {
                tempUserList.push(user);
            }
        });
        this.lenderReviewForm.patchValue({users: tempUserList});
    }

    toggleRightEmail() {
        if(!this.showLenderReviewEmail) {
            this.lenderReviewForm.controls['email'].setValidators([Validators.email, Validators.required]);
        } else {
            this.lenderReviewForm.controls['email'].clearValidators();
        }
        this.lenderReviewForm.controls['email'].updateValueAndValidity();
        this.showLenderReviewEmail = !this.showLenderReviewEmail;
    }

    toggleLeftAddingUser() {
        this.addingSubmissionUser = !this.addingSubmissionUser;
    }

    addLeftUser(newUser: UserInformation) {
        const tempUserList: number[] = [];
        this.submissionForm.controls['users'].value.forEach(user => {
            if(user != newUser.id) {
                tempUserList.push(user);
            }
        });
        tempUserList.push(newUser.id);
        this.submissionForm.patchValue({users: tempUserList});
        this.toggleLeftAddingUser();
    }

    removeLeftUser(userId: number) {
        const tempUserList: number[] = [];
        this.submissionForm.controls['users'].value.forEach(user => {
            if(user != userId) {
                tempUserList.push(user);
            }
        });
        this.submissionForm.patchValue({users: tempUserList});
    }

    toggleLeftEmail() {
        if(!this.showSubmissionEmail) {
            this.submissionForm.controls['email'].setValidators([Validators.email, Validators.required]);
        } else {
            this.submissionForm.controls['email'].clearValidators();
        }
        this.submissionForm.controls['email'].updateValueAndValidity();
        this.showSubmissionEmail = !this.showSubmissionEmail;
    }
}
