import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';

import {
	GlobalDocumentListComponent,
	GlobalDocumentListItemComponent,
} from './list';
import {
	ApproveLibraryDocumentReplaceModalComponent,
	DocumentDiscontinueModalComponent,
	RequestDocumentModalComponent,
	GlobalDocumentComponent,
	GlobalDocumentDetailsComponent,
	GlobalDocumentHistoryComponent,
} from './document';
import { GlobalDocumentsService } from './documents.service';
import { InvestorLibraryDocumentsService } from './library-documents.service';
import { InvestorChannelsService } from './channels.service';
import { DocumentsComponent } from './documents.component';
import { DocusignComponent } from './docusign/docusign.component';
import { SharedDocumentsModule } from 'shared/documents';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
        BsDropdownModule.forRoot(),
        SharedDocumentsModule,
    ],
	declarations: [
		ApproveLibraryDocumentReplaceModalComponent,
		DocumentDiscontinueModalComponent,
		RequestDocumentModalComponent,
		GlobalDocumentComponent,
		GlobalDocumentDetailsComponent,
		GlobalDocumentHistoryComponent,
		GlobalDocumentListComponent,
		GlobalDocumentListItemComponent,
		DocumentsComponent,
		DocusignComponent,
	],
	providers: [
		GlobalDocumentsService,
		InvestorLibraryDocumentsService,
		InvestorChannelsService,
	],
	// exports: [
	// 	GlobalDocumentsService,
	// ],
})
export class GlobalDocumentsModule {}
