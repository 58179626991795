<div class="remote-resource" [class.resolved]="list.$resolved">

	<notification></notification>

	<div class="filters-rows" *ngIf="!inline">
		<div class="row-fluid filter-labels">
			<div class="col-sm-3">
				<div class="text-muted">Channels</div>
			</div>
			<div class="col-sm-3">
				<div class="text-muted">Questionnaire</div>
			</div>
			<div class="col-sm-6"></div>
		</div>

		<div class="row-fluid filter-selectors">
			<div class="col-sm-3">
				<ng-select
					[(ngModel)]="params.channelId"
					(change)="setFilter({ channelId: $event })"
					[multiple]="true"
					[items]="filters.channelId?.$promise | async"
					[loading]="!filters.channelId?.$resolved"
					bindValue="id"
					bindLabel="name"
					placeholder="Select"
				></ng-select>
			</div>
			<div class="col-sm-3">
				<ng-select
					[(ngModel)]="params.questionnaireId"
					(change)="setFilter({ questionnaireId: $event })"
					[items]="filters.questionnaireId?.$promise | async"
					[loading]="!filters.questionnaireId?.$resolved"
					bindValue="id"
					bindLabel="name"
					placeholder="Select"
				></ng-select>
			</div>

			<div class="col-sm-6 text-right">
				<a
					*ngIf="user.can('MANAGE_CLIENT_DOCUMENTS') && isEditable"
					class="btn btn-primary"
					uiSref=".new"
				>
					Add Document
				</a>
			</div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<div class="col-sm-12 text-center va-m">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</div>
		</div>

		<div class="row-fluid no-border" *ngFor="let item of list">
			<client-document-list-item
				[tpoId]="tpoId"
				[document]="item"
				[editable]="isEditable"
                (docReviewed)="reloadPage()"
			></client-document-list-item>
		</div>

		<div class="row-fluid no-border" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center va-m text-bigger text-muted">No documents</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
