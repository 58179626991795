import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient, RealmParams } from 'commons/services/http';
import {
    ScorecardAssignedUser,
    ScorecardReview,
    ScorecardReviewHistory,
    ScorecardStatus,
    ScorecardStatusTransition,
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-reviews.interface';
import { ListParams } from 'commons/components/new-list/list.component';


import { OwnerFindings, SortedOwnerFindings } from '../scorecard-owners.interface';
import {
    CommentType,
    ScorecardComment,
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments.interface';
import {
    OwnerContactInformation,
    ScorecardSummaryOwner,
} from 'shared/due-diligence/scorecard/scorecard-summary-owners/scorecard.interface';
import {
    ScorecardFindingsMetrics,
} from '../scorecard-findings-metrics.interface';
import { AddEditFindingData, OwnerInformation } from 'shared/due-diligence/scorecard/scorecard-add-findings/scorecard-add-finding.interface';
import { ScorecardFindingCategories } from '../scorecard-finding-category.interface';
import { ScorecardFinding, ScorecardFindingReviewerInfo } from '../scorecard-finding.interface';
import { ScorecardFindingFormService } from './scorecard-finding-form.service';
import {
    ScorecardFindingHistory,
} from 'shared/due-diligence/scorecard/scorecard-finding-history/scorecard-finding-history.interface';
import { ReviewAlertInformation } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-finding-summary.interface';



declare let apiPath;

@Injectable()
export class ScorecardService {
    constructor(
        private readonly http: RealmHttpClient,
        private readonly scorecardFindingFormService: ScorecardFindingFormService,
    ) {
    }

    // Resources

    getScorecardOwners(tpoId: (number | string)): Observable<OwnerFindings[]> {
        return this.http.request<OwnerFindings[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/scorecard-summary/owners`,
        );
    }

    getSortedScorecardOwners(tpoId: (number | string)): Observable<SortedOwnerFindings> {
        return this.http.request<SortedOwnerFindings>(
            'GET',
            `${apiPath}/tpos/${tpoId}/scorecard-summary/sorted-owners`,
        );
    }

    getScorecardCategories(tpoId: (number | string)): Observable<ScorecardFindingCategories> {
        return this.http.request<ScorecardFindingCategories>(
            'GET',
            `${apiPath}/tpos/${tpoId}/scorecard-summary/categories`,
        );
    }

    getScorecardSummaryMetrics(tpoId: (number | string)): Observable<ScorecardFindingsMetrics> {
        return this.http.request<ScorecardFindingsMetrics>(
            'GET',
            `${apiPath}/tpos/${tpoId}/scorecard-summary/metrics`,
        );
    }

    getReviewHistory(reviewId: number, params: ListParams): Observable<PagedData<ScorecardReviewHistory[]>> {
        return this.http.pagedRequest<ScorecardReviewHistory[]>(
            'GET',
            `${apiPath}/reviews/${reviewId}/history`,
            params
        );
    }

    getScorecardComments(tpoId: number, params: RealmParams): Observable<PagedData<ScorecardComment[]>> {
        return this.http.pagedRequest<ScorecardComment[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/scorecard-summary/comment`,
            params
        )
    }

    createScorecardComment(tpoId: number, comment: ScorecardComment): Observable<ScorecardComment> {
        return this.http.request<ScorecardComment>(
            'POST',
            `${apiPath}/tpos/${tpoId}/scorecard-summary/comment`,
            null,
            comment
        )
    }

    getScorecardFindingComments(tpoId: number, findingId: number, params: RealmParams): Observable<PagedData<ScorecardComment[]>> {
        return this.http.pagedRequest<ScorecardComment[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/findings/${findingId}/comment`,
            params
        )
    }

    createScorecardFindingComment(tpoId: number, findingId: number, comment: ScorecardComment): Observable<ScorecardComment> {
        return this.http.request<ScorecardComment>(
            'POST',
            `${apiPath}/tpos/${tpoId}/findings/${findingId}/comment`,
            null,
            comment
        )
    }

    getScorecardFindingHistory(tpoId: number, findingId: number, params): Observable<PagedData<ScorecardFindingHistory[]>> {
        return this.http.pagedRequest<ScorecardFindingHistory[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/findings/${findingId}/history`,
            params
        )
    }

    getCommentTypes(): Observable<CommentType[]> {
        return this.http.request<CommentType[]>(
            'GET',
            `${apiPath}/commenttype`
        )
    }

    getScorecardReviews(params: ListParams): Observable<PagedData<ScorecardReview[]>> {
        return this.http.pagedRequest<ScorecardReview[]>(
            'GET',
            `${apiPath}/reviews`,
            params
        )
    }

    getAssignedUsers(): Observable<ScorecardAssignedUser[]> {
        return this.http.request<ScorecardAssignedUser[]>(
            'GET',
            `${apiPath}/reviews/assigned-users`
        )
    }

    getStatusTransitions(): Observable<ScorecardStatusTransition[]> {
        return this.http.request<ScorecardStatusTransition[]>(
            'GET',
            `${apiPath}/reviews/statuses/transitions`
        )
    }

    updateAssignedUser(reviewId: number, assignee: ScorecardAssignedUser): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/reviews/${reviewId}/assignee`,
            null,
            assignee
        )
    }

    updateStatus(reviewId: number, status: ScorecardStatus): Observable<ScorecardStatus> {
        return this.http.request<ScorecardStatus>(
            'PUT',
            `${apiPath}/reviews/${reviewId}/status`,
            null,
            status
        )
    }

    public getScorecardSummaryOwnerFindings(listParam, tpoId: number, ownerId: number): Observable<PagedData<ScorecardSummaryOwner[]>> {
        return this.http.pagedRequest<ScorecardSummaryOwner[]>(
            'GET' ,
            `${apiPath}/tpos/${tpoId}/findings/owners/${ownerId}` ,
            listParam
        );
    }

    public getOwnerContactInformation(ownerId: number, tpoId: number): Observable<OwnerContactInformation> {
        return this.http.request<OwnerContactInformation>(
            'GET',
            `${apiPath}/tpos/${tpoId}/contacts/${ownerId}`
        );
    }

    public markReview(tpoId: number, ownerId: number): Observable<ScorecardFindingReviewerInfo> {
        return this.http.request<ScorecardFindingReviewerInfo>(
            'POST',
            `${apiPath}/tpos/${tpoId}/findings/${ownerId}/review`,
        );
    }

    public getOwnerInformation(tpoId: number) : Observable<OwnerInformation[]> {
        return this.http.request<OwnerInformation[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/owners`,
        );
    }

    public addCompanyFinding(
        tpoId: number,
        categoryId: string,
        data: AddEditFindingData
    ): Observable<ScorecardFinding> {
        const formData = this.scorecardFindingFormService.prepareFindingFormData(data);

        return this.http.requestWithHeaders<ScorecardFinding> (
            'POST',
            `${apiPath}/tpos/${tpoId}/findings/category/${categoryId}`,
            {},
                formData,
            {
                'enctype': 'multipart/form-data',
            },
       );
    }

    public addIndividualFinding(
        tpoId: number,
        categoryId: string,
        individualId: number,
        data: AddEditFindingData
    ): Observable<ScorecardFinding> {
        const formData = this.scorecardFindingFormService.prepareFindingFormData(data);

        return this.saveFinding(
            'POST',
            `${apiPath}/tpos/${tpoId}/findings/category/${categoryId}/individuals/${individualId}`,
            formData,
        );
    }

    public editFinding(
        tpoId: number,
        findingId: (number | string),
        data: AddEditFindingData
    ): Observable<ScorecardFinding> {
        const formData: FormData = this.scorecardFindingFormService.prepareFindingFormData(data);

        return this.saveFinding(
            'PUT',
            `${apiPath}/tpos/${tpoId}/findings/${findingId}`,
            formData,
        );
    }

    public getFindingsSummary(tpoId: number, listParams: ListParams, findingId: string) {
        return this.http.pagedRequest<ScorecardSummaryOwner[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/findings/category/${findingId}`,
            listParams
        );
    }

    public getFindingDetails(tpoId: number, findingId: number) : Observable<ScorecardFinding> {
        return this.http.request<ScorecardFinding>(
            'GET',
            `${apiPath}/tpos/${tpoId}/findings/${findingId}`,
        );
    }

    private saveFinding(
        verb: string,
        url: string,
        formData
    ): Observable<ScorecardFinding> {
        return this.http.requestWithHeaders<ScorecardFinding>(
            verb,
            url,
            {},
            formData,
            {
                'enctype': 'multipart/form-data',
            },
        );
    }
}
