import { isFunction } from 'lodash';
import { AbstractControl, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Pin code must be 4 digits';

type pinTemplateStringFn = (value: string | number | null) => string;

export function PinValidator(messageTemplate: string | pinTemplateStringFn = ''): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} | null => {
		const isValid = !control.value || !!`${control.value}`.replace(/\W/g, '').match(/^\d{4}$/);

		const message = isFunction(messageTemplate) ? messageTemplate(control.value) : messageTemplate;

		return isValid ? null : {
			pin: {
				value: control.value,
				message: message || defaultMessage,
			},
		};
	};
}
