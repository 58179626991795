<div class="social-media-campaigns">

	<div class="gray-head flex-head">

		<ul class="breadcrumbs">
			<li>
				<a uiSref="social-compliance.publisher.groups" [uiParams]="{ page: null, size: null, sort: null, q: null }">
					<span class="glyphicon glyphicon-chevron-left"></span>
					Group Sharing
				</a>
			</li>
		</ul>

		<h2>{{group.name}}</h2>

		<div class="col-min">
			<ul class="tab-menu">
				<li uiSrefActive="active">
					<a uiSref=".contacts">
						<b>Contacts</b>
					</a>
				</li>
				<li uiSrefActive="active">
					<a uiSref=".sharing">
						<b>Sharing</b>
					</a>
				</li>
			</ul>
		</div>

	</div>

	<div ui-view></div>

</div>
