<div class="dropdown" dropdown>
    <a class="btn btn-icon btn-tertiary text-bigger dropdown-toggle" dropdownToggle placement="top"><span class="c-icon c-icon-dot-3"></span></a>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <li role="menuitem" [ngSwitch]="application.inProgress && application.channel.id > 0">
            <a *ngSwitchCase="true" href="/lenders/{{userProfileOrganizationId}}/tpos/{{application.tpoId}}/channels/{{application.channel.id}}/application/print" target="_blank">View</a>
            <a *ngSwitchDefault href="/lenders/{{userProfileOrganizationId}}/tpos/{{application.tpoId}}/channels/{{application.channel.id}}/applications/{{application.applicationId}}/print" target="_blank">View</a>
        </li>
        <li role="menuitem" *ngIf="application.channel.id > 0">
            <a
                uiSref="client.:id.account.channels.:channelId.applications.:applicationId.history"
                [uiParams]="{id: application.tpoId, channelId: application.channel.id, applicationId: application.applicationId}"
            >History</a>
        </li>
        <li role="menuitem" *ngIf="application.channel.id > 0">
            <a
                uiSref="client.:id.account.channels.:channelId.applications.:applicationId.comments"
                [uiParams]="{id: application.tpoId, channelId: application.channel.id, applicationId: application.applicationId}"
            >Comments</a>
        </li>
        <li role="menuitem" *ngIf="userProfileCanPrintPackage && application.hasPrintPackage">
            <a href="/api/download/applications/{{application.applicationId}}/export" target="_blank">Print Package</a>
        </li>
    </ul>
</div>
