<client-document-card
	[className]="className"
	[document]="document"
	[downloadLink]="downloadLink"
	(upload)=upload($event)
>
	<ng-template #indications>
		<div class="flex-row gap-sm">
			<span
				*ngIf="document.isCSD"
				class="label label-default text-smaller"
			>CSD</span>

			<span
				*ngIf="document.libraryId"
				class="label label-default text-smaller"
			>From Library</span>

			<span
				*ngIf="!user.isTpo && document.docusignTemplate"
				class="label label-default text-smaller"
			>DocuSign</span>

			<document-status
				*ngIf="document.isActive && document.documentState === 'REQUESTED'"
				[status]="document.documentState"
			></document-status>

			<span
				*ngIf="!document.isActive"
				class="label label-danger-wired text-smaller"
			>{{discontinuedBadgeText || 'Discontinued'}}</span>

			<span
				*ngIf="document.isExpired"
				class="label label-danger-wired text-smaller"
			>Expired</span>

			<a
				*ngIf="document.hasTemplate && templateDownloadLink"
				class="btn btn-tertiary"
				[documentLink]="templateDownloadLink"
			>
				<span class="c-icon c-icon-file"></span> Template
			</a>

			<a
				class="btn btn-tertiary"
				(click)="showHistory()"
			>
				<span class="c-icon c-icon-back-in-time"></span> History
			</a>

            <span
                *ngIf="document.hasUnreviewedDocuments && !user.isTpo && canManageDocuments"
                [tooltip]="'Unreviewed Documents'"
                class="c-icon c-icon-attention icon-warning"
            ></span>&nbsp;

			<span
				*ngIf="document.lastHistoryAction && document.lastHistoryActionDate"
				class="last-history-action flex text-overflow"
			>
				{{document.lastHistoryAction}}{{document.lastHistoryActionDate | date:'MM/dd/yyyy'}}
			</span>
		</div>
	</ng-template>

	<ng-template #actions>
		<ng-content></ng-content>
	</ng-template>
</client-document-card>
