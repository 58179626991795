<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<div class="alert alert-danger" *ngIf="errorText" [innerHTML]="errorText"></div>
	<p [innerHTML]="message"></p>
	<div class="flex-row gap">
		<sm-icon
			[config]="account"
			[showRssStatus]="account.monitoringStatus === 'on' || account.rssLink"
		></sm-icon>
		<div class="flex text-overflow">
			<ng-container *ngIf="account.networkCode !== 'RS' && account.link; then linkAnchor; else linkName"></ng-container>
			<ng-template #linkAnchor>
				<a [href]="account.link | hrefReady" target="_blank">{{account.name}}</a>
			</ng-template>
			<ng-template #linkName>
				{{account.name}}
			</ng-template>
		</div>
	</div>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="text-right">
		<button type="button" (click)="onConfirm()" class="btn btn-primary">{{confirmText}}</button>
		<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
	</div>
</div>
