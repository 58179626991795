import { reduce } from 'lodash';
import { ElementRef, Directive, Input, Output, EventEmitter } from '@angular/core';

import { InfiniteScrollEntry } from './scroll-entry';

@Directive({
	selector: '[infiniteScrollEntry]',
})
export class InfiniteScrollEntryDirective {
	@Input('infiniteScrollEntry')
	scrollEntry: InfiniteScrollEntry;

	@Output()
	onHeightCalculate: EventEmitter<any> = new EventEmitter();

	constructor(public element: ElementRef) {}

	ngAfterViewInit() {
		// TODO: this is very bad, think about something smarter later
		// we should be more declarative and use some sort of infiniteScrollImage directive when it is needed to wait for content load
		const images = this.element.nativeElement.querySelectorAll('img:not([infiniteScrollSkipImageLoadEvent])');

		if (images.length) {
			this.setHeightAfterImagesLoaded(images);
			return;
		}

		this.setElementHeight();
	}

	setHeightAfterImagesLoaded(images) {
		const promises = reduce(images, (acc, image) => {
			const promise = new Promise<void>((resolve) => {
				if (image.complete) {
					resolve();
					return;
				}

				image.onload = image.onerror = () => {
					resolve();
					image.onload = image.onerror = () => {};
				};
			});

			return [ ...acc, promise ];
		}, []);

		Promise.all(promises).then(() => {
			this.setElementHeight();
		});
	}

	setElementHeight() {
		const element = this.element.nativeElement;
		const style = getComputedStyle(element);

		const margins = parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);

		this.onHeightCalculate.emit({
			scrollEntry: this.scrollEntry,
			innerHeight: element.scrollHeight,
			height: element.scrollHeight + margins,
		});
	}
}
