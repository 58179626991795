import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator } from 'commons/validators';

import { ClientsService } from '../../clients.service';
import { InvestorOptions, ShareOrganizationChannel, AssigneeOptions, ChannelOptions } from './share-clients.interface';

@Component({
    templateUrl: './share-clients-modal.component.html',
})
export class ShareClientsModalComponent implements OnInit, OnDestroy {
    resolving: boolean;
    channelId: number;
    tpoIds: number[];
    selectedInvestorId: number;
    callbackOnSave: () => void;
    investors: Observable<InvestorOptions[]>;
    channels: Observable<ChannelOptions[]> = of([]);
    users: Observable<AssigneeOptions[]> = of([]);
    loading = {
        investors: true,
        channels: false,
        users: false,
    };
    destroy$: Subject<void> = new Subject();

    form = new RealmFormGroup({
        investor: new RealmFormControl(
            'investor',
            {
                label: 'Investor',
                updateOn: 'change',
                disabled: true,
            },
            DropdownRequiredValidator,
        ),
        channelToId: new RealmFormControl(
            'channelToId',
            {
                label: 'Channel',
                updateOn: 'change',
            },
            DropdownRequiredValidator,
        ),
        assignedUserId: new RealmFormControl(
            'assignedUserId',
            {
                label: 'User',
                updateOn: 'change',
            },
            DropdownRequiredValidator,
        ),
        message: new RealmFormControl(
            'message',
            {
                label: 'Message',
                updateOn: 'change',
            },
            Validators.required,
        ),
    });

    constructor(
        private modalRef: BsModalRef,
        public clientsService: ClientsService,
    ) {}

    ngOnInit() {
        this.investors = this.clientsService.getInvestorOptionsForShare(this.channelId);

        this.investors.subscribe(() => {
            this.loading.investors = false;
            this.disableField('investor', false);
        });

        const investorValueChanges = this.form.get('investor').valueChanges;

        this.channels = investorValueChanges.pipe(
            distinctUntilChanged(),
            switchMap(id => {
                this.loading.channels = true;
                this.disableField('channelToId', true);
                this.form.patchValue({ channelToId: null });
                if (!id) {
                    return of([]);
                }
                return this.clientsService.getChannelOptionsForShare(this.channelId, id);
            }),
            tap(() => {
                this.loading.channels = false;
                this.selectedInvestorId = this.form.get('investor').value;
                this.disableField('channelToId', false);
            }),
            takeUntil(this.destroy$),
            shareReplay(1),
        );

        const channelValueChanges =  this.form.get('channelToId').valueChanges;

        this.users = channelValueChanges.pipe(
            distinctUntilChanged(),
            switchMap(id => {
                this.loading.users = true;
                this.disableField('assignedUserId', true);
                this.form.patchValue({ assignedUserId: null });
                if (!id) {
                    return of([]);
                }

                return this.clientsService.getAssigneeOptionsForShare(this.channelId, this.selectedInvestorId , id);
            }),
            tap((users) => {
                this.loading.users = false;
                if (users.length) {
                    this.form.patchValue({ assignedUserId: -1 });
                }
                this.disableField('assignedUserId', false);
            }),
            takeUntil(this.destroy$),
            shareReplay(1),
        );
    }

    disableField(field: string, disable: boolean) {
        const fieldControl = this.form.get(field);
        return disable ? fieldControl.disable() : fieldControl.enable();
    }

    close(): void {
        this.modalRef.hide();
    }

    async submit() {
        const { channelToId, assignedUserId, message } =  this.form.value as ShareOrganizationChannel;
        try {
            this.resolving = true;
            await this.clientsService.shareOrganizationChannel(
                this.channelId,
                {
                    channelToId,
                    assignedUserId,
                    message,
                    tpoIds: this.tpoIds,
                }
            ).toPromise();
            this.close();
            this.callbackOnSave();
        } catch ({ error }) {
            this.form.setServerError(error);
        } finally {
            this.resolving = false;
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
