<ng-container *ngIf="link?.data?.isManual; else dashTpl">
	<ng-container *ngIf="link?.data?.rssLink; then viewRss; else addRss"></ng-container>
	<ng-template #viewRss>
		<a (click)="showEditModal()">View RSS</a>
	</ng-template>
	<ng-template #addRss>
		<ng-container *ngIf="canManage; else dashTpl">
			<a (click)="showAddModal()">Add RSS</a>
		</ng-container>
	</ng-template>
</ng-container>

<ng-template #dashTpl>
	&ndash;
</ng-template>
