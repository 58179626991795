import { Injectable } from '@angular/core';

import { NgResourceArray } from 'commons/interfaces';

import { ActivityResourceHistoryService } from './history-resource.service';

@Injectable()
export class ActivityHistoryService {
	constructor(
		public historyService: ActivityResourceHistoryService,
	) {
	}

	history = (queryParams?: ActivityHistoryParams): NgResourceArray<History> => this.historyService.history.query(queryParams);
}

interface ActivityHistoryParams {
	activityId?: string,
	page?: number;
	size?: number;
	sort?: string[];
}

export interface History {
	actionAt: number;
	actionBy: string;
	actions: string[];
	id: number;
}
