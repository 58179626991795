import { Component, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { debounce } from 'lodash';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { PagedData, RealmPagedParams } from 'commons/services/http';

import { ReviewAccount } from '../account.interface';
import { NetworkCodes } from '../sm-connect-button/connectors/connector.interface';
import { GoogleBusinessService } from './google-business.service';

@Component({
	selector: 'google-business',
	templateUrl: './google-business.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => GoogleBusinessComponent) },
		{ provide: NewPagedListComponent, useExisting: forwardRef(() => GoogleBusinessComponent) },
	],
})
export class GoogleBusinessComponent extends NewPagedListComponent<ReviewAccount> {
	static listName = 'googleBusinessList' as const;
    public readonly networkCode = NetworkCodes.GMB;
    public User: UserProfile;

	constructor(
        { profile }: UserService,
		router: UIRouter,
		private googleBusinessService: GoogleBusinessService,
	) {
		super(router);
        this.User = profile;
	}

	protected loadList(params: RealmPagedParams): Observable<PagedData<ReviewAccount[]>> {
		return this.googleBusinessService.getAccounts(params);
	}

    onConnectFinish = debounce(() => {
        this.updateList(this.params);
    }, 10)
}
