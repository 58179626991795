import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { CommonProductionService} from 'shared/production/production.service';

import { CommonProductionComponent } from './production.component';
import { ProductionTotalVolumeComponent } from './total-volume/total-volume.component';
import { ProductionTopListComponent } from './top-list/top-list.component';

const components = [
    CommonProductionComponent,
    ProductionTotalVolumeComponent,
    ProductionTopListComponent,
];

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
    ],
    declarations: [
        ...components,
    ],
    providers: [
        CommonProductionService,
    ],
    exports: [
        ProductionTotalVolumeComponent,
    ],
})

export class CommonProductionModule {}
