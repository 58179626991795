<div class="advertising-modal">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
	</div>
	<div class="modal-body text-center">
		<img src="/static/images/telegram.svg" alt="Audit Checklist"/>
		<br>
		<br>
		This feature requires you have an active subscription to <br>
		our Social media platform. For more information please <br>
		contact sales at 800-465-1002.
		<br>
		<br>
		<button class="btn btn-primary" (click)="onCancel()">Close</button>
		<br>
		<br>
	</div>
</div>
