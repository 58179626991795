<section class="remote-resource" [class.resolved]="!financialSanctionsResolving">
    <h3>Financial Sanctions</h3>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <span class="col-sm-3">
                <b>First and Last Name</b>
            </span>
            <span class="col-sm-2">
                <b>Date of Action</b>
            </span>
            <span class="col-sm-a">
                <b>Action Type</b>
            </span>
            <span class="col-sm-2">
                <b>Regulator</b>
            </span>
            <span class="col-f-120">
                <b>Document</b>
            </span>
        </div>
        <div class="row-fluid findings" *ngFor="let financialSanction of financialSanctions">
            <span class="col-sm-3">{{ financialSanction.findingFor || '&ndash;' }}</span>
            <span class="col-sm-2">
                {{ (financialSanction.dateOfAction ? (financialSanction.dateOfAction | date) : '&ndash;') }}
            </span>
            <span class="col-sm-a">{{ financialSanction.actionType || '&ndash;' }}</span>
            <span class="col-sm-2">{{ financialSanction.regulator || '&ndash;' }}</span>
            <span class="col-f-120">
                <a *ngIf="financialSanction.associatedDocumentURL"
                    [href]="financialSanction.associatedDocumentURL"
                    target="_blank"
                    class="btn btn-primary"
                >
                    Download
                </a>
            </span>
        </div>
        <div class="row-fluid" *ngIf="!financialSanctions.length">
            <span class="col-sm-12 text-muted text-center text-bigger text-weak">No records added</span>
        </div>
    </div>
</section>
