<div class="review-header-wrapper flex-row gap-m">
    <h2>Affiliates</h2>
    <section-review [section]="sectionType" [organizationId]="lenderInfo.organizationId" class="flex-grow"></section-review>
    <div *ngIf="user.isTpo">
        <button class="btn btn-primary" uiSref="account.relationships.affiliates.new">
            <span class="glyphicon glyphicon-plus"></span>
             Add
        </button>
    </div>
</div>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-sm-a">Company</div>
            <div class="col-sm-a">Business Type</div>
            <div class="col-sm-a">Address</div>
            <div class="col-sm-a">City</div>
            <div class="col-sm-a">State</div>
            <div class="col-sm-a">Zip Code</div>
            <div class="col-sm-a">Affiliation Dates</div>
        </div>

        <div *ngIf="!resolving && ((list | async)?.length > 0); else noAffiliates">
            <a
                *ngFor="let affiliate of list | async"
                class="row-fluid clickable table-row"
                [uiSref]="individualAffiliateLink"
                [uiParams]="{id: tpoId, affiliateId: affiliate.id}"
            >
                <div class="col-md-a">
                    <a class="two-liners">{{affiliate.entityName}}</a>
                </div>
                <div class="col-md-a">
                    <div class="col-md-a">{{affiliate.businessType}}</div>
                </div>
                <div class="col-md-a">
                    <div class="col-md-a">{{affiliate.address1}}</div>
                    <div class="col-md-a">{{affiliate.address2}}</div>
                </div>
                <div class="col-md-a">
                    <div class="col-md-a">{{affiliate.city}}</div>
                </div>
                <div class="col-md-a">
                    <div class="col-md-a">{{affiliate.state}}</div>
                </div>
                <div class="col-md-a">
                    <div class="col-md-a">{{affiliate.zip}}</div>
                </div>
                <div class="col-md-a">
                    <div class="col-md-a">{{affiliate.startDate | date: labelDateFormat}} - {{affiliate.endDate ? (affiliate.endDate | date: labelDateFormat) : 'Present'}}</div>
                </div>
            </a>
        </div>
        <ng-template #noAffiliates>
            <div class="row">
                <div class="col-sm-12 text-center text-light text-bigger">
                    No Affiliates
                </div>
            </div>
        </ng-template>
        <br>
    </div>
    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>
