<div class="container">
    <div class="row">
    <div *ngFor="let column of documentSummaryColumns" class="col-xs-12 col-lg-{{columnWidth}}">
        <div *ngFor="let documentSummary of column" class="row">
            <div
                [document-card]
                [strategy]="strategy"
                [allChannels]="allChannels"
                [strategyParams]="financialDocumentParams"
                [documentSummary]="documentSummary"
                class="col-xs-12"
            >
            </div>
        </div>
    </div>
    </div>
</div>
