<div class="services remote-resource" [class.resolved]="apiSettings.$resolved">
	<h2 class="title-label">
		API
	</h2>
	<form [formGroup]="apiSettingsForm" id="apiSettingsForm" (onValidSubmit)="save()">
		<form-errors [form]="apiSettingsForm"></form-errors>
		<div class="rows-striped client-id">
			<div class="row-fluid">
				<div class="col-sm-9">
					<div class="form-group row">
						<label class="control-label col-sm-2">Client ID:</label>
						<div class="col-sm-8" [class.preview]="!editing">
							<editable-component [editable]="editing">
								<ng-template #editTemplate>
									<input type="text" formControlName="adClientId" class="form-control" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{apiSettingsFormCopy?.adClientId || '&ndash;'}}
									</div><!--end: control-value-->
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-sm-3 text-right">
					<div *ngIf="User?.can('CCM_MANAGE_CUSTOMERS')">
						<button *ngIf="!editing" (click)="edit()" class="btn btn-primary">Edit</button>
						<button *ngIf="editing" type="submit" class="btn btn-primary">Save</button>
						<a *ngIf="editing" (click)="reset()" class="btn btn-default">Cancel</a>
					</div>
				</div>
			</div>
		</div>
	</form>
</div><!--end: services-->
