import { Component, Input } from '@angular/core';

import { Comment } from 'lender/common/interfaces/comment.interface';


@Component({
    selector: 'alert-comment',
	templateUrl: './alert-comment.component.html',
})
export class AlertCommentComponent {
    @Input() comment: Comment;
}