import { Component, Input, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { ReplaySubject, Subject } from 'rxjs';

import { ScorecardFinding, ScorecardFindingDetailRow } from '../scorecard-finding.interface';
import { ScorecardService } from '../services/scorecard.service';
import { UserService } from 'angularjs-providers/user.provider';
import { AddEditFindingData } from '../scorecard-add-findings/scorecard-add-finding.interface';
import { ScorecardFindingService } from '../services/scorecard-finding.service';
import { ScorecardMarkReviewedService } from '../services/scorecard-mark-reviewed-service';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ScorecardFindingCategoryCode } from 'shared/due-diligence/scorecard/scorecard-finding-category.interface';


@Component({
    templateUrl: './scorecard-finding.component.html',
})
export class ScorecardFindingComponent implements OnInit {
    @Input() public lenderInfo: AccountInformation;

    findingIsLoading: boolean = false;
    markingFindingAsReviewed: boolean = false;
    savingFinding: boolean = false;

    isCCM: boolean;
    isLender: boolean;
    hasReviewFindingPermission: boolean;
    hasEditAndViewAlertPermission: boolean;
    tpoId: number;
    lenderId: number;
    findingCategoryCode: ScorecardFindingCategoryCode;
    findingId: number;

    readonly finding$: ReplaySubject<ScorecardFinding> = new ReplaySubject(1);
    readonly saveSuccessful$: Subject<void> = new Subject();
    readonly serverErrorMessage$: Subject<string> = new Subject();
    detailRows: ScorecardFindingDetailRow[] = [];

    sref: string = '';
    srefParams: any = {};

    constructor(
        private readonly userService: UserService,
        private readonly scorecardService: ScorecardService,
        private readonly scorecardFindingService: ScorecardFindingService,
        private readonly scorecardMarkReviewedService: ScorecardMarkReviewedService,
        private readonly uiRouterGlobals: UIRouterGlobals,
    ) {
    }

    // Override
    async ngOnInit(): Promise<void> {
        this.isCCM = this.userService.profile.isComergence;
        this.isLender = this.userService.profile.isLender;
        // TODO: Refactor this to scorecard mark reviewed service
        this.hasReviewFindingPermission = this.userService.profile.can('REVIEW_FINDINGS');
        this.hasEditAndViewAlertPermission = this.scorecardMarkReviewedService.checkAlertPermission(this.userService);

        this.tpoId = this.lenderInfo.identifier.tpoId;
        this.lenderId = this.userService.profile.organization.id;
        this.findingCategoryCode = this.uiRouterGlobals.params.sType;
        this.findingId = this.uiRouterGlobals.params.sid;

        this.sref = this.scorecardFindingService.determineHistorySref(this.isCCM);
        this.srefParams = this.scorecardFindingService.determineHistorySrefParams(
            this.tpoId,
            this.findingCategoryCode,
            this.findingId,
        );

        this.loadFinding();
    }

    async loadFinding(): Promise<void> {
        this.findingIsLoading = true;
        try {
            const finding: ScorecardFinding = await this.scorecardFindingService.retrieveFinding(this.tpoId, this.findingId);
            this.detailRows = this.scorecardFindingService.annotateFindingAndSplitDetailsIntoRows(finding);

            this.processFinding(finding);
        } finally {
            this.findingIsLoading = false;
        }
    }

    async save(formValue: AddEditFindingData): Promise<void> {
        this.savingFinding = true;

        try {
            const finding: ScorecardFinding = await this.scorecardService.editFinding(
                this.tpoId,
                this.findingId,
                formValue
            ).toPromise();

            this.processFinding(finding);
            this.saveSuccessful$.next();
        } catch({ error: { message }, message: httpError }) {
            this.serverErrorMessage$.next(message);
        } finally {
            this.savingFinding = false;
        }
    }

    setMarkingReviewed(markingFindingAsReviewed: boolean): void {
        this.markingFindingAsReviewed = markingFindingAsReviewed;
    }

    private processFinding(finding: ScorecardFinding): void {
        if (!finding.supportingDocuments) {
            finding.supportingDocuments = [];
        }

        this.finding$.next(finding);
    }
}
