<h2>Locations</h2>

<div class="rows-bordered remote-resource" [class.resolved]="!resolving">
	<div class="row-fluid center-columns header">
		<div class="coldp-sm-6">
			<b>Branch</b>
		</div>
		<div class="coldp-sm-3">
			<b>NMLS ID</b>
		</div>
		<div class="coldp-sm-6">
			<b>Location</b>
		</div>
		<div class="coldp-sm-4">
			<b>Status</b>
		</div>
		<div class="coldp-sm-4">
			<b>Assigned</b>
		</div>
		<div class="coldp-sm-1"></div>
	</div>
	<div
		*ngFor="let item of list | async; index as i"
		class="row-fluid center-columns"
		[ngClass]="{ 'remote-resource': item.loading, 'highlighted': item.editing }"
	>
		<div class="coldp-sm-6 relative">
			<span
				*ngIf="item.removed"
				class="glyphicon glyphicon-exclamation-sign text-danger item-removed"
				placement="top"
				tooltip="This record was removed from NMLS"
			></span>
			{{item.name}}
		</div>
		<div class="coldp-sm-3">
			{{item.nmlsId}}
		</div>
		<div class="coldp-sm-6">
			{{item.location}}
		</div>
		<div class="coldp-sm-4">
			<span *ngIf="(!item.approvalStatus && !item.editing) || item.removed">&ndash;</span>
			<editable-component
				class="w100"
				*ngIf="!item.removed"
				[editable]="item.editing"
			>
				<ng-template #editTemplate>
					<ng-select
							[(ngModel)]="item.approvalStatus"
							[items]="availableStatuses"
							[clearable]="false"
							[disabled]="!canEditStatus || !channelInfo?.isLocationApprovalStatusEditable"
							bindLabel="name"
							placeholder="Select status"
					></ng-select>
				</ng-template>
				<ng-template #viewTemplate>
					<span
						*ngIf="item.approvalStatus as status"
						class="text-smaller label block label-{{relationStatusColors[status.id] || 'default'}}"
					>
						{{status.name}}
					</span>
				</ng-template>
			</editable-component>
		</div>
		<div class="coldp-sm-4">
			<editable-component
				class="w100"
				[view]="item.assigned?.personalName || 'Unassigned'"
				[editable]="item.editing"
			>
				<ng-template #editTemplate>
					<ng-select
						[items]="accountExecutives | async"
						[ngModel]="{ id: item.assigned?.id || -1, fullName: item.assigned?.personalName || 'Unassigned' }"
						(ngModelChange)="assignedChange(item, $any($event))"
						[clearable]="false"
						[disabled]="!canEditAssigment"
						bindLabel="fullName"
						placeholder="Select assigned"
					></ng-select>
				</ng-template>
			</editable-component>
		</div>
		<div class="coldp-sm-1">
			<button
					*ngIf="!item.removed && item.editing"
					class="btn btn-tertiary btn-icon"
					(click)="save(item, i)"
					type="button"
					tabindex="-1"
			>
				<span class="glyphicon glyphicon-ok text-muted"></span>
			</button>
			<button
				*ngIf="!item.removed && ((channelInfo?.isLocationApprovalStatusEditable && canEditStatus) || canEditAssigment)"
				class="btn btn-tertiary btn-icon btn-mr10"
				(click)="item.editing ? cancel(item, i) : edit(item)"
				type="button"
				tabindex="-1"
			>
				<span class="glyphicon glyphicon-{{item.editing ? 'remove' : 'pencil'}} text-muted"></span>
			</button>
			<button
					*ngIf="!item.removed && !canEditStatus && !canEditAssigment"
					tooltip="You don't have permissions"
					placement="bottom"
					class="btn btn-tertiary btn-icon btn-mr10"
					type="button"
					tabindex="-1"
			>
				<span class="glyphicon glyphicon-pencil text-muted"></span>
			</button>
		</div>
	</div>
	<div class="row-fluid" *ngIf="!resolving && !(list | async)?.length && !noMatches">
		<div class="col-md-12 text-center text-bigger text-muted va-m">No locations</div>
	</div>
	<br>
	<paginator></paginator>
</div>