import { isFunction } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Please enter a valid number';

type NumberTemplateStringFn = (value: string | number | null) => string;

export function NumberValidator(messageTemplate: string | NumberTemplateStringFn = '', length?: number): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
        const regex = length ? new RegExp(`^\\d{${length}}$`) : /^[0-9.]+$/;
        const isValid = !value || !!`${value}`.replace(/\W/g, '').match(regex);
        const message = isFunction(messageTemplate) ? messageTemplate(value) : messageTemplate;

        return isValid ? null : {
            num: {
                value,
                message: message || defaultMessage,
            },
        };
    };
}
