import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';

const ClientDocumentChannelEditForm = new RealmFormGroup({
	isRequired: new RealmFormControl(
		'isRequired',
		{label: 'New'},
		Validators.required,
	),
	isRequiredForRenewal: new RealmFormControl(
		'isRequiredForRenewal',
		{label: 'Renewal'},
		Validators.required,
	),
});

export { ClientDocumentChannelEditForm };
