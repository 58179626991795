<question-new
	[(list)]="list"
	(listChange)="calcSkipLogic()"
	(editing)="isCreating = $event"
	(onListSaveLoaded)="_setLoaded($event)"
	[disabled]="!!editId"
	[hasOwnLoader]="false"
	[availableDocuments]="availableDocuments"
></question-new>
<div class="questionnaire-list" [ngClass]="{ 'remote-resource': hasOwnLoader, 'resolved': list.$resolved, 'curtain': isCreating }">
	<div
		*ngFor="let item of list; let first = first; let last = last; let index = index"
		class="question-container flex-row align-top gap"
		[class.curtain]="editId && editId !== item.id"
		[class.edit]="editId === item.id"
		[class.non-reachable]="skipLogic && !skipLogic?.reachableQuestions[index]"
	>
		<move-question-controls
			*ngIf="user.can('MANAGE_GLOBAL_QUESTIONNAIRE')"
			[class.curtain]="editId === item.id"
			[showUp]="!first"
			[showDown]="!last"
			(moveUp)="moveUp(item)"
			(moveDown)="moveDown(item)"
		></move-question-controls>

		<div class="question-number" *ngIf="item._number">
			{{item._number}}.
		</div>

		<questionnaire-list-item
			class="flex"
			[item]="item"
			[skipLogic]="skipLogic"
			[availableDocuments]="availableDocuments"
			(edit)="toggleEdit(item, $event)"
			(update)="update(item, $event)"
			(remove)="checkIsQuestionRemovable(item)"
		></questionnaire-list-item>
	</div>
</div>
