<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">&times;</button>
	<h4 class="modal-title">Remove Category</h4>
</div>

<div class="modal-body remote-resource" [class.resolved]="!resolving">
	<form [formGroup]="form" id="removeBankCategoryForm" (onValidSubmit)="confirm()">
		<form-errors [form]="form"></form-errors>

		<p>Category <b>{{category.name}}</b> contains <b>{{category.questionCount}} question(s)</b>. Confirm category removal and move all questions to:</p>

		<div class="form-group row" has-error>
			<div class="col-sm-6">
				<ng-select
					formControlName="destinationCategoryId"
					[items]="destinationCategories"
					[searchable]="true"
					[clearable]="false"
					[multiple]="false"
					placeholder="Select Category"
					bindValue="id"
					bindLabel="name"
				></ng-select>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer" [class.curtain]="resolving">
	<div class="btn-group-lg">
		<input type="submit" form="removeBankCategoryForm" value="Save" class="btn btn-primary" />
		<button type="button" (click)="cancel()" class="btn btn-default">Cancel</button>
	</div>
</div>
