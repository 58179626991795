import { Component, Host } from '@angular/core';

import { NgResource } from 'commons/interfaces';

import { FindingDetail } from 'shared/findings/$findingId/finding.interface';

import { FindingComponent } from '..';

@Component({
	selector: 'finding-post-preview',
	templateUrl: './post-preview.component.html',
})
export class PostPreviewComponent {
	finding: NgResource<FindingDetail>;

	constructor(
		@Host() findingComponent: FindingComponent,
	) {
		this.finding = findingComponent.finding;
	}
}
