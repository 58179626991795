<contacts-import-steps></contacts-import-steps>
<div class="alert alert-warning">
    <span class="glyphicon glyphicon-exclamation-sign"></span> Before proceeding, please review the following requirements.
</div>
<p>
    1. The file types that can be used are .csv, .xls, and .xlsx.<br>
    2. The first row must contain column headers.<br>
    3. NMLS ID and Email are required fields.<br>
</p>
<form class="remote-resource" [class.resolved]="!uploading" [formGroup]="form" (onValidSubmit)="upload()">

    <form-errors [form]="form"></form-errors>

    <uploader
        #uploader
        (select)="onFileSelect($event)"
        [config]="{ accept: '.csv, .xls, .xlsx' }"
        [customTemplate]="selectedFile.value ? selectedTemplate : null"
    >
        <ng-template #selectedTemplate>
            <div class="uploader-container flex-row" (fileDrop)="uploader.select.emit($event)">
                <div class="selected-file flex-row gap">
                    <span class="flex-grow">
                        <span class="c-icon c-icon-file text-muted"></span>
                        {{ selectedFile.value.name }}
                    </span>

                    <label class="btn btn-secondary required">
                        Update
                        <input
                            type="file"
                            (input)="uploader.onFileInput($event)"
                            [multiple]="uploader.config.multiple"
                            [accept]="uploader.config.accept"
                        >
                    </label>
                </div>
            </div>
        </ng-template>
    </uploader>

    <div class="text-right">
        <button type="submit" [disabled]="form.invalid" class="btn btn-primary">Next</button>
        <a class="btn btn-secondary" (click)="cancel()">Cancel</a>
    </div>
</form>
