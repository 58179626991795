<div class="modal-content remote-resource" [class.resolved]="!resolving">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close bold" (click)="close()">&times;</button>
        <div class="modal-title">
            {{notificationsType}} Notifications
            -
            <span class="text-light">{{channelName}}</span>
        </div>
    </div>
    <div class="modal-body">
        <form-errors [form]="lenderReviewForm"></form-errors>
        <form-errors [form]="submissionForm"></form-errors>
        <form [formGroup]="submissionForm" (onValidSubmit)="save()">
            <div class="col-sm-6">
                <div class="notification-header">
                    {{notificationsType}}s
                    -
                    <span class="text-light">Submission</span>
                </div>
                <div [isEditable]="isEditing">
                    <div view>
                        <p *ngIf="submissionNotification.accountEx">
                            <span class="glyphicon glyphicon-envelope text-light"></span> Assigned
                        </p>
                        <p *ngIf="submissionNotification.emailEnabled">
                            <span class="glyphicon glyphicon-envelope text-light"></span> {{submissionNotification.email}}
                        </p>
                        <div *ngIf="!resolving">
                            <p *ngFor="let user of submissionNotification.users">
                                <span class="glyphicon glyphicon-envelope text-light"></span> {{findByUserId(user).personalName}}
                            </p>
                            <p *ngIf="isSubmissionEmptyAndDisabled()">
                                <b>OFF</b>
                            </p>
                        </div>
                    </div>
                    <div edit>
                        <p>
                            <input
                                formControlName="accountEx"
                                type="checkbox">
                            Assigned
                        </p>
                        <p>
                            <input
                                formControlName="emailEnabled"
                                type="checkbox"
                                (change)="toggleLeftEmail()"
                            >
                            Email
                        </p>
                        <p *ngIf="showSubmissionEmail" class="form-group" has-error>
                            <span class="text-light">Enter User</span>
                            <span class="text-light pull-right">(max of 1)</span>
                            <input
                                formControlName="email"
                                type="email"
                                placeholder="Add Email"
                                class="form-control"
                            >
                        </p>
                        <button class="btn btn-primary" *ngIf="!addingSubmissionUser" (click)="toggleLeftAddingUser()">
                            Add User
                        </button>
                        <span class="text-light" *ngIf="addingSubmissionUser">Select Users</span>
                        <div class="row">
                            <div class="col-sm-9" *ngIf="addingSubmissionUser">
                                <ng-select
                                    (change)="addLeftUser($event)"
                                    [items]="users"
                                    bindValue="id"
                                    bindLabel="personalName"
                                    placeholder="Select username"
                                ></ng-select>
                            </div>
                            <div class="col-sm-3" *ngIf="addingSubmissionUser">
                                <a (click)="toggleLeftAddingUser()">Cancel</a>
                            </div>
                        </div>
                        <br />
                        <div *ngIf="!resolving">
                            <p *ngFor="let user of submissionForm.controls['users'].value">
                                <span>{{findByUserId(user).personalName}} </span>
                                <a (click)="removeLeftUser(user)">Remove</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <form [formGroup]="lenderReviewForm" (onValidSubmit)="save()">
            <div class="col-sm-6">
                <div class="notification-header">
                    {{notificationsType}}s
                    -
                    <span class="text-light">Lender Review</span>
                </div>
                <div [isEditable]="isEditing">
                    <div view>
                        <p *ngIf="lenderReviewNotification.accountEx">
                            <span class="glyphicon glyphicon-envelope text-light"></span> Assigned
                        </p>
                        <p *ngIf="lenderReviewNotification.emailEnabled">
                            <span class="glyphicon glyphicon-envelope text-light"></span> {{lenderReviewNotification.email}}
                        </p>
                        <div *ngIf="!resolving">
                            <p *ngFor="let user of lenderReviewNotification.users">
                                <span class="glyphicon glyphicon-envelope text-light"></span> {{findByUserId(user).personalName}}
                            </p>
                            <p *ngIf="isLenderReviewEmptyAndDisabled()">
                                <b>OFF</b>
                            </p>
                        </div>
                    </div>
                    <div edit>
                        <p>
                            <input
                                formControlName="accountEx"
                                type="checkbox">
                            Assigned
                        </p>
                        <p>
                            <input
                                formControlName="emailEnabled"
                                type="checkbox"
                                (change)="toggleRightEmail()"
                            >
                            Email
                        </p>
                        <p *ngIf="showLenderReviewEmail" class="form-group" has-error>
                            <span class="text-light">Enter User</span>
                            <span class="text-light pull-right">(max of 1)</span>
                            <input
                                formControlName="email"
                                type="email"
                                placeholder="Add Email"
                                class="form-control"
                            >
                        </p>
                        <button class="btn btn-primary" *ngIf="!addingLenderReviewUser" (click)="toggleRightAddingUser()">
                            Add User
                        </button>
                        <span class="text-light" *ngIf="addingLenderReviewUser">Select Users</span>
                        <div class="row">
                            <div class="col-sm-9" *ngIf="addingLenderReviewUser">
                                <ng-select
                                    (change)="addRightUser($event)"
                                    [items]="users"
                                    bindValue="id"
                                    bindLabel="personalName"
                                    placeholder="Select username"
                                ></ng-select>
                            </div>
                            <div class="col-sm-3" *ngIf="addingLenderReviewUser">
                                <a (click)="toggleRightAddingUser()">Cancel</a>
                            </div>
                        </div>
                        <br />
                        <div *ngIf="!resolving">
                            <p *ngFor="let user of lenderReviewForm.controls['users'].value">
                                <span>{{findByUserId(user).personalName}} </span>
                                <a (click)="removeRightUser(user)">Remove</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div style="clear:both">&nbsp;</div>
    <div class="modal-footer" *ngIf="canEditNotifications">
        <div [isEditable]="isEditing">
            <div edit>
                <button class="btn btn-default" (click)="toggleEditing()">
                    Cancel
                </button>
                <button class="btn btn-primary" type="submit" (click)="save()">
                    Save
                </button>
            </div>
            <div view>
                <button class="btn btn-primary" (click)="toggleEditing()">
                    <span class="glyphicon glyphicon-pencil"></span> Edit
                </button>
            </div>
        </div>

    </div>
</div>
