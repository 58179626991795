import { get } from 'lodash';
import { Directive, OnDestroy, Input, AfterContentInit, ElementRef } from '@angular/core';

import { RealmFormControl} from 'commons/forms';

import { RecurlyService } from './abstract-recurly-service.class';

@Directive({
	selector: '[recurlyError]',
})
export class RecurlyErrorContainer implements AfterContentInit, OnDestroy {
	@Input('recurlyError') formControl: RealmFormControl;

	public invalid: boolean = true;
	public touched: boolean = false;
	public fieldName: string;
	public recHandler: (changes) => any;

	constructor(public recurly: RecurlyService, public elementRef: ElementRef) {
		this.recHandler = (changes) => {
			this.changeHandler(changes);
		};
	}

	ngAfterContentInit() {
		this.formControl.setValidators(() => {
			return !this.invalid ? null : {recurly: {}};
		});
		this.fieldName = this.elementRef.nativeElement.querySelector('[data-recurly]').getAttribute('data-recurly');
		this.recurly.on('change', this.recHandler);
	}

	changeHandler(changes) {
		const field = get(changes.fields, this.fieldName, {});
		const formField: RealmFormControl = this.formControl;
		this.invalid = !formField.disabled && !field.valid;
		formField.setValue(field.empty ? null : 'CARD');

		if (!field.focus && this.touched) {
			formField.markAsTouched();
			formField.updateValueAndValidity({ onlySelf: false, emitEvent: true });
		}

		this.touched = this.touched || field.focus;
	}

	ngOnDestroy(): void {
		this.recurly.off('change', this.recHandler);
	}
}
