import { Component, forwardRef, OnInit, Input } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';
import { PagedData } from 'commons/services/http';

import {
    AccountInformationService,
    AccountInformationHistory,
    AccountInformation,
} from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: './history.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => AccountHistoryListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => AccountHistoryListComponent) },
    ],
})
export class AccountHistoryListComponent extends NewPagedListComponent<AccountInformationHistory> {
    @Input('lenderInfo') lenderInfo: AccountInformation;
    static listName = 'historyList' as const;

    constructor(
        router: UIRouter,
        public accountInformation: AccountInformationService,
    ) {
        super(router);
    }

    protected loadList(params: ListParams): Observable<PagedData<AccountInformationHistory[]>> {
        return this.accountInformation.getAccountInformationHistory(this.lenderInfo.nmlsId, params);
    }
}
