import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';
import { NgResourceArray } from 'commons/interfaces';

import {
	QuestionType,
	Question,
	QuestionnaireDocumentAvailable,
	QuestionBankCategory,
} from 'lender/questionnaires/manage/questionnaires.interface';

import { SkipLogic } from './get-skip-logic';

@Component({
	selector: 'questionnaire-list-item',
	templateUrl: './item.component.html',
})
export class QuestionnaireListItemComponent {
	user: UserProfile;
	QuestionType = QuestionType;
	@Input() item: Question;
	@Input() skipLogic: SkipLogic | null = null;
	@Input() availableDocuments: NgResourceArray<QuestionnaireDocumentAvailable> = [];
	// Indicates special type of question: Bank question.
	// Result: disabled skip logic, documents functionality, show "In Use" column
	@Input() isBankQuestion = false;
	@Input() categories: NgResourceArray<QuestionBankCategory> = [];
	@Input() showInUseLink = false;
	@Input() updateError: ServerErrorResponse;
	@Input() closeOnUpdateCall = true;

	@Output('edit') _edit = new EventEmitter<boolean>();
	@Output('update') _update = new EventEmitter<Partial<Question>>();
	@Output('remove') _remove = new EventEmitter<void>();
	@Output('showInUse') _showInUse = new EventEmitter<void>();

	edit: boolean;

	constructor(
		userService: UserService,
	) {
		this.user = userService.profile;
	}

	toggleEdit(edit = !this.edit): void {
		this.edit = edit;
		this._edit.emit(edit);
	}

	update = (update: Partial<Question>): void => {
		if (this.closeOnUpdateCall) {
			this.toggleEdit(false);
		}

		this._update.emit(update);
	};

	remove = (): void => {
		this._remove.emit();
	};

	showInUse = (): void => {
		this._showInUse.emit();
	};

	get isEditDisabled(): boolean {
		return !!this.item.questionBankQuestionId && ![
			QuestionType.SINGLE_SELECT,
			QuestionType.MULTI_SELECT,
			QuestionType.YES_NO,
		].includes(this.item.questionType);
	}
}
