<ng-container *ngIf="hasViewPermissions; then validPermissions; else invalidPermissions"></ng-container>
<ng-template #validPermissions>
	<div class="remote-resource" [class.resolved]="details.$resolved">
		<ng-container *ngIf="editing; then editHeader; else viewHeader"></ng-container>
		<ng-template #viewHeader>
			<div class="gray-head">
				<h2>
					<div class="flex-row gap">
						<span class="va-m" *ngIf="details.id && hasManagePermissions && (User.isComergence || !details.isDeactivatedByCCM)">
							<toggle
								[ngModel]="details.status.code"
								[values]="{true: 'A', false: 'I'}"
								(toggle)="toggleStatus()"
							></toggle>
						</span>
						<span class="va-m flex-shrink ellipsis">{{ details.name }}</span>
						<span class="text-bigger va-m" *ngIf="details.status">
							<span class="label" [ngClass]="details.isDeactivatedByCCM ? [ 'label-default' ] : [ triggerStatuses[details.status.code].class ]">{{details.status.label || '&ndash;'}}</span>
						</span>
						<span class="va-m" style="width: 21px; height: 21px;" *ngIf="details.isDefault">
							<span class="comergence-icon" tooltip="Default Trigger" placement="top"></span>
						</span>
						<a *ngIf="!details.isDefault" class="btn btn-tertiary btn-icon va-m" uiSref=".history">
							<span class="c-icon c-icon-back-in-time medium-icon"></span>
						</a>
					</div>
				</h2>
			</div>
		</ng-template>
		<ng-template #editHeader>
			<h2>
				<span>{{ isNew ? (isCloning ? 'Clone' : 'Add') : 'Edit' }} Trigger</span>
				<smHelpLink [url]="helpLink" *ngIf="User.isTpo"></smHelpLink>
			</h2>
		</ng-template>

		<section>
			<form [formGroup]="form" (onValidSubmit)="save()">
				<div *ngIf="!editing">
					<div class="pull-right">
						<a class="btn btn-default" uiSref=".clone" *ngIf="details.id && hasManagePermissions">Clone</a>
						<a class="btn btn-primary"
							  *ngIf="details.id && hasManagePermissions && (User.isComergence || !details.isDefault)"
							  (click)="enterEditing()"
						><span class="glyphicon glyphicon-pencil"></span> Edit</a>
					</div>

					<h3 class="flex-grow">Preview</h3>
				</div>

				<form-errors [form]="form"></form-errors>

				<div *ngIf="editing" class="rows-striped">
					<div class="row">
						<label class="col-sm-2 control-label required">Trigger Name</label>
						<div class="col-sm-8">
							<input
								formControlName="name"
								placeholder="Enter Trigger Name"
								class="form-control"
								maxlength="250"
							/>
						</div>
					</div>
					<br>
				</div>

				<div class="row-fluid" *ngIf="!editing" formArrayName="andExpressions">
					<div class="col-md-4" *ngFor="let words of details.andExpressions; index as i">
						<strong>{{ i ? 'And must' : 'Must' }} include</strong> one of keywords
						<word-editor [formControlName]="i" [editable]="false"></word-editor>
					</div>
				</div>

				<div class="row-fluid" *ngIf="editing" formArrayName="andExpressions">
					<div class="col-md-4" *ngFor="let words of andExpressions.controls; index as i">
						<strong>{{ i ? 'And must' : 'Must' }} include</strong> one of keywords
						<word-editor [formControlName]="i" [editable]="true" (update)="generatePreviewPreview()"></word-editor>
					</div>
				</div>
				<br>

				<div class="rows-striped" *ngIf="preview">
					<div class="row">
						<div class="col-md-12 control-value" [innerHTML]="preview"></div>
					</div>
				</div>

				<div *ngIf="editing" class="text-right">
					<br>
					<button type="submit" class="btn btn-primary" [disabled]="!canSave">Save</button>
					<span class="btn btn-secondary" (click)="cancelEditing()">Cancel</span>
				</div>
			</form>
		</section>
	</div>
</ng-template>
<ng-template #invalidPermissions>
	<no-permissions title="Triggers">
		<img src="/static/images/promo/sm-get-started/Icon_settings_off.svg" alt="Triggers"/>
	</no-permissions>
</ng-template>
