import { isFunction } from 'lodash';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const defaultMessage = 'Please enter a valid number';

type NumberTemplateStringFn = (value: string | number | null) => string;

export function WholeNumberValidator(messageTemplate: string | NumberTemplateStringFn = ''): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
        const isValid = !value || !!`${value}`.match(/^\d+$/);
        const message = isFunction(messageTemplate) ? messageTemplate(value) : messageTemplate;

        return isValid ? null : {
            num: {
                value,
                message: message || defaultMessage,
            },
        };
    };
}
