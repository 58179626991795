import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Observable, ReplaySubject, defer, of } from 'rxjs';
import { concatMap, map, shareReplay, publishReplay, refCount } from 'rxjs/operators';

import { HttpErrorCallback, HttpSuccessCallback } from 'commons/interfaces';
import { ActivePackage, PACKAGE_FEATURE, SmPackageService } from 'tpo/social-media/sm-package.service';
import { RealmHttpClient } from 'commons/services/http';

declare let apiPath: string;
declare let UPGRADE_STATS_PROJECT: string;

export type LenderOrganizationType = ('TPO' | 'TPO_LIMITED');
export type InvestorOrganizationType = ('LENDER');
export type OrganizationType = (LenderOrganizationType | 'COMERGENCE' | 'LO' | 'CUSTOMER');

export interface GenericOrganization {
    id: number;
    organizationId: number;
    organizationType: OrganizationType;
    appliedForChannelId?: number;
    nmlsId?: string;
    name?: string;
    ownershipVerificationRequired?: boolean;
    type?: OrganizationType; // This must be an alias set client-side?
}

// TODO: Eventually tease apart LenderOrganization, InvestorOrganization, etc.
export type Organization = GenericOrganization;

export interface UserProfile {
    id: number;
    isTpo: boolean;
    isLender: boolean;
    isCustomer: boolean;
    isComergence: boolean;
    isPublicAccess: boolean;
    contactId: number;
    can: (permission) => any;
    organization: Organization;
    firstName: string;
    lastName: string;
    email: string;
    crmEnabled: boolean;
    reportingEnabled?: boolean;
    smEnabled: boolean;
    smGlobalArchiveEnabled: boolean;
    smListingEnabled: boolean;
    smPublisherEnabled: boolean;
    hasInProgressQuestionnaires: boolean;
    hasSocialComplianceIssue: boolean;
    hasOutstandingRequiredDocuments: boolean;
    ownershipVerificationRequired: boolean;
    permissions: any;
    package?: ActivePackage; //only for lender
    hasFeature?: (feature: PACKAGE_FEATURE) => boolean;
    nextRequiredScreen?: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    profile$: ReplaySubject<UserProfile> = new ReplaySubject<UserProfile>();
    profile: UserProfile;
    authenticated = false;
    public login$ = new ReplaySubject<OrganizationType | null>();
    public logout$ = new ReplaySubject<OrganizationType | null>();
    private session$: Observable<UserProfile>;
    private cachedUser: Observable<UserProfile>;
    private cachedUserTO: ReturnType<typeof setTimeout>;

    constructor(
        private http: RealmHttpClient,
        private stateService: StateService,
        private packageService: SmPackageService,
    ) {
        this.session$ = defer(() => {
            if (!this.cachedUser) {
                this.setUserCache(this.requestUser());
            }

            return this.cachedUser;
        });
    }

    setUserCache(requestedUser: Observable<UserProfile>) {
        this.cachedUser = this.getCachedUser(requestedUser);
        this.cachedUserTO = setTimeout(() => {
            this.clearUserCache();
        }, 10000);
        return this.cachedUser;
    }

    clearUserCache(): void {
        clearTimeout(this.cachedUserTO);
        this.cachedUser = undefined;
    }

    getCachedUser(requestedUser: Observable<UserProfile>): Observable<UserProfile> {
        return requestedUser.pipe(
            /** Following two lines ensures that in case of http error, result will be replayed for subsequent subscribers instead of requested again **/
            publishReplay(1),
            refCount(),
            concatMap(user => {
                const { organization: { id: tpoId, type } } = user;

                if (type === 'TPO') {
                    // Injecting package info for lender user into the session
                    return this.packageService.getActivePackage(tpoId).pipe(
                        map(pkg => ({ ...user, package: pkg })),
                    );
                }

                return of({ ...user, package: undefined });
            }),
            shareReplay(1),
        );
    }

    requestUser(): Observable<UserProfile> {
        return this.http.request<UserProfile>('get', `${apiPath}/sessions/current`);
    }

    private setUser(user: UserProfile) {
        this.update(user);

        if (!this.authenticated) {
            this.authenticated = true;
        }
    }

    getUser(success?: HttpSuccessCallback<UserProfile>, error?: HttpErrorCallback, force: boolean = false) {
        if (force) {
            this.clearUserCache();
        }

        const $promise: Promise<UserProfile> = this.session$.toPromise();

        $promise.then(user => this.setUser(user), () => {
            this.authenticated = false;
        });
        $promise.then(success, this.legacyErrorCB(error));
        return { $promise };
    }

    update(user) {
        this.profile = user;

        Object.assign(
            this.profile,
            {
                can(permission) {
                    return this.permissions.includes(permission);
                },
                isComergence: user.organization.type === 'COMERGENCE',
                isLender: user.activeServices && user.activeServices.includes('INVESTOR'),
                isCustomer: user.organization.type === 'CUSTOMER',
                isTpo: user.organization.type === 'TPO',
                isPublicAccess: user.organization.type === 'TPO_LIMITED',
                ...(user.organization.type === 'TPO' ? {
                    hasFeature(feature: PACKAGE_FEATURE) {
                        return this.package.has(feature);
                    },
                    smEnabled: true,
                    smGlobalArchiveEnabled: user.package?.has(PACKAGE_FEATURE.COMPLIANCE),
                    smPublisherEnabled: user.package?.has(PACKAGE_FEATURE.MARKETING),
                    smListingEnabled: user.package?.has(PACKAGE_FEATURE.LISTINGS),
                } : {}),
            },
        );

        this.profile$.next(this.profile);
    }

    doLogout = () => this.http.request<unknown>('post', `${apiPath}/logout`).toPromise();

    logout(success?: HttpSuccessCallback<UserProfile>, error?: HttpErrorCallback) {
        const $promise = this.doLogout();
        const { type } = this.profile.organization;

        $promise.then(() => {
            this.gracefulClear();
            this.authenticated = false;
            this.logout$.next(type);
        });

        $promise.then(success, this.legacyErrorCB(error));
        return { $promise };
    }

    private gracefulClear() {
        const user = {
            organization: {}, // not null for compatibility
            permissions: [], // not null for compatibility
            package: null,
            isComergence: false,
            isLender: false,
            isCustomer: false,
            isTpo: false,
            isPublicAccess: false,
            can: () => (false),
            hasFeature: () => (false),
        } as unknown as UserProfile;
        this.session$ = of(user).pipe(shareReplay(1));
        this.profile = user;
    }

    setNextScreen(screen: string) {
        if (this.profile?.organization.id) {
            this.profile.nextRequiredScreen = screen;
        }
    }

    die(success?: HttpSuccessCallback<UserProfile>, error?: HttpErrorCallback){
        this.authenticated = false;
        this.gracefulClear();

        return this.doLogout().then((...args) => {
            if (success) success.apply(this, args);
        }, (...args) => {
            if(error) error.apply(this, args);
        });
    }

    private legacyErrorCB(cb) {
        return (errorResponse) => {

            cb && cb({
                ...errorResponse,
                data: errorResponse.error,
            });
        }
    }

    public redirectToLogin(url?: string) {
        window.location.href = `/b2c${(url ? '?path=' + encodeURIComponent(url) : '')}`;
    }
}
