import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import {
	GlobalNotificationsService,
	GlobalNotificationType,
} from 'global-elements/notication-center/notifications.service';

import { NotifyVerificationService } from './notify-verification.service';

@Component({
	templateUrl: './notify-ownership-verification-modal.component.html',
})

export class NotifyOwnershipVerificationModalComponent {
	resolving: boolean = false;
	// TODO: check if it is needed
	// used in src/main/webapp/src/tpo/applications/list/applications-list.component.ts
	channelId?: number;

	constructor(
		public modalRef: BsModalRef,
		public verificationService: NotifyVerificationService,
		private readonly notificationsService: GlobalNotificationsService,
	) {
	}

	verify(): void {
		this.resolving = true;
		this.verificationService.verify().$promise
			.then(() => {
				this.notificationsService.add({
					type: GlobalNotificationType.POSITIVE,
					message: 'Verification email has been sent',
				});
				this.modalRef.hide();
			});
	}
}
