import { Component, Input } from '@angular/core';

import { AlertHistoryItem } from 'lender/alerts/alert.interface';


@Component({
    selector: 'alert-history',
	templateUrl: './alert-history.component.html',
})
export class AlertHistoryComponent {
	@Input() alertHistoryItems: AlertHistoryItem[];
}
