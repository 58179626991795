<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" [disabled]="resolving" (click)="cancel()">
		&times;
	</button>
	<div class="flex-row">
		<h4 class="modal-title">Regulations</h4>&nbsp;&nbsp;&nbsp;&nbsp;

		<div class="btn-group" *ngIf="checkListFindingId">
			<button *ngFor="let tab of tabs"
					[ngClass]="tab === activeTab ? 'btn btn-primary' : 'btn btn-default'"
					(click)="changeTab(tab)"
			>
				{{ tab }}
			</button>
		</div>
	</div>
</div>
<div class="modal-body remote-resource regulations-body" [class.resolved]="!resolving">
	<div *ngIf="!resolving && regulations.length" class="regulations-entry-holder rows-bordered">
		<ng-container *ngFor="let regulation of regulations">
			<div class="row-fluid header">
				<div class="col-sm-12">{{ regulation.groupName }}</div>
			</div>
			<div class="row-fluid regulation-entry">
				<ul>
					<li *ngFor="let finding of regulation.entries">
						<ng-container [ngSwitch]="finding.isAuthorized">
							<span *ngSwitchCase="false"
								  tooltip="Not Authorized"
								  container="body"
								  placement="top"
								  class="glyphicon glyphicon-remove text-danger"></span>
							<span *ngSwitchCase="true"
								  tooltip="Authorized"
								  container="body"
								  placement="top"
								  class="glyphicon glyphicon-ok text-success"></span>
							<span *ngSwitchDefault class="text-muted font-bold">&#8226;</span>
							&nbsp;
						</ng-container>

						{{ finding.type }}
					</li>
				</ul>
			</div>
		</ng-container>
	</div>

	<div *ngIf="!resolving && !regulations.length" class="text-center text-muted text-bigger">
		<br>
		No data
	</div>
</div>
