import { Injectable } from '@angular/core';

import { AbstractIssueStatus } from '../../abstractIssueStatus';
import { ListingsStatusResourceService } from '.';

@Injectable()
export class ListingAlertsIssueStatus extends AbstractIssueStatus {
	constructor(
		private listingsStatusResourceService: ListingsStatusResourceService,
	) {
		super();
	}

	protected async updateValue(): Promise<boolean> {
		return this.listingsStatusResourceService.getAlerts().$promise
			.then(({ exclamation }) => exclamation);
	}
}
