import { ColumnConfig } from 'commons/mixins';

export const columnsConfig: ColumnConfig[] = [
	{
		id: 'statusReason',
		name: 'Status Reason',
	},
	{
		id: 'statusDate',
		name: 'Status Date',
		isDefault: true,
	},
	{
		id: 'location',
		name: 'Location',
		isDefault: true,
	},
	{
		id: 'accountExecutive',
		name: 'Assigned',
		isDefault: true,
	},
	{
		id: 'renewalDate',
		name: 'Renewal Date',
	},
	{
		id: 'phone',
		name: 'Phone',
	},
	{
		id: 'clientUID',
		name: 'Client UID',
	},
	{
		id: 'businessEntity',
		name: 'Business Entity',
	},
	{
		id: 'stateOfInc',
		name: 'State of Inc',
	},
	{
		id: 'compFax',
		name: 'Comp Fax',
	},
	{
		id: 'primaryFederalRegulator',
		name: 'Primary Federal Regulator',
	},
	{
		id: 'registrationStatus',
		name: 'Registration Status',
	},
	{
		id: 'fiscalYearEnd',
		name: 'Fiscal Year End',
	},
	{
		id: 'businessTax',
		name: 'Business Tax',
	},
	{
		id: 'dateFormed',
		name: 'Date Formed',
	},
	{
		id: 'mersId',
		name: 'MERS ID',
	},
	{
		id: 'fdicNcuaCert',
		name: 'FDIC/NCUA Cert',
	},
];
