import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from 'commons/components/components.module';

import { SmConnectButtonComponent } from './sm-connect-button.component';
import { GmbConnectorAdapter } from './connectors/gmb-connector/gmb-connector.adapter';
import { FbConnectorAdapter } from './connectors/fb-connector/fb-connector.adapter';
import { GmbLocationsModalComponent } from './gmb-locations-modal';


@NgModule({
  declarations: [SmConnectButtonComponent, GmbLocationsModalComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
  ],
  providers: [GmbConnectorAdapter, FbConnectorAdapter],
  exports: [SmConnectButtonComponent]
})
export class SmConnectButtonModule { }
