import { Component, OnInit } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { ChannelCardDetail } from 'lender/manage-channels/manage-channel-card.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ManageChannelService } from 'lender/manage-channels/manage-channel.service';
import { PhoneValidator } from 'commons/validators';

@Component({
    templateUrl: 'screen-applicant-toggle-modal.component.html',
})
export class ScreenApplicantToggleModalComponent implements OnInit {
    screenApplicantInfo: ChannelCardDetail;
    callbackOnCancel: () => void;
    callbackOnSave: (channelId: number) => void;
    channelId: number;
    isEditing: boolean;
    canManageChannelSettings: boolean;
    screenApplicantForm = new RealmFormGroup({
        name: new RealmFormControl(
            'name',
            {
                label: 'Contact Name',
            },
            Validators.required,
        ),
        phone: new RealmFormControl(
            'phone',
            {
                label: 'Phone',
            },
           [
                Validators.required,
                Validators.maxLength(10),
                PhoneValidator(),
           ],
        ),
        email: new RealmFormControl(
            'email',
            {
                label: 'Email',
            },
            [
                Validators.required,
                Validators.email,
            ],
        ),
        webSite: new RealmFormControl(
            'webSite',
            {
                label: 'Website',
            },
            Validators.required,
        ),
        description: new RealmFormControl(
            'description',
            {
                label: 'Screen Message',
            },
            Validators.required,
        ),
        channelId: new RealmFormControl(
            'channelId',
            {
                label: 'channel ID',
            },
        ),
    });

    resolving: boolean;
    isApplicationToggle: boolean;

    constructor(
        private readonly modalRef: BsModalRef,
        private readonly manageChannelService: ManageChannelService,
    ) {
    }

    ngOnInit() {
        this.setFormValue();
        this.isApplicationToggle = this.isEditing;
    }

    close() {
        this.callbackOnCancel();
        this.modalRef.hide();
    }

    cancel() {
        if(this.isApplicationToggle) {
            this.callbackOnCancel();
            this.modalRef.hide();
        }
        else {
            this.setEditMode(false);
            this.screenApplicantForm.reset();
            this.setFormValue();
        }
    }

    async submit() {
        try {
            const channelScreeningInfo = this.screenApplicantForm.value;
            this.screenApplicantInfo.channelSettings.contact = {...channelScreeningInfo};
            this.screenApplicantInfo.channelSettings.webSite = channelScreeningInfo.webSite;
            this.resolving = true;

            await this.manageChannelService.updateOrganizationalChannelSettings(
                this.channelId,
                this.screenApplicantInfo.channelSettings
            ).toPromise();

            this.callbackOnSave(this.screenApplicantInfo.channelSettings.contact.channelId);
            this.isEditing = false;
        } catch ({ error: { message }, message: httpError }) {
            this.screenApplicantForm.setServerError({ message: message || httpError });
        } finally {
            this.resolving = false;
        }
    }

    setEditMode(value: boolean) {
        this.isEditing = value;
    }

    getCharsCount() : number {
        return Math.max(0, (this.screenApplicantForm.value?.description?.length ?? 0));
    }

    setFormValue () {
        if(this.screenApplicantInfo) {
            this.screenApplicantForm.patchValue(this.screenApplicantInfo.channelSettings.contact);
            this.screenApplicantForm.get('webSite').patchValue(this.screenApplicantInfo.channelSettings.webSite);
        }
    }
}
