<div class="alerts-modal">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title">Edit Alert Status</h4>
    </div>
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form-errors [form]="findingAlertsForm"></form-errors>
        <form [formGroup]="findingAlertsForm" id="findingAlertsForm" (onValidSubmit)="onConfirm()">
            <label class="text-normal">Please select Alert Status to assign for alert.</label>
            <ng-select
                formControlName="alertStatus"
                [items]="statuses"
                bindValue="id"
                bindLabel="status"
                required>
            </ng-select>
        </form>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="submit" form="findingAlertsForm" class="btn btn-primary">Yes</button>
            <button type="button" (click)="close()" class="btn btn-default">No</button>
        </div>
    </div>
</div>
