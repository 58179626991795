<div class="gray-head">
    <h2>Register a New Account</h2>
</div>
<div class="col-md-12 va-m" *ngIf="onPageOne">
    <form [formGroup]="registrationForm" id="registrationForm" (onValidSubmit)="goNext()">
    <form-errors [form]="registrationForm"></form-errors>
        <h3>Individual Information</h3>
        <div class="row-fluid">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('firstName')}}</label>
            </div>
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('lastName')}}</label>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-8">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="text" name="firstName" formControlName="firstName" class="form-control" maxlength="50" tabindex="3" />
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="text" name="lastName" formControlName="lastName" maxlength="50" class="form-control" tabindex="3" />
                    </div>
                </div>
            </div>
        </div>
        <div></div>
        <div class="row-fluid">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('email')}}</label>
            </div>
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('phone')}}</label>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-8">
                <div class="form-group row" has-error>
                    <div class="col-md-8"[class.has-error]="emailHasError">
                        <input type="text" name="email" formControlName="email" class="form-control" maxlength="100" tabindex="3" (change)="validateEmail($event)" placeholder="john@example.com"/>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="text" name="phone" formControlName="phone" maxlength="10" class="form-control" tabindex="3" placeholder="xxxxxxxxxx"/>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <h3>Company Information</h3>
        <div class="row">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('nmlsId')}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row" has-error>
                    <div class="col-md-8" [class.has-error]="nmlsHasError">
                        <input
                            type="text"
                            name="nmlsId"
                            formControlName="nmlsId"
                            maxlength="75"
                            class="form-control"
                            tabindex="3"
                            (keyup)="clearCompanyName()"
                            (paste)="clearCompanyName()"
                            (change)="validateNMLSId($event.target.value)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div *ngIf="companyInformation" class="rows-striped">
                <div class="row-fluid">
                    <div class="row-fluid new-registration">
                        <div class="col-sm-6">
                            <span class="text-right text-light">Company Name</span>
                        </div>
                        <div class="col-sm-6">
                            <span class="text-right text-light">Business Entity</span>
                        </div>
                    </div>
                    <div class="row-fluid new-registration">
                        <div class="col-md-6">
                            <span class="text-black text-right">{{companyInformation.companyName}}</span>
                        </div>
                        <div class="col-md-6">
                            <span class="text-black text-right">{{companyInformation.businessEntity}}</span>
                        </div>
                    </div>
                    <br>
                    <div class="row-fluid new-registration">
                        <div class="col-md-6">
                            <span class="text-light">Address</span>
                        </div>
                        <div class="col-md-2">
                            <span class="text-light">City</span>
                        </div>
                        <div class="col-md-2">
                            <span class="text-light">State</span>
                        </div>
                        <div class="col-md-2">
                            <span class="text-light">Zip</span>
                        </div>
                    </div>
                    <div class="row-fluid new-registration">
                        <div class="col-md-6">
                            <span class="text-black text-right">{{companyInformation.companyAddress}}</span>
                        </div>
                        <div class="col-md-2">
                            <span class="text-black">{{companyInformation.city}}</span>
                        </div>
                        <div class="col-md-2">
                            <span class="text-black">{{companyInformation.state}}</span>
                        </div>
                        <div class="col-md-2">
                            <span class="text-black">{{companyInformation.zip}}</span>
                        </div>
                    </div>
                </div>
            <hr>
        </div>
        <h3>Confirm Your Information</h3>
        <div class="row-fluid">
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('confirmEmail')}}</label>
            </div>
            <div class="col-sm-6">
                <label class="control-label text-left required">{{registrationForm.getFieldLabel('companyName')}}</label>
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-8">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="text" name="confirmEmail" formControlName="confirmEmail" maxlength="100" class="form-control" tabindex="3" (paste)="disablePaste($event)"/>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group row" has-error>
                    <div class="col-md-8">
                        <input type="text" name="companyName" formControlName="companyName" maxlength="100" class="form-control" tabindex="3" (paste)="disablePaste($event)"/>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="pull-right">
            <div>
                <button class="btn btn-primary" [disabled]="registrationForm.invalid" type="submit">Next</button>
                <button class="btn btn-default" type="button" (click)="cancelRegistration()">Cancel</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!onPageOne">
    <registration-password-creation
        [registrationFormInformation]="registrationForm.value"
        [passwordInformation]="passwordInformation"
        (goBackEvent)="goBack($event)">
    </registration-password-creation>
</div>
