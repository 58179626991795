<h3 class="flex-row gap">
    <span>Audit New NMLS IDs</span>
    <span class="flex-grow">
        <toggle
            [ngModel]="settings?.auditNewNmlsIds.enabled"
            [ngModelOptions]="{standalone: true}"
            [loading]="loading"
            [disabled]="!toggleEnabled"
            [tooltip]="permissionTooltipText"
            (ngModelChange)="toggleSection($event)"
            (click)="toggleClicked()"
        ></toggle>
    </span>
</h3>
