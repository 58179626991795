<div class="entity-fields-details">
	<back-bread class="gray-head flex-head mb15">
		{{item.fieldName}}
	</back-bread>

	<div>
		<h3>Field Information</h3>
		<br>
		<div class="rows-striped">
			<div class="row-fluid">
				<label class="col-md-2 control-label">Field Type</label>
				<div class="col-md-a control-value">
					<span class="field-icon field-icon-{{item.controlTypeId}}"></span>
					{{types[item.controlTypeId]}}
				</div>
			</div>

			<div class="row-fluid">
				<label class="col-md-2 control-label">Field Name</label>
				<div class="col-md-6">
					<span class="control-value">{{item.fieldName}}</span>
				</div>
				<div class="col-md-a"></div>
			</div>

			<div class="row-fluid">
				<label class="col-md-2 control-label">Field Description</label>
				<div class="col-md-6">
					<span class="control-value">{{item.fieldDescription}}</span>
				</div>
				<div class="col-md-a"></div>
			</div>
		</div>

		<div class="remote-resource" [class.resolved]="list.$resolved && states.$resolved">
			<h3>
				Field Mapping
				<div class="pull-right">
					<a *ngIf="!editMode"  (click)="edit()" class="btn btn-primary" tabindex=14>
						<span class="glyphicon glyphicon-pencil"></span> Edit
					</a>
					<button *ngIf="editMode" type="submit" form="systemFieldForm" class="btn btn-primary" tabindex=14>
						Save
					</button>
					<a *ngIf="editMode" (click)="cancelEdit()" class="btn btn-default" tabindex=14>Cancel</a>
				</div>
			</h3>
			<br>
			<p class="text-muted">
				You can customize your workflow and track various stages by editing your status values. You will need to
				map your status values to the proper “state” to maintain reporting integrity. You can automate processes
				by mapping to an “Event” trigger.
				<a target="_blank" href="/help/authenticated/lender/crm/custom-settings">
					<i class="glyphicon glyphicon-question-sign"></i> Help
				</a>
			</p>

			<form id="systemFieldForm" name="systemFieldForm" [formGroup]="systemFieldForm" (onValidSubmit)="submitForm()"
			>
				<form-errors [form]="systemFieldForm"></form-errors>

				<div class="rows-bordered">
					<div class="row-fluid header">
						<span class="col-md-3"><b>Status</b></span>
						<span class="col-md-2"><b>Result</b></span>
						<span class="col-md-2"><b>Event</b></span>
						<span class="col-md-3"></span>
						<span class="col-md-2"><b *ngIf="editMode && statusesFormArray.length > 1">Order</b></span>
					</div>

					<div *ngFor="let group of statusesFormArray.controls; index as i; first as isFirst; last as isLast"
						class="row-fluid"
					>
						<ng-container [formGroup]="group">
							<span class="col-md-3 form-group" [class.has-error]="hasError(i,'name')">
								<editable-component [editable]="editMode" [view]="group.value.name">
									<ng-template #editTemplate>
										<input formControlName="name"
											type="text"
											class="form-control"
											[maxlength]="maxNameLength"
											tabindex="1"
										>
									</ng-template>
								</editable-component>
							</span>

							<span class="col-md-2 form-group" [class.has-error]="hasError(i,'stateId')">
								<editable-component *ngIf="list.$resolved" [editable]="editMode">
									<ng-template #viewTemplate>
										<span class="text-bigger state-badge">
											<span class="label label-{{stateColor[list[i].stateId]}}">
												{{getStateEntity(list[i].stateId)?.name}}
											</span>
										</span>
									</ng-template>
									<ng-template #editTemplate>
										<ng-select
											formControlName="stateId"
											[items]="states.$promise | async"
											bindValue="shortName"
											bindLabel="name"
											placeholder="Select System State"
											[tabindex]="2"
										></ng-select>
									</ng-template>
								</editable-component>
							</span>

							<span class="col-md-2 form-group" [class.has-error]="hasError(i,'event_' + i)">
								<editable-component *ngIf="list.$resolved"
									[editable]="editMode"
									[view]="getEventEntity(group.value['event_' + i])?.name"
								>
									<ng-template #editTemplate>
										<ng-select
											[formControlName]="'event_' + i"
											[items]="events.$promise | async"
											[clearable]="false"
											(change)="setState(i)"
											bindValue="id"
											bindLabel="name"
											placeholder="Select Event"
											[tabindex]="3"
										></ng-select>
									</ng-template>
								</editable-component>
							</span>

							<div class="col-md-3">
								<button *ngIf="editMode"
									class="btn btn-tertiary btn-icon"
									(click)="removeLine(i)"
									type="button"
									tabindex="-1"
								>
									<span class="glyphicon glyphicon-trash"></span>
								</button>
							</div>

							<div class="col-md-2">
								<div *ngIf="editMode" class="common-order-actions arrows">
									<button class="btn btn-tertiary btn-icon btn-ml2"
										[class.hide-by-default]="isFirst"
										(click)="move(i, -1)"
										type="button"
										tabindex="-1"
									>
										<span class="c-icon c-icon-arrow-down icon-reverse text-bigger"></span>
									</button>

									<button class="btn btn-tertiary btn-icon btn-ml2"
										[class.hide-by-default]="isLast"
										(click)="move(i, +1)"
										type="button"
										tabindex="-1"
									>
										<span class="c-icon c-icon-arrow-down text-bigger"></span>
									</button>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="row-fluid" *ngIf="!statusesFormArray.length">
						<span class="col-md-12 text-muted text-center text-bigger text-weak">No records added</span>
					</div>
					<div class="row-fluid no-border" *ngIf="editMode">
						<span class="col-md-2">
							<a class="btn btn-primary" (click)="addFormGroup()">Add New Line</a>
						</span>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
