import { Component, Input, OnInit } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { CrmSettings } from 'comergence/customers/services/customer-services/crm-settings.interface';
import { InvestorMarketingService } from 'comergence/customers/services/customer-services/investor-marketing-service.service';
import { StateService, UIRouter, UIRouterGlobals } from '@uirouter/core';
import { UserInformation } from 'comergence/customers/services/customer-services/user-information.interface';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { Observable } from 'rxjs';


@Component({
    templateUrl: './investor-marketing-visibility.component.html',
    selector: 'investor-marketing-visibility-form',
})
export class InvestorMarketingVisibilityComponent implements OnInit {

    private user: UserProfile;
    userList: Observable<UserInformation[]>;
    resolving: boolean;
    canManageCustomers: boolean;
    editing: boolean;
    isNew: boolean;
    isEditable: boolean;

    @Input() crmSettings: CrmSettings;
    @Input() tpoStatusCode: string;

    organizationId: number;

    visibilityForm = new RealmFormGroup({
        enabled: new RealmFormControl(
            'enabled',
            {
                label: 'Visibility',
                updateOn: 'change',
            },
            Validators.required,
        ),
        designatedUsers: new RealmFormControl(
            'designatedUsers',
            {},
        ),
    })

    constructor(
        private routerGlobals: UIRouterGlobals,
        protected router: UIRouter,
        private investorMarketingService: InvestorMarketingService,
        private userService: UserService,
        private stateService: StateService,
    ) {
        this.user = this.userService.profile;
        this.canManageCustomers = this.user?.can('CCM_MANAGE_CUSTOMERS');
        this.organizationId = routerGlobals.params.organizationId;
        this.isNew = stateService.includes('**.services.crm.new');
        this.isEditable = this.isNew || this.editing;
    }

    async ngOnInit() {
        this.visibilityForm.patchValue(this.crmSettings.designatedOptions);
        this.userList = this.investorMarketingService.getUsers(this.organizationId);
    }

    setEditing() {
        this.editing = true;
        this.isEditable = this.isNew || this.editing;
    }

    cancelEdit() {
        this.editing = false;
        this.isEditable = this.isNew || this.editing;
        this.visibilityForm.reset();
        this.visibilityForm.patchValue(this.crmSettings.designatedOptions);
    }

    async save() {
        const newSettings = this.crmSettings
        newSettings.designatedOptions = this.visibilityForm.value;
        if(this.stateService.is('customers.:customerType.:organizationId.services.crm.new')) {
            try {
                this.resolving = true;
                this.crmSettings = await this.investorMarketingService.addNewCrmSettings(this.organizationId, newSettings).toPromise();
            } catch(e) {
                this.visibilityForm.setServerError(e);
            } finally {
                this.resolving = false;
                this.stateService.go('customers.:customerType.:organizationId.services.crm');
            }
        } else {
            try {
                this.resolving = true;
                this.crmSettings = await this.investorMarketingService.saveCrmSettings(this.organizationId, newSettings).toPromise();
                this.editing = false;
                this.isEditable = this.isNew || this.editing;
            } catch(e) {
                this.visibilityForm.setServerError(e);
            } finally {
                this.resolving = false;
            }
        }
    }

    compareUsers(user1: UserInformation, user2: UserInformation) {
        return user1.id == user2.id;
    }

}
