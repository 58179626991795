import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class SmOrderService {
	public pagedResource: any;
	public resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const $resource = injector.get('$injector').get('$resource');
		const defaultParams = {tpoId: User.profile.organization.id};
		const basePath = `${apiPath}/tpos/:tpoId/social-media-compliance/order`;
		const contactsPath = `${basePath}/contacts`;

		this.pagedResource = PagedResource(contactsPath, defaultParams, {
			contacts: {
				method: 'get',
				isArray: true,
			},
		});

		this.resource = $resource(`${basePath}`, defaultParams, {
			save: {
				method: 'post',
			},
			getPrice: {
				url: `${basePath}/price`,
				method: 'post',
			},
			getAutoclose: {
				url: `${basePath}/autoCloseStatuses`,
				method: 'get',
				isArray: true,
			},
			getBranches: {
				url: `${contactsPath}/branches`,
				method: 'get',
				isArray: true,
			},
		});
	}

	contacts = (...args) => this.pagedResource.contacts(...args);
	save = (...args) => this.resource.save(...args);
	getPrice = (...args) => this.resource.getPrice(...args);
	getAutoclose = (...args) => this.resource.getAutoclose(...args);
	getBranches = (...args) => this.resource.getBranches(...args);
}
