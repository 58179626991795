import { Component, Input } from '@angular/core';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';

export enum UserCode {
    LOCKED = 'L',
    ACTIVE = 'A',
    INACTIVE = 'I',
}

@Component({
    selector: 'contact-role',
    templateUrl: './contact-role.component.html',
})
export class ContactRoleComponent {
    @Input() contact: FullTpoContact;
    UserCode = UserCode;

     constructor() {
     }
}
