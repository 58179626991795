import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import {
	SocialMediaNetworkConfig,
	socialMediaNetworksConfig,
	SocialMediaNetworkIconConfig,
} from '../';

@Component({
	selector: 'sm-network-icon',
	templateUrl: './sm-network-icon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaNetworkIconComponent {
	@Input() config: SocialMediaNetworkIconConfig;
	@Input() className: string | string[];
	@Input() showTooltip = true;
	@HostBinding('class.minified') @Input() minified = false;
	@HostBinding('class.show-title') @Input() showTitle = false;

	get networkCode(): string {
		const {
			networkCode,
			source,
		} = this.config;
		switch (networkCode) {
			case 'FBI':
				return 'FB';
			case 'WS':
				switch (source) {
					case 'googlesearch':
						return 'G';
					case 'bingsearch':
						return 'B';
					case 'bingsearch-googlesearch':
						return 'G';
					default:
						return networkCode;
				}
			default:
				return networkCode;
		}
	}

	set networkConfig(value) {
		this._networkConfig = value;
	}
	get networkConfig() {
		return this._networkConfig || socialMediaNetworksConfig[this.networkCode] || socialMediaNetworksConfig.OT;
	}
	private _networkConfig: SocialMediaNetworkConfig;

	get isSVGIcon(): boolean {
		return [ 'YLP', 'ZLW' ].indexOf(this.config?.networkCode) > -1;
	}

	get showBadge(): boolean {
		return !!this.networkConfig.badgeClassName;
	}

	// prefix class names to make it more specific and not intersect with existing class names
	// FIXME: replace with a proper implementation (RM-24608)
	private _classNamePrefix = 'sm-network-icon';
	iconContainerClassName = `${this._classNamePrefix}-container`;
	private _iconClassName = `${this._classNamePrefix}-icon`;
	private _iconBadgeClassName = `${this._classNamePrefix}-badge`;
	titleClassName = `${this._classNamePrefix}-title`;

	get iconClassName(): string[] {
		return [
			this._iconClassName,
			...[].concat(this.networkConfig.className),
			...[].concat(this.className),
		].filter(Boolean);
	}

	get iconBadgeClassName(): string[] {
		return [
			this._iconBadgeClassName,
			...[].concat(this.networkConfig.badgeClassName),
		].filter(Boolean);
	}

	get title(): string {
		return this.config.title || this.networkConfig.title;
	}
}
