import { ValidatorFn, Validators } from '@angular/forms';
import { RealmFormControl } from 'commons/forms';
import { ContactListOption, FilterOperationFieldType } from '../../../investor-crm-contact-list.interface';


export type ValueType = (FilterOperationFieldType | 'EMPTY');

export class ValueFormControl extends RealmFormControl {
    private readonly numOnly: RegExp = new RegExp('^[0-9]+$');

    valueType: ValueType = 'EMPTY';
    valueOptions: ContactListOption[] = [];

    get shouldDisplay(): boolean {
        return (this.valueType !== 'EMPTY');
    }

    get isDropdown(): boolean {
        return (this.valueType === 'CATEGORY');
    }

    get isFreeText(): boolean {
        return (
            (this.valueType === 'FREE_TEXT') ||
            this.isNumber
        );
    }

    get isNumber(): boolean {
        return (
            (this.valueType === 'NUMBER') ||
            (this.valueType === 'PERCENT')
        );
    }

    get isDate(): boolean {
        return (this.valueType === 'DATE');
    }

    setTypeFromOperation(operation: ContactListOption): void {
        if (!operation || operation.id.toLocaleLowerCase().includes('empty')) {
            this.valueType = 'EMPTY';
            this.setValue(null);

            this.setValidators([]);
            this.updateValueAndValidity();
        } else {
            this.valueType = operation.key;

            const validators: ValidatorFn[] = [];
            if (this.isNumber) {
                validators.push(Validators.required, Validators.pattern(this.numOnly));
            } else if (this.shouldDisplay) {
                validators.push(Validators.required);
            }

            this.setValidators(validators);
            this.updateValueAndValidity();
        }
    }

    setValueOptions(valueOptions: ContactListOption[]): void {
        this.valueOptions = valueOptions;
    }
}
