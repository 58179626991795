import { Component, forwardRef, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';


import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { ListComponent } from 'commons/components/list';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import {
    ProspectsService,
} from 'lender/prospects/prospects.service';
import { ProspectHistory } from 'lender/prospects/prospects.interface';


@Component({
    templateUrl: './prospect-history.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ProspectsHistoryListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ProspectsHistoryListComponent) },
    ],
})
export class ProspectsHistoryListComponent extends NewPagedListComponent<ProspectHistory> implements OnInit {
    static listName = 'prospectsHistoryList';

    private User: UserProfile;
    public prospectId: number;

    constructor(
        router: UIRouter,
        userService: UserService,
        public prospectsService: ProspectsService,
    ) {
        super(router);
        this.User = userService.profile;
        this.prospectId = router.globals.params.id;
    }

    protected loadList(params: ListParams): Observable<PagedData<ProspectHistory[]>> {
        return this.prospectsService.getHistoryList(this.prospectId, params);
    }


}
