<div class="lender-documents">
	<div class="gray-head flex-head lender-documnets">
		<h2>Global Documents</h2>
		<div class="col-min">
			<ul class="tab-menu">
				<ng-container *ngFor="let item of tabs">
					<li uiSrefActive="active">
						<a uiSref="{{item.sref}}"><b>{{item.title}}</b></a>
						<span class="icon-alert" *ngIf="item.hasAlert | async"></span>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>

	<ui-view></ui-view>
</div>
