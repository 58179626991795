const PostsTypeConfig: PostsTypeMap = new Map([
	[
		'drafts',
		{
			id: 'drafts',
			resourceId: 'DRAFT',
			title: 'Drafts',
			icon: 'c-icon c-icon-pencil',
			columns: [
				{
					id: 'updatedDate',
					title: 'Last Updated Date',
				},
				{
					id: 'updatedBy',
					title: 'Updated by',
				},
			],
		},
	],
	[
		'scheduled',
		{
			id: 'scheduled',
			resourceId: 'SCHEDULED',
			title: 'Scheduled',
			icon: 'c-icon c-icon-calendar-empty',
			columns: [
				{
					id: 'scheduledDate',
					title: 'Scheduled date',
				},
				{
					id: 'updatedBy',
					title: 'Scheduled by',
				},
			],
		},
	],
	[
		'posted',
		{
			id: 'posted',
			resourceId: 'SENT',
			title: 'Published',
			icon: 'c-icon c-icon-paper-plane',
			columns: [
				{
					id: 'postedDate',
					title: 'Posted date',
				},
				{
					id: 'updatedBy',
					title: 'Posted by',
				},
			],
		},
	],
]);

export { PostsTypeConfig };

interface PostType {
	id: string;
	resourceId: string;
	title: string;
	icon: string;
	columns: Array<{
		id: string,
		title: string,
	}>;
}

export type PostsTypeMap = Map<string, PostType>;
