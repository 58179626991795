import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ReviewComponent } from '../review.component';
import { Review } from '../../../reviews.interface';
import { ReviewsResourceService } from '../../../reviews-resource.service';

@Component({
	selector: 'zillow-yelp-review',
	templateUrl: './zillow-yelp-review.component.html',
})
export class ZillowYelpReviewComponent extends ReviewComponent {
	@Input() review: Review;

	loading = false;

	constructor(
		public userService: UserService,
		public stateService: StateService,
		public modalService: BsModalService,
		public reviewsResource: ReviewsResourceService,
	) {
		super(userService, stateService, modalService, reviewsResource);
	}
}
