import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { LenderAlertsService } from 'lender/alerts/alerts.service';

import { CommentType } from 'lender/common/interfaces/comment-type.interface';
import { Comment, CreateCommentDTO } from 'lender/common/interfaces/comment.interface';


@Component({
    selector: 'alert-comments',
	templateUrl: './alert-comments.component.html',
})
export class AlertCommentsComponent {
	@Input() lenderId: number;
	@Input() tpoId: string;
	@Input() alertId: number;
	@Input() commentTypes: CommentType[];
	@Input() comments: Comment[];

	@Output() commentAdded: EventEmitter<void> = new EventEmitter();

	isSaving: boolean = false;
	isEditing: boolean = false;

	commentForm = new RealmFormGroup({
        comment: new RealmFormControl(
            'comment',
            {
                label: 'Comment',
            },
            Validators.required,
        ),
        commentType: new RealmFormControl(
            'commentType',
            {
				label: 'Comment Type',
			},
            Validators.required,
        ),
    })

	constructor(
		private readonly alertsService: LenderAlertsService,
	) {
	}

	get typeDescription(): string {
		return this.commentForm.get('commentType').value?.description;
	}

	startEditing(): void {
		// Called from onFocus, so we need this.
		if (this.isEditing) {
			return;
		}

		this.isEditing = true;

		this.commentForm.reset();

		const publicCommentType: CommentType = this.commentTypes.find((ct: CommentType) => ct.code === 'PUBLIC');
		this.commentForm.get('commentType').setValue(publicCommentType);
	}

	cancelEditing(): void {
		this.isEditing = false;

		this.commentForm.reset();
	}

	async saveComment(): Promise<void> {
		const comment: CreateCommentDTO = {
			alertId: `${this.alertId}`,
			comment: this.commentForm.value.comment,
			commentType: this.commentForm.value.commentType.code,
			tpoId: `${this.tpoId}`,
		};

		try {
			this.isSaving = true;

			await this.alertsService.createComment(this.lenderId, this.alertId, comment).toPromise();

			this.cancelEditing();
			this.comments = null;	// Cause the loading indicator to appear again
			this.commentAdded.next();
        } catch ({ error: { message }, message: httpError }) {
            this.commentForm.setServerError({ message: message || httpError });
		} finally {
			this.isSaving = false;
		}
	}
}
