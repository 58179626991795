import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { every, filter, reject, first, map, isArray, isPlainObject, assign } from 'lodash';

import { allowedNetworks } from 'commons/components/sm-icons';
import {
	Attachment,
	AttachmentClasses,
} from 'commons/components/attachments-preview/attachments-preview.interface';
import { NgResourceArray } from 'commons/interfaces';

import { PublisherAttachmentsPreviewService } from './publisher-attachments-previews.service';

@Component({
	selector: 'publisher-attachments-preview',
	templateUrl: './publisher-attachments-previews.component.html',
})
export class PublisherAttachmentsPreviewComponent implements OnInit, OnChanges, OnDestroy {
	@Input() networkCode: allowedNetworks | string;
	@Input() attachments: NgResourceArray<Attachment>;
	@Input() attachmentNgClass?: AttachmentClasses = {};
	@Input() carouselScaling: boolean;

	@Output() onIsRendered = new EventEmitter<boolean>();
	@Output() onAttachmentClick = new EventEmitter<{ attachments, index }>();

	attachmentClasses: { [className: string]: boolean | string | number } | {
		[className: string]: (attachment) => boolean,
	} = {};
	isRendered: boolean = false;
	loadedAttachments: NgResourceArray<Attachment>;

	constructor(
		private previewsService: PublisherAttachmentsPreviewService,
		private cd: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.previewsService.setInstance(this.networkCode, this.init.bind(this));
	}

	ngOnChanges(): void {
		this.init();
	}

	ngOnDestroy(): void {
		this.previewsService.setInstance(this.networkCode, null);
	}

	init(): void {
		this.loadedAttachments = null;
		if (this.attachments?.length) {
			this.customClasses();
			this.isRendered = false;
			const promises = map(this.attachments, async (attachment) => (
				!attachment?.$error && (await attachment?.$promise || attachment)
			));
			Promise.all(promises).then(async (attachments: NgResourceArray<Attachment>) => {
				attachments = filter(attachments);
				this.cd.detectChanges();

				if (this.networkCode === allowedNetworks.INSTAGRAM) {
					attachments = await Promise.all(map(attachments, async (attachment) => {
						if (attachment.type === 'VIDEO') {
							const { selectedThumbnailId,
								thumbnailIds,
								uploadedManuallyThumbnailId,
							} = await this.previewsService.getCachedThumbnails(attachment.id);

							if (selectedThumbnailId === uploadedManuallyThumbnailId) {
								const imageUrl = attachment.imageUrl
									.replace(/thumbnails\/.*/g, `thumbnails/${thumbnailIds[1]}`)
									.replace(/attachments\/.*\/full/g, `attachments/video-thumbnails/${thumbnailIds[1]}`);
								return { ...attachment, imageUrl };
							}
						}
						return attachment;
					}));
					this.cd.detectChanges();
					this.loadedAttachments = reject(attachments, '$error' )
				} else if (every(attachments, { type: 'VIDEO' })) {
					this.loadedAttachments = [ first(attachments) ];
				} else {
					this.loadedAttachments = filter(attachments, { type: 'IMAGE' });
				}

				if (!this.loadedAttachments.length) {
					this.onIsRendered.emit(true);
					this.isRendered = true;
				}
			});
		} else {
			this.onIsRendered.emit(true);
			this.isRendered = true;
		}
	}

	customClasses(): void {
		if (typeof this.attachmentNgClass === 'string') {
			assign(this.attachmentClasses, { [this.attachmentNgClass]: true });
		}

		if (isArray(this.attachmentNgClass)) {
			for (const klass of this.attachmentNgClass) {
				assign(this.attachmentClasses, { [klass]: true });
			}
		}

		if (isPlainObject(this.attachmentNgClass)) {
			assign(this.attachmentClasses, this.attachmentNgClass);
		}
		assign(this.attachmentClasses, {'attachment-video': isVideo});
	}

	isOnlyErrors = (): boolean => every(this.attachments, '$error');

	setIsRendered = (isRendered: boolean): void => {
		this.onIsRendered.emit(isRendered);
		this.isRendered = isRendered;
	}
}
const isVideo = (attachment: Attachment): boolean => attachment?.type.includes('VIDEO');
