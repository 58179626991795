import { Injectable } from '@angular/core';


@Injectable()
export class ListQueryUtilsService {
    objectFor<T>(
        propertyName: string,
        value: T,
        convertToString: boolean = true,
        wrapInArray: boolean = false,
        defaultValue?: T,
    ): any {
        if (!value) {
            // Explicit cast to T because defaultValue could technically be undefined
            value = <T>defaultValue;
        }

        if (value) {
            let finalValue: any = value;
            if (!!convertToString) {
                finalValue = `${value}`;
            }

            if (!!wrapInArray) {
                finalValue = [ finalValue ];
            }

            const object = {
                [propertyName]: finalValue,
            };

            return object;
        }

        return {};
    }
}
