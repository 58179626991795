import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import { EmploymentHistory } from './interfaces/employment-history.interface';
import { MfsFindingSummary } from './interfaces/financial-sanctions.interface';
import { MloName } from './interfaces/mlo-name.interface';
import { OtherTradeName } from './interfaces/other-trade-name.interface';


declare let apiPath;

@Injectable()
export class IndividualNmlsService {
    constructor(private http: RealmHttpClient) {
    }

    // Resources

    getMloName(nmlsId: number): Observable<MloName> {
        return this.http.request(
            'GET',
            `${apiPath}/nmls/individual/${nmlsId}/mlo-name`,
        );
    }

    getEmploymentHistory(nmlsId: number): Observable<EmploymentHistory[]> {
        return this.http.request(
            'GET',
            `${apiPath}/nmls/individual/${nmlsId}/employment/history`,
        );
    }

    getOtherTradeNames(nmlsId: number): Observable<OtherTradeName[]> {
        return this.http.request(
            'GET',
            `${apiPath}/nmls/individual/${nmlsId}/other-trade-names`,
        );
    }

    getMfsFindingSummaries(nmlsId: number): Observable<MfsFindingSummary[]> {
        return this.http.request(
            'GET',
            `${apiPath}/nmls/individual/${nmlsId}/findings/MFS`,
        );
    }
}
