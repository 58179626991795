import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { SocialAccountLink } from '../link.class';
import { AddRSSModalComponent, EditRSSModalComponent } from './modals';

@Component({
	selector: 'manage-rss',
	templateUrl: './manage-rss.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRSSComponent {
	@Input() params: any;
	@Input() link: SocialAccountLink;
	@Input() canManage: boolean;
	@Output() reload = new EventEmitter<any>();

	modalRef: BsModalRef;

	constructor(
		public modalService: BsModalService,
	) {}

	showEditModal = (): void => {
		const initialState = {
			params: this.params,
			link: this.link,
			canDelete: this.canManage,
			onSave: () => {
				this.reload.emit();
			},
		};

		this.modalRef = this.modalService.show(EditRSSModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}

	showAddModal = (): void => {
		const initialState = {
			params: this.params,
			link: this.link,
			onSave: () => {
				this.reload.emit();
			},
		};

		this.modalRef = this.modalService.show(AddRSSModalComponent, {
			initialState,
			class: 'modal-smd modal-new',
		});
	}
}
