<div class="remote-resource" [class.resolved]="list.$resolved">

	<notification></notification>

	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-2">
				<div class="text-muted">Channel</div>
			</div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-2">
				<ng-select
					[(ngModel)]="params.channels"
					(change)="setFilter({ channels: $event })"
					[multiple]="true"
					[items]="filters.channels.$promise | async"
					[loading]="!filters.channels.$resolved"
					bindValue="id"
					bindLabel="name"
					placeholder="All Channels"
				></ng-select>
			</div>
			<div class="col-sm-10 text-right">
				<a
					*ngIf="user?.can('MANAGE_GLOBAL_DOCUMENTS')"
					class="btn btn-primary"
					uiSref="documents.new"
					[uiOptions]="{ inherit: false, reload: true }"
				>
					Add Document
				</a>
			</div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<div class="col-sm-12 text-center va-m">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</div>
		</div>

		<div class="row-fluid no-border" *ngFor="let item of list">
			<lender-global-document
				[document]="item"
			></lender-global-document>
		</div>

		<div class="row-fluid no-border" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-bigger text-muted text-center va-m">
				{{(filtersApplied || filters.q) ? 'No matches found' : 'No documents'}}
			</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
