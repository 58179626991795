import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { ClientLibraryDocument } from '../documents.interface';
import { DocumentComponent } from '../document/document.component';

@Component({
	selector: 'client-library-document',
	templateUrl: './client-library-document.component.html',
})
export class ClientLibraryDocumentComponent<T extends ClientLibraryDocument> extends DocumentComponent<T> {
	@Input('className')
	className?: string | string[];

	@Input()
	downloadLink?: string;

	@Input()
	templateDownloadLink?: string;

	@ContentChild('indications', { static: true })
	indicationsTemplate: TemplateRef<any>;

	@ContentChild('actions', { static: true })
	actionsTemplate?: TemplateRef<any>;

	@Output('upload')
	private _upload = new EventEmitter<FileList>();

	@Output('showHistory')
	private _showHistory = new EventEmitter<void>();

	upload(fileList: FileList): void {
		this._upload.emit(fileList);
	}

	showHistory(): void {
		this._showHistory.emit();
	}
}
