import { DatePipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { chain } from 'lodash';
import { locale } from 'moment';
import { ContactListField, ContactListOption, ContactListSegmentFilterAndClause, FilterOperationFieldType } from '../../../investor-crm-contact-list.interface';
import { ValueFormControl } from './ValueFormControl';


export class AndClauseFormGroup extends RealmFormGroup {
    static readonly FIELD_TYPE_FIELD_NAME: string = 'fieldType';
    static readonly FIELD_NAME_FIELD_NAME: string = 'fieldName';
    static readonly OPERATION_FIELD_NAME: string = 'operation';
    static readonly VALUE_FIELD_NAME: string = 'value';

    private readonly fieldTypeFormControl: RealmFormControl = new RealmFormControl(
        AndClauseFormGroup.FIELD_TYPE_FIELD_NAME,
        {
            label: 'Field Type',
            updateOn: 'change',
        },
        Validators.required,
    );
    private readonly fieldFormControl: RealmFormControl = new RealmFormControl(
        AndClauseFormGroup.FIELD_NAME_FIELD_NAME,
        {
            label: 'Field Name',
            updateOn: 'change',
            disabled: true,
        },
        Validators.required,
    );
    private readonly operationFormControl: RealmFormControl = new RealmFormControl(
        AndClauseFormGroup.OPERATION_FIELD_NAME,
        {
            label: 'Operation',
            updateOn: 'change',
            disabled: true,
        },
        Validators.required,
    );
    private readonly valueFormControl: ValueFormControl = new ValueFormControl(
        AndClauseFormGroup.VALUE_FIELD_NAME,
        {
            label: 'Value',
            updateOn: 'change',
            disabled: true,
        },
    );

    restorationMissingMapping: boolean = false;
    private userAlteredValue: boolean = false;

    constructor(
        private readonly andClauseDto?: ContactListSegmentFilterAndClause
    ) {
        super({});

        this.addControl(AndClauseFormGroup.FIELD_TYPE_FIELD_NAME, this.fieldTypeFormControl);
        this.addControl(AndClauseFormGroup.FIELD_NAME_FIELD_NAME, this.fieldFormControl);
        this.addControl(AndClauseFormGroup.OPERATION_FIELD_NAME, this.operationFormControl);
        this.addControl(AndClauseFormGroup.VALUE_FIELD_NAME, this.valueFormControl);
    }

    getFieldTypeFormControl(): RealmFormControl {
        return this.fieldTypeFormControl;
    }

    getFieldFormControl(): RealmFormControl {
        return this.fieldFormControl;
    }

    getOperationFormControl(): RealmFormControl {
        return this.operationFormControl;
    }

    getValueFormControl(): ValueFormControl {
        return this.valueFormControl;
    }

    get printOutTypeLabel(): string {
        if (this.userAlteredValue && !this.fieldTypeFormControl.value?.title) {
            return '';
        }

        if (this.fieldTypeFormControl.value?.title) {
            return this.fieldTypeFormControl.value.title;
        }

        return (this.andClauseDto?.fieldType || '');
    }

    get printOutFieldLabel(): string {
        if (this.userAlteredValue && !this.fieldFormControl.value?.title) {
            return '';
        }

        if (this.fieldFormControl.value?.title) {
            return this.fieldFormControl.value.title;
        }

        return this.transformFieldName(this.andClauseDto?.fieldName || '');
    }

    get printOutOperationLabel(): string {
        if (this.userAlteredValue && !this.operationFormControl.value?.title) {
            return '';
        }

        if (this.operationFormControl.value?.title) {
            return this.operationFormControl.value.title;
        }

        return this.transformOperation(this.andClauseDto?.operation || '');
    }

    get printOutFieldValue(): string {
        if (this.userAlteredValue && !this.valueFormControl.value) {
            return '';
        }

        if (this.valueFormControl.value) {
            if (this.valueFormControl.isDate) {
                return new DatePipe(locale()).transform(this.valueFormControl.value, 'MM/dd/yyyy');
            }

            return this.valueFormControl.value;
        }

        if (this.andClauseDto && (this.andClauseDto.fieldValue !== undefined)) {
            return this.andClauseDto.fieldValue;
        }

        return '';
    }

    get shouldDisplayValueFormControl(): boolean {
        return this.valueFormControl.shouldDisplay;
    }

    get hasDtoValue(): boolean {
        return ((this.andClauseDto?.fieldValue !== undefined) && (this.andClauseDto?.fieldValue !== null));
    }

    setupFrom(
        andClauseDto: ContactListSegmentFilterAndClause,
        fieldTypesById: Map<string, ContactListOption>,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>,
    ): void {
        this.restoreFieldType(andClauseDto, fieldTypesById);

        const field: ContactListField = this.restoreField(andClauseDto, fieldsById_ByFieldTypeId);
        if (!field) {
            return;
        }

        const operation: ContactListOption = this.restoreOperation(andClauseDto, operationsById_ByTypeId, field);

        this.restoreValue(andClauseDto, field, operation);
    }

    fieldTypeChanged(shouldUpdateRestorationMissingMapping: boolean): void {
        this.updateRestorationMissingMapping(shouldUpdateRestorationMissingMapping);
        this.fieldFormControl.reset();
        this.operationFormControl.reset();
        this.valueFormControl.reset();

        this.operationFormControl.disable();

        this.valueFormControl.disable();
        this.valueFormControl.setTypeFromOperation(this.operationFormControl.value);

        if (this.fieldTypeFormControl.value) {
            this.fieldFormControl.enable();
        } else {
            this.fieldFormControl.disable();
        }
    }

    fieldNameChanged(shouldUpdateRestorationMissingMapping: boolean): void {
        this.updateRestorationMissingMapping(shouldUpdateRestorationMissingMapping);
        this.operationFormControl.reset();
        this.valueFormControl.reset();

        this.valueFormControl.disable();
        this.valueFormControl.setTypeFromOperation(this.operationFormControl.value);

        if (this.fieldFormControl.value) {
            this.operationFormControl.enable();

            this.valueFormControl.setValueOptions(this.fieldFormControl.value.values);
        } else {
            this.operationFormControl.disable();

            this.valueFormControl.setValueOptions([]);
        }
    }

    operationChanged(shouldUpdateRestorationMissingMapping: boolean): void {
        this.updateRestorationMissingMapping(shouldUpdateRestorationMissingMapping);
        this.valueFormControl.reset();

        if (this.operationFormControl.value) {
            this.valueFormControl.enable();
        } else {
            this.valueFormControl.disable();
        }

        this.valueFormControl.setTypeFromOperation(this.operationFormControl.value);
    }

    fieldValueChanged(shouldUpdateRestorationMissingMapping: boolean): void {
        this.updateRestorationMissingMapping(shouldUpdateRestorationMissingMapping);
    }

    transformFieldName(original: string): string {
        const result: string = original.replace(/^CF_/, 'Custom Field ');

        return result;
    }

    transformOperation(original: string): string {
        const result: string =
            chain(original)
            .replace(/_\w$/, '')
            .lowerCase()
            .startCase()
            .value();
        
        return result;
    }

    private restoreFieldType(
        andClauseDto: ContactListSegmentFilterAndClause,
        fieldTypesById: Map<string, ContactListOption>,
    ): void {
        const fieldType: ContactListOption = fieldTypesById.get(andClauseDto.fieldType);
        this.fieldTypeFormControl.setValue(fieldType);
        this.fieldTypeChanged(false);
    }

    private restoreField(
        andClauseDto: ContactListSegmentFilterAndClause,
        fieldsById_ByFieldTypeId: Map<string, Map<string, ContactListField>>,
    ): ContactListField {
        const field: ContactListField = fieldsById_ByFieldTypeId
            .get(andClauseDto.fieldType)
            .get(andClauseDto.fieldName);
        
        if (!field) {
            this.restorationMissingMapping = true;

            return null;
        }

        this.fieldFormControl.setValue(field);
        this.fieldNameChanged(false);
        
        return field;
    }

    private restoreOperation(
        andClauseDto: ContactListSegmentFilterAndClause,
        operationsById_ByTypeId: Map<FilterOperationFieldType, Map<string, ContactListOption>>,
        field: ContactListField,
    ): ContactListOption {
        const operation: ContactListOption = operationsById_ByTypeId
            .get(field.typeId)
            .get(andClauseDto.operation);
        this.operationFormControl.setValue(operation);
        this.operationChanged(false);

        return operation;
    }

    private restoreValue(
        andClauseDto: ContactListSegmentFilterAndClause,
        field: ContactListField,
        operation: ContactListOption,
    ): void {
        if (andClauseDto.fieldValue) {
            if ((field.valuesById.size > 0) && !field.valuesById.has(andClauseDto.fieldValue)) {
                this.restorationMissingMapping = true;
            } else {
                this.valueFormControl.setValue(andClauseDto.fieldValue);
            }
        }

        this.valueFormControl.setTypeFromOperation(operation);
        this.valueFormControl.setValueOptions(this.fieldFormControl.value.values);
    }

    private updateRestorationMissingMapping(shouldUpdate: boolean): void {
        if (shouldUpdate) {
            this.restorationMissingMapping = false;
            this.userAlteredValue = true;
        }
    }
}
