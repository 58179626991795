<ng-container *ngIf="contact; then contactLink; else noContact"></ng-container>
<ng-template #contactLink>
	<ng-container [ngSwitch]="contact?.contactType">
		<ng-container *ngSwitchCase="['company', 'individual', 'originator'].includes(contact.contactType) && contact.contactType">
			<a [uiSref]="state.sref"
			   [uiParams]="state.params"
			   target="_blank"
			>{{contact.name}}</a>
		</ng-container>
		<ng-container *ngSwitchDefault>
			{{contact.name}}
		</ng-container>
	</ng-container>
</ng-template>
<ng-template #noContact>&ndash;</ng-template>
