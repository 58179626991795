<form
    [formGroup]="form"
    class="remote-resource"
    (onValidSubmit)="formSubmit()"
    [ngClass]="{ curtain: !settings?.auditSchedule.enabled, resolved: !loading }"
>
    <h3 class="flex-row">
        <span class="flex-grow">Selection Pool</span>
        <div *ngIf="toggleEnabled" [isEditable]="editing">
            <ng-container view>
                <a class="btn btn-default" [href]="this.scheduleService.getSelectionPoolFileLink()" target="_blank">Export</a>
                <button
                    class="btn btn-primary"
                    (click)="toEdit()"
                    type="button"
                >Edit</button>
            </ng-container>
            <ng-container edit>
                <button class="btn btn-default" type="button" (click)="cancelEdit()">Cancel</button>
                <button class="btn btn-primary" type="submit">Save</button>
            </ng-container>
        </div>
    </h3>

    <div class="rows-striped">
        <div class="row-fluid" [ngSwitch]="editing">
            <div class="col-md-6 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 va-m control-label">Include All NMLS IDs</label>
                    <div class="col-md-7 control-value">
                        <span *ngSwitchDefault>
                            <span class="glyphicon"
                                  [ngClass]="' glyphicon-' + (settings?.auditSchedule.selectionPool.includeAllNmlsContacts ? 'ok text-success' : 'remove text-danger')"
                                  aria-hidden="true"></span> {{settings?.auditSchedule.selectionPool.includeAllNmlsContacts ? 'ON' : 'OFF'}}
                        </span>
                        <span *ngSwitchCase="true">
                            <label class="radio-inline">
                                <input type="radio" formControlName="includeAllNmlsContacts" [value]="true"> ON
                            </label>
                            <label class="radio-inline">
                                <input type="radio" formControlName="includeAllNmlsContacts" [value]="false"> OFF
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Include Contacts with no NMLS</label>
                    <div class="col-md-7 control-value">
                        <span *ngSwitchDefault>
                            <span class="glyphicon"
                                  [ngClass]="' glyphicon-' + (settings?.auditSchedule.selectionPool.includeNonNmlsContacts ? 'ok text-success' : 'remove text-danger')"
                                  aria-hidden="true"></span> {{settings?.auditSchedule.selectionPool.includeNonNmlsContacts ? 'ON' : 'OFF'}}
                        </span>
                        <span *ngSwitchCase="true">
                            <label class="radio-inline">
                                <input type="radio" formControlName="includeNonNmlsContacts" [value]="true"> ON
                            </label>
                            <label class="radio-inline">
                                <input type="radio" formControlName="includeNonNmlsContacts" [value]="false"> OFF
                            </label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid" *ngIf="hasPermission">
            <div class="col-md-6 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Selection Pool File</label>
                    <div class="col-md-7">
                        <div class="flex-row gap">
                            <button
                                class="btn btn-primary"
                                type="button"
                                (click)="openFileModal()"
                                [disabled]="editing"
                            >
                                Manage
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Manage Individuals</label>
                    <div class="col-md-7">
                        <button
                            class="btn btn-primary"
                            type="button"
                            (click)="manageIndividuals()"
                            [disabled]="editing"
                        >Manage</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-fluid" *ngIf="hasPermission">
            <div class="col-md-6 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Individual NMLS Count ({{settings?.auditSchedule.selectionPool.nmlsContactsCount || 0}})</label>
                    <div class="col-md-7">
                        <button
                            class="btn btn-primary"
                            type="button"
                            (click)="resetSelectionPool(true)"
                            [disabled]="editing">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 form-group">
                <div class="row-fluid">
                    <label class="col-md-5 control-label">Non-NMLS Count ({{settings?.auditSchedule.selectionPool.nonNmlsContactsCount || 0}})</label>
                    <div class="col-md-7">
                        <button
                            class="btn btn-primary"
                            type="button"
                            (click)="resetSelectionPool(false)"
                            [disabled]="editing">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
