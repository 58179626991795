import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { MediaLinkHelper } from './utils/media-link.helper';

import { NgResource, NgResourceArray } from 'commons/interfaces';
import { Comment } from 'commons/components/sm-post/sm-post.interface';

import { DocumentsServiceInterface } from 'shared/supporting-documents/documents.service.interface';
import { linkTemplate } from 'shared/supporting-documents/helpers';
import { FindingPost } from 'shared/findings/findings.interface';

declare let apiPath: string;

export type FindingResource = any;

@Injectable()
export class FindingsService implements DocumentsServiceInterface {
	finding: FindingResource;
	findingHistory: any;
	statuses: any;
	documents: any;
	issues: any;
	remediation: any;
	post: any;
	mediaLinks: any;
	types: any;
	reviews: any;
	comments: any;
	taskCommunication: any;
	findingScreenshot: any;

	private findingDocumentsProps: {
		url: string,
		params: any,
	};

	constructor(
		injector: Injector,
		public userService: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');
		const PagedResource = injector.get('$injector').get('PagedResource');

		const User = userService.profile;


		const basePath = `${apiPath}${User.isTpo || User.isPublicAccess ? '/tpos/:tpoId' : ''}/social-media-compliance`;
		const baseParams = User.isTpo || User.isPublicAccess ? { tpoId: User.organization.id } : {};
		const findingProps = {
			url: `${basePath}/finding/:findingId`,
			params: {
				...baseParams,
				findingId: '@id',
			},
		};
		this.findingDocumentsProps = {
			url: `${findingProps.url}/document/:docId`,
			params: {
				...findingProps.params,
				docId: '@id',
			},
		};
		const findingIssuesProps = {
			url: `${findingProps.url}/issues/:issueId`,
			params: {
				...baseParams,
				issueId: '@id',
			},
		};
		const findingRemediationProps = {
			url: `${findingProps.url}/remediation`,
			params: {
				...findingProps.params,
			},
		};
		const findingPostProps = {
			url: `${basePath}/archive/:smAccountId/:type/:id/:version`,
			params: {
				...baseParams,
			},
		};
		const createFindingProps = {
			params: {
				...findingProps.params,
			},
			method: 'POST',
			headers: { 'Content-Type': undefined },
			transformRequest: (inputData) => {
				const data = new FormData();
				const {
					screenshot,
					...findingFields
				} = inputData;

				// required finding part
				const finding = new Blob([JSON.stringify(findingFields)], {
					type: 'application/json',
				});

				data.append('finding', finding);

				if (!screenshot) {
					return data;
				}

				data.append('screenshot', screenshot);

				return data;
			},
		};
		this.finding = $resource(
			findingProps.url,
			findingProps.params,
			{
				createFromReview: {
					url: `${basePath}/review/:reviewId/finding`,
					...createFindingProps,
				},
				createFromContact: {
					url: `${basePath}/contacts/:contactId/finding`,
					...createFindingProps,
				},
				createFromFeed: {
					url: `${basePath}/global-archive/feed/finding`,
					...createFindingProps,
				},
				get: {
					method: 'GET',
				},
				update: {
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
				updateStatus: {
					method: 'PUT',
					url: `${findingProps.url}/status`,
				},
			},
		);

		this.findingHistory = PagedResource(
			`${findingProps.url}/history`,
			{ ...findingProps.params },
			{
				list: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.statuses = $resource(
			`${basePath}/finding-status`,
			{ ...baseParams },
			{
				list: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.documents = PagedResource(
			this.findingDocumentsProps.url,
			{ ...this.findingDocumentsProps.params },
			{
				list: {
					method: 'GET',
					isArray: true,
				},
				history: {
					url: `${this.findingDocumentsProps.url}/history`,
					method: 'GET',
					isArray: true,
				},
				one: { method: 'get' },
				create: {
					method: 'POST',
					headers: {'Content-Type': undefined},
					transformRequest: ({ file, properties }) => {
						const formData = new FormData();
						formData.append('properties', new Blob([JSON.stringify(properties)], {
							type: 'application/json',
						}));
						if (file) {
							formData.append('file', file);
						}
						return formData;
					},
				},
				update: { method: 'PUT' },
				replace: {
					url: `${this.findingDocumentsProps.url}/file`,
					method: 'POST',
					headers: { 'Content-Type': undefined },
					transformRequest: (item) => {
						const data = new FormData();
						data.append('file', item.file);
						delete item.file;
						return data;
					},
				},
			},
		);

		this.issues = $resource(
			findingIssuesProps.url,
			{ ...findingIssuesProps.params },
			{
				create: {
					method: 'POST',
				},
				update: {
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
				nextNumber: {
					url: `${apiPath}/social-media-compliance/finding/:findingId/issues/next-number`,
					method: 'GET',
					transformResponse: (response) => {
						return { number: response * 1 };
					},
				},
			},
		);

		this.remediation = $resource(
			findingRemediationProps.url,
			{ ...findingRemediationProps.params },
			{
				create: {
					method: 'POST',
				},
				complete: {
					url: `${findingRemediationProps.url}/complete`,
					method: 'POST',
				},
				get: {
					method: 'GET',
				},
				update: {
					method: 'PUT',
				},
			},
		);

		this.post = PagedResource(
			findingPostProps.url,
			{ ...findingPostProps.params },
			{
				get: {
					method: 'GET',
				},
				getComments: {
					method: 'GET',
					url: `${findingPostProps.url}/comments`,
					isArray: true,
				},
			},
		);

		const findingTypePath = `${basePath}/finding-type/:findingIssueTypeId`;
		this.types = $resource(
			findingTypePath,
			{
				...findingPostProps.params,
			},
			{
				issueTypes: {
					method: 'GET',
					isArray: true,
				},
				observations: {
					url: `${findingTypePath}/observations`,
					method: 'GET',
					isArray: true,
				},
				enabledObservations: {
					url: `${basePath}/finding/:findingId/enabled-observations`,
					method: 'GET',
				},
				groups: {
					url: `${basePath}/groups`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		const transformLinks = (reponse) => (
			MediaLinkHelper.processLinkTitles(JSON.parse(reponse))
		);

		// FIXME: migrate to SharedSocialMediaService
		this.mediaLinks = {
			socialMedia: $resource(
				`${apiPath}/social-media/links/:type/:id`,
				{
					type: '@type',
					id: '@id',
				},
				{
					get: {
						method: 'GET',
						isArray: true,
						transformResponse: transformLinks,
					},
				},
			),
			digitalMedia: $resource(
				`${apiPath}/digital-media/all-links/:type/:id`,
				{
					type: '@type',
					id: '@id',
				},
				{
					get: {
						method: 'GET',
						isArray: true,
						transformResponse: transformLinks,
					},
				},
			),
		};

		this.comments = PagedResource(
			`${basePath}/finding/:findingId/comment`,
			{
				...baseParams,
				findingId: '@findingId',
			},
			{
				query: { method: 'get', isArray: true},
				add: { method: 'post' },
			},
		);

		this.findingScreenshot = $resource(
			User.isComergence ?
				`${apiPath}/social-media-compliance/finding/:findingId/screen` :
				`${apiPath}/tpos/:tpoId/social-media-compliance/finding/:findingId/screen`,
			{
				tpoId: User.organization.id,
				findingId: '@findingId',
			},
			{
				create: {
					method: 'post',
					headers: {'Content-Type': undefined},
					transformRequest: function (item) {
						const data = new FormData()
						data.append('screenshot', item);
						return data;
					},
				},
			},
		);

		this.taskCommunication = $resource(
			`${apiPath}/tpos/:tpoId/social-media-compliance/finding/:findingId/remediation/message`,
			{
				tpoId: User.organization.id,
				findingId: '@findingId',
			},
			{
				query: { method: 'get', isArray: true},
				add: { method: 'post' },
			},
		);
	}

	getFindingHistory = (...args) => this.findingHistory.list(...args);
	getDocumentHistory = (...args) => this.documents.history(...args);
	getPost = (...args): NgResource<FindingPost> => this.post.get(...args);
	getPostComments = (...args): NgResourceArray<Comment> => this.post.getComments(...args);
	getDocumentDownloadLink = (params) => {
		return linkTemplate(
			`${this.findingDocumentsProps.url}/file`,
			{ ...this.findingDocumentsProps.params, ...params }
		);
	};
	getDocumentHistoryDownloadLink = (params) => {
		return linkTemplate(
			`${this.findingDocumentsProps.url}/history/:id/file`,
			{ ...this.findingDocumentsProps.params, ...params }
		);
	};

}
