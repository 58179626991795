<div class="remote-resource document-modal-form" [class.resolved]="!saving">
	<div class="modal-header">
		<button type="button" *ngIf="!saving" class="close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<form [formGroup]="assignForm" id="assignForm" (onValidSubmit)="save()" autocomplete="off" role="presentation">
		<div class="modal-body" [class.curtain]="saving">
			<form-errors [form]="assignForm"></form-errors>
            <div *ngIf="isDuplicateRequest" class="alert alert-danger">You are about to request financial documents that have already been uploaded. Select 'Request' to continue.</div>

            <div class="form-group" has-error>
                <label class="control-label required" [class.has-warning]="isDuplicateRequest">
                    {{ assignForm?.getFieldLabel('isAudited') }}
                </label>
                <div class="flex-row gap-l">
                    <label class="radio-inline" [class.has-warning]="isDuplicateRequest">
                        <input
                            type="radio"
                            formControlName="isAudited"
                            [value]="true"
                            (change)="checkForDuplicateRequest()"
                        >
                        Audited
                    </label>
                    <label class="radio-inline" [class.has-warning]="isDuplicateRequest">
                        <input
                            type="radio"
                            formControlName="isAudited"
                            [value]="false"
                            (change)="checkForDuplicateRequest()"
                        >
                        Unaudited
                    </label>
                </div>
            </div>

            <div class="flex-row gap-l">
                <div class="flex-grow form-group" has-error>
                    <label class="control-label required" [class.has-warning]="isDuplicateRequest">
                        {{ assignForm?.getFieldLabel('period') }}
                    </label>
                    <div>
                        <ng-select
                            [class.has-warning-select]="isDuplicateRequest"
                            formControlName="period"
                            [items]="quarters"
                            [clearable]="false"
                            placeholder="Select Period"
                            [tabindex]="2"
                            (change)="checkForDuplicateRequest()"
                        ></ng-select>
                    </div>
                </div>

                <div class="flex-grow form-group" has-error>
                    <label class="control-label required" [class.has-warning]="isDuplicateRequest">
                        {{ assignForm?.getFieldLabel('year') }}
                    </label>
                    <div>
                        <ng-select
                            [class.has-warning-select]="isDuplicateRequest"
                            formControlName="year"
                            [items]="years"
                            [clearable]="false"
                            placeholder="Select Year"
                            [tabindex]="2"
                            (change)="resetPeriod(); checkForDuplicateRequest()"
                        ></ng-select>
                    </div>
                </div>
            </div>

            <notification-users-form
                [form]="assignForm"
                [service]="financialDocumentResourceService"
                [params]="getResourceParams()"
            ></notification-users-form>
		</div>
		<div class="modal-footer" [class.curtain]="saving">
			<div class="text-right">
				<input
					type="submit"
					class="btn btn-primary"
					[value]="'Request'"
					tabindex="7"
				>
				<a (click)="onCancel()" tabindex="8" class="btn btn-default">Cancel</a>
			</div>
		</div>
	</form>
</div>

