import { Injectable, Injector } from '@angular/core';

import { UserService } from '../../angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class AppcodesResourceService {
	resource: any;

	constructor(public injector: Injector, public User: UserService) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		this.resource = PagedResource(`${apiPath}/lenders/${User.profile.organization.id}/application-codes/:applicationCodeId`, {}, {
			get: {method: 'get', isArray: true},
			put: {method: 'put'},
		});
	}

	get = (...args) => this.resource.get(...args);
	put = (...args) => this.resource.put(...args);
	save = (...args) => this.resource.save(...args);
}
