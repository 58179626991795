<div class="rows-bordered remote-resource" [class.resolved]="!connecting">
	<div class="row-fluid">
		<div class="col-sm-9">
			<div class="flex-row gap-m">
				<div class="list-item-image" [bgImage]="selectedItem?.coverImage"></div>
				<div class="flex align-top">
					<ng-select
						[multiple]="false"
						(change)="selectItem($event)"
						[items]="candidates?.$promise | async"
						[loading]="!candidates?.$resolved"
						[searchFn]="search"
						placeholder="Select Listing"
					>
						<ng-template ng-label-tmp let-item="item">
							{{item.businessName}}
						</ng-template>

						<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
							<div class="flex-row gap">
								<sm-network-icon [config]="item" [minified]="true"></sm-network-icon>
								<div class="flex text-wrap">
									<div>{{ item.businessName }}</div>
									<div class="text-muted">{{item | address}}</div>
								</div>
							</div>
						</ng-template>
					</ng-select>
					<p></p>
					<div class="text-muted" *ngIf="selectedItem">{{ selectedItem | address }}</div>
				</div>
				<sm-network-icon [config]="selectedItem"></sm-network-icon>
			</div>
		</div>
		<div class="col-sm-3 va-m text-right">
			<button class="btn btn-primary" (click)="save()" [disabled]="!selectedItem">Save</button>
			<button class="btn btn-secondary" (click)="cancelAdd()">Cancel</button>
		</div>
	</div>
</div>
