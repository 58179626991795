import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
declare let window: Window;

@Injectable({
    providedIn: 'root',
})
export class InspectletService {
    constructor() {
        const { env } = window
        const WID = (env['application_inspectlet-sid'] || '').trim();

        if (environment.production && WID) {
            window.__insp = window.__insp || [];
            window.__insp.push(['wid', WID]);
            const ldinsp = function () {
                if (typeof window.__inspld != 'undefined') return;
                window.__inspld = 1;
                const insp = document.createElement('script');
                insp.type = 'text/javascript';
                insp.async = true;
                insp.id = 'inspsync';
                const date =  Math.floor(new Date().getTime() / 3600000);
                const proto = ('https:' == document.location.protocol ? 'https' : 'http');
                insp.src = `${proto}://cdn.inspectlet.com/inspectlet.js?wid=${WID}&r=${date}`;
                const x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(insp, x);
            };
            setTimeout(ldinsp, 0);
        }
    }
}
