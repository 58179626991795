<div class="modal-header">
    <button *ngIf="!static" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">{{ title }}</h4>
</div>
<ng-container *ngIf="error">
    <div class="modal-body">
        <div class="text-danger">
            {{error == 'Token is expired' ? 'Page you are trying to access has been expired, if you want to setup a new password please use "Forgot Password"' : 'Invalid Link: Page you are trying to access does not exist'}}
        </div>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <a class="btn btn-primary" uiSref="login" (click)="cancel()">Ok</a>
    </div>
</ng-container>
<ng-container *ngIf="!error">
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form id="changeModalForm" [formGroup]="form" (onValidSubmit)="save()">
            <div class="well text-center text-bigger" [innerHTML]="passwordNotification"></div>
            <form-errors [form]="form"></form-errors>
            <div class="row">
                <div class="col-md-10">
                    <div class="row-fluid form-group">
                        <label class="col-sm-5 control-label">Password</label>
                        <div class="col-sm-7">
                            <form-show-password [form]="form" control="password"  maxlength="20"></form-show-password>
                        </div>
                    </div>
                    <div class="row-fluid form-group">
                        <label class="col-sm-5 control-label">Confirm Password</label>
                        <div class="col-sm-7">
                            <form-show-password [form]="form" control="repeatPassword"  maxlength="20"></form-show-password>
                        </div>
                    </div>
                    <div class="row-fluid form-group" *ngIf="resetPolicy && !resetPolicy.pinSet">
                        <label  class="col-sm-5 control-label">Security Pin Code</label>
                        <div class="col-sm-7">
                            <div class="flex-row gap">
                                <form-show-password class="flex-grow" [form]="form" control="pin" maxlength="4"></form-show-password>
                                <span class="glyphicon glyphicon-question-sign text-muted col-sm-2" placement="left"
                                      tooltip="Comergence Support will use your 4 digit pin code to help verify your identity"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <password-requirements-tooltip class="v-align"></password-requirements-tooltip>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <button type="submit" form="changeModalForm" class="btn btn-primary">Save</button>
        <ng-container [ngSwitch]="static">
            <button *ngSwitchCase="false" type="button" (click)="cancel()" class="btn btn-secondary">Cancel</button>
            <a *ngSwitchDefault class="btn btn-secondary" uiSref="login" (click)="cancel()">Cancel</a>
        </ng-container>
    </div>
</ng-container>
