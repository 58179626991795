<ul>
	<ng-container *ngTemplateOutlet="liTemplate; context: { items: items }"></ng-container>
</ul>

<ng-template #liTemplate let-items="items">
	<li *ngFor="let item of items" [ngSwitch]="item.link?.type" class="sidenav-menu-item" [class.submenu]="item.items?.length" [class.active]="isActive(item)">

        <a *ngSwitchCase="LINK_TYPE.ROUTE"
           [uiSref]="item.link?.sref"
           [uiParams]="item.link?.params"
           [uiOptions]="item.link?.options"
           [target]="item.link?.target || ''"
        >
            <ng-container *ngTemplateOutlet="labelTemplate || defaultLabelTemplate; context: { item: item }"></ng-container>
        </a>

        <a *ngSwitchCase="LINK_TYPE.CLICK"
           (click)="item.link.click()"
        >
            <ng-container *ngTemplateOutlet="labelTemplate || defaultLabelTemplate; context: { item: item }"></ng-container>
        </a>

        <a *ngSwitchCase="LINK_TYPE.HREF"
           [href]="item.link.href"
           [target]="item.link?.target || ''"
        >
            <ng-container *ngTemplateOutlet="labelTemplate || defaultLabelTemplate; context: { item: item }"></ng-container>
        </a>

        <span class="not-link" *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="labelTemplate || defaultLabelTemplate; context: { item: item }"></ng-container>
        </span>

		<ul *ngIf="item.items?.length">
			<ng-container *ngTemplateOutlet="liTemplate; context: { items: item.items }"></ng-container>
		</ul>
	</li>
</ng-template>

<ng-template #defaultLabelTemplate let-item="item">
	<span>{{item.title}}</span>
</ng-template>
