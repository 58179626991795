<div class="company-pages-modal">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="onCancel();">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<div class="modal-body remote-resource max-h450" [class.resolved]="!resolving">
		<p>
			{{message}}
		</p>

		<section *ngFor="let group of groups">
			<h5><strong>{{group.title}}s</strong></h5>
			<div class="rows-bordered">
				<div class="row header">
					<label class="col-md-12 horizontal-flex-group sortable">
						<span class="page-select">
							<input type="checkbox" [(ngModel)]="$all[group.type]" (click)="selectAll(group)"/>
						</span>
						<span>{{ group.title }}</span>
					</label>
				</div>
			</div>
			<div class="rows-bordered">
				<ng-container *ngFor="let page of list">
					<label class="row horizontal-flex-group clickable" *ngIf="page.type === group.type">
							<span class="page-select">
								<input [(ngModel)]="page.$selected" type="checkbox" (click)="checkAll(group)" [disabled]="page.previouslyConnected"/>
							</span>
							<sm-icon class="space-bigger" [config]="page"></sm-icon>
							<div class="full-width">
								<span>
									{{page.name}}
								</span><br>
								<span class="text-muted" *ngIf="page.address">
									{{page.address}}
								</span>
							</div>
					</label>
				</ng-container>
			</div>
		</section>


	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="btn-group-lg">
			<button type="button" (click)="onConfirm()" class="btn btn-primary">{{confirmText}}</button>
			<button type="button" (click)="onCancel()" class="btn btn-default">{{cancelText}}</button>
		</div>
	</div>
</div>
