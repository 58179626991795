<ng-container *ngIf="(User?.can('TPO_SMC_VIEW_ALL') && User.smGlobalArchiveEnabled); else noPermissionsSection">
	<div class="archive">
		<div class="filters-rows">
			<div class="row-fluid filter-labels">
				<div class="coldp-sm-5">
					<div class="text-muted">Search</div>
				</div>
				<div class="coldp-sm-5">
					<div class="text-muted">Post Date After</div>
				</div>
				<div class="coldp-sm-5">
					<div class="text-muted">Post Date Before</div>
				</div>
				<div class="coldp-sm-5">
					<div class="text-muted">Entities</div>
				</div>
				<div class="coldp-sm-4"></div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="coldp-sm-5">
					<search-list placeholder="Enter Keyword"></search-list>
				</div>

				<div class="coldp-sm-5">
					<input type="text" class="form-control"
						   bsDatepicker
						   [bsConfig]="bsConfig"
						   [bsValue]="params.postStartDate"
						   [maxDate]="params.postEndDate"
						   (bsValueChange)="setFilter({ postStartDate: $event })"
						   placeholder="mm/dd/yyyy"
					>
				</div>

				<div class="coldp-sm-5">
					<input type="text" class="form-control"
						   bsDatepicker
						   [bsConfig]="bsConfig"
						   [bsValue]="params.postEndDate"
						   [minDate]="params.postStartDate"
						   (bsValueChange)="setFilter({ postEndDate: $event })"
						   placeholder="mm/dd/yyyy"
					>
				</div>

				<div class="coldp-sm-5 archive-entity"
					 [class.curtain]="!filters?.entity">
					<ng-select
						[ngModel]="params.entity"
						(change)="setFilter({ entity: $event })"
						[items]="filters?.entity?.$promise | async"
						bindValue="shortName"
						bindLabel="name"
						[clearable]="false"
						[loading]="!filters?.entity?.$resolved"
						placeholder="All">
					</ng-select>
				</div>
				<div class="coldp-sm-4">
					<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
				</div>
			</div>

			<div [class.hidden]="!moreFilters">
				<div class="row-fluid filter-labels">
					<div class="coldp-sm-5">
						<div class="text-muted">Account Type</div>
					</div>
					<div class="coldp-sm-5">
						<div class="text-muted">Media Content</div>
					</div>
				</div>
				<div class="row-fluid filter-selectors">
					<div class="coldp-sm-5"
						 [class.curtain]="!filters?.source">
						<ng-select
							[ngModel]="params.source"
							(change)="setFilter({ source: $event })"
							[items]="filters?.source?.$promise | async"
							bindValue="id"
							bindLabel="name"
							[clearable]="true"
							[multiple]="true"
							[loading]="!filters?.source?.$resolved"
							placeholder="All Account Type">
						</ng-select>
					</div>
					<div class="coldp-sm-5"
						 [class.curtain]="!filters?.mediaContentType">
						<ng-select
							[ngModel]="params.mediaContentType"
							(change)="setFilter({ mediaContentType: $event })"
							[items]="filters?.mediaContentType?.$promise | async"
							bindValue="shortName"
							bindLabel="name"
							[clearable]="true"
							[multiple]="true"
							[loading]="!filters?.mediaContentType?.$resolved"
							placeholder="All Content">
						</ng-select>
					</div>
					<div class="coldp-sm-4"></div>
				</div>
			</div>
		</div>

		<div class="rows-white post-list">
			<div
				*ngIf="filtersApplied"
				class="row-fluid yellow-bg"
			>
				<div class="col-sm-12 va-m text-center">
					Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results.
				</div>
			</div>

			<div
				*ngIf="resolveCount === 1 && list?.$resolved && !list?.length"
				class="row-fluid"
			>
				<div class="col-sm-12 va-m text-center text-bigger text-muted">
					{{filtersApplied || filters.q ? 'No matches found' : 'No posts'}}
				</div>
			</div>

			<!--@Notice: *ngIf="list?.$resolved" in infinite-scroll related to reloadOnSearch in the router settings,
						with true option scroll sends the request 2 times. Revert this commit if you don't like it.-->
			<div
				*ngIf="!list?.$resolved"
				class="row-fluid loader remote-resource"
			></div>

			<infinite-scroll
				#infiniteScrollComponent
				*ngIf="list?.$resolved"
				[list]="list"
				(onNextBatch)="loadNextPage()"
				[shouldResetList]="shouldResetList"
				[lastPageReached]="lastPageReached"
			>
				<ng-template #entryTemplate let-item>
					<div
						#currentPost
						class="row-fluid post-container remote-resource"
						[class.resolved]="list?.$resolved && !item?.$resolving"
					>
						<div class="post-container-header clearfix">
							<div class="pull-left flex-row gap-m">
								<div class="pull-left flex-row gap-m">
									<ng-container *ngIf="item?.owners as owners">
										<div
											*ngIf="owners[0] as primaryOwner"
											class="primary-owner"
											[class.margined]="primaryOwner?.contactId && primaryOwner?.nmlsId"
										>
											<ng-container *ngIf="primaryOwner?.contactId; else contactLabelTpl">
												<contact-link
													[data]="getContactData(primaryOwner)"
												></contact-link>
											</ng-container>
											<ng-template #contactLabelTpl>
												{{primaryOwner?.name}}
											</ng-template>

											<ng-container *ngIf="primaryOwner?.nmlsId as nmlsId">
												<div class="nmls">
													NMLS ID:
													<ng-container *ngIf="primaryOwner?.contactId; else nmlsLabel">
														<a
															uiSref="nmls.individual.:nmlsId"
															[uiParams]="{ nmlsId: nmlsId }"
														>
															{{ nmlsId }}
														</a>
													</ng-container>
													<ng-template #nmlsLabel>
														{{ nmlsId }}
													</ng-template>
												</div>
											</ng-container>
										</div>

										<a
											*ngIf="owners.length > 1"
											class="owners"
											(click)="showOwners(owners)"
										>
											<span class="c-icon c-icon-users"></span>
											View more
										</a>
									</ng-container>
								</div>
							</div>

							<div class="pull-right flex-row right gap">
									<span
										*ngIf="item.deleted"
										class="label label-default text-smaller"
									>Post Removed</span>

								<span
									*ngIf="item?.findings?.length"
									class="text-danger clickable"
									[popover]="popoverTemplate"
									[popoverContext]="{ findings: item?.findings }"
									[outsideClick]="true"
								>
										{{item?.findings?.length}} {{item?.findings?.length == 1 ? 'finding' : 'findings'}}
									</span>

								<span
									class="c-icon c-icon-back-in-time history-icon"
									(click)="showPostHistory(item)"
								></span>

								<a *ngIf="item.findingId"
								   class="btn btn-danger-wired"
								   uiSref="social-compliance.findings.:actionType.:findingId"
								   [uiParams]="{ findingId: item?.findingId, actionType: 'edit' }"
								>View Finding</a>

								<button
									*ngIf="!item.findingId && item.canAddFinding && User?.can('TPO_SMC_MANAGE_SOCIAL_MEDIA_COMPLIANCE')"
									class="btn btn-primary flex-button"
									(click)="createFinding(item)"
								>Add Finding</button>
							</div>
						</div>

						<sm-post>
							<sm-post-header [post]="item"></sm-post-header>

							<sm-post-message [post]="item"></sm-post-message>

							<sm-post-attachments
								[post]="item"
								(isRendered)="infiniteScrollComponent.updateHeight(item?.id, currentPost)"
							></sm-post-attachments>

							<sm-post-comments
								*ngIf="item?.comments"
								[post]="item"
								[global]="true"
								(onMoreComments)="infiniteScrollComponent.updateHeight($event.id, currentPost)"
							></sm-post-comments>
						</sm-post>
					</div>
				</ng-template>
			</infinite-scroll>
		</div>
	</div>
</ng-container>

<ng-template #noPermissionsSection>
    <archive-no-permissions></archive-no-permissions>
</ng-template>

<ng-template #popoverTemplate let-findings="findings">
	<div class="finding-holder" *ngFor="let finding of findings">
		<span class="label"
			[class.label-success]="finding.status && finding.condition === 'Closed'"
			[class.label-warning]="!finding.status || finding.condition !== 'Closed'"
		>{{finding.status}}</span>
		&nbsp;&nbsp;
		<span>{{finding.statusDate | date:'MM/dd/yyyy '}}</span>
		&nbsp;&nbsp;
		<a
			uiSref="social-compliance.findings.:actionType.:findingId"
			[uiParams]="{ findingId: finding.id, actionType: 'edit' }"
		>View</a>
	</div>
</ng-template>
