import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ScorecardFinding, ScorecardFindingSupportingDocument } from 'shared/due-diligence/scorecard/scorecard-finding.interface';


@Component({
    selector: 'scorecard-finding-details-supporting-documents',
    templateUrl: './scorecard-finding-details-supporting-documents.component.html',
})
export class ScorecardFindingDetailsSupportingDocumentsComponent {
    @Input() tpoId: number;
    @Input() editing: boolean = false;
    @Input() finding: ScorecardFinding;
    @Input() newFiles: File[];
    @Output() oldFileRemoved: EventEmitter<ScorecardFindingSupportingDocument> = new EventEmitter();
    @Output() newFileAdded: EventEmitter<FileList> = new EventEmitter();
    @Output() newFileRemoved: EventEmitter<File> = new EventEmitter();

    removeOldFile(fileToRemove: ScorecardFindingSupportingDocument) {
        this.oldFileRemoved.emit(fileToRemove);
    }

    addNewFile(file: FileList) {
        this.newFileAdded.emit(file);
    }

    removeNewFile(fileToRemove: File) {
        this.newFileRemoved.emit(fileToRemove);
    }
}