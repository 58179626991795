<div *ngIf="form" class="form-group-show-password form-group has-feedback no-margin" [formGroup]="form">
	<input type="password" name="fakepinremembered" class="hidden">
	<input class="form-control"
		[ngClass]="[class]"
		[type]="fieldType"
		[formControlName]="control"
		[maxlength]="maxlength"
		[tabindex]="tabindex"
	>
	<span class="c-icon c-icon-eye form-control-feedback" (click)="toggleType()"></span>
</div>
