import { Component, Input } from '@angular/core';

import { socialMediaNetworksConfig } from 'commons/components/sm-icons';

@Component({
	selector: 'limits',
	templateUrl: './limits.component.html',
})
export class LimitsComponent {
	networksConfig = socialMediaNetworksConfig;
	@Input() textLength: number;
	@Input() accounts: any[];

	scrollToElement(networkCode: string): void {
		const notification = document.querySelector(`#${networkCode}`);
		if (notification) {
			notification.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
		}
	}
}
