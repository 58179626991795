import { Injectable } from "@angular/core";
import { UserProfile } from "angularjs-providers/user.provider";


@Injectable()
export class GlobalSearchRulesService {
    userMayPerformSearch(userProfile: UserProfile): boolean {
        // Explicit guard in case more rules are added later
        if (!userProfile) {
            return false;
        }

        const userIsComergenceWithAccess: boolean = (
            userProfile.isComergence &&
            userProfile.can('CCM_GLOBAL_SEARCH')
        );

        const userMayPerformSearch: boolean = (
            userProfile.isLender ||
            userIsComergenceWithAccess
        );

        return userMayPerformSearch;
    }
}
