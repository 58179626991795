import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient, RealmParams } from 'commons/services/http';
import { Observable } from 'rxjs';
import { AffiliateBusinessTypeInterface, AffiliateInterface } from 'tpo/account/affiliates/affiliate.interface';

declare let apiPath: string;

@Injectable()
export class AffiliatesService {

    constructor(
        private http: RealmHttpClient,
    ) {

    }

    public getAffiliatesList(params): Observable<PagedData<AffiliateInterface[]>> {
        return this.http.pagedRequest<AffiliateInterface[]>(
            'GET',
            `${apiPath}/affiliates`,
            params
        );
    }

    public getAffiliate(tpoId: number, affiliateId: number): Observable<AffiliateInterface> {
        return this.http.request<AffiliateInterface>(
            'GET',
            `${apiPath}/tpos/${tpoId}/affiliates/${affiliateId}`
        );
    }

    public createAffiliate(tpoId: number, affiliate: AffiliateInterface): Observable<AffiliateInterface> {
        const params: RealmParams = {tpoId: tpoId};
        return this.http.request<AffiliateInterface>(
            'POST',
            `${apiPath}/affiliates`,
            params,
            affiliate
        );
    }

    public updateAffiliate(affiliateId: number, affiliate: AffiliateInterface): Observable<AffiliateInterface> {
        return this.http.request<AffiliateInterface>(
            'PUT',
            `${apiPath}/affiliates/${affiliateId}`,
            null,
            affiliate
        );
    }

    public getBusinessType(): Observable<AffiliateBusinessTypeInterface[]> {
        return this.http.request<AffiliateBusinessTypeInterface[]>(
            'GET',
            `${apiPath}/code-value/AffiliateBusinessType`
        );
    }

}
