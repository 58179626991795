<div class="post-owners">
	<div class="modal-header">
		<button type="button" class="close" aria-label="Close" (click)="onCancel();">&times;</button>
		<h4 class="modal-title">Account Owners</h4>
	</div>
	<div class="modal-body no-top-spacing">
		<div class="rows-bordered">
			<div class="row-fluid header">
				<div class="col-md-8">
					<b class="two-liners">Name</b>
				</div>
				<div class="col-md-4">
					<b class="two-liners">NMLS</b>
				</div>
			</div>

			<div class="row-fluid" *ngFor="let item of list">
				<div class="col-md-8 va-m">
					<ng-container *ngIf="item?.contactId; then contactLink; else contactName"></ng-container>
					<ng-template #contactLink>
						<contact-link [data]="getContactData(item)"></contact-link>
					</ng-template>
					<ng-template #contactName>
						{{ item?.name || '' }}
					</ng-template>
				</div>
				<div class="col-md-4 va-m">
					<a *ngIf="item?.contactId"
						uiSref="nmls.individual.:nmlsId"
						[uiParams]="{ nmlsId: item?.nmlsId }"
						target="_blank"
					>
						{{ item?.nmlsId }}
					</a>
					<span *ngIf="!item?.contactId">{{ item?.nmlsId }}</span>
				</div>
			</div>
		</div>
	</div>
</div>
