<form [formGroup]="form" *ngIf="show" [ngSwitch]="question.group" class="clearfix" (submit)="submit.emit()">
    <h3 *ngSwitchCase="true">{{question.text}}</h3>
    <ng-container *ngSwitchDefault>
        <div class="col-sm-12 control-label text-default">
            <div class="countee">{{question.text}}</div>
        </div>

        <ng-container [ngSwitch]="question.questionType">
            <div class="col-sm-12" *ngSwitchCase="QUESTION_TYPE.MULTISELECT">
                <div *ngFor="let answer of question.answers; index as i" >
                    <label class="checkbox-inline">
                        <input type="checkbox" [checked]="answerField.value?.includes(answer.text)" (change)="setMultiAnswer(answer.text, $event)">
                        <b>{{answer.text}}</b>
                    </label>
                </div>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Select answer
                </div>
            </div>

            <div class="col-sm-12" *ngSwitchCase="QUESTION_TYPE.YES_NO">
                <div *ngFor="let answer of question.answers; index as i" >
                    <label class="radio-inline"><input type="radio" formControlName="answer" [value]="answer.text">
                        <b>{{answer.text}}</b>
                    </label>
                </div>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Select answer
                </div>
            </div>

            <div class="col-sm-12" *ngSwitchCase="QUESTION_TYPE.PICK_LIST">
                <div *ngFor="let answer of question.answers; index as i" >
                    <label class="radio-inline"><input type="radio" formControlName="answer" [value]="answer.text">
                        <b>{{answer.text}}</b>
                    </label>
                </div>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Select answer
                </div>
            </div>

            <div class="col-sm-12" *ngSwitchCase="QUESTION_TYPE.FREE_FORM">
                <textarea class="form-control-default form-control resize-vertical" rows="4" maxlength="8000" formControlName="answer"></textarea>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Enter your answer
                </div>
            </div>

            <div class="col-sm-3" *ngSwitchCase="QUESTION_TYPE.NUMBER">
                <input class="form-control-default form-control form-control-NUM" formControlName="answer" validFloat="1.0-3" placeholder="Enter Number">
                <span class="form-control-addon"></span>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Enter valid number
                </div>
            </div>

            <div class="col-sm-3" *ngSwitchCase="QUESTION_TYPE.PERCENT">
                <input class="form-control-default form-control form-control-PCT" formControlName="answer" validFloat="1.0-3" placeholder="Enter Percent">
                <span class="form-control-addon"></span>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Enter valid percent
                </div>
            </div>

            <div class="col-sm-3" *ngSwitchCase="QUESTION_TYPE.DOLLAR">
                <input class="form-control-default form-control form-control-USD" formControlName="answer" validFloat="1.0-2" placeholder="Enter Amount">
                <span class="form-control-addon"></span>
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Enter valid dollar amount
                </div>
            </div>

            <div class="col-sm-3" *ngSwitchCase="QUESTION_TYPE.DATE">
                <input type="text" class="form-control"
                       formControlName="answer"
                       bsDatepicker
                       [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                       placement="right"
                       placeholder="mm/dd/yyyy"
                />
                <div class="text-danger" *ngIf="hasError && answerField.invalid">
                    <br>Enter valid date
                </div>
            </div>
        </ng-container>

        <div class="col-sm-12" *ngIf="explanationRequired">
            <div class="control-label">Please Explain:</div>
            <textarea class="form-control-default form-control resize-vertical" rows="4" maxlength="8000" formControlName="explanation"></textarea>
            <div class="text-danger" *ngIf="hasError && explanationField.invalid">
                <br>Enter explanation
            </div>
        </div>
    </ng-container>
</form>
