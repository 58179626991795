import { map } from 'lodash';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms';
import { NgResourceObject } from 'commons/interfaces';

import { getRequestForm } from './request-document-modal.form';
import { ClientDocumentsService, RequestableDocumentService } from '../../client-documents.service';

import {
	ClientDocument,
	NotificationRequest,
} from '../../client-documents.interface';

@Component({
	templateUrl: './request-document-modal.component.html',
})
export class RequestDocumentModalComponent {
	// modal inputs
	reload: (updatedDocument?: ClientDocument) => void;
	getResourceParams: () => Record<string, unknown>;

	// modal props
	saving = false;
	title = 'Request Document';
	description = '';
	sendRequest?: any;
	onRequest?: (data: NotificationRequest) => void;
	document: any;
    service: RequestableDocumentService;

	assignForm: RealmFormGroup = getRequestForm();

	constructor(
		public modalRef: BsModalRef,
		protected User: UserService,
		public clientDocumentsResource: ClientDocumentsService,
	) {

	}

	ngOnInit(): void {
		if (this.document) {
			const { name: organizationName } = this.User.profile.organization;
			const { name: documentName } = this.document;
			this.assignForm.get('message').patchValue(this.getMessage(organizationName, documentName));
		}
	}

	getMessage(organizationName: string, documentName: string): string {
		return `${organizationName} is requesting that you upload the following document: ${documentName}` +
				'\n\n' +
				'Please log into your account at http://www.comergence.com, navigate to your Documents Tab to access ' +
				'and upload any outstanding documents requested by your investors, or in your library.' +
				'\n\n' +
				'Thank you,' +
				'\n' +
				'Optimal Blue Customer Support';
	}

	async save(): Promise<void> {
		this.saving = true;

		const {
			_notify_upon_assignment,
			toClients,
			ccRealmUserIds,
			message,
			_notify_upon_completion,
			notifyUponCompletionRealmUserIds,
			// dueDate,
		} = this.assignForm.value;

		const hasDocumentId = this.document.id || this.document.libraryId;

		const assignment: NotificationRequest = {
			...(_notify_upon_assignment ? {
				message,
				ccRealmUserIds,
                toContactIds: map(toClients, 'contactId'),
			} : {}),
			...(_notify_upon_completion ? { notifyUponCompletionRealmUserIds } : {}),
			// ...(this.mode === this.modes.FINANCIAL_REQUEST_DOCUMENT ? { dueDate } : {}),
		}

		if (!hasDocumentId) {
			this.document.assignment = assignment;
		}

		if (this.sendRequest) {
			try {
				const updatedDocument: NgResourceObject<ClientDocument> = await this.sendRequest(
					this.getResourceParams(),
					hasDocumentId ? assignment : this.document,
				).$promise;
				this.modalRef.hide();
				this.reload(updatedDocument);
			} catch ({ data }) {
				this.saving = false;
				this.assignForm.setServerError(data);
			}
		} else if (this.onRequest) {
			this.onRequest(assignment);
		}
	}

	onCancel = (): void => {
		this.modalRef.hide();
	}
}
