<ng-container>
	<a class="btn btn-primary btn-icon zoom-btn" (click)="toggleZoom()" *ngIf="allowZoom" [aDisabled]="!loaded">
		<span
			class="glyphicon"
			[ngClass]="zoom ? 'glyphicon glyphicon-resize-small' : 'glyphicon glyphicon-resize-full'"
		></span>
	</a>

	<img
        #image
		class="screenshot-preview"
		[class.zoom]="zoom"
		[src]="screenshot"
		(load)="load()"
		(click)="addIssue($event)"
		alt="Screenshot Preview"
	>

	<div class="issue-pin-container" *ngIf="imageSize" [ngStyle]="{height: (imageSize.height * scale) + 'px'}">
		<ng-container *ngFor="let issue of issues">
			<div
				*ngIf="issue.screenPosX"
				class="issue-pin"
				[ngStyle]="{top: issue.screenPosY * scale + 'px', left: (left + issue.screenPosX * scale) + 'px'}"
				[class.selected]="selectedIssue == issue"
				(click)="selectIssue(issue)"
			>{{issue.number}}</div>
		</ng-container>
	</div>
</ng-container>
