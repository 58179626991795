import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { defaults } from 'lodash';

import { FinancialHistoryRequestData } from 'commons/services/financial-documents.interface';

@Component({
	templateUrl: './request-financial-document-history-modal.component.html',
})
export class RequestFinancialDocumentHistoryModalComponent {
	snapshotTitles = {
		type: 'Type',
		period: 'Period',
		year: 'Year',
		toClient: 'To: Client',
		ccInternal: 'CC: Internal',
		message: 'Message',
		notifyCompletion: 'Notify upon completion',
		toInternal: 'To: Internal',
	};
	snapshotData: FinancialHistoryRequestData;
	resolving: boolean;

	constructor(public modalRef: BsModalRef) {
		defaults(this, {
			resolving: false,
			snapshotData: {
				toRealmUsers: [],
				ccRealmUsers: [],
				notifyUponCompletionRealmUsers: [],
			},
		});
	}
}
