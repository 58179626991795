import { Pipe, PipeTransform } from '@angular/core';
import { range } from 'lodash';

@Pipe({
	name: 'numbersRange',
})
export class NumbersRangePipe implements PipeTransform {
	transform(value: number): number[] {
		if (!value) {
			return null;
		}

		return range(value);
	}
}
