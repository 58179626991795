import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIRouterGlobals } from '@uirouter/core';

import { NgResourceArray } from 'commons/interfaces';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { MaxLengthValidator } from 'commons/validators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ApplicationsCommentsService } from './applications-comments.service';
import { CommentType } from 'lender/common/interfaces/comment-type.interface';

@Component({
	templateUrl: './applications-comments.component.html',
})
export class ApplicationsCommentsComponent {
	User: UserProfile;
	commentTypes: NgResourceArray<CommentType> = [];
	typeDescriptions: { [code: string]: CommentType };
	commentsForm: RealmFormGroup = new RealmFormGroup({
		comment: new RealmFormControl(
			'comment',
			{ label: 'Comment', updateOn: 'change' },
			Validators.compose([ Validators.required, MaxLengthValidator(2000) ]),
		),
		commentType: new RealmFormControl(
			'commentType',
			{ label: 'commentType', value: 'PUBLIC', updateOn: 'change' },
			[Validators.required],
		),
	});
	tpoId: number;
	applicationId: number;

	constructor(
		{ params }: UIRouterGlobals,
		{ profile }: UserService,
		public commentsService: ApplicationsCommentsService,
	) {
		this.User = profile;

		this.commentTypes = this.commentsService.comments.commentType();
		this.commentTypes.$promise.then((types) => {
			this.typeDescriptions = types.reduce((result, value) => {
				result[value.code] = value.description;
				return result;
			}, {});
		});

		const { id, applicationId } = params;
		this.tpoId = id;
		this.applicationId = applicationId;
	}

	get commentsResource(): any {
		return {
			query: (listParams) => {
				const params = { ...listParams, applicationId: this.applicationId };
				return this.commentsService.comments.query(params);
			},
			add: (listParams, data, successHandler, ererrorHandlerror) => {
				const params = { ...listParams, applicationId: this.applicationId };
				return this.commentsService.comments.add(params, { ...data, tpoId: this.tpoId }, successHandler, ererrorHandlerror);
			},
		}
	}
}
