import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

import { transformDynamicFieldsResponse } from 'tpo/social-compliance/publisher/library/templates/$templateId/template-text-edit/utils';
import { filterAttachmentsTransform } from '../attachments/filterAttachmentsTrasform';

declare let apiPath: string;

@Injectable()
export class PostsResourceService {

	posts: PostsResource;
	post: any;
	dynamicFields: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {

		const $resource = injector.get('$injector').get('$resource');

		const postsPath = {
			url: `${apiPath}/tpos/:tpoId/social-media-posts`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const postPath = {
			url: `${postsPath.url}/:postId`,
			params: {
				...postsPath.params,
				postId: '@postId',
			},
		};

		this.posts = $resource(
			postsPath.url,
			postsPath.params,
			{
				accounts: {
					url: `${postsPath.url}/accounts`,
					method: 'GET',
					isArray: true,
				},
				availableAccounts: {
					url: `${postsPath.url}/accounts-with-enrollment`,
					method: 'GET',
				},
			},
		);

		this.post = $resource(
			postPath.url,
			postPath.params,
			{
				create: {
					url: postsPath.url,
					method: 'POST',
					transformRequest: filterAttachmentsTransform,
				},
				get: {
					method: 'GET',
					transformRequest: filterAttachmentsTransform,
				},
				update: {
					method: 'PUT',
				},
				remove: {
					method: 'DELETE',
				},
				post: {
					url: `${postsPath.url}/post`,
					method: 'POST',
					transformRequest: filterAttachmentsTransform,
				},
				// TODO: remove after RM-19511 done
				availableAccounts: {
					url: `${postPath.url}/available-accounts`,
					method: 'GET',
					isArray: true,
				},
				postedAccounts: {
					url: `${postPath.url}/accounts`,
					method: 'GET',
					isArray: true,
				},
				postedCampaigns: {
					url: `${postPath.url}/campaigns`,
					method: 'GET',
					isArray: true,
				},
				validate: {
					url: `${apiPath}/tpos/:tpoId/social-media/publisher/post/text/validation`,
					method: 'POST',
				},
				preview: {
					url: `${postsPath.url}/post-preview`,
					method: 'POST',
				},
			},
		);

		this.dynamicFields = $resource(
			postPath.url,
			postPath.params,
			{
				query: {
					url: `${postPath.url}/field-types`,
					method: 'GET',
					isArray: true,
					transformResponse: transformDynamicFieldsResponse,
				},
			}
		);
	}

    doCreate = (...args) => this.post.create(...args);
    doUpdate = (...args) => this.post.update(...args);
    doPost = (...args) => this.post.post(...args);
    doRemove = (...args) => this.post.remove(...args);
}

interface PostsResource {
	accounts: (...args: any) => any;
	availableAccounts: (...args: any) => any;
}
