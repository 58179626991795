<div class="entity-fields" cdkDropListGroup>
	<back-bread class="gray-head flex-head">
		<div class="pull-right">
			<a
				*ngIf="!editingLayout"
				class="btn btn-secondary"
				(click)="editLayout()"
				[aDisabled]="!fieldsCount"
			>Layout</a>
			<a *ngIf="editingLayout"  class="btn btn-primary" (click)="saveLayout()" [aDisabled]="!layout.$resolved">Save</a>
			<a
				*ngIf="editingLayout"
				class="btn btn-secondary"
				(click)="cancelLayoutEdit()"
				[aDisabled]="!layout.$resolved"
			>Cancel</a>
			<a *ngIf="!editingLayout" class="btn btn-secondary" (click)="editGroup()" [aDisabled]="editingGroup">Add Group</a>

			<a class="btn btn-primary"
				uiSref=".custom.new"
				*ngIf="!editingLayout"
				[aDisabled]="fieldsCount >= maxFields"
			>
				Add Custom Field
			</a>
		</div>
		{{titles[this.entityType]}} Settings
	</back-bread>

	<div class="remote-resource" [class.resolved]="layout.$resolved">
		<notification></notification>

		<ng-container *ngIf="editingGroup && !activeGroupId">
			<div class="group-form">
				<form-errors [form]="groupForm"></form-errors>

				<form class="flex-row"
					  [formGroup]="groupForm" (onValidSubmit)="saveGroups()">
					<input
						#groupName
						type="text"
						formControlName="name"
						class="form-control group-name"
						placeholder="Enter group name"
						maxlength="96"
						required
					/>

					<span class="right">
						<button type="submit" [disabled]="!groupName.value" class="btn btn-primary" tabindex="20">
							Save
						</button>
						<a class="btn btn-default" (click)="cancelEdit()" tabindex="20">
							Cancel
						</a>
					</span>
				</form>
			</div>
		</ng-container>

		<div class="grouped-fields">
			<div class="group flex-row" *ngIf="entityType === 'CRMProspect'">
				<div class="col-md-12">Prospect Information</div>
			</div>
			<div class="group-fields">
				<a
					*ngFor="let field of systemFields;"
					class="clickable field-element system w100"
					uiSref=".system.:systemType" [uiParams]="{ systemType: field.systemType }"
				>
					<span class="field-icon field-icon-{{field?.controlTypeId}}"></span>
					<span class="text-bigger">{{field.fieldName}}</span>&nbsp;&nbsp;
					<span class="glyphicon text-muted glyphicon-question-sign"
						  tooltip="This is a system field, you can customize and edit it"
					></span>
				</a>
			</div>
			<div class="group-fields">
				<div
					class="col"
					*ngFor="let column of columnNames"
					cdkDropList
					[cdkDropListData]="splitFieldsLayout[column]"
					(cdkDropListDropped)="drop($event, column === 'fields' ? 1 : 2)"
				>
					<ng-container *ngFor="let field of splitFieldsLayout[column]">
						<div class="border-placeholder" *ngIf="editingLayout && splitFieldsLayout[column].length"></div>
						<a
							class="clickable field-element"
							[class.layout-editing-element]="editingLayout"
							[uiSref]="!editingLayout && '.custom.:id'" [uiParams]="{ id: field.id }"
							cdkDrag
							[cdkDragDisabled]="!editingLayout"
							(cdkDragStarted)="setCursor('move');"
							(cdkDragEnded)="removeCursor()"
						>
							<div class="fields-drag-placeholder" *cdkDragPlaceholder></div>
							<ng-container *ngIf="editingLayout">
								<span class="c-icon c-icon-dot-3 rotate-90 text-light text-bigger"></span>
								&nbsp;&nbsp;
							</ng-container>
							<span class="field-icon field-icon-{{field?.controlTypeId}}"></span>
							<span class="text-bigger">{{field.fieldName}}</span>&nbsp;&nbsp;
							<span
								*ngIf="!field.visible"
								class="glyphicon text-muted glyphicon-eye-close"
								tooltip="This Field is Hidden"
							></span>
						</a>
					</ng-container>
					<div *ngIf="editingLayout" class="border-placeholder"></div>
				</div>

				<div
					class="row-fluid"
					*ngIf="layout.$resolved && !layout.fields?.length && !layout.groups?.length && !systemFields?.length"
				>
					<span class="text-muted text-center text-bigger va-m">No Records Added</span>
				</div>
			</div>
		</div>

		<div class="grouped-fields">
			<ng-container *ngFor="let group of splitFieldsLayout.groups; first as isFirst; last as isLast">
				<editable-component [editable]="group.editing">
					<ng-template #editTemplate>
						<div class="group-form">
							<form-errors [form]="groupForm"></form-errors>

							<form class="flex-row"
								  [formGroup]="groupForm" (onValidSubmit)="saveGroups()">
								<input
									#groupName
									type="text"
									formControlName="name"
									class="form-control group-name"
									placeholder="Enter group name"
									maxlength="96"
									required
								/>

								<span class="right">
									<button type="submit" [disabled]="!groupName.value" class="btn btn-primary" tabindex="20">
										Save
									</button>
									<a class="btn btn-default" (click)="cancelEdit()" tabindex="20">
										Cancel
									</a>
								</span>
							</form>
						</div>
					</ng-template>
					<ng-template #viewTemplate>
						<div class="group flex-row">
							<div class="col-md-10">{{group.name}}</div>
							<div class="col-md-2 text-right text-nowrap arrows va-m" *ngIf="!editingLayout && !editingGroup">
								<button
										type="button"
										class="btn btn-tertiary btn-icon btn-ml2"
										[class.hide-by-default]="isFirst"
										(click)="groupAction(group.id, 'up')"
								>
									<span class="c-icon c-icon-arrow-down icon-reverse text-bigger"></span>
								</button>

								<button
										type="button"
										class="btn btn-tertiary btn-icon btn-ml2"
										[class.hide-by-default]="isLast"
										(click)="groupAction(group.id, 'down')"
								>
									<span class="c-icon c-icon-arrow-down text-bigger"></span>
								</button>
								<button
									type="button"
									class="btn btn-tertiary btn-icon btn-ml2"
									(click)="renameGroup(group.name, group.id)"
								>
									<span class="glyphicon glyphicon-pencil text-muted"></span>
								</button>
								<button
									type="button"
									class="btn btn-tertiary btn-icon btn-ml2"
									(click)="removeGroup(group.id)"
								>
									<span class="glyphicon glyphicon-trash"></span>
								</button>
							</div>
						</div>
					</ng-template>
				</editable-component>

				<div class="group-fields">
					<div
						class="col"
						*ngFor="let column of columnNames"
						cdkDropList
						[cdkDropListData]="group[column]"
						(cdkDropListDropped)="drop($event, column === 'fields' ? 1 : 2)"
					>
						<ng-container *ngFor="let field of group[column]">
							<div class="border-placeholder" *ngIf="editingLayout && group[column].length"></div>
							<a
								class="clickable field-element"
								[uiSref]="!editingLayout && '.custom.:id'" [uiParams]="{ id: field.id }"
								cdkDrag
								[cdkDragDisabled]="!editingLayout"
							>
								<div class="fields-drag-placeholder" *cdkDragPlaceholder></div>
								<ng-container *ngIf="editingLayout">
									<span class="c-icon c-icon-dot-3 rotate-90 text-light text-bigger"></span>
									&nbsp;&nbsp;
								</ng-container>
								<span class="field-icon field-icon-{{field?.controlTypeId}}"></span>
								<span class="text-bigger">{{field.fieldName}}</span>&nbsp;&nbsp;
								<span
									*ngIf="!field.visible"
									class="glyphicon text-muted glyphicon-eye-close"
									tooltip="This Field is Hidden"
								></span>
							</a>
						</ng-container>
						<div *ngIf="editingLayout" class="border-placeholder"></div>
					</div>
				</div>

			</ng-container>
		</div>
	</div>
</div>
