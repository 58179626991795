<div class="remote-resource" [class.resolved]="true">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-2">Channel Name</div>
            <div class="col-md-1">Status</div>
            <div class="col-md-2">Applicant pays for Application</div>
            <div class="col-md-2">Applicant pays for Renewal</div>
            <div class="col-md-2">Ownership Verification</div>
            <div class="col-md-3"></div>
        </div>
        <ng-container *ngIf="channels && channels.length != 0; else noChannels">
            <div class="row-fluid" *ngFor="let channel of channels">
                <div class="col-md-2 control-value">
                    {{channel.name}}
                </div>
                <div class="col-md-1 control-value">
                    <span>{{channel.isActive ? 'Active' : 'Inactive'}}</span>
                </div>
                <div class="col-md-2 control-value" [ngSwitch]="channel.tpoPaysForApplication">
                    <span *ngSwitchCase="true" class="text-success glyphicon glyphicon-ok"></span>
                    <span *ngSwitchDefault class="text-danger glyphicon glyphicon-remove"></span>
                </div>
                <div class="col-md-2 control-value" [ngSwitch]="channel.tpoPaysForApplication">
                    <span *ngSwitchCase="true" class="text-success glyphicon glyphicon-ok"></span>
                    <span *ngSwitchDefault class="text-danger glyphicon glyphicon-remove"></span>
                </div>
                <div class="col-md-2 control-value">
                    {{channel.ownershipVerificationPercentage}}%
                </div>
                <div *ngIf="canManageCustomers" class="col-md-3 button-holder">
                    <div class="flex-row gap">
                        <div class="flex-grow"></div>
                        <!--button class="btn btn-secondary" (click)="setChannelStatus(channel.isActive, channel.id)">
                            {{channel.isActive ? 'Deactivate' : 'Activate'}}
                        </button-->
                        <button class="btn btn-primary" (click)="openEditModal(channel.id)">
                            <span class="glyphicon glyphicon-pencil"></span> Edit
                        </button>
                        <div dropdown>
                            <a class="btn btn-tertiary btn-icon" dropdownToggle>
                                <span class="c-icon c-icon-dot-3"></span>
                            </a>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                                <li role="menuitem">
                                    <a (click)="setChannelStatus(channel.isActive, channel.id)">{{ channel.isActive ? 'Deactivate' : 'Activate' }}</a>
                                    <a (click)="shareChannel(channel.id)">Share</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noChannels>
            <div class="row-fluid">
                <span class="col-md-12 text-muted text-center text-bigger">No channels</span>
            </div>
        </ng-template>
    </div>
</div>
