import { StateOrName, UIRouter, UIRouterPlugin } from '@uirouter/core';

export const findClosestParent = (name: string, base: StateOrName): string => {
    const baseName = (typeof base === 'string' ? base : base?.name) || '';
    const parts = baseName.split('.');
    const newName = name.replace(/^\^([^.]+)/, (matchStr, stateName) => {
        const length = parts.lastIndexOf(stateName) + 1;
        if (!length) throw new Error(`Error resolving parent state "${stateName}" using base state "${baseName}"`);

        return parts.slice(0, length).join('.');
    });
    return newName;
};

export class RelativeParentFinder implements UIRouterPlugin {
    name = 'realm/relative-parent';

    constructor({ stateRegistry }: UIRouter) {
        const { resolvePath: oldResolve, find: oldFind } = stateRegistry.matcher;

        stateRegistry.matcher.resolvePath = function (name: string, base: StateOrName) {
            return oldResolve.call(this, findClosestParent(name, base), base);
        };

        stateRegistry.matcher.find = function (stateOrName: StateOrName, base: StateOrName, ...rest) {
            if (typeof(stateOrName) === 'string') {
                return oldFind.call(this, findClosestParent(stateOrName, base), base, ...rest);
            }

            return oldFind.call(this, stateOrName, base, ...rest);
        }
    }

    dispose() {
    }
}
