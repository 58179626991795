import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SegmentedControlValue } from './segment-control-value.interface';

@Component({
	selector: 'segmented-controls',
	templateUrl: './segmented-controls.html',
})
export class SegmentedControlsComponent {
	@Input() items: SegmentedControlValue[];
	@Input() value: string;
	@Output() selected: EventEmitter<string> = new EventEmitter();

	change(newValue): void {
		if (this.value !== newValue) {// check if already selected
			this.value = newValue;
			this.selected.emit(newValue);
		}
	}
}
