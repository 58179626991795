<div class="remote-resource" [ngClass]="{ 'resolved': resolved }">
	<header>
		<div class="column">
			<div class="row">
				<div class="col-md-4"><span class="text-muted">Location</span></div>
				<div class="col-md-3"><span class="text-muted">Individual</span></div>
				<div class="col-md-a"></div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<ng-container *ngIf="review?.companyName || review?.nmlsId">
						<ng-container *ngIf="review.companyName">
							{{review.companyName}}{{review.nmlsId ? ',' : ''}}
						</ng-container>
						<ng-container *ngIf="review.nmlsId">NMLS ID: {{review.nmlsId}}</ng-container>
					</ng-container>
					<ng-container *ngIf="!review?.companyName && !review.nmlsId">&ndash;</ng-container>
				</div>
				<div class="col-md-3">
					<a *ngIf="review?.contactId && !(review.owners?.length > 1)"
					   uiSref="people.contacts.:contactId.information"
					   [uiParams]="{ contactId: review.contactId }"
					   target="_blank">{{review.loanOriginatorFullName}}
					</a>
					<a *ngIf="review?.contactId && review.owners?.length > 1"
					   (click)="showIndividualsModal(review.owners)">
						{{review.owners.length}} individuals
					</a>
					{{!review?.contactId ? '&ndash;' : ''}}
				</div>
				<div class="col-md-a"></div>
			</div>
		</div>
		<div class="column button-holder">
			<a class="btn btn-primary"
			   *ngIf="review?.hasPostPermission"
			   (click)="post(review)"
			>
				<i class="c-icon c-icon-send"></i>&nbsp;Post
			</a>
		</div>
	</header>
	<div class="content-holder">
		<div class="column">
			<sm-icon [config]="{
						networkCode: review?.networkId || 'ER',
						profilePictureUrl: review?.reviewerAvatarUrl,
						title: !review?.networkId && review.providerName
					 }">
			</sm-icon>
		</div>
		<div class="column">
			<div><b>{{review?.reviewerName}}</b></div>
			<div class="text-muted">
				{{(review?.reviewDate | date:'MMMM d, y \'at\' h:mm a') || '&ndash;'}}
			</div>
			<div class="horizontal-flex-group recommends">
				<i class="c-icon c-icon-recommend space-small"
				   [ngClass]="{ 'recommend': isRecommended, 'not-recommend': !isRecommended }"></i>
				<a href="{{review.reviewerProfileUrl}}" class="space-small" [aDisabled]="!review.reviewerProfileUrl"
				   target="_blank"><b>{{review?.reviewerName}}</b></a>
				<b class="space-small">
					<ng-container *ngIf="!isRecommended">doesn't recommend</ng-container>
					<ng-container *ngIf="isRecommended">recommends</ng-container>
				</b>
				<a href="{{review.pageUrl}}" [aDisabled]="!review.pageUrl" target="_blank">
					<b>{{review.companyName}}</b>
				</a>
			</div>
			<div *ngIf="review?.text" class="review-text">
				<show-more [maxHeight]="60" [text]="review.text"></show-more>
			</div>
			<review-comments *ngIf="review.replies?.length"
							 [replies]="review.replies"
							 [reviewId]="review.reviewId"
							 [permission]="review.hasReplyEditPermission"
							 [companyName]="review.companyName"
							 (onAdd)="onReply()">
			</review-comments>
			<review-reply *ngIf="review?.hasReplyEditPermission" class="reply-holder btn-reply-holder"
						  [reviewId]="review.reviewId"
						  [permission]="review?.hasReplyEditPermission"
						  [charsAmount]="8000"
						  [openedFormsList]="openedReplyForms"
						  (onOpen)="onOpenReplyForm($event)"
						  (onAdd)="onReply()">

				<ng-template #replyFormTemplate
							 let-addReplyForm="addReplyForm"
							 let-replyBtnText="replyBtnText"
							 let-submitted="submitted"
							 let-isInvalid="isInvalid"
							 let-charsAmount="charsAmount"
							 let-add="methods.add"
							 let-cancelEdit="methods.cancelEdit"
							 let-getCharsCount="methods.getCharsCount">
					<form [formGroup]="addReplyForm" id="addReplyForm" (onValidSubmit)="add()" autocomplete="off"
						  role="presentation">
						<form-errors [form]="addReplyForm"></form-errors>

						<div class="mb15 remote-resource" [class.resolved]="!submitted">
							<div class="full-width mb10">
								<label for="reply">{{review.companyName}}</label>
								<textarea id="reply"
									  class="form-control resize-vertical"
									  formControlName="text"
									  [rows]="1"
									  [maxlength]="charsAmount"
									  placeholder="Type here">
								</textarea>
							</div>

							<div class="characters mb10 text-muted text-smaller">Characters: {{getCharsCount()}}</div>
							<div>
								<span class="btn btn-default" (click)="cancelEdit()">Cancel</span>

								<button [disabled]="isInvalid" type="submit" class="btn btn-primary">
									{{replyBtnText}}
								</button>
							</div>
						</div>
					</form>
				</ng-template>

			</review-reply>
		</div>
	</div>
</div>
