import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StateService } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

@Component({
	selector: 'contact-link',
	templateUrl: './contact-link.component.html',
})
export class ContactLinkComponent implements OnChanges {
	User: UserProfile;
	contact: {
		tpoId: number;
		contactType: string;
		contactId: number;
		name: string;
	} | null;
	state: {
		sref: string;
		params: any;
	} | null;

	@Input() data: any;

    constructor(
        { profile }: UserService,
        public stateService: StateService,
    ) {
        this.User = profile;
    }

	ngOnChanges(changes: SimpleChanges) {
		const data = changes.data.currentValue;
		this.contact = this.getContact(data);
		this.state = this.getState(this.contact);
	}

	// extend with other options
	getTpoId = ({ tpoId }) => (
		tpoId
	)

	// extend with other options
	getContactType = ({ contactType }) => (
		contactType
	)

	// extend with other options
	getContactId = ({ contactId }) => (
		contactId
	)

	// extend with other options
	getName = ({ contactName }) => (
		contactName
	)

	getContact = (data) => {
		if (data) {
			return {
				tpoId: this.getTpoId(data),
				contactType: this.getContactType(data),
				contactId: this.getContactId(data),
				name: this.getName(data),
			};
		}

		return null;
	}

	getState = ({ tpoId, contactId, contactType }) => {
        const { isTpo, isLender } = this.User || {};

		if (contactType) {
            const prefix = isTpo ? 'people' : isLender ? 'client.:id' : 'tpo.:id';
			const isCompany = contactType === 'company';

			return {
				sref: !isCompany ?
					`${prefix}.contacts.:contactId.information` :
					`${prefix}.information`,
				params: {
					...(!isTpo && { id: tpoId }),
					...(!isCompany && { contactId }),
				},
			};

		}

		return null;
	}
}
