<section class="remote-resource" [class.resolved]="contacts.$resolved">
	<h3>
		Contacts
		&nbsp;
		<segmented-controls
			*ngIf="!User?.profile?.isLender"
			[items]="filters.previous"
			[value]="params.previous"
			(selected)="setFilter({previous: $event})"
		></segmented-controls>

		<div *ngIf="hasPermission('MANAGE_CONTACT')" class="pull-right" [ngSwitch]="User.profile.isLender">
			<a *ngSwitchDefault class="btn btn-primary" fixedSref=".new"><span class="glyphicon glyphicon-plus small-icon"></span> Add Contact</a>
            <a *ngSwitchCase="true" class="btn btn-primary" uiSref="crm.contacts.new" [uiParams]="{relatedAccount: relatedAccount}"><span class="glyphicon glyphicon-plus small-icon"></span> Add Contact</a>
		</div>
	</h3>

	<notification></notification>

	<div>
		<div class="row">
			<div class="col-md-3">
				<div class="text-muted">Type</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3">
				<ng-select
					[ngModel]="params.contactType"
					(change)="setFilter({contactType: $event.value})"
					[items]="filters.contactType"
					[disabled]="!hasPermission('FILTER_CONTACT')"
					bindValue="value"
					bindLabel="title"
					[clearable]="false"
					placeholder="All">
				</ng-select>
			</div>
			<div class="col-md-9">
				<a *ngIf="filtersApplied" (click)="resetFilters()" class="form-filter">Reset Filters</a>
			</div>
		</div>
		<br>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-md-4">
				<search-list placeholder="Search by any parameter"></search-list>
			</div>
			<span [ngClass]="['col-md-2', getSortClass('nmlsId')]" (click)="setSort('nmlsId')"><b class="two-liners">NMLS ID</b></span>
			<span [ngClass]="['col-md-2', getSortClass('phone')]" (click)="setSort('phone')"><b class="two-liners">Phone</b></span>
			<span [ngClass]="['col-md-3', getSortClass('email')]" (click)="setSort('email')"><b class="two-liners">Email</b></span>
			<span class="col-sm-1 text-right"></span>
		</div>

		<div class="row-fluid yellow-bg no-border" [class.hidden]="!filtersApplied">
			<span class="col-sm-12 text-center">Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results</span>
		</div>

		<div class="row-fluid" *ngFor="let item of contacts">
			<span class="col-sm-4 loan-margin" [ngSwitch]="item.contactType">

				<i *ngIf="item.isMobileUser" class="c-icon c-icon-phone" placement="top" tooltip="Mobile User"></i>
				<!-- TODO: check contact-list template, it has .lender-individual route, normalize crm routes -->
				<a *ngSwitchCase="TYPE.CRM" fixedSref="crm.contacts.:contactId.information" [uiParams]="{contactId: item.contactId}" class="text-bigger">{{item.fullName}}</a>
				<a *ngSwitchDefault fixedSref=".:contactId.information" [uiParams]="{contactId: item.contactId}" class="text-bigger">{{item.fullName}}</a>
				<br>
				<span class="text-light">{{item.officerDesignation?.join(', ')}}</span>
			</span>
			<span class="col-md-2">
				<a *ngIf="item.nmlsId && !isEmbedded" fixedSref="{{getFixedNmlsSref()}}" [uiParams]="{nmlsId:item.nmlsId, id:stateParamsId}">{{item.nmlsId || '&ndash;'}}</a>
				<span *ngIf="!(item.nmlsId && !isEmbedded)" >{{item.nmlsId || '&ndash;'}}</span>
			</span>
			<span class="col-md-2">{{item.phone || '&ndash;' | tel}}</span>
			<span [ngClass]="['break-word', 'col-md-3']">
				<a [aDisabled]="!item.email" href="mailto:{{item.email}}">{{item.email || '&ndash;'}}</a>
			</span>
			<span class="col-sm-1 text-right">
				<div *ngIf="item.isPrimaryContact">
					<span class="glyphicon glyphicon-star action" tooltip="Notifications" placement="left"></span>
				</div>
				<contact-role [contact]="item"></contact-role>
			</span>

		</div>

		<div class="row-fluid" *ngIf="noMatches">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>

		<div class="row-fluid" *ngIf="!contacts.length && !noMatches">
			<span class="col-md-12 text-muted text-center text-bigger">No contacts</span>
		</div>

		<br>
		<new-paging class="no-border" [(list)]="contacts" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</section>
