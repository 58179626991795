<div class="production">
    <h2>
        Production
       <span *ngIf="isNqmEnabled" class="label label-primary-wired text-smaller va-m">NQM</span>
    </h2>
    <span class="text-muted">
        Total Volume
    </span>
    <production-total-volume [nmlsId]="nmlsId" class="mb20"></production-total-volume>
    <production-top-list [nmlsId]="nmlsId" [type]="'branches'" [title]="'Top 5 Branches'" class="mb20"></production-top-list>
    <production-top-list [nmlsId]="nmlsId" [type]="'officers'" [title]="'Top 5 Loan Officers'"></production-top-list>
</div>
