import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import moment from 'moment';

import { LenderApplicationsResourceService } from 'lender/applications/applications.service';

import { ExpirationDateEditForm } from './expiration-date.form';

@Component({
	templateUrl: './expiration-date-modal.component.html',
})

export class ApplicationExpirationDateModalComponent {
	onCancel: () => any;
	onConfirm: () => any;
	resolving: boolean = false;

	today: Date = new Date();
	application: any = {};
	editForm = ExpirationDateEditForm;

	constructor(
		public service: LenderApplicationsResourceService,
		public modalRef: BsModalRef,
	) {
	}

	ngOnInit() {
		this.today.setUTCHours(12, 0, 0 ,0);
		this.resetForm();
	}

	cancel() {
		this.modalRef.hide();
	}

	confirm() {
		const expirationDateString = moment(this.editForm.value.expirationDate as Date).format('MM/DD/YYYY'),
			{ applicationId } = this.application;

		this.resolving = true;
		this.service.setExpirationDate(
			{ applicationId },
			{ expirationDateString },
			/* success */ () => {
				this.cancel();
				this.onConfirm()
			},
			/* error */ ({ data }) => {
				this.editForm.setServerError(data);
				this.resolving = false;
			},
		);
	}

	// Workaround for a datepicker not updating value when closed and value wasn't changed
	updateValue(skip: boolean = false) {
		if (!skip)  {
			this.editForm.updateValueAndValidity({ emitEvent: true });
		}
	}

	resetForm() {
		const expirationDate = new Date(this.application.expirationDate);
		expirationDate.setUTCHours(12, 0, 0 ,0);
		this.editForm.markAsPristine();
		this.editForm.reset({ expirationDate: expirationDate >= this.today ? expirationDate : null });
	}

}
