<div class="col-md-a">
    <span class="control-value">Ownership</span>
</div>
<div class="col-md-a">
    <span class="control-value">
        <span *ngIf="ownership.completed" class="label label-success-wired">Completed</span>
        <span *ngIf="!ownership.completed" class="label label-danger-wired">Required</span>
    </span>
</div>
<div class="col-md-a">
    <span class="control-value text-muted">N/A</span>
</div>
<div class="col-md-a">
   <span class="control-value text-muted">N/A</span>
</div>
<div class="col-f-120 button-holder text-right">
    <a uiSref="people.ownership" [uiParams]="{ channelId: channelId }" class="btn btn-default">
        <span class="glyphicon glyphicon-pencil"></span>Update
    </a>
</div>
