import { Component, Input } from '@angular/core';
import { AlertChannel } from 'lender/alerts/alert.interface';


@Component({
    selector: 'alert-channels',
	templateUrl: './alert-channels.component.html',
})
export class AlertChannelsComponent {
	@Input() alertChannels: AlertChannel[] = [];
}
