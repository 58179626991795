import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs'

import { Issue } from './finding.interface';

@Injectable()
export class FindingModelService {

	public isEditingIssue: boolean;

	onHighlightIssue$: Subject<Issue> = new Subject();
	onAddIssue$: Subject<{ screenPosX: number, screenPosY: number }> = new Subject();
	onImageLoaded$: Subject<boolean> = new Subject();
	issueInFocus$: BehaviorSubject<Issue> = new BehaviorSubject(null);
}
