<div
	class="pending-application-holder clearfix"
	[ngClass]="{ 'remote-resource': hasOwnLoader, 'resolved': list.$resolved }"
>
	<div class="pending-application row-fluid clearfix" *ngFor="let application of list">
		<div class="coldp-md-8 application-info">
			<div class="row">
				<div class="col-sm-3 logo">
					<img
						class="img-rounded img-responsive"
                        src="{{application.lender.id | lenderLogo}}"
						alt="{{application.lender.name}}"
					/>
				</div>
				<div class="col-sm-9">
					<h3>{{application.lender.name}}</h3>
					<small>{{application.channel.name}}</small>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-5 control-label text-right">Date Created:</div>
				<div class="col-sm-7">{{application.createdDate | date : 'MM/dd/yyyy'}}</div>
			</div>
			<div class="row">
				<div class="col-sm-5 control-label text-right">Expiration Date:</div>
				<div class="col-sm-7">{{application.expirationDate | date : 'MM/dd/yyyy'}}</div>
			</div>
			<div class="row">
				<div class="col-sm-5 control-label text-right">Assigned:</div>
				<div class="col-sm-7">
					<span>{{application.accountExecutive?.fullName || 'Unassigned'}}</span>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-5 control-label text-right">App Type:</div>
				<div class="col-sm-7">
					<span>{{application.applicationType == 'NEW' ? 'New' : 'Renewal'}}</span>
				</div>
			</div>
			<div class="row" *ngIf="application.accountExecutive?.email">
				<div class="col-sm-5 control-label text-right">Email:</div>
				<div class="col-sm-7">
					<a href="mailto:{{application.accountExecutive.email}}">{{application.accountExecutive.email}}</a>
				</div>
			</div>
			<div class="row" *ngIf="application.accountExecutive?.phone">
				<div class="col-sm-5 control-label text-right">Phone:</div>
				<div class="col-sm-7">{{application.accountExecutive.phone | tel}}</div>
			</div>

		</div>
		<div class="coldp-md-16 application-tbd-holder">
			<div class="horizontal-flex-group">
				<div class="application-tbd no-space">
					<h3>Application Process Actions</h3>
					<ul>
						<ng-container *ngTemplateOutlet="
							stepStatusTemplate;
							context:{
								name: 'Questionnaire',
								valid:  application.questionnaireSubmitted,
								invalid: !application.questionnaireSubmitted
							}">
						</ng-container>
						<ng-container *ngTemplateOutlet="
							stepStatusTemplate;
							context:{
								name: 'Account Update',
								valid:  application.accountUpdateCompleted,
								invalid: !application.accountUpdateCompleted
							}">
						</ng-container>
						<ng-container *ngTemplateOutlet="
							stepStatusTemplate;
							context:{
								name: 'Documents',
								valid:  !application.missingRequiredDocsCount,
								invalid: application.missingRequiredDocsCount
							}">
						</ng-container>
                        <ng-container *ngTemplateOutlet="
							stepStatusTemplate;
							context:{
								name: 'Financials',
								valid:  !application.missingRequiredFinancialDocsCount,
								invalid: application.missingRequiredFinancialDocsCount
							}">
                        </ng-container>
						<ng-container *ngTemplateOutlet="
							stepStatusTemplate;
							context:{
								name: 'Ownership',
								valid:  !application.ownershipVerificationNeeded,
								invalid: application.ownershipVerificationNeeded,
								showButton: true
							}">
						</ng-container>
						<ng-container *ngTemplateOutlet="
							stepStatusTemplate;
							context:{
								name: 'Submit',
								valid:  application.submitted,
								invalid: !application.submitted
							}">
						</ng-container>
						<ng-template #stepStatusTemplate let-name="name" let-valid="valid" let-invalid="invalid" let-showButton="showButton">
							<li>
								<span
									class="c-icon"
									[ngClass]="{
										'c-icon-check text-success': valid,
										'c-icon-attention color-yellow': invalid
									}"
								></span>
								&nbsp;
								{{name}}
								<ng-container *ngIf="showButton && hasContactPermission(User, 'MANAGE_OWNERS') && invalid">
									&nbsp;
									<a (click)="notifyUnverifiedOwners()" class="btn btn-sm btn-danger">Notify Unverified Owners</a>
								</ng-container>
							</li>
						</ng-template>
					</ul>
				</div>
				<div class="application-status no-space">
					<div class="status" [ngSwitch]="isBlocked(application.applicationStatus.id)">
						<span class="glyphicon glyphicon-minus-sign" *ngSwitchCase="true"></span>
						<span class="glyphicon glyphicon-time" *ngSwitchDefault></span>
						<h6>{{application.applicationStatus.name}}</h6>
						<div *ngIf="application.expirationInDays > 0" class="expired">Expires in <a>{{application.expirationInDays}} days</a></div>
					</div>

					<div><a class="btn btn-primary" uiSref="new-application" [uiParams]="{channelId: application.channel.id}"><span class="glyphicon glyphicon-pencil"></span>Edit Application</a></div>
					<div><a class="btn btn-default" uiSref="documents.list" [uiParams]="application.docListParams">Upload Documents</a></div>
                    <div><a class="btn btn-default" uiSref="account.financials" style="margin-top: 10px">Upload Financials</a></div>
					<div class="text-center">
						<br>
						<a href="/tpo-applications/channels/{{application.channel.id}}/print" target="_blank"> Print View</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="text-muted text-center v-align" *ngIf="list.$resolved && list.length == 0">
		<h2>There are no applications in progress</h2>
	</div>
</div>
