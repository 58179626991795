import { Component } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import {
	AbstractClientDocumentRequest,
	DocumentType,
} from 'lender/clients/$id/documents/document/details/client-document-request.abstract';
import { ClientsService } from 'lender/clients/clients.service';
import { BulkRequestService } from 'lender/clients/list/document/bulk-request.service';
import { ClientDocumentDetailsFormData } from 'lender/clients/$id/documents/client-documents.interface';

@Component({
	templateUrl: './bulk-document-request.component.html',
})
export class BulkDocumentRequestComponent extends AbstractClientDocumentRequest {
	constructor(
		routerGlobals: UIRouterGlobals,
		userService: UserService,
		private router: UIRouter,
		private bulkRequestService: BulkRequestService,
		private clientsService: ClientsService,
	) {
		super(routerGlobals, userService)

		const { channelId, tpoIds } = routerGlobals.params;

		this.params = {
			...this.params,
			channelId,
			tpoIds,
		};

		this.documents = this.bulkRequestService.documentsList({ channelId });
		this.channels = this.clientsService.lenderChannels({
			activeOnly: true,
			accessibleOnly: true,
		}, () => {
			const channel = this.channels.find((channel) => channel.id=== channelId);
			// filling channel so it'll pass validation
			this.toggleChannel(channel);
		});
		this.create = true;
	}

	get templateDownloadLink(): string {
		const {
			id: documentId,
		} = this.getFormGroup('document').value ?? {};
		return this.bulkRequestService.getTemplateDownloadLink({ documentId });
	}

	async submit(): Promise<void> {
		this.resolved = false;
		try {
			const { channelId, tpoIds } = this.params
			if ( this.type === DocumentType.GD ) {
				const { document: { id: documentId } } = this.form.getRawValue();
				await this.bulkRequestService.requestGlobal({ channelId, documentId }, { tpoIds }).$promise;
			} else if ( this.type === DocumentType.CSD ) {
				const { id, ...documentDetails }: Partial<ClientDocumentDetailsFormData> = this.getFormGroup('documentDetails').getRawValue();
				await this.bulkRequestService.requestClient({ channelId }, { tpoIds, ...documentDetails }).$promise;
			}

			this.cancel();

		} catch ( { data } ) {
			this.getFormGroup('documentDetails').setServerError(data);
		}
		this.resolved = true;
	}

	cancel(): void {
		this.router.stateService.transitionTo('clients.list');
	}
}
