import { Component, forwardRef } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { ChannelsService, InviteHistoryItem, TPOInviteStatuses } from 'lender/clients/$id/channels/channels.service';
import { Entity } from 'tpo/documents/documents.interface';

@Component({
    templateUrl: './invite-history.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => InviteHistoryComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => InviteHistoryComponent) },
    ],
})
export class InviteHistoryComponent extends NewPagedListComponent<InviteHistoryItem> {
    static listName = 'inviteHistoryList';
    readonly STATUSES = TPOInviteStatuses;
    channel!: Entity;

    constructor(
        router: UIRouter,
        private readonly channelsService: ChannelsService,
    ) {
        super(router);
        this.loadChannel();
    }

    async loadChannel() {
        const { channelId } = this.router.globals.params;
        this.channel = await this.channelsService.getBase(channelId).toPromise();
    }

    protected loadList(listParams: ListParams): Observable<PagedData<InviteHistoryItem[]>> {
        const { id: tpoId, channelId } = this.router.globals.params;
        return this.channelsService.getInviteHistory(tpoId, channelId, listParams);
    }
}
