<div class="remote-resource" [class.resolved]="list.$resolved">
	<div class="rows-bordered">
		<div class="row-fluid no-border" *ngFor="let item of list">
			<tpo-library-document-list-item
				[document]="item"
			></tpo-library-document-list-item>
		</div>

		<div class="row-fluid no-border" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center va-m text-bigger text-muted">No documents</div>
		</div>

		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
