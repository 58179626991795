import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import {
    COLUMNS_AVAILABLE,
    ContactImportColumn,
    ContactImportColumnKey,
    ContactImportColumnMappings,
    ContactsImportService,
} from 'tpo/people/contacts/import/import.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UIRouter } from '@uirouter/core';
import { shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { CustomRequiredValidator } from 'commons/validators';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

const REQUIRED_COLUMNS: ContactImportColumnKey[] = ['nmlsId', 'email'];
const COLUMN_LABELS: Record<ContactImportColumnKey, string> = {
    nmlsId: 'NMLS ID',
    email: 'Email',
    phone: 'Phone',
    title: 'Title',
    street: 'Street',
    suite: 'Suite',
    zip: 'Zip',
};

@Component({
    selector: 'contacts-import-mapping',
    templateUrl: './mapping.component.html',
})
export class ContactsImportMappingComponent implements OnDestroy, AfterViewInit {

    form = new RealmFormGroup({});
    selectedFile = this.form.get('selectedFile');
    saving = false;
    loading = true;
    COLUMNS_AVAILABLE = COLUMNS_AVAILABLE;
    COLUMN_LABELS = COLUMN_LABELS;
    REQUIRED_COLUMNS = REQUIRED_COLUMNS;
    // Sample data for each column
    columnsSampleData: Record<ContactImportColumnKey, string[]> = {} as Record<ContactImportColumnKey, string[]>;

    // Unfiltered select options
    private columns$: Observable<ContactImportColumn[]>;
    // Filtered select options
    public possibleMappingValues$: Record<ContactImportColumnKey, Observable<ContactImportColumn[]>> = {} as Record<ContactImportColumnKey, Observable<ContactImportColumn[]>>;
    private destroy$ = new Subject<void>();

    constructor(
        private readonly contactsImportService: ContactsImportService,
        private readonly router: UIRouter,
        private readonly historyLogService: HistoryLogService,
        private readonly notificationsService: GlobalNotificationsService,
    ) {
        const { importId } = this.router.globals.params;
        this.columns$ = this.contactsImportService.getColumns(importId).pipe(
            tap(() => {
                this.loading = false;
            }),
            takeUntil(this.destroy$),
            shareReplay(1),
        );
        // Setup form fields
        this.setupFormFields();
    }

    ngAfterViewInit() {
        // Trigger recalculating of possible values
        this.form.updateValueAndValidity();
    }

    private setupFormFields() {
        this.form.valueChanges.pipe(
            takeUntil(this.destroy$),
        ).subscribe((value: ContactImportColumnMappings) => {
            this.columnsSampleData = Object.fromEntries(
                Object.entries(value).map(([key, value]) => [key, value?.sampleData]),
            ) as Record<ContactImportColumnKey, string[]>;
        });

        COLUMNS_AVAILABLE.forEach(column => {
            this.possibleMappingValues$[column] = combineLatest([
                this.columns$,
                this.form.valueChanges,
            ]).pipe(
                switchMap(([columns, value]: [ContactImportColumn[], ContactImportColumnMappings]) => {
                    // Filter out columns that are already mapped to another columns
                    const strip = Object.entries(value)
                        .filter(([key, value]) => !!value && key !== column)
                        .map(([, value]) => value?.columnNumber);
                    return of(columns.filter(({ columnNumber }) => !strip.includes(columnNumber)));
                }),
                takeUntil(this.destroy$),
                shareReplay(1),
            );

            const required = REQUIRED_COLUMNS.includes(column);
            const field = new RealmFormControl(
                column,
                { label: COLUMN_LABELS[column], updateOn: 'change' },
                required ? CustomRequiredValidator('Please select the column with matching data for the {field} field(s).') : null,
            );
            this.form.addControl(column, field);
        });
    }

    async import() {
        const { importId } = this.router.globals.params;
        const mappings = this.form.value as ContactImportColumnMappings;
        this.saving = true;
        try {
            await this.contactsImportService.saveMappingAndImport(importId, { mappings }).toPromise();
            this.notificationsService.add({
                message: 'Contacts Imported successfully.',
                type: GlobalNotificationType.POSITIVE,
            });
            this.navigateBackWOHistory();
        } catch (e) {
            const { error, status } = e as HttpErrorResponse;
            if ([403, 404, 500].includes(status)) {
                throw e;
            }
            this.form.setServerError(error);
        } finally {
            this.saving = false;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    navigateBackWOHistory() {
        return this.router.stateService.go('people.imports', {}, { location: 'replace' });
    }

    cancel() {
        const backState = this.historyLogService.findBackState([ '**.contacts-import.**' ], false);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }
        return this.router.stateService.go('people.contacts');
    }
}
