<div class="infinite-scroll" [ngClass]="options.holderClass">
	<div class="start-height-holder" [style.height.px]="topHeight" #topElement></div>
	<div class="infinite-scroll-entry"
		*ngFor="let entry of visibleElements"
		[infiniteScrollEntry]="entry"
		(onHeightCalculate)="setEntryHeight($event)"
		[ngClass]="options.entryClass"
		[ngStyle]="{minHeight: entry.height + 'px'}"
	>
		<ng-container *ngTemplateOutlet="entryTemplate; context: { $implicit: entry.data }"></ng-container>
	</div>
	<div class="loader remote-resource" *ngIf="!allowedBatchRequest && !lastPageReached"></div>
	<div class="end-height-holder" [style.height.px]="bottomHeight"></div>
</div>
