import { Injectable } from '@angular/core';
import { RealmFormControl } from 'commons/forms';
import { ContactList, ContactListClone } from '../investor-crm-contact-list.interface';


@Injectable()
export class InvestorCrmCloneContactListBuilderService {
    buildContactListClone(
        contactList: ContactList,
        includeManuallyAddedControl: RealmFormControl,
    ): ContactListClone {
        const includeManuallyAdded: boolean = this.extractIncludeManuallyAddedValue(includeManuallyAddedControl);
        const clonedFrom: number = contactList.id;

        const contactListClone: ContactListClone = {
            contactListDTO: contactList,
            includeManuallyAdded,
            clonedFrom,
        };

        return contactListClone;
    }

    extractIncludeManuallyAddedValue(
        includeManuallyAddedControl: RealmFormControl,
    ): boolean {
        const includeManuallyAdded: boolean = includeManuallyAddedControl.value;

        return includeManuallyAdded;
    }
}