import { Component, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';

import { map, toPairs } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import { PinValidator } from 'commons/validators';

import { LenderUsersResourceService } from '../users-resource.service';
import { SharedUserManagementResourceService } from 'shared/user-management/user-management-resource.service';

@Component({
	templateUrl: './my-settings.component.html',
})
export class LenderMySettingsComponent {
	editable: boolean = false;

	user: any = {};
	userSettings: any = {};

	settingsForm: any = new RealmFormGroup({
		securityPinCode: new RealmFormControl(
			'securityPinCode',
			{label: 'Security Pin'},
			Validators.compose([
				Validators.required,
				PinValidator('Pin code must be 4 digits'),
			]),
		),
	});
	saving: boolean = false;

    private changeOwnPasswordModalRef: BsModalRef;

	constructor(
		public User: UserService,
		public stateService: StateService,
		public LenderUsersService: LenderUsersResourceService,
		public modalService: BsModalService,
	) {}

	ngOnInit() {
		this.user = this.LenderUsersService.get({
			userId: this.User.profile.id,
		});

		this.userSettings = this.LenderUsersService.getSettings({
			userId: this.User.profile.id,
		});
	}

	toggleEditing(isEditing) {
		if (this.editable === isEditing) {
			return;
		}

		this.settingsForm.markAsPristine();
		this.settingsForm.reset();
		this.settingsForm.serverError = '';

		if (!isEditing) {
			this.editable = false;
			return;
		}

		const { securityPinCode } = this.userSettings;
		this.settingsForm.patchValue({ securityPinCode });
		this.editable = true;
	}

	hasError(fieldName) {
		const field = this.settingsForm.get(fieldName);
		return this.editable && field.invalid && field.touched;
	}

	getLabel(fieldName) {
		const field = this.settingsForm.get(fieldName);
		return field && (field as any).label;
	}

	save() {
		if (this.saving) {
			return;
		}

		this.saving = true;

		this.LenderUsersService.updateSettings({ userId: this.User.profile.id },
			{ ...this.settingsForm.value },
			() => {
				const data = { ...this.settingsForm.value };
				map(toPairs(data), ([fieldName, fieldValue]) => (this.userSettings[fieldName] = fieldValue));

				this.saving = false;
				this.editable = false;
			}, ({ data: response }) => {
				this.settingsForm.setServerError(response);
				this.saving = false;
			},
		);
	}

    public UserManagement = inject(SharedUserManagementResourceService);
    public passwordResent = false;
    resetPassword() {
        this.UserManagement.resendPassword({ userId: this.user.id });
        this.passwordResent = true;
    }
}
