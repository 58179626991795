<form [formGroup]="form" class="remote-resource" [class.resolved]="resolved" (onValidSubmit)="save()">
    <div class="review-header-wrapper flex-row gap-m">
        <h2>Technology & Operations</h2>
        <section-review [section]="sectionType" [allowEmpty]="false" class="flex-grow"></section-review>
        <ng-container *ngIf="canManage" [ngSwitch]="editing">
            <ng-container *ngSwitchDefault>
                <div>
                    <a class="btn btn-primary" (click)="startEditing()"><span class="glyphicon glyphicon-pencil"></span> Edit</a>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <div>
                    <button type="submit" class="btn btn-primary">Save</button>
                    <a class="btn btn-default" (click)="cancelEditing()">Cancel</a>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <form-errors [form]="form"></form-errors>
    <div class="rows-striped">
        <div class="row" *ngFor="let pair of fields">
            <ng-container *ngFor="let field of pair">
                <div class="col-md-6">
                    <div class="row form-group" has-error>
                        <div class="col-md-5 control-label" [class.required]="editing">{{ field.name }}</div>
                        <div class="col-md-6" [isEditable]="editing">
                            <ng-select edit
                                [items]="field.values"
                                bindLabel="name"
                                [multiple]="true"
                                [formControlName]="field.id"></ng-select>
                            <div view class="control-value">
                                <span>{{ values[field.id] | entityNames }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>
