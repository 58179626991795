import { Component, forwardRef, Input } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { PagedData } from 'commons/services/http';
import { ListComponent } from 'commons/components/list';
import { NewPagedListComponent } from 'commons/components/new-list/list.component';

import { hasContactPermission } from 'tpo/contacts/contact-permissions';

import {
    TpoContactsListParams,
    TpoContact,
} from '../contacts.interface';
import { TpoContactsService } from '../contacts.service';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { isNil, pickBy } from 'lodash';


@Component({
    selector: 'people-contacts-list',
    templateUrl: './list.component.html',
    viewProviders: [
        // TODO: required by SearchListComponent
        { provide: ListComponent, useExisting: forwardRef(() => TpoContactsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => TpoContactsListComponent) },
    ],
})
export class TpoContactsListComponent extends NewPagedListComponent<TpoContact> {
    static listName = 'tpoContactsList';

    @Input() isEmbedded?: boolean;
    @Input() isWizard?: boolean;
    filters: Partial<{
        previous: {
            [key in 'value' | 'title']: string;
        }[];
        contactType: {
            [key in 'value' | 'title']: string;
        }[];
    }> = {};

    user: UserProfile;
    exportUrl: string;

    constructor(
        router: UIRouter,
        { profile }: UserService,
        private resourceService: TpoContactsService,
    ) {
        super(router);

        this.user = profile;

        Object.assign(
            this.filters,
            {
                previous: [
                    { value: 'false', title: 'Current' },
                    { value: 'true', title: 'Previous' },
                ],
                contactType: [
                    { value: '', title: 'All' },
                    { value: 'individual', title: 'Users' },
                    { value: 'originator', title: 'NMLS ID' },
                ],
            }
        );

        Object.assign(
            this.defaultParams,
            {
                previous: 'false',
                contactType: '',
            }
        );

        this.nonResettableParams.push('previous');
        this.params$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(params => {
            const queryString = new HttpParams({ fromObject: pickBy(params, (val, key) => (!['page', 'size', 'sort'].includes(key) && !isNil(val))) }).toString();
            this.exportUrl = `/api/rest/tpos/${profile.organization.id}/contacts/export?${queryString}`;
        });
    }

    loadList(params: TpoContactsListParams): Observable<PagedData<TpoContact[]>> {
        return this.resourceService.getList(params);
    }

    hasPermission = (permission) => hasContactPermission(this.user, permission);
}
