<ng-container *ngIf="!editable">
	<ng-container *ngIf="view">
		<span class="control-value">{{ view }}</span>
	</ng-container>
	<ng-template *ngIf="!view" [ngTemplateOutlet]="viewTemplate"></ng-template>
</ng-container>

<ng-container *ngIf="editable">
	<ng-template [ngTemplateOutlet]="editTemplate"></ng-template>
</ng-container>
