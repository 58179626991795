import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { NgSelectConfig } from '@ng-select/ng-select';
import { CellSelectModalComponent } from 'commons/components/cell-select/cell-select-modal.component';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';

export type CellSelectConfig = {
        required?: true;
        message: string;
        clearable?: boolean;
        title?: string;
        label: string;

    }
    & Pick<NgSelectConfig, 'bindLabel' | 'bindValue'>
    & Partial<Pick<NgSelectConfig, 'placeholder'>>;

@Component({
    selector: 'cell-select',
    template: `
        <span class="glyphicon glyphicon-pencil" *ngIf="editable"></span>
        <span class="text-default"><ng-content></ng-content></span>
    `,
    host: {
        'class': '',
        '(click)': 'click()',
    },
})
export class CellSelectComponent<T = unknown> {
    // simple optimizations
    @HostBinding('class.btn') @HostBinding('class.btn-tertiary') protected _editable = false;
    @HostBinding('class.control-value') protected _readOnly = true;

    @Input('editable') private set __editable(value: boolean) {
        this._editable = value;
        this._readOnly = !this._editable;
    }
    get editable(): boolean {
        return this._editable;
    }
    @Input() config: CellSelectConfig;

    @Output('open') protected openEmitter = new EventEmitter<CellSelectComponent>();
    @Output('save') protected saveEmitter = new EventEmitter<[CellSelectComponent, T]>();

    private modalRef: BsModalRef<CellSelectModalComponent>;

    constructor(
        protected readonly modalService: BsModalService,
    ) {
    }

    async click(): Promise<void> {
        if (!this._editable) return;

        this.openEmitter.emit(this);


    }

    public open(items$: Observable<unknown>, value: unknown) {
        const initialState: Partial<CellSelectModalComponent> = {
            items$,
            value,
            config: this.config,
            onConfirm: (value) => {
                this.saveEmitter.emit([this, value]);
            },
        };

        this.modalRef = this.modalService.show<CellSelectModalComponent>(
            CellSelectModalComponent,
            {
                initialState,
                class: 'modal-new modal-smd',
            },
        );
    }

    public setResolving(resolving: boolean) {
        this.modalRef.content.resolving = resolving;
    }

    public setError(error: ServerErrorResponse) {
        this.modalRef.content.setServerError(error);
    }

    public close() {
        this.modalRef.hide();
    }
}
