<div class="gray-head flex-head">
	<h2>
		Archive
		<smHelpLink [url]="helpLink" *ngIf="User?.isTpo"></smHelpLink>
	</h2>

	<notification></notification>
	<div class="col-min">
		<ul class="tab-menu">
			<ng-container *ngFor="let item of items">
				<li uiSrefActive="active">
					<a uiSref="{{item.sref}}">
						<b>{{item.title}}</b>
					</a>
				</li>
			</ng-container>
		</ul>
	</div>
</div>
<ui-view></ui-view>
