import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity } from 'tpo/documents/documents.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChangeOptionalStatusModal } from 'lender/applications/optional-status/change-optional-status.modal';

@Component({
    selector: 'optional-status',
    templateUrl: './optional-status.component.html',
})
export class OptionalStatusComponent {
    @Input() applicationId!: number;
    @Input() status!: Entity;
    @Input() statusList!: Entity[];
    @Input() canEdit: boolean;
    @Output() onUpdate = new EventEmitter<Entity>();

    private modalRef: BsModalRef;

    constructor(
        private readonly modalService: BsModalService,
    ) {
    }

    showEditModal() {
        const { status, statusList, applicationId } = this;
        const onUpdate = (newStatus: Entity) => {
            this.onUpdate.emit(newStatus);
        };

        this.modalRef = this.modalService.show(
            ChangeOptionalStatusModal,
            {
                initialState: { status, statusList, onUpdate, applicationId },
                backdrop: 'static',
                class: 'modal-new modal-smd',
            },
        );
    }
}
