import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { ArchiveComponent } from './archive.component';
import { TpoArchiveListComponent } from './list';
import { TPOArchiveService } from './archive.service';
import { TPOArchiveResourceService } from './archive-resource.service';
import { PostHistoryModalComponent } from './post-history-modal';
import { OwnersModalComponent } from './owners-modal';
import { ArchiveNoPermissionsComponent } from './archive-no-permissions';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DirectivesModule,
		ComponentsModule,
		BsDropdownModule.forRoot(),
		PopoverModule.forRoot(),
	],
	declarations: [
		ArchiveComponent,
		TpoArchiveListComponent,
		PostHistoryModalComponent,
		OwnersModalComponent,
		ArchiveNoPermissionsComponent,
	],
	// entryComponents: [ Entry components are deprecated! @SEE https://angular.io/guide/entry-components
	// 	ArchiveComponent,
	// 	TpoArchiveListComponent,
	// 	PostHistoryModalComponent,
	// 	OwnersModalComponent,
	// 	ArchiveNoPermissionsComponent,
	// ],
	providers: [
		TPOArchiveService,
		TPOArchiveResourceService,
	],
	exports: [
		ArchiveNoPermissionsComponent,
	],
})
export class TpoArchiveModule {}
