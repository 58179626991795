<div class="gray-head flex-head">
    <h2>
        {{pageTitle}}
    </h2>
</div>

<div class="remote-resource" [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div *ngFor="let column of channelColumnsAndHeaders" class="{{column.columnClass}}">
                {{column.columnName}}
            </div>
        </div>
    </div>
    <div *ngIf="!resolving && ((list | async)?.length > 0); else noHistory" class="rows-bordered">
        <div *ngFor="let item of list | async" class="row-fluid">
            <div *ngIf="useCustomHistoryEntryLine; else default">
                <ng-container *ngTemplateOutlet="historyActionsStrategy.historyEntryTemplate; context: { $implicit: item }"></ng-container>
            </div>
            <ng-template #default>
                <div class="col-md-4">
                    {{item.date | date: 'MM/dd/yyyy HH:mm'}}
                </div>
                <div class="col-md-4">
                    {{item.action}}
                </div>
                <div class="col-md-4">
                    {{item.actionBy}}
                </div>
            </ng-template>
        </div>
    </div>
    <ng-template #noHistory>
		<div class="rows-bordered">
			<div class="row-fluid">
				<span class="col-sm-12 text-center text-light text-bigger va-m">
					No records found
				</span>
			</div>
		</div>
    </ng-template>
    <br>
    <paginator [sizes]="[10,25,50]"></paginator>
</div>
