import { Component, forwardRef, Input } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { Observable } from 'rxjs';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { PagedData } from 'commons/services/http';

import { ConfirmModalComponent } from 'commons/components/modals';
import { ListComponent } from 'commons/components/list';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';

import { ContactList, ContactListContact } from '../../../investor-crm-contact-list.interface';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvestorCrmContactListService } from '../../../services/investor-crm-contact-list.service';


@Component({
    selector: 'contact-list-contacts-list',
    templateUrl: './contact-list-contacts-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => ContactListContactsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => ContactListContactsListComponent) },
    ],
})
export class ContactListContactsListComponent extends NewPagedListComponent<ContactListContact> {
    static listName = 'contactListContactsListComponent';

    @Input() userCanManageContactLists: boolean = false;
    @Input() existingContactList: ContactList;

    userProfile: UserProfile;
    organizationId: number;
    userCanAddContactToList: boolean = false;
    userCanExportContactList: boolean = false;
    userCanDeleteContactFromList: boolean = false;
    exportDownloadUrl: string = '';

    private bsModalRef: BsModalRef;

    constructor(
        router: UIRouter,
        private readonly investorCrmContactListService: InvestorCrmContactListService,
        private readonly modalService: BsModalService,
        private readonly userService: UserService,
    ) {
        super(router);

        this.userProfile = this.userService.profile;
        this.organizationId = this.userProfile.organization.organizationId;
        this.userCanAddContactToList = this.userProfile.can('ADD_CONTACT_TO_LIST');
        this.userCanExportContactList = this.userProfile.can('EXPORT_CONTACT_LIST');
        this.userCanDeleteContactFromList = this.userProfile.can('DELETE_CONTACT_FROM_LIST');
    }

    // Override
    ngOnInit(): void {
        this.exportDownloadUrl = `/api/download/contact/list/${this.organizationId}/export/${this.existingContactList.id}`;

        super.ngOnInit();
    }

    // Override
    protected loadList(params: ListParams): Observable<PagedData<ContactListContact[]>> {
        const organizationId: number = this.userProfile.organization.organizationId;
        const contactListId: number = this.existingContactList.id;

        return this.investorCrmContactListService.getContactListContacts(organizationId, contactListId, params);
    }

    async removeFromList(contact: ContactListContact): Promise<void> {
        const initialState = {
            title: 'Please Confirm',
            confirmText: 'Remove',
            cancelText: 'Cancel',
            message: `Please confirm removing from the <strong>${this.existingContactList.name}</strong> List`,
            onConfirm: async (): Promise<void> => {
                this.bsModalRef.content.resolving = true;
                try {
                    await this.investorCrmContactListService
                        .deleteContactFromList(
                            this.organizationId,
                            this.existingContactList.id,
                            contact.organizationContactId
                        )
                        .toPromise();

                    this.bsModalRef.content.resolving = false;
                    this.bsModalRef.hide();

                    this.updateList();
                } catch ({ error: { message }, message: httpError }) {
                    this.bsModalRef.content.errorText = message;

                    this.bsModalRef.content.resolving = false;
                }
            },
        };
        this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }
}
