<div class="remote-resource document-modal-form" [class.resolved]="!saving">
	<div class="modal-header">
		<button type="button" *ngIf="!saving" class="close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">{{title}}</h4>
	</div>
	<form [formGroup]="assignForm" id="assignForm" (onValidSubmit)="save()" autocomplete="off" role="presentation">
		<div class="modal-body" [class.curtain]="saving">
			<form-errors [form]="assignForm"></form-errors>

            <notification-users-form
                [form]="assignForm"
                [service]="service || clientDocumentsResource"
                [params]="getResourceParams()"
            ></notification-users-form>
		</div>
		<div class="modal-footer" [class.curtain]="saving">
			<div class="text-right">
				<input
					type="submit"
					class="btn btn-primary"
					[value]="'Request'"
					tabindex="7"
				>
				<a (click)="onCancel()" tabindex="8" class="btn btn-default">Cancel</a>
			</div>
		</div>
	</form>
</div>

