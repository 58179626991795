import { Component, forwardRef } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { CcmDataMatchHistoryInterface } from 'comergence/data-match/ccm-data-match.interface';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { StateService, UIRouter } from '@uirouter/core';
import { CcmDataMatchService } from 'comergence/data-match/ccm-data-match.service';
import { ListComponent } from 'commons/components/list';
import { statusConfig } from '../statusConfig';

@Component({
    templateUrl:'ccm-data-match-history.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => CcmDataMatchHistoryComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => CcmDataMatchHistoryComponent) },
    ],
})
export class CcmDataMatchHistoryComponent extends NewPagedListComponent<CcmDataMatchHistoryInterface> {
    static listName = 'dataMatchHistoryList';
    statusConfig = statusConfig;

    constructor(
        private stateService: StateService,
        router: UIRouter,
        private ccmDataMatchService: CcmDataMatchService,
    )
    {
        super(router);
    }

    protected loadList(params: ListParams): Observable<PagedData<CcmDataMatchHistoryInterface[]>> {
        return this.ccmDataMatchService.getDataMatchHistoryList(params);
    }

    exportReport(id: number) {
        window.open(`api/rest/datamatch/${id}/report`, '_blank');
    }
}
