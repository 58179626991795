<div class="remote-resource campaigns-enroll" [class.resolved]="resource.$resolved">
	<div class="modal-header">
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
		<h4 class="modal-title">Enroll</h4>
	</div>
	<div class="modal-body max-h300">
		<div *ngFor="let type of accountTypes">
			<label class="sm-account flex-row">
				<input [(ngModel)]="type.enrolled" (change)="typeChange($event)" type="checkbox" *ngIf="false"/>&nbsp;&nbsp;
				<b>{{type.name}} Accounts</b>
			</label>
			<ng-container *ngFor="let i of type.indexes;">
				<label class="sm-account has-action flex-row" [ngClass]="{danger: !resourceCopy[i].connected}">
					<input [(ngModel)]="resource[i].enrolled" (change)="hasChanges()" type="checkbox" />&nbsp;&nbsp;
					<sm-icon [config]="resource[i]" [minified]="true"></sm-icon>
					<div class="flex text-overflow">
						{{resource[i].name}} <br>
					</div>
					<span *ngIf="!resource[i].connected" class="text-default"><i class="dot dot-danger block"></i>&nbsp;Disconnected</span>
				</label>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" [disabled]="disableSaveBtn" (click)="onConfirm()"  class="btn btn-primary">Save</button>
		<button type="button" (click)="modalRef.hide()" class="btn btn-default">Cancel</button>
	</div>
</div>
