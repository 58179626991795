import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';
import { NgResourceObject } from 'commons/interfaces';
import { ConfirmModalComponent } from 'commons/components/modals';

import { LenderIssuesStatusService } from 'lender/status/lender-issues-status.service';

import { DocuSignStatus, DocuSignStatuses } from '../documents.interface';
import { GlobalDocumentsService } from '../documents.service';

declare let window: Window;
declare let screen: Screen;

@Component({
	templateUrl: './docusign.component.html',
})
export class DocusignComponent implements OnInit {
	User: UserProfile;
	docuSignStatus: NgResourceObject<DocuSignStatus> = {};
	docuSignStatuses = DocuSignStatuses;
	docusignTokenStatus: Observable<boolean>;
	helpRef = 'https://o.comergencesupport.com/en/support/solutions/articles/8000098183-connecting-docusign';
	modalRef: BsModalRef;

	constructor(
		{ profile }: UserService,
		private documentsService: GlobalDocumentsService,
		private modalService: BsModalService,
		private readonly lenderIssuesStatusService: LenderIssuesStatusService,
	) {
		this.User = profile;
		this.loadDocuSignStatus();
	}

	public ngOnInit(): void {
		this.docusignTokenStatus = this.lenderIssuesStatusService.documents.docusign.get();
	}

	private async loadDocuSignStatus(): Promise<void> {
		try {
			this.docuSignStatus = await this.documentsService.getDocusignStatus().$promise;
			this.lenderIssuesStatusService.documents.docusign.update();
		} catch (e) {}
	}

	connect(): void {
		this.openConnectWindow().then(() => {
			this.loadDocuSignStatus();
		})
	}

	openConnectWindow(): Promise<void> {
		const url = `/api/rest/lenders/${this.User.organization.id}/docmanagement/docusign/connect`;
		const width = 400;
		const height = 500;
		const top = (screen.height - height) / 2;
		const left = (screen.width - width) / 2;

		return new Promise((resolve) => {
			const connectorWindow = window.open(
				url,
				'DocuSign',
				`width=${width},height=${height},top=${top},left=${left}`
			);

			const antiBlur = () => {
				connectorWindow.focus();
			}
			window.addEventListener('click', antiBlur);

			this.docuSignStatus.$resolved = false;
			const interval = window.setInterval((() => {
				if (connectorWindow.closed) {
					window.clearInterval(interval);
					window.removeEventListener('click', antiBlur);
					resolve();
				}
			}), 500);
		});
	}

	private disconnect(): void {
		const initialState = {
			title: 'Revoke DocuSign',
			message: 'Any documents that have already been assigned and are still required, will remain in a required state but will NOT be able to be signed electronically.',
			onConfirm: async () => {
				this.modalRef.content.resolving = true;
				try {
					await this.documentsService.disconnectDocuSign().$promise;
					this.modalRef.hide();
					this.loadDocuSignStatus();
				} catch (error) {
					if (error.data) {
						this.modalRef.content.errorText = error.data.message;
					}
				}
				this.modalRef.content.resolving = false;
			},
			onCancel: () => {
				this.modalRef.hide();
			},
		};
		this.modalRef = this.modalService.show(
			ConfirmModalComponent,
			{
				initialState,
				class: 'modal-new modal-smd',
				backdrop: 'static',
			},
		);
	}
}
