import {without} from 'lodash';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'arrayFilter',
})
export class ArrayFilterPipe implements PipeTransform {
	/* Function is too performance heavy */
	transform(items: any[]): any[] {
		if (!items) { return []; }

		return without(items, '', null, undefined);
	}
}
