import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Error40xComponent } from 'commons/services/error/error-40x.component';
import { ErrorModalService } from 'commons/services/error/error-modal.service';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        BsDropdownModule.forRoot(),
    ],
    declarations: [
        Error40xComponent,
    ],
    providers: [
        ErrorModalService,
    ],
    exports: [
        Error40xComponent,
        // ErrorModalService,
    ],
})
export class ErrorModalsModule {
}
