<scorecard-header></scorecard-header>

<scorecard-comments-form
    *ngIf="!resolving && (list | async)"
    [tpoId]="tpoId"
    (commentSubmitted)="refreshPage()"
></scorecard-comments-form>
&nbsp;
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="rows-bordered" *ngIf="!resolving && ((list | async)?.length > 0); else noComments">
        <div class="row-fluid" *ngFor="let item of list | async">
            <div class="row">
                <div class="col-md-8">
                    <b>{{item.createdBy}}</b>
                </div>
                <div class="col-md-4">
                    <div class="comment-info text-light text-smaller">
                        <div class="row">
                            <div class="col-md-8 text-right">
                                {{item.commentType}}
                            </div>
                            <div class="col-md-4 text-right">
                                {{item.createdDate | date: 'MM/dd/yyyy HH:mm'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            &nbsp;
            <p class="comment">{{item.comment}}</p>
        </div>
    </div>
    <ng-template #noComments>
        <span class="col-md-12 text-muted text-center text-bigger">No comments</span>
    </ng-template>

    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>
