import { ColumnConfig } from 'commons/mixins';

export const columnsConfig: ColumnConfig[] = [
	{
		id: 'locationName',
		name: 'Location',
		isDefault: true,
	},
    {
		id: 'prospectStatus',
		name: 'Status',
		isDefault: true,
	},
    {
		id: 'assigneeName',
		name: 'Assigned',
		isDefault: true,
	},
    {
		id: 'totalVolume',
		name: 'Total Vol.',
		isDefault: true,
	},
	{
		id: 'individualNmlsCount',
		name: 'Ind. NMLS',
		isDefault: true,
	},
	{
		id: 'brokerVolume',
		name: 'Broker Vol.',
	},
	{
		id: 'soldVolume',
		name: 'Sold Vol.',
	},
];
