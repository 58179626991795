import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';

import { UserService } from 'angularjs-providers/user.provider';

import { ReviewComponent } from '../review.component';
import { Review } from '../../../reviews.interface';
import { ReviewsResourceService } from '../../../reviews-resource.service';

@Component({
	selector: 'facebook-review',
	templateUrl: './facebook-review.component.html',
})
export class FacebookReviewComponent extends ReviewComponent {
	@Input() review: Review;

	modalRef: BsModalRef;

	constructor(
		public userService: UserService,
		public stateService: StateService,
		public modalService: BsModalService,
		public reviewsResource: ReviewsResourceService,
    ) {
		super(userService, stateService, modalService, reviewsResource);
	}

	onOpenReplyForm(reviewId): void {
		if (!this.review.hasConnectedListing) {
			this.openedReplyForms[reviewId] = false;
			this.showAccountDisconnectedModal(this.review);
			return;
		}

		this.openedReplyForms[reviewId] = true;
	}

	get isRecommended(): boolean {
		return this.review?.scores?.[0]?.score > 2.5;
	}
}
