import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import { ClientInterface } from 'shared/global-search/clients/client.interface';
import { IndividualsInterface } from 'shared/global-search/individulals/individuals.interface';
import { UsersInterface } from 'shared/global-search/users/users.interface';

declare let apiPath: string;

@Injectable()
export class GlobalSearchService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {}

    public getClientsList(params): Observable<PagedData<ClientInterface[]>> {
       return this.http.pagedRequest<ClientInterface[]> (
            'GET' ,
            `${apiPath}/search/clients`,
            params
       );
    }

    public getIndividualsList(listParams): Observable<PagedData<IndividualsInterface[]>> {
        return this.http.pagedRequest<IndividualsInterface[]>(
            'GET',
            `${apiPath}/search/individuals`,
            listParams
        );
    }

    public getUsersList(listParams): Observable<PagedData<UsersInterface[]>> {
       return this.http.pagedRequest<UsersInterface[]>(
            'GET',
            `${apiPath}/search/users`,
            listParams
       );
    }
}
