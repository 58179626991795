<div class="remote-resource" [class.resolved]="resolved">
    <h2 class="flex-row">
        Channels
    </h2>
    <div *ngIf="resolved">
        <two-column-card-container
            [card]="cardTemplate"
            [items]="channels"
        >
        </two-column-card-container>
    </div>
</div>

<ng-template #cardTemplate let-item>
    <channel-summary-card
        *ngIf="item"
        [tpoId]="lenderInfo.identifier.tpoId"
        [channel]="item"
        [investorIsRegistered]="investorIsRegistered"
        [lenderInfo]="lenderInfo"
        [totalChannels]="channels.length"
        [statusTransitions]="transitions"
        [userProfile]="userProfile"
        class="col-xs-12"
    ></channel-summary-card>
</ng-template>
