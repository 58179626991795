export class MapUtils {
    static mapByObjectKey<K extends (number | string), V>(
        array: V[],
        key: keyof V
    ): Map<K, V> {
        const map: Map<K, V> = array.reduce(
            (map, item) => {
                map.set(item[key] as any, item);

                return map;
            },
            new Map<K, V>()
        );

        return map;
    }
}
