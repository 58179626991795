<div class="document-history rows-bordered remote-resource" [class.resolved]="list.$resolved && !resolving">
	<div class="row-fluid header">
		<div class="col-sm-2"><b class="two-liners">Date</b></div>
		<div class="col-sm-2"><b class="two-liners">Action</b></div>
		<div class="col-sm-3"><b class="two-liners">Action by</b></div>
		<div class="col-sm-3"><b class="two-liners">Document</b></div>
        <div class="col-sm-2" *ngIf="isLender"><b class="two-liners">Last Reviewed</b></div>
	</div>
	<div class="row-fluid" *ngFor="let item of list">
		<div class="col-sm-2 va-m">{{item.date | date : 'MM/dd/yyyy HH:mm'}}</div>
		<div class="col-sm-2 va-m">
			<ng-container [ngSwitch]="item.type">
				<a *ngSwitchCase="DocumentHistoryType.REQUEST" (click)="showRequestHistoryModal(item)">{{item.action}}</a>
				<a *ngSwitchCase="DocumentHistoryType.WAIVE" (click)="showWaiveHistoryModal(item)">{{item.action}}</a>
				<ng-container *ngSwitchDefault>{{item.action}}</ng-container>
			</ng-container>
		</div>
		<div class="col-sm-3 va-m">{{item.actionBy}}</div>
		<div class="col-sm-3 va-m">
			<ng-container [ngSwitch]="!!item.fileName">
				<ng-container *ngSwitchCase="true">
					<ng-container [ngSwitch]="!!item.downloadLink">
						<a *ngSwitchCase="true" [href]="item.downloadLink" target="_blank">{{item.fileName}}</a>
						<ng-container *ngSwitchCase="false">{{item.fileName}}</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="false">&ndash;</ng-container>
			</ng-container>
		</div>
        <div class="col-sm-2" *ngIf="isLender">
            <button
                *ngIf="!item.reviewedBy && canManageDocuments && item.hasUploadedDocument && item.clientDocumentRequestId"
                class="btn btn-primary w-auto"
                (click)="markDocumentReviewed(item)"
            >
                Mark Reviewed
            </button>
            <div *ngIf="item.reviewedBy">
                <span class="col-sm-2 remove-padding">
                    <div class="glyphicon glyphicon-ok-sign glyphicon-object-align-vertical text-success medium-icon"></div>
                </span>
                <span class="col-sm-9 remove-padding">
                    <div class="text-small font-bold">Reviewed by</div>
                    <div class="text-small text-muted">{{item.reviewedBy}}</div>
                    <div class="text-small text-muted w-auto">{{item.reviewedDate | date: 'MM/dd/yyyy HH:mm'}}</div>
                </span>
            </div>
        </div>
	</div>

	<div class="row-fluid" *ngIf="list.$resolved && list.length === 0">
		<div class="col-sm-12 text-center va-m text-bigger text-muted">No history</div>
	</div>

	<br>

	<new-paging [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
</div>
