import { Component, forwardRef, OnInit } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { IndividualsInterface } from 'shared/global-search/individulals/individuals.interface';
import { PagedData } from 'commons/services/http';
import { Observable } from 'rxjs';
import { ListComponent } from 'commons/components/list';
import { UIRouter } from '@uirouter/core';
import { GlobalSearchService } from 'shared/global-search/global-search.service';
import { GlobalSearchDataService } from 'shared/global-search/global-search-data.service';
import { tap } from 'rxjs/operators';

@Component({
    templateUrl: 'individuals.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => SearchIndividualsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => SearchIndividualsListComponent) },
    ],
})
export class SearchIndividualsListComponent extends NewPagedListComponent<IndividualsInterface> implements OnInit {
    static listName = 'globalSearchIndividuals';
    constructor(
        router: UIRouter,
        private globalSearchService: GlobalSearchService,
        private globalSearchDataService: GlobalSearchDataService,
        )
    {
        super(router);
        this.globalParams.push('q');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.globalSearchDataService.resetIndividualCount();
    }

    protected loadList(params: ListParams): Observable<PagedData<IndividualsInterface[]>> {
        return this.globalSearchService.getIndividualsList(params).pipe(
            tap(data => {
                this.globalSearchDataService.getIndividualCount(data);
            })
        );
    }
}
