import { Injectable, Injector } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class ChecklistSettingsService {
	regulations: any;
	stateChecks: any;
	groups: any;

	constructor(
		injector: Injector,
		userService: UserService,
	) {
		const $resource = injector.get('$injector').get('$resource');

		const User = userService.profile;

		const basePath = `${apiPath}/tpos/:tpoId/social-media-compliance/checklist`;
		const baseParams = {
			tpoId: User.organization.id,
		};
		const regulationsConfig = {
			url: `${basePath}/federal`,
			params: {
				...baseParams,
			},
		};
		const stateChecksConfig = {
			url: `${basePath}/state`,
			params: {
				...baseParams,
			},
		};

		this.regulations = $resource(
			regulationsConfig.url,
			regulationsConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				update: {
					method: 'PUT',
					url: `${regulationsConfig.url}/observations`,
					isArray: true,
				},
			},
		);

		this.groups = $resource(
			`${apiPath}/tpos/:tpoId/social-media-compliance/groups`,
			regulationsConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.stateChecks = $resource(
			stateChecksConfig.url,
			stateChecksConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
				update: {
					method: 'PUT',
					url: `${stateChecksConfig.url}/observations`,
					isArray: true,
				},
			},
		);
	}
}
