import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hrefReady',
})
export class HrefReadyPipe implements PipeTransform {
	transform(text: string): string {
		const URLIZE_URL_REGEXP = /^((mailto:)?([A-Za-z0-9._%+-]+@)|(ftp|https?:\/\/))?(.+)$/i;
		//    						1    2              3                  4             5

		if (!text) { return text; }
		const match = text.match(URLIZE_URL_REGEXP);
		if (!match) { return text; }
		let proto = '';
		if (!match[1]) {
			proto = 'http://';
		} else {
			if (match[3]) {
				proto = `mailto:${match[3]}`;
			} else {
				proto = match[4];
			}
		}

		return proto + match[5];
	}
}
