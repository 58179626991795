import { Component } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { DocumentComponent, DocumentStatus } from 'shared/documents';

import { RequestDocumentModalComponent } from '../../../common';

import { ClientLibraryDocument } from '../../library-documents.interface';
import { ClientLibraryDocumentsService } from '../../library-documents.service';

@Component({
	selector: 'client-library-document-list-item',
	templateUrl: './item.component.html',
})
export class ClientLibraryDocumentListItemComponent extends DocumentComponent<ClientLibraryDocument> {
	modalRef: BsModalRef;

	constructor(
		private uiRouterGlobals: UIRouterGlobals,
		private stateService: StateService,
		userService: UserService,
		private clientLibraryDocumentsService: ClientLibraryDocumentsService,
		private modalService: BsModalService,
	) {
		super(userService);
	}

	getDownloadLink(type: 'template' | 'uploadedDocument'): string {
		const { id: tpoId } = this.uiRouterGlobals.params;
		const { libraryId } = this.document;
		return this.clientLibraryDocumentsService.getDownloadLink({
			tpoId,
			libraryId,
			type,
		});
	}

	async request(): Promise<void> {
		const { id: tpoId } = this.uiRouterGlobals.params;
		const { libraryId } = this.document;
		const params = {
			tpoId,
			libraryId,
		};
		const initialState = {
			sendRequest: this.clientLibraryDocumentsService.requestDocument,
			service: this.clientLibraryDocumentsService,
			document: this.document,
			reload: (updatedDocument) => {
				Object.assign(
					this.document,
					updatedDocument,
				);
			},
			getResourceParams: () => params,
		};
		this.modalRef = this.modalService.show(
			RequestDocumentModalComponent,
			{
				initialState,
				backdrop: 'static',
				class: 'modal-new modal-smd',
			},
		);
	}

	showHistory(): void {
		const { libraryId } = this.document;
		this.stateService.go('.:libraryId.history', { libraryId });
	}
}
