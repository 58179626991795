<div
	*ngIf="attachments?.length"
	class="social-media-post-attachments"
>
	<ng-container *ngIf="previewEnabled">
		<attachments-preview
			[ngClass]="networkCode"
			[networkCode]="networkCode"
			[attachments]="attachments"
			[weedOutGif]="false"
			[attachmentNgClass]="{ 'glyphicon': isVideo, 'video-preview': isVideo, 'attachment-share': isShare }"
			(onAttachmentClick)="openFullScreenLightbox($event.attachments, $event.index)"
			(isRendered)="isRendered.emit($event)"
		></attachments-preview>
	</ng-container>
	<ng-container *ngIf="!previewEnabled">
		<ng-container *ngFor="let attachment of attachments; let index = index">
			<div
				*ngIf="attachment.videoImageUrl || attachment.sourceUrl"
				[class.video-preview]="attachment.videoImageUrl"
			>
				<a (click)="openFullScreenLightbox(attachments, index)">
					<img src="{{attachment.videoImageUrl || attachment.sourceUrl}}"/>
				</a>
			</div>
		</ng-container>
	</ng-container>
</div>
