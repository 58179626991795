<form [formGroup]="form" class="form-horizontal mb30" (onValidSubmit)="save()">
    <form-errors [form]="form"></form-errors>

    <div class="flex-row align-bottom mb5 gap">
        <label class="control-label text-smaller">
            Products Overview
            <span *ngIf="editing()"
                  class="text-muted text-big glyphicon glyphicon-question-sign"
                  placement="top"
                  tooltip="Links must be formatted https://"
            ></span>
        </label>
        <div class="flex-grow"></div>
        <ng-container [ngSwitch]="editing()">
            <a *ngSwitchDefault class="btn btn-primary" (click)="edit()"><span class="glyphicon glyphicon-pencil"></span> Edit</a>
            <ng-container *ngSwitchCase="true">
                <button [className]="" class="btn btn-primary">Publish</button>
                <a class="btn btn-secondary" (click)="cancel()">Cancel</a>
            </ng-container>
        </ng-container>
    </div>
    <div [ngSwitch]="editing()">
        <quill-view *ngSwitchDefault [content]="solutionCenter.productOverview()?.description" [sanitize]="true" theme="snow"></quill-view>
        <ng-container *ngSwitchCase="true">
            <quill-editor [styles]="{ height: '200px' }" formControlName="description" theme="snow" [maxLength]="maxLength"></quill-editor>
        </ng-container>
    </div>
</form>

<!-- can be extracted as a separate component -->
<ng-container *ngIf="solutionCenter.productList() as products">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-6">Product Name</div>
            <div class="col-md-3"><!-- Plan Name --></div>
            <div class="col-md-1">Product Visibility</div>
            <div class="col-md-2"></div>
        </div>
        <div class="row-fluid" *ngFor="let product of products">
            <div class="col-md-6 control-value">{{ product.productName }}</div>
            <div class="col-md-3"></div>
            <div class="col-md-1">
                <toggle
                    [ngModel]="product.visibilityAllowedByCustomer"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="!product.visibilityAllowedByComergence"
                    [loading]="product.loading"
                    (toggle)="changeProductVisibility(product)"
                ></toggle>
            </div>
            <div class="col-md-2">
                <div class="pull-right">
                    <button class="btn btn-primary" [disabled]="product.loading" (click)="openManageModal(product)">Manage</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
