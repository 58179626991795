import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { AbstractNavigationMenuComponent } from '../abstract-navigation-menu.component';


@Component({
	selector: 'sidenav-menu',
	templateUrl: './sidenav-menu.component.html',
	// changeDetection: ChangeDetectionStrategy.OnPush, // TODO: menu should listen to all changes, but it is extremely inefficient
})
export class SidenavMenuComponent extends AbstractNavigationMenuComponent {
	private _narrow: boolean = false;
	@Input() set narrow(value) {
		this._narrow = value;
	}
	get narrow() {
		return this._narrow;
	}

	private _expanded: boolean = true;
	@Input() set expanded(value) {
		this._expanded = value;
	}
	get expanded() {
		return this._expanded;
	}

	private _visible: boolean = false;
	get visible() {
		return this._visible;
	}

    @Input() hackUsedByNewAngular: boolean = true;
    @HostBinding('class.side-menu-element') _elementClass = true;

    private listeningForCollapseClick = false;

	constructor() {
        super();
    }

	toggleExpanded(expanded = !this.expanded): void {
		this.expanded = expanded;
	}

	toggleVisible(visible = !this.visible): void {
		this._visible = visible;

        // Clicking the icon to open menu, then clicking the icon again to close the menu =>
        // handleClick() won't catch setting the listening flag back to false
        if (!this.visible) {
            this.listeningForCollapseClick = false;
        }
	}

    @HostListener('body:click')
    public clickEvent(): void {
        this.handleClick();
    }

    @HostListener('body:touchend')
    public touchEndEvent(): void {
        this.handleClick();
    }

    private handleClick(): void {
        if (this.visible) {
            if (this.listeningForCollapseClick) {
                this.toggleVisible(false);
                // listeningForCollapseClick is set to false at the end of toggleVisible()
            } else {
                this.listeningForCollapseClick = true;
            }
        }
    }
}
