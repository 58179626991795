<ng-container *ngIf="appStatuses">
    <div class="dropdown" dropdown>
        <a class="btn btn-primary btn-dropdown dropdown-toggle" dropdownToggle placement="top">
            <span>Status</span>
            <span class="caret"></span>
        </a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem" *ngFor="let status of appStatuses[applicationStatusId]?.availability">
                <a (click)="setAppStatus(status)">{{ statusActionName[status.id] }}</a>
            </li>
        </ul>
    </div>
</ng-container>
