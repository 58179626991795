import { Component, OnDestroy, OnInit } from '@angular/core';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';

import { NgResourceObject } from 'commons/interfaces';

import { GlobalDocument } from '../documents.interface';
import { GlobalDocumentsService } from '../documents.service';

interface Tab {
	state: string;
	title: string;
}

@Component({
	templateUrl: './document.component.html',
})
export class GlobalDocumentComponent implements OnInit, OnDestroy {
	title: string;
	tabs: Tab[];
	private deregisterTransitionHook;

	constructor(
		private transitionService: TransitionService,
		private uiRouterGlobals: UIRouterGlobals,
		private documentsService: GlobalDocumentsService,
	) {}

	ngOnInit(): void {
		this.init();

		this.deregisterTransitionHook = this.transitionService.onSuccess(
			{
				to: 'documents.**',
			},
			() => {
				this.init();
			},
		);
	}

	ngOnDestroy(): void {
		this.deregisterTransitionHook();
	}

	private async init(): Promise<void> {
		const {
			name: stateName,
		} = this.uiRouterGlobals.current;

		if (/^documents\.new$/.test(stateName)) {
			this.title = 'New Document';
			return;
		}

		this.tabs = [
			{
				state: '.',
				title: 'Settings',
			},
			{
				state: '.history',
				title: 'History',
			},
		];

		if (/^documents\.:documentId$/.test(stateName)) {
			this.title = 'Document Details';
			return;
		}

		if (/^documents\.:documentId/.test(stateName)) {
			try {
				this.title = '';
				const { documentId } = this.uiRouterGlobals.params;
				const {
					name: documentName,
				}: NgResourceObject<GlobalDocument> = await this.documentsService.getDocument({
					documentId,
				}).$promise;
				this.title = documentName;
			} catch (e) {}
			return;
		}
	}
}
