import { Injector, Injectable } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath: string;

@Injectable()
export class SharedUsersResourceService {
	users: any;

	constructor(
		public injector: Injector,
		public user: UserService,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const path = `${apiPath}/organizations/:organizationId/users/all/page`;

		this.users = PagedResource(path, { organizationId: this.user.profile.organization.organizationId }, {
			get: {
				method: 'GET',
				isArray: true,
			},
		});
	}

	get = (...args) => this.users.get(...args);
}
