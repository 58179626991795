<section>
	<h3>
		Visibility
	</h3>
	<div class="rows-bordered">
		<div class="row-fluid header">
			<div class="col-md-4">
				<b>Name</b>
			</div>
			<div class="col-md-4">
				<b>Phone</b>
			</div>
			<div class="col-md-4">
				<b>Email</b>
			</div>
		</div>
        <div *ngFor="let user of displayUsers" class="row-fluid">
			<div class="col-md-4">{{ user.personalName }}</div>
			<div class="col-md-4">{{ (user.phoneNumber | tel) || '-' }}</div>
			<div class="col-md-4">
				<a [href]="user.email | hrefReady">{{ user.email }}</a>
			</div>
		</div>
	</div>
</section>
