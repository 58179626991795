import { ColumnConfig } from 'commons/mixins';

export const columnsConfig: ColumnConfig[] = [
	{
		id: 'lastLoginDate',
		name: 'Last Login Date',
		isDefault: true,
	},
	{
		id: 'location',
		name: 'Location',
		isDefault: true,
	},
	{
		id: 'channel',
		name: 'Channels',
	},
	{
		id: 'email',
		name: 'Email',
	},
	{
		id: 'createdBy',
		name: 'Created By',
	},
	{
		id: 'createdDate',
		name: 'Created Date',
	},
	{
		id: 'lastModifiedBy',
		name: 'Last Modified By',
	},
	{
		id: 'lastModifiedDate',
		name: 'Last Modified Date',
	},
	{
		id: 'reportingManagerName',
		name: 'Reports To',
	},
];
