import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

import { DropdownRequiredValidator } from 'commons/validators';
import { Entity } from 'commons/interfaces/entity.type';

import { LenderHomeService, RequestChannelInfo } from 'lender/home/lender-home.service';
import { TextEntity } from 'lender/clients/$id/channels/channels.service';

@Component({
    templateUrl: 'investor-terminated-lenders-accept.modal.component.html',
})

export class InvestorTerminatedLendersAcceptModalComponent implements OnInit {
    id!: number;
    renewalDate!: number;
    approvalStatus: TextEntity;
    approvalAssigned: Entity;
    channelFrom: RequestChannelInfo;
    today = new Date();

    onFinalize: () => void;
    callbackOnSave: () => void;

    resolving: boolean;

    dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    approvalStatuses: Observable<TextEntity[]> = this.lenderHomeService.getRenewableApprovalStatuses().pipe(
        tap(() => this.loading.approvalStatus = false),
        shareReplay(1),
    );

    users: Observable<TextEntity[]>;

    loading = {
        approvalStatus: true,
        users: true,
    };

    form = new RealmFormGroup({
        approvalStatus: new RealmFormControl(
            'approvalStatus',
            {
                label: ' Relationship Status',
                updateOn: 'change',
            },
            DropdownRequiredValidator,
        ),
        assigned: new RealmFormControl(
            'assigned',
            {
                label: 'Assigned',
                updateOn: 'change',
            },
            DropdownRequiredValidator,
        ),
        renewalDate: new RealmFormControl(
            'renewalDate',
            {
                label: 'Renewal Date',
                updateOn: 'change',
            },
            Validators.required,
        ),
    });

    constructor(
        private modalRef: BsModalRef,
        private readonly lenderHomeService: LenderHomeService,
    ) {}

    ngOnInit() {
        if (this.renewalDate) {
            this.form.get('renewalDate').patchValue(new Date(this.renewalDate));
        }
        this.users = this.lenderHomeService.getOrganizationUsers(this.id).pipe(
            tap(() => {
                this.loading.users = false;
                this.form.get('assigned').patchValue(this.approvalAssigned);
            }),
            shareReplay(1),
        );
    }

    close(): void {
        this.modalRef.hide();
    }

    async accept(): Promise<void> {
        if (this.form.valid) {
            try {
                this.resolving = true;
                await this.lenderHomeService.acceptShareApprovalRequest(
                    this.id,
                    {
                        ...this.form.getRawValue(),
                        renewalDate: this.dateFormatter.format(this.form.get('renewalDate').value),
                    },
                ).toPromise();
                this.modalRef.hide();
                this.onFinalize?.();
            } catch ({ error }) {
                this.form.setServerError(error);
            } finally {
                this.resolving = false;
            }
        }
    }

    async decline(): Promise<void> {
        try {
            this.resolving = true;
            await this.lenderHomeService.declinedShareApprovalRequest(
                this.id,
                {
                    ...this.form.getRawValue(),
                    renewalDate: this.dateFormatter.format(this.form.get('renewalDate').value),
                },
            ).toPromise();
            this.modalRef.hide();
            this.onFinalize?.();
        } catch (error) {
            this.form.setServerError(error.data || error);
        } finally {
            this.resolving = false;
        }
    }
}
