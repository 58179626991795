<h2 class="gray-head">Review ID: {{reviewId}}</h2>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-sm-4" columnName="actionAt">Date</div>
            <div class="col-sm-4" columnName="actions">Action</div>
            <div class="col-sm-4" columnName="actionBy">Action By</div>
        </div>
        <div *ngIf="!resolving && ((list | async)?.length > 0); else noHistory">
            <div class="row-fluid table-row" *ngFor="let item of list | async">
                <div class="col-md-4">{{item.actionAt | date: 'MM/dd/YYYY HH:mm'}}</div>
                <div class="col-md-4">
                    <div *ngFor="let action of item.actions; let i = index">
                        {{action}}<span *ngIf="i != (item.actions.length - 1)">, </span>
                    </div>
                </div>
                <div class="col-md-4">{{item.actionBy}}</div>
            </div>
        </div>
        <ng-template #noHistory>
            <div class="col-sm-12 text-center text-light text-bigger row-fluid">
                No History
            </div>
        </ng-template>
    </div>
    &nbsp;
    <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
</div>
