import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RealmHttpClient } from 'commons/services/http';
import {
    InvestorBaseInfo, ShareChannelRequest,
    TpoChannelInformation,
    TpoChannelOverview,
    TpoServiceInformation,
} from 'comergence/customers/tpo-services/tpo-service.interface';
import { UserInformation } from 'comergence/customers/services/customer-services/user-information.interface';
declare let apiPath: string;

@Injectable()
export class ThirdPartyOversightService {
    constructor(
        private  http: RealmHttpClient,
    ) {}

    public updateChannel(channelId: number, organizationId: number, changedChannel: TpoChannelInformation): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/investor/channels/${channelId}`,
            null,
            changedChannel
        );
    }

    public createTpoChannel(organizationId: number, channel: TpoChannelInformation) {
        return this.http.request<void>(
            'POST',
            `${apiPath}/organizations/${organizationId}/investor/channels`,
            null,
            channel
        );
    }

    public getTpoChannelsList(organizationId: number, params?: { activeOnly: boolean }): Observable<TpoChannelOverview[]> {
        return this.http.request<TpoChannelOverview[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/investor/channels`,
            params,
        );
    }

    public activateChannel(organizationId, channelId): Observable<void> {
         return this.http.request<void>(
             'POST',
             `${apiPath}/organizations/${organizationId}/investor/channels/${channelId}/activation`,
         )
    }

    public deactivateChannel(organizationId, channelId): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${apiPath}/organizations/${organizationId}/investor/channels/${channelId}/deactivation`,
        )
    }

    public getChannelInformation(organizationId, channelId): Observable<TpoChannelOverview> {
        return this.http.request<TpoChannelOverview>(
            'GET',
            `${apiPath}/organizations/${organizationId}/investor/channels/${channelId}`
        )
    }
    public getTpoService(organizationId: number | string): Observable<TpoServiceInformation> {
        return this.http.request<TpoServiceInformation>(
            'GET',
            `${apiPath}/customers/${organizationId}/services`
        );
    }

    public updateStatus(organizationId:number, serviceCode:string, status): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/customers/${organizationId}/services/code/${serviceCode}/status`,
            null,
            status
        );
    }

    public getAllInvestors(): Observable<InvestorBaseInfo[]> {
        return this.http.request<InvestorBaseInfo[]>(
            'GET',
            `${apiPath}/customers/investors/all/base`
        );
    }

    public getInvestorUsers(organizationId: number): Observable<UserInformation[]> {
        return this.http.request<UserInformation[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/users`,
        );
    }

    public shareChannel(organizationId: number, channelId: number, shareRequest: ShareChannelRequest): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${apiPath}/organizations/${organizationId}/investor/channels/${channelId}/share-request`,
            null,
            shareRequest
        );
    }
}
