import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { PostsModule } from './posts';
import { LibraryModule } from './library';
import { CampaignsModule } from './campaigns';
import { GroupsModule } from './groups';
import { PostsTypeListModule } from './posts-type-list';
import { PublisherComponent } from './publisher.component';
import { SocialMediaModule } from 'shared/social-media';
import { CommonModule } from '@angular/common';


@NgModule({
	imports: [
        UIRouterModule,
		PostsModule,
		LibraryModule,
		CampaignsModule,
		GroupsModule,
		PostsTypeListModule,
        SocialMediaModule,
        CommonModule,
	],
    declarations: [
        PublisherComponent,
    ],
    exports: [
    ],
})
export class PublisherModule {
}
