import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SocialMediaLinkConfig } from '../../sm-icons';
import { SocialMediaPost } from '../sm-post.interface';

@Component({
	selector: 'sm-post-header',
	templateUrl: './sm-post-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaPostHeaderComponent {
	@Input() post: SocialMediaPost;
    @Input() displayWebLink = false;

    get title() {
        return this.post.name;
    }

	get iconConfig(): SocialMediaLinkConfig {
		const {
			profilePictureUrl,
			networkId: networkCode,
			source,
		} = this.post;
		return {
			profilePictureUrl,
			networkCode,
			source,
		};
	}
}
