<div class="gray-head">
	<h2>Invites</h2>
</div>

<div class="remote-resource list-management" [class.resolved]="list?.$resolved">
	<div class="filters-rows">
		<div class="row-fluid filter-labels">
			<div class="col-sm-2">
				<div class="text-muted">Search</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Channel</div>
			</div>
			<div class="col-sm-2">
				<div class="text-muted">Invite Status</div>
			</div>
			<div class="col-sm-2" *ngIf="User.crmEnabled">
				<div class="text-muted">State</div>
			</div>
			<div class="col-sm-a"></div>
		</div>
		<div class="row-fluid filter-selectors">
			<div class="col-sm-2">
				<search-list placeholder="Name, NMLS ID"></search-list>
			</div>
			<div class="col-sm-2">
				<ng-select
					[multiple]=true
					[(ngModel)]="params.channelId"
					(change)="setFilter({ channelId: $event })"
					[items]="filters.channelId.$promise | async"
					[loading]="!filters.channelId.$resolved"
					bindValue="id"
					bindLabel="name"
					placeholder="All Channels"
				></ng-select>
			</div>
			<div class="col-sm-2">
				<ng-select
					[multiple]=true
					[(ngModel)]="params.status"
					(change)="setFilter({ status: $event })"
					[items]="filters.status"
					bindValue="id"
					bindLabel="name"
					placeholder="All Statuses"
				></ng-select>
			</div>
			<div class="col-sm-2" *ngIf="User.crmEnabled">
				<ng-select
					[multiple]=true
					[(ngModel)]="params.state"
					(change)="setFilter({ state: $event })"
					[items]="filters.state.$promise | async"
					[loading]="!filters.state.$resolved"
					bindValue="shortName"
					bindLabel="shortName"
					placeholder="All States"
				></ng-select>
			</div>
			<div class="col-sm-2">
				<filters-toggle [opened]="moreFilters" (toggle)="toggleFilters()"></filters-toggle>
			</div>
			<div class="col-sm-a">
				<div class="horizontal-flex-group right">
					<div class="dropdown" dropdown [insideClick]="true">
						<a class="dropdown-toggle btn btn-tertiary btn-icon" dropdownToggle tooltip="Manage Columns" placement="top">
							<span class="c-icon c-icon-list-bullet"></span>
						</a>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li *ngFor="let column of columnValues()" role="menuitem">
								<a class="link-option" (click)="toggleColumn(column.id)">
									<span class="checkbox-holder">
										<input type="checkbox" [checked]="column.enabled">
										{{column.name}}
									</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div [class.hidden]="!moreFilters">
			<div class="row-fluid filter-labels">
				<div class="col-sm-2">
					<div class="text-muted">Assigned</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Invited After</div>
				</div>
				<div class="col-sm-2">
					<div class="text-muted">Invited Before</div>
				</div>
				<div class="col-sm-a"></div>
			</div>
			<div class="row-fluid filter-selectors">
				<div class="col-sm-2">
					<ng-select
						[multiple]=true
						[(ngModel)]="params.assigneeContactId"
						(change)="setFilter({ assigneeContactId: $event })"
						[items]="filters.assigneeContactId.$promise | async"
						[loading]="!filters.assigneeContactId.$resolved"
						bindValue="id"
						bindLabel="name"
						placeholder="All Assigned"
					></ng-select>
				</div>
				<div class="col-sm-2">
					<input type="text" class="form-control"
					       bsDatepicker
					       [bsValue]="params.dateFrom"
					       [maxDate]="params.dateFrom"
					       (bsValueChange)="setFilter({ dateFrom: $event })"
					       placeholder="mm/dd/yyyy"
					>
				</div>
				<div class="col-sm-2">
					<input type="text" class="form-control"
					       bsDatepicker
					       [bsValue]="params.dateTo"
					       [minDate]="params.dateTo"
					       (bsValueChange)="setFilter({ dateTo: $event })"
					       placeholder="mm/dd/yyyy"
					>
				</div>
				<div class="col-sm-a"></div>
			</div>
		</div>
	</div>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<sortable-header class="col-sm-3" columnName="tpoName">Client</sortable-header>
			<ng-container *ngFor="let column of columnValues()">
				<sortable-header class="col-sm-a" isColumnEnabled [columnName]="column.id">
					<span class="two-liners">{{column.name}}</span>
				</sortable-header>
			</ng-container>
		</div>
		<div class="row-fluid yellow-bg no-border" *ngIf="filtersApplied">
			<span class="col-sm-12 va-m text-center">
				Filters are applied. <a (click)="resetFilters()">Reset filters</a> to see all results
			</span>
		</div>
		<div class="row-fluid" *ngFor="let item of list">
			<div class="col-sm-3">
				<a uiSref="client.:id.account.information" [uiParams]="{ id: item.tpoId }">{{item.tpoName}}</a>
				<div class="text-light text-smaller">NMLS ID:{{item.tpoNmlsId}}</div>
			</div>
			<div class="col-sm-a" isColumnEnabled columnName="channelName">{{item.channelName || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="status">
				<ng-container *ngIf="InviteStatuses.get(item.status); let status">
					<div class="display-inline text-bigger">
						<span class="label" [ngClass]="'label-' + status.color">{{status.name}}</span>
					</div>
					&nbsp;
					<ng-container *ngIf="User.can('INVITE_TO_APPLY') && item.status == 'SENT'">
						<a class="text-medium" (click)="cancelInvite(item)">Cancel</a>
					</ng-container>
				</ng-container>
			</div>
			<div class="col-sm-a" isColumnEnabled columnName="inviteDate">{{(item.inviteDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="statusDate">{{(item.statusDate | date:'MM/dd/yyyy') || '&ndash;'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="assigneeFullName">{{item.assigneeFullName || 'Unassigned'}}</div>
			<div class="col-sm-a" isColumnEnabled columnName="tpoContactFullName">
				<ng-container *ngIf="User.crmEnabled && item.tpoContactFullName; then accountLinkAnchor; else accountLinkName"></ng-container>
				<ng-template #accountLinkAnchor>
					<a uiSref="client.:id.contacts.:contactId.information" [uiParams]="{ id: item.tpoId, contactId: item.tpoContactId }" target="_blank">{{item.tpoContactFullName}}</a>
				</ng-template>
				<ng-template #accountLinkName>
					{{item.tpoContactFullName || '&ndash;'}}
				</ng-template>
			</div>
			<div class="col-sm-a" isColumnEnabled columnName="expiresIn">
				<ng-container [ngPlural]="item.expiresIn">
					<ng-template ngPluralCase="=null">&ndash;</ng-template>
					<ng-template ngPluralCase="=1">1 Day</ng-template>
					<ng-template ngPluralCase="other">{{item.expiresIn}} Days</ng-template>
				</ng-container>
			</div>
			<div class="col-sm-a" isColumnEnabled columnName="location" *ngIf="User.crmEnabled">
				<div>{{getLocation(item) || '&ndash;'}}</div>
				<div>{{item.location.zip}}</div>
			</div>
		</div>
		<div class="row-fluid" *ngIf="list.$resolved && !list.length">
			<div class="col-sm-12 text-center text-muted text-bigger">
				{{filtersApplied || filters.q ? 'No matches found' : 'No invites'}}
			</div>
		</div>
		<br>
		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
