import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { SharedDocumentsModule } from 'shared/documents';

import { ClientDocumentsService } from './client-documents.service';
import { ClientLibraryDocumentsService } from './library/library-documents.service';
import { InternalDocumentsService } from './internal-documents.service';

import {
	DocumentChannelsComponent,
	RequestDocumentModalComponent,
	ClientChannelEditModalComponent,
	RequestDocumentHistoryModalComponent,
	WaiveDocumentModalComponent,
} from './common';
import { CommonDocumentsHistoryComponent } from './history';
import { ClientDocumentsComponent } from './client-documents.component';
import {
	ClientDocumentListComponent,
	ClientDocumentListItemComponent,
} from './list';
import {
	ClientDocumentComponent,
	ClientDocumentDetailsComponent,
	ClientDocumentHistoryComponent,
} from './document';
import {
	ClientLibraryDocumentListComponent,
	ClientLibraryDocumentListItemComponent,
	ClientLibraryDocumentComponent,
	ClientLibraryDocumentHistoryComponent,
} from './library';
import {
	InternalDocumentsListComponent,
	InternalDocumentsListItemComponent,
	InternalDocumentDetailsComponent,
} from './internal-documents';
import { WaiveDocumentHistoryModalComponent } from './common/waive-document-history-modal/waive-document-history-modal.component';
import {
    NotificationUsersFormComponent,
} from 'lender/clients/$id/documents/common/request-document-modal/notification-users-form/notification-users-form.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		BsDropdownModule.forRoot(),
		DirectivesModule,
		ComponentsModule,
		SharedDocumentsModule,
	],
	declarations: [
		// Client Documents
		ClientDocumentsComponent,
		ClientDocumentListComponent,
		ClientDocumentListItemComponent,
		ClientDocumentComponent,
		ClientDocumentDetailsComponent,
		ClientDocumentHistoryComponent,
		// Client Library Documents
		ClientLibraryDocumentListComponent,
		ClientLibraryDocumentListItemComponent,
		ClientLibraryDocumentComponent,
		ClientLibraryDocumentHistoryComponent,
		// Client Internal Documents
		InternalDocumentsListComponent,
		InternalDocumentsListItemComponent,
		InternalDocumentDetailsComponent,
		// Other
		CommonDocumentsHistoryComponent,
		RequestDocumentModalComponent,
        NotificationUsersFormComponent,
		RequestDocumentHistoryModalComponent,
		WaiveDocumentModalComponent,
		WaiveDocumentHistoryModalComponent,
		DocumentChannelsComponent,
		ClientChannelEditModalComponent,
	],
	providers: [
		ClientDocumentsService,
		ClientLibraryDocumentsService,
		InternalDocumentsService,
	],
	exports: [
		ClientDocumentListComponent,
        NotificationUsersFormComponent,
	],
})
export class ClientDocumentsModule {}
