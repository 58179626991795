import { isNull } from 'lodash';
import moment from 'moment';
import { Component } from '@angular/core';
import { Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';

import { AppcodeFormValue } from '../appcodes.interface';

type SetDatesProps = {
	startDate?: Date | null;
	expirationDate?: Date | null;
};

type DatesTimestamps = {
	startDate?: number;
	expirationDate?: number;
};

@Component({
	templateUrl: './create-code-modal.component.html',
})
export class CreateAppcodeModalComponent {
	onCancel: () => void;
	onConfirm: (formValue: AppcodeFormValue) => void;
	resolving: boolean = false;
	ready: boolean = false;
	bsConfig: Partial<BsDatepickerConfig> = {
		dateInputFormat: 'MM/DD/YYYY',
	};

	today = new Date();

	appcodeForm: RealmFormGroup = new RealmFormGroup({
		numberOfUses: new RealmFormControl(
			'numberOfUses',
			{
				label: 'Number of Uses',
			},
			[
				Validators.required,
				(control: AbstractControl): ValidationErrors | null => {
					if (isNull(control.value)) {
						return null;
					}

					const isValidValue = /^[0-9]{1,9}$/.test(`${control.value}`)
						&& (parseInt(`${control.value}`, 10) > 0);

					return isValidValue ? null : {
						isNumber: {
							value: control.value,
							template: 'Please enter valid number',
						},
					};
				},
			],
		),
		startDate: new RealmFormControl(
			'startDate',
			{
				label: 'Start Date',
			},
			[
				Validators.required,
			],
		),
		expirationDate: new RealmFormControl(
			'expirationDate',
			{
				label: 'Expires',
			},
			[
				Validators.required,
				(control: AbstractControl): ValidationErrors | null => {
					if (!this.appcodeForm || !this.appcodeForm.value || !control.value) {
						return null;
					}

					const dates = this.getDatesFromForm({
						startDate: this.appcodeForm.value.startDate,
						expirationDate: this.appcodeForm.value.expirationDate,
					});

					const isInvalidExpire: boolean = !!dates.startDate && !!dates.expirationDate
						&& (dates.startDate > dates.expirationDate);

					return isInvalidExpire ? {
						dateOverflow: {
							value: control.value,
							template: '<b>Expiration Date</b> date should be after <b>Start Date</b>',
						},
					} : null;
				},
			],
		),
		description: new RealmFormControl(
			'description',
			{
				label: 'Description',
			},
			[
				Validators.required,
			],
		),
	});

	constructor(public modalRef: BsModalRef) {}

	ngOnDestroy(): void {
		this.onCancel();
	}

	cancel(): void {
		if (this.resolving) {
			return;
		}

		this.onCancel();
	}

	getDatesFromForm = ({ startDate, expirationDate }: SetDatesProps): DatesTimestamps => {
		return {
			startDate: startDate ? +moment(startDate).startOf('day').format('x') : undefined,
			expirationDate: expirationDate ? +moment(expirationDate).startOf('day').format('x') : undefined,
		};
	}

	validateDates = (): void => {
		this.appcodeForm.controls['expirationDate'].updateValueAndValidity();
	}

	save(): void {
		if (this.resolving || !this.appcodeForm.valid) {
			return;
		}

		this.resolving = true;

		const formValueDates = this.getDatesFromForm({
			startDate: this.appcodeForm.value?.startDate,
			expirationDate: this.appcodeForm.value?.expirationDate,
		});

		const data = {
			...this.appcodeForm.value,
			...formValueDates,
		};

		this.onConfirm(data);
	}
}
