import { Injectable } from '@angular/core';

import { InvestorUserRelationshipService } from 'commons/services/user-capabilities/investor-user/investor-user-relationship.service';
import { InvestorUserTpoChannelService } from 'commons/services/user-capabilities/investor-user/investor-user-tpo-channel.service';
import { ListQueryUtilsService } from 'commons/services/utils/list-query-utils.service';

import {
	ApplicationsListComponent,
} from 'lender/applications';

import { DotMenuItem } from 'commons/components/navigation/dots-menu/dot-menu.items';
import { ChannelsOtherActionsConfig } from './channel-other-actions-config.interface';


@Injectable({
	providedIn: 'root',
})
export class ChannelsOtherActionsService {
    constructor(
        private readonly investorTpoRelationshipService: InvestorUserRelationshipService,
        private readonly investorUserTpoChannelService: InvestorUserTpoChannelService,
        private readonly listQueryUtilsService: ListQueryUtilsService,
    ) {
    }

    createChannelMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        const channelMenuItems: DotMenuItem[] = [
            ...this.createPurchaseMenuItems(config),
            ...this.createApplicationsMenuItems(config),
            ...this.createHistoryMenuItems(config),
            ...this.createRenewalMenuItems(config),
            ...this.createLocationsMenuItems(config),
            ...this.createInviteMenuItems(config),
            ...this.createCancelInviteMenuItems(config),
        ];

        return channelMenuItems;
    }

    // ====

    createPurchaseMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorUserTpoChannelService.canPurchaseTpoProfile(
            config.userCanAccessChannel,
            config.relationshipStatus,
            config.investorIsRegistered,
            config.userCanPurchaseChannel,
        )) {
            return [];
        }

        const purchaseMenuItems: DotMenuItem[] = [
            {
                title: 'Purchase',
                click: config.purchaseConfig.click,
            },
        ];

        return purchaseMenuItems;
    }

    createApplicationsMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorTpoRelationshipService.relationshipExists(config.relationshipStatus)) {
            return [];
        }

        const listFilters = {
            ...this.listQueryUtilsService.objectFor('channelName', config.channelId, false, true),
            ...this.listQueryUtilsService.objectFor('q', config.tpoNmlsId, true, false),
        };

        const applicationsParams = {
            [ApplicationsListComponent.listName]: listFilters,
        };

        const applicationsMenuItems: DotMenuItem[] = [
            {
                title: 'Applications',
                sref: 'applications',
                params: applicationsParams,
            },
        ];

        return applicationsMenuItems;
    }

    createHistoryMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorUserTpoChannelService.canAccessTpoChannelDetails(
            config.userCanAccessChannel,
            config.relationshipStatus,
            config.investorIsRegistered,
        )) {
            return [];
        }

        const historyParams = {
            id: config.tpoId,
            channelId: config.channelId,
        };

        const historyMenuItems: DotMenuItem[] = [
            {
                title: 'History',
                sref: 'client.:id.account.channels.:channelId.history',
                params: historyParams,
            },
        ];

        return historyMenuItems;
    }

    createRenewalMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorUserTpoChannelService.canRenewTpoRelationship(
            config.userCanAccessChannel,
            config.userProfile,
            config.relationshipStatus,
            config.investorIsRegistered,
        )) {
            return [];
        }

        const renewalMenuItems: DotMenuItem[] = [
            {
                title: 'Renewal',
                click: config.renewalConfig.click,
            },
        ];

        return renewalMenuItems;
    }

    createLocationsMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorUserTpoChannelService.canAccessTpoChannelDetails(
            config.userCanAccessChannel,
            config.relationshipStatus,
            config.investorIsRegistered,
        )) {
            return [];
        }

        const locationsParams = {
            id: config.tpoId,
            channelId: config.channelId,
        };

        const locationsMenuItems: DotMenuItem[] = [
            {
                title: 'Locations',
                sref: 'client.:id.account.channels.:channelId',
                params: locationsParams,
            },
        ];

        return locationsMenuItems;
    }

    createInviteMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorUserTpoChannelService.canInviteTpoToChannel(
            config.channelIsInvitable,
            config.userProfile,
            config.relationshipStatus,
            config.investorIsRegistered,
        )) {
            return [];
        }

        const inviteParams = {
            id: config.tpoId,
            channelId: config.channelId,
        };


        const inviteMenuItems: DotMenuItem[] = [
            {
                title: 'Invite',
                sref: 'client.:id.account.channels.invite.:channelId',
                params: inviteParams,
            },
        ];

        return inviteMenuItems;
    }

    createCancelInviteMenuItems(config: ChannelsOtherActionsConfig): DotMenuItem[] {
        if (!this.investorUserTpoChannelService.canCancelInvitation(
            config.inviteStatus,
            config.userProfile,
        )) {
            return [];
        }

        const cancelInviteMenuItems: DotMenuItem[] = [
            {
                title: 'Cancel Invite',
                click: config.cancelInviteConfig.click,
            },
        ];

        return cancelInviteMenuItems;
    }
}

