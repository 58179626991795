import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserService, UserProfile } from 'angularjs-providers/user.provider';

import { CompanyListingsService } from '../../../../company-listings.service';
import { ListingLinkConfirmModalComponent } from '../common';

@Component({
	selector: 'listing-group-listing-add',
	templateUrl: './listing-add.component.html',
})
export class ListingGroupListingAddComponent implements OnInit {
	connecting: boolean = false;
	User: UserProfile;
	candidates: any;
	selectedItem: any;

	@Input() group: any;
	@Output() add: EventEmitter<any> = new EventEmitter();
	@Output() cancel: EventEmitter<any> = new EventEmitter();

	constructor(
		private stateService: StateService,
		{ profile }: UserService,
		public modalService: BsModalService,
		public companyListingsService: CompanyListingsService,
	) {
		this.User = profile;
	}

	ngOnInit(): void {
		const { listingGroupId } = this.stateService.params;
		this.candidates = this.companyListingsService.listingGroupListingsListCandidates({ listingGroupId });
	}

	selectItem($event: any): void {
		this.selectedItem = $event;
	}

	cancelAdd(): void {
		this.cancel.emit();
	}

	save(): void {
		const initialState = {
			onConfirm: () => {
				this.doSave();
				modalRef.hide();
			},
		};

		const modalRef = this.modalService.show(
			ListingLinkConfirmModalComponent,
			{
				initialState,
				class: 'modal-smd modal-new',
			},
		);
	}

	doSave(): void {
		const { mediaLinkId, groupId: listingGroupId } = this.selectedItem;
		this.connecting = true;
		this.companyListingsService.moveListing({ listingGroupId, mediaLinkId }, this.group)
			.$promise
			.then(() => {
				this.add.emit(this.selectedItem);
			})
			.finally(() => {
				this.connecting = false;
			});
	}

	search(term: string, item: any): boolean {
		const termWords: string = term.toLocaleLowerCase();
		const searchWords: string = [
			item.address,
			item.city,
			item.state,
			item.zip,
			item.businessName,
		].join(', ').toLocaleLowerCase();
		return searchWords.includes(termWords);
	}
}
