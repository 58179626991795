import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';
import { FilterDefault } from 'commons/components/list/list.interface';

import { ProspectBody, ProspectInfoChanges } from 'shared/prospecting/prospecting.interface';

declare let apiPath: string;

@Injectable()
export class ProspectingService {
	constructor(
		private readonly http: RealmHttpClient,
	) {}

	public createProspect(prospectBody: ProspectBody): Observable<ProspectInfoChanges> {
		return this.http.request<ProspectInfoChanges>(
			'POST',
			`${apiPath}/crm/prospect`,
			null,
			prospectBody,
		);
	}

	public getProspectChannels(companyNmlsId: number, locationNmlsId: number): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/prospect/available-channels`,
			{ companyNmlsId, locationNmlsId }
		);
	}

	public getProspectAssignees(channelId: string): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/prospect/available-assignees`,
			{ channelId }
		);
	}
}
