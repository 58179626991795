<div class="gray-head">
    <h2>Lists
        <div class="horizontal-flex-group pull-right" *ngIf="canManageContactList">
            <a class="btn btn-primary"
               uiSref="crm.contact.lists.new"
            >Create List</a>
        </div>
    </h2>
</div>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="filters-rows">
        <div class="row filter-labels">
            <div class="col-sm-2">
                <div class="text-light">Search</div>
            </div>
        </div>
        <div class="row filter-selectors">
            <div class="col-sm-2">
                <search-list placeholder="List Name"></search-list>
            </div>
        </div>
    </div>
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-a">
                <b>Lists Name</b>
            </div>
            <div class="col-md-a">
                <b>Created Date</b>
            </div>
            <div class="col-md-a">
                <b>Created By</b>
            </div>
        </div>
        <div class="col-md-a">
            <div class="row-fluid">
                <reset-filters></reset-filters>
            </div>
        </div>
        <div *ngIf="!resolving && ((list | async)?.length > 0); else noRecord">
            <a
                *ngFor="let item of list | async"
                class="row-fluid clickable table-row"
                uiSref="crm.contact.lists.:listId"
                [uiParams]="{listId: item.id}"
            >
                <div columnName="listName" class="col-md-a">
                    <a class="col-md-a">{{item.name}}</a>
                </div>
                <div columnName="createdDate" class="col-md-a">
                    <div class="col-md-a">{{(item.createdDate | date )|| '&ndash;'}}</div>
                </div>
                <div columnName="createdBy" class="col-md-a">
                    <div class="col-md-a">{{item.createdBy || '&ndash;'}}</div>
                </div>
            </a>
        </div>
        <ng-template #noRecord>
            <div class="col-sm-12 text-center text-light text-bigger">
                {{filtersApplied ? 'No matches found' : 'No records added'}}
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>
