import { ColumnConfig } from 'commons/mixins';

export const columnsConfig: ColumnConfig[] = [
	{
		id: 'channelName',
		name: 'Channel',
		isDefault: true,
	},
	{
		id: 'status',
		name: 'Invite Status',
		isDefault: true,
	},
	{
		id: 'inviteDate',
		name: 'Invite Date',
		isDefault: true,
	},
	{
		id: 'statusDate',
		name: 'Status Date',
	},
	{
		id: 'assigneeFullName',
		name: 'Assigned',
		isDefault: true,
	},
	{
		id: 'tpoContactFullName',
		name: 'Contact',
		isDefault: true,
	},
	{
		id: 'expiresIn',
		name: 'Expires in',
		isDefault: true,
	},
];
