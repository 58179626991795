import { trim, isString } from 'lodash';
import { Directive, HostListener, forwardRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const TRIM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TrimValueAccessor),
	multi: true,
};

const commonExclude = ':not([readonly]):not([validFloat]):not([phoneFormatter]):not(.no-trim):not([bsDaterangepicker]):not([bsDatepicker])';

/**
 * The trim accessor for writing trimmed value and listening to changes that is
 * used by the {@link NgModel}, {@link FormControlDirective}, and
 * {@link FormControlName} directives.
 */
@Directive({
	selector: `
    input:not([type=checkbox]):not([type=radio]):not([type=password])${commonExclude}[formControlName],
    input:not([type=checkbox]):not([type=radio]):not([type=password])${commonExclude}[formControl],
    input:not([type=checkbox]):not([type=radio]):not([type=password])${commonExclude}[ngModel],
    textarea${commonExclude}[formControlName],
    textarea${commonExclude}[formControl],
    textarea${commonExclude}[ngModel],
    ${commonExclude}[ngDefaultControl]
  `,
	providers: [TRIM_VALUE_ACCESSOR],
})
export class TrimValueAccessor extends DefaultValueAccessor {

	@HostListener('input', ['$event.target.value'])
	ngOnChange = (val: string) => {
		this.onChange(trim(val));
	}

	@HostListener('blur', ['$event.target.value'])
	ngOnBlur = (val: string) => {
		this.onChange(trim(val));
		this.onTouched();
	}

	writeValue(inputValue: any): void {
		const value = isString(inputValue) ? trim(inputValue) : inputValue;

		super.writeValue(value);
	}
}
