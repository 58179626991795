<div class="data-match">
    <div class="gray-head">
        <h2 class="clearfix">
            <span>Data Match and Upload</span>
            <div class="pull-right">
                <a class="btn btn-primary"
                   uiSref="data-match.new">
                    Create
                </a>
            </div>
        </h2>
    </div>
    <div class="remote-resource" [class.resolved]="!resolving">
        <div class="filters-rows">
            <div class="row filter-labels">
                <div class="col-sm-2">
                    <div class="text-light">Search</div>
                </div>
            </div>
            <div class="row filter-selectors">
                <div class="col-sm-2">
                    <search-list placeholder="Search by any parameter"></search-list>
                </div>
            </div>
        </div>
        <div class="rows-bordered">
            <div class="row-fluid header">
                <div class="col-md-3">
                </div>
                <div class="col-md-a">
                    <b class="two-liners">Status</b>
                </div>
                <div class="col-md-2">
                    <b class="two-liners">Customer</b>
                </div>
                <div class="col-md-a">
                    <b class="two-liners">Channel</b>
                </div>
                <div class="col-md-1">
                    <b class="two-liners">Total</b>
                </div>
                <div class="col-md-1">
                    <b class="two-liners">Match</b>
                </div>
                <div class="col-md-a">
                    <b class="two-liners">Created By</b>
                </div>
                <div class="col-md-a">
                    <b class="two-liners">Created Date</b>
                </div>
            </div>
            <div class="col-md-a">
                <div class="row-fluid">
                    <reset-filters></reset-filters>
                </div>
            </div>
            <div *ngIf="!resolving && ((list | async)?.length > 0); else noRecord">
                <div class="row-fluid table-row" *ngFor="let item of list | async">
                    <div class="col-md-3">
                        <a class="btn btn-primary-wired btn-sm" (click)="exportReport(item.id)">
                            <span>Download results</span>
                        </a>
                        &nbsp;
                        <a uiSref="data-match.report" [uiParams]="{id: item.id}">
                            <span>{{'#&nbsp;' + item.id}}</span>
                        </a>
                    </div>
                    <div class="col-md-a">
                        <ng-container *ngIf="statusConfig[item.status]; let status">
                            <span class="status label" [ngClass]="'label-' + status.color">{{status.title}}</span>
                        </ng-container>
                    </div>
                    <div class="col-md-2">
                        <span class="control-value">{{item.customer || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-a">
                        <span class="control-value">{{item.channel || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="control-value">{{item.total || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-1">
                        <span class="control-value">{{item.match + '%'}}</span>
                    </div>
                    <div class="col-md-a">
                        <span class="control-value">{{item.createdBy || '&ndash;'}}</span>
                    </div>
                    <div class="col-md-a">
                        <span class="control-value">{{item.createdDate | date : 'MM/dd/yyyy'}}</span>
                    </div>
                </div>
            </div>
            <ng-template #noRecord>
                <div class="col-sm-12 text-center text-light text-bigger">
                    {{filtersApplied ? 'No matches found' : 'No records added'}}
                </div>
            </ng-template>
            <br>
            <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
        </div>
    </div>
</div>



