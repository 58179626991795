import { Component, forwardRef, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { ListComponent } from 'commons/components/list';

import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { PagedData } from 'commons/services/http';
import { Observable } from 'rxjs';
import { ContactService } from 'shared/contact/contact.service';

import { reviewStatuses } from 'shared/review-statuses';
import { AuditReview } from 'shared/social-compliance/audits/audits.service.interface';


export interface NewContactsAuditsListParams extends ListParams {
    tpoId: number;
    contactId: number;
}

@Component({
	templateUrl: './new-contacts-audits-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => NewContactsAuditsListComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => NewContactsAuditsListComponent) },
    ],
})
export class NewContactsAuditsListComponent extends NewPagedListComponent<AuditReview> implements OnInit {
	static listName = 'newContactComplianceAudits';

	userProfile: UserProfile;
	reviewStatuses = reviewStatuses;

	constructor(
        uiRouter: UIRouter,
		public userService: UserService,
		public contactService: ContactService,
	) {
		super(uiRouter);

		this.userProfile = userService.profile;
	}

    // Override
	ngOnInit(): void {
		this.configureList();

		super.ngOnInit();
	}

    // Override
    loadList(params: NewContactsAuditsListParams): Observable<PagedData<AuditReview[]>> {
		return this.contactService.getAudits(params.tpoId, params.contactId, params);
	}

	private configureList(): void {
		const { id, contactId } = this.router.stateService.params;

		Object.assign(this.defaultParams, {
			size: 5,
			tpoId: this.userProfile.isTpo ? this.userProfile.organization.id : id,
			contactId,
		});
	}
}
