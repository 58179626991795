import { Component } from '@angular/core';
import { ContactsImportService } from 'tpo/people/contacts/import/import.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { DropdownRequiredValidator, FileSizeValidator, FileTypeValidator } from 'commons/validators';
import { StateService } from '@uirouter/core';
import { HistoryLogService } from 'angularjs-providers/history-log-service.provider';

@Component({
    selector: 'contacts-import-upload',
    templateUrl: './upload.component.html',
})
export class ContactsImportUploadComponent {

    form = new RealmFormGroup({
        selectedFile: new RealmFormControl(
            'selectedFile',
            {
                label: 'File',
            },
            [DropdownRequiredValidator, FileTypeValidator(['CSV', 'XLS', 'XLSX']), FileSizeValidator(20 * 1024 * 1024)],
        ),
    });
    uploading = false;
    selectedFile = this.form.get('selectedFile');

    constructor(
        private readonly contactsImportService: ContactsImportService,
        private readonly stateService: StateService,
        private readonly historyLogService: HistoryLogService,
    ) {
    }

    onFileSelect(files: FileList) {
        if (!files.length) {
            return;
        }

        this.selectedFile.setValue(files[0]);
        this.selectedFile.markAllAsTouched();
    }

    async upload() {
        try {
            this.uploading = true;
            const result = await this.contactsImportService.uploadContactsImport(this.selectedFile.value).toPromise();
            this.stateService.go('people.contacts-import.match', { importId: result.id }, { location: 'replace' });
        } catch ({ error }) {
            this.form.setServerError(error);
        }
        this.uploading = false;
    }

    cancel() {
        const backState = this.historyLogService.findBackState([ '**.contacts-import.**' ], false);

        if (backState) {
            return this.historyLogService.goBackTo(backState);
        }
        return this.stateService.go('people.contacts');
    }
}
