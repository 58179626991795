<ng-container [ngSwitch]="resolving">
    <ng-container *ngSwitchCase="true">
        <div class="flex-grow"></div>
        <loader  size="small"></loader>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="review && (isTpo || review.reviewDate)" [ngSwitch]="isTpo && review.expired">
            <div class="flex-grow">
                <div class="text-danger flex-row gap-sm" *ngSwitchCase="true">
                    <span class="icon-alert"></span>
                    <span>Out of date and requires review</span>
                </div>
            </div>
            <ng-container *ngSwitchCase="true">
                <button class="btn btn-danger-wired" *ngIf="allowEmpty || review.reviewDate" (click)="markReviewed()">
                    <span class="glyphicon glyphicon-flag"></span>
                    Mark Reviewed
                </button>
            </ng-container>
            <div *ngSwitchDefault class="flex-row gap review-block">
                <div class="text-bigger" *ngIf="isTpo">
                    <span class="c-icon c-icon-check text-success"></span>
                </div>
                <div>
                    <div>Reviewed by</div>
                    <div class="text-smaller text-muted condensed">
                        <div>{{review.reviewer}}</div>
                        <div>{{review.reviewDate | date: 'MM/dd/yyyy hh:mm a'}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
