import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ClientDocumentsModule } from 'lender/clients';
import { FederalRegistrationsListComponent } from './federal-registrations-list.component';
import { FederalRegistrationsResourceService } from './federal-registrations-resource.service';


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        FormsModule,
        ClientDocumentsModule,
        PipesModule,
    ],
    declarations: [
        FederalRegistrationsListComponent,
    ],
    providers: [
        FederalRegistrationsResourceService,
    ],
})
export class FederalRegistrationsModule {
}
