<div class="modal-header">
	<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
	<h4 class="modal-title">{{title}}</h4>
</div>

<form [formGroup]="modalForm" (onValidSubmit)="submit()">
	<div class="modal-body remote-resource" [class.resolved]="!resolving">

		<form-errors [form]="modalForm"></form-errors>

		<div class="form-group">
			<input type="text" class="form-control" formControlName="name" maxlength="2000" placeholder="Enter Name">
		</div>
	</div>
	<div class="modal-footer" [class.curtain]="resolving">
		<div class="text-right">
			<button type="submit" class="btn btn-primary">Save</button>
			<button type="button" (click)="modalRef.hide()" class="btn btn-secondary">Cancel</button>
		</div>
	</div>
</form>
