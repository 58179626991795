import { ScriptResolver } from 'commons/recurly/scripts-resolver.class';

declare let window: Window;
// declare let grecaptcha: ReCaptchaV2.ReCaptcha;
declare let grecaptcha: any;

let cachedPromise: Promise<any>;

const recaptchaLoader: () => Promise<any> = () => {
   if (cachedPromise !== undefined) {
       return cachedPromise;
   }

   return cachedPromise = recaptchaPromise();
};

const recaptchaPromise =  () => {
    return new Promise<any>((resolve, reject) => {
        const siteKey = window.env['application_re-captcha_site-key'];
        const script = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        const loader = new ScriptResolver(script);
        loader.promise.then(() => {
            resolve(grecaptcha);
        }, () => {
            reject();
        });
    })
}

export const recaptchaResolver = { token: 'recaptcha', deps: [], resolveFn: recaptchaLoader };
