import { Component, Input } from '@angular/core';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { NgResourceObject } from 'commons/interfaces';

import { Document } from '../documents.interface';

@Component({
	template: '',
})
export abstract class DocumentComponent<T extends Document> {
	user: UserProfile;

	@Input() document: NgResourceObject<T>;

	constructor(
		userService: UserService,
	) {
		this.user = userService.profile;
	}
}
