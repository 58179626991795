import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CcmBillingSubscriptionDetailsResourceService, BillingSubscriptionDetails } from 'comergence/tpo/billing/social-media/billing-subscription/billing-subscription-resource.service';
import { SmPackage } from '../invoicing-settings/invoicing-settings.service';

@Component({
    selector: 'ccm-billing-subscription-display',
    templateUrl: './billing-subscription.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcmSmBillingSubscriptionComponent {
    @Input()
    organizationId: number;

    resolved = false;   // Default to spinner being on
    public billingSubscriptionDetails: BillingSubscriptionDetails;

    @Input('smPackage')
    set smPackage(smPackage: SmPackage) {
        if (smPackage) {
            this.initLoading();
        }
    }

    constructor(
        protected _cd: ChangeDetectorRef,
        protected ccmBillingSubscriptionDetailsResourceService: CcmBillingSubscriptionDetailsResourceService
    ) {
    }

    async initLoading(): Promise<void> {
        this.resolved = false;
        try {
            this.billingSubscriptionDetails =
                await this.ccmBillingSubscriptionDetailsResourceService.getBillingSocialMediaSubscriptionDetails(this.organizationId)
                    .toPromise();
        } catch (e) {
        }
        this.resolved = true;

        this._cd.markForCheck();
    }
}
