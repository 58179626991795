import { OnInit } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { Mixin } from 'utils/mixin.decorator';

import { UserService } from 'angularjs-providers/user.provider';

import { ColumnsManagerMixin, ExportMixin } from 'commons/mixins';
import { PagedListComponent } from 'commons/components/list/paged-list.component';

import { UserStatusColor } from '../user-status-color';

@Mixin([ ColumnsManagerMixin, ExportMixin ])
export abstract class SharedUsersListComponent extends PagedListComponent implements OnInit, ColumnsManagerMixin, ExportMixin {
	list: any = [];
	createdUser: any = {};
	UserStatusColor = UserStatusColor;
	service: any;

	// ColumnsManagerMixin
	// GOTCHA: objects and arrays should have default value to properly work with mixins
	StorageService;
	localStorageName;
	columns;
	columns$;
    columnValues: () => [];
	prepareColumns;
	toggleColumn;
	getColumnVisibility;

	// ExportMixin
	url: any;
	export: any;
	getExportParams: any;

	constructor(
		public transitionService: TransitionService,
		public stateService: StateService,
		public User: UserService,
	) {
		super(transitionService, stateService);
		this.list.$resolved = false;

		this.omitParams.push('createdUser');
	}

	setService() {}
	setDefaultFilters() {}
	prepareFilters() {}
	readColumnsConfig() {}

	ngOnInit() {
		super.ngOnInit();
		this.createdUser = this.stateService.params.createdUser;

		if (this.User.profile.isLender) {
			this.url = `/api/download/organizations/${this.User.profile.organization.organizationId}/users/all/export`;
		}
	}

	loadList(queryParams) {
		this.list.$resolved = false;

		return this.service.get(queryParams).$promise
			.then((result) => {
				this.list = result;
				this.list.$resolved = true;
			})
			.catch(() => {
				this.list.$resolved = true;
			});
	}
}
