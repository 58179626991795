<h2 class="flex-row">
    <span class="flex-grow">
        {{title}}
        <smHelpLink [url]="helpLink" *ngIf="helpLink"></smHelpLink>
    </span>
    <ng-container>
        <span *ngFor="let button of buttons" [ngSwitch]="enabled">
            <a [uiSref]="button.sref" class="btn btn-primary" *ngSwitchDefault>{{button.title}}</a>
            <a class="btn btn-primary" *ngSwitchCase="false" (click)="showNotEnabledModal()">{{button.title}}</a>
        </span>
    </ng-container>
</h2>

<div
    class="landing-page-grid-container text-center"
    [style.grid-template-columns]="'repeat(' + items.length + ', 1fr)'"
>
    <ng-container *ngFor="let item of items; let index = index">
        <div
            [ngClass]="[ 'row-1', 'column-' + (index + 1) ]"
            [ngSwitch]="item.isMaterial"
            class="icon-container"
        >
            <img [src]="item.icon" [alt]="item.title" *ngSwitchDefault>
            <div class="material" *ngSwitchCase="true">
                <img [src]="item.icon" [alt]="item.title">
            </div>
            <span *ngIf="item.beta" class="label label-success text-medium text-normal">Beta</span>
        </div>

        <div
            [ngClass]="[ 'row-2', 'column-' + (index + 1) ]"
        >
            <h3>{{item.title}}</h3>
            <p class="text-muted" *ngIf="item.description">{{item.description}}</p>
        </div>

        <div
            [ngClass]="[ 'row-3', 'column-' + (index + 1) ]"
        >
            <ng-container [ngSwitch]="item.noAccess">
                <ng-container *ngSwitchDefault [ngSwitch]="enabled && !item.disabled">
                    <ng-container *ngSwitchDefault>
                        <a
                            [uiSref]="item.sref"
                            [uiOptions]="{reload: true}"
                            class="btn btn-primary"
                            (click)="item.onEnter && item.onEnter()"
                        >{{item.button}}</a>

                        <a *ngFor="let button of item.additionalButtons" [uiSref]="button.sref" class="btn btn-default">{{button.title}}</a>
                    </ng-container>

                    <ng-container *ngSwitchCase="false">
                        <a class="btn btn-primary" (click)="showNotEnabledModal()">{{item.button}}</a>
                        <a *ngFor="let button of item.additionalButtons" class="btn btn-default" (click)="showNotEnabledModal()">{{button.title}}</a>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="true">
                    <button class="btn btn-primary" (click)="showAccessDeniedModal()">{{item.button}}</button>
                    <a *ngFor="let button of item.additionalButtons" class="btn btn-default" (click)="showAccessDeniedModal()">{{button.title}}</a>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>
