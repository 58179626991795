<div class="roles" uiView>
	<notification></notification>

	<h3>
		<div *ngIf="hasManagePermission()" class="pull-right">
			<a class="btn btn-primary" uiSref="users-management.roles.:actionType.:roleId" [uiParams]="{roleId: null, actionType:'new'}" [uiOptions]="{ inherit: false, reload: true }">Create Role</a>
		</div>
		Roles
	</h3>

	<div class="rows-bordered remote-resource" [class.resolved]="roles.$resolved">
		<div class="row-fluid header">
			<span class="col-sm-2 text-left">
				<b>Role Name</b>
			</span>
			<span class="col-sm-1"></span>
			<span class="col-sm-7 text-left">
				<b>Description</b>
			</span>
			<span class="col-sm-2 text-center">
				<b>Users</b>
			</span>
		</div>
		<div class="row-fluid" *ngFor="let role of roles">
			<span class="col-sm-2 text-left">
				<a uiSref="users-management.roles.:actionType.:roleId" [uiParams]="{roleId: role.id, actionType: 'edit'}" [uiOptions]="{ inherit: false, reload: true }">{{role.name}}</a>
			</span>
			<span class="col-sm-1">
				<span class="label" [ngClass]="{'label-success': role.isActive, 'label-default': !role.isActive}">{{role.isActive ? 'Active' : 'Inactive'}}</span>
			</span>
			<span class="col-sm-7 text-left">
				{{role.description || '&ndash;'}}
			</span>
			<span class="col-sm-2 text-center">
				{{role.count}}
			</span>
		</div>
	</div>
</div>
