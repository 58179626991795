<div class="modal-header flex-row">
	<h4 class="modal-title flex"><span class="text-light">{{document.name}}</span> document requested</h4>
	<button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">&times;</button>
</div>

<div class="modal-body remote-resource" [class.resolved]="requests.$resolved">
	<div class="rows-bordered">
		<div class="row-fluid header">
			<strong class="col-md-8">Requested by</strong>
			<strong class="col-md-4">Date of Request</strong>
		</div>
		<div class="row-fluid" *ngFor="let item of requests">
			<div class="col-md-8 va-m">{{item.lenderName}}</div>
			<div class="col-md-4 va-m">{{item.createdDate | date:'MM/dd/yyyy HH:mm'}}</div>
		</div>
	</div>
</div>
