import {
	QuestionType,
	AnswerOption,
	Question,
} from '../../../questionnaires.interface';

export const getAnswers = ({ questionType: originalQuestionType, answers }: Question, questionType: QuestionType): Partial<AnswerOption>[] | null => {
	switch (questionType) {
		case QuestionType.YES_NO: {
			return originalQuestionType === QuestionType.YES_NO
				? answers
				: [
					{ text: 'Yes' },
					{ text: 'No' },
				];
		}
		case QuestionType.SINGLE_SELECT:
		case QuestionType.MULTI_SELECT: {
			return [
				QuestionType.SINGLE_SELECT,
				QuestionType.MULTI_SELECT,
			].includes(originalQuestionType)
				? answers
				: [ {} ];
		}
		default:
			return null;
	}
};
