import { NgModule } from '@angular/core';

import { ListingAlertsIssueStatus, ListingGroupIssueStatus } from './social-media/listings';
import { MonitoringSettingsResourceService } from './monitoring-settings-resource.service';
import { SocialComplianceScheduleIssueStatus } from './social-media/social-compliance/social-compliance-schedule-issue-status';
import { TpoIssuesStatusService } from './tpo-issues-status.service';
import { AccountInProgressQuestionnairesIssueStatus } from './account/account-in-progress-questionnaires-issue-status';
import { HasOutstandingRequiredDocumentsIssueStatus } from './documents/has-outstanding-required-documents-issue-status';
import { HasPastDueInvoiceAlertStatus } from './social-media/subscription/has-past-due-invoice-alert-status';
import { SocialComplianceOrderPaymentFailedIssueStatus } from './social-media/social-compliance/social-compliance-order-payment-failed-issue-status';
import { FinancialAlertStatus } from 'tpo/status/financial/financial-alert-status';


@NgModule({
    imports: [
    ],
	providers: [
        AccountInProgressQuestionnairesIssueStatus,
        HasOutstandingRequiredDocumentsIssueStatus,
        SocialComplianceScheduleIssueStatus,
		ListingGroupIssueStatus,
        ListingAlertsIssueStatus,
        TpoIssuesStatusService,
        MonitoringSettingsResourceService,
        HasPastDueInvoiceAlertStatus,
        SocialComplianceOrderPaymentFailedIssueStatus,
        FinancialAlertStatus,
	],
})
export class TpoStatusModule {}
