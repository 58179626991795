<div class="remote-resource" [class.resolved]="list.$resolved">
	<div class="row">
		<span class="col-sm-3 text-muted">Start Date</span>
		<span class="col-sm-3 text-muted">End Date</span>
	</div><!--end:row-->

	<div class="row">
		<div class="col-sm-3">
			<input type="text" class="form-control"
			       bsDatepicker
				   [bsValue]="params?.dateFrom"
				   [maxDate]="params?.dateTo"
			       placeholder="mm/dd/yyyy"
			       (bsValueChange)="setFilter({dateFrom: $event})"
			/>
		</div>
		<div class="col-sm-3">
			<input type="text" class="form-control"
			       bsDatepicker
				   [bsValue]="params?.dateTo"
				   [minDate]="params?.dateFrom"
			       placeholder="mm/dd/yyyy"
			       (bsValueChange)="setFilter({dateTo: $event})"
			/>
		</div>
	</div><!--end:row-->

	<br>

	<div class="rows-bordered">
		<div class="row-fluid header">
			<span class="col-md-3"><b class="two-liners">Date</b></span>
			<span class="col-md-5"><b class="two-liners">Action</b></span>
			<span class="col-md-a"><b class="two-liners">Source IP</b></span>
		</div>
		<div class="row-fluid" *ngFor="let item of list">
			<span class="col-md-3 text-left">
				{{item.actionDate | date : 'MM/dd/yyyy HH:mm'}}
			</span>
			<span class="col-md-5 text-left">
				<span class="glyphicon text-success glyphicon-ok" *ngIf="'Login Success' === item.loginStatus"></span>
				<span class="glyphicon text-danger glyphicon-remove" *ngIf="'Login Success' !== item.loginStatus"></span>
				{{item.loginStatus}}
			</span>
			<span class="col-md-a text-left">{{item.sourceIP}}</span>
		</div>
		<div class="row-fluid" *ngIf="!list.length">
			<span class="col-sm-12 text-muted text-center text-bigger text-weak">No matches found</span>
		</div>
		<br>

		<new-paging class="no-border" [(list)]="list" [(params)]="params$" [sizes]="[10,25,50]"></new-paging>
	</div>
</div>
