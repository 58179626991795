import { Component, Input, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { UserService } from 'angularjs-providers/user.provider';
import { AccountInformation } from 'shared/account/company-information/account-information.service';


@Component({
    templateUrl: './tpo-financials.component.html',
})
export class TpoFinancialsComponent implements OnInit {
    tpoId: number;
    investorId: number;
    helpLink: string;
    @Input('lenderInfo') companyInformation: AccountInformation;

    constructor(
        private uiRouterGlobals: UIRouterGlobals,
        private readonly userService: UserService,
        public stateService: StateService,
        public routerGlobals: UIRouterGlobals,
    ) {
    }

    // Override
    public ngOnInit(): void {
        const { tpoId } = this.companyInformation.identifier;
        if (this.uiRouterGlobals.params?.id) {
            // Investor
            this.tpoId = tpoId;
            this.investorId = Number(this.userService.profile.organization.id);
            this.helpLink = '/help/authenticated/lender/financials';
        } else {
            // Lender/TPO
            this.tpoId = tpoId;
            this.helpLink = '/help/authenticated/tpo/financials';
        }
    }
}
