import { Glob, StateObject, UIRouter } from '@uirouter/core';

export interface FilterableState {
    name: string;
    params: any;
}

export abstract class BackNavigationFilter {
    public abstract shouldSkipPrevious(
            uiRouter: UIRouter,
            states: FilterableState[],
            currentStateIndex: number,
            previousStateIndex: number): boolean;

    public static stateNamePattern(stateNamePattern: string): BackNavigationFilter {
        return new StateNamePatternBackNavigationFilter(stateNamePattern);
    }

    public static consecutive(subFilter: BackNavigationFilter): BackNavigationFilter {
        return new ConsecutiveBackNavigationFilter(subFilter);
    } 

    public static or(subFilters: BackNavigationFilter[]): BackNavigationFilter {
        return new OrBackNavigationFilter(subFilters);
    }

    public static and(subFilters: BackNavigationFilter[]): BackNavigationFilter {
        return new AndBackNavigationFilter(subFilters);
    }
}

class StateNamePatternBackNavigationFilter extends BackNavigationFilter {
    private readonly glob: Glob;

    public constructor(private readonly stateNamePattern: string) {
        super();

        this.glob = Glob.fromString(this.stateNamePattern);
    }

    // Override
    public shouldSkipPrevious(
            uiRouter: UIRouter,
            states: FilterableState[],
            currentStateIndex: number,
            previousStateIndex: number): boolean {
        if (this.glob && (previousStateIndex >= 0) && (previousStateIndex < states.length)) {
            const globMatches: boolean = this.glob.matches(states[previousStateIndex].name);

            return globMatches;
        }

        return false;
    }
}

class ConsecutiveBackNavigationFilter extends BackNavigationFilter {
    public constructor(private readonly subFilter: BackNavigationFilter) {
        super();
    }

    // Override
    public shouldSkipPrevious(
            uiRouter: UIRouter,
            states: FilterableState[],
            currentStateIndex: number,
            previousStateIndex: number): boolean {
        const previousPreviousStateIndex: number = (previousStateIndex + 1);
        if (previousPreviousStateIndex < states.length) {
            return (this.subFilter.shouldSkipPrevious(uiRouter, states, currentStateIndex, previousStateIndex) &&
                this.subFilter.shouldSkipPrevious(uiRouter, states, currentStateIndex, previousPreviousStateIndex));
        }

        return false;
    }
}

class OrBackNavigationFilter extends BackNavigationFilter {
    public constructor(private readonly subFilters: BackNavigationFilter[]) {
        super();
    }

    // Override
    public shouldSkipPrevious(
            uiRouter: UIRouter,
            states: FilterableState[],
            currentStateIndex: number,
            previousStateIndex: number): boolean {
        for (let ii = 0; ii < this.subFilters.length; ii++) {
            if (this.subFilters[ii].shouldSkipPrevious(uiRouter, states, currentStateIndex, previousStateIndex)) {
                return true;
            }
        }

        return false;
    }
}

class AndBackNavigationFilter extends BackNavigationFilter {
    public constructor(private readonly subFilters: BackNavigationFilter[]) {
        super();
    }

    // Override
    public shouldSkipPrevious(
            uiRouter: UIRouter,
            states: FilterableState[],
            currentStateIndex: number,
            previousStateIndex: number): boolean {
        if (this.subFilters.length == 0) {
            return false;
        }

        for (let ii = 0; ii < this.subFilters.length; ii++) {
            if (!this.subFilters[ii].shouldSkipPrevious(uiRouter, states, currentStateIndex, previousStateIndex)) {
                return false;
            }
        }

        return true;
    }
}
