import { cloneDeep } from 'lodash';
import { Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';

import { TpoApplicationService, AccountExecutive, AccountExecutiveValues } from 'tpo/application/application.service';

@Component({
    templateUrl: './account-executive.component.html',
    selector: 'account-executive',
})
export class AppAccountExecutiveComponent {
    accountExecutive: AccountExecutive;
    accountExecutiveInitial: AccountExecutive;
    accountExecutiveValues: Observable<AccountExecutiveValues>;

    channelId: number;
    editing: boolean;
    loading = true;


    constructor(
        { params: { channelId } }: UIRouterGlobals,
        private readonly applicationService: TpoApplicationService,
    ) {
        this.channelId = channelId;
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.accountExecutiveValues = this.applicationService.getAppAccountExecutiveValues(this.channelId);
        this.accountExecutive = await this.applicationService.getAppAccountExecutive(this.channelId).toPromise();
        this.loading = false;
    }

    async save(): Promise<void> {
        this.loading = true;

        try {
            await this.applicationService.updateAppAccountExecutive(this.channelId, this.accountExecutive).toPromise();
            this.editing = false;
        } catch (e) {
            this.accountExecutive = this.accountExecutiveInitial;
        }

        this.loading = false;
    }

    edit(): void {
        this.editing = true;
        this.accountExecutiveInitial = cloneDeep(this.accountExecutive);
    }

    cancel(): void {
        this.editing = false;
        this.accountExecutive = cloneDeep(this.accountExecutiveInitial);
    }

    compare = (a: AccountExecutive, b: AccountExecutive): boolean => a.id === b.id;
}
