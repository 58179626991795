import { reduce, keys, find, findKey, chain, get } from 'lodash';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormArray } from 'commons/forms/form-array.hoc';
import { Subscription } from 'rxjs';
import { BoxShowAnimation } from 'commons/animation/simpleShowAnimation';

const defaultMessage = (label) => (`Please enter valid <b>${label}</b>`);
const requiredTemplate = 'Please enter {field}';

const parseTemplate = (template: string, labels: string[]) => (
    template.replace('{field}', labels.map((value) => (`<span> ${value}</span>`)).join(''))
);

@Component({
    selector: 'form-errors',
    templateUrl: './form-errors.component.html',
    animations: [
        BoxShowAnimation,
    ],

})
export class FormErrorsComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    form: RealmFormGroup | RealmFormArray;
    _sub: Subscription;
    errors: object[] = [];
    groupedErrors: string[] = [];


    constructor(protected cd: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.startCheck();
    }

    ngOnChanges({ form }: SimpleChanges) {
        if (form) {
            this.startCheck();
        }
    }

    ngOnDestroy() {
        this._sub?.unsubscribe();
    }

    startCheck() {
        this._sub?.unsubscribe();

        this._sub = this.form.valueChanges.subscribe((changes) => {
            setTimeout(() => {
                const templateAccumulator: { [key: string]: string[] } = {};

                const pushTemplateError = (template: string, label: string) => {
                    templateAccumulator[template] = [...get(templateAccumulator, template, []), label];
                };

                const recursiveReduce = (formGroup) => {
                    /* Global form errors, should always have {message} */
                    const startAcc = [];
                    const groupErrors = formGroup.errors;
                    if (groupErrors) {
                        const firstError = chain(groupErrors).toPairs().filter(([name, message]) => (name !== 'required')).head().value();
                        if (firstError) {
                            startAcc.push({ ...firstError[1] });
                        }
                    }

                    return reduce(keys(formGroup.controls), (acc, name) => {
                        const control = formGroup.controls[name];
                        const errors = control.errors;

                        if (control instanceof RealmFormGroup || control instanceof RealmFormArray) {
                            const insideErrors = recursiveReduce(control);
                            acc.push(...insideErrors);
                        }

                        if (!errors || control.untouched || find(acc, (value) => (value[name]))) {
                            return acc;
                        }

                        const label = control.label || name;

                        const someRequired = findKey(errors, (obj, key) => (key === 'required'));
                        if (someRequired) {
                            // Using default required template for required fields
                            pushTemplateError(requiredTemplate, label);
                        }

                        const firstError = chain(errors).toPairs().filter(([innerName, innerMessage]) => (innerName !== 'required')).head().value();
                        if (!firstError) {
                            return acc;
                        }

                        const [_, messageObj] = firstError;

                        if (messageObj.template) {
                            pushTemplateError(messageObj.template, label);

                            // skip normal error collection is error is templated;
                            return acc;
                        }

                        const message = messageObj.message ? `<b>${label}</b>: ${messageObj.message}` : defaultMessage(label);

                        return [...acc, { label, ...messageObj, message }];
                    }, startAcc);
                };

                this.errors = recursiveReduce(this.form);
                this.groupedErrors = reduce(
                    templateAccumulator,
                    (errors: string[], value: string[], template: string) => ([...errors, parseTemplate(template, value)]),
                    [],
                );

                this.cd.detectChanges();
            }, 10);
        });
    }

    index = (index) => index;

    get hasErrors() {
        return this.groupedErrors.length || this.errors.length || this.form.serverError;
    }
}
