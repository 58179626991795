import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { ListParams } from 'commons/components/new-list/list.component';
import { FilterDefault } from 'commons/components/list/list.interface';

import {
	CompaniesListItem,
	MarketLocation,
	MsaListItem,
	MapLocation,
	MarketLocationParams,
	MarketMapMarkerInfo,
} from './market.interface';

declare let apiPath: string;

@Injectable()
export class MarketService {
	constructor(
		private readonly http: RealmHttpClient,
	) {}

	public getMsaList(listParams: ListParams & MarketLocationParams): Observable<PagedData<MsaListItem[]>> {
		return this.http.pagedRequest<MsaListItem[]>(
			'GET',
			`${apiPath}/crm/forecast/msas`,
			listParams,
		);
	}

	public getCompaniesList(listParams: ListParams & MarketLocationParams): Observable<PagedData<CompaniesListItem[]>> {
		return this.http.pagedRequest<CompaniesListItem[]>(
			'GET',
			`${apiPath}/crm/marketplace/search`,
			listParams,
		);
	}

	public getLicenseTypes(params: MarketLocationParams): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/filter/license-type`,
			params
		);
	}

	public getAuthValues(): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/filter/authorization`,
		);
	}

	public getBusinessModel(): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/filter/business-model`,
		);
	}

	public getBusinessTypes(): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/filter/business-type`,
		);
	}

	public getClientTypes(): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/filter/client-type`,
		);
	}

	public getLoanTypes(): Observable<FilterDefault[]> {
		return this.http.request<FilterDefault[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/filter/loan-type`,
		);
	}

	public getMarketplaceLocations(q: string): Observable<MarketLocation[]> {
		return this.http.request<MarketLocation[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/locations`,
			{ q }
		);
	}

	public getMarkerInfo(companyNmlsId: number, locationNmlsId: number): Observable<MarketMapMarkerInfo> {
		return this.http.request<MarketMapMarkerInfo>(
			'GET',
			`${apiPath}/crm/marketplace/search/map-data/marker-info`,
			{ companyNmlsId, locationNmlsId }
		);
	}

	getMapData(params: MarketLocationParams & ListParams): Observable<PagedData<MapLocation[]>> {
		return this.http.pagedRequest<MapLocation[]>(
			'GET',
			`${apiPath}/crm/marketplace/search/map-data`,
			params,
		);
	}
}
