export interface OwnerFindings {
    id: number;
    name: string;
    type: string;
    ownershipPercentage: number;
    ssnVerified: boolean;
    total: number;
    notReviewed: number;
}

export interface SortedOwnerFindings {
    total: number;

    currentOwners: OwnerFindings[];
    Current?: OwnerFindings[];

    previousOwners: OwnerFindings[];
    Previous?: OwnerFindings[];
}

const _OWNERSHIP_TYPES = [ 'Current', 'Previous' ] as const;
export type OwnershipType = typeof _OWNERSHIP_TYPES[number];
export const OWNERSHIP_TYPES: OwnershipType[] = [..._OWNERSHIP_TYPES];
