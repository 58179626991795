<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">&times;</button>
    <h4 class="modal-title">Purchase</h4>
</div>
<form [formGroup]="form" (onValidSubmit)="save()">
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form-errors [form]="form"></form-errors>
        <p>
            By purchasing this profile you accept to be billed on your next invoice.
            <a href="/help/authenticated/lender/relationships/purchase-profile" target="_blank">More information</a>.
        </p>
        <br>
        <div class="row">
            <div class="col-md-6 form-group no-margin" has-error>
                <label class="control-label required">{{form.getFieldLabel('restoreToStatusId')}}</label>
                <ng-select
                           formControlName="restoreToStatusId"
                           [items]="availableStatuses"
                           bindLabel="name"
                           bindValue="id"
                           [clearable]="false"
                           placeholder="Select Status"></ng-select>
            </div>
            <div class="col-md-6 form-group no-margin" has-error>
                <label class="control-label required">{{form.getFieldLabel('restoreDate')}}</label>
                <div>
                    <input class="form-control"
                           bsDatepicker
                           [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                           [minDate]="minDate"
                           formControlName="restoreDate"
                           placeholder="mm/dd/yyyy">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div class="text-right">
            <button type="submit" class="btn btn-primary">Purchase</button>
            <button type="button" (click)="modalRef.hide()" class="btn btn-default">Cancel</button>
        </div>
    </div>
</form>
