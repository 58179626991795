import { Directive, Renderer2, ElementRef, Input, HostListener, forwardRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const numberRegex: RegExp = /^-?\d*(?:\.\d*(?:e[+-]\d+)?)?$/;
const stripRegex: RegExp = /[,]/g;

@Directive({
	selector: '[validFloat]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => ValidFloatAccessor),
		multi: true,
	}],
})
export class ValidFloatAccessor implements ControlValueAccessor {
	@Input('validFloat') set setFloatFormat(value: string) {
		this.floatFormat = value;
		this.parseValue(this._lastValue + '');
		this._updateInput(this._lastValue);
		this.onTouchedCallback();
	}
	@Input('maxlength') set setMaxLength(value: string) {
		const newMax = parseInt(value, 10);
		if (!Number.isNaN(newMax)) {
			this._maxLength = newMax;
		} else {
			// tslint:disable-next-line:no-console
			console.warn('Ignored setMaxLength', value);
		}
	}

	floatFormat: string;

	public _maxLength = 20;

	public _lastValue: number;
	public _lastCursor: number;

	public _decimalPipe: DecimalPipe;

	constructor(public _renderer: Renderer2, public _elementRef: ElementRef) {
		this._decimalPipe = new DecimalPipe('en-US');
	}

	onChangeCallback = (_: unknown): void => {
	}

	onTouchedCallback = (): void => {
	}

	public _format(value): string {
		return this._decimalPipe.transform(value, this.floatFormat);
	}

	public _updateInput(value): void {
		this._renderer.setProperty(this._elementRef.nativeElement, 'value', this._format(value));
	}

	public _revertPreviousValue(): number {
		this._updateInput(this._lastValue);
		this._elementRef.nativeElement.setSelectionRange(this._lastCursor, this._lastCursor);
		return this._lastValue;
	}

	@HostListener('keydown', ['$event.target']) keydown(element: HTMLInputElement): void {
		this._lastCursor = element.selectionStart;
	}

	@HostListener('input', ['$event', '$event.target']) input(e, element: HTMLInputElement): void {
		this.parseValue(element.value);
		this._lastCursor = element.selectionStart;
	}

	@HostListener('blur', ['$event.target.value']) touched(): void {
		this._updateInput(this._lastValue);
        this.onChangeCallback(this._lastValue);
		this.onTouchedCallback();
	}
	parseValue(inputValue: string): void {
		const value = inputValue.replace(stripRegex, '');

		let parsedValue: number;
		if (!value || value === '-' || value === '.') {
			parsedValue = null;
		} else if (value.match(numberRegex)) {
			const formattedValue = this._format(value);
			parsedValue = (formattedValue.length > this._maxLength && formattedValue.length >= this._lastValue.toString().length) ? this._revertPreviousValue() : parseFloat(formattedValue.replace(stripRegex, ''));
		} else {
			parsedValue = this._revertPreviousValue();
		}

		this._lastValue = parsedValue;
		this.onChangeCallback(parsedValue);
	}

	writeValue(value: number): void {
		this._lastValue = value;
		this._lastCursor = this._elementRef.nativeElement.getAttribute('selectedIndex');
		this._updateInput(value);
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: () => unknown): void {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: () => unknown): void {
		this.onTouchedCallback = fn;
	}
}
