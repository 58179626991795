import { Injectable, Injector } from '@angular/core';
import moment from 'moment';
import { forEach, omit } from 'lodash';

import { UserService } from 'angularjs-providers/user.provider';
import { NgResourceObject } from 'commons/interfaces';

declare const apiPath: string;

export function timeToDate(time: string): Date {
	return moment(`2000-01-01 ${time}`).toDate();
}

export function dateToTime(date: Date): string {
	return moment(date).format('HH:mm');
}

export interface Exclamation {
	exclamation: boolean;
}

enum AddressDiscrepancy {
	address = 'address',
	city = 'city',
	state = 'state',
	zip = 'zip',
	fullAddress = 'fullAddress',
}

interface Address {
	address: string;
	city: string;
	state: string;
	zip: string;
}

export interface ListingGroup extends Address {
	id: number;
	nmlsId: number;
	name: string;
	businessName: string;
	phoneNumber: string;
	website: string;
	description: string;
	networks: {
		[key: string]: boolean;
	};
	hasCoverImage: boolean;
	coverImage?: string;
	isAddressEditable: boolean;
}

interface ListingGroupAlertServerResource {
	listingGroupId: number;
	listingDetailsId: number;
	networkId: string;
	listingName: string;
	id: number;
	code: string;
}

export interface ListingGroupOpenAlertServerResource extends ListingGroupAlertServerResource {
	groupValue: string;
	listingValue: string;
	previousValue: string;
}

export interface ListingGroupResolvedAlertServerResource extends ListingGroupAlertServerResource {
	alertValue: string;
	confirmedValue: string;
	confirmedBy: string;
	confirmedDate: number;
}

export enum ListingGroupAlertField {
	ADDRESS = 'Address',
	NAME = 'Name',
	PHONENUMBER = 'Phone',
}

interface ListingGroupAlert {
	listingGroupId: number;
	listingId: number;
	listingNetworkCode: string;
	listingName: string;
	id: number;
	alertField: ListingGroupAlertField;
}

export interface ListingGroupOpenAlert extends ListingGroupAlert {
	listingGroupValue: string;
	listingValue: string;
	previousValue: string;
}

export interface ListingGroupResolvedAlert extends ListingGroupAlert {
	alertValue: string;
	confirmedValue: string;
	confirmedBy: string;
	confirmedDate: number;
}

enum NmlsLocationBaseDiscrepancy {
	locationName = 'locationName',
	locationNmlsId = 'locationNmlsId',
}

type NmlsLocationDiscrepancy = AddressDiscrepancy | NmlsLocationBaseDiscrepancy;

export interface ListingGroupNmlsLocation extends Address {
	locationType: string;
	locationNmlsId: number;
	locationName: string;
	branchManagers: any[];
	discrepancies: NmlsLocationDiscrepancy[];
}

enum ListingBaseDiscrepancy {
	businessName = 'businessName',
	phoneNumber = 'phoneNumber',
	website = 'website',
	description = 'description',
	'regularHours.1' = 'regularHours.1',
	'regularHours.2' = 'regularHours.2',
	'regularHours.3' = 'regularHours.3',
	'regularHours.4' = 'regularHours.4',
	'regularHours.5' = 'regularHours.5',
	'regularHours.6' = 'regularHours.6',
	'regularHours.7' = 'regularHours.7',
}

type ListingDiscrepancy = AddressDiscrepancy | ListingBaseDiscrepancy;

export interface ListingGroupListing extends Address {
	groupId: number;
	listingDetailsId: number;
	mediaLinkId: number;
	name: string;
	businessName: string;
	phoneNumber: string;
	website: string;
	networkCode: string;
	description: string;
	coverImage?: string;
	discrepancies: ListingDiscrepancy[];
	syncWithGroup: boolean;
}

export interface AlertedListing extends ListingGroupListing {
	alertedDate: number;
}

@Injectable({
	providedIn: 'root',
})
export class CompanyListingsService {
	private listingGroupsListResource: any;
	private listingGroupResource: any;
	private listingGroupExclamations: {
		alerts: any;
	};
	private listingGroupAlerts: {
		open: any;
		resolved: any;
		alert: any;
	};

	// listing group nmls locations
	private listingGroupNmlsLocationsListResource: any;
	private listingGroupNmlsLocationResource: any;

	// listing group listings
	private listingGroupListingsListResource: any;
	private listingGroupListingResource: any;

	private alertedListingsResource: any;

	private nmlsLocationsListResource: any;

	constructor(
		injector: Injector,
		user: UserService,
	) {
		const PagedResource = injector.get('$injector').get('PagedResource');
		const resource = injector.get('$injector').get('$resource');

		// configs
		const defaultConfig = {
			url: `${apiPath}/tpos/:tpoId`,
			params: {
				tpoId: user.profile.organization.id,
			},
		};
		const listingGroupsListResourceConfig = {
			url: `${defaultConfig.url}/company-listing-groups`,
			params: {
				...defaultConfig.params,
			},
		};
		const listingGroupResourceConfig = {
			url: `${listingGroupsListResourceConfig.url}/:listingGroupId`,
			params: {
				...listingGroupsListResourceConfig.params,
			},
		};
		const listingGroupExclamationsConfig = {
			url: `${listingGroupResourceConfig.url}/exclamations`,
			params: {
				...listingGroupResourceConfig.params,
			},
		};
		const listingGroupAlertsConfig = {
			url: `${listingGroupResourceConfig.url}/alerts`,
			params: {
				...listingGroupResourceConfig.params,
			},
		};
		const listingGroupAlertConfig = {
			url: `${listingGroupResourceConfig.url}/alerts/:alertId`,
			params: {
				...listingGroupResourceConfig.params,
			},
		};

		// listing group nmls locations
		const listingGroupNmlsLocationsListResourceConfig = {
			url: `${listingGroupResourceConfig.url}/nmls-locations`,
			params: {
				...listingGroupResourceConfig.params,
			},
		};
		const listingGroupNmlsLocationResourceConfig = {
			url: `${listingGroupNmlsLocationsListResourceConfig.url}/:nmlsId`,
			params: {
				...listingGroupNmlsLocationsListResourceConfig.params,
			},
		};

		// listing group listings
		const listingGroupListingsListResourceConfig = {
			url: `${listingGroupResourceConfig.url}/listing-details`,
			params: {
				...listingGroupResourceConfig.params,
			},
		};
		const listingGroupListingResourceConfig = {
			url: `${listingGroupListingsListResourceConfig.url}/:mediaLinkId`,
			params: {
				...listingGroupListingsListResourceConfig.params,
			},
		};

		// resources
		this.listingGroupsListResource = PagedResource(
			listingGroupsListResourceConfig.url,
			listingGroupsListResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		const combineDiscrepancy = <T extends ListingGroupNmlsLocation | ListingGroupListing>(value: T): T => {
			const hasAddressDiscrepancy = value.discrepancies?.some((value): boolean => {
				switch (value) {
					case AddressDiscrepancy.address:
					case AddressDiscrepancy.city:
					case AddressDiscrepancy.state:
					case AddressDiscrepancy.zip:
						return true;
					default:
						return false;
				}
			});

			if (hasAddressDiscrepancy) {
				value.discrepancies.push(AddressDiscrepancy.fullAddress);
			}

			return value;
		};

		const listResponseParser = <T extends ListingGroupNmlsLocation | ListingGroupListing>(response) => forEach(JSON.parse(response), (value) => combineDiscrepancy<T>(value));

		const detailsResponseParser = (response) => {
			const result = JSON.parse(response, (key, value) => {
				if (key === 'regularHours') {
					const result = {};
					for (let i = 0; i++ < 7;) {
						result[`${i}`] = [];
					}
					forEach(value, (time) => {
						const { day } = time;
						const startTime = timeToDate(time.startTime);
						const endTime = timeToDate(time.endTime);

						result[`${day}`].push({ startTime, endTime });
					});

					return result;
				}
				return value;
			});

			if (result.discrepancies) {
				combineDiscrepancy(result);
			}
			return result;
		};

		const detailsRequestStringify = (request) => {
			const formData = new FormData();
			const details = JSON.stringify(omit(request, [ 'coverImage', 'logo' ]), (key, value) => {
				if (key === 'regularHours') {
					const result = [];

					forEach(value, (timeTable, day) => {
						forEach(timeTable, (time) => {
							const startTime = dateToTime(time.startTime);
							const endTime = dateToTime(time.endTime);
							result.push({ day: parseInt(day, 10), startTime, endTime });
						});
					});

					return result;
				}
				return value;
			});
			const blobData = new Blob([ details ], {
				type: 'application/json',
			});
			formData.append('details', blobData);
			if (request.coverImage instanceof File) {
				formData.append('cover', request.coverImage);
			}
			if (request.logo instanceof File) {
				formData.append('logo', request.logo);
			}

			return formData;
		};

		this.listingGroupResource = resource(
			listingGroupResourceConfig.url,
			listingGroupResourceConfig.params,
			{
				get: {
					method: 'GET',
					transformResponse: detailsResponseParser,
				},
				save: {
					method: 'PUT',
					headers: {
						'Content-Type': undefined,
					},
					transformResponse: detailsResponseParser,
					transformRequest: detailsRequestStringify,
				},
				patch: {
					method: 'PATCH',
				},
			},
		);

		this.listingGroupExclamations = {
			alerts: resource(
				`${listingGroupExclamationsConfig.url}/alerts`,
				listingGroupExclamationsConfig.params,
				{
					get: {
						method: 'GET',
					},
				},
			),
		};

		this.listingGroupAlerts = {
			open: PagedResource(
				`${listingGroupAlertsConfig.url}/open`,
				listingGroupAlertsConfig.params,
				{
					get: {
						method: 'GET',
						isArray: true,
						transformResponse: (response): ListingGroupOpenAlert[] | null => {
							const data: ListingGroupOpenAlertServerResource[] = JSON.parse(response);
							return (
								Array.isArray(data)
									? data.map(({
										groupValue: listingGroupValue,
										listingDetailsId: listingId,
										networkId: listingNetworkCode,
										code: alertFieldCode,
										...item
									}) => ({
										listingGroupValue,
										listingId,
										listingNetworkCode,
										alertField: ListingGroupAlertField[alertFieldCode],
										...item,
									}))
									: null
							);
						},
					},
				},
			),
			resolved: PagedResource(
				`${listingGroupAlertsConfig.url}/resolved`,
				listingGroupAlertsConfig.params,
				{
					get: {
						method: 'GET',
						isArray: true,
						transformResponse: (response): ListingGroupResolvedAlert[] | null => {
							const data: ListingGroupResolvedAlertServerResource[] = JSON.parse(response);
							return (
								Array.isArray(data)
									? data.map(({
										listingDetailsId: listingId,
										networkId: listingNetworkCode,
										code: alertFieldCode,
										...item
									}) => ({
										listingId,
										listingNetworkCode,
										alertField: ListingGroupAlertField[alertFieldCode],
										...item,
									}))
									: null
							);
						},
					},
				},
			),
			alert: resource(
				listingGroupAlertConfig.url,
				listingGroupAlertConfig.params,
				{
					resolve: {
						url: `${listingGroupAlertConfig.url}/resolved/:resolveType`,
						method: 'PUT',
					},
				},
			),
		};

		// listing group nmls locations
		this.listingGroupNmlsLocationsListResource = resource(
			listingGroupNmlsLocationsListResourceConfig.url,
			listingGroupNmlsLocationsListResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
					transformResponse: (data) => listResponseParser<ListingGroupNmlsLocation>(data),
				},
				dismissSuggestion: {
					url: `${listingGroupNmlsLocationsListResourceConfig.url}/suggestion/:nmlsId`,
					method: 'DELETE',
				},
			},
		);
		this.listingGroupNmlsLocationResource = resource(
			listingGroupNmlsLocationResourceConfig.url,
			listingGroupNmlsLocationResourceConfig.params,
			{
				link: {
					method: 'PUT',
				},
				unlink: {
					method: 'DELETE',
				},
				move: {
					url: `${listingGroupNmlsLocationResourceConfig.url}/group`,
					method: 'PUT',
				},
				moveToNew: {
					url: `${listingGroupNmlsLocationResourceConfig.url}/group`,
					method: 'POST',
				},
				getMoveList: {
					url: `${listingGroupNmlsLocationResourceConfig.url}/move-to-group-candidates`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		// listing group listings
		this.listingGroupListingsListResource = resource(
			listingGroupListingsListResourceConfig.url,
			listingGroupListingsListResourceConfig.params,
			{
				list: {
					method: 'GET',
					isArray: true,
					transformResponse: (data) => listResponseParser<ListingGroupListing>(data),
				},
				get: {
					url: `${listingGroupResourceConfig.url}/:listingDetailsId`,
					method: 'GET',
					transformResponse: detailsResponseParser,
				},
				save: {
					url: `${listingGroupResourceConfig.url}/:listingDetailsId`,
					method: 'PUT',
					headers: {
						'Content-Type': undefined,
					},
					transformResponse: detailsResponseParser,
					transformRequest: detailsRequestStringify,
				},
				patch: {
					url: `${listingGroupResourceConfig.url}/:listingDetailsId`,
					method: 'PATCH',
					transformResponse: detailsResponseParser,
				},
				getCandidates: {
					url: `${listingGroupListingsListResourceConfig.url}-candidates`,
					method: 'GET',
					isArray: true,
				},
			},
		);
		this.listingGroupListingResource = resource(
			listingGroupListingResourceConfig.url,
			listingGroupListingResourceConfig.params,
			{
				move: {
					url: `${listingGroupListingResourceConfig.url}/group`,
					method: 'PUT',
				},
				moveToNewGroup: {
					url: `${listingGroupListingResourceConfig.url}/group`,
					method: 'POST',
				},
				getMoveList: {
					url: `${listingGroupListingResourceConfig.url}/move-to-group-candidates`,
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.alertedListingsResource = PagedResource(
			`${defaultConfig.url}/company-listing-details/alerted`,
			{
				...defaultConfig.params,
			},
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);

		this.nmlsLocationsListResource = resource(
			`${listingGroupsListResourceConfig.url}/nmls-locations`,
			listingGroupsListResourceConfig.params,
			{
				get: {
					method: 'GET',
					isArray: true,
				},
			},
		);
	}

	listingGroupsList = (...params) => this.listingGroupsListResource.get(...params);
	listingGroupDetails = (...params) => this.listingGroupResource.get(...params);
	// TODO: move to statuses
	groupAlertsExclamation = (...params): NgResourceObject<Exclamation> => this.listingGroupExclamations.alerts.get(...params);
	listingGroupOpenAlerts = (...params) => this.listingGroupAlerts.open.get(...params);
	listingGroupResolvedAlerts = (...params) => this.listingGroupAlerts.resolved.get(...params);
	resolveListingGroupAlert = (...params) => this.listingGroupAlerts.alert.resolve(...params);
	saveListingGroup = (...params) => this.listingGroupResource.save(...params);
	saveListingGroupPartial = (...params) => this.listingGroupResource.patch(...params);

	// listing group nmls locations
	listingGroupNmlsLocationsList = (...params) => this.listingGroupNmlsLocationsListResource.get(...params);
	linkListingGroupNmlsLocation = (...params) => this.listingGroupNmlsLocationResource.link(...params);

	// listing group listings
	listingGroupListingsList = (...params) => this.listingGroupListingsListResource.list(...params);
	listingGroupListingsDetails = (...params) => this.listingGroupListingsListResource.get(...params);
	saveListingGroupListingsDetails = (...params) => this.listingGroupListingsListResource.save(...params);
	patchListingGroupListingsDetails = (...params) => this.listingGroupListingsListResource.patch(...params);
	listingGroupListingsListCandidates = (...params) => this.listingGroupListingsListResource.getCandidates(...params);
	moveListing = (...params) => this.listingGroupListingResource.move(...params);
	moveListingToNewGroup = (...params) => this.listingGroupListingResource.moveToNewGroup(...params);
	listingMoveList = (...params) => this.listingGroupListingResource.getMoveList(...params);

	alertedListings = (...params) => this.alertedListingsResource.get(...params);

	nmlsLocationsList = (...params) => this.nmlsLocationsListResource.get(...params);
}
