import { map, forIn } from 'lodash';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import {
	Thumbnails,
	TpoPublisherAttachmentService,
} from 'tpo/social-compliance/publisher/attachments/tpo-publisher-attachment.service';

import { dataURLtoFile } from 'utils/data-url-to-file';
import defaultThumbnail from '~static/images/posts-preview/empty-image.png';

@Injectable()
export class PublisherAttachmentsPreviewService {
	private defaultVideoThumbnail = defaultThumbnail;
	private updateInstance: { [networkCode: string]: () => void; } = {};
	private readonly _instanceThumbnailsCache = new Map<number, Thumbnails>();

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private attachmentService: TpoPublisherAttachmentService,
	) {}

	public getDefaultThumbnail(): string {
		return this.defaultVideoThumbnail;
	}

	async getCachedThumbnails(attachmentId: number): Promise<Thumbnails> {
		if (!this._instanceThumbnailsCache.has(attachmentId)) {
			await this.setCachedThumbnails(attachmentId);
		}
		return this._instanceThumbnailsCache.get(attachmentId);
	}

	public async setCachedThumbnails(attachmentId: number, thumbnails?: Thumbnails): Promise<void> {
		if (thumbnails) {
			this._instanceThumbnailsCache.set(attachmentId, thumbnails);
		} else {
			const thumbnails = await this.attachmentService.getThumbnails(attachmentId).$promise;
			this._instanceThumbnailsCache.set(attachmentId, thumbnails);
		}
	}

	public setInstance(key: string, instance: () => void | null): void {
		this.updateInstance[key] = instance;
	}

	public updatePreviews(): void {
		forIn(this.updateInstance, (init) => {
			typeof init === 'function' && init();
		});
	}

	public getVideoPreviews(video: File, previewsRange: [number, number, number]): Promise<ThumbnailFrame[] | null> {
		return this.generateThumbnails(video, previewsRange).then((frames: ThumbnailFrame[] | null) => {
			if (frames) {
				const promises = map(frames, (frame) => ({ ...frame, file: dataURLtoFile(frame.url) }));
				return Promise.all(promises).then((resolvedFrames: ThumbnailFrame[]) => resolvedFrames);
			} else {
				return Promise.resolve(null);
			}
		});
	}

	private generateThumbnails(videoFile: File, previewsRange: [number, number, number]): Promise<ThumbnailFrame[] | null> {
		const video: HTMLVideoElement = this.document.createElement('video');
		const canvas: HTMLCanvasElement = this.document.createElement('canvas');
		const context: CanvasRenderingContext2D = canvas.getContext('2d');
		return new Promise<ThumbnailFrame[] | null>((resolve) => {
            const frames: ThumbnailFrame[] = [];
			const reject = (): void => resolve(null);
			canvas.addEventListener('error', reject);
			video.addEventListener('error', reject);
			video.addEventListener('loadeddata', () => {
				if (!isNaN(video.duration)) {
					video.currentTime = video.duration * (previewsRange[0] / 100);
				}

				video.addEventListener('seeked', () => {
					context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
					frames.push({
                        url: canvas.toDataURL('image/jpeg', 0.7),
                        offset: Math.trunc(video.currentTime * 1000), // note: milliseconds
                    });
					const length = frames.length;
					if (length < previewsRange.length) {
						video.currentTime = video.duration * (previewsRange[length] / 100);
					} else {
						resolve(frames);
					}
				});

				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
			});
			if (videoFile.type) {
				video.setAttribute('type', videoFile.type);
			}
			video.preload = 'auto';
			video.src = window.URL.createObjectURL(videoFile);
			video.load();
		});
	}
}

export interface ThumbnailFrame {
    url: string;
    file?: File
    offset: number;
}
